<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <section class="page pageheader">
        <div class="container">
            <div class="col-xs-6 col-sm-6 col-lg-6 abannimgwrp">
                <div class="otherbannimg"><img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" /></div>
            </div>
            <div class="col-xs-6 col-sm-6 col-lg-6">
                <div class="ptitle">
                    <h1>{{title}}</h1>
                    <h3>{{subtitle}}</h3>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-lg-6 mcont100">
                <p [innerHTML]="description"></p>
            </div>

        </div>
    </section>
    <section class="aboutsectin2 awardswapper">
        <div class="container">
            <div class="row nsg_text">
                <div class="col-xs-12 col-lg-12">
                    <h2>Developmental Milestones</h2>
                    <p>For premature babies, the developmental milestones might arrive slighted behind than those for normal kids. This is no cause for worry, as over a period of time they will catch up with the rest. Sometimes babies require additional
                        support or physiotherapy to grow adequately. In other cases, it might just be a time to be patient and they will catch up sooner than later.</p>
                    <p>It is a good practice to have the child’s progress assessed regularly and take advice from your paediatrician. Regular monitoring coupled with nutrition and support will always aid your child’s growth.</p>
                </div>
            </div>
            <div class="row nsg_text">
                <div class="col-xs-12 col-lg-12">
                    <h2>Starting primary School</h2>
                    <p>Starting school is an important milestone for every child. Typically, they will start at the age of four in kindergarten or even earlier if it’s a play school. At this age there shouldn’t be extra burden placed on the child and allowing
                        the time &amp; space to grow is vital. Given that the child is sensitive and impressionable, excess pressure to start school might prove counter productive.</p>
                    <p>Parents can start teaching the children at home, some of the basic things and soon enough they will come up the curve. Premature babies may take time and it is normal. Pushing them harder will not be of help, instead try to support
                        your children.</p>
                </div>
            </div>
            <div class="row nsg_text">
                <div class="col-xs-12 col-lg-12">
                    <h2>Advice for families with a disabled child</h2>
                    <p>Premature children sometimes will also have certain disabilities compared to other children. In such cases, parents have a greater sense of responsibility to support the child. They should seek advise and counselling on how best to
                        help the child. Giving them love &amp; affection with confidence is vital for their growth.</p>
                    <p>Disabilities can be overcome and your child shouldn’t be made to feel any different from the others.</p>
                </div>
            </div>
            <div class="row nsg_text">
                <div class="col-xs-12 col-lg-12">
                    <h2>The Next Pregnancy</h2>
                    <p>One preterm pregnancy does not automatically qualify you for a subsequent preterm pregnancy. Often some couples become pessimistic and would like to avoid subsequent pregnancies, however, such fears are unfounded. Preterm pregnancies
                        occur due to a wide range of factors – it is advisable to understand them and take preventive measures – instead of avoiding pregnancy altogether. </p>
                </div>
            </div>
        </div>
    </section>
    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>