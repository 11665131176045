// import { Subscription } from 'rxjs/Rx';
import { ApiAppointment } from './../services/api.appointment';
import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  PLATFORM_ID,
  ViewChildren,
  QueryList,
  Input,
  HostListener,
  EventEmitter,
  Output,
  ChangeDetectorRef,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { DatePipe } from '@angular/common';

import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
} from '@angular/forms';

import { ApiService } from '../services/api.service';

import { IMG_BASE_URL, BASE_URL } from '../services/constants';
import { Subject } from 'rxjs';
// import { Http, RequestOptions, URLSearchParams, Headers } from "@angular/http";

import * as moment from 'moment';

import { Router, ActivatedRoute, Params } from '@angular/router';
// import { errorHandler } from "@angular/platform-browser/src/browser";
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
declare let $: any;
declare let initHome: any;
declare let initPopup: any;
declare let gtag: any;
declare let dataLayer: any;
declare let dataLayerPush: any;
@Component({
  selector: 'book-an-appointment-detail',
  templateUrl: './book-an-appointment-detail.component.html',
  styles: [
    `
      .has-error {
        border: 1px solid red !important;
      }
      input[disabled] {
        border: 1px solid rgba(61, 41, 121, 0.2) !important;
        background: rgba(61, 41, 121, 0.2) !important;
      }
      a {
        cursor: pointer;
      }
      .CalcLoader {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto auto;
        z-index: 999;
        width: 100%;
        height: 100%;
        background: rgba(51, 51, 51, 0.2);
      }
      .CalcLoader img {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto auto;
        z-index: 999;
      }
      .disabled {
        pointer-events: none;
      }
      td.disabled .btnview {
        color: rgba(61, 41, 121, 0.34) !important;
      }
      .dconfirmadresswrp,
      .dconfirmadresswrp2 {
        margin-bottom: 20px !important;
      }
      .otpMsg {
        font-size: 18px;
        text-align: center;
        margin-bottom: 5px;
        color: #5f2160;
      }
      .purple {
        color: #5f2160;
      }
      .invalidDropDown {
        border: 1px solid red !important;
      }
      .validDropDown {
        border: none;
      }
      .error.error_specialty {
        color: red;
        text-align: center;
        display: block;
      }
      .appointmentNoteWrp > div {
        background: #f00;
        padding: 10px 15px;
        width: 100%;
        margin: 10px auto;
        font-size: 14px;
        color: #fff;
        display: inline-block;
      }
    `,
  ],
})
export class BookanAppointmentDetail
  implements OnDestroy, OnInit, AfterViewInit
{
  @Input() doctorUID: Subject<any>;
  @Input() specialityUid: Subject<any>;
  @Input() actionSection: string;
  @Output() close: EventEmitter<any> = new EventEmitter();
  public LocationID: any;
  public centersID: any;
  public hidden = 'hidden';
  public logo = 'logo';

  public loginTitle: string = '';
  public loginMsg: string = '';

  public doctorDetail: any = [];
  public imgBasePath: string;
  public selectedLocationId: any;
  public locationdetail: any;
  public isShow: boolean = false;

  public doctorSlot: any = [];
  public sessionSlot: any = [];
  public sessionID: any;
  public isShowDate: boolean = true;
  public isShowCalendar: boolean = true;
  //public datepickrInput:any;
  public selectedDate: any;
  //uid
  private centre_uid: number; //nOwnerOrganisationUID
  private specility_uid: number; //nSpecialityUID
  private doctor_uid: number; //nCareproviderUID

  private nLocationUID: any;
  private nServiceUID: any;
  private nSessionUID: any;
  private lPatientUID: any;
  private sSelectedSlot: any;
  private sSlotEndTime: any;

  public slotStartDate: any;
  public slotEndDate: any;
  public sComments: any;

  public BookingNumber: any;
  public LoggedInName: any;

  public calenderVisible: boolean = false;
  //uid
  public CAppointmentRegistered: any;
  public CNewAppointmentRegistered: any;
  public CSendOTPForm: any;
  public formModel: any = {};

  public defaultSource: number = 0;
  public leadSource: any = [];
  public slotNotAvailable: boolean = false;
  public newPatientStatus: boolean = false;
  public oldPatientStatus: boolean = false;
  public sourceModel: any = 0;
  public otpMobileNumber: any;
  public ConfirmBookingdata: any;
  public otpMsg: string = '';
  public serviceNotAvailable: boolean = false;
  public selected: boolean = false;
  speciltyList: Array<any> = [];
  public ownerOrganizationIDBySlot: any;
  public curTime: any = new Date().getTime();
  public slotTime: any;
  public sectionType: string = null;
  public pageType: any;
  public appointmentnote: any;
  public AppointmentNoteNotAvailable: boolean = false;

  //Ranjay:For Appointment
  public PopupPayNowPayLaterLoader: boolean = false;
  public appointmentPaynowPaylater: boolean = false;
  public appointmentType: boolean = false;
  public slotNotAvailableAddBut: boolean = false;
  public ConfirmAppointmentData: any;
  public currentTimeEntry: any;
  public ConfirmAppointmentID: any;
  public butClick: any;
  public cityID: any;
  public centerUid: any;
  public centerName: any;
  public doctorID: any;
  public doctorName: any;
  public doctorFees: any;
  public apiDoctorFees: any;
  public apiDoctorFeesNew: any;
  public speciality_Uid: any;
  public cAppointmentID: any;
  public cRegistrationNo: any;
  public patientName: any;
  public patientEmail: any;
  public patientMobile: any;
  public doctorConsultationCharges: any = 0;
  public disableSelectOnAppointmentBook: boolean = true;
  public bookingChangeStatus: any;
  public bookingDoctorFeesErrorStatus: boolean = false;
  public bookingDoctorFeesStatus: boolean = false;
  public isDoctorFees: boolean = false;
  public isDoctorFeesEnambledSubmitBtn: boolean = false;
  public appointmentCheckAuth: boolean = false;
  public Patient_UID: any;
  public BookingStatus: any;
  //public nLocationUID:any;
  public nCareproviderUID: any;
  public nOwnerOrganisationUID: any;
  public nAppointmentType: any;

  public MOrderId: any;
  public sCustomDetails: any;
  public auth_token: string;
  public mid: any;
  public check_sum_hash: any;
  public paymentFormData: any;
  public paymentBtn: boolean = false;
  public appointmentDate: any;
  public appointmentStartDateTime: any;
  public appointmentEndDateTime: any;
  public changeLocationID: any;
  public changedSpecialtyId: any;
  @ViewChild('formPayment') formPayment: ElementRef;

  public atype: any = 1;

  public AppointmentDataToClient: any;
  public c_doctorName: any;
  public c_locaionName: any;
  public c_specialityName: any;
  public c_Uhid: any;
  public c_Temp_uhid: any;
  public c_Websiteid: any;
  public c_Enter: any;
  public appointmentmode: any;

  public dl_MobileNum: any = '';
  public dl_EmailID: any = '';
  public dl_birthDate: any = '';
  public dl_Weight: any = '';
  public dl_City: any = '';
  public dl_Speciality: any = '';
  public dl_unitName: any = '';
  public dl_DoctorName: any = '';
  public dl_PreferredDate: any = '';
  public dl_utmSource: any = '';
  public dl_PatientType: any = '';
  public dl_UserType: any = '';
  public dl_AppointmentTime: any = '';
  public dl_BookforSubSpecility: any = '';
  public cityList: any;
  public physicalbookingstatus: any;
  public phybookingstatus: boolean = true;
  public csSpeciality: any = '';
  public speciltyselectedList: any;

  constructor(
    private api: ApiService,
    private appointment: ApiAppointment,
    public formBuilder: FormBuilder,
    public datepipe: DatePipe,
    public cdref: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/datepickr.min.js');
      require('../../assets/js/fancybox/jquery.fancybox.js');
      require('../../assets/js/jquery.cookie.js');
      require('../../assets/js/custom.js');
    }

    let selectedSpecialitySlug = $.cookie('selectedSpecialitySlug');
    console.log('selectedSpecialitySlug: ', selectedSpecialitySlug);
    if (selectedSpecialitySlug != '') {
      this.changedSpecialtyId = selectedSpecialitySlug;
    } else {
      this.changedSpecialtyId = localStorage.getItem('selectedSpeciality');
    }
  }
  @ViewChildren('appointment') things: QueryList<any>;
  @ViewChildren('datepickrInput') datepicker: QueryList<any>;
  @ViewChildren('currentTimeChange') currentTimeThings: QueryList<any>;

  ngOnInit() {
    this.getCenterByIpRegionCity();

    this.LocationID = localStorage.getItem('Locationid');

    this.paymentBtn = false;
    this.route.params.subscribe((params: Params) => {
      this.sectionType = params['type'] ? params['type'] : null;
      this.pageType =
        this.sectionType == 'visiting-consultant' ? 'visiting-consultant/' : '';
    });
    //this.getDoctorDetail(11);
    this.imgBasePath = IMG_BASE_URL;
    this.doctorUID.subscribe((event) => {
      //console.log('doctorUID event:', event);
      this.locationdetail = null;
      this.isShow = true;
      //this.formModel.locationModel = 0;
      //$('span#selectDlocation').html('Select Location');
      //this.formModel.speciltyModel = 0;
      //this.formModel.speciltyLabel = 'Select Specialty';
      this.getDoctorDetail(event);

      //console.log('doctorDetail-inner');
    });
    this.specialityUid.subscribe((sUid) => {
      //console.log('specialityUid sUid: ', sUid);
      this.specility_uid = sUid;
      this.speciltyList = [];
      setTimeout(() => {
        $('html, body').animate(
          { scrollTop: $('.ddetailstimet').offset().top - 120 },
          1000
        );
      }, 100);
      this.AppointmentNoteNotAvailable = false;
      this.isShow = false;
      this.isShowDate = true;
      this.isShowCalendar = true;
      this.calenderVisible = false;
      this.newPatientStatus = false;
      this.oldPatientStatus = false;
      this.slotNotAvailable = false;
      this.sessionSlot = 0;
      this.serviceNotAvailable = false;
      $('.dconfirmawrp, .dconfirmawrp2,.dconfirmadresswrp')
        .delay(100)
        .slideUp();
      this.CAppointmentRegistered.reset();
      this.CNewAppointmentRegistered.reset();
      this.selected = false;
    });

    this.validateRegisteredPatient();
    this.validateNewRegister();
    this.validateCSendOTPForm();
    //this.postAppointmentDetailsToClient();
    //this.PayNow();
    this.DataLayerOnFormChange(
      'BookingPage',
      'BookingPage_BookAppointment_show_doctors_slote',
      'Book-an-appointment'
    );
  }

  DataLayerOnFormChange(eventAction, eventlabel, eventCategory) {
    let c_path = window.location.pathname.split('/');
    let pName = c_path[c_path.length - 2],
      spName = c_path[c_path.length - 1];
    pName = pName.replace(/-/gi, '-');
    spName = spName.replace(/-/gi, '_');
    dataLayerPush(
      eventAction,
      eventlabel,
      eventCategory,
      '',
      pName,
      pName,
      spName,
      '',
      'English',
      pName,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      ''
    );
  }

  getCenterByIpRegionCity() {
    this.api.getCenterByIpRegionCity().subscribe((data) => {
      console.log('getCenterByIpRegionCity:', data, data.country);
      /*if(data.country != 'IN'){
                window.open('https://www.rainbowhospitals.in/hello-doctor-online-consultation/', "_self");
            } */
    });
  }
  navigateAppointmentConfirmed() {
    this.router.navigate(['/appointment-confirmed']);
  }
  PayNow() {
    this.paymentBtn = true;
    if (this.ConfirmAppointmentID != '') {
      let ClickPayNowAppointmentData = {
        appointmentid: this.ConfirmAppointmentID,
        buttonid: '1',
      };
      this.api
        .postBtnClickDetails(ClickPayNowAppointmentData)
        .subscribe((res) => {
          //console.log('PayNow Response', res);
        });

      let updatePayNowAppointmentData = {
        appointmentid: this.ConfirmAppointmentID,
        paymentstatusid: 0,
        paymentstatus: 'Pending',
        appointmentbokignbtatus: 'booked',
        appointmentmode: this.appointmentmode,
      };
      this.api
        .postPayNowStatus(updatePayNowAppointmentData)
        .subscribe((res) => {
          console.log('Api PayNow update Response', res);
        });

      this.ClientSideUpdateTable(this.BookingNumber, '0', 'paynow_update');
      this.formSubmit();
      setTimeout(() => {
        this.PopupPayNowPayLaterLoader = false;
      }, 2000);
    }
  }

  ClientSideUpdateTable(appointmentID, status, action) {
    this.api.get_Appointment_id(appointmentID).subscribe((res_id) => {
      //console.log('get_Appointment_id: ', res_id, res_id['id'][0].id);
      let website_id = res_id['id'][0].id;
      this.AppointmentDataToClient = {
        action: action,
        Websiteid: website_id,
        status: status,
      };
      this.api
        .AppointmentPostClientData(this.AppointmentDataToClient)
        .subscribe((res) => {
          console.log('AppointmentPostClientData: ', res);
        });
    });
  }

  PayLater() {
    if (this.ConfirmAppointmentID != '') {
      let ClickPayLaterAppointmentData = {
        appointmentid: this.ConfirmAppointmentID,
        buttonid: '2',
      };
      this.api
        .postBtnClickDetails(ClickPayLaterAppointmentData)
        .subscribe((res) => {
          initPopup('paylater');
        });
      let updatePayNowAppointmentData = {
        appointmentid: this.ConfirmAppointmentID,
        paymentstatusid: 4,
        paymentstatus: 'N/A',
        appointmentbokignbtatus: 'booked',
        appointmentmode: this.appointmentmode,
      };
      this.api
        .postPayNowStatus(updatePayNowAppointmentData)
        .subscribe((res) => {
          console.log('Api PayNow PayLater update Response', res);
        });
      this.ClientSideUpdateTable(this.BookingNumber, '4', 'paylater_update');
      setTimeout(() => {
        this.PopupPayNowPayLaterLoader = false;
      }, 2000);
    }
  }

  postAppointmentDetailsByBookNow() {
    this.paymentBtn = true;
    this.ConfirmAppointmentData = {
      doctor: this.doctorID,
      doctor_fees: this.apiDoctorFees,
      appointment_id: this.BookingNumber,
      registration_no: this.LoggedInName,
      patient_name: this.patientName,
      patient_email: this.patientEmail,
      patient_mobile: this.patientMobile,
      location: this.cityID,
      center: this.centre_uid,
      speciality: this.speciality_Uid,
      status: '1',
      appointmentdate: this.appointmentDate,
      appointmentstarttime: this.appointmentStartDateTime,
      appointmentendtime: this.appointmentEndDateTime,
      patient_uid: this.Patient_UID,
      nLocationUID: this.nLocationUID,
      nCareproviderUID: this.doctor_uid,
      nOwnerOrganisationUID: this.ownerOrganizationIDBySlot,
      nAppointmentType: this.atype,
      entry_time: this.currentTimeEntry,
    };
    this.ConfirmAppointmentID = '';
    this.api
      .postAppointmentDetails(this.ConfirmAppointmentData)
      .subscribe((res) => {
        //console.log('postAppointmentDetails: ', res, res.status_code, res.appointment_id);
        if (res.status_code == 200) {
          this.ConfirmAppointmentID = res.appointment_id;
          localStorage.setItem('cAppointmentID', this.ConfirmAppointmentID);
          localStorage.setItem('apiDoctorFees', this.apiDoctorFees);
          $.cookie('cAppointmentID', this.ConfirmAppointmentID);
          this.paymentBtn = false;
          this.c_Websiteid = this.ConfirmAppointmentID;
          this.postAppointmentDetailsToClient();
          this.dataLayerPush();
        }
      });
  }

  postAppointmentDetails() {
    this.paymentBtn = true;
    this.ConfirmAppointmentData = {
      doctor: this.doctorID,
      doctor_fees: this.apiDoctorFees,
      appointment_id: this.BookingNumber,
      registration_no: this.LoggedInName,
      patient_name: this.patientName,
      patient_email: this.patientEmail,
      patient_mobile: this.patientMobile,
      location: this.cityID,
      center: this.centre_uid,
      speciality: this.speciality_Uid,
      status: '1',
      appointmentdate: this.appointmentDate,
      appointmentstarttime: this.appointmentStartDateTime,
      appointmentendtime: this.appointmentEndDateTime,
      patient_uid: this.Patient_UID,
      nLocationUID: this.nLocationUID,
      nCareproviderUID: this.doctor_uid,
      nOwnerOrganisationUID: this.ownerOrganizationIDBySlot,
      nAppointmentType: this.atype,
      entry_time: this.currentTimeEntry,
    };
    this.ConfirmAppointmentID = '';
    this.api
      .postAppointmentDetails(this.ConfirmAppointmentData)
      .subscribe((res) => {
        //console.log('postAppointmentDetails: ', res, res.status_code, res.appointment_id);
        if (res.status_code == 200) {
          this.ConfirmAppointmentID = res.appointment_id;
          localStorage.setItem('cAppointmentID', this.ConfirmAppointmentID);
          localStorage.setItem('apiDoctorFees', this.apiDoctorFees);
          $.cookie('cAppointmentID', this.ConfirmAppointmentID);
          this.paymentBtn = false;
          this.c_Websiteid = this.ConfirmAppointmentID;
          this.PayLater();
          this.postAppointmentDetailsToClient();
          this.dataLayerPush();
        }
      });
  }

  postAppointmentDetailsWithPayNow() {
    this.paymentBtn = true;
    this.ConfirmAppointmentData = {
      doctor: this.doctorID,
      doctor_fees: this.apiDoctorFees,
      appointment_id: this.BookingNumber,
      registration_no: this.LoggedInName,
      patient_name: this.patientName,
      patient_email: this.patientEmail,
      patient_mobile: this.patientMobile,
      location: this.cityID,
      center: this.centre_uid,
      speciality: this.speciality_Uid,
      status: '1',
      appointmentdate: this.appointmentDate,
      appointmentstarttime: this.appointmentStartDateTime,
      appointmentendtime: this.appointmentEndDateTime,
      patient_uid: this.Patient_UID,
      nLocationUID: this.nLocationUID,
      nCareproviderUID: this.doctor_uid,
      nOwnerOrganisationUID: this.ownerOrganizationIDBySlot,
      nAppointmentType: this.atype,
      entry_time: this.currentTimeEntry,
    };
    this.ConfirmAppointmentID = '';
    this.api
      .postAppointmentDetails(this.ConfirmAppointmentData)
      .subscribe((res) => {
        //console.log('Create New DB Response', res, res.status_code, res.appointment_id);
        if (res.status_code == 200) {
          this.ConfirmAppointmentID = res.appointment_id;
          localStorage.setItem('cAppointmentID', this.ConfirmAppointmentID);
          localStorage.setItem('apiDoctorFees', this.apiDoctorFees);
          $.cookie('cAppointmentID', this.ConfirmAppointmentID);
          this.paymentBtn = false;
          this.c_Websiteid = this.ConfirmAppointmentID;
          this.postAppointmentDetailsToClient();
          this.dataLayerPush();
          this.PayNow();
        }
      });
  }

  postAppointmentDetailsToClient() {
    this.AppointmentDataToClient = {
      action: 'insert',
      Websiteid: this.c_Websiteid,
      Doctor: this.c_doctorName,
      Doctor_fees: this.apiDoctorFees,
      Appointment_id: this.BookingNumber,
      Registration_no: this.LoggedInName,
      Patient_name: this.patientName,
      Patient_email: this.patientEmail,
      Patient_mobile: this.patientMobile,
      location: this.c_locaionName,
      Enter: this.c_Enter,
      Speciality: this.c_specialityName,
      status: 0,
      Appointmentdate: this.appointmentDate,
      Appointmentstarttime: this.appointmentStartDateTime,
      Appointmentendtime: this.appointmentEndDateTime,
      Patient_uid: this.Patient_UID,
      Uhid: this.Patient_UID,
      Temp_uhid: this.Patient_UID,
    };

    this.api
      .AppointmentPostClientData(this.AppointmentDataToClient)
      .subscribe((res) => {
        //console.log('AppointmentInsertDatatoClientDB: ', res);
      });
  }

  formSubmit() {
    this.apiDoctorFeesNew = this.apiDoctorFees;
    setTimeout(() => {
      this.formPayment.nativeElement.submit();
    }, 1000);
  }

  public tncModelshow: boolean = false;
  tncModelOpen() {
    this.tncModelshow = true;
  }

  tncModelClose() {
    this.tncModelshow = false;
  }

  getPageInfo() {
    this.api.getPageDescription('book-an-appointment').subscribe(
      (data) => {
        this.appointmentnote = data.page_descriptions[0].appointmentnote;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  validateAllFormFields(formGroup: FormGroup) {
    //{1}
    Object.keys(formGroup.controls).forEach((field) => {
      //{2}
      const control = formGroup.get(field); //{3}
      if (control instanceof FormControl) {
        //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        //{5}
        this.validateAllFormFields(control); //{6}
      }
    });
  }
  // onSelectCheckBoxTNC(e:any, formName){
  // 	setTimeout(() => {
  // 		if(e == "new"){
  // 			console.log('onSelectCheckBox:', e, this.CNewAppointmentRegistered.value, this.CNewAppointmentRegistered.valid);
  // 			if (!this.CNewAppointmentRegistered.valid) {
  // 				this.validateAllFormFields(this.CNewAppointmentRegistered);
  // 				this.loginTitle ='Error';
  // 				this.loginMsg = 'all fields are required!!!';
  // 				initPopup('loginErrorMsg');
  // 				return;
  // 			}
  // 			if(this.CNewAppointmentRegistered.valid){
  // 				this.SelectCheckBox();
  // 			}
  // 		}
  // 		if(e == "Registered"){
  // 			console.log('onSelectCheckBox:', e, this.CAppointmentRegistered.value, this.CAppointmentRegistered.valid);
  // 			if (!this.CAppointmentRegistered.valid) {
  // 				this.loginTitle ='Error';
  // 				this.loginMsg = 'all fields are required!!!';
  // 				initPopup('loginErrorMsg');
  // 				this.validateAllFormFields(this.CAppointmentRegistered);
  // 				return;
  // 			}
  // 			if(this.CAppointmentRegistered.valid){
  // 				this.SelectCheckBox();
  // 			}
  // 		}
  // 	}, 500);
  // }

  onSelectCheckBoxTNC(e: any) {
    setTimeout(() => {
      console.log(
        'onSelectCheckBox:',
        e,
        this.CAppointmentRegistered.value,
        this.CAppointmentRegistered
      );
      if (e == 'new') {
        this.CNewAppointmentRegistered.controls.newUserTNC.setValue(true);
        if (!this.CNewAppointmentRegistered.valid) {
          this.validateAllFormFields(this.CNewAppointmentRegistered);
          this.loginTitle = 'Error';
          this.loginMsg = 'all fields are required!!!';
          initPopup('loginErrorMsg');
          return;
        }
        if (this.CNewAppointmentRegistered.valid) {
          this.SelectCheckBox();
        }
      }
      if (e == 'Registered') {
        this.CAppointmentRegistered.controls.registeredUserTNC.setValue(true);
        if (!this.CAppointmentRegistered.valid) {
          this.loginTitle = 'Error';
          this.loginMsg = 'all fields are required!!!';
          initPopup('loginErrorMsg');
          this.validateAllFormFields(this.CAppointmentRegistered);
          return;
        }
        if (this.CAppointmentRegistered.valid) {
          this.SelectCheckBox();
        }
      }
    }, 100);
  }
  onChangeMobile(e) {
    ////console.log('Mobile:', evt);
    if (e == 'new') {
      this.CNewAppointmentRegistered.controls.newUserTNC.setValue(true);
      this.SelectCheckBoxSH();
      this.bookingDoctorFeesStatus = false;
    }
    if (e == 'Registered') {
      this.CAppointmentRegistered.controls.registeredUserTNC.setValue(true);
      this.SelectCheckBoxSH();
      this.bookingDoctorFeesStatus = false;
    }
  }

  gobacktoDateSelection() {
    $('.capopup').hide();
    this.goBackToDateSelection();
    $('.btnPrevca').trigger('click');
    this.appointmentCheckAuth = false;
  }

  resetLoginForm(e) {
    //this.CAppointmentRegistered.reset();
    //this.CNewAppointmentRegistered.reset();
  }

  SelectCheckBoxSH() {
    this.selected = false;
  }
  SelectCheckBox() {
    this.selected = !this.selected;
    this.getDoctorFees(this.selected);
  }
  selectSpeciltyID(speciltyData: any): void {
    this.specility_uid = this.formModel.speciltyModel;
    this.formModel.speciltyLabel =
      speciltyData.options[speciltyData.options.selectedIndex].text;
    //let specialtywrp = document.getElementById("specialtywrp");
    //specialtywrp.classList.remove("invalidDropDown");
    //specialtywrp.classList.add("validDropDown");
    document.getElementsByClassName('error_specialty')[0].innerHTML = '';
    if (this.specility_uid == 0) {
      this.isShowDate = true;
    } else {
      if (this.formModel.locationModel != 0) {
        this.changeLocation(this.formModel.locationModel);
      }
    }
  }
  getDoctorSpecility() {
    console.log('changedSpecialtyId: ', this.changedSpecialtyId);

    this.api
      .getDoctorSpecitlytID(this.doctorDetail.id)
      .pipe(map((res) => res))
      .subscribe((data) => {
        this.speciltyList = data;

        console.log('changedSpecialtyId: ', this.changedSpecialtyId);
        if (this.changedSpecialtyId != null) {
          switch (this.changedSpecialtyId) {
            case 'child-care':
              this.csSpeciality = 'Child Care';
              break;
            case 'women-care':
              this.csSpeciality = 'Women Health';
              break;
            case 'fertility-care':
              this.csSpeciality = 'Fertility Care';
              break;
          }

          let dcenter = [];
          this.speciltyList.forEach((element) => {
            if (element.speciality == this.csSpeciality) {
              dcenter.push(element);
            }
          });
          this.speciltyselectedList = dcenter;
          console.log('speciality Data: ', this.speciltyselectedList);
          if (this.speciltyselectedList.length > 0) {
            //this.formModel.speciltyLabel = this.speciltyselectedList['0'].speciality;
            this.c_specialityName = this.speciltyselectedList['0'].speciality;
            $('select#txtDSpecilty')
              .val(this.speciltyselectedList['0'].speciality_uid)
              .trigger('change');
            $('#selectedDSpecilty').text(
              this.speciltyselectedList['0'].speciality
            );
            this.autoLoadSpecilty(
              this.speciltyselectedList['0'].speciality_uid
            );
            //console.log('if2 speciltyselectedList: ', this.speciltyselectedList['0'].speciality, this.speciltyselectedList['0'].speciality_uid);
          } else {
            //this.formModel.speciltyLabel = this.speciltyList['0'].speciality;
            this.c_specialityName = this.speciltyList['0'].speciality;
            $('select#txtDSpecilty')
              .val(this.speciltyList['0'].speciality_uid)
              .trigger('change');
            $('#selectedDSpecilty').text(this.speciltyList['0'].speciality);
            this.autoLoadSpecilty(this.speciltyList['0'].speciality_uid);
            //console.log('else2 speciltyList: ', this.speciltyList['0'].speciality, this.speciltyList['0'].speciality_uid);
          }
        } else {
          //this.formModel.speciltyLabel = this.speciltyList['0'].speciality;
          this.c_specialityName = this.speciltyList['0'].speciality;
          $('select#txtDSpecilty')
            .val(this.speciltyList['0'].speciality_uid)
            .trigger('change');
          $('#selectedDSpecilty').text(this.speciltyList['0'].speciality);
          this.autoLoadSpecilty(this.speciltyList['0'].speciality_uid);
          //console.log('else dcenter: ', this.speciltyList['0'].speciality, this.speciltyList['0'].speciality_uid);
        }

        /*this.formModel.speciltyLabel = this.speciltyList['0'].speciality;
			this.c_specialityName = this.speciltyList['0'].speciality;
			$("span#selectDSpecilty3").html(this.speciltyList['0'].speciality);
			console.log('speciltyList', this.speciltyList, this.speciltyList['0'].speciality, this.speciltyList['0'].speciality_uid);
			this.onChangeSpecilty(this.speciltyList['0'].speciality_uid);*/
      });
  }
  /**
   * check only number value
   */

  isNumber(evt) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  /**
   * check only Latters value
   */
  isletters(event: any) {
    const pattern = /^[a-zA-Z, '']+$/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      return false;
    }
    return true;
  }
  isAlfa(evt) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (
      charCode > 31 &&
      (charCode < 65 || charCode > 90) &&
      (charCode < 97 || charCode > 122)
    ) {
      return false;
    }
    return true;
  }

  onDateChange(event) {
    if (event.target.value) {
      this.CNewAppointmentRegistered.controls['VisitorDOB'].setValue(
        event.target.value
      );
    }
  }
  getDoctorDetail(slug: number) {
    return this.api.doctorsbyslug(slug, this.sectionType).subscribe((res) => {
      console.log('getDoctorDetail Response:', res);
      this.doctorDetail = res.doctors[0];
      this.doctor_uid = res.doctors[0].doctor_uid;
      this.doctorFees = res.doctors[0].fees;
      this.c_doctorName = res.doctors[0].name;
      this.doctorConsultationCharges = res.doctors[0].fees;
      this.physicalbookingstatus = res.doctors[0].physicalbookingstatus;
      localStorage.setItem(
        'physicalbookingstatus',
        res.doctors[0].physicalbookingstatus
      );

      //console.log('doctorFees from DB:', this.doctorFees);

      this.dl_DoctorName = res.doctors[0].slug;

      this.getDoctorSpecility();
      let dcenter = [];
      this.doctorDetail['centers'].forEach((element) => {
        if (this.LocationID != null) {
          if (element.id == this.LocationID) {
            dcenter.push(element);
          }
        }
      });
      //console.log('dcenter: ', dcenter);
      if (this.LocationID != null) {
        if (dcenter.length > 0) {
          this.formModel.locationModel = dcenter[0].center_name_alias;
          $('span#selectDlocation').html(dcenter[0].center_name_alias);
          this.centersID = dcenter[0].id;
        } else {
          this.formModel.locationModel =
            this.doctorDetail.centers[0].center_name_alias;
          $('span#selectDlocation').html(
            this.doctorDetail.centers[0].center_name_alias
          );
          this.centersID = this.doctorDetail.centers[0].id;
        }
      } else {
        this.formModel.locationModel =
          this.doctorDetail.centers[0].center_name_alias;
        $('span#selectDlocation').html(
          this.doctorDetail.centers[0].center_name_alias
        );
        this.centersID = this.doctorDetail.centers[0].id;
      }

      //this.changeLocation2(this.doctorDetail.centers[0].id);
      if (
        this.doctorDetail.appointmentnote != '' &&
        this.doctorDetail.appointmentnote != null
      ) {
        this.AppointmentNoteNotAvailable = true;
      }
      /*
			if(this.specility_uid == 0){
				this.getDoctorSpecility();
			}
			*/
    });
  }

  autoLoadSpecilty(event) {
    this.specility_uid = event;
    this.changeLocation2(this.centersID);
  }
  onChangeSpecilty(event) {
    this.specility_uid = event;
    this.autoLoadSpecilty(event.target.value);
    this.DataLayerOnFormChange(
      'BookingPage',
      'BookingPage_SelectDoctor_Specilty',
      'Book-an-appointment'
    );
  }
  changeLocation2(event) {
    this.defoultTimeSlote(event);
    this.DataLayerOnFormChange(
      'BookingPage',
      'BookingPage_SelectDoctor_Location',
      'Book-an-appointment'
    );
  }
  onChangeLocation(event) {
    this.defoultTimeSlote(event.target.value);
    this.DataLayerOnFormChange(
      'BookingPage',
      'BookingPage_SelectDoctor_Location',
      'Book-an-appointment'
    );
  }

  PhysicalAndVideoConsultation() {
    this.atype = 0;
    this.getCentreSessionID(
      this.doctor_uid,
      this.centre_uid,
      this.speciality_Uid,
      this.atype
    );
    this.DataLayerOnFormChange(
      'BookingPage',
      'BookingPage_SelectDoctor_PhysicalAndVideoConsultation',
      'Book-an-appointment'
    );
  }
  VideoConsultation() {
    this.atype = 2;
    this.getCentreSessionID(
      this.doctor_uid,
      this.centre_uid,
      this.speciality_Uid,
      this.atype
    );
    this.DataLayerOnFormChange(
      'BookingPage',
      'BookingPage_SelectDoctor_VideoConsultation',
      'Book-an-appointment'
    );
  }
  PhysicalConsultation() {
    this.atype = 1;
    this.getCentreSessionID(
      this.doctor_uid,
      this.centre_uid,
      this.speciality_Uid,
      this.atype
    );
    this.DataLayerOnFormChange(
      'BookingPage',
      'BookingPage_SelectDoctor_PhysicalConsultation',
      'Book-an-appointment'
    );
  }

  defoultTimeSlote(value) {
    //console.log('defoultTimeSlote:', value, this.doctorDetail);

    //console.log('LocationID:', this.LocationID);
    this.changeLocationID = value;
    let doctordetail = this.doctorDetail.centers
      ? this.doctorDetail.centers
      : this.doctorDetail.doctor_consultant_center;
    let filterLocationDetail = doctordetail.filter((res) => {
      return res.id == value;
    });
    filterLocationDetail.map((res) => {
      this.centre_uid = res.center_uid;

      this.locationdetail = {
        center_name: res.center_name,
        address: res.address,
        lat_lng: res.lat_long,
      };
    });
    //console.log('doctordetail:', doctordetail, this.changeLocationID);
    let finalDoctorDetails = [];

    doctordetail.forEach((element) => {
      if (this.LocationID != null && this.LocationID != 0) {
        //console.log('if LocationID:', element.id, this.LocationID);
        if (element.id == this.LocationID) {
          finalDoctorDetails.push(element);
        }
      } else {
        //console.log('else changeLocationID:', element.id, this.changeLocationID);
        if (element.id == this.changeLocationID) {
          finalDoctorDetails.push(element);
        }
      }
    });
    //console.log('finalDoctorDetails', finalDoctorDetails);
    this.cityID = finalDoctorDetails[0].city;
    this.centerUid = finalDoctorDetails[0].center_uid;
    this.centerName = finalDoctorDetails[0].center_name;
    this.doctorID = this.doctor_uid;
    this.speciality_Uid = this.specility_uid;
    //this.doctorName =  doctordetail[0].name;
    this.c_locaionName = finalDoctorDetails[0].center_name_alias;
    this.c_Enter = finalDoctorDetails[0].center_name;

    this.cityList.forEach((element) => {
      if (element.cityId == this.cityID) {
        this.dl_City = element.city;
      }
    });

    this.dl_unitName = finalDoctorDetails[0].center_name;
    this.getCentreSessionID(
      this.doctor_uid,
      this.centre_uid,
      this.speciality_Uid,
      this.atype
    );
    this.DataLayerOnFormChange(
      'BookingPage',
      'BookingPage_SelectDoctor_DateTimeSlote',
      'Book-an-appointment'
    );
  }
  getCityList() {
    this.api.getCentreCities().subscribe((res) => {
      this.cityList = res;
      console.log('cityList:', this.cityList);
    });
  }
  changeLocation(value) {
    this.changeLocationID = value;
    if (this.formModel.speciltyModel == 0 && this.specility_uid == 0) {
      //if(specialtywrp.classList.contains('validDropDown')){
      let specialtywrp = document.getElementById('specialtywrp');
      specialtywrp.classList.remove('validDropDown');
      specialtywrp.classList.add('invalidDropDown');
      //}

      document.getElementsByClassName('error_specialty')[0].innerHTML =
        'Please Select Specialty';
      //return false;
    } else {
      let specialtywrp = document.getElementById('specialtywrp');
      //console.log(specialtywrp);
      if (specialtywrp) {
        specialtywrp.classList.remove('invalidDropDown');
        specialtywrp.classList.add('validDropDown');
        document.getElementsByClassName('error_specialty')[0].innerHTML = '';
      }

      if (value != 0) {
        let doctordetail = this.doctorDetail.centers
          ? this.doctorDetail.centers
          : this.doctorDetail.doctor_consultant_center;
        let filterLocationDetail = doctordetail.filter((res) => {
          return res.id == value;
        });
        filterLocationDetail.map((res) => {
          this.centre_uid = res.center_uid;

          this.locationdetail = {
            center_name: res.center_name,
            address: res.address,
            lat_lng: res.lat_long,
          };
        });
        //console.log('doctordetail', doctordetail);
        let finalDoctorDetails = [];
        doctordetail.forEach((element) => {
          if (element.id == this.changeLocationID) {
            finalDoctorDetails.push(element);
          }
        });
        console.log('finalDoctorDetails', finalDoctorDetails);
        this.cityID = finalDoctorDetails[0].city;
        this.centerUid = finalDoctorDetails[0].center_uid;
        this.centerName = finalDoctorDetails[0].center_name;
        this.doctorID = this.doctor_uid;
        this.speciality_Uid = this.specility_uid;
        //this.doctorName =  doctordetail[0].name;
        this.c_locaionName = finalDoctorDetails[0].center_name_alias;
        this.c_Enter = finalDoctorDetails[0].center_name;

        this.cityList.forEach((element) => {
          if (element.cityId == this.cityID) {
            this.dl_City = element.city;
          }
        });
        this.dl_unitName = finalDoctorDetails[0].center_name;

        this.getCentreSessionID(
          this.doctor_uid,
          this.centre_uid,
          this.specility_uid,
          this.atype
        );
      } else {
        this.locationdetail = {};
        this.sessionSlot = 0;
        this.serviceNotAvailable = false;
      }
    }

    this.DataLayerOnFormChange(
      'BookingPage',
      'BookingPage_SelectDoctor_Location',
      'Book-an-appointment'
    );
  }
  reformatDate(n) {
    return new Date(n.match(/\d+/)[0] * 1);
  }
  goBackToDateSelection() {
    this.phybookingstatus = true;
    this.slotNotAvailableAddBut = false;
    //this.CAppointmentRegistered.reset();
    //this.CNewAppointmentRegistered.reset();
    //this.CAppointmentRegistered.reset();
    //this.CNewAppointmentRegistered.reset();

    this.SelectCheckBox();
    this.SelectCheckBoxSH();
    console.log('atype:', this.atype);
    if (this.atype == 2) {
      setTimeout(() => {
        $('.dbut').removeClass('active');
        $('.dbut.VideoConsultation').addClass('active');
      }, 100);
    }
    this.DataLayerOnFormChange(
      'BookingPage',
      'BookingPage_SelectDoctor_goBackToDateSelection',
      'Book-an-appointment'
    );
  }
  changeStatus(status) {
    alert();
    this.bookingChangeStatus = status;
    this.slotNotAvailableAddBut = false;
    status == 'existing'
      ? (this.oldPatientStatus = true)
      : (this.newPatientStatus = true);
    let statusClass = status == 'existing' ? '.dconfirmawrp' : '.dconfirmawrp2';
    $('.ttheader').delay(600).slideUp();
    $(statusClass).delay(600).slideDown();
    $('[data-popup="popup-22"]').delay(300).fadeOut(350);
  }
  getCentreSessionID(dUid, cUid, sUid, atype) {
    //console.log('dUid,cUid,sUid', dUid,cUid,sUid);
    $.cookie('SessionType', atype);
    this.appointmentmode = atype;
    if (atype == 1) {
      this.appointmentType = true;
    } else {
      this.appointmentType = false;
    }
    $('.CalcLoader').css('display', 'block'); //='display:block';
    this.appointment.getDoctorSessionID(dUid, cUid, sUid, atype).subscribe(
      (res) => {
        this.serviceNotAvailable = false;
        this.sessionSlot = [];
        console.log('SESSION', res);
        // if (res.status_code == 200) {
        console.log('SESSION', res);
        let doctorArr: any = res;
        let apiResult = res; //JSON.parse(doctorArr._body);

        let doctorApiResult = [];
        apiResult.PRTGetSessionsByDoctorResult.forEach((element) => {
          doctorApiResult.push(element);
          /*if(new Date(element.A.match(/\d+/)[0] * 1)  >= new Date()){
						doctorApiResult.push(element);
					}*/
        });
        //console.log('doctorArr:', doctorApiResult);
        this.sessionSlot = doctorApiResult.map((res) => {
          $('.CalcLoader').css('display', 'none');
          return {
            A: new Date(res.A.match(/\d+/)[0] * 1),
            B: res.B,
            C: res.C,
            D: res.D,
            E: res.E,
            F: res.F,
            G: res.G,
            H: res.H,
            I: res.I,
            J: res.J,
            K: res.K,
            L: res.L,
            M: res.M,
            N: res.N,
            O: res.O,
            P: res.O,
            Q: res.O,
            R: res.O,
          };
        });

        //console.log('sessionSlot', this.sessionSlot, this.sessionSlot.length);
        if (this.sessionSlot.length > 0) {
          this.slotNotAvailable = false;
          $('.CalcLoader').css('display', 'none');
          this.isShowDate = this.sessionSlot ? false : true;
        } else {
          this.slotNotAvailable = true;
          $('.CalcLoader').css('display', 'none');
        }
        this.slotNotAvailableAddBut = true;
        $('body').animate(
          { scrollTop: $('.bookanappointment').offset().top - 100 },
          1000
        );
        setTimeout(() => {
          $('.dbut').each(function () {
            $(this).click(function (e) {
              $('.dbut').removeClass('active');
              $(this).addClass('active');
            });
          });
        }, 1000);
        this.setSpecialty();
      },
      // } else {
      //   this.slotNotAvailable = true;
      //   this.slotNotAvailableAddBut = true;
      //   $('.CalcLoader').css('display', 'none');
      // }
      (err) => {
        $('.CalcLoader').css('display', 'none');
        this.serviceNotAvailable = true;
        this.sessionSlot = [];
        console.error(err);
      }
    );
  }
  /***
   *
   */
  prevDate(date) {
    let momentPrevDate = moment(date).subtract(1, 'day').format('DD/MM/YYYY');
    this.takeDatePicker(momentPrevDate, this.sessionID);
    this.DataLayerOnFormChange(
      'BookingPage',
      'BookingPage_SelectDoctor_prevDate',
      'Book-an-appointment'
    );
  }
  /***
   *
   */
  nextDate(date) {
    let momentPrevDate = moment(date).add(1, 'day').format('DD/MM/YYYY');
    this.takeDatePicker(momentPrevDate, this.sessionID);
    this.DataLayerOnFormChange(
      'BookingPage',
      'BookingPage_SelectDoctor_NextDate',
      'Book-an-appointment'
    );
  }
  pickerSelectedDate(value: any = null) {
    if (value) {
      let selectedDateUnix = moment(value.dateTime).format(
        'YYYY-MM-DD HH:mm:ss'
      );
      let currentDateUnix = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');

      let isTimeTrue = moment(selectedDateUnix).isBefore(
        currentDateUnix,
        'second'
      );
      if (!isTimeTrue) {
        this.takeDatePicker(value.date, value.session);
      } else {
        initPopup('notimeslot');
      }
    }
    this.DataLayerOnFormChange(
      'BookingPage',
      'BookingPage_SelectDoctor_SelectedDate',
      'Book-an-appointment'
    );
  }
  takeDatePicker(date: any = null, sessionId: any = null) {
    if (date) {
      $('.CalcLoader').css('display', 'block');
      let beforeSplit = date.split('/');
      let newDate = `${beforeSplit[1]}/${beforeSplit[0]}/${beforeSplit[2]}`;
      let apiBookingDate = `${beforeSplit[0].trim()}-${beforeSplit[1].trim()}-${beforeSplit[2].trim()}`;
      this.sessionID = sessionId;
      this.appointment.getSessionsByDoctor(sessionId, apiBookingDate).subscribe(
        (res) => {
          console.log('doctor arr',res)
          // if (res.status == 200) {
            let doctorArr: any = res;
            let apiResult = doctorArr
            //console.log('ITS WORKING',apiResult.PRTGetSlotsBySessionResult.length);

            this.doctorSlot = apiResult.PRTGetSlotsBySessionResult.map(
              (res) => {
                //console.log('res.N', res.N, new Date(res.F.match(/\d+/)[0] * 1), this.curTime);
                $('.CalcLoader').css('display', 'none');
                this.nLocationUID = res.E;
                this.nServiceUID = res.B;
                this.nSessionUID = res.A;
                this.ownerOrganizationIDBySlot = res.O;
                let resN;
                if (res.N != null) {
                  if (res.N == 'Cancelled') {
                    resN = 'available';
                  } else {
                    resN = 'booked';
                  }
                }
                return {
                  A: res.A, //SESSION UID
                  B: res.B, //serviceUID
                  C: res.C, //careproviderID
                  D: res.D,
                  E: res.E, //locationUID
                  F: new Date(res.F.match(/\d+/)[0] * 1),
                  G: new Date(res.G.match(/\d+/)[0] * 1),
                  H: res.H,
                  I: res.I,
                  J: res.J,
                  K: res.K,
                  L: res.L,
                  M: res.M,
                  N: res.N ? resN : 'available',
                  O: res.O,
                };
              }
            );
            if (this.doctorSlot.length > 0) {
              $('.CalcLoader').css('display', 'none');
              this.isShowCalendar = this.doctorSlot.length > 0 ? false : true;
              this.calenderVisible = true;
              this.slotNotAvailableAddBut = false;
              this.isShowDate = true;
              $('#selectSource').text('Source');
              //this.lPatientUID = '';
            } else {
              $('.CalcLoader').css('display', 'none');
              this.isShowCalendar = this.doctorSlot.length > 0 ? false : true;
              //alert('no slot available');
            }
            this.selectedDate = newDate;
            $('body').animate(
              { scrollTop: $('.bookanappointment').offset().top - 100 },
              2000
            );
          // } else {
          //   $('.CalcLoader').css('display', 'none');
          // }
        },
        (err) => {
          $('.CalcLoader').css('display', 'none');
          console.error('datepicker:', err);
        }
      );
    } else {
      console.log('#############No Slot Availble#############');
    }
    //this.DataLayerOnFormChange("BookingPage","BookingPage_SelectDoctor_Date","Book-an-appointment");
  }
  appointmentPopup(value, slotStart, slotEnd) {
    let activeClass = value.toLowerCase();
    if (
      activeClass != 'booked' &&
      activeClass != 'cancelled' &&
      activeClass != 'arrived'
    ) {
      this.appointmentPopupMobile();
    }

    let calendarTime = this.calendarTime(slotEnd);
    let currentTime = this.curTime;
    //console.log(calendarTime + '==='+ currentTime);
    if (calendarTime > currentTime) {
      //let activeClass = value.toLowerCase();

      this.slotStartDate = this.datepipe.transform(
        new Date(slotStart),
        'dd-MM-yyyy HH:mm'
      );
      this.slotEndDate = this.datepipe.transform(
        new Date(slotEnd),
        'dd-MM-yyyy HH:mm'
      );
      let slotStartTime = this.datepipe.transform(new Date(slotStart), 'HH:mm');
      let slotEndTime = this.datepipe.transform(new Date(slotStart), 'HH:mm');
      this.sComments = `/Date(${new Date(
        slotStart
      ).valueOf()}+0530)/,/Date(${new Date(
        slotEnd
      ).valueOf()}+530)/,${slotStartTime}-${slotEndTime}`;

      if (
        activeClass != 'booked' &&
        activeClass != 'cancelled' &&
        activeClass != 'arrived'
      ) {
        //initPopup(22);
        this.currentTimeEntry = this.datepipe.transform(
          new Date(this.curTime),
          'yyyy-MM-dd HH:mm'
        );
        this.appointmentDate = this.datepipe.transform(
          new Date(slotStart),
          'yyyy-MM-dd HH:mm'
        );
        this.appointmentStartDateTime = this.datepipe.transform(
          new Date(slotStart),
          'yyyy-MM-dd HH:mm'
        );
        this.appointmentEndDateTime = this.datepipe.transform(
          new Date(slotEnd),
          'yyyy-MM-dd HH:mm'
        );

        this.dl_PreferredDate = this.datepipe.transform(
          new Date(slotStart),
          'dd-MM-yyyy'
        );
        this.dl_AppointmentTime = this.datepipe.transform(
          new Date(slotStart),
          'HH:mm'
        );
      }
    } else {
      initPopup('noslot');
    }
    this.DataLayerOnFormChange(
      'BookingPage',
      'BookingPage_SelectDoctor_SelectTime',
      'Book-an-appointment'
    );
  }

  BookingMobile = false;
  appointmentPopupMobile() {
    this.BookingMobile = true;
  }
  appointmentPopupMobileClose() {
    this.BookingMobile = false;
  }
  validateRegisteredPatient() {
    this.CAppointmentRegistered = this.formBuilder.group({
      VisitorName: ['', Validators.required],
      VisitorMobile: [
        '',
        Validators.compose([Validators.required, Validators.minLength(10)]),
      ],
      VisitorEmail: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            '[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}'
          ),
        ]),
      ],
      sSelectedSlot: [''],
      sSlotEndTime: [''],
      sComments: [''],
      registeredUserTNC: ['', Validators.required],
    });
  }
  numberOnly(event) {
    const thisO = event.target;
    if (/\D/g.test(thisO.value)) {
      thisO.value = thisO.value.replace(/\D/g, '');
    }
  }

  patientNameList;
  mobNo = '';
  submitForm() {
    let mobileNo = document.querySelector('#mobileNoForm');
    let No = mobileNo['elements']['mobileNo']['value'];
    this.mobNo = No;
    this.appointment.PortalPatientData(No).subscribe((res) => {
      let data: any = res;
      let dataResult = data;
      console.log(
        'Data ',
        data,
        dataResult,
        dataResult['PortalPatientDataResult'].length
      );
      this.BookingMobile = false;
      if (dataResult['PortalPatientDataResult'].length) {
        this.patientNameList = dataResult['PortalPatientDataResult'];
        $('.ttheader').delay(600).slideUp();
        $('.patientNameList').delay(600).slideDown();
      } else {
        this.bookingChangeStatus = 'new';
        $('.ttheader').delay(600).slideUp();
        //$('.dconfirmawrp1').delay(600).slideDown();
        $('.dconfirmawrp2').delay(600).slideDown();
        $('#txtMobileSN').prop('disabled', false);
        this.CNewAppointmentRegistered.patchValue({
          VisitorMobile: this.mobNo,
        });
        this.CNewAppointmentRegistered.controls['VisitorMobile'].disable();
      }
    });
  }

  AddPatient() {
    this.phybookingstatus = true;
    this.bookingChangeStatus = 'new';
    $('.patientNameList').delay(600).slideUp();
    $('.dconfirmawrp2').delay(600).slideDown();
    //this.CAppointmentRegistered.set('VisitorMobile').value = this.mobNo
    //$("#txtMobileSN").val(this.mobNo);
    this.CNewAppointmentRegistered.patchValue({
      VisitorMobile: this.mobNo,
    });
    this.CNewAppointmentRegistered.controls['VisitorMobile'].disable();
    //console.log(this.mobNo);
  }
  continueBtnFn(data) {
    this.bookingChangeStatus = 'existing';
    //console.log(data);
    this.CAppointmentRegistered.patchValue({
      VisitorName: data.C,
      VisitorMobile: data.J,
      VisitorEmail: data.K,
    });
    this.CAppointmentRegistered.controls['VisitorName'].disable();
    this.CAppointmentRegistered.controls['VisitorMobile'].disable();
    this.CAppointmentRegistered.controls['VisitorEmail'].disable();
    $('.patientNameList').delay(600).slideUp();
    $('.dconfirmawrp1').delay(600).slideDown();
  }
  registeredPatient(payType) {
    if (payType == 'paynow') {
      this.appointmentPaynowPaylater = true;
    } else {
      this.appointmentPaynowPaylater = false;
    }
    var lPatientUID = '';

    if (this.CAppointmentRegistered.value) {
      //alert('adfadf');
      let loginName = this.CAppointmentRegistered.get('VisitorName').value;
      let loginMobile = this.CAppointmentRegistered.get('VisitorMobile').value;
      let loginEmail = this.CAppointmentRegistered.get('VisitorEmail').value;
      let sSelectedSlot =
        this.CAppointmentRegistered.get('sSelectedSlot').value;
      let sSlotEndTime = this.CAppointmentRegistered.get('sSlotEndTime').value;
      let sComments = this.CAppointmentRegistered.get('sComments').value;

      this.appointment
        .AuthenticatePatientLogin(loginName, loginMobile)
        .subscribe(
          (res) => {
            let doctorArr: any = res;
            let apiResult =doctorArr;

            this.LoggedInName =
              apiResult.AuthenticatePatientLoginResult.LoginName;
            let nOwnerOrganisationUID =
              apiResult.AuthenticatePatientLoginResult.OwnerOrganisationUID;

            var loginResult = {
              LoginName: apiResult.AuthenticatePatientLoginResult.LoginName,
              HasUserChangedPassword:
                apiResult.AuthenticatePatientLoginResult.HasUserChangedPassword,
              OwnerOrganisationUID:
                apiResult.AuthenticatePatientLoginResult.OwnerOrganisationUID,
              UID: apiResult.AuthenticatePatientLoginResult.UID,
              IsLocked: apiResult.AuthenticatePatientLoginResult.IsLocked,
              LastAccessedDTTM:
                apiResult.AuthenticatePatientLoginResult.LastAccessedDTTM,
            };
            if (loginResult.UID) {
              // this.loginTitle ='Success';
              // this.loginMsg = 'Logged in successfuly!';
              // initPopup('loginmsg');
              this.patientName = loginName;
              this.patientMobile = loginMobile;
              this.patientEmail = loginEmail;

              this.dl_PatientType = 'Existing Patient';
              this.dl_UserType = 'Existing';
              this.dl_utmSource = '';
              this.dl_birthDate = '';

              this.otpMobileNumber = loginMobile;
              let otpData = {
                mobile: loginMobile,
                sent_type: 'send',
              };
              $('.CalcLoader').css('display', 'none');

              initPopup('sendotp');

              //alert('logged in!');
              lPatientUID = loginResult.UID;
              localStorage.setItem('lPatient_UID', loginResult.UID);
              this.Patient_UID = loginResult.UID;
              this.ConfirmBookingdata = {
                lPatientUID: loginResult.UID,
                //"nOwnerOrganisationUID":loginResult.OwnerOrganisationUID,
                nOwnerOrganisationUID: this.ownerOrganizationIDBySlot,
                nLocationUID: this.nLocationUID,
                nCareproviderUID: this.doctor_uid,
                nServiceUID: this.nServiceUID,
                nSessionUID: this.nSessionUID,
                sSelectedSlot: this.slotStartDate,
                sSlotEndTime: this.slotEndDate,
                sComments: this.sComments,
              };

              this.getDoctorFeesByApi(
                this.Patient_UID,
                this.nLocationUID,
                this.doctor_uid,
                this.ownerOrganizationIDBySlot,
                this.atype
              );

              //console.log('ConfirmBookingdata', this.ConfirmBookingdata);
              this.api.postConfirmationOTP(otpData).subscribe(
                (res) => {
                  console.log('##########OTP Send#########');
                },
                (err) => {
                  console.error(err);
                  //this.otpMsg = 'Please enter valid OTP';
                }
              );
              //this.confirmBooking(data, this.atype);
            } else {
              this.loginTitle = 'Error';
              this.loginMsg = 'Invalid login detail!';
              initPopup('loginmsg');
              //alert('invalid login')
            }
          },
          (err) => {
            $('.CalcLoader').css('display', 'none');
            console.error(err);
          }
        );
    }
  }
  validateCSendOTPForm() {
    this.CSendOTPForm = this.formBuilder.group({
      userOTPCode: ['', Validators.required],
    });
  }
  confirmOTPWithPayNowPayLater() {
    this.PopupPayNowPayLaterLoader = true;
    if (this.CSendOTPForm.value) {
      let data = {
        mobile: this.otpMobileNumber,
        otp: this.CSendOTPForm.get('userOTPCode').value,
      };

      this.api.postConfirmationOTPVerify(data).subscribe(
        (res) => {
          if (res.status_code == 200) {
            console.log('verifyAppointmentByPhoneNo', res);
            this.appointment
              .verifyAppointmentByPhoneNo(
                this.ConfirmBookingdata,
                this.otpMobileNumber
              )
              .subscribe((res) => {
                console.log('verifyAppointmentByPhoneNo', res);
                let appointmentByPhoneNoArr: any = res;
                let aByPhoneNoBody = appointmentByPhoneNoArr;
                let aByPhoneNoResult =
                  aByPhoneNoBody.GetAppointmentByPhoneNoResult;
                this.BookingStatus = aByPhoneNoResult.Status;
                //console.log('BookingStatus', this.BookingStatus);
                // if (res.status == 200) {
                  //console.log('aByPhoneNoResult.Status: ', aByPhoneNoResult.Status);
                  if (aByPhoneNoResult.Status == 'False') {
                    this.CNewAppointmentRegistered.reset();
                    this.CAppointmentRegistered.reset();
                    this.otpMsg = 'Thankyou';
                    this.CSendOTPForm.reset();
                    this.confirmBooking(this.ConfirmBookingdata, this.atype);
                  } else {
                    $('.capopup').hide();
                    initPopup('verifyAppointment');
                    this.navBack();
                    $('.dconfirmawrp').slideUp();
                    this.CNewAppointmentRegistered.reset();
                    this.CAppointmentRegistered.reset();
                    this.CSendOTPForm.reset();
                    this.SelectCheckBox();
                    this.slotNotAvailableAddBut = true;
                    this.PopupPayNowPayLaterLoader = false;
                    setTimeout(() => {
                      $('body').animate(
                        {
                          scrollTop: $('.bookanappointment').offset().top - 100,
                        },
                        2000
                      );
                    }, 200);
                  }
                // }
              });
          }
        },
        (err) => {
          this.PopupPayNowPayLaterLoader = false;
          this.otpMsg = 'Please enter valid OTP';
        }
      );
    }
  }
  confirmOTP() {
    if (this.CSendOTPForm.value) {
      let data = {
        mobile: this.otpMobileNumber,
        otp: this.CSendOTPForm.get('userOTPCode').value,
      };

      this.api.postConfirmationOTPVerify(data).subscribe(
        (res) => {
          if (res.status_code == 200) {
            //nCareproviderUID,nOwnerOrganisationUID,sMobile,dtAppointmentDttm,dtSlotStartDttm,dtSlotEndDttm,lPatientUID
            this.appointment
              .verifyAppointmentByPhoneNo(
                this.ConfirmBookingdata,
                this.otpMobileNumber
              )
              .subscribe((res) => {
                //console.log('verifyAppointmentByPhoneNo', res);
                let appointmentByPhoneNoArr: any = res;
                let aByPhoneNoBody = appointmentByPhoneNoArr;
                let aByPhoneNoResult =
                  aByPhoneNoBody.GetAppointmentByPhoneNoResult;
                this.BookingStatus = aByPhoneNoResult.Status;
                //console.log('BookingStatus', this.BookingStatus);
                // if (res.status == 200) {
                  if (aByPhoneNoResult.Status == 'False') {
                    this.CNewAppointmentRegistered.reset();
                    this.CAppointmentRegistered.reset();
                    this.otpMsg = 'Thankyou';
                    this.CSendOTPForm.reset();
                    this.confirmBooking(this.ConfirmBookingdata, this.atype);
                  } else {
                    $('.capopup').hide();
                    $('body').animate(
                      { scrollTop: $('.bookanappointment').offset().top - 100 },
                      2000
                    );
                    initPopup('verifyAppointment');
                    this.navBack();
                    $('.dconfirmawrp').slideUp();
                    this.CNewAppointmentRegistered.reset();
                    this.CAppointmentRegistered.reset();
                    this.CSendOTPForm.reset();
                    this.SelectCheckBox();
                  }
                // }
              });
            /*
					this.CNewAppointmentRegistered.reset();
					this.CAppointmentRegistered.reset();
					this.otpMsg = 'Thankyou';
					this.CSendOTPForm.reset();
					this.confirmBooking(this.ConfirmBookingdata, this.atype);
					*/
          }
        },
        (err) => {
          this.otpMsg = 'Please enter valid OTP';
        }
      );
    }
    this.DataLayerOnFormChange(
      'BookingPage',
      'BookingPage_SelectDoctor_ConfirmOTP',
      'Book-an-appointment'
    );
  }

  onClickBookNow() {
    initPopup('sendotp');
    let otpData = { mobile: this.otpMobileNumber, sent_type: 'send' };
    this.api.postConfirmationOTP(otpData).subscribe(
      (res) => {
        console.log('##########OTP Send#########');
      },
      (err) => {
        console.error(err);
      }
    );
    this.DataLayerOnFormChange(
      'BookingPage',
      'BookingPage_SelectDoctor_Send_OTP',
      'Book-an-appointment'
    );
  }

  onClickRegisteredPatient(payType) {
    if (payType == 'paynow') {
      this.appointmentPaynowPaylater = true;
    } else {
      this.appointmentPaynowPaylater = false;
    }
    initPopup('sendotp');
    let otpData = { mobile: this.otpMobileNumber, sent_type: 'send' };
    this.api.postConfirmationOTP(otpData).subscribe(
      (res) => {
        console.log('##########OTP Send#########');
      },
      (err) => {
        console.error(err);
      }
    );
    this.DataLayerOnFormChange(
      'BookingPage',
      'BookingPage_SelectDoctor_Send_OTP',
      'Book-an-appointment'
    );
  }
  onClickNewPatient(payType) {
    if (payType == 'paynow') {
      this.appointmentPaynowPaylater = true;
    } else {
      this.appointmentPaynowPaylater = false;
    }
    initPopup('sendotp');
    let otpData = { mobile: this.otpMobileNumber, sent_type: 'send' };
    this.api.postConfirmationOTP(otpData).subscribe(
      (res) => {
        console.log('##########OTP Send#########');
      },
      (err) => {
        console.error(err);
      }
    );
    this.DataLayerOnFormChange(
      'BookingPage',
      'BookingPage_SelectDoctor_Send_OTP',
      'Book-an-appointment'
    );
  }

  getDoctorFees(selectedStatus) {
    //console.log('getDoctorFees', selectedStatus, 'bookingChangeStatus =',this.bookingChangeStatus)
    if (selectedStatus) {
      this.bookingDoctorFeesStatus = false;
      this.appointmentCheckAuth = false;
      //console.log('bookingDoctorFeesStatus:', this.bookingChangeStatus);
      if (this.bookingChangeStatus == 'existing') {
        let loginName = this.CAppointmentRegistered.get('VisitorName').value;
        let loginMobile =
          this.CAppointmentRegistered.get('VisitorMobile').value;
        let loginEmail = this.CAppointmentRegistered.get('VisitorEmail').value;
        let sSelectedSlot =
          this.CAppointmentRegistered.get('sSelectedSlot').value;
        let sSlotEndTime =
          this.CAppointmentRegistered.get('sSlotEndTime').value;
        let sComments = this.CAppointmentRegistered.get('sComments').value;
        //console.log('loginName:', loginName,' loginMobile:', loginMobile,' loginEmail:', loginEmail);
        //if(loginName != null && loginMobile != null && loginEmail != null){
        //this.bookingDoctorFeesStatus = true;
        let lPatientUID = '';
        //console.log('existing bookingDoctorFeesStatus:');
        this.appointment
          .AuthenticatePatientLogin(loginName, loginMobile)
          .subscribe(
            (res) => {
              this.appointmentCheckAuth = true;
              let doctorArr: any = res;
              let apiResult = doctorArr;

              this.LoggedInName =
                apiResult.AuthenticatePatientLoginResult.LoginName;
              let nOwnerOrganisationUID =
                apiResult.AuthenticatePatientLoginResult.OwnerOrganisationUID;

              var loginResult = {
                LoginName: apiResult.AuthenticatePatientLoginResult.LoginName,
                HasUserChangedPassword:
                  apiResult.AuthenticatePatientLoginResult
                    .HasUserChangedPassword,
                OwnerOrganisationUID:
                  apiResult.AuthenticatePatientLoginResult.OwnerOrganisationUID,
                UID: apiResult.AuthenticatePatientLoginResult.UID,
                IsLocked: apiResult.AuthenticatePatientLoginResult.IsLocked,
                LastAccessedDTTM:
                  apiResult.AuthenticatePatientLoginResult.LastAccessedDTTM,
              };
              if (loginResult.UID) {
                this.patientName = loginName;
                this.patientMobile = loginMobile;
                this.patientEmail = loginEmail;

                this.dl_PatientType = 'Existing Patient';
                this.dl_UserType = 'Existing';
                this.dl_utmSource = '';
                this.dl_birthDate = '';

                lPatientUID = loginResult.UID;
                localStorage.setItem('lPatient_UID', loginResult.UID);
                this.Patient_UID = loginResult.UID;

                this.otpMobileNumber = loginMobile;
                this.ConfirmBookingdata = {
                  lPatientUID: loginResult.UID,
                  nOwnerOrganisationUID: this.ownerOrganizationIDBySlot,
                  nLocationUID: this.nLocationUID,
                  nCareproviderUID: this.doctor_uid,
                  nServiceUID: this.nServiceUID,
                  nSessionUID: this.nSessionUID,
                  sSelectedSlot: this.slotStartDate,
                  sSlotEndTime: this.slotEndDate,
                  sComments: this.sComments,
                };

                this.getDoctorFeesByApi(
                  this.Patient_UID,
                  this.nLocationUID,
                  this.doctor_uid,
                  this.ownerOrganizationIDBySlot,
                  this.atype
                );
              } else {
                this.loginTitle = 'Error';
                this.loginMsg = 'Invalid login detail!';
                initPopup('loginmsg');
                setTimeout(() => {
                  this.CAppointmentRegistered.controls.registeredUserTNC.setValue(
                    true
                  );
                  this.SelectCheckBoxSH();
                  this.bookingDoctorFeesStatus = false;
                  this.appointmentCheckAuth = false;
                  this.CAppointmentRegistered.reset();
                }, 1000);
              }
            },
            (err) => {
              $('.CalcLoader').css('display', 'none');
              console.error(err);
            }
          );
        //}
      } else if (this.bookingChangeStatus == 'new') {
        console.log('booking status new');
        //this.bookingDoctorFeesStatus =true;
        let loginName = this.CNewAppointmentRegistered.get('VisitorName').value;
        let loginMobile =
          this.CNewAppointmentRegistered.get('VisitorMobile').value;
        let VisitorEmail =
          this.CNewAppointmentRegistered.get('VisitorEmail').value;
        let VisitorDOB = this.CNewAppointmentRegistered.get('VisitorDOB').value;
        let VisitorMotherName =
          this.CNewAppointmentRegistered.get('VisitorMotherName').value;
        let sourceName = this.CNewAppointmentRegistered.get('sourceName').value;
        let sSelectedSlot =
          this.CNewAppointmentRegistered.get('sSelectedSlot').value;
        let sSlotEndTime =
          this.CNewAppointmentRegistered.get('sSlotEndTime').value;
        let sComments = this.CNewAppointmentRegistered.get('sComments').value;

        this.patientName = loginName;
        this.patientMobile = loginMobile;
        this.patientEmail = VisitorEmail;
        this.dl_PatientType = 'New Patient';
        this.dl_UserType = 'New';
        this.dl_utmSource = sourceName;
        this.dl_birthDate = VisitorDOB;

        let userDetail = {
          first_name: loginName,
          middle_name: '',
          last_name: '',
          dob: VisitorDOB,
          mobile_number: loginMobile,
          email_address: VisitorEmail,
          org_id: this.centre_uid,
          mother_name: VisitorMotherName,
          reference_id: sourceName,
        };

        this.appointment.newPatientRegister(userDetail).subscribe(
          (res) => {
            console.log('newPatientRegister', res);
            // if (res.status == 200) {
              this.appointmentCheckAuth = true;
              let doctorArr: any = res;
              let apiResult = doctorArr;
              this.otpMobileNumber = loginMobile;
              this.LoggedInName = apiResult.RegisterPatientResult.AAA;
              var registerResult = {
                OwnerOrganisationUID: apiResult.RegisterPatientResult.AAD,
                UID: apiResult.RegisterPatientResult.AAC,
              };
              localStorage.setItem('lPatient_UID', registerResult.UID);
              this.Patient_UID = registerResult.UID;
              this.otpMobileNumber = loginMobile;

              this.otpMobileNumber = loginMobile;
              this.ConfirmBookingdata = {
                lPatientUID: registerResult.UID,
                //"nOwnerOrganisationUID":registerResult.OwnerOrganisationUID,
                nOwnerOrganisationUID: this.ownerOrganizationIDBySlot,
                nLocationUID: this.nLocationUID,
                nCareproviderUID: this.doctor_uid,
                nServiceUID: this.nServiceUID,
                nSessionUID: this.nSessionUID,
                sSelectedSlot: this.slotStartDate,
                sSlotEndTime: this.slotEndDate,
                sComments: this.sComments,
              };

              this.getDoctorFeesByApi(
                this.Patient_UID,
                this.nLocationUID,
                this.doctor_uid,
                this.ownerOrganizationIDBySlot,
                this.atype
              );
            // } else {
            //   $('.CalcLoader').css('display', 'none');
            // }
          },
          (err) => {
            $('.CalcLoader').css('display', 'none');
            console.error(err);
          }
        );
      }
    } else {
      this.phybookingstatus = true;
      this.bookingDoctorFeesStatus = false;
      console.log('else');
    }

    this.DataLayerOnFormChange(
      'BookingPage',
      'BookingPage_SelectDoctor_GetDoctorFees',
      'Book-an-appointment'
    );
  }

  /***
   * New register
   */
  validateNewRegister() {
    this.leadSource = [
      { id: 1845, title: 'Clinic / Hospital' },
      { id: 164928, title: 'Hoardings' },
      { id: 164930, title: 'Hospital Signage' },
      { id: 5271, title: 'Inhouse Doctors' },
      { id: 164934, title: 'Newspaper Advertisement' },
      { id: 164927, title: 'Newspaper Article' },
      { id: 5140, title: 'Others' },
      { id: 164929, title: 'Radio' },
      { id: 164932, title: 'Referred by Doctor' },
      { id: 164931, title: 'Referred by Known' },
      { id: 163722, title: 'Self' },
      { id: 164933, title: 'TV Commercial' },
    ];
    this.CNewAppointmentRegistered = this.formBuilder.group({
      VisitorName: ['', Validators.required],
      VisitorMobile: [
        '',
        Validators.compose([Validators.required, Validators.minLength(10)]),
      ],
      VisitorEmail: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            '[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}'
          ),
        ]),
      ],
      VisitorDOB: ['', Validators.required],
      VisitorMotherName: ['', Validators.required],
      sourceName: ['0', Validators.required],
      sSelectedSlot: [''],
      sSlotEndTime: [''],
      sComments: [''],
      newUserTNC: ['', Validators.required],
    });
  }
  resendOTP() {
    let otpData = {
      mobile: this.otpMobileNumber,
      sent_type: 'resend',
    };
    this.api.postConfirmationOTP(otpData).subscribe(
      (res) => {
        alert('New OTP has been sent to your ' + this.otpMobileNumber);
        console.log('otp send');
      },
      (err) => {
        console.error(err);
        //this.otpMsg = 'Please enter valid OTP';
      }
    );
    this.DataLayerOnFormChange(
      'BookingPage',
      'BookingPage_SelectDoctor_Resend_OTP',
      'Book-an-appointment'
    );
  }
  newPatient(payType) {
    if (payType == 'paynow') {
      this.appointmentPaynowPaylater = true;
    } else {
      this.appointmentPaynowPaylater = false;
    }

    //console.log('CNewAppointmentRegistered: ', this.CNewAppointmentRegistered.value)
    if (this.CNewAppointmentRegistered.value) {
      $('.CalcLoader').css('display', 'block');
      let loginName = this.CNewAppointmentRegistered.get('VisitorName').value;
      let loginMobile =
        this.CNewAppointmentRegistered.get('VisitorMobile').value;
      let VisitorEmail =
        this.CNewAppointmentRegistered.get('VisitorEmail').value;
      let VisitorDOB = this.CNewAppointmentRegistered.get('VisitorDOB').value;
      let VisitorMotherName =
        this.CNewAppointmentRegistered.get('VisitorMotherName').value;
      let sourceName = this.CNewAppointmentRegistered.get('sourceName').value;
      let sSelectedSlot =
        this.CNewAppointmentRegistered.get('sSelectedSlot').value;
      let sSlotEndTime =
        this.CNewAppointmentRegistered.get('sSlotEndTime').value;
      let sComments = this.CNewAppointmentRegistered.get('sComments').value;
      //let VisitorDOBFormat = moment(VisitorDOB).format('DD-MMMM-YYYY');

      this.patientName = loginName;
      this.patientMobile = loginMobile;
      this.patientEmail = VisitorEmail;
      //let convertedDate = this.datepipe.transform(new Date(), 'dd-MMMM-yyyy');

      this.dl_PatientType = 'New Patient';
      this.dl_UserType = 'New';
      this.dl_utmSource = sourceName;
      this.dl_birthDate = VisitorDOB;

      let userDetail = {
        first_name: loginName,
        middle_name: '',
        last_name: '',
        dob: VisitorDOB,
        mobile_number: loginMobile,
        email_address: VisitorEmail,
        org_id: this.centre_uid,
        mother_name: VisitorMotherName,
        reference_id: sourceName,
      };

      this.appointment.newPatientRegister(userDetail).subscribe(
        (res) => {
          // if (res.status == 200) {
            ///$('.CalcLoader').css('display','none');
            let doctorArr: any = res;
            let apiResult = doctorArr;

            this.otpMobileNumber = loginMobile;
            let otpData = {
              mobile: loginMobile,
              sent_type: 'send',
            };

            initPopup('sendotp');
            this.LoggedInName = apiResult.RegisterPatientResult.AAA;

            //"{"RegisterPatientResult":{"AAA":"VIKTMP12236","AAB":0,"AAC":848632,"AAD":4}}"
            var registerResult = {
              OwnerOrganisationUID: apiResult.RegisterPatientResult.AAD,
              UID: apiResult.RegisterPatientResult.AAC,
            };

            localStorage.setItem('lPatient_UID', registerResult.UID);
            this.Patient_UID = registerResult.UID;
            this.ConfirmBookingdata = {
              lPatientUID: registerResult.UID,
              //"nOwnerOrganisationUID":registerResult.OwnerOrganisationUID,
              nOwnerOrganisationUID: this.ownerOrganizationIDBySlot,
              nLocationUID: this.nLocationUID,
              nCareproviderUID: this.doctor_uid,
              nServiceUID: this.nServiceUID,
              nSessionUID: this.nSessionUID,
              sSelectedSlot: this.slotStartDate,
              sSlotEndTime: this.slotEndDate,
              sComments: this.sComments,
            };
            this.getDoctorFeesByApi(
              this.Patient_UID,
              this.nLocationUID,
              this.doctor_uid,
              this.ownerOrganizationIDBySlot,
              this.atype
            );
            this.api.postConfirmationOTP(otpData).subscribe(
              (res) => {
                console.log('otp send');
              },
              (err) => {
                console.error(err);
                //this.otpMsg = 'Please enter valid OTP';
              }
            );

            $('.CalcLoader').css('display', 'none');
            //this.confirmBooking(data, this.atype);
          // } else {
          //   $('.CalcLoader').css('display', 'none');
          // }
        },
        (err) => {
          $('.CalcLoader').css('display', 'none');
          console.error(err);
        }
      );
    }
    this.DataLayerOnFormChange(
      'BookingPage',
      'BookingPage_SelectDoctor_NewPatient',
      'Book-an-appointment'
    );
  }

  confirmBooking(data: any, atype: any) {
    this.appointment.ConfirmBooking(data, atype).subscribe(
      (res) => {
        //console.log('confirmBooking', res);
        // if (res.status == 200) {
          $('.CalcLoader').css('display', 'none');
          let confirmationBooking: any = res;
          let apiResult = confirmationBooking;

          apiResult.ConfirmBookingResult.map((r) => {
            this.BookingNumber = r.BookingNumber;
          });

          if (this.BookingNumber) {
            // Event snippet for All_Leads conversion page -->
            //gtag('event', 'conversion', {'send_to': 'AW-975509116/Ux_-CMbgj4gBEPyslNED'});
            if (this.isDoctorFees) {
              $('[data-popup="popup-sendotp"]').delay(300).fadeOut(350);
              setTimeout(() => {
                $('.dconfirmadresswrp').slideDown();
                $('.dconfirmawrp').slideUp();
                $('.dconfirmawrp2').slideUp();
                this.disableSelectOnAppointmentBook = false;
                this.postAppointmentDetailsByBookNow();
              }, 500);
            } else {
              if ($.cookie('SessionType') == 1) {
                if (this.appointmentPaynowPaylater) {
                  this.postAppointmentDetailsWithPayNow();
                } else {
                  $('[data-popup="popup-sendotp"]').delay(300).fadeOut(350);
                  setTimeout(() => {
                    $('.dconfirmadresswrp').slideDown();
                    $('.dconfirmawrp').slideUp();
                    $('.dconfirmawrp2').slideUp();
                    this.disableSelectOnAppointmentBook = false;
                    this.postAppointmentDetails();
                  }, 500);
                }
              } else if ($.cookie('SessionType') == 2) {
                if (this.appointmentPaynowPaylater) {
                  this.postAppointmentDetailsWithPayNow();
                }
              }
            }
            /*
					$('[data-popup="popup-sendotp"]').delay(300).fadeOut(350);
					setTimeout(()=>{
						$('.dconfirmadresswrp').slideDown();
						$('.dconfirmawrp').slideUp();
						$('.dconfirmawrp2').slideUp();
						this.postAppointmentDetails();	
						// Event snippet for All_Leads conversion page -->
						gtag('event', 'conversion', {'send_to': 'AW-975509116/Ux_-CMbgj4gBEPyslNED'});
					},500);
					*/
            this.DataLayerOnFormChange(
              'BookingPage',
              'BookingPage_SelectDoctor_BookAppointment_Bookingconfirmed',
              'Book-an-appointment'
            );
          } else {
            $('[data-popup="popup-sendotp"]').delay(300).fadeOut(350);
            //$('.dconfirmadresswrp').slideDown();
            setTimeout(() => {
              initPopup('invalidrecipt');
            }, 500);
          }
        // }
      },
      (err) => {
        $('.CalcLoader').css('display', 'none');
      }
    );

    $('body').animate(
      { scrollTop: $('.bookanappointment').offset().top - 100 },
      2000
    );
  }

  getDoctorFeesByApi(lPatientUID, lUid, cUid, oUid, atype) {
    this.appointment
      .getFeesByDoctor(lPatientUID, lUid, cUid, oUid, atype)
      .subscribe(
        (data) => {
          this.bookingDoctorFeesErrorStatus = false;
          console.log('getFeesByDoctor: ', data, this.atype);
          let jdata = data;
          //this.apiDoctorFees = jdata.PRTFollowUpRuleConsultationChargeResult.C;
          //this.apiDoctorFeesNew = jdata.PRTFollowUpRuleConsultationChargeResult.C;
          //this.apiDoctorFees = 0;
          //jdata.PRTFollowUpRuleConsultationChargeResult = null;
          if (this.atype == 2) {
            this.apiDoctorFees =
              jdata.PRTFollowUpRuleConsultationChargeResult.C;
          } else {
            let apiDoctorFees: any = '';
            if (this.physicalbookingstatus == 1) {
              apiDoctorFees = jdata.PRTFollowUpRuleConsultationChargeResult.C;
            } else if (this.physicalbookingstatus == 2) {
              apiDoctorFees = this.doctorFees;
            } else if (this.physicalbookingstatus == 3) {
              apiDoctorFees = 0;
            } else {
              apiDoctorFees = 0;
            }
            this.apiDoctorFees = apiDoctorFees;
          }
          //console.log('doctorFees from API:', this.apiDoctorFees);
          //console.log('physicalbookingstatus:', this.physicalbookingstatus);
          if (this.physicalbookingstatus == 3) {
            this.phybookingstatus = false;
          } else {
            this.phybookingstatus = true;
          }

          if (this.atype == 2) {
            this.phybookingstatus = true;
            this.bookingDoctorFeesStatus = true;
            this.isDoctorFeesEnambledSubmitBtn = true;
            if (
              jdata.PRTFollowUpRuleConsultationChargeResult == null ||
              jdata.PRTFollowUpRuleConsultationChargeResult == ''
            ) {
              this.bookingDoctorFeesStatus = false;
              initPopup('timeSloteErrorMsg');
            } else {
              if (this.apiDoctorFees == 0) {
                this.isDoctorFees = true;
              } else {
                this.isDoctorFees = false;
              }
            }
            this.appointmentCheckAuth = false;
          } else {
            if (this.phybookingstatus) {
              this.bookingDoctorFeesStatus = true;
              this.isDoctorFeesEnambledSubmitBtn = true;
              if (
                jdata.PRTFollowUpRuleConsultationChargeResult == null ||
                jdata.PRTFollowUpRuleConsultationChargeResult == ''
              ) {
                this.bookingDoctorFeesStatus = false;
                initPopup('timeSloteErrorMsg');
              } else {
                if (this.apiDoctorFees == 0) {
                  this.isDoctorFees = true;
                } else {
                  this.isDoctorFees = false;
                }
              }
              this.appointmentCheckAuth = false;
              //console.log('apiDoctorFees: ', this.apiDoctorFees);
            }
          }
          this.appointmentCheckAuth = false;
        },
        (err) => {
          this.bookingDoctorFeesErrorStatus = true;
          if (this.bookingChangeStatus == 'existing') {
            this.loginTitle = 'Error';
            this.loginMsg = '/PRTFollowUpRuleConsultationCharge Api Error!!!';
            initPopup('loginmsg');
            setTimeout(() => {
              this.CAppointmentRegistered.controls.registeredUserTNC.setValue(
                true
              );
              this.SelectCheckBoxSH();
              this.bookingDoctorFeesStatus = false;
              this.appointmentCheckAuth = false;
            }, 1000);
          }
          $('.CalcLoader').css('display', 'none');
          console.error(err);
        }
      );
    this.DataLayerOnFormChange(
      'BookingPage',
      'BookingPage_SelectDoctor_GetDoctorFeesByApi',
      'Book-an-appointment'
    );
  }

  closeModal() {
    //console.log(event)
    this.locationdetail = {};
    this.doctorDetail = {};
    this.close.emit(null);
  }
  currentTime() {
    //this.cdref.markForCheck();
    //this.cdref.detectChanges();
    return new Date().getTime();
  }
  calendarTime(val: any) {
    return new Date(val).getTime();
  }
  navBack() {
    /* Hide the Appointment block */
    this.calenderVisible = false;
    this.isShowDate = false;
    this.slotNotAvailableAddBut = true;
    $('.dconfirmawrp1,.dconfirmawrp2').slideUp();
  }

  setSpecialty() {
    //console.log('WindowLoad specilty:', this.csSpeciality, this.speciltyList.length)

    if (this.speciltyList.length > 0) {
      if (this.csSpeciality != '') {
        //$('select#txtDSpecilty').val(this.speciltyselectedList['0'].speciality_uid).trigger('change');
        $('#selectedDSpecilty').text(this.speciltyselectedList['0'].speciality);
        //console.log('speciltyselectedList: ', this.speciltyselectedList['0'].speciality)
      } else {
        //$('select#txtDSpecilty').val(this.speciltyList['0'].speciality_uid).trigger('change');
        $('#selectedDSpecilty').text(this.speciltyList['0'].speciality);
        //console.log('speciltyList: ', this.speciltyList['0'].speciality)
      }
    }

    localStorage.removeItem('Locationid');
    localStorage.removeItem('selectedSpeciality');
    $.removeCookie('selectedSpecialitySlug');
  }

  ngAfterViewInit() {
    this.getCityList();

    if (this.api.isBrowser && window['jQuery']) {
      //initHome();
    }
    //	setTimeout()
    $('.dconfirmadresswrp').slideUp();
    $('.dconfirmawrp').slideUp();
    $('.dconfirmawrp2').slideUp();
    $('span#selectDSpecilty3').html('Hello');
  }
  ngOnDestroy() {}

  CancellationPolicy() {
    initPopup('cancelpolicy');
  }
  CancellationTC() {
    initPopup('cancelTnC');
  }
  closePopup() {
    $('.capopup').hide();
  }

  dataLayerPush() {
    let MobileNum,
      EmailID,
      bSubSpecility,
      birthDate,
      Weight,
      City,
      Speciality,
      unitName,
      DoctorName,
      utmSource,
      PreferredDate,
      appointmentTime;

    let c_path = window.location.pathname.split('/');
    let pName = c_path[c_path.length - 2];
    pName = pName.replace(/-/gi, '_');

    let gaid: any = tvc_get_cookies('_ga', 100);

    if (gaid != '') {
      gaid = gaid.split('.');
      gaid = gaid[2] + '.' + gaid[3];
      gaid = gaid.substring(0, 100);
    }

    let sSpeciality = localStorage.getItem('sSpecialtyName');
    if (sSpeciality != '') {
      this.dl_BookforSubSpecility = sSpeciality;
    } else {
      this.dl_BookforSubSpecility = '';
    }

    MobileNum = this.patientMobile;
    EmailID = this.patientEmail;
    birthDate = this.dl_birthDate;
    Weight = this.dl_Weight;
    City = this.dl_City;
    Speciality = this.c_specialityName;
    unitName = this.dl_unitName;
    DoctorName = this.dl_DoctorName;
    PreferredDate = this.dl_PreferredDate;
    utmSource = this.dl_utmSource;
    appointmentTime = this.dl_AppointmentTime;
    bSubSpecility = this.dl_BookforSubSpecility;

    MobileNum = MD5(MobileNum.toString());
    EmailID = MD5(EmailID.toString());

    let dName = DoctorName.replace(/ - /gi, '_').toLowerCase();
    dName = dName.replace(/ /gi, '-');

    Speciality = Speciality.replace(/ - /gi, '_').toLowerCase();
    Speciality = Speciality.replace(/ /gi, '_');

    let age: any = '';
    if (birthDate != '') {
      let dob: any = new Date(birthDate);
      let today: any = new Date();
      age = Math.floor((today - dob) / (365.25 * 24 * 60 * 60 * 1000));
    }

    let pDate = PreferredDate.split('-');
    //console.log('pDate:', pDate, pDate[0], pDate[1], pDate[2]);
    let getDateFormate = moment(
      pDate[2] + '-' + pDate[1] + '-' + pDate[0]
    ).format('dddd, Do MMM YYYY');
    appointmentTime = moment(appointmentTime, 'h:mm A').format('h:mm A');
    //console.log('getDateFormate:', getDateFormate);
    dataLayer.push({
      event: Speciality + '_BookanAppointment_ConfirmAppointment_Submit_UAT',
      GAClientId: gaid,
      UserType: this.dl_UserType,
      PageCategory: 'all:book-an-appointment:dr-' + dName,
      PageSubCategory: 'book-an-appointment:dr-' + dName,
      BookforSpecility: this.c_specialityName,
      BookforLocation: City,
      BookforSubSpecility: bSubSpecility,
      DoctorsName: DoctorName,
      WeightofPatient: Weight,
      AgeofPatient: age,
      SelectedHospitalUnit: unitName,
      AppointmentDate: getDateFormate,
      AppointmentTime: appointmentTime,
      PatientType: this.dl_PatientType,
      EncryptedEmailIid: EmailID,
      EncryptedMobileNumber: MobileNum,
      DOBofPatient: birthDate,
      Source: utmSource,
      LeadSubmit: '1',
      AppointmentId: this.BookingNumber,
    });
    console.log('On Appointment Confirm Data Layer Pass ->:', dataLayer);
  }
}

function tvc_get_cookies2(cname) {
  var result;
  return (result = new RegExp(
    '(?:^|; )' + encodeURIComponent(cname) + '=([^;]*)'
  ).exec(document.cookie))
    ? decodeURIComponent(result[1])
    : '';
}
function tvc_get_cookies(x, l) {
  var tempvalue = tvc_get_cookies2(x);
  if (tempvalue.length > l) {
    return tempvalue.substring(0, l);
  } else {
    return tempvalue;
  }
}
function MD5(string) {
  function RotateLeft(lValue, iShiftBits) {
    return (lValue << iShiftBits) | (lValue >>> (32 - iShiftBits));
  }

  function AddUnsigned(lX, lY) {
    var lX4, lY4, lX8, lY8, lResult;
    lX8 = lX & 0x80000000;
    lY8 = lY & 0x80000000;
    lX4 = lX & 0x40000000;
    lY4 = lY & 0x40000000;
    lResult = (lX & 0x3fffffff) + (lY & 0x3fffffff);
    if (lX4 & lY4) {
      return lResult ^ 0x80000000 ^ lX8 ^ lY8;
    }
    if (lX4 | lY4) {
      if (lResult & 0x40000000) {
        return lResult ^ 0xc0000000 ^ lX8 ^ lY8;
      } else {
        return lResult ^ 0x40000000 ^ lX8 ^ lY8;
      }
    } else {
      return lResult ^ lX8 ^ lY8;
    }
  }

  function F(x, y, z) {
    return (x & y) | (~x & z);
  }
  function G(x, y, z) {
    return (x & z) | (y & ~z);
  }
  function H(x, y, z) {
    return x ^ y ^ z;
  }
  function I(x, y, z) {
    return y ^ (x | ~z);
  }

  function FF(a, b, c, d, x, s, ac) {
    a = AddUnsigned(a, AddUnsigned(AddUnsigned(F(b, c, d), x), ac));
    return AddUnsigned(RotateLeft(a, s), b);
  }

  function GG(a, b, c, d, x, s, ac) {
    a = AddUnsigned(a, AddUnsigned(AddUnsigned(G(b, c, d), x), ac));
    return AddUnsigned(RotateLeft(a, s), b);
  }

  function HH(a, b, c, d, x, s, ac) {
    a = AddUnsigned(a, AddUnsigned(AddUnsigned(H(b, c, d), x), ac));
    return AddUnsigned(RotateLeft(a, s), b);
  }

  function II(a, b, c, d, x, s, ac) {
    a = AddUnsigned(a, AddUnsigned(AddUnsigned(I(b, c, d), x), ac));
    return AddUnsigned(RotateLeft(a, s), b);
  }

  function ConvertToWordArray(string) {
    var lWordCount;
    var lMessageLength = string.length;
    var lNumberOfWords_temp1 = lMessageLength + 8;
    var lNumberOfWords_temp2 =
      (lNumberOfWords_temp1 - (lNumberOfWords_temp1 % 64)) / 64;
    var lNumberOfWords = (lNumberOfWords_temp2 + 1) * 16;
    var lWordArray = Array(lNumberOfWords - 1);
    var lBytePosition = 0;
    var lByteCount = 0;
    while (lByteCount < lMessageLength) {
      lWordCount = (lByteCount - (lByteCount % 4)) / 4;
      lBytePosition = (lByteCount % 4) * 8;
      lWordArray[lWordCount] =
        lWordArray[lWordCount] |
        (string.charCodeAt(lByteCount) << lBytePosition);
      lByteCount++;
    }
    lWordCount = (lByteCount - (lByteCount % 4)) / 4;
    lBytePosition = (lByteCount % 4) * 8;
    lWordArray[lWordCount] = lWordArray[lWordCount] | (0x80 << lBytePosition);
    lWordArray[lNumberOfWords - 2] = lMessageLength << 3;
    lWordArray[lNumberOfWords - 1] = lMessageLength >>> 29;
    return lWordArray;
  }

  function WordToHex(lValue) {
    var WordToHexValue = '',
      WordToHexValue_temp = '',
      lByte,
      lCount;
    for (lCount = 0; lCount <= 3; lCount++) {
      lByte = (lValue >>> (lCount * 8)) & 255;
      WordToHexValue_temp = '0' + lByte.toString(16);
      WordToHexValue =
        WordToHexValue +
        WordToHexValue_temp.substr(WordToHexValue_temp.length - 2, 2);
    }
    return WordToHexValue;
  }

  function Utf8Encode(string) {
    string = string.replace(/\r\n/g, '\n');
    var utftext = '';

    for (var n = 0; n < string.length; n++) {
      var c = string.charCodeAt(n);

      if (c < 128) {
        utftext += String.fromCharCode(c);
      } else if (c > 127 && c < 2048) {
        utftext += String.fromCharCode((c >> 6) | 192);
        utftext += String.fromCharCode((c & 63) | 128);
      } else {
        utftext += String.fromCharCode((c >> 12) | 224);
        utftext += String.fromCharCode(((c >> 6) & 63) | 128);
        utftext += String.fromCharCode((c & 63) | 128);
      }
    }

    return utftext;
  }

  var x = Array();
  var k, AA, BB, CC, DD, a, b, c, d;
  var S11 = 7,
    S12 = 12,
    S13 = 17,
    S14 = 22;
  var S21 = 5,
    S22 = 9,
    S23 = 14,
    S24 = 20;
  var S31 = 4,
    S32 = 11,
    S33 = 16,
    S34 = 23;
  var S41 = 6,
    S42 = 10,
    S43 = 15,
    S44 = 21;

  string = Utf8Encode(string);

  x = ConvertToWordArray(string);

  a = 0x67452301;
  b = 0xefcdab89;
  c = 0x98badcfe;
  d = 0x10325476;

  for (k = 0; k < x.length; k += 16) {
    AA = a;
    BB = b;
    CC = c;
    DD = d;
    a = FF(a, b, c, d, x[k + 0], S11, 0xd76aa478);
    d = FF(d, a, b, c, x[k + 1], S12, 0xe8c7b756);
    c = FF(c, d, a, b, x[k + 2], S13, 0x242070db);
    b = FF(b, c, d, a, x[k + 3], S14, 0xc1bdceee);
    a = FF(a, b, c, d, x[k + 4], S11, 0xf57c0faf);
    d = FF(d, a, b, c, x[k + 5], S12, 0x4787c62a);
    c = FF(c, d, a, b, x[k + 6], S13, 0xa8304613);
    b = FF(b, c, d, a, x[k + 7], S14, 0xfd469501);
    a = FF(a, b, c, d, x[k + 8], S11, 0x698098d8);
    d = FF(d, a, b, c, x[k + 9], S12, 0x8b44f7af);
    c = FF(c, d, a, b, x[k + 10], S13, 0xffff5bb1);
    b = FF(b, c, d, a, x[k + 11], S14, 0x895cd7be);
    a = FF(a, b, c, d, x[k + 12], S11, 0x6b901122);
    d = FF(d, a, b, c, x[k + 13], S12, 0xfd987193);
    c = FF(c, d, a, b, x[k + 14], S13, 0xa679438e);
    b = FF(b, c, d, a, x[k + 15], S14, 0x49b40821);
    a = GG(a, b, c, d, x[k + 1], S21, 0xf61e2562);
    d = GG(d, a, b, c, x[k + 6], S22, 0xc040b340);
    c = GG(c, d, a, b, x[k + 11], S23, 0x265e5a51);
    b = GG(b, c, d, a, x[k + 0], S24, 0xe9b6c7aa);
    a = GG(a, b, c, d, x[k + 5], S21, 0xd62f105d);
    d = GG(d, a, b, c, x[k + 10], S22, 0x2441453);
    c = GG(c, d, a, b, x[k + 15], S23, 0xd8a1e681);
    b = GG(b, c, d, a, x[k + 4], S24, 0xe7d3fbc8);
    a = GG(a, b, c, d, x[k + 9], S21, 0x21e1cde6);
    d = GG(d, a, b, c, x[k + 14], S22, 0xc33707d6);
    c = GG(c, d, a, b, x[k + 3], S23, 0xf4d50d87);
    b = GG(b, c, d, a, x[k + 8], S24, 0x455a14ed);
    a = GG(a, b, c, d, x[k + 13], S21, 0xa9e3e905);
    d = GG(d, a, b, c, x[k + 2], S22, 0xfcefa3f8);
    c = GG(c, d, a, b, x[k + 7], S23, 0x676f02d9);
    b = GG(b, c, d, a, x[k + 12], S24, 0x8d2a4c8a);
    a = HH(a, b, c, d, x[k + 5], S31, 0xfffa3942);
    d = HH(d, a, b, c, x[k + 8], S32, 0x8771f681);
    c = HH(c, d, a, b, x[k + 11], S33, 0x6d9d6122);
    b = HH(b, c, d, a, x[k + 14], S34, 0xfde5380c);
    a = HH(a, b, c, d, x[k + 1], S31, 0xa4beea44);
    d = HH(d, a, b, c, x[k + 4], S32, 0x4bdecfa9);
    c = HH(c, d, a, b, x[k + 7], S33, 0xf6bb4b60);
    b = HH(b, c, d, a, x[k + 10], S34, 0xbebfbc70);
    a = HH(a, b, c, d, x[k + 13], S31, 0x289b7ec6);
    d = HH(d, a, b, c, x[k + 0], S32, 0xeaa127fa);
    c = HH(c, d, a, b, x[k + 3], S33, 0xd4ef3085);
    b = HH(b, c, d, a, x[k + 6], S34, 0x4881d05);
    a = HH(a, b, c, d, x[k + 9], S31, 0xd9d4d039);
    d = HH(d, a, b, c, x[k + 12], S32, 0xe6db99e5);
    c = HH(c, d, a, b, x[k + 15], S33, 0x1fa27cf8);
    b = HH(b, c, d, a, x[k + 2], S34, 0xc4ac5665);
    a = II(a, b, c, d, x[k + 0], S41, 0xf4292244);
    d = II(d, a, b, c, x[k + 7], S42, 0x432aff97);
    c = II(c, d, a, b, x[k + 14], S43, 0xab9423a7);
    b = II(b, c, d, a, x[k + 5], S44, 0xfc93a039);
    a = II(a, b, c, d, x[k + 12], S41, 0x655b59c3);
    d = II(d, a, b, c, x[k + 3], S42, 0x8f0ccc92);
    c = II(c, d, a, b, x[k + 10], S43, 0xffeff47d);
    b = II(b, c, d, a, x[k + 1], S44, 0x85845dd1);
    a = II(a, b, c, d, x[k + 8], S41, 0x6fa87e4f);
    d = II(d, a, b, c, x[k + 15], S42, 0xfe2ce6e0);
    c = II(c, d, a, b, x[k + 6], S43, 0xa3014314);
    b = II(b, c, d, a, x[k + 13], S44, 0x4e0811a1);
    a = II(a, b, c, d, x[k + 4], S41, 0xf7537e82);
    d = II(d, a, b, c, x[k + 11], S42, 0xbd3af235);
    c = II(c, d, a, b, x[k + 2], S43, 0x2ad7d2bb);
    b = II(b, c, d, a, x[k + 9], S44, 0xeb86d391);
    a = AddUnsigned(a, AA);
    b = AddUnsigned(b, BB);
    c = AddUnsigned(c, CC);
    d = AddUnsigned(d, DD);
  }

  var temp = WordToHex(a) + WordToHex(b) + WordToHex(c) + WordToHex(d);

  return temp.toLowerCase();
}
