import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL } from '../services/constants';
import { Meta, Title } from '@angular/platform-browser';

import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
} from '@angular/forms';

declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
declare let initRemoveClass: any;
declare let initAboutTabs: any;
declare let initToggle: any;
declare let initquizaq: any;
declare let initPopup: any;
@Component({
  selector: 'quiz-page',
  templateUrl: './quiz.component.html',
  styles: [
    `
      .has-error {
        border: 1px solid red !important;
      }
      .show {
        display: block;
      }
      .hide {
        display: none;
      }
      .CalcLoader {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto auto;
        z-index: 999;
        width: 100%;
        height: 100%;
        background: rgba(51, 51, 51, 0.2);
      }
      .CalcLoader img {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto auto;
        z-index: 999;
      }
      .qzqlist li label i {
        display: none;
      }
      #quzfnextActive {
        display: inline-block;
        font-size: 18px;
        border-radius: 20px;
        background: #3d2979;
        margin: 15px 0px 0px 0px;
        text-align: center;
        line-height: 37px;
        width: 129px;
        font-family: 'dinrg';
        color: #fff;
      }

      #quzfnextActive:hover {
        background: #fff;
        color: #3d2979;
        border: 1px solid #3d2979;
      }
    `,
  ],
})
export class QuizPage implements OnDestroy, OnInit, AfterViewInit {
  public group_sections: any = [];
  public title: any = '';
  public subtitle: any = '';
  public description: any = '';
  public imgsrc: any = '';
  public imgBasePath = '';
  public hidden = 'hidden';
  public logo = 'logo';
  public specialityName: string;
  public specialityMasters: any[] = [];
  public selectedQuizCategory: any = 0;
  public quizHeading: string;
  public quizDescription: string;
  public quizGroup: any = [];
  public quizList: any = [];
  public selectedOption: any = [];
  public selectedScores: any = [];
  public quizMsg: string;
  quizForm: any;
  constructor(
    private api: ApiService,
    public formBuilder: FormBuilder,
    public metaTitle: Title,
    public meta: Meta
  ) {
    if (this.api.isBrowser) {
      require('../../assets/js/jquery.min.js');
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
    }
  }

  //  public services:any[] = [];
  //  public brief_facilities:any[] = [];

  public bread_crumb_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Other', link: '', class: '' },
    { label: 'Quiz', link: '', class: '' },
  ];

  public links: any = [];
  public activePage: string = 'quiz';

  @ViewChildren('quiz') things: QueryList<any>;
  ngOnInit() {
    this.api.getPageDescription('quiz').subscribe(
      (data) => {
        (this.title = data.page_descriptions[0].title),
          (this.subtitle = data.page_descriptions[0].subtitle),
          (this.description = data.page_descriptions[0].description),
          (this.imgsrc = data.page_descriptions[0].image),
          (this.imgBasePath = IMG_BASE_URL);
        if (data) {
          //console.log(data);
          this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
          this.meta.updateTag({
            name: 'keywords',
            content: data.page_descriptions[0].meta_keyword,
          });
          this.meta.updateTag({
            name: 'description',
            content: data.page_descriptions[0].meta_description,
          });
          this.meta.addTags([
            {
              property: 'og:title',
              content: data.page_descriptions[0].meta_title,
            },
            {
              property: 'og:description',
              content: data.page_descriptions[0].meta_description,
            },
            {
              property: 'og:keywords',
              content: data.page_descriptions[0].meta_keyword,
            },
          ]);
        }
      },
      (error) => {
        console.log(error);
      }
    );
    this.api
      .getOtherSubFooterDetails()
      .map((res) => res)
      .subscribe((data) => {
        this.links = data;
      });
    this.api
      .getSpecialityMasters()
      .map((res) => res)
      .subscribe((res) => {
        //	console.log(res);
        this.specialityMasters = res.speciality_masters;
      });
    this.quizForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      phone: [
        '',
        Validators.compose([Validators.required, Validators.minLength(10)]),
      ],
      city: ['', Validators.required],
      quiz_group_id: [''],
    });
  }
  clickInput(event) {
    $('.quizform .quizqst.active .qzqlist li input:radio').each(function() {
      $(this).click(function (){
        if ($(this).parent('span').hasClass('selected')) {
          $('#quzfnext').show();
          $('#quzfnextActive').hide();
        }
      });
    });
  }
  checkSelecteOption(event) {
    $('.quizform .quizqst.active .qzqlist').each(function () {
      console.log('hello');
      if ($(this).find('span').hasClass('selected')) {
        $('#quzfnext').show();
        $('#quzfnextActive').hide();
      } else {
        $('#quzfnext').hide();
        $('#quzfnextActive').show();
        alert('Please Select Option!!');
      }
    });
  }
  selectedQuiz(obj) {
    $('#quzfnext').hide();
    $('#quzfnextActive').show();
    if (obj == 'push') {
      var selected = $('.selected').children('input').val();
      this.selectedOption.push(selected);

      var selectedScore = $('.selected').parents('label').find('i').text();
      this.selectedScores.push(selectedScore);

      if (this.selectedOption.length == 10) {
        $('#quzfnext').hide();
        $('#quzfnextActive').hide();
      }
    } else {
      this.selectedOption.pop(selected);
      this.selectedScores.pop(selectedScore);
    }
  }

  startQuiz() {
    $('.startquiz').parents('.quizwrp').find('.quzsinfo').slideUp();
    $('.startquiz').parents('.quizwrp').find('.startquizwrp').slideDown();
    initquizaq('#quizform > li', '#quzfprev', '#quzfnext');
  }
  public total = 0;
  submitQuizForm() {
    this.selectedScores.forEach((element) => {
      this.total = this.total + parseFloat(element);
    });
    this.total = Math.round((this.total / 6.5) * 100);
    //console.log(this.total);
    if (this.quizForm.value) {
      $('.CalcLoader').css('display', 'block');
      let quizFormDetail = {
        questions: Array.from(new Set(this.selectedOption)),
      };

      quizFormDetail['name'] = this.quizForm.value['name'];
      quizFormDetail['email'] = this.quizForm.value['email'];
      quizFormDetail['phone'] = this.quizForm.value['phone'];
      quizFormDetail['city'] = this.quizForm.value['city'];
      quizFormDetail['quiz_group_id'] = this.quizForm.value['quiz_group_id'];
      //console.log('specialityName:', this.specialityName);
      this.quizMsg = '';
      if (this.specialityName == 'Child Care') {
        if (this.total > 0 && this.total <= 40) {
          quizFormDetail['quiz_score_msg'] =
            "Your child is healthy & growing well. However, it’s a good practice to visit the pediatrician once every six months to keep a check of your child's growth!";
          this.quizMsg =
            "Your child is healthy & growing well. However, it’s a good practice to visit the pediatrician once every six months to keep a check of your child's growth!";
        } else if (this.total > 40 && this.total < 65) {
          quizFormDetail['quiz_score_msg'] =
            'Your child is fairly healthy with minor health issues and therefore it is advised to consult a pediatrician to ensure he continues to grow well and has no health concerns.';
          this.quizMsg =
            'Your child is fairly healthy with minor health issues and therefore it is advised to consult a pediatrician to ensure he continues to grow well and has no health concerns.';
        } else if (this.total > 64) {
          quizFormDetail['quiz_score_msg'] =
            'Your child may be suffering from growth challenges or other minor heath issues. Speak to your pediatrician today to ensure that the issues can be diagnosed at an early stage.';
          this.quizMsg =
            'Your child may be suffering from growth challenges or other minor heath issues. Speak to your pediatrician today to ensure that the issues can be diagnosed at an early stage.';
        } else {
          quizFormDetail['quiz_score_msg'] = 'Thank You for playing the Quiz';
        }
      } else if (this.specialityName == 'Women Health') {
        if (this.total > 0 && this.total <= 40) {
          quizFormDetail['quiz_score_msg'] = 'Thank You for playing the Quiz';
          this.quizMsg = 'Thank You for playing the Quiz';
        } else if (this.total > 40 && this.total < 65) {
          quizFormDetail['quiz_score_msg'] = 'Thank You for playing the Quiz';
          this.quizMsg = 'Thank You for playing the Quiz';
        } else if (this.total > 64) {
          quizFormDetail['quiz_score_msg'] = 'Thank You for playing the Quiz';
          this.quizMsg = 'Thank You for playing the Quiz';
        } else {
          quizFormDetail['quiz_score_msg'] = 'Thank You for playing the Quiz';
        }
      } else if (this.specialityName == 'Fertility Care') {
        if (this.total > 0 && this.total <= 40) {
          quizFormDetail['quiz_score_msg'] =
            "You're good. Continue to eat healthy & have a healthy lifestyle.";
          this.quizMsg =
            "You're good. Continue to eat healthy & have a healthy lifestyle.";
        } else if (this.total > 40 && this.total < 65) {
          quizFormDetail['quiz_score_msg'] =
            "You're lifestyle can potentially cause infertility. Change in lifestyle can help mitigate any future risks. Taking the advise of a doctor is advised";
          this.quizMsg =
            "You're lifestyle can potentially cause infertility. Change in lifestyle can help mitigate any future risks. Taking the advise of a doctor is advised";
        } else if (this.total > 64) {
          quizFormDetail['quiz_score_msg'] =
            "You're at the high risk of infertility. Immediate changes in lifestyle coupled with expert doctor opinion is a must.";
          this.quizMsg =
            "You're at the high risk of infertility. Immediate changes in lifestyle coupled with expert doctor opinion is a must.";
        } else {
          quizFormDetail['quiz_score_msg'] = 'Thank You for playing the Quiz';
        }
      }

      //console.log(quizFormDetail);

      this.api.postQuiz(quizFormDetail).subscribe((res) => {
        $('.CalcLoader').css('display', 'none');
        //initPopup(126);
        if (this.total > 0 && this.total <= 40) {
          initPopup(140);
        } else if (this.total > 40 && this.total < 65) {
          initPopup(141);
        } else if (this.total > 64) {
          initPopup(165);
        } else {
          initPopup(120);
        }
        this.total = 0;
        this.selectedScores = [];
        this.quizForm.reset();
        this.selectedQuizCategory = 0;
      });
    }
  }
  isNumber(evt) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
      initRemoveClass();
      initToggle();
    }

    this.things.changes.subscribe((t) => {
      initAboutTabs();
      initToggle();
    });
    $('.capopup .capopup-close').each((element) => {
      $(this).click(function (element) {
        location.reload();
      });
    });
  }
  changeQuiz() {
    this.quizForm.reset();
    this.quizGroup = [];
    this.quizList = [];
    this.selectedOption = [];
    this.api.getQuiz(this.selectedQuizCategory).subscribe((res) => {
      if (res) {
        //console.log('speciality', res.speciality_masters[0].speciality)
        this.quizHeading = res.speciality_masters[0].speciality
          ? res.speciality_masters[0].speciality
          : '';
        this.quizGroup = res.speciality_masters[0].groups
          ? res.speciality_masters[0].groups
          : '';
        this.specialityName = res.speciality_masters[0].speciality
          ? res.speciality_masters[0].speciality
          : '';
        if (this.quizGroup) {
          this.quizDescription = this.quizGroup[0]
            ? this.quizGroup[0].description
            : '';
          this.quizList = this.quizGroup[0] ? this.quizGroup[0].questions : '';
          this.quizForm.controls['quiz_group_id'].setValue(
            res.speciality_masters[0].id
          );
        }
      }
    });
  }
  ngOnDestroy() {}
}
``;