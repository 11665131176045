import { Inject, Component, OnDestroy, OnInit, AfterViewInit, PLATFORM_ID, ViewChildren, QueryList, HostListener } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from "../services/api.service";
import { IMG_BASE_URL } from "../services/constants";
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
declare let $: any;
declare let initHome: any;
declare let initPopup: any;
declare let openZopim:any;
@Component({
	selector: 'footer-subscription',
	templateUrl: './footer-subscription.component.html',
	styles: [`.data p {
    margin-right: 14% !important;
  }
  .vediobg.active{
    z-index:0 !important;
  }
  .knowmore{
    margin-left:20px;
    width:100px !important; 
  }
  .knowmore img{
    display:inline-block;
  }
  .has-error{
    border:1px solid red !important;
  }
  `]
})

export class Footersubscriptioncomponent implements OnDestroy, OnInit, AfterViewInit {

	public subscriptionForm: any = [];
	public popupTitle: string;
	public popupMsg: string;
	constructor(private api: ApiService, public formBuilder: FormBuilder) {
		if (this.api.isBrowser) {
			window['jQuery'] = $;
			window['$'] = $;
		}
		this.validateSubscriptionForm();
	}
	ngOnInit() {

	}
	openChat(){
		openZopim();
	}
	ngOnDestroy() { }
	ngAfterViewInit() { }
	validateSubscriptionForm() {
		this.subscriptionForm = this.formBuilder.group({
			"email": ['', Validators.required]
		});
	}
	SubmitSubscriptionForm() {
		//console.log(this.subscriptionForm.value);
		this.api.postSubmitSubscription(this.subscriptionForm.value).subscribe(res => {
			//console.log(res);
			if (res.status_code == 200) {
				this.subscriptionForm.reset();
				// alert('thankyou');
				this.popupTitle = "Thank you";
				this.popupMsg = '';
			} else if (res.status_code == 406) {
				// alert('Entered email address exist in system');
				this.popupTitle = 'Error';
				this.popupMsg = 'Entered email address exist in system';
			} else {
				//alert('enter valid email address');
				this.popupTitle = 'Error';
				this.popupMsg = 'Enter valid email address';
			}
			initPopup(1);
		}, err => {
			console.log('error', err);
		});
	}
}
