<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>
    <div id="topsearchwrp">
        <div class="inputbox">
            <input class="searchInput" type="text" name="txtSearch" id="txtSearch" placeholder="type here..." />
            <input type="submit" name="submit" class="ssubmitbtn" value="" />
        </div>
    </div>
    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <section class="page pageheader">
        <div class="container">
            <div class="col-xs-12 col-sm-12 col-lg-12">
                <div class="ptitle">
                    <h1 class="underline">Sitemap</h1>
                </div>
            </div>
        </div>
    </section>
    <section class="aboutsectin2 wcuswrp">
        <!--
        <div class="container">
            <div class="row SiteMap">
                <div class="col-md-12">
                    <ng-template ngFor let-sidebar [ngForOf]="sideBarList" let-i="index">
                        <div class="col-xs-12 col-sm-4 col-lg-3" *ngIf="sidebar.type === 'col-1'">                        
                            <a href="javascript:void(0)" class="Tital">{{sidebar.title}}</a>
                            <ul>                                        
                                <ng-template ngFor let-links [ngForOf]="sidebar.links">
                                        <li *ngIf="links.status == 1"><a [href]="links.link"  [target]="links.target">{{links.title}}</a>
                                            <ul class="submenuSitemap1" *ngIf="links.title == 'Our Specialities'">
                                                <ng-template ngFor let-women [ngForOf]="womenSpec.our_speciality_features">
                                                    <li><a href="/women-care/speciality/{{women.slug}}">{{women.title}}</a></li>
                                                </ng-template>
                                            </ul>
                                            <ul class="submenuSitemap1" *ngIf="links.title == 'Our Centres / Specialities'">
                                                <ng-template ngFor let-fertility [ngForOf]="fertilitySpec.our_speciality_features">
                                                    <li><a href="/fertility-care/speciality/{{fertility.slug}}">{{fertility.title}}</a></li>
                                                </ng-template>
                                            </ul>

                                            <ul class="submenuSitemap1" *ngIf="links.link == '/child-care/specialities'">
                                                <ng-template ngFor let-child [ngForOf]="childSpec.our_speciality_features">
                                                    <li><a href="/child-care/speciality/{{child.slug}}">{{child.title}}</a></li>
                                                </ng-template>
                                            </ul>
                                        </li>
                                </ng-template>
                            </ul>
                        </div>
                        <div class="col-xs-12 col-sm-4 col-lg-3" *ngIf="sidebar.type === 'col-2'">                        
                            <a href="javascript:void(0)" class="Tital">{{sidebar.title}}</a>                            
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    -->
        <div class="container">
            <div class="row SiteMap">
                <div class="col-md-12"> <a href="/" class="Tital">Home</a> </div>
                <div class="col-xs-12 col-sm-4 col-lg-3"> <a href="javascript:void(0)" class="Tital">Women’s Health</a>
                    <ul>
                        <li><a href="women-care/about">About Women's Care</a></li>
                        <li><a href="women-care/doctors">Doctors</a></li>
                        <li><a href="women-care/specialities">Our Specialities</a> </li>
                        <ul class="submenuSitemap1">
                            <ng-template ngFor let-women [ngForOf]="womenSpec.our_speciality_features">
                                <li><a href="/women-care/speciality/{{women.slug}}">{{women.title}}</a></li>
                            </ng-template>
                            
                        </ul>
                    </ul>
                    <a href="women-care/centres" class="Tital">Our Centres</a>
                    <ul>
                        <li><a href="women-care/about">About Women's Care</a></li>
                        <ul class="submenuSitemap1">
                            <li><a href="/our-centre-details/hyderabad">Hyderabad</a></li>
                            <ul class="submenuSitemap2">
                                <li><a href="/women-care/centre/banjara-hills">Banjara Hills</a></li>
                                <li><a href="/women-care/centre/vikrampuri-colony">Vikrampuri</a></li>
                                <li><a href="/women-care/centre/madhapur">Madhapur</a></li>
                                <li><a href="/women-care/centre/serilingampally-mandal">Kondapur</a></li>
                                <li><a href="/women-care/centre/hydernagar">Hydernagar</a></li>
                                <li><a href="/women-care/centre/lb-nagar">LB Nagar</a></li>
                            </ul>
                            <li><a href="/our-centre-details/bengaluru">Bengaluru</a></li>
                            <ul class="submenuSitemap2">
                                <li><a href="/women-care/centre/bannerghatta">Bannerghatta Road</a></li>
                                <li><a href="/women-care/centre/marathahalli">Marathahalli</a></li>
                            </ul>
                            <li><a href="/our-centre-details/vijayawada">Vijayawada</a></li>
                            <ul class="submenuSitemap2">
                                <li><a href="/women-care/centre/governorpet">Governorpet</a></li>
                                <li><a href="/women-care/centre/currency-nagar">Currency Nagar</a></li>
                            </ul>

                        </ul>
                        <li><a href="/women-care/testimonials">Testimonials</a></li>
                        <li><a href="/women-care/feedback">Share Feedback</a></li>
                        <li><a href="/women-care/book-an-appointment">Book An Appointment</a></li>
                    </ul>
                    <!-- <a href="/women-care/doctors" class="Tital">Doctors</a> 
					<a href="/women-care/book-an-appointment" class="Tital">Book an Appointment</a> 
					<a href="/women-care/testimonials" class="Tital">Testimonials </a>					
					<a href="/women-care/centres" class="Tital">Our Centres</a>
					<ul>
						<li><a href="#">About Women's Care</a></li>
						<li><a href="#">Doctors</a></li>
						<li><a href="#">Our Specialities</a> </li>
						<ul class="submenuSitemap1">
							<li><a href="#">Hyderabad</a></li>
							<ul class="submenuSitemap2">
								<li><a href="#">Banjara Hills</a></li>
								<li><a href="#">Madhapur</a></li>
								<li><a href="#">Hydernagar</a></li>
								<li><a href="#">LB Nagar</a></li>
							</ul>
							<li><a href="#">Bengaluru</a></li>
							<ul class="submenuSitemap2">
								<li><a href="#">Bannerghatta Road</a></li>
								<li><a href="#">Marathahalli</a></li>
							</ul>
							<li><a href="#">Vijayawada</a></li>
							<ul class="submenuSitemap2">
								<li><a href="#">Governorpet</a></li>
								<li><a href="#">Currency Nagar</a></li>
							</ul>
							<li><a href="#">Secunderabad</a></li>
							<ul class="submenuSitemap2">
								<li><a href="#">Vikrampuri Colony</a></li>
							</ul>
							<li><a href="#">Kondapur</a></li>
							<ul class="submenuSitemap2">
								<li><a href="#">Serilingampally Mandal</a></li>
							</ul>
							<li><a href="#">Delhi</a></li>
							<ul class="submenuSitemap2">
								<li><a href="#">Lorem Ipsum</a></li>
							</ul>
						</ul>
					</ul> -->
                </div>
                <div class="col-xs-12 col-sm-4 col-lg-3"> <a href="javascript:void(0)" class="Tital">Fertility Care</a>
                    <ul>

                        <li><a href="/fertility-care/about">About Fertility Care</a></li>
                        <li><a href="/fertility-care/doctors">Doctors</a></li>
                        <li><a href="/fertility-care/specialities">Our Specialities</a> </li>
                        <ul class="submenuSitemap1">
                            <ng-template ngFor let-fertility [ngForOf]="fertilitySpec.our_speciality_features">
                                <li><a href="/fertility-care/speciality/{{fertility.slug}}">{{fertility.title}}</a></li>
                            </ng-template>
                        </ul>
                        <a href="fertility-care/centres" class="Tital">Our Centres</a>
                        <ul>
                            <li><a href="fertility-care/about">About Women's Care</a></li>
                            <ul class="submenuSitemap1">
                                <li><a href="/our-centre-details/hyderabad">Hyderabad</a></li>
                                <ul class="submenuSitemap2">
                                    <li><a href="/fertility-care/centre/banjara-hills">Banjara Hills</a></li>
                                    <li><a href="/fertility-care/centre/vikrampuri-colony">Vikrampuri</a></li>
                                    <li><a href="/fertility-care/centre/madhapur">Madhapur</a></li>
                                    <li><a href="/fertility-care/centre/serilingampally-mandal">Kondapur</a></li>
                                    <li><a href="/fertility-care/centre/hydernagar">Hydernagar</a></li>
                                    <li><a href="/fertility-care/centre/lb-nagar">LB Nagar</a></li>
                                </ul>
                                <li><a href="/our-centre-details/bengaluru">Bengaluru</a></li>
                                <ul class="submenuSitemap2">
                                    <li><a href="/fertility-care/centre/bannerghatta">Bannerghatta Road</a></li>
                                    <li><a href="/fertility-care/centre/marathahalli">Marathahalli</a></li>
                                </ul>
                                <li><a href="/our-centre-details/vijayawada">Vijayawada</a></li>
                                <ul class="submenuSitemap2">
                                    <li><a href="/fertility-care/centre/governorpet">Governorpet</a></li>
                                    <li><a href="/fertility-care/centre/currency-nagar">Currency Nagar</a></li>
                                </ul>
                                <li><a href="/fertility-care/testimonials">Testimonials</a></li>
                                <li><a href="/fertility-care/feedback">Share Feedback</a></li>
                                <li><a href="/fertility-care/book-an-appointment">Book An Appointment</a></li>
                            </ul>
                        </ul>
                        <li><a href="/fertility-care/testimonials">Testimonials</a></li>
                        <li><a href="/fertility-care/feedback">Share Feedback</a></li>
                        <li><a href="/fertility-care/book-an-appointment">Book An Appointment</a></li>
                    </ul>
                </div>
                <div class="col-xs-12 col-sm-4 col-lg-3"> <a href="javascript:void(0)" class="Tital">Children’s Health</a>
                    <ul>
                        <li><a href="/child-care/about">About Child Care</a></li>
                        <li><a href="/child-care/child-safety">Child Safety</a></li>
                        <li><a href="/child-care/doctors">Doctors</a></li>
                        <li><a href="/child-care/specialities">Our Specialities</a> </li>
                        <ul class="submenuSitemap1">
                            <ng-template ngFor let-child [ngForOf]="childSpec.our_speciality_features">
                                <li><a href="/child-care/speciality/{{child.slug}}">{{child.title}}</a></li>
                            </ng-template>
                        </ul>
                        <a href="/child-care/centres" class="Tital">Our Centres</a>
                        <ul>
                            <li><a href="/child-care/about">About Women's Care</a></li>
                            <ul class="submenuSitemap1">
                                <li><a href="/our-centre-details/hyderabad">Hyderabad</a></li>
                                <ul class="submenuSitemap2">
                                    <li><a href="/child-care/centre/banjara-hills">Banjara Hills</a></li>
                                    <li><a href="/child-care/centre/vikrampuri-colony">Vikrampuri</a></li>
                                    <li><a href="/child-care/centre/madhapur">Madhapur</a></li>
                                    <li><a href="/child-care/centre/serilingampally-mandal">Kondapur</a></li>
                                    <li><a href="/child-care/centre/hydernagar">Hydernagar</a></li>
                                    <li><a href="/child-care/centre/lb-nagar">LB Nagar</a></li>
                                </ul>
                                <li><a href="/our-centre-details/bengaluru">Bengaluru</a></li>
                                <ul class="submenuSitemap2">
                                    <li><a href="/child-care/centre/bannerghatta">Bannerghatta Road</a></li>
                                    <li><a href="/child-care/centre/marathahalli">Marathahalli</a></li>
                                </ul>
                                <li><a href="/our-centre-details/vijayawada">Vijayawada</a></li>
                                <ul class="submenuSitemap2">
                                    <li><a href="/child-care/centre/governorpet">Governorpet</a></li>
                                    <li><a href="/child-care/centre/currency-nagar">Currency Nagar</a></li>
                                </ul>
                                <li><a href="/child-care/testimonials">Testimonials</a></li>
                                <li><a href="/child-care/feedback">Share Feedback</a></li>
                                <li><a href="/child-care/book-an-appointment">Book An Appointment</a></li>
                            </ul>
                        </ul>
                        <li> <a href="/child-care/vaccination">Vaccination</a></li>
                        <li><a href="/child-care/testimonials">Testimonials</a></li>
                        <li><a href="/child-care/feedback">Share Feedback</a></li>
                        <li><a href="/child-care/book-an-appointment">Book An Appointment</a></li>
                    </ul>
                </div>
                <div class="col-xs-12 col-sm-4 col-lg-3"> <a href="javascript:void(0)" class="Tital">About us</a>
                    <ul> 
                        <li><a href="about-us/who-we-are">Who We are </a></li>
                        <li><a href="about-us/what-we-do">What We do </a></li>
                        <li><a href="about-us/why-chooseus">Why Choose Us? </a></li>
                        <li><a href="about-us/cutting-edge-medical-solutions">Cutting Edge Medical Solutions </a></li>
                        <li><a href="about-us/mds-message">MD Message</a></li>
                        <li><a href="about-us/management">Management</a></li>
                        <li><a href="about-us/history">History</a></li>
                        <li><a href="about-us/awards">Awards</a></li>
                        <li><a href="about-us/media">Media</a></li>
                        <ul class="submenuSitemap1">
                            <li><a href="/about-us/media/media-press-releases">Press Releases</a></li>
                            <li><a href="/about-us/media/media-press-clips">Press Clips</a></li>
                            <li><a href="javascript:void(0)">Media Contact & Policy</a></li>
                            <li><a href="/assets/pdf/CSR-Policy-Rainbow-Hospitals.pdf">CSR Policy - Rainbow Hospitals</a></li>
                        </ul>

                        <li><a href="about-us/news">News </a></li>
                        <li><a href="about-us/events">Events</a></li>
                        <li><a href="https://ehubnext.myadrenalin.com/candidate/LoginPage.aspx?obj=0qKjcPeCekWVUp%2f8Wz3cmM5%2fF4SZ%2bT68" target="_blank" class="CareersBtnSitemap">Careers</a></li>
                        <li><a href="about-us/channel">Channel </a></li>
                        <li><a href="about-us/quality-accreditation">QUALITY & ACCREDITATION </a></li>
                        <li><a href="about/contact-us">Contact Us</a></li>
                    </ul>
                    <a href="javascript:void(0)" class="Tital">Other</a>
                    <ul>
                        <li><a href="other/faqs">Faqs</a></li>
                        <li><a href="other/insurance-empanelment">Insurance</a></li>
                        <li><a href="other/terms-conditions">T&amp;C and Polices</a></li>
                        <!--<li><a href="other/health-card">Health Card</a></li>-->
                        <li><a href="other/academic-programs">Academics</a></li>
                        <li><a href="other/inpatient-feedback">Inpatient Feedback</a></li>
                        <li><a href="other/quiz">Quiz</a></li>
                        <li><a href="/other/knowledge-centre">Knowledge Centre</a> </li>
                        <!-- <li><a href="javascript:void()">Login/ Register</a></li> -->
                        <!-- <li><a href="javascript:void()">Virtual tours</a></li> -->
                        <li><a href="other/packages-plans">Packages/ Plans</a></li>
                        <li><a href="other/international-patients">International Patients</a></li>
                        <li><a href="other/app">App</a></li>
                        <li><a href="javascript:void()">Newsletter</a></li>
                        <li><a href="javascript:void()">Associates</a></li>
                    </ul>
                    <a href="/other/terms-conditions" class="Tital">TERMS OF CONDITIONS</a>
                    <a href="javascript:void(0)" class="Tital">CALCULATOR</a>
                    <ul>
                        <li><a href="/other/ovulation-calculator">Ovulation Calculator </a></li>
                        <li><a href="/other/contraction-calculator">Contraction Calculator </a></li>
                        <li><a href="/other/bmi-calculator">BMI Calculator </a></li>
                        <li><a href="/other/kick-counter-calculator">Kick Counter</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav *ngIf="fdisShow"></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>