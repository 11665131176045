import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL } from '../services/constants';
import { Meta, Title } from '@angular/platform-browser';
declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
declare let initAboutTabs: any;

@Component({
  selector: 'what-we-do',
  templateUrl: './what-we-do.component.html',
})
export class WhatWeDoComponent implements OnDestroy, OnInit, AfterViewInit {
  constructor(
    private api: ApiService,
    public metaTitle: Title,
    public meta: Meta
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
    }
  }

  public services: any[] = [];
  public title: any = '';
  public subtitle: any = '';
  public description: any = '';
  public imgsrc: any = '';
  public brief_facilities: any[] = [];
  public imgBasePath = '';
  public hidden = 'hidden';
  public logo = 'logo';
  public bread_crumb_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'About Us', link: '/about-us', class: '' },
    { label: 'What we do', link: '', class: '' },
  ];

  public links: any = [];
  public activePage: string = 'what-we-do';

  @ViewChildren('abouttabs') things: QueryList<any>;

  ngOnInit() {
    this.api.getPageDescription('what-we-do').subscribe(
      (data) => {
        this.title = data.page_descriptions[0].title;
        this.subtitle = data.page_descriptions[0].subtitle;
        this.description = data.page_descriptions[0].description;
        this.imgsrc = data.page_descriptions[0].image;
        this.imgBasePath = IMG_BASE_URL;
        if (data) {
          //console.log(data);
          this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
          this.meta.updateTag({
            name: 'keywords',
            content: data.page_descriptions[0].meta_keyword,
          });
          this.meta.updateTag({
            name: 'description',
            content: data.page_descriptions[0].meta_description,
          });
          this.meta.addTags([
            {
              property: 'og:title',
              content: data.page_descriptions[0].meta_title,
            },
            {
              property: 'og:description',
              content: data.page_descriptions[0].meta_description,
            },
            {
              property: 'og:keywords',
              content: data.page_descriptions[0].meta_keyword,
            },
          ]);
        }
      },
      (error) => {
        console.log(error);
      }
    );
    this.api
      .getAboutSubFooterDetails()
      .map((res) => res)
      .subscribe((data) => {
        this.links = data;
      });
    this.api.getHealthCareServices('what-we-do').subscribe(
      (data) => {
        let knowmore_link: any = '';
        const column = [];
        for (let service of data.healthcare_services) {
          switch (service.title) {
            case 'Child care':
              service['knowmore_link'] = 'child-care/about';
              break;
            case 'Women Care':
              service['knowmore_link'] = 'women-care/about';
              break;
            case 'Fertility Care':
              service['knowmore_link'] = 'fertility-care/about';
              break;
          }
          //console.log(service);
          this.services.push(service);
        }
      },
      (error) => {
        console.log(error);
      }
    );

    this.api.getBriefFacilities('what-we-do').subscribe(
      (data) => {
        this.brief_facilities = data.brief_facilities;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
      setTimeout(() => {
        initAboutTabs();
      }, 2000);
    }

    this.things.changes.subscribe((t) => {
      // this.ngForRendred();
      //initAboutTabs();
    });
  }

  ngOnDestroy() {}
}
