<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>

    <section class="page wtwedo">
        <div class="row">
            <div class="col-md-5 col-xs-5 col-lg-4">
                <div class="circuleframe">
                    <img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" />
                </div>
                <!-- End Circle -->
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7">
                <div class="pagetitle">
                    <h1 class="tgradient">{{title}} </h1>
                    <div class="underline">
                        <img src="assets/images/underline2.png" alt="" />
                    </div>
                    <h3>{{subtitle}}</h3>
                </div>
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7 mcontentview">
                <p [innerHTML]="description"></p>
            </div>
        </div>

    </section>
    <section class="aboutsectin2 awardswapper">
        <div class="container">
            <div class="row nsg_text">
                <div class="col-xs-12 col-lg-12">
                    <h2>Complications of UTI</h2>
                    <ul>
                        <li>&nbsp;Nearly all urinary tract infections are mild, treatable, and have no long-term consequences. Serious physical complications can occur in some cases, especially if untreated.</li>
                        <li>Recurrent UTIs may cause scarring in the kidneys, which over time can lead to hypertension and eventual kidney failure.</li>
                        <li>Recurrent UTIs may cause septicemia and spread to bloodstream.</li>
                        <li>Recurrent UTIs may increase the risk for urge incontinence after menopause. People with urge incontinence experience leakage and the need to urinate frequently.</li>
                        <li>Kidney stones can be caused by urinary tract infections. Those known as struvite stones are almost always caused by urinary tract infections due to bacteria that secrete certain enzymes.</li>
                    </ul>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>Complications of Urinary Tract Infections in Pregnancy</h2>
                    <p>Urinary tract infections during pregnancy pose particular risks for both mother and child: premature delivery, impaired growth of baby, and later chronic kidney disease.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>Reasons for Recurrent UTI</h2>
                    <p>The reasons could be any of these: structural problems with the urinary tract, high bacteria levels and weak immunity.</p>
                    <p>Sometimes there are structural problems in the urinary tract causing a woman to be more prone to infections. Investigations such as urine examination, culture of urine to check for bacterial growth, ultrasound, blood sugar testing,
                        kidney function tests and cystoscopy are required to find if there is a structural abnormality.</p>
                    <p>If there is no underlying structural abnormality, then therapeutic option would be eitherstrengthening immunity or bydecreasing the number of bacteria in the bladder.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>Reducing UTI</h2>
                    <p><strong>Treat infections with antibiotics: </strong>Treat any infections quickly and completely to protect your bladder. Over time, bacteria that have gained entry into the bladder release toxins that damage mucosa, the lining of the
                        bladder. The mucosa acts as an entry barrier and if this is damaged, the bacteria can gain access to the deeper layers and cause severe cystitis, which can cause irreversible scarring.</p>
                    <p>UTI should be treated with antibiotics prescribed by your doctor after a urine culture and sensitivity test. To ensure that the infection has been cured completely, another urine culture and sensitivity test must be done after the
                        course of antibiotics. Some women may require more than one course of antibiotic to eliminate the bacteria.</p>
                    <p><strong>Reduce vaginal dryness: </strong>If there is significant vaginal dryness, you are at a greater risk of UTI. The most common reason this is menopause, when the levelsof the hormone estrogen become very low. Talk to your doctor
                        who may recommend various options such as vaginal lubricants, vaginal moisturizers or vaginal estrogen therapy, based on your health record. Estrogen levels also tend to vary in young women during the menstrual cycle. Women are
                        more likely to develop an infection just before or just after a period and at these times, extra care is needed.</p>
                    <p><strong>Boost your immune system</strong></p>
                    <p>To prevent recurrent UTI, following are some positive things you can do:</p>
                    <ul>
                        <li>Increase consumption of fruit and vegetables, which are rich in anti-oxidants, vitamins and fiber.</li>
                        <li>Drink lot of water and fluids.</li>
                        <li>Do moderate exercise for 30 minutes at a time, three times a week.</li>
                        <li>If you are overweight, lose weight.</li>
                    </ul>
                    <p><strong>Decrease bacterial numbers</strong></p>
                    <p><strong>Drink enough water</strong>: Urinating regularly is a natural defense to bacterial multiplication. This is so because when bacteria are flushed out regularly, these do not reach the number required to cause UTI. If you are
                        drinking enough water and there is good flushing of the bladder, the urine would be white and clear.</p>
                    <p>To ensure good flushing of the bladder, you need not drink gallons of water; rather drink one and half to two liters in a twenty-four hour period. Ensure that you are drinking evenly throughout the day. Drink at least one glass every
                        hour that you are awake and increase that to about two glasses at meal times.</p>
                    <p><strong>Empty your bladder after sex: </strong>Bacteria can enter the bladder during an intercourse. Flushing the bacteria by urinating after sex can reduce the risk of UTI.</p>
                    <p><strong>Avoid spermicides: </strong>Spermicides used with condoms can alter the bacterial types in the vagina. These tend to destroy the friendly bacteria, and allow disease causing bacteria to grow. Use the ones without spermicide
                        to fend off the risk of infections.</p>
                    <p><strong>Avoid constipation: </strong>Due to constipation, the number of bacteria deposited around the area between the anus and vagina increases. You can reduce constipation by increasing your fiber intake by eating whole fruits, greens
                        and salads. Also, drink plenty of water to reduce constipation.</p>
                    <p><strong>Avoid irritants: </strong>Do not use strong soaps, antiseptic creams, sprays, or powders in the genital area.</p>
                    <p><strong>Genital Hygiene:</strong>After a bowel movement, use front to back strokes while wiping to prevent germs in the stool from getting near your urethra.Wear all-cotton underwear and change underwear twice daily.</p>
                    <p><strong>Prophylactic antibiotics: </strong>Antibiotics are used to decrease bacterial numbers and prevent infection. This should be done only by advice of a doctor.</p>
                </div>
            </div>
        </div>
    </section>
    <ng-template [ngIf]="ins_links.length > 0">
        <footer-nav-mini [links]="ins_links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>