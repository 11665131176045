<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <section class="page pageheader aboutushead">
        <div class="row">
            <div class="container">
                <div class="col-xs-12 col-sm-7 col-lg-7 abannimgwrp">
                    <div class="aboutbannimg"><img src="assets/images/banner-BODprofile.png" class="img-responsive" alt="Dr. Ramesh Kancharla Chairman and Managing Director" title="Dr. Ramesh Kancharla Chairman and Managing Director" /></div>
                </div>
                <div class="col-xs-12 col-sm-5 col-lg-5">
                    <div class="ptitle blue">
                        <h1>MD's Message </h1>
                        <h3>Dr. Ramesh Kancharla <span>Chairman and Managing Director </span></h3>
                    </div>
                </div>
                <div class="col-xs-12 mcontentview">
                    <p>“At Rainbow we are driven with the powerful belief that to convert the ‘impossible’ to ‘possible’, one has to convert the ‘I’s and Me’s’ to ‘Us and We’. Teamwork is the very soul of our entity as Rainbow Group. One may have a vision
                        but without unequivocal support from likeminded and dedicated child specialists, who joined Rainbow, supported and strengthened the idea further by working alongside to help bring this vision to concrete reality, we would literally
                        still be chasing rainbows..</p>
                    <p>For us at Rainbow, our greatest measure of success is in the number of smiling faces, love and appreciation from our children and their parents and the tremendous support from the medical community. Our success also lies in the knowledge,
                        that with their continued support we will keep on going from strength to strength and hopefully one day Rainbow will extend its umbrella of Perinatal and Tertiary Pediatric care to children nationwide.”</p>
                        <div class="mt-2">
                            <iframe allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" frameborder="0" height="315" src="https://www.youtube.com/embed/VWjNxLA0zTA" title="YouTube video player" width="560"></iframe>
                              
                          </div>
                </div>
            </div>
        </div>
    </section>

    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>

    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>