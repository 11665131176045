import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  ViewChildren,
  QueryList,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL } from '../services/constants';
import { ActivatedRoute } from '@angular/router';

import { Meta, Title } from '@angular/platform-browser';

import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
} from '@angular/forms';

declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
declare let initAboutTabs: any;
declare let initRemoveClass: any;
declare let initPopup: any;
export class CustomValidators {
  static multipleCheckboxRequireOne(fa: FormArray) {
    let valid = false;

    for (let x = 0; x < fa.length; ++x) {
      if (fa.at(x).value) {
        valid = true;
        break;
      }
    }
    return valid
      ? null
      : {
          multipleCheckboxRequireOne: true,
        };
  }
}

@Component({
  selector: 'share-feedback',
  templateUrl: './feedback.component.html',
  styles: [
    `
      li a {
        text-transform: capitalize;
      }
      .has-error {
        border: 1px solid red !important;
      }
      input[disabled] {
        border: 1px solid rgba(61, 41, 121, 0.01) !important;
        background: rgba(61, 41, 121, 0.5) !important;
      }
    `,
  ],
})
export class FeedbackComponent implements OnDestroy, OnInit {
  /* Declare variable*/
  public services: any[] = [];
  public brief_facilities: any[] = [];
  public title: any = '';
  public subtitle: any = '';
  public description: any = '';
  public imgsrc: any = '';
  public imgBasePath = '';
  public hidden = 'hidden';
  public currentSection: string = '';
  public doctorList: any = [];
  public doctorDetailsViewStatus: boolean = true;
  public selected: any = '';

  //doctor detail
  public selectedDoctorId: number;
  public selectedDoctorName: string = '';
  public selectedDoctorImg: string = '';
  public selectedDoctorInfo: string = '';
  Î;
  public selectedDoctorDesignation: string = '';
  public logo = 'logo';
  //doctor
  public mostHappyList: any[] = [];
  public activeRoute: any[] = [];
  public activePage: string = '';
  public selectedDoctorSlug: string = '';
  public DoctorModel: string;
  public doctorSlug: string = '';
  public selectedDoctor: any = '';
  public links: any = [];
  public bread_crumb_links: any = [];
  public bread_crumb_child_links: any = [
    { label: 'Home', link: '/home', class: '' },
    { label: 'Child Care', link: '/child-care/about', class: '' },
    { label: 'Feedback', link: '', class: '' },
  ];

  public bread_crumb_women_links: any = [
    { label: 'Home', link: '/home', class: '' },
    { label: 'Women Health', link: '/women-care/about', class: '' },
    { label: 'Feedback', link: '', class: '' },
  ];

  public bread_crumb_fertility_links: any = [
    { label: 'Home', link: '/home', class: '' },
    { label: 'Fertility Care', link: '/fertility-care/about', class: '' },
    { label: 'Feedback', link: '', class: '' },
  ];

  public child_care_links: any = [
    {
      label: 'About Child Care',
      link: '/child-care-about',
      class: '',
      slug: 'about',
    },
    {
      label: 'Child Safety',
      link: '/child-care/child-safety',
      class: '',
      slug: 'child-safety',
    },
    {
      label: 'Doctors',
      link: '/child-care/doctors',
      class: '',
      slug: 'doctors',
    },
    {
      label: 'Our Specialities',
      link: '/child-care/specialities',
      class: '',
      slug: 'specialities',
    },
    {
      label: 'Our Centres',
      link: '/child-care/centres',
      class: '',
      slug: 'centres',
    },
    {
      label: 'Vaccination',
      link: '/child-care/vaccination',
      class: '',
      slug: 'vaccination',
    },
    {
      label: 'Testimonials',
      link: '/child-care/testimonials',
      class: '',
      slug: 'testimonials',
    },
    {
      label: 'Share Feedback',
      link: '/child-care/feedback',
      class: 'active',
      slug: 'child-care-feedback',
    },
    {
      label: 'Book An Appointment',
      link: '/child-care/book-an-appointment',
      class: '',
      slug: 'book-an-appointment',
    },
  ];

  public women_care_links: any = [
    {
      label: "About Women's Care",
      link: '/women-care/about',
      class: '',
      slug: 'about',
    },
    {
      label: 'Doctors',
      link: '/women-care/doctors',
      class: '',
      slug: 'doctors',
    },
    {
      label: 'Our Specialities',
      link: '/women-care/specialities',
      class: '',
      slug: 'specialities',
    },
    {
      label: 'Our Centres',
      link: '/women-care/centres',
      class: '',
      slug: 'centres',
    },
    {
      label: 'Testimonials',
      link: '/women-care/testimonials',
      class: '',
      slug: 'testimonials',
    },
    {
      label: 'Share Feedback',
      link: '/women-care/feedback',
      class: 'active',
      slug: 'women-care-feedback',
    },
    {
      label: 'Book An Appointment',
      link: '/women-care/book-an-appointment',
      class: '',
      slug: 'book-an-appointment',
    },
  ];

  public fertility_care_links: any = [
    {
      label: 'About Fertility Care',
      link: '/fertility-care/about',
      class: '',
      slug: 'about',
    },
    {
      label: 'Doctors',
      link: '/fertility-care/doctors',
      class: '',
      slug: 'doctors',
    },
    {
      label: 'Our Treatments',
      link: '/fertility-care/treatments',
      class: 'active',
      slug: 'treatments',
    },
    {
      label: 'Our Centres',
      link: '/fertility-care/centres',
      class: '',
      slug: 'centres',
    },
    {
      label: 'Testimonials',
      link: '/fertility-care/testimonials',
      class: 'active',
      slug: 'testimonials',
    },
    {
      label: 'Share Feedback',
      link: '/fertility-care/feedback',
      class: 'active',
      slug: 'fertility-care-feedback',
    },
    {
      label: 'Book An Appointment',
      link: '/fertility-care/book-an-appointment',
      class: '',
      slug: 'book-an-appointment',
    },
  ];

  public selectlabel: string = 'selectlocation';
  public activeSubPage: string;
  feedbackForm: any;
  public pageType: any;
  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    public formBuilder: FormBuilder,
    public metaTitle: Title,
    public meta: Meta
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/fancybox/jquery.fancybox.js');
      require('../../assets/js/slick.min.js');
      this.mostHappyList = [
        { name: 'Doctor friendliness', key: 'doctorFriendliness' },
        { name: 'Value for money', key: 'valueForMoney' },
        { name: 'Explanation of the health issue', key: 'healthIssue' },
        { name: 'Treatment satisfaction', key: 'treatmentSatisfaction' },
      ];
      let happyFormGroup: FormGroup = new FormGroup({});
      for (let happy of this.mostHappyList) {
        let control: FormControl = new FormControl(happy.key);
        happyFormGroup.addControl(happy.key, control);
      }
      this.feedbackForm = formBuilder.group({
        health_problem: ['', Validators.required],
        doctor_experience: ['', Validators.required],
        happyList: happyFormGroup,
        doctor_recommend: ['', Validators.required],
        patient_name: ['', Validators.required],
        patient_id: ['', Validators.compose([Validators.minLength(5)])],
        mobile: [
          '',
          Validators.compose([Validators.required, Validators.minLength(10)]),
        ],
        submit_as_anonymously: [''],
        TandCApprove: ['', Validators.required],
        doctor_id: [''],
      });
    }
  }
  activeBreadCrum(currentSection) {
    switch (currentSection) {
      case 'child-care':
        this.bread_crumb_links = this.bread_crumb_child_links;
        this.links = this.child_care_links;
        this.logo = 'rainbow-logo';
        this.activeSubPage = 'child-care-feedback';
        break;
      case 'women-care':
        this.bread_crumb_links = this.bread_crumb_women_links;
        this.links = this.women_care_links;
        this.logo = 'birth-right-logo';
        this.activeSubPage = 'women-care-feedback';
        break;
      case 'fertility-care':
        this.bread_crumb_links = this.bread_crumb_fertility_links;
        this.links = this.fertility_care_links;
        this.logo = 'birth-right-logo';
        this.activeSubPage = 'fertility-care-feedback';
        break;
      default:
        this.logo = 'logo';
    }
  }
  @ViewChildren('abouttabs') things: QueryList<any>;
  @ViewChild('doctorSelected') elName: any;
  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.currentSection = params['section'];
      this.doctorSlug = params['doctorslug'];
      this.pageType =
        params['type'] == 'visiting-consultant' ? '/visiting-consultant' : '';
    });
    this.activeBreadCrum(this.currentSection);
    this.api
      .getSpecialityMasters()
      .map((res) => res)
      .subscribe((data) => {
        //console.log(data);
        this.activeRoute = data.speciality_masters.filter((filterdata) => {
          if (this.currentSection == 'women-care') {
            return filterdata.slug == 'women-care';
          } else {
            return filterdata.slug == this.currentSection;
          }
        });
        if (this.activeRoute.length > 0) {
          //console.log('array');
          this.activePage = this.activeRoute[0].speciality;
          this.api.getDoctorSpecialityMasterByName(this.activePage).subscribe(
            (data) => {
              this.doctorList = data.speciality_masters[0].doctors;
              //console.log(this.doctorList);
            },
            (error) => {
              console.log(error);
            }
          );
        } else {
          this.activePage = this.currentSection;
          //console.log('empty');
          this.api.getDoctorsList('').subscribe(
            (data) => {
              //console.log(data.doctors);
              this.doctorList = data.doctors;
              if (this.doctorSlug) {
                //let selectedDoctor;

                this.doctorList
                  .filter((res) => res.slug == this.doctorSlug)
                  .map((data) => {
                    this.selectedDoctor = data.id;
                  });
                this.getDoctorDetail(this.selectedDoctor);
                //console.log(this.selectedDoctor);
              }
            },
            (err) => {
              console.log('error => ', err);
            }
          );
        }
      });

    this.api.getPageDescription(this.currentSection + '-feedback').subscribe(
      (data) => {
        this.title = data.page_descriptions[0].title;
        this.subtitle = data.page_descriptions[0].subtitle;
        this.description = data.page_descriptions[0].description;
        this.imgsrc = data.page_descriptions[0].image;
        this.imgBasePath = IMG_BASE_URL;
        if (data) {
          //console.log(data);
          this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
          this.meta.updateTag({
            name: 'keywords',
            content: data.page_descriptions[0].meta_keyword,
          });
          this.meta.updateTag({
            name: 'description',
            content: data.page_descriptions[0].meta_description,
          });
          this.meta.addTags([
            {
              property: 'og:title',
              content: data.page_descriptions[0].meta_title,
            },
            {
              property: 'og:description',
              content: data.page_descriptions[0].meta_description,
            },
            {
              property: 'og:keywords',
              content: data.page_descriptions[0].meta_keyword,
            },
          ]);
        }
      },
      (error) => {
        console.log(error);
      }
    );

    this.api.getHealthCareServices('about-child-care').subscribe(
      (data) => {
        this.services = data.healthcare_services;
      },
      (error) => {
        console.log(error);
      }
    );

    this.api.getBriefFacilities('about-child-care').subscribe(
      (data) => {
        this.brief_facilities = data.brief_facilities;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getDoctorDetail(doctorId) {
    this.feedbackForm.controls['doctor_id'].setValue(doctorId);
    let filteredDoctor = this.doctorList.filter((res) => {
      return res.id == doctorId;
    });
    //console.log(filteredDoctor);
    this.selectedDoctorId = filteredDoctor[0].id;
    this.selectedDoctorSlug = filteredDoctor[0].slug;
    this.selectedDoctorName = filteredDoctor[0].name;
    this.selectedDoctorImg = filteredDoctor[0].photo;
    this.selectedDoctorDesignation = filteredDoctor[0].designation;

    //console.log(filteredDoctor);
    this.doctorDetailsViewStatus = false;
  }

  isNumber(evt) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  submitForm() {
    if (this.feedbackForm.value) {
      let doctor_recommend = 0;
      let submit_as_anonymously = 0;
      if (this.feedbackForm.value['doctor_recommend'] == 'Yes') {
        doctor_recommend = 1;
      }
      if (this.feedbackForm.value['submit_as_anonymously']) {
        submit_as_anonymously = 1;
      }
      let MostHappy = this.feedbackForm.controls['happyList'];

      this.feedbackForm.controls['doctor_recommend'].setValue(doctor_recommend);
      this.feedbackForm.controls['submit_as_anonymously'].setValue(
        submit_as_anonymously
      );

      this.api.postFeedback(this.feedbackForm.value).subscribe((res) => {
        //console.log(res);
        if (res.status_code == 200) {
          initRemoveClass();
          initPopup(124);
          this.feedbackForm.reset();
        }
      });
    }
  }

  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
      initRemoveClass();
      //customRadio();
    }

    this.things.changes.subscribe((t) => {
      // this.ngForRendred();
      initAboutTabs();
    });
  }
  ngOnDestroy() {}
}
