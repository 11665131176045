import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  ViewChildren,
  QueryList,
  HostListener,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL } from '../services/constants';
import { Meta, Title } from '@angular/platform-browser';
declare let $: any;
declare let initHome: any;
declare let initAllPage: any;

declare let initAboutTabs: any;

@Component({
  selector: 'knowledge-centre',
  templateUrl: './knowledge-centre.component.html',
})
export class OtherknowledgeCentreComponent
  implements OnDestroy, OnInit, AfterViewInit
{
  constructor(
    private api: ApiService,
    public metaTitle: Title,
    public meta: Meta
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
    }
  }

  public specId = 2;
  public group_sections: any = [];
  public title: any = '';
  public subtitle: any = '';
  public description: any = '';
  public imgsrc: any = '';
  public imgBasePath = '';
  public logo = 'logo';
  public hidden = 'hidden';
  public specialityMasters: any = [];
  public selectedSpecMaster: any = 0;
  public rows: any;
  public selectedSpecMasterTitle: string = 'Select';
  public bread_crumb_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Others', link: '', class: '' },
    { label: 'Knowledge Centre', link: '', class: '' },
  ];

  public links: any = [];
  public activePage: string = 'knowledge-centre';

  public counter: number = 1;
  ngOnInit() {
    this.api.getPageDescription('knowledge_centre').subscribe(
      (data) => {
        this.title = data.page_descriptions[0].title;
        this.subtitle = data.page_descriptions[0].subtitle;
        this.description = data.page_descriptions[0].description;
        this.imgsrc = data.page_descriptions[0].image;
        this.imgBasePath = IMG_BASE_URL;
        if (data) {
          //console.log(data);
          this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
          this.meta.updateTag({
            name: 'keywords',
            content: data.page_descriptions[0].meta_keyword,
          });
          this.meta.updateTag({
            name: 'description',
            content: data.page_descriptions[0].meta_description,
          });
          this.meta.addTags([
            {
              property: 'og:title',
              content: data.page_descriptions[0].meta_title,
            },
            {
              property: 'og:description',
              content: data.page_descriptions[0].meta_description,
            },
            {
              property: 'og:keywords',
              content: data.page_descriptions[0].meta_keyword,
            },
          ]);
        }
      },
      (error) => {
        console.log(error);
      }
    );
    this.api
      .getSpecialityMasters()
      .map((res) => res)
      .subscribe((res) => {
        this.specialityMasters = res.speciality_masters;
      });
    this.api
      .getOtherSubFooterDetails()
      .map((res) => res)
      .subscribe((data) => {
        this.links = data;
      });
  }
  changeSpecMaster(selected) {
    this.selectedSpecMasterTitle = this.specialityMasters
      .filter((res) => res.id == selected)
      .map((data) => data.speciality);

    this.api.getknowledgeSection(this.selectedSpecMaster).subscribe(
      (data) => {
        this.group_sections = data.reverse();
        this.rows = Array.from(
          Array(Math.ceil(this.group_sections.length)).keys()
        ).reverse();
        //console.log(this.rows)
        $('.KCSliderCont .slick-list').first().next().remove('.slick-list');
        setTimeout(function () {
          $('.KCSliderCont .slick-list').first().next().remove('.slick-list');
          equalheight('.kcCont .row .col-sm-6');
        }, 500);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  jqueryFunc() {
    $('.KCSlider > ul')
      .removeClass('slick-initialized')
      .removeClass('slick-slider');
    $('.KCSliderCont')
      .removeClass('slick-initialized')
      .removeClass('slick-slider');
    $('.KCSlider > ul')
      .not('.slick-initialized')
      .slick({
        slide: 'li',
        asNavFor: '.KCSliderCont',
        slidesToShow: 7,
        slidesToScroll: 1,
        arrows: true,
        prevArrow:
          '<a class="slick-prev"><img src="assets/images/navprew.png" alt="" /></a>',
        nextArrow:
          '<a class="slick-next"><img src="assets/images/navnext.png" alt="" /></a>',
        dots: false,
        centerMode: false,
        centerPadding: 20,
        focusOnSelect: true,
        infinite: false,
        touchMove: false,
        draggable: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: { slidesToShow: 3, slidesToScroll: 1 },
          },
          {
            breakpoint: 768,
            settings: { slidesToShow: 3, slidesToScroll: 1 },
          },
          {
            breakpoint: 520,
            settings: { slidesToShow: 2, slidesToScroll: 1, centerPadding: 2 },
          },
          {
            breakpoint: 400,
            settings: { slidesToShow: 1, slidesToScroll: 1, centerPadding: 2 },
          },
        ],
      });
    $('.KCSliderCont').not('.slick-initialized').slick({
      slide: '.kcCont',
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true,
      asNavFor: '.KCSlider > ul',
      infinite: false,
      adaptiveHeight: true,
      focusOnSelect: false,
      touchMove: false,
      draggable: false,
      swipeToSlide: false,
      swipe: false,
    });
  }
  @ViewChildren('checkKnowledge') things: QueryList<any>;

  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
    }

    this.things.changes.subscribe((t) => {
      this.jqueryFunc();
    });
  }

  ngOnDestroy() {}
}
const equalheight = (container) => {
  var currentTallest = 0,
    currentRowStart = 0,
    rowDivs = new Array(),
    $el,
    topPosition = 0,
    topPostion,
    currentDiv;
  $(container).each(() => {
    $el = $(this);
    $($el).height('auto');
    topPostion = $el.position().top;

    if (currentRowStart != topPostion) {
      for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
        rowDivs[currentDiv].height(currentTallest);
      }
      rowDivs.length = 0; // empty the array
      currentRowStart = topPostion;
      currentTallest = $el.height();
      rowDivs.push($el);
    } else {
      rowDivs.push($el);
      currentTallest =
        currentTallest < $el.height() ? $el.height() : currentTallest;
    }
    for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
      rowDivs[currentDiv].height(currentTallest);
    }
  });
};
