<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>
    <div id="topsearchwrp">
        <div class="inputbox">
            <input class="searchInput" type="text" name="txtSearch" id="txtSearch" placeholder="type here..." />
            <input type="submit" name="submit" class="ssubmitbtn" value="" />
        </div>
    </div>
    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>

    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <section class="page pageheader">
        <div class="container">
            <div class="col-xs-6 col-sm-6 col-lg-6 abannimgwrp">
                <div class="otherbannimg active"><img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" /></div>
            </div>
            <div class="col-xs-6 col-sm-6 col-lg-6">
                <div class="ptitle">
                    <h1>{{title}}</h1>
                    <h3>{{subtitle}}</h3>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-lg-6 mcont100">
                <p>{{description}}</p>
            </div>
        </div>
    </section>

    <section class="bnaformwrp">
        <div class="container">
            <div class="col-lg-12">
                <h3>Sign Up</h3>
            </div>
            <form [formGroup]="inpatientFeedbackForm" method="post" name="inpatientFeedbackForm">
                <div class="formcasn">
                    <ul>
                        <li class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                            <label>Select the City*</label>
                            <div class="drpdownwrp">
                                <select [ngClass]="{'has-error':!inpatientFeedbackForm.controls.city.valid && inpatientFeedbackForm.controls.city.touched}" class="select" name="city" required="" formControlName="city">
                    <option value="0">--Select the City--</option>
                    <option *ngFor="let city of cityList">
                        {{city.name}}
                    </option>
                </select>
                                <span id="drpdowncity" class="select-span">Select the City</span>

                            </div>
                        </li>
                        <li class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                            <label>Health Card No.* 
                <span  class="alertMsg"
                *ngIf="!inpatientFeedbackForm.controls.health_card_no.valid && inpatientFeedbackForm.controls.health_card_no.touched">
                 required
                </span>
            </label>
                            <input type="text" name="txtNOB" [ngClass]="{'has-error':!inpatientFeedbackForm.controls.health_card_no.valid && inpatientFeedbackForm.controls.health_card_no.touched}" (keypress)="isNumber($event)" formControlName="health_card_no" required="" placeholder="Enter Health Card No.">
                        </li>
                        <li class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                            <label>UHID No.</label>
                            <input type="text" name="txtNOB" [ngClass]="{'has-error':!inpatientFeedbackForm.controls.uhid_no.valid && inpatientFeedbackForm.controls.uhid_no.touched}" formControlName="uhid_no" placeholder="Enter the UHID(for existing patients)">

                        </li>
                        <li class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                            <label>Name*
                <span  class="alertMsg"
                    *ngIf="!inpatientFeedbackForm.controls.name.valid && inpatientFeedbackForm.controls.name.touched">
                    required
                </span>
            </label>
                            <input type="text" name="name" [ngClass]="{'has-error':!inpatientFeedbackForm.controls.name.valid && inpatientFeedbackForm.controls.name.touched}" formControlName="name" required="" placeholder="Enter Name">

                        </li>
                        <li class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                            <label>Age*
                <span  class="alertMsg"
                    *ngIf="!inpatientFeedbackForm.controls.age.valid && inpatientFeedbackForm.controls.age.touched">
                    required
                </span>
            </label>
                            <input type="text" name="age" [ngClass]="{'has-error':!inpatientFeedbackForm.controls.age.valid && inpatientFeedbackForm.controls.age.touched}" (keypress)="isNumber($event)" formControlName="age" maxlength="3" required="" placeholder="Enter Age">

                        </li>
                        <li class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                            <label>Mobile No*
                <span  class="alertMsg"
                  *ngIf="!inpatientFeedbackForm.controls.mobile.valid && inpatientFeedbackForm.controls.mobile.touched">
                  required
                </span>
            </label>
                            <input type="text" name="mobile" [ngClass]="{'has-error':!inpatientFeedbackForm.controls.mobile.valid && inpatientFeedbackForm.controls.mobile.touched}" (keypress)="isNumber($event)" formControlName="mobile" required="" maxlength="10" placeholder="Enter Mobile No">

                        </li>
                        <li class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                            <label>Email*
                <span  class="alertMsg" 
                    *ngIf="!inpatientFeedbackForm.controls.email.valid && inpatientFeedbackForm.controls.email.touched">
                    required
                </span>
            </label>
                            <input type="email" [ngClass]="{'has-error':!inpatientFeedbackForm.controls.email.valid && inpatientFeedbackForm.controls.email.touched}" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" name="email" formControlName="email" required="" placeholder="Enter Email ID">

                        </li>
                        <li class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                            <label>Company*
                <span  class="alertMsg"
                    *ngIf="!inpatientFeedbackForm.controls.company.valid && inpatientFeedbackForm.controls.company.touched">
                    required
                </span>
            </label>
                            <input type="text" [ngClass]="{'has-error':!inpatientFeedbackForm.controls.company.valid && inpatientFeedbackForm.controls.company.touched}" name="company" formControlName="company" required="" placeholder="Enter Company">

                        </li>
                        <li class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                            <label>Area/Location</label>
                            <input type="text" [ngClass]="{'has-error':!inpatientFeedbackForm.controls.area.valid && inpatientFeedbackForm.controls.area.touched}" name="area" formControlName="area" placeholder="Enter Area/Location">

                        </li>
                        <li class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                            <label>Zip Code</label>

                            <input [ngClass]="{'has-error':!inpatientFeedbackForm.controls.zip.valid && inpatientFeedbackForm.controls.zip.touched}" type="text" name="zip" (keypress)="isNumber($event)" formControlName="zip" placeholder="Enter Area/Location" maxlength="6">
                        </li>
                        <li class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                            <label>Category</label>
                            <div class="ifclistwrp">
                                <ul>
                                    <li><label><input type="radio" formControlName="category" class="fcategory" data-class="fcategory" name="category" value="OBG/GYN">OBG/GYN</label></li>
                                    <li><label><input type="radio" formControlName="category" class="fcategory" data-class="fcategory" name="category" value="Paediatrics">Paediatrics</label></li>
                                    <li><label><input type="radio" formControlName="category" class="fcategory" data-class="fcategory" name="category" value="Fertility">Fertility</label></li>
                                </ul>

                            </div>
                        </li>


                        <li class="col-md-12 col-sm-12 col-xs-12 col-lg-12 addFmform" formArrayName="userDetails">
                            <label><h3>Family Details</h3></label>
                            <div class="familyformwrp" *ngFor="let userdetail of inpatientFeedbackForm.controls.userDetails.controls; let i=index">
                                <div [formGroupName]="i">

                                    <div class="col-sm-6 col-xs-12 col-lg-2 fdbox">
                                        <div class="drpdownwrp">
                                            <select class="select" name="family_member_spouse" formControlName="family_member_spouse">
                                <option *ngFor="let spouse of SpouseList">{{spouse.name}}</option>
                            </select>
                                            <span id="drpdowncity" class="select-span">Select Spouse</span>

                                        </div>

                                    </div>
                                    <div class="col-sm-6 col-xs-12 col-lg-3 fdbox">
                                        <input type="text" name="family_member_name" formControlName="family_member_name" placeholder="Enter Name">
                                        <!--<p class="help-block errorMsg" *ngIf="inpatientFeedbackForm.get('FName').hasError('required') && inpatientFeedbackForm.get('FName').touched">Please Enter Name.</p>-->
                                    </div>
                                    <div class="col-sm-6 col-xs-12 col-lg-2 fdbox">
                                        <input type="text" name="family_member_age" (keypress)="isNumber($event)" formControlName="family_member_age" placeholder="Enter Age">
                                        <!--<p class="help-block errorMsg" *ngIf="inpatientFeedbackForm.get('FAge').hasError('required') && inpatientFeedbackForm.get('FAge').touched">Please Enter Age.</p>-->
                                    </div>
                                    <div class="col-sm-6 col-xs-12 col-lg-2 fdbox">
                                        <div class="drpdownwrp">
                                            <select class="select" name="family_member_gender" formControlName="family_member_gender">
                                <option *ngFor="let gender of GenderList">{{gender.name}}</option>
                               
                            </select>
                                            <span id="drpdowncity" class="select-span">Select Gender</span>
                                        </div>
                                        <!--<p class="help-block errorMsg" *ngIf="inpatientFeedbackForm.get('Gender').hasError('required') && inpatientFeedbackForm.get('Gender').touched">Please Select Gender.</p>-->
                                    </div>
                                    <div class="col-sm-6 col-xs-12 col-lg-3 fdbox">
                                        <input type="text" name="family_member_uhid" formControlName="family_member_uhid" placeholder="Enter UHID">
                                        <!--<p class="help-block errorMsg" *ngIf="inpatientFeedbackForm.get('UHID').hasError('required') && inpatientFeedbackForm.get('UHID').touched">Please Enter UHID.</p>-->
                                    </div>
                                    <span class="btn" *ngIf="inpatientFeedbackForm.controls.userDetails.controls.length > 1" (click)="removeUserDetail(i)">X</span>
                                </div>

                            </div>

                        </li>
                        <li class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                            <input type="button" (click)="submitUserDetails()" [disabled]="!inpatientFeedbackForm.valid" name="submit" class="rimsubmitbtn addFmsm" value="Submit">
                            <a href="javascript:void(0);" class="addFm" (click)="addUserDetail()">Add Family Member</a>
                        </li>
                    </ul>

                </div>

            </form>
        </div>
    </section>
    <div class="capopup" data-popup="popup-125">
        <div class="capopup-inner">
            <h2>Thankyou</h2>
            <h3>For your feedback</h3>
            <a class="capopup-close" data-popup-close="popup-125" href="#"><img src="assets/images/icon-close2.png" alt="" /></a>
        </div>
    </div>
    <find-a-doctors-nav></find-a-doctors-nav>

    <footer-nav-mini [links]="links"></footer-nav-mini>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>