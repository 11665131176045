<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>

    <section class="page wtwedo">
        <div class="row">
            <div class="col-md-5 col-xs-5 col-lg-4">
                <div class="circuleframe">
                    <img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" />
                </div>
                <!-- End Circle -->
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7">
                <div class="pagetitle">
                    <h1 class="tgradient">{{title}} </h1>
                    <div class="underline">
                        <img src="assets/images/underline2.png" alt="" />
                    </div>
                    <h3>{{subtitle}}</h3>
                </div>
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7 mcontentview">
                <p [innerHTML]="description"></p>
            </div>
        </div>

    </section>
    <section class="aboutsectin2 awardswapper">
        <div class="container">
            <div class="row nsg_text">
                <div class="col-xs-12 col-lg-12">
                    <h2>Plan far ahead</h2>
                    <p>Birth plans are something we do in the last trimester and include practical things like packing a hospital bag and keeping it ready. But, planning for your birth actually starts a long time before that. Discussing your birth choices
                        and having an open discussion about these choices is something you can do from the second trimester. It also helps in visualisation (see box), a useful exercise when planning for a normal delivery.</p>
                    <p>Your doctor is an important character in this adventure of birth so let your doctor know from the start that you favour a normal vaginal delivery, if all goes smoothly.&nbsp; If your doctor says that there’s plenty of time to start
                        discussing your birth, do not be deterred and go ahead and discuss it already. The minds of pregnant mums just naturally fill up with questions and these need to be addressed. And, you need to build a relationship of trust with
                        your doctor which may be strengthened with each discussion. Also, consider what hospital you are going to deliver in and check out the rate of natural deliveries in that hospital.</p>
                    <p>As you think ahead to your natural delivery, think that you will need a supporter through the preparation as well as actual delivery. So, choose a birth partner as soon as you can. Whether this is your partner or your mother or a friend,
                        appoint someone whose unconditional support you can count on and someone who you know will be available around your due date. Once you have identified this person, you can share your apprehensions as well as facts you find as you
                        read up on birth or attend a birthing class.</p>
                </div>
            </div>
            <div class="col-xs-12 col-lg-12">
                <h2>Get fit for labour</h2>
                <p>Labour and birth can often turn into a marathon adventure! So, when you are choosing to have a normal vaginal birth, one thing that can really help you go through it more easily is building some stamina and being physically fit. Doctors
                    say that regular gentle exercises like yoga, walking or swimming can tone the right muscles for delivery and greatly increase your chances of a normal vaginal birth. The usual prescription for exercise during pregnancy is half an hour
                    a day. There are many ways to do this.</p>
                <p><strong>Walking:</strong>Walking is the easiest and gentlest way to get your half an hour daily quota of exercise done. Walking tones the right muscles and also promotes a good positioning of baby for a normal delivery. Regular brisk walks
                    are something you can do until birth, as long as there are no pregnancy complications. It is also an exercise that is suitable both for women who are not in the habit of exercising daily as well as those who are.</p>
                <p><strong>Workouts:</strong> If you are a person who runs on the treadmill or does other high intensity workouts, you can continue with the same in a gentler way. Walk rather than run on your treadmill. Stop weight training or modify it
                    by using lighter weights. Do speak with a trainer who has experience working with pregnant mums and remember that your workout should now be geared on general fitness rather than muscle gain or weight loss.</p>
                <p><strong>Swimming:</strong>As the weather gets warmer, this exercise option seems most tempting. What’s great about swimming is that it can help you strengthen your muscles and stay toned, without putting any unnecessary weight on your
                    back. In the pool, your body feels light, for a change! All in all, another great way to build up strength and stamina for your normal delivery.</p>
                <p><strong>Yoga and stretching<span style="text-decoration: underline;">:</span></strong> These can help strengthen your pelvic area, the very muscles you will count on when it is time to push baby out. Squats and stretches are the usual
                    exercises prescribed. However, if it not your first pregnancy and if you have incurred a stitch in the mouth of your uterus, you may need to refrain from exercises like squats. Best to do this sort of exercise under a qualified trainer.</p>
                <p><strong>Eat healthy</strong></p>
                <p>Eating healthy during pregnancy your pregnancy is very important if you want to plan for a normal vaginal delivery. Apart from eating foods that are high on substance and low on calories (think fruit, vegetables, meats and milk) helps
                    you put on less weight. Too much weight gain can hinder a normal delivery. Also, stocking up on the right minerals and vitamins through you pre-birth diet will help your body stand up to and recover from the efforts of labour and delivery.</p>
                <p>Also, look out for certain foods that can impact the condition of your cervix. Pineapple and mangoes are two fruits that are known to stimulate labour and soften the cervix. So, when you are ready and your date is nearing, you can have
                    an extra helping of these. Raspberries and even raspberry leaf tea is also known to be good for the cervix.</p>
                <p>Once you are in labour, remember to keep consuming light healthy snacks. Just like a marathon runner, you need to refuel to stay energised and reach the finish without being completely drained of energy.</p>
            </div>
            <div class="col-xs-12 col-lg-12">
                <h2>Approach to labour</h2>
                <p>If you manage to eat healthy and get fit, you’ll be in perfect form to go ahead and deliver normally. But, positive as you may feel, the pain of labour is something you will encounter. So, after all your other on going preparation for
                    your normal delivery, do apprise yourself of techniques to cope with the pain.</p>
                <p>Learn about labour positioning, for a start. Birthing classes or even discussions with your doctor can teach you about this. The right positions during labour can help relieve your pain as well as speed up the delivery. For example, experts
                    say that being upright (either sitting or walking) once labour begins is better than lying down. The baby moves into a correct position for delivery sooner when you are upright.</p>
                <p>As for the pain, the fact that you have to grasp and not let go is this: the pain is intermittent and not permanent. When you feel the first contractions, relax in between each contraction. When you are in the final stages, tell yourself
                    that the pain will stop completely soon. And, use breathing exercises. Deep breathing during labour helps a lot. Massages and hot and cold compresses may also be used as comfort. Discuss these with your birth partner and be prepared.</p>
                <p>Finally, as with all parenting concerns with this, one of the first challenges you’ll be facing as a mom, tune into your instinct and believe. Believe that your body has been designed to give birth and over the last nine months has innately
                    prepared itself for birth. Your body and your mind will come together and produce strategies to help you go ahead and have a safe and normal vaginal delivery. All the very best for the birth!</p>
            </div>
            <div class="col-xs-12 col-lg-12">
                <h2>The power of the mind’s eye</h2>
                <p>Visualisation is a mental exercise that can really help you be psychologically and emotionally prepared for birth. Playing out events ahead of time, in your mind, just as you want them to pan out will not only help you feel more positive
                    but also more confident. Indulging in these thoughts, narrating them to your birth partner and knowing what to expect will be like a rehearsal for the main event. When you are finally in the delivery room, you’ll feel calmer and almost
                    familiar with the proceedings of labour and birth.</p>
            </div>
            <div class="col-xs-12 col-lg-12">
                <h2>The benefits of a normal delivery</h2>
                <p>If you’ve decided you are going to try for a normal delivery, there is a lot to look forward to. There are many reasons why this is a good birth choice for both you and baby.</p>
                <p>When baby comes out through the vaginal opening, the amniotic fluid passes out easily from baby’s lungs. This makes breathing easier at once and reduces the risk of respiratory problems.&nbsp; Also, there are good bacteria in the birth
                    canal which baby naturally ingests as she passes through. This helps with her immunity to infections. And finally, baby gets to immediately breast feed and get her most valuable dose of colostrum right after birth.<br> For moms, a
                    normal delivery usually means getting home with baby sooner! Both the time spent in treatment as well as the time spent in hospital after birth is less than it would be if you were to have a caesarean. You also have no scars and stitches
                    to worry about and can stand under a hot shower as soon as you like. And, you get the joy of holding baby and feeding baby, instantly. Also, it is believed that your body gets back in shape far quicker after a normal vaginal delivery.</p>
            </div>
        </div>
    </section>
    <ng-template [ngIf]="ins_links.length > 0">
        <footer-nav-mini [links]="ins_links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>