<div class="footersitenav">
	<div class="container">
        <div class="col-md-12 col-sm-12 col-lg-12">
            <div class="fncarousel2">
                <ul>
                    <li *ngFor="let link of links" [ngClass]="{'active': link.slug == activePage}" ><a href="{{link.link}}" [target]="link.openNewTab ? '_blank' : ''">{{link.label}}</a></li>
                </ul>
            </div>
        </div>
     </div>
</div>
