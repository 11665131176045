/* tslint:disable: member-ordering */
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { Response } from '@angular/http';
import { Action } from '@ngrx/store';

import { User } from './user.model';
interface ExtendedAction extends Action {
  payload: any;
}

@Injectable()
export class UserActions {
  static EDIT_USER = '[User] Edit User';
  editUser(user: User): ExtendedAction {
    return {
      type: UserActions.EDIT_USER,
      payload: user,
    };
  }

  static LOGOUT = '[User] Logout';
  logout(): ExtendedAction {
    return {
      type: UserActions.LOGOUT,
      payload: null,
    };
  }

  static LOGOUT_FAIL = '[User] Logout Fail';
  logoutFail(err: Error): ExtendedAction {
    return {
      type: UserActions.LOGOUT_FAIL,
      payload: err,
    };
  }

  static LOGOUT_SUCCESS = '[User] Logout Success';
  logoutSuccess(res: HttpResponse<any>): ExtendedAction {
    return {
      type: UserActions.LOGOUT_SUCCESS,
      payload: res,
    };
  }
}
