<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <section class="pagebcwrp">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-lg-12">
                    <ul style="display:none;">
                        <li><a href="/">Home</a></li>
                        <!-- <li *ngIf="currentSection"><a href="{{currentSection}}/about">{{activePage}}</a></li> -->
                        <li><a href="{{currentSection}}{{pageType}}">Doctors</a> </li>
                        <li> {{doctor.name}} </li>
                    </ul>
                    <ul>
                        <li *ngFor="let link of bread_crumb_links" class="{{link.class}}">
                          <a *ngIf="link.link" href="{{link.link}}">{{link.label}}</a>
                          <span *ngIf="!link.link">{{link.label}}</span>
                        </li>
                        <li *ngIf="centreParamNameCond" class="active">
                            <a href="/{{centreParamName}}/{{cityParamName}}">{{cityParamNameString | titlecase}}</a>
                        </li>
                        <li *ngIf="specialityParamNameCond" class="active">
                            <a href="/{{categoryParamName}}/speciality/{{specialityParamName}}">{{specialityParamNameString | titlecase}}</a>
                        </li>
                        <li>{{doctor.name}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <section class="page wtwedo">
        <div class="row">
            <div class="col-md-5 col-sm-5 col-lg-4 col-xs-5">
                <div class="circuleframe"> <img src="{{imgBasePath}}{{doctor.profile}}" class="img-responsive" alt="{{doctor.name}}" title="{{doctor.name}}" /> </div>
                <!-- End Circle -->
            </div>
            <div class="col-md-7 col-sm-7 col-lg-7 col-xs-7">
                    <h4 class="bookAppPgBgAnim" *ngIf="Highlights">{{Highlight}}</h4>
                <div class="pagetitle">
                    <h1 class="textgradient">{{doctor.name}}</h1>
                    <div class="underline"> <img src="assets/images/underline.png" alt="" /> </div>
                    <h3>{{doctor.qualification}} </h3>
                </div>
            </div>
            <div class="col-md-7 col-sm-7 col-lg-7 col-xs-12">
                <p>{{doctor.designation}}</p>
                <div class="btnwrpappointment2">
                    <ul>
                        <li>
                            <a href="{{currentSection}}{{pageType}}/book-an-appointment/{{doctor.slug}}.html"><img src="assets/images/icon-appointment.png" alt="">Book an appointment</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-7 col-sm-7 col-lg-7 col-xs-12 appointmentNoteWrp2" *ngIf="AppointmentNoteNotAvailable">
                <div [innerHTML]="doctor.appointmentnote"></div>
            </div>
        </div>
    </section>
    <section class="drinfowapper" *ngIf="atabsSliderStatus" #abouttabs>
        <div class="container" *ngIf="doctor.centers">
            <ul class="atabs slider">
                <li class="active">
                    <a href="javascript:void(0)" data-id="data1">
                        <div class="aiconwrp"><img src="{{imgBasePath}}{{imgsrc}}" alt="" /></div>
                        <h2>Info</h2>
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0)" data-id="data2">
                        <div class="aiconwrp"><img src="assets/images/icon2-doctor-info.png" alt="" /></div>
                        <h2>Location</h2>
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0)" data-id="data3">
                        <div class="aiconwrp"><img src="assets/images/icon3-doctor-info.png" alt="" /></div>
                        <h2>Doctors Desk</h2>
                    </a>
                </li>
            </ul>
            <div class="atabscontent slider">
                <div class="atabsdata">
                    <div class="row about-dr">
                        <div class="col-xs-12 col-sm-12 col-md-9">
                            <p [innerHTML]="doctor.info"></p>
                            <div class="linkswrp">
                                <a *ngIf="doctor.casestudy" href="javascript:;" class="btn"><span>Case Study</span></a>
                                <a *ngIf="doctor.profilepdf" target="_blank" href="{{imgBasePath}}{{doctor.profilepdf}}" class="btn"><span>Download Profile</span></a>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-3">
                            <div class="dofferswrp">
                                <div class="sitem">
                                    <div class="icondofr"> <img src="assets/images/icon-cup.png" alt="" /> </div>
                                    <h3>Coming Soon</h3>
                                    <!-- <p> From adolescence to conceiving to delivery and beyond. </p> -->
                                </div>
                            </div>
                            <!-- <div class="sitem">
								<div class="icondofr"> <img src="assets/images/icon-cup.png" alt="" /> </div>
								<h3>Coming Soon</h3>
								
							</div> -->

                            <div class="sharef">
                                <a href="{{currentSection}}{{pageType}}/feedback/{{doctor.slug}}" class="rbtn"><span>Share Feedback</span></a>
                            </div>
                            <div class="sendbwrp">
                                <a href="javascript:void(0)">Send Bravo</a>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="atabsdata">
                    <div class="row about-dr" *ngFor="let center of doctor.centers">
                        <div class="col-xs-12 col-sm-6 col-md-6 address-wapper ">
                            <h2 class="textname">{{center.center_name}}</h2>
                            <div class="underline"><img src="{{imgBasePath}}{{imgsrc}}" width="77" height="3"></div>
                            <p [innerHTML]="center.address"></p>
                            <a href="https://www.google.com/maps?saddr=My+Location&daddr={{center.lat_long}}" target="_blank" class="viewmap">View map</a>
                            <a href="{{currentSection}}{{pageType}}/book-an-appointment" class="btn-Bookapopoi"><span>Book an Appointment</span></a>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6">
                            <img src="{{imgBasePath}}{{center.image}}" alt="{{center.center_name}}" class="img-responsive img-rounded">
                        </div>
                    </div>
                    <hr>

                </div>
                <div class="atabsdata">
                    <div class="col-md-12" *ngFor="let desk of doctor_desk; let doc_id = index">
                        <h2 class="headding">{{desk.title}}</h2>
                        <div class="row" *ngIf="desk.description != ''" [innerHTML]="desk.description">  </div>                        
                        <!--<p *ngIf="desk.description != ''">{{desk.description}}</p>-->
                        <div class="row aco-wapper" *ngFor="let question of desk.doctors_desk_questions; let desk_question_id = index">
                            <div class="DoctorDesk-aco" [ngClass]="{'sel':question.toggle}" (click)="toggle(doc_id, question)">{{question.question}}</div>
                            <div class="infotext" [ngClass]="{'active':question.toggle}">
                                <p>{{question.answer}}</p>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </section>
    <footer-nav-mini [links]="links"></footer-nav-mini>

    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>