<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <section class="pagebcwrp">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-lg-12">
                    <ul>
                        <li><a href="javascript:void()">Home</a></li>
                        <li><a href="javascript:void()">Our Centres</a></li>
                        <li>About Centre</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <section class="page wtwedo">
        <div class="aboutcentre-banner container">
            <div class="col-md-5 col-xs-5 col-lg-4 floatright">
                <div class="circuleframe2"> <img src="{{imgBasePath}}{{imgsrc}}" alt="{{title}}" title="{{title}}" /> </div>
                <!-- End Circle -->
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7 floatleft">
                <div class="pagetitle ">
                    <h1 class="textgradient fontsize">{{title}}</h1>
                    <div class="underline"> <img src="assets/images/underline.png" alt="" /> </div>
                </div>
            </div>
            <div class="col-md-7 col-xs-12 col-lg-7 mcontentview">
                <p [innerHTML]="description"></p>
            </div>
            <!-- <div class=" col-xs-12 col-sm-12 col-md-7 col-lg-7 ios-text">
      <p class="col-xs-12 col-sm-7 col-md-5 col-lg-7  padding ">The centre also runs one of the largest post graduate training programs in the country. It offers the following training programs for doctors – DNB in pediatrics, DNB in Neonatology, Pediatric Critical Care Fellowship certification course by IAP and IAP Fellowship in Neonatology.</p>
      <div class=" col-xs-12 col-sm-5 col-md-5 col-lg-5"> <img src="assets/images/icon-ios.png" alt="" /> <img src="assets/images/icon-nabh.png" alt="" /></div>
    </div> -->
        </div>
    </section>
    <section class="apapproachwrp">
        <div class="row">
            <div class="container">
                <div class="col-md-12 col-xs-12 col-lg-12">
                    <div class="apatitle">
                        <h2 class="gradient">A prudent approach</h2>
                    </div>
                    <ul class="apapproachlist">
                        <li *ngFor="let brief_facility of brief_facilities">
                            <div class="cirwrp"> <img src="{{imgBasePath}}{{brief_facility.image}}" class="img-responsive" alt="" /> </div>
                            <div class="txtwrp">
                                <p [innerHTML]="brief_facility.description"></p>
                            </div>
                        </li>
                        <!-- <li>
                    	 <div class="cirwrp"> <img src="assets/images/icon-apa2.png" alt="" /> </div>
                         <div class="txtwrp">
                            <p><span>2500 +</span> surgeries performed annually </p>
                        </div>
                    </li>
                    <li>
                    	 <div class="cirwrp"> <img src="assets/images/icon-apa3.png" alt="" /> </div>
                         <div class="txtwrp">
                            <p><span>75%</span> are done laparoscopically</p>
                        </div>
                    </li>
                    <li>
                    	 <div class="cirwrp"> <img src="assets/images/icon-apa4.png" alt="" /> </div>
                         <div class="txtwrp">
                            <p><span>200+</span> children brought in with Emergency Transport Service</p>
                        </div>
                    </li> -->
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <section class="aboutityiconwrp">
        <div class="row">
            <div class="ourspecialties">
                <h2 class="gradient fontsize">Our Specialties</h2>
            </div>
            <div class="col-md-12 col-xs-12 col-lg-12 text-center">
                <p>Reference site about Lorem Ipsum, giving information on its origins,Reference site about Lorem Ipsum, giving information on its origins,</p>
            </div>
            <div class="col-md-12 col-xs-12 col-lg-12">
                <ul class="aboutuslider slider">
                    <li>
                        <div class="viconwrp">
                            <div class="cirwrp"> <img src="assets/images/ourspecialities-icon1.png" data-img="" alt="" /> </div>
                            <div class="textbtn">
                                <div class="inner">
                                    <p>Pediatric ICU</p>
                                    <a href="#" class="knowmore"><span>Know More</span></a></div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="viconwrp">
                            <div class="cirwrp"> <img src="assets/images/ourspecialities-icon2.png" data-img="" alt="" /> </div>
                            <div class="textbtn">
                                <div class="inner">
                                    <p>Pediatric Neurology</p>
                                    <a href="#" class="knowmore"><span>Know More</span></a></div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="viconwrp">
                            <div class="cirwrp"> <img src="assets/images/ourspecialities-icon3.png" data-img="" alt="" /> </div>
                            <div class="textbtn">
                                <div class="inner">
                                    <p>Pediatric Surgery</p>
                                    <a href="#" class="knowmore"><span>Know More</span></a></div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="viconwrp">
                            <div class="cirwrp"> <img src="assets/images/ourspecialities-icon4.png" data-img="" alt="" /> </div>
                            <div class="textbtn">
                                <div class="inner">
                                    <p>Pediatric ICU</p>
                                    <a href="#" class="knowmore"><span>Know More</span></a></div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="viconwrp">
                            <div class="cirwrp"> <img src="assets/images/ourspecialities-icon1.png" data-img="" alt="" /> </div>
                            <div class="textbtn">
                                <div class="inner">
                                    <p>Pediatric ICU</p>
                                    <a href="#" class="knowmore"><span>Know More</span></a></div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="viconwrp">
                            <div class="cirwrp"> <img src="assets/images/ourspecialities-icon4.png" data-img="" alt="" /> </div>
                            <div class="textbtn">
                                <div class="inner">
                                    <p>General Pediatrics</p>
                                    <a href="#" class="knowmore"><span>Know More</span></a></div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="viconwrp">
                            <div class="cirwrp"> <img src="assets/images/ourspecialities-icon5.png" data-img="" alt="" /> </div>
                            <div class="textbtn">
                                <div class="inner">
                                    <p>Neonatal</p>
                                    <a href="#" class="knowmore"><span>Know More</span></a></div>
                            </div>
                        </div>
                    </li>
                </ul>
                <ul class="aboutuslidercon slider">
                    <li>
                        <div class="col-xs-12 col-sm-6 col-md-3  ">
                            <div class="doctorsListwrp">
                                <div class="dthumbwrp"> <img src="assets/images/doc-thumb1.png" alt=""> </div>
                                <div class="ddetailswrp">
                                    <h3>Dr. A. Venkatalakshmi</h3>
                                    <p> Neonatologist & Pediatrician</p>
                                </div>
                                <div class="clr"></div>
                                <a href="#" class="knowmore2 active"><span>Know More</span></a> <a href="#" class="btn-Bookapopoi"><span>Book an appointment</span></a> </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-3">
                            <div class="doctorsListwrp">
                                <div class="dthumbwrp"> <img src="assets/images/doc-thumb2.png" alt=""> </div>
                                <div class="ddetailswrp">
                                    <h3>Dr. Ramesh Kancharla</h3>
                                    <p> Pediatrician &amp; Gastroenterologist, Managing Director </p>
                                </div>
                                <div class="clr"></div>
                                <a href="#" class="knowmore2 active"><span>Know More</span></a> <a href="#" class="btn-Bookapopoi"><span>Book an appointment</span></a> </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-3">
                            <div class="doctorsListwrp">
                                <div class="dthumbwrp"> <img src="assets/images/doc-thumb4.png" alt=""> </div>
                                <div class="ddetailswrp">
                                    <h3>Dr. Faisal B Nahdi</h3>
                                    <p>Pediatrician</p>
                                </div>
                                <div class="clr"></div>
                                <a href="#" class="knowmore2 active"><span>Know More</span></a> <a href="#" class="btn-Bookapopoi"><span>Book an appointment</span></a> </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-3">
                            <div class="doctorsListwrp">
                                <div class="dthumbwrp"> <img src="assets/images/doc-thumb3.png" alt=""> </div>
                                <div class="ddetailswrp">
                                    <h3>Dr. Dinesh Kumar Chirla</h3>
                                    <p> Director Intensive Care Services</p>
                                </div>
                                <div class="clr"></div>
                                <a href="#" class="knowmore2 active"><span>Know More</span></a> <a href="#" class="btn-Bookapopoi"><span>Book an appointment</span></a> </div>
                        </div>
                    </li>
                    <li>
                        <div class="col-xs-12 col-sm-6 col-md-3">
                            <div class="doctorsListwrp">
                                <div class="dthumbwrp"> <img src="assets/images/doc-thumb2.png" alt=""> </div>
                                <div class="ddetailswrp">
                                    <h3>Dr. Ramesh Kancharla</h3>
                                    <p> Pediatrician &amp; Gastroenterologist, Managing Director </p>
                                </div>
                                <div class="clr"></div>
                                <a href="#" class="knowmore2 active"><span>Know More</span></a> <a href="#" class="btn-Bookapopoi"><span>Book an appointment</span></a> </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-3">
                            <div class="doctorsListwrp">
                                <div class="dthumbwrp"> <img src="assets/images/doc-thumb1.png" alt=""> </div>
                                <div class="ddetailswrp">
                                    <h3>Dr. A. Venkatalakshmi</h3>
                                    <p> Neonatologist & Pediatrician</p>
                                </div>
                                <div class="clr"></div>
                                <a href="#" class="knowmore2 active"><span>Know More</span></a> <a href="#" class="btn-Bookapopoi"><span>Book an appointment</span></a> </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-3">
                            <div class="doctorsListwrp">
                                <div class="dthumbwrp"> <img src="assets/images/doc-thumb3.png" alt=""> </div>
                                <div class="ddetailswrp">
                                    <h3>Dr. Dinesh Kumar Chirla</h3>
                                    <p> Director Intensive Care Services</p>
                                </div>
                                <div class="clr"></div>
                                <a href="#" class="knowmore2 active"><span>Know More</span></a> <a href="#" class="btn-Bookapopoi"><span>Book an appointment</span></a> </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-3">
                            <div class="doctorsListwrp">
                                <div class="dthumbwrp"> <img src="assets/images/doc-thumb4.png" alt=""> </div>
                                <div class="ddetailswrp">
                                    <h3>Dr. Faisal B Nahdi</h3>
                                    <p>Pediatrician</p>
                                </div>
                                <div class="clr"></div>
                                <a href="#" class="knowmore2 active"><span>Know More</span></a> <a href="#" class="btn-Bookapopoi"><span>Book an appointment</span></a> </div>
                        </div>
                    </li>
                    <li>
                        <div class="col-xs-12 col-sm-6 col-md-3">
                            <div class="doctorsListwrp">
                                <div class="dthumbwrp"> <img src="assets/images/doc-thumb1.png" alt=""> </div>
                                <div class="ddetailswrp">
                                    <h3>Dr. A. Venkatalakshmi</h3>
                                    <p> Neonatologist & Pediatrician</p>
                                </div>
                                <div class="clr"></div>
                                <a href="#" class="knowmore2 active"><span>Know More</span></a> <a href="#" class="btn-Bookapopoi"><span>Book an appointment</span></a> </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-3">
                            <div class="doctorsListwrp">
                                <div class="dthumbwrp"> <img src="assets/images/doc-thumb2.png" alt=""> </div>
                                <div class="ddetailswrp">
                                    <h3>Dr. Ramesh Kancharla</h3>
                                    <p> Pediatrician &amp; Gastroenterologist, Managing Director </p>
                                </div>
                                <div class="clr"></div>
                                <a href="#" class="knowmore2 active"><span>Know More</span></a> <a href="#" class="btn-Bookapopoi"><span>Book an appointment</span></a> </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-3">
                            <div class="doctorsListwrp">
                                <div class="dthumbwrp"> <img src="assets/images/doc-thumb4.png" alt=""> </div>
                                <div class="ddetailswrp">
                                    <h3>Dr. Faisal B Nahdi</h3>
                                    <p>Pediatrician</p>
                                </div>
                                <div class="clr"></div>
                                <a href="#" class="knowmore2 active"><span>Know More</span></a> <a href="#" class="btn-Bookapopoi"><span>Book an appointment</span></a> </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-3">
                            <div class="doctorsListwrp">
                                <div class="dthumbwrp"> <img src="assets/images/doc-thumb3.png" alt=""> </div>
                                <div class="ddetailswrp">
                                    <h3>Dr. Dinesh Kumar Chirla</h3>
                                    <p> Director Intensive Care Services</p>
                                </div>
                                <div class="clr"></div>
                                <a href="#" class="knowmore2 active"><span>Know More</span></a> <a href="#" class="btn-Bookapopoi"><span>Book an appointment</span></a> </div>
                        </div>
                    </li>
                    <li>
                        <div class="col-xs-12 col-sm-6 col-md-3">
                            <div class="doctorsListwrp">
                                <div class="dthumbwrp"> <img src="assets/images/doc-thumb2.png" alt=""> </div>
                                <div class="ddetailswrp">
                                    <h3>Dr. Ramesh Kancharla</h3>
                                    <p> Pediatrician &amp; Gastroenterologist, Managing Director </p>
                                </div>
                                <div class="clr"></div>
                                <a href="#" class="knowmore2 active"><span>Know More</span></a> <a href="#" class="btn-Bookapopoi"><span>Book an appointment</span></a> </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-3">
                            <div class="doctorsListwrp">
                                <div class="dthumbwrp"> <img src="assets/images/doc-thumb1.png" alt=""> </div>
                                <div class="ddetailswrp">
                                    <h3>Dr. A. Venkatalakshmi</h3>
                                    <p> Neonatologist & Pediatrician</p>
                                </div>
                                <div class="clr"></div>
                                <a href="#" class="knowmore2 active"><span>Know More</span></a> <a href="#" class="btn-Bookapopoi"><span>Book an appointment</span></a> </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-3">
                            <div class="doctorsListwrp">
                                <div class="dthumbwrp"> <img src="assets/images/doc-thumb3.png" alt=""> </div>
                                <div class="ddetailswrp">
                                    <h3>Dr. Dinesh Kumar Chirla</h3>
                                    <p> Director Intensive Care Services</p>
                                </div>
                                <div class="clr"></div>
                                <a href="#" class="knowmore2 active"><span>Know More</span></a> <a href="#" class="btn-Bookapopoi"><span>Book an appointment</span></a> </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-3">
                            <div class="doctorsListwrp">
                                <div class="dthumbwrp"> <img src="assets/images/doc-thumb4.png" alt=""> </div>
                                <div class="ddetailswrp">
                                    <h3>Dr. Faisal B Nahdi</h3>
                                    <p>Pediatrician</p>
                                </div>
                                <div class="clr"></div>
                                <a href="#" class="knowmore2 active"><span>Know More</span></a> <a href="#" class="btn-Bookapopoi"><span>Book an appointment</span></a> </div>
                        </div>
                    </li>
                    <li>
                        <div class="col-xs-12 col-sm-6 col-md-3">
                            <div class="doctorsListwrp">
                                <div class="dthumbwrp"> <img src="assets/images/doc-thumb1.png" alt=""> </div>
                                <div class="ddetailswrp">
                                    <h3>Dr. A. Venkatalakshmi</h3>
                                    <p> Neonatologist & Pediatrician</p>
                                </div>
                                <div class="clr"></div>
                                <a href="#" class="knowmore2 active"><span>Know More</span></a> <a href="#" class="btn-Bookapopoi"><span>Book an appointment</span></a> </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-3">
                            <div class="doctorsListwrp">
                                <div class="dthumbwrp"> <img src="assets/images/doc-thumb2.png" alt=""> </div>
                                <div class="ddetailswrp">
                                    <h3>Dr. Ramesh Kancharla</h3>
                                    <p> Pediatrician &amp; Gastroenterologist, Managing Director </p>
                                </div>
                                <div class="clr"></div>
                                <a href="#" class="knowmore2 active"><span>Know More</span></a> <a href="#" class="btn-Bookapopoi"><span>Book an appointment</span></a> </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-3">
                            <div class="doctorsListwrp">
                                <div class="dthumbwrp"> <img src="assets/images/doc-thumb4.png" alt=""> </div>
                                <div class="ddetailswrp">
                                    <h3>Dr. Faisal B Nahdi</h3>
                                    <p>Pediatrician</p>
                                </div>
                                <div class="clr"></div>
                                <a href="#" class="knowmore2 active"><span>Know More</span></a> <a href="#" class="btn-Bookapopoi"><span>Book an appointment</span></a> </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-3">
                            <div class="doctorsListwrp">
                                <div class="dthumbwrp"> <img src="assets/images/doc-thumb3.png" alt=""> </div>
                                <div class="ddetailswrp">
                                    <h3>Dr. Dinesh Kumar Chirla</h3>
                                    <p> Director Intensive Care Services</p>
                                </div>
                                <div class="clr"></div>
                                <a href="#" class="knowmore2 active"><span>Know More</span></a> <a href="#" class="btn-Bookapopoi"><span>Book an appointment</span></a> </div>
                        </div>
                    </li>
                    <li>
                        <div class="col-xs-12 col-sm-6 col-md-3">
                            <div class="doctorsListwrp">
                                <div class="dthumbwrp"> <img src="assets/images/doc-thumb2.png" alt=""> </div>
                                <div class="ddetailswrp">
                                    <h3>Dr. Ramesh Kancharla</h3>
                                    <p> Pediatrician &amp; Gastroenterologist, Managing Director </p>
                                </div>
                                <div class="clr"></div>
                                <a href="#" class="knowmore2 active"><span>Know More</span></a> <a href="#" class="btn-Bookapopoi"><span>Book an appointment</span></a> </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-3">
                            <div class="doctorsListwrp">
                                <div class="dthumbwrp"> <img src="assets/images/doc-thumb1.png" alt=""> </div>
                                <div class="ddetailswrp">
                                    <h3>Dr. A. Venkatalakshmi</h3>
                                    <p> Neonatologist & Pediatrician</p>
                                </div>
                                <div class="clr"></div>
                                <a href="#" class="knowmore2 active"><span>Know More</span></a> <a href="#" class="btn-Bookapopoi"><span>Book an appointment</span></a> </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-3">
                            <div class="doctorsListwrp">
                                <div class="dthumbwrp"> <img src="assets/images/doc-thumb3.png" alt=""> </div>
                                <div class="ddetailswrp">
                                    <h3>Dr. Dinesh Kumar Chirla</h3>
                                    <p> Director Intensive Care Services</p>
                                </div>
                                <div class="clr"></div>
                                <a href="#" class="knowmore2 active"><span>Know More</span></a> <a href="#" class="btn-Bookapopoi"><span>Book an appointment</span></a> </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-3">
                            <div class="doctorsListwrp">
                                <div class="dthumbwrp"> <img src="assets/images/doc-thumb4.png" alt=""> </div>
                                <div class="ddetailswrp">
                                    <h3>Dr. Faisal B Nahdi</h3>
                                    <p>Pediatrician</p>
                                </div>
                                <div class="clr"></div>
                                <a href="#" class="knowmore2 active"><span>Know More</span></a> <a href="#" class="btn-Bookapopoi"><span>Book an appointment</span></a> </div>
                        </div>
                    </li>
                    <li>
                        <div class="col-xs-12 col-sm-6 col-md-3">
                            <div class="doctorsListwrp">
                                <div class="dthumbwrp"> <img src="assets/images/doc-thumb1.png" alt=""> </div>
                                <div class="ddetailswrp">
                                    <h3>Dr. A. Venkatalakshmi</h3>
                                    <p> Neonatologist & Pediatrician</p>
                                </div>
                                <div class="clr"></div>
                                <a href="#" class="knowmore2 active"><span>Know More</span></a> <a href="#" class="btn-Bookapopoi"><span>Book an appointment</span></a> </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-3">
                            <div class="doctorsListwrp">
                                <div class="dthumbwrp"> <img src="assets/images/doc-thumb2.png" alt=""> </div>
                                <div class="ddetailswrp">
                                    <h3>Dr. Ramesh Kancharla</h3>
                                    <p> Pediatrician &amp; Gastroenterologist, Managing Director </p>
                                </div>
                                <div class="clr"></div>
                                <a href="#" class="knowmore2 active"><span>Know More</span></a> <a href="#" class="btn-Bookapopoi"><span>Book an appointment</span></a> </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-3">
                            <div class="doctorsListwrp">
                                <div class="dthumbwrp"> <img src="assets/images/doc-thumb4.png" alt=""> </div>
                                <div class="ddetailswrp">
                                    <h3>Dr. Faisal B Nahdi</h3>
                                    <p>Pediatrician</p>
                                </div>
                                <div class="clr"></div>
                                <a href="#" class="knowmore2 active"><span>Know More</span></a> <a href="#" class="btn-Bookapopoi"><span>Book an appointment</span></a> </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-3">
                            <div class="doctorsListwrp">
                                <div class="dthumbwrp"> <img src="assets/images/doc-thumb3.png" alt=""> </div>
                                <div class="ddetailswrp">
                                    <h3>Dr. Dinesh Kumar Chirla</h3>
                                    <p> Director Intensive Care Services</p>
                                </div>
                                <div class="clr"></div>
                                <a href="#" class="knowmore2 active"><span>Know More</span></a> <a href="#" class="btn-Bookapopoi"><span>Book an appointment</span></a> </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </section>
    <section>
        <div class="container">
            <div class="visitor-head col-lg-12">
                <div class="visitor-icon"><img src="{{imgBasePath}}{{services.image}}" alt=""></div>
                <h2 class="textgradient fontsize">{{services.title}}</h2>
            </div>
            <div class="visitext col-lg-12">
                <p [innerHTML]="services.description"></p>
            </div>
        </div>
    </section>
    <section class="gmapwrp row">
        <div class="sidelocdetwrp">
            <div class="gmheader">
                <div class="box">
                    <select name="textMap" id="textMap" class="select">
          <option selected>Find a Center</option>
          <option value="Center1">Center 1</option>
          <option value="Center2">Center 2</option>
          <option value="Center3">Center 3</option>
        </select>
                    <span id="selectcity" class="select-span"></span> </div>
                <div class="box">
                    <input type="text" name="txtMapsearch" class="msearch" />
                    <input type="submit" name="txtMsubmit" class="mssubmit" value="" />
                </div>
            </div>
            <div class="mimgrolatedwrp">
                <div class="mimgl">
                    <a href="#" class="rolink"><img src="assets/images/icon-360.png" alt="" /></a> <img src="assets/images/mimg.jpg" alt="" /> </div>
            </div>
            <div class="mdrwrp">
                <div class="mtitle">
                    <p>Rainbow Children's Hospital & BirthRight, Banjara Hills</p>
                </div>
                <div class="icondr">
                    <a href="#"><img src="assets/images/icon_dir.png" alt="" /></a>
                </div>
            </div>
            <div class="mdetailwrp">
                <ul>
                    <li>
                        <p>22, Road No - 4, (old road no-10), Karvy Lanes, Banjara Hills, Hyderabad - 500 034, Telangana, INDIA.</p>
                    </li>
                    <li class="phone"><strong>+91-40-2331 9191 / +91-40-2331 9191</strong></li>
                    <li class="mail"><a href="mailto:info@rainbowhospitals.in">info@rainbowhospitals.in</a></li>
                    <div class="clearfix"></div>
                    <li class="view-map"><a href="javascript:void()">view-map</a></li>
                </ul>
            </div>
        </div>
        <div class="gmap  mapshow-mob" id="google-container"> </div>
    </section>
    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>