import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TransferHttp } from '../../modules/transfer-http/transfer-http';
// import { Http, RequestOptions, URLSearchParams, Headers,Response } from "@angular/http";

import { API_BASE_URL, IMG_BASE_URL } from './constants';
import { isPlatformBrowser } from '@angular/common';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ApiClick2Call {
  public isBrowser: boolean = isPlatformBrowser(this.platform_id);
  public api_url: any = `https://telephonycloud.co.in/api/v1/`; //live server

  constructor(
    @Inject(PLATFORM_ID) private platform_id,
    private tHttp: TransferHttp,
    private http: HttpClient
  ) {}
  getOTP(mobile: number) {
    this.http.get(`${API_BASE_URL}home/user_send_otp?number=${mobile}`);
  }
}
