<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>

    <section class="page wtwedo">
        <div class="row">
            <div class="col-md-5 col-xs-5 col-lg-4">
                <div class="circuleframe">
                    <img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" />
                </div>
                <!-- End Circle -->
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7">
                <div class="pagetitle">
                    <h1 class="tgradient">{{title}} </h1>
                    <div class="underline">
                        <img src="assets/images/underline2.png" alt="" />
                    </div>
                    <h3>{{subtitle}}</h3>
                </div>
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7 mcontentview">
                <p [innerHTML]="description"></p>
            </div>
        </div>

    </section>
    <section class="aboutsectin2 awardswapper">
        <div class="container">
            <div class="row nsg_text">
                <div class="col-xs-12 col-lg-12">
                    <h2>What is a blood transfusion?</h2>
                    <p>A blood transfusion involves the transfer of blood or blood components from one person (the donor) to another person (the recipient). A blood transfusion can be a life-saving process. It is often done to replace blood that has been
                        lost due to severe bleeding or in some cases for the treatment of severe anaemia (low hemoglobin count).</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>Why is blood important?</h2>
                    <p>Blood is important because it supplies your body with the oxygen and nutrients it needs, to function. Blood also carries away waste products such as carbon dioxide.</p>
                    <p>Blood is made up of red blood cells, platelets and white blood cells in a fluid called plasma. These components each have different jobs to do.</p>
                    <ul>
                        <li>Red blood cells contain an iron-rich pigment called haemoglobin which carries oxygen around the body. You need a certain level of healthy haemoglobin in your blood.</li>
                        <li>Platelets help control bleeding by making the blood clot, where there is a cut or wound.</li>
                        <li>White blood cells fight infection and form the body’s defense system (immune system)</li>
                    </ul>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>What is anaemia?</h2>
                    <p>Anaemia is when the level of healthy haemoglobin in your blood is lower than normal; it can be mild or severe. Anaemia can cause tiredness, breathlessness, fainting, headaches and your heart to beat faster. Mild anaemia is common during
                        pregnancy and your haemoglobin level will be routinely checked at your first pregnancy appointment and at 28 weeks .</p>
                    <p>Severe anaemia is when the level of healthy haemoglobin is very much lower than normal. It can make you feel very unwell with chest pain and breathlessness.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>Why is a blood transfusion needed?</h2>
                    <p><strong>In an emergency situation</strong></p>
                    <p>When you haemorrhage (bleed very heavily), this is an emergency situation. As a result of this bleeding, you can become severely anaemic. Without a transfusion to replace the blood you have lost, you could die. A haemorrhage can happen:</p>
                    <ul>
                        <li>early in pregnancy if you have an ectopic pregnancy (when the pregnancy is growing outside the uterus) or a miscarriage</li>
                        <li>after 24 weeks of pregnancy (antepartum haemorrhage)</li>
                        <li>during birth (intrapartum haemorrhage)</li>
                        <li>immediately after birth (postpartum haemorrhage)</li>
                    </ul>
                    <p>Even with excellent care in pregnancy and monitoring during labour, it is not possible to predict or detect every complication in time to prevent a life-threatening bleed. Surgical techniques and medication will be used to try to limit
                        the need for a blood transfusion but a blood transfusion might be needed to save your life or to prevent serious harm to your health and your baby’s health.</p>
                    <p><strong>In a non-emergency situation</strong></p>
                    <p>You may be offered a blood transfusion in a non-emergency situation when:</p>
                    <ul>
                        <li>You have anaemia</li>
                        <ul>
                            <li>If you have anaemia just before you are expecting your baby, there is a risk that, if you bleed even a small amount during birth, you may become severely anaemic.</li>
                            <li>If you have anaemia immediately after birth, you may be offered a blood transfusion, depending on your symptoms.</li>
                            <li>If you have a blood condition, such as sickle cell disease or thalassaemia, it affects your body’s ability to produce healthy haemoglobin. You have an increased risk of developing severe anaemia when you become pregnancy. You
                                will have specialist care during pregnancy.</li>
                        </ul>
                        <li>You bleed heavily during or after birth</li>
                    </ul>
                    <p>If you bled heavily during birth and the bleeding has stopped, your haemoglobin level will be monitored. If you are very anaemic and unwell, making it difficult for you to care for your baby, you may be offered a blood transfusion
                        to restore your haemoglobin level. Your doctor may discuss the option of avoiding a transfusion and using alternative methods to restore the haemoglobin to normal</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>About blood transfusion</h2>
                    <p><strong>How safe is the blood I get?</strong></p>
                    <p>All blood donations should be tested for viruses such as hepatitis and HIV. Only blood that is free from these infections should be used in a blood transfusion. The chance of getting an infection from a blood transfusion is very, very
                        rare.</p>
                    <p><strong>How is the blood matched?</strong></p>
                    <p>There are four main blood groups: A, B, AB and O. Blood is also rhesus (RhD) positive or negative. In the laboratory your blood is tested and compared with the donor blood to make sure that it matches.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>During a transfusion</h2>
                    <p>Most transfusions during pregnancy and after birth are given as red blood cells only. Very occasionally, platelets and plasma may be required as well.</p>
                    <p>A cannula (small tube) is placed into a vein in your hand or arm. The tube is attached to a drip and donor blood flows through the drip. Blood for transfusion is stored in small plastic bags containing a unit of blood which is about
                        a third of a litre. Each unit of blood takes about 3 hours to transfuse. In an emergency, blood may be transfused more quickly.</p>
                    <p>You are carefully monitored before and during the transfusion. Your midwife will take your blood pressure, temperature and heart rate during the transfusion.</p>
                    <p>Some people get mild side effects, such as headaches, chills and fever, a rash and itchiness. These symptoms are relieved by drugs, such as paracetamol, and will improve within a day or so.</p>
                    <p>Very rarely, there may be more severe side effects, including difficulty in breathing, severe headaches and a sudden fall in blood pressure which can be life-threatening.</p>
                    <p>If you get side effects, the transfusion will be stopped immediately and the situation reviewed.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>After a transfusion</h2>
                    <p>Once all the blood has been transfused, the drip is taken down. Your haemoglobin level will be re-checked to make sure that you have had received enough blood. Most women do not need another transfusion.</p>
                    <p>If the blood transfusion is done because of an emergency, you will need to stay in hospital overnight and sometimes for some days afterwards. The length of time will depend upon your situation.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>Making the decision to have a blood transfusion</h2>
                    <p>If you are offered a blood transfusion, make sure you have all the information you need to make an informed decision. Ask for information about all your options (see Is a blood transfusion my only option?). If you have any concerns
                        about having a blood transfusion, speak with your obstetrician or midwife.</p>
                    <p><strong>What happens in an emergency?</strong></p>
                    <p>In an emergency your doctors need to act immediately. Your obstetrician, anaesthetist and haematologist (a doctor who specialises in the treatment of diseases of the blood) will make an informed decision on your behalf for you to have
                        a blood transfusion.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>Is a blood transfusion my only option?</h2>
                    <p><strong>Iron supplements</strong></p>
                    <p>If you have anaemia because of blood loss or lack of iron, you may be offered iron supplements to restore your haemoglobin level instead of a blood transfusion. Iron supplements can be taken as tablets or syrup. If you are unable to
                        take iron tablets, you can have iron through a drip. You may also need to take folic acid, in addition to taking iron, to raise your haemoglobin level.</p>
                    <p>There are advantages and disadvantages of taking iron supplements. It will take longer to feel completely well but you avoid the minimal risks associated with blood transfusion. If you can cope with your symptoms and have support at
                        home while you recover, you may decide to take iron supplements rather than having a blood transfusion. Iron supplements may be particularly suitable if your symptoms are mild.</p>
                    <p>Storing your own blood for a future transfusion It is not recommended to use your own blood for transfusion during pregnancy because it can only be stored for five weeks. Giving blood in late pregnancy may make you anaemic before delivery
                        and reduce the supply of oxygen to your baby through the placenta.</p>
                    <p>Replacing your own lost blood back into your bloodstream (cell salvage) If you have had a caesarean delivery, the doctor may be able to collect the blood lost and replace this back into your bloodstream. Trained staff and specialist
                        equipment are required for this, which may not be available in your hospital at all times.</p>
                    <p><strong>What can I do to prevent anaemia?</strong></p>
                    <p>To produce healthy haemoglobin, the body needs (among other things) iron, vitamin B12 and folic acid. If there is a lack of one or more of these, you become anaemic.</p>
                    <p>The additional demands of pregnancy can increase the risk of anaemia. There are some positive steps you can take to ensure that you do not become anaemic during this time. You can do this by:</p>
                    <ul>
                        <li>having a varied diet</li>
                        <li>having enough iron in your diet (iron-containing foods include</li>
                        <li>meat, poultry, eggs, vegetables and cereals)</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <ng-template [ngIf]="ins_links.length > 0">
        <footer-nav-mini [links]="ins_links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>