<div class="container-fluid">
    <div class="background">
        <img src="assets/images/bg_childcare.png" data-t="bg_01_00.jpg" data-d="bg_childcare.png" class="bgImg bg0 active" />
    </div>
    <div class="loder"></div>
    <sidenav></sidenav>
    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <section class="page pageheader">
        <div class="row">
            <div class="container">
                <div class="col-xs-12 col-sm-7 col-lg-7 abannimgwrp">
                    <div class="aboutbannimg"><img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" /></div>
                </div>
                <div class="col-xs-12 col-sm-5 col-lg-5">
                    <div class="ptitle blue">
                        <h1>{{title}}</h1>
                        <h3>{{subtitle}}</h3>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-lg-12">
                    <p [innerHTML]="description"></p>
                </div>
            </div>
        </div>
    </section>
    <section class="aboutsectin2 rachieved">
        <div class="container">
            <div #atabs class="row">
                <div class="col-md-12 col-sm-12 col-lg-12">
                    <h3 class="sinfo">Rainbow has achieved the following milestones:</h3>
                    <ul class="atabs">
                        <li *ngFor="let service of services; let i = index;" [ngClass]="{'active': i == 1}">
                            <a href="javascript:void()" data-id="data1">
                                <div class="aiconwrp"><img src="{{imgBasePath}}{{service.image}}" alt="" /></div>
                                <h2>{{service.title}}</h2>
                            </a>
                        </li>
                    </ul>
                    <div class="atabscontent">
                        <div class="atabsdata" *ngFor="let service of services">
                            <p [innerHTML]="service.description"></p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="page apagewht">
        <div class="row">
            <div class="container">
                <div class="col-xs-12 col-sm-12 col-lg-12" *ngFor="let description_section of description_sections">
                    <ul class="cemslist">
                        <li>
                            <div class="row">
                                <div class="cellwrp1">
                                    <h3>{{description_section.title}}</h3>
                                    <p [innerHTML]="description_section.description"></p>
                                </div>
                                <div class="cellwrp2">
                                    <div class="athumbwrp">
                                        <img src="{{imgBasePath}}{{description_section.image}}" alt="" />
                                    </div>
                                </div>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>
        </div>
    </section>

    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>