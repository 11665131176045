import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL } from '../services/constants';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
// declare let initAboutTabs:any;

@Component({
  selector: 'articles-details',
  templateUrl: './articles-details.component.html',
})
export class ArticleDetailsComponent
  implements OnDestroy, OnInit, AfterViewInit
{
  constructor(
    private api: ApiService,
    private activatedRoute: ActivatedRoute,
    public metaTitle: Title,
    public meta: Meta,
    private _sanitizer: DomSanitizer
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
    }
  }

  public bread_crumb_title: any;
  public bread_crumb_title_length: any;
  public safeURL: any;
  public videoUrl: boolean = false;
  public eventDetail: any = [];
  public slug: string;
  public title: any = '';
  public subtitle: any = '';
  public description: any = '';
  public imgsrc: any = '';
  public brief_facilities_description: any = '';
  public brief_facilities_image: any = '';
  public imgBasePath = '';
  public hidden = 'hidden';
  public logo = 'logo';
  public aArticles_events: any;
  public bread_crumb_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Other', link: '/other', class: '' },
    { label: 'Academic Article', link: '/other/academic-article', class: '' },
  ];

  public links: any = [];
  public activePage: string = 'events';

  public id: any = '';
  // @ViewChildren('abouttabs') things: QueryList<any>;

  ngOnInit() {
    this.api
      .getAboutSubFooterDetails()
      .map((res) => res)
      .subscribe((data) => {
        this.links = data;
      });
    this.activatedRoute.params.subscribe((params: Params) => {
      this.id = params['slug'];
    });

    /*this.api.getAcademicArticleDetail(this.id).subscribe(
        data =>{
          
          this.eventDetail = data[0];
          this.bread_crumb_title = this.eventDetail.name;
          this.bread_crumb_title_length =  this.bread_crumb_title.length;
          //console.log(this.eventDetail.video);
          if(this.eventDetail.video !== null && this.eventDetail.video !== ""){  console.log('if', this.eventDetail.video);
            this.videoUrl = true;
          }
          this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl( this.eventDetail.video);
        
          this.slug = this.eventDetail.slug;
          if(data){
            //console.log(data);
            this.metaTitle.setTitle(this.eventDetail.meta_title);
            this.meta.updateTag({name:'keywords',content: this.eventDetail.meta_keyword});
            this.meta.updateTag({name:'description',content: this.eventDetail.meta_description});
            this.meta.addTags([
              { property: 'og:title', content:  this.eventDetail.meta_title},
              { property: 'og:description', content: this.eventDetail.meta_description },
              { property: 'og:keywords', content: this.eventDetail.meta_keyword }
            ]);
            }
         
          this.api.getPageDescription(this.slug).map(res => res)
          .subscribe((data)=>{
          
           if(JSON.stringify(data).length > 100){
            this.getDescription(data);
           }else{
              this.slug = 'events-details';
              this.api.getPageDescription(this.slug).subscribe((data)=>{
                //console.log('error=>>',data)
                this.getDescription(data);
              })
           }
          },err =>{
            console.log('err');
           
          });
          //console.log(this.eventDetail);
        },
        error => {
          console.log(error);
        }
      )*/
    this.getContentHubListingAcademicArticles();
  }
  public getDescription(data) {
    // console.log(data);
    (this.title = data.page_descriptions[0].title),
      (this.subtitle = data.page_descriptions[0].subtitle),
      (this.description = data.page_descriptions[0].description),
      (this.imgsrc = data.page_descriptions[0].image),
      (this.imgBasePath = IMG_BASE_URL);
  }
  getAcademicArticles() {
    this.api.getAcademicArticles().subscribe((data) => {
      console.log('getAcademicArticles: ', data);
      this.aArticles_events = data;
    });
  }
  getContentHubListingAcademicArticles() {
    this.api.getContentHubListingAcademicArticles(3).subscribe((data) => {
      //console.log('getContentHubListingAcademicArticles: ', data);
      let aDetailsData: any = [];
      data.forEach((element) => {
        let cheading = element.contentheading;
        cheading = cheading.replace(/ /gi, '-');
        if (cheading === this.id) {
          aDetailsData.push(element);
        }
      });
      this.eventDetail = aDetailsData[0];
      //console.log('eventDetail: ', this.eventDetail);
      this.bread_crumb_title = this.eventDetail.contentheading;
      this.bread_crumb_title_length = this.bread_crumb_title.length;
      //console.log(this.eventDetail.video);
      /*
          if(this.eventDetail.video !== null && this.eventDetail.video !== ""){  console.log('if', this.eventDetail.video);
            this.videoUrl = true;
          }
          this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl( this.eventDetail.video);
        */
      this.slug = this.eventDetail.contentheading;
      if (data) {
        //console.log(data);
        this.metaTitle.setTitle(this.eventDetail.metatitle);
        this.meta.updateTag({
          name: 'keywords',
          content: this.eventDetail.metakeyword,
        });
        this.meta.updateTag({
          name: 'description',
          content: this.eventDetail.metadescription,
        });
        this.meta.addTags([
          { property: 'og:title', content: this.eventDetail.metatitle },
          {
            property: 'og:description',
            content: this.eventDetail.metadescription,
          },
          { property: 'og:keywords', content: this.eventDetail.metakeyword },
        ]);
      }
    });
  }
  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
    }

    // this.things.changes.subscribe(t => {
    //   // this.ngForRendred();
    //   initAboutTabs();
    // })
  }

  ngOnDestroy() {}
}
