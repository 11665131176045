<!-- <!DOCTYPE html>
<html lang="en">

<head> -->

    <!-- Basic Page Needs
	================================================== -->
    <!-- <meta charset="utf-8">
    <title>Rainbow Hospital</title> -->

    <!-- Mobile Specific Metas
	================================================== -->

    <!-- <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"> -->

    <!--Favicon-->
    <!-- <link rel="shortcut icon" href="assets/international-patients/images/favicon.ico" type="image/x-icon"> -->
    <!-- <link rel="icon" href="assets/international-patients/images/favicon.ico" type="image/x-icon"> -->

    <!-- CSS
	================================================== -->

    <!-- Bootstrap -->
    <!-- <link rel="stylesheet" href="international-patients/css/bootstrap.min.css"> -->
    <!-- Template styles-->
    <!-- <link rel="stylesheet" href="international-patients/css/style.css?ver=1.1"> -->
    <!-- Responsive styles-->
    <!-- <link rel="stylesheet" href="international-patients/css/responsive.css?ver=1.1"> -->
    <!-- FontAwesome -->
    <!-- <link rel="stylesheet" href="international-patients/css/font-awesome.min.css"> -->
    <!-- Animation -->
    <!-- <link rel="stylesheet" href="international-patients/css/animate.css"> -->
    <!-- Owl Carousel -->
    <!-- <link rel="stylesheet" href="international-patients/css/owl.carousel.min.css"> -->
    <!-- <link rel="stylesheet" href="international-patients/css/owl.theme.default.min.css"> -->
    <!-- Colorbox -->
    <!-- <link rel="stylesheet" href="international-patients/css/colorbox.css"> -->
    <!-- <link rel="stylesheet" href="international-patients/css/intlTelInput.css"> -->

    <!-- HTML5 shim, for IE6-8 support of HTML5 elements. All other JS at the end of file. -->
    <!--[if lt IE 9]>
      <script src="international-patients/js/html5shiv.js"></script>
      <script src="international-patients/js/respond.min.js"></script>
    <![endif]-->
    <!-- Global site tag (gtag.js) - Google Ads: 755747868 -->
<!-- <script async src="https://www.googletagmanager.com/gtag/js?id=AW-755747868"></script> -->
<!-- <script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'AW-755747868');
</script> -->
<!-- Google Tag Manager -->
<!-- <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-5C83MFQ');</script> -->
<!-- End Google Tag Manager -->
<!-- </head> -->

<!-- <body>
Google Tag Manager (noscript)
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5C83MFQ"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript> -->
<!-- End Google Tag Manager (noscript) -->
    <div class="body-inner">


        <!-- Header start -->
        <header id="header" class="header">
            <div class="container">
                <div class="row">
                    <div class="navbar-header">
                        <div class="logo">
                            <a href="#">
	                	<img src="assets/international-patients/images/logo.png" alt="">
	                </a>
                        </div>
                        <!-- logo end -->
                    </div>
                    <!-- Navbar header end -->

                    <div class="site-nav-inner">
                        <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
				      <span class="sr-only">Toggle navigation</span>
				      <span class="icon-bar"></span>
				      <span class="icon-bar"></span>
				      <span class="icon-bar"></span>
	    			</button>

                        <nav class="collapse navbar-collapse navbar-responsive-collapse pull-right">

                            <ul class="nav navbar-nav">
<!--
                                <li class="dropdown ">
                                    <a href="#" class="dropdown-toggle6" data-toggle="dropdown" id="ban">Scroll-Up</a>

                                </li>
-->

                                <li class="dropdown">
                                    <a href="#" id="spe" class="dropdown-toggle" data-toggle="dropdown">Specialities</a>

                                </li>

                                <li class="dropdown">
                                    <a href="#" id="gas" class="dropdown-toggle" data-toggle="dropdown">Plan Your Visit  </a>

                                </li>
                                <li class="dropdown">
                                    <a href="#" class="dropdown-toggle custm" data-toggle="dropdown" id="enq">Enquire Now </a>

                                </li>

<!--
                                <li class="dropdown">
                                    <a href="#" class="dropdown-toggle" data-toggle="dropdown">Contact Us</a>

                                </li>
-->



                            </ul>
                            <!--/ Nav ul end -->

                        </nav>
                        <!--/ Collapse end -->

                        <div class="search" style="display: none;">
                            <input type="text" class="form-control" placeholder="Type what you want and enter">
                            <span class="search-close">&times;</span>
                        </div>
                        <!-- Site search end -->

                    </div>
                    <!-- Site nav inner end -->

                </div>
                <!-- Row end -->
            </div>
            <!-- Container end -->
        </header>
        <!--/ Header end -->

        <!-- Carousel -->
        <div id="main-slide" class="carousel slide" data-ride="carousel" id="bann">

            <!-- Indicators -->
            <ol class="carousel-indicators visible-lg visible-md">
                <li data-target="#main-slide" data-slide-to="0" class="active"></li>
                <li data-target="#main-slide" data-slide-to="1"></li>
                <li data-target="#main-slide" data-slide-to="2"></li>
                <li data-target="#main-slide" data-slide-to="3"></li>
            </ol>
            <!--/ Indicators end-->

            <!-- Carousel inner -->
            <div class="carousel-inner">

                <div class="item active" style="background-image:url(assets/international-patients/images/slider-main/slide1.jpg)">

                    <div class="slider-content text-left">
               <div class="col-md-12">
                  <h2 class="slide-title-box animated3">India’s largest children’s private hospital</h2>
                  <div class="animated3">
                    <ul>
<li>Over 1300 bed network located in 4 Metros</li>
<li>Largest pediatric ICU infrastructure in India</li>
<li>Network of 13 hospitals in India</li>


                    </ul>
                </div>       
               </div>
            </div>

                </div>
                <!--/ Carousel item 1 end -->

                <div class="item" style="background-image:url(assets/international-patients/images/slider-main/slide2.jpg)">


<div class="slider-content text-left">
               <div class="col-md-12">
                  <h2 class="slide-title-box animated3">Number 1 children's hospital in India</h2>
                  <div class="animated3">
                    <ul>
<li>Times Health Critical Care Survey- 2018</li>
<li>1st NABH accredited corporate children’s hospital</li>
<li>34 pediatric super-specialties under 1 roof</li>


                    </ul>
                </div>       
               </div>
            </div>


                </div>
                <!--/ Carousel item 2 end -->

                <div class="item" style="background-image:url(assets/international-patients/images/slider-main/slide3.jpg)">



                    <div class="slider-content text-left">
               <div class="col-md-12">
                  <h2 class="slide-title-box animated3">Comprehensive pediatric, women and fertility care</h2>
                  <div class="animated3">
                    <ul>

<li>Over 2000 critically ill children treated in PICU annually</li>
<li>600,000 pediatric patients registered every year</li>


                    </ul>
                </div>       
               </div>
            </div>


                </div>
                <!--/ Carousel item 3 end -->

                 <div class="item" style="background-image:url(international-patients/images/slider-main/slide4.jpg)">



                    <div class="slider-content text-left">
               <div class="col-md-12">
                  <h2 class="slide-title-box animated3">Lab strictly adheres to class 10,000 standard</h2>
                  <div class="animated3">
                    <ul>
<li>4000 minimal access pediatric surgeries performed annually</li>
<li>More than 3000 international patients treated in year 2018</li>



                    </ul>
                </div>       
               </div>
            </div>

                    
                </div>
                <!--/ Carousel item 4 end -->


            </div>
            <!-- Carousel inner end-->


        </div>
        <!--/ Carousel end -->

        <section id="top" class="enquiry ">
            
            <div class="container space">
                <div class="col-md-12">
                 <h2 class="mar">Greetings from Rainbow Children's Hospital, India!</h2>
                </div>
            <div class="col-md-3">
            <img src="assets/international-patients/images/helloo.png" style="width: 100%;">
            </div>
            <div class="col-md-9">
<!--                <h2 class="mar">Greetings from Rainbow Children's Hospital, India!</h2>-->
                <br>
<p>If you are a patient located outside India and would like to get in touch with us please fill the form below. We will get in touch with you.
<br>Rainbow Children’s Hospitals recognizes that international patients have unique needs and require personalized service for their treatments outside their country of origin. <br>
 At Rainbow Children’s Hospitals, we offer seamless service and end-to-end services in choosing the right Rainbow Children’s Hospital in India and the right doctor for you. <br>
From the warmth of our greeting at the airport, to your registration and discharge, we have created an unparalleled experience through our seamless services for our international guests.
</p>
            </div>
            </div>
            <div class="visit">
            <div class="container">
                <div class="row" id="enqu">
                    <div class="col-md-12">
                        <div class="enquirey-content">
                            <h2 class="text-center">Make An Enquiry | تحقيقات   | তদন্ত করতে</h2>
                            <p class="text-center">Fill the form below for a call back and FREE medical opinion from our doctors within 24 hours. <br>
املأ النموذجللحصول على مكالمة مجانية والتشاور في غضون 24 ساعة
<br>আমাদের তরফ থেকে কল পাওয়ার জন্য অথবা বিনামূল্যে চিকিৎসা সম্পর্কিত মতামত পাওয়ার জন্য নিচের ফর্ম পূরণ করুন।
</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <form id="enquiry-form" [formGroup] = "formdata" (submit)="formSubmit(formdata.value)" name="info_form" method="get" onsubmit="return(validate_form());">
                            <div class="error-container"></div>
                            <div class="row">
                                <div class="col-md-6 col-xs-12">
                                    <div class="form-group">

                                        <input class="form-control form-control-name" formControlName="name" name="name" id="name" placeholder="Full Name* | الاسم" type="text" required>
                                    </div>
                                </div>
                                <div class="col-md-6 col-xs-12 ">
                                <div class="col-md-4 col-xs-4 padingremove1 ">
                                    <div class="form-group mob-rpad">

                                        <input class="form-control form-control-email" formControlName="code" name="code" id="code" placeholder="Country Code*" type="tel" required>
                                    </div>
                                </div>
                                <div class="col-md-8 col-xs-8 padingremove2">
                                    <div class="form-group">

                                        <input class="form-control form-control-email" formControlName="phone" name="phone" id="mobile" placeholder="Your Mobile Number* | رقم الهاتف*" type="tel" required>
                                    </div>
                                </div>
                            </div>
                                <div class="col-md-6 col-xs-12">
                                    <div class="form-group">

                                        <input class="form-control form-control-subject" formControlName="email" type="email" name="email" id="email" placeholder="E-mail ID* | البريد الإلكتروني*" required>
                                    </div>
                                </div>

                                <div class="col-md-6 col-xs-12">
                                    <div class="form-group">

                                        <input class="form-control form-control-subject" formControlName="country" name="country" id="country" placeholder="Country | بلدك" >
                                    </div>
                                </div>
                                <div class="col-md-6 col-xs-12">
                                    <div class="form-group">
                                   <select class="form-control form-control-subject" formControlName="location" name="location" id="location"placeholder="Preferred Location" required="">>
                                     <option>Preferred Location | المدينة المفضلة</option>
                                     <option value="Hyderabad">Hyderabad</option>
                                     <option value="Bengaluru">Bengaluru</option>
<!--                                     <option value="Vijayawada">Vijayawada</option>-->
                                      <option value="Delhi">Delhi</option>
                                     <option value="Chennai">Chennai</option>
                                   </select>
                                 </div>
                                   <!--  <div class="form-group">

                                        <input class="form-control form-control-subject" name="location" id="location" placeholder="Preferred Location" required="">
                                    </div> -->
                                </div>
                                <div class="col-md-6 col-xs-12">
                                    <div class="form-group">
                                        <div class="uploaddiv"> 
                                            <p> Upload Report | إرفاق تقرير طبي</p>
                                            <small> File up to 5mb can be uploaded. </small>
                                        </div>
                                        <input class="form-control form-control-subject" formControlName="file" type="file" name="attach" id="subject" placeholder="Upload Report | إرفاق تقرير طبي">
                                    </div>
                                </div>
                            </div>

                            <div class="text-center"><br>
                                <button class="btn btn-primary solid blank" type="submit">Submit</button> 

                                <p class="text" id="spec">Send us your enquiry at- <a href="mailto:international.patients@rainbowhospitals.in">international.patients@rainbowhospitals.in</a></p>
                            </div>
                            <div class="talk-to-doctor text-center">
                               
                                    
                                    <a class="mobile-no" href="https://www.rainbowhospitals.in/hello-doctor-online-consultation/" target="_blank">Video Consultation</a>
<!--                                    <a class="mobile-no" target="_blank" href="#">Chat with us (Live chat)</a>-->
                                    <a class="mobile-no" href="tel:+91 8008232100"><img src="assets/international-patients/images/24call.png"/> +91 8008232100</a>
                                   

                            </div>
                            <input type="hidden" id="utm_source" name="utm_source" value="">
                            <input type="hidden" id="utm_medium" name="utm_medium" value="">
                            <input type="hidden" id="utm_campaign" name="utm_campaign" value="">
                            <input type="hidden" id="utm_content" name="utm_content" value="">
                            <input type="hidden" id="utm_term" name="utm_term" value="">
                            <input type="hidden" id="utm_lms" name="utm_lms" value="">
                            <input type="hidden" id="gclid" name="gclid" value="">
                        </form>
                    </div>
                </div>
            </div>
            </div>
            
        </section>
        <section class="Our-Specialities">
            <div class="Our-Specialities-content text-center container">
                <h2>Our major specialties | تخصصاتنا 
                | আমাদের বিশেষত্ব</h2>
                <div class="row">
                    <div class="col-md-3 mt-3 border-mainright">
                        <a href="https://www.rainbowhospitals.in/child-care/speciality/hematology-and-oncology" target="_blank">
                            <div class="fetal-medicine">
                            <img src="assets/international-patients/images/osteric.jpg" alt="Fetal Medicine">

                        </div>
                        <p>Oncology/ BMT</p></a>
                    </div>
                    <div class="col-md-3 mt-3 border-mainright">
                       <a href="https://www.rainbowhospitals.in/child-care/speciality/the-pediatric-neurology" target="_blank"> 
                        <div class="Pregnancy-Delivery">
                            <img src="assets/international-patients/images/fertility.jpg" alt="Neurology">

                        </div>
                        <p>Neurology </p> </a>
                    </div>
                    <div class="col-md-3 mt-3 border-mainright">
                        <a href="https://www.rainbowhospitals.in/child-care/speciality/orthopedics" target="_blank">
                            <div class="Child-Birth">
                            <img src="assets/international-patients/images/kidney-transplant.jpg" alt="Orthopedics">

                        </div>
                        <p>Orthopedics</p></a>
                    </div>
                    <div class="col-md-3 mt-3 ">
                        <a href="https://www.rainbowhospitals.in/child-care/speciality/urology" target="_blank"><div class="Obstetrics-Gynecology">
                            <img src="assets/international-patients/images/neurology.jpg" alt="Urology">

                        </div>
                        <p>Urology</p></a>
                    </div>

                    <div class="col-md-3 mt-3 border-mainright">
                       <a href="https://www.rainbowhospitals.in/fertility-care/treatments" target="_blank"> <div class="fetal-medicine">
                            <img src="assets/international-patients/images/pediartic.jpg" alt="IVF/ Infertility">
                        </div>
                        <p>IVF/ Infertility</p></a>
                    </div>
                    <div class="col-md-3 mt-3 border-mainright">
                       <a href="https://www.rainbowhospitals.in/child-care/speciality/pediatric-nephrology" target="_blank">
                            <div class="Child-Birth"><img src="assets/international-patients/images/neurology.jpg" alt="Kidney transplants">
                        </div>
                        <p>Kidney transplants</p> </a>
                    </div>
                    <div class="col-md-3 mt-3 border-mainright">
                        <a href="https://www.rainbowhospitals.in/child-care/speciality/pediatric-surgery" target="_blank">
                            <div class="Child-Birth">
                            <img src="assets/international-patients/images/IVF Infertility.jpg" alt="Pediatric Surgery"> </div>
                        <p>Pediatric Surgery 
					</p></a>
                    </div>
                    <div class="col-md-3 mt-3 ">
                       <a href="https://www.rainbowhospitals.in/child-care/speciality/cardiology" target="_blank"> 
                        <div class="Obstetrics-Gynecology">
                            <img src="assets/international-patients/images/oncology.jpg" alt="Cardiology">
                        </div>
                        <p>Cardiology
</p></a>
                    </div>


                    <div class="col-md-3 mt-3 border-mainright">
                      <a href="https://www.rainbowhospitals.in/child-care/speciality/pediatric-gastroenterology" target="_blank"  id="gastr">  <div class="Obstetrics-Gynecology">
                            <img src="assets/international-patients/images/urology.jpg" alt="Obstetrics-Gynecology">
                        </div>
                        <p>Gastroenterology <br>and liver diseases
</p></a>
                    </div>


                    <div class="col-md-3 mt-3">
                      <a href="https://www.rainbowhospitals.in/our-specialities" target="_blank"  id="gastr">  <div class="Obstetrics-Gynecology">
                            <img src="assets/international-patients/images/all-icon.png" alt="Other Specialties">
                        </div>
                        <p>Other Specialties
</p></a>
                    </div>


                </div>
            </div>

        </section>


        <section class="visit">
            <div class="plan-your-visit container">
                <h2 class="text-center">Plan your visit |خطة السفر  | ভ্রমন পরিকল্পনা</h2>
                <ul class="nav nav-tabs mt-4" role="tablist">
                 
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#tabs-1" role="tab">FAQs </a>
                    </li>
                    <li class="nav-item active">
                        <a class="nav-link " data-toggle="tab" href="#tabs-4" role="tab">Contact helpdesk</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#tabs-3" role="tab">Special services </a>
                    </li>
<li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#tabs-6" role="tab">Your medical tour guide </a>
                    </li>
                       
                    
                    
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#tabs-5" role="tab">  Patient Testimonial </a>
                    </li>
                    

                </ul>
                <!-- Tab panes -->
                <div class="tab-content">
                    <div class="tab-pane" id="tabs-2" role="tabpanel"><br><br>
                       <div class="vista-assistance">
                           <div class="hello">
                               <div class="greeting-pic">
                                  <img src="assets/international-patients/images/hello.png">
                               </div>
                               <div class="greeting-txt">
                                   <p>Greetings from Rainbow Children's Hospital, India!</p>
                                   <p>If you are a patient located outside India and would like to get in touch with us please fill the form below. We will get in touch with you.</p>
                                   <p>Rainbow Children’s Hospitals recognizes that international patients have unique needs and require personalized service for their treatments outside their country of origin. </p>
                                   <p>At Rainbow Children’s Hospitals, we offer seamless service and end-to-end services in choosing the right Rainbow Children’s Hospital in India and the right doctor for you. </p>
                                   <p>From the warmth of our greeting at the airport, to your registration and discharge, we have created an unparalleled experience through our seamless services for our international guests.</p>
                               </div>
                           </div>
                       </div>
                    </div>
                    <div class="tab-pane" id="tabs-1" role="tabpanel">
                        <div class="panel-group" id="accordion">
                            <div class="panel panel-default">
                                <div class="panel-heading">
                                    <h4 class="panel-title">
                                        <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne">Does your hospital have any accreditation?</a>
                                    </h4>
                                </div>
                                <div id="collapseOne" class="panel-collapse collapse in">
                                    <div class="panel-body">
                                        
                                        <p>Rainbow Children’s Hospital has an unwavering focus towards quality accreditations and patient safety. We have an accreditation from National Accreditation Board of Hospitals & Healthcare Providers (NABH) and International society of Qualitity Assurance (ISQUA).</p><p>

We aim towards achieving similar accreditations for all our hospitals across India.
</p>
                                    </div>
                                </div>
                            </div>
                            <!--/ Panel 1 end-->

                            <div class="panel panel-default">
                                <div class="panel-heading">
                                    <h4 class="panel-title">
                                        <a data-toggle="collapse" class="collapsed" data-parent="#accordion" href="#collapseTwo"> Are your doctors US or UK board certified?</a>
                                    </h4>
                                </div>
                                <div id="collapseTwo" class="panel-collapse collapse">
                                    <div class="panel-body">
                                        
                                        <p>We have committed and full-time association with internationally trained and certified consultants. These consultants bring with them not only years of experience with international healthcare institutions but also ensure highest quality of treatment to the patients.</p>
                                       
                                    </div>
                                </div>
                            </div>
                            <!--/ Panel 2 end-->

                            <div class="panel panel-default">
                                <div class="panel-heading">
                                    <h4 class="panel-title">
                                        <a data-toggle="collapse" class="collapsed" data-parent="#accordion" href="#collapseThree">What language does the staff speak in your hospital?</a>
                                    </h4>
                                </div>
                                <div id="collapseThree" class="panel-collapse collapse">
                                    <div class="panel-body">
                                       
                                        <p>All our staffs speak fluent English. A language interpreter can be arranged for you in case you wish to converse in your native language. 

                                        </p>
                                    </div>
                                </div>
                            </div>
                            <!--/ Panel 3 end-->


                            <div class="panel panel-default">
                                <div class="panel-heading">
                                    <h4 class="panel-title">
                                        <a data-toggle="collapse" class="collapsed" data-parent="#accordion" href="#collapsefour">Can I contact your doctors directly to take an opinion?</a>
                                    </h4>
                                </div>
                                <div id="collapsefour" class="panel-collapse collapse">
                                    <div class="panel-body">
                                       
                                        <p>Yes, you may contact the doctor through our dedicated International Patient Helpdesk who would schedule your consultation with the doctor via teleconference or video conference.

                                        </p>
                                    </div>
                                </div>
                            </div>
                            <!--/ Panel 4 end-->


                            <div class="panel panel-default">
                                <div class="panel-heading">
                                    <h4 class="panel-title">
                                        <a data-toggle="collapse" class="collapsed" data-parent="#accordion" href="#collapsefive">Will I be received at the Airport by the hospital?</a>
                                    </h4>
                                </div>
                                <div id="collapsefive" class="panel-collapse collapse">
                                    <div class="panel-body">
                                       
                                        <p>Upon your arrival at the Airport, you would be received at the Airport by your Case Manager who will escort you to the hospital or to the guest house. You will be sent an appointment letter before you embark for your treatment. This appointment letter will provide a detailed schedule of your appointments in the hospital and your expected stay with us.

                                        </p>
                                    </div>
                                </div>
                            </div>
                            <!--/ Panel 5 end-->

                            <div class="panel panel-default">
                                <div class="panel-heading">
                                    <h4 class="panel-title">
                                        <a data-toggle="collapse" class="collapsed" data-parent="#accordion" href="#collapsesix">How much money should I carry for my treatment?</a>
                                    </h4>
                                </div>
                                <div id="collapsesix" class="panel-collapse collapse">
                                    <div class="panel-body">
                                       
                                        <p>Before you arrive for your treatment, you will be sent detailed information about the estimated cost of your treatment.
After your initial evaluation at the hospital and as per the recommended line of treatment the cost may sometimes vary. In such cases, you will be counseled by our finance team before we start your treatment.


                                        </p>
                                    </div>
                                </div>
                            </div>
                            <!--/ Panel 6 end-->

                            <div class="panel panel-default">
                                <div class="panel-heading">
                                    <h4 class="panel-title">
                                        <a data-toggle="collapse" class="collapsed" data-parent="#accordion" href="#collapseseven">Do you make arrangements for patient’s stay outside the hospital while he/she is undergoing treatment?</a>
                                    </h4>
                                </div>
                                <div id="collapseseven" class="panel-collapse collapse">
                                    <div class="panel-body">
                                       
                                        <p>Yes, we do make arrangements for your stay outside the hospital while you undergo treatment at Rainbow Hospitals. We can arrange for comfortable and cost-effective serviced apartments and guest houses for your comfortable stay.


                                        </p>
                                    </div>
                                </div>
                            </div>
                            <!--/ Panel 7 end-->


                                 <div class="panel panel-default">
                                <div class="panel-heading">
                                    <h4 class="panel-title">
                                        <a data-toggle="collapse" class="collapsed" data-parent="#accordion" href="#collapseeight">Do you provide post-discharge consults with the treating doctor? Do you charge for such consultations?</a>
                                    </h4>
                                </div>
                                <div id="collapseeight" class="panel-collapse collapse">
                                    <div class="panel-body">
                                       
                                        <p>Our e-Docs Online Consultation ensures that you are provided complete care and guidance even after your discharge from the hospital. Our medical coordinators can review and monitor your post-discharge progress, rehabilitation and medical compliance through offshore interaction with you via emails, teleconference or videoconference. This service is complimentary and is not charged.


                                        </p>
                                    </div>
                                </div>
                            </div>
                            <!--/ Panel 8 end-->




                        </div>
                        <!--/ Accordion end -->
                    </div>
                    <div class="tab-pane" id="tabs-3" role="tabpanel">
                            <h3>Services that we offer to our foreign guests</h3>
                            <ol>
<li>Dedicated international patient helpdesk</li>
<li>Face-to-face video conferencing with treating doctors</li>
<li>No-cost medical opinions and treatment estimates</li>
<li>Visa assistance and Free Airport transfers</li>
<li>Arranging guest houses and serviced apartments for patient stay near our hospital</li>
<li>Concierge services (Local SIM card, registration with police, foreign exchange, local transport, confirmation of air tickets, shopping, sightseeing &amp; visa extensions)</li>
<li>In-room internet access</li>
<li>Arabic food and TV channels in room</li>
<li>Free internet access</li>
<li>Full time language interpreters in the hospital</li>
</ol>
                        <!-- Team slide end -->

                    </div>
                    <div class="tab-pane active" id="tabs-4" role="tabpanel">
                        <div class="row">
                            <div class="col-md-5">

                                <div class="panel-group help" id="accordion">
                                    <div class="panel panel-default">
                                        <div class="panel-heading">
                                            <h4 class="panel-title">
                                               Contact Helpdesk
                                            </h4>
                                        </div>
                                        <div id="collapseOne1" class="">
                                            <div class="panel-body">
                                                
                                                <div class="help-desk">

                                                    <div class="help-desk-img">
                                                        <img src="assets/international-patients/images/help-desk.png" alt="Help Desk" class="d-block mx-auto">
                                                    </div>
                                                    <div class="help-desk-no text-center">+91 8008232100</div>
                                                    <div class="help-txt text-center">
<!--                                                        <p>if urgent. Your personal case manager will ensure that you receive the best possible care.</p>-->
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--/ Panel 1 end-->

                                </div>


                            </div>
                            <div class="col-md-7">
                                <div class="document-require">
                                 <!--/ Panel 1  <h3 class="my-4">Document Required</h3> end--> 
                                    <ol>
                                        <li>FREE medical opinions by experts</li>
                                        <li>Visa assistance and accommodation </li>
                                        <li>Ticketing and travel plan</li>
                                        <li> Fix appointment with our doctors.</li>
                                        <li>Email us at <a href="mailto:international.patients@rainbowhospitals.in">international.patients@rainbowhospitals.in</a><br>Or talk to us on 24/7 Emergency helpline number +91 8008232100</li>
                                       
                                    </ol>
<!--
                                    <div class="pull-right btn-mob">
                                        <div class="live-chat text-center">
                                            <a href="#">Live Chat</a>
                                        </div>
                                        <br>
                                        <p class="pull-right">Get Your Answer</p>

                                    </div>
-->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane" id="tabs-5" role="tabpanel">


                        <div id="testimonial-slide" class="owl-carousel owl-theme testimonial-slide">
                            <div class="item">
                               <iframe width="560" height="315" src="https://www.youtube.com/embed/9wD5-2LGy0A" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <!-- Quote item end -->
                            </div>
                            <!--/ Item 1 end -->

                            <div class="item">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/3_4-S-aDPeU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <!-- Quote item end -->
                            </div>
                            <!--/ Item 2 end -->

                            <div class="item">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/mUW3F6LfFns" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <!-- Quote item end -->
                            </div>
                            <!--/ Item 3 end -->

                            <div class="item">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/dF3QzqzhYVc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <!-- Quote item end -->
                            </div>
                            <!--/ Item 4 end -->

                            <div class="item">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/TVDFodE_FP8" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <!-- Quote item end -->
                            </div>
                            
                            <!--/ Item 5 end -->

                             <div class="item">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/8JEz-2FKqNY" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <!-- Quote item end -->
                            </div>
                            
                            <!--/ Item 6 end -->

                             <div class="item">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/2sH9zAVYWmw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <!-- Quote item end -->
                            </div>
                            
                            <!--/ Item 7 end -->


                             <div class="item">
                               <iframe width="560" height="315" src="https://www.youtube.com/embed/pAnxvgn7XGw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <!-- Quote item end -->
                            </div>
                            
                            <!--/ Item 8 end -->

                             <div class="item">
                               <iframe width="560" height="315" src="https://www.youtube.com/embed/bGENbbJH87k" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <!-- Quote item end -->
                            </div>
                            
                            <!--/ Item 9 end -->

                            <div class="item">
                               <iframe width="560" height="315" src="https://www.youtube.com/embed/9dsRhbEoBGk" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <!-- Quote item end -->
                            </div>
                            
                            <!--/ Item 10 end -->

                        </div>
                        <!--/ Testimonial carousel end-->


                    </div>
                    <div class="tab-pane" id="tabs-6" role="tabpanel">
                        <p>We recognize that you may have several questions you need answered before deciding on a right hospital for your needs.</p>
                        <p>Which is why a tour for a first-hand experience encourage.</p>
                        <p>You may write, phone, or fax your medical requirement to us</p>
                        <p>Our dedicated Case Managers will be in touch to arrange both, your pick up at the airport and accommodation, as well as meetings with our specialists.</p>
                        <p class="text-bold">Bring along the following documents:</p>
                        <ul>
<li>Your &lsquo;fitness to fly&rsquo; certificate (if medically warranted)</li>
<li>Copy/originals of all medical reports and medication details</li>
<li>CD copies of any scans like MRI/CT/ECHO</li>
<li>Any referral notes from your doctor</li>
</ul>
<p class="text-bold">Guidelines to follow for medical tourist:</p>
<p>Here is what every prospective medical traveler should know before going overseas for treatment.</p>
<ul>
    <li>Find out from a qualified medical professional if you are fit enough to travel overseas and can fly long distance flights</li>
    <li>Make sure the hospital you wish to go is licensed and preferably accredited by recognized healthcare accreditation agency.</li>

    <li>Cost and quality of patient care should be kept in mind before finalizing the hospital for your treatment.</li>
    <li>Cost and quality of patient care should be kept in mind before finalizing the hospital for your treatment.</li>
    <li>Get details about the doctor, including their professional achievements, qualification and credentials before you select them. Cross-check with existing patients or do some search on the internet, for the hospital you are looking to go.</li>
    <li>Educate yourself on the procedure and compare your expectations with what’s achievable by the surgery. Also, be clear about follow-up care needed, time required for recovery, physical therapy etc.</li>
    <li>The decisions on treating a patient can be made only after you meet with your doctor. Most hospitals do not guarantee any kind of medical treatments, without a personal examination which may include diagnostic tests, unless it is a very clear case or a procedure that is very commonplace. In some cases your doctor, upon examining you, may decide you are not fit for surgery or recommend treatment different from what you planned.</li>
    <li>You must ensure that you always carry necessary medical documents with you, like X-Rays, MRIs, health histories, photographs, immunization records, prescriptions and any other health records relevant to the surgery.</li>
    <li>Passport and visa: You will need a passport for yourself and your travel companion (if any). Depending upon the country you are traveling from, you may or may not need a visa.</li>
    <li>Credit cards, debit cards and travelers checks: Bring some local currency, travelers checks and one or two major credit cards and debit cards.</li>
    <li>Carry your driver’s license and make sure it remains valid while you’re traveling</li>
    </ul>
                    </div>
                </div>
            </div>

        </section>




        <section>
            <div class="treatment-container container">
                <div class="row">
                    <div class="col-md-6">
                        <div class="treatment1">
                            <div class="Left">
                                <img src="assets/international-patients/images/treatment1.jpg" alt="treatment">
                            </div>
                            <div class="Right">
                                <h2 id="est4">Second Opinion</h2>
                               
                            </div>

                            <a href="#" id="est3"  class="all-package ">Get A  Second Opinion </a>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="treatment2">
                            <div class="Left"><img src="assets/international-patients/images/treatment2.jpg" alt="treatment"></div>
                            <div class="Right">
                                <h2 id="est2"><a href="#" >Get an Estimate</a></h2>
                               
                            </div>
                            <a class="all-package" href="#"  id="est">Request An Estimate</a>
                        </div>
                    </div>
                </div>
            </div>
    </section>







    <footer class="footer">


        <div id="Footer" class="container">
            <div class="row">
                <div class="col-md-4 text-center">
                   <div class="phone">Phone: (+91)8008232100</div> 
                   <div class="copy-right py-4">
                <p class="text-center">&copy; 2020 rainbowhospital.in - All Rights Reserved.</p>
            </div>
                </div>
                <div class="col-md-4 ">
                    
                </div>
                <div class="col-md-4">
                    <div class="social-media">
                        <ul class="text-right">
                            <li><a href="https://www.facebook.com/rainbowchildrenshospitals/"><i class="fa fa-facebook"></i></a></li>
                            <li><a href="https://www.youtube.com/channel/UCx6MAbZuphamC0bUy85gK3Q"style=" color: #af2a5e; line-height: 31px;font-size: 17px;"><i class="fa fa-youtube" ></i></a></li>
                            <li><a href="https://twitter.com/RainbowSpectra"><i class="fa fa-twitter"></i></a></li>
                            
                            
                        </ul>
                    </div>
                </div>
            </div>
            
        </div>

    </footer>
    <!-- Footer end -->


    <!-- Javascript Files
	================================================== -->

    <!-- initialize jQuery Library -->
    <!-- <script type="text/javascript" src="international-patients/js/jquery.js"></script> -->
    <!-- Bootstrap jQuery -->
    <!-- <script type="text/javascript" src="international-patients/js/bootstrap.min.js"></script> -->
    <!-- Owl Carousel -->
    <!-- <script type="text/javascript" src="international-patients/js/owl.carousel.min.js"></script> -->
    <!-- <script type="text/javascript" src="international-patients/js/ini.isotope.js"></script> -->
    <!-- <script type="text/javascript" src="international-patients/js/intlTelInput.js"></script> -->
    <!-- <script type="text/javascript" src="international-patients/js/ini.isotope.js"></script> -->

    <!-- Template custom -->
    <!-- <script type="text/javascript" src="international-patients/js/custom.js"></script> -->
    
    <!-- <script>
    var input = document.querySelector("#phone");
    window.intlTelInput(input, {
      // allowDropdown: false,
      // autoHideDialCode: false,
      // autoPlaceholder: "off",
      // dropdownContainer: document.body,
      // excludeCountries: ["us"],
      // formatOnDisplay: false,
      // geoIpLookup: function(callback) {
      //   $.get("http://ipinfo.io", function() {}, "jsonp").always(function(resp) {
      //     var countryCode = (resp && resp.country) ? resp.country : "";
      //     callback(countryCode);
      //   });
      // },
      // hiddenInput: "full_number",
      // initialCountry: "auto",
      // localizedCountries: { 'de': 'Deutschland' },
      // nationalMode: false,
      // onlyCountries: ['us', 'gb', 'ch', 'ca', 'do'],
      // placeholderNumberType: "MOBILE",
      // preferredCountries: ['cn', 'jp'],
       separateDialCode: true,
      //utilsScript: "build/js/utils.js",
    });
  </script> -->



    <!-- </div> -->
    <!-- Body inner end -->
    <div class="internationalPatientsWrap">
        <a class="internationalPatients" target="_blank" href="https://www.rainbowhospitals.in/hello-doctor-online-consultation"><img src="assets/international-patients/images/icon_vc.png" alt="" srcset=""> Book a Video <br> Consultation Now </a>
    </div>

<!-- <script type="text/javascript">function add_chatinline(){var hccid=21573166;var nt=document.createElement("script");nt.async=true;nt.src="https://mylivechat.com/chatinline.aspx?hccid="+hccid;var ct=document.getElementsByTagName("script")[0];ct.parentNode.insertBefore(nt,ct);}
add_chatinline(); </script>
 <script type='text/javascript' src='international-patients/js/getQueryString.js'></script> -->
    <!-- <script type="text/javascript">getQueryData();</script> -->
<!-- </body> -->

<!-- </html> -->