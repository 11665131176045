import { Inject, Component, OnDestroy, OnInit, AfterViewInit, PLATFORM_ID, HostListener,ViewChildren } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from "../services/api.service";
declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
declare let initVimslider:any;
@Component({
	selector: 'vaccination',
	templateUrl: './vaccination.component.html'
})

export class FertilityCareVaccinationComponent implements OnDestroy, OnInit, AfterViewInit {

	
	constructor(private api: ApiService, ) {
		if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
    }
	}

	public events: any[] = [];
	public hidden: string = "hidden";
	public logo = "birth-right-logo";
	public imgBasePath: string = "";
	public imgsrc: any = "";
	public title: any = "";
	public subtitle: any = "";
	public description: any = "";

	ngOnInit() {
		this.api.getEvents()
			.subscribe(
			data => {
				this.events = data.home_events;
			},
			error => {
				console.log(error);
			}
			)
	}

	ngAfterViewInit() {

		if (this.api.isBrowser && window['jQuery']) {
			initHome();
			initAllPage();
			
			//$(".ourcenterlist").removeClass("slick-initialized").removeClass("slick-slider");

		}
		
	}
	ngOnDestroy() { }

}
