<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>

    <section class="page wtwedo">
        <div class="row">
            <div class="col-md-5 col-xs-5 col-lg-4">
                <div class="circuleframe">
                    <img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" />
                </div>
                <!-- End Circle -->
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7">
                <div class="pagetitle">
                    <h1 class="tgradient">{{title}} </h1>
                    <div class="underline">
                        <img src="assets/images/underline2.png" alt="" />
                    </div>
                    <h3>{{subtitle}}</h3>
                </div>
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7 mcontentview">
                <p [innerHTML]="description"></p>
            </div>
        </div>

    </section>
    <section class="aboutsectin2 awardswapper">
        <div class="container">
            <div class="row nsg_text">
                <div class="col-xs-12 col-lg-12">
                    <h2>What is the cervix?</h2>
                    <p>The cervix is the lower portion of the uterus which forms the neck of the uterus that opens into the vagina. The cervix is about 1 inch long. It opens into the vagina by the os which is a narrow opening. The os allows menstrual blood
                        to flow out, and widens during labor to allow the passage of the fetus through the vagina during childbirth.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>What are the symptoms of cervical cancer?</h2>
                    <p>Early stage cervical cancer has no symptoms. Symptoms appear only when the cancer is advanced.</p>
                    <p>Some symptoms are:</p>
                    <ul>
                        <li><em>Unusual heavy discharge.</em> An increased vaginal discharge is also a symptom of cervical cancer. It may be foul smelling, watery, thick, or contain mucus.</li>
                        <li><em>Bleeding between regular menstrual periods, after sexual intercourse. </em>This is due to the irritation of the cervix during these activities.</li>
                        <li><em>Pain during urination.</em>Bladder pain or pain during urination can be a symptom of advanced cervical cancer.</li>
                    </ul>
                    <p>All of the above symptoms can be caused by various other common conditions. But if you develop any of these symptoms, you should have it checked by a doctor.<br> <br> In time, if the cancer spreads to other parts of the body, various
                        other symptoms can develop.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>Cervical Cancer Risk Factors</h2>
                    <ul>
                        <li><strong>One of the greatest risk factors is infection with HPV</strong>: Human papillomavirus or HPV for short is common cause of cervical cancer. Although several cervical cancer risk factors have been identified, HPV remains
                            the strongest link. HPV is transmitted by skin to skin contact through vaginal, anal and oral sex with a partner who already has HPV. If infected, signs and symptoms may take weeks, months and even years to appear. <strong>Symptoms may never appear.</strong></li>
                        <li>Giving birth to many children</li>
                        <li>Having many sexual partners</li>
                        <li>Having first sexual intercourse at a young age</li>
                        <li>Smoking cigarettes</li>
                        <li>Oral contraceptive use ("the Pill")</li>
                        <li>Weakened immune system</li>
                    </ul>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>Natural History</h2>
                    <p>All activities which increase the risk of cervical cancer start at a young age. However the disease may present itself only at a very late stage. The changes induced by any of the risk factors, particularly the HPV, transforms the
                        cells of the cervix into cancerous cells. This transformation takes a very long time, many years sometimes. This actually is an advantage. Because it provides us with a long pre-cancerous stage and if the disease is picked up at
                        this stage it is completely curable. But this long pre-cancerous stage will not have any symptoms except occasional non-specific ones like white discharge. Therefore the woman needs to take care even if she has no symptoms. She
                        has to take all preventive measures to avoid the disease or if it has already occurred to detect it in the pre-cancerous stage when cure is complete.<br> It is also a myth that only older women can get full blown cervical cancer.
                        It can be seen in women as young as 30 years if they have multiple risk factors.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>Procedures for Examining the Cervix</h2>
                    <p><strong>Physical Exam</strong><strong> and </strong><strong>History</strong> : A physical examination to check general health, including checking for signs of disease, such as lumps or any other symptoms that are not normal. The patient’s
                        history of health habits and past illnesses and treatments are also taken into consideration.</p>
                    <p><strong>Pelvic Exam</strong>: The doctor or nurse does a physical examination of the vagina, cervix, uterus, fallopian tubes, ovaries, and rectum. The physical pelvic examination is done by inserting one or two lubricated, gloved fingers
                        of one hand into the vagina to feel the size, shape, and position of the uterus and ovaries. Alternatively, a speculum may be inserted into the vagina and the doctor or nurse looks at the vagina and cervix for signs of disease.</p>
                    <p><strong>Pap Smear:</strong> <br> This is the single most important test that helps prevent cervical cancer. A pap smear is a gentle scraping of the cells around the opening of the cervix (os). The cells are put on a glass slide and
                        examined under a microscope for any changes or abnormalities. It is performed as part of gynecological examination. This procedure does not cause pain and does not need any special preparation. There are no risks involved in getting
                        this test done. This test cannot be done when you are menstruating.</p>
                    <p><span style="text-decoration: underline;">First Pap test</span><strong>:</strong> Women should have a pap test at least once every three years, beginning about three years after they begin to have sexual intercourse, but no later than
                        age 21.</p>
                    <p><span style="text-decoration: underline;">Up to 65 years of age:</span> Pap smear every three years unless advised more frequently by your gynecologist.</p>
                    <p><span style="text-decoration: underline;">After 65:</span>Women who have had at least three normal Pap tests and no abnormal Pap tests in the last 10 years, after talking with their gynecologist, can stop having pap smears performed.<br>                        Depending on the results of this test your doctor will decide if you need further tests like biopsy or colposcopy.</p>
                    <p><strong>Colposcopy</strong>: A colposcope is a lighted, magnifying instrument, used to check the vagina and cervix for abnormal areas. Tissue samples may be taken using a spoon-shaped instrument called curette and checked under a microscope
                        for signs of disease.<br> <strong>Biopsy</strong>: The doctor may recommend biopsy if abnormal cells are found in a pap test. Biopsy is a technique in which a sample of tissue is cut from the cervix. This is then examined under
                        a microscope by a pathologist to check for signs of cancer.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>Testing for HPV</h2>
                    <p>Now we have means to test for HPV. This is the most specific testing method available to pick up cervical pre-cancer at early stages and offer full cure. The HPV test is often performed at the same time as a pap smear and involves
                        the same technique of taking a sample of cervical cells. Right now, women over 30 are offered the HPV test to be done along with a routine pap smear. If a woman under 30 has an abnormal pap smear, then an HPV test may be ordered.
                        Test results can take up to a few weeks to come back. HPV test results come back as normal or abnormal. If the HPV test results show you have HPV, then you may need a biopsy. Keep in mind that HPV is very common, and not all types
                        of HPV will cause cancer. <br> If the test showed no HPV infection, you probably do not have any precancerous changes on your cervix and your cervical cancer risk is relatively low.<br> If your combined pap smear and HPV test result
                        is negative, it means you are at very low risk of developing cervical cancer. You may even be able to reduce the frequency of testing to five years instead of three years.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>Assessing the Extent and Spread</h2>
                    <p>If you are found to have cervical cancer, then further tests such as a CT scan, an MRI scan, a chestX-ray, an ultrasound scan, and blood tests may be advised to assess if the cancer has spread. This assessment is called staging of
                        the cancer. The aim of staging is to find out:</p>
                    <ul>
                        <li>How much the tumour has grown, and whether it has grown to other nearby structures such as the bladder or rectum</li>
                        <li>Whether the cancer has spread to local lymph glands (nodes)</li>
                        <li>Whether the cancer has spread to other areas of the body (metastasized)</li>
                    </ul>
                    <p>The exact tests needed depend on the initial assessment and the results of the biopsy. For example, the biopsy may show that the cancer is at a very early stage and remains just in the surface cells of the cervix. This is unlikely
                        to have spread (metastasized) and you may not need many other tests. However, if the cancer appears to be more advanced and likely to have spread then a range of tests may be needed. Finding out the stage of the cancer helps doctors
                        to advise on the best treatment options and also gives a reasonable indication of prognosis.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>Treatment Options for Cervical Cancer</h2>
                    <p>Treatment options which may be considered include surgery, radiotherapy, chemotherapy, or a combination of these treatments. The treatment advised for each case depends on various factors. For example, the stage of the cancer (how
                        large the primary cancer tumour is and whether it has spread), and your general health.<br> <br> You should have a full discussion with a specialist who knows your case. They will be able to give the pros and cons, likely success
                        rate, possible side-effects, and other details about the various possible treatment options for your type and stage of cancer. You should also discuss with your specialist the aims of treatment. For example:</p>
                    <ul>
                        <li>In some cases, treatment aims to cure the cancer. Some cervical cancers can be cured, particularly if they are treated in the early stages of the disease. (Doctors tend to use the word remission rather than the word cured. Remission
                            means there is no evidence of cancer following treatment. If you are in remission, you may be cured. However, in some cases, a cancer returns months or years later. This is why doctors are sometimes reluctant to use the word
                            cured.)</li>
                        <li>In some cases, treatment aims to control the cancer. If a cure is not realistic, with treatment it is often possible to limit the growth or spread of the cancer so that it progresses less rapidly. This may keep you free of symptoms
                            for some time.</li>
                        <li>In some cases, treatment aims to ease symptoms. For example, if a cancer is advanced then you may require treatments such as painkillers or other treatments to help keep you free of pain or other symptoms. Some treatments may be
                            used to reduce the size of a cancer, which may ease symptoms such as pain.</li>
                    </ul>
                    <p>The outlook is best in those who are diagnosed when the cancer is confined to the cervix and has not spread. Treatment in this situation gives a good chance of cure for 8-9 women out of 10. For women who are diagnosed when the cancer
                        has already spread, a cure is less likely but still possible. Even if a cure is not possible, treatment can often slow down the progression of the cancer.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>Preventing Cervical Cancer</h2>
                    <p>You may reduce your risk of cervical cancer if you:</p>
                    <p><strong>Have routine pap tests</strong> <br> Routine pap tests can detect abnormal cells in the cervix early, and help in timely detection and prevention of cervical cancer. Talk to your doctor and decide on when to schedule routine
                        pap tests.</p>
                    <p><strong>Limit the amount of sexual partners you have.</strong> Studies have shown women who have many sexual partners increase their risk for cervical cancer. They also are increasing their risk of developing HPV, a known cause for
                        cervical cancer.</p>
                    <p><strong>Quit smoking or avoid secondhand smoke. </strong>Smoking cigarettes increases your risk of developing many cancers, including cervical cancer. Smoking combined with an HPV infection can actually accelerate cervical precancer
                        to progress faster to cancer.</p>
                    <p><strong>If you are sexually active, use a condom. </strong>Having unprotected sex puts you at risk for HPV, HIV and other STDs which can increase the risk of cervical cancer</p>
                    <p><strong>Get vaccinated against HPV</strong> <br> <br> The vaccine protects a person against the HPV virus that causes majority of cervical cancers. It is not a vaccine against cancer itself. The vaccination only protects a person from
                        future infection that can lead to cancer.</p>
                    <p>A person receives a series of three injections over a 6-month period. Once inside, these particles trigger a strong immune response, so the vaccinated person's body makes and stockpiles antibodies that can recognize and attack the
                        HPV viruses. However, if a woman has been infected with a HPV type(s) prior to vaccination, she is not protected. Therefore the vaccine is most effective if given to girls before they become sexually active.<br> It is important
                        to understand that the cervical cancer vaccine is not intended to replace pap tests. Routine screening for cervical cancer through regular pelvic exams and pap tests remains an essential part of a woman's preventive healthcare.</p>
                </div>
            </div>
        </div>
    </section>
    <ng-template [ngIf]="ins_links.length > 0">
        <footer-nav-mini [links]="ins_links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>