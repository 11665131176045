import {Inject, Component, OnDestroy, OnInit, AfterViewInit, PLATFORM_ID, ViewChildren, QueryList } from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {ApiService} from "../services/api.service";
import {IMG_BASE_URL} from "../services/constants";

import { Meta, Title } from '@angular/platform-browser';
declare let $:any;
declare let initHome:any;
declare let initAllPage:any;

declare let initCustomSelect:any;

@Component({
  selector: 'doctors',
  templateUrl: './doctors.component.html'
})

export class FertilityCareDoctorsComponent implements OnDestroy, OnInit, AfterViewInit {


  constructor(private api:ApiService, public metaTitle: Title, public meta: Meta) {
    if(this.api.isBrowser){
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
    }
  }
  public doctors:any[] = [];
  public title:any = "";
  public subtitle:any = "";
  public description:any = "";
  public imgsrc:any = "";
  public imgBasePath = "";
  public hidden = "hidden";
  public logo="birth-right-logo";
  public bread_crumb_links:any = [{"label":"Home", "link": "/", "class": ""},
                                 {"label":"Fertility Care", "link": "/fertility-care/about", "class": ""},
                                 {"label":"Doctors", "link": "", "class": ""}];


  public links:any = [{"label":"About Fertility Care", "link": "/fertility-care/about", "class": ""},
                      {"label":"Doctors", "link": "/fertility-care/doctors", "class": "active"},
                      {"label":"Our Treatments", "link": "/fertility-care/treatments", "class": ""},
                      {"label":"Our Centres", "link": "/fertility-care/centres", "class": ""},
                      {"label":"Testimonials", "link": "/fertility-care/testimonials", "class": ""},
                      {"label":"Share Feedback", "link": "/fertility-care/feedback", "class": ""},
                      {"label":"Book An Appointment", "link": "/fertility-care/book-an-appointment", "class": ""}]

  @ViewChildren('doctors') things: QueryList<any>;

  ngOnInit() {
    this.api.getPageDescription('fertility-care-doctors')
      .subscribe(
        data =>{
          this.title = data.page_descriptions[0].title;
          this.subtitle = data.page_descriptions[0].subtitle;
          this.description = data.page_descriptions[0].description;
          this.imgsrc = data.page_descriptions[0].image;
          this.imgBasePath = IMG_BASE_URL;
          this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
            this.meta.addTags([
                {
                    name: 'keywords', content: data.page_descriptions[0].meta_keyword
                },
                {
                    name: 'description', content: data.page_descriptions[0].meta_description
                },
            ])
        },
        error => {
          console.log(error);
        }
      )

      this.api.getDoctorSpecialityMasterByName('Fertility Care')
        .subscribe(
          data =>{
            this.doctors = data.speciality_masters[0].doctors
          },
          error => {
            console.log(error);
          }
        )
  }

  ngAfterViewInit() {

    if ( this.api.isBrowser && window['jQuery'] ) {
      initHome();
      initAllPage();
    }

    this.things.changes.subscribe(t => {
      // this.ngForRendred();
      initCustomSelect();
    })
  }

  ngOnDestroy() {}

}
