<div class="container-fluid">
    <div class="background">
        <img src="assets/images/bg-press-clip.png" data-t="bg_01_00.jpg" data-d="bg-press-clip.png" class="bgImg bg0 active" />
    </div>
    <div class="loder"></div>
    <sidenav></sidenav>
    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <!--<bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>-->
    <section class="pagebcwrp">
        <div class="container">
            <div class="row">
            <div class="col-md-12 col-sm-12 col-lg-12">
                <ul>
                <li *ngFor="let link of bread_crumb_links" class="{{link.class}}">
                    <a *ngIf="link.link" href="{{link.link}}">{{link.label}}</a>
                    <span *ngIf="!link.link">{{link.label}}</span>
                </li>
                <li >{{title | slice:0:32}}<span *ngIf="title.length > 32">...</span></li>
                </ul>
            </div>
            </div>
        </div>
    </section>
    <section class="page ">
        <div class="row mediaSection pageheader">
            <div class="container">
                <div class="col-xs-12 col-sm-7 col-lg-7 abannimgwrp">
                    <div class="aboutbannimg"><img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" /></div>
                </div>
                <div class="col-xs-12 col-sm-5 col-lg-5">
                    <div class="ptitle yellow">
                        <h1>{{title}}</h1>
                        <h3>{{subtitle}}</h3>                        
                    </div>
                </div>
                <div class="col-xs-12">
                    <p [innerHTML]="description"></p>
                </div>
                <div class="col-xs-12 PressReleases">
                    <div class="row">
                        <ul class="hlfcube">
                            <li >
                            <div  class="specialtywrp">
                                <select id="Hospitals" class="select" (change)="onChangeHospitals($event)">
                                    <option  value="0">Select Hospitals</option>
                                    <option *ngFor="let bwmCenter of bwmCenters" [value]="bwmCenter.unitName">
                                        {{bwmCenter.unitName}}
                                    </option>
                                </select>
                                <span  class="select-span" id="SelectBwmCenter">Select Center</span>
                            </div>
                            <div  class="specialtywrp">
                                <select id="BMWYear" class="select">
                                    <option  value="0">Select Year</option>
                                    <option *ngFor="let bwmYear of bwmYears" [value]="bwmYear.Year">
                                        Year - {{bwmYear.Year}}
                                    </option>
                                </select>
                                <span  class="select-span" id="SelectYear"> Year - {{ bwmYear }}</span>
                            </div>
                            <div  class="searchbtn">
                                <input  name="txtSearch" type="button" value="Search" (click)="searchBWMData()">
                            </div>
                        </li>
                        </ul>
                    </div>
                    <!--
                    <div class="row BMWData" *ngFor="let bmwdt of bwmData">
                        <div class="news_text">
                            <h2>{{bmwdt.unitName}} Month Wise BMW-2019</h2>
                        </div> 
                        <div class="table-responsive">              
                            <table class="table newTable">
                                <tr>
                                    <th class="col-darkBlueW">MONTH (2019)</th>
                                    <th class="col-yellowW">INFECTIOUS WASTE <span> (yellow) </span> </th>
                                    <th class="col-red">CLINICAL WASTE <span> (red)</span></th>
                                    <th class="col-blue">BOTTLES WASTE <span> (blue)</span> </th>
                                    <th class="col-white">SHARP WASTE <span> (white container)</span></th>
                                </tr>
                            </table>
                            <table class="table table-bordered newTableBody">
                                <tr *ngFor="let bmwd of bmwdt.data">
                                    <td class="col-darkBlueW">{{bmwd.bmw_date | date : "MMM-y"}}</td>
                                    <td class="col-yellowW">{{bmwd.infectious_waste}}</td>
                                    <td class="col-red">{{bmwd.clinical_waste}}</td>
                                    <td class="col-blue">{{bmwd.bottles_waste}}</td>
                                    <td class="col-white">{{bmwd.sharp_waste}}</td>
                                </tr>                               
                            </table>
                        </div> 
                    </div>
                    -->
                    <!-- Start BMWData New -->
                    <div class="BMWData" *ngFor="let bmwdt of bwmData" >
                        <div *ngFor="let bmwt of bmwdt.newCenterData" class="centerlist {{cleanup(bmwt.centerName)}} Year_{{bmwdt.Year}} {{cleanup(bmwt.centerName)}}_{{bmwdt.Year}}">
                            <div class="news_text">
                                <h2>{{bmwt.centerName}} Month Wise BMW-{{bmwdt.Year}}</h2>
                            </div> 
                            <div class="table-responsive">              
                                <table class="table newTable">
                                    <tr>
                                        <th class="col-darkBlueW">MONTH ({{bmwdt.Year}})</th>
                                        <th class="col-yellowW">INFECTIOUS WASTE <span> (yellow) </span> </th>
                                        <th class="col-red">CLINICAL WASTE <span> (red)</span></th>
                                        <th class="col-blue">BOTTLES WASTE <span> (blue)</span> </th>
                                        <th class="col-white">SHARP WASTE <span> (white container)</span></th>
                                    </tr>
                                </table>
                                <table class="table table-bordered newTableBody">
                                    <tr *ngFor="let bmwd of bmwt.centerData">
                                        <td class="col-darkBlueW">{{bmwd.bmw_date | date : "MMM-y"}}</td>
                                        <td class="col-yellowW">{{bmwd.infectious_waste}}</td>
                                        <td class="col-red">{{bmwd.clinical_waste}}</td>
                                        <td class="col-blue">{{bmwd.bottles_waste}}</td>
                                        <td class="col-white">{{bmwd.sharp_waste}}</td>
                                    </tr>                               
                                </table>
                            </div>
                        </div> 
                    </div>
                <!-- End BMWData -->
                </div>
            </div>
        </div>
    </section>
    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>

    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>