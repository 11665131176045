<div class="container-fluid">
<div class="loder"></div>
	<sidenav></sidenav>
	<header-wrap [hidden]="hidden" [logo]="logo"></header-wrap> 
<bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
<section class="page pageheader">
	<div class="container">
		<div class="col-xs-12 col-sm-7 col-lg-7 abannimgwrp">
			<div class="aboutbannimg"><img src="{{imgBasePath}}{{imgsrc}}"  class="img-responsive" alt="{{title}}" title="{{title}}" /></div>
		</div>
		<div class="col-xs-12 col-sm-5 col-lg-5">
			<div class="ptitle">
				<h1 class="underline">{{title}}</h1>
				<p [innerHTML]="description"></p>
			</div>
		</div>
		<div class="col-md-12 col-xs-12 col-lg-12 mcontentview">
			<div class="dfindwrp">
				<ul class="hlfcube">
					<li>
						<div class="locationwrp">
							<select class="select" name="txtLocation" [(ngModel)]="selectLocation">
								<option value="0" [selected]="selectLocation == 0">Select City</option>
								<option *ngFor="let clocations of clocation; let i = index" value="{{clocations.id}}" [attr.selected]="i==0 ? 'selected' : 'null'">
									{{clocations.city}}
								</option>
							</select>
							<span id="selectlocation" class="select-span"></span>
						</div>
						<div class="searchbtn">
							<input type="submit" name="txtSearch" value="Search" (click)="searchCareerDetailsByCity()" />
						</div>
					</li>
				</ul>
			</div>
		</div>
		<!-- <div class="col-md-12 col-xs-12 col-lg-12 mcontentview">
		<div class="dfindwrp">
			<ul class="hlfcube">
				<li>
					<div class="specialtywrp">
						<select class="select" name="txtLocation" [(ngModel)]="selectSpeciality">
							<option *ngFor="let specialities of cspeciality; let i = index" value="{{specialities.id}}" [attr.selected]="i==0 ? 'selected' : 'null'">
								{{specialities.speciality}}
							</option>
						</select>
						<span id="SelectSpecialty " class="select-span"></span>
					</div>
					<div class="locationwrp">
						<select class="select" name="txtLocation" [(ngModel)]="selectLocation">
							<option *ngFor="let clocations of clocation; let i = index" value="{{clocations.id}}" [attr.selected]="i==0 ? 'selected' : 'null'">
								{{clocations.city}}
							</option>
						</select>
						<span id="selectlocation" class="select-span"></span>
					</div>
					<div class="searchbtn">
						<input type="submit" name="txtSearch" value="Search" (click)="searchCareerDetails()" />
					</div>
				</li>
			</ul>
		</div>
		</div> -->
	</div>
</section>
<section class="pediatricianp">
	<div class="container">
		<div class="col-lg-12">
			<h2 class="textgradient">Joining us?</h2>
		</div>
		<div  myMatchHeight="pediat-text">
			<div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 pediat-text " *ngFor="let career of careers">
				<h3>{{career.title}}</h3>
				<p>{{career.qualification}}</p>
				<p>{{career.experience}}</p>
				<p>{{career.location}}</p>
				<a href="/about-us/careers/careers-join-withus/careers-apply/{{career.id}}" class="submitbtn">Apply</a>
			</div>
		</div>
		<div  *ngIf="careersList">
			<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 pediat-text " >
				<p>No current openings in the selected location. Try refining your search.</p>
			</div>
		</div>

	</div>
</section>
<ng-template [ngIf]="links.length > 0">
	<footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
  </ng-template>
<footer-subscription></footer-subscription>
<footer class="footerNavwrp">
		<footer-nav></footer-nav>
</footer>
<footer-copyright-nav></footer-copyright-nav>
</div>
