<div class="container-fluid">
  <div class="loder"></div>
  <sidenav></sidenav>

  <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
  <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
  <section class="page wtwedo">
    <div class="row">
      <div>
        <div class="col-md-5 col-sm-5 col-lg-4 col-xs-5">
          <div class="circuleframe active">
            <img
              src="{{ imgBasePath }}{{ imgsrc }}"
              class="img-responsive"
              alt="{{ title }}"
              title="{{ title }}"
            />
          </div>
          <!-- End Circle -->
        </div>
        <div class="col-md-7 col-sm-7 col-lg-7 col-xs-7">
          <div class="pagetitle">
            <h1 class="textgradient">{{ title }}</h1>
            <div class="underline">
              <img src="assets/images/underline.png" alt="" />
            </div>
            <h3 [innerHTML]="subtitle"></h3>
          </div>
        </div>
        <div class="col-md-7 col-sm-7 col-lg-7 col-xs-12">
          <p>
            Rainbow Children’s Hospital and BirthRight by Rainbow is an
            exclusive woman and child care hospital with a nationwide footprint.
            Our full-spectrum, consultant-led woman and child care services are
            driven by 22 years of experience and have transformed the lives of
            women and children across the cities of Hyderabad, Vijayawada,
            Bengaluru, Delhi, Chennai, and Visakhapatnam.
          </p>
        </div>
      </div>
    </div>
  </section>
  <section class="ourcentrebg">
    <div class="container">
      <ul #centreCity class="ctabs">
        <li *ngFor="let cityn of centerCityList">
          <a href="javascript:void(0)">
            <div class="aiconwrp">
              <img src="{{ cityn.cityicon }}" alt="" />
            </div>
            <h2>{{ cityn.city }}</h2>
          </a>
        </li>
      </ul>

      <div class="ctabscontent">
        <div class="ctabsdata" *ngFor="let centre of centerCityList">
          <div class="OurCentreLists">
            <div class="tagslink">
              <ul>
                <li>
                  <a href="our-centre-details/{{ centre.city }}"
                    >Services and other Offerings at {{ centre.city }}</a
                  >
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li class="ourCentreWrp" *ngFor="let centd of centre.centers">
                  <div class="BranchImg">
                    <img
                      src="{{ imgBasePath }}{{ centd.centerimage }}"
                      class="img-responsive"
                    />
                    <a
                      href="https://www.google.com/maps?saddr=My+Location&daddr={{
                        centd.lat_long
                      }}"
                      class="directiontext"
                      target="_blank"
                      ><img src="assets/images/icon_dir.png" />
                      <p>Direction</p></a
                    >
                  </div>
                  <div class="address-wapper">
                    <h2 class="textname">
                      <a href="/our-centre/{{ centd.slug }}">{{
                        centd.center_name_alias
                      }}</a>
                    </h2>
                    <p class="addressText">{{ centd.address }}</p>
                    <div class="aboutCenter clearfix">
                      <span class="aboutcent">About Centre</span>
                    </div>
                    <div
                      class="aboutCenter mart clearfix"
                      *ngIf="centd.smalldescription != null"
                    >
                      <p class="about-read-more">
                        <span>{{ centd.smalldescription }} </span>
                        <a href="/our-centre/{{ centd.slug }}" class="kMoreLink"
                          >Know More</a
                        >
                      </p>
                    </div>
                    <div class="cButWrp">
                      <a
                        href="all/book-an-appointment"
                        class="booknapopoinment"
                        tabindex="0"
                        ><span>Book an Appointment</span></a
                      >
                      <a href="tel:1800 2122" class="callto"
                        ><span>1800 2122</span></a
                      >
                    </div>
                  </div>
                  <div class="hospitalInfoWrp">
                    <div class="hBox">
                      <h4 class="beds">{{ centd.bedscount }}+<br />Bed</h4>
                    </div>
                    <div class="hBox">
                      <h4 class="doctors">
                        {{ centd.doctorscount }}+<br />Doctors
                      </h4>
                    </div>
                    <div class="hBox">
                      <h4 class="nurses">
                        {{ centd.nursescount }}+<br />Nurses
                      </h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!--
                <div class="ctabsdata">
                    <div class="OurCentreLists">
                        <div>
                            <ul>
                                <li class="ourCentreWrp">
                                    <div class="BranchImg ">
                                        <img src="https://rainbowbackenduat.rainbowhospitals.in//uploads/3614ddd818a3a9d35912816e688b9225.jpg" class="img-responsive">
                                        <a class="directiontext" target="_blank"><img src="assets/images/icon_dir.png"><p>Direction</p></a>
                                    </div>
                                    <div class="address-wapper">
                                        <h2 class="textname">Banjara Hills</h2>
                                        <p>22, Road No - 4, (old road no-10), Karvy Lanes, Banjara Hills, Hyderabad - 500 034. Telangana, INDIA..</p>
                                        <div class="aboutCenter clearfix"><a href="#" class="aboutcent" tabindex="0"><span>About Centre</span></a></div>
                                        <div class="cButWrp">
                                            <a href="all/book-an-appointment" class="booknapopoinment" tabindex="0"><span>Book an Appointment</span></a>
                                            <a href="tel:1800 2122" class="callto"><span>1800 2122</span></a>
                                        </div>
                                    </div>
                                    <div class="hospitalInfoWrp">
                                        <div class="hBox">
                                            <h4 class="beds">225+<br>Bed</h4>
                                        </div>
                                        <div class="hBox">
                                            <h4 class="doctors">200+<br>Doctors</h4>
                                        </div>
                                        <div class="hBox">
                                            <h4 class="nurses">320+<br>Nurses</h4>
                                        </div>
                                    </div>
                                </li>
                                <li class="ourCentreWrp">
                                    <div class="BranchImg ">
                                        <img src="https://rainbowbackenduat.rainbowhospitals.in//uploads/3614ddd818a3a9d35912816e688b9225.jpg" class="img-responsive">
                                        <a class="directiontext" target="_blank"><img src="assets/images/icon_dir.png"><p>Direction</p></a>
                                    </div>
                                    <div class="address-wapper">
                                        <h2 class="textname">LB Nagar, Hyderabad</h2>
                                        <p>Plot No 73/C & 73/D, Survey No # 52, Saraswathi Nagar Colony,Mansoorabad Village,LB Nagar, Ranga Reddy District, 500074.</p>
                                        <div class="aboutCenter clearfix"><a href="#" class="aboutcent" tabindex="0"><span>About Centre</span></a></div>
                                        <div class="cButWrp">
                                            <a href="all/book-an-appointment" class="booknapopoinment" tabindex="0"><span>Book an Appointment</span></a>
                                            <a href="tel:1800 2122" class="callto"><span>1800 2122</span></a>
                                        </div>
                                    </div>
                                    <div class="hospitalInfoWrp">
                                        <div class="hBox">
                                            <h4 class="beds">225+<br>Bed</h4>
                                        </div>
                                        <div class="hBox">
                                            <h4 class="doctors">200+<br>Doctors</h4>
                                        </div>
                                        <div class="hBox">
                                            <h4 class="nurses">320+<br>Nurses</h4>
                                        </div>
                                    </div>
                                </li>
                                <li class="ourCentreWrp">
                                    <div class="BranchImg ">
                                        <img src="https://rainbowbackenduat.rainbowhospitals.in//uploads/3614ddd818a3a9d35912816e688b9225.jpg" class="img-responsive">
                                        <a class="directiontext" target="_blank"><img src="assets/images/icon_dir.png"><p>Direction</p></a>
                                    </div>
                                    <div class="address-wapper">
                                        <h2 class="textname">Madhapur</h2>
                                        <p>No 25, Survey No. 11/2 Opposite Shilparamam Hitech City, Hyderabad 500081.</p>
                                        <div class="aboutCenter clearfix"><a href="#" class="aboutcent" tabindex="0"><span>About Centre</span></a></div>
                                        <div class="cButWrp">
                                            <a href="all/book-an-appointment" class="booknapopoinment" tabindex="0"><span>Book an Appointment</span></a>
                                            <a href="tel:1800 2122" class="callto"><span>1800 2122</span></a>
                                        </div>
                                    </div>
                                    <div class="hospitalInfoWrp">
                                        <div class="hBox">
                                            <h4 class="beds">225+<br>Bed</h4>
                                        </div>
                                        <div class="hBox">
                                            <h4 class="doctors">200+<br>Doctors</h4>
                                        </div>
                                        <div class="hBox">
                                            <h4 class="nurses">320+<br>Nurses</h4>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="ctabsdata">
                    <h2>Vijayawada</h2>
                </div>
                <div class="ctabsdata">
                    <h2>Bengaluru</h2>
                </div>
                <div class="ctabsdata">
                    <h2>Delhi</h2>
                </div>
                <div class="ctabsdata">
                    <h2>Chennai</h2>
                </div>
                <div class="ctabsdata">
                    <h2>Vizag</h2>
                </div>
            -->
      </div>
    </div>
  </section>

  <!--<centres-map [centerList]="centerList" [centerId]="selectedCentreId"></centres-map>-->
  <ng-template [ngIf]="links.length > 0">
    <footer-nav-mini
      [links]="links"
      [activePage]="activePage"
    ></footer-nav-mini>
  </ng-template>
  <find-a-doctors-nav></find-a-doctors-nav>

  <footer-subscription></footer-subscription>
  <footer class="footerNavwrp">
    <footer-nav></footer-nav>
  </footer>
  <footer-copyright-nav></footer-copyright-nav>
</div>
