import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL } from '../services/constants';
import { Meta, Title } from '@angular/platform-browser';
// import { Http, RequestOptions, URLSearchParams, Headers } from "@angular/http";
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DatePipe } from '@angular/common';
import { data } from '../../mock-api/data';

declare let $: any;
declare let initHome: any;
declare let initAllPage: any;

declare let initAboutTabs: any;
declare let dataLayerPush: any;

@Component({
  selector: 'confirmed',
  templateUrl: './confirmed.component.html',
  styles: [
    `
      .caDetails {
        font-size: 1.1em;
      }
      .caDetails span {
        font-weight: bold;
        font-size: 1.1em;
        color: #773996;
        display: inline-block;
        margin: 4px 0px;
      }
      .pagetitle h3 {
        font-size: 1.8em;
        margin: 0px 0px 0px;
      }
    `,
  ],
})
export class ConfirmedComponent implements OnDestroy, OnInit, AfterViewInit {
  public hidden = 'hidden';
  public logo = 'logo';
  imgBasePath: any;
  ConfirmAppointmentID: any;
  ConfirmAppointmentInfo: any = [];
  public trackingId: any;
  public links: any = [];
  public title: any = '';
  public aID: any;
  public payment_status_code: boolean = false;
  public cancelAppointmentId: any;
  today = Date.now();
  public nBookingUID: any;
  public bAID: any;
  public payment_sucsses_status: boolean = false;
  public payment_fail_status: boolean = false;

  constructor(
    private api: ApiService,
    public datepipe: DatePipe,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    public metaTitle: Title,
    public meta: Meta
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/jquery.cookie.js');
    }
    this.aID = this.route.snapshot.queryParams['aid'];
    this.bAID = this.route.snapshot.queryParams['status'];
    //localStorage.setItem("cAppointmentID", '694');
  }

  ngOnInit() {
    //this.OnSucssesClientSideUpdateTable(appointmentID)
    //console.log('Appointment ID:', this.bAID);
    this.ConfirmAppointmentID = localStorage.getItem('cAppointmentID'); //"408";
    if ($.cookie('cAppointmentID') != undefined) {
      this.ConfirmAppointmentID = $.cookie('cAppointmentID');
    }
    //console.log('localStorage Set Appointment ID: ', this.ConfirmAppointmentID, 'Cookie Set AppointmentID : ',$.cookie("cAppointmentID"));
    this.imgBasePath = IMG_BASE_URL;
    if (this.ConfirmAppointmentID != null || this.ConfirmAppointmentID != '') {
      console.log('Appointment ID:', this.ConfirmAppointmentID);
      this.api
        .getAppointmentDetails(this.ConfirmAppointmentID)
        .subscribe((res) => {
          console.log('getAppointmentDetails', res);

          this.getEmailAndSms(this.ConfirmAppointmentID);
          let response = res;

          if (response[0] == undefined || response[0] == '') {
            this.ConfirmAppointmentInfo = response;
          } else {
            this.ConfirmAppointmentInfo = response[0];
          }

          if (this.ConfirmAppointmentInfo.paymentstatus == 'Fail') {
            this.cancelAppointmentId =
              this.ConfirmAppointmentInfo.account_number;
            this.GetPatientUID(this.cancelAppointmentId);
          }
          if (
            this.ConfirmAppointmentInfo.paymentstatus == 'Pending' ||
            this.ConfirmAppointmentInfo.paymentstatus == 'N/A'
          ) {
            this.payment_status_code = true;
          } else {
            this.payment_status_code = false;
            if (this.ConfirmAppointmentInfo.paymentstatus != 'Fail') {
              this.payment_sucsses_status = true;
              this.payment_fail_status = false;
            } else {
              this.payment_fail_status = true;
              this.payment_sucsses_status = false;
            }
          }
          console.log(
            'pg_status_code:',
            this.ConfirmAppointmentInfo.pg_status_code
          );
          if (
            this.ConfirmAppointmentInfo.pg_status_code == 1200 ||
            this.ConfirmAppointmentInfo.pg_status_code == 1406
          ) {
            this.OnSucssesClientSideUpdateTable(
              this.ConfirmAppointmentInfo.account_number
            );
          }
          // this.ClientSideUnpaidUpdateTable(this.ConfirmAppointmentInfo.account_number,'cancel');
          // let res_status_message = JSON.parse(this.ConfirmAppointmentInfo.res_status_message);
          //console.log('res_status_message:', res_status_message);
          //let payment_response = res_status_message.payment_response;
          //console.log('payment_response:', payment_response);
          // this.trackingId = payment_response.tracking_id;
          //localStorage.removeItem("cAppointmentID");
        });
    }
    if (this.ConfirmAppointmentID == null || this.ConfirmAppointmentID == '') {
      //console.log('ID:', this.ConfirmAppointmentID);
      this.router.navigate(['../']);
    }
  }

  //this.DataLayerOnFormChange("BookingPage","BookingPage_SelectDoctor_ConfirmBooking","Book-an-appointment");
  DataLayerOnFormChange(eventAction, eventlabel, eventCategory) {
    let c_path = window.location.pathname.split('/');
    let pName = c_path[c_path.length - 2],
      spName = c_path[c_path.length - 1];
    pName = pName.replace(/-/gi, '-');
    spName = spName.replace(/-/gi, '_');
    dataLayerPush(
      eventAction,
      eventlabel,
      eventCategory,
      '',
      pName,
      pName,
      spName,
      '',
      'English',
      pName,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      ''
    );
  }

  getEmailAndSms(id: any) {
    this.api.getemailNsmsSend(id).subscribe((data) => {
      console.log('getEmailAndSms:', data);
    });
  }

  ThankYouMassage(id) {
    this.api.getAppointmentDetails(id).subscribe((res) => {
      let response = res;
      if (response[0] == undefined || response[0] == '') {
        this.ConfirmAppointmentInfo = response;
      } else {
        this.ConfirmAppointmentInfo = response[0];
      }
    });
  }

  cancellationAPI() {
    let appointmentId = this.cancelAppointmentId,
      cReason = 'Cancel Appointment on Payment Fail';

    this.api.postCancellationAPI(this.nBookingUID, cReason).subscribe((res) => {
      console.log('postCancellationAPI Response:', res);
      let resAppointmentId = res['CancelPortalAppointmentResult'].A;
      let resAppointmentC = res['CancelPortalAppointmentResult'].B;
      console.log(
        'resAppointmentId:',
        resAppointmentId,
        'resAppointmentC:',
        resAppointmentC
      );
      if (res != '') {
        //if(appointmentId == resAppointmentId){
        if (resAppointmentC == 'Cancelled') {
          let cancelAppointmentData = {
            appointment_id: this.cancelAppointmentId,
            cancellationdate: this.datepipe.transform(
              new Date(this.today),
              'yyyy-MM-dd HH:mm'
            ),
            cancellationremark: 'Cancel',
            appointmentbokignbtatus: 'cancel',
          };

          //console.log('cancelAppointment paramiter post: ', cancelAppointmentData);
          this.api.cancelAppointment(cancelAppointmentData).subscribe((res) => {
            console.log('cancelAppointment: ', res);

            this.ClientSideUnpaidUpdateTable(appointmentId, cReason);
          });
        }
        //}
      }
    });
  }
  OnSucssesClientSideUpdateTable(appointmentID) {
    this.api.get_Appointment_id(appointmentID).subscribe((res_id) => {
      console.log('get_Appointment_id: ', res_id, res_id['id'][0].id);
      console.log('ConfirmAppointmentInfo: ', this.ConfirmAppointmentInfo);
      let website_id = res_id['id'][0].id;
      let AppointmentDataUpdateToClientDB = {
        action: 'PaymentCompletion_Update',
        status: 1,
        Paymentstatus: this.ConfirmAppointmentInfo.status_code,
        Paymentmode: this.ConfirmAppointmentInfo.payment_mode,
        Transactionid: this.ConfirmAppointmentInfo.transaction_id,
        Orderid: this.ConfirmAppointmentInfo.processing_id,
        statusmessage: this.ConfirmAppointmentInfo.status_message,
        Statuscode: this.ConfirmAppointmentInfo.status_code,
        Responsemessage: this.ConfirmAppointmentInfo.responseMessage,
        Websiteid: website_id,
      };
      this.api
        .AppointmentPostClientData(AppointmentDataUpdateToClientDB)
        .subscribe((res) => {
          console.log('OnSucssesClientSideUpdateTable: ', res);
        });
    });
  }

  ClientSideUnpaidUpdateTable(appointmentID, cancellationremark) {
    this.api.get_Appointment_id(appointmentID).subscribe((res_id) => {
      console.log('get_Appointment_id: ', res_id, res_id['id'][0].id);
      //console.log('ConfirmAppointmentInfo: ',this.ConfirmAppointmentInfo)
      let website_id = res_id['id'][0].id;
      let AppointmentDataUpdateToClientDB = {
        action: 'appointmet_fail_auto_cancel_update',
        status: 2,
        Websiteid: website_id,
        cancellationremark: cancellationremark,
        cancellationtype: 0,
        p_responseStatus: this.ConfirmAppointmentInfo.responseStatus,
        payment_mode: this.ConfirmAppointmentInfo.payment_mode,
        transaction_id: this.ConfirmAppointmentInfo.transaction_id,
        order_id: this.ConfirmAppointmentInfo.processing_id,
        status_message: this.ConfirmAppointmentInfo.status_message,
        status_code: this.ConfirmAppointmentInfo.status_code,
        responseMessage: this.ConfirmAppointmentInfo.responseMessage,
      };
      let AppointmentFeesMismachDataUpdateToClientDB = {
        action: 'appointmet_fail_cancel_update',
        status: 2,
        Websiteid: website_id,
        cancellationremark: cancellationremark,
        cancellationtype: 0,
      };
      //console.log('AppointmentDataUpdateToClientDB: ', AppointmentDataUpdateToClientDB);
      if (this.payment_status_code) {
        this.api
          .AppointmentPostClientData(AppointmentFeesMismachDataUpdateToClientDB)
          .subscribe((res) => {
            console.log('AppointmentPostClientData: ', res);
          });
      } else {
        this.api
          .AppointmentPostClientData(AppointmentDataUpdateToClientDB)
          .subscribe((res) => {
            console.log('AppointmentPostClientData: ', res);
          });
      }
    });
  }
  GetPatientUID(id) {
    this.api.getPatient_Uid(id).subscribe((res) => {
      console.log('getPatient_Uid', res);
      let pUid = res[0].patient_uid;
      this.GetAppointmentForPatient(pUid);
    });
  }
  GetAppointmentForPatient(id) {
    this.api.GetAppointmentForPatientID(id).subscribe((res) => {
      console.log('GetAppointmentForPatientID', res);
      this.nBookingUID = res['GetAppointmentForPatientResult'][0].A;
      console.log('nBookingUID', this.nBookingUID);
      this.cancellationAPI();
    });
  }
  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
    }
  }

  ngOnDestroy() {}
}
