<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <section class="page pageheader">

        <div class="container">
            <div class="col-xs-6 col-sm-6 col-lg-6 abannimgwrp">
                <div class="otherbannimg"><img src="assets/images/bann-quiz.png" class="img-responsive" alt="{{title}}" title="{{title}}" /></div>
            </div>
            <div class="col-xs-6 col-sm-6 col-lg-6">
                <div class="ptitle">
                    <h1>{{title}}</h1>
                    <h3>{{subtitle}}</h3>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-lg-6 mcont100">
                <p [innerHTML]="description"></p>
                <div class="childbirthfmwrp">
                    <ul class="childblst">
                        <li>
                            <div class="findoswrp">
                                <select class="select" name="selectedQuizCategory" (change)="changeQuiz()" [(ngModel)]="selectedQuizCategory">
							<option value="0" [selected]="selectedQuizCategory == 0">Select</option>
							<option *ngFor="let speciality of specialityMasters; let i= index;" [ngValue]="speciality.id">{{speciality.speciality}}</option>
							<!--<option value="Child Care" selected>Child Care</option>
							<option value="Women Care">Women Care</option>
							<option value="Fertility Care">Fertility Care</option>-->
						</select>
                                <span class="select-span"></span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>

    <section #quiz class="quizwrp" [hidden]="!selectedQuizCategory" style="position: relative;">
        <div class="CalcLoader">
            <img src="assets/images/loading.gif" alt="" />
        </div>
        <div class="container" *ngIf="quizList.length == 0">
            <div class="col-md-12col-sm-12 col-lg-12">
                <div class="subtitle2">
                    <h3>Coming Soon</h3>
                </div>
            </div>
        </div>
        <div class="container" *ngIf="quizList.length > 0">
            <div class="col-md-12 col-sm-12 col-lg-12">
                <div class="subtitle2" *ngIf="quizHeading">
                    <h3 [innerHTML]="quizHeading"></h3>
                </div>
                <div *ngIf="quizGroup">
                    <ng-template ngFor let-quiz [ngForOf]="quizGroup" class="startquizinfowrp">
                        <div class="quizicon"><img src="{{imgBasePath}}{{quiz.image}}" alt=""></div>
                        <h4 [innerHTML]="quiz.title"></h4>
                    </ng-template>

                </div>
                <div class="quizslidewrp">
                    <div class="quzsinfo startquizinfowrp">
                        <span *ngIf="quizDescription" [innerHTML]="quizDescription"></span>
                        <a href="javascript:void(0);" class="startquiz" id="quzsNext" (click)="startQuiz()">Start Now</a>
                    </div>

                    <form class="quizform" [formGroup]="quizForm" method="post">
                        <div class="quzs startquizwrp">
                            <div class="slidequizwrp">
                                <ul id="quizform" class="quizform" data-class="quizform">
                                    <li *ngFor="let quiz of quizList; let i = index;" class="quizqst" [ngClass]="{active:i == 0}" data-class="quizqst">
                                        <div class="qztitle">
                                            <h5>Question {{i+1}}</h5>
                                        </div>
                                        <h6 [innerHTML]="quiz.question"></h6>
                                        <ul class="qzqlist">
                                            <li [ngClass]="{'show':quiz.option1,'hide':!quiz.option1}">
                                                <label data-class="question{{i+1}}">
                                                        <i>{{quiz.score1}}</i>
													<input (click)="clickInput($event)" *ngIf="quiz.option1" type="radio" data-class="question{{i+1}}" value="{{quiz.id}}-1"  class="question{{i+1}}" name="childage{{i+1}}" > {{quiz.option1}}
												</label>
                                            </li>
                                            <li [ngClass]="{'show':quiz.option2,'hide':!quiz.option2}"><label><i>{{quiz.score2}}</i><input (click)="clickInput($event)" *ngIf="quiz.option2"  type="radio" data-class="question{{i+1}}" value="{{quiz.id}}-2"  class="question{{i+1}}" name="childage{{i+1}}" >  {{quiz.option2}}</label></li>
                                            <li [ngClass]="{'show':quiz.option3,'hide':!quiz.option3}"><label><i>{{quiz.score3}}</i><input (click)="clickInput($event)" *ngIf="quiz.option3"  [ngClass]="{'show':quiz.option3,'hide':!quiz.option3}"  type="radio" data-class="question{{i+1}}"  value="{{quiz.id}}-3"  class="question{{i+1}}" name="childage{{i+1}}">  {{quiz.option3}}</label></li>
                                            <li [ngClass]="{'show':quiz.option4,'hide':!quiz.option4}"><label><i>{{quiz.score4}}</i><input (click)="clickInput($event)" *ngIf="quiz.option4"  [ngClass]="{'show':quiz.option4,'hide':!quiz.option4}"  type="radio" data-class="question{{i+1}}"  value="{{quiz.id}}-4"  class="question{{i+1}}" name="childage{{i+1}}">  {{quiz.option4}}</label></li>
                                            <li [ngClass]="{'show':quiz.option5,'hide':!quiz.option5}"><label><i>{{quiz.score5}}</i><input (click)="clickInput($event)" *ngIf="quiz.option5"  [ngClass]="{'show':quiz.option5,'hide':!quiz.option5}"  type="radio" data-class="question{{i+1}}"  value="{{quiz.id}}-5"  class="question{{i+1}}" name="childage{{i+1}}" >  {{quiz.option5}}</label></li>
                                            <li [ngClass]="{'show':quiz.option6,'hide':!quiz.option6}"><label><i>{{quiz.score6}}</i><input (click)="clickInput($event)" *ngIf="quiz.option6"  [ngClass]="{'show':quiz.option6,'hide':!quiz.option6}"  type="radio" data-class="question{{i+1}}"   value="{{quiz.id}}-6" class="question{{i+1}}" name="childage{{i+1}}">  {{quiz.option6}}</label></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <p>Thank you for taking the Quiz. Please fill the details below and click on “Get Results” to get your quiz results. Thank you!</p>
                                        <div class="formcasn">
                                            <ul>
                                                <li class="col-xs-12 col-sm-6 col-lg-6">
                                                    <input type="hidden" name="quiz_group_id" formControlName="quiz_group_id">
                                                    <label>Name</label>
                                                    <input type="text" [ngClass]="{'has-error':!quizForm.controls.name.valid && quizForm.controls.name.touched}" formControlName="name" name="name" placeholder="Enter Name" />
                                                </li>
                                                <li class="col-xs-12 col-sm-6 col-lg-6">
                                                    <label>Email</label>
                                                    <input type="email" [ngClass]="{'has-error':!quizForm.controls.email.valid && quizForm.controls.email.touched}" formControlName="email" name="email" placeholder="Enter Email ID" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" />
                                                </li>

                                                <li class="col-xs-12 col-sm-6 col-lg-6">
                                                    <label>Phone</label>
                                                    <input type="text" [ngClass]="{'has-error':!quizForm.controls.phone.valid && quizForm.controls.phone.touched}" (keypress)="isNumber($event)" formControlName="phone" name="phone" placeholder="Enter Phone" />
                                                </li>
                                                <li class="col-xs-12 col-sm-6 col-lg-6">
                                                    <label>City</label>
                                                    <input type="text" [ngClass]="{'has-error':!quizForm.controls.city.valid && quizForm.controls.city.touched}" formControlName="city" name="city" placeholder="Enter City" />
                                                </li>
                                                <li class="col-xs-12 col-lg-12">
                                                    <input type="button" name="submit" class="rimsubmitbtn" value="Submit" (click)="submitQuizForm()" />
                                                </li>
                                            </ul>
                                            <p>Please note that the score and what it indicates is based on the responses you have shared in the quiz. This is not to be taken as a means of diagnosis. It is best to consult an expert specialist for any further
                                                investigations or diagnosis.</p>
                                        </div>
                                    </li>
                                </ul>
                                <a href="javascript:;" id="quzfprev" (click)="selectedQuiz('pop')">Back</a>
                                <a href="javascript:;" id="quzfnext" (click)="selectedQuiz('push')" style="display: none;">Next</a>
                                <a href="javascript:;" id="quzfnextActive" (click)="checkSelecteOption($event)">Next</a>
                            </div>
                        </div>
                    </form>
                    <!--<div class="quizresult">Hi</div>-->
                </div>
            </div>
        </div>
    </section>

    <div class="capopup" data-popup="popup-120">
        <div class="capopup-inner">
            <h2>Thank You for playing the Quiz</h2>
            <a class="capopup-close" data-popup-close="popup-120" href="#"><img src="assets/images/icon-close2.png" alt="" /></a>
        </div>
    </div>
    <div class="capopup" data-popup="popup-140">
        <div class="capopup-inner">
            <h2>Thank You for playing the Quiz</h2>
            <h3>{{ quizMsg }}</h3>
            <a class="capopup-close" data-popup-close="popup-140" href="#"><img src="assets/images/icon-close2.png" alt="" /></a>
        </div>
    </div>
    <div class="capopup" data-popup="popup-141">
        <div class="capopup-inner">
            <h2>Thank You for playing the Quiz</h2>
            <h3>{{ quizMsg }}</h3>
            <a class="capopup-close" data-popup-close="popup-141" href="#"><img src="assets/images/icon-close2.png" alt="" /></a>
        </div>
    </div>
    <div class="capopup" data-popup="popup-165">
        <div class="capopup-inner">
            <h2>Thank You for playing the Quiz</h2>
            <h3>{{ quizMsg }}</h3>
            <a class="capopup-close" data-popup-close="popup-165" href="#"><img src="assets/images/icon-close2.png" alt="" /></a>
        </div>
    </div>
    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>

</div>