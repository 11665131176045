import { Component, Input } from '@angular/core';

@Component({
  selector: 'find-a-doctors-nav',
  template: `
    <div class="footerstikynav active">
      <div class="container">
        <div class="btnwrpappointment">
          <ul>
            <li>
              <a href="/all/book-an-appointment" class="fdnbaa"
                ><img src="assets/images/icon-findadoctor.png" alt="" />Find a
                doctor /<br />Book an appointment</a
              >
              <div class="scrollTopBg clickScrollTop">
                <span class="icon-scroll"></span>
              </div>
            </li>
            <li>
              <a
                href="/hello-doctor-online-consultation/"
                target="_blank"
                class="fdnbaa"
                ><img src="assets/images/icon_vc.png" alt="" />Book a Video
                <br />
                Consultation Now</a
              >
            </li>
            <!--
                            <li>
                                <a href="/hello-doctor-online-consultation/" target="_blank" class="fdnbaa"><img src="assets/images/icon-findadoctor.png" alt="" /> Book a Video <br> Consultation Now</a>
                                <div class="scrollTopBg clickScrollTop"><span class="icon-scroll"></span></div>
                            </li>
                            <li>
                                <a href="/our-centres" class="fdnbaa"><img src="https://backend.rainbowhospitals.in/assets/images/location.png" alt="" />Our Centres /<br>Specialities</a>
                            </li>
                            -->
          </ul>
        </div>
      </div>
    </div>
  `,
})
export class findADoctorsComponent {
  @Input('isShowFAD') isShowFAD: boolean;
}
