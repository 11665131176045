import { Inject, Component, OnDestroy, OnInit, AfterViewInit, PLATFORM_ID, ElementRef, ViewChildren, QueryList, ViewChild } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from "../services/api.service";
import { IMG_BASE_URL } from "../services/constants";
import { Meta, Title } from '@angular/platform-browser';


declare let $:any;
declare let initHome:any;
declare let initAllPage:any;

@Component({
  selector: 'thankyou',
  templateUrl: './thankyou.component.html',
  styles: [
    `
      .abdspecialities {
        padding: 40px 0 40px;
        position: relative;
        border-top: 1px dashed #e4d8ea;
        background: #fbf5ff;
      }
      .abdlist li .cellwrp1 h3 {
        font-size: 36px;
      }
      .pageheader {
        padding: 150px 0px 20px !important;
      }
      header.headerwrp {
        padding: 0 0 4px 0;
        position: fixed;
        top: 0;
        height: 84px;
        background: rgba(255, 255, 255, 0.7) none repeat;
        border-bottom: 1px solid #f6f6f6;
      }
      header.headerwrp .logowrp {
        width: 135px;
        padding: 0px 0;
        margin-top: 0px !important;
      }
      header.headerwrp .logowrp img {
        margin-top: 0px !important;
      }
      header.headerwrp.fixed .logowrp {
        width: 135px;
        padding: 0px 0;
      }
      header.headerwrp.fixed .logowrp img {
        margin-top: 0px !important;
      }
      .navgroup {
        margin-top: 2px;
        padding: 7px 12px 0px 15px;
        // background: url(../../assets/images/visitBg.png) no-repeat right top;
        background-size: 100% 100%;
        border: none;
      }
      header .tonnavwrp li .navgroup a {
        color: #5f2160;
        font-family: 'dinrg';
        font-size: 14px;
      }
      header .tonnavwrp li .navgroup a:first-child {
        margin-right: 30px;
      }
      header .tonnavwrp li .navgroup a:first-child::after {
        position: absolute;
        right: -26px;
        top: 7px;
        height: 7px;
        width: 23px;
        background: url(../../assets/images/btn-arrow-right.png) no-repeat right
          top;
        content: '';
      }
      @media (max-width: 1080px) {
        .pageheader {
          padding: 20px 0px 20px !important;
        }
      }
      @media (max-width: 800px) {
        header.headerwrp,
        header.headerwrp.fixed {
          height: 68px;
        }
        header .logowrp img {
          width: 120px;
        }
      }
      @media (max-width: 680px) {
        .abdlist li .cellwrp1 h3 {
          font-size: 22px;
        }
        header.headerwrp .logowrp,
        header.headerwrp.fixed .logowrp {
          width: auto;
          margin-top: 10px;
        }
      }
      @media (max-width: 480px) {
        .pageheader .ptitle h2 {
          font-size: 24px !important;
          line-height: 25px !important;
        }
      }
      @media (max-width: 360px) {
        header .tonnavwrp {
          right: -6px;
        }
        header .logowrp img {
          width: 120px;
        }
        .navgroup {
          margin-top: 2px;
          padding: 7px 6px 0px 7px;
        }
      }
    `,
  ],
})
export class GetInTouchThankyouComponent implements OnDestroy, OnInit {
  @ViewChild('scriptConversion') scriptConversion: ElementRef;
  constructor(
    private api: ApiService,
    public metaTitle: Title,
    public meta: Meta
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/jquery.cookie.js');
    }
  }
  public features: any[] = [];
  public facilities: any[] = [];
  public question: any = [];
  public doctors: any = [];
  public title: any = '';
  public subtitle: any = '';
  public description: any = '';
  public imgsrc: any = '';
  public columns = -1;
  public id: any = '';
  public imgBasePath = '';
  public speciality: string = '';
  public category: string = '';

  ngOnInit() {
    this.title = $.cookie('pTitle');
    this.imgsrc = $.cookie('pBannImg');
  }

  ngAfterViewInit() {
    var date = new Date();
    date.setTime(date.getTime() + 30 * 1000);
    $.cookie('showThankYouPage', '1', { expires: date });
    setTimeout(function () {
      history.back();
    }, 5000);
  }

  ngOnDestroy() {}
}

