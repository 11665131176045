<div class="container-fluid">
    <div class="background">
        <img src="assets/images/bg_childcare.png" data-t="bg_01_00.jpg" data-d="bg_childcare.png" class="bgImg bg0 active" />
    </div>
    <div class="loder"></div>
    <sidenav></sidenav>
    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <section class="page pageheader">
        <div class="row">
            <div class="container">
                <div class="col-xs-12 col-sm-7 col-lg-7 abannimgwrp">
                    <div class="aboutbannimg"><img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" /></div>
                </div>
                <div class="col-xs-12 col-sm-5 col-lg-5">
                    <div class="ptitle blue">
                        <h1>{{title}}</h1>
                        <h3>{{subtitle}}</h3>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="rchannelistwrp">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-lg-12">
                    <ul class="rchannelist" *ngIf="videoList.length != -1">
                        <ng-template ngFor let-video [ngForOf]="videoList" let-i="index">
                            <li>
                                <div class="rclhumb">
                                    <a data-fancybox [href]="video.youtube_url" class="fancybox">
                                        <img *ngIf="video.image" src="{{imgBasePath}}{{video.image}}" alt="{{video.title}}">
                                        <img *ngIf="!video.image" src="./assets/images/no-image.png" alt="{{video.title}}">
                                    </a>
                                </div>
                                <p [innerHTML]="video.title"></p>
                            </li>
                        </ng-template>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>