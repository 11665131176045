import { Inject, Component, OnDestroy, OnInit, AfterViewInit, PLATFORM_ID, ViewChildren, QueryList } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from "../services/api.service";
import { IMG_BASE_URL } from "../services/constants";
import { Meta, Title } from '@angular/platform-browser';
declare let $: any;
declare let initHome: any;
declare let initAllPage: any;

declare let initAboutTabs: any;

@Component({
  selector: 'emergency-service',
  templateUrl: './emergency-service.component.html',
})
export class EmergencyServiceComponent
  implements OnDestroy, OnInit, AfterViewInit
{
  public active: any;
  public title: any = '';
  public subtitle: any = '';
  public description: any = '';
  public services: any = [];
  public imgsrc: any = '';
  public imgBasePath = '';
  public hidden = 'hidden';
  public logo = 'logo';
  public pageData: any = [];
  public bread_crumb_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Others', link: '', class: '' },
    { label: 'Emergency Care', link: '', class: '' },
  ];

  constructor(
    private api: ApiService,
    public metaTitle: Title,
    public meta: Meta
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
    }
  }

  @ViewChildren('abouttabs') things: QueryList<any>;

  ngOnInit() {
    this.api.getPageDescription('emergency').subscribe(
      (data) => {
        // this.title = data.page_descriptions[0].title,
        // this.subtitle = data.page_descriptions[0].subtitle,
        // this.description = data.page_descriptions[0].description,
        // this.imgsrc = data.page_descriptions[0].image,
        this.pageData = data.page_descriptions[0];
        //console.log(this.pageData);
        this.imgBasePath = IMG_BASE_URL;
        if (data) {
          //console.log(data);
          this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
          this.meta.updateTag({
            name: 'keywords',
            content: data.page_descriptions[0].meta_keyword,
          });
          this.meta.updateTag({
            name: 'description',
            content: data.page_descriptions[0].meta_description,
          });
          this.meta.addTags([
            {
              property: 'og:title',
              content: data.page_descriptions[0].meta_title,
            },
            {
              property: 'og:description',
              content: data.page_descriptions[0].meta_description,
            },
            {
              property: 'og:keywords',
              content: data.page_descriptions[0].meta_keyword,
            },
          ]);
        }
      },
      (error) => {
        console.log(error);
      }
    );

    this.api.getHealthCareServices('emergency').subscribe((data) => {
      this.services = data.healthcare_services;
      console.log(this.services);
    });
  }

  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
    }

    this.things.changes.subscribe((t) => {
      // this.ngForRendred();
      initAboutTabs();
    });
  }

  ngOnDestroy() {}
}
