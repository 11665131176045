import { Inject, Component, OnDestroy, OnInit, AfterViewInit, PLATFORM_ID, ViewChildren, QueryList } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ApiService } from "../services/api.service";
import { IMG_BASE_URL } from "../services/constants";
import { Meta, Title } from '@angular/platform-browser';

declare let $:any;
declare let initHome:any;
declare let initAllPage:any;
declare let initAboutTabs:any;

@Component({
  selector: 'md-profile',
  templateUrl: './md-profile.component.html'
})

export class MdProfileComponent implements OnDestroy, OnInit, AfterViewInit {

   constructor(private api: ApiService, private activatedRoute: ActivatedRoute,public metaTitle: Title, public meta: Meta ) {
    if(this.api.isBrowser){
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/fancybox/jquery.fancybox.js');
      require('../../assets/js/slick.min.js');
    }
  }
 
  public services:any[] = [];
  public title:any = "";
  public subtitle:any = "";
  public description:any = "";
  public imgsrc:any = "";
  public brief_facilities_description:any = "";
  public brief_facilities_image:any = "";
  public imgBasePath = "";
  public hidden = "hidden";
  public logo="logo";
  public links: any = [];
  public doctorslug: any = "";
  public currentSection: string= '';
	public activeRoute: any = [];
	public activePage: any = [];

	public prevSection: string;
	public prevUrl: string;
  public bread_crumb_links:any = [{"label":"Home", "link": "/", "class": ""},
                                 {"label":"Doctors", "link": "all/doctors", "class": ""},
                                 {"label":"Dr. Ramesh Kancharla", "link": "", "class": ""}];








public child_care_links: any = [{ "label": "About Child Care", "link": "/child-care/about", "class": "" },
{ "label": "Child Safety", "link": "/child-care/child-safety", "class": "" },
	{ "label": "Doctors", "link": "/child-care/doctors", "class": "active" },
	{ "label": "Our Specialities", "link": "/child-care/specialities", "class": "" },
	{ "label": "Our Centres", "link": "/child-care/centres", "class": "" },
	{ "label": "Vaccination", "link": "/child-care/vaccination", "class": "" },
	{ "label": "Testimonials", "link": "/child-care/testimonials", "class": "" },
	{ "label": "Share Feedback", "link": "/child-care/feedback", "class": "" },
	{ "label": "Book An Appointment", "link": "/child-care/book-an-appointment", "class": "" }]


	public women_care_links: any = [{ "label": "About Women's Care", "link": "/women-care/about", "class": "" },
	{ "label": "Doctors", "link": "/women-care/doctors", "class": "active" },
	{ "label": "Our Specialities", "link": "/women-care/specialities", "class": "" },
	{ "label": "Our Centres", "link": "/women-care/centres", "class": "" },
	{ "label": "Testimonials", "link": "/women-care/testimonials", "class": "" },
	{ "label": "Share Feedback", "link": "/women-care/feedback", "class": "" },
	{ "label": "Book An Appointment", "link": "/women-care/book-an-appointment", "class": "" }]

	public fertility_care_links: any = [{ "label": "About Fertility Care", "link": "/fertility-care/about", "class": "" },
	{ "label": "Doctors", "link": "/fertility-care/doctors", "class": "active" },
	{ "label": "Our Specialities", "link": "/fertility-care/specialities", "class": "" },
	{ "label": "Our Centres", "link": "/fertility-care/centres", "class": "" },
	{ "label": "Testimonials", "link": "/fertility-care/testimonials", "class": "" },
	{ "label": "Share Feedback", "link": "/fertility-care/feedback", "class": "" },
	{ "label": "Book An Appointment", "link": "/fertility-care/book-an-appointment", "class": "" }];
  
  @ViewChildren('abouttabs') things: QueryList<any>;
  
  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
			//console.log(params);
			//let doctorurl = params['doctorslug'].split('.');
			//console.log(doctorurl);
			this.doctorslug = params['doctorslug'];
			this.currentSection = params['section'];
			//console.log(this.id);
    });
    this.activeBreadCrum(this.currentSection);
    this.api.getPageDescription('md-profile')
      .subscribe(
        data =>{
          this.title = data.page_descriptions[0].title;
          this.subtitle = data.page_descriptions[0].subtitle;
          this.description = data.page_descriptions[0].description;
          this.imgsrc = data.page_descriptions[0].image;
          this.imgBasePath = IMG_BASE_URL;
          if(data){
            //console.log(data);
            this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
            this.meta.updateTag({name:'keywords',content: data.page_descriptions[0].meta_keyword});
            this.meta.updateTag({name:'description',content: data.page_descriptions[0].meta_description});
            this.meta.addTags([
              { property: 'og:title', content:  data.page_descriptions[0].meta_title},
              { property: 'og:description', content: data.page_descriptions[0].meta_description },
              { property: 'og:keywords', content: data.page_descriptions[0].meta_keyword }
            ]);
          }
          
        },
        error => {
          console.log(error);
        }
      )


      this.api.getHealthCareServices('who-we-are')
        .subscribe(
          data =>{
            this.services = data.healthcare_services
          },
          error => {
            console.log(error);
          }
        )

        this.api.getBriefFacilities('who-we-are')
          .subscribe(
            data =>{
              this.brief_facilities_description = data.brief_facilities[0].description,
              this.brief_facilities_image = data.brief_facilities[0].image
            },
            error => {
              console.log(error);
            }
          )

  }
  activeBreadCrum(currentSection){
		switch (currentSection) {
			case "child-care":
				
				this.links = this.child_care_links;
				break;
      case "women-care":
      
				this.links = this.women_care_links;
				break;
      case "fertility-care":
      
				this.links = this.fertility_care_links;
        break;
      default:
      
				this.links = this.child_care_links;
		}
	}
  ngAfterViewInit() {

    if ( this.api.isBrowser && window['jQuery'] ) {
      initHome();
      initAllPage();
    }

    this.things.changes.subscribe(t => {
      // this.ngForRendred();
      initAboutTabs();
    })
  }

  ngOnDestroy() {}

}
