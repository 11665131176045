<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>
    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <section class="page pageheader">
        <div class="container">
            <div class="col-xs-12 col-sm-7 col-lg-7 abannimgwrp">
                <div class="aboutbannimg">
                    <img src="assets/images/banner-consultant.png" class="img-responsive"  alt="{{career.title}}" title="{{career.title}}" />
                </div>
            </div>
            <div class="col-xs-12 col-sm-5 col-lg-5">
                <div class="ptitle">
                    <h1 class="underline">{{career.title}}</h1>
                    <p>Qualification: {{career.qualification}}</p>
                    <p>Experience: {{career.experience}}</p>
                    <p>Location: {{career.location}}</p>
                </div>
            </div>
        </div>
    </section>
    <section class="jobdiscription">
        <div class="container">
            <div class="col-md-12 col-xs-12 col-lg-12">
                <h2 class="gradient">Job Description</h2>
                <ul class="list-discription">
                    <p [innerHTML]="career.description"></p>
                </ul>
            </div>
        </div>
    </section>
    <section class="applyformwrp">
        <div class="CalcLoader">
            <img src="assets/images/loading.gif" alt="" />
        </div>
        <div class="container">
            <div class="col-lg-12">
                <h3>Apply Now</h3>
                <p>Fill in your details below and submit.</p>
            </div>
            <div class="formcasn">
                <form id="form" [formGroup]="applyCarrerFormGroup" (submit)="careerFormApply($event, applyCarrerFormGroup)">
                    <ul>
                        <li class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                            <label>Name*
                                <span class="alertMsg" *ngIf="!applyCarrerFormGroup.controls.name.valid && submitted">
                                    required
                                </span>
                            </label>
                            <i>
                                <img src="assets/images/icon-name.png" class="img-responsive">
                            </i>
                            <input type="text" name="name" formControlName="name" class="" />
                        </li>
                        <li class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                            <label>Phone*
                                <span class="alertMsg" *ngIf="!applyCarrerFormGroup.controls.phone.valid && submitted">
                                    required
                                </span>
                            </label>
                            <i>
                                <img src="assets/images/icon-call3.png" class="img-responsive">
                            </i>
                            <input type="number" min="10" name="phone" formControlName="phone" class="" />
                        </li>
                        <li class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                            <label>Email*
                                <span class="alertMsg" *ngIf="!applyCarrerFormGroup.controls.email.valid && submitted">
                                    required
                                </span>
                            </label>
                            <i>
                                <img src="assets/images/icon-email.png" class="img-responsive">
                            </i>
                            <input type="email" name="email" class="" formControlName="email"
                            />
                        </li>
                        <li class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                            <label>Upload Resume*
                                <span class="alertMsg" *ngIf="!applyCarrerFormGroup.controls.upload_resume.valid && submitted">
                                    required
                                </span>
                            </label>
                            <i>
                                <img src="assets/images/icon-resume.png" class="img-responsive">
                            </i>
                            <div class="uploadfile ">
                                <div class="browse-btn">
                                    <input id="uploadCaptureInputFile" accept=".doc,.docx,.pdf" value="" type="file" class="inputfile" name="upload_resume" (change)="fileEvent($event)"
                                    />
                                </div>
                                <div>{{resume}}</div>
                                <input formControlName="upload_resume" type="hidden" />
                            </div>
                        </li>
                        <li class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                            <label>Message</label>
                            <i>
                                <img src="assets/images/icon-message.png" class="img-responsive">
                            </i>
                            <input type="text" name="message" formControlName="message" class="" />
                        </li>
                        <li class="col-md-4 col-sm-6 col-xs-12 col-lg-4">
                            <p>&nbsp;</p>
                            <input type="submit" class="rimsubmitbtn" />
                            <input type="reset" name="reset" class="rimresetbtn" value="Reset" (click)="resetForm()" />
                        </li>
                    </ul>
                </form>
            </div>
        </div>
    </section>
    <div class="capopup" data-popup="popup-2">
        <div class="capopup-inner">
            <h2>{{popUpMessage.title}}</h2>
            <h3>{{popUpMessage.message}}</h3>
            <a class="capopup-close" data-popup-close="popup-2" href="#">
                <img src="assets/images/icon-close2.png" alt="" />
            </a>
        </div>
    </div>
    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>