<div>
    <div class="loder"></div>
    <sidenav></sidenav>
    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <section class="page wtwedo">
        <div class="">
            <div class="col-md-5 col-xs-5 col-lg-4">
                <div class="circuleframe">
                    <img src="{{imgBasePath}}{{pageData.image}}" class="img-responsive" alt="{{pageData.title}} - Rainbow Hospitals" title="{{pageData.title}}" /> </div>
                <!-- End Circle -->
            </div>

            <div class="col-md-7 col-xs-7 col-lg-7">
                <div class="pagetitle">
                    <h1 class="textgradient">{{pageData.title}}</h1>
                    <div class="underline">
                        <img src="assets/images/underline.png" alt="" /> </div>
                    <h3>{{pageData.subtitle}}</h3>
                </div>
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7 mcontentview">
                <p [innerHTML]="pageData.description"></p>
            </div>
        </div>
    </section>
    <section>
        <div class="container">
            <div class="row">
                <div class=" col-xs-12 col-sm-12 col-md-8" >
                <ng-template ngFor let-contact [ngForOf]="contactus_list">
                        <div class="contactWapper col-xs-12 col-sm-6 col-md-6"  myMatchHeight="contactWapper">
                            <h3>{{contact.title}}</h3>
                            <p>{{contact.address}}</p>
                            <p class="contectInfo">
                                <span>
									<img src="assets/images/icon-Contact1.png">
								</span>{{contact.landline}}</p>
                            <p *ngIf="contact.fax" class="contectInfo">
                                <span>
									<img src="assets/images/icon-Contact2.png">
								</span>{{contact.fax}}</p>
                            <p class="contectInfo">
                                <span>
									<img src="assets/images/icon-Contact3.png">
								</span>
                                <a href="mailto:{{contact.email}}">{{contact.email}}</a>
                            </p>
                        </div>
                    </ng-template>
                    <div class="corporatecom">
                        <div class="contactWapper col-xs-12 col-sm-12 col-md-12">
                            <h3>For Corporate Communications, reach out to us at</h3>
                            <p class="contectInfo"><span><img src="assets/images/icon-Contact3.png"></span>
                            <a href="mailto:info@rainbowhospitals.in">info@rainbowhospitals.in</a></p>
                        </div>
                    </div>
                    <!--<div class="corporatecom" *ngIf="corporate">
                        <div class="perpuleWap">{{corporate.title}}</div>
                        <div class="contactWapper col-xs-12 col-sm-6 col-md-6" *ngFor="let m of corporate.management">
                            <h3>{{m.title}}</h3>
                            <p>{{m.name}}</p>
                            <p class="contectInfo"><span><img src="assets/images/icon-Contact3.png"></span>
                                <a href="mailto:{{m.email}}">{{m.email}}</a></p>
                        </div>

                        <div class="contactWapper col-xs-12 col-sm-6 col-md-6" *ngIf="corporate.websiteRelated">
                            <h3>For Website related issues:</h3>
                            <p>Please write to: <span><img src="assets/images/icon-Contact3.png"></span>&nbsp;<a href="mailto:{{corporate.websiteRelated.writeTo}}">{{corporate.websiteRelated.writeTo}}</a></p>
                            <p>E-mail us: <span><img src="assets/images/icon-Contact3.png"></span>&nbsp;<a href="mailto:{{corporate.websiteRelated.emailTo}}">{{corporate.websiteRelated.emailTo}}</a></p>
                            <p>Website: <a href="{{corporate.websiteRelated.website}}">{{corporate.websiteRelated.website}}</a></p>
                        </div>
                    </div>-->
                    <style type="text/css">
                        /* .corporatecom {} */
                        
                        .corporatecom .perpuleWap {
                            margin: 10px 0px 20px;
                        }
                        
                        .corporatecom .contactWapper h3 {
                            min-height: auto;
                        }
                        
                        .corporatecom p a {
                            color: #545454;
                        }
                        
                        .EmergTransservices a {
                            color: #545454 !important;
                        }
                    </style>
                </div>
                <div class=" col-xs-12 col-sm-12 col-md-4">
                    <div class="TelephonicAppointmentWapper ">
                        <div class="inner-wap">
                            <h4>Telephonic Appointments</h4>
                            <div class="TollfreeNO">
                                <div class="icons">
                                    <img src="assets/images/icon-Contact4.png">
                                    <br> Toll Free</div>
                                <div class="text"> 1800 2122
                                    <p>(Banjara Hills, Vikrampuri, Madhapur, Kondapur, Vijayawada, Marathahalli)</p>
                                </div>
                            </div>
                        </div>
                        <div class="perpuleWap" *ngIf="vaccines">{{vaccines.title}}</div>
                        <div class="Vaccinesimg">
                            <img src="assets/images/image-contact1.png" class="img-responsive" alt="Vaccines and Immunization Facility at Rainbow Hospitals" /> </div>
                        <div class="doctroCont" *ngIf="vaccines">
                            <div class="box" *ngFor="let vcontact of vaccines.doctor">
                                <p class="name">{{vcontact.name}}</p>
                                <p class="contectInfo">
                                    <span>
										<img src="assets/images/icon-Contact5.png">
									</span>{{vcontact.mobile}}</p>
                            </div>
                            <p class="contectInfo">
                                <span>
									<img src="assets/images/icon-Contact6.png">
								</span>
                                <a href="mailto:{{vaccines.email}}">{{vaccines.email}}</a>
                            </p>
                        </div>
                    </div>
                    <div class="EmergTransservices" *ngIf="emergencyContact">
                        <div class="name">{{emergencyContact.title}}</div>
                        <ul class="Addresswapper">
                            <li *ngFor="let branch of emergencyContact.centers">
                                <p class="contectInfo">
                                    <strong>{{branch.centre}}</strong> {{branch.city}}</p>
                                <p class="contectInfo" *ngIf="branch.contact">
                                    <span>
										<img src="assets/images/icon-Contact1.png">
									</span>{{branch.contact}}</p>
                                <p class="contectInfo" *ngIf="!branch.contact">Emergency and transport services not available here</p>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>



    <footer-subscription></footer-subscription>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>