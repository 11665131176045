import {
  TWITTER_URL,
  FB_URL,
  YOUTUBE_URL,
  LINKEDIN_URL,
  IMG_BASE_URL,
} from './../services/constants';
//import { styles } from './../../../compiled/src/assets/css/animate.min.css.shim.ngstyle';
import {
  Component,
  Input,
  OnInit,
  AfterViewInit,
  Output,
  EventEmitter,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { ApiService } from '../services/api.service';
import {
  ReactiveFormsModule,
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Meta } from '@angular/platform-browser';
import {
  DomSanitizer,
  SafeHtml,
  SafeUrl,
  SafeStyle,
} from '@angular/platform-browser';

declare let $: any;
declare let initPopup: any;

@Component({
  selector: 'header-wrap',
  templateUrl: './header-wrap.component.html',
  styles: [
    `
      a {
        cursor: pointer;
      }
      input[disabled] {
        border: 1px solid rgba(61, 41, 121, 0.01) !important;
        background: rgba(61, 41, 121, 0.5) !important;
      }
      .has-error {
        border: 1px solid red !important;
      }
      header.headerwrp.fixed .logos2 {
        padding: 0;
      }
      header.headerwrp.fixed .logos2 > img.rainbow-logo {
        height: 80px;
      }
    `,
  ],
})
export class HeaderWrapComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() hidden: string;
  @Input() logo: string;

  @Output() section: EventEmitter<string> = new EventEmitter<string>();

  logoImg: any;
  showModel: boolean = false;
  gorbox: boolean = false;
  getaCallScreen: boolean = true;
  OTPScreen: boolean = false;
  ThankyouScreen: boolean = false;
  click2callMobileForm: any;
  click2callOTPForm: any;
  mobileNumber: number;
  otpErrorMsg: any;
  fb_url: string = FB_URL;
  youtube_url: string = YOUTUBE_URL;
  twitter_url: string = TWITTER_URL;
  linkedin_url: string = LINKEDIN_URL;
  virtualTourLink: any;
  metaDis: any;

  public modelPupup: any;
  public modelPupupCon: boolean = false;
  public modelPupupCon2: boolean = false;
  public BASEURL: any;
  public dBgImage: any;
  public mBgImage: any;

  constructor(
    public api: ApiService,
    public formBuilder: FormBuilder,
    private router: Router,
    private meta: Meta,
    private sanitization: DomSanitizer
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
    }
    require('../../assets/js/jquery.cookie.js');
  }
  ngOnInit() {
    /*
		let currentUrl = this.router.url;
		if(currentUrl == '/' || currentUrl == ''){
			this.logoImg = 'logo20y2';
		}else{
			this.logoImg = 'logo';
		}
		*/
    /*
		let currentUrl = this.router.url; 
		if(currentUrl != '/all/book-an-appointment'){
			localStorage.removeItem('ImportantMeg');
			if(localStorage.getItem('ImportantMeg') != 'true'){
				this.autoLoadImportantMeg();			
			}
		}
		*/
    this.validateClick2callMobileForm();
    this.validateClick2callOTPForm();
    this.api
      .getHeaderVirtualTour()
      .map((res) => res)
      .subscribe((data) => {
        this.virtualTourLink = data;
      });

    /*
		let expiryTime = Math.round(1000 * 60 * 30);		
		setTimeout(() => {
			localStorage.removeItem('ImportantMeg');
		}, expiryTime);
		*/
  }
  autoLoadImportantMeg() {
    initPopup('ImportantMessage');
  }
  autoLoadCodidMeg() {
    initPopup('Covid19');
  }
  autoLoadCodidPandemic() {
    initPopup('Covid19Pandemic');
  }
  getModelPopupAllPage() {
    this.api.getAllPageModelPopup().subscribe(
      (data) => {
        let settime;
        if (data.length > 0) {
          this.BASEURL = IMG_BASE_URL;
          this.modelPupup = data;

          if (this.modelPupup[0].settime !== '') {
            settime = parseInt(this.modelPupup[0].settime);
          }
          if (this.modelPupup[0].css != null) {
            $('style').append(this.modelPupup[0].css);
          }

          if (settime !== null) {
            if (!$.cookie('DynamicModelPopup')) {
              if (this.modelPupup[0].hcode == null) {
                this.modelPupupCon2 = true;
                this.modelPupupCon = false;
                this.dBgImage = this.sanitization.bypassSecurityTrustStyle(
                  `background:transparent url(` +
                    '/assets/images/covid19_pandemic_popup_d_.jpg' +
                    `)  no-repeat top center; background-size: contain;`
                );
                this.mBgImage = this.sanitization.bypassSecurityTrustStyle(
                  `background:transparent url(` +
                    this.BASEURL +
                    this.modelPupup[0].filename +
                    `)  no-repeat top center; background-size: contain;`
                );
              } else {
                this.modelPupupCon2 = false;
                this.modelPupupCon = true;

                if (this.modelPupup[0].image != null) {
                  this.dBgImage =
                    'background:transparent url(' +
                    this.BASEURL +
                    this.modelPupup[0].image +
                    ')  no-repeat top center; background-size: cover;';
                  $('style').append(
                    `.CovidModelPopup .bodyCont.impMes{` + this.dBgImage + `}`
                  );
                }
              }
              setTimeout(() => {
                $('.modelPupup .capopup').show();
              }, 1000);
              setTimeout(() => {
                $('.capopup-close').click(function (e) {
                  e.preventDefault();
                  $('.capopup').hide();

                  var date = new Date(),
                    minutes = settime;
                  date.setTime(date.getTime() + minutes * 60 * 1000);
                  $.cookie('DynamicModelPopup', true, { expires: date });
                });
              }, 1200);
            }
          } else {
            if (this.modelPupup[0].hcode == null) {
              this.modelPupupCon2 = true;
              this.modelPupupCon = false;
              this.dBgImage = this.sanitization.bypassSecurityTrustStyle(
                `background:transparent url(` +
                  this.BASEURL +
                  this.modelPupup[0].image +
                  `)  no-repeat top center; background-size: contain;`
              );
              this.mBgImage = this.sanitization.bypassSecurityTrustStyle(
                `background:transparent url(` +
                  this.BASEURL +
                  this.modelPupup[0].filename +
                  `)  no-repeat top center; background-size: contain;`
              );
            } else {
              this.modelPupupCon2 = false;
              this.modelPupupCon = true;

              if (this.modelPupup[0].image != null) {
                this.dBgImage =
                  'background:transparent url(' +
                  this.BASEURL +
                  this.modelPupup[0].image +
                  ')  no-repeat top center; background-size: cover;';
                $('style').append(
                  `.CovidModelPopup .bodyCont.impMes{` + this.dBgImage + `}`
                );
              }
            }
            setTimeout(() => {
              $('.modelPupup .capopup').show();
              $.removeCookie('DynamicModelPopup');
            }, 1000);
            setTimeout(() => {
              $('.capopup-close').click(function (e) {
                e.preventDefault();
                $('.capopup').hide();
              });
            }, 1200);
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  CovidPandemicModelPopupLink(link, target, settime) {
    console.log('link, target, settime: ', link, target, settime);
    $('.capopup').hide();
    if (settime !== null) {
      var date = new Date(),
        minutes = settime;
      date.setTime(date.getTime() + minutes * 60 * 1000);
      $.cookie('DynamicModelPopup', true, { expires: date });
    }
    window.open(link, target);
  }
  /*
	CovidPandemicModelPopupLink(){
		$('.capopup').hide();
		var date = new Date(), minutes = 10;
		date.setTime(date.getTime() + (minutes * 60 * 1000)); 
		$.cookie('Codid19popup', true, { expires: date });
		window.open("https://www.rainbowhospitals.in/about-us/events/knowledge-sharing-webinars-for-july-2020", "_self");
	}
	*/
  autoLoadIMesremoveItem() {
    localStorage.removeItem('ImportantMeg');
  }
  closeImortMsg() {
    //localStorage.setItem('ImportantMeg', 'true');
  }
  searchBar($evt) {
    //console.log($evt);
    $('#topsearchwrp').toggleClass('visible');
  }
  halfCircle(value) {
    this.section.emit(value);
  }
  modelFunc() {
    this.showModel = !this.showModel;
    this.click2callMobileForm.reset();
    this.click2callOTPForm.reset();
    this.getaCallScreen = true;
    this.OTPScreen = false;
    this.ThankyouScreen = false;
  }
  showModelStyle(status) {
    let myStyles = status ? 'block' : 'none';
    return { display: myStyles };
  }

  validateClick2callMobileForm() {
    this.click2callMobileForm = this.formBuilder.group({
      mobileNumber: [
        '',
        Validators.compose([Validators.required, Validators.minLength(10)]),
      ],
    });
  }
  validateClick2callOTPForm() {
    this.click2callOTPForm = this.formBuilder.group({
      otp: [
        '',
        Validators.compose([Validators.required, Validators.minLength(4)]),
      ],
    });
  }
  virtualtourDD() {}

  @HostListener('window:load', []) onWindowLoad() {
    this.getModelPopupAllPage();
    /*
		if (!$.cookie('Codid19popup')) {
			//this.autoLoadCodidPandemic();
			this.autoLoadCodidMeg();
			$(".capopup-close").click(function() {
				$('.capopup').hide();

				var date = new Date(), minutes = 10;
				date.setTime(date.getTime() + (minutes * 60 * 1000)); 
				$.cookie('Codid19popup', true, { expires: date });
			});
		}
		*/
    let $windowWidth = $(window).width();
    if ($windowWidth > 682) {
      $('.dropdowntn').each((index, element) => {
        $(this).hover(
          () => {
            $(this).find('.dropdown-tggle').stop(true, true).slideDown();
          },
          () => {
            $(this).find('.dropdown-tggle').stop(true, true).slideUp();
          }
        );
      });
    } else {
      $('.dropdowntn').each((index, element) => {
        $(this).click(() => {
          $('.dropdown-tggle').removeClass('active');
          $(this).find('.dropdown-tggle').addClass('active');
        });
      });
    }
    $('.headerwrp')
      .find('.submenu360 > ul > li')
      .each((index, element) => {
        if ($(this).find('.ddown360').length == 1) {
          if ($(this).find('.dd360 span').length == 0) {
            $(this).find('a.dd360').append('<span></span>');
          }
          $(this).find('.ddown360 li').attr('style', 'margin: 0px !important');
          $(this).attr('style', 'margin: 0px !important');
        }
        $(this).on('click', () => {
          if ($(this).find('.ddown360').hasClass('active')) {
            $('a.dd360').removeClass('active');
            $(this).find('.ddown360').removeClass('active').slideUp('slow');
          } else {
            $('a.dd360').removeClass('active');
            $(this).find('.dd360').addClass('active');
            $('ul.ddown360').removeClass('active').slideUp('slow');
            $(this).find('.ddown360').addClass('active').slideDown('slow');
          }
        });
      });
    if ($(window).width() > 682) {
      $('.tonnavwrp > li.virtualtourDD').each((index, element) => {
        $(this).hover(
          () => {
            $(this).find('.dropdown-tggle').stop(true, true).slideDown();
          },
          () => {
            $(this).find('.dropdown-tggle').stop(true, true).slideUp();
          }
        );
      });
    } else {
      $('.tonnavwrp > li.virtualtourDD > a').each((index, element) => {
        $(this).click(() => {
          if ($(this).hasClass('open')) {
            $(this).removeClass('open');
            $(this).parent().find('.dropdown-tggle').removeClass('active');
          } else {
            $('.virtualtourDD > a').removeClass('open');
            $(this).addClass('open');
            $('.dropdown-tggle').removeClass('active');
            $(this).parent().find('.dropdown-tggle').addClass('active');
          }
        });
      });
    }
    if ($(window).width() > 682) {
      $('.tonnavwrp > li.langDD').each((index, element) => {
        $(this).hover(
          () => {
            $(this).find('.dropdown-tggle').stop(true, true).slideDown();
          },
          () => {
            $(this).find('.dropdown-tggle').stop(true, true).slideUp();
          }
        );
      });
    } else {
      $('.tonnavwrp > li.langDD > a').each((index, element) => {
        $(this).click(() => {
          if ($(this).hasClass('open')) {
            $(this).removeClass('open');
            $(this).parent().find('.dropdown-tggle').removeClass('active');
          } else {
            $('.langDD > a').removeClass('open');
            $(this).addClass('open');
            $('.dropdown-tggle').removeClass('active');
            $(this).parent().find('.dropdown-tggle').addClass('active');
          }
        });
      });
    }
  }

  submitClick2callMobileForm() {
    if (this.click2callMobileForm.value) {
      let mobile = this.click2callMobileForm.value['mobileNumber'];
      this.mobileNumber = mobile;
      this.api.getOTP(mobile).subscribe(
        (res) => {
          //console.log(res);
          if (res.status_code == 200) {
            //this.getaCallScreen = false;
            this.getaCallScreen = false;
            this.OTPScreen = true;
          } else {
            //this.getaCallScreen = true;
            //this.OTPScreen = true;

            this.getaCallScreen = true;
            this.OTPScreen = false;
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
    //console.log(this.click2callMobileForm.value);
  }
  submitClick2callOTPForm() {
    this.otpErrorMsg = '';
    if (this.click2callOTPForm.value) {
      let otpCode = this.click2callOTPForm.value['otp'];
      let validateOTP = {
        number: this.mobileNumber,
        otp: otpCode,
      };
      this.api.click2callAPI(validateOTP).subscribe(
        (res) => {
          //console.log(res);
          if (res.status_code == 200) {
            this.getaCallScreen = false;
            this.OTPScreen = false;
            this.ThankyouScreen = true;
          } else {
            this.otpErrorMsg = 'Please enter valid One Time Password';
            this.getaCallScreen = true;
            //this.OTPScreen = true;
          }
        },
        (err) => {
          if (err.status == 406) {
            this.otpErrorMsg = 'Please enter valid One Time Password';
            this.getaCallScreen = false;
          }
          //console.log(err);
        }
      );
    }
  }
  ngAfterViewInit() {
    /*
		$('.vBtn2').click(function(e){ 
			var x = e.pageX + 20; var y = e.pageY + 22;
			$('.btn_tooltip').css('display', 'inline');
			//$('.btn_tooltip').offset({ left: '10px', top: '10px' });
		});
		$('.vBtn2, .btn_tooltip').mouseover(function(e){ 
			$('.btn_tooltip').css('display', 'inline');
		});
		$('.btn_tooltip').mouseleave(function(){
			$('.btn_tooltip').css('display', 'none');
		});
		$('.vBtn2').mouseout(function(){
			$('.btn_tooltip').css('display', 'none');
		});
		*/
  }
  ngOnDestroy() {}
  isNumber(evt) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
