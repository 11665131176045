import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  ViewChildren,
  QueryList,
  HostListener,
} from '@angular/core';
import { isPlatformBrowser, Location } from '@angular/common';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL, API_BASE_URL } from '../services/constants';
import { Meta, Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {
  ReactiveFormsModule,
  FormsModule,
  FormControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
// import { QueryEncoder, URLSearchParams } from "@angular/http";
import { filter } from 'rxjs/operator/filter';
import { TransferHttp } from '../../modules/transfer-http/transfer-http';
declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
declare let initGoogleMap: any;
declare let initCentersSpecialityTabs: any;
declare let changeShowStatus: any;
declare let dataLayer: any;
declare let dataLayerPush: any;
declare let MD5: any;
//declare let ourCenter:any;
@Component({
  selector: 'our-center-new',
  templateUrl: './our-center-new.component.html',
  styles: [
    `
      .visitext {
        white-space: pre-wrap;
      }
      .gmapwrp .sidelocdetwrp .gmheader .box {
        width: 100%;
      }
      .specdoctorslider {
        list-style: none;
        position: relative;
        color: #4d4d4d;
        font-family: 'dinrg';
        font-size: 14px;
        float: left;
        text-align: center;
        padding: 0px;
      }
      .showdiv {
        opacity: 1;
      }
      .hidediv {
        opacity: 0;
      }

      #ModelFormGetInTouch {
        display: none;
      }
      .capopup {
        display: none;
      }
      #ModelFormGetInTouch .capopup-inner {
        max-width: 600px;
        width: 90%;
        padding: 40px;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
        border-radius: 20px;
        background: #faf5ff;
      }
      #ModelFormGetInTouch .capopup-inner .launchpreloader {
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: 11;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        background: rgba(255, 255, 255, 0.6) url(../../assets/css/ajax-loader.gif)
          no-repeat center center;
      }
      #ModelFormGetInTouch h2 {
        width: 100%;
        text-transform: uppercase;
        color: #5f2160;
        font-weight: 700;
        font-family: 'dinrg';
        font-size: 30px;
        display: inline-block;
        margin: 0 0px 10px 0;
        position: relative;
        padding: 0px;
      }
      #ModelFormGetInTouch .formcasn {
        width: 100%;
        margin: 20px 0 0 0;
        padding: 0;
        position: relative;
        float: left;
      }
      #ModelFormGetInTouch .formcasn ul {
        margin: 0;
        padding: 0px;
      }
      #ModelFormGetInTouch .formcasn li {
        float: left;
        width: 100%;
        list-style: none;
        position: relative;
        margin-bottom: 10px;
      }
      #ModelFormGetInTouch .formcasn li:last-child {
        margin-bottom: 0px;
      }
      #ModelFormGetInTouch .formcasn li i {
        width: 25px;
        height: 25px;
        position: absolute;
        left: 32px;
        top: 39px;
      }
      #ModelFormGetInTouch .formcasn li .alertMsg {
        position: absolute;
        right: 12px;
        bottom: 14px;
        z-index: 1;
        color: #e51e2c;
        font-family: 'dinrg';
        font-size: 13px;
        font-weight: normal;
      }
      #ModelFormGetInTouch .formcasn li input {
        color: #545454;
        font-family: 'dinrg';
        font-size: 17px;
        width: 100%;
        float: left;
        background: #fff;
        border: 1px solid #ccc;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        padding: 10px 20px 10px 20px;
        box-sizing: border-box;
      }
      #ModelFormGetInTouch .formcasn li select {
        color: #545454;
        font-family: 'dinrg';
        font-size: 17px;
        width: 100%;
        float: left;
        background: #fff;
        border: 1px solid #ccc;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        padding: 10px 20px 10px 20px;
        box-sizing: border-box;
      }
      #ModelFormGetInTouch .formcasn li textarea {
        color: #545454;
        font-family: 'dinrg';
        font-size: 17px;
        width: 100%;
        float: left;
        background: #fff;
        border: 1px solid #ccc;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        padding: 10px 20px 10px 20px;
        box-sizing: border-box;
      }
      #ModelFormGetInTouch .formcasn li input:focus,
      #ModelFormGetInTouch .formcasn li textarea:focus,
      #ModelFormGetInTouch .formcasn li select:focus {
        outline: none;
      }
      #ModelFormGetInTouch .btnPrevca {
        color: #5f2160;
        font-family: 'dinrg';
        font-size: 14px;
        width: 100%;
        margin: 20px 10px 20px 0px;
        position: relative;
        display: inline-block;
        text-align: right;
      }
      #ModelFormGetInTouch .formcasn li .submitbtn {
        width: 100%;
        text-align: center;
        display: inline-block;
        float: left;
      }
      #ModelFormGetInTouch .formcasn li .submitbtn input {
        float: right;
        display: inline-block;
        width: auto;
        color: #fff;
        background: #3d2979;
        border: 1px solid #3d2979;
        padding: 0px 40px;
        margin: 2px 4px;
        height: 38px;
        text-transform: uppercase;
        font-family: 'dinrg';
        font-size: 18px;
        border-radius: 30px;
      }
      #ModelFormGetInTouch .formcasn li input:focus {
        outline: none;
      }
      span.error {
        position: absolute;
        right: 12px;
        bottom: 14px;
        z-index: 1;
        color: #e51e2c;
        font-family: 'dinrg';
        font-size: 13px;
        font-weight: normal;
      }
      .iconForm {
        bottom: 96px;
        position: fixed;
        right: 10px;
        z-index: 12;
        display: none !important;
      }
      .iconFormV {
        width: 30px;
        word-break: break-all;
        top: 100px;
        position: fixed;
        right: 0px;
        z-index: 12;
        display: none;
        background: #80368d;
        text-align: center;
        padding: 10px 10px;
        color: #fff;
        text-transform: uppercase;
        font-family: 'dinrg';
        font-size: 14px;
        line-height: 14px;
        text-transform: uppercase;
      }
      .iconFormV span {
        display: inline-block;
        margin-top: 6px;
      }
      @media (max-width: 767px) {
        .iconForm {
          bottom: 62px;
          position: fixed;
          right: 10px;
          z-index: 12;
        }
        .iconForm img {
          width: 50px;
        }
      }
      @media (max-width: 480px) {
        .iconForm {
          bottom: 112px;
          position: fixed;
          right: 6px;
          z-index: 12;
        }
        #ModelFormGetInTouch h2 {
          font-size: 18px;
        }
        #ModelFormGetInTouch .formcasn {
          margin: 10px 0 0 0;
        }
        #ModelFormGetInTouch .formcasn li input,
        #ModelFormGetInTouch .formcasn li select,
        #ModelFormGetInTouch .formcasn li textarea {
          font-size: 13px;
          padding: 6px 10px;
        }
        #ModelFormGetInTouch .formcasn li .submitbtn input {
          height: 30px;
          font-size: 18px;
        }
        #ModelFormGetInTouch .formcasn li .alertMsg {
          right: 5px;
          bottom: 9px;
          font-size: 11px;
        }
      }
      @media (max-width: 420px) {
        .iconForm {
          bottom: 90px;
          position: fixed;
          right: 6px;
          z-index: 12;
        }
        .iconForm img {
          width: 40px;
        }
      }
      @media (max-width: 420px) {
        .iconForm {
          bottom: 132px;
          position: fixed;
          right: 6px;
          z-index: 12;
        }
        .iconFormV {
          top: 80px;
          line-height: 12px;
        }
      }
    `,
  ],
})
export class OurCenterNewComponent implements OnDestroy, OnInit, AfterViewInit {
  constructor(
    private api: ApiService,
    private http: TransferHttp,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    public metaTitle: Title,
    public meta: Meta,
    private location: Location,
    private formBuilder: FormBuilder,
    private _router: Router
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/fancybox/jquery.fancybox.js');
      require('../../assets/js/slick.min.js');
      require('../../assets/js/jquery.cookie.js');
      this.userAgent = window.navigator.userAgent;
    }
  }

  public zone: any;
  public submited: boolean = false;
  public showHide: boolean = false;
  public preloader: boolean = false;
  GetInTouch: FormGroup;
  GetInTouchLms: FormGroup;
  public pageName: string;
  public countryModel: string = '';
  public locationModel: string = '';
  public locationList: any = [
    { name: 'Hyderabad', code: 'Hyderabad' },
    { name: 'Bengaluru', code: 'Bengaluru' },
  ];
  public interestedList: any = [
    { name: 'Fertility treatment', code: 'Fertility treatment' },
    {
      name: 'Intrauterine insemination (IUI)',
      code: 'Intrauterine insemination (IUI)',
    },
    {
      name: 'In vitro fertilization (IVF)',
      code: 'In vitro fertilization (IVF)',
    },
    { name: 'Infertility screening', code: 'Infertility screening' },
    { name: 'Male infertility', code: 'Male infertility' },
    { name: 'Preconception counselling', code: 'Preconception counselling' },
  ];

  public centrelocation: any = '';
  public specialityModel: any = '';
  public CentreDetails: any;
  public GetInTouchOnPage: FormGroup;
  // public specialityList: any = [
  // 	{ name: 'Paediatric', code: 'Paediatric'},
  // 	{ name: 'Obstetrics and Gynaecology', code: 'Obstetrics and Gynaecology'},
  // 	{ name: 'Fertility', code: 'Fertility'},
  // 	{ name: 'Others', code: 'Others'}
  // ];

  public specialityList: any = [
    { name: 'Child Care', value: 'child-care' },
    { name: 'Women Health', value: 'women-care' },
    { name: 'Fertility Care', value: 'fertility-care' },
  ];

  public reload(): any {
    return this.zone.runOutsideAngular(() => {
      location.reload();
    });
  }

  public programName: string = '';
  public cityName: string = '';
  public CenterName: string;
  public CenterID = '';
  public currentSection: string;
  public specilityDoctor: any = [];
  public centerList: any[] = [];
  public visitor_information: string;
  public center_brief_facilities: any[] = [];
  public PackageListing: any = [];
  public address: string;
  public WebinarAllData: any = [];
  public telephone: string;
  public lat_lng: string;
  public our_speciality_features: any = [];
  public services: any = [];
  public features: any = [];
  public docspecsfeatures: any = [];
  public brief_facilities: any[] = [];
  public title: any = '';
  public specialAnnouncements: any[] = [];
  public firstTimePaging: boolean = false;
  public subtitle: any = '';
  public description: any = '';
  public imgsrc: any = '';
  public imgBasePath = '';
  public centerImgsrc: any = '';
  public hidden = 'hidden';
  public logo = '';
  public links: any = [];
  public bread_crumb_links: any = [];
  public bread_crumb_child_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Child Care', link: '/child-care/about', class: '' },
    { label: 'Our Centres', link: '/child-care/centres', class: '' },
    { label: 'About Centre', link: '', class: '' },
  ];

  public bread_crumb_women_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Women Care', link: '/women-care/about', class: '' },
    { label: 'Our Centres', link: '/women-care/centres', class: '' },
    { label: 'About Centre', link: '', class: '' },
  ];

  public bread_crumb_fertility_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Fertility Care', link: '/fertility-care/about', class: '' },
    { label: 'Our Centres', link: '/fertility-care/centres', class: '' },
    { label: 'About Centre', link: '', class: '' },
  ];

  public bread_crumb_center_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Our Centres', link: '/our-centre', class: '' },
    { label: this.cityName, link: '', class: '' },
  ];

  public child_care_links: any = [
    { label: 'About Child Care', link: '/child-care/about', class: '' },
    { label: 'Child Safety', link: '/child-care/child-safety', class: '' },
    { label: 'Doctors', link: '/child-care/doctors', class: '' },
    { label: 'Our Specialities', link: '/child-care/specialities', class: '' },
    { label: 'Our Centres', link: '/child-care/centres', class: 'active' },
    { label: 'Vaccination', link: '/child-care/vaccination', class: '' },
    { label: 'Testimonials', link: '/child-care/testimonials', class: '' },
    { label: 'Share Feedback', link: '/child-care/feedback', class: '' },
    {
      label: 'Book An Appointment',
      link: '/child-care/book-an-appointment',
      class: '',
    },
  ];

  public women_care_links: any = [
    { label: "About Women's Care", link: '/women-care/about', class: '' },
    { label: 'Doctors', link: '/women-care/doctors', class: '' },
    { label: 'Our Specialities', link: '/women-care/specialities', class: '' },
    { label: 'Our Centres', link: '/women-care/centres', class: 'active' },
    { label: 'Testimonials', link: '/women-care/testimonials', class: '' },
    { label: 'Share Feedback', link: '/women-care/feedback', class: '' },
    {
      label: 'Book An Appointment',
      link: '/women-care/book-an-appointment',
      class: '',
    },
  ];

  public fertility_care_links: any = [
    { label: 'About Fertility Care', link: '/fertility-care/about', class: '' },
    { label: 'Doctors', link: '/fertility-care/doctors', class: '' },
    {
      label: 'Our Specialities',
      link: '/fertility-care/specialities',
      class: '',
    },
    { label: 'Our Centres', link: '/fertility-care/centres', class: 'active' },
    { label: 'Testimonials', link: '/fertility-care/testimonials', class: '' },
    { label: 'Share Feedback', link: '/fertility-care/feedback', class: '' },
    {
      label: 'Book An Appointment',
      link: '/fertility-care/book-an-appointment',
      class: '',
    },
  ];

  public virtual_tour_link: any;
  public activeRoute: any = [];
  public speciality_master: number;
  public centre_id: any;
  public activePage: any;
  public paramsId: any;
  public params: any;
  public AllCenter: any;
  public MapLink: any;
  public utmSource: any;
  //public our_speciality_features_id:any = 0;
  //@ViewChildren('abouttabs') things: QueryList<any>;

  capitalize(s) {
    return s.toLowerCase().replace(/\b./g, function (a) {
      return a.toUpperCase();
    });
  }
  activeBreadCrum(currentSection) {
    switch (currentSection) {
      case 'child-care':
        this.bread_crumb_links = this.bread_crumb_child_links;
        this.links = this.child_care_links;
        this.logo = 'rainbow-logo';
        break;
      case 'women-care':
        this.bread_crumb_links = this.bread_crumb_women_links;
        this.links = this.women_care_links;
        this.logo = 'birth-right-logo';
        break;
      case 'fertility-care':
        this.bread_crumb_links = this.bread_crumb_fertility_links;
        this.links = this.fertility_care_links;
        this.logo = 'birth-right-logo';
        break;
      default:
        this.bread_crumb_links = [
          { label: 'Home', link: '/', class: '' },
          { label: 'Our Centres', link: '/our-centre', class: '' },
          {
            label: this.capitalize(this.cityName.replace('-', ' ')),
            link: '',
            class: '',
          },
        ];
        this.logo = 'logo';
    }
  }
  public aboutusliderconConfig: any = {
    slide: 'li',
    slidesToShow: 4,
    slidesToScroll: 4,
    fade: true,
    asNavFor: '.aboutuslider',
    infinite: true,
    adaptiveHeight: true,
    arrows: true,
    prevArrow:
      '<a class="slick-prev"><img src="assets/images/prv-arrow2.png" alt="" /></a>',
    nextArrow:
      '<a class="slick-next"><img src="assets/images/nxt-arrow2.png" alt="" /></a>',
  };
  public showDoctor: boolean;
  public SubSpecialtyList: any;
  public SubSpecialtyListByApi: any;
  public subSpecialtyModel: any = 'null';
  public subSpecialtyListModel: any = '';
  public utm: any;
  public ipAddress: any;
  public referral: any;
  public userAgent: any;
  public pageN: any;

  @ViewChildren('aboutSlider') things: QueryList<any>;
  @ViewChildren('abouticonslider') iconSliderthing: QueryList<any>;
  ngOnInit() {
    console.log('paremaaaa', this.activatedRoute.params['value']);

    this.GetInTouchOnPage = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern('.*\\S.*')]],
      mobile: [
        '',
        Validators.compose([Validators.required, Validators.minLength(10)]),
      ],
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            '[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}'
          ),
        ]),
      ],
      speciality: ['', Validators.required],
      intrestedin: [''],
      subSpecialty: ['', Validators.required],
      location: [''],
      message: [''],
      page: [''],
      ipAddress: [''],
      useragent: [''],
      authorization: [false, [Validators.required]],
    });
    this.GetInTouchOnPage.controls.location.setValue(
      this.capitalize(this.cityName.replace('-', ' '))
    );

    if (this.activatedRoute && this.activatedRoute.params) {
      //console.log('ROUTE', this.route);
      this.params = this.activatedRoute.params['value'];
      this.cityName = this.params.id;
      console.log('cityName', this.params.id);
    }

    this.countryModel = '';
    this.locationModel = '';
    this.GetInTouch = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern('.*\\S.*')]],
      mobile: ['', Validators.required],
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            '[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}'
          ),
        ]),
      ],
      txtLocation: ['', [Validators.required]],
      interested_in: ['', [Validators.required]],
      message: [''],
      CityName: [''],
    });

    this.GetInTouchLms = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern('.*\\S.*')]],
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            '[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}'
          ),
        ]),
      ],
      mobile: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(/^(\+\d{1,3}[- ]?)?\d{10}$/),
        ]),
      ],
      message: [''],
      programName: [''],
    });

    this.route.params.subscribe((params) => {
      console.log('params', params);
      switch (params.id) {
        case 'madhapur':
          this.pageName = params.id;
          setTimeout(() => {
            if ($.cookie('showThankYouPage') != 1) {
              this.showHide = true;
              $('#ModelFormGetInTouch').show();
            }
          }, 5000);
          break;
        case 'kondapur-op-block':
          this.pageName = params.id;
          setTimeout(() => {
            if ($.cookie('showThankYouPage') != 1) {
              this.showHide = true;
              $('#ModelFormGetInTouch').show();
            }
          }, 5000);
          break;
        case 'malviya-nagar':
          this.pageName = params.id;
          this.programName = 'Our Centres Malviya Nagar';
          this.GetInTouchLms.controls.programName.setValue(this.programName);
          setTimeout(() => {
            $('#LMSDataCaptureFormGetInTouch').show();
          }, 5000);
          break;
      }

      this.paramsId = params['id'];
      this.currentSection = params['section'];

      console.log('paramsId', this.paramsId);

      this.api.getCentreSpecification().subscribe((res) => {
        document.querySelector('#Loader').classList.add('active');
        this.AllCenter = res.centers;
        res.centers.forEach((element) => {
          if (element.slug == this.paramsId) {
            this.CenterID = element.id;
            this.center = element.id;
            //console.log('CenterID', this.CenterID);
            this.centerDetails(this.CenterID);
            this.GetContantHubList();
            this.getCentersDetails();
          }
        });
      });

      //console.log('route currentSection', this.currentSection);
      if (this.currentSection) {
        this.api
          .getSpecialityMasters()
          .map((res) => res.json())
          .subscribe((data) => {
            //console.log(data);
            this.activeRoute = data.speciality_masters.filter((filterdata) => {
              if (this.currentSection == 'women-care') {
                return filterdata.slug == 'women-care';
              } else {
                return filterdata.slug == this.currentSection;
              }
            });
            this.activePage = this.activeRoute[0].speciality;
            this.speciality_master = this.activeRoute[0].id;
          });
      }

      //this.api.getCenterDetailsBySlug(this.paramsId).subscribe((res)=>{
    });
    this.activeBreadCrum(this.currentSection);
    this.api.getHealthCareServices('center-detail').subscribe(
      (data) => {
        this.services = data.healthcare_services[0];
      },
      (error) => {
        console.log(error);
      }
    );

    this.api.getBriefFacilities(`about-${this.paramsId}-center`).subscribe(
      (data) => {
        if (data) {
          this.brief_facilities = data.brief_facilities;
        }
      },
      (error) => {
        console.log(error);
      }
    );

    this.api.getDoctorSpecialityFeaturesByName('Neonatal').subscribe(
      (data) => {
        this.docspecsfeatures = data.our_speciality_features;
      },
      (error) => {
        console.log(error);
      }
    );

    this.api.getspeciality_features().subscribe(
      (data) => {
        this.features = data.our_speciality_features;
      },
      (error) => {
        console.log(error);
      }
    );
    this._getcenter();

    this.SpecialityMasters();

    this.getCentreList();
  }

  getCentreList() {
    this.api.getCentreCities().subscribe((res) => {
      this.centerList = res;
      let cityDetails = [];
      this.centerList.forEach((element) => {
        element['area'].forEach((element2) => {
          if (this.cityName == element2.slug) {
            cityDetails.push({
              location: element.city,
              hospital: element2.center_name,
              city: element2.center_name_alias,
            });
          }
        });
      });
      this.CentreDetails = cityDetails;
      console.log('CentreDetails: ', this.CentreDetails);
    });
  }

  public subSpecialtyListArray;
  selectSubSpecility(event) {
    //	let selectedVal = event.target.value
    console.log(
      'selectedVal',
      event.target.value,
      this.route.params['value'].id,
      this.AllCenter
    );
    let centersListA = [];
    this.AllCenter.forEach((element) => {
      if (element.slug == this.route.params['value'].id) {
        console.log('dsffff', element);
        centersListA = element.speciality_features;
      }
    });
    let finalList = [];
    centersListA.forEach((element) => {
      if (element.page == event.target.value) {
        finalList.push(element);
      }
    });
    console.log('finalList', finalList);
    this.subSpecialtyListArray = finalList;
    this.subSpecialtyListModel = '';
    $('#subSpecialtySpan').html('Select Sub Specialty');

    this.DataLayerOnSpecialityChange(event.target.value);
  }

  onSubmitDirectPost() {
    this.submited = true;
    if (!this.GetInTouchOnPage.valid) {
      return;
    }
    $('#centreFGetInTouch .formWrp .launchpreloader').show();
    console.log('GetInTouchOnPage', this.GetInTouchOnPage);
    console.log(
      'GetInTouchOnPage Name',
      this.GetInTouchOnPage.controls.name.value
    );

    if (this.GetInTouchOnPage.valid) {
      this.api
        .postGetInTouchFormData(
          this.GetInTouchOnPage.value,
          this.CentreDetails,
          this.utm
        )
        .subscribe((res) => {
          console.log('postGetInTouchFormData:', res);
          this.DataLayerONSubmitForm(
            this.GetInTouchOnPage.controls.name.value,
            this.GetInTouchOnPage.controls.email.value,
            this.GetInTouchOnPage.controls.mobile.value,
            this.cityName,
            this.GetInTouchOnPage.controls.speciality.value,
            this.GetInTouchOnPage.controls.subSpecialty.value,
            this.GetInTouchOnPage.controls.message.value
          );
          $('#centreFGetInTouch .formWrp .launchpreloader').hide();
          $('#centreFGetInTouch .thankYouMsg').show();
          setTimeout(() => {
            $('#centreFGetInTouch .thankYouMsg').hide();
            this.GetInTouchOnPage.reset();
            this.submited = false;
            $('#txtSpeciality').html('Select Speciality');
            $('#subSpecialtySpan').html('Select Sub Specialty');
            setTimeout(() => {
              this.GetInTouchOnPage.controls.location.setValue(
                this.capitalize(this.cityName.replace('-', ' '))
              );
              this.GetInTouchOnPage.controls.page.setValue(this.pageN);
              this.GetInTouchOnPage.controls.ipAddress.setValue(this.ipAddress);
              this.GetInTouchOnPage.controls.useragent.setValue(this.userAgent);
            }, 1000);
          }, 3000);
        });
    }
  }

  getSubSpeciality(cid, sid) {
    this.api.getSubSpeciality(cid, sid).subscribe((data) => {
      let SubSTitle = [];
      data.forEach((element) => {
        element.subspecialitylist.forEach((element1) => {
          SubSTitle.push({
            id: element1.our_speciality_features_id,
            title: element1.title,
          });
        });
      });
      console.log('getSubSpeciality:', data, SubSTitle);
      this.SubSpecialtyList = SubSTitle;
    });
  }
  getSubSpecialtyList(id) {
    this.api.getOurDoctorList(id).subscribe((res) => {
      let sMaster = res.speciality_master;
      let sMasterSubSpecl = [];
      sMaster['centers'].forEach((element) => {
        if (element.speciality_features.length != 0) {
          sMasterSubSpecl.push(element.speciality_features);
        }
      });
      let SubSTitle = [];
      sMasterSubSpecl.forEach((element) => {
        element.forEach((element1) => {
          SubSTitle.push(element1.title);
        });
      });
      this.SubSpecialtyList = SubSTitle;
    });
  }

  centerDetails(center) {
    this.api.getCentersDetails(center).subscribe(
      (res) => {
        console.log('getCentersDetails', res);
        this.centre_id = res.centers[0].id;
        let imgUrl = res.centers[0].circleimage
          ? res.centers[0].circleimage
          : this.imgsrc;
        this.title = res.centers[0].center_name;
        this.specialAnnouncements = res.centers[0].special_announcements;
        this.description = res.centers[0].description;
        this.address = res.centers[0].address;
        this.telephone = res.centers[0].telephone;
        this.lat_lng = res.centers[0].lat_long;
        this.imgsrc = imgUrl;
        this.imgBasePath = IMG_BASE_URL;
        this.center_brief_facilities = res.centers[0].brief_facilities;
        this.visitor_information = res.centers[0].visitor_information;
        this.centerImgsrc = IMG_BASE_URL + res.centers[0].centerimage;
        this.CenterName = res.centers[0].center_name_alias;
        this.MapLink = `http://www.google.com/maps/place/${res.centers[0].lat_long}`;
        //this.CenterID =
        //console.log('Center ',res);

        $.cookie('pTitle', this.title);
        $.cookie('pBannImg', this.imgsrc);

        this.getMetaInfo(res.centers[0]);
        if (this.currentSection) {
          this.filterCenterSpecility(
            this.currentSection,
            res.centers[0].our_speciality_features
          );
        } else {
          this.our_speciality_features = res.centers[0].our_speciality_features;
          //console.log('our_speciality_features',this.our_speciality_features)
        }

        this.virtual_tour_link = res.centers[0].virtual_tour_link;

        //let our_speciality_features_id = (this.our_speciality_features[0] ? this.our_speciality_features[0].id : 0);
        //let speciality_master = (this.our_speciality_features[0] ? this.our_speciality_features[0].speciality_masters_id : 0);

        //this.viewDoctorList(speciality_master,our_speciality_features_id);

        let user_lat_long_info = res.centers[0].lat_long.split(',');
        initGoogleMap(user_lat_long_info[0], user_lat_long_info[1]);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getMetaInfo(metadetail: any) {
    this.metaTitle.setTitle(metadetail.meta_title);
    this.meta.updateTag({ name: 'keywords', content: metadetail.meta_keyword });
    this.meta.updateTag({
      name: 'description',
      content: metadetail.meta_description,
    });
    this.meta.addTags([
      { property: 'og:title', content: metadetail.meta_title },
      { property: 'og:description', content: metadetail.meta_description },
      { property: 'og:keywords', content: metadetail.meta_keyword },
    ]);
  }
  filterCenterSpecility(currentSection: string, data: any) {
    this.our_speciality_features = data.filter((res) => {
      //console.log('filterCenterSpecility', res.page)
      return res.page == currentSection;
    });
  }
  _getcenter() {
    this.api.getCentreCities().subscribe(
      (res) => {
        this.centerList = res;
      },
      (err) => {
        console.error(err);
      }
    );
  }
  changeCenter(value) {
    //console.log('changeCenter', value);
    if (this.activePage == null) {
      document.location.href = '/our-centre/' + value;
    } else {
      document.location.href = '/' + this.currentSection + '/centre/' + value;
    }
  }
  viewDoctorList(speciality_master, speciality_feature): void {
    this.api
      .getDoctorListBySpecilityMaster(
        speciality_master,
        speciality_feature,
        this.centre_id,
        0
      )
      .subscribe((res) => {
        this.specilityDoctor = res;
        this.showDoctor = this.showDoctorFunc(this.specilityDoctor);
      });
  }
  showDoctorFunc(doctorList) {
    return this.specilityDoctor.length > 0 ? true : false;
  }

  public patientResources: any = [];
  public prepareYourselfYesources: any = [];
  public visitorResources: any = [];

  public subspecialityListArray: any = [];

  public AllsubspecialityListArray = false;
  getCentersDetails() {
    //console.log('qqqqqqqq', this.CenterID)
    this.api.getCentersDetails(this.CenterID).subscribe((res) => {
      console.log('CentersDetails', res, res['centers']);
      this.subspecialityListArray = res['centers'][0]['subspeciality'];
      if (this.subspecialityListArray.length > 0) {
        this.AllsubspecialityListArray = true;
      }
      res['centers'].forEach((element) => {
        console.log('kkk ==', element);
        this.patientResources = element.patient_resources;
        this.prepareYourselfYesources = element.prepare_yourself_resources;
        this.visitorResources = element.visitor_resources;
      });
      this.prepareYourselfYesources = this.prepareYourselfYesources.filter(
        (resd) => resd.flag !== 0
      );
      this.patientResources = this.patientResources.filter(
        (resd) => resd.flag !== 0
      );
      this.visitorResources = this.visitorResources.filter(
        (resd) => resd.flag !== 0
      );

      //console.log('prepareYourselfYesources 1',  this.prepareYourselfYesources)
      // this.patientResources = res['centers'][0]['patient_resources']
      // this.prepareYourselfYesources = res['centers'][0]['prepare_yourself_resources']
      // this.visitorResources = res['centers'][0]['visitor_resources']
      setTimeout(() => {
        document.querySelector('#Loader').classList.remove('active');
      }, 1000);
    });
  }
  public WorkShopData;
  public AllPackegs = false;
  public AllPackegeData = [];
  getPackageListing(PackageID, center, specialityid) {
    //document.querySelector('#Loader').classList.add('active');
    $('#CPackagesLoader').addClass('active');
    this.api
      .getPackageListing(PackageID, center, specialityid)
      .subscribe((res) => {
        // res.forEach(element => {
        // 	element.calenderDate = this.DateFormater(element.workshopdate);
        // });
        res = res.filter((res) => res.flag !== 0);

        res.forEach((element) => {
          element.DateStr = new Date(element.created_at);
        });
        res = res.sort((a, b) => b.DateStr - a.DateStr);

        this.Packages = res;
        this.AllPackegeData = res;
        if (res.length > 0) {
          this.AllPackegs = true;
        }

        console.log('Packages', this.Packages);

        this.packagesListSlider();
      });

    this.api
      .getSpecialityMappingByCenter(center, specialityid)
      .subscribe((res) => {
        console.log('SpecialityMappingByCenter', res);
      });
  }

  public AllWorkShopData = false;
  getWorkshopListing(PackageID, center, specialityid) {
    this.api
      .getWorkshopListing(PackageID, center, specialityid)
      .subscribe((res) => {
        res.forEach((element) => {
          element.calenderDate = this.DateFormater(element.workshopdate);
          element.startTime = this.formatAMPM(
            new Date(element.workshopstartime)
          );
          element.endTime = this.formatAMPM(new Date(element.workshopendtime));
        });
        res = res.filter((res) => res.flag !== 0);

        res.forEach((element) => {
          element.DateStr = new Date(element.created_at);
        });
        res = res.sort((a, b) => b.DateStr - a.DateStr);
        console.log('Workshop', res);

        this.WorkShopData = res;
        if (res.length > 0) {
          this.AllWorkShopData = true;
        }
        console.log('this.WorkShopData', this.WorkShopData);
        if ($('.cWorkshopsLists').hasClass('slick-initialized')) {
          $('.cWorkshopsLists').slick('destroy');
        }
        setTimeout(() => {
          $('.cWorkshopsLists').slick({
            slide: 'li',
            dots: false,
            arrows: true,
            draggable: false,
            swipeToSlide: false,
            focusOnSelect: true,
            prevArrow:
              '<a class="slick-prev cWorkshopsListPrev"><img src="assets/images/centreimg/icon_btn_prev.png" alt="" /></a>',
            nextArrow:
              '<a class="slick-next cWorkshopsListNext"><img src="assets/images/centreimg/icon_btn_next.png" alt="" /></a>',
            infinite: false,
            autoplay: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            lazyLoad: 'ondemand',
            cssEase: 'linear',
            adaptiveHeight: true,
            responsive: [
              {
                breakpoint: 1080,
                settings: {
                  infinite: false,
                  slidesToShow: 2,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 580,
                settings: {
                  infinite: false,
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
            ],
          });
          document.querySelector('#Loader').classList.remove('active');
          setTimeout(() => {
            let aThis = this;
            $('.cWorkshopsListNext').click(function (e) {
              aThis.DataLayerOnClickUnitactivities(
                'Services & packages-Workshops',
                'Services&packages_Workshops_Right_Toggle'
              );
            });
            $('.cWorkshopsListPrev').click(function (e) {
              aThis.DataLayerOnClickUnitactivities(
                'Services & packages-Workshops',
                'Services&packages_Workshops_Left_Toggle'
              );
            });
          }, 200);
        }, 1000);
      });
  }

  closePopup() {
    document.querySelector('.dataPopupBox').classList.remove('active');
  }
  public Packages: any = [];

  // getPackages() {
  // 	// this.api.getPackageListing(PackageID, CenterId).subscribe(res => {

  // 	// })
  // 	this.api.getPackages().subscribe(res => {
  // 		console.log('getPackages', res);
  // 		this.Packages = res['packages'];
  // 		this.Packages = this.Packages.filter(res => res.headertag !== null);
  // 	})
  // }

  public specialityVal = '';
  public subSpeciality = '';
  public center = '';

  public Speciality: any = [];
  SpecialityMasters() {
    this.api
      .getSpecialityMasters()
      .map((res) => res.json())
      .subscribe((res) => {
        //console.log('SpecialityMasters----', res.speciality_masters);
        this.Speciality = res.speciality_masters;
      });
  }

  public SubSpeciality: any = [];
  selectSubCenters(event) {
    this.center = event.target.value;
    //console.log(event.target.value);
    this.AllCenter.forEach((element) => {
      //console.log(element.center_uid, parseInt(event.target.value))
      if (element.center_uid == parseInt(event.target.value)) {
        this.SubSpeciality = element.speciality_features;
      }
    });
    //console.log('location', this.center);
  }

  public SearchSpec = '';

  selectAllValue(event) {
    this.subSpeciality = event.target.value;
    //console.log('subSpeciality', this.subSpeciality);
    //console.log('specialityVal', this.specialityVal);
  }

  ResetSelectlocation() {
    //console.log('sssss', this.specialityVal, this.subSpeciality, this.center)
    this.specialityVal = '';
    this.subSpeciality = '';
    this.center = '';
    this.SearchSpec = '';
    this.getDoctorsList();
    this.SpecialityMasters();
    //console.log('sssss', this.specialityVal, this.subSpeciality, this.center)
  }

  public locationListItem: any = [];
  Selectlocation(event) {
    this.specialityVal = event.target.value;
    this.api.getCentreCities().subscribe((res) => {
      //console.log('locationList----', res);
      this.locationListItem = res;
    });
    //console.log('specialityVal', this.specialityVal);
    //this.getSubSpecialtyList(this.specialityVal);
    this.getSubSpeciality('', this.specialityVal);
    this.DataLayerOnClickDoctorEvent(
      'Doctors',
      'Doctors_Select_Specialty',
      '',
      '',
      event.target.value,
      ''
    );
  }

  // public WorkShopData: any = [];
  // getWorkShop() {
  // 	this.api.getWorkShop().subscribe(res => {
  // 		console.log('WorkShopData', res);
  // 		this.WorkShopData = res['workshops']
  // 		this.WorkShopData = this.WorkShopData.filter(res => res.workshopdate !== null);
  // 		this.WorkShopData.forEach(element => {
  // 			element.startTime = element.workshopstartime.split(" ")[1]
  // 			element.endTime = element.workshopendtime.split(" ")[1]
  // 		});
  // 	})
  // }

  // public WorkshopListing: any = [];
  // getWorkshopListing(WorkshopID) {
  // 	this.api.getWorkshopListing(WorkshopID).subscribe(res => {
  // 		res.forEach(element => {
  // 			element.startTime = element.workshopstartime.split(" ")[1]
  // 			element.endTime = element.workshopendtime.split(" ")[1]
  // 		});
  // 		console.log('WorkshopListing', res)
  // 		this.WorkshopListing = res
  // 	})
  // }

  DateFormater(dateStr) {
    dateStr.split(' ');
    const monthNamesShort = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    let newDate = new Date(dateStr);
    let DateNo = newDate.getDate();
    let MonthNo = newDate.getMonth();
    let yearNo = newDate.getFullYear();

    let DateData = {
      DateNo: DateNo,
      MonthName: monthNames[MonthNo],
      MonthShort: monthNamesShort[MonthNo],
      yearNo: yearNo,
    };
    return DateData;
  }

  formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  doctorSlickSlider() {
    if ($('.DoctorDataSleder').hasClass('slick-initialized')) {
      $('.DoctorDataSleder').slick('destroy');
    }

    setTimeout(() => {
      $('.DoctorDataSleder').slick({
        arrows: true,
        prevArrow:
          '<a class="slick-prev doctorSPrev"><img src="assets/images/centreimg/icon_prev_circle2.png" alt="" /></a>',
        nextArrow:
          '<a class="slick-next doctorSNext"><img src="assets/images/centreimg/icon_next_circle2.png" alt="" /></a>',
        dots: false,
        centerMode: false,
        adaptiveHeight: true,
        focusOnSelect: true,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 580,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });

      document.querySelector('#Loader').classList.remove('active');

      const sameHeight = () => {
        $('.doctorDataWrap').height('auto');
        $('.DoctorDataSleder').each(() => {
          var highestBox = 0;
          $('.doctorDataWrap', this).each(() => {
            if ($(this).height() > highestBox) {
              highestBox = $(this).height() + 50;
            }
          });
          $('.doctorDataWrap', this).height(highestBox);
        });
      };

      setTimeout(() => {
        sameHeight();
        $(window).resize(function () {
          sameHeight();
        });
      }, 500);
      setTimeout(() => {
        let oThis = this;
        $('.doctorSPrev').click(function () {
          oThis.DataLayerOnClickEvent('Doctors', 'Doctors_Left_Toggle', '');
        });
        $('.doctorSNext').click(function () {
          oThis.DataLayerOnClickEvent('Doctors', 'Doctors_Right_Toggle', '');
        });
      }, 200);
    }, 1000);
  }

  public DoctorDefaultData: any = [];
  public DefaultDoctorsName: any = [];
  public FilterNameList: any = [];

  getDoctorname(event) {
    let str = event.target.value;
    this.FilterNameList = this.DefaultDoctorsName.filter((res) =>
      res.DrName.toString().toLowerCase().includes(str.toLowerCase())
    );
    //console.log('FilterNameList', this.FilterNameList, event);
  }

  selectDoctor(event) {
    document.querySelector('#Loader').classList.add('active');
    let str = event.target.innerHTML;
    $('#DoctorSerch').val(str);
    this.FilterNameList = [];
    this.doctorsList = [
      this.DoctorDefaultData.filter((res) => res.id == event.target.value),
    ];
    //console.log('doctorsList:', this.doctorsList);
    this.doctorSlickSlider();
    this.DataLayerOnClickDoctorEvent(
      'Doctors',
      'Doctors_' + event.target.innerHTML,
      '',
      event.target.innerHTML,
      '',
      ''
    );
  }

  SelectSubSpecialty(event) {
    let ssid = event.target.value;
    if (ssid != 'null') {
      this.subSpeciality = ssid;
    } else {
      this.subSpeciality = '';
    }
    this.DataLayerOnClickDoctorEvent(
      'Doctors',
      'Doctors_Select_Sub_Specialty',
      '',
      '',
      '',
      event.target.value
    );
  }
  getDoctorsListBySubSpecialty() {
    this.getDoctorsList();
  }

  public doctorsList: any = [];
  getDoctorsList() {
    $('#DoctorSerch').val('');
    document.querySelector('#Loader').classList.add('active');
    this.api
      .getgetDoctors(this.specialityVal, this.subSpeciality, this.center)
      .subscribe((res) => {
        //console.log('DoctorsList ================', res);
        res = res.filter((res) => res.flag !== 0);
        this.DefaultDoctorsName = [];
        this.DoctorDefaultData = res;
        let doctorsList = res;
        res.forEach((element) => {
          let item = { DrName: element.name, DrID: element.id };
          this.DefaultDoctorsName.push(item);
        });

        //console.log('DefaultDoctorsNameList:', this.DefaultDoctorsName);

        let DrList = [];
        let dList = [];
        let item = 0;
        let count = 0;
        doctorsList.forEach((element) => {
          dList.push(element);
          item += 1;
          count += 1;
          if (item == 2) {
            DrList.push(dList);
            item = 0;
            dList = [];
          }
          //console.log(this.doctorsList.length , count)
          if (doctorsList.length == count) {
            if (dList.length != 0) {
              DrList.push(dList);
            }
          }
        });
        this.doctorsList = [] = DrList;
        //console.log('DrList:', DrList);
        this.doctorSlickSlider();
        $('body').click((e) => {
          if (!e.target.closest('.DoctorSerchBox')) {
            this.FilterNameList = [];
          }
        });
      });
  }

  // checkParent(element){
  // 	if(!event.target.closest('.DoctorSerchBox')){
  // 		this.FilterNameList = [];
  // 	}
  // }

  CheckPastFutureDate(dateStr) {
    var date = new Date();
    var mydate = new Date(dateStr);
    var isDate = '';
    if (date < mydate) {
      isDate = 'Future';
    } else {
      isDate = 'Past';
    }
    return isDate;
  }

  public Webinar: any = [];
  public EventAllData: any = [];
  public Event: any = [];

  public MediaAllData: any = [];
  public Media: any = [];

  public ArticlesAllData: any = [];
  public Articles: any = [];

  public isWebinar = false;
  public isEvent = false;
  public isMedia = false;
  public isArticls = false;

  isLoadedAllData() {
    //if(this.isWebinar && this.isEvent && this.isMedia && this.isArticls ){

    //console.log('webinar Data', this.Webinar);
    //console.log('Event Data', this.Event);
    //console.log('Media Data', this.Media);
    //console.log('Articles Data', this.Articles);

    setTimeout(() => {
      //console.log('fancy box loaded')

      $('.AnnouncementSlider').slick({
        arrows: true,
        prevArrow:
          '<a class="slick-prev"><img src="assets/images/centreimg/icon_btn_prev.png" alt="" /></a>',
        nextArrow:
          '<a class="slick-next"><img src="assets/images/centreimg/icon_btn_next.png" alt="" /></a>',
        dots: false,
        centerMode: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
      });
      setTimeout(() => {
        $('.dataSection').removeClass('activeDiv').addClass('hideElement');
        $('.csServicesPackages')
          .removeClass('hideElement')
          .addClass('activeDiv');
        document.querySelector('#Loader').classList.remove('active');
      }, 1000);

      $('.fancyboximg').fancybox({});

      $('.fancybox').fancybox({
        type: 'iframe',
        maxWidth: 700,
        maxHeight: 600,
        fitToView: true,
        width: '70%',
        height: '70%',
        autoSize: true,
        closeClick: true,
        openEffect: 'none',
        closeEffect: 'none',
        helpers: {
          media: true,
        },
        youtube: {
          autoplay: 1, // enable autoplay
        },
      });
    }, 1000);
    //}
  }

  WebinarFilter(event, dateType) {
    $(event.target).addClass('Active').siblings().removeClass('Active');
    if (dateType !== '') {
      this.Webinar = this.WebinarAllData.filter(
        (res) => res.isDate == dateType
      );
    } else {
      this.Webinar = this.WebinarAllData;
    }

    console.log('WebinarFilter', this.Webinar);
    if ($('.WebinarSliderWrap').hasClass('slick-initialized')) {
      $('.WebinarSliderWrap').slick('destroy');
    }
    this.WebinarSlickSlider();
    if (dateType == 'Future') {
      this.DataLayerOnClickEvent(
        'Unit Activities',
        'Unitactivities_Webinar_Upcoming',
        ''
      );
    }
    if (dateType == 'Past') {
      this.DataLayerOnClickEvent(
        'Unit Activities',
        'Unitactivities_Webinar_Past',
        ''
      );
    }
  }

  EventFilter(event, dateType) {
    $(event.target).addClass('Active').siblings().removeClass('Active');
    if (dateType !== '') {
      this.Event = this.EventAllData.filter((res) => res.isDate == dateType);
    } else {
      this.Event = this.EventAllData;
    }

    //console.log('EventFilter', this.Event);

    if ($('.eventSliderWrap').hasClass('slick-initialized')) {
      $('.eventSliderWrap').slick('destroy');
    }

    this.EventSlickSlider();
  }

  selectDataYearFilter(event, dataType) {
    if (event.target.value !== '') {
      if (dataType == 'Event') {
        this.Event = this.EventAllData.filter(
          (res) => res.calenderDate['yearNo'] == event.target.value
        );
        this.EventSlickSlider();
        this.DataLayerOnClickEvent(
          'Unit Activities',
          'Unitactivities_Event_Filter_Select_Year',
          ''
        );
      } else if (dataType == 'Media') {
        this.Media = this.MediaAllData.filter(
          (res) => res.calenderDate['yearNo'] == event.target.value
        );
        this.MediaSlickSlider();
        this.DataLayerOnClickEvent(
          'Unit Activities',
          'Unitactivities_Media_Filter_Select_Year',
          ''
        );
      } else if (dataType == 'Articles') {
        this.Articles = this.ArticlesAllData.filter(
          (res) => res.calenderDate['yearNo'] == event.target.value
        );
        this.ArticlesSlickSlider();
        this.DataLayerOnClickEvent(
          'Unit Activities',
          'Unitactivities_Article_Filter_Select_Year',
          ''
        );
      }
    }
  }

  WebinarSlickSlider() {
    setTimeout(() => {
      $('.WebinarSliderWrap').slick({
        arrows: true,
        prevArrow:
          '<a class="slick-prev WebinarSPrev"><img src="assets/images/centreimg/icon_prev_circle2.png" alt="" /></a>',
        nextArrow:
          '<a class="slick-next WebinarSNext"><img src="assets/images/centreimg/icon_next_circle2.png" alt="" /></a>',
        dots: false,
        centerMode: false,
        adaptiveHeight: true,
        focusOnSelect: true,
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
      setTimeout(() => {
        let oThis = this;
        $('.WebinarSPrev').click(function () {
          oThis.DataLayerOnClickEvent(
            'Unit Activities',
            'Unitactivities_Webinar_Left_Toggle',
            ''
          );
        });
        $('.WebinarSNext').click(function () {
          oThis.DataLayerOnClickEvent(
            'Unit Activities',
            'Unitactivities_Webinar_Right_Toggle',
            ''
          );
        });
      }, 200);
    }, 1000);
  }
  public AllWebinarData = false;
  WebinarSliderData() {
    this.api.getContentHubList(this.CenterID, `1`).subscribe((res) => {
      res = res.filter((res) => res.flag !== 0);

      res.forEach((element) => {
        element.DateStr = new Date(element.entrydate);
      });
      res = res.sort((a, b) => b.DateStr - a.DateStr);

      res.forEach((element) => {
        element.isDate = this.CheckPastFutureDate(element.contentdate);
        element.calenderDate = this.DateFormater(element.contentdate);
        if (element.contentvideourl != null) {
          // if youtube embed url match:, iframe_src.match(/youtu\.be.*(\?v=|\/embed\/)(.{11})/).pop();
          let youtube_video_id = element.contentvideourl.split('/').pop();
          //element.contentvideoimage = "https://img.youtube.com/vi/"+youtube_video_id+"/0.jpg";
          element.contentvideourlnew =
            'https://www.youtube.com/embed/' + youtube_video_id + '?autoplay=1';
          element.contentvideourlcopy =
            'https://www.youtube.com/embed/' + youtube_video_id;
        }
      });

      this.WebinarAllData = res;
      if (this.WebinarAllData.length > 0) {
        this.AllWebinarData = true;
      }

      //this.Webinar = res;

      this.Webinar = this.WebinarAllData.filter((res) => res.isDate == 'Past');

      this.isWebinar = true;
      this.isLoadedAllData();
      this.WebinarSlickSlider();
    });
  }

  packagesListSlider() {
    setTimeout(() => {
      $('.packagesLists').slick({
        slide: 'li',
        dots: false,
        arrows: true,
        draggable: false,
        swipeToSlide: false,
        prevArrow:
          '<a class="slick-prev packagesPrev"><img src="assets/images/centreimg/icon_prev_circle2.png" alt="" /></a>',
        nextArrow:
          '<a class="slick-next packagesNext"><img src="assets/images/centreimg/icon_next_circle2.png" alt="" /></a>',
        infinite: false,
        autoplay: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        lazyLoad: 'ondemand',
        responsive: [
          {
            breakpoint: 1100,
            settings: { slidesToShow: 2, slidesToScroll: 1 },
          },
          { breakpoint: 680, settings: { slidesToShow: 1, slidesToScroll: 1 } },
          { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } },
        ],
      });
      setTimeout(() => {
        let aThis = this;
        $('.packagesNext').click(function (e) {
          aThis.DataLayerOnClickUnitactivities(
            'Services & packages',
            'Services&packages_Right_Toggle'
          );
        });
        $('.packagesPrev').click(function (e) {
          aThis.DataLayerOnClickUnitactivities(
            'Services & packages',
            'Services&packages_Left_Toggle'
          );
        });
      }, 200);
      document.querySelector('#Loader').classList.remove('active');
      $('#CPackagesLoader').removeClass('active');
    }, 1000);
  }

  EventSlickSlider() {
    setTimeout(() => {
      $('.eventSliderWrap').slick({
        arrows: true,
        prevArrow:
          '<a class="slick-prev eventSPrev"><img src="assets/images/centreimg/icon_prev_circle2.png" alt="" /></a>',
        nextArrow:
          '<a class="slick-next eventSNext"><img src="assets/images/centreimg/icon_next_circle2.png" alt="" /></a>',
        dots: false,
        centerMode: false,
        adaptiveHeight: true,
        focusOnSelect: true,
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
      setTimeout(() => {
        let oThis = this;
        $('.eventSPrev').click(function () {
          oThis.DataLayerOnClickEvent(
            'Unit Activities',
            'Unitactivities_Event_Left_Toggle',
            ''
          );
        });
        $('.eventSNext').click(function () {
          oThis.DataLayerOnClickEvent(
            'Unit Activities',
            'Unitactivities_Event_Right_Toggle',
            ''
          );
        });
      }, 200);
    }, 1000);
  }

  EventSliderData() {
    this.api.getContentHubList(this.CenterID, `5`).subscribe((res) => {
      res = res.filter((res) => res.flag !== 0);

      res.forEach((element) => {
        element.isDate = this.CheckPastFutureDate(element.contentdate);
        element.calenderDate = this.DateFormater(element.contentdate);
        if (element.contentvideourl != null) {
          // if youtube embed url match:, iframe_src.match(/youtu\.be.*(\?v=|\/embed\/)(.{11})/).pop();
          let youtube_video_id = element.contentvideourl.split('/').pop();
          //element.contentvideoimage = "https://img.youtube.com/vi/"+youtube_video_id+"/0.jpg";
          element.contentvideourlnew =
            'https://www.youtube.com/embed/' + youtube_video_id + '?autoplay=1';
          element.contentvideourlcopy =
            'https://www.youtube.com/embed/' + youtube_video_id;
        }
      });
      this.EventAllData = res;
      this.Event = res;
      this.Event = this.EventAllData.filter((res) => res.isDate == 'Past');

      this.isLoadedAllData();
      this.EventSlickSlider();
      if (res.length == 0) {
        this.isEvent = false;
      } else {
        this.isEvent = true;
      }
    });
  }

  public AllMediaData = false;
  MediaSlickSlider() {
    if ($('.MediaSlider').hasClass('slick-initialized')) {
      $('.MediaSlider').slick('destroy');
    }

    setTimeout(() => {
      $('.MediaSlider').slick({
        arrows: true,
        prevArrow:
          '<a class="slick-prev mediaSPrev"><img src="assets/images/centreimg/icon_prev_circle2.png" alt="" /></a>',
        nextArrow:
          '<a class="slick-next mediaSNext"><img src="assets/images/centreimg/icon_next_circle2.png" alt="" /></a>',
        dots: false,
        centerMode: true,
        centerPadding: 20,
        focusOnSelect: true,
        adaptiveHeight: true,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
      setTimeout(() => {
        let oThis = this;
        $('.mediaSPrev').click(function () {
          oThis.DataLayerOnClickEvent(
            'Unit Activities',
            'Unitactivities_Media_Left_Toggle',
            ''
          );
        });
        $('.mediaSNext').click(function () {
          oThis.DataLayerOnClickEvent(
            'Unit Activities',
            'Unitactivities_Media_Right_Toggle',
            ''
          );
        });
      }, 200);
    }, 1000);
  }

  MediaSliderData() {
    this.api.getContentHubList(this.CenterID, `7`).subscribe((res) => {
      res = res.filter((res) => res.flag !== 0);
      res.forEach((element) => {
        element.isDate = this.CheckPastFutureDate(element.contentdate);
        element.calenderDate = this.DateFormater(element.contentdate);
      });

      this.MediaAllData = res;
      this.Media = res;
      if (this.Media.length > 0) {
        this.AllMediaData = true;
      }
      this.isMedia = true;
      this.isLoadedAllData();

      this.MediaSlickSlider();
    });
  }

  ArticlesSlickSlider() {
    if ($('.ArticlesSlider').hasClass('slick-initialized')) {
      $('.ArticlesSlider').slick('destroy');
    }

    setTimeout(() => {
      $('.ArticlesSlider').slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: true,
        prevArrow:
          '<a class="slick-prev articleSPrev"><img src="assets/images/centreimg/icon_prev_circle2.png" alt="" /></a>',
        nextArrow:
          '<a class="slick-next articleSNext"><img src="assets/images/centreimg/icon_next_circle2.png" alt="" /></a>',
        dots: false,
        centerMode: true,
        centerPadding: 20,
        focusOnSelect: true,
        adaptiveHeight: true,
        infinite: true,
        responsive: [
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
      setTimeout(() => {
        let oThis = this;
        $('.articleSPrev').click(function () {
          oThis.DataLayerOnClickEvent(
            'Unit Activities',
            'Unitactivities_Article_Left_Toggle',
            ''
          );
        });
        $('.articleSNext').click(function () {
          oThis.DataLayerOnClickEvent(
            'Unit Activities',
            'Unitactivities_Article_Right_Toggle',
            ''
          );
        });
      }, 200);
    }, 1000);
  }

  ArticleSliderData() {
    this.api.getContentHubList(this.CenterID, `3`).subscribe((res) => {
      res = res.filter((res) => res.flag !== 0);

      res.forEach((element) => {
        element.isDate = this.CheckPastFutureDate(element.contentdate);
        element.calenderDate = this.DateFormater(element.contentdate);
      });

      this.ArticlesAllData = res;
      this.Articles = res;
      this.isArticls = true;
      this.isLoadedAllData();

      this.ArticlesSlickSlider();
    });
  }

  public FeaturedArticlesData: any = [];
  public AllFeaturedArticlesData = false;
  FeaturedArticles(centerId) {
    this.api.getfeaturedArticles(centerId).subscribe((res) => {
      this.FeaturedArticlesData = res;
      if (res.length > 0) {
        this.AllFeaturedArticlesData = true;
      }
      console.log('FeaturedArticles == ', res);
    });
  }

  GetContantHubList() {
    this.WebinarSliderData();
    this.EventSliderData();
    this.MediaSliderData();
    this.ArticleSliderData();

    this.getPackageListing('', this.CenterID, '');
    this.getWorkshopListing('', this.CenterID, '');
    this.FeaturedArticles(this.CenterID);
    this.getDoctorsList();

    // 	this.api.getContentHubList(this.CenterID, `webinar`).subscribe(res => {
    // 		console.log('ContantHubList', res['content_hubs'])

    // 		// res['content_hubs'].forEach(element => {
    // 		// 	element.calenderDate = this.DateFormater(element.contentdate);
    // 		// });

    // 		// this.Webinar = res['content_hubs'].filter(data => data.contentcategory == 1);
    // 		// this.Event = res['content_hubs'].filter(data => data.contentcategory == 5);
    // 		// this.Media = res['content_hubs'].filter(data => data.contentcategory == 7);
    // 		// this.Articles = res['content_hubs'].filter(data => data.contentcategory == 3);
    // 		console.log('Webinar', this.Webinar);
    // 		console.log('Event', this.Event);
    // 		console.log('Media', this.Media);
    // 		console.log('Articles', this.Articles);

    // 	})
  }

  ngAfterViewChecked() {
    //console.log('after view checked');
    //initCentersSpecialityTabs();
  }

  hideModelForm() {
    $('#LMSDataCaptureFormGetInTouch').hide();
    $('#LMSDataCaptureFormGetInTouch .ThankYouMsgWrp').hide();
  }
  changeShowStatus() {
    this.showHide = !this.showHide;
    $('#ModelFormGetInTouch').hide();
    $('.iconForm').show();
    $('.iconFormV').show();
  }
  ShowForm() {
    this.showHide = true;
    $('.iconForm').hide();
    $('.iconFormV').hide();
    $('#ModelFormGetInTouch').show();
  }
  onSubmit() {
    this.submited = true;
    if (!this.GetInTouch.valid) {
      return;
    }
    this.preloader = true;
    var self = this;

    if (this.GetInTouch.valid) {
      $('#ModelFormGetInTouch').hide();

      let formTemp: any = <any>document.querySelector('#form');
      let formData: FormData = new FormData(formTemp);
      //console.log('Form Post Data',formData);
      let xhr: XMLHttpRequest = this.buildForm(formData);
      //console.log('XMLHttpRequest', xhr);
      xhr.onreadystatechange = () => {
        var obj = xhr;
        //console.log('obj', obj);
        if (obj.readyState == 4) {
          switch (this.pageName) {
            case 'madhapur':
              window.open(
                '/our-centre/' + this.pageName + '/thankyou?tag=AW',
                '_blank'
              );
              break;
            case 'kondapur-op-block':
              window.open(
                '/our-centre/' + this.pageName + '/thankyou?tag=AW',
                '_blank'
              );
              break;
            default:
              window.location.href =
                '/fertility-care/treatments/thankyou?tag=AW';
              break;
          }
          $('#ModelFormGetInTouch').hide();
          self.showHide = false;
          self.preloader = false;
          this.GetInTouch.reset();
        }
        if (obj.status == 200) {
          //console.log(obj.status);
        }
      };
    }
  }

  public buildForm(formData) {
    let url: any =
      API_BASE_URL + 'home/fertility_Speciality_get_in_touch_with_us';
    let xhr: XMLHttpRequest = new XMLHttpRequest();
    xhr.open('POST', url, true);

    // IE bug fixes to clear cache
    xhr.setRequestHeader('Cache-Control', 'no-cache');
    xhr.setRequestHeader('Cache-Control', 'no-store');

    xhr.send(formData);
    return xhr;
  }

  PackagesFilter(event, dateType) {
    $(event.target).addClass('Active').siblings().removeClass('Active');

    if ($('.packagesLists').hasClass('slick-initialized')) {
      $('.packagesLists').slick('destroy');
    }

    this.getPackageListing('', this.CenterID, dateType);
  }

  workshopFilter(event, specialityid) {
    console.log('aaa = ', event.target);
    $(event.target).addClass('Active').siblings().removeClass('Active');
    this.getWorkshopListing('', this.CenterID, specialityid);
    if (specialityid == '') {
      this.DataLayerOnClickUnitactivities(
        'Services & packages-Workshops',
        'Services&packages_Workshops_ALL'
      );
    }
    if (specialityid == 1) {
      this.DataLayerOnClickUnitactivities(
        'Services & packages-Workshops',
        'Services&packages_Workshops_Child_Care'
      );
    }
    if (specialityid == 2) {
      this.DataLayerOnClickUnitactivities(
        'Services & packages-Workshops',
        'Services&packages_Workshops_Women_Care'
      );
    }
    if (specialityid == 4) {
      this.DataLayerOnClickUnitactivities(
        'Services & packages-Workshops',
        'Services&packages_Workshops_Fertility_Care'
      );
    }
  }

  onLMSDataCaptureFormSubmit() {
    this.submited = true;
    if (!this.GetInTouchLms.valid) {
      return;
    }
    //this.preloader = true;
    var self = this;

    if (this.GetInTouchLms.valid) {
      //name:any, email:any, mobile:any, city:any, speciality:any

      let formTemp: any = <any>document.querySelector('#GetInTouchLms');
      console.log('formTemp: ', formTemp);
      let formData: FormData = new FormData(formTemp);

      let xhr: XMLHttpRequest = this.buildLMSDataCaptureForm(formData);

      xhr.onreadystatechange = () => {
        var obj = xhr;
        if (obj.readyState == 4) {
          //window.location.href = `other/academic-programs/thankyou?tag=AW`;/
          $('#LMSDataCaptureFormGetInTouch .ThankYouMsgWrp').show();
          setTimeout(() => {
            $('#LMSDataCaptureFormGetInTouch .ThankYouMsgWrp').hide();
            $('#LMSDataCaptureFormGetInTouch').hide();
            this.GetInTouch.reset();
          }, 2000);
        }
        if (obj.status == 200) {
          //console.log(obj.status);
        }
      };
    }
  }

  public buildLMSDataCaptureForm(formData) {
    let url: any = API_BASE_URL + 'home/get/in_touch_with_us';
    let xhr: XMLHttpRequest = new XMLHttpRequest();
    xhr.open('POST', url, true);

    // IE bug fixes to clear cache
    xhr.setRequestHeader('Cache-Control', 'no-cache');
    xhr.setRequestHeader('Cache-Control', 'no-store');

    xhr.send(formData);
    return xhr;
  }

  isNumber(evt) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  isletters(event: any) {
    const pattern = /^[a-zA-Z, '']+$/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      return false;
    }
    return true;
  }
  isAlfa(evt) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (
      charCode > 31 &&
      (charCode < 65 || charCode > 90) &&
      (charCode < 97 || charCode > 122)
    ) {
      return false;
    }
    return true;
  }

  getUtmSource() {
    this.http.get('http://api.ipify.org/?format=json').subscribe((res: any) => {
      this.ipAddress = res.ip;
      console.log('ipAddress: ', this.ipAddress);
      this.GetInTouchOnPage.controls.ipAddress.setValue(this.ipAddress);
    });
    let href = document.location.href;
    let pageName = getPageName(href);
    this.pageN = pageName;
    console.log('pageName: ', pageName);
    let utmLms = getUrlVars()['utm_lms'];
    if (utmLms == '' || utmLms == undefined) {
      utmLms = [''];
    } else {
      utmLms = utmLms.split('-');
    }
    //console.log('utmLms:', utmLms);
    let lms_product = utmLms[0],
      lms_campaign = utmLms[1],
      lms_lpname = utmLms[2],
      lms_agency = utmLms[3],
      lms_publisher = utmLms[4],
      lms_source = utmLms[5],
      lms_medium = utmLms[6],
      lms_dealtype = utmLms[7],
      lms_creative = utmLms[8],
      lms_cta = utmLms[9];
    //console.log("product:",lms_product,"campaign:",lms_campaign,"lpname:",lms_lpname,"agency:",lms_agency,"publisher:",lms_publisher,"source:",lms_source,"medium:",lms_medium,"dealtype:",lms_dealtype,"creative:",lms_creative,"cta:",lms_cta);
    if (lms_product == '' || lms_product == undefined) {
      lms_product = 'RCW';
    }
    if (lms_campaign == '' || lms_campaign == undefined) {
      lms_campaign = 'RainbowWebsiteLead';
    }
    if (lms_lpname == '' || lms_lpname == undefined) {
      lms_lpname = 'RainbowCorpWeb';
    }
    if (lms_agency == '' || lms_agency == undefined) {
      lms_agency = 'na';
    }
    if (lms_publisher == '' || lms_publisher == undefined) {
      lms_publisher = 'na';
    }
    if (lms_source == '' || lms_source == undefined) {
      lms_source = 'website';
    }
    if (lms_medium == '' || lms_medium == undefined) {
      lms_medium = 'na';
    }
    if (lms_dealtype == '' || lms_dealtype == undefined) {
      lms_dealtype = 'na';
    }
    if (lms_creative == '' || lms_creative == undefined) {
      lms_creative = 'na';
    }
    if (lms_cta == '' || lms_cta == undefined) {
      lms_cta = 'na';
    }

    let Leadtype = '';
    if (
      getUrlVars()['utm_source'] == '' ||
      getUrlVars()['utm_source'] == undefined
    ) {
      Leadtype = 'owned';
    } else {
      Leadtype = 'earned';
    }

    let utm_Source = getUrlVars()['utm_source'],
      utm_Medium = getUrlVars()['utm_medium'],
      utm_Campaign = getUrlVars()['utm_campaign'],
      utm_Content = getUrlVars()['utm_content'],
      utm_Term = getUrlVars()['utm_term'],
      utm_Lms = getUrlVars()['utm_lms'];
    this.utmSource = utm_Source;
    if (utm_Source == '' || utm_Source == undefined) {
      utm_Source = '';
      this.utmSource = '';
    }
    if (utm_Medium == '' || utm_Medium == undefined) {
      utm_Medium = '';
    }
    if (utm_Campaign == '' || utm_Campaign == undefined) {
      utm_Campaign = '';
    }
    if (utm_Content == '' || utm_Content == undefined) {
      utm_Content = '';
    }
    if (utm_Term == '' || utm_Term == undefined) {
      utm_Term = '';
    }
    if (utm_Lms == '' || utm_Lms == undefined) {
      utm_Lms = '';
    }

    /*
		this.GetInTouchOnPage.controls.page.setValue(pageName);		
		this.GetInTouchOnPage.controls.utm_source.setValue(utm_Source);
		this.GetInTouchOnPage.controls.utm_medium.setValue(utm_Medium);
		this.GetInTouchOnPage.controls.utm_campaign.setValue(utm_Campaign);
		this.GetInTouchOnPage.controls.utm_content.setValue(utm_Content);
		this.GetInTouchOnPage.controls.utm_term.setValue(utm_Term);
		this.GetInTouchOnPage.controls.utm_lms.setValue(utm_Lms);		
		this.GetInTouchOnPage.controls.useragent.setValue(this.userAgent);
		*/

    this.utm = {
      utm_source: utm_Source,
      utm_medium: utm_Medium,
      utm_campaign: utm_Campaign,
      utm_content: utm_Content,
      utm_term: utm_Term,
      utm_lms: utm_Lms,
      product: lms_product,
      campaign: lms_campaign,
      lpname: lms_lpname,
      agency: lms_agency,
      publisher: lms_publisher,
      source: lms_source,
      medium: lms_medium,
      dealtype: lms_dealtype,
      creative: lms_creative,
      cta: lms_cta,
      leadtype: Leadtype,
      modeoflead: 'website',
      page: pageName,
    };

    //var me = getUrlVars()["me"];
    function getUrlVars() {
      var vars = [],
        hash;
      var hashes = window.location.href
        .slice(window.location.href.indexOf('?') + 1)
        .split('&');
      for (var i = 0; i < hashes.length; i++) {
        hash = hashes[i].split('=');
        vars.push(hash[0]);
        vars[hash[0]] = hash[1];
      }
      return vars;
    }
    function getPageName(url) {
      if (!url || (url && url.length === 0)) {
        return '';
      }
      var index = url.lastIndexOf('/') + 1;
      var filenameWithExtension = url.substr(index);
      var basename = filenameWithExtension.split(/[.?&#]+/)[0];
      if (basename.length === 0) {
        url = url.substr(0, index - 1);
        basename = getPageName(url);
      }
      return basename ? basename : '';
    }
  }

  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
      //initGoogleMap();
      this.initCentersSpecialityTabs();
      this.iconSliderthing.changes.subscribe((t) => {
        //$(".aboutuslidercon").removeClass('slick-initialized').removeClass("slider").removeClass("slick-slider");

        $('.aboutuslidercon')
          .not('.slick-initialized')
          .slick(this.aboutusliderconConfig);
      });

      filterBoxWrapTab();
    }

    this.getUtmSource();
  }
  @HostListener('window:load', []) onWindowLoad() {
    $('#pageForm');

    $('.footersitenav').hide();
    $('.centreServiceList')
      .not('.slick-initialized')
      .slick({
        slide: 'li',
        dots: false,
        arrows: true,
        draggable: false,
        swipeToSlide: false,
        focusOnSelect: true,
        prevArrow:
          '<a class="slick-prev"><img src="assets/images/centreimg/icon_btn_prev.png" alt="" /></a>',
        nextArrow:
          '<a class="slick-next"><img src="assets/images/centreimg/icon_btn_next.png" alt="" /></a>',
        infinite: false,
        autoplay: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        lazyLoad: 'ondemand',
        cssEase: 'linear',
        adaptiveHeight: true,
        responsive: [
          {
            breakpoint: 1080,
            settings: { infinite: true, slidesToShow: 2, slidesToScroll: 1 },
          },
          {
            breakpoint: 580,
            settings: { infinite: false, slidesToShow: 1, slidesToScroll: 1 },
          },
        ],
      });

    $('.centreServiceList').on(
      'afterChange',
      (event, slick, currentSlide, nextSlide) => {
        var $current = $('li.active');
        $('li').removeClass('active');
        // $current.prev().addClass('active');
        // $current.next().addClass('active');
        $('.dataSection').removeClass('activeDiv').addClass('hideElement');
        $('.' + $('.centreServiceList').find('.slick-current').attr('id'))
          .removeClass('hideElement')
          .addClass('activeDiv');
        //console.log('sadsadasaddsda', currentSlide, nextSlide, $('.centreServiceList').find('.slick-current').attr('id'));
      }
    );

    $('.centreServiceList li').each(() => {
      $(this).click((e) => {
        //console.log('li clicked', e, $(this).attr('id'))
        $('.centreServiceList li').removeClass('active');
        $(this).addClass('active');
        var slideIndex = $(this).data('slick-index');
        console.log(slideIndex);
        $('.centreServiceList').slick('slickGoTo', parseInt(slideIndex)); //.slickGoTo(parseInt(slideIndex));
        $('.dataSection').removeClass('activeDiv').addClass('hideElement');
        $('.' + $(this).attr('id'))
          .removeClass('hideElement')
          .addClass('activeDiv');
        //console.log($(this).attr('id'));
      });
    });

    $('.apapproachlist').slick({
      slide: 'li',
      dots: false,
      arrows: true,
      prevArrow:
        '<a class="slick-prev"><img src="assets/images/prv-arrow2.png" alt="" /></a>',
      nextArrow:
        '<a class="slick-next"><img src="assets/images/nxt-arrow2.png" alt="" /></a>',
      infinite: false,
      autoplay: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        { breakpoint: 1100, settings: { slidesToShow: 2, slidesToScroll: 1 } },
        { breakpoint: 680, settings: { slidesToShow: 2, slidesToScroll: 1 } },
        { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } },
      ],
    });
    //$(".aboutuslidercon").removeClass('slick-initialized').removeClass("slider").removeClass("slick-slider");

    $('.aboutuslidercon')
      .not('.slick-initialized')
      .slick(this.aboutusliderconConfig);
    $('.aboutuslider')
      .removeClass('slick-initialized')
      .removeClass('slider')
      .removeClass('slick-slider');
    $('.aboutuslider')
      .not('.slick-initialized')
      .slick({
        slide: 'li',
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: true,
        prevArrow:
          '<a class="slick-prev"><img src="assets/images/prv-arrow2.png" alt="" /></a>',
        nextArrow:
          '<a class="slick-next"><img src="assets/images/nxt-arrow2.png" alt="" /></a>',
        asNavFor: '.aboutuslidercon',
        dots: false,
        centerMode: true,
        centerPadding: 20,
        focusOnSelect: true,
        infinite: true,
        responsive: [
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 680,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    setTimeout(() => {
      console.log('programName: ', this.programName);
      if (this.programName != '') {
        $('.modelPupup').hide();
      }
    }, 2500);

    this.DataLayerONLoad();
  }

  initCentersSpecialityTabs() {}
  ngOnDestroy() {}

  onNameBlur(e) {
    this.DataLayerOnNameBlur(e.target.value);
  }
  onMobileBlur(e) {
    this.DataLayerOnMobileBlur(e.target.value);
  }
  onEmailBlur(e) {
    this.DataLayerOnEmailBlur(e.target.value);
  }
  OnChangeSubSpecility(e) {
    this.DataLayerOnSubSpecialityChange(e.target.value);
  }
  onMassgeBlur(e) {
    this.DataLayerOnMassgeBlur(e.target.value);
  }
  OnChangeAuthorize(e) {
    this.DataLayerOnAuthorizeBlur(e.target.value);
  }

  DataLayerOnNameBlur(e) {
    let ename: any = MD5(e.toString()),
      c_path = window.location.pathname.split('/');
    let pName = c_path[c_path.length - 2],
      spName = c_path[c_path.length - 1];
    pName = pName.replace(/-/gi, '-');
    spName = spName.replace(/-/gi, '_');
    dataLayerPush(
      'OurCentre_' + spName + '_GetinTouchwithUs_name',
      'Ourcentre_' + spName + '_getintouchwithus_name',
      'Ourcentre_' + spName + '_getintouchwithus_name',
      '',
      pName,
      pName,
      spName,
      '',
      'English',
      pName,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      ename,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      this.utmSource,
      ''
    );
  }
  DataLayerOnMobileBlur(e) {
    let emobile: any = MD5(e.toString()),
      c_path = window.location.pathname.split('/');
    let pName = c_path[c_path.length - 2],
      spName = c_path[c_path.length - 1];
    pName = pName.replace(/-/gi, '-');
    spName = spName.replace(/-/gi, '_');
    dataLayerPush(
      'OurCentre_' + spName + '_GetinTouchwithUs_mobile',
      'Ourcentre_' + spName + '_getintouchwithus_mobile',
      'Ourcentre_' + spName + '_getintouchwithus_mobile',
      '',
      pName,
      pName,
      spName,
      '',
      'English',
      pName,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      emobile,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      this.utmSource,
      ''
    );
  }
  DataLayerOnEmailBlur(e) {
    let eemail: any = MD5(e.toString()),
      c_path = window.location.pathname.split('/');
    let pName = c_path[c_path.length - 2],
      spName = c_path[c_path.length - 1];
    pName = pName.replace(/-/gi, '-');
    spName = spName.replace(/-/gi, '_');
    dataLayerPush(
      'OurCentre_' + spName + '_GetinTouchwithUs_email',
      'Ourcentre_' + spName + '_getintouchwithus_email',
      'Ourcentre_' + spName + '_getintouchwithus_email',
      '',
      pName,
      pName,
      spName,
      '',
      'English',
      pName,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      eemail,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      this.utmSource,
      ''
    );
  }
  DataLayerOnSpecialityChange(speciality) {
    let c_path = window.location.pathname.split('/');
    let pName = c_path[c_path.length - 2],
      spName = c_path[c_path.length - 1];
    pName = pName.replace(/-/gi, '-');
    spName = spName.replace(/-/gi, '_');
    speciality = speciality.replace(/ - /gi, '_').toLowerCase();
    speciality = speciality.replace(/ /gi, '_');
    dataLayerPush(
      'OurCentre_' + spName + '_GetinTouchwithUs_Speciality',
      'Ourcentre_' + spName + '_getintouchwithus_Speciality',
      'Ourcentre_' + spName + '_getintouchwithus_Speciality',
      '',
      pName,
      pName,
      spName,
      '',
      'English',
      pName,
      '',
      '',
      '',
      '',
      '',
      speciality,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      this.utmSource,
      ''
    );
  }
  DataLayerOnSubSpecialityChange(subspeciality) {
    let c_path = window.location.pathname.split('/');
    let pName = c_path[c_path.length - 2],
      spName = c_path[c_path.length - 1];
    pName = pName.replace(/-/gi, '-');
    spName = spName.replace(/-/gi, '_');
    subspeciality = subspeciality.replace(/ - /gi, '_').toLowerCase();
    subspeciality = subspeciality.replace(/ /gi, '_');
    dataLayerPush(
      'OurCentre_' + spName + '_GetinTouchwithUs_SubSpeciality',
      'Ourcentre_' + spName + '_getintouchwithus_SubSpeciality',
      'Ourcentre_' + spName + '_getintouchwithus_SubSpeciality',
      '',
      pName,
      pName,
      spName,
      '',
      'English',
      pName,
      '',
      '',
      '',
      '',
      '',
      '',
      subspeciality,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      this.utmSource,
      ''
    );
  }
  DataLayerOnMassgeBlur(e) {
    let massge: any = e,
      c_path = window.location.pathname.split('/');
    let pName = c_path[c_path.length - 2],
      spName = c_path[c_path.length - 1];
    pName = pName.replace(/-/gi, '-');
    spName = spName.replace(/-/gi, '_');
    dataLayerPush(
      'OurCentre_' + spName + '_GetinTouchwithUs_Message',
      'Ourcentre_' + spName + '_getintouchwithus_Message',
      'Ourcentre_' + spName + '_getintouchwithus_Message',
      '',
      pName,
      pName,
      spName,
      '',
      'English',
      pName,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      this.utmSource,
      massge
    );
  }
  DataLayerOnAuthorizeBlur(e) {
    let massge: any = e,
      c_path = window.location.pathname.split('/');
    let pName = c_path[c_path.length - 2],
      spName = c_path[c_path.length - 1];
    pName = pName.replace(/-/gi, '-');
    spName = spName.replace(/-/gi, '_');
    dataLayerPush(
      'OurCentre_' + spName + '_GetinTouchwithUs_Authorize',
      'Ourcentre_' + spName + '_getintouchwithus_Authorize',
      'Ourcentre_' + spName + '_getintouchwithus_Authorize',
      '',
      pName,
      pName,
      spName,
      '',
      'English',
      pName,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      this.utmSource,
      ''
    );
  }
  DataLayerONLoad() {
    let c_path = window.location.pathname.split('/');
    let pName = c_path[c_path.length - 2],
      spName = c_path[c_path.length - 1];
    pName = pName.replace(/-/gi, '-');
    spName = spName.replace(/-/gi, '_');

    //eventAction,eventlabel,eventCategory,linkClicked,pageName,pageCategory,pageSubcategory,AppointmentDate,language,pageType,ageofPatient,appointmentId,appointmentdate,appointmentTime,bookforLocation,bookforSpeciality,bookforSubSpeciality,packagePlan,dobofPatient,doctorsName,usermobilenumber,useremail,username,selectedHospitalUnit,typeOfAppointment,userType,patientType,weightofPatient,temperatureofPatient,transactionID,revenue,products,price,Quantity,paymentStatus,utmSource,message
    dataLayerPush(
      'OurCentre_' + spName + '_GetinTouchwithUs',
      'Ourcentre_' + spName + '_getintouchwithus',
      'Ourcentre_' + spName + '_getintouchwithus',
      '',
      pName,
      pName,
      spName,
      '',
      'English',
      pName,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      this.utmSource,
      ''
    );
  }

  DataLayerONSubmitForm(
    name: any,
    email: any,
    mobile: any,
    city: any,
    speciality: any,
    subspeciality: any,
    massge: any
  ) {
    localStorage.setItem('mobNumber', mobile);
    let mNumber = localStorage.getItem('mobNumber'),
      UserType;
    if (mNumber == mobile) {
      UserType = 'Existing';
    } else {
      UserType = 'New';
    }

    if (email != '') {
      email = MD5(email.toString());
    }
    if (mobile != '') {
      mobile = MD5(mobile.toString());
    }
    let c_path = window.location.pathname.split('/');
    let pName = c_path[c_path.length - 2],
      spName = c_path[c_path.length - 1];
    pName = pName.replace(/-/gi, '-');
    spName = spName.replace(/-/gi, '-');

    speciality = speciality.replace(/ - /gi, '_').toLowerCase();
    speciality = speciality.replace(/ /gi, '_');

    subspeciality = subspeciality.replace(/ - /gi, '_').toLowerCase();
    subspeciality = subspeciality.replace(/ /gi, '_');
    //eventAction,eventlabel,eventCategory,linkClicked,pageName,pageCategory,pageSubcategory,AppointmentDate,language,pageType,ageofPatient,appointmentId,appointmentdate,appointmentTime,bookforLocation,bookforSpeciality,bookforSubSpeciality,packagePlan,dobofPatient,doctorsName,usermobilenumber,useremail,username,selectedHospitalUnit,typeOfAppointment,userType,patientType,weightofPatient,temperatureofPatient,transactionID,revenue,products,price,Quantity,paymentStatus,utmSource,message
    dataLayerPush(
      'OurCentre_' + city + '_GetinTouchwithUs_Submit',
      'OurCentre_' + city + '_GetinTouchwithUs_Submit',
      'OurCentre_' + city + '_GetinTouchwithUs_Submit',
      '',
      pName,
      pName,
      spName,
      '',
      'English',
      pName,
      '',
      '',
      '',
      '',
      city,
      speciality,
      subspeciality,
      '',
      '',
      '',
      mobile,
      email,
      name,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      this.utmSource,
      massge
    );
  }

  DataLayerOnClickUnitactivities(name, event) {
    let c_path = window.location.pathname.split('/'),
      pName = c_path[c_path.length - 2],
      spName = c_path[c_path.length - 1];
    pName = pName.replace(/-/gi, '-');
    spName = spName.replace(/-/gi, '_');
    dataLayerPush(
      name,
      'Ourcentre_' + spName + '_' + event,
      'Ourcentre_' + spName + '_' + event,
      '',
      pName,
      pName,
      spName,
      '',
      'English',
      pName,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      this.utmSource,
      ''
    );
  }
  DataLayerOnClickEvent(name, event, linktitle) {
    let c_path = window.location.pathname.split('/'),
      pName = c_path[c_path.length - 2],
      spName = c_path[c_path.length - 1];
    pName = pName.replace(/-/gi, '-');
    spName = spName.replace(/-/gi, '_');
    linktitle = linktitle.replace(/ - /gi, '_').toLowerCase();
    linktitle = linktitle.replace(/ /gi, '_');
    console.log('event:', event, linktitle);
    if (linktitle !== '') {
      console.log('event if:', event);
      if (name == 'Doctors') {
        event = 'Doctors_' + linktitle + '_' + event;
      }
    }
    dataLayerPush(
      name,
      'Ourcentre_' + spName + '_' + event,
      'Ourcentre_' + spName + '_' + event,
      linktitle,
      pName,
      pName,
      spName,
      '',
      'English',
      pName,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      this.utmSource,
      ''
    );
  }

  DataLayerOnClickDoctorEvent(
    name,
    event,
    linktitle,
    doctorname,
    speciality,
    subspeciality
  ) {
    let c_path = window.location.pathname.split('/'),
      pName = c_path[c_path.length - 2],
      spName = c_path[c_path.length - 1];
    pName = pName.replace(/-/gi, '-');
    spName = spName.replace(/-/gi, '_');
    dataLayerPush(
      name,
      'Ourcentre_' + spName + '_' + event,
      'Ourcentre_' + spName + '_' + event,
      linktitle,
      pName,
      pName,
      spName,
      '',
      'English',
      pName,
      '',
      '',
      '',
      '',
      '',
      speciality,
      subspeciality,
      '',
      '',
      doctorname,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      this.utmSource,
      ''
    );
  }
}

const filterBoxWrapTab = () => {
  $('.filterBoxWrap')
    .find('.tab')
    .click((e) => {
      $(this).addClass('Active').siblings().removeClass('Active');
    });
};
