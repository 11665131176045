<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>

    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <section class="page pageheader">
        <div class="container">
            <div class="col-xs-6 col-sm-6 col-lg-6 abannimgwrp">
                <div class="otherbannimg"><img src="{{imgBasePath}}{{imgsrc}}" alt="{{title}}" title="{{title}}" class="img-responsive" /></div>
            </div>
            <div class="col-xs-6 col-sm-6 col-lg-6">
                <div class="ptitle">
                    <h1>{{title}}</h1>
                    <h3>{{subtitle}}</h3>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-lg-6 mcont100">
                <p [innerHTML]="description"></p>
                <div class="btnwrpblu" style="display: none;">
                    <a href="/other/health-card/register" class="bluBtn">Click Here to Register</a>
                </div>
            </div>

        </div>
    </section>
    <section class="aboutsectin2 wcuswrp">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-lg-12">
                    <ul class="atabs slider">
                        <li class="active">
                            <a href="javascript:void()" data-id="data1">
                                <div class="aiconwrp"><img src="assets/images/healthcard_new.png" alt="" /></div>
                                <h2>New Card Holder</h2>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void()" data-id="data2">
                                <div class="aiconwrp"><img src="assets/images/healthcard_existing.png" alt="" /></div>
                                <h2>Existing card Holders</h2>
                            </a>
                        </li>
                    </ul>
                    <div class="atabscontent slider">
                        <div class="atabsdata">
                            <ul class="hlthlist">
                                <li>Please note that this offer has been discontinued. Sorry for any inconvenience  caused</li>
                                <!--<li>New Patient Registration Fee Waiver</li>
                                <li>First Consultation Free (T &amp; C Apply)</li>
                                <li>10% Discount on OPD investigations (In house only)</li>
                                <li>Complimentary 1 level room up-gradation-Subject to room availability</li>
                                <li>Free Safe Baby Work Shop Enrollment (Work Shop date to be communicated shortly). To know more click here <a href="http://www.rainbowhospitals.in/child-safety/" target="_blank">http://www.rainbowhospitals.in/child-safety/</a></li>
                                <li>EMI Facility on Fertility packages</li>-->
                            </ul>
                        </div>
                        <div class="atabsdata">
                            <ul class="hlthlist">
                                <li>Please note that this offer has been discontinued. Sorry for any inconvenience  caused</li>
                                <!--<li>10% Discount on OPD investigations (In house Only)</li>
                                <li>Complimentary 1 level room up-gradation-Subject to room availability</li>
                                <li>Free Safe Baby Work Shop Enrollment (Work Shop Date to be communicated shortly). To know more click here - <a href="http://www.rainbowhospitals.in/child-safety/" target="_blank">http://www.rainbowhospitals.in/child-safety/</a></li>
                                <li>EMI Facility on Fertility packages</li>-->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <footer-nav-mini [links]="links"></footer-nav-mini>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>