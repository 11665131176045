<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <section class="page pageheader">
        <div class="container">
            <div>

                <div class="col-xs-6 col-sm-6 col-lg-6 abannimgwrp">
                    <div class="otherbannimg"><img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" /></div>
                </div>
                <div class="col-xs-6 col-sm-6 col-lg-6">
                    <div class="ptitle">
                        <h1>{{title}}</h1>
                        <h3>{{subtitle}}</h3>
                    </div>
                    <p [innerHTML]="description"></p>
                </div>
                <div class="col-xs-6 col-sm-6 col-lg-6 mcont100">
                </div>
            </div>

        </div>
    </section>

    <section class="aboutsectin2 wcuswrp">
        <div class="row">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 col-xs-12 col-lg-12">
                        <div class="centrePackagesWrp">
                            <div class="sheading">
                                <h3>Packages</h3> 
                                <div class="filterBoxWrap">
                                    <div class="tab Active" (click)="PackagesFilter($event,'')">All</div>
                                    <div class="tab" (click)="PackagesFilter($event,'4')">Fertility Care </div>
                                    <div class="tab" (click)="PackagesFilter($event,'2')">Women Care</div>
                                    <div class="tab" (click)="PackagesFilter($event,'1')">Child Care</div>
                                </div>
                            </div>
                            <div class="col-xs-12" *ngIf="Packages?.length == 0">
                                <div class="noDataFound">No Packages available in this section</div>
                            </div>
                            <ul class="packagesLists" *ngIf="Packages?.length > 0">
                                <li *ngFor="let item of Packages">
                                    <div class="leftBannWrp">
                                        <img src="{{imgBasePath}}{{item.image}}" alt="" />
                                    </div>
                                    <div class="rightTxtWrp">
                                        <h4>{{item.packageheading}}</h4>
                                        <!-- <p>{{item.packageduration}}</p> -->
                                        <p>{{item.smallDiscription}}</p>                                        
                                                <div class="location"> <img src="../../assets/images/location-icon.png" alt="" srcset=""> <div class="item" *ngFor="let center of item.centerlist">{{center.center_name_alias}}</div></div>                                        
                                        <div class="col-xs-12">
                                            <a href="other/packages-plans/{{item.slug}}"
                                                class="readMoreStyle2"><span>Read More</span></a>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>