<div class="container-fluid">
    <div class="background">
        <img src="assets/images/bg-press-clip.png" data-t="bg_01_00.jpg" data-d="bg-press-clip.png" class="bgImg bg0 active" />
    </div>
    <div class="loder"></div>
    <sidenav></sidenav>
    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <!--<bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>-->
    <section class="pagebcwrp">
        <div class="container">
            <div class="row">
            <div class="col-md-12 col-sm-12 col-lg-12">
                <ul>
                <li *ngFor="let link of bread_crumb_links" class="{{link.class}}">
                    <a *ngIf="link.link" href="{{link.link}}">{{link.label}}</a>
                    <span *ngIf="!link.link">{{link.label}}</span>
                </li>
                <li >{{bread_crumb_title | slice:0:32}}<span *ngIf="bread_crumb_title_length > 50">...</span></li>
                </ul>
            </div>
            </div>
        </div>
    </section>
    <section class="page mediaSection pageheader">
        <div class="row">
            <div class="container">
                <div class="col-xs-12 col-sm-7 col-lg-7 abannimgwrp">
                    <div class="aboutbannimg"><img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" /></div>
                </div>
                <div class="col-xs-12 col-sm-5 col-lg-5">
                    <div class="ptitle yellow">
                        <h4>{{title}}</h4>
                        <h3>{{subtitle}}</h3>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="container-fluid aboutbod">
        <div class="row">
            <div class="container">
                <div class="col-xs-12 col-sm-12 col-lg-12 event-details">
                    <div class="prelshead">
                        <div class="prdate">
                            <h3>{{eventDetail.event_date | amDateFormat : "MMMM"}}</h3>
                            <h2 class="gradient">{{eventDetail.event_date | amDateFormat : "DD"}}</h2>
                            <h4>{{eventDetail.event_date | amDateFormat : "YYYY"}}</h4>
                        </div>
                        <div class="prtitle">
                            <h1>{{eventDetail.name}}</h1>
                            <h4>{{eventDetail.event_date | amDateFormat : "dddd, hh:mm"}}</h4>
                        </div>
                    </div>
                    <div class="prelscontent">

                        <div [ngClass]="{'col-xs-12 col-sm-6 col-lg-6': eventDetail.description_two == '', 'col-xs-12 col-sm-12 col-lg-12':  !eventDetail.description_two}" class="">
                            <p [innerHTML]="eventDetail.description"></p>
                        </div>


                        <div *ngIf="eventDetail.description_two" class="col-xs-12 col-sm-6 col-lg-6">
                            <address>
								<p [innerHTML]="eventDetail.description_two"></p>
							</address>
                        </div>

                        <div *ngIf="videoUrl" class="iframeVideoWrp">
                            <iframe [src]='safeURL' frameborder="0" allowfullscreen class="responsive_iframe"></iframe>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>

</div>