<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>

    <section class="page wtwedo">
        <div class="row">
            <div class="col-md-5 col-xs-5 col-lg-4">
                <div class="circuleframe">
                    <img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" />
                </div>
                <!-- End Circle -->
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7">
                <div class="pagetitle">
                    <h1 class="tgradient">{{title}} </h1>
                    <div class="underline">
                        <img src="assets/images/underline2.png" alt="" />
                    </div>
                    <h3>{{subtitle}}</h3>
                </div>
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7 mcontentview">
                <p [innerHTML]="description"></p>
            </div>
        </div>

    </section>
    <section class="aboutsectin2 awardswapper">
        <div class="container">
            <div class="row nsg_text">
                <div class="col-xs-12 col-lg-12">
                    <h2>What is Rhesus factor?</h2>
                    <p>The rhesus-D factor is a special antigen found on red blood cells. 85% of people have the rhesus-D factor and are called Rh positive. The ones who do not have the rhesus-D factor are Rh negative.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>What is Rhesus sensitisation?</h2>
                    <p>When the mother is Rh-negative and the father is Rh-positive, complications may arise if thebaby inherits the Rh factor from the father. Unless there's some sort of abnormality, the baby’s blood does not normally enter the mother's
                        blood stream during pregnancy. But during delivery the mother’s blood may get in contact with the baby’s blood. In this scenario, the mother's body recognizes the Rh protein as a foreign substance and can begin producing antibodies.</p>
                    <p>These antibodies are generally harmless until the mother's second or later pregnancies. If she has another Rh-positive child in her womb, antibodies will be produced and her body will trigger an attack as if the baby were a foreign
                        body. These antibodies can pass into the baby's bloodstream and attack the baby’s red blood cells. This can lead to rupture of the baby's RBCs thereby makingthe baby very anemic. This is called hemolytic disease of the newborn
                        or HDN.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>How can rhesus sensitization be prevented?</h2>
                    <p>When a Rh negative woman is pregnant and there are chances of Rhesus factor incompatibility, she is given two Rh immunoglobulin shots during her first pregnancy, one in the 28th week of pregnancy and the second within 72 hours after
                        giving birth. This immunoglobulin acts like a vaccine and does not allow the mother's body to produce any potentially dangerous Rh antibodies that can cause serious complications in the newborn or future pregnancies.</p>
                    <p>In case of a potentially sensitizing event during the pregnancy such as a miscarriage, an amniocentesis, or any bleeding during pregnancy, a preventive or prophylactic dose of Rh immunoglobulin may also be given.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>Can the baby be treated?</h2>
                    <p>A baby with HDN can be treated in two ways. First option would be to deliver the baby early and then treat the baby outside of the womb. The second option is to treat the baby before delivery with a blood transfusion.</p>
                    <p>Using ultrasound, a site of the cord insertion into the placenta or a large blood vessel in the baby’s abdomen is identified. A needle is then passed through the mother’s abdomen into the baby’s blood vessel and a blood sample is taken.
                        If the baby is anemic, a blood transfusion may be given dependingon how anemic the baby is and the stage of pregnancy. Further transfusions may need to be given every two weeks until delivery.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>What are the risks of fetal blood transfusions?</h2>
                    <p>Fetal blood transfusions use very fresh blood that has been cross matched against the mother. The blood is treated so as to provide as minimal a risk as possible to the baby. Moreover, blood is taken from donors who are very healthy
                        and have been tested for infections. However, this procedure is not without risk and carries a risk of causing a miscarriage in the order of one in a hundred for each transfusion performed.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>How do we decide when a transfusion is necessary?</h2>
                    <p><strong>Presence of antibodies in mother:</strong>Pregnant women need to get their antibodies checked as part of their routine checkups and again at around 28 weeks. If tests suggest the presence of a blood group antibody, further
                        action may be required. Generally, this involves monitoring and blood group tests every four weeks.</p>
                    <p>There is hardly any problem if the antibody levels are below 4 international units. However, if this level goes up, further intervention is needed.</p>
                    <p><strong>Ultrasound assessment:</strong> Fetal anemia can be screened by studying the blood flow in a blood vessel inside the baby’s head using the ColorFlow Dopplertechnique.After understanding the location of the blood flow, is it
                        is very easy for the machine to calculate the maximum flow velocity. A number of recordings are done and the results are then compared with the expected level at that stage of the pregnancy. This needs to be done on a weekly basis
                        to get an accurate picture. If the value is very high,it suggests the baby is anemicand a decision needs to be made whether to deliver or treat. The decision to deliver or transfuse depends on a number of factors. If the baby is
                        less than 34 weeks, a transfusion is opted and if beyond that stage, delivery is preferred.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>How can we prevent this problem?</h2>
                    <p>In case of any sensitizing events that may cause the baby’s blood to enter the mother’s blood stream, the doctor can recommend either a Kleihauer test or give a prophylactic immunoglobulin shot. TheKleihauer test determines if there
                        is any fetal blood in the mother’s blood stream and calculates the amount of fetal blood. If the fetal blood is high, the mother is given prophylactic immunoglobulin. This suppresses the mother's immune system from attacking Rh-positive
                        blood cells which have entered the maternal blood stream from fetal circulation.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>What happens after delivery with a rhesus negative mother?</h2>
                    <p>After delivery, blood sample is taken from the mother as well as the cord. The cord blood enables the doctors to know the fetal blood group. If the baby is rhesus negative like the mother, then there is no problem. If the baby is rhesus
                        positive, a Kleihauertest is done and an appropriate amount of immunoglobulin is given to the mother.</p>
                </div>
            </div>
        </div>
    </section>
    <ng-template [ngIf]="ins_links.length > 0">
        <footer-nav-mini [links]="ins_links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>