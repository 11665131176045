<div class="container-fluid">
  <div class="loder"></div>
  <sidenav></sidenav>

  <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
  <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>

  <section class="page abccare">
    <div class="">
      <div class="col-md-5 col-xs-5 col-lg-4">
        <div class="circuleframe">
          <img
            src="assets/images/bann-dr-ramesh-kancharla.png"
            alt="Dr. Ramesh Kancharla"
            title="Dr. Ramesh Kancharla"
          />
        </div>
        <!-- End Circle -->
      </div>
      <div class="col-md-7 col-xs-7 col-lg-7">
        <div class="pagetitle">
          <h1 class="textgradient">Dr. Ramesh Kancharla</h1>
          <div class="underline">
            <img src="assets/images/underline.png" alt="" />
          </div>
          <h3>MBBS, MD (Paediatrics)</h3>
        </div>
      </div>
      <div class="col-md-7 col-xs-7 col-lg-7 mcontentview">
        <p>Chairman & Managing Director, Rainbow Hospitals</p>
        <div class="drtestimonial">
          <p>
            At Rainbow we are driven with the powerful belief that to convert
            the ‘impossible’ to ‘possible’, one has to convert the ‘I’s and
            Me’s’ to ‘Us and We’. Teamwork is the very soul of our entity as
            Rainbow Group.
          </p>
        </div>
      </div>
    </div>
  </section>
  <section class="container-fluid ospesltyaccord drdetails">
    <div class="whitecolor-sec drinfowrp">
      <div class="container">
        <div class="col-xs-12 col-lg-12">
          <div class="drinfotitle">Primary Education</div>
          <div class="drinfoText">
            <p>
              Dr Ramesh Kancharla comes from middle class village background. He
              studied in his native village
              <br />
              Chiramana, Nellore district and subsequently high school in
              neighboring village called Kavali Yedavalli upto
              <br />
              7th class, Intermediate from V R College, Nellore and obtained
              MBBS seat in SV Medical College, Tirupathi.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="whitecolor-sec faqswrp">
      <div class="container">
        <div class="col-xs-12 col-lg-12">
          <div class="GenraelPediatrics-aco">PROFESSIONAL QUALIFICATIONS</div>
          <div class="GenraelPediatrics-Infotext tncont">
            <div class="rstable">
              <table cellpadding="0" cellspacing="0" class="drtable1">
                <tr>
                  <td>MBBS</td>
                  <td>1987</td>
                  <td>Shri SV Medical College Tirupati, Andhra Pradesh</td>
                </tr>
                <tr>
                  <td>MD (Paediatrics)</td>
                  <td>1992</td>
                  <td>Kasturba Medical College, Mangalore, Karnataka</td>
                </tr>
                <tr>
                    <td>MRCP(UK)</td>
                    <td>1999</td>
                    <td>Royal college of Physicians, London UK</td>
                  </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="whitecolor-sec faqswrp">
      <div class="container">
        <div class="col-xs-12 col-lg-12">
            <div class="GenraelPediatrics-aco">Overseas Professional Career:</div>
            <div class="GenraelPediatrics-Infotext tncont" style="display: block;">
              <p>Dr Ramesh spent 8 years in UK working mostly in London teaching hospitals like:</p>
                <ul class="CourseAndCcon">
                    <li>KING’S COLLEGE HOSPITAL, LONDON, UK</li>
                    <li>ROYAL FREE SCHOOL OF MEDICINE, LONDON, UK </li>
                    <li>GREAT ORMOND STREET CHILDREN’S HOSPITAL, LONDON, UK</li>
                </ul>

                <p>With his long paediatric background, he chose to do specialisation in Paediatric Gastroenterology, Liver Diseases and Transplantation. After returning from UK he started Rainbow Children’s Hospital in 1999. He was practising paediatric gastroenterology and Liver Diseases for 14 years. He then chose to become fulltime entrepreneur to build Paediatric Healthcare tertiary care model in India. In the last 4 years Rainbow expanded to 5 cities – Hyderabad, Secunderabad Bengaluru, Vijayawada,Delhi, Chennai  and Vishakapatnam with 1300 beds (14 units).</p>

<p>Rainbow Partnered with CDC Plc, UK govt funded institution to build the paediatric model across the country.</p>

<p>Rainbow runs one of the leading academic Programme to generate future paediatricians and sub specialties through National Board of Examinations (NBE), Indian Academics of Paediatrics (IAP), National Neonatology Forum (NNF), Rajiv Gandhi University of Medical Sciences.</p>

            </div>
        </div>
      </div>
    </div>
    <div class="whitecolor-sec faqswrp">
      <div class="container">
        <div class="col-xs-12 col-lg-12">
            <div class="GenraelPediatrics-aco sel">The efforts of team Rainbow earned several awards as follows:</div>
            <div class="GenraelPediatrics-Infotext tncont" style="display: block;">
              <ul class="CourseAndCcon">
                <li>Awarded Best Children's Hospital in India by CNBC TV 18 and ICICI Lombard – 2010 and 2015</li>
                <li>Hansa Survey/The WEEK  – Amongst Top Four Best Children’s Hospital in India – 2010 </li>
                <li>Hansa Survey /The WEEK – Best Pediatric Hospital in AP – 2010</li>
                <li>Best Children's Hospital &amp; one of the top two Gynecology centers in Hyderabad - Times Health Survey, Hyderabad - 2015</li>
                <li>Awarded Best Hospital in Single Specialty services in VCCircle Healthcare Awards – September, 2015</li>
                <li>Six Sigma Healthcare Excellence Awards – Best Patient Care – 2013</li>
                <li>Awarded Best Hospital in Pediatrics &amp; Gynecology in National Awards for Excellence in Healthcare - July, 2015</li>
                <li>Healthcare Leader awards – Rainbow Digital -2015</li>
                <li>FTAPCCI Excellence Award for 'Excellence in Innovative Product/Service in Health Care with Highest Societal Impact' is awarded to Rainbow Children's Hospitalv-2015</li>
                <li>Rainbow Children’s Hospital entered into Guinness World Record books  – In Gathering 445 Prematurely born babies under one roof on Nov 17th  2016 World Prematurity Day.</li>
                <li>Telangana’s Best Healthcare Professional awards - Awarded Rainbow Children’s Hospital as Best Healthcare Professional – 2017</li>
                <li>World Health and Wellness Congress awards – Best Single specialty Hospital – 2017</li>
                <li>Times Healthcare achievers Telangana state 2017: Rainbow children's hospital recognized as the best hospital of the year in Pediatrics</li>
                <li>HI9 Doctor Awards 2018</li>
                <li>Limca Book of Records 2019 – lightest baby born in Southeast Asia born and survives at Rainbow Children’s Hospital. </li>
                <li>Rainbow Certified GREAT PLACE TO WORK for two consecutive years 2019-2020, 2020-2021.</li>


              </ul>
            </div>
        </div>
      </div>
    </div>
    <div class="whitecolor-sec faqswrp">
      <div class="container">
        <div class="col-xs-12 col-lg-12">
            <div class="GenraelPediatrics-aco sel">PUBLICATIONS</div>
            <div class="GenraelPediatrics-Infotext tncont" style="display: block;">
                <div class="tablecontres">
                    <table border="0" cellpadding="0" cellspacing="0" class="drtable1">

                            <tbody><tr>
                            <td align="left" valign="top"><img alt="" src="assets/images/dot.png"></td>
                            <td align="left" class="PublicationName" valign="top"> Journals:</td>
                            <td align="left" valign="middle">Systemic Wangiella dermatitidis infection presenting as liver mass and obstructive cholangiopathy<br>PMID- 25046897<br>Trop Gastroenterol. 2013 Oct-Dec;34(4):277-9. doi: 10.7869/tg.151.<br>
                              <br> Authors: Patra S, Vij M, Kancharla R, Lingappa L, Rela M.</td>
                        </tr>
                    </tbody></table>
                </div>
            </div>
        </div>
      </div>
    </div>
    <!-- <div class="whitecolor-sec faqswrp">
      <div class="container">
        <div class="col-xs-12 col-lg-12">
          <div class="GenraelPediatrics-aco">
            Teaching & Managerial Experience:
          </div>
          <div class="GenraelPediatrics-Infotext tncont">
            <p>
              I used to actively teach medical students in the class rooms as
              well as bed side clinics. I used to do regular audits & used to
              take part in managerial meetings & used to take part in networking
              with other hospitals for shared care services, training &
              research, organizing transplant meetings, transplant
              histopathology meetings, transplant radiology meetings.
            </p>
            <p>
              I have been the recipient of
              <strong
                >National Board of Examinations (NBE) Distinguished Teacher
                Award -2015</strong
              >
              for contribution to the field of medical education in the key area
              of Pediatric Gastroenterology.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="whitecolor-sec faqswrp">
      <div class="container">
        <div class="col-xs-12">
          <div class="GenraelPediatrics-aco">Allied Skills / Attributes:</div>
          <div class="GenraelPediatrics-Infotext tncont">
            <ul class="CourseAndCcon">
              <li>Entrepreneurial and effective team leadership</li>
              <li>Conflict resolution, management</li>
              <li>
                Listening and networking skills with ability to articulately
                communicate with any audience
              </li>
              <li>Project planning & implementation skills.</li>
            </ul>

            <p>
              In 1999, I returned to India and established Rainbow Children’s
              Hospital – it was then a 50-bedded facility with the objective of
              developing intensive care services for the new born and
              establishing other paediatric sub-specialties.
            </p>
            <p>
              In 2000, established the Paediatric Liver Transplant program at
              Global Hospitals, Hyderabad.
            </p>
            <p>
              Currently practicing Paediatric Gastroenterology, Hepatology &
              liver transplantation at Rainbow Children’s Hospital.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="whitecolor-sec faqswrp">
      <div class="container">
        <div class="col-xs-12">
          <div class="GenraelPediatrics-aco">COURSES AND CONFERENCES</div>
          <div class="GenraelPediatrics-Infotext tncont">
            <ul class="CourseAndCcon">
              <li>
                <span>1998 :</span> British Paediatric Association National
                conference York – Floor Presentation on outcome of vertically
                transmitted hepatitis B infection: Clinical Presentation &
                outcomes in Infants
              </li>
              <li>
                <span>1998 :</span> Paediatric Gastroenterology conference: Time
                of Diagnosis & Outcomes Acute Liver Failure, Bangalore.
              </li>
              <li>
                <span>2004 :</span> Platform: Entra formulas indications IAP
                National conference Bangalore.
              </li>
              <li>
                <span>2006 : </span> Indications for Liver transplantation in
                Children Pune
              </li>
              <li>
                <span>2009 :</span> Paediatric Gastroenterology National
                conference: Auto immune liver diseases – Diagnosis & Treatment
                Mumbai
              </li>
              <li>
                <span>2012 :</span> Paediatric Gastroenterology conference Auto
                immune sclerosing cholangitis: Diagnosis & Treatment modalities
                Delhi
              </li>
              <li>
                <span>2013 :</span> Biliary atresia: Timing of kasai
                portoenterostomy & outcomes Master class in Liver diseases,
                Chennai
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="whitecolor-sec faqswrp">
      <div class="container">
        <div class="col-xs-12">
          <div class="GenraelPediatrics-aco">PUBLICATIONS</div>
          <div class="GenraelPediatrics-Infotext tncont">
            <div class="tablecontres">
              <table
                border="0"
                cellspacing="0"
                cellpadding="0"
                class="drtable1"
              >
                <tr>
                  <td width="1%" align="left" valign="top">
                    <img src="assets/images/dot.png" alt="" />
                  </td>
                  <td
                    width="8%"
                    align="left"
                    valign="top"
                    class="PublicationName"
                  >
                    Text Books:
                  </td>
                  <td width="91%" align="left" valign="middle">
                    Recent advances in Pediatrics, Pediatric Gastroenterology,
                    Hepatology &amp; nutrition<br />
                    Acute Liver Failure: P Bachina, Ramesh Kancharla<br />
                    Nutrition in Pediatric Liver Diseases<br />
                    Authors: N Shanmugam, Brindavani, Ramesh K<br />
                    Editor: Suraj Gupte, JP Medical Ltd Jun 2013
                  </td>
                </tr>
                <tr>
                  <td align="left" valign="top">&nbsp;</td>
                  <td align="left" valign="top">&nbsp;</td>
                  <td align="left" valign="middle">&nbsp;</td>
                </tr>
                <tr>
                  <td align="left" valign="top">
                    <img src="assets/images/dot.png" alt="" />
                  </td>
                  <td align="left" valign="top" class="PublicationName">
                    Journals:
                  </td>
                  <td align="left" valign="middle">
                    Tropical Gastroenterol 20134 Oct – Dec: 34(4): 277-9<br />
                    Systematic Wangiella dermatitidis infection presenting as
                    liver mass and obstructive cholangiopathy<br />
                    Envisaged need of the children’s hospital worked hard &amp;
                    made it successful &amp; were instrumental in pushing other
                    groups to start with paediatric hospital<br />
                    Authors: N Shanmugam, Ramesh Kancharla
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </section>

  <footer-nav-mini [links]="links"></footer-nav-mini>
  <find-a-doctors-nav></find-a-doctors-nav>
  <footer-subscription></footer-subscription>
  <footer class="footerNavwrp">
    <footer-nav></footer-nav>
  </footer>
  <footer-copyright-nav></footer-copyright-nav>
</div>
