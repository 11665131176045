import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  ViewChildren,
  QueryList,
  HostListener,
} from '@angular/core';
import { isPlatformBrowser, DatePipe } from '@angular/common';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL } from '../services/constants';
import { dateFormatPipe } from '../home/dateFormatPipe';
import { Meta, Title } from '@angular/platform-browser';
declare let $: any;
declare let initHome: any;
declare let initAllPage: any;

@Component({
  selector: 'news',
  templateUrl: './news.component.html',
  providers: [DatePipe],
})
export class NewsComponent implements OnDestroy, OnInit, AfterViewInit {
  constructor(
    private api: ApiService,
    public metaTitle: Title,
    public meta: Meta
  ) {
    if (this.api.isBrowser) {
      // some of these will be required in near future
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/datepickr.min.js');
      require('../../assets/js/slick.min.js');
    }
  }

  public news: any[] = [];
  public filtered_news: any[] = [];
  public title: any = '';
  public subtitle: any = '';
  public description: any = '';
  public imgsrc: any = '';
  public brief_facilities_description: any = '';
  public brief_facilities_image: any = '';
  public imgBasePath = '';
  public hidden = 'hidden';
  public logo = 'logo';
  public bread_crumb_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'About Us', link: '/about-us', class: '' },
    { label: 'News', link: '', class: '' },
  ];

  public links: any = [];
  public activePage: string = 'news';
  public selectMonth: any = '';

  ngOnInit() {
    this.api
      .getAboutSubFooterDetails()
      .map((res) => res)
      .subscribe((data) => {
        this.links = data;
      });
    this.api.getPageDescription('news').subscribe(
      (data) => {
        this.title = data.page_descriptions[0].title;
        this.subtitle = data.page_descriptions[0].subtitle;
        this.description = data.page_descriptions[0].description;
        this.imgsrc = data.page_descriptions[0].image;
        this.imgBasePath = IMG_BASE_URL;
        if (data) {
          //console.log(data);
          this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
          this.meta.updateTag({
            name: 'keywords',
            content: data.page_descriptions[0].meta_keyword,
          });
          this.meta.updateTag({
            name: 'description',
            content: data.page_descriptions[0].meta_description,
          });
          this.meta.addTags([
            {
              property: 'og:title',
              content: data.page_descriptions[0].meta_title,
            },
            {
              property: 'og:description',
              content: data.page_descriptions[0].meta_description,
            },
            {
              property: 'og:keywords',
              content: data.page_descriptions[0].meta_keyword,
            },
          ]);
        }
      },
      (error) => {
        console.log(error);
      }
    );

    this.api.getNewsEvents('news').subscribe(
      (data) => {
        this.news = data.home_events;
        this.filtered_news = data.home_events;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  @HostListener('window:load', [])
  onWindowLoad() {
    $('.news_datepickr').Zebra_DatePicker({
      format: 'm/Y',
      default_position: 'below',
      offset: [-200, 26],
      onSelect: function (date) {
        this.selectMonth = date;
        $('#selectMonthYear').val(date);
      },
    });
  }

  filterNews() {
    let dateFormatPipeFilter = new dateFormatPipe();
    this.selectMonth = (<HTMLInputElement>(
      document.getElementById('selectMonthYear')
    )).value;
    if (this.selectMonth == '') {
      this.filtered_news = this.news;
    } else {
      this.filtered_news = this.news;
      this.filtered_news = this.filtered_news.filter(
        (news) =>
          dateFormatPipeFilter.transform(news.event_date) == this.selectMonth
      );
    }
  }

  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
    }
  }

  ngOnDestroy() {}
}
