<section #centerMap  class="gmapwrp">
    <!--<div id="cd-zoom-in"></div>
        <div id="cd-zoom-out"></div>-->
	<div class="sidelocdetwrp">
		<div class="gmheader">
        	<div class="box">
            	<select name="textMap" id="textMap"  [(ngModel)]="selectCenter" class="select" (change)="filterCenter($event)">
                    <option value="0" [selected]="selectCenter == 0">Select location</option>
                    <ng-template ngFor let-c [ngForOf]="centerDropDown" let-i="index">
                        <optgroup *ngIf="c.area.length > 0" label="{{c.city}}">
                            <option *ngFor="let branch of c.area; let ci= index" [value]="branch.slug" [selected]="ci == 0"> {{branch.center_name_alias}}</option> 
                        </optgroup>
                    </ng-template>
                    <!-- <option *ngFor="let center of centerList; let i=index;">{{center.center_name_alias}}</option>	 -->
                </select>  
                <span id="selectcity" class="select-span"></span>
            </div>
            <!--<div class="box">
            	<input type="text" name="txtMapsearch" class="msearch" />
                <input type="submit" name="txtMsubmit" class="mssubmit" value="" />
            </div>-->
        </div>
        <div class="mimgrolatedwrp">
        	<div class="mimgl">
            	<a *ngIf="virtual_tour_link" class="fancybox-effects-a rolink" target="_blank" [href]="virtual_tour_link" data-fancybox-group="gallery" title="Rainbow Hospital">
                    <img src="assets/images/icon-360.png" alt="" />
                </a>
            	<img *ngIf="centreImage" src="{{imgBasePath}}{{centreImage}}" alt="{{centreName}}" />
                <img *ngIf="!centreImage" src="assets/images/mimg.jpg">
            </div>
        </div>
        <div class="mdrwrp">
        	<div class="mtitle"><p>{{centreName}}</p></div>
            <div class="icondr"><a  href="https://www.google.com/maps?saddr=My+Location&daddr={{centreLatLng}}" target="_blank"><img src="assets/images/icon_dir.png" alt="" /></a></div>
        </div>
        <div class="mdetailwrp">
        	<ul>
            	<li><p [innerHTML]="centreAddress"></p></li>
                <li class="phone"><strong>{{centreTelephone}}</strong></li>
                <li class="mail"><a href="mailto:info@rainbowhospitals.in">info@rainbowhospitals.in</a></li>
                <div class="clearfix"></div>
                <li class="view-map"><a href="javascript:void()">view-map</a></li>
            </ul>
        </div>	
    </div>
    <div class="gmap  mapshow-mob" id="google-container">

    </div>
</section><!-- End Google Map -->