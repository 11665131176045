import {
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  HostListener,
} from '@angular/core';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL } from '../services/constants';
import { Meta, Title } from '@angular/platform-browser';
declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
declare let initAboutTabs: any;

@Component({
  selector: 'academic-Articles',
  templateUrl: './articles.component.html',
})
export class AcademicArticlesComponent
  implements OnDestroy, OnInit, AfterViewInit
{
  constructor(
    private api: ApiService,
    public metaTitle: Title,
    public meta: Meta
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
    }
  }

  public services: any[] = [];
  public brief_facilities: any[] = [];
  public title: any = '';
  public subtitle: any = '';
  public description: any = '';
  public imgsrc: any = '';
  public imgBasePath = '';
  public hidden = 'hidden';
  public logo = 'logo';
  public aArticles_events: any;
  public aArticlesAllEvents: any;
  public aArticlesResult: boolean = false;
  public aArticles: any;

  public specilityMasterList = [
    {
      id: 1,
      slug: 'child-care',
      name: 'Child Care',
    },
    {
      id: 2,
      slug: 'women-care',
      name: 'Women Health',
    },
    {
      id: 3,
      slug: 'fertility-care',
      name: 'Fertility Care',
    },
  ];

  public bread_crumb_links: any = [
    { label: 'Home', link: '/', class: '', slug: 'home' },
    { label: 'Others', link: '', class: '', slug: 'others' },
    {
      label: 'Publications',
      link: '/other/academic-article',
      class: '',
      slug: 'academic-article',
    },
  ];

  //public links: any = [];
  public links: any = [
    {
      label: 'Publications',
      link: '/other/academic-article',
      class: 'active',
      slug: 'academic-article',
    },
    {
      label: 'Webinars',
      link: '/other/academic-webinar',
      class: '',
      slug: 'academic-webinar',
    },
    {
      label: 'Programs',
      link: '/other/academic-programs',
      class: '',
      slug: 'academic-programs',
    },
  ];

  public activePage: string = 'academic-article';

  //@ViewChildren('atabs') atabsThings: QueryList<any>;

  ngOnInit() {
    this.api.getPageDescription('academic-article').subscribe(
      (data) => {
        (this.title = data.page_descriptions[0].title),
          (this.subtitle = data.page_descriptions[0].subtitle),
          (this.description = data.page_descriptions[0].description),
          (this.imgsrc = data.page_descriptions[0].image),
          (this.imgBasePath = IMG_BASE_URL);
        if (data) {
          //console.log(data);
          this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
          this.meta.updateTag({
            name: 'keywords',
            content: data.page_descriptions[0].meta_keyword,
          });
          this.meta.updateTag({
            name: 'description',
            content: data.page_descriptions[0].meta_description,
          });
          this.meta.addTags([
            {
              property: 'og:title',
              content: data.page_descriptions[0].meta_title,
            },
            {
              property: 'og:description',
              content: data.page_descriptions[0].meta_description,
            },
            {
              property: 'og:keywords',
              content: data.page_descriptions[0].meta_keyword,
            },
          ]);
        }
      },
      (error) => {
        console.log(error);
      }
    );
    /*this.api.getOtherSubFooterDetails().map(res => res).subscribe((data)=>{
			this.links =  data;
		})*/
    //this.getAcademicArticles();
    this.getContentHubListingAcademicArticles(3);
  }

  onChangeSpecialty(e) {
    //console.log(e.target.value);
    if (e.target.value == 0) {
      this.aArticles = this.aArticlesAllEvents;
    } else {
      let filterArray = [];
      this.aArticlesAllEvents.forEach((element) => {
        element.specialitylist.forEach((element2) => {
          if (element2.speciality == e.target.value) {
            filterArray.push(element);
          }
        });
      });
      this.aArticles = filterArray;
      //console.log('aArticles filterArray: ', this.aArticles);
    }
    if (
      this.aArticles == '' ||
      this.aArticles == undefined ||
      this.aArticles.length < 0
    ) {
      this.aArticlesResult = true;
    } else {
      this.aArticlesResult = false;
    }
    this.aArticles.forEach((element) => {
      element.event_date = new Date(element.event_date);
    });
    this.aArticles = this.aArticles.sort((a, b) => b.event_date - a.event_date);
  }

  getAcademicArticles() {
    this.api.getAcademicArticles().subscribe((data) => {
      //console.log('getAcademicArticles: ', data);
      this.aArticles_events = data;
      this.aArticlesAllEvents = data;
      if (
        this.aArticles_events == '' ||
        this.aArticles_events == undefined ||
        this.aArticles_events.length < 0
      ) {
        this.aArticlesResult = true;
      } else {
        this.aArticlesResult = false;
      }
      this.aArticles_events.forEach((element) => {
        element.event_date = new Date(element.event_date);
      });
      this.aArticles_events = this.aArticles_events.sort(
        (a, b) => b.event_date - a.event_date
      );
    });
  }
  getContentHubListingAcademicArticles(id) {
    this.api.getContentHubListingAcademicArticles(id).subscribe((data) => {
      //console.log('getContentHubListingAcademicArticles: ', data);

      let aData: any = [];
      data.forEach((element) => {
        element.slug = element.contentheading.replace(/ /gi, '-');
        aData.push(element);
      });
      this.aArticles = aData;
      this.aArticlesAllEvents = aData;
    });
  }

  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
    }
  }

  @HostListener('window:load', [])
  onWindowLoad() {}

  ngOnDestroy() {}
}
