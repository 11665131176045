<div class="container-fluid">
  <div class="background">
    <img
      src="assets/images/bg-press-clip.png"
      data-t="bg_01_00.jpg"
      data-d="bg-press-clip.png"
      class="bgImg bg0 active"
    />
  </div>
  <div class="loder"></div>
  <sidenav></sidenav>
  <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
  <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
  <section class="page">
    <div
      class="row mediaSection pageheader"
      style="padding-bottom: 0px !important"
    >
      <div class="container">
        <div class="col-xs-12 col-sm-7 col-lg-7 abannimgwrp">
          <div class="aboutbannimg">
            <img
              src="{{ imgBasePath }}{{ imgsrc }}"
              class="img-responsive"
              alt="{{ title }}"
              title="{{ title }}"
            />
          </div>
        </div>
        <div class="col-xs-12 col-sm-5 col-lg-5">
          <div class="ptitle yellow">
            <h1>{{ title }}</h1>
            <h3>{{ subtitle }}</h3>
          </div>
        </div>
        <div class="col-xs-12">
          <p [innerHTML]="description"></p>
        </div>
        <div class="col-xs-12 PressReleases NewCSRWapper">
          <div class="row">
            <div class="visitor-head">
              <div class="visitor-icon">
                <img src="assets/images/CSR-activitiesicon.jpg" alt="" />
              </div>
              <h2 class="fontsize">CSR ACTIVITIES</h2>
            </div>
          </div>
          <div class="row">
            <div class="CSRACTIVITIESSlider">
              <div class="iterm" *ngFor="let csr of csrList">
                <div class="box-wapper">
                  <div class="img">
                    <img src="{{ imgBasePath }}{{ csr.image }}" alt="" />
                  </div>
                  <div class="text">
                    <h2>{{ csr.title }}</h2>
                    <div [innerHTML]="csr.short_description"></div>
                    <a
                      href="about-us/csr/{{ csr.slug }}"
                      class="knowmore2 active"
                      ><span>Read more</span></a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mediaSection pageheader">
      <div class="container">
        <div class="col-xs-12 PressReleases NewCSRWapper">
          <div class="row">
            <div class="CollaborateWapper">
              <p>
                Want to join us or have ideas to build a healthier society &
                environment?
              </p>
              <a
                href="javascript:void(0)"
                class="btn-Collaborate"
                id="collaboratewithus"
                >Collaborate with us</a
              >
            </div>
            <div class="PageText">
              <p class="t1">
                Our CSR activities go in line with our vision and mission. We
                would plan and act accordingly in achieving all or any of the
                below mentioned activities along with the activities as
                mentioned in Schedule VII of the Companies Act, 2013 as amended
                from time to time either on our own strength or by seeking the
                assistance of other organizations depending on the activity,
                cost involved, personnel strength and as such. We will endeavor
                to discharge our corporate social responsibility through the
                following set of activities:
              </p>
            </div>
          </div>
          <div class="row">
            <ul class="newatabs slider">
              <li class="active">
                <a href="javascript:void()" data-id="data1">
                  <h2>
                    Support to government sponsored rural health care centers
                  </h2>
                </a>
              </li>
              <li>
                <a href="javascript:void()" data-id="data2">
                  <h2>Basic life support training</h2>
                </a>
              </li>
              <li>
                <a href="javascript:void()" data-id="data3">
                  <h2>Youth Counseling Programmes</h2>
                </a>
              </li>
              <li>
                <a href="javascript:void()" data-id="data3">
                  <h2>Sports Promoting Activities</h2>
                </a>
              </li>
              <li>
                <a href="javascript:void()" data-id="data3">
                  <h2>Education Promotion Activities</h2>
                </a>
              </li>
              <li>
                <a href="javascript:void()" data-id="data3">
                  <h2>Other Potential Activities</h2>
                </a>
              </li>
            </ul>
            <div class="newatabscontent slider">
              <div class="atabsdata">
                <ul class="pontBox">
                  <li>
                    Provision of training to nurses so they can handle basic
                    cases and thereby substitute for GPs where appropriate
                  </li>
                  <li>
                    Provision of training to doctors so that they are able to
                    handle routine CSec operations at remote and rural locations
                  </li>
                  <li>
                    Provision of equipment such as delivery beds, surgical
                    equipment etc
                  </li>
                  <li>
                    Supporting the NGOs to reduce the infant mortality rates.
                  </li>
                </ul>
              </div>
              <div class="atabsdata">
                <ul class="pontBox">
                  <li>
                    Develop and implement training of trainers programmes for
                    schools and village level organizations on basic life
                    support
                  </li>
                  <li>
                    Develop and implement training of trainers programmes for
                    corporate entities to enable them to further disseminate the
                    programmes as part of their CSR initiatives
                  </li>
                </ul>
              </div>
              <div class="atabsdata">
                <ul class="pontBox">
                  <li>
                    Provide stress management counseling for students at
                    educational institutions to enable them to handle the
                    studies and exams without stress or fear of failure.
                  </li>
                </ul>
              </div>
              <div class="atabsdata">
                <ul class="pontBox">
                  <li>
                    Supporting sportspersons to participate in state, national
                    and international tournaments and other sports events.
                  </li>
                  <li>
                    Offering scholarships and sports equipment to deserving
                    sportspersons.
                  </li>
                  <li>Preserving and promoting indigenous sports.</li>
                  <li>Organizing leadership and motivational camps.</li>
                </ul>
              </div>
              <div class="atabsdata">
                <ul class="pontBox">
                  <li>
                    To train students from underprivileged backgrounds in its
                    various development centers.
                  </li>
                  <li>
                    Provide various grants and donations to schools, colleges
                    and any other educational institution in India for improving
                    infrastructure and other facilities.
                  </li>
                  <li>Promoting vocational skills training</li>
                  <li>
                    Promoting education by taking various other activities,
                    which may add benefits to education sector.
                  </li>
                </ul>
              </div>
              <div class="atabsdata">
                <ul class="pontBox">
                  <li>
                    Educate various sections of society on the hazardous
                    diseases and various preventive measures.
                  </li>
                  <li>
                    Activities that enhance public health, hygiene, cleanliness
                    etc. especially through empowering women in rural areas by
                    creating self-help groups or other means and skill
                    development among youth
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6">
              <div class="csrMissonblueBox">
                <div class="ImgBox">
                  <img src="assets/images/new-icon-csr.png" alt="" />
                </div>
                <div class="textwapper">
                  <h2>CSR VISION</h2>
                  <p>
                    Our CSR Vision is to contribute to the
                    socio-economic-environmental development of the society
                  </p>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6">
              <div class="csrMissonblueBox">
                <div class="ImgBox">
                  <img src="assets/images/new-icon-csrmisson.png" alt="" />
                </div>
                <div class="textwapper">
                  <h2>CSR MISSION</h2>
                  <p>
                    Our CSR Mission is to contribute to sustainable development
                    by undertaking activities that primarily draw upon our core
                    strengths and abilities as a health care provider.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12 NewCSRWapper">
        <div class="AccordionWrp">
          <div class="AccordionCont">
            <div class="visitor-head">
              <div class="container">
                <div class="visitor-icon">
                  <img src="assets/images/icon-csr-commitee.png" alt="" />
                </div>
                <h2 class="fontsize">CSR COMMITTEE</h2>
              </div>
            </div>
            <div class="AccordionBody">
              <div class="container">
                <div class="PageText">
                  <h3>Composition of the CSR Committee</h3>
                  <p>
                    The composition of the CSR committee of the board is as
                    under:
                  </p>
                  <ul class="CSRcommiteeBox">
                    <li>
                      <a
                        href="https://www.rainbowhospitals.in/about-us/management"
                        target="_blank"
                      >
                        <div class="imagebox">
                          <img src="assets/images/img2.jpg" alt="" />
                        </div>
                        <div class="textbox">
                          <p class="p1">Prof. Anil Dhawan</p>
                          <p class="p2">Independent Director</p>
                          <p class="p3">CSR Committee: Chairman</p>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.rainbowhospitals.in/md-profile"
                        target="_blank"
                      >
                        <div class="imagebox">
                          <img src="assets/images/img.jpg" alt="" />
                        </div>
                        <div class="textbox">
                          <p class="p1">Dr. Ramesh Kancharla</p>
                          <p class="p2">Managing Director</p>
                          <p class="p3">CSR Committee: Member</p>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.rainbowhospitals.in/about-us/management"
                        target="_blank"
                      >
                        <div class="imagebox">
                          <img src="assets/images/dinesh.jpg" alt="" />
                        </div>
                        <div class="textbox">
                          <p class="p1">Dr. Dinesh Kumar Chirla</p>
                          <p class="p2">Whole-time Director </p>
                          <p class="p3">CSR Committee: Member</p>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a
                      href="https://www.rainbowhospitals.in/about-us/management"
                      target="_blank"
                      >
                        <div class="imagebox">
                          <img src="https://backend.rainbowhospitals.in//uploads/741cd986bd588f90c094179c5e4bc90a.jpg" alt="" />
                        </div>
                        <div class="textbox">
                          <p class="p1">Ms. Sundari R. Pisupati</p>
                          <p class="p2">Independent Director </p>
                          <p class="p3">CSR Committee: Member</p>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.rainbowhospitals.in/about-us/management"
                        target="_blank"
                      >
                        <div class="imagebox">
                          <img src="https://backend.rainbowhospitals.in//uploads/ff8589440b770019e47017bbb4604b50.jpg" alt="" />
                        </div>
                        <div class="textbox">
                          <p class="p1">Mr. Santanu Mukherjee</p>
                          <p class="p2">Independent Director </p>
                          <p class="p3">CSR Committee: Member</p>
                        </div>
                      </a>
                    </li>
                  </ul>
                  <p class="info text-center">
                    The board shall have the power to make any change(s) in the
                    constitution of the committee.
                  </p>
                  <div class="row">
                    <div class="col-xs-12 col-sm-6">
                      <div class="PageText">
                        <p class="t2">Responsibility of the CSR committee:</p>
                        <ul>
                          <li>
                            Formulate and recommend the CSR Policy to the Board
                            for approval. The Committee shall indicate thve
                            projects to be undertaken by the Company as
                            specified in Schedule VII.
                          </li>
                          <li>
                            Monitor the Policy from time to time and recommend
                            changes to the Board.
                          </li>
                          <li>
                            Recommend the amount of expenditure to be incurred
                            on CSR projects.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-xs-12 col-sm-6">
                      <div class="PageText">
                        <p class="t2">Reporting framework:</p>
                        <ul>
                          <li>
                            The Company will monitor progress on CSR projects
                            and CSR spend and report to the top management
                            quarterly and the CSR Committee half yearly.
                          </li>
                          <li>
                            The Company will report CSR performance in its
                            annual report as per the structure and format
                            prescribed in the notified CSR Rules.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="AccordionWrp">
          <div class="AccordionCont">
            <div class="visitor-head">
              <div class="container">
                <div class="visitor-icon">
                  <img src="assets/images/icon-rollandrespos.png" alt="" />
                </div>
                <h2 class="fontsize">ROLES AND RESPONSIBILITIES</h2>
              </div>
            </div>
            <div class="AccordionBody">
              <div class="container">
                <div class="PageText">
                  <p class="t2">
                    The Board of Directors of the Company will be responsible
                    for:
                  </p>
                  <ul>
                    <li>Approval of the CSR policy of the company.</li>
                    <li>
                      Disclosing the content of the policy in its report and
                      place the policy on the company's website in such a manner
                      as prescribed under section 135 of the Companies Act, 2013
                      read with CSR rules.
                    </li>
                    <li>
                      Ensuring that the social projects included in the policy
                      are undertaken by the company.
                    </li>
                    <li>
                      Ensuring that the Company gives preference to the local
                      areas around its operations for spending the amount
                      earmarked for CSR project.
                    </li>
                    <li>
                      Ensuring that it specifies the reasons in its report for
                      not spending the earmarked amount in case the Company
                      fails to spend such amount.
                    </li>
                    <li>
                      Ensuring that the Company spends, in every financial year,
                      at least 2% of the average net profits of the company made
                      during the three immediately preceding financial years in
                      pursuance of the Policy.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="AccordionWrp">
          <div class="AccordionCont">
            <div class="visitor-head">
              <div class="container">
                <div class="visitor-icon">
                  <img src="assets/images/icon-csr-bug.png" alt="" />
                </div>
                <h2 class="fontsize">CSR BUDGET</h2>
              </div>
            </div>
            <div class="AccordionBody">
              <div class="container">
                <div class="PageText">
                  <ul>
                    <li>
                      The Board of Company shall ensure that in each financial
                      year, the Company spends at least 2% of the average net
                      profit made during the immediate three proceeding
                      financial years.
                    </li>
                    <li>
                      The unutilized CSR budget from the 2% of the average net
                      profit will be parked in a CSR Fund (Corpus) created by
                      the company. This Fund would also include any income
                      arising there from and any surplus arising out of CSR
                      activities
                    </li>
                    <li>
                      In case of any surplus arising out of CSR projects the
                      same shall not form part of business profits of the
                      Company
                    </li>
                    <li>
                      The Company may collaborate or pool resources with other
                      companies (its subsidiary companies) to undertake CSR
                      activities.
                    </li>
                  </ul>
                  <p class="t2">OUR IMPLEMENTATION CHANNELS</p>
                  <p class="t1">
                    We sustain to achieve our mission by taking up any of our
                    CSR activities depending on the need. We are well versed to
                    take up some projects on our own and with regard to
                    implementation of CSR activities that are beyond our
                    capacity in terms of monetary contribution or personnel
                    strength, we join our hands with other organizations that
                    are either carrying on the same CSR activity or
                    organizations specially formed to take up such projects. The
                    decision of choosing the channel for implementation of
                    activities is taken by our Board of Directors after
                    considering the recommendations of our CSR committee on case
                    to case basis.
                  </p>
                  <ul>
                    <li>
                      The CSR projects will be implemented in a time-bound
                      manner with clear objectives, plan, targets and robust
                      monitoring and evaluation mechanisms.
                    </li>
                    <li>
                      The Company's ongoing CSR projects will be aligned to the
                      Policy. This Policy builds on the learning's and good
                      practices of the CSR projects initiated by the Company.
                    </li>
                    <li>
                      The Company has set up dedicated teams for implementation
                      of CSR projects. The mode of implementation will include a
                      combination of direct implementation and implementation
                      through trust/foundation/society set up by the Company and
                      partners such as Foundations, Registered Societies,
                      Trusts, etc. The Company will select its partners after
                      appropriate due diligence.
                    </li>
                    <li>
                      The Company will use services of expert agencies,
                      consultancy firms etc. wherever required for carrying out
                      baseline surveys, guidance on project design and
                      implementation, third-party monitoring and evaluations,
                      impact assessment surveys etc.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="AccordionWrp">
          <div class="AccordionCont">
            <div class="visitor-head">
              <div class="container">
                <div class="visitor-icon">
                  <img src="assets/images/icon-csr-moni.png" alt="" />
                </div>
                <h2 class="fontsize">MONITORING</h2>
              </div>
            </div>
            <div class="AccordionBody">
              <div class="container">
                <div class="PageText">
                  <p class="t1">
                    The CSR Committee shall submit its recommendations to the
                    Board in adopting the activity to be undertaken after
                    analyzing various internal sources available with the
                    Company and the external conditions prevailing. The
                    Committee shall also recommend the Board in choosing the
                    implementation channel based on the activity proposed to be
                    undertaken. The Committee shall also present a periodical
                    report to the Board with regard to various activities
                    undertaken, their progress or status, further assistance
                    required in stepping forward etc. The Committee shall also
                    maintain a cordial relation with other organizations that
                    are sharing the CSR activity be it a trust, society or
                    Company and ensure submission of periodical reports by such
                    organizations to the Board.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="AccordionWrp">
          <div class="AccordionCont">
            <div class="visitor-head">
              <div class="container">
                <div class="visitor-icon">
                  <img src="assets/images/icon-csrpolyi.png" alt="" />
                </div>
                <h2 class="fontsize">REVISION OF CSR POLICY</h2>
              </div>
            </div>
            <div class="AccordionBody">
              <div class="container">
                <div class="PageText">
                  <p class="t1">
                    The board may, subject to applicable laws, amend any
                    provision(s) or substitute any of the provision(s) with the
                    new provision(s) or replace the Policy entirely with a new
                    policy, based on the recommendation(s) of CSR Committee. The
                    Board may also establish further rules and procedures, from
                    time to time, to give effect to this Policy.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="AccordionWrp">
                  <div class="AccordionCont ">
                    <div class="visitor-head">
                      <div class="container">
                        <div class="visitor-icon"><img src="assets/images/icon-applicapng.png" alt=""></div>
                        <h2 class=" fontsize">APPLICABILITY</h2>
                      </div>
                    </div>
                    <div class="AccordionBody">
                      <div class="container">
                        <div class="PageText">
                          <p class="t1">Rainbow Children's Medicare Private Ltd. has developed its Corporate Social Responsibility Policy in accordance with section 135 of the Companies Act, 2013 and the rules made there under.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
      </div>
    </div>
  </section>
  <ng-template [ngIf]="links.length > 0">
    <footer-nav-mini
      [links]="links"
      [activePage]="activePage"
    ></footer-nav-mini>
  </ng-template>

  <find-a-doctors-nav></find-a-doctors-nav>
  <footer-subscription></footer-subscription>
  <footer class="footerNavwrp">
    <footer-nav></footer-nav>
  </footer>
  <footer-copyright-nav></footer-copyright-nav>
</div>

<!-- Start Model Popup -->
<div class="capopup" id="ModelFormGetInTouch">
  <div class="capopup-inner">
    <div class="launchpreloader" *ngIf="preloader"></div>
    <a
      class="capopup-close"
      href="javascript:void()"
      (click)="changeShowStatus()"
    >
      <img alt="" src="assets/images/icon-close2.png" />
    </a>
    <h2 style="text-align: center">Collaborate With Us</h2>
    <form id="form" [formGroup]="GetInTouch" (submit)="onSubmit()" novalidate>
      <ul class="formcasn">
        <li>
          <input
            type="text"
            name="name"
            [ngClass]="{
              'has-error':
                !GetInTouch.controls.name.valid &&
                GetInTouch.controls.name.touched
            }"
            placeholder="Individual/Organisation`s Name*"
            formControlName="name"
            required
          />
          <span
            class="alertMsg"
            *ngIf="!GetInTouch.controls.name.valid && submited"
            >required</span
          >
        </li>
        <li>
          <input
            type="text"
            name="mobile"
            [ngClass]="{
              'has-error':
                !GetInTouch.controls.mobile.valid &&
                GetInTouch.controls.mobile.touched
            }"
            placeholder="Contact No"
            formControlName="mobile"
            required
          />
          <span
            class="alertMsg"
            *ngIf="!GetInTouch.controls.mobile.valid && submited"
            >required</span
          >
        </li>
        <li>
          <input
            type="text"
            name="email"
            [ngClass]="{
              'has-error':
                !GetInTouch.controls.email.valid &&
                GetInTouch.controls.email.touched
            }"
            placeholder="Email ID"
            formControlName="email"
            required
          />
          <span
            class="alertMsg"
            *ngIf="!GetInTouch.controls.email.valid && submited"
            >required</span
          >
        </li>
        <li class="singledropdown">
          <div class="selectbox">
            <select
              class="select"
              name="stateName"
              id="slectStateName"
              formControlName="stateName"
              (change)="onChangeState($event)"
            >
              <option value="" selected>State</option>
              <option
                *ngFor="let state of stateList; let i = index"
                [value]="state.name"
              >
                {{ state.name }}
              </option>
            </select>
            <span class="select-span"></span>
          </div>
          <span
            class="alertMsg"
            *ngIf="!GetInTouch.controls.stateName.valid && submited"
            >required
          </span>
        </li>
        <li class="singledropdown">
          <div class="selectbox">
            <select
              class="select"
              name="cityName"
              id="selectCityName"
              formControlName="cityName"
            >
              <option value="" selected>City</option>
              <option
                *ngFor="let city of cityList; let i = index"
                [value]="city.name"
              >
                {{ city.name }}
              </option>
            </select>
            <span class="select-span" id="cityName"></span>
          </div>
          <span
            class="alertMsg"
            *ngIf="!GetInTouch.controls.cityName.valid && submited"
            >required
          </span>
        </li>
        <li>
          <input
            type="text"
            name="message"
            placeholder="Leave a Massage"
            formControlName="message"
          />
        </li>
        <li>
          <div class="submitbtn">
            <input
              class="casubmit"
              id="txtSubmit"
              name="txtSubmit"
              type="submit"
              value="Submit"
              style="float: none"
            />
          </div>
        </li>
      </ul>
    </form>
  </div>
</div>
<!-- End Model Popup -->
<div class="alertMG alSuccess" id="success_alert" *ngIf="alertMG">
  <button type="button" class="alertClose" data-dismiss="alertMG">x</button>
  <h3>Thank you for Collaborate With Us.</h3>
</div>
