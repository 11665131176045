<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <section class="page pageheader">
        <div class="container">

            <div class="col-xs-6 col-sm-6 col-lg-6 abannimgwrp">
                <div class="otherbannimg"><img src="{{imgBasePath}}{{imgsrc}}" alt="{{title}}" title="{{title}}" class="img-responsive" /></div>
            </div>
            <div class="col-xs-6 col-sm-6 col-lg-6">
                <div class="ptitle">
                    <h1 class="textgradient">{{title}}</h1>
                    <h3>{{subtitle}}</h3>
                </div>
            </div>

            <div class="col-xs-6 col-sm-6 col-lg-6 mcont100">
                <div class="childbirthfmwrp">
                    <ul class="childblst">
                        <li>
                            <div class="findoswrp insurance">
                                <select class="select" name="txtChildBirth" [(ngModel)]="centrename" (change)="changeCentre()">
                        <option value="0" [selected]="centrename == 0">Select Centre</option>
                        <option value="" *ngFor="let center of centerList" [value]="center.id">
                            {{center.center_name_alias}}
                        </option>
                  </select>
                                <span class="select-span"></span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <section #insurance class="aboutsectin2 wcuswrp" *ngIf="centerInsuranceList.length > 0">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-lg-12">
                    <div class="subtitle">
                        <h3>Rainbow Hospitals @{{filteredCenter}}</h3>
                    </div>
                    <ul data-class="atabs" class="atabs slider">
                        <li *ngFor="let insurance of centerInsuranceList; let i = index;" (click)="showSlick(i)" [ngClass]="{'active': i==0}">
                            <a href="javascript:;" data-id="data{{i}}">
                                <div class="aiconwrp"><img src="{{imgBasePath}}{{insurance.image}}" alt="" /></div>
                                <h2>{{insurance.title}}</h2>
                            </a>
                        </li>
                    </ul>
                    <div data-class="atabscontent" class="atabscontent slider">
                        <ng-template ngFor let-detail [ngForOf]="centerInsuranceList" let-i='index'>
                            <div data-class="atabsdata" class="atabsdata" [ngClass]="{'active':i == 0}">
                                <p [innerHTML]="detail.description"></p>
                                <div class="row aco-wapper">
                                    <ng-template ngFor let-insuranceDetail let-si='index' [ngForOf]="detail.insurance">
                                        <div class="DoctorDesk-aco" [ngClass]="{'sel':si == 0}" data-class="DoctorDesk-aco">{{insuranceDetail.title}}</div>
                                        <div [innerHTML]="insuranceDetail.description" class="infotext"></div>
                                    </ng-template>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>>
</div>