<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <section class="page wtwedo">
        <div class="row">
            <div class="col-md-5 col-xs-5 col-lg-4">
                <div class="circuleframe">
                    <img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" />
                </div>
                <!-- End Circle -->
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7">
                <div class="pagetitle">
                    <h1 class="textgradient">{{title}} </h1>
                    <div class="underline">
                        <img src="assets/images/underline.png" alt="" />
                    </div>

                </div>
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7 mcontentview">
                <p [innerHTML]="description"></p>
                <!-- <div class="dfindwrp">
                <ul class="findtstwrp">
                    <li>
                        <div class="finddwrp2">
                        	<div class="findbox">
                                <select class="select" name="txtDLocation">
                                    <option value="Find Testimonials by Specialty" selected>Find Testimonials by Specialty</option>
                                    <option *ngFor="let specility of filterdspecilityList" [value]="specility.id">{{specility.title}}</option>
                                </select>
                                <span id="selectDlocation" class="select-span"></span>
                            </div>
                        </div>
                        <div class="gobtn">
                            <input type="submit" name="txtSearch" value="GO" />
                        </div>
                    </li>
                    <li>
                        <div class="finddwrp3">
                            <div class="findbox">
                                <select class="select" name="txtTArchive">
                                    <option value="Testimonials Archive" selected>Testimonials Archive</option>
                                    <option value="2010">2010</option>
                                    <option value="2011">2011</option>
                                    <option value="2012">2012</option>
                                    <option value="2013">2013</option>
                                    <option value="2014">2014</option>
                                    <option value="2015">2015</option>
                                    <option value="2016">2016</option>
                                    <option value="2017">2017</option>
                                </select>
                                <span id="selectTArchive" class="select-span"></span>
                            </div>
                        </div>
                        <div class="gobtn">
                            <input type="submit" name="txtSearch" value="GO" />
                        </div>
                    </li>
                </ul>
            </div> -->
            </div>
        </div>
    </section>
    <section class="ddetailstimet">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-xs-12 col-lg-12">
                    <ul class="testdList">
                        <li *ngFor="let story  of testimonials; let i = index; odd as isOdd; even as isEven;">

                            <div *ngIf="isEven">
                                <div class="ssthumbwrp eqhlist col-xs-12 col-sm-3 border-right" myMatchHeight="eqhlist">

                                    <div class="thumb" *ngIf="story.youtube">
                                        <a data-fancybox [href]='story.youtube' class="playvid">
                                            <span></span>
                                            <img src="{{imgBasePath}}{{story.thumb}}" alt=" " />
                                        </a>
                                    </div>
                                    <div class="thumb" *ngIf="!story.youtube">
                                        <img src="{{imgBasePath}}{{story.thumb}}" alt=" " />
                                    </div>

                                    <ul>
                                        <li>
                                            <div class="txtWatchV" *ngIf="story.youtube">
                                                <p>Watch This video <img src="{{imgBasePath}}{{story.icon}}" alt=" " /></p>
                                            </div>
                                            <div class="txtName" *ngIf="story.youtube">
                                                <h3 class="textgradient">{{story.userTitle}}<span>{{story.userName}} </span></h3>
                                                <h4 *ngIf="story.city">( {{story.city}} )</h4>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="ssdetailswrp eqhlist col-xs-12 col-sm-9" myMatchHeight="eqhlist">
                                    <div class="ssicon"><img src="{{imgBasePath}}{{story.icon}}" alt="" /></div>
                                    <ul>
                                        <li>
                                            <div class="txtComments">
                                                <p [innerHTML]="story.comment"></p>
                                            </div>
                                            <div class="txtName">

                                                <h3 class="textgradient">{{story.userTitle}}<span>{{story.userName}} </span></h3>
                                                <h4 *ngIf="story.city">( {{story.city}} )</h4>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div *ngIf="isOdd">
                                <div class="ssdetailswrp2 eqhlist2 col-xs-12 col-sm-9 border-right" myMatchHeight="eqhlist2">
                                    <div class="ssicon"><img src="{{imgBasePath}}{{story.icon}}" alt="" /></div>
                                    <ul>
                                        <li>
                                            <div class="txtComments">
                                                <p [innerHTML]="story.comment"></p>
                                            </div>
                                            <div class="txtName">

                                                <h3 class="textgradient">{{story.userTitle}}<span>{{story.userName}} </span></h3>
                                                <h4 *ngIf="story.city">( {{story.city}} )</h4>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="ssthumbwrp margr eqhlist2 col-xs-12 col-sm-3" myMatchHeight="eqhlist2">
                                    <div class="thumb" *ngIf="story.youtube">
                                        <a data-fancybox [href]='story.youtube' class="fancybox playvid">
                                            <span></span>
                                            <img src="{{imgBasePath}}{{story.thumb}}" alt=" " />
                                        </a>
                                    </div>
                                    <div class="thumb" *ngIf="!story.youtube">
                                        <img src="{{imgBasePath}}{{story.thumb}}" alt=" " />
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>