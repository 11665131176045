import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  ViewChildren,
  QueryList,
  HostListener,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL } from '../services/constants';
import { dateFormatPipe } from '../home/dateFormatPipe';

import { Meta, Title } from '@angular/platform-browser';
declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
// declare let initAboutTabs:any;

@Component({
  selector: 'media-press-clips',
  templateUrl: './media-press-clips.html',
})
export class MediaPressClipsComponent
  implements OnDestroy, OnInit, AfterViewInit
{
  constructor(
    private api: ApiService,
    public metaTitle: Title,
    public meta: Meta
  ) {
    if (this.api.isBrowser) {
      // some of these will be required in near future
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/fancybox/jquery.fancybox.min.js');
      require('../../assets/js/datepickr.min.js');
      require('../../assets/js/slick.min.js');
    }
  }

  public filterPC: any[];
  public filterArray: any = [];
  public filtered_records: any[];
  public filterLabel: any;
  public filterLabelCheck: boolean = false;
  public press_Clips: any[] = [];
  public filtered_record: any[] = [];
  public title: any = '';
  public subtitle: any = '';
  public description: any = '';
  public imgsrc: any = '';
  public brief_facilities_description: any = '';
  public brief_facilities_image: any = '';
  public imgBasePath = '';
  public hidden = 'hidden';
  public logo = 'logo';
  public id: number;
  public bread_crumb_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'About Us', link: '/about-us', class: '' },
    { label: 'Media', link: '/about-us/media', class: '' },
    { label: 'Press Clips', link: '', class: '' },
  ];

  public links: any = [];
  public activePage: string = 'media';
  public selectMonth: any = '';

  // @ViewChildren('abouttabs') things: QueryList<any>;

  ngOnInit() {
    this.api
      .getAboutSubFooterDetails()
      .map((res) => res)
      .subscribe((data) => {
        this.links = data;
      });
    this.api.getPageDescription('press-clips').subscribe(
      (data) => {
        this.title = data.page_descriptions[0].title;
        this.subtitle = data.page_descriptions[0].subtitle;
        this.description = data.page_descriptions[0].description;
        this.imgsrc = data.page_descriptions[0].image;
        this.imgBasePath = IMG_BASE_URL;
        if (data) {
          //console.log(data);
          this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
          this.meta.updateTag({
            name: 'keywords',
            content: data.page_descriptions[0].meta_keyword,
          });
          this.meta.updateTag({
            name: 'description',
            content: data.page_descriptions[0].meta_description,
          });
          this.meta.addTags([
            {
              property: 'og:title',
              content: data.page_descriptions[0].meta_title,
            },
            {
              property: 'og:description',
              content: data.page_descriptions[0].meta_description,
            },
            {
              property: 'og:keywords',
              content: data.page_descriptions[0].meta_keyword,
            },
          ]);
        }
      },
      (error) => {
        console.log(error);
      }
    );

    this.filterPC = [
      {
        id: 'text',
        name: 'text',
        selected: false,
        className: 'style iconText',
      },
      {
        id: 'img',
        name: 'img',
        selected: false,
        className: 'style iconImg',
      },
      {
        id: 'video',
        name: 'video',
        selected: false,
        className: 'style iconVideo',
      },
    ];
    this.api.getPressClips().subscribe(
      (data) => {
        this.press_Clips = data.press_clips;
        this.filtered_record = data.press_clips;
        //console.log('press_Clips', this.press_Clips);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  @HostListener('window:load', [])
  onWindowLoad() {
    $('.datepickr').Zebra_DatePicker({
      format: 'm/Y',
      default_position: 'below',
      offset: [-200, 26],
      onSelect: function (date) {
        this.selectMonth = date;
        $('#selectMonthYear').val(date);
      },
    });
  }

  filterRecord() {
    let dateFormatPipeFilter = new dateFormatPipe();
    this.selectMonth = (<HTMLInputElement>(
      document.getElementById('selectMonthYear')
    )).value;
    if (this.selectMonth == '') {
      //this.filtered_record = this.press_Clips;
      //console.log('filterArray', this.filterArray);
      this.filtered_record = [];
      for (let filterA of this.filterArray) {
        this.press_Clips.forEach((element) => {
          if (element.mfilter === filterA) {
            this.filtered_record.push(element);
          }
        });
      }
      if (this.filterArray.length == 0) {
        this.filtered_record = this.press_Clips;
      }
    } else {
      if (this.filterArray.length == 0) {
        this.filtered_record = this.press_Clips;
        this.filtered_record = this.filtered_record.filter(
          (news) =>
            dateFormatPipeFilter.transform(news.date) == this.selectMonth
        );
      } else {
        this.filtered_record = [];
        for (let filterA of this.filterArray) {
          this.press_Clips.forEach((element) => {
            if (element.mfilter === filterA) {
              this.filtered_record.push(element);
            }
          });
          this.filtered_record = this.filtered_record.filter(
            (news) =>
              dateFormatPipeFilter.transform(news.date) == this.selectMonth
          );
        }
      }
    }
  }

  shorting(e) {
    let dateFormatPipeFilter = new dateFormatPipe();
    this.selectMonth = (<HTMLInputElement>(
      document.getElementById('selectMonthYear')
    )).value;
    var status = e.target.checked;
    var checkedVal = e.target.value;
    //console.log('shorting', status, checkedVal);
    if (status) {
      this.filterArray.push(checkedVal);

      if (this.selectMonth != '') {
        this.filtered_record = [];

        for (let filterA of this.filterArray) {
          this.press_Clips.forEach((element) => {
            if (element.mfilter === filterA) {
              this.filtered_record.push(element);
            }
          });
        }
        this.filtered_record = this.filtered_record.filter(
          (news) =>
            dateFormatPipeFilter.transform(news.date) == this.selectMonth
        );
      } else {
        this.filtered_record = [];
        for (let filterA of this.filterArray) {
          this.press_Clips.forEach((element) => {
            if (element.mfilter === filterA) {
              this.filtered_record.push(element);
            }
          });
        }
      }
    } else {
      for (let filterA of this.filterArray) {
        if (filterA === checkedVal) {
          this.filterArray.splice(this.filterArray.indexOf(filterA), 1);
          break;
        }
      }
      if (this.selectMonth != '') {
        this.filtered_record = [];
        for (let filterA of this.filterArray) {
          this.press_Clips.forEach((element) => {
            if (element.mfilter === filterA) {
              this.filtered_record.push(element);
            }
          });
        }

        if (this.filterArray.length == 0) {
          this.filtered_record = this.press_Clips;
          this.filtered_record = this.filtered_record.filter(
            (news) =>
              dateFormatPipeFilter.transform(news.date) == this.selectMonth
          );
        } else {
          this.filtered_record = this.filtered_record.filter(
            (news) =>
              dateFormatPipeFilter.transform(news.date) == this.selectMonth
          );
        }
      } else {
        this.filtered_record = [];
        for (let filterA of this.filterArray) {
          this.press_Clips.forEach((element) => {
            if (element.mfilter === filterA) {
              this.filtered_record.push(element);
            }
          });
        }
        if (this.filterArray.length == 0) {
          this.press_Clips.forEach((element) => {
            this.filtered_record.push(element);
          });
        }
      }
    }
    let filterLabelArray = [];
    this.filterArray.forEach((element) => {
      if (element == 'text') {
        element = 'Text';
      }
      if (element == 'img') {
        element = 'Image';
      }
      if (element == 'video') {
        element = 'Video';
      }
      filterLabelArray.push(element);
    });
    if (this.filterArray.length > 0) {
      this.filterLabelCheck = true;
    } else {
      this.filterLabelCheck = false;
    }

    this.filterLabel = filterLabelArray.toString();
    //console.log('filterArray', this.filterArray, this.filterArray.length);
    //console.log('filtered_record', this.filtered_record);
  }

  gotoReadMore(news) {
    console.log(news);
  }

  pressClipId(id) {
    localStorage.setItem('pressClipId', id);
  }

  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
    }

    // this.things.changes.subscribe(t => {
    //   // this.ngForRendred();
    //   initAboutTabs();
    // })
  }

  ngOnDestroy() {}
}
