<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>

    <section class="page wtwedo">
        <div class="row">
            <div class="col-md-5 col-xs-5 col-lg-4">
                <div class="circuleframe">
                    <img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" />
                </div>
                <!-- End Circle -->
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7">
                <div class="pagetitle">
                    <h1 class="tgradient">{{title}} </h1>
                    <div class="underline">
                        <img src="assets/images/underline2.png" alt="" />
                    </div>
                    <h3>{{subtitle}}</h3>
                </div>
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7 mcontentview">
                <p [innerHTML]="description"></p>
            </div>
        </div>

    </section>
    <section class="aboutsectin2 awardswapper">
        <div class="container">
            <div class="row nsg_text">
                <div class="col-xs-12 col-lg-12">
                    <h2>MANAGING NAUSEAS & VOMITING DURING PREGNANCY </h2>
                    <ul>
                        <li>Rest, as tiredness can make nausea worse.</li>
                        <li>Drinking little and often rather than in large amounts, as this may help to prevent vomiting.</li>
                        <li>Eat small, frequent meals high in carbohydrate and low in fat.</li>
                        <li>Eat cold meals rather than hot meals. This may prevent any smell-related nausea, as cold food does not seem to give off as much smell as hot food.</li>
                        <li>Eat plain biscuits about 20 minutes before getting up.</li>
                        <li>Avoid any foods that trigger symptoms.</li>
                        <li>Avoid smells that trigger symptoms.</li>
                        <li>Eat away from kitchen</li>
                        <li>Do not sit at the dining table</li>
                        <li>Take a small quantity of food on a large plate .Will make you feel you can handle it better</li>
                        <li>Suck on something sour (lime, amla, peppermint) or ginger flavour</li>
                        <li>Consult your doctor for medications if all the above does not work</li>
                    </ul>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>Seek urgent medical advice if you experience: </h2>
                    <ul>
                        <li>Very dark urine, or no urination for more than 8 hours.</li>
                        <li>Abdominal pain or fever.</li>
                        <li>Severe weakness or feeling faint.</li>
                        <li>Vomiting blood.</li>
                        <li>Repeated, unstoppable vomiting.</li>
                        <li>Inability to keep down food or fluids for 24 hours.</li>
                    </ul>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>INDIGESTION IN PREGNANCY</h2>
                    <p>Indigestion is the name often given to a number of symptoms, including heartburn, regurgitation (food coming back up from the stomach), bloating, and nausea or vomiting. It can be described as pain, or discomfort, in the upper abdomen.
                        Indigestion is also known as dyspepsia, and some people describe it as an upset stomach.</p>
                    <p>How does indigestion affect pregnancy?</p>
                    <p>Many people are affected by indigestion from time to time, although women are particularly prone to getting it while they are pregnant. As many as half of all women experience indigestion at some point during their pregnancy, and the
                        risk of indigestion increases as the baby develops.</p>
                    <p>This is mainly due to changes that occur in the body during pregnancy, such as rising levels of hormones and increased pressure on the abdomen. These changes can often result in acid reflux, which is the most common cause of indigestion.
                        Acid reflux occurs when stomach acid flows back up from the stomach into the oesophagus (gullet) and irritates the lining (mucosa).</p>
                    <p>The symptoms of indigestion (dyspepsia) are caused by stomach acid coming into contact with the sensitive, protective lining (mucosa) of your digestive system. Your stomach acid breaks down the mucosa, causing irritation. This brings
                        on the symptoms of indigestion.</p>
                    <p>MANAGING INDIGESTION IN PREGNANCY</p>
                    <p>If you have indigestion (dyspepsia) while you are pregnant, you may not need medicine in order to control your symptoms.</p>
                    <p><strong>1.</strong> Diet and lifestyle changes</p>
                    <ul>
                        <li>Stop Smoking: Smoking while pregnant can cause indigestion and seriously affect the health of both you and your unborn baby. Smoking during pregnancy may cause your baby to be born prematurely, with a lower birth weight. It also
                            significantly increases the risk of cot death.</li>
                        <li>When you smoke, the chemicals that you inhale in cigarette smoke can contribute to your indigestion. These chemicals can cause the ring of muscle that separates your oesophagus (gullet) from your stomach to relax, allowing stomach
                            acid to leak back up into your gullet more easily (acid reflux).</li>
                        <li>Avoid alcohol: Drinking alcohol can contribute to the symptoms of indigestion, and can also put your unborn baby at risk of serious birth defects if you are pregnant.</li>
                        <li>Eat healthily: You are more likely to get indigestion if you are very full, so regularly eating large amounts of food may make your symptoms worse. If you are pregnant, it can be tempting to eat much more than you would normally,
                            although this may not be good for either you or your baby.</li>
                        <li>You should try to eat a wide variety of foods, based around balanced amounts of carbohydrates, fruit and vegetables, proteins, and dairy products.</li>
                    </ul>
                    <p><strong>2.</strong> Avoid your 'triggers'</p>
                    <p>You may find that your indigestion is made worse by certain triggers, such as drinking coffee, eating fatty foods, or even bending over. Try to keep a note of any particular food, drink or activity that seems to make your indigestion
                        worse, and avoid them if possible. This may mean eating less rich, spicy, and fatty foods, and cutting down on drinks that contain caffeine, such as tea, coffee, and cola.</p>
                    <p><strong>3.</strong> Change your eating habits</p>
                    <p>In some cases, you may be able to control your indigestion by making changes to the way you eat. For example, it may help to eat smaller meals more frequently, rather than larger meals three times a day. You should also avoid eating
                        within three hours of going to bed at night.</p>
                    <p><strong>4.</strong> Prop your head up while you sleep</p>
                    <p>When you go to bed, use a couple of pillows to prop your head and shoulders up, or raise the head of your bed by a few inches by propping something under the feet.</p>
                    <p>The slight slope should help to prevent stomach acid from moving up into your oesophagus (gullet or food pipe) while you are asleep.</p>
                    <p>However, if your indigestion is more severe, your doctor may suggest or prescribe something to ease your symptoms.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>MANAGING INDIGESTION IN PREGNANCY</h2>
                    <p>If you have indigestion (dyspepsia) while you are pregnant, you may not need medicine in order to control your symptoms.</p>
                    <p><strong>1.</strong> Diet and lifestyle changes</p>
                    <ul>
                        <li>Stop Smoking: Smoking while pregnant can cause indigestion and seriously affect the health of both you and your unborn baby. Smoking during pregnancy may cause your baby to be born prematurely, with a lower birth weight. It also
                            significantly increases the risk of cot death.</li>
                        <li>When you smoke, the chemicals that you inhale in cigarette smoke can contribute to your indigestion. These chemicals can cause the ring of muscle that separates your oesophagus (gullet) from your stomach to relax, allowing stomach
                            acid to leak back up into your gullet more easily (acid reflux).</li>
                        <li>Avoid alcohol: Drinking alcohol can contribute to the symptoms of indigestion, and can also put your unborn baby at risk of serious birth defects if you are pregnant.</li>
                        <li>Eat healthily: You are more likely to get indigestion if you are very full, so regularly eating large amounts of food may make your symptoms worse. If you are pregnant, it can be tempting to eat much more than you would normally,
                            although this may not be good for either you or your baby.</li>
                        <li>You should try to eat a wide variety of foods, based around balanced amounts of carbohydrates, fruit and vegetables, proteins, and dairy products.</li>
                    </ul>
                    <p><strong>2.</strong> Avoid your 'triggers'</p>
                    <p>You may find that your indigestion is made worse by certain triggers, such as drinking coffee, eating fatty foods, or even bending over. Try to keep a note of any particular food, drink or activity that seems to make your indigestion
                        worse, and avoid them if possible. This may mean eating less rich, spicy, and fatty foods, and cutting down on drinks that contain caffeine, such as tea, coffee, and cola.</p>
                    <p><strong>3.</strong> Change your eating habits</p>
                    <p>In some cases, you may be able to control your indigestion by making changes to the way you eat. For example, it may help to eat smaller meals more frequently, rather than larger meals three times a day. You should also avoid eating
                        within three hours of going to bed at night.</p>
                    <p><strong>4.</strong> Prop your head up while you sleep</p>
                    <p>When you go to bed, use a couple of pillows to prop your head and shoulders up, or raise the head of your bed by a few inches by propping something under the feet.</p>
                    <p>The slight slope should help to prevent stomach acid from moving up into your oesophagus (gullet or food pipe) while you are asleep.</p>
                    <p>However, if your indigestion is more severe, your doctor may suggest or prescribe something to ease your symptoms.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>MEDICINES FOR INDIGESTION DURING PREGNANCY</h2>
                    <p>Antacids are a type of medicine that can provide immediate relief from indigestion. They work by neutralising the acid in your stomach (making it less acidic), so that it no longer irritates the protective lining (mucosa) of your digestive
                        system.
                    </p>
                    <p>Some antacids are combined with another medicine called an alginate. This helps to relieve indigestion caused by acid reflux. Acid reflux occurs when stomach acid leaks back up into your esophagus (gullet) and irritates its protective
                        lining (mucosa). Alginates work by forming a foam barrier that floats on the surface of your stomach contents, keeping stomach acid in your stomach and away from your esophagus</p>
                    <p>If antacids and alginates do not improve your symptoms of indigestion, you may be prescribed a different medicine that works by suppressing the acid in your stomach.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>CONSTIPATION IN PREGNANCY</h2>
                    <p>Pregnancy hormones have a relaxing effect on your muscles, so your bowels don't work as well as they normally would to push the food through your body. Your digestive system absorbs more water from the food and this makes your faeces
                        harder and more difficult to pass. Some types of iron tablets can make constipation worse. Lack of exercise will make your whole system sluggish. Additionally, as pregnancy progresses and your baby gets bigger, increased downward
                        pressure on the bowel, making constipation more likely.</p>
                    <ul>
                        <li>You should make sure you are drinking plenty of fluids</li>
                        <li>Eat plenty of fresh fruit, vegetables and high-fibre foods such as cereals.</li>
                        <li>Eat frequent small meals</li>
                        <li>Avoid drinks which are diuretic (make you pass more urine), such as tea, coffee, cola and alcohol, as these can dehydrate you, making constipation worse.</li>
                        <li>Staying active can help prevent constipation; generally keeping your whole body in better health than if you are completely sedentary.</li>
                        <li>Walking, swimming, cycling on a stationary bike and yoga can all help ease constipation, and leave you feeling more fit and healthy. It is important that you follow an exercise routine that is appropriate for your fitness level
                            and don't exhaust yourself.</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <ng-template [ngIf]="ins_links.length > 0">
        <footer-nav-mini [links]="ins_links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>