import { Inject, Component, OnDestroy, OnInit, AfterViewInit, PLATFORM_ID, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from "../services/api.service";
import { IMG_BASE_URL } from "../services/constants";

import { Meta, Title } from '@angular/platform-browser';
declare let $: any;
declare let initHome: any;
declare let initAllPage: any;

@Component({
	selector: 'specialities',
	templateUrl: './specialities.component.html'
})

export class ChildCareSpecialitiesComponent implements OnDestroy, OnInit, AfterViewInit {


	constructor(private api: ApiService, public metaTitle: Title, public meta: Meta) {
		if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
    }
	}

	public features: any[] = [];
	public pageinfo: any[] = [];
	public title: any = "";
	public subtitle: any = "";
	public description: any = "";
	public imgsrc: any = "";
	public columns = -1;
	public sItems: any[] = [];
	public zone: any;
	public imgBasePath = "";
	public hidden = "hidden";
	public logo = "rainbow-logo";
	public bread_crumb_links: any = [{ "label": "Home", "link": "/", "class": "" },
	{"label":"Child Care", "link": "/child-care/about", "class": ""},
	{ "label": "Our Specialities", "link": "", "class": "" }];

	public links: any = [
		{ "label": "About Child Care", "link": "/child-care/about", "class": "","slug":"about" },
		{ "label": "Child Safety", "link": "/child-care/child-safety", "class": "","slug":"child-safety"  },
	{ "label": "Doctors", "link": "/all/doctors", "class": "","slug":"doctors"  },
	{ "label": "Our Specialities", "link": "/child-care/specialities", "class": "","slug":"specialities"  },
	{ "label": "Our Centres", "link": "/child-care/centres", "class": "active","slug":"centres"  },
	{ "label": "Vaccination", "link": "/child-care/vaccination", "class": "","slug":"vaccination"  },
	{ "label": "Testimonials", "link": "/child-care/testimonials", "class": "","slug":"testimonials"  },
	{ "label": "Share Feedback", "link": "/child-care/feedback", "class": "","slug":"feedback"  },
	{ "label": "Book An Appointment", "link": "/child-care/book-an-appointment", "class": "","slug":"book-an-appointment"  }]
	public activePage:string = 'specialities';



	@ViewChildren('allTheseThings') things: QueryList<any>;

	// ngForRendred() {
	//
	// }

	ngOnInit() {
		this.api.getPageDescription('child-care-specialities')
			.subscribe(
			data => {
				this.title = data.page_descriptions[0].title;
				this.subtitle = data.page_descriptions[0].subtitle;
				this.description = data.page_descriptions[0].description;
				this.imgsrc = data.page_descriptions[0].image;
				this.imgBasePath = IMG_BASE_URL;
				if(data){
					//console.log(data);
					this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
					this.meta.updateTag({name:'keywords',content: data.page_descriptions[0].meta_keyword});
					this.meta.updateTag({name:'description',content: data.page_descriptions[0].meta_description});
					this.meta.addTags([
					  { property: 'og:title', content:  data.page_descriptions[0].meta_title},
					  { property: 'og:description', content: data.page_descriptions[0].meta_description },
					  { property: 'og:keywords', content: data.page_descriptions[0].meta_keyword }
					]);
				  }
			},
			error => {
				console.log(error);
			}
			)

		this.api.getSpecialityFeatures('child-care')
			.subscribe(
			data => {
				this.features = data.our_speciality_features;
				if (Array.isArray(data.our_speciality_features) && data.our_speciality_features.length > 0) {
					for (let i = 0; i < data.our_speciality_features.length; i += 2) {
						let column = [];
						column.push(data.our_speciality_features[i])
						if (data.our_speciality_features[i + 1]) {
							column.push(data.our_speciality_features[i + 1])
						}
						this.sItems.push(column);
						column = [];
					}
				};
			},
			error => {
				console.log(error);
			}
			)

	}

	ngAfterViewInit() {
		if (this.api.isBrowser && window['jQuery']) {
			initHome();
			initAllPage();
		}

		this.things.changes.subscribe(t => {
			// this.ngForRendred();
			this.setSpecialitiesSlider();
		})
	}
	findComingSoon(data:string){
		if(data){
			let findmatching = data.toLowerCase().search('coming soon');
		//	console.log(findmatching);
			if(findmatching != -1){
				return false;
			}
			return true;
		}
	}
	setSpecialitiesSlider() {
		//console.log("Specialities Slider Invoked");
		$(".ourSpecialitiess").removeClass("slick-slider").removeClass("slick-initialized").removeClass("slider");
		$(".ourSpecialitiess").not('.slick-initialized').slick({
			slide: '.sitem',
			dots: true,
			arrows: false,
			infinite: false,
			slidesToShow: 3,
			slidesToScroll: 3,
			slidesPerRow: 1,
			adaptiveHeight: true,
			responsive: [
				{
					breakpoint: 1024, settings: {
						slidesToShow: 2, slidesToScroll: 1
					}
				},
				{
					breakpoint: 680, settings: {
						slidesToShow: 1, slidesToScroll: 1
					}
				},
				{
					breakpoint: 480, settings: {
						slidesToShow: 1, slidesToScroll: 1
					}
				}
			]
		});

	}
	ngOnDestroy() { }

}
