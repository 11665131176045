import {
  Component,
  Input,
  Output,
  EventEmitter,
  HostListener,
} from '@angular/core';

import {
  TWITTER_URL,
  FB_URL,
  YOUTUBE_URL,
  LINKEDIN_URL,
} from './../services/constants';
import { ApiService } from '../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
declare let $: any;
@Component({
  selector: 'sidenav',
  templateUrl: './sidenav.component.html',
})
export class SidenavComponent {
  @Output() section: EventEmitter<string> = new EventEmitter<string>();

  public search: string;
  public sideBarList: Array<any>;
  public activeul: any;
  metaDis: any;
  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
    }
  }
  socialIcon: any = {
    facebook: FB_URL,
    youtube: YOUTUBE_URL,
    twitter: TWITTER_URL,
    linkedin: LINKEDIN_URL,
  };
  ngOnInit() {
    this.api
      .getSideBarlist()
      .map((res) => res)
      .subscribe((data) => {
        data.forEach((element) => {
          element.links = element.links.filter(
            (data) =>
              data.title !== 'Health Card' &&
              data.title !== 'Symptom Checker' &&
              data.title !== 'BirthRight Premium'
          );
        });
        data.forEach((element) => {
          element.links.forEach((element1) => {
            if (element1.title == 'Careers') {
              element1.class = 'CareersBtn';
            }
          });
        });
        this.sideBarList = data;
        console.log('sideBarList', this.sideBarList);

        //this.sideBarList = data;
        //console.log('this.sideBarList:', this.sideBarList);
      });
  }
  onSubmit() {
    //	console.log(this.search);
    $('#topsearchwrp').toggleClass('visible');
    $(location).attr('href', 'search?q=' + this.search);
    //	this.router.navigateByUrl(`search?q=${this.search}`);
    //this.search = '';
  }
  searchBar($evt) {
    //console.log($evt);
    $('#topsearchwrp').toggleClass('visible');
  }
  activeMenu(i) {
    // Handled for double click on menu to hide the same menu list
    if (i != this.activeul) {
      this.activeul = i;
    } else {
      this.activeul = null;
    }
  }
  ngAfterViewInit() {
    //console.log(this.socialIcon);
    if (this.api.isBrowser && window['jQuery']) {
      var $body = $('body');
      var $sidenavId = $body.find('#sidenav');
      $body.find('.sidenavbtn').on('click', function (e) {
        e.preventDefault();
        $sidenavId.toggleClass('visible');

        $body.css('overflow', 'hidden');
      });
      $body.find('.closesidenav').on('click', function (e) {
        //e.preventDefault();
        $sidenavId.toggleClass('visible');
        $body.css({ overflow: 'auto', 'overflow-x': 'hidden' });
        $('.dropdown-tggle').hasClass('active')
          ? $('.dropdown-tggle').removeClass('active')
          : '';
      });

      // $body.find(".sidenavwrp").each(function (index, element) {
      // 	var thisP = $(this)
      // 	$("h2", this).on('click',function (e) {
      // 		if (!$(this).parents('li').hasClass('active')) {
      // 			thisP.find('li.active').removeClass('active')
      // 			$(this).parents('li').addClass('active')
      // 			$(this).parents('.sidenavwrp').find('.subList').slideUp(1000)
      // 			$(this).siblings('ul').slideDown(1200);
      // 		} else {
      // 			$(this).parents('li').removeClass('active');
      // 			$(this).siblings('ul').slideUp(1200);
      // 		}
      // 	});
      // });
    }
  }
  @HostListener('window:load', []) onWindowLoad() {
    console.log('title: ', document.title);
    this.metaDis = $('meta[name=description]').attr('content');
  }
}
