import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  ViewChildren,
  QueryList,
  ChangeDetectorRef,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL } from '../services/constants';
import { Meta, Title } from '@angular/platform-browser';
declare let $: any;
declare let initHome: any;
declare let initAllPage: any;

declare let initAboutTabs: any;

@Component({
  selector: 'academic-programs',
  templateUrl: './academic-programs.component.html',
})
export class AcademicPrograms implements OnDestroy, OnInit, AfterViewInit {
  public group_sections: any = [];
  public title: any = '';
  public subtitle: any = '';
  public description: any = '';
  public imgsrc: any = '';
  public imgBasePath = '';
  public hidden = 'hidden';
  public logo = 'logo';
  public academicSections: any[];
  public academicPrograms: any;
  public pageData: any;
  public showAcademicProgramsDetails: any;
  public activeList: any;
  public activeTableId: any = 1;
  public activeQuestionId: any;
  public showActive: boolean = false;
  public programsShowActive: boolean = false;

  constructor(
    private api: ApiService,
    public ref: ChangeDetectorRef,
    public metaTitle: Title,
    public meta: Meta
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
    }
  }
  //  public services:any[] = [];
  //  public brief_facilities:any[] = [];

  public bread_crumb_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Other', link: '', class: '' },
    { label: 'Academic', link: '', class: '' },
  ];

  public links: any = [];
  public activePage: string = 'academic-programs';
  @ViewChildren('faqlist') things: QueryList<any>;

  ngOnInit() {
    this.api.getPageDescription('academic-programs').subscribe(
      (data) => {
        this.pageData = data.page_descriptions[0];
        this.imgBasePath = IMG_BASE_URL;
        if (data) {
          //console.log(data);
          this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
          this.meta.updateTag({
            name: 'keywords',
            content: data.page_descriptions[0].meta_keyword,
          });
          this.meta.updateTag({
            name: 'description',
            content: data.page_descriptions[0].meta_description,
          });
          this.meta.addTags([
            {
              property: 'og:title',
              content: data.page_descriptions[0].meta_title,
            },
            {
              property: 'og:description',
              content: data.page_descriptions[0].meta_description,
            },
            {
              property: 'og:keywords',
              content: data.page_descriptions[0].meta_keyword,
            },
          ]);
        }
        /* Removed for extra declaration of variables */
        /* this.title = data.page_descriptions[0].title,
				this.subtitle = data.page_descriptions[0].subtitle,
				this.description = data.page_descriptions[0].description,
				this.imgsrc = data.page_descriptions[0].image,
				this.imgBasePath = IMG_BASE_URL;*/
      },
      (error) => {
        console.log(error);
      }
    );
    this.api
      .getAcademicContent()
      .map((res) => res)
      .subscribe((res) => {
        this.academicPrograms = res;

        if (this.academicPrograms) {
          this.showAcademicDetails(
            this.academicPrograms.group_sections[0],
            this.academicPrograms.group_sections[0].id
          );
        }

        /* this.academicSections = res.group_sections.map(res =>{
		 				return {title:res.title, icon:res.image,data:'data'+res.id};
		 	});
			 console.log("-->", this.academicSections); */
      });
    // this.api.getAcademicContent()
    // .map(res => res.group_sections)
    // .subscribe(
    // 	data =>{
    // 		console.log(data);
    // 		 this.academicTitle = data.map(res =>{
    // 				return {title:res.title, icon:res.image,data:'data'+res.id};
    // 		 });
    // 		 console.log(this.academicTitle);
    // 		//this.group_sections = data.group_sections
    // 	},
    // 	error => {
    // 		console.log(error);
    // 	}
    // )
    this.api
      .getOtherSubFooterDetails()
      .map((res) => res)
      .subscribe((data) => {
        this.links = data;
      });
  }

  public showAcademicDetails(showDataFor: any, activeId) {
    this.showAcademicProgramsDetails = showDataFor;
    this.activeList = activeId;
    this.ref.markForCheck();
    this.ref.detectChanges();
    if (showDataFor.sub_group[0].academic_programs.length == 0) {
      this.programsShowActive = true;
    } else {
      this.programsShowActive = false;
    }
  }

  public activeTable(academicProgram) {
    this.activeTableId = academicProgram.id;
    this.ref.markForCheck();
    this.ref.detectChanges();
  }

  activeQuestion(que) {
    this.activeQuestionId = que.id;
    this.ref.markForCheck();
    this.ref.detectChanges();
  }

  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
    }

    this.things.changes.subscribe((t) => {
      // this.ngForRendred();
      initAboutTabs();
    });
  }

  ngOnDestroy() {}
}
