<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <section class="page pageheader">
        <div class="container">
            <div class="col-xs-6 col-sm-6 col-lg-6 abannimgwrp">
                <div class="otherbannimg"><img src="{{imgBasePath}}{{pageData.image}}" class="img-responsive" alt="{{pageData.title}}" title="{{pageData.title}}" /></div>
            </div>
            <div class="col-xs-6 col-sm-6 col-lg-6">
                <div class="ptitle">
                    <h1>{{pageData.title}}</h1>
                    <h3>{{pageData.subtitle}}</h3>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-lg-6 mcont100">
                <p>{{pageData.description}}</p>
            </div>
        </div>
    </section>
    <section class="aboutsectin2 wcuswrp">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-lg-12">
                    <ul class="atabs slider">
                        <li #abouttabs *ngFor="let service of services; let first = first;" [ngClass]="{ first: active }">
                            <a href="javascript:void()" data-id="{{service.id}}">
                                <div class="aiconwrp"><img src="{{imgBasePath}}{{service.image}}" alt="" /></div>
                                <h2>{{service.title}}</h2>
                            </a>
                        </li>

                    </ul>
                    <div class="atabscontent slider">
                        <div #emergency class="atabsdata" *ngFor="let service of services">
                            <p [innerHTML]="service.description"></p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>


    <footer-subscription></footer-subscription>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>