import {Inject, Component, OnDestroy, OnInit, AfterViewInit, PLATFORM_ID } from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {ApiService} from "../services/api.service";
declare let $:any;
declare let initHome:any;
declare let initAllPage:any;

@Component({
  selector: 'vaccination',
  templateUrl: './vaccination.component.html'
})

export class WomenCareVaccinationComponent implements OnDestroy, OnInit, AfterViewInit {


  constructor(private api:ApiService, ) {
    if(this.api.isBrowser){
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
    }
  }

  public events:any[] = [];
  public hidden:string = "hidden";
  public logo="birth-right-logo";
  public imgBasePath:string = "";
  public imgsrc:any = "";
  public title:any = "";
  public subtitle:any = "";
  public description:any = "";
  public links:any = [{"label":"About Women's Care", "link": "/women-care-about", "class": "active"},
                      {"label":"Doctors", "link": "/women-care-doctors", "class": ""},
                      {"label":"Our Specialities", "link": "/women-care-specialities", "class": ""},
                      {"label":"Our Centres", "link": "/women-care-centres", "class": ""},
                      {"label":"Vaccination", "link":"#", "class":"active"},
                      {"label":"Testimonials", "link": "/women-care-testimonials", "class": ""},
                      {"label":"Share Feedback", "link": "/women-care-feedback", "class": ""},
                      {"label":"Book An Appointment", "link": "/women-care-appointment", "class": ""}]

  ngOnInit() {
    this.api.getEvents()
      .subscribe(
        data =>{
          this.events = data.home_events;
        },
        error => {
          console.log(error);
        }
      )
  }

  ngAfterViewInit() {

    if ( this.api.isBrowser && window['jQuery'] ) {
      initHome();
      initAllPage();
    }
  }

  ngOnDestroy() {}

}
