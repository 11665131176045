import { Inject, Component, OnDestroy, OnInit, AfterViewInit, PLATFORM_ID, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { isPlatformBrowser, Location } from '@angular/common';
import { ApiService } from "../services/api.service";
import { API_BASE_URL, IMG_BASE_URL } from "../services/constants";
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ReactiveFormsModule, FormsModule, FormControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Meta, Title } from '@angular/platform-browser';

declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
declare let initCustomSelect: any;
declare let initQuestionAccordion: any;
declare let changeShowStatus: any;

@Component({
  selector: 'our-speciality-details',
  templateUrl: './our-speciality-details.component.html',
  styles: [
    `
      [data-icon='360icon'] {
        width: auto !important;
        position: absolute;
        right: 30px;
        top: 15px;
      }
      .breadnav li a {
        text-transform: capitalize;
      }
      #ModelFormGetInTouch {
        display: none;
      }
      .capopup {
        display: none;
      }
      #ModelFormGetInTouch .capopup-inner {
        max-width: 600px;
        width: 90%;
        padding: 40px;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
        border-radius: 20px;
        background: #faf5ff;
      }
      #ModelFormGetInTouch .capopup-inner .launchpreloader {
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: 11;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        background: rgba(255, 255, 255, 0.6)
          url(../../assets/css/ajax-loader.gif) no-repeat center center;
      }
      #ModelFormGetInTouch h2 {
        width: 100%;
        text-transform: uppercase;
        color: #5f2160;
        font-weight: 700;
        font-family: 'dinrg';
        font-size: 30px;
        display: inline-block;
        margin: 0 0px 10px 0;
        position: relative;
        padding: 0px;
      }
      #ModelFormGetInTouch .formcasn {
        width: 100%;
        margin: 20px 0 0 0;
        padding: 0;
        position: relative;
        float: left;
      }
      #ModelFormGetInTouch .formcasn ul {
        margin: 0;
        padding: 0px;
      }
      #ModelFormGetInTouch .formcasn li {
        float: left;
        width: 100%;
        list-style: none;
        position: relative;
        margin-bottom: 10px;
      }
      #ModelFormGetInTouch .formcasn li:last-child {
        margin-bottom: 0px;
      }
      #ModelFormGetInTouch .formcasn li i {
        width: 25px;
        height: 25px;
        position: absolute;
        left: 32px;
        top: 39px;
      }
      #ModelFormGetInTouch .formcasn li .alertMsg {
        position: absolute;
        right: 12px;
        bottom: 14px;
        z-index: 1;
        color: #e51e2c;
        font-family: 'dinrg';
        font-size: 13px;
        font-weight: normal;
      }
      #ModelFormGetInTouch .formcasn li input {
        color: #545454;
        font-family: 'dinrg';
        font-size: 17px;
        width: 100%;
        float: left;
        background: #fff;
        border: 1px solid #ccc;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        padding: 10px 20px 10px 20px;
        box-sizing: border-box;
      }
      #ModelFormGetInTouch .formcasn li select {
        color: #545454;
        font-family: 'dinrg';
        font-size: 17px;
        width: 100%;
        float: left;
        background: #fff;
        border: 1px solid #ccc;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        padding: 10px 20px 10px 20px;
        box-sizing: border-box;
      }
      #ModelFormGetInTouch .formcasn li textarea {
        color: #545454;
        font-family: 'dinrg';
        font-size: 17px;
        width: 100%;
        float: left;
        background: #fff;
        border: 1px solid #ccc;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        padding: 10px 20px 10px 20px;
        box-sizing: border-box;
      }
      #ModelFormGetInTouch .formcasn li input:focus,
      #ModelFormGetInTouch .formcasn li textarea:focus,
      #ModelFormGetInTouch .formcasn li select:focus {
        outline: none;
      }
      #ModelFormGetInTouch .btnPrevca {
        color: #5f2160;
        font-family: 'dinrg';
        font-size: 14px;
        width: 100%;
        margin: 20px 10px 20px 0px;
        position: relative;
        display: inline-block;
        text-align: right;
      }
      #ModelFormGetInTouch .formcasn li .submitbtn {
        width: 100%;
        text-align: center;
        display: inline-block;
        float: left;
      }
      #ModelFormGetInTouch .formcasn li .submitbtn input {
        float: right;
        display: inline-block;
        width: auto;
        color: #fff;
        background: #3d2979;
        border: 1px solid #3d2979;
        padding: 0px 40px;
        margin: 2px 4px;
        height: 38px;
        text-transform: uppercase;
        font-family: 'dinrg';
        font-size: 18px;
        border-radius: 30px;
      }
      #ModelFormGetInTouch .formcasn li input:focus {
        outline: none;
      }
      span.error {
        position: absolute;
        right: 12px;
        bottom: 14px;
        z-index: 1;
        color: #e51e2c;
        font-family: 'dinrg';
        font-size: 13px;
        font-weight: normal;
      }
      .iconForm {
        bottom: 96px;
        position: fixed;
        right: 10px;
        z-index: 12;
        display: none !important;
      }
      .iconFormV {
        width: 30px;
        word-break: break-all;
        top: 100px;
        position: fixed;
        right: 0px;
        z-index: 12;
        display: none;
        background: #80368d;
        text-align: center;
        padding: 10px 10px;
        color: #fff;
        text-transform: uppercase;
        font-family: 'dinrg';
        font-size: 14px;
        line-height: 14px;
        text-transform: uppercase;
      }
      .iconFormV span {
        display: inline-block;
        margin-top: 6px;
      }
      @media (max-width: 767px) {
        .iconForm {
          bottom: 62px;
          position: fixed;
          right: 10px;
          z-index: 12;
        }
        .iconForm img {
          width: 50px;
        }
      }
      @media (max-width: 480px) {
        .iconForm {
          bottom: 112px;
          position: fixed;
          right: 6px;
          z-index: 12;
        }
        #ModelFormGetInTouch h2 {
          font-size: 18px;
        }
        #ModelFormGetInTouch .formcasn {
          margin: 10px 0 0 0;
        }
        #ModelFormGetInTouch .formcasn li input,
        #ModelFormGetInTouch .formcasn li select,
        #ModelFormGetInTouch .formcasn li textarea {
          font-size: 13px;
          padding: 6px 10px;
        }
        #ModelFormGetInTouch .formcasn li .submitbtn input {
          height: 30px;
          font-size: 18px;
        }
        #ModelFormGetInTouch .formcasn li .alertMsg {
          right: 5px;
          bottom: 9px;
          font-size: 11px;
        }
      }
      @media (max-width: 420px) {
        .iconForm {
          bottom: 90px;
          position: fixed;
          right: 6px;
          z-index: 12;
        }
        .iconForm img {
          width: 40px;
        }
      }
      @media (max-width: 420px) {
        .iconForm {
          bottom: 132px;
          position: fixed;
          right: 6px;
          z-index: 12;
        }
        .iconFormV {
          top: 80px;
          line-height: 12px;
        }
      }
    `,
  ],
})
export class OurSpecialityDetailsComponent
  implements OnDestroy, OnInit, AfterViewInit
{
  constructor(
    private api: ApiService,
    private location: Location,
    private formBuilder: FormBuilder,
    private _router: Router,
    private activatedRoute: ActivatedRoute,
    public metaTitle: Title,
    public meta: Meta
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
      require('../../assets/js/jquery.cookie.js');
    }
  }
  public submited: boolean = false;
  public showHide: boolean = false;
  public preloader: boolean = false;
  GetInTouch: FormGroup;
  public pageName: string;
  public countryModel: string = '';
  public locationModel: string = '';
  public locationList: any = [
    { name: 'Hyderabad', code: 'Hyderabad' },
    { name: 'Bengaluru', code: 'Bengaluru' },
  ];
  public interestedList: any = [
    { name: 'Fertility treatment', code: 'Fertility treatment' },
    {
      name: 'Intrauterine insemination (IUI)',
      code: 'Intrauterine insemination (IUI)',
    },
    {
      name: 'In vitro fertilization (IVF)',
      code: 'In vitro fertilization (IVF)',
    },
    { name: 'Infertility screening', code: 'Infertility screening' },
    { name: 'Male infertility', code: 'Male infertility' },
    { name: 'Preconception counselling', code: 'Preconception counselling' },
  ];
  public reload(): any {
    return this.zone.runOutsideAngular(() => {
      location.reload();
    });
  }

  public features: any[] = [];
  public facilities: any[] = [];
  public question: any = [];
  public pageinfo: any[] = [];
  public doctors: any = [];
  public title: any = '';
  public subtitle: any = '';
  public description: any = '';
  public imgsrc: any = '';
  public columns = -1;
  public sItems: any[] = [];
  public zone: any;
  public id: any = '';
  public imgBasePath = '';
  public hidden = 'hidden';
  public logo = '';
  public speciality: string = '';
  public category: string = '';
  public links: any = [];
  public bread_crumb_links: any = [];
  public bread_crumb_child_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Child Care', link: '/child-care/about', class: '' },
    { label: 'Our Specialities', link: 'child-care/speciality', class: '' },
  ];

  public bread_crumb_women_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Women Care', link: '/women-care/about', class: '' },
    { label: 'Our Specialities', link: 'women-care/speciality', class: '' },
  ];

  public bread_crumb_fertility_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Fertility Care', link: '/fertility-care/about', class: '' },
    { label: 'Our Treatments', link: 'fertility-care/treatments', class: '' },
  ];

  public child_care_links: any = [
    { label: 'About Child Care', link: '/child-care-about', class: '' },
    { label: 'Child Safety', link: '/child-care/child-safety', class: '' },
    { label: 'Doctors', link: '/all/doctors', class: '' },
    {
      label: 'Our Specialities',
      link: '/child-care/specialities',
      class: 'active',
    },
    { label: 'Our Centres', link: '/child-care/centres', class: '' },
    { label: 'Vaccination', link: '/child-care/vaccination', class: '' },
    { label: 'Testimonials', link: '/child-care/testimonials', class: '' },
    { label: 'Share Feedback', link: '/child-care/feedback', class: '' },
    {
      label: 'Book An Appointment',
      link: '/child-care/book-an-appointment',
      class: '',
    },
  ];

  public women_care_links: any = [
    { label: "About Women's Care", link: '/women-care/about', class: '' },
    { label: 'Doctors', link: '/all/doctors', class: '' },
    {
      label: 'Our Specialities',
      link: '/women-care/specialities',
      class: 'active',
    },
    { label: 'Our Centres', link: '/women-care/centres', class: '' },
    { label: 'Testimonials', link: '/women-care/testimonials', class: '' },
    { label: 'Share Feedback', link: '/women-care/feedback', class: '' },
    {
      label: 'Book An Appointment',
      link: '/women-care/book-an-appointment',
      class: '',
    },
  ];

  public fertility_care_links: any = [
    { label: 'About Fertility Care', link: '/fertility-care/about', class: '' },
    { label: 'Doctors', link: '/all/doctors', class: '' },
    {
      label: 'Our Treatments',
      link: '/fertility-care/treatments',
      class: 'active',
    },
    { label: 'Our Centres', link: '/fertility-care/centres', class: '' },
    { label: 'Testimonials', link: '/fertility-care/testimonials', class: '' },
    { label: 'Share Feedback', link: '/fertility-care/feedback', class: '' },
    {
      label: 'Book An Appointment',
      link: '/fertility-care/book-an-appointment',
      class: '',
    },
  ];

  public centerList: any = [];
  public params: any;
  @ViewChildren('allTheseThings') things: QueryList<any>;
  @ViewChildren('questions') questions: QueryList<any>;
  @ViewChildren('doctorlist') doctorlist: QueryList<any>;

  // ngForRendred() {
  //
  // }
  public fertilityC: any = false;

  ngOnInit() {
    if (this.activatedRoute && this.activatedRoute.params) {
      //console.log('params', this.activatedRoute.params);
      //console.log('ROUTE', this.route);
      this.params = this.activatedRoute.params['value'];
      //console.log('ParamsId', this.params);
    }

    this.countryModel = '';
    this.locationModel = '';
    this.GetInTouch = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern('.*\\S.*')]],
      mobile: ['', Validators.required],
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            '[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}'
          ),
        ]),
      ],
      txtLocation: ['', [Validators.required]],
      interested_in: ['', [Validators.required]],
      message: [''],
      CityName: [''],
    });

    this.activatedRoute.params.subscribe((params: Params) => {
      this.speciality = params['speciality'];
      this.category = params['category'];
      //console.log('activatedRoute', params);
      // this.category = params['category'];

      //console.log(params);
      switch (params['category']) {
        case 'child-care':
          this.bread_crumb_links = this.bread_crumb_child_links;
          this.links = this.child_care_links;
          this.logo = 'rainbow-logo';
          break;
        case 'women-care':
          this.bread_crumb_links = this.bread_crumb_women_links;
          this.links = this.women_care_links;
          this.logo = 'birth-right-logo';
          break;
        case 'fertility-care':
          this.bread_crumb_links = this.bread_crumb_fertility_links;
          this.links = this.fertility_care_links;
          this.logo = 'birth-right-logo';
          this.fertilityC = true;
          break;
        default:
          this.logo = 'logo';
      }
      switch (params['speciality']) {
        case 'in-vitro-fertilization':
          this.pageName = params['speciality'];
          setTimeout(() => {
            if ($.cookie('showThankYouPage') != 1) {
              this.showHide = true;
              $('#ModelFormGetInTouch').show();
            }
          }, 5000);
          break;
        case 'obstetrics-and-gynecology':
          this.pageName = params['speciality'];
          setTimeout(() => {
            if ($.cookie('showThankYouPage') != 1) {
              this.showHide = true;
              $('#ModelFormGetInTouch').show();
            }
          }, 5000);
          break;
      }
    });

    this.api
      .getPageDescription(this.category + '-specialities-' + this.speciality)
      .subscribe(
        (data) => {
          this.title = data.page_descriptions[0].title;
          this.subtitle = data.page_descriptions[0].subtitle;
          this.description = data.page_descriptions[0].description;
          this.imgsrc = data.page_descriptions[0].image;
          this.imgBasePath = IMG_BASE_URL;
          $.cookie('pTitle', this.title);
          $.cookie('pBannImg', this.imgsrc);
          if (data) {
            //console.log(data);
            this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
            this.meta.updateTag({
              name: 'keywords',
              content: data.page_descriptions[0].meta_keyword,
            });
            this.meta.updateTag({
              name: 'description',
              content: data.page_descriptions[0].meta_description,
            });
            this.meta.addTags([
              {
                property: 'og:title',
                content: data.page_descriptions[0].meta_title,
              },
              {
                property: 'og:description',
                content: data.page_descriptions[0].meta_description,
              },
              {
                property: 'og:keywords',
                content: data.page_descriptions[0].meta_keyword,
              },
            ]);
          }
          //console.log(this.title);
        },
        (error) => {
          console.log(error);
        }
      );

    this.api
      .getBriefFacilities(this.category + '-specialities-' + this.speciality)
      .subscribe(
        (data) => {
          this.facilities = this.arrayChunk(data.brief_facilities, 2);
        },
        (error) => {
          console.log(error);
        }
      );
    this.api
      .getSpecialityQuestions(
        this.category + '-specialities-' + this.speciality
      )
      .subscribe(
        (data) => {
          if (data.speciality_group) {
            console.log(
              'QUESTIONS',
              data.speciality_group[0].specility_question
            );
            this.question = data.speciality_group[0].specility_question;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
  replaceString(value) {
    return value.replace('-', ' ');
  }
  arrayChunk(arr, chunk_size) {
    return arr
      .map(function (e, i) {
        return i % chunk_size === 0 ? arr.slice(i, i + chunk_size) : null;
      })
      .filter(function (e) {
        return e;
      });
  }
  getSpecDoctorList(name) {
    this.api
      .getDoctorSpecialityFeaturesByName(this.speciality)
      //	.map(res => res)
      .subscribe(
        (data) => {
          if (data.our_speciality_features) {
            this.doctors = data.our_speciality_features[0].doctors;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
  getSpecilityCentre(speciality) {
    this.api.getCentresbyspecialityfeatures(speciality).subscribe((centers) => {
      this.centerList = centers;
    });
  }

  changeShowStatus() {
    this.showHide = !this.showHide;
    $('#ModelFormGetInTouch').hide();
    $('.iconForm').show();
    $('.iconFormV').show();
  }
  ShowForm() {
    this.showHide = true;
    $('.iconForm').hide();
    $('.iconFormV').hide();
    $('#ModelFormGetInTouch').show();
  }
  onSubmit() {
    this.submited = true;
    if (!this.GetInTouch.valid) {
      return;
    }
    this.preloader = true;
    var self = this;

    if (this.GetInTouch.valid) {
      $('#ModelFormGetInTouch').hide();

      let formTemp: any = <any>document.querySelector('#form');
      let formData: FormData = new FormData(formTemp);
      //console.log('Form Post Data',formData);
      let xhr: XMLHttpRequest = this.buildForm(formData);
      //console.log('XMLHttpRequest', xhr);
      xhr.onreadystatechange = () => {
        var obj = xhr;
        //console.log('obj', obj);
        if (obj.readyState == 4) {
          switch (this.pageName) {
            case 'in-vitro-fertilization':
              window.open(
                '/fertility-care/treatment/' + this.pageName + '/thankyou',
                '_blank'
              );
              break;
            case 'obstetrics-and-gynecology':
              window.open(
                '/women-care/speciality/' + this.pageName + '/thankyou',
                '_blank'
              );
              break;
            default:
              window.location.href = '/fertility-care/treatments/thankyou';
              break;
          }
          $('#ModelFormGetInTouch').hide();
          self.showHide = false;
          self.preloader = false;
          this.GetInTouch.reset();
        }
        if (obj.status == 200) {
          //console.log(obj.status);
        }
      };
    }
  }

  public buildForm(formData) {
    let url: any =
      API_BASE_URL + 'home/fertility_Speciality_get_in_touch_with_us';
    let xhr: XMLHttpRequest = new XMLHttpRequest();
    xhr.open('POST', url, true);

    // IE bug fixes to clear cache
    xhr.setRequestHeader('Cache-Control', 'no-cache');
    xhr.setRequestHeader('Cache-Control', 'no-store');

    xhr.send(formData);
    return xhr;
  }

  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
    }

    setTimeout(() => {
      this.getSpecDoctorList(this.title);
      this.getSpecilityCentre(this.speciality);
    }, 1000);

    this.things.changes.subscribe((t) => {
      // this.ngForRendred();
      this.setSpecialitiesSlider();
      initCustomSelect();
    });
    this.doctorlist.changes.subscribe((t) => {
      initAllPage();
    });
    this.questions.changes.subscribe((t) => {
      initQuestionAccordion();
    });
  }
  setSpecialitiesSlider() {
    //console.log("Specialities Slider Invoked");
    $('.ourSpecialitiess')
      .removeClass('slick-slider')
      .removeClass('slick-initialized')
      .removeClass('slider');
    $('.ourSpecialitiess')
      .not('.slick-initialized')
      .slick({
        slide: '.sitem',
        dots: true,
        arrows: false,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        slidesPerRow: 1,
        adaptiveHeight: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 680,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
  }
  ngOnDestroy() {}
}
