import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL } from '../services/constants';
import { Meta, Title } from '@angular/platform-browser';
declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
declare let initAboutTabs: any;
declare let dataLayerPush: any;

@Component({
  selector: 'packages-plans',
  templateUrl: './packages-plans.component.html',
  styles: [
    `
      .packages_table {
        width: 99.9%;
        margin-top: 10px;
      }
      .table > tbody > tr.warning > td,
      .table > tbody > tr.warning > th,
      .table > tbody > tr > td.warning,
      .table > tbody > tr > th.warning,
      .table > tfoot > tr.warning > td,
      .table > tfoot > tr.warning > th,
      .table > tfoot > tr > td.warning,
      .table > tfoot > tr > th.warning,
      .table > thead > tr.warning > td,
      .table > thead > tr.warning > th,
      .table > thead > tr > td.warning,
      .table > thead > tr > th.warning {
        background-color: #ffebff;
      }
      .packages_table .table > tbody > tr > td,
      .packages_table .table > tbody > tr > th,
      .packages_table .table > thead > tr > td,
      .packages_table .table > thead > tr > th {
        padding: 8px;
      }
      @media only screen and (max-width: 680px) {
        .packages_table > .table > tbody > tr > td,
        .packages_table > .table > tbody > tr > th,
        .packages_table > .table > thead > tr > td,
        .packages_table > .table > thead > tr > th {
          padding: 5px;
        }
      }
      @media only screen and (max-width: 580px) {
        .packages_table > .table > tbody > tr > td,
        .packages_table > .table > tbody > tr > th,
        .packages_table > .table > thead > tr > td,
        .packages_table > .table > thead > tr > th {
          padding: 4px;
        }
      }
      @media only screen and (max-width: 480px) {
        .packages_table > .table > tbody > tr > td,
        .packages_table > .table > tbody > tr > th,
        .packages_table > .table > thead > tr > td,
        .packages_table > .table > thead > tr > th {
          padding: 3px;
        }
      }
      @media only screen and (max-width: 420px) {
        .packages_table {
          font-size: 10px;
        }
        .packages_table > .table > tbody > tr > td,
        .packages_table > .table > tbody > tr > th,
        .packages_table > .table > thead > tr > td,
        .packages_table > .table > thead > tr > th {
          padding: 2px;
        }
      }
      @media only screen and (max-width: 360px) {
        .packages_table > .table > tbody > tr > td,
        .packages_table > .table > tbody > tr > th,
        .packages_table > .table > thead > tr > td,
        .packages_table > .table > thead > tr > th {
          padding: 1px;
        }
      }
    `,
  ],
})
export class OtherPackagesAndPlansComponent
  implements OnDestroy, OnInit, AfterViewInit
{
  constructor(
    private api: ApiService,
    public metaTitle: Title,
    public meta: Meta
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
    }
  }

  public services: any[] = [];
  public brief_facilities: any[] = [];
  public title: any = '';
  public subtitle: any = '';
  public description: any = '';
  public imgsrc: any = '';
  public imgBasePath = '';
  public hidden = 'hidden';
  public logo = 'logo';
  public bread_crumb_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Others', link: '', class: '' },
    { label: 'Packages / Plans', link: '', class: '' },
  ];

  public links: any = [];
  public activePage: string = 'packages_plans';

  public packages: any = [];
  public packageDetails: any = [];
  public activeList: any;
  @ViewChildren('atabs') atabsThings: QueryList<any>;

  Packages;

  getPackageListing(PackageID, center, specialityid) {
    this.api
      .getPackageListing(PackageID, center, specialityid)
      .subscribe((res) => {
        res = res.filter((res) => res.flag !== 0);

        res.forEach((element) => {
          element.DateStr = new Date(element.created_at);
        });
        res = res.sort((a, b) => b.DateStr - a.DateStr);

        this.Packages = res;
        //console.log('Packages', this.Packages);

        this.Packages.forEach((element) => {
          let aaa =
            element.packagesmalldecription.length > 150
              ? element.packagesmalldecription.substring(0, 120) + '...'
              : element.packagesmalldecription;
          //console.log('aaaab', element.packagesmalldecription.length, aaa);
          element.smallDiscription = aaa;

          setTimeout(() => {
            filterBoxWrapTab();
          }, 100);
        });
        this.pageLoadDataLayerPush();
      });
  }

  ngOnInit() {
    this.getPackageListing('', '', '');

    this.api.getPageDescription('packages').subscribe(
      (data) => {
        (this.title = data.page_descriptions[0].title),
          (this.subtitle = data.page_descriptions[0].subtitle),
          (this.description = data.page_descriptions[0].description),
          (this.imgsrc = data.page_descriptions[0].image),
          (this.imgBasePath = IMG_BASE_URL);
        if (data) {
          //console.log(data);
          this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
          this.meta.updateTag({
            name: 'keywords',
            content: data.page_descriptions[0].meta_keyword,
          });
          this.meta.updateTag({
            name: 'description',
            content: data.page_descriptions[0].meta_description,
          });
          this.meta.addTags([
            {
              property: 'og:title',
              content: data.page_descriptions[0].meta_title,
            },
            {
              property: 'og:description',
              content: data.page_descriptions[0].meta_description,
            },
            {
              property: 'og:keywords',
              content: data.page_descriptions[0].meta_keyword,
            },
          ]);
        }
      },
      (error) => {
        console.log(error);
      }
    );
    this.api
      .getOtherSubFooterDetails()
      .map((res) => res)
      .subscribe((data) => {
        this.links = data;
      });

    this.api.getpackageData().subscribe((res) => {
      this.packages = res;
      console.log(this.packages);
      if (this.packages) {
        this.getpackageDetails(
          this.packages.group_sections[0],
          this.packages.group_sections[0].id
        );
      }
    });
  }

  PackagesFilter(event, specialityid) {
    this.getPackageListing('', '', specialityid);
  }

  public getpackageDetails(showDataFor: any, activeId) {
    this.packageDetails = showDataFor;
    this.activeList = activeId;
  }
  pageLoadDataLayerPush() {
    let c_path = window.location.pathname.split('/');
    let pName = c_path[c_path.length - 1];
    pName = pName.replace(/-/gi, '-');
    //eventAction,eventlabel,eventCategory,linkClicked,pageName,pageCategory,pageSubcategory,AppointmentDate,language,pageType,ageofPatient,appointmentId,appointmentdate,appointmentTime,bookforLocation,bookforSpeciality,bookforSubSpeciality,packagePlan,dobofPatient,doctorsName,usermobilenumber,useremail,username,selectedHospitalUnit,typeOfAppointment,userType,patientType,weightofPatient,temperatureofPatient,transactionID,revenue,products,price,Quantity,paymentStatus,message
    dataLayerPush(
      'Packages',
      'PageLoad-Packages',
      'PageLoad-Packages',
      '',
      pName,
      'Packages',
      '',
      '',
      'English',
      pName,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      ''
    );
  }

  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
      initAboutTabs();
    }
  }

  ngOnDestroy() {}
}
const filterBoxWrapTab = () => {
  $('.filterBoxWrap')
    .find('.tab')
    .click((e) => {
      $(this).addClass('Active').siblings().removeClass('Active');
    });
};
