import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL } from '../services/constants';
import { Meta, Title } from '@angular/platform-browser';
declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
declare let initQuestionAccordion: any;
declare let initAboutTabs: any;

@Component({
  selector: 'terms-conditions',
  templateUrl: './terms-conditions.component.html',
})
export class OtherTermsAndConditionsComponent
  implements OnDestroy, OnInit, AfterViewInit
{
  constructor(
    private api: ApiService,
    public metaTitle: Title,
    public meta: Meta
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
    }
  }

  public services: any[] = [];
  public brief_facilities: any[] = [];
  public title: any = '';
  public subtitle: any = '';
  public description: any = '';
  public imgsrc: any = '';
  public imgBasePath = '';
  public hidden = 'hidden';
  public logo = 'logo';
  tncDetails: any;
  public bread_crumb_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Other', link: '', class: '' },
    { label: 'Terms & Conditions', link: '', class: '' },
  ];

  public links: any = [];
  public activePage: string = 'terms_and_conditions';

  @ViewChildren('termsconditions') things: QueryList<any>;

  public TermsNConditions: any;
  public RefundPolicy: any;
  public PrivacyPolicy: any;

  ngOnInit() {
    this.api.getPageDescription('terms-and-conditions').subscribe(
      (data) => {
        (this.title = data.page_descriptions[0].title),
          (this.subtitle = data.page_descriptions[0].subtitle),
          (this.description = data.page_descriptions[0].description),
          (this.imgsrc = data.page_descriptions[0].image),
          (this.imgBasePath = IMG_BASE_URL);
        if (data) {
          //console.log(data);
          this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
          this.meta.updateTag({
            name: 'keywords',
            content: data.page_descriptions[0].meta_keyword,
          });
          this.meta.updateTag({
            name: 'description',
            content: data.page_descriptions[0].meta_description,
          });
          this.meta.addTags([
            {
              property: 'og:title',
              content: data.page_descriptions[0].meta_title,
            },
            {
              property: 'og:description',
              content: data.page_descriptions[0].meta_description,
            },
            {
              property: 'og:keywords',
              content: data.page_descriptions[0].meta_keyword,
            },
          ]);
        }
      },
      (error) => {
        console.log(error);
      }
    );

    this.api
      .getTnCDetails()
      .map((res) => res)
      .subscribe((res) => {
        //console.log(res);
        //this.tncDetails = res;
      });
    this.api
      .getOtherSubFooterDetails()
      .map((res) => res)
      .subscribe((data) => {
        this.links = data;
      });

    this.api.getTermsNConditions().subscribe((data) => {
      //console.log('getTermsNConditions:', data['our_missions']);
      let allpolicis = data['our_missions'];
      let alltnc: any = [];
      allpolicis.forEach((element) => {
        if (element.page == 'terms-and-conditions') {
          this.TermsNConditions = element;
          alltnc.push(element);
        }
        if (element.page == 'privacy-policy') {
          this.PrivacyPolicy = element;
          alltnc.push(element);
        }
        if (element.page == 'refund-policy') {
          this.RefundPolicy = element;
          alltnc.push(element);
        }
      });
      this.tncDetails = alltnc;
      //console.log('alltnc:', alltnc);
      setTimeout(() => {
        initAllPage();
        initQuestionAccordion();
      }, 200);
    });
    // this.api.getHealthCareServices('women-care-about')
    //   .subscribe(
    //     data =>{
    //       this.services = data.healthcare_services
    //     },
    //     error => {
    //       console.log(error);
    //     }
    //   )

    // this.api.getBriefFacilities('women-care-about')
    //   .subscribe(
    //     data =>{
    //       this.brief_facilities = data.brief_facilities
    //     },
    //     error => {
    //       console.log(error);
    //     }
    //   )
  }

  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
    }

    this.things.changes.subscribe((t) => {
      // this.ngForRendred();
      initAboutTabs();
    });
  }

  ngOnDestroy() {}
}
