<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>

    <section class="page wtwedo">
        <div class="row">
            <div class="col-md-5 col-xs-5 col-lg-4">
                <div class="circuleframe">
                    <img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" />
                </div>
                <!-- End Circle -->
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7">
                <div class="pagetitle">
                    <h1 class="tgradient">{{title}} </h1>
                    <div class="underline">
                        <img src="assets/images/underline2.png" alt="" />
                    </div>
                    <h3>{{subtitle}}</h3>
                </div>
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7 mcontentview">
                <p [innerHTML]="description"></p>
            </div>
        </div>

    </section>
    <section class="aboutsectin2 awardswapper">
        <div class="container">
            <div class="row nsg_text">
                <div class="col-xs-12 col-lg-12">
                    <h2>Advantages of Breast Feeding</h2>
                    <ul>
                        <li>Breastfed babies are healthier and have fewer infections as antibodies and other proteins are passed in the breast milk from the mother to the child. Breastfed babies have lesser incidences of chest and ear infections, diarrhoea
                            and vomiting as compared to babies who are not breastfed.</li>
                        <li>According to some studies, performance in childhood intelligence tests was better in children who had been breastfed compared to those who had been bottle fed.</li>
                        <li>Breast feeding enhances the bonding process between baby and mother.</li>
                        <li>Many studies suggest that babies that have been breastfed enjoy long term-health benefits than the babies who have not been breastfed. On an average, children who are breastfed have lesser chances of common health problems such
                            as eczema, diabetes, leukemia, asthma, hypertension, high cholesterol and obesity.</li>
                        <li>Feeding a baby only breast milk exclusively for the first six months provides maximum health benefits and safeguards a child from illnesses in later stages of life. However, even in cases where a baby can only be partially breastfed
                            or where a woman can breastfeed only for a short time, there is still a reduction in the risk of developing the diseases mentioned above.</li>
                        <li>As per studies, women who have breastfed have a lesser risk of having illnesses such as breast cancer, ovarian cancer, type 2 diabetes and postnatal depression.</li>
                        <li>For women who have put on weight post pregnancy, there is good news! It is easier to lose weight after giving birth if a woman is breast feeding.</li>
                        <li>&nbsp;Breast feeding is a very convenient method of feeding the baby as it does not require any preparation and is always available.</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <ng-template [ngIf]="ins_links.length > 0">
        <footer-nav-mini [links]="ins_links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>