<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>

    <section class="page wtwedo">
        <div class="row">
            <div class="col-md-5 col-xs-5 col-lg-4">
                <div class="circuleframe">
                    <img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" />
                </div>
                <!-- End Circle -->
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7">
                <div class="pagetitle">
                    <h1 class="tgradient">{{title}} </h1>
                    <div class="underline">
                        <img src="assets/images/underline2.png" alt="" />
                    </div>
                    <h3>{{subtitle}}</h3>
                </div>
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7 mcontentview">
                <p [innerHTML]="description"></p>
            </div>
        </div>

    </section>
    <section class="aboutsectin2 awardswapper">
        <div class="container">
            <div class="row nsg_text">
                <div class="col-xs-12 col-lg-12">
                    <h2>Position and Attachment</h2>
                    <p>The most important aspect of breastfeeding is correct attachment and position; any complications that may arise in breastfeeding are as a result of bad attachment and position. Basic rules to follow while Breast feeding are:</p>
                    <ul>
                        <li>Baby goes to the breast.</li>
                        <li>Mother sits with a good back support.</li>
                        <li>It’s important that the baby opens the mouth wide and takes the latch on the areola.</li>
                        <li>Ear-shoulder-hip of the baby should be in a straight line (the mother’s abdomen should be in contact with the baby’s abdomen).</li>
                    </ul>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>Flat / inverted nipples</h2>
                    <p>A mother with a flat or inverted nipple has to work a little hard while latching the baby, pulling out the nipple in a tea-cup hold and offering it to the baby can be helpful, nipple puller can be used to pull the nipple out, however
                        when milk starts to flow the nipple softens and the baby looses grip. A nipple shield is recommended for such a situation.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>NICU Admissions</h2>
                    <p>In the case of a NICU admission, the mother needs to constantly stimulate the breast and express milk manually or by using a breast pump. It is important that the demand is put on the breast even if the mother is not making any milk,
                        manually expressing or pumping is a way to communicate to the body to produce milk. Information on rental pumps is available at the nursing station.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>Care of the Breast</h2>
                    <ul>
                        <li>Do not apply soap on the nipple, as soap tends to take away the moisture and make them dry, warm water rinsing is recommended.</li>
                        <li>Make sure that you wear yours brassieres at all times (during night also) to avoid loss of muscle tone.</li>
                        <li>After you are done feeding, squeeze out a little milk from your breast, apply it on the nipple and the areola and let it air dry for 5-10 minutes.</li>
                        <li>In case of engorgement (lumps, heaviness) in the breast, use hot compress on the breast before a feed to melt the lumps and a cold compress after the feed for pain relief.</li>
                    </ul>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>Key to know if baby’s got enough</h2>
                    <ul>
                        <li>Assess baby for a proper latch.</li>
                        <li>Look for rhythmic sucking with pause in jaw excursions.</li>
                        <li>Baby should fall away from the breast sleepy and satisfied.</li>
                        <li>What goes in must come out, reduced urine output means dehydration, keeping a count of number of urine the baby passes is important.</li>
                        <li>For the first day (24 hours) the baby should pass at least one urine, on day 2, 2 urines, day 3, 3 urines, day 4, 4 urines, day 5, 5 urines and from the 6th day, there should be a minimum of 6-7 wet nappies for a day.</li>
                    </ul>
                    <p>Dehydration can be avoided if the baby is fed every two hours, if the baby refuses to latch after a two hours break, it is necessary to give expressed breast milk with a spoon for the first two weeks.</p>
                    <p>Feeding schedule for the infant is 15 minutes of active sucking on each breast whenever the baby demands, i.e., a minimum of half hour gap or maximum of two and half hour gap. A different feeding schedule will be given at your lactation
                        consultant appointment after a week.</p>
                </div>
            </div>
        </div>
    </section>
    <ng-template [ngIf]="ins_links.length > 0">
        <footer-nav-mini [links]="ins_links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>