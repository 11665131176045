<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <section class="page pageheader">
        <div class="aboutcentre-banner container" *ngIf="PackageListing.length > 0">
            <div class="row" *ngFor="let item of PackageListing" >
                <!--<div class="col-xs-12 col-xs-6"><br><br><br>
                    <img src="{{imgBasePath}}{{item.image}}" class="img-responsive" alt="">
                </div>-->
                <div class="col-xs-12 col-xs-12"> 
                    <div class="sheading">
                        <div class="row packegPage">
                            <div class="col-xs-12 col-sm-5">
                                <img src="{{imgBasePath}}{{item.image}}" class="imgInline" alt="">
                            </div>
                            <div class="col-xs-12 col-sm-7">
                                <h3>{{item.packageheading}}</h3>
                        <p [innerHTML]="item.packagesmalldecription"></p>
                        <div class="location"> <img src="../../assets/images/location-icon.png" alt="" srcset=""> <div class="item" *ngFor="let center of item.centerlist">{{center.center_name_alias}}</div></div> 
                        <p><a href="other/packages-plans" class="knowmoreS2"><span>Back</span></a></p>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </div><br>

<hr>
<br>
        <div class="row">
            <div class="container">
                <div class="col-xs-12">
                    <p [innerHTML]="LargeDecription"></p>
                </div>
                
            </div>
        </div><br><br>
        <div class="centreServiceListWrp">
            <div class="launchpreloader"></div>
            <div class="thankYouBox postaddLeads">
                <div class="text">
                    <h3>Thanks for enquiring. Our team will get back to you shortly.</h3>
                </div>                
            </div>
                    <div class="row lightBg">
            <div class="container">
                <div class="col-xs-12" id="cDetailFGetInTouch">
                    <h2>GET IN TOUCH WITH US</h2>
                    <form id="pageForm" [formGroup]="postaddLeadsForm" >
                        <ul class="formcasn">
                            <li>
                                <input type="text" name="name"
                                    [ngClass]="{'has-error':!postaddLeadsForm.controls.name.valid && postaddLeadsForm.controls.name.touched}"
                                    placeholder="Name*" formControlName="name" (keypress)="isletters($event)" required />
                                <span class="alertMsg" *ngIf="nameValid && postaddLeadsForm.controls.name.invalid"> Please enter Name* </span>
                            </li>
                            <li>
                                <input type="text" name="mobile"
                                    [ngClass]="{'has-error':!postaddLeadsForm.controls.mobile.valid && postaddLeadsForm.controls.mobile.touched}"
                                    maxlength="10" placeholder="Mobile*" formControlName="mobile" (keypress)="isNumber($event)" required />
                                <span class="alertMsg" *ngIf="mobileValid && postaddLeadsForm.controls.mobile.invalid">Please enter Mobile*</span>
                            </li>
                            <li>
                                <input type="text" name="email"
                                    [ngClass]="{'has-error':!postaddLeadsForm.controls.email.valid && postaddLeadsForm.controls.email.touched}"
                                    placeholder="Email ID*" formControlName="email" />
                                <span class="alertMsg"
                                    *ngIf="emailValid && postaddLeadsForm.controls.email.invalid">Please enter Email ID</span>
                            </li>
                            <li class="singledropdown">
                                <div class="selectbox">
                                    <select class="select" name="location" (change)="setHospital($event)"   [(ngModel)]="locationModel"
                                        formControlName="location" required>
                                        <option value="null" selected>Select Centre</option>
                                        <option *ngFor="let center of centersList;let i= index;" [value]="center.city">
                                            {{center.center_name_alias}}
                                        </option>
                                    </select>
                                    <span class="select-span" id="locationCtrl"></span>
                                </div>
                                <span class="alertMsg"
                                    *ngIf="locationValid && postaddLeadsForm.controls.location.invalid">Please select Centre</span>
                            </li>
                           
                            <li>
                                <input type="text" name="message" placeholder="Message" formControlName="message" />
                            </li>
                            <li class="w100">
                                <label for="authorization" class="checklabel" placeholder="consent">
                                    <input id="authorization" placeholder="consent" name="authorization"
                                        formControlName="authorization" class="checkbox" type="checkbox"
                                        value="consent" (change)="OnClickAuthorization($event);" required />
                                    I hereby authorize Rainbow Hospitals and their certified partners to contact me
                                    through email, phone or any other modes of communication. It will override my
                                    registry on NCPR
                                </label>
                                <span class="alertMsg"
                                    *ngIf="authorizationValid && postaddLeadsForm.controls.authorization.invalid">Please select
                                    agree the consent</span>
                            </li>
                            <li>
                                <div class="submitbtn">   
                                    <input type="hidden" name="page" id="page" formControlName="page" /> 
                                    <input type="hidden" name="intrestedin" id="intrestedin" formControlName="intrestedin" />                     
                                    <input class="casubmit" id="txtSubmit" name="txtSubmit" type="submit" (click)="onSubmitPost()" value="Submit">
                                </div>
                            </li>
                        </ul>
                    </form>
                </div>                
            </div>
        </div>
        </div><br><br>
    </section>




    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>