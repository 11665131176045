<div class="container-fluid">
    <div class="background">
        <img src="assets/images/bg_childcare.png" data-t="bg_01_00.jpg" data-d="bg_childcare.png"
            class="bgImg bg0 active" />
    </div>
    <div class="loder"></div>
    <sidenav></sidenav>
    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <section class="page pageheader bannerHeder">
        <div class="row">
            <div class="container">
                <div class="col-xs-12 col-sm-5 col-lg-6">
                    <div class="ptitle vhubtitle">
                        <h1>{{title}}</h1>
                        <h3>{{subtitle}}</h3>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-7 col-lg-6 RAINBOWChannelbanner floatright">
                    <div class="banner-Slier" *ngIf="!videoHubBannerListResult">
                        <div id="slider">
                          <div class="dp-wrap" id="dpSlider">
                            <div id="dp-slider">
                              <div class="dp_item" *ngFor="let vBanner of videoHubBannerList;let i = index;" data-lang="{{vBanner.bannerNumber}}" data-id="{{vBanner.bannerNumber}}" (click)="onClickVideoDetails($event, vBanner)">
                                <div class="dp-img"> <div class="videoBanner"><img class="img-fluid" src="{{vBanner.contentimageNew}}" alt="investing"></div> </div>
                                <div class="Bannertext">
                                  <h2>Latest Videos</h2>
                                  <p [innerHTML]="vBanner.contentheading | truncatetext:90"></p>
                                </div>
                              </div>
                            </div>
                            <ul id="dp-dots">
                              <li lang="1" class="active"></li>
                              <li lang="2"></li>
                              <li lang="3"></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                </div>
                
            </div>
        </div>
    </section>
    <section class="rchannelistwrp" style="background: #fff; border-top-color: #fff;">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 col-lg-12 seprateSelectWrp" style="display: none;">
                    <ul class="selectwrp">
                        <li>
                            <div class="specialitywrp s_dd specialitydd">
                                <select class="select" id="cSpecialty" (change)="onChangeCategory($event)">
                                    <option value="" selected>Category</option>
                                    <option *ngFor="let Category of CategoryList;" value="{{Category.ID}}">
                                        {{Category.Category}}</option>
                                </select>
                                <span class="select-span" id="SelectYear">Select Category</span>
                            </div>
                        </li>
                        <li class="subCategory">
                            <div class="specialitywrp subspecialitywrp">
                                <select class="select" id="SubCategory" (change)="onChangeSubCategory($event)">
                                    <option value="" selected>Sub Category</option>
                                    <option *ngFor="let SubCategory of SubCategoryList;" value="{{SubCategory.SUBID}}">
                                        {{SubCategory.SUBVALUE}}</option>
                                </select>
                                <span class="select-span" id="SelectYear">Select Sub Category</span>
                            </div>
                        </li>
                    </ul>
                </div>
                
                <div class="col-md-12 col-sm-12 col-lg-12">
                    <div class="RAINBOWChannelwapper">
                        <hr style="margin-top: 0px;">
                        <div class="lefthedding row">
                          <div class="visitorHead col-xs-12 col-md-12 col-lg-6">
                            <h2 class="fontsize textgradient">Webinars</h2>
                            <p>Watch our in-house specialists discuss health issues and their management</p>
                          </div>
                          <div class="col-xs-12 col-md-12 col-lg-6">
                            <div class="seprateSelectWrp">                    
                                <ul class="selectwrp videoHubSelectWrp">
                                    <li>
                                        <div class="specialitywrp s_dd specialitydd">
                                            <select class="select" id="cSpecialty" (change)="onChangeWebnarSpecialty($event)">
                                                <option value="0" selected>Specialty</option>
                                                <option *ngFor="let specMaster of specilityMasterList;" value="{{specMaster.id}}">{{specMaster.name}}</option>
                                            </select>
                                            <span class="select-span specilityMasterLabel" id="specilityMasterLabel">Specialty</span>
                                        </div>
                                    </li>                                    
                                    <li><div class="inputLoader" id="cWebnarLocation"></div>
                                        <div class="unitwrp s_dd locationdd">
                                            <select class="select" id="centerLocation" (change)="onChangeWebnarLocation($event)">
                                                <option value="0" selected>Location </option>
                                                <ng-template ngFor let-c [ngForOf]="centerList" let-i="index">
                                                    <optgroup *ngIf="c.area.length > 0" label="{{c.city}}">
                                                        <option *ngFor="let branch of c.area" [value]="branch.id">{{branch.center_name_alias}}</option> 
                                                    </optgroup>
                                                </ng-template>
                                            </select>  
                                            <span class="select-span" id="specialityLocationLabel">Location</span>                                          
                                        </div>
                                    </li> 
                                    <li><div class="inputLoader" id="cWebnarSpecialityFeatures"></div>
                                        <div class="unitwrp s_dd sspecialitydd">
                                            <select class="select" id="centerSpecialityFeatures" (change)="onChangeWebnarSpecialityFeatures($event)">
                                                <option value="0" selected>Sub Specialty</option>
                                                <option *ngFor="let sFeature of specialityFeatures" [value]="sFeature.id">{{sFeature.title}}</option>
                                            </select>
                                            <span class="select-span" id="specialityFeaturesLabel">Sub Specialty</span>
                                        </div>
                                    </li>                                                                       
                                </ul>
                            </div>
                          </div>
                        </div>
                        <div class="WebinarListWrp videosWrp active row">
                            <div class="sliderLoader" id="sl_Webnar"></div>
                            <h3 class="noData" *ngIf="videoListResult">There are currently no videos in this category.</h3>
                            <div class="siderbox WebinarSider" *ngIf="!videoListResult">
                                <div class="item" *ngFor="let vhub of videoList; let i = index">
                                    <div class="ImageBox" id="eachImageBoxId_{{i}}">
                                    <div class="img" (click)="onClickVideoDetails($event, vhub)">
                                        <img src="{{vhub.contentimageNew}}" alt="" />
                                        <div class="imageshedow" [innerHTML]="vhub.contentheading | truncatetext:90"></div>
                                    </div>
                                    <a href="javascript:void(0);" class="copylink" (click)="copyToClipboard($event)">
                                        Copy link <span >{{vhub.facebookUrl? vhub.contentvideourl : vhub.contentvideourlNew}}</span>
                                    </a> 
                                    </div>
                                </div>
                            </div>
                            <a class="viewbtn" href="/other/academic-webinar" *ngIf="!videoListResult"> View All</a>
                            <hr *ngIf="!videoListResult">
                        </div>

                        <!-- Start Testimonials-->
                        <div class="lefthedding row">
                            <div class="visitorHead col-xs-12 col-md-12 col-lg-6">
                              <h2 class="fontsize textgradient">Testimonials</h2>
                              <p>Discover happy experiences and success stories from our happy patients</p>
                            </div>
                            <div class="col-xs-12 col-md-12 col-lg-6">
                              <div class="seprateSelectWrp">                    
                                  <ul class="selectwrp videoHubSelectWrp">
                                    <li>
                                        <div class="specialitywrp  s_dd specialitydd">
                                            <select class="select" id="cSpecialty" (change)="onChangeTestimonialSpecialty($event)">
                                                <option value="0" selected>Specialty</option>
                                                <option *ngFor="let specMaster of specilityMasterList;" value="{{specMaster.id}}">{{specMaster.name}}</option>
                                            </select>
                                            <span class="select-span specilityMasterLabel" id="specilityMasterLabel">Specialty</span>
                                        </div>
                                    </li>
                                    <li><div class="inputLoader" id="cTestimonialLocation"></div>
                                        <div class="unitwrp s_dd locationdd">
                                            <select class="select" id="centerLocation" (change)="onChangeTestimonialLocation($event)">
                                                <option value="0" selected>Location </option>
                                                <ng-template ngFor let-c [ngForOf]="centerList" let-i="index">
                                                    <optgroup *ngIf="c.area.length > 0" label="{{c.city}}">
                                                        <option *ngFor="let branch of c.area" [value]="branch.id">{{branch.center_name_alias}}</option> 
                                                    </optgroup>
                                                </ng-template>
                                            </select>  
                                            <span class="select-span" id="specialityLocationLabel">Location</span>                                          
                                        </div>
                                    </li>
                                    <li><div class="inputLoader" id="cTestimonialSpecialityFeatures"></div>
                                        <div class="unitwrp s_dd sspecialitydd">
                                            <select class="select" id="centerSpecialityFeatures" (change)="onChangeTestimonialSpecialityFeatures($event)">
                                                <option value="0" selected>Sub Specialty</option>
                                                <option *ngFor="let sFeature of specialityFeatures" [value]="sFeature.id">{{sFeature.title}}</option>
                                            </select>
                                            <span class="select-span" id="specialityFeaturesLabel">Sub Specialty</span>
                                        </div>
                                    </li>                                    
                                  </ul>
                              </div>
                            </div>
                        </div>
                        <div class="testimonialsWrp videosWrp active row">
                            <div class="sliderLoader" id="sl_Testimonials"></div>
                            <h3 class="noData" *ngIf="testimonialListResult">There are currently no videos in this category.</h3>
                            <div class="siderbox testimonialsSider" *ngIf="!testimonialListResult">
                                <div class="item" *ngFor="let vhub of testimonialList; let i = index">
                                    <div class="ImageBox" id="eachTestimonialBoxId_{{i}}">
                                    <div class="img" (click)="onClickVideoDetails($event, vhub)">
                                        <img src="{{vhub.contentimageNew}}" alt="" />
                                        <div class="imageshedow" [innerHTML]="vhub.contentheading | truncatetext:90"></div>
                                    </div>
                                    <a href="javascript:void(0);" class="copylink" (click)="copyToClipboard($event)">
                                        Copy link <span >{{vhub.facebookUrl? vhub.contentvideourl : vhub.contentvideourlNew}}</span>
                                    </a> 
                                    </div>
                                </div>
                            </div>
                            <a class="viewbtn" href="/all/testimonials" *ngIf="!testimonialListResult"> View All</a>
                            <hr *ngIf="!testimonialListResult">
                        </div>
                        <!-- End Testimonials-->

                        <!-- Start News & Media-->
                        <div class="lefthedding row">
                            <div class="visitorHead col-xs-12 col-md-12 col-lg-6">
                              <h2 class="fontsize textgradient">News & Media</h2>
                              <p>From news coverage to Doctor Bytes, explore the latest videos here</p>
                            </div>
                            <div class="col-xs-12 col-md-12 col-lg-6">
                              <div class="seprateSelectWrp">                    
                                  <ul class="selectwrp videoHubSelectWrp"> 
                                      <li>
                                        <div class="specialitywrp  s_dd specialitydd">
                                            <select class="select" id="cSpecialty" (change)="onChangeNewsNMediaSpecialty($event)">
                                                <option value="0" selected>Specialty</option>
                                                <option *ngFor="let specMaster of specilityMasterList;" value="{{specMaster.id}}">{{specMaster.name}}</option>
                                            </select>
                                            <span class="select-span specilityMasterLabel" id="specilityMasterLabel">Specialty</span>
                                        </div>
                                    </li>
                                    <li><div class="inputLoader" id="cNewsNMediaLocation"></div>
                                        <div class="unitwrp s_dd locationdd">
                                            <select class="select" id="centerLocation" (change)="onChangeNewsNMediaLocation($event)">
                                                <option value="0" selected>Location </option>
                                                <ng-template ngFor let-c [ngForOf]="centerList" let-i="index">
                                                    <optgroup *ngIf="c.area.length > 0" label="{{c.city}}">
                                                        <option *ngFor="let branch of c.area" [value]="branch.id">{{branch.center_name_alias}}</option> 
                                                    </optgroup>
                                                </ng-template>
                                            </select>  
                                            <span class="select-span" id="specialityLocationLabel">Location</span>                                          
                                        </div>
                                    </li>
                                  </ul>
                              </div>
                            </div>
                        </div>
                        <div class="NewsNMediaWrp  videosWrp active row">
                            <div class="sliderLoader" id="sl_NewsNMedia"></div>
                            <h3 class="noData" *ngIf="NewsNMediaListResult">There are currently no videos in this category.</h3>
                            <div class="siderbox NewsNMediaSider" *ngIf="!NewsNMediaListResult">
                                <div class="item" *ngFor="let vhub of NewsNMediaList; let i = index">
                                    <div class="ImageBox" id="eachNewsNMediaBoxId_{{i}}">
                                    <div class="img" (click)="onClickVideoDetails($event, vhub)">
                                        <img src="{{vhub.contentimageNew}}" alt="" />
                                        <div class="imageshedow" [innerHTML]="vhub.contentheading | truncatetext:90"></div>
                                    </div>
                                    <a href="javascript:void(0);" class="copylink" (click)="copyToClipboard($event)">
                                        Copy link <span >{{vhub.facebookUrl? vhub.contentvideourl : vhub.contentvideourlNew}}</span>
                                    </a> 
                                    </div>
                                </div>
                            </div>
                            <a class="viewbtn" href="/about-us/news" *ngIf="!NewsNMediaListResult"> View All</a>
                            <hr *ngIf="!NewsNMediaListResult">
                        </div>
                        <!-- End News & Media-->

                        <!-- Start Events-->
                        <div class="lefthedding row">
                            <div class="visitorHead col-xs-12 col-md-12 col-lg-6">
                              <h2 class="fontsize textgradient">Events</h2>
                              <p>Watch the latest happenings, launches and event coverages here</p>
                            </div>
                            <div class="col-xs-12 col-md-12 col-lg-6">
                              <div class="seprateSelectWrp">                    
                                  <ul class="selectwrp videoHubSelectWrp"> 
                                      <li>
                                        <div class="specialitywrp  s_dd specialitydd">
                                            <select class="select" id="cSpecialty" (change)="onChangeEventSpecialty($event)">
                                                <option value="0" selected>Specialty</option>
                                                <option *ngFor="let specMaster of specilityMasterList;" value="{{specMaster.id}}">{{specMaster.name}}</option>
                                            </select>
                                            <span class="select-span specilityMasterLabel" id="specilityMasterLabel">Specialty</span>
                                        </div>
                                    </li>
                                    <li><div class="inputLoader" id="cEventLocation"></div>
                                        <div class="unitwrp s_dd locationdd">
                                            <select class="select" id="centerLocation" (change)="onChangeEventLocation($event)">
                                                <option value="0" selected>Location </option>
                                                <ng-template ngFor let-c [ngForOf]="centerList" let-i="index">
                                                    <optgroup *ngIf="c.area.length > 0" label="{{c.city}}">
                                                        <option *ngFor="let branch of c.area" [value]="branch.id">{{branch.center_name_alias}}</option> 
                                                    </optgroup>
                                                </ng-template>
                                            </select>  
                                            <span class="select-span" id="specialityLocationLabel">Location</span>                                          
                                        </div>
                                    </li>
                                  </ul>
                              </div>
                            </div>
                        </div>
                        <div class="EventsWrp videosWrp active row">
                            <div class="sliderLoader" id="sl_Events"></div>
                            <h3 class="noData" *ngIf="eventsListResult">There are currently no videos in this category.</h3>
                            <div class="siderbox EventsSlider" *ngIf="!eventsListResult">
                                <div class="item" *ngFor="let vhub of eventsList; let i = index">
                                    <div class="ImageBox" id="eachEventsBoxId_{{i}}">
                                    <div class="img" (click)="onClickVideoDetails($event, vhub)">
                                        <img src="{{vhub.contentimageNew}}" alt="" />
                                        <div class="imageshedow" [innerHTML]="vhub.contentheading | truncatetext:90"></div>
                                    </div>
                                    <a href="javascript:void(0);" class="copylink" (click)="copyToClipboard($event)">
                                        Copy link <span >{{vhub.facebookUrl? vhub.contentvideourl : vhub.contentvideourlNew}}</span>
                                    </a> 
                                    </div>
                                </div>
                            </div>
                            <a class="viewbtn" style="bottom: 7px;" href="/about-us/events" *ngIf="!eventsListResult"> View All</a>
                        </div>
                        <!-- End Events-->
                        
                      </div>
                </div>
            </div>
        </div>
    </section>
    <div class="customloder"></div>
    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>


<!-- Modal -->
<div id="modal2" class="c_modal modal--align-top modal__bg" (click)="onClickOverFlow($event);">
    <div class="modal__dialog">
        <a href="javascript:void(0);" (click)="closeVideoModal()" class="modal__close demo-close">X</a>
        <div class="modal__content" *ngFor="let vd of VideoDetails">            
            <div class="videoWrapper" *ngIf="contentvideourlexit">
                <iframe id="iframeVideo" [src]="urlSafe" scrolling="no" frameborder="0" allowTransparency="true"  allow="autoplay; encrypted-media" controls="0" allowfullscreen></iframe>
                <div class="Bannertext"><h2>{{vd.contentheading}}</h2></div>
            </div>
            <div class="videoWrapper facebookVideoUrl" *ngIf="!contentvideourlexit">
                <iframe id="iframeFacebookVideo" [src]="facebookUrlSafe" scrolling="no" frameborder="0" allowTransparency="true"  allow="autoplay; encrypted-media" controls="0" allowfullscreen></iframe>
                <div class="Bannertext"><h2>{{vd.contentheading}}</h2></div>
            </div>
            <!--<div class="modalBannerheading" *ngIf="!contentvideourlexit">
                <h2>{{vd.contentheading}}</h2>
            </div>-->
            <div class="date">
                <span class="location" *ngIf="vd.contentlocation != null">{{vd.contentlocation}}</span>
                <span class="dateicon" *ngIf="vd.contentdate != null">{{vd.contentdate}}</span>
                <span class="connect" *ngIf="vd.primaryfilterText != null">{{vd.primaryfilterText}}</span>  
                <span class="copy" (click)="copyToClipboard($event)" *ngIf="vd.contentvideourl != null">Copy link <span>{{vd.contentvideourl}}</span> </span>
            </div>
            <div class="vDecription">
                <!--<h5 *ngIf="vd.contentsmalldecription != null"><span>{{vd.contentsmalldecription}}</span></h5>-->
                <h5 *ngIf="vd.doctorlist != ''"><span  *ngFor="let dl of vd.doctorlist">{{dl.name}}</span></h5>
                <div class="lDecription" [innerHtml]="vd.contentlargedecription"></div>
            </div>             
        </div>        
    </div>
</div>