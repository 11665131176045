<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>

    <section class="page wtwedo">
        <div class="row">
            <div class="col-md-5 col-xs-5 col-lg-4">
                <div class="circuleframe">
                    <img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" />
                </div>
                <!-- End Circle -->
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7">
                <div class="pagetitle">
                    <h1 class="tgradient">{{title}} </h1>
                    <div class="underline">
                        <img src="assets/images/underline2.png" alt="" />
                    </div>
                    <h3>{{subtitle}}</h3>
                </div>
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7 mcontentview">
                <p [innerHTML]="description"></p>
            </div>
        </div>

    </section>
    <section class="aboutsectin2 awardswapper">
        <div class="container">
            <div class="row nsg_text">
                <div class="col-xs-12 col-lg-12">
                    <h2>Urge Urinary Incontinence</h2>
                    <p>Urge incontinence is a common form of incontinence. Urge incontinence is when you get an urgent desire to pass urine and sometimes urine leaks before you have time to get to the toilet. It is usually due to an 'overactive bladder'.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>Understanding urine and the bladder</h2>
                    <p>The kidneys make urine all the time. A trickle of urine is constantly passing to the bladder down the ureters (the tubes from the kidneys to the bladder). You make different amounts of urine depending on how much you drink, eat and
                        sweat.
                    </p>
                    <p>The bladder is made of muscle and stores the urine. It expands like a balloon as it fills with urine. The outlet for urine (the urethra) is normally kept closed. This is helped by the muscles beneath the bladder that sweep around the
                        urethra (the pelvic floor muscles). When a certain amount of urine is in the bladder, you become aware that the bladder is getting full. When you go to the toilet to pass urine, the bladder muscle contracts (squeezes), and the
                        urethra and pelvic floor muscles relax.</p>
                    <p>Complex nerve messages are sent between the brain, the bladder, and the pelvic floor muscles. These tell you how full your bladder is, and tell the right muscles to contract or relax at the right time.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>What causes Urge Incontinence?</h2>
                    <p>The cause is not fully understood. The bladder muscle seems to become overactive and contract (squeeze) when you don't want it to.</p>
                    <p>Normally, the bladder muscle (detrusor) is relaxed as the bladder gradually fills up. As the bladder is gradually stretched, we get a feeling of wanting to pass urine when the bladder is about half full. Most people can hold on quite
                        easily for some time after this initial feeling until a convenient time to go to the toilet arises. However, in people with overactive bladder and urge incontinence, the bladder muscle seems to give wrong messages to the brain.
                        The bladder may feel fuller than it actually is. This means that the bladder contracts too early when it is not very full, and not when you want it to. This can make you suddenly need the toilet. In effect, you have much less control
                        over when your bladder contracts to pass urine.</p>
                    <p>In most cases, the reason why an overactive bladder develops is not known. This is called 'overactive bladder syndrome' or 'idiopathic urge incontinence'. Symptoms may get worse at times of stress. Symptoms may also be made worse by
                        caffeine in tea, coffee, cola, etc, and by alcohol .Also, similar symptoms may occur if there is irritation in the bladder. For example, if you have a urine infection or stones in your bladder.</p>
                    <p>In some cases, symptoms of an overactive bladder develop as a complication of a nerve or brain related disease such as following a stroke, or after spinal cord damage/injury.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>What are the treatments for urge incontinence?</h2>
                    <p><strong>Avoid Caffeine.</strong> This is in tea, coffee, cola, and is part of some painkiller tablets. Caffeine has a diuretic effect (will make urine form more often). Caffeine may also directly stimulate the bladder to make urgency
                        symptoms worse. It may be worth trying without caffeine for a week or so to see if symptoms improve. If symptoms do improve, you may not want to give up caffeine. However, you may wish to limit the times that you have a caffeine-containing
                        drink. Also, you will know to be near to a toilet whenever you have caffeine.</p>
                    <p><strong>Drink normal quantities of fluids</strong>. It may seem sensible to cut back on the amount that you drink so that the bladder does not fill so quickly. However, this can make symptoms worse as the urine becomes more concentrated
                        which may irritate the bladder muscle. Aim to drink normal quantities of fluids each day. This is usually about two litres of fluid per day - about 6-8 cups of fluid, and more in hot climates and hot weather.</p>
                    <p><strong>Go to the toilet only when you need to</strong>. Some people get into the habit of going to the toilet more often than they need. They may go when their bladder only has a small amount of urine . This again may sound sensible
                        as some people think that symptoms of urgency and urge incontinence will not develop if the bladder does not fill very much and is emptied regularly. However, again, this can make symptoms worse in the long-run. If you go to the
                        toilet too often, the bladder becomes used to holding less urine. The bladder may then become even more sensitive and overactive at times when it is stretched a little. So, you may find that when you need to hold on a bit longer
                        (for example, if you go out), symptoms are worse than ever.</p>
                    <p><strong>Lose weight if you are overweight.</strong> If you have urge incontinence or an overactive bladder, symptoms may improve if you lose some weight if you are overweight.</p>
                    <p><strong>Getting to the toilet.</strong> Make this as easy as possible. If you have difficulty getting about, consider special adaptations like a handrail or a raised seat in your toilet. Sometimes a commode in the bedroom makes life
                        much easier.</p>
                    <p><strong>Bladder Training<br></strong>The aim is to slowly stretch the bladder so that it can hold larger and larger volumes of urine. In time, the bladder muscle should become less overactive and you should become more in control of
                        your bladder. This means that more time can elapse between feeling the desire to pass urine and having to get to a toilet. Leaks of urine are then less likely. A doctor will explain how to do bladder training.</p>
                    <p><strong>Medicines:</strong><br> If there is not enough improvement with bladder training alone, medicines in the class of drugs called antimuscarinics (also called anticholinergics) may also help.&nbsp; They work by blocking certain
                        nerve impulses to the bladder which 'relaxes' the bladder muscle and so increases the bladder capacity.</p>
                    <p>Medication may improve symptoms further in some cases. The amount of improvement varies from person to person. You may have fewer toilet trips, fewer urine leaks, and less urgency. However, it is uncommon for symptoms to go completely
                        with medication alone. A common plan is to try a course of medication for a month or so. If it is helpful, you may be advised to continue for up to six months or so and then stop the medication to see how symptoms are without the
                        medication. Symptoms may return after you finish a course of medication. However, if you combine a course of medication with bladder training, the long-term outlook may be better and symptoms may be less likely to return when you
                        stop the medication. So, it is best if the medication is used in combination with the bladder training.</p>
                    <p>Side-effects are quite common with these medicines, but are often minor and tolerable.&nbsp; The most common is a dry mouth, and sucking on something sour or minty may overcome this. Other common side-effects include dry eyes, constipation
                        . However, the medicines have differences, and and you may find that if one medicine causes troublesome side-effects, a switch to a different one may suit you better.</p>
                    <p>Many women are afraid to mention their problem. They may have urinary incontinence that can improve with treatment but remain silent sufferers and resort to wearing absorbent undergarments, or diapers. This practice is unfortunate,
                        because diapering can lead to diminished self-esteem, as well as skin irritation and sores.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>Stress Urinary Incontinence</h2>
                    <p>Stress incontinence is caused by activities (such as coughing, sneezing, laughing, running, or lifting) that apply pressure to a full bladder. In these situations there is a sudden extra pressure ('stress') inside the abdomen and on
                        the bladder. Small amounts of urine may leak, but sometimes it may be quite a lot and cause embarrassment. The common reason for the pelvic floor muscles to become weakened is childbirth. Stress incontinence is common in women
                        who have had several children. It is also more common with increasing age and with obesity.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>What causes stress incontinence?</h2>
                    <ul>
                        <li>The bladder stores the urine until it is eliminated from the body via a tube called the urethra, which is the lowest part of the urinary tract.</li>
                        <li>The connection between the bladder and the urethra is called the bladder neck. Strong muscles called sphincter muscles encircle the bladder neck (the smooth internal sphincter muscles) and urethra (the fibrous external sphincter
                            muscles). In women with stress urinary incontinence</li>
                        <li>The urethra fails to close and becomes overly movable (urethral hypermobility).</li>
                        <li>The muscles around the bladder neck weaken (intrinsic sphincteric deficiency, or ISD). Some doctors believe that this problem is present to some degree in nearly all women with stress incontinence.</li>
                        <li>Many women are prone to one or both of these problems, which can occur under the following circumstances:</li>
                        <li>Having had many children through vaginal deliveries. In such cases, pregnancy and childbirth strain the muscles of the pelvic floor. Prolapsed uterus, in which the uterus protrudes into the vagina, occurs in about half of all women
                            who have given birth. This condition can often cause incontinence.</li>
                        <li>Menopause. Estrogen deficiencies after menopause can cause the urethra to thin out so that it may not close properly.</li>
                    </ul>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>What are the treatment options for stress incontinence?</h2>

                    <p>The usual first treatment is to strengthen the pelvic floor muscles. About 6 in 10 cases of stress incontinence can be cured or much improved with this treatment. Surgery may be advised if the problem persists and pelvic floor exercises
                        have not worked well. In some women, instead of surgery, medication may be advised in addition to pelvic floor exercises. This is usually in women who do not want to have surgery or who have health problems that may mean that surgery
                        is not suitable.</p>
                    <p>Strengthening the pelvic floor muscles - 'pelvic floor exercises'</p>
                    <p>It is important that you exercise the correct muscles. Your doctor may refer you to a continence advisor or physiotherapist for advice on the exercises. The sort of exercises are as follows.</p>

                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>Learning to exercise the right muscles</h2>
                    <p>Sit in a chair with your knees slightly apart. Imagine you are trying to stop wind escaping from your anus (back passage). You will have to squeeze the muscle just above the entrance to the anus. You should feel some movement in the
                        muscle. Don't move your buttocks or legs.</p>
                    <p>Now imagine you are passing urine and are trying to stop the stream. You will find yourself using slightly different parts of the pelvic floor muscles to the first exercise (ones nearer the front). These are the ones to strengthen.
                        If you are not sure that you are exercising the right muscles, put a couple of fingers into your vagina. You should feel a gentle squeeze when doing the exercises.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>Doing the exercises</h2>
                    <ul>
                        <li>You need to do the exercises every day.</li>
                        <li>Sit, stand or lie with your knees slightly apart. Slowly tighten your pelvic floor muscles under the bladder as hard as you can. Hold to the count of five, then relax. Repeat at least five times. These are called slow pull-ups.</li>
                        <li>Then do the same exercise quickly for a second or two. Repeat at least five times. These are called fast pull-ups.</li>
                        <li>Keep repeating the five slow pull ups and the five fast pull-ups for five minutes.</li>
                        <li>Aim to do the above exercises for about five minutes at least three times a day, and preferably 6-10 times a day.</li>
                        <li>Ideally, do each five minute bout of exercise in a different position each time. That is, sometimes when sitting, sometimes when standing, and sometimes when lying down.</li>
                        <li>As the muscles become stronger, increase the length of time you hold each slow pull-up. You are doing well if you can hold each slow pull-up for a count of 10 (about 10 seconds).</li>
                        <li>Do not squeeze other muscles at the same time as you squeeze your pelvic floor muscles. For example, do not use any muscles in your back, thighs, buttocks, or stomach.</li>
                        <li>In addition to the times you set aside to do the exercises, try to get into the habit of doing pelvic floor exercises whilst going about everyday life. For example, when answering the phone, when washing up, etc.</li>
                        <li>After several weeks the muscles will start to feel stronger. You may find you can squeeze the pelvic floor muscles for much longer without the muscles feeling tired.</li>
                    </ul>
                    <p>It takes time, effort and practice to become good at these exercises. It is advised that you do these exercises for at least three months to start with. You should start to see benefits after a few weeks. However, it often takes 8-20
                        weeks for most improvement to occur. After this time you may be cured from stress incontinence. If you are not sure that you are doing the correct exercises, ask a doctor, physiotherapist or continence advisor for advice.</p>
                    <p>If possible, continue exercising as a part of everyday life for the rest of your life to stop the problem recurring. Once incontinence has gone, you may only need to do 1-2 five minute bouts of exercise each day to keep the pelvic
                        floor muscles strong and toned up, and incontinence away.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>Lifestyle Changes</h2>
                    <p>Obesity. It is known that stress incontinence is more common in women who are obese. If you are obese, losing weight may help to ease the problem.</p>
                    <p>Diet and Weight Control. In women, pelvic floor muscle tone weakens with significant weight gain, so women are urged to eat healthy foods in moderation and to exercise regularly. Constipation can worsen urinary incontinence, so diets
                        should be high in fiber, fruits, and vegetables.</p>
                    <p>Fluid Intake. A common misconception among people with incontinence is that drinking lesswater will prevent accidents. In reality, limiting fluid intake has the following effects:</p>
                    <ul>
                        <li>The lining of the urethra and bladder becomes irritated, which may actually increase leakage.</li>
                        <li>Concentrated urine also has a stronger pungency, so drinking plenty of fluids can help reduce odor.</li>
                    </ul>
                    <p>People with incontinence, however, should stop drinking beverages 2 - 4 hours before going to bed, particularly those who experience leakage or accidents during the night.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>Fluid and Food Restrictions</h2>
                    <p>A number of foods and beverages may increase incontinence. Some doctors suggest that people who eat or drink the following items should try eliminating one a day over a 10-day period and check to see if removing them improves continence:</p>
                    <ul>
                        <li>Caffeinated beverages</li>
                        <li>Carbonated beverages such as soda</li>
                        <li>Alcoholic beverages</li>
                        <li>Spicy foods</li>
                        <li>Chocolate</li>
                        <li>Sugars and honey</li>
                        <li>Artificial sweeteners</li>
                    </ul>
                    <p><strong>Surgery</strong>Various surgical operations are used to treat stress incontinence. They tend only to be used when the pelvic floor muscle exercises have not helped. The operations aim to 'tighten' or support the muscles and
                        structures below the bladder. Surgery is often successful.</p>
                    <p><strong>Can stress incontinence be prevented? </strong>If you do regular pelvic floor exercises (as described above) during pregnancy and after you have a baby, then stress incontinence is less likely to develop following childbirth
                        and in later life.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>Bed Wetting</h2>
                    <p>Bedwetting&nbsp;when asleep is very common in children, especially those under the age of about six or seven. It is also known as nocturnal enuresis. Bedwetting&nbsp;usually resolves itself by the age of about seven.In some children
                        if the problem is not addressed in time it can persist into adulthood. It is therefore important that the problem is addressed in childhood itself and not left to persist into older age groups.</p>
                    <p>In a lot of cases, there is no obvious cause. To achieve control over the bladder; we have to learn how to recognize the feeling that they need to go to the toilet.&nbsp;&nbsp;Although there is no definite cause, it has been suggested
                        that&nbsp;bedwetting&nbsp;may be linked to problems with messages between the bladder and the brain.&nbsp;&nbsp;Urine is stored in the urinary bladder. The urinary bladder is connected via nerves to the brain. When the urinary
                        bladder gets full at night, the signal from the urinary bladder is sent up to the brain through this nervous system. The brain then activates a reticular system which wakes us up.</p>
                    <p>However, in people who wet their beds, this nervous system could be at fault. Due to this their brains do not get the message even if their bladders are full. The result is that these people wet their beds.</p>
                    <ul type="disc">
                        <li><strong>A small bladder capacity is one theory.</strong>&nbsp;Some people with&nbsp;bedwetting&nbsp;go to the toilet more often than average during the day. They tend not to be able to 'hold on', and have more 'overactive' bladders.
                            If this is a cause, it usually improves as the child gets older.</li>
                        <li><strong>A slight hormone imbalance is another theory.</strong>&nbsp;People with a&nbsp;bedwetting&nbsp;problem may not make as much hormone called ADH (antidiuretic hormone) at night as non-bedwetting&nbsp;children. This hormone
                            reduces the amount of urine that you make. With less ADH, you tend to make more urine. If this is a factor, it tends to improve as the child gets older.</li>
                        <li><strong>Bladder awareness at night.</strong>&nbsp;A person with a&nbsp;bedwetting&nbsp;problem may also be less aware of a full bladder, and not wake up so easily to go to the toilet when their bladder is full. Again, if this is
                            a factor, it tends to improve as the child gets older.</li>
                        <li><strong>Genetic factors.</strong>&nbsp;Bedwetting&nbsp;often runs in families. About 1 in 7 children who are late in being dry have a parent, brother, or sister who also had this problem in childhood.</li>
                    </ul>
                    <p>Bedwetting&nbsp;is sometimes a sign that the person is upset about something. Encourage your child to talk to you or another adult that they trust, such as a teacher, if they are worried about anything.</p>
                    <p>Some things are thought to make&nbsp;bedwetting&nbsp;worse or more likely. They may 'tip the balance' in some children on some nights. These include the following:</p>
                    <ul type="disc">
                        <li><strong>Times of stress</strong>&nbsp;may start up&nbsp;bedwetting&nbsp;again after a period of dryness. For example: starting new school or college, arrival of a new baby, illness, moving house, bullying, abuse, etc.</li>
                        <li><strong>Drinks and foods that contain caffeine.</strong>&nbsp;These include tea, coffee, cola, and chocolate. Caffeine increases the amount of urine made by the kidneys (it is a 'diuretic').</li>
                        <li><strong>Constipation.</strong>&nbsp;Large stools (faeces) in the rectum may press on and irritate the back of the bladder. In particular, people who have chronic (persistent) constipation are more likely to have a&nbsp;bedwetting&nbsp;problem.</li>
                        <li>Bedwetting&nbsp;may be linked to diabetes or a urinary tract infection (which can irritate the bladder).</li>
                    </ul>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>Treatment</h2>
                    <p>If the child is under the age of six there's usually no need to seek treatment. If your child is older than this, consult a doctor who can do a urine test to rule out a physical cause such as an infection. They might need other tests
                        such as a scan of the abdomen.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>Patience, reassurance and love</h2>
                    <p>Even if your child is&nbsp;bedwetting&nbsp;when they start school, there is a high chance that it will stop soon. There is a great variation in when children become naturally dry at night.<br> <br> Do not punish children for&nbsp;bedwetting.
                        It is not their fault. Rather, they should be praised and made a fuss of if you notice any improvement. Try to be sensitive to any family or school disruption that might be stressful to your child. If&nbsp;bedwetting&nbsp;appears
                        after a period of dryness, it may reflect a hidden stress or fear (such as bullying at school, etc).</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>Explaining to children</h2>
                    <p>It needs a child's co-operation to be dry at night. As soon as your child is old enough to understand, a simple explanation on the following lines can be helpful. "The body makes water (wee) all the time and stores it in the bladder.
                        The bladder is like a balloon which fills up with water. We open the 'tap' when the bladder gets full. The bladder fills up at night when we are asleep. However, the bladder tap should not go to sleep, and should wake us up when
                        the bladder is full."</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>Child's responsibility</h2>
                    <p>When old enough (about age five or six), encourage your child to help change the wet sheets. It may be quicker for parents to do it, but many children respond to being given responsibility. It might also give extra motivation for them
                        to get out of bed and go to the toilet to avoid the chore of changing the sheets. Try to make it a 'matter of fact' routine with as little fuss as possible.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>Getting up</h2>
                    <p>Make sure there are no hidden fears or problems about getting up at night. For example, fear of the dark or spiders, getting up from a top bunk. Try leaving the bathroom light on.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>Drinking fluids</h2>
                    <p>Restricting drinking fluids sounds sensible, but it does not help to cure&nbsp;bedwetting. The bladder has to get used to filling up and holding on to urine. If you limit drinks all day then the bladder cannot 'train itself up'. A
                        sensible plan is to give drinks to your child if he or she is thirsty in the 2-3 hours before bedtime, but not to have extra drinks for pleasure.&nbsp;<strong>Do not restrict drinks for the rest of the day.&nbsp;</strong>Most people
                        should drink about 6-8 cups of fluid a day. Also, as mentioned above, caffeine in tea, coffee, cola, and chocolate may make&nbsp;bedwetting&nbsp;worse. So these are best avoided in the few hours before bedtime.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>Lifting</h2>
                    <p>It is common practice to wake children up to take them to the toilet several hours after they go to sleep. However, this 'lifting' is of little use, and may even prolong the problem. The child has to get used to waking up when their
                        bladder is full. Children often do not remember being lifted, and it usually does not help to achieve their own bladder control. However, make sure your child goes to the toilet just before bedtime.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>Constipation</h2>
                    <p>Treatment of constipation often cures bedwetting too. Treatment of the constipation also cured the bedwetting in 2 out of 3 of those who had a problem with bedwetting.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>Nights away</h2>
                    <p>A common worry is that staying at friends or relatives will be embarrassing. However, it is not uncommon to find the bedwetting stops for the nights away in a strange bed. A few days away with an understanding relative or friend may
                        result in dry nights. This may be a very positive experience and encouraging for your child.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>Practical measures</h2>
                    <p>Use waterproof covers for mattress and duvet, and use absorbent quilted sheets. A moisturiser cream is useful to rub on the skin that is likely to become wet to prevent chaffing and soreness.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>Reward systems</h2>
                    <p>Briefly, you agree a reward with your child if they achieve a goal. Often the goal is not a complete dry night (as most children who wet the bed have no control over their wetting.) An agreed goal could be: going to the toilet before
                        going to bed, getting up and telling the parents they are wet, helping to remake the bed, etc. A goal of a dry night may be appropriate in some cases when the situation is improving. A common example of a reward system is a star
                        chart. This is simply a calendar with a space for each day. A child places a sticky star on each day following a good night (where the goal was achieved) and left blank for a poor night. You may agree a 'reward' for a number of
                        stars.
                    </p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2></h2>
                    <p></p>
                </div>
            </div>
        </div>
    </section>
    <ng-template [ngIf]="ins_links.length > 0">
        <footer-nav-mini [links]="ins_links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>