import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  ViewChildren,
  QueryList,
  EventEmitter,
  Output,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL } from '../services/constants';
import { Meta, Title } from '@angular/platform-browser';
// import { Http, RequestOptions, URLSearchParams, Headers } from "@angular/http";
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
} from '@angular/forms';
import { DatePipe } from '@angular/common';

declare let $: any;
declare let initHome: any;
declare let initAllPage: any;

declare let initAboutTabs: any;
declare let initPopup: any;

@Component({
  selector: 'cancel',
  templateUrl: './cancel.component.html',
  styles: [
    `
      .caDetails {
        font-size: 1.1em;
      }
      .caDetails span {
        font-weight: bold;
        font-size: 1.1em;
        color: #773996;
        display: inline-block;
        margin: 4px 0px;
      }
      .pagetitle h3 {
        font-size: 1.8em;
        margin: 0px 0px 0px;
      }
      .timetablewrp .dconfirmawrp .ttheader2 ul li input.casubmit {
        color: #fff;
        height: 45px;
        line-height: 20px;
        text-transform: uppercase;
        font-family: 'dinrg';
        font-size: 25px;
        background: #3d2979;
        border: 1px solid #3d2979;
        border-radius: 30px;
        padding: 10px 30px;
        width: auto;
      }
      .calculatorwrp {
        border: 0px solid #fcf2ff;
      }
      .calculatorwrp .LCTablewrp {
        width: 100%;
        margin: 0px;
      }
    `,
  ],
})
export class CancelComponent implements OnDestroy, OnInit, AfterViewInit {
  today = Date.now();
  public hidden = 'hidden';
  public logo = 'logo';
  imgBasePath: any;
  searchAppointmentData: any;
  public links: any = [];
  public title: any = '';
  public searchAppointmentForm: any;
  public cancelAppointmentForm: any;
  public cancelAppointmentReasonForm: any;
  public otpMobileNumber: any;
  public otpMsg: string = '';
  public CSendOTPForm: any;
  public ConfirmBookingData: any;
  public patientMobile: any;
  public patientConfirmID: any;
  public ConfirmBookingDataTable: boolean = false;
  public checkmoble: boolean = false;
  public cancelAppointmentData: any;
  public appointmentID: any;
  public currentTimeEntry: any;
  public noMoreBooking: boolean = false;
  public loader2: boolean = false;
  public cancelAppointmentId: any;
  public checkGetBankForm: boolean = false;
  public yesnobtn: boolean = true;
  public cReasonForm: boolean = false;
  public cancellationAPIData: any;
  public AppointmentCancellationBankData: any;
  public closePopupBTN: boolean = false;
  public remark1: any;
  public cReason: any;
  public prReason: any;
  public amountPaid: any;
  public amountPaidStatus: boolean = false;
  submitted = false;
  lPatient_UID: any;
  nBookingUID: any;
  remarkRR: any = 0;
  public cancel_appointment_details: any;
  public AppointmentCancellationPostData: any;
  public AppointmentDataUpdateToClientDB: any;
  public OnlineRefund: boolean = false;
  public cancellationtype: any;

  @Output() close: EventEmitter<any> = new EventEmitter();

  constructor(
    private api: ApiService,
    public formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private http: HttpClient,
    public datepipe: DatePipe,
    public metaTitle: Title,
    public meta: Meta
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
    }
  }

  ngOnInit() {
    this.lPatient_UID = localStorage.getItem('lPatient_UID');

    this.searchAppointmentForm = this.formBuilder.group({
      AppointmentID: [''],
      PMOBILENO: [''],
    });

    this.cancelAppointmentForm = this.formBuilder.group({
      AccountHolderName: [''],
      BankAccountNumber: [''],
      IFSCCode: [''],
      remarkRefundReuse: ['', [Validators.required]],
      CancellationReason: [''],
      updateOn: 'blur',
    });

    this.cancelAppointmentReasonForm = this.formBuilder.group({
      aCancellationReason: [''],
    });

    this.validateCSendOTPForm();
    /* For Onload check searchAppointmentDetails:
        this.searchAppointmentData = {
			"account_number":"",
			"patient_mobile":"9890010253"
		}
        this.api.searchAppointmentDetails(this.searchAppointmentData).subscribe(res=>{
            console.log('searchAppointmentDetails', res);
            this.loader2 = false;
            this.ConfirmBookingDataTable = true;
            this.ConfirmBookingData = [] = res;
                        
            if(res.length == undefined){
                if(res.count == 0){
                    this.noMoreBooking = true;
                }else{
                    this.noMoreBooking = false;
                }
            }
            
		});
         */
  }

  GetPatientUID(id) {
    this.api.getPatient_Uid(id).subscribe((res) => {
      console.log('getPatient_Uid', res);
      this.cancel_appointment_details = res;
      let pUid = res[0].patient_uid;
      this.GetAppointmentForPatient(pUid);
    });
    /*
        if(this.lPatient_UID != ""){
            this.GetAppointmentForPatient(this.lPatient_UID);
        }else{
            this.api.getPatient_Uid(id).subscribe(res=>{ 
                console.log('getPatient_Uid', res); 
                let pUid = res[0].patient_uid;
                this.GetAppointmentForPatient(pUid);
            });
        }
        */
  }

  GetAppointmentForPatient(id) {
    console.log('GetAppointmentForPatient ID:', id);
    this.api.GetAppointmentForPatientID(id).subscribe((res) => {
      console.log('GetAppointmentForPatientID', res);
      //let cRes = {"GetAppointmentForPatientResult":[{"A":3370680,"B":"Booked","C":"Rainbow Children's Hospital - Banjara Hills","D":1644808,"E":"Dr. FAISAL B NAHDI ","F":151,"G":null,"H":5409,"I":"PEDIATRICS","J":"\/Date(1607775600000+0530)\/","K":"\/Date(1607775600000+0530)\/","L":"\/Date(1607776200000+0530)\/","M":null,"N":"BAHTMP297939","O":"rkguptak  ","P":"9619079190","Q":null,"R":"Online.","S":"EVENING SESSION MON TO SAT EXC TUES 5PM TO 6.30PM","T":822,"U":null,"V":null}]}
      this.nBookingUID = res['GetAppointmentForPatientResult'][0].A;
      console.log('nBookingUID', this.nBookingUID);
    });
  }
  closePopup() {
    $('.capopup').hide();
    this.loader2 = false;
  }
  onChangerRRR(e) {
    this.cancelAppointmentForm.controls.AccountHolderName.setValue('');
    this.cancelAppointmentForm.controls.BankAccountNumber.setValue('');
    this.cancelAppointmentForm.controls.IFSCCode.setValue('');
    this.cancelAppointmentForm.controls.CancellationReason.setValue('');
    let eValue = e.target.value;
    if (eValue == 'Online Refund') {
      this.cancellationtype = 0;
      this.OnlineRefund = true;
      this.AccountFormValidationRequireUpdate();
    } else if (eValue == 'Reuse in Hospital') {
      this.OnlineRefund = false;
      this.cancellationtype = 1;
      this.AccountFormValidationNotRequireUpdate();
    }
    if (eValue == '') {
      $('#remarkRefundReuse').text('Select Remark');
      this.OnlineRefund = false;
    } else {
      $('#remarkRefundReuse').text(e.target.value);
    }
  }
  AccountFormValidationRequireUpdate() {
    this.cancelAppointmentForm.controls.AccountHolderName.setValidators(
      Validators.required
    );
    this.cancelAppointmentForm.controls.AccountHolderName.updateValueAndValidity();
    this.cancelAppointmentForm.controls.BankAccountNumber.setValidators(
      Validators.compose([
        Validators.required,
        Validators.pattern('^[0-9]+(.?[0-9]+)?$'),
      ])
    );
    this.cancelAppointmentForm.controls.BankAccountNumber.updateValueAndValidity();
    this.cancelAppointmentForm.controls.IFSCCode.setValidators(
      Validators.compose([
        Validators.required,
        Validators.pattern('^[A-Z]{4}[0-9]{6,7}$'),
      ])
    );
    this.cancelAppointmentForm.controls.IFSCCode.updateValueAndValidity();
  }
  AccountFormValidationNotRequireUpdate() {
    this.cancelAppointmentForm.controls.AccountHolderName.clearValidators();
    this.cancelAppointmentForm.controls.AccountHolderName.updateValueAndValidity();
    this.cancelAppointmentForm.controls.BankAccountNumber.clearValidators();
    this.cancelAppointmentForm.controls.BankAccountNumber.updateValueAndValidity();
    this.cancelAppointmentForm.controls.IFSCCode.clearValidators();
    this.cancelAppointmentForm.controls.IFSCCode.updateValueAndValidity();
  }
  searchBookingAppointment() {
    /*this.searchAppointmentData = {
			"account_number":this.searchAppointmentForm.get('AppointmentID').value,
			"patient_mobile":this.searchAppointmentForm.get('PMOBILENO').value
		}*/
    this.searchAppointmentData = {
      account_number: this.patientConfirmID,
      patient_mobile: this.patientMobile,
    };
    this.api
      .searchAppointmentDetails(this.searchAppointmentData)
      .subscribe((res) => {
        console.log('search Appointment List : ', res, res.length);
        this.loader2 = false;
        if (res.count == 0) {
          this.noMoreBooking = true;
        } else {
          this.noMoreBooking = false;
        }
        this.ConfirmBookingDataTable = true;
        this.ConfirmBookingData = [] = res;
        this.amountPaid = 'Unpaid';
        if (this.ConfirmBookingData.paymentstatus == 'Success') {
          this.amountPaid = 'Paid';
        }
        if (res.length == undefined) {
          if (res.count == 0) {
            this.noMoreBooking = true;
          } else {
            this.noMoreBooking = false;
          }
        }
      });
  }
  /**
   * check only number value
   */
  isNumber(evt) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  sentOTP() {
    let aID = this.searchAppointmentForm.get('AppointmentID').value;
    let Mobile = this.searchAppointmentForm.get('PMOBILENO').value;
    this.patientConfirmID =
      this.searchAppointmentForm.get('AppointmentID').value;
    this.searchAppointmentData = {
      account_number: aID,
      patient_mobile: Mobile,
    };

    console.log('this.searchAppointmentData', this.searchAppointmentData);

    if (aID != '' && Mobile != '') {
      this.patientMobile = Mobile;
      this.sendMobileOtp(this.patientMobile);
    } else if (aID != '') {
      this.api
        .searchAppointmentDetails(this.searchAppointmentData)
        .subscribe((res) => {
          console.log('searchAppointmentDetails', res);
          this.patientMobile = Mobile != '' ? res[0].patient_mobile : '';
          this.sendMobileOtp(this.patientMobile);
        });
    } else if (Mobile != '') {
      this.patientMobile = Mobile;
      this.sendMobileOtp(this.patientMobile);
    }
  }

  cancelAppointmentAsk(id) {
    let today = new Date();
    let alerts: boolean = false;
    this.ConfirmBookingData.forEach((element) => {
      if (element.account_number == id) {
        let matchDate = new Date(element.appointmentdate);
        //console.log("today date:", today.setHours(0,0,0,0), " matchDate: ",matchDate.setHours(0,0,0,0));
        if (today.setHours(0, 0, 0, 0) == matchDate.setHours(0, 0, 0, 0)) {
          alerts = true;
        } else {
          alerts = false;
        }
      }
    });

    if (alerts) {
      //alert("Current date cancelation will not be allowed, Please call 18002020");
      initPopup('AppointmentNotCancel');
    } else {
      this.cancelAppointmentId = id;
      initPopup('cancelAppointmentAsk');
      this.checkGetBankForm = false;
      this.cReasonForm = false;
      this.yesnobtn = true;
      this.GetPatientUID(this.cancelAppointmentId);
    }
  }

  clickYes() {
    console.log('this.cancelAppointmentId:', this.cancelAppointmentId);
    this.closePopupBTN = true;
    let selectedData: any[] = [];
    this.ConfirmBookingData.forEach((element) => {
      if (element.account_number == this.cancelAppointmentId) {
        selectedData.push(element);
      }
    });
    //console.log('ClickYes:', selectedData[0]);
    //console.log('ClickYes order_status:', selectedData[0].order_status);
    if (selectedData[0].paymentstatus == 'Success') {
      this.checkGetBankForm = true;
      this.cReasonForm = false;
      this.yesnobtn = false;
    } else {
      this.cReasonForm = true;
      this.checkGetBankForm = false;
      this.yesnobtn = false;
    }
  }
  get f() {
    return this.cancelAppointmentForm.controls;
  }
  cancelAppointment() {
    if (this.checkGetBankForm) {
      this.submitted = true;
      // stop here if form is invalid
      if (this.cancelAppointmentForm.invalid) {
        return;
      }
      if (this.cancelAppointmentForm.valid) {
        $('.capopup').hide();
        this.loader2 = true;
        this.cancellationAPI();
      }
    }
    if (this.cReasonForm) {
      $('.capopup').hide();
      this.loader2 = true;
      this.cancellationAPI();
    }
  }

  cancellationAPI() {
    let cAID = this.cancelAppointmentId;
    cAID = cAID.replace(/[^0-9]/g, '');
    this.cReason =
      'The Patient not write Reason for Cancellation Appointment!!!';
    if (this.checkGetBankForm) {
      this.cReason = this.cancelAppointmentForm.get('CancellationReason').value;
    }
    if (this.cReasonForm) {
      this.cReason = this.cancelAppointmentReasonForm.get(
        'aCancellationReason'
      ).value;
    }

    this.cancellationAPIData = {
      nBookingUID: cAID,
      sReason: this.cReason,
    };
    this.api
      .postCancellationAPI(this.nBookingUID, this.cReason)
      .subscribe((res) => {
        console.log('postCancellationAPI Response:', res);
        if (res != '') {
          let resAppointmentId = res['CancelPortalAppointmentResult'].A;
          let resAppointmentC = res['CancelPortalAppointmentResult'].B;
          console.log(
            'resAppointmentId:',
            this.nBookingUID + ' = ' + resAppointmentId,
            'resAppointmentC:',
            resAppointmentC
          );
          console.log(this.cancelAppointmentId + ' == ' + resAppointmentId);
          //if(this.cancelAppointmentId == resAppointmentId){
          if (resAppointmentC == 'Cancelled') {
            this.remark1 = 'Cancel';
            if (this.checkGetBankForm) {
              if (
                this.cancelAppointmentForm.get('CancellationReason').value !==
                ''
              ) {
                this.remark1 =
                  this.cancelAppointmentForm.get('CancellationReason').value;
              }
            }
            if (this.cReasonForm) {
              if (
                this.cancelAppointmentReasonForm.get('aCancellationReason')
                  .value !== ''
              ) {
                this.remark1 = this.cancelAppointmentReasonForm.get(
                  'aCancellationReason'
                ).value;
              }
            }
            this.cancelAppointmentData = {
              appointment_id: this.cancelAppointmentId,
              cancellationdate: this.datepipe.transform(
                new Date(this.today),
                'yyyy-MM-dd HH:mm'
              ),
              cancellationremark: this.remark1,
              appointmentbokignbtatus: 'cancel',
            };
            //console.log('cancelAppointment paramiter post: ', this.cancelAppointmentData);
            this.api
              .cancelAppointment(this.cancelAppointmentData)
              .subscribe((res) => {
                console.log('cancelAppointment Response: ', res);
                this.searchBookingAppointment();

                if (this.checkGetBankForm) {
                  //console.log('remarkRefundReuse:', this.cancelAppointmentForm.get('remarkRefundReuse').value);
                  if (
                    this.cancelAppointmentForm.get('remarkRefundReuse').value ==
                    'Online Refund'
                  ) {
                    this.paymentRefundMail();
                  } else {
                    this.paymentReuseInHospital();
                  }
                } else {
                  this.ClientSideUnpaidUpdateTable(
                    this.cancelAppointmentId,
                    this.remark1
                  );
                }
                setTimeout(() => {
                  this.cancelAppointmentForm.reset();
                  this.OnlineRefund = false;
                }, 100);
              });
          }
          //}
        }
      });
  }
  paymentReuseInHospital() {
    this.prReason = 'Appointment Reuse in Hospital';
    if (this.cancelAppointmentForm.get('CancellationReason').value !== '') {
      this.prReason =
        this.cancelAppointmentForm.get('CancellationReason').value;
    }
    let Remark = this.cancelAppointmentForm.get('remarkRefundReuse').value;

    this.ClientSideReuseInHospitalUpdateTable(
      this.cancelAppointmentId,
      Remark,
      this.prReason
    );
  }
  paymentRefundMail() {
    this.prReason = 'Booking Appointment Reschedule';
    if (this.cancelAppointmentForm.get('CancellationReason').value !== '') {
      this.prReason =
        this.cancelAppointmentForm.get('CancellationReason').value;
    }
    let AHName = this.cancelAppointmentForm.get('AccountHolderName').value;
    let BnNumber = this.cancelAppointmentForm.get('BankAccountNumber').value;
    let IFSCCode = this.cancelAppointmentForm.get('IFSCCode').value;
    let Remark = this.cancelAppointmentForm.get('remarkRefundReuse').value;
    this.AppointmentCancellationBankData = {
      AccountHolderName:
        this.cancelAppointmentForm.get('AccountHolderName').value,
      appointment_id: this.cancelAppointmentId,
      BankAccountNumber:
        this.cancelAppointmentForm.get('BankAccountNumber').value,
      IFSCCode: this.cancelAppointmentForm.get('IFSCCode').value,
      Remark: this.cancelAppointmentForm.get('remarkRefundReuse').value,
      Reason: this.prReason,
    };
    this.api
      .postAppointmentCancellation(this.AppointmentCancellationBankData)
      .subscribe((res) => {
        console.log('paymentRefundMail', res);
      });

    this.ClientSidePaidUpdateTable(
      this.cancelAppointmentId,
      Remark,
      AHName,
      BnNumber,
      IFSCCode,
      this.prReason,
      Remark
    );
  }
  paymentRefundBankDetails() {
    this.prReason =
      'Cancel this Appointment for Booking Appointment Reschedule';
    if (this.cancelAppointmentForm.get('CancellationReason').value !== '') {
      this.prReason =
        this.cancelAppointmentForm.get('CancellationReason').value;
    }
    this.AppointmentCancellationBankData = {
      AccountHolderName:
        this.cancelAppointmentForm.get('AccountHolderName').value,
      BankAccountNumber:
        this.cancelAppointmentForm.get('BankAccountNumber').value,
      IFSCCode: this.cancelAppointmentForm.get('IFSCCode').value,
      Remark: this.cancelAppointmentForm.get('remarkRefundReuse').value,
      Reason: this.prReason,
    };
    //console.log('AppointmentCancellationBankData:', this.AppointmentCancellationBankData, this.cancel_appointment_details[0]);
    let AppointmentCancellationData = [];
    let BankData = [this.AppointmentCancellationBankData];
    let appointment_details = [this.cancel_appointment_details[0]];
    appointment_details.forEach((itm, i) => {
      AppointmentCancellationData.push(Object.assign({}, itm, BankData[i]));
    });
    this.AppointmentCancellationPostData = AppointmentCancellationData;
  }

  ClientSideUnpaidUpdateTable(appointmentID, cancellationremark) {
    this.api.get_Appointment_id(appointmentID).subscribe((res_id) => {
      //console.log('get_Appointment_id: ', res_id, res_id['id'][0].id);
      let website_id = res_id['id'][0].id;
      this.AppointmentDataUpdateToClientDB = {
        action: 'appointmet_fail_cancel_update',
        status: this.cancellationtype,
        Websiteid: website_id,
        cancellationremark: cancellationremark,
        cancellationtype: this.cancellationtype,
      };
      this.api
        .AppointmentPostClientData(this.AppointmentDataUpdateToClientDB)
        .subscribe((res) => {
          console.log('AppointmentPostClientData: ', res);
        });
    });
  }

  ClientSideReuseInHospitalUpdateTable(appointmentID, Remark, Reason) {
    this.api.get_Appointment_id(appointmentID).subscribe((res_id) => {
      //console.log('get_Appointment_id: ', res_id, res_id['id'][0].id);
      let website_id = res_id['id'][0].id;
      this.AppointmentDataUpdateToClientDB = {
        action: 'appointmet_reuse_cancel_update',
        Websiteid: website_id,
        status: this.cancellationtype,
        Reason: Reason,
        Remark: Remark,
        cancellationtype: this.cancellationtype,
      };
      this.api
        .AppointmentPostClientData(this.AppointmentDataUpdateToClientDB)
        .subscribe((res) => {
          console.log('AppointmentPostClientData: ', res);
        });
    });
  }
  ClientSidePaidUpdateTable(
    appointmentID,
    canremark,
    AcHolderName,
    BANumber,
    IFSCCode,
    Reason,
    Remark
  ) {
    this.api.get_Appointment_id(appointmentID).subscribe((res_id) => {
      //console.log('get_Appointment_id: ', res_id, res_id['id'][0].id);
      let website_id = res_id['id'][0].id;
      this.AppointmentDataUpdateToClientDB = {
        action: 'appointmet_paid_cancel_update',
        Websiteid: website_id,
        status: this.cancellationtype,
        cancellationremark: canremark,
        AccountHolderName: AcHolderName,
        BankAccountNumber: BANumber,
        IFSCCode: IFSCCode,
        Reason: Reason,
        Remark: Remark,
        cancellationtype: this.cancellationtype,
      };
      this.api
        .AppointmentPostClientData(this.AppointmentDataUpdateToClientDB)
        .subscribe((res) => {
          console.log('AppointmentPostClientData: ', res);
        });
    });
  }

  sendMobileOtp(m) {
    let otpData = {
      mobile: m,
      sent_type: 'send',
    };
    initPopup('sendotp');

    this.api.postCancelConfirmationOTP(otpData).subscribe(
      (res) => {
        console.log('##########OTP Send#########');
      },
      (err) => {
        console.error(err);
        //this.otpMsg = 'Please enter valid OTP';
      }
    );
  }

  resendOTP() {
    let otpData = {
      mobile: this.patientMobile,
      sent_type: 'resend',
    };
    this.api.postCancelConfirmationOTP(otpData).subscribe(
      (res) => {
        console.log('otp send');
      },
      (err) => {
        console.error(err);
      }
    );
  }
  validateCSendOTPForm() {
    this.CSendOTPForm = this.formBuilder.group({
      userOTPCode: ['', Validators.required],
    });
  }
  confirmOTP() {
    if (this.CSendOTPForm.value) {
      let data = {
        mobile: this.patientMobile,
        otp: this.CSendOTPForm.get('userOTPCode').value,
      };

      this.api.postCancelConfirmationOTPVerify(data).subscribe(
        (res) => {
          console.log('after Confirmation get Response:', res);
          this.CSendOTPForm.controls['userOTPCode'].setValue('');
          if (res.status_code == 200) {
            this.closePopup();
            this.searchBookingAppointment();
          }
        },
        (err) => {
          this.otpMsg = 'Please enter valid OTP';
        }
      );
    }
  }

  CancellationPolicy() {
    initPopup('cancelpolicy');
  }
  CancellationTC() {
    initPopup('cancelTnC');
  }
  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
    }
  }

  ngOnDestroy() {}
}
