import { FB_URL, YOUTUBE_URL, TWITTER_URL } from './../services/constants';
import { Component, Input } from '@angular/core';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL } from '../services/constants';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
} from '@angular/forms';

declare let initPopup: any;
declare let $: any;
declare let initHome: any;
declare let initGoogleMap: any;
declare let initAllPage: any;

@Component({
  selector: 'footer-nav',
  templateUrl: './footernav.component.html',
  styles: [
    `
      .data p {
        margin-right: 14% !important;
      }
      .vediobg.active {
        z-index: 0 !important;
      }
      .knowmore {
        margin-left: 20px;
        width: 100px !important;
      }
      .knowmore img {
        display: inline-block;
      }
      .has-error {
        border: 1px solid red !important;
      }
    `,
  ],
})
export class FooternavComponent {
  public subscriptionForm: any = [];
  public popupTitle: string;
  public popupMsg: string;
  fb_url: string = FB_URL;
  youtube_url: string = YOUTUBE_URL;
  twitter_url: string = TWITTER_URL;
  public footerLinks: any;
  constructor(private api: ApiService, public formBuilder: FormBuilder) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
    }
    this.api
      .getFooterDetail()
      .map((res) => res)
      .subscribe(
        (data) => {
          ///console.log('Footer Details', data);
          data.forEach((element) => {
            element.pages = element.pages.filter(
              (data) => data.title !== 'Health Card'
            );
          });
          data.forEach((element) => {
            element.pages.forEach((element1) => {
              if (element1.title == 'Careers') {
                element1.class = 'CareersBtnFooter';
              }
            });
          });
          this.footerLinks = data;
          //console.log(this.footerLinks);
        },
        (err) => {
          console.error(err);
        }
      );
  }
  validateSubscriptionForm() {
    this.subscriptionForm = this.formBuilder.group({
      email: ['', Validators.required],
    });
  }
  SubmitSubscriptionForm() {
    //console.log(this.subscriptionForm.value);
    this.api.postSubmitSubscription(this.subscriptionForm.value).subscribe(
      (res) => {
        //console.log(res);
        if (res.status_code == 200) {
          this.subscriptionForm.reset();
          // alert('thankyou');
          this.popupTitle = 'Thank you';
          this.popupMsg = '';
        } else if (res.status_code == 406) {
          // alert('Entered email address exist in system');
          this.popupTitle = 'Error';
          this.popupMsg = 'Entered email address exist in system';
        } else {
          //alert('enter valid email address');
          this.popupTitle = 'Error';
          this.popupMsg = 'Enter valid email address';
        }
        initPopup(1);
      },
      (err) => {
        console.log('error', err);
      }
    );
  }
}
