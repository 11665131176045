<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>
    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>

    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>

    <section class="page pageheader">
        <div class="container">
            <div class="col-xs-12 col-sm-12 col-lg-12">
                <div class="ptitle">
                    <h1>{{pageInfo.title}}</h1>
                    <ng-template [ngIf]="pageInfo.subtitle">
                        <h3>{{pageInfo.subtitle}}</h3>
                    </ng-template>
                    <p [innerHTML]="pageInfo.description"></p>
                </div>
            </div>
        </div>
    </section>
    <section class="aboutsectin2 wcuswrp">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-lg-12">
                    <div class="calculatorwrp">
                        <div class="alertwrp">
                            <div class="alertOverlay"></div>
                            <div class="alertcirbox">
                                <div class="closebtn">
                                    <a href="#"><img src="assets/images/icon-close2.png" alt="" /></a>
                                </div>
                                <div class="childicon"><img src="assets/images/calc/icon-s-alert.png" alt="" /></div>
                                <div class="timecalc"><span>Alert</span></div>
                                <div class="tsebtn">
                                    <div class="tsebtnbg"><a href="#">Call Doctor</a></div>
                                </div>
                            </div>
                        </div>
                        <div class="calcInfoWrp">
                            <div class="alertOverlay"></div>
                            <div class="calcInfobox">
                                <div class="closebtn">
                                    <a href="#"><img src="assets/images/icon-close2.png" alt="" /></a>
                                </div>
                                <h2>Contraction</h2>
                                <div class="infotxt" style="overflow: initial; margin-top: 0px; margin-bottom: 20px;">
                                    <!-- <p>Once you are in the last month of your pregnancy, itís normal to start experiencing labor-like symptoms, which can make it really difficult to determine when you are actually in labor!</p>
								<p>Luckily your body knows the difference, so before you pack up and head for the hospital, listen to these five things that your contractions are telling you.</p> -->
                                    <h3>5 essential things about contractions</h3>
                                    <table cellpadding="0" cellspacing="0">
                                        <tr>
                                            <th></th>
                                            <th>True Labor</th>
                                            <th>False Labor</th>
                                        </tr>
                                        <tr>
                                            <td>Timing</td>
                                            <td>Your contractions will happen consistently and get closer and closer together</td>
                                            <td>Your contractions will be irregular and have no pattern of occurrence.</td>
                                        </tr>
                                        <tr>
                                            <td>Duration</td>
                                            <td>Your contractions will last anywhere between 30-70 seconds, getting gradually longer.</td>
                                            <td>Your contractions will occur for various lengths of time, without consistency.</td>
                                        </tr>
                                        <tr>
                                            <td>Intensity</td>
                                            <td>The intensity of your contractions will continually increase as time progresses.</td>
                                            <td>Your contractions will have no consistent progression of intensity.</td>
                                        </tr>
                                        <tr>
                                            <td>Sensation</td>
                                            <td>You will feel contractions radiating in your lower back and upper abdomen.</td>
                                            <td>You may feel contractions in your lower abdomen without the radiating sensation.</td>
                                        </tr>
                                        <tr>
                                            <td>Effect</td>
                                            <td>Your level of activity will have no effect on your contractions, and you may be unable to speak to others or laugh at jokes while they occur.</td>
                                            <td>Your contractions can change with your level of activity, and you will not have as much difficulty interacting with those around you.</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <!-- Go to Back Link -->
                        <div class="calctopnav">
                            <a href="#" class="gobacktotime"><img src="assets/images/calc/icon-backarrow.png" alt="" /> Go back to Date selection</a>
                            <ul class="calcnav">
                                <li class="dropdowntn CalcSignIn active"><a href="javascript:void(0)" [attr.data-id]="calcCAccountShow"><span class="login"></span></a>
                                    <div class="calcSignIn dropdown-tggle">
                                        <ul *ngIf="!loggedInUser">
                                            <li><a href="javascript:void(0)" class="SignIn">Sign In</a></li>
                                            <li><a href="javascript:void(0)" class="CreateAnAccount">Create an account</a></li>
                                        </ul>
                                        <ul *ngIf="loggedInUser">
                                            <li id="ApiUser" *ngIf="ApiUsersignOut"><span class="infoEmail">Hello <br />{{userInfoEmail}}</span><br /><a (click)="userLogout()" href="javascript:void(0)">Logout</a></li>
                                            <li id="facebookUser" *ngIf="facebookLoggedInUser"><span class="infoEmail">Hello <br />{{userInfoEmail}}</span><br /><a (click)="logoutFacebook()" href="javascript:void(0)">Logout</a></li>
                                            <li id="googleUser" *ngIf="googleLoggedInUser"><span class="infoEmail">Hello <br />{{userInfoEmail}}</span><br /><a (click)="GoogleSignOut()" href="javascript:void(0)">Logout</a></li>
                                        </ul>
                                    </div>
                                </li>
                                <li class="showContraction"><a (click)="showContractionTimer()" href="javascript:void(0)" [attr.data-id]="calcContractionShow"><span class="child"></span></a></li>
                                <li class="LastContraction"><a href="javascript:void(0)" data-id="LastContraction"><span class="datat"></span></a></li>
                                <li class="CalcAlarm"><a href="javascript:void(0)"><span class="alarm"></span></a></li>
                                <li class="CalcAlarm"><a href="javascript:void(0)" class="calcInfo"><span class="info"></span></a></li>
                                <li class="dropdowntn"><a href="javascript:void(0)"><span class="share"></span></a>
                                    <div class="calcSocial dropdown-tggle">
                                        <ul>
                                            <li><a [href]="fb_url" target="_blank" class="fb">&nbsp;</a></li>
                                            <li><a [href]="twitter_url" target="_blank" class="tw">&nbsp;</a></li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <!-- Child Calculator Navigation -->
                        <div class="calcscreen1 calcContraction calctab">
                            <div class="calcttitle">
                                <h2>Contraction</h2>
                            </div>
                            <div class="calcirwrp">
                                <div class="calcirwtch">
                                    <div class="childicon"><img src="assets/images/calc/icon-child.png" alt="" /></div>
                                    <div class="timecalc">
                                        <h2>
                                            <span class="min">{{(minutesDisplay) && (minutesDisplay <= 59) ? minutesDisplay : '00'}}</span>
                                            <span>:</span>
                                            <span class="sec">{{(secondsDisplay) && (secondsDisplay <= 59) ? secondsDisplay : '00'}}</span>
                                        </h2>
                                    </div>
                                    <div class="tsebtn">
                                        <div class="tsebtnbg"><a href="javascript:void(0)" (click)="startContraction()" class="start">{{timerTitle}}</a></div>
                                    </div>
                                </div>
                            </div>
                            <div class="currentwrp">
                                <div class="currentbox">
                                    <div class="timesatrt">
                                        <div class="timeicon">
                                            <img src="assets/images/calc/icon-ClockCarrent.png" alt="" />
                                        </div>
                                        <div class="satrtime">
                                            <span id="timeclc" class="carrenstime">{{carrenstime | amDateFormat:'HH:mm:ss'}}</span>
                                            <span class="txtse">Start</span>
                                        </div>
                                    </div>
                                    <div class="currentxt">Current</div>
                                    <div class="timend">
                                        <div class="timeicon">
                                            <img src="assets/images/calc/icon-ClockEnd.png" alt="" />
                                        </div>
                                        <div class="endtime">
                                            <span id="timeclc" class="carrenetime">{{carrenetime | amDateFormat:'HH:mm:ss'}}</span>
                                            <span class="txtse">End</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="dfwrp">
                                <div class="currentbox">
                                    <div class="timesatrt">
                                        <div class="satrtime">
                                            <span id="timeclc" class="diffrance">{{durationTime}}</span>
                                            <span class="txtse">Duration</span>
                                        </div>
                                    </div>
                                    <div class="timend">
                                        <div class="endtime">
                                            <span id="timeclc">{{frequencyTime}}</span>
                                            <span class="txtse">Frequency</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Child Calculator Circle -->
                        <div class="calcscreen2 ContractionIcon calctab">
                            <div class="iconfreq">
                                <div class="calcttitle">
                                    <h2>Contraction</h2>
                                </div>
                                <ul class="painiconlist">
                                    <li>
                                        <a href="javascript:void(0)" (click)="submitPain('Less')" data-val="Less" data-img="icon-less.png">
                                            <div class="pficon"><img src="assets/images/calc/icon-less.png" alt="" /></div>
                                            <div class="pftxt"><span>Less</span></div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)" (click)="submitPain('Very Mild')" data-val="Very Mild" data-img="icon-verymild.png">
                                            <div class="pficon"><img src="assets/images/calc/icon-verymild.png" alt="" /></div>
                                            <div class="pftxt"><span>Very Mild</span></div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)" (click)="submitPain('Mild')" data-val="Mild" data-img="icon-mild.png">
                                            <div class="pficon"><img src="assets/images/calc/icon-mild.png" alt="" /></div>
                                            <div class="pftxt"><span>Mild</span></div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)" (click)="submitPain('Hard')" class="alarm" data-val="Hard" data-img="icon-hard.png">
                                            <div class="pficon"><img src="assets/images/calc/icon-hard.png" alt="" /></div>
                                            <div class="pftxt"><span>Hard</span></div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <!-- Contraction Pain -->
                        <div class="caawrp calcscreen3 calcLogn calctab">
                            <div class="formbox">
                                <div class="calcttitle">
                                    <h2>Sign in</h2>
                                </div>
                                <form name="CAppointmentSN" [formGroup]="userLogin" id="CAppointmentSN" method="post" action="">
                                    <ul class="formcasn signForm">
                                        <li>
                                            <div class="fbox"><label>Email ID*</label>
                                                <input type="text" name="userEmailId" id="txtEmaiIDSN" placeholder="Enter Email ID" [ngClass]="{'has-error':!userLogin.controls.userEmailId.valid 
                                            && userLogin.controls.userEmailId.touched}" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" formControlName="userEmailId">
                                            </div>
                                        </li>
                                        <li>
                                            <div class="fbox">
                                                <label>Password</label>
                                                <input type="password" name="userPassword" maxlength="10" id="txtMNameSN" placeholder="Enter Password" [ngClass]="{'has-error':!userLogin.controls.userPassword.valid 
                                            && userLogin.controls.userPassword.touched}" formControlName="userPassword">
                                            </div>
                                        </li>
                                    </ul>
                                    <div class="signupwrp" style="margin:0px 0px 0px">
                                        <div class="tncnbcwrp signLinkColor">
                                            <a href="javascript:;" class="FPassBtn">Forgot password</a>
                                        </div>
                                    </div>
                                    <div class="signupwrp">
                                        <div class="tncnbcwrp signLinkColor">
                                            <a href="javascript:;" class="CreateAnAccount">Create An Account</a> <span style="opacity: 1;">/ or login with
                                            <a href="javascript:void(0);" (click)="FBLogin()"><img src="../../assets/images/facebook_icon.png" alt="Facebook Login" /></a>
                                            <a href="javascript:void(0);" id="googleBtn"><img src="../../assets/images/google_icon.png" alt="Google Login" /></a></span>
                                        </div>
                                        <div class="submitbtnrp">
                                            <input type="button" name="submit" [disabled]="!userLogin.valid" (click)="submitLogin()" class="casubmit" id="caSubmitSN" value="Submit">
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <!-- Account Login -->
                        <div class="caawrp calcscreen4 calcFPass calctab">
                            <div class="formbox">
                                <div class="calcttitle">
                                    <h2>Forgot Password</h2>
                                </div>
                                <form name="CAppointmentSN" [formGroup]="userForgotPassword" id="CAppointmentSN" method="post" action="">
                                    <ul class="formcasn">
                                        <li>
                                            <div class="fbox">
                                                <label>Email ID*</label>
                                                <input type="text" name="userEmailId" id="txtEmaiIDSN" placeholder="Enter Email ID" [ngClass]="{'has-error':!userForgotPassword.controls.userEmailId.valid 
                                            && userForgotPassword.controls.userEmailId.touched}" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" formControlName="userEmailId">
                                            </div>
                                        </li>
                                    </ul>
                                    <div class="signupwrp">
                                        <div class="submitbtnrp">
                                            <input type="button" name="submit" class="casubmit" [disabled]="!userForgotPassword.valid" (click)="submitForgotpassword()" id="caSubmitSN" value="Submit">
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <!-- Forgote Password -->
                        <div class="caawrp calcscreen5 calcCAccount calctab">
                            <div class="formbox">
                                <div class="calcttitle">
                                    <h2>Create An Account</h2>
                                </div>
                                <form name="CAppointmentSN" [formGroup]="userRegisterForm" id="CAppointmentSN" method="post" action="">
                                    <ul class="formcasn">
                                        <li>
                                            <div class="fbox"><label>Name*<span class="nameErrorMsg"></span></label><input type="text" (keypress)="isAlpha($event)" pattern="[A-Za-z]+" formControlName="userName" name="userName" id="txtVisitorNameSN" placeholder="Enter Patient/Visitor Name"></div>
                                        </li>
                                        <li>
                                            <div class="fbox"><label>Email ID*<span class=" emailErrorMsg"></span></label><input type="text" formControlName="userEmailId" name="userEmailId" [ngClass]="{'has-error':!userRegisterForm.controls.userEmailId.valid 
                                            && userRegisterForm.controls.userEmailId.touched}" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" id="txtEmaiIDSN" placeholder="Enter Email ID"></div>
                                        </li>
                                        <li>
                                            <div class="fbox"><label>Mobile*<span class=" mobileErrorMsg"></span></label><input type="text" formControlName="userMobile" name="userMobile" [ngClass]="{'has-error':!userRegisterForm.controls.userMobile.valid 
											&& userRegisterForm.controls.userMobile.touched}" maxlength="10" (keypress)="isNumber($event)" id="txtMobileSN" placeholder="Enter Mobile Number"></div>
                                        </li>
                                        <li>
                                            <div class="fbox" formGroupName="passGroup"><label>Password</label><input type="password" [class.has-error]="userRegisterForm.get('passGroup').errors?.invalid" formControlName="userRegPassword" name="userRegPassword" maxlength="10" id="txtMNameSN" placeholder="Enter Password"></div>
                                        </li>
                                        <li>
                                            <div class="fbox" formGroupName="passGroup">
                                                <label>Confirm Password</label>
                                                <input type="password" formControlName="userRegConfirmPassword" name="userRegConfirmPassword" maxlength="10" [class.has-error]="userRegisterForm.get('passGroup').errors?.invalid" id="txtCalcCP" placeholder="Re Enter Password">
                                            </div>
                                        </li>
                                        <!-- <li class="bdcalender">
                                            <div class="fbox"><label>Birth Date</label>
                                                <input type="text" formControlName="userDOB" [ngClass]="{'has-error':!userRegisterForm.controls.userDOB.valid 
                                            && userRegisterForm.controls.userDOB.touched}" (blur)="onDateChange($event)" class="datepickr2" name="userDOB" id="txtCalcBD" placeholder="DD / MM / YYYY" /></div>
                                        </li> -->
                                        <li  class="bdcalender">
                                            <div  class="fbox"><label >Birth Date</label>
                                                <span class="Zebra_DatePicker_Icon_Wrapper" style="display: block; position: relative; float: left; inset: auto; width: 0px;"><input  class="datepickr2 " formcontrolname="userDOB" id="txtCalcBD" name="userDOB" placeholder="DD / MM / YYYY" [ngClass]="{'has-error':!userRegisterForm.controls.userDOB.valid && userRegisterForm.controls.userDOB.touched}"  type="text" (blur)="onDateChange($event)" style="position: relative; inset: auto;"><button type="button" class="Zebra_DatePicker_Icon Zebra_DatePicker_Icon_Inside_Right" style="top: 14.2414px; right: 0px;">Pick a date</button></span></div>
                                        </li>
                                        <li>
                                            <div class="fbox"><label>Age</label>
                                                <div class="selectbox">
                                                    <select class="select" formControlName="userAge" name="userAge" id="txtCalcAge">
                                                    <option value="Refer by Doctor" selected="selected">Select Your Age</option>
                                                    <option *ngFor="let a of ageArray; let index = index"  value="{{index+20}}">
                                                        {{ index+20 }} Years
                                                    </option>
                                                </select>
                                                    <span id="selectSource" class="select-span">Refer by Doctor</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="CalcWeight">
                                            <div class="fbox"><label>Weight</label>
                                                <input type="text" formControlName="userWeight" [ngClass]="{'has-error':!userRegisterForm.controls.userWeight.valid 
											&& userRegisterForm.controls.userWeight.touched}" name="userWeight" (keypress)="isNumber($event)" id="txtCalcWeight" minlength="1" maxlength="3" placeholder="55" min="3"></div>
                                        </li>
                                    </ul>
                                    <div class="signupwrp">
                                        <div class="tncnbcwrp">
                                            <ul>
                                                <li>
                                                    <input type="checkbox" name="confirm" formControlName="confirm" id="SNTNCA" checked="checked" value="yes">
                                                    <label>
                                                    <a data-fancybox="modal" data-src="#tncmodal" href="javascript:;">Terms &amp; conditions apply</a>
                                                </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="submitbtnrp">
                                            <input type="button" [disabled]="!userRegisterForm.valid" (click)="submitUserRegister()" name="submit" class="casubmit" id="caSubmitSN" value="Register">
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <!-- Create An Account -->
                        <div class="calcscreen6 LastContraction calctab">
                            <div class="calcttitle">
                                <h2>Contraction</h2>
                            </div>
                            <div class="LCwrp">
                                <div class="LCtitle">
                                    <h2>Last Contraction</h2>
                                </div>
                                <div class="lcBD"><input type="text" class="datepickr2" (blur)="getContractionReport($event)" name="txtCalcBD" id="txtCalcBD" placeholder="DD / MM / YYYY" readonly /></div>
                            </div>
                            <div class="LCTablewrp">
                                <table cellpadding="0" cellspacing="0" border="0">
                                    <tr>
                                        <th>Start</th>
                                        <th>Duration</th>
                                        <th>Frequency</th>
                                        <th>Intensity</th>
                                    </tr>

                                    <tr *ngFor="let contract of contractionList | reverse">

                                        <td>{{contract.start_time | amDateFormat:'HH:mm:ss'}}</td>
                                        <td>{{contract.end_time | amDateFormat:'HH:mm:ss'}}</td>
                                        <td>{{contract.frequency}}</td>
                                        <td>
                                            <div class="Intensity"><img src="assets/images/calc/icon-s-{{contract.type | removeSpaces | lowercase}}.png" alt="" />{{contract.type}}</div>
                                        </td>
                                    </tr>

                                </table>
                            </div>
                        </div>
                        <!-- Last Contraction Data-->
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="capopup" data-popup="popup-10">
        <div class="capopup-inner">
            <h2>Thankyou</h2>
            <h3>{{popupTitle}}</h3>
            <p>
                {{popupMsg}}
            </p>
            <a class="capopup-close" data-popup-close="popup-10" href="#"><img src="assets/images/icon-close2.png" alt="" /></a>
        </div>
    </div>
    <div class="capopup" data-popup="popup-forgotpopup">
        <div class="capopup-inner">
            <h2>{{forgotTitle}}</h2>
            <br>
            <p>
                {{forgotMsg}}
            </p>
            <a class="capopup-close" data-popup-close="popup-forgotpopup" href="#"><img src="assets/images/icon-close2.png" alt="" /></a>
        </div>
    </div>
    <div id="tncmodal" class="tncmodal">
        <div class="tncmodalwrp">
            <h2>Terms &amp; Conditions</h2>
            <p>All the information displayed, transmitted or carried by Rainbow Children’s Medicare Private Limited and its subsidiaries (hereinafter called Rainbow Hospital) including, but not limited to, news articles, opinions, reviews, text, photographs,
                images, illustrations, profiles, audio clips, video clips, trademarks, service marks and others (collectively the "Content,") on this Rainbow Hospital’s website is for informational purposes only.</p>
            <p>The Content is owned by Rainbow Hospital, its affiliates or third party licensors. No one has any right to modify, publish, transmit, transfer, sell, reproduce, create derivative work from, distribute, repost, perform, display or in any way
                commercially exploit any of the Content listed on the website.</p>
            <p>By usage of this Rainbow Hospital’s website the users agree to abide by all copyright notices and restrictions attached to any Content accessed through it and failure to do so will be considered a case of Copyright Infringement, which is termed
                an offence under the Copyright Act of India, 1957, and is punishable with imprisonment which may extend to one year, or with fine, or with both. Any individual who knowingly infringes or abets the infringement is liable for appropriate
                legal action to be taken by Rainbow Hospital.</p>
            <p>The Rainbow Hospital does not represent or endorse the accuracy, completeness or reliability of any advice, opinion, statement or other information displayed, uploaded or distributed through its website. The usage of the content by any person
                implies that it is for his information purpose only and any self-drawn conclusions, experimenting on the basis of content provided there in shall be at user’s own risk and Rainbow Hospital is not liable or responsible for the same.</p>
            <p>The content displayed on the Rainbow Hospital’s website is primarily to enable the users to obtain a better understanding of the medical services provided by the hospital and facilities available, Doctors and Consultants expertise and allied
                services provided by the Rainbow Hospital Group and the information thereby provided is subject to changes and modifications as and when required without any prior notice.</p>
            <p>None of the individual developers, system operators, third-party contributors and management of Rainbow Hospital or anyone else connected to Rainbow Hospital can be held responsible for the results or consequences incurred due to any self-attempt
                to copy, use, imitate or adopt any of the information presented on this Rainbow Hospital’s website without proper medical guidance. Rainbow Hospital reserves the right to modify/change the policy without prior intimation/publication/prior
                information.</p>
            <p><strong>Privacy Policy</strong></p>
            <p>Rainbow Hospital has its own Privacy Policy and it is also required by law to maintain the privacy of the patients medical information, users information and to abide by the terms of the Privacy Policy entered in person or through this Rainbow
                Hospital’s website. This policy shall be applicable to the information provided by users displayed, collected, or payment collected through payment gateway on Rainbow Hospital’s website for any medical Advise Services/Educational/Skill
                Enhancement Programmes,/ Conferences/ interactions/ meetings conducted by the Rainbow Hospital.</p>
            <p>Rainbow Hospital will not intentionally share the information of any user who has accessed the Rainbow Hospital’s website to any third party unless as required under statutory laws. We do not guarantee or assure that the electronic communications
                received from you or contents or records may not be accessible to the third parties.</p>
            <p>Rainbow Hospital reserves the right to modify/change the policy without prior intimation/publication/prior information.
            </p>
            <p><strong>Refund Policy</strong></p>
            <p>Rainbow Hospital will facilitate a hassle-free online secured payment gateway registration facility wherein the users visiting Rainbow Hospital’s website can access the payment gateway /registration and can utilise the said services and make
                payments. Since most of the services offered under the Rainbow Hospital are medical advisory &amp; services in nature, there is no return of the services availed through our Rainbow Hospital’s website, but certain refund and cancellation
                policies apply which may differ or vary from time to time and also service to service, please read all refund and cancellation related policies to the service, or promotion before availing and/or before registration.</p>
            <p>Rainbow Hospital will offer refunds purely on the basis of facts concerning an individual case. There will be no refunds for cancellations post a registration, unless sufficiently provided evidence for – mistaken denominations, factual errors,
                inaccurate payment deductions, duplicate payments or any other instance wherein the payee has faced a loss beyond reasonable control.</p>
            <p>Refunds if any will be made to the same card or account using which the payment was initially made and it will be processed only after verification of the accountholders credentials. No cash or cheque refunds will be made under any circumstances.</p>
            <ul>
                <li>Users cannot return the same order more than once.</li>
                <li>Rainbow Hospital shall not be responsible in case communication is not received by the customer due to incomplete contact details given by the individual.</li>
                <li>Any registration and/or payment made during a promotional campaign will be not considered for cancellation or refund.</li>
                <li>Customers requesting serial or repeated refunds may be blocked to access the services provided on the website.</li>
                <li>For claiming refund, the payee should provide valid invoice of the investigations &amp; ID documents of the customer who is seeking refund so as to be able to get the refund.</li>
                <li>No interest will be paid on refund</li>
            </ul>
            <p>Rainbow Hospitals reserves the right to modify/change the policy without prior intimation/publication/prior information.
            </p>
        </div>
    </div>
    <!-- 	
	<footer-nav-mini [links]="links"></footer-nav-mini> -->
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>