<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>


    <section class="page pageheader">
        <div class="container">
            <div class="col-xs-6 col-sm-6 col-lg-6 abannimgwrp">
                <div class="otherbannimg"><img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" /></div>
            </div>
            <div class="col-xs-6 col-sm-6 col-lg-6">
                <div class="ptitle">
                    <h1>{{title}}</h1>
                    <h3>{{subtitle}}</h3>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-lg-6 mcont100">
                <p [innerHTML]="description"></p>
            </div>

        </div>
    </section>
    <section class="aboutsectin2 awardswapper">
        <div class="container">
            <div class="row nsg_text">
                <div class="col-xs-12 col-sm-7 col-lg-8">
                    <h2>Before Birth</h2>
                    <p>It is possible to pre-empt premature births – taking care of your pregnancy and certain conditions does reduce the risk of preterm births. However, the risk may never be fully averted. Sometimes the factors are beyond your control.
                        You can have a conversation with your doctor on the possible date and prepare accordingly. In the interim, you must also figure…</p>
                    <p><a href="other/neonatal-support-group/before-birth">Read More</a></p>
                </div>
                <div class="col-xs-12 col-sm-5 col-lg-4">
                    <div class="nsg_thumbwrp">
                        <img src="https://backend.rainbowhospitals.in/assets/images/nsg/BeforeBirth.jpg" alt="" />
                    </div>
                </div>
            </div>
            <hr>
            <div class="row nsg_text">
                <div class="col-xs-12 col-sm-7 col-lg-8">
                    <h2>In Hospital</h2>
                    <p>Premature births are not uncommon although they do pose certain challenges. Premature babies are vulnerable and need to be provided specialized care to ensure that they are able to have a healthy and normal growth. The Premature Support
                        Group seeks to equip you with the best possible help to deal with the situation as well as have the right support…</p>
                    <p><a href="other/neonatal-support-group/in-hospital">Read More</a></p>
                </div>
                <div class="col-xs-12 col-sm-5 col-lg-4">
                    <div class="nsg_thumbwrp">
                        <img src="https://backend.rainbowhospitals.in/assets/images/nsg/In-Hospital.jpg" alt="" />
                    </div>
                </div>
            </div>
            <hr>
            <div class="row nsg_text">
                <div class="col-xs-12 col-sm-7 col-lg-8">
                    <h2>Going Home</h2>
                    <p>Having weathered the storm of a premature birth and possible complications, the moment of going home is an exciting and well-awaited one for the parents. For the child it is now time to leave the sterile environs of the NICU and step
                        out into the open for the first time. The excitement is palpable but caution is advisable. Given that…</p>
                    <p><a href="other/neonatal-support-group/going-home">Read More</a></p>
                </div>
                <div class="col-xs-12 col-sm-5 col-lg-4">
                    <div class="nsg_thumbwrp">
                        <img src="https://backend.rainbowhospitals.in/assets/images/nsg/Going-Home.jpg" alt="" />
                    </div>
                </div>
            </div>
            <hr>
            <div class="row nsg_text">
                <div class="col-xs-12 col-sm-7 col-lg-8">
                    <h2>At Home</h2>
                    <p>Post-delivery and hospitalization when you had the assistance of the nurses & support group at the hospital, this is your first time alone with your baby. You might find it challenges, but there is plenty to look forward to and learn.
                        Remain positive and calm; take sufficient advice and the time at home with your child is sure to be…</p>
                    <p><a href="other/neonatal-support-group/at-home">Read More</a></p>
                </div>
                <div class="col-xs-12 col-sm-5 col-lg-4">
                    <div class="nsg_thumbwrp">
                        <img src="https://backend.rainbowhospitals.in/assets/images/nsg/At-Home.jpg" alt="" />
                    </div>
                </div>
            </div>
            <hr>
            <div class="row nsg_text">
                <div class="col-xs-12 col-sm-7 col-lg-8">
                    <h2>Growing Up</h2>
                    <p>Children at the infancy stage seem to grow with every passing day. It’s an exciting time for the parents as they watch their little ones take little steps of growth. Some parents will also worry about the inadequacy or delayed growth,
                        it is normal for different children to grow at slightly different pace. One must be patient and allow the…</p>
                    <p><a href="other/neonatal-support-group/growing-up">Read More</a></p>
                </div>
                <div class="col-xs-12 col-sm-5 col-lg-4">
                    <div class="nsg_thumbwrp">
                        <img src="https://backend.rainbowhospitals.in/assets/images/nsg/Growing-Up.jpg" alt="" />
                    </div>
                </div>
            </div>
        </div>
    </section>



    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>