<div class="container-fluid">	<    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <section class="page pageheader">
        <div class="container">
            <div class="col-xs-6 col-sm-6 col-lg-6 abannimgwrp">
                <div class="otherbannimg"><img src="{{imgBasePath}}{{imgsrc}}" alt="{{title}}" title="{{title}}" class="img-responsive" /></div>
            </div>
            <div class="col-xs-6 col-sm-6 col-lg-6">
                <div class="ptitle">
                    <h1 class="textgradient">{{title}}</h1>
                    <h3>{{subtitle}}</h3>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-lg-6 mcont100">
                <p>{{description}}</p>
            </div>
        </div>
    </section>
    <section class="container-fluid ospesltyaccord" data-class="ospesltyaccord">

        <div #faq class="whitecolor-sec faqswrp" *ngFor="let section of group_sections; let i = index;">
            <div class="container">
                <div class="col-xs-12 col-lg-12">
                    <div class="GenraelPediatrics-aco" [ngClass]="i == 0 ? 'sel':'test'" data-class="GenraelPediatrics-aco">{{section.title}}</div>
                    <div class="GenraelPediatrics-Infotext" data-class="GenraelPediatrics-Infotext" style="display: block;">
                        <div class="row" >
                            <div class="row aco-wapper" >
                                <div *ngFor="let question of section.questions; let j=index;" style="width: 100%;">
                                    <div data-class="DoctorDesk-aco" [ngClass]="j == 0 ? 'sel':'test'" class="DoctorDesk-aco">{{question.question}}</div>
                                    <div class="infotext" [ngStyle]="{'display':j==0 ? 'block':'none'}" [innerHTML]="question.answer">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
