import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  ViewChildren,
  QueryList,
  HostListener,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL } from '../services/constants';
import { Meta, Title } from '@angular/platform-browser';
declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
//declare let awardsSlider: any;
//declare let initAwardsSlider:any;
declare let achievemwntAwardsSlider: any;

// declare let initAboutTabs:any;

@Component({
  selector: 'awards',
  templateUrl: './awards.component.html',
  styles: [
    `
      .DoctorsAwards li {
        width: 100vw !important;
      }
      .awardsBtn:nth-child(1) {
        border-right: 0px solid #cccccc !important;
      }
    `,
  ],
  /* styles: [
	  `
	  .hospitalAwardsSlider {
		margin-top: 100px;
	  }
	  .doctorAwardsSlider {
		margin-top: 100px;
	  }
	  .hospitalAwardsSlider li:not(.slick-current) .cirwrp {
		background: url(assets/images/awarsIcon.png) no-repeat;
	  }
	  .hospitalAwardsSlider li:not(.slick-current) .cirwrp img {
		opacity: 0;
	  }
	  .doctorAwardsSlider li:not(.slick-current) .cirwrp {
		background: url(assets/images/awarsIcon.png) no-repeat;
	  }
	  .doctorAwardsSlider li:not(.slick-current) .cirwrp img {
		opacity: 0;
	  }
	  `
	] */
})
export class AwardsComponent implements OnDestroy, OnInit, AfterViewInit {
  constructor(
    private api: ApiService,
    public metaTitle: Title,
    public meta: Meta
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
    }
  }

  public services: any = [];
  public hospitalAwards: any = [];
  public doctorsAwrds: any = [];
  public allAwards: any[];

  public title: any = '';
  public subtitle: any = '';
  public description: any = '';
  public imgsrc: any = '';
  public brief_facilities_description: any = '';
  public brief_facilities_image: any = '';
  public imgBasePath = '';
  public hidden = 'hidden';
  public logo = 'logo';
  public active: any = 'hospital-awards';
  public bread_crumb_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'About Us', link: '/about-us', class: '' },
    { label: 'Awards & Recognitions', link: '', class: '' },
  ];

  public links: any = [];
  public activePage: string = 'awards';

  @ViewChildren('awardsHospitalSlider') thingsHospital: QueryList<any>;
  @ViewChildren('awardsDoctorSlider') thingsDoctor: QueryList<any>;

  ngOnInit() {
    this.api
      .getAboutSubFooterDetails()
      .map((res) => res)
      .subscribe((data) => {
        this.links = data;
      });
    this.api.getPageDescription('awards-and-recognitions').subscribe(
      (data) => {
        (this.title = data.page_descriptions[0].title),
          (this.subtitle = data.page_descriptions[0].subtitle),
          (this.description = data.page_descriptions[0].description),
          (this.imgsrc = data.page_descriptions[0].image),
          (this.imgBasePath = IMG_BASE_URL);
        if (data) {
          //console.log(data);
          this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
          this.meta.updateTag({
            name: 'keywords',
            content: data.page_descriptions[0].meta_keyword,
          });
          this.meta.updateTag({
            name: 'description',
            content: data.page_descriptions[0].meta_description,
          });
          this.meta.addTags([
            {
              property: 'og:title',
              content: data.page_descriptions[0].meta_title,
            },
            {
              property: 'og:description',
              content: data.page_descriptions[0].meta_description,
            },
            {
              property: 'og:keywords',
              content: data.page_descriptions[0].meta_keyword,
            },
          ]);
        }
      },
      (error) => {
        console.log(error);
      }
    );

    this.getAwards('hospital-awards');

    /* this.api.getAllAwards('hospital-awards')
			.subscribe(
			  data =>{
				this.hospitalAwards = data.awards_histories; 
				console.log('hospitalAwards', this.hospitalAwards);
			  },
			  error =>{
				console.log(error)
			  }
			)
		  
		  this.api.getAllAwards('doctors-awards')
			.subscribe(
			  data =>{
				this.doctorsAwrds = data.awards_histories;
				console.log('hospitalAwards', this.doctorsAwrds);
			  },
			  error =>{
				console.log(error)
			  }
			) */
  }

  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
    }
    // this.thingsHospital.changes.subscribe(t => {

    // });
  }

  getAwards(id) {
    this.active = id;
    //console.log(this.active);
    this.api.getAllAwards(id).subscribe(
      (data: any) => {
        this.allAwards = data.awards_histories;
        setTimeout(() => {
          this.awardsSlider();
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }
  awardsSlider() {
    //function awardsSlider() {
    $('.awardsBtn:first a').addClass('active');
    $('.awardsSlider:first').addClass('active');
    $('.awardsBtn').each((index, element) => {
      $(this).click((e) => {
        e.preventDefault();
        $(this)
          .find('a')
          .addClass('active')
          .parents('.awardsBtn')
          .siblings()
          .find('a')
          .removeClass('active');
        var oClass = $(this).find('a').attr('data-showSlider');
        $('.' + oClass)
          .addClass('active')
          .siblings()
          .removeClass('active');
      });
    });

    //	}
    $('.awardsSlider')
      .not('.slick-initialized')
      .slick({
        // slide: 'li',
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        prevArrow:
          '<a class="slick-prev"><img src="assets/images/prv-arrow2.png" alt="" /></a>',
        nextArrow:
          '<a class="slick-next"><img src="assets/images/nxt-arrow2.png" alt="" /></a>',
        dots: false,
        centerMode: true,
        centerPadding: 20,
        focusOnSelect: true,
        infinite: true,
        responsive: [
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 680,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
  }
  hospitalAwardsSlider() {
    $('.hospitalAwardsSlider')
      .not('.slick-initialized')
      .slick({
        // slide: 'li',
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        prevArrow:
          '<a class="slick-prev"><img src="assets/images/prv-arrow2.png" alt="" /></a>',
        nextArrow:
          '<a class="slick-next"><img src="assets/images/nxt-arrow2.png" alt="" /></a>',
        dots: false,
        centerMode: true,
        centerPadding: 20,
        focusOnSelect: true,
        infinite: true,
        responsive: [
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 680,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
  }
  doctorAwardsSlider() {
    $('.doctorAwardsSlider')
      .not('.slick-initialized')
      .slick({
        // slide: 'li',
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: true,
        prevArrow:
          '<a class="slick-prev"><img src="assets/images/prv-arrow2.png" alt="" /></a>',
        nextArrow:
          '<a class="slick-next"><img src="assets/images/nxt-arrow2.png" alt="" /></a>',
        dots: false,
        centerMode: true,
        centerPadding: 20,
        focusOnSelect: true,
        infinite: true,
        responsive: [
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 680,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
  }
  @HostListener('window:load', [])
  onWindowLoad() {
    this.doctorAwardsSlider();
    this.hospitalAwardsSlider();
  }
  ngOnDestroy() {}
}
