<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <section class="page pageheader aboutushead">
        <div class="row">
            <div class="container">
                <div class="col-xs-12 col-sm-7 col-lg-7 abannimgwrp">
                    <div class="aboutbannimg"><img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" /></div>
                </div>
                <div class="col-xs-12 col-sm-5 col-lg-5">
                    <div class="ptitle blue">
                        <h1>{{title}}</h1>
                    </div>
                </div>
                <div class="row clearfix">
                    <div class="col-xs-12 col-sm-6 col-lg-6">
                        <div class="visionmwrp">
                            <h3>Our Vision</h3>
                            <p> Our aim at Rainbow Hospitals is to provide high standards of care for the mother, fetus, newborn and children so that none of them is deprived of a tertiary care facility. </p>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-lg-6">
                        <div class="visionmwrp">
                            <h3>Our Mission </h3>
                            <p> The measure of our success is in the number of smiling faces.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="ourvalues wwdovwrp">
        <div class="row">
            <div class="container">
                <div >
                    <h3 class="h3" >Our Values </h3>
                </div>
            </div>
        </div>
        <div class="row wwdovlist">
            <div class="container">
                <div class="col-md-12 col-sm-12 col-lg-12">
                    <ul class="csrlist">
                        <li *ngFor="let facility of brief_facilities">
                            <table cellpadding="0" cellspacing="0" border="0">
                                <tr>
                                    <td align="left" valign="top">
                                        <div class="csricon"><img src="{{imgBasePath}}{{facility.image}}" alt="" /></div>
                                    </td>
                                    <td align="left" [innerHTML]="facility.description"></td>
                                </tr>
                            </table>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <section #abouttabs class="aboutsectin2">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-lg-12">
                    <h3 class="sinfo">Our clinical specialties revolve around the following three clinical services and care:</h3>
                    <ul class="atabs">
                        <li *ngFor="let service of services;let i = index" [ngClass]="{'active':i == 1}">
                            <a href="javascript:void(0)" data-id="data1">
                                <div class="aiconwrp"><img src="{{imgBasePath}}{{service.image}}" alt="" /></div>
                                <h2>{{service.title}}</h2>
                            </a>
                        </li>
                        <!-- <li> <a href="javascript:void()" data-id="data2">
            <div class="aiconwrp"><img src="assets/images/icon-child-care2.png" alt="" /></div>
            <h2>Women Care</h2>
            </a> </li>
          <li> <a href="javascript:void()" data-id="data3">
            <div class="aiconwrp"><img src="assets/images/icon-child-care3.png" alt="" /></div>
            <h2>Fertility Care</h2>
            </a> </li> -->
                    </ul>
                    <div class="atabscontent">
                        <div *ngFor="let service of services" class="atabsdata">
                            <p [innerHTML]="service.description"></p>
                            <p><a href="{{service.knowmore_link}}" class="sknowmore">Know More</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>

    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>