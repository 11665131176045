<div class="capopup" data-popup="popup-1">
    <div class="capopup-inner">
        <h2>{{popupTitle}}</h2>
        <p>
            {{popupMsg}}
        </p>
        <a class="capopup-close" data-popup-close="popup-1" href="#"><img src="assets/images/icon-close2.png" alt="" /></a>
    </div>
</div>
<a href="https://www.rainbowhospitals.in/hello-doctor-pregnancy-teleconsultation-covd19/" target="_blank" class="iconhdoctor" style="display: none;"><img src="assets/images/hello_doctor.png" alt="" /></a>
<!-- <a href="javascript:void(0);" (click)="openChat();" class="iconcomment"><img src="assets/images/Icon_chat.png" alt="" /></a> -->
<a href="https://patientservices.rainbowhospitals.in/~feedback/" target="_blank" class="iconfeedback"><img src="assets/images/Icon_feedback.png" alt="" /></a>
<a href="https://appointment.rainbowhospitals.in:7100/PatientPortal/#/signin" target="_blank" class="iconreports"><img src="assets/images/Icon_reports.png" alt="" /></a>
<footer class="subscribewrp adfasd" style="display: none !important;">
    <div class="container">
        <div class="row subscribewrpm">
            <div class="container">
                <div class="col-md-12 col-sm-6 col-lg-4"> <strong>SUBSCRIBE NOW FOR Newsletter</strong> </div>
                <div class="col-md-12 col-sm-6 col-lg-6">
                    <form action="" [formGroup]="subscriptionForm">
                        <div class="subscribebox">
                            <input type="text" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" name="email" id="txtSubscribe" [ngClass]="{'has-error':!subscriptionForm.controls.email.valid && subscriptionForm.controls.email.touched}" formControlName="email" />
                            <input type="button" name="submit" [disabled]="!subscriptionForm.valid" value="Submit" class="btnSubmit" (click)="SubmitSubscriptionForm()" />
                        </div>
                    </form>

                </div>
                <!-- <a href="javascript:void(0);" (click)="openChat();" class="iconcomment"><img src="assets/images/Icon_chat.png" alt="" /></a> -->
                <a href="https://patientservices.rainbowhospitals.in/~feedback/" target="_blank" class="iconfeedback"><img src="assets/images/Icon_feedback.png" alt="" /></a>
                <a href="https://appointment.rainbowhospitals.in:7100/PatientPortal/#/signin" target="_blank" class="iconreports"><img src="assets/images/Icon_reports.png" alt="" /></a>
                <!--<a href="#" class="iconcomment"><img src="assets/images/icon-comment.png" alt="" /></a> -->
            </div>
        </div>
    </div>
</footer>