<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>
    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <section class="page wtwedo">
        <div class="row">
            <div class="col-md-5 col-sm-5 col-lg-4 col-xs-5">
                <div class="circuleframe"> <img src="{{imgBasePath}}{{imgsrc}}" alt="{{title}}" title="{{title}}" /> </div>
                <!-- End Circle -->
            </div>
            <div class="col-md-7 col-sm-7 col-lg-7 col-xs-7">
                <div class="pagetitle">
                    <h1 class="textgradient">{{title}}</h1>
                    <div class="underline"> <img src="assets/images/underline.png" alt="" /> </div>
                    <h3>{{subtitle}}</h3>
                </div>
            </div>
            <div class="col-md-7 col-xs-7 col-lg-8 mcontentview">

                <p> Use the tools below to search for a doctor by name, specialty, gender, service line or practice location. </p>
                <div class="dfindwrp">
                    <ul class="hlfcube">
                        <li>
                            <div class="locationwrp">
                                <select class="select" name="txtLocation">
                  <option value="Select location" selected>Select location </option>
                  <option value="DL">Delhi</option>
                  <option value="MU">Mumbai</option>
                </select>
                                <span id="selectlocation" class="select-span"></span> </div>
                            <div class="specialtywrp">
                                <select class="select" name="txtLocation">
                  <option value="Select location" selected>Select Specialty </option>
                  <option value="DL">Delhi</option>
                  <option value="MU">Mumbai</option>
                </select>
                                <span id="SelectSpecialty " class="select-span"></span> </div>
                            <div class="searchbtn">
                                <input type="submit" name="txtSearch" value="Search" />
                            </div>
                        </li>
                        <li>
                            <div class="finddwrp">
                                <div class="findbox">
                                    <input type="text" name="txtfinddoctors" onfocus="if (this.value==this.defaultValue) this.value = ''" onblur="if (this.value=='') this.value = this.defaultValue" value="Find a Doctor by name" />
                                </div>
                            </div>
                            <div class="gobtn">
                                <input type="submit" name="txtSearch" value="GO" />
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <section class="childdoctor-result">
        <div class="container">
            <ul>
                <li #doctors class="col-xs-12 col-sm-6 col-md-4" *ngFor="let doctor of doctors;">
                    <div class="doctorsListwrp">
                        <div class="dthumbwrp"> <img src="{{imgBasePath}}{{doctor.photo}}" alt="" /> </div>
                        <div class="ddetailswrp">
                            <h3>{{doctor.name}}</h3>
                            <p> {{doctor.qualification}}, {{doctor.designation}} </p>
                        </div>
                        <div class="dunderline"> <img src="assets/images/underline.png" alt="" /> </div>
                        <div class="dselectlwrp">
                            <!-- <div class="locationwrp">
                <div class="selectbox">
                  <select class="select" name="txtDLocation">
                    <option value="Select location" selected>Select location </option>
                    <option *ngFor="let center of doctor.centers" value="{{center.id}}">{{center.center_name}}</option>
                  </select>
                  <span id="selectDlocation" class="select-span"></span> </div>
              </div>
              <p>BirthRight by Rainbow</p> -->
                        </div>
                        <a href="women-care/doctors/{{doctor.id}}" class="knowmore2 active"><span>Know More</span></a> <a href="doctors.html" class="btn-Bookapopoi"><span>Book an appointment</span></a>
                    </div>
                </li>
            </ul>
        </div>
    </section>
    <footer-nav-mini [links]="links"></footer-nav-mini>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>