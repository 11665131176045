import { Component, OnInit } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { ApiService } from '../services/api.service';

declare let $: any;
declare let initHome: any;
declare let initGoogleMap: any;
declare let initAllPage: any;

@Component({
  selector: 'app-conference-pay',
  templateUrl: './conference-pay.component.html',
  styleUrls: [
    './conference-pay.component.scss',
    '../conference-on-special-pediatrics/conference-on-special-pediatrics.component.scss',
  ],
})
export class ConferencePayComponent implements OnInit {
  constructor(private api: ApiService) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;

      require('../../assets/js/bootstrap.js');
      require('../../assets/js/fancybox/jquery.fancybox.min.js');

      require('../../assets/js/slick.min.js');
    }
  }
  private subscription: Subscription;
  public dateNow = new Date();
  public dDay = new Date('Jul 15 2022 00:00:00');
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute = 60;

  public timeDifference;
  public secondsToDday;
  public minutesToDday;
  public hoursToDday;
  public daysToDday;

  public userName: string;
  public amount: string;
  public email: string;

  private getTimeDifference() {
    this.timeDifference = this.dDay.getTime() - new Date().getTime();
    this.allocateTimeUnits(this.timeDifference);
  }

  private allocateTimeUnits(timeDifference) {
    this.secondsToDday = Math.floor(
      (timeDifference / this.milliSecondsInASecond) % this.SecondsInAMinute
    );
    this.minutesToDday = Math.floor(
      (timeDifference / (this.milliSecondsInASecond * this.minutesInAnHour)) %
        this.SecondsInAMinute
    );
    this.hoursToDday = Math.floor(
      (timeDifference /
        (this.milliSecondsInASecond *
          this.minutesInAnHour *
          this.SecondsInAMinute)) %
        this.hoursInADay
    );
    this.daysToDday = Math.floor(
      timeDifference /
        (this.milliSecondsInASecond *
          this.minutesInAnHour *
          this.SecondsInAMinute *
          this.hoursInADay)
    );
  }

  ngOnInit(): void {
    this.userName = localStorage.getItem('userName');
    this.amount = localStorage.getItem('amount');
    this.email = localStorage.getItem('email');
    this.subscription = interval(1000).subscribe((x) => {
      this.getTimeDifference();
    });
  }
  ngAfterViewInit(): void {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      this.hideVirtualAssistant();
      let id = setTimeout(this.hideVirtualAssistant, 2500);
      initAllPage();
      // this.scrollToTop();
    }
  }

  hideVirtualAssistant() {
    $('#ymDivBar').remove();
  }
}
