import {Inject, Component, OnDestroy, OnInit, AfterViewInit, PLATFORM_ID, ViewChildren, QueryList } from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {ApiService} from "../services/api.service";
import {IMG_BASE_URL} from "../services/constants";
import { Meta, Title } from '@angular/platform-browser';
declare let $:any;
declare let initHome:any;
declare let initAllPage:any;

declare let initAboutTabs:any;

@Component({
  selector: 'about',
  templateUrl: './about.component.html'
})

export class WomenCareAboutComponent implements OnDestroy, OnInit, AfterViewInit {


  constructor(private api:ApiService, public metaTitle: Title, public meta: Meta) {
    if(this.api.isBrowser){
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
    }
  }

  public services:any[] = [];
  public brief_facilities:any[] = [];
  public title:any = "";
  public subtitle:any = "";
  public description:any = "";
  public imgsrc:any = "";
  public imgBasePath = "";
  public hidden = "hidden";
  public logo="birth-right-logo";
  public bread_crumb_links:any = [{"label":"Home", "link": "/", "class": ""},
                                 {"label":"Women Care", "link": "/women-care/about", "class": ""},
                                 {"label":"About Women Care", "link": "", "class": ""}];

  public links:any = [{"label":"About Women's Care", "link": "/women-care/about", "class": "active", "slug":"about"},
                      {"label":"Doctors", "link": "/women-care/doctors", "class": "", "slug":""},
                      {"label":"Our Specialities", "link": "/women-care/specialities", "class": "", "slug":""},
                      {"label":"Our Centres", "link": "/women-care/centres", "class": "", "slug":""},
                      {"label":"Testimonials", "link": "/women-care/testimonials", "class": "", "slug":""},
                      {"label":"Share Feedback", "link": "/women-care/feedback", "class": "", "slug":""},
                      {"label":"Book An Appointment", "link": "/women-care/book-an-appointment", "class": "", "slug":""}];
  public activePage:string = 'about';

  @ViewChildren('abouttabs') things: QueryList<any>;

  ngOnInit() {
    this.api.getPageDescription('women-care-about')
      .subscribe(
        data =>{
           this.title = data.page_descriptions[0].title;
          this.subtitle = data.page_descriptions[0].subtitle;
          this.description = data.page_descriptions[0].description;
          this.imgsrc = data.page_descriptions[0].image;
          this.imgBasePath = IMG_BASE_URL;
          
            if(data){
              //console.log(data);
              this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
              this.meta.updateTag({name:'keywords',content: data.page_descriptions[0].meta_keyword});
              this.meta.updateTag({name:'description',content: data.page_descriptions[0].meta_description});
              this.meta.addTags([
                { property: 'og:title', content:  data.page_descriptions[0].meta_title},
                { property: 'og:description', content: data.page_descriptions[0].meta_description },
                { property: 'og:keywords', content: data.page_descriptions[0].meta_keyword }
              ]);
              }
        },
        error => {
          console.log(error);
        }
      )

      this.api.getHealthCareServices('women-care-about')
        .subscribe(
          data =>{
            this.services = data.healthcare_services
          },
          error => {
            console.log(error);
          }
        )

        this.api.getBriefFacilities('women-care-about')
          .subscribe(
            data =>{
              this.brief_facilities = data.brief_facilities
            },
            error => {
              console.log(error);
            }
          )
  }

  ngAfterViewInit() {

    if ( this.api.isBrowser && window['jQuery'] ) {
      initHome();
      initAllPage();
    }

    this.things.changes.subscribe(t => {
      // this.ngForRendred();
      initAboutTabs();
    })
  }

  ngOnDestroy() {}

}
