<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <section class="pagebcwrp">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-lg-12">
                    <ul class="breadnav">
                        <li><a href="/">Home</a></li>
                        <li>Cancel Appointment</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>


    <!-- Start App Middle Contents Add after bread-crumb -->
    <section class="page wtwedo">
        <div class="container">
            <div class="col-md-12 col-xs-12 col-lg-12">
                <div class="pagetitle">
                    <h1 class="textgradient">Cancel Your Appointment</h1>  
                </div>
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7 timetablewrp ">
                <div class="dconfirmawrp" style="display:block;">
                    <div class="ttheader2">
                        <h4>Please enter your Appointment ID or Mobile Number to cancel your appointment(s).</h4>  
                        <form [formGroup]="searchAppointmentForm">
                            <ul class="formcasn">
                                <li>
                                <input type="text" name="AppointmentID" id="AppointmentID" placeholder="Appointment ID"
                                [ngClass]="{'has-error':!searchAppointmentForm.controls.AppointmentID.valid && searchAppointmentForm.controls.AppointmentID.touched}"
                                formControlName="AppointmentID" /></li>
                                
                                <li>
                                <input type="text" name="PMOBILENO" maxlength="10" id="PMOBILENO" placeholder="Mobile*"
                                [ngClass]="{'has-error':!searchAppointmentForm.controls.PMOBILENO.valid && searchAppointmentForm.controls.PMOBILENO.touched}"
                                required="" maxlength="10" 
                                (keypress)="isNumber($event)" 
                                formControlName="PMOBILENO"
                                    /></li>
                                <li><input type="button"name="submit" 
                                    class="canSubmit" 
                                    id="1caSubmit" 
                                    value="Search" 
                                    (click)="sentOTP()"  /></li>
                            </ul>
                        </form>
                        <div class="tandc">
                            <a class="cntnc" href="javascript:void(0);" (click)="CancellationPolicy()">Cancellation/Refund policy</a>
                            <!--<a class="cntnc" href="javascript:void(0);" (click)="CancellationTC()">T&C</a>-->
                        </div>
                    </div>
                </div>                
            </div>
            <div class="col-md-12 col-xs-12 col-lg-12 calculatorwrp nomorebookingwrp" *ngIf="noMoreBooking">
                <h1 class="textgradient">NO MORE UPCOMING BOOKING!</h1>
                <p><a class="btnVisiting" href="/all/doctors">Book New Appointment</a></p>
            </div>
            <div class="col-md-12 col-xs-12 col-lg-12 calculatorwrp" *ngIf="ConfirmBookingDataTable">               
                <div class="LCTablewrp caTable" style="position: relative;" *ngIf="ConfirmBookingData.length > 0">
                    <span class="loader3" *ngIf="loader2">
                        Your cancellation request is being processed. <br>Please keep this page open until it's done. 
                        <br>
                        <img src="assets/css/ajax-loader.gif" alt=""/>
                    </span>
                    <table border="0" cellpadding="0" cellspacing="0">
                        <tr>
                            <th>Doctor’s Name</th>
                            <th>Appointment ID</th>
                            <th>Time</th>
                            <th>Paid/Unpaid</th>
                            <th>Action</th>
                        </tr>
                        <tr *ngFor="let btada of ConfirmBookingData;">
                            <td>{{ btada.name }}</td>
                            <td>{{ btada.account_number }}</td>
                            <td>{{ btada.appointmentdate }}</td>
                            <td><span *ngIf="btada.paymentstatus=='Success'">Paid</span><span *ngIf="btada.paymentstatus!='Success'">Unpaid</span></td>
                            <td>
                                <a class="cmInfo" href="javascript:void(0);" style="display: none;">
                                    <div class="cmaTooltip">
                                        <div class="tandc">
                                            <a class="cntnc" href="javascript:void(0);" (click)="CancellationPolicy()">Cancellation/Refund policy</a>
                                            <!--<a class="cntnc" href="javascript:void(0);">T&C</a>-->
                                        </div>
                                    </div>
                                </a>
                                <a class="kickview" href="javascript:void(0);" (click)="cancelAppointmentAsk(btada.account_number)">CANCEL</a>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </section>
    
    <div class="capopup" data-popup="popup-cancelTnC">
        <div class="capopup-inner-tcn">
            <h2>Terms and Conditions</h2>
            <div class="contentwrp">
                <p>All the information displayed, transmitted or carried by Rainbow Children’s Medicare Private Limited and its subsidiaries (hereinafter called Rainbow Hospital) including, but not limited to, news articles, opinions, reviews, text, photographs, images, illustrations, profiles, audio clips, video clips, trademarks, service marks and others (collectively the "Content,") on this Rainbow Hospital’s website is for informational purposes only.</p>
            </div>
            <a class="otp-popup capopup-close" data-popup-close="popup-cancelTnC" href="javascript:void(0);" (click)="closePopup()"><img src="assets/images/icon-close2.png" alt="" /></a>
        </div>
    </div>
    <div class="capopup" data-popup="popup-cancelpolicy">
        <div class="capopup-inner-tcn">
            <h2>Refund and Cancellation Policy</h2>
            <div class="contentwrp">
                <p>This policy is approved by the management of Rainbow Children’s Medicare Private Limited (RCMPL) and is applicable for all units of RCMPL and its affiliates. This policy is subject to revisions based on the decisions of the management.</p>
                <ol>
                    <li>
                        <p>Amount once paid through the payment gateway shall not be refunded other than in the following circumstances:</p>
                        <ol type="a">
                            <li> <p>Multiple times debiting of Customer’s Bank Account due to technical error OR Customer's
                                account being debited with excess amount in a single transaction due to technical error.</p>
                            </li>
                            <li> <p>In case the customer has paid the full amount and due to non availability of the doctor the
                                consultation cannot be provided. The cutomer can opt for using the amount as a deposit for
                                any future consultations at Rainbow. </p>
                            </li>
                        </ol>
                    </li>
                    <li> <p>If the services have not been availed and customer does not intend to keep it as a deposit with
                        Rainbow
                        childrens medicare Pvt Ltd. The customer can send and email to <a href="mailto:refund@rainbowhospitals.in">refund@rainbowhospitals.in</a> in the detailed format
                        shared in Annexure1 below. </p>
                    </li>
                    <li> <p>The refund request will be processed manually after due verification. If the claim is found valid
                        the eligible amount will be refunded by Rainbow Children’s Medicare Pvt Ltd through electronic mode
                        within the next Seven (7) working days from the date of receiving the email request in the format
                        shared in Annexure1 below. </p>
                    </li>
                    <li> <p>It may take 3 to 21 working days for the payment to reflect in your bank account depending on your
                        banks policy.</p>
                    </li>
                    <li> <p>Company assumes no responsibility and shall incur no liability if it is unable to effect any
                        Payment Instruction(s) on the Payment Date owing to any one or more of the following
                        circumstances:</p>
                        <ol type="a">
                            <li><p>If the payment instructions(s) issued by you is/are incomplete, inaccurate and invalid.</p></li>
                            <li><p>If the receiving bank refuses or delays in honoring the Payment instruction(s).</p></li>
                            <li><p>Circumstances beyond the control of Company (including, but not limited to,fire,
                                flood,natural disasters, bank strikes, power failure, systems failure like computer or
                                telephone lines breakdown due to an unforeseeable cause or interference from an outside
                                force)</p></li>
                            <li><p>In case the payment is not effected for any reason, you will be intimated about the failed
                                payment by an e-mail.</p></li>
    
                        </ol>
                    </li>
                    <li> <p>User agrees that Company, in its sole discretion, for any or no reason, and without penalty, may
                        suspend or terminate his/her account (or any part thereof) or use of the services and remove and
                        discard all or any part of his/her account, user profile, or his/her recipient profile, at any time.
                        Company may also in its sole discretion and at any time discontinue providing access to the
                        Services, or any part thereof, with or without notice. User agrees that any termination of his/her
                        access to the service or any account he/she may have or portion thereof may be effected without
                        prior notice, and also agree that company will not be liable to user or any third party for any such
                        termination. Any suspected, fraudulent, abusive or illegal activity may be referred to appropriate
                        law enforcement authorities. These remedies are in addition to any other remedies company may have
                        at law or in equity. Upon termination for any reason, user agrees to immediately stop using the
                        services.</p>
                    </li>
                    <li> <p>Company may elect to resolve any dispute, controversy or claim arising out of or relating to this
                        Agreement or Service provided in connection with this Agreement by binding arbitration in accordance
                        with the provisions of the Indian Arbitration &amp; Conciliation Act 1996, Any such dispute, controversy
                        or claim shall be arbitrated on an individual basis and shall not be consolidated in any arbitration
                        with any claim or controversy of any other party.</p>
                    </li>
                    <li> <p>Governing Law &amp; Jurisdiction: Subject to the foregoing, it is hereby expressly agreed and declared
                        that court of competent jurisdiction in Hyderabad alone shall have jurisdiction with respect to
                        matters pertaining hereto.</p></li>
                </ol> 
            </div>
            <a class="otp-popup capopup-close" data-popup-close="popup-cancelpolicy" href="javascript:void(0);" (click)="closePopup()"><img src="assets/images/icon-close2.png" alt="" /></a>
        </div>
    </div>
    <div class="capopup" data-popup="popup-AppointmentNotCancel">
        <div class="capopup-inner caAsk">
            <h2>Current date cancelation will not be allowed, Please call 18002020</h2>
            <a class="otp-popup capopup-close" data-popup-close="popup-AppointmentNotCancel" href="javascript:void(0);" (click)="closePopup()"><img src="assets/images/icon-close2.png" alt="" /></a>
        </div>
    </div>
    <div class="capopup" data-popup="popup-cancelAppointmentAsk">
        <div class="capopup-inner caAsk">
            <h2>Are you sure you want to cancel your appointment?</h2>
            <div class="yesnobtnwrp" *ngIf="yesnobtn">
                <a href="javascript:void(0);" class="yesnobtn" (click)="clickYes()">YES</a>
                <a href="javascript:void(0);" class="yesnobtn" (click)="closePopup()">NO</a>
            </div>
            <div class="yesnobtnwrp formSWrp" *ngIf="cReasonForm">
                <form [formGroup]="cancelAppointmentReasonForm">
                    <ul class="formcasn">
                        <li>
                            <textarea type="text" name="aCancellationReason" placeholder="Please share your reasons for cancellation."  formControlName="aCancellationReason"></textarea>
                        </li>
                        <li>
                            <input type="button" name="submit" class="canSubmit" value="Submit" (click)="cancelAppointment()"  />
                        </li>
                    </ul>
                </form>
            </div>
            <div class="yesnobtnwrp formSWrp" *ngIf="checkGetBankForm">
                <form [formGroup]="cancelAppointmentForm">
                    <ul class="formcasn">
                        <li>
                            <div class="selectboxWrp" [ngClass]="{ 'has-error': submitted && f.remarkRefundReuse.errors }">
                                <select class="select" formControlName="remarkRefundReuse" (change)="onChangerRRR($event)">
                                    <option value="" selected="select">Select Remark*</option>
                                    <option value="Online Refund">Online Refund</option>
                                    <option value="Reuse in Hospital">Reuse in Hospital</option>
                                </select>
                                <span id="remarkRefundReuse" class="select-span">Select Remark*</span>
                            </div>
                            <div *ngIf="submitted && f.remarkRefundReuse.errors" class="hasEerrorMsg">
                                <div *ngIf="f.remarkRefundReuse.errors.required">Remark is required</div>
                            </div>
                        </li>
                        <li *ngIf="OnlineRefund">
                            <input type="text" name="AccountHolderName" id="AccountHolderName" placeholder="Account Holder Name*" [ngClass]="{ 'has-error': submitted && f.AccountHolderName.errors }" formControlName="AccountHolderName" />
                            <div *ngIf="submitted && f.AccountHolderName.errors" class="hasEerrorMsg">
                                <div *ngIf="f.AccountHolderName.errors.required">Account Holder Name is required</div>
                            </div>
                        </li>
                        <li *ngIf="OnlineRefund">
                            <input type="text" name="BankAccountNumber" id="BankAccountNumber" placeholder="Bank Account Number*" [ngClass]="{ 'has-error': submitted && f.BankAccountNumber.errors }" formControlName="BankAccountNumber" />
                            <div *ngIf="submitted && f.BankAccountNumber.errors" class="hasEerrorMsg">
                                <div *ngIf="f.BankAccountNumber.errors.required">Bank Account Number is required</div>
                                <div *ngIf="f.BankAccountNumber.errors?.pattern">Please specify a valid Account Number</div>
                            </div>
                        </li>
                        <li *ngIf="OnlineRefund">
                            <input type="text" name="IFSCCode" id="IFSCCode" placeholder="IFSC Code*" [ngClass]="{ 'has-error': submitted && f.IFSCCode.errors }" maxlength="11" formControlName="IFSCCode" />
                            <div *ngIf="submitted && f.IFSCCode.errors" class="hasEerrorMsg">
                                <div *ngIf="f.IFSCCode.errors.required">IFSC Code is required</div>
                                <div *ngIf="f.IFSCCode.errors?.pattern">Please specify a valid IFSC CODE</div>
                            </div>
                        </li>                        
                        <li>
                            <textarea type="text" name="CancellationReason" id="CancellationReason" placeholder="Please share your reasons for cancellation." formControlName="CancellationReason"></textarea>
                        </li>
                        <li>
                            <input type="button" name="submit" class="canSubmit" value="Submit" (click)="cancelAppointment()"  />
                        </li>
                    </ul>
                </form>
            </div>
            <a *ngIf="closePopupBTN" class="otp-popup capopup-close" data-popup-close="popup-cancelAppointmentAsk" href="javascript:void(0);" (click)="closePopup()"><img src="assets/images/icon-close2.png" alt="" /></a>
        </div>
    </div>
    <div class="capopup" data-popup="popup-sendotp">
        <div class="capopup-inner caInnerWrp">
            <h2>Appointment Cancellation</h2>
            <h3>Please enter the OTP sent to your registered mobile {{otpMobileNumber}} to cancel your appointment.</h3>
            <span class="otpMsg" *ngIf="otpMsg.length > 0">{{otpMsg}}</span>
            <form name="CAppointment" id="Appointment" method="post" [formGroup]="CSendOTPForm">
                <ul class="otpwrp">
                    <li><input type="text" name="userOTPCode" id="txtOtp" maxlength="4" placeholder="Enter OTP" 
                        [ngClass]="{'has-error':!CSendOTPForm.controls.userOTPCode.valid && CSendOTPForm.controls.userOTPCode.touched}"
                        required="" maxlength="4" 
                        (keypress)="isNumber($event)" 
                        formControlName="userOTPCode"
                        /></li>
                    <li><input type="button" name="submit" class="casubmit" id="OtpSubmit" value="Submit" [disabled]="!CSendOTPForm.valid" (click)="confirmOTP()" /></li>
                </ul>
            </form>
            <div class="linkwrp">
                <ul>
                    <li>Still not received OTP?</li>
                    <li>
                        <div class="resendlinkwrp">
                            
                            <a href="javascript:void(0);" (click)="resendOTP()">Resend OTP</a>
                        </div>
                    </li>
                </ul>
            </div>
            <a class="otp-popup capopup-close" data-popup-close="popup-sendotp" href="javascript:void(0);" (click)="closePopup()"><img src="assets/images/icon-close2.png" alt="" /></a>
        </div>
    </div>
    <!-- End App Middle Contents before footer-nav-mini 
    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>-->
    <find-a-doctors-nav></find-a-doctors-nav>

    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>