<div class="container-fluid">
  <div class="loder"></div>
</div>
<div class="row header">
  <div class="container mx-auto">
    <a href="https://rainbowhospitals.in/" target="_blank"
      ><img
        src="assets/images/RCH-logo.png"
        alt=""
        style="transform: scale(85%)"
    /></a>
  </div>
</div>

<section class="cosp-first">
  <div class="cosp-hero-container">
    <div class="cosp-hero-column-outer-container">
      <div class="cosp-hero-column-container-bg">
        <!-- <img src="" /> -->
      </div>
      <div class="container">
        <div class="cosp-hero-column-container row">
          <div class="row col-sm-12 mx-auto">
            <div class="cosp-hero-columns col-lg-4 col-sm-12">
              <!-- <h1 class="cosp-hero-title">
                Conference on Updates In Special Pediatrics
              </h1> -->
              <img
                src="assets/images/conference-on-special-pediatrics/CUSP - Anouncement Post 010622.png"
                style="width: 100%"
              />
            </div>
            <div class="cosp-hero-columns col-lg-4 col-sm-12">
              <h1 class="cosp-hero-sub-title">Bank Details</h1>
              <ul class="cosp-highlights">
                <li>Account&nbsp;No.: <b>05212320003780</b></li>
                <li>IFSC&nbsp;:&nbsp; <b>HDFC0000521</b> </li>
                <li>
                  Bank Address: <b>HDFC Bank LTD 6-3-24B/1/1/A ,Bhaskar Plaza , Road No.1 Banjara Hills Hyderabad-500034, Telangana</b>
                </li>
              </ul>
            </div>
            <div class="cosp-hero-columns col-lg-4 col-sm-12">
              <div class="cosp-form-outer-container">
                <div class="inner-container">
                  <h3>Name: <span>{{userName}}</span></h3>
                  <h3>Email: <span>chandanbauri441@gmail.com</span></h3>
                  <h3>Total Payable Amount: <span>{{amount}} /-</span></h3>
                  <p>To initiate the Payment process <br />
                      please scan this QR code .
                  </p>
                  <img src="/assets/images/conference/qr.png" />
              </div>
              </div>
            </div>
          </div>
          <div
            class="cosp-hero-columns col-md-12"
            style="width: 100%; overflow: hidden"
          >
            <div
              class="cosp-location-strip"
              style="width: 100%; overflow: hidden"
            >
              <div class="row mx-auto" style="text-align: center">
                <div class="col-sm-12 col-md-4">
                  <!-- <div class="cosp-strip-icon">
                    <img src="" />
                  </div> -->
                  <h3>17<sup>th</sup> JULY 2022</h3>
                </div>
                <div class="col-sm-12 col-md-4">
                  <!-- <div class="cosp-strip-icon">
                    <img src="" />
                  </div> -->
                  <h3>JW Marriott Hotel , Vittal Mallya Road, Bengaluru</h3>
                </div>
                <div class="col-sm-12 col-md-4">
                  <!-- <div class="cosp-register-now-btn">
                    <button class="cosp-reg-now">Register Now</button>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="main-section">
    <div class="content-container">

    </div>
</section>

<section class="cosp-second">
  <div class="container">
    <div class="row mx-auto mb-5">
      <div class="timer mx-auto">
        <div id="days">{{ daysToDday }} <span>Days</span></div>
        <div id="hours">{{ hoursToDday }} <span>Hrs</span></div>
        <div id="minutes">{{ minutesToDday }} <span>Min</span></div>
        <div id="seconds">{{ secondsToDday }} <span>sec</span></div>
      </div>
    </div>
    <div
      class="row mx-auto"
      style="width: 100%; color: #7c3996; margin-top: 2em !important"
    >
      <div class="col-sm-12 col-lg-12">
        <h1 style="text-align: center">Workshop Details</h1>
      </div>
      <div
        class="col-sm-12 col-lg-6"
        style="
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        "
      >
        <h1>15<sup>th</sup> JULY</h1>
        <h2>Neu Advanced Neonatal Ventilation Workshop</h2>
        <p style="color: #231745">
          <b>VENUE</b>:&nbsp;Rainbow Children's Hospital Marathahalli
          Bengaluru
        </p>
      </div>
      <div
        class="col-sm-12 col-lg-6"
        style="
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        "
      >
        <h1>16<sup>th</sup> JULY</h1>
        <h2>
          SONIC Workshop: <br />
          Point of Care Ultrasound
        </h2>
        <p style="color: #231745">
          <b>VENUE</b>:&nbsp;Rainbow Children's Hospital Marathahalli
          Bengaluru
        </p>
      </div>
    </div>
  </div>
</section>

<section class="cosp-event-description">
  <div class="container">
    <h1 class="cosp-event-title">
      CUSP - Conference on Updates in Specialty Paediatrics, JW Marriott ,
      Bangalore <br />
      July 17th 2022 <br />
      9:00am to 18:30
    </h1>
    <div class="cosp-table-container">
      <h3>9:00- 9:30 Registration</h3>
      <table class="table table-striped table-bordered">
        <thead>
          <tr>
            <th scope="col">
              SESSION I : 9:30 - 11:15 A.M <br />
              CHAIRPERSONS: Dr.Prakash Vemgal, Dr.Naveen Kini, Dr.Priya
              Shivalli
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Food allergy: Is it preventable?</td>
            <td>9:30 - 9:50</td>
            <td>Dr. Nandana Bala</td>
          </tr>
          <tr>
            <td>IBD In children: what is the “ gut feeling”</td>
            <td>9:50 - 10:10</td>
            <td>Dr. Lavenya R P</td>
          </tr>
          <tr>
            <td>
              PANEL DISCUSSION: COVID Aftermath in Children: Long COVID and
              Immune Dysregulation
            </td>
            <td>10:10 - 10:55</td>
            <td>
              <b>Moderator:</b> <br />
              Dr. Dinesh Chirla <br />

              <b>Panelists:</b> <br />
              Dr. Ravichander <br />
              Dr. Bhaskar Shenoy <br />
              Dr. Manu Chaudhary<br />
              Dr. Chandrika S Bhat<br />
            </td>
          </tr>
          <tr>
            <td>Bed Wetting: Truth Revealed</td>
            <td>10:55 – 11:15</td>
            <td>Dr. Saumil Gaur</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="cosp-table-container">
      <h3>
        11:15 - 11:30 AM: INAUGURATION <br />
        11:30 – 11:45 AM: TEA BREAK
      </h3>
      <table class="table table-striped table-bordered">
        <thead>
          <tr>
            <th scope="col">
              SESSION II: 11:45 – 13:45 <br />
              Chairpersons: Dr.Kishore Phadke, Dr. Madhuri , Dr.Sumitha Nayak
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              The truth behind precocious puberty: Is it really on the rise
              and whom to blame
            </td>
            <td>11:45 - 12:15</td>
            <td>Dr. Kavitha Bhat</td>
          </tr>
          <tr>
            <td>
              Targeted therapy in paediatric oncology; Is the future free of
              chemotherapy
            </td>
            <td>12:15 - 12:35</td>
            <td>Dr. Vasudha N Rao</td>
          </tr>
          <tr>
            <td>
              <b>DEBATE: </b> <br />
              Is evidence-based pediatrics the axiom for providing care?
            </td>
            <td>12:35 - 12:55</td>
            <td>
              <b>Moderator</b>: Dr.Dinesh Chirla <br />
              For: Dr. Sourabh Dutta <br />
              Against: Dr.Jagdish Chinappa
            </td>
          </tr>
          <tr>
            <td>
              Non Alcoholic Fatty Liver disease in Children; Current
              Perspectives.
            </td>
            <td>12:55 - 13:15</td>
            <td>Dr. Sanjay Bansal</td>
          </tr>
          <tr>
            <td>
              <b>INTERESTING CASES:</b> <br />
              Case 1: Hypotension: Think beyond the heart <br />
              Case 2: Pediatric Surgery
            </td>
            <td>13:15 - 13:35</td>
            <td>
              Dr. Mounica Reddy (CAH) <br />
              Dr. Antony Roberts
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="cosp-table-container">
      <h3>13:35 - 14:20 P.M : LUNCH</h3>
      <table class="table table-striped table-bordered">
        <thead>
          <tr>
            <th scope="col">
              SESSION III : 14:20 To 16:10 <br />
              Chairpersons: Dr.Bhaskar Shenoy, Dr.Gnanamurthy, Dr.Nagesh
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Refractory Status Epilepticus: My Approach</td>
            <td>14:20 - 14:40</td>
            <td>Dr. Vrajesh Udani</td>
          </tr>
          <tr>
            <td>Brain on Fire !! What’s new in Immune Encephalopathy?</td>
            <td>14:40 - 15:00</td>
            <td>Dr. Deiva Kumaran</td>
          </tr>
          <tr>
            <td><b>Kindly correlate clinically</b></td>
            <td>15:00 – 15:30</td>
            <td>
              Moderator: Dr Rakshay Shetty <br />
              Dr Indumathi CK <br />
              Dr Satish Kumar <br />
              Dr Girish HC <br />
              Dr Vinu N
            </td>
          </tr>
          <tr>
            <td>
              Recurrent Fever; When to suspect auto inflammatory disorders
            </td>
            <td>15:30 - 15:50</td>
            <td>Dr. Chandrika S Bhat</td>
          </tr>
          <tr>
            <td>Gut Microbiota and Health: An essential Symbiosis?</td>
            <td>15:50 - 16:10</td>
            <td>Dr. Ira Shah</td>
          </tr>
          <tr>
            <td>Case 1: Unusual case of Pulmonary hypertension</td>
            <td>16:10-16:30</td>
            <td>
              Dr Deepak R <br />
              Dr Prabhjot Kaur <br />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="cosp-table-container">
      <h3>16:30 - 16:45 : TEA BREAK</h3>
      <table class="table table-striped table-bordered">
        <thead>
          <tr>
            <th scope="col">
              SESSION IV : 16:45 to 18:30 CHAIRPERSONS: Dr.Basavaraj,
              Dr.Swarnarekha Bhat ,Dr.Ravichander
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>NICU TALK TBD</td>
            <td>16:45- 17:05</td>
            <td>Dr. Sourabh Dutta</td>
          </tr>
          <tr>
            <td>
              <b>Panel discussion :</b>
              Improving Neurodevelopmental outcomes in Newborn: What have we
              learnt so far?
            </td>
            <td>17:05 - 17:50</td>
            <td>
              <b>Moderator</b> - Dr.Arvind Shenoi <br />
              <b>Panelists:</b> <br />
              Dr.Dinesh Chirla <br />
              Dr.Prakash Vemgal <br />
              Dr.Ranjan Pejawar <br />
              Dr. Sourabh <br />
              Dutta Dr.Babu Madarkar <br />
            </td>
          </tr>
          <tr>
            <td>SEPSIS and MODS; Where are we now?</td>
            <td>17:50- 18:10</td>
            <td>Dr.Joe Carcillo</td>
          </tr>
          <tr>
            <td>Severe Dengue; Is there a role for Immunomodulation</td>
            <td>18:10 - 18:30</td>
            <td>Dr.Rakshay Shetty</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</section>
<footer class="contact-us-section">
  <div style="width: 100%; background-color: #231745; padding: 2em 0px">
    <div class="container">
      <div class="row mx-auto">
        <div class="col-lg-4 col-sm-12 cosp-con-columns">
          <p>
            For Registration contact <br />
            Mr. Karthik <a href="tel:+918884436033">+91 8884436033</a>
          </p>
        </div>
        <div class="col-lg-4 col-sm-12 cosp-con-columns">
          <p>
            For More information <br />
            Dr. Vasudha Rao <a href="tel:+917899925948">+91 7899925948</a>
          </p>
        </div>
        <div class="col-lg-4 col-sm-12 cosp-con-columns">
          <p>
            or write to us at <br />
            <a href="mailto:cusprch@gmail.com">cusprch@rainbowhospitals.in</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</footer>

