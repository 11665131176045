<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>

    <section class="page wtwedo">
        <div class="row">
            <div class="col-md-5 col-xs-5 col-lg-4">
                <div class="circuleframe">
                    <img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" />
                </div>
                <!-- End Circle -->
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7">
                <div class="pagetitle">
                    <h1 class="tgradient">{{title}} </h1>
                    <div class="underline">
                        <img src="assets/images/underline2.png" alt="" />
                    </div>
                    <h3>{{subtitle}}</h3>
                </div>
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7 mcontentview">
                <p [innerHTML]="description"></p>
            </div>
        </div>

    </section>
    <section class="aboutsectin2 awardswapper">
        <div class="container">
            <div class="row nsg_text">
                <div class="col-xs-12 col-lg-12">
                    <h2>How does it occur?</h2>
                    <p>PMS are related to hormonal changes during the menstrual cycle. It is not due to a hormone imbalance, or due to too much or too little of any hormone (as was previously thought). However, ovulation (when you release an egg from an
                        ovary each month) appears to trigger symptoms. It is thought that women with PMS are more sensitive to the normal level of progesterone. This hormone is passed into the bloodstream from the ovaries after you ovulate. . At present
                        the consensus view is that the woman’s hormone levels are not at fault. In the fortnight preceding menstruation all women produce a hormone called progesterone. This is normal. However women with PMS do not tolerate this hormone
                        and exhibit symptoms. This is like an allergic response to the body’s own hormone</p>
                    <p>One effect of over-sensitivity to progesterone seems to reduce the level of a brain chemical (neurotransmitter) called serotonin. This may lead to symptoms, and may explain why medicines that increase the serotonin level work in PMS.</p>
                </div>
            </div>
            <div class="col-xs-12 col-lg-12">
                <h2>How is it diagnosed?</h2>
                <p>PMS cannot be diagnosed from any one physical exam or lab test. There is no test for PMS. The diagnosis of PMS is based on your symptoms. Sometimes it is difficult to tell if your symptoms are due to PMS, or if they are due to other conditions
                    such as anxiety or depression. Symptoms that always appear 1 to 2 weeks before your periods may be caused by PMS. For you to be diagnosed with PMS, your monthly symptoms must significantly interfere with work, school, or relationships.
                    Diagnosis can be difficult and may take several months of careful observation.</p>
            </div>
            <div class="col-xs-12 col-lg-12">
                <h2>How is it treated?</h2>
                <p>No one therapy works for all women. Understanding the problem, knowing when the symptoms are coming and planning a coping strategy are all that is required for many women.Aerobic exercise, rest, stress reduction, and less salt, caffeine,
                    and refined sugar in your diet may help.</p>
                <p><strong>Lifestyle and home remedies:</strong> You can sometimes manage or reduce the symptoms of premenstrual syndrome by making changes in the way you eat, exercise and approach daily life. Try these approaches:</p>
                <p><strong>Modify your diet:</strong> Eat smaller, more frequent meals to reduce bloating and the sensation of fullness. Limit salt and salty foods to reduce bloating and fluid retention. Choose foods high in complex carbohydrates, such as
                    fruits, vegetables and whole grains. Choose foods rich in calcium. If you can't tolerate dairy products or aren't getting adequate calcium in your diet, you may need a daily calcium supplement. Take a daily multivitamin supplement.
                    Avoid caffeine and alcohol.</p>
                <p><strong>Incorporate exercise into your regular routine: </strong>Engage in at least 30 minutes of brisk walking, cycling, swimming or other aerobic activity most days of the week. Regular daily exercise can help improve your overall health
                    and alleviate symptoms such as fatigue and a depressed mood.</p>
                <p><strong>Reduce stress:</strong> Get plenty of sleep. Practice progressive muscle relaxation or deep-breathing exercises to help reduce headaches, anxiety or trouble sleeping (insomnia). Try yoga or massage to relax and relieve stress.</p>
                <p><strong>Record your symptoms for a few months: </strong>Keep a record to identify the triggers and timing of your symptoms. This will allow you to intervene with strategies that may help to lessen them.</p>
                <p><strong>Psychotherapy: </strong>Counseling may help you deal with your emotional or relationship difficulties. Cognitive behavioral therapy may also help with PMDD.</p>
                <p><strong>Medicines:</strong> There are a variety of medicines which can be prescribed depending on the nature and severity of your symptoms. You have to consult your gynaecologist for this.</p>
                <p><strong>SSRI:</strong> An SSRI is a medicine commonly prescribed to treat more severe PMS. These medicines were developed to treat depression. However, they have also been found to ease the symptoms of PMS, even if you are not depressed.
                    They work by increasing the level of serotonin in the brain. Research suggests that taking an SSRI for just half of the cycle (the second half of the monthly cycle) is just as effective as taking an SSRI all of the time. Side-effects
                    occur in some women, although most women have no problems taking an SSRI. There are various types and brands.</p>
                <p><strong>The combined oral contraceptive pill: </strong>In theory, preventing ovulation should help PMS. This is because ovulation, and the release of progesterone into the bloodstream after ovulation, seems to trigger symptoms of PMS.
                    The COCP (known as 'the pill') works as a contraceptive by preventing ovulation. However, most pills do not help with PMS, as they contain progestogen hormones (with a similar action to progesterone). A newer type of pill contains
                    a progestogen called drospirenone which does not seem to have the downside of other progestogens. If you have PMS and require contraception, then this pill may be a possible option to use for both effects.</p>
                <p><strong>Oestrogen:</strong> Oestrogen given via a patch or gel has been shown to improve symptoms by suppressing egg production. Oestrogen tablets are not effective though. However, you will also need to take progestogens if you have not
                    had a hysterectomy. These can be taken as tablets or by having the Mirena® inserted. The doses of oestrogen in a patch are much lower than in the COCP, so the patch is not a method of contraception.</p>
                <p><strong>Gonadotrophin-releasing hormone analogues:</strong> Other methods of suppressing ovulation include gonadotrophin-releasing hormone analogues. They are usually given by injection and with hormone replacement therapy (HRT) to protect
                    your womb.</p>
                <p>Surgery to remove both ovaries also prevents ovulation and is likely to cure PMS, although is a drastic option. Because of this, it is only done in the most severe cases where nothing else has helped.</p>
            </div>
        </div>
    </section>
    <ng-template [ngIf]="ins_links.length > 0">
        <footer-nav-mini [links]="ins_links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>