import { Component, Input } from '@angular/core';
import { ApiService } from "../services/api.service";

declare let $: any;

@Component({
	selector: 'footer-nav-mini',
	templateUrl: './footernav-mini.component.html',
})
export class FooternavMiniComponent {
	@Input() links:any;

	@Input() activePage: any;

	constructor(private api: ApiService) {
		if (this.api.isBrowser) {
			window['jQuery'] = $;
			window['$'] = $;
		}
		
	}
	ngAfterViewInit() {
		this.links.forEach(element => {
			if(element.label == 'Careers'){
				element.link = 'https://ehubnext.myadrenalin.com/candidate/LoginPage.aspx?obj=0qKjcPeCekWVUp%2f8Wz3cmM5%2fF4SZ%2bT68';
				element.class = 'CareersBtn';
			}		
		});
		console.log('links:', this.links);
		
		if (this.api.isBrowser && window['jQuery']) {
			var $fncarousel2UL = $('body').find('.fncarousel2 ul');
			$fncarousel2UL.not('.slick-initialized').slick({
				slide: 'li',
				slidesToShow: 7,
				slidesToScroll: 1,
				arrows: true,
				prevArrow: '<a class="slick-prev"><img src="assets/images/navprew.png" alt="" /></a>',
				nextArrow: '<a class="slick-next"><img src="assets/images/navnext.png" alt="" /></a>',
				dots: false,
				centerMode: false,
				centerPadding: 20,
				focusOnSelect: true,
				infinite: false,
				responsive: [
					{
						breakpoint: 1024, settings: {
							slidesToShow: 3, slidesToScroll: 1
						}
					},
					{
						breakpoint: 768, settings: {
							slidesToShow: 3, slidesToScroll: 1
						}
					},
					{
						breakpoint: 520, settings: {
							slidesToShow: 2, slidesToScroll: 1, centerPadding: 2,
						}
					},
					{
						breakpoint: 400, settings: {
							slidesToShow: 1, slidesToScroll: 1, centerPadding: 2,
						}
					}
				]
			});

		}
	}

}
