import {Inject, Component, OnDestroy, OnInit, AfterViewInit, PLATFORM_ID } from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {ApiService} from "../services/api.service";
import { Meta, Title } from '@angular/platform-browser';
declare let $:any;
declare let initHome:any;
declare let initAllPage:any;

@Component({
  selector: 'centers',
  templateUrl: './centers.component.html'
})

export class WomenCareCentersComponent implements OnDestroy, OnInit, AfterViewInit {


  constructor(private api:ApiService, ) {
    if(this.api.isBrowser){
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
    }
  }

  public features:any[] = [];
  public title:any = "";
  public subtitle:any = "";
  public description:any = "";
  public imgsrc:any = "";
  public hidden:string = "hidden";
  public logo="birth-right-logo";
  public bread_crumb_links:any = [{"label":"Home", "link": "/", "class": ""},
                                 {"label":"Women Care", "link": "/women-care-about", "class": ""},
                                 {"label":"Our Centres", "link": "", "class": ""}];

  public links:any = [{"label":"About Women's Care", "link": "/women-care-about", "class": ""},
                      {"label":"Doctors", "link": "/women-care-doctors", "class": ""},
                      {"label":"Our Specialities", "link": "/women-care-specialities", "class": ""},
                      {"label":"Our Centres", "link": "/women-care-centers", "class": "active"},
                      {"label":"Testimonials", "link": "/women-care-testimonials", "class": ""},
                      {"label":"Share Feedback", "link": "/women-care-feedback", "class": ""},
                      {"label":"Book An Appointment", "link": "/women-care-appointment", "class": ""}]


  ngOnInit() {
    this.api.getPageDescription('women-care-centers')
      .subscribe(
        data =>{
          this.title = data.page_descriptions[0].title,
          this.subtitle = data.page_descriptions[0].subtitle,
          this.description = data.page_descriptions[0].description,
          this.imgsrc = data.page_descriptions[0].image
        },
        error => {
          console.log(error);
        }
      )

      this.api.getSpecialityFeatures('women-care-centers')
        .subscribe(
          data =>{
            this.features = data.our_speciality_features
          },
          error => {
            console.log(error);
          }
        )

  }

  ngAfterViewInit() {

    if ( this.api.isBrowser && window['jQuery'] ) {
      initHome();
      initAllPage();
    }
  }

  ngOnDestroy() {}

}
