import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  ViewChildren,
  QueryList,
  HostListener,
} from '@angular/core';
import { isPlatformBrowser, DatePipe } from '@angular/common';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL } from '../services/constants';
import { dateFormatPipe } from '../home/dateFormatPipe';
import { Meta, Title } from '@angular/platform-browser';
import { json } from 'body-parser';
import { Observable } from 'rxjs';

declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
// declare let initAboutTabs:any;

@Component({
  selector: 'bmw',
  templateUrl: './bmw.component.html',
})
export class BmwComponent implements OnDestroy, OnInit, AfterViewInit {
  constructor(
    private api: ApiService,
    public metaTitle: Title,
    public meta: Meta,
    public datepipe: DatePipe
  ) {
    if (this.api.isBrowser) {
      // some of these will be required in near future
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/datepickr.min.js');
      require('../../assets/js/slick.min.js');
    }
  }

  public events: any[] = [];
  public csrd: any;
  public bwmCenters: any;
  public bwmCenterID: any;
  public bwmYears: any;
  public bwmYear: any;
  public bwmYearformate: any;
  public bwmData: any;
  public title: any = '';
  public subtitle: any = '';
  public description: any = '';
  public imgsrc: any = '';
  public brief_facilities_description: any = '';
  public brief_facilities_image: any = '';
  public imgBasePath = '';
  public hidden = 'hidden';
  public logo = 'logo';
  public bread_crumb_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'About Us', link: '/about-us', class: '' },
  ];

  public links: any = [];
  public activePage: string = 'bwm';
  public selectMonth: any = '';
  public pbtitle: any = '';
  // @ViewChildren('abouttabs') things: QueryList<any>;

  ngOnInit() {
    this.api
      .getAboutSubFooterDetails()
      .map((res) => res)
      .subscribe((data) => {
        this.links = data;
      });
    this.api.getPageDescription('bio-medical-waste').subscribe(
      (data) => {
        (this.title = data.page_descriptions[0].title),
          (this.subtitle = data.page_descriptions[0].subtitle),
          (this.description = data.page_descriptions[0].description),
          (this.imgsrc = data.page_descriptions[0].image),
          (this.imgBasePath = IMG_BASE_URL);
        if (data) {
          //console.log(data);
          this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
          this.meta.updateTag({
            name: 'keywords',
            content: data.page_descriptions[0].meta_keyword,
          });
          this.meta.updateTag({
            name: 'description',
            content: data.page_descriptions[0].meta_description,
          });
          this.meta.addTags([
            {
              property: 'og:title',
              content: data.page_descriptions[0].meta_title,
            },
            {
              property: 'og:description',
              content: data.page_descriptions[0].meta_description,
            },
            {
              property: 'og:keywords',
              content: data.page_descriptions[0].meta_keyword,
            },
          ]);
        }
      },
      (error) => {
        console.log(error);
      }
    );

    this.api.getBWMYears().subscribe(
      (res) => {
        //console.log('getBWMYears:', res);
        this.bwmYears = res;
        this.bwmYear = res[0].Year;
        //$('#SelectYear').text("Year - "+ this.bwmYear );

        this.api.getBWMCenters().subscribe(
          (res) => {
            //console.log('getBWMCenters:', res);
            this.bwmCenters = res;
            this.getAllBWMData(this.bwmCenters);
          },
          (error) => {
            console.log(error);
          }
        );
      },
      (error) => {
        console.log(error);
      }
    );

    this.api.getBWMAllData().subscribe((res) => {
      console.log('getBWMAllData:', res);
      let GropData = [];
      this.bwmCenters.forEach((element) => {
        if (element.id == res.unitid)
          res.forEach((element) => {
            GropData.push(element);
          });
      });
      console.log('GropData:', GropData);
    });

    this.myFunction();
  }
  getAllBWMData(Centers) {
    this.api.getBWMAllData().subscribe((res) => {
      //console.log('getBWMAllData:', res, Centers);
      //console.log('getBWMAllData Centers:', Centers);
      //console.log('getBWMAllData bwmYear:', this.bwmYears);
      this.bwmData = [];
      let NewArrays = [];

      let NewYearArrays = [];
      let latest_date = this.datepipe.transform(this.bwmYearformate, 'yyyy');
      this.bwmYears.forEach((element) => {
        let yearbydata = [];
        res.forEach((element1) => {
          if (
            element.Year == this.datepipe.transform(element1.bmw_date, 'yyyy')
          ) {
            yearbydata.push(element1);
          }
        });
        const obj = {
          Year: element.Year,
          data: yearbydata,
        };
        NewYearArrays.push(obj);
      });
      //console.log('NewYearArrays:', NewYearArrays);

      NewYearArrays.forEach((element) => {
        let newcenters = [];
        element.data.forEach((element1) => {
          if (!newcenters.includes(element1.unitName)) {
            newcenters.push(element1.unitName);
          }
        });
        let newcenterData = [];
        newcenters.forEach((center) => {
          let centerData = [];
          element.data.forEach((cData) => {
            if (center == cData.unitName) {
              centerData.push(cData);
            }
          });
          const obj = {
            centerName: center,
            centerData: centerData,
          };
          newcenterData.push(obj);
          //console.log('newcenterData', newcenterData)
        });

        element['newCenterData'] = newcenterData;
      });
      //console.log('NewYearArray', NewYearArrays)
      this.bwmData = NewYearArrays;

      /*
      Centers.forEach(element => {
        let arrayGroup = []
        res.forEach(element1 => {
          if(element.id === element1.unitid){
            arrayGroup.push(element1)
          }
        });
        const obj = {
          'unitName': element.unitName,
          "id":element.id,
          "data":arrayGroup
        }
        NewArrays.push(obj);
      });
      
     this.bwmData = NewArrays;
     //console.log('NewArrays:', this.bwmData);
     */
      $('.loder').hide();
    });
  }
  onChangeHospitals(e) {
    this.bwmCenterID = e.target.value;
  }

  searchBWMData() {
    $('.loder').show();
    let bwmCenterID, bwmCenterYear;
    if ($('#Hospitals option:selected').val() != 0) {
      bwmCenterID = $('#Hospitals option:selected').val().replace(/\s/g, '');
    } else {
      bwmCenterID = $('#Hospitals option:selected').val();
    }

    if ($('#BMWYear option:selected').val() != 0) {
      bwmCenterYear = $('#BMWYear option:selected').val();
    } else {
      bwmCenterYear = $('#BMWYear option:selected').val();
    }
    console.log('searchBWMData', bwmCenterID, bwmCenterYear);
    //let bwmCenterYear  = this.bwmCenterYear;

    if (bwmCenterID == 0 && bwmCenterYear == 0) {
      $('.centerlist').show();
    } else if (bwmCenterID == 0) {
      $('.centerlist').hide();
      setTimeout(() => {
        $('.Year_' + bwmCenterYear).show();
      }, 100);
    } else if (bwmCenterYear == 0) {
      $('.centerlist').hide();
      setTimeout(() => {
        $('.' + bwmCenterID).show();
      }, 100);
    }

    if (bwmCenterID != 0 && bwmCenterYear != 0) {
      console.log(
        'bwmCenterID, bwmCenterYear != 0: ',
        bwmCenterID,
        bwmCenterYear
      );
      $('.centerlist').hide();
      setTimeout(() => {
        $('.' + bwmCenterID + '_' + bwmCenterYear).show();
      }, 100);
    } else if (bwmCenterID != 0) {
      console.log('bwmCenterID != 0: ', bwmCenterID, bwmCenterYear);
      $('.centerlist').hide();
      setTimeout(() => {
        $('.' + bwmCenterID).show();
      }, 100);
    } else if (bwmCenterYear != 0) {
      console.log('bwmCenterYear != 0: ', bwmCenterID, bwmCenterYear);
      $('.centerlist').hide();
      setTimeout(() => {
        $('.Year_' + bwmCenterYear).show();
      }, 100);
    }
    setTimeout(() => {
      $('.loder').hide();
    }, 100);
    /*
    let centers = [];
    this.bwmCenters.forEach(element => {
      if(element.id == this.bwmCenterID){      centers.push(element);  }
    });
    if(this.bwmCenterID == 0){
      this.getAllBWMData(this.bwmCenters);
    }else{
      this.getAllBWMData(centers);
    }
    */
    /*
    let HospitalId = $('#Hospitals option:selected').val();   
    if(HospitalId == 0){ 
        $(".BMWData").show();
    }else{ 
        if(HospitalId != ""){  
            $(".BMWData").hide();
            $('#hospital_'+this.bwmCenterID).show();
        }
    }
    */
  }

  cleanup(message) {
    return message.replace(/\s/g, '');
  }
  myFunction() {
    this.bwmYearformate = '2018-12-31 23:20:00';
    let latest_date = this.datepipe.transform(this.bwmYearformate, 'yyyy');
    console.log('latest_date:', latest_date);
  }

  @HostListener('window:load', [])
  onWindowLoad() {
    $('.datepickr').Zebra_DatePicker({
      format: 'm/Y',
      default_position: 'below',
      offset: [-200, 26],
      onSelect: function (date) {
        this.selectMonth = date;
        $('#selectMonthYear').val(date);
      },
    });
  }

  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
    }
  }

  ngOnDestroy() {}
}
