import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  ViewChildren,
  QueryList,
  HostListener,
} from '@angular/core';
import { isPlatformBrowser, CommonModule } from '@angular/common';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL } from '../services/constants';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';

import { Meta, Title } from '@angular/platform-browser';
declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
// declare let initAboutTabs:any;

@Component({
  selector: 'history',
  templateUrl: './history.component.html',
  styleUrls: ['../../assets/css/animate.min.css', './history.component.css'],
})
export class HistoryComponent implements OnDestroy, OnInit, AfterViewInit {
  constructor(
    private api: ApiService,
    public metaTitle: Title,
    public meta: Meta
  ) {
    if (this.api.isBrowser) {
      // some of these will be required in near future
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/jquery.min.js');
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/htimeline.js');
      require('../../assets/js/custom.min.js');
      require('../../assets/js/fancybox/jquery.fancybox.min.js');
      require('../../assets/js/slick.min.js');
    }
  }

  public historyList: any = [];
  public title: any = '';
  public subtitle: any = '';
  public description: any = '';
  public imgsrc: any = '';
  public imgBasePath = '';
  public hidden = 'hidden';
  public logo = 'logo';
  public bread_crumb_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'About Us', link: '/about-us', class: '' },
    { label: 'History', link: '', class: '' },
  ];

  public links: any = [];
  public activePage: string = 'history';

  @ViewChildren('history') things: QueryList<any>;

  ngOnInit() {
    this.api
      .getAboutSubFooterDetails()
      .map((res) => res)
      .subscribe((data) => {
        this.links = data;
      });
    this.api.getPageDescription('history').subscribe(
      (data) => {
        (this.title = data.page_descriptions[0].title),
          (this.subtitle = data.page_descriptions[0].subtitle),
          (this.description = data.page_descriptions[0].description),
          (this.imgsrc = data.page_descriptions[0].image),
          (this.imgBasePath = IMG_BASE_URL);
        if (data) {
          //console.log(data);
          this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
          this.meta.updateTag({
            name: 'keywords',
            content: data.page_descriptions[0].meta_keyword,
          });
          this.meta.updateTag({
            name: 'description',
            content: data.page_descriptions[0].meta_description,
          });
          this.meta.addTags([
            {
              property: 'og:title',
              content: data.page_descriptions[0].meta_title,
            },
            {
              property: 'og:description',
              content: data.page_descriptions[0].meta_description,
            },
            {
              property: 'og:keywords',
              content: data.page_descriptions[0].meta_keyword,
            },
          ]);
        }
      },
      (error) => {
        console.log(error);
      }
    );

    this.api.getHistoryDetails('history').subscribe(
      (data) => {
        this.historyList = data;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
      setTimeout(function () {
        $('.newsblock').each(function(key, data){
          if (key % 2 == 0) {
            $(this).addClass('frst-odd-item');
          } else {
            $(this).addClass('frst-even-item');
          }
        });
      }, 1000);
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    $('.htimeline-content').each(function(index){
      var docViewTop = $(window).scrollTop();
      var docViewBottom = docViewTop + $(window).height() - 100;
      var itself = $(this).offset().top;
      if (itself > docViewTop && itself < docViewBottom) {
        $(this).addClass('animated');
        $(this).addClass('slideInUp');
      }
    });
    // var parents = document.getElementsByClassName("newsblock");

    // for (var i = 0, ii = parents.length; i < ii; i++) {
    // 	var parent = parents[i],
    // 		children = parent.children;

    // 	for (var j = 0, jj = children.length; j < jj; j++) {
    // 		var elem = children[j];
    // 		if (j % 2 === 0) {
    // 			elem.classList.add("even");
    // 		}else{
    // 			elem.classList.add("odd");
    // 		}
    // 	}
    // }

    $('.fancybox').fancybox({
      type: 'inline',
      maxWidth: 400,
      maxHeight: 500,
      fitToView: false,
      width: '70%',
      height: '70%',
      autoSize: false,
      closeClick: false,
      openEffect: 'none',
      closeEffect: 'none',
    });
  }

  ngOnDestroy() {}
}
