import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  ElementRef,
  ViewChildren,
  QueryList,
  HostListener,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL } from '../services/constants';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';

declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
//eclare let initOurCenter: any;
@Component({
  selector: 'out-center',
  templateUrl: './our-center.component.html',
  styles: [
    `
      ul li a {
        text-transform: capitalize;
      }
      .ourcenterlist .address-img-wapper img {
        max-height: 425px;
      }
      .slick-vertical .slick-slide {
        min-height: 450px;
      }
    `,
  ],
})
export class OurCenterComponent implements OnDestroy, OnInit, AfterViewInit {
  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    public metaTitle: Title,
    public meta: Meta
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
    }
  }
  public features: any[] = [];
  public title: any = '';
  public subtitle: any = '';
  public description: any = '';
  public imgsrc: any = '';
  public hidden: string = 'hidden';
  public logo = 'logo';

  public currentSection: string;
  public activeRoute: any[];
  public activePage: string;
  public centerCity: any[] = [];
  public centerList: any[] = [];
  public centerCopyList: any[] = [];
  public selectedLocation: any = '0';
  public searchBtn = 'search';
  public imgBasePath: string;

  public links: any = [];
  public bread_crumb_links: any = [];
  public activeSubPage: any;
  public bread_crumb_child_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Child Care', link: '/child-care/about', class: '' },
    { label: 'Our Centres / Specialities', link: '', class: '' },
  ];

  public bread_crumb_women_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Women Health', link: '/women-care/about', class: '' },
    { label: 'Our Centres / Specialities', link: '', class: '' },
  ];

  public bread_crumb_fertility_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Fertility Care', link: '/fertility-care/about', class: '' },
    { label: 'Our Centres / Specialities', link: '', class: '' },
  ];

  public child_care_links: any = [
    {
      label: 'About Child Care',
      link: '/child-care/about',
      class: '',
      slug: 'about',
    },
    {
      label: 'Child Safety',
      link: '/child-care/child-safety',
      class: '',
      slug: 'child-safety',
    },
    {
      label: 'Doctors',
      link: '/child-care/doctors',
      class: '',
      slug: 'doctors',
    },
    {
      label: 'Our Specialities',
      link: '/child-care/specialities',
      class: '',
      slug: 'specialities',
    },
    {
      label: 'Our Centres / Specialities',
      link: '/child-care/centres',
      class: 'active',
      slug: 'child-care-centres',
    },
    {
      label: 'Vaccination',
      link: '/child-care/vaccination',
      class: '',
      slug: 'vaccination',
    },
    {
      label: 'Testimonials',
      link: '/child-care/testimonials',
      class: '',
      slug: 'testimonials',
    },
    {
      label: 'Share Feedback',
      link: '/child-care/feedback',
      class: '',
      slug: 'feedback',
    },
    {
      label: 'Book An Appointment',
      link: '/child-care/book-an-appointment',
      class: '',
      slug: 'child-care',
    },
  ];

  public women_care_links: any = [
    {
      label: "About Women's Care",
      link: '/women-care/about',
      class: '',
      slug: 'about',
    },
    {
      label: 'Doctors',
      link: '/women-care/doctors',
      class: '',
      slug: 'doctors',
    },
    {
      label: 'Our Specialities',
      link: '/women-care/specialities',
      class: '',
      slug: 'specialities',
    },
    {
      label: 'Our Centres / Specialities',
      link: '/women-care/centres',
      class: 'active',
      slug: 'women-care-centres',
    },
    {
      label: 'Testimonials',
      link: '/women-care/testimonials',
      class: '',
      slug: 'testimonials',
    },
    {
      label: 'Share Feedback',
      link: '/women-care/feedback',
      class: '',
      slug: 'feedback',
    },
    {
      label: 'Book An Appointment',
      link: '/women-care/book-an-appointment',
      class: '',
      slug: 'women-care',
    },
  ];

  public fertility_care_links: any = [
    {
      label: 'About Fertility Care',
      link: '/fertility-care/about',
      class: '',
      slug: 'about',
    },
    {
      label: 'Doctors',
      link: '/fertility-care/doctors',
      class: '',
      slug: 'doctors',
    },
    {
      label: 'Our Treatments',
      link: '/fertility-care/treatments',
      class: 'active',
      slug: 'treatments',
    },
    {
      label: 'Our Centres / Specialities',
      link: '/fertility-care/centres',
      class: 'active',
      slug: 'fertility-care-centres',
    },
    {
      label: 'Testimonials',
      link: '/fertility-care/testimonials',
      class: '',
      slug: 'testimonials',
    },
    {
      label: 'Share Feedback',
      link: '/fertility-care/feedback',
      class: '',
      slug: 'feedback',
    },
    {
      label: 'Book An Appointment',
      link: '/fertility-care/book-an-appointment',
      class: '',
      slug: 'fertility-care',
    },
  ];

  activeBreadCrum(currentSection) {
    switch (currentSection) {
      case 'child-care':
        this.bread_crumb_links = this.bread_crumb_child_links;
        this.links = this.child_care_links;
        this.logo = 'rainbow-logo';
        this.activeSubPage = 'child-care-centres';
        break;
      case 'women-care':
        this.bread_crumb_links = this.bread_crumb_women_links;
        this.links = this.women_care_links;
        this.logo = 'birth-right-logo';
        this.activeSubPage = 'women-care-centres';
        break;
      case 'fertility-care':
        this.bread_crumb_links = this.bread_crumb_fertility_links;
        this.links = this.fertility_care_links;
        this.logo = 'birth-right-logo';
        this.activeSubPage = 'fertility-care-centres';
        break;
      default:
        this.logo = 'logo';
    }
  }

  @ViewChildren('centerSlider') things: QueryList<any>;

  ngOnInit() {
    this.api.getPageDescription('about-center').subscribe(
      (data) => {
        this.title = data.page_descriptions[0].title;
        this.subtitle = data.page_descriptions[0].subtitle;
        this.description = data.page_descriptions[0].description;
        this.imgsrc = data.page_descriptions[0].image;
        this.imgBasePath = IMG_BASE_URL;
        if (data) {
          //console.log(data);
          this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
          this.meta.updateTag({
            name: 'keywords',
            content: data.page_descriptions[0].meta_keyword,
          });
          this.meta.updateTag({
            name: 'description',
            content: data.page_descriptions[0].meta_description,
          });
          this.meta.addTags([
            {
              property: 'og:title',
              content: data.page_descriptions[0].meta_title,
            },
            {
              property: 'og:description',
              content: data.page_descriptions[0].meta_description,
            },
            {
              property: 'og:keywords',
              content: data.page_descriptions[0].meta_keyword,
            },
          ]);
        }
      },
      (error) => {
        console.log(error);
      }
    );
    this.route.params.subscribe((params) => {
      this.currentSection = params['section'];
    });

    this.activeBreadCrum(this.currentSection);
    this.api
      .getSpecialityMasters()
      .map((res) => res)
      .subscribe((data) => {
        //console.log(data);
        this.activeRoute = data.speciality_masters.filter((filterdata) => {
          if (this.currentSection == 'women-care') {
            return filterdata.slug == 'women-care';
          } else {
            return filterdata.slug == this.currentSection;
          }
        });
        if (this.activeRoute.length > 0) {
          //console.log('array');
          this.activePage = this.activeRoute[0].speciality;
        } else {
          this.activePage = this.currentSection;
        }
      });
    this.api.getSpecialityFeatures('child-care-centers').subscribe(
      (data) => {
        this.features = data.our_speciality_features;
      },
      (error) => {
        console.log(error);
      }
    );

    this.api
      .getCenters()
      //.map(res=>{res.json()})
      .subscribe(
        (res) => {
          //console.log(res.centers);
          res.centers.map((data, key) => {
            const cityName = data.center_name.split(',');
            let city = data.city ? data.city.city : cityName[1];
            this.centerCity.push(city);
            this.centerList.push({
              id: data.id,
              center_name: data.center_name,
              address: data.address,
              telephone: data.telephone,
              lat_long: data.lat_long,
              image: data.image,
              city: city,
              slug: data.slug,
              virtual_tour_link: data.virtual_tour_link,
            });
            this.centerCopyList.push({
              id: data.id,
              center_name: data.center_name,
              address: data.address,
              telephone: data.telephone,
              lat_long: data.lat_long,
              image: data.image,
              city: city,
              slug: data.slug,
              virtual_tour_link: data.virtual_tour_link,
            });
          });
          this.centerCity = this.centerCity.reduce(function (a, b) {
            if (a.indexOf(b) < 0) a.push(b);
            return a;
          }, []);
        },
        (err) => {
          console.log(err);
        }
      );
  }
  submitLocation(f: NgForm) {
    //alert('clicked');
    this.centerList = [];

    if (f.value['selectedLocation'] != 0) {
      let filteredList = this.centerCopyList.filter((res) => {
        return res.city == f.value['selectedLocation'];
      });
      this.centerList = filteredList;
      ///console.log(this.centerList);
    } else {
      this.api.getCenters().subscribe(
        (res) => {
          res.centers.map((data, key) => {
            const cityName = data.center_name.split(',');
            let city = data.city ? data.city.city : cityName[1];
            //this.centerCity.push(city);
            this.centerList.push({
              id: data.id,
              center_name: data.center_name,
              address: data.address,
              telephone: data.telephone,
              lat_long: data.lat_long,
              image: data.image,
              city: city,
              slug: data.slug,
              virtual_tour_link: data.virtual_tour_link,
            });
          });
        },
        (err) => {
          console.log(err);
        }
      );
    }
    //this.jqueryLoad();
    //initOurCenter();
    //console.log(this.centerList);
    ///this.centerCity =  this.centerCopyList;
  }
  jqueryLoad() {
    var $body = $('body');
    var $ourcenterlist = $body.find('.ourcenterlist');

    $ourcenterlist.removeClass('slick-initialized').removeClass('slick-slider');
    $ourcenterlist.not('.slick-initialized').slick({
      dots: false,
      arrows: true,
      infinite: false,
      autoplay: false,
      prevArrow: '#slickPrev',
      nextArrow: '#slickNext',
      //prevArrow:'<a class="slick-prev"><img src="assets/images/btn-arrowtop.png" alt="" /></a>',
      //nextArrow:'<a class="slick-next"><img src="assets/images/btn-arrowbottom.png" alt="" /></a>',
      //speed: 2000,
      //autoplaySpeed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      vertical: true,
      verticalSwiping: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: $('.ourcenterlist .sitem').length,
            slidesToScroll: 1,
            arrows: false,
          },
        },
      ],
    });
    //console.log('changes....');
    var current = $ourcenterlist
      .parent()
      .find('#slickNext')
      .text($ourcenterlist.find('.slick-current').next().attr('data-cityname'));
    var prev = $ourcenterlist
      .parent()
      .find('#slickPrev')
      .text($ourcenterlist.find('.slick-current').prev().attr('data-cityname'));
    // console.log(prev);
    $ourcenterlist
      .parent()
      .find('#slickNext')
      .on('click', (e) => {
        $(this).text('');
        if ($ourcenterlist.find('.slick-current').next() != null) {
          $(this).text(
            $ourcenterlist.find('.slick-current').next().attr('data-cityname')
          );
        }
        if ($ourcenterlist.find('.slick-current').prev() != null) {
          $ourcenterlist
            .parent()
            .find('#slickPrev')
            .text(
              $ourcenterlist.find('.slick-current').prev().attr('data-cityname')
            );
        }
      });
    $ourcenterlist
      .parent()
      .find('#slickPrev')
      .on('click', (e) => {
        $(this).text('');
        if ($ourcenterlist.find('.slick-current').next() != null) {
          $ourcenterlist
            .parent()
            .find('#slickNext')
            .text(
              $ourcenterlist.find('.slick-current').next().attr('data-cityname')
            );
        }
        if ($ourcenterlist.find('.slick-current').prev() != null) {
          $(this).text(
            $ourcenterlist.find('.slick-current').prev().attr('data-cityname')
          );
        }
      });
  }
  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
    }
    this.things.changes.subscribe((t) => {
      this.jqueryLoad();
    });
  }
  @HostListener('window:load', [])
  onWindowLoad() {
    this.jqueryLoad();
  }
  ngOnDestroy() {}
}
