<!-- <header class="navbar navbar-static-top">
	<h2 class="bd-title" id="my-app-title">Angular Starter App</h2>
  <div ngbDropdown id="my-nav-menu">
		<button class="btn btn-outline-primary" id="dropdownMenu3" ngbDropdownToggle>Navigation Menu</button>
		<div class="dropdown-menu" aria-labelledby="dropdownMenu3">
			<button *ngFor="let view of views" [routerLink]="view.link" class="dropdown-item">{{view.name}}</button>
		</div>
	</div>
</header> -->
<router-outlet (activate)="activateEvent($event)" (deactivate)="deactivateEvent($event)"></router-outlet>
<div id="ymPluginDivContainerInitial" style="display: block">
	<div
	  id="ymDivBar"
	  class="left"
	  style="
		background-color: rgb(98, 2, 238);
		display: flex;
		align-items: center;
		cursor: pointer;
		color: rgb(240, 240, 240);
		font-size: 14px;
	  "
	>
	  <div class="ym-icon-wrapper">
		<img
		  class="ym-icon"
		  src="https://cdn.yellowmessenger.com/x176qFEqF5h71640160261497.png"
		/>
		<div class="ym-online-box"></div>
	  </div>
	  <div class="ym-title-parent">
		<div class="ym-title">
		  Rene
		  <div class="ym-online-dot" aria-hidden="true"></div>
		</div>
		<div
		  class="ym-sub-title"
		  style="color: rgb(240, 240, 240); font-size: 14px"
		>
		  Your virtual assistant
		</div>
	  </div>
	</div>
	<div id="ymDivCircle" class="hide left compact"></div>
	<div id="ym-notification" class="hide ym-hidden left"></div>
	<div id="ymFrameHolder" class="left compact" style="display: none">
	  <div class="ym-loading-spinner" style="display: none">
		<div class="ym-double-bounce1"></div>
		<div class="ym-double-bounce2"></div>
	  </div>
	  <iframe
		scrolling="yes"
		allowtransparency="true"
		frameborder="0"
		allowfullscreen=""
		name="ymIframe"
		id="ymIframe"
		style="height: calc(100vh - 120px)"
	  ></iframe>
	</div>
	<div id="ymFramebranding" style="display: none">
	  <img
		src="https://cdn.yellowmessenger.com/NyMG8Q7jfPGq1624329985373.svg"
	  />
	</div>
  </div>
<!-- <my-store-devtools *ngIf="showMonitor"></my-store-devtools> -->
