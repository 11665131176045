<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>


    <!-- Start App Middle Contents Add after bread-crumb -->
    <section class="page wtwedo">
        <div class="row">
            <div class="col-md-5 col-sm-5 col-lg-4 col-xs-5">
                <div class="circuleframe">
                    <img src="assets/images/appImg.png" alt="{{pageData.title}}" title="{{pageData.title}}" /> </div>
                <!-- End Circle -->
            </div>
            <div class="col-md-7 col-sm-7 col-lg-7 col-xs-7">
                <div class="pagetitle">
                    <h1 class="textgradient">{{pageData.title}}</h1>
                    <div class="underline">
                        <img src="assets/images/underline.png" alt="" /> </div>
                    <h3>{{pageData.subtitle}}</h3>
                </div>
            </div>
            <div class="col-md-7 col-xs-7 col-lg-8 mcontentview app">
                <p [innerHTML]="pageData.description"></p>
            </div>
        </div>
    </section>
    <div class="appSection1">
        <div class="container">
            <div class="col-xs-12 col-sm-6 col-lg-6">
                <div class="appCBWrp">
                    <div class="thumbBann">
                        <img src="assets/images/bann-app1.png" alt="" />
                    </div>
                    <div class="appTitle">
                        <h2>Rainbow Children's
                            <br>
                            <span>Hospital App</span>
                        </h2>
                    </div>
                    <div class="appText">
                        <p>This is an appointment booking platform. Patients/Parents can book appointments & view doctors profiles. Parents can see vaccination work flow for their children...</p>
                        <p>
                            <a href="javascript:void()" class="btnReadM appLink" data-id="HospitalApp">Read More</a>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-lg-6">
                <div class="appCBWrp">
                    <div class="thumbBann">
                        <img src="assets/images/bann-app2.png" alt="" />
                    </div>
                    <div class="appTitle">
                        <h2>BirthRight
                            <span>App</span>
                        </h2>
                    </div>
                    <div class="appText">
                        <p>BirthRight is a space dedicated to simplifying motherhood in every possible way. This app by Rainbow Hospitals understands that there is more to motherhood than just the 9 months of pregnancy...</p>
                        <p>
                            <a href="javascript:void()" class="btnReadM appLink" data-id="BirthRightApp">Read More</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="appSection2" id="HospitalApp">
        <div class="container">
            <div class="col-xs-12 col-sm-5 appBannWrp">
                <img src="assets/images/bann-app1.png" class="img-responsive inline-block" /> </div>
            <div class="col-xs-12 col-sm-7">
                <div class="appCBWrp">
                    <div class="appTitle">
                        <h2>Rainbow Children’s
                            <br>
                            <span>Hospital App</span>
                        </h2>
                    </div>
                    <div class="appText">
                        <p>This is an appointment booking platform. Patients/Parents can book appointments & view doctors profiles. Parents can see vaccination work flow for their children & can also check the Child’s BMI. The app also has details of emergency
                            services for all the Rainbow Locations. It also has information on Children’s Safety program & women’s health. We have Ask a Question feature which can be used to clear any doubts that the parents/patients might have about
                            the child’s health or any other issue related to the hospital management.</p>
                    </div>
                    <div class="mAppWrp">
                        <ul>
                            <li>
                                <a href="https://play.google.com/store/apps/details?id=com.sparctechnologies.rainbow" target="_blank">
                                    <img src="assets/images/app-googlePlay.png" alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="https://itunes.apple.com/nz/app/rainbow-childrens-hospital/id1132607946?mt=8" target="_blank">
                                    <img src="assets/images/app-store.png" alt="" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="section appFeatures">
        <div class="container">
            <div class="row">
                <div class="col-xs-12 col-sm-8 links">
                    <h3>Features</h3>
                    <div class="row2">
                        <div class="col-xs-12 col-sm-6">
                            <ul>
                                <li>Doctor's Profile Page</li>
                                <li>Search validations</li>
                                <li>Corrections Vaccination Workflow</li>
                            </ul>
                        </div>
                        <div class="col-xs-12 col-sm-6">
                            <ul>
                                <li>Email Registration and Authentication</li>
                                <li>Add Child</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-4 links">
                    <h3>What's New</h3>
                    <ul>
                        <li>Family members can be added with phone number as password</li>
                        <li>Book appointment for family member</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="appSection2" id="BirthRightApp">
        <div class="container">
            <div class="col-xs-12 col-sm-7">
                <div class="appCBWrp">
                    <div class="appTitle">
                        <h2>BirthRight
                            <span>App</span>
                        </h2>
                    </div>
                    <div class="appText">
                        <p>BirthRight is a space dedicated to simplifying motherhood in every possible way. This app by Rainbow Hospitals understands that there is more to motherhood than just the 9 months of pregnancy. That is why, this app supports you
                            through the first 1000 days of pregnancy, right from planning for a baby to introducing your new born to the world. </p>
                        <p>Get customized support for every phase of pregnancy:
                            <br>- Pre-pregnancy
                            <br>- During Pregnancy
                            <br>- Post-delivery
                            <br>- Raising your child
                            <br>- Planning for your second baby
                        </p>
                        <p>At each phase of your journey as a parent, you can trust BirthRight to guide you through.</p>
                    </div>
                    <div class="mAppWrp">
                        <ul>
                            <li>
                                <a href="https://play.google.com/store/apps/details?id=com.birthrightapp&hl=en" target="_blank">
                                    <img src="assets/images/app-googlePlay.png" alt="" />
                                </a>
                            </li>
                            <li>
                                <a href="https://itunes.apple.com/in/app/birthright/id1288452755?mt=8" target="_blank">
                                    <img src="assets/images/app-store.png" alt="" />
                                </a>
                            </li>
                            <!--<li><a href=""><img src="assets/images/app-store.png" alt="" /></a></li>-->
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-5 appBannWrp">
                <img src="assets/images/bann-app2.png" class="img-responsive inline-block" /> </div>
        </div>
    </div>
    <div class="section appFeatures">
        <div class="container">
            <div class="row">
                <div class="col-xs-12 col-sm-12 links">
                    <h3>Features</h3>
                    <div class="row2">
                        <div class="col-xs-12 col-sm-6">
                            <ul>
                                <li>Make use of helpful pre-pregnancy tools like fertility tracker and BMI calculator</li>
                                <li>Use the Kick counter and Contraction timer to ensure a smooth sailing pregnancy</li>
                                <li>Keep track of your baby’s development inside the womb through regular updates</li>
                                <li>Choose your Rainbow hospital and consultant based on your location</li>
                                <li>Book appointments from within the app</li>
                            </ul>
                        </div>
                        <div class="col-xs-12 col-sm-6">
                            <ul>
                                <li>Stay informed through medically accurate articles covering everything from fertility to diet plans and health tips throughout your journey into motherhood and beyond</li>
                                <li>Access information and appointments for specific fertility treatments</li>
                                <li>Keep track of your doctor’s appointments and medical records</li>
                                <li>Maintain a digital calendar for your new born including a Vaccination Calendar and Medical Records</li>
                                <li>Learn how to take care of your baby through the early development stages</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End App Middle Contents before footer-nav-mini -->
    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>

    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>