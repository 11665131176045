import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL } from '../services/constants';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/timer';
import 'rxjs/add/observable/merge';
import 'rxjs/add/operator/takeWhile';
import 'rxjs/add/operator/mapTo';

declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
declare let initPopup: any;
declare let initCustomSelect: any;

@Component({
  selector: 'our-doctors',
  templateUrl: './our-doctor.component.html',
  styles: [
    `
      ul li a {
        text-transform: capitalize;
      }
      a {
        cursor: pointer;
      }
      .disabled {
        pointer-events: none;
        cursor: default;
      }
      ul.popupmsg li {
        padding: 0;
        margin: 10px 0;
        font-size: 14px;
      }
      ul {
        padding-left: 5px;
        list-style: none;
      }
      ul.inactive {
        display: none;
      }
      ul.active {
        display: block !important;
      }
      .searchDD {
        position: relative;
      }
      .searchDD ul {
        width: 134%;
        display: none;
        background: #3d2979;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        left: -10px;
        margin: 0;
        padding: 0;
        padding-bottom: 10px;
        position: absolute;
        top: 46px;
        z-index: 2;
        overflow-x: hidden;
        overflow: auto;
      }
      .searchDD ul.active.maxhight {
        max-height: 170px;
      }
      .searchDD ul li {
        margin: 0px;
        float: left;
        list-style: none;
        width: 100%;
        background: #3d2979;
        border-radius: 0px;
        padding: 6px 10px;
        position: relative;
        color: #fff;
      }
      .searchDD ul li:hover {
        background: rgba(255, 255, 255, 0.1);
      }
      .searchDD ul li a {
        color: #fff;
        font-family: 'dinrg';
        font-size: 15px;
        text-decoration: none;
      }

      .bookAppPgBgAnim {
        background-color: #3d2979;
        margin: 0;
        padding: 8px 20px;
        display: inline-block;
        font-weight: normal;
        color: #fff;
        -webkit-animation-name: bganimate;
        -webkit-animation-duration: 1s;
        animation-iteration-count: infinite;
        border-radius: 2px;
        text-align: center;
        font-size: 16px;
        line-height: 20px;
      }
      @-webkit-keyframes bganimate {
        50% {
          background-color: #773996;
        }
      }
      @keyframes bganimate {
        50% {
          background-color: rgb(236, 28, 36, 0.8);
        }
      }

      @media (max-width: 1280px) {
        .searchDD ul {
          width: 122%;
        }
      }
      @media (max-width: 420px) {
        .searchDD ul {
          width: 136%;
        }
        .bookAppPgBgAnim {
          font-size: 14px;
          line-height: 18px;
          padding: 6px 10px;
        }
      }
    `,
  ],
})
export class OurDoctorComponent implements OnDestroy, OnInit, AfterViewInit {
  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    public metaTitle: Title,
    public meta: Meta
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
    }
  }

  public doctors: any = [];
  public filteredSpecDoctor: any[];
  public title: any = '';
  public info: any = '';
  public subtitle: any = '';
  public description: any = '';
  public imgsrc: any = '';
  public imgBasePath = '';
  public currentSection: any;
  public activeRoute: any;
  public activePage: any;
  public centerList: any[] = [];
  public specList: any[] = [];
  public specility: any;
  public searchDoctor: any;
  public centrelocation: any = 0;
  public hidden = 'hidden';
  finddoctorclass: boolean = false;
  doctorSearchResult: Array<any>;
  public logo: string;
  p: number = 1;
  public links: any = [];
  public bread_crumb_links: any = [];
  public bread_crumb_child_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Child Care', link: '/child-care/about', class: '' },
    { label: 'Our Doctors', link: '', class: '' },
  ];

  public bread_crumb_women_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Women Care', link: '/women-care/about', class: '' },
    { label: 'Our Doctors', link: '', class: '' },
  ];

  public bread_crumb_fertility_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Fertility Care', link: '/fertility-care/about', class: '' },
    { label: 'Our Doctors', link: '', class: '' },
  ];

  public child_care_links: any = [
    {
      label: 'About Child Care',
      link: '/child-care-about',
      class: '',
      slug: 'about',
    },
    {
      label: 'Child Safety',
      link: '/child-care/child-safety',
      class: '',
      slug: 'child-safety',
    },
    {
      label: 'Doctors',
      link: '/child-care/doctors',
      class: '',
      slug: 'child-care-doctors',
    },
    {
      label: 'Our Specialities',
      link: '/child-care/specialities',
      class: '',
      slug: 'specialities',
    },
    {
      label: 'Our Centres',
      link: '/child-care/centres',
      class: '',
      slug: 'centres',
    },
    {
      label: 'Vaccination',
      link: '/child-care/vaccination',
      class: '',
      slug: 'vaccination',
    },
    {
      label: 'Testimonials',
      link: '/child-care/testimonials',
      class: '',
      slug: 'testimonials',
    },
    {
      label: 'Share Feedback',
      link: '/child-care/feedback',
      class: '',
      slug: 'feedback',
    },
    {
      label: 'Book An Appointment',
      link: '/child-care/book-an-appointment',
      class: 'active',
      slug: 'child-care',
    },
  ];

  public women_care_links: any = [
    {
      label: "About Women's Care",
      link: '/women-care/about',
      class: '',
      slug: 'about',
    },
    {
      label: 'Doctors',
      link: '/women-care/doctors',
      class: '',
      slug: 'women-care-doctors',
    },
    {
      label: 'Our Specialities',
      link: '/women-care/specialities',
      class: '',
      slug: 'specialities',
    },
    {
      label: 'Our Centres',
      link: '/women-care/centres',
      class: '',
      slug: 'centres',
    },
    {
      label: 'Testimonials',
      link: '/women-care/testimonials',
      class: '',
      slug: 'testimonials',
    },
    {
      label: 'Share Feedback',
      link: '/women-care/feedback',
      class: '',
      slug: 'feedback',
    },
    {
      label: 'Book An Appointment',
      link: '/women-care/book-an-appointment',
      class: 'active',
      slug: 'women-care',
    },
  ];

  public fertility_care_links: any = [
    {
      label: 'About Fertility Care',
      link: '/fertility-care/about',
      class: '',
      slug: 'about',
    },
    {
      label: 'Doctors',
      link: '/fertility-care/doctors',
      class: '',
      slug: 'fertility-care-doctors',
    },
    {
      label: 'Our Treatments',
      link: '/fertility-care/treatments',
      class: 'active',
      slug: 'treatments',
    },
    {
      label: 'Our Centres',
      link: '/fertility-care/centers',
      class: '',
      slug: 'centers',
    },
    {
      label: 'Testimonials',
      link: '/fertility-care/testimonials',
      class: '',
      slug: 'testimonials',
    },
    {
      label: 'Share Feedback',
      link: '/fertility-care/feedback',
      class: '',
      slug: 'feedback',
    },
    {
      label: 'Book An Appointment',
      link: '/fertility-care/book-an-appointment',
      class: 'active',
      slug: 'fertility-care',
    },
  ];

  public speciality_master: number;
  public bookingBtnStatus: number = 1;
  public centre_id: number = 0;
  public specialty: any = 0;
  public specilityMaster: string = '0';
  public activeSubPage: any;
  public Highlight: any;
  public Highlights: boolean = false;
  public specilityMasterList = [
    {
      id: 1,
      slug: 'child-care',
      name: 'Child Care',
    },
    {
      id: 2,
      slug: 'women-care',
      name: 'Women Health',
    },
    {
      id: 3,
      slug: 'fertility-care',
      name: 'Fertility Care',
    },
  ];
  welcomePopup: any = {
    title: 'Dear Visitor',
    msg: [
      {
        data: 'If you find the appointments for any of our doctors across all the centres completely booked, you can always call us at our Toll Free - 1800 2122 to check if any appointments are still available and also for any further assistance.',
      },
      {
        data: "The online appointment booking system is an effort to streamline Outpatient Consultations. Being a Children's Hospital, there may be waiting periods for about 30-60 minutes. Kindly bear with us. We appreciate your understanding.",
      },
      {
        data: 'You would receive the appointment confirmation by SMS once you book an appointment online. In case you do not receive a confirmation SMS please write to hosupport@rainbowhospitalsin and we will resolve it.',
      },
    ],
  };
  @ViewChildren('doctors') things: QueryList<any>;

  activeBreadCrum(currentSection) {
    switch (currentSection) {
      case 'child-care':
        this.bread_crumb_links = this.bread_crumb_child_links;
        this.links = this.child_care_links;
        this.logo = 'rainbow-logo';
        this.activeSubPage = 'child-care-doctors';
        break;
      case 'women-care':
        this.bread_crumb_links = this.bread_crumb_women_links;
        this.links = this.women_care_links;
        this.logo = 'birth-right-logo';
        this.activeSubPage = 'women-care-doctors';
        break;
      case 'fertility-care':
        this.bread_crumb_links = this.bread_crumb_fertility_links;
        this.links = this.fertility_care_links;
        this.logo = 'birth-right-logo';
        this.activeSubPage = 'fertility-care-doctors';
        break;
      default:
        this.logo = 'logo';
    }
  }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.currentSection = params['section'];
    });
    if (this.currentSection == 'all') {
      this.bookingBtnStatus = 0;
      this.api.getPageDescription(`child-care-doctors`).subscribe(
        (data) => {
          this.title = data.page_descriptions[0].title;
          this.subtitle = data.page_descriptions[0].subtitle;
          this.description = data.page_descriptions[0].description;
          this.info = data.page_descriptions[0].info;
          this.imgsrc = data.page_descriptions[0].image;
          this.imgBasePath = IMG_BASE_URL;
          this.Highlight = data.page_descriptions[0].highlight;
          if (this.Highlight != null) {
            this.Highlights = true;
          }
          if (data) {
            //console.log(data);
            this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
            this.meta.updateTag({
              name: 'keywords',
              content: data.page_descriptions[0].meta_keyword,
            });
            this.meta.updateTag({
              name: 'description',
              content: data.page_descriptions[0].meta_description,
            });
            this.meta.addTags([
              {
                property: 'og:title',
                content: data.page_descriptions[0].meta_title,
              },
              {
                property: 'og:description',
                content: data.page_descriptions[0].meta_description,
              },
              {
                property: 'og:keywords',
                content: data.page_descriptions[0].meta_keyword,
              },
            ]);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      this.api.getPageDescription(`${this.currentSection}-doctors`).subscribe(
        (data) => {
          (this.title = data.page_descriptions[0].title),
            (this.subtitle = data.page_descriptions[0].subtitle),
            (this.description = data.page_descriptions[0].description),
            (this.info = data.page_descriptions[0].info),
            (this.imgsrc = data.page_descriptions[0].image),
            (this.imgBasePath = IMG_BASE_URL);
          this.Highlight = data.page_descriptions[0].highlight;
          if (this.Highlight != null) {
            this.Highlights = true;
          }
          this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
          this.meta.addTags([
            {
              name: 'keywords',
              content: data.page_descriptions[0].meta_keyword,
            },
            {
              name: 'description',
              content: data.page_descriptions[0].meta_description,
            },
          ]);
        },
        (error) => {
          console.log(error);
        }
      );
    }
    this.activeBreadCrum(this.currentSection);
    this.getSpecialityMasters(this.currentSection);

    //this.blinkTest();
  }
  /*
	blinkTest(){
		setInterval(function () {
			console.log("1")
		}, 1000)
	}
	*/
  getSpecialityMasters(currentSection) {
    this.api
      .getSpecialityMasters()
      .map((res) => res)
      .subscribe((data) => {
        //console.log(currentSection);
        this.activeRoute = data.speciality_masters.filter((filterdata) => {
          if (currentSection == 'women-care') {
            return filterdata.slug == 'women-care';
          } else {
            return filterdata.slug == currentSection;
          }
        });
        //console.log(this.activeRoute);
        if (this.activeRoute.length > 0) {
          this.speciality_master = this.activeRoute[0].id;
          this.activePage = this.activeRoute[0].speciality;
        } else {
          this.speciality_master = 0;
          this.activePage = currentSection;
        }
        //console.log(this.activeRoute);
        if (this.activeRoute.length > 0) {
          this.getCenterList(this.activeRoute[0].id);
        } else {
          this.getAllCentresSpeciality();
        }

        // this.api.getDoctorListBySpecilityMaster(this.speciality_master,0,this.centre_id,0).subscribe(res=>{
        // 	this.filteredSpecDoctor = res;
        // 	//console.log(this.filteredSpecDoctor);
        // });
        //console.log(this.activeRoute);
        //console.log(this.activePage);
      });
  }
  getDoctorname(evt) {
    if (evt.target.value.length >= 2) {
      this.api.getDoctorSearchList(evt.target.value).subscribe((data) => {
        this.doctorSearchResult = data.doctors;
      });
      this.finddoctorclass = true;
    } else {
      this.finddoctorclass = false;
    }
  }
  selectDoctor(id) {
    this.api.getSearchedDoctorDetail(id).subscribe((res) => {
      //console.log(res);
      setTimeout(() => {
        var childResult = $('.childdoctor-result');
        if (childResult.length) {
          $('html, body')
            .delay(500)
            .animate({ scrollTop: childResult.offset().top - 80 }, 500);
        }
      }, 500);
      this.filteredSpecDoctor = res;
      this.searchDoctor = '';
      this.finddoctorclass = false;
    });
  }
  selectSpecMaster() {
    this.bookingBtnStatus = 1;
    this.centrelocation = 0;
    this.specialty = 0;
    this.specList = [];
    this.centerList = [];
    //console.log(this.specilityMaster);
    this.getSpecialityMasters(this.specilityMaster);
  }
  getAllCentresSpeciality() {
    return this.api.getCentreSpecification().subscribe((res) => {
      this.doctors = res;
      let centers = res.centers;
      this.api.getCentreCities().subscribe((res) => {
        this.centerList = res;
        //console.log(this.centerList);
        //console.log('list of centre and city',res);
      });
      // this.centerList = centers.map((data) => {
      // 	return { 'centerName': data.center_name,  'center_Name_alias': data.center_name_alias, 'centres_id': data.id };
      // });
    });
  }
  getCenterList(id) {
    this.api.getOurDoctorList(id).subscribe((res) => {
      this.doctors = res.speciality_master;
      let centers = res.speciality_master.centers;

      this.api.getCentreCities().subscribe((res) => {
        this.centerList = res;
        //console.log(this.centerList);
        //console.log('list of centre and city',res);
      });
      //console.log(this.centerList);
    });
  }
  searchDoctorSection() {
    document.getElementById('doctorfilter').scrollIntoView();
    //	initAllPage();
  }
  locationChange() {
    localStorage.setItem('Locationid', this.centrelocation);
    if (this.centrelocation != 0) {
      this.specialty = 0;
      let doctorSpec = this.doctors.centers;
      this.specList = [];
      let filterSpec = doctorSpec
        .map((r) => {
          return r.speciality_features;
        })
        .map((s) => {
          for (var index = 0; index < s.length; index++) {
            //console.log(s[index].centers_id);

            if (s[index].centers_id == this.centrelocation) {
              //console.log(s[index]);
              this.specList.push(s[index]);
            }
          }
        });
      //console.log(this.specList);
    } else {
      console.log('no data');
    }
  }
  submitfilter(f) {
    if (f.value) {
      //console.log(f.value)
      let center_id = this.centrelocation;
      let specialty_id = this.specialty ? this.specialty : 0;
      this.getDoctorListByFilter(
        this.speciality_master,
        specialty_id,
        center_id
      );
      setTimeout(() => {
        var childResult = $('.childdoctor-result');
        if (childResult.length) {
          $('html, body')
            .delay(500)
            .animate({ scrollTop: childResult.offset().top - 80 }, 500);
        }
      }, 500);

      //	initAllPage();
    }
  }
  getDoctorListByFilter(speciality_master, specialty_id, center_id) {
    return this.api
      .getDoctorListBySpecilityMaster(
        this.speciality_master,
        specialty_id,
        center_id,
        0
      )
      .subscribe(
        (res) => {
          //console.log(res);
          return (this.filteredSpecDoctor = res);
          //console.log(res);
        },
        (err) => {
          console.log(err);
        }
      );
  }
  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
    }

    this.things.changes.subscribe((t) => {
      // this.ngForRendred();
      initCustomSelect();
      initAllPage();
    });
    // this.showSubField = false;
    this.specilityMaster = '0';
    this.speciality_master = 0;
    this.centrelocation = 0;
    this.specialty = 0;
    // this.filteredSpecDoctor = [];
    // this.searchDoctor = [];
    // this.selectSpecMaster(null);
    this.locationChange();
    // this.subSpecialty();
  }

  ngOnDestroy() {}
}
