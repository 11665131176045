<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>
    <div id="topsearchwrp">
        <div class="inputbox">
            <input class="searchInput" type="text" name="txtSearch" id="txtSearch" placeholder="type here..." />
            <input type="submit" name="submit" class="ssubmitbtn" value="" />
        </div>
    </div>
    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>

    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <section class="page wtwedo">
        <div class="aboutcentre-banner container" *ngIf="PackageListing.length > 0">
            <div class="row" *ngFor="let item of PackageListing" >
                <!--<div class="col-xs-12 col-xs-6"><br><br><br>
                    <img src="{{imgBasePath}}{{item.image}}" class="img-responsive" alt="">
                </div>-->
                <div class="col-xs-12 col-xs-12">
                    <div class="sheading">
                        <img src="{{imgBasePath}}{{item.image}}" class="imgInline" alt="">
                        <h3>{{item.packageheading}}</h3>
                        <p [innerHTML]="item.packagelargedecription"></p>
                        <p><a href="our-center/banjara-hills" class="knowmoreS2"><span>Back</span></a></p>
                    </div>                    
                </div>
            </div>
        </div>

    </section>
    <section>
        <div class="centreServiceListWrp">
            <div class="row lightBg"><!---->
                <div class="container">
                    <div class="col-sx-12 col-lg-12">
                        <div id="cDetailFGetInTouch">
                            <div class="formWrp">
                                <div class="launchpreloader"></div>
                                <h2>GET IN TOUCH WITH US</h2>
                                <div class="thankYouMsg">
                                    <span>Thanks for enquiring. Our team will get back to you shortly.</span>
                                </div>
                                <form id="pageForm" [formGroup]="GetInTouchOnPage" (submit)="onSubmitDirectPost()">
                                    <ul class="formcasn">
                                        <li>
                                            <input type="text" name="name"
                                                [ngClass]="{'has-error':!GetInTouchOnPage.controls.name.valid && GetInTouchOnPage.controls.name.touched}"
                                                placeholder="Name*" formControlName="name" (keypress)="isletters($event)" required />
                                            <span class="alertMsg"
                                                *ngIf="!GetInTouchOnPage.controls.name.valid && submited">Please enter
                                                Name*</span>
                                        </li>
                                        <li>
                                            <input type="text" name="mobile"
                                                [ngClass]="{'has-error':!GetInTouchOnPage.controls.mobile.valid && GetInTouchOnPage.controls.mobile.touched}"
                                                maxlength="10" placeholder="Mobile*" formControlName="mobile" (keypress)="isNumber($event)" required />
                                            <span class="alertMsg"
                                                *ngIf="!GetInTouchOnPage.controls.mobile.valid && submited">Please enter
                                                Mobile*</span>
                                        </li>
                                        <li>
                                            <input type="text" name="email"
                                                [ngClass]="{'has-error':!GetInTouchOnPage.controls.email.valid && GetInTouchOnPage.controls.email.touched}"
                                                placeholder="Email ID*" formControlName="email" />
                                            <span class="alertMsg"
                                                *ngIf="!GetInTouchOnPage.controls.email.valid && submited">Please enter Email
                                                ID</span>
                                        </li>
                                        <li class="singledropdown">
                                            <div class="selectbox">
                                                <select class="select" name="speciality" [(ngModel)]="specialityModel"
                                                    formControlName="speciality">
                                                    <option value="" selected>Select Speciality</option>
                                                    <option *ngFor="let special of specialityList;let i= index;"
                                                        [value]="special.name">
                                                        {{special.name}}
                                                    </option>
                                                </select>
                                                <span class="select-span"></span>
                                            </div>
                                            <span class="alertMsg"
                                                *ngIf="!GetInTouchOnPage.controls.speciality.valid && submited">Please select
                                                Speciality</span>
                                        </li>
                                        <li class="singledropdown">
                                            <div class="selectbox">
                                                <select class="select" name="intrestedin" id="intrestedin"
                                                    [(ngModel)]="countryModel" formControlName="intrestedin">
                                                    <option value="" selected>I am interested in</option>
                                                    <option *ngFor="let interest of interestedList;let i= index;"
                                                        [value]="interest.packageheading">
                                                        {{interest.packageheading}}
                                                    </option>
                                                </select>
                                                <span class="select-span" id="intrestedinP"></span>
                                            </div>
                                            <span class="alertMsg"
                                                *ngIf="!GetInTouchOnPage.controls.intrestedin.valid && submited">Please select I
                                                am interested in*</span>
                                        </li>
                                        <li>
                                            <input type="text" name="message" placeholder="Message" formControlName="message" />
                                        </li>
                                        <li class="w100">
                                            <label for="authorization" class="checklabel" placeholder="consent">
                                                <input id="authorization" placeholder="consent" name="authorization"
                                                    formControlName="authorization" class="checkbox" type="checkbox"
                                                    value="consent" required />
                                                I hereby authorize Rainbow Hospitals and their certified partners to contact me
                                                through email, phone or any other modes of communication. It will override my
                                                registry on NCPR
                                            </label>
                                            <span class="alertMsg"
                                                *ngIf="!GetInTouchOnPage.controls.authorization.valid && submited">Please select
                                                agree the consent</span>
                                        </li>
                                        <li>
                                            <div class="submitbtn">
                                                <input type="hidden" name="location" id="location" formControlName="location" />
                                                <input class="casubmit" id="txtSubmit" name="txtSubmit" type="submit"
                                                    value="Submit">
                                            </div>
                                        </li>
                                    </ul>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12" style="display: none;">
                        <div class="sheading">
                            <h3>Once you enrol, you will be eligible for</h3>
                        </div> 
                        <div>
                            <div class="listItemBox" *ngFor="let item of PackageListing">
                                <div class="listWrap" *ngFor="let mainservicelist of item.mainservicelist, let index = index">
                                    <h5><span>{{index + 1}}</span>   {{mainservicelist.servicename}}</h5>
                                    <ul class="listWrap">
                                        <li *ngFor="let subList of mainservicelist.subservice" [innerHtml]="subList.subservicename"></li>
                                    </ul>
                                </div>
                                
                            </div>
                        </div>  
                    </div>                                     
                </div>
            </div>
        </div>
    </section>





    <a class="iconFormV" href="javascript:void();" (click)="ShowForm()">ENQUIRE<span>NOW</span></a>
    <a class="iconForm" href="javascript:void();" (click)="ShowForm()"><img alt=""
            src="assets/images/Icon_form.png"></a>
    <footer-nav-mini [links]="links"></footer-nav-mini>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
    <!-- Start Model Popup  *ngIf="showHide" -->
    <div class="capopup" id="ModelFormGetInTouch">
        <div class="capopup-inner">
            <div class="launchpreloader" *ngIf="preloader"></div>
            <a class="capopup-close" href="javascript:void()" (click)="changeShowStatus()"> <img alt=""
                    src="assets/images/icon-close2.png"> </a>
            <h2>GET IN TOUCH WITH US</h2>
            <form id="form" [formGroup]="GetInTouch" (submit)="onSubmit()" novalidate>
                <ul class="formcasn">
                    <li>
                        <input type="text" name="name"
                            [ngClass]="{'has-error':!GetInTouch.controls.name.valid && GetInTouch.controls.name.touched}"
                            placeholder="Name*" formControlName="name" required />
                        <span class="alertMsg" *ngIf="!GetInTouch.controls.name.valid && submited">required</span>
                    </li>
                    <li>
                        <input type="text" name="mobile"
                            [ngClass]="{'has-error':!GetInTouch.controls.mobile.valid && GetInTouch.controls.mobile.touched}"
                            maxlength="10" placeholder="Mobile*" formControlName="mobile" required />
                        <span class="alertMsg" *ngIf="!GetInTouch.controls.mobile.valid && submited">required</span>
                    </li>
                    <li>
                        <input type="text" name="email"
                            [ngClass]="{'has-error':!GetInTouch.controls.email.valid && GetInTouch.controls.email.touched}"
                            placeholder="Email ID*" formControlName="email" required />
                        <span class="alertMsg" *ngIf="!GetInTouch.controls.email.valid && submited">required</span>
                    </li>
                    <li class="singledropdown">
                        <div class="selectbox">
                            <select class="select" name="txtLocation" id="txtLocation" [(ngModel)]="locationModel"
                                formControlName="txtLocation">
                                <option value="" selected>Location*</option>
                                <option *ngFor="let interest of locationList;let i= index;" [value]="interest.name">
                                    {{interest.name}}
                                </option>
                            </select>
                            <span class="select-span"></span>
                        </div>
                        <span class="alertMsg" *ngIf="!GetInTouch.controls.txtLocation.valid && submited">required
                        </span>
                    </li>
                    <li class="singledropdown">
                        <div class="selectbox">
                            <select class="select" name="interested_in" id="txtinterested_in" [(ngModel)]="countryModel"
                                formControlName="interested_in">
                                <option value="" selected>I am interested in*</option>
                                <option *ngFor="let interest of interestedList;let i= index;" [value]="interest.name">
                                    {{interest.name}}
                                </option>
                            </select>
                            <span class="select-span"></span>
                        </div>
                        <span class="alertMsg" *ngIf="!GetInTouch.controls.interested_in.valid && submited">required
                        </span>
                    </li>
                    <li>
                        <input type="text" name="message" placeholder="Message" formControlName="message" />
                    </li>
                    <li>
                        <div class="submitbtn">

                            <input class="casubmit" id="txtSubmit" name="txtSubmit" type="submit" value="Submit">
                        </div>
                    </li>
                </ul>
            </form>
        </div>
    </div>

    <div class="capopup ModelFormStyle1" id="LMSDataCaptureFormGetInTouch">
        <div class="capopup-inner">
            <div class="customloder"></div>
            <a class="capopup-close " href="javascript:void()" (click)="hideModelForm();"> <img alt=""
                    src="assets/images/icon-close2.png"> </a>
            <h2>GET IN TOUCH WITH US</h2>
            <form id="GetInTouchLms" [formGroup]="GetInTouchLms" (submit)="onLMSDataCaptureFormSubmit()" novalidate>
                <ul class="formcasn">
                    <div class="ThankYouMsgWrp"><span>Thanks for enquiring about our Evening Clinic. Our team will get
                            back to you shortly.</span></div>
                    <li>
                        <input type="text" name="name"
                            [ngClass]="{'has-error':!GetInTouchLms.controls.name.valid && GetInTouchLms.controls.name.touched}"
                            placeholder="Name*" formControlName="name" required />
                        <span class="alertMsg" *ngIf="!GetInTouchLms.controls.name.valid && submited">required</span>
                    </li>
                    <li>
                        <input type="text" name="mobile"
                            [ngClass]="{'has-error':!GetInTouchLms.controls.mobile.valid && GetInTouchLms.controls.mobile.touched}"
                            placeholder="Mobile*" formControlName="mobile" required />
                        <span class="alertMsg" *ngIf="!GetInTouchLms.controls.mobile.valid && submited">required</span>
                    </li>
                    <li>
                        <input type="text" name="email"
                            [ngClass]="{'has-error':!GetInTouchLms.controls.email.valid && GetInTouchLms.controls.email.touched}"
                            placeholder="Email ID*" formControlName="email" required />
                        <span class="alertMsg" *ngIf="!GetInTouchLms.controls.email.valid && submited">required</span>
                    </li>
                    <li>
                        <input type="text" name="message" placeholder="Message" formControlName="message" />
                        <input type="hidden" name="programName" id="programName" formControlName="programName"
                            value="Academic Programs" />
                    </li>
                    <li>
                        <div class="submitbtn">
                            <input class="casubmit" id="txtSubmit" name="txtSubmit" type="submit" value="Submit">
                        </div>
                    </li>
                </ul>
            </form>
        </div>
    </div>
    <!-- End Model Popup -->
</div>