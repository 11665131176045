<div id="sidenav">
	<div class="navgroup-mo">
    	<ul class="iconnavtop">
			<li><a href="javascript:void(0)" (click)="searchBar($event)"  class="searchbtn" id="searchbtn"><span class="icon2"></span></a></li>
			<li style="display: none;"><a title="whatsapp" href="https://api.whatsapp.com/send?phone=+919619079190&text={{ metaDis }}" target="_blank"><span class="wtup"></span></a></li>
			<li class="dropdowntn" style="display: none;"><a href="javascript:void(0)" class="dropdownSocial"><span class="iconSocial"></span></a>
                <div class="submenuSocial dropdown-tggle">
                    <ul class="hidden-xs">
                        <li><a [href]="socialIcon.facebook" target="_blank" class="fb">&nbsp;</a></li>
						<li><a [href]="socialIcon.twitter" target="_blank" class="tw">&nbsp;</a></li>
						<li><a [href]="socialIcon.youtube" target="_blank" class="yt">&nbsp;</a></li>
						<li><a [href]="socialIcon.linkedin" target="_blank" class="ln" title="Linkedin">&nbsp;</a></li>
					</ul>
					<ul class="visible-xs">
						<li><a title="whatsapp" href="https://api.whatsapp.com/send?phone=+919619079190&text={{ metaDis }}" target="_blank" class="wt">&nbsp;</a></li>
					</ul>
                </div>
            </li>
            <li><a href="/other/our-centre-map"><span class="icon3"></span></a></li>
            <!-- <li class="dropdowntn"><a href="javascript:void(0)" class="dropdown360"><span class="icon360"></span></a>
                <div class="submenu360 dropdown-tggle">
                    <ul>
                        <li><a href="#">Banjara Hills</a></li>
                        <li><a href="#">Kondapur</a></li>
                        <li><a href="#">Madhapur</a></li>
                        <li><a href="#">Vijayawada</a></li>
                        <li><a href="#">Hydernagar</a></li>
                        <li><a href="#">Hyderabad</a></li>
                    </ul>
                </div>
			</li> -->
			<li><a title="Emergency Services" href="/other/emergency-service"><span class="iconEmergencyS"></span></a></li>
			<li><a title="Investor Relations" href="/investor-relations"><span class="iconinvestor"></span></a></li>
			<li><a title="Innternational Patients" href="/international-patients/index.html"><span class="iconInt"></span></a></li>
			<li><a title="Academic Programs" href="/other/academic-programs"><span class="iconAP"></span></a></li>
            <!--<li><a href="http://103.231.214.196/~healthcard/ccr/index.php" target="_blank"><span class="icon4"></span></a> </li>-->
        </ul>
    </div>

	<a href="javascript:void()" class="closesidenav"><img src="assets/images/icon-close.png" alt="" /></a>
	<div class="sidenavwrp">
		
		
			<div class="navalignwrp">
				<ng-template ngFor let-sidebar [ngForOf]="sideBarList" let-i="index">
					<ul *ngIf="sidebar.type === 'col-1'"> 
						<li  [class.active]="i == activeul">
							<h2 (click)="activeMenu(i)">{{sidebar.title}}<span class=""></span></h2>
							<p>{{sidebar.subtitle}}</p>
							<ul class="subList" [class.active]="i == activeul">
									
									<ng-template ngFor let-links [ngForOf]="sidebar.links">
											<li *ngIf="links.status == 1"><a [href]="links.link"  [target]="links.target">{{links.title}}</a></li>
									</ng-template>
							</ul>
						</li>
					</ul>	
				</ng-template>	
			</div>
			<div class="navalignwrp">
					<ng-template ngFor let-sidebar [ngForOf]="sideBarList"  let-i="index">
						<ul *ngIf="sidebar.type === 'col-2'"> 
							<li  [class.active]="i == activeul">
								<h2 (click)="activeMenu(i)">{{sidebar.title}}<span class=""></span></h2>
								<p>{{sidebar.subtitle}}</p>
								<ul class="subList"  [class.active]="i == activeul">
									<ng-template ngFor let-links [ngForOf]="sidebar.links">
											<li *ngIf="links.status == 1"><a [href]="links.link == '/international-patients' ?links.link + '/index.html' : links.link " [class]="links.class"  [target]="links.target">{{links.title}}</a></li>
									</ng-template>
										
								</ul>
							</li>
						</ul>	
					</ng-template>	
				</div>
		<div class="sidefooternavwrp">
			<div class="col-md-12 col-sm-12 col-lg-12">
				<ng-template ngFor let-footernav [ngForOf]="sideBarList">
					<ul *ngIf="footernav.type == 'footer-1'">
						
						<ng-template ngFor let-link [ngForOf]="footernav.links">
								<li *ngIf="link.status == 1"><a [href]="link.link"  [target]="link.target">{{link.title}}</a></li>
						</ng-template>
					</ul>
					<ul *ngIf="footernav.type == 'footer-2'" class="tnav">
							<ng-template ngFor let-link [ngForOf]="footernav.links">
									<li *ngIf="link.status == 1"><a [href]="link.link"  [target]="link.target">{{link.title}}</a></li>
							</ng-template>
					</ul>
				</ng-template>
				
			</div>
		</div>
	</div>
</div>
<div id="topsearchwrp">
	<div class="inputbox">
		<form  #f="ngForm"  >
				<input class="searchInput" type="text" name="q" id="txtSearch" placeholder="type here..." [(ngModel)]="search" />
				<input type="submit" name="submit" (click)="onSubmit()" class="ssubmitbtn" value="" />
		</form>
		
	</div>
</div>