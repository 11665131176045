import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL } from '../services/constants';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { Subject } from 'rxjs/Subject';
import * as $ from 'jquery'
// declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
declare let initGoogleMap: any;
declare let initCentersSpecialityTabs: any;
//declare let ourCenter:any;
@Component({
  selector: 'our-center-map',
  templateUrl: './our-center-map.component.html',
  styles: [
    `
      .visitext {
        white-space: pre-wrap;
      }
      ul li > a {
        text-transform: capitalize;
      }
      .gmapwrp .sidelocdetwrp .gmheader .box {
        width: 98%;
      }
      ul li a {
        text-transform: capitalize;
      }
      .OurCentertext .cityname li:nth-child(even) {
        width: 45% !important;
      }
      .OurCentertext .cityname li:nth-child(odd) {
        width: 45% !important;
      }
      ul.cityname a {
        color: #000;
      }
    `,
  ],
})
export class OurCenterMapComponent implements OnDestroy, OnInit, AfterViewInit {
  public selectedCentreId = new Subject<any>();
  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    public metaTitle: Title,
    public meta: Meta
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
    }
  }
  public currentSection: any;
  public title: any = '';
  public subtitle: any = '';
  public description: any = '';
  public imgsrc: any = '';
  public imgBasePath = '';
  public hidden = 'hidden';
  public logo = 'logo';

  public activeRoute: any[] = [];
  public activePage: any[] = [];
  public centerCity: any[] = [];
  public centerList: any[] = [];
  public centerCopyList: any[] = [];

  public centreName: string;
  public centreAliasName: string;
  public centreAddress: string;
  public centreTelephone: string;
  public centreImage: string;
  public centreEmail: string;
  public centreLatLng: string;
  public virtual_tour_link: string;
  public selectedLocation: any = 0;
  public links: any = [];
  public bread_crumb_links: any = [];
  public activeSubPage: any;
  public cities: any = '';
  //public selectedCentreId:any = '';
  public bread_crumb_child_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Child Care', link: '/child-care/about', class: '' },
    { label: 'Our Centres', link: '', class: '' },
  ];

  public bread_crumb_women_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Women Health', link: '/women-care/about', class: '' },
    { label: 'Our Centres', link: '', class: '' },
  ];

  public bread_crumb_fertility_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Fertility Care', link: '/fertility-care/about', class: '' },
    { label: 'Our Centres', link: '', class: '' },
  ];

  public child_care_links: any = [
    {
      label: 'About Child Care',
      link: '/child-care-about',
      class: '',
      slug: 'about',
    },
    {
      label: 'Child Safety',
      link: '/child-care/child-safety',
      class: '',
      slug: 'child-safety',
    },
    {
      label: 'Doctors',
      link: '/child-care/doctors',
      class: '',
      slug: 'child-care-doctors',
    },
    {
      label: 'Our Specialities',
      link: '/child-care/specialities',
      class: '',
      slug: 'specialities',
    },
    {
      label: 'Our Centres',
      link: '/child-care/centres',
      class: '',
      slug: 'centres',
    },
    {
      label: 'Vaccination',
      link: '/child-care/vaccination',
      class: '',
      slug: 'vaccination',
    },
    {
      label: 'Testimonials',
      link: '/child-care/testimonials',
      class: '',
      slug: 'testimonials',
    },
    {
      label: 'Share Feedback',
      link: '/child-care/feedback',
      class: '',
      slug: 'feedback',
    },
    {
      label: 'Book An Appointment',
      link: '/child-care/book-an-appointment',
      class: 'active',
      slug: 'child-care',
    },
  ];

  public women_care_links: any = [
    {
      label: "About Women's Care",
      link: '/women-care/about',
      class: '',
      slug: 'about',
    },
    {
      label: 'Doctors',
      link: '/women-care/doctors',
      class: '',
      slug: 'women-care-doctors',
    },
    {
      label: 'Our Specialities',
      link: '/women-care/specialities',
      class: '',
      slug: 'specialities',
    },
    {
      label: 'Our Centres',
      link: '/women-care/centres',
      class: '',
      slug: 'centres',
    },
    {
      label: 'Testimonials',
      link: '/women-care/testimonials',
      class: '',
      slug: 'testimonials',
    },
    {
      label: 'Share Feedback',
      link: '/women-care/feedback',
      class: '',
      slug: 'feedback',
    },
    {
      label: 'Book An Appointment',
      link: '/women-care/book-an-appointment',
      class: 'active',
      slug: 'women-care',
    },
  ];

  public fertility_care_links: any = [
    {
      label: 'About Fertility Care',
      link: '/fertility-care/about',
      class: '',
      slug: 'about',
    },
    {
      label: 'Doctors',
      link: '/fertility-care/doctors',
      class: '',
      slug: 'fertility-care-doctors',
    },
    {
      label: 'Our Specialities',
      link: '/fertility-care/specialities',
      class: '',
      slug: 'specialities',
    },
    {
      label: 'Our Centres',
      link: '/fertility-care/centres',
      class: '',
      slug: 'centres',
    },
    {
      label: 'Testimonials',
      link: '/fertility-care/testimonials',
      class: '',
      slug: 'testimonials',
    },
    {
      label: 'Share Feedback',
      link: '/fertility-care/feedback',
      class: '',
      slug: 'feedback',
    },
    {
      label: 'Book An Appointment',
      link: '/fertility-care/book-an-appointment',
      class: 'active',
      slug: 'fertility-care',
    },
  ];

  //@ViewChildren('abouttabs') things: QueryList<any>;
  activeBreadCrum(currentSection) {
    switch (currentSection) {
      case 'child-care':
        this.bread_crumb_links = this.bread_crumb_child_links;
        this.links = this.child_care_links;
        this.logo = 'rainbow-logo';
        this.activeSubPage = 'child-care-centres';
        break;
      case 'women-care':
        this.bread_crumb_links = this.bread_crumb_women_links;
        this.links = this.women_care_links;
        this.logo = 'birth-right-logo';
        this.activeSubPage = 'child-care-centres';
        break;
      case 'fertility-care':
        this.bread_crumb_links = this.bread_crumb_fertility_links;
        this.links = this.fertility_care_links;
        this.logo = 'birth-right-logo';
        this.activeSubPage = 'child-care-centres';
        break;
      default:
        this.logo = 'logo';
    }
  }
  @ViewChildren('centerMap') things: QueryList<any>;
  ngOnInit() {
    this.api.getPageDescription('our-center-map').subscribe(
      (data) => {
        //console.log(data);
        this.title = data.page_descriptions[0].title;
        this.subtitle = data.page_descriptions[0].subtitle;
        this.description = data.page_descriptions[0].description;
        this.imgsrc = data.page_descriptions[0].image;
        this.imgBasePath = IMG_BASE_URL;
        if (data) {
          //console.log(data);
          this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
          this.meta.updateTag({
            name: 'keywords',
            content: data.page_descriptions[0].meta_keyword,
          });
          this.meta.updateTag({
            name: 'description',
            content: data.page_descriptions[0].meta_description,
          });
          this.meta.addTags([
            {
              property: 'og:title',
              content: data.page_descriptions[0].meta_title,
            },
            {
              property: 'og:description',
              content: data.page_descriptions[0].meta_description,
            },
            {
              property: 'og:keywords',
              content: data.page_descriptions[0].meta_keyword,
            },
          ]);
        }
      },
      (error) => {
        console.log(error);
      }
    );
    this.route.params.subscribe((params) => {
      //this.currentSection = params['id'];
      this.currentSection = params['section'];
    });
    this.activeBreadCrum(this.currentSection);
    this.api
      .getSpecialityMasters()
      .map((res) => res)
      .subscribe((data) => {
        //console.log(data);
        this.activeRoute = data.speciality_masters.filter((filterdata) => {
          return filterdata.slug == this.currentSection;
        });

        //console.log(this.activeRoute);
        if (this.activeRoute.length > 0) {
          //this.activePage = this.activeRoute[0].id;
          this.activePage = this.activeRoute[0].speciality;
        } else {
          this.activePage = this.currentSection;
        }
      });
    this.api.getCenters().subscribe(
      (res) => {
        res.centers.map((data, key) => {
          const cityName = data.center_name.split(',');
          let city = data.city ? data.city.city : cityName[1];
          this.centerCity.push(city.trim());
          let center = this.api.getFilteredCenterData(data);
          this.centerList.push(center);
          this.centerCopyList.push(center);
        });
        this.centerCity = this.centerCity.reduce(function (a, b) {
          if (a.indexOf(b) < 0) a.push(b);
          return a;
        }, []);
      },
      (err) => {
        console.log(err);
      }
    );

    this.api.getCenterCity().subscribe((data) => {
      this.cities = data.cities;

      //console.log(this.cities);
    });
  }
  submitLocation(f: NgForm) {
    //alert('clicked');
    this.centerList = [];
    //this.selectedCentreId = ;
    this.selectedCentreId.next(f.value['selectedLocation']);
    $('html, body').animate({ scrollTop: $('.gmap').offset().top - 80 }, 1000);
    //console.log(this.selectedCentreId);
    // if(f.value['selectedLocation'] != 0){
    // 	let filteredList = this.centerCopyList.filter((res)=>{
    // 		return res.city == f.value['selectedLocation'];
    // 	});
    // 	if(filteredList.length > 0){
    // 		this.centerList =  filteredList;
    // 	}
    // }else{
    // 	this.api.getCenters()
    // 	.subscribe(res => {
    // 		res.centers.map((data, key) => {
    // 			let filter = this.api.getFilteredCenterData(data);
    // 			this.centerList.push(filter);
    // 		});
    // 	}, err => {
    // 		console.log(err);
    // 	});
    // }
  }

  filterCenter(event) {
    //console.log(event.target.value);
    let filterCenter = this.centerCopyList.filter((res) => {
      return res.center_name_alias == event.target.value;
    });
  }

  ngAfterViewChecked() {}
  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
      initGoogleMap();
      this.things.changes.subscribe((t) => {
        initGoogleMap();
      });
    }
  }

  ngOnDestroy() {}
}
