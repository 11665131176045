<div class="container-fluid">
  <!-- <div class="loder"></div> -->
  <div class="row header w-full">
    <div class="container mx-auto w-full px-0" style="margin-bottom: -2em">
      <a href="/" target="_blank"
        ><img
          src="assets/images/RCH-logo.png"
          alt=""
          style="transform: scale(75%)"
      /></a>
    </div>
  </div>
  <section class="container">
      <h1 style="color: #7c3996;">Thanks for registering with us. </h1>
      <h3 style="color: #231745;">
      <!-- Your payment was successful  with Transaction ID&nbsp;:&nbsp;{{tid}}<br />
                  and -->
      an email confirmation will be sent to your registered email address shortly.
      </h3>
  </section>
</div>
