function initHome() {
    var $body = $('body');
    var $loder = $body.find('.loder');
    //var $KCSliderCont =  $body.find('.KCSliderCont');
    //var $KCSlider = $body.find('.KCSlider');
    var $fncarousel2UL = $body.find('.fncarousel2 ul');
    var $joinusslide = $body.find('.joinusslide');
    var $joinusslideVideoitemsIframe = $joinusslide.find('.videoitems iframe');
    var $joinusslideSlickDotsLiButton = $joinusslide.find('.slick-dots li button');
    var $workingexpslider = $body.find('.workingexpslider');
    var $apapproachlist = $body.find('.apapproachlist');
    var $aboutlphmqslider = $body.find('.aboutlphmqslider');
    var $getacallwrp = $body.find('.getacallwrp');

    if (!window['YT']) { var YT = { loading: 0, loaded: 0 }; }
    if (!window['YTConfig']) { var YTConfig = { 'host': 'http://www.youtube.com' }; }
    if (!YT.loading) {
        YT.loading = 1;
        (function() {
            var l = [];
            YT.ready = function(f) { if (YT.loaded) { f(); } else { l.push(f); } };
            window.onYTReady = function() { YT.loaded = 1; for (var i = 0; i < l.length; i++) { try { l[i](); } catch (e) {} } };
            YT.setConfig = function(c) { for (var k in c) { if (c.hasOwnProperty(k)) { YTConfig[k] = c[k]; } } };
            var a = document.createElement('script');
            a.type = 'text/javascript';
            a.id = 'www-widgetapi-script';
            a.src = 'https://s.ytimg.com/yts/jsbin/www-widgetapi-vflfUQ-oz/www-widgetapi.js';
            a.async = true;
            var b = document.getElementsByTagName('script')[0];
            b.parentNode.insertBefore(a, b);
        })();
    }
    //declareDOM();

    /* Iframe Api Script */
    var getacallwrp = function() {
        //alert('adfadf');
        $('.getacallwrp').animate({ 'top': (($(window).height() - $('.getacallwrp').height()) / 2) - 40 }, 1000);
    }
    $('body').on('click', '.getaCall', getacallwrp);
    //$body.find('.getaCall').on('click',getacallwrp);

    $getacallwrp.find('ul li input').each(function(index, element) {
        $(this).keyup(function(e) {
            $(this).parent().find('span').hide();
        });
    });
    $('#txtGetaCall, #txtGetaCallOTP').on('input', function(event) {
        this.value = this.value.replace(/[^0-9]/g, '');
    });

    $('#submitOTP').on('click', function (e) {
        $('.gorbox').hide();
        $('.ResultScrren').show();
    });

    $loder.fadeOut(300);

    $joinusslideVideoitemsIframe.each(function(index, element) {
        $(this).attr('src', $(this).attr('src') + '?enablejsapi=1&autohide=1&showinfo=0&rel=0');
    });
    $joinusslideSlickDotsLiButton.on('click', function () {
        for (var i = 0; i < ytplayer.length; i++)
            ytplayer[i].stopVideo();
    });
    (function pressClipsBox() {
        $('.pressClipsBox').each(function(index, element) {
            $(this).find('.imageBox').css({ 'height': $(this).width() });
            $(this).find('.imageBox img').css('width', '100%').wrap('<div style="width:100%; height:100%;border-radius:100%; overflow: hidden;"></div>');
        });

        $(window).on('click', function () {
            $('.pressClipsBox').each(function(index, element) {
                $(this).find('.imageBox').css({ 'height': $(this).width() });
            });
        });
    })();

}

function initAllPage() {
    var $body = $('body');
    var $headerwrp = $body.find('.headerwrp');
    var $slidenavwrp = $body.find('.slidenavwrp');
    var $logowrp = $body.find('.logowrp');
    var $tonnavwrp = $body.find('.tonnavwrp');

    var wth = $(window).width();
    var baseUrl = location.pathname.substring(location.pathname.lastIndexOf("/") + 1);
    $(window).on('scroll', function () {
        //console.log('scroll 1')
        var y = $(this).scrollTop();
        var headerwrpFunc = function(mTop) {
                if (mTop == 'fixed') {
                    $headerwrp.addClass("fixed");
                    $slidenavwrp.addClass("fixed");
                    $logowrp.find('img').css('margin-top', '0px');
                } else {
                    $headerwrp.removeClass("fixed");
                    $slidenavwrp.removeClass("fixed");
                    $logowrp.find('img').css('margin-top', '9px');
                }

            }
            //console.log(y);
        var headerCheck = y > 0 ? headerwrpFunc('fixed') : headerwrpFunc('float');
        if (wth < 1000) {
            if (y > 0) {
                $logowrp.addClass("active");
                $tonnavwrp.addClass("active");
            } else {
                $logowrp.removeClass("active");
                $tonnavwrp.removeClass("active");
            }
        }

        if (baseUrl == "index.html" || baseUrl == "" || baseUrl == "home") {
            var os_offsetop = $body.find('.ourSpecialitieswrp').offset();
            var rainbow_offsetop = $body.find('.kaleidoscopewrp').offset().top - 100;
            var about_offsetop = $body.find('.aboutuswrp').offset().top - 100;
            var osbox1Anime, osbox2Anime, osbox3Anime, osbox4Anime, rbbox1Anime, rbbox2Anime, aboutuswrpAnime;
            os_offsetop = os_offsetop.top - 200;

            if (y > os_offsetop) {
                //console.log('present in ourSpecialitieswrp');
                osbox1Anime = setTimeout(function() { $('.osbox1').stop().removeClass("anim"); }, 500);
                osbox2Anime = setTimeout(function() { $('.osbox2').stop().removeClass("anim"); }, 600);
                osbox3Anime = setTimeout(function() { $('.osbox3').stop().removeClass("anim"); }, 700);
                osbox4Anime = setTimeout(function() { $('.osbox4').stop().removeClass("anim"); /*anlr('.ourSpecialitieswrp .hanginganim .text');  */ }, 650);
                //setTimeout(function(){$('.oursslider .sitem').removeClass('hanginganim'); $('.oursslider .sitem .text').removeAttr('style'); }, 4000);
                clearTimeout(rbbox1Anime);
                clearTimeout(rbbox2Anime);
                clearTimeout(aboutuswrpAnime);
            }


            if (y > rainbow_offsetop) {
                rbbox1Anime = setTimeout(function() { $('.rbbox1').stop().removeClass("anim"); }, 500);
                rbbox2Anime = setTimeout(function() { $('.rbbox2').stop().removeClass("anim"); /*anlr('.kaleidoscopewrp .hanginganim .text');*/ }, 700);
                //setTimeout(function(){$('.kaleidoscopewrp .sitem').removeClass('hanginganim'); $('.kaleidoscopewrp .sitem .text').removeAttr('style'); }, 3000);
                clearTimeout(osbox1Anime);
                clearTimeout(osbox2Anime);
                clearTimeout(osbox3Anime);
                clearTimeout(osbox4Anime);
                clearTimeout(aboutuswrpAnime);
            }
            if (y > about_offsetop) {
                aboutuswrpAnime = setTimeout(function() { $('.aboutuswrp .contentwrp').stop().removeClass("anim"); }, 100);
                clearTimeout(osbox1Anime);
                clearTimeout(osbox2Anime);
                clearTimeout(osbox3Anime);
                clearTimeout(osbox4Anime);
                clearTimeout(rbbox1Anime);
                clearTimeout(rbbox2Anime);
            }
        }
        var st = $(this).scrollTop();
        ((st > lastScrollTop) ? $(".headerwrp, .slidenavwrp").addClass("active") : $(".headerwrp, .slidenavwrp").removeClass("active"))
        lastScrollTop = st;
    });
    //variable
    var $tableAccordion = $body.find('.tableAccordion');
    var $background = $('.background');
    var $backgroundImg = $background.find('img');
    var $abawardswrp = $('.abawardswrp');
    var $openingwrpUL = $('.openingwrpUL');
    var $openingstaff_cont = $('.openingstaff_cont');
    var $ttheader = $('.ttheader');
    var lastScrollTop = 0;
    //variable end
    $tableAccordion.each(function(index, element) {
        $(".TAContant:first").slideDown()
        $(".TAHead:first").addClass("active");
        $(this).find(".TAHead").each(function(index, element) {
            $(this).on('click', function (e) {
                $(this).addClass("active").siblings(".TAHead").removeClass("active")
                $(this).next(".TAContant").slideDown().siblings(".TAContant").slideUp()
            });
        });
    });

    $('.circuleframe, .circuleframe2, .aboutbannimg, .otherbannimg').addClass('active');
    $('.logowrp img').css('margin-top', '9px');

    $('section, footer, .footerstikynav, .logowrp, .sidenavwrp').on('click', function () {
        $('#topsearchwrp').removeClass('visible');
    });
    var bgImgFunc = function(attr) {
        $backgroundImg.each(function(index, element) {
            $(this).attr('src', 'assets/images/' + $(this).attr(attr));
        });
    }
    var abawardswrpFunc = function() {
        $abawardswrp.each(function(index, element) {
            var setwh = $(this).width();
            $(this).stop().animate({ height: setwh }, 600);
        });
    }
    $(window).width() < 980 ? bgImgFunc('data-t') : bgImgFunc('data-d');
    if ($(window).width() < 1200) {
        $('.abmdscir .text').each(function(index, element) {
            $(this).stop().animate({ width: $(this).parent().width(), height: $(this).parent().width() }, 600);
        });
    }

    $('.ttheader').find('.timewrp li a').each(function(index, element) {
        $(this).on('click', function (e) {
            if (!$(this).parent().hasClass('booked')) {
                $('.ttheader .timewrp li').removeClass('active');
                $(this).parent().addClass('active');
                $('[data-popup="popup-2"]').delay(200).fadeIn(350);
            }
        });
    });
    var ttheaderFunc = function(e) {
        $('.ttheader').delay(600).slideUp();
        $('.dconfirmawrp2').delay(600).slideDown();
        $('[data-popup="popup-22"]').delay(300).fadeOut(350);
    }
    $('body').on('click', '#NewPatient', ttheaderFunc);
    $('body').on('click', '#ExistingPatient', ttheaderFunc);

    $('.btnPrevca').on('click', function (e) {
        $('.ttheader').slideDown();
        $('.dconfirmawrp').slideUp();
        $('.dconfirmawrp2').slideUp();
    });
    /* Start Confirm Appointment Form Validation */



    $('.ttheader2 ul li input').each(function(index, element) {
        $(this).on('click', function (e) {
            $(this).parent().find('span').remove('span');
        });
    });
    $(".select option").each(function(index, element) {
        $(this).val($(this).val());
    });
    $(".select").each(function(index, element) {
        var thisVal = $('option:selected', this).text();
        $(this).siblings(".select-span").text(thisVal);
        $(this).on("change", function() {
            var thisVal = $('option:selected', this).text();
            $(this).siblings(".select-span").text(thisVal);
            $(this).parents('.selectbox').find('span.error').remove('span.error');
        });
    });
    $(window).width() <= 980 ? $("#bg-video3").remove('#bg-video3') : '';

    var $ourcenterlist = $body.find('.ourcenterlist');
    $ourcenterlist.parent().find('#slickNext').text($('.ourcenterlist .slick-current').next().attr('data-cityname'));
    $ourcenterlist.parent().find('#slickPrev').text($('.ourcenterlist .slick-current').prev().attr('data-cityname'));
    var ourcenterlistFunc = function(section) {
        $(this).text("");
        if ($('.ourcenterlist .slick-current').next() != null) {
            if (section === 'next') {
                $(this).text($('.ourcenterlist .slick-current').next().attr('data-cityname'));
            } else {
                $ourcenterlist.parent().find('#slickNext').text($('.ourcenterlist .slick-current').next().attr('data-cityname'));
            }

        } else {
            if (section === 'next') {
                $ourcenterlist.parent().find('#slickPrev').text($('.ourcenterlist .slick-current').prev().attr('data-cityname'));
            } else {
                $(this).text($('.ourcenterlist .slick-current').prev().attr('data-cityname'));
            }
        }
    }
    $ourcenterlist.parent().find('#slickNext').on('click', ourcenterlistFunc);
    $ourcenterlist.parent().find('#slickPrev').on('click', ourcenterlistFunc);

    $('.circuleframe, .circuleframe2, .aboutbannimg, .otherbannimg').addClass('active');
    //$('.logowrp img').css('margin-top', '9px');

    if ($(window).width() <= 980) {
        $("#bg-video").remove('#bg-video');
        $("#bg-video1").remove('#bg-video1');
    }
    $(window).on('load', function() {
        /*$('#bg-video').videoBackground("https://backend.rainbowhospitals.in/assets/images/0_blue_optimized.mp4");
        $('#bg-video1').videoBackground("https://backend.rainbowhospitals.in/assets/images/1_purpule_optimized.mp4");
        $('#bg-video2').videoBackground("https://backend.rainbowhospitals.in/assets/images/2_green_optimized.mp4");
        $('#bg-video3').videoBackground("https://backend.rainbowhospitals.in/assets/images/3_yellow_optimized.mp4");
        $('#bg-video4').videoBackground("https://backend.rainbowhospitals.in/assets/images/0_blue_optimized.mp4");
        */
        $('.backgroundPopup').delay(500).stop().fadeIn(600);
        $('.homepopup').delay(1000).stop().fadeIn(600);
        $(".hpclose").on('click', function (e) {
            $(".homepopup, .backgroundPopup").fadeOut();
        });
        //limittext(".data .text > p");
    });


    function fadeInDiv() {
        var divs = $('.background .bgImg.active');
        var elem = divs.eq(Math.floor(Math.random() * divs.length));
        $('.background .bgImg').css('opacity', '0');
        if (!elem.is(':visible')) {
            elem.animate({
                opacity: 1
            }, Math.floor(Math.random() * 10), fadeInDiv);
        } else {
            elem.animate({
                opacity: (Math.random() * 1)
            }, Math.floor(Math.random() * 10), function() {
                window.setTimeout(fadeInDiv, 1000);
            });
        }
    }

    $(function() {
        $('[data-popup-open]').on('click', function(e) {
            var targeted_popup_class = jQuery(this).attr('data-popup-open');
            $('[data-popup="' + targeted_popup_class + '"]').fadeIn(350);
            e.preventDefault();
        });
        $('[data-popup-close]').on('click', function(e) {
            var targeted_popup_class = jQuery(this).attr('data-popup-close');
            $('[data-popup="' + targeted_popup_class + '"]').fadeOut(350);
            e.preventDefault();
        });

        function fontSize() {
            var fontSizeH2 = $(".bannerCircle").width() * 76 / 100
            var fontSizeP = $(".bannerCircle").width() * 24 / 100
            var fontSizeH3 = $(".bannerCircle").width() * 42 / 100
            var fontSizeH3mr = $(".bannerCircle").width() * 5 / 500
                //$(".bannerCircle h2").css("font-size", fontSizeH2+ "%")
        };

        function resizeBox() {
            $(".bannerCircle").each(function(index, element) {
                $(this).height($(this).width())
            });
            $(".bannerCirclebox").each(function(index, element) {
                $(this).height($(this).width())
            });
        };
        $(window).on('resize', function (e) {
            resizeBox();
            fontSize();
        });
        resizeBox();
        fontSize();
        msieversion();

        function msieversion() {
            var ua = window.navigator.userAgent;
            var msie = ua.indexOf("MSIE ");
            ((msie > 0) ? animationForIe() : animationForOtherBrowser());
            ((msie > 0) ? animationForIe2() : '');
            return false;
        };

        function animationForOtherBrowser() {
            var $bannerCircle = $('.bannerCircle');
            $bannerCircle.find(".st0").addClass("active")
            $bannerCircle.find(".no").each(function(index, element) {
                $(this).on('click', function(e) {
                    //$('.cdloader').fadeIn();
                    var thisO = $(this);
                    var indexOfNo = $(this).index();
                    var bgImg = indexOfNo + 1
                    setTimeout(function() {
                        thisO.addClass("active").siblings(".no").removeClass("active");
                        $(".zzwrp img").removeClass("active");
                        $(".zz" + (indexOfNo + 1)).addClass("active");
                        $(".data .text").removeClass("active");
                        $(".tdata" + (indexOfNo + 1)).addClass("active");
                        //$('.cdloader').fadeOut();
                    }, 1200);
                    $(".vd0, .vd1, .vd2, .vd3").removeClass("active");
                    $(".vd" + bgImg).addClass("active").siblings().removeClass("active");
                    $(".bg" + bgImg).addClass("active").siblings().removeClass("active");
                    $bannerCircle.find(".st0").removeClass("active0 active1 active2 active3").addClass("active" + indexOfNo);
                });
            });
        }

        function animationForIe2() {
            var dashoffset = 0;
            var totaldashArray = parseInt($(".bannerCirclebox .st0").css('stroke-dasharray'));
            var loadAnim = setInterval(function() {
                if ((dashoffset + 40) < 1604) {
                    dashoffset = dashoffset + 40
                    $(".bannerCirclebox .st0").css('stroke-dashoffset', dashoffset)
                } else {
                    clearInterval(loadAnim)
                }
            }, 2000)

        }

        function animationForIe() {
            var dashoffset = 0;
            var totaldashArray = parseInt($(".bannerCircle .st0").css('stroke-dasharray'));
            var loadAnim = setInterval(function() {
                if ((dashoffset + 40) < 1604) {
                    dashoffset = dashoffset + 40
                    $(".bannerCircle .st0").css('stroke-dashoffset', dashoffset)
                } else {
                    clearInterval(loadAnim)
                }
            }, 20);
            $(".no").on('click', function(e) {
                var thisO = $(this);
                var offsetStop = $(this).index() * 401;
                forwards(offsetStop, thisO)
            });

            function forwards(offsetStop, thisO, thisdata) {
                var offVal = offsetStop;
                var active = thisO
                var datatext = thisdata
                    //alert( dashoffset +" / "+ offVal +" / "+ (1604 - offVal) )
                var myAnimation = setInterval(function() {
                    if (dashoffset > (1604 - offVal)) {
                        dashoffset = dashoffset - 1
                        $(".bannerCircle .st0").css('stroke-dashoffset', dashoffset)
                    } else if (dashoffset < (1604 - offVal)) {
                        dashoffset = dashoffset + 1
                        $(".bannerCircle .st0").css('stroke-dashoffset', dashoffset)
                    } else {
                        clearInterval(myAnimation)
                        active.addClass("active").siblings(".no").removeClass("active");
                        //datatext.addClass("active").siblings(".tdata").removeClass("active");
                    }
                }, 2)
            }
        }
        $(window).on("scroll resize", function() {
            stickyFooter();
            //	limittext(".redefcontent > p");
        })
    });

    //(function ($) {
    $.fn.videoBackground = function(video, options) {
            var that = this;
            if (typeof options === 'undefined') options = {};

            //check and see if options were passed
            var settings = {};
            settings.autoplay = options.autoplay ? options.autoplay : 'autoplay';
            settings.muted = options.muted ? options.muted : 'muted';
            settings.loop = options.loop ? options.loop : 'loop';
            settings.fit = options.fit ? options.fit : 'fill';
            settings.src = options.src ? options.src : video;

            //append a video tag to the target element
            that.append($('<video>')
                .attr({ 'autoplay': settings.autoplay, 'muted': settings.muted, 'loop': settings.loop, 'src': settings.src })
                .css({ 'height': '100%', 'width': '100%', 'object-fit': settings.fit, 'overflow': 'hidden', 'position': 'absolute' }));

            //sets the initial video size
            resizeVideo(that);

            //updates the sizing as the window size changes
            $(window).on('resize', function(e) {
                that.css({ 'width': '100%', 'height': '100%' });
                resizeVideo(that);
            });

            //function to adjust the video size as needed
            function resizeVideo(vidElement) {
                vidElement.height(vidElement.height());
                vidElement.width(vidElement.width())
            }
        }
        //}(jQuery));



    function getMobileOperatingSystem() {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        // Windows Phone must come first because its UA also contains "Android"
        (/windows phone/i.test(userAgent)) ? '' : (/android/i.test(userAgent)) ? headerAnimation(): (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) ? '' : '';
        //return alert("unknown")
        headerAnimation()
    }


    function headerAnimation() {
        var lastScrollTop = 0;
        $(window).on('scroll', function (event) {
            //console.log('scroll 2')
            var st = $(this).scrollTop();
            if (st > lastScrollTop) {
                $(".headerwrp, .slidenavwrp").addClass("active")
            } else {
                $(".headerwrp, .slidenavwrp").removeClass("active")
            }
            lastScrollTop = st;
        });
    }

    function headerAnimationForMAC() {
        var lastScrollTop = 0;
        $(window).on('scroll', function (event) {
            //console.log('scroll 3')
            var st = $(this).scrollTop();
            if (st < lastScrollTop) {
                $(".headerwrp, .slidenavwrp").removeClass("active")
            } else {
                $(".headerwrp, .slidenavwrp").addClass("active")
            }
            if (lastScrollTop > -1) {
                lastScrollTop = st;
            } else {
                lastScrollTop = 0
            }
        });
    }

    function stickyFooter() {
        var $footer = $(".footerNavwrp");
        var $stickyFooter = $(".footerstikynav");
        var footerOffsetTop = $footer.offset().top;
        var viewportHeight = $(window).height();
        var scrollPosition = $(this).scrollTop() + viewportHeight - $stickyFooter.outerHeight();
        (scrollPosition >= footerOffsetTop) ? $stickyFooter.fadeOut(): $stickyFooter.stop().fadeIn();
    }

    function anlr(anmhanid) {
        $(anmhanid).stop().animate({ 'left': '10px' }, 1000).animate({ 'left': '-10px' }, 1000).stop().animate({ 'left': '10px' }, 1000).animate({ 'left': '0px' }, 1000);
    }



    $(function () {
        //	customCheckbox("confirm");
        $(".knowmore2").on('click', function () {
            $("html, body").delay(1000).animate({ scrollTop: $('.top').offset().top }, 2000);
        });

        $(window).on('scroll', function () {
            if ($(this).scrollTop() > 100) {
                $('.clickScrollTop').fadeIn();
            } else {
                $('.clickScrollTop').fadeOut();
            }
        });
        $(".clickScrollTop").on('click', function () {
            $('html,body').animate({ scrollTop: 0 }, 1000); return false;
        });
        

        $(".learnacrwrp .questionwrp").each(function(index, element) {
            $(this).on('click', function () {
                $(this).toggleClass("sel");
                $(this).siblings().removeClass("sel");
                $(this).next(".answerwrp").slideToggle(600);
                $(".answerwrp").not($(this).next()).slideUp(600)
            });
        });
        $(".GenraelPediatrics-aco").each(function(index, element) {
            if ($(this).hasClass("sel")) {
                $(this).parents().addClass('active');
                $(this).next(".GenraelPediatrics-Infotext").slideDown();
            }
            $(this).on('click', function (e) {
                $('html,body').animate({ scrollTop: $('.ospesltyaccord').offset().top - 80 });
                $(".GenraelPediatrics-aco").parents().removeClass('active');
                $(this).parents().addClass('active');
                $(this).toggleClass("sel");
                $(this).siblings().removeClass("sel");
                $(this).next(".GenraelPediatrics-Infotext").slideDown();
                $(".GenraelPediatrics-Infotext").not($(this).next()).slideUp(function() {

                })
            });
        });

        $(".rightsec").each(function(index, element) {
            $(this).append('<div class="plus hidden-lg hidden-md">Read More <span>+</span></div> <div class="minus hidden-lg hidden-md">Read More <span>-</span></div>');

            if ($(this).find("p:visible").length > 1) {
                $(this).find(".minus").css("display", "block");
                $(this).find(".plus").css("display", "none");
            } else {
                $(this).find(".minus").css("display", "none");
                $(this).find(".plus").css("display", "block");
            }

            if ($(this).find("p").length < 2) {
                $(this).find(".plus, .minus").hide();
            }
            $(".rightsec").on("click", ".plus", function() {
                $(this).hide().siblings(".minus").show();
                $(this).siblings("p").show();
            })
            $(".rightsec").on("click", ".minus", function() {
                $(this).hide().siblings(".plus").show();
                $(this).siblings("p").not("p:first").hide();
            })
        });


    });

    function setHeight(col) {
        var $col = $(col);
        var $maxHeight = 0;
        $col.each(function() {
            var $thisHeight = $(this).outerHeight();
            if ($thisHeight > $maxHeight) {
                $maxHeight = $thisHeight;
            }
        });
        $col.height($maxHeight);
    }

    function customCheckbox(checkboxName) {
        var checkBox = $('input[name="' + checkboxName + '"]');
        $(checkBox).each(function() {
            //console.log($(this).parent().hasClass('custom-checkbox'));
            if (!$(this).parent().hasClass('custom-checkbox')) {
                $(this).wrap("<span class='custom-checkbox'></span>");
                if ($(this).is(':checked')) {
                    $(this).parent().addClass("selected");
                }
            }
        });
        $(checkBox).on('click', function () {
            $(this).parent().toggleClass("selected");
            $(this).parents('li').find('span.error').remove('span.error');
        });
    }

    /* Responcive Equal Height */
    equalheight = function(container) {

        var currentTallest = 0,
            currentRowStart = 0,
            rowDivs = new Array(),
            $el,
            topPosition = 0;
        $(container).each(function() {

            $el = $(this);
            $($el).height('auto')
            topPostion = $el.position().top;

            if (currentRowStart != topPostion) {
                for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                    rowDivs[currentDiv].height(currentTallest);
                }
                rowDivs.length = 0; // empty the array
                currentRowStart = topPostion;
                currentTallest = $el.height();
                rowDivs.push($el);
            } else {
                rowDivs.push($el);
                currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
            }
            for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                rowDivs[currentDiv].height(currentTallest);
            }
        });
    }

    function resizeLiRows() {
        //equalheight('.eqhlist');
        //equalheight('.eqhlist2');
        //equalheight('.PressReleasesBox');
        //equalheight('.pressrelease-sec');
        //equalheight('.pediat-text');
        //equalheight('.contactWapper');
        //equalheight('.doctorsListwrp');
        //console.log('here');
    }

    $(function () {
        headernav('#snav', '#sprev', '#snext');
        setTimeout(function() { $(window).trigger('resize'); }, 1000);
    });

    $(window).on('resize', function () {
        resizeLiRows();
    });

    function headernav(sslide, sprev, snext) {
        var $slider = $(sslide),
            $prev = $(sprev),
            $next = $(snext),
            $slide = $slider.find('li');

        var currentSlide = 0,
            allSlides = $slider.find('li').length - 1; // index start from 0

        $slider.find('li').eq(0).addClass('active');
        if (currentSlide == 0) {
            $prev.css({ 'opacity': '.5' });
        }

        function nextSlide() {
            if (currentSlide < allSlides) {
                $slide.eq(currentSlide).removeClass('active');
                $slide.eq(currentSlide + 1).addClass('active');
                currentSlide += 1;
                $prev.css({ 'opacity': '1' });
            }
            if (currentSlide == allSlides) {
                $next.css({ 'opacity': '.5' });
            }
        }

        function prevSlide() {
            if (currentSlide > 0) {
                $slide.eq(currentSlide).removeClass('active');
                $slide.eq(currentSlide - 1).addClass('active');
                currentSlide -= 1;
                $next.css({ 'opacity': '1' });
            }
            if (currentSlide == 0) {
                $prev.css({ 'opacity': '.5' });
            }
        }
        $next.on('click', nextSlide);
        $prev.on('click', prevSlide);
    }

    function customRadio(radioName) {
        var radioButton = $('input[data-class="' + radioName + '"]');
        $(radioButton).each(function() {
            $(this).wrap("<span class='custom-radio '></span>");
            if ($(this).is(':checked')) {
                $(this).parent().addClass("selected");
            }
        });
        $(radioButton).on('click', function () {
            if ($(this).is(':checked')) {
                $(this).parent().addClass("selected");
            }
            $(radioButton).not(this).each(function() {
                $(this).parent().removeClass("selected");
            });
        });
    }
    $(function () {
        customCheckbox("confirm");
        customCheckbox2("MostHappy");
        customCheckbox2("submit_as_anonymously");
        customCheckbox2("TandCApprove");
        $('li[data-class="quizqst"]').each(function(index, element) {
            //alert('its working');
            var qzlenth = index + 1;
            //console.log(index+1);
            customRadio("question" + qzlenth);
        });
        customRadio("fcategory");
        customRadio("recommend");

        // for(var i = 1; i<=6; i++){
        //  customRadio("question"+i);
        // }
        //customRadio("question2");
    });
}

function initAboutTabs() {
    var $body = $('body');
    var $atabs = $('body').find('.atabs');
    var $atabscontent = $('body').find('.atabscontent');
    $atabs.removeClass("slick-initialized").removeClass("slick-slider");
    $atabscontent.removeClass("slick-initialized").removeClass("slick-slider");

    $atabscontent.not('.slick-initialized').slick({
        slide: '.atabsdata',
        adaptiveHeight: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: false,
        asNavFor: '.atabs',
        infinite: false,  
        draggable: false,
        swipe: false,
        touchMove: false      
    });
    $atabs.not('.slick-initialized').slick({
        slide: 'li',
        adaptiveHeight: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow: '<a class="slick-prev"><img src="assets/images/prv-arrow2.png" alt="" /></a>',
        nextArrow: '<a class="slick-next"><img src="assets/images/nxt-arrow2.png" alt="" /></a>',
        asNavFor: '.atabscontent',
        dots: false,
        centerMode: false,
        focusOnSelect: true,
        arrows: true,
        infinite: false,
        responsive: [{
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    $atabs.find('a').hasClass('slick-arrow') ? $atabs.addClass('acarrow') : $atabs.removeClass('acarrow');
    $(window).on('resize', function () {
        $atabs.find('a').hasClass('slick-arrow') ? $atabs.addClass('acarrow') : $atabs.removeClass('acarrow');
    });
    $("[data-class='DoctorDesk-aco']").each(function(index, element) {
        $(this).hasClass("sel") ? $(this).next(".infotext").slideDown() : '';
    });
    $("[data-class='DoctorDesk-aco']").each(function(index, element) {
        $(this).on('click', function() {
            $(this).toggleClass("sel");
            $(this).siblings().removeClass("sel");
            $(this).next(".infotext").slideDown();
            $(this).parents('.aco-wapper').find(".infotext").not($(this).next()).slideUp()
        });
    });
}

function intEqualheight() {

    equalHeight('.ddetailswrp');

    $(window).on('resize', function () {
        equalHeight('.ddetailswrp');
    });

    function equalHeight(container) {
        console.log('container:', container);
        var currentTallest = 0,
            currentRowStart = 0,
            rowDivs = new Array(),
            $el,
            topPosition = 0;
        $(container).each(function() {
            console.log('each container:', container)
            $el = $(this);
            $($el).height('auto')
            topPostion = $el.position().top;

            if (currentRowStart != topPostion) {
                for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                    rowDivs[currentDiv].height(currentTallest);
                }
                rowDivs.length = 0; // empty the array
                currentRowStart = topPostion;
                currentTallest = $el.height();
                rowDivs.push($el);
            } else {
                rowDivs.push($el);
                currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
            }
            for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                rowDivs[currentDiv].height(currentTallest);
            }
        });
    }
}



function initCustomSelect() {
    $(".select option").each(function(index, element) {
        $(this).val($(this).val());
    });
    $(".select").each(function(index, element) {
        var thisVal = $('option:selected', this).text();
        $(this).siblings(".select-span").text(thisVal);
        $(this).on("change", function() {
            var thisVal = $('option:selected', this).text();
            $(this).siblings(".select-span").text(thisVal);
            $(this).parents('.selectbox').find('span.error').remove('span.error');
        });
    });
}
// function initHomeSpecialities() {
// 	$('.oursslider .sitem::before').animate({ 'left': '10px' }, 1000).animate({ 'left': '-10px' }, 1000);
// }

/**
 * ewebcore (ep-1)
 */
function initquizaq(qzid, qzprev, qznext) {
    //console.log(qzid, qzprev, qznext);

    $('#quizform > li').each(function(index, element) {

        var radioButton = $('input[type="radio"]');
        var checkParent = radioButton.parent().hasClass('custom-radio');
        if (!checkParent) {
            $(radioButton).each(function() {
                $(this).wrap("<span class='custom-radio'></span>");
                if ($(this).is(':checked')) {
                    $(this).parent().addClass("selected");
                }
            });
        }

        $(radioButton).on('click', function () {
            if ($(this).is(':checked')) {
                $(this).parent().addClass("selected");
            }
            $(radioButton).not(this).each(function() {
                $(this).parent().removeClass("selected");
            });
        });
    });
    $(qzid).each(function(e) {
        if (e != 0)
            $(this).slideUp();
    });
    $(qzid + ":first").addClass('active');
    $(qznext).on('click', function() {
        //alert('working');
        $('#quizform > li').each(function(index, element) {
            if ($(this).hasClass("active")) {
                if ($(this).find('.custom-radio').hasClass('selected')) {
                    if ($(qzid + ":visible").next().length != 0) {
                        $(qzid + ":visible").next().slideDown().addClass('active').prev().slideUp().removeClass('active');

                        if ($(qzid + ":visible").next().length == 0) {
                            $(qznext, qzprev).slideUp().removeClass('active');
                            var divs = $(qzid + ":visible").prevAll().clone();
                            divs.slideDown().removeClass('active');
                            var divs = jQuery.makeArray(divs);
                            divs.reverse();
                        }
                        if ($(qzid + ":last").hasClass("active")) {
                            $(qzprev).hide();
                            $(qznext).hide();
                        }
                    }
                    return false;
                }
            }
        });
    });

    $(qzprev).on('click', function () {
        if ($(qzid + ":visible").prev().length != 0) {
            //console.log("There are still elements");
            $(qzid + ":visible").prev().slideDown().addClass('active').next().slideUp().removeClass('active');
        } else {
            //$(qzid+":visible").hide();
            //$(qzid+":last").show();
        }
        return false;
    });
}

function initRemoveClass() {
    $(function() {
        $('.custom-radio').removeClass('selected');
        $('.custom-checkbox').removeClass('selected');
    });
}

function initToggle() {
    $("[data-class='GenraelPediatrics-aco']").each(function(index, element) {
        // console.log('its working');
        if ($(this).hasClass("sel")) {
            $(this).parents().addClass('active');
            $(this).next("[data-class='GenraelPediatrics-Infotext']").slideDown();
        }
        $(this).on('click', function(e) {
            // alert('daar');
            $('html,body').animate({ scrollTop: $("[data-class='ospesltyaccord']").offset().top - 80 });
            $("[data-class='GenraelPediatrics-aco']").parents().removeClass('active');
            $(this).parents().addClass('active');
            $(this).toggleClass("sel");
            $(this).siblings().removeClass("sel");
            $(this).next("[data-class='GenraelPediatrics-Infotext']").slideDown();
            $("[data-class='GenraelPediatrics-Infotext']").not($(this).next()).slideUp(function() {

            })

        });
    });
    $("[data-class='DoctorDesk-aco']").each(function(index, element) {
        if ($(this).hasClass("sel")) {
            $(this).next(".infotext").slideDown();
        }
    });
    $("[data-class='DoctorDesk-aco']").each(function (index, element) {
        $(this).on('click', function () {
            console.log('hello')
            $(this).toggleClass("sel");
            $(this).siblings().removeClass("sel");
            $(this).next(".infotext").slideDown();
            $(this).parents('.aco-wapper').find(".infotext").not($(this).next()).slideUp()
        });
    });
}

function initPopup(id) {
    $('[data-popup="popup-' + id + '"]').delay(200).fadeIn(350);
}

function customCheckbox2(checkboxName) {
    var checkBox = $('input[data-class="' + checkboxName + '"]');
    $(checkBox).each(function() {
        $(this).wrap("<span class='custom-checkbox'></span>");
        if ($(this).is(':checked')) {
            $(this).parent().addClass("selected");
        }
    });
    $(checkBox).on('click', function() {
        $(this).parent().toggleClass("selected");
        $(this).parents('li').find('span.error').remove('span.error');
    });
}
/**
 * ewebcore (ep-1)
 */

function initQuestionAccordion() {
    $(".aco-wapper .DoctorDesk-aco").each(function(index, element) {
        if ($(this).hasClass("sel")) {
            $(this).next(".infotext").slideDown();
        }
        $(this).on('click', function() {
            $(this).toggleClass("sel");
            $(this).siblings().removeClass("sel");
            $(this).next(".infotext").slideDown();
            $(this).parents('.aco-wapper').find(".infotext").not($(this).next()).slideUp()
        });
    });
}
