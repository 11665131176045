import { FB_URL, YOUTUBE_URL, TWITTER_URL } from './../services/constants';
import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL } from '../services/constants';
import { ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import {
  ReactiveFormsModule,
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
} from '@angular/forms';

declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
declare let CacStopWatch: any;
declare let initPopup: any;
declare var window: any;
declare var FB: any;
declare const gapi: any;

export function comparePassword(group: FormGroup) {
  const pass = group.value.passGroup;
  return pass.userRegPassword === pass.userRegConfirmPassword
    ? null
    : {
        invalid: true,
      };
}

@Component({
  selector: 'bmi-calculator',
  templateUrl: './bmi.component.html',
  styles: [
    `
      .has-error {
        border: 1px solid red !important;
      }
      input[disabled] {
        border: 1px solid rgba(61, 41, 121, 0.01) !important;
        background: rgba(61, 41, 121, 0.5) !important;
        color: #fff !important;
      }
      .errorMsg {
        color: red;
        font-size: 13px;
        padding-left: 10px;
      }
      .calculatorwrp .caawrp .formbox .formcasn.signForm li {
        margin-bottom: 0px !important;
      }
      .signLinkColor,
      .signLinkColor a {
        color: #3b2a7a !important;
        font-size: 17px !important;
        font-family: 'dinrg';
        font-weight: normal;
      }
      .calculatorwrp .calcSignIn ul li .infoEmail {
        width: 100%;
        color: #3b2a7a !important;
        font-size: 15px !important;
        font-family: 'dinrg';
        font-weight: normal;
        padding: 0px 10px 8px;
        width: 100%;
        word-break: break-word;
        display: inline-block;
        border-bottom: 1px solid #ddd;
      }
      .calculatorwrp .calcSignIn ul li a {
        width: 100%;
      }
      .calculatorwrp .calcSignIn ul li:hover .infoEmail {
        color: #fff !important;
      }
      .calculatorwrp .calcSignIn {
        width: 260px !important;
        right: -201px !important;
      }
      .calculatorwrp .calcSignIn::before {
        top: -13px !important;
        left: 25px !important;
        right: auto !important;
      }
    `,
  ],
})
export class BmiComponent implements OnDestroy, OnInit {
  //doctor detail
  public userInfoEmail: any = '';
  public logo = 'logo';
  public ageArray = new Array(51);

  public AuCalcWT: string = '';
  public AuCalcBmiRst: any = '';
  public userWeight: any = '';
  public userHeight: any = '';
  public currentActiveUser: any = [];
  /*******************************/
  public bread_crumb_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Calculator', link: '', class: '' },
    { label: 'BMI Calculator', link: '', class: '' },
  ];

  public links: any = [
    { label: 'About Child Care', link: '/child-care-about', class: '' },
    { label: 'Doctors', link: '/child-care/doctors', class: '' },
    { label: 'Our Specialities', link: '/child-care/specialities', class: '' },
    { label: 'Our Centres', link: '/child-care/centres', class: '' },
    { label: 'Vaccination', link: '/child-care/vaccination', class: '' },
    { label: 'Testimonials', link: '/child-care/testimonials', class: '' },
    { label: 'Share Feedback', link: '/child-care/feedback', class: 'active' },
    {
      label: 'Book An Appointment',
      link: '/child-care/book-an-appointment',
      class: '',
    },
  ];
  //form
  public userLogin: any = [];
  public userForgotPassword: any = [];
  public userRegisterForm: any = [];
  public userBmiForm: any = [];

  public hidden = 'hidden';
  public activeEmail: string = '';
  public activeUserDetail: any = [];
  public userType: string = '';
  public loggedInUser: boolean = false;
  public calcCAccountShow: string = 'calcCAccount';
  public AuResultShow: string = '';
  public calcnavLi = $('.calcnav > li');

  public popupTitle: string = '';
  public popupMsg: string = '';
  public forgotTitle: string = '';
  public forgotMsg: string = '';
  public pageInfo: any = {};
  fb_url: string = FB_URL;
  youtube_url: string = YOUTUBE_URL;
  twitter_url: string = TWITTER_URL;

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    public formBuilder: FormBuilder,
    public metaTitle: Title,
    public meta: Meta
  ) {
    if (this.api.isBrowser) {
      // some of these will be required in near future
        window['jQuery'] = $;
        window['$'] = $;
        require('../../assets/js/bootstrap.js');
        require('../../assets/js/fancybox/jquery.fancybox.js');
        require('../../assets/js/slick.min.js');
        require('../../assets/js/datepickr.min.js');
        require('../../assets/js/gApiPlatform.js');
    }
  }

  public now: Date = new Date();
  public facebookLoggedInUser: boolean = false;
  public googleLoggedInUser: boolean = false;
  public ApiUsersignOut: boolean = true;

  faceBookLogin() {
    window.fbAsyncInit = function () {
      FB.init({
        appId: '219494271415489',
        cookie: true,
        xfbml: true,
        version: 'v2.8',
      });
      //FB.AppEvents.logPageView();
    };
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }
  FBLogin() {
    var thisO = this;
    FB.login(
      function (response) {
        //var token = response.authResponse.accessToken;
        //var uid = response.authResponse.userID;
        if (response.authResponse) {
          FB.api(
            '/me',
            { fields: 'id,name,about,birthday,email' },
            function (response) {
              let loginDetail = {
                email: response.email,
              };

              thisO.api.fbgmlogin(loginDetail).subscribe((res) => {
                /* Start FB Login */
                if (res.status_code == '200') {
                  if (res.message.error_message == 'success') {
                    $('.CalcLoader').css('display', 'block');
                    setTimeout(() => {
                      $('.CalcLoader').css('display', 'none');
                      if (thisO.calcnavLi.hasClass('active')) {
                        thisO.calcnavLi.removeClass('active');
                      }
                      $('.CalcBmi')
                        .addClass('active')
                        .find('a')
                        .trigger('click');
                    }, 1000);
                    thisO.userInfoEmail = response.email;
                    thisO.calcCAccountShow = '';
                    localStorage.removeItem('rainbowUser');
                    let gooleLoginDetail = {
                      email: response.email,
                      userType: 'Facebook Login',
                      createdAt: Date.now(),
                      updatedAt: Date.now(),
                    };
                    localStorage.setItem(
                      'rainbowUser',
                      JSON.stringify(gooleLoginDetail)
                    );
                    thisO.loggedInUser = true;
                    thisO.facebookLoggedInUser = true;
                    thisO.ApiUsersignOut = false;
                    thisO.googleLoggedInUser = false;
                  } else if (res.message.error_message == 'newuser') {
                    //console.log('else if res.message.error_message',res.message.error_message);
                    var age = Math.floor(20 + Math.random() * 30);
                    var weight = Math.floor(40 + Math.random() * 48);
                    let regDetail = {
                      user_name: response.name,
                      email: response.email,
                      password: 'Facebook@123',
                      mobile: '',
                      birth_date: '',
                      age: age,
                      weight: weight,
                      user_type: 'Facebook Login',
                    };

                    thisO.api.fbregistration(regDetail).subscribe((res) => {
                      if (res.status_code == 200) {
                        if (res.message.error_message == 'success') {
                          thisO.popupTitle = 'Thank You! ';
                          thisO.popupMsg = 'You are successfully logged in.';

                          $('.CalcLoader').css('display', 'block');
                          setTimeout(() => {
                            $('.CalcLoader').css('display', 'none');
                            initPopup(10);
                            if (thisO.calcnavLi.hasClass('active')) {
                              thisO.calcnavLi.removeClass('active');
                            }
                            $('.CalcBmi')
                              .addClass('active')
                              .find('a')
                              .trigger('click');
                          }, 1000);
                          thisO.userInfoEmail = response.email;
                          thisO.calcCAccountShow = '';
                          localStorage.removeItem('rainbowUser');
                          let gooleLoginDetail = {
                            email: response.email,
                            userType: 'Facebook Login',
                            createdAt: Date.now(),
                            updatedAt: Date.now(),
                          };
                          localStorage.setItem(
                            'rainbowUser',
                            JSON.stringify(gooleLoginDetail)
                          );
                          thisO.loggedInUser = true;
                          thisO.facebookLoggedInUser = true;
                          thisO.ApiUsersignOut = false;
                          thisO.googleLoggedInUser = false;
                        }
                      } else {
                        alert('invalid');
                      }
                    });
                  } else if (res.message.error_message == 'emailverify') {
                    console.log('Email Verify', 'Please verify your email id');
                  }
                }
                /* End FB Login */
              });
            }
          );

          FB.getLoginStatus(function (response) {
            if (response.status === 'connected') {
              //var uid = response.authResponse.userID;
              //var accessToken = response.authResponse.accessToken;
            } else if (response.status === 'not_authorized') {
              //console.log('The user hasn`t authorized your application.');
            } else {
              //console.log('The user isn`t logged in to Facebook. You can launch a');
            }
          });
        } else {
          console.log('User cancelled login or did not fully authorize.');
        }
      },
      {
        scope: 'email,user_birthday',
        return_scopes: true,
      }
    );
  }
  logoutFacebook() {
    FB.logout((response) => {
      document.cookie =
        'fblo_' +
        response.authResponse.userID +
        '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      document.cookie.split(';').forEach(function (c) {
        document.cookie = c
          .replace(/^ +/, '')
          .replace(
            /=.*/,
            '=;expires=' +
              new Date().toUTCString() +
              ';domain=rainbowhospitals.in;path=/'
          );
      });
      this.facebookLoggedInUser = false;
      this.loggedInUser = false;
    });

    localStorage.removeItem('rainbowUser');
    this.loggedInUser = false;
    this.calcCAccountShow = 'calcLogn';
    $('.CalcLoader').css('display', 'block');
    setTimeout(() => {
      $('.CalcLoader').css('display', 'none');
      $('.calcnav li').removeClass('active');
      $('.CalcSignIn').addClass('active').find('a.SignIn').trigger('click');
    }, 1000);
  }
  public auth2: any;
  public googleInit() {
    gapi.load('auth2', () => {
      this.auth2 = gapi.auth2.init({
        client_id:
          '1060968979005-bq6vb96dfoc4m4s325r96bv50752su56.apps.googleusercontent.com',
        cookiepolicy: 'single_host_origin',
        scope: 'profile email',
      });
      this.attachSignin(document.getElementById('googleBtn'));
    });
  }
  public attachSignin(element) {
    this.auth2.attachClickHandler(
      element,
      {},
      (googleUser) => {
        let profile = googleUser.getBasicProfile();
        if (profile.getEmail() != null) {
          let loginDetail = {
            email: profile.getEmail(),
          };

          this.api.fbgmlogin(loginDetail).subscribe((res) => {
            if (res.status_code == '200') {
              if (res.message.error_message == 'success') {
                $('.CalcLoader').css('display', 'block');
                setTimeout(() => {
                  $('.CalcLoader').css('display', 'none');
                  if (this.calcnavLi.hasClass('active')) {
                    this.calcnavLi.removeClass('active');
                  }
                  $('.CalcBmi').addClass('active').find('a').trigger('click');
                }, 1000);
                this.userInfoEmail = profile.getEmail();
                this.calcCAccountShow = '';
                localStorage.removeItem('rainbowUser');
                let gooleLoginDetail = {
                  email: profile.getEmail(),
                  userType: 'Google Login',
                  createdAt: Date.now(),
                  updatedAt: Date.now(),
                };
                localStorage.setItem(
                  'rainbowUser',
                  JSON.stringify(gooleLoginDetail)
                );
                this.loggedInUser = true;
                this.googleLoggedInUser = true;
                this.ApiUsersignOut = false;
                this.facebookLoggedInUser = false;
              } else if (res.message.error_message == 'newuser') {
                //console.log('else if res.message.error_message',res.message.error_message);
                var age = Math.floor(20 + Math.random() * 30);
                var weight = Math.floor(40 + Math.random() * 48);
                let regDetail = {
                  user_name: profile.getName(),
                  email: profile.getEmail(),
                  password: 'Google@123',
                  mobile: '',
                  birth_date: '',
                  age: age,
                  weight: weight,
                  user_type: 'Google Login',
                };

                this.api.fbregistration(regDetail).subscribe((res) => {
                  if (res.status_code == 200) {
                    if (res.message.error_message == 'success') {
                      this.popupTitle = 'Thank You! ';
                      this.popupMsg = 'You are successfully logged in.';

                      $('.CalcLoader').css('display', 'block');
                      setTimeout(() => {
                        $('.CalcLoader').css('display', 'none');
                        initPopup(10);
                        if (this.calcnavLi.hasClass('active')) {
                          this.calcnavLi.removeClass('active');
                        }
                        $('.CalcBmi')
                          .addClass('active')
                          .find('a')
                          .trigger('click');
                      }, 1000);
                      this.userInfoEmail = profile.getEmail();
                      this.calcCAccountShow = '';
                      localStorage.removeItem('rainbowUser');
                      let gooleLoginDetail = {
                        email: profile.getEmail(),
                        userType: 'Google Login',
                        createdAt: Date.now(),
                        updatedAt: Date.now(),
                      };
                      localStorage.setItem(
                        'rainbowUser',
                        JSON.stringify(gooleLoginDetail)
                      );
                      this.loggedInUser = true;
                      this.googleLoggedInUser = true;
                      this.ApiUsersignOut = false;
                      this.facebookLoggedInUser = false;
                    }
                  } else {
                    alert('invalid');
                  }
                });
              } else if (res.message.error_message == 'emailverify') {
                console.log('Email Verify', 'Please verify your email id');
              }
            }
          });
        } else {
          alert('invalid login');
        }
        /*
			console.log('Google Profile',profile);
			console.log('Token || ' + googleUser.getAuthResponse().id_token);
			console.log('ID: ' + profile.getId());
			console.log('Name: ' + profile.getName());
			console.log('Image URL: ' + profile.getImageUrl());
			console.log('Email: ' + profile.getEmail());
			*/
      },
      (error) => {
        alert(JSON.stringify(error, undefined, 2));
      }
    );
  }
  GoogleSignOut() {
    var auth2 = gapi.auth2.getAuthInstance();
    auth2.signOut().then(function () {
      console.log('User signed out.');
      auth2.disconnect();
    });
    this.loggedInUser = false;
    this.googleLoggedInUser = false;
    localStorage.removeItem('rainbowUser');
    this.calcCAccountShow = 'calcLogn';
    $('.CalcLoader').css('display', 'block');
    setTimeout(() => {
      $('.CalcLoader').css('display', 'none');
      $('.calcnav li').removeClass('active');
      $('.CalcSignIn').addClass('active').find('a.SignIn').trigger('click');
    }, 1000);
  }
  LoginStatus() {
    this.calcCAccountShow = 'calcLogn';
    this.loggedInUser = false;
    this.googleLoggedInUser = false;
    this.ApiUsersignOut = false;
    this.facebookLoggedInUser = false;
    $('.CalcSignIn').addClass('active').find('a.SignIn').trigger('click');
    localStorage.removeItem('rainbowUser');
  }

  ngOnInit() {
    this.validateUserLogin();
    this.validateUserForgotpassword();
    this.validateUserRegister();
    this.validateuserbmiform();
    this.getMetaInfo();
    this.faceBookLogin();
    this.googleInit();
    this.LoginStatus();
  }
  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
      CacStopWatch();
    }

    var currentUser = JSON.parse(localStorage.getItem('rainbowUser'));
    //console.log('currentUser',this.loggedInUser);
    if (currentUser) {
      this.activeEmail = currentUser.email;
      this.userType = currentUser.userType;
      this.activeUserDetail = currentUser;
      this.loggedInUser = true;
      this.calcCAccountShow = '';
    }
  }
  getMetaInfo() {
    this.api.getPageDescription('bmi-calculator').subscribe(
      (data) => {
        if (data) {
          this.pageInfo.title = data.page_descriptions[0].title;
          this.pageInfo.subtitle = data.page_descriptions[0].subtitle;
          this.pageInfo.description = data.page_descriptions[0].description;

          //console.log(data);
          this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
          this.meta.updateTag({
            name: 'keywords',
            content: data.page_descriptions[0].meta_keyword,
          });
          this.meta.updateTag({
            name: 'description',
            content: data.page_descriptions[0].meta_description,
          });
          this.meta.addTags([
            {
              property: 'og:title',
              content: data.page_descriptions[0].meta_title,
            },
            {
              property: 'og:description',
              content: data.page_descriptions[0].meta_description,
            },
            {
              property: 'og:keywords',
              content: data.page_descriptions[0].meta_keyword,
            },
          ]);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  //validated user login form
  validateUserLogin() {
    this.userLogin = this.formBuilder.group({
      userEmailId: ['', Validators.required],
      userPassword: ['', Validators.required],
    });
  }
  //validate user forgot password
  validateUserForgotpassword() {
    this.userForgotPassword = this.formBuilder.group({
      userEmailId: ['', Validators.required],
    });
  }
  //validate user register form
  validateUserRegister() {
    this.userRegisterForm = this.formBuilder.group(
      {
        userName: ['', Validators.required],
        userEmailId: ['', Validators.required],
        userMobile: ['', Validators.required],
        passGroup: this.formBuilder.group({
          userRegPassword: ['', Validators.required],
          userRegConfirmPassword: ['', Validators.required],
        }),
        userDOB: [''],
        userAge: ['Refer by Doctor'],
        userWeight: [''],
        confirm: ['', Validators.required],
      }
      // { validator: comparePassword }
    );
  }
  initPassGroup() {
    return this.formBuilder.group({
      userRegPassword: ['', Validators.required],
      userRegConfirmPassword: ['', Validators.required],
    });
  }
  //validate user bmi form
  validateuserbmiform() {
    this.userBmiForm = this.formBuilder.group({
      height: ['', Validators.required],
      weight: ['', Validators.required],
      age: ['Select Your Age'],
    });
  }
  onDateChange(event) {
    //console.log(event.target.value);
    if (event.target.value) {
      this.userRegisterForm.controls['userDOB'].setValue(event.target.value);
    }
  }
  /**
   * Submit User Login
   */
  submitLogin() {
    if (this.userLogin) {
      //console.log(this.userLogin.value);
      let loginDetail = {
        email: this.userLogin.value['userEmailId'],
        password: this.userLogin.value['userPassword'],
      };
      this.api.postLogin(loginDetail).subscribe(
        (res) => {
          //console.log(res.status);
          if (res.status_code == 200) {
            //let calcnavLi = $('.calcnav li');
            $('.CalcLoader').css('display', 'block');
            setTimeout(() => {
              $('.CalcLoader').css('display', 'none');
              if (this.calcnavLi.hasClass('active')) {
                this.calcnavLi.removeClass('active');
              }
              $('.CalcBmi').addClass('active').find('a').trigger('click');
            }, 1000);

            this.calcCAccountShow = '';
            localStorage.removeItem('rainbowUser');
            let guestLoginDetail = {
              email: res.email,
              userType: 'login',
              createdAt: Date.now(),
              updatedAt: Date.now(),
            };
            localStorage.setItem(
              'rainbowUser',
              JSON.stringify(guestLoginDetail)
            );
            this.loggedInUser = true;
            this.ApiUsersignOut = true;
            this.facebookLoggedInUser = false;
            this.googleLoggedInUser = false;
          } else {
            alert('invalid login');
          }
        },
        (err) => {
          ///console.log(err.status);
          if (err.status == 406) {
            //alert('invalid login')
            $('[name="userPassword"],[name="userEmailId"]').css(
              'border',
              '1px solid red'
            );
          } else if (err.status == 401) {
            $('[name="userPassword"]').css('border', '1px solid red');
          } else if (err.status == 404) {
            $('[name="userPassword"],[name="userEmailId"]').css(
              'border',
              '1px solid red'
            );
          }
        }
      );
    }
  }
  /***
   * Submit User Forgot Password detail
   */
  submitForgotpassword() {
    if (this.userForgotPassword) {
      //console.log(this.userForgotPassword.value);
      let forgotPasswordEmail = {
        email: this.userForgotPassword.value['userEmailId'],
      };

      this.api.postForgotpassword(forgotPasswordEmail).subscribe(
        (res) => {
          if (res.status_code == 200) {
            //console.log();
            let msg = res.message.sucess[0];
            this.forgotTitle = 'Thank you';
            this.forgotMsg = msg;
            initPopup('forgotpopup');
            setTimeout(() => {
              $('.calcFPass').hide();
              $('.calcLogn').show();
            }, 1000);
          }
        },
        (err) => {
          //console.log(res.status_code);
          if (err.status == 404) {
            this.forgotTitle = 'Error';
            this.forgotMsg = 'Email address not exist!';
          }
          if (err.status == 406) {
            this.forgotTitle = 'Error';
            this.forgotMsg = 'Please enter valid email address';
          }
          initPopup('forgotpopup');
        }
      );
    }
  }
  /***
   * submit user register detail
   */
  submitUserRegister() {
    if (this.userRegisterForm) {
      $('.errorMsg').removeAttr('style').text('');
      $(
        '[name="userPassword"],[name="userEmailId"],[name="userAge"],[name="userMobile"],[name="userWeight"]'
      ).removeClass('has-error');
      //console.log();
      let regDetail = {
        user_name: this.userRegisterForm.value['userName'],
        email: this.userRegisterForm.value['userEmailId'],
        password: this.userRegisterForm.get('passGroup.userRegPassword').value,
        mobile: this.userRegisterForm.value['userMobile'],
        birth_date: this.userRegisterForm.value['userDOB'],
        age: this.userRegisterForm.value['userAge'],
        weight: this.userRegisterForm.value['userWeight'],
      };
      this.api.postRegister(regDetail).subscribe(
        (res) => {
          //console.log(res);
          if (res.status_code == 200) {
            $('.CalcLoader').css('display', 'block');
            this.popupTitle = 'Success';
            this.popupMsg = 'Your account created successfully!';
            setTimeout(() => {
              $('.CalcLoader').css('display', 'none');

              initPopup(10);
            }, 1000);
            //alert('Please confirm your email address');
            this.userRegisterForm.reset();
            this.userRegisterForm.reset();
          } else {
            alert('invalid');
          }
        },
        (err) => {
          //console.log(err._body);
          var error = err;

          if (err.status == 406) {
            if (error.message.name && error.message.name[0]) {
              $('[name="userName"]').addClass('has-error');
              $('.nameErrorMsg')
                .addClass('errorMsg')
                .text(error.message.name[0]);
            }
            if (error.message.email && error.message.email[0]) {
              $('[name="userEmailId"]').addClass('has-error');
              $('.emailErrorMsg')
                .addClass('errorMsg')
                .text(error.message.email[0]);
            }
            if (error.message.mobile && error.message.mobile[0]) {
              $('[name="userMobile"]').addClass('has-error');
              $('.mobileErrorMsg')
                .addClass('errorMsg')
                .text(error.message.mobile[0]);
            }
            if (error.message.weight && error.message.weight[0]) {
              $('[name="userWeight"]').addClass('has-error');
              $('.userWeight')
                .addClass('errorMsg')
                .text(error.message.weight[0]);
            }
            //alert('invalid login')
          } else if (err.status == 401) {
            $('[name="userPassword"]').addClass('has-error');
          } else if (err.status == 404) {
            $('[name="userPassword"]').addClass('has-error');
          }
        }
      );
    }
  }
  userLogout() {
    localStorage.removeItem('rainbowUser');
    this.loggedInUser = false;
    this.calcCAccountShow = 'calcLogn';
    $('.CalcLoader').css('display', 'block');
    setTimeout(() => {
      $('.CalcLoader').css('display', 'none');
      $('.calcnav li').removeClass('active');
      $('.CalcSignIn').addClass('active').find('a.SignIn').trigger('click');
    }, 1000);
  }
  /***
   *
   */
  submitBmiDetail() {
    //console.log(this.userBmiForm.value);
    if (
      this.userBmiForm.value &&
      this.userBmiForm.value['height'] != 0 &&
      this.userBmiForm.value['weight'] != 0
    ) {
      let result =
        (this.userBmiForm.value['weight'] /
          (this.userBmiForm.value['height'] *
            this.userBmiForm.value['height'])) *
        10000;
      var ckCalcTb: any = result.toFixed(1);

      this.AuCalcWT =
        ckCalcTb < 18.5
          ? 'Underweight'
          : ckCalcTb > 18.5 == ckCalcTb < 24.99
          ? 'Normal weight'
          : ckCalcTb > 25 == ckCalcTb < 29.99
          ? 'Overweight'
          : ckCalcTb > 30 == ckCalcTb < 34.99
          ? 'Obese Class I'
          : ckCalcTb > 35 == ckCalcTb < 39.99
          ? 'Obese Class II'
          : ckCalcTb > 40
          ? 'Obese Class III'
          : '';

      //console.log(this.AuCalcWT,ckCalcTb);
      // Guest login
      this.AuCalcBmiRst = result.toFixed(2);
      this.AuResultShow = 'AuResult';

      if (!this.activeEmail) {
        let randomBetween0to9999999 = Math.floor(Math.random() * 9999999);
        let guestEmail = `guest${randomBetween0to9999999}@rainbowhospital.in`;
        let guestLoginDetail = {
          email: guestEmail,
          userType: 'guest',
          bmiRate: this.AuCalcBmiRst,
          bodyType: this.AuCalcWT,
          height: this.userBmiForm.value['height'],
          weight: this.userBmiForm.value['weight'],
          age: this.userBmiForm.value['age'],
          createdAt: Date.now(),
          updatedAt: Date.now(),
        };
        localStorage.setItem('rainbowUser', JSON.stringify(guestLoginDetail));
      } else {
        if (this.userType == 'login') {
          localStorage.removeItem('rainbowUser');
          let guestLoginDetail = {
            email: this.activeEmail,
            userType: 'login',
            bmiRate: this.AuCalcBmiRst,
            bodyType: this.AuCalcWT,
            height: this.userBmiForm.value['height'],
            weight: this.userBmiForm.value['weight'],
            age: this.userBmiForm.value['age'],
            createdAt: this.activeUserDetail.createdAt,
            updatedAt: Date.now(),
          };
          localStorage.setItem('rainbowUser', JSON.stringify(guestLoginDetail));
        } else {
          localStorage.removeItem('rainbowUser');
          this.activeEmail = this.activeUserDetail.email;
          let guestLoginDetail = {
            email: this.activeUserDetail.email,
            userType: 'guest',
            bmiRate: this.AuCalcBmiRst,
            bodyType: this.AuCalcWT,
            height: this.userBmiForm.value['height'],
            weight: this.userBmiForm.value['weight'],
            age: this.userBmiForm.value['age'],
            createdAt: this.activeUserDetail.createdAt,
            updatedAt: Date.now(),
          };
          localStorage.setItem('rainbowUser', JSON.stringify(guestLoginDetail));
        }
      }
      let postDetail = {
        email: this.activeEmail,
        height: this.userBmiForm.value['height'],
        weight: this.userBmiForm.value['weight'],
        age: this.userBmiForm.value['age'],
        weight_type: this.AuCalcWT,
        bmi_rate: this.AuCalcBmiRst,
      };
      this.api.postBMIDetail(postDetail).subscribe(
        (res) => {
          this.userBmiForm.reset();
          // if (res.status == 200) {
          console.log('bmi rated added');
          // }
        },
        (err) => {
          //console.log(err);
        }
      );
      $('.CalcBmi').removeClass('active');
      $('.CalcLoader').fadeIn();
      $('.LastContraction').addClass('active');
      var AuResult = setInterval(function () {
        $('.calctab').hide();
        $('.AuResult').show();
        $('.CalcLoader').fadeOut();

        $('.LastContraction').addClass('active');
        if (ckCalcTb < 18.5) {
          $('.CalcAlarm a').trigger('click');
        }
        clearInterval(AuResult);
      }, 1000);
    }
    //console.log(result.toFixed(1));
  }
  isNumber(evt) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  isAlpha(evt) {
    evt = evt ? evt : window.event;
    var keyunicode = evt.charCode || evt.keyCode;
    return (keyunicode >= 65 && keyunicode <= 122) ||
      keyunicode == 8 ||
      keyunicode == 32
      ? true
      : false;
  }
  clearnInput() {
    $('input[type="text"]').val('');
  }
  ngOnDestroy() {}
}
