<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>

    <section class="page wtwedo">
        <div class="row">
            <div class="col-md-5 col-xs-5 col-lg-4">
                <div class="circuleframe">
                    <img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" />
                </div>
                <!-- End Circle -->
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7">
                <div class="pagetitle">
                    <h1 class="tgradient">{{title}} </h1>
                    <div class="underline">
                        <img src="assets/images/underline2.png" alt="" />
                    </div>
                    <h3>{{subtitle}}</h3>
                </div>
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7 mcontentview">
                <p [innerHTML]="description"></p>
            </div>
        </div>

    </section>
    <section class="aboutsectin2 awardswapper">
        <div class="container">
            <div class="row nsg_text">
                <div class="col-xs-12 col-lg-12">
                    <h2>Factors influencing recurrent miscarriage</h2>
                    <p><strong><em>Age:</em></strong> The older a woman gets, the greater are her risks of having a miscarriage.</p>
                    <p><strong><em>Past pregnancies:</em></strong>Women who have already had miscarriages earlier are more prone to have another one.</p>
                    <p><strong><em>Abnormalities in the embryo: </em></strong>A fertilized egg is referred to as the embryo. Abnormalities in the embryo are a very common reason for a miscarriage. However, this generally is not the cause of a recurrent miscarriage.</p>
                    <p><strong><em>Genetic factors:</em></strong>It has been found that some women or their partner have an abnormality on one of their chromosomes that sometimes cause problems if passed on to the baby.</p>
                    <p><strong><em>Autoimmune factors: </em></strong>Antibodies is a defense mechanism triggered by the body in order to fight off infections. Around 15 in every 100 women who have had recurrent miscarriages have antibodies called antiphospholipid
                        antibodies (aPL) in their blood. These antibodies react against the body's own tissues. For a woman with a history of recurrent miscarriage and presence ofaPL antibodies, the chances of a successful pregnancy may be only one in
                        ten.
                    </p>
                    <p><strong><em>Womb structure:</em></strong>Minor variations in the structure of the womb do not cause miscarriages.However, women with serious anatomical abnormalities of the womb that cannot be treated are more likely to miscarry or
                        give birth early.</p>
                    <p><strong><em>Weak cervix: </em></strong>In some women, the entrance of the womb or the cervix opens too early in the pregnancy and causes a miscarriage in the third to sixth month. This is known as having a weak or ‘incompetent' cervix.</p>
                    <p><strong><em>Polycystic ovaries: </em></strong>Polycystic ovaries refer to a condition where the ovaries are slightly larger than normal but produce smaller follicles. This could be as a result of hormonal imbalance. Many women with
                        polycystic ovaries and recurrent miscarriage have high levels of luteinising hormone (LH) in their blood. However, reduction of the level of LH before pregnancydoes not improve chances of a successful birth.</p>
                    <p><strong><em>Infections: </em></strong>Serious infections may lead to a miscarriage. Infections such as bacterial vaginosis(BV)early in pregnancy can increase the risk of a miscarriage or of premature birth.</p>
                    <p><strong><em>Blood conditions: </em></strong>Certain inherited conditions such as thrombophiliawhere blood is more likely to clot than usual are believed to play a part in miscarriage.</p>
                    <p><strong><em>Diabetes and thyroid problems: </em></strong>Diabetes or thyroid disorders are known to be risk factors in single miscarriages. However, these conditions do not cause recurrent miscarriage, as long as they are treated and
                        kept under control.</p>
                </div>
            </div>
            <div class="col-xs-12 col-lg-12">
                <h2>Preventing Recurrent Miscarriages</h2>
                <p><strong><em>Screening for abnormalities in the structure of your womb: </em></strong>Apelvic ultrasound scan should be done to check for and assess any abnormalities in the structure of the womb.</p>
                <p><strong><em>Screening for genetic problems: </em></strong>A blood test called karyotyping is done to check for chromosome abnormalitiesin a couple. If either or both have an abnormality, the couple is referred to a clinical geneticist,
                    who providesgenetic counseling and explains the chances for future pregnancies as well asrisks associated.</p>
                <p><strong><em>Screening for abnormalities in the embryo: </em></strong>For womenwith a history of recurrent miscarriage, doctors may suggest checking for abnormalities in the embryo or the placenta through karyotyping and microscopic examination
                    of the placenta. The results of these tests can help doctors identify the problem and come up with possible avenues of treatment.</p>
                <p><strong><em>Screening for vaginal infection: </em></strong>Women who have had miscarriages in the fourth to sixth month of pregnancy or premature labor, should be offered a test for bacterial vaginosis. Treatment with antibiotics reduces
                    the risks of losing the baby or of premature birth.</p>
                <p><strong><em>Treatment for aPL antibodies: </em></strong>Treatment with low-dose aspirin tablets and heparin injections in the early pregnancy may improve chances of birthin women who have aPL antibodies and a history of recurrent miscarriages.</p>
                <p><strong><em>Treatment for thrombophilia: </em></strong>Although there is a higher risk of miscarriage for thrombophilia, it possible to have a successful pregnancy with treatments to reduce the risk of a blood clot.</p>
                <p><strong><em>Tests and treatment for a weak cervix: </em></strong>A vaginal ultrasound scan during pregnancy can indicate a weak cervix and if a miscarriage is likely. An option is to put a stitch in the cervix to ensurethat it stays closed.
                    This operation is usually done through the vagina, but may also be done through a bikini line cut in the abdomen.</p>
                <p><strong><em>Hormone treatment: </em></strong>For women with hormonal imbalance, it has been suggested that use of progesterone or human chorionic gonadotrophin hormones early in pregnancy could help prevent a miscarriage.</p>
            </div>
        </div>
    </section>
    <ng-template [ngIf]="ins_links.length > 0">
        <footer-nav-mini [links]="ins_links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>