<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>
    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <section class="page aboutlp aboutushead">
        <div class="container">
            <div class="row">
                <div class="col-xs-12 col-sm-6 col-lg-6">
                    <div class="abtitle">
                        <h1>{{title}}</h1>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 ablpcell">
                    <div class="abwwawrp">

                        <slick-carousel class="abwwaslider">
                            <li slick-carousel-item *ngFor="let slide of abwwaslider" [sliderConfig]="abwwasliderConfig" class="sitem">
                                <h3>{{slide.title}}</h3>
                                <p [innerHTML]="slide.description"></p>
                                <p><a href="/about-us/{{slide.link}}" class="readm">Read More </a></p>
                            </li>
                        </slick-carousel>
                    </div>
                </div>
                
                <!-- <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 ablpcell">
                    <div class="abmdswrp">
                        <div class="abmdscir">
                            <div class="text">
                                <div class="bordercir"></div>
                                <a href="https://www.youtube.com/embed/e_qpNBdO2-w" class="playvid fancybox" tabindex="0" title="Dr. Ramesh Kancharla - Chairman and MD of Rainbow Hospitals"><span></span><img src="assets/images/pic-doc.jpg" alt="Dr. Ramesh Kancharla - Chairman and MD of Rainbow Hospitals" title="Dr. Ramesh Kancharla - Chairman and MD of Rainbow Hospitals" /></a> -->
                                <!-- <a href="https://www.youtube.com/embed/e_qpNBdO2-w" class="fancybox"><img src="assets/images/pic-doc.jpg"" alt="" /></a> -->
                            <!-- </div>
                        </div>
                        <div class="abmdsdts">
                            <div class="abmdtitle">
                                <h2>MD <span>Speaks</span></h2>
                            </div>
                            <div class="details">
                                <h3>Dr. Ramesh Kancharla</h3>
                                <p> Watch Dr. Ramesh Kancharla’s vision on Rainbow Hospitals. </p>
                                <a href="md-profile" class="knowmore"><span>View Profile</span></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 ablpcell">
                    <div class="abfswrp">
                        <h3>Factsheet</h3>

                        <slick-carousel class="abfsslider">
                            <li slick-carousel-item *ngFor="let factSlide of FactsheetSlide" [sliderConfig]="abfssliderConfig" class="sitem">
                                <div class="cirwrp"> <img src="assets/images/{{factSlide.icon}}" alt="{{factSlide.alt}}" /> </div>
                                <div class="txtwrp">
                                    <p [innerHTML]="factSlide.iconInfo"></p>
                                </div>
                            </li>
                        </slick-carousel>
                    </div>
                </div>
            </div> -->
            
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 ablpcell">
                    <div class="abfswrp">
                        <h3>Corporate</h3>
                        <iframe allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" frameborder="0" height="315" src="https://www.youtube.com/embed/VWjNxLA0zTA" title="YouTube video player" width="560"></iframe>
                    </div>
                </div>
            </div>
            <!-- <div class="row"> -->
                <!-- <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 ablpcell">
                    <div class="abcemswrp">
                        <div class="cemstitle">
                            <h2>Cutting Edge Medical Solutions</h2>
                        </div>
                        <div class="abcemssrc">
                            <div class="abcirwrp"><img src="assets/images/about-landingpage_01_Cutting Edge.jpg" alt="" /></div>
                        </div>
                        <div class="abcemsdts">
                            <p>As a holistic centre to care for and heal infants, children and women, we define ourselves as a community health provider with patient-centric care and value for money to all sections of society.</p>
                            <p><a href="/about-us/cutting-edge-medical-solutions" class="readm">Read More</a></p>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 ablpcell" *ngIf="awards">
                    <div class="abawardswrp">
                        <div class="abawicon"><img src="assets/images/icon-cup.png" alt=""></div>
                        <div class="abawtitle">
                            <h2>Awards</h2>
                        </div>
                        <div class="abcsmarrow">
                            <a class="slick-prev awprev"><img src="assets/images/btn-arrow-left.png" alt="" /></a>
                            <a class="slick-next awnext"><img src="assets/images/btn-arrow-right.png" alt="" /></a>
                        </div>
                        <div class="abawardslider">
                            <ng-template ngFor let-award [ngForOf]="awards|slice:0:5">
                                <div class="item">
                                    <h3>{{award.title}}</h3>
                                    <p [innerHTML]="award.description|slice:0:100"></p>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 ablpcell" *ngIf="events">
                    <div class="abeventswrp">
                        <div class="abevtitlenar">
                            <div class="abevtitle">
                                <h2>Events</h2>
                            </div>
                            <div class="abevarrow">
                                <a class="slick-prev evprev"><img src="assets/images/prv-arrow3.png" alt="" /></a>
                                <a class="slick-next evnext"><img src="assets/images/nxt-arrow3.png" alt="" /></a>
                            </div>
                        </div>
                        <div class="abeventsslider">
                            <ng-template ngFor let-event [ngForOf]="events">
                                <div class="item">
                                    <div class="abcevsrc" *ngIf="event.image"> -->
                                        <!-- <div class="abcirwrp"><img src="assets/images/about-landingpage_02_events.jpg" alt="" /></div> -->
                                        <!-- <div class="abcirwrp"><img src="{{imgBasePath}}{{event.image}}" alt="" /></div>
                                    </div>
                                    <div class="abcevsrc" *ngIf="!event.image">
                                        <div class="abcirwrp"><img src="assets/images/about-landingpage_02_events.jpg" alt="" /></div>
                                    </div>

                                    <div class="abcevdts">
                                        <h3>{{event.name}}</h3>
                                        <h4>{{event.event_date}}</h4>
                                        <p [innerHTML]="event.summery"></p>
                                        <p><a href="/about-us/events/events-details/{{event.id}}" class="readm">Read More</a>&nbsp;&nbsp;</p>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 ablpcell">
                    <div class="abmediawrp">
                        <div class="abmediatle">
                            <h2>Media</h2>
                        </div>
                        <div class="abmediadts">
                            <h3>Press Releases Press Clips Media Contact & Policy</h3>
                            <p>Rainbow Hospitals for Women and Children today reflects a decade plus history of dedicated patient care, pioneering procedures,</p>
                            <p><a href="/about-us/media" class="readm">Read More</a></p>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 ablpcell">
                    <div class="abrcwrp">
                        <div class="abrcsrc">
                            <img src="assets/images/about_cir_thumb2.png" alt="Rainbow Hospitals Channel" />
                        </div>
                        <div class="abrctle">
                            <h2>Rainbow Channel</h2>
                        </div>
                        <div class="abrcdts">
                            <p>Watch the latest television advertisement of Rainbow Hospitals and latest news of Rainbow Hospitals.</p>
                            <p><a href="/about-us/channel" class="readm">Read More</a></p>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 ablpcell">
                    <div class="abcareerwrp">
                        <div class="abcareertle">
                            <h2>Career</h2>
                        </div>
                        <div class="abcareerdts">
                            <p>Rainbow Hospitals is one of the largest tertiary paediatric health care organization & perinatal centre in India and the first Children's Hospital with </p>
                            <p><a href="https://ehubnext.myadrenalin.com/candidate/LoginPage.aspx?obj=0qKjcPeCekWVUp%2f8Wz3cmM5%2fF4SZ%2bT68" target="_blank" class="readm CareersBtnAboutViewAll"> View All </a></p>
                        </div>
                    </div>
                </div>
            </div> -->
        </div> 
    </section>
    <section class="page aboutlphmq">
        <div class="container">
            <div class="row">
                <div class="aboutlphmqslider">
                    <div class="abhmqwrp">
                        <div class="abhmqtle">
                            <h2>History</h2>
                        </div>
                        <div class="abhmqsrcwrp">
                            <div class="abhmqsrc">
                                <img src="assets/images/thumb_h.jpg" alt="The Rainbow Journey Began" />
                            </div>
                        </div>
                        <div class="abhmqdts">
                            <h3>Taking a walk down the memory lane</h3>
                            <p>The Rainbow Journey began</p>
                            <p><a href="/about-us/history" class="readm">View All</a></p>
                        </div>
                    </div>
                    <div class="abhmqwrp">
                        <div class="abhmqtle">
                            <h2>Management</h2>
                        </div>
                        <div class="abhmqsrcwrp">
                            <div class="abhmqsrc">
                                <img src="https://backend.rainbowhospitals.in//uploads/fad8ee966cbe4144a5b4b2b1d40b5180.jpg" alt="" />
                            </div>
                        </div>
                        <div class="abhmqdts">
                            <h3>Dr. Ramesh Kancharla</h3>
                            <p>Chairman and Managing Director </p>
                            <p><a href="/about-us/management" class="readm">View All</a></p>
                        </div>
                    </div>
                    <div class="abhmqwrp">
                        <div class="abhmqtle">
                            <h2>Quality & Accreditation</h2>
                        </div>
                        <div class="abhmqsrcwrp">
                            <div class="abhmqsrc">
                                <img src="assets/images/doc-thumb6.png" alt="Dr. Farhan Shaikh" />
                            </div>
                        </div>
                        <div class="abhmqdts">
                            <h3>Dr. Farhan Shaikh</h3>
                            <p>Welcome to the Department of Quality at Rainbow Hospitals. </p>
                            <p><a href="/about-us/quality-accreditation" class="readm">View All</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <ng-template [ngIf]="links.length > 0"> 
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>

    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>