import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
// import { ValidatorFn } from '@angular/forms/src/directives/validators';
import { ApiService } from '../services/api.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { API_BASE_URL, IMG_BASE_URL } from '../services/constants';
import { Meta, Title } from '@angular/platform-browser';
declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
declare let initPopup: any;
// declare let initAboutTabs:any;

@Component({
  selector: 'careers-apply',
  templateUrl: './careers-apply.component.html',
  styles: [
    `
      .alertMsg {
        color: red !important;
      }
      .CalcLoader {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto auto;
        z-index: 999;
        width: 100%;
        height: 100%;
        background: rgba(51, 51, 51, 0.2);
      }
      .CalcLoader img {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto auto;
        z-index: 999;
      }
    `,
  ],
})
export class CareersApplyComponent implements OnDestroy, OnInit, AfterViewInit {
  constructor(
    private api: ApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public fb: FormBuilder,
    public metaTitle: Title,
    public meta: Meta
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
    }
    this.routerUrl = this.router.url;
  }

  public title: any = '';
  public subtitle: any = '';
  public description: any = '';
  public career: any = [];
  public imgsrc: any = '';
  public imgBasePath = '';
  public id: any = '';
  public hidden = 'hidden';
  public logo = 'logo';
  public file: any;
  public routerUrl;

  public resume: any;

  public applyCarrerFormGroup: FormGroup;
  public submitted: boolean = false;
  public popUpMessage: any = { title: '', message: '' };

  public bread_crumb_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'About Us', link: '/about-us', class: '' },
    { label: 'Careers', link: '/about-us/careers', class: '' },
    {
      label: 'Current openings',
      link: 'about-us/careers/careers-join-withus',
      class: '',
    },
    { label: 'Apply', link: '', class: '' },
  ];

  public links: any = [];
  public activePage: string = 'careers';

  // @ViewChildren('abouttabs') things: QueryList<any>;

  ngOnInit() {
    let emailPattern = '[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}';
    this.api
      .getAboutSubFooterDetails()
      .map((res) => res)
      .subscribe((data) => {
        this.links = data;
      });
    this.applyCarrerFormGroup = this.fb.group({
      name: ['', Validators.required],
      phone: [
        '',
        Validators.compose([Validators.required, Validators.minLength(10)]),
      ],
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(emailPattern),
        ]),
      ],
      upload_resume: ['', Validators.required],
      message: [''],
    });

    this.activatedRoute.params.subscribe((params: Params) => {
      //console.log(params);
      this.id = params['id'];
    });

    this.api.getPageDescription('careers-apply').subscribe(
      (data) => {
        if (data && data.page_descriptions) {
          (this.title = data.page_descriptions[0].title),
            (this.subtitle = data.page_descriptions[0].subtitle),
            (this.description = data.page_descriptions[0].description),
            (this.imgsrc = data.page_descriptions[0].image),
            (this.imgBasePath = IMG_BASE_URL);
        }
      },
      (error) => {
        console.log(error);
      }
    );

    this.api.getCareerDetail(this.id).subscribe((data) => {
      this.career = data.careers[0];
      this.imgBasePath = IMG_BASE_URL;
      if (data) {
        //console.log(data);
        this.metaTitle.setTitle(this.career.meta_title);
        this.meta.updateTag({
          name: 'keywords',
          content: this.career.meta_keyword,
        });
        this.meta.updateTag({
          name: 'description',
          content: this.career.meta_description,
        });
        this.meta.addTags([
          { property: 'og:title', content: this.career.meta_title },
          { property: 'og:description', content: this.career.meta_description },
          { property: 'og:keywords', content: this.career.meta_keyword },
        ]);
      }
    });
  }

  careerFormApply(event: Event, form: FormGroup) {
    this.submitted = true;
    if (form.valid && form.dirty) {
      $('.CalcLoader').css('display', 'block');
      this.submitted = false;
      let formTemp: any = <any>document.querySelector('#form');
      let formData: FormData = new FormData(formTemp);
      formData.append('career_id', this.id);
      let xhr: XMLHttpRequest = this.buildForm(formData);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          this.resetForm();
          if (xhr.status === 201) {
            //window.location.href = this.routerUrl+'?tag=AW';
            $('.CalcLoader').css('display', 'none');
            this.popUpMessage = {
              title: 'Thankyou',
              message: 'You have applied sucessfully..',
            };
            initPopup(2);
          } else if (xhr.status === 409) {
            //window.location.href = this.routerUrl+'?tag=AW';
            $('.CalcLoader').css('display', 'none');
            this.popUpMessage = {
              title: 'Failed',
              message: 'You have alredy applied with same details..',
            };
            initPopup(2);
          } else {
            $('.CalcLoader').css('display', 'none');
            this.popUpMessage = {
              title: 'Failed',
              message: 'Something went wrong..',
            };
            initPopup(2);
          }
        }
      };
    }
  }

  // buildForm multipart/form-data function handled for file POST request

  public buildForm(formData) {
    let url: any = API_BASE_URL + 'home/apply_career';
    let xhr: XMLHttpRequest = new XMLHttpRequest();
    xhr.open('POST', url, true);

    // enctype For Multipart Request
    xhr.setRequestHeader('enctype', 'multipart/form-data');

    // IE bug fixes to clear cache
    xhr.setRequestHeader('Cache-Control', 'no-cache');
    xhr.setRequestHeader('Cache-Control', 'no-store');
    xhr.setRequestHeader('Pragma', 'no-cache');

    xhr.send(formData);
    return xhr;
  }

  resetForm() {
    document.getElementById('uploadCaptureInputFile')['value'] = '';
    this.resume = '';
    this.applyCarrerFormGroup.reset();
  }

  fileEvent(fileInput: any) {
    if (fileInput && fileInput.target && fileInput.target.files[0]) {
      //console.log(fileInput);
      let a = document.querySelector('#uploadCaptureInputFile');
      console.log(a);
      this.file = fileInput.target.files[0];
      let fileName = this.file.name;
      this.resume = fileName;
      (<any>this.applyCarrerFormGroup.controls['upload_resume']).setValue(
        fileName
      );
    }
  }

  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
    }

    // this.things.changes.subscribe(t => {
    //   // this.ngForRendred();
    //   initAboutTabs();
    // })
  }

  ngOnDestroy() {}
}
