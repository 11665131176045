<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>
    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <section class="page pageheader">
        <div class="container">
            <div class="col-xs-6 col-sm-6 col-lg-6 abannimgwrp">
                <div class="otherbannimg"><img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" /></div>
            </div>
            <div class="col-xs-6 col-sm-6 col-lg-6">
                <div class="ptitle">
                    <h1 class="tgradient3">{{title}}</h1>
                    <h3>{{subtitle}}</h3>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-lg-6 mcont100">
                <p [innerHTML]="description"></p>
                <div class="childbirthfmwrp">
                    <ul class="childblst">
                        <li>
                            <div class="findoswrp">
                                <select class="select" name="txtChildBirth" #specMas [(ngModel)]="selectedSpecMaster">
                                    <option value="0" [selected]="selectedSpecMaster == 0">Select</option>
                                    <option *ngFor="let spec of specialityMasters; let i= index;" [value]="spec.id" >{{spec.speciality}}</option>
                                </select>
                                <span class="select-span">{{selectedSpecMasterTitle}}</span>
                            </div>
                            <div class="gobtn">
                                <input type="button" name="txtSearch" value="GO" (click)="changeSpecMaster(specMas.value)">
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <section #checkKnowledge class="KnowledgeCentre">
        <div class="row" *ngIf="group_sections.length > 0">
            <div class="kcWrap">
                <div class="container">
                    <div class="KCSlider">
                        <ul>
                            <li *ngFor="let groupSection  of group_sections">
                                <a href="javascript:void(0)">{{groupSection.category}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="container">
                    <div class="KCSliderCont">
                        <div class="kcCont" *ngFor="let groupSection  of group_sections">
                            <div class="row">
                                <ng-template ngFor let-knowledgeList [ngForOf]="groupSection.knowledge_center">
                                    <div class="col-xs-12 col-sm-6">
                                        <h4>{{knowledgeList.title}}</h4>
                                        <p><strong>{{knowledgeList.sub_title}}</strong></p>
                                        <p [innerHTML]="knowledgeList.short_description"></p>
                                        <p><a href="other/knowledge-centre/{{knowledgeList.slug}}" class="readMore">Read more</a></p>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                        <!--<div class="kcCont" *ngFor="let groupSection  of group_sections" [ngClass]="{'content': i == 0}">
                            <div class="row" *ngFor="let i of rows">
                                <ng-template ngFor let-knowledgeList [ngForOf]="groupSection.knowledge_center | slice:(i*2):(i+1)*2">
                                    <div class="col-xs-12 col-sm-6">
                                        <h4>{{knowledgeList.title}}</h4>
                                        <p><strong>{{knowledgeList.sub_title}}</strong></p>
                                        <p [innerHTML]="knowledgeList.short_description"></p>
                                        <p><a href="other/knowledge-centre/{{knowledgeList.slug}}" class="readMore">Read more</a></p>
                                    </div>
                                </ng-template>
                            </div>
                        </div>-->

                    </div>
                </div>
            </div>
        </div>
    </section>

    <find-a-doctors-nav></find-a-doctors-nav>

    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>

</div>