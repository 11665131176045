import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  ViewChildren,
  QueryList,
  HostListener,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL } from '../services/constants';
import { Meta, Title } from '@angular/platform-browser';
declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
declare let aboutSlide: any;
// declare let initAboutTabs:any;
interface AWARDS {
  title: any;
  description: any;
}
@Component({
  selector: 'about-us',
  templateUrl: './about-us.component.html',
  styles: [
    `
      .abawardswrp {
        width: 100%;
        float: left;
        clear: both;
        padding-top: 53px;
        min-height: 400px;
        /*min-width: 400px;*/
      }
    `,
  ],
})
export class AboutUsComponent implements OnDestroy, OnInit, AfterViewInit {
  constructor(
    private api: ApiService,
    public metaTitle: Title,
    public meta: Meta
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/fancybox/jquery.fancybox.min.js');
      require('../../assets/js/slick.min.js');
    }
  }
  public awards: Array<AWARDS>;
  public events: any;
  public services: any[] = [];
  public title: any = '';
  public subtitle: any = '';
  public description: any = '';
  public imgsrc: any = '';
  public brief_facilities_description: any = '';
  public brief_facilities_image: any = '';
  public imgBasePath = '';
  public hidden = 'hidden';
  public logo = 'logo';
  public abwwasliderConfig: any = {
    slide: 'li',
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    fade: false,
    infinite: true,
    adaptiveHeight: true,
  };

  public abfssliderConfig: any = {
    slide: 'li',
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    fade: false,
    infinite: true,
    adaptiveHeight: true,
  };
  public aboutlphmqsliderConfig: any = {
    slide: 'li',
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    fade: false,
    infinite: true,
    adaptiveHeight: true,
  };

  public bread_crumb_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'About Us', link: '', class: '' },
  ];
  public links: any = [];
  public activePage: string = 'about-us';

  //static data
  public abwwaslider: any = [];
  public FactsheetSlide: Array<any> = [];
  ngOnInit() {
    //this.imgBasePath = IMG_BASE_URL;
    this.api.getPageDescription('about-us').subscribe(
      (data) => {
        (this.title = data.page_descriptions
          ? data.page_descriptions[0].title
          : ''),
          (this.subtitle = data.page_descriptions
            ? data.page_descriptions[0].subtitle
            : ''),
          (this.description = data.page_descriptions
            ? data.page_descriptions[0].description
            : ''),
          (this.imgsrc = data.page_descriptions
            ? data.page_descriptions[0].image
            : ''),
          (this.imgBasePath = IMG_BASE_URL);
        if (data) {
          //console.log(data);
          this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
          this.meta.updateTag({
            name: 'keywords',
            content: data.page_descriptions[0].meta_keyword,
          });
          this.meta.updateTag({
            name: 'description',
            content: data.page_descriptions[0].meta_description,
          });
          this.meta.addTags([
            {
              property: 'og:title',
              content: data.page_descriptions[0].meta_title,
            },
            {
              property: 'og:description',
              content: data.page_descriptions[0].meta_description,
            },
            {
              property: 'og:keywords',
              content: data.page_descriptions[0].meta_keyword,
            },
          ]);
        }
      },
      (error) => {
        console.log(error);
      }
    );
    this.api
      .getAboutSubFooterDetails()
      .map((res) => res)
      .subscribe((data) => {
        data.forEach((element) => {
          if (element.label == 'Careers') {
            element.link =
              'https://ehubnext.myadrenalin.com/candidate/LoginPage.aspx?obj=0qKjcPeCekWVUp%2f8Wz3cmM5%2fF4SZ%2bT68';
          }
        });
        this.links = data;
      });
    // this.api.getHealthCareServices('who-we-are')
    //   .subscribe(
    //     data =>{
    //       this.services = data.healthcare_services
    //     },
    //     error => {
    //       console.log(error);
    //     }
    //   )
    //
    //   this.api.getBriefFacilities('who-we-are')
    //     .subscribe(
    //       data =>{
    //         this.brief_facilities_description = data.brief_facilities[0].description,
    //         this.brief_facilities_image = data.brief_facilities[0].image
    //       },
    //       error => {
    //         console.log(error);
    //       }
    //     )
    this.getAwards();
  }

  getAwards() {
    this.api.getHomeAwards().subscribe(
      (data) => {
        this.awards = data.awards_histories;
      },
      (error) => {
        console.log(error);
      }
    );
    this.api.getPageEvents('about-us').subscribe(
      (data) => {
        this.events = data.home_events;
        console.log(data);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  @HostListener('window:load', [])
  onWindowLoad() {
    $('.fancybox').fancybox({
      type: 'iframe',
      maxWidth: 700,
      maxHeight: 600,
      fitToView: false,
      width: '70%',
      height: '70%',
      autoSize: false,
      closeClick: false,
      openEffect: 'none',
      closeEffect: 'none',
    });
    this.jqueryLoad();
  }
  jqueryLoad() {
    //$(".abawardslider").removeClass("slick-slider").removeClass("slick-initialized").removeClass("slider");
    $('.abawardslider')
      .not('.slick-initialized')
      .slick({
        dots: false,
        arrows: true,
        mobileFirst: true,
        prevArrow: $('.awprev'),
        nextArrow: $('.awnext'),
        infinite: false,
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,
      });

    $('.abeventsslider')
      .not('.slick-initialized')
      .slick({
        dots: false,
        arrows: true,
        mobileFirst: true,
        prevArrow: $('.evprev'),
        nextArrow: $('.evnext'),
        infinite: false,
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,
      });

    $('.aboutlphmqslider')
      .not('.slick-initialized')
      .slick({
        slide: '.abhmqwrp',
        dots: false,
        arrows: true,
        prevArrow:
          '<a class="slick-prev"><img src="assets/images/prv-arrow2.png" alt="" /></a>',
        nextArrow:
          '<a class="slick-next"><img src="assets/images/nxt-arrow2.png" alt="" /></a>',
        infinite: false,
        autoplay: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 680,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 580,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
  }
  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
      this.jqueryLoad();

      setTimeout(() => {
        this.abwwaslider = [
          {
            id: 1,
            title: 'Who we are',
            description: `Rainbow Hospital for Women and Children has ${new Date()
              .getFullYear()
              .toString()
              .split('')
              .splice(2, 2)
              .join(
                ''
              )} glorious years of excellence in child care, women care and fertility. It is the first corporate children's hospital in India, started on 14th November 1999 on Children's Day.",
            link: 'who-we-are`,
          },
          {
            id: 2,
            title: 'What We Do',
            description:
              'Rainbow Hospitals aim to provide high standards of care for the mother, fetus, newborn and children so that none of them is deprived of a tertiary care facility.',
            link: 'what-we-do',
          },
          // {
          //   id: 3,
          //   title: 'Why Choose us',
          //   description:
          //     'As a holistic centre to care for and heal infants, children and women, we define ourselves as a community health provider with patient-centric care and value for money to all sections of society.',
          //   link: 'why-chooseus',
          // },
        ];

        this.FactsheetSlide = [
          {
            id: 1,
            icon: 'icon-about_No01_ChildrensHospital.png',
            iconInfo: "No. 1 children's multispecialty hospital in India",
            alt: 'Number 1 Multispeciality Hospital at Rainbow Hospitals',
          },
          {
            id: 2,
            icon: 'icon-about_1800pretermbabies.png',
            iconInfo: 'Over 1800 preterm babies treated annually',
            alt: 'Preterm Babies Annually Treated at Rainbow Hospitals',
          },
          {
            id: 3,
            icon: 'icon-about_9000-Deliveries.png',
            iconInfo: '9000 Deliveries annually',
            alt: '9000 Deliveries annually at Rainbow Hospitals',
          },
          {
            id: 4,
            icon: 'icon-about_IVF_success.png',
            iconInfo: 'World class success rates in IVF procedures',
            alt: 'IVF Success Rate at BirthRight By Rainbow Hospitals',
          },
        ];
      }, 500);
    }
  }

  ngOnDestroy() {}
}
