import {Inject, Component, OnDestroy, OnInit, AfterViewInit, PLATFORM_ID, ViewChildren, QueryList  } from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {ApiService} from "../services/api.service";
import {IMG_BASE_URL} from "../services/constants";
import {Router, ActivatedRoute, Params} from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
declare let $:any;
declare let initHome:any;
declare let initAllPage:any;
// declare let initAboutTabs:any;

@Component({
  selector: 'press-releases-details',
  templateUrl: './press-releases-details.html'
})

export class PressReleasesDetailsComponent implements OnDestroy, OnInit, AfterViewInit {


  constructor(private api:ApiService,  private activatedRoute: ActivatedRoute, public metaTitle: Title, public meta: Meta) {
    if(this.api.isBrowser){
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
    }
  }

  public pressDetail:any = [];

  public title:any = "";
  public subtitle:any = "";
  public description:any = "";
  public imgsrc:any = "";
  public brief_facilities_description:any = "";
  public brief_facilities_image:any = "";
  public imgBasePath = "";
  public hidden = "hidden";
  public logo="logo";
  public bread_crumb_links:any = [{"label":"Home", "link": "/", "class": ""},
                                 {"label":"About Us", "link": "/about-us", "class": ""},
                                 {"label":"Media", "link": "/about-us/media", "class": ""},
                                 {"label":"Press Releases", "link": "", "class": ""}];

  public links:any = [ {"label":"About Us", "link": "/about-us", "class": ""},
  {"label":"Who We are", "link": "/about-us/who-we-are", "class": ""},
                      {"label":"What We do", "link": "/about-us/what-we-do", "class": ""},
                      {"label":"Why Choose Us", "link": "/about-us/why-chooseus", "class": ""},
                      {"label":"Cutting Edge Medical Solutions", "link": "/about-us/cutting-edge-medical-solutions", "class": ""},
                      {"label":"MD Message", "link":"/about-us/mds-message", "class":""},
                      {"label":"Management", "link": "/about-us/management", "class": ""},
                      {"label":"History", "link": "/about-us/history", "class": ""},
                      {"label":"Awards", "link": "/about-us/awards", "class": ""},
                      {"label":"Media", "link": "/about-us/media", "class": "active"},
                      {"label":"News", "link": "/about-us/news", "class": ""},
                      {"label":"Events", "link": "/about-us/events", "class": ""},
                      {"label":"Careers", "link": "https://ehubnext.myadrenalin.com/candidate/LoginPage.aspx?obj=0qKjcPeCekWVUp%2f8Wz3cmM5%2fF4SZ%2bT68", "class": "CareersBtnAll"},
                      {"label":"Channel", "link": "/about-us/channel", "class": ""},
                      {"label":"Quality & Accreditation", "link": "/about-us/quality-accreditation", "class": ""}]

  public id:any = "";

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
          this.id = params['id'];
      });

    this.api.getMediaReleaseById(this.id)
    .subscribe(
        data =>{
          this.pressDetail = data.media_press_releases[0];
          if(data){
            //console.log(data);
            this.metaTitle.setTitle(this.pressDetail.meta_title);
            this.meta.updateTag({name:'keywords',content: this.pressDetail.meta_keyword});
            this.meta.updateTag({name:'description',content: this.pressDetail.meta_description});
            this.meta.addTags([
              { property: 'og:title', content:  this.pressDetail.meta_title},
              { property: 'og:description', content: this.pressDetail.meta_description },
              { property: 'og:keywords', content: this.pressDetail.meta_keyword }
            ]);
            }
        },
        error => {
          console.log(error);
        }
      )

    this.api.getPageDescription('media-press-releases-details')
      .subscribe(
        data =>{
          this.title = data.page_descriptions[0].title;
  				this.subtitle = data.page_descriptions[0].subtitle;
  				this.description = data.page_descriptions[0].description;
  				this.imgsrc = data.page_descriptions[0].image;
  				this.imgBasePath = IMG_BASE_URL
  				
        },
        error => {
          console.log(error);
        }
      )

  }

  ngAfterViewInit() {

    if ( this.api.isBrowser && window['jQuery'] ) {
      initHome();
      initAllPage();
    }
  }

  ngOnDestroy() {}

}
