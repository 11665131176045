<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>


    <section class="page pageheader">
        <div class="container">
            <div class="col-xs-6 col-sm-6 col-lg-6 abannimgwrp">
                <div class="otherbannimg"><img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" /></div>
            </div>
            <div class="col-xs-6 col-sm-6 col-lg-6">
                <div class="ptitle">
                    <h1>{{title}}</h1>
                    <h3>{{subtitle}}</h3>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-lg-6 mcont100">
                <p [innerHTML]="description"></p>
            </div>

        </div>
    </section>
    <section class="aboutsectin2 wcuswrp acadamicWrp">
        <div class="container">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-lg-12">
                    <ul class="aCircleList">
                        <li>
                            <div class="iconwrp">
                                <a href="/other/academic-webinar">
                                    <img src="../../assets/images/academic/icon-webinar.png" alt="" />
                                    <h4>Webinars</h4>
                                </a>
                            </div>
                        </li>
                        
                        <li>
                            <div class="iconwrp">
                                <a href="/other/academic-programs">
                                    <img src="../../assets/images/academic/icon-programs.png" alt="" />
                                    <h4>Programs</h4>
                                </a>
                            </div>
                        </li>
                        <li>
                            <div class="iconwrp">
                                <a href="javascript:void(0);">
                                    <img src="../../assets/images/academic/icon-article.png" alt="" />
                                    <h4 class="active">Publications</h4>
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-12 pgLine">
                    <p>Explore peer-reviewed publications on the best healthcare practices and latest innovations</p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-lg-12 headingWrp">
                    <h3>Featured Publications</h3>
                </div>
                <div class="col-sm-12 col-lg-12 seprateSelectWrp">
                    <ul class="selectwrp">
                        <li>
                            <div class="specialitywrp">
                                <select class="select" id="cSpecialty" (change)="onChangeSpecialty($event)">
                                    <option value="0" selected>Select Specialty</option>
									<option *ngFor="let specMaster of specilityMasterList;" value="{{specMaster.name}}">{{specMaster.name}}</option>
                                </select>
                                <span class="select-span" id="SelectSpecialty">Select Specialty</span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="col-xs-12 aArticles">
                    <div class="row" myMatchHeight="PressReleasesBox">
                        <h3 *ngIf="aArticlesResult">Sorry, we couldn't fetch any results.</h3>
                        <div class="col-xs-12 col-sm-6 PressReleasesBox" *ngFor="let article of aArticles">
                            <div class="dateBox">
                                <div class="month">{{article.contentdate | amDateFormat : "MMMM"}}</div>
                                <div class="date">{{article.contentdate | amDateFormat : "DD"}}</div>
                                <div class="year">{{article.contentdate | amDateFormat : "YYYY"}}</div>
                            </div>
                            <h4>{{article.contentheading}}</h4>
                            <p [innerHTML]="article.contentsmalldecription"></p>
                            <a href="other/academic-article/detail/{{article.slug}}" class="readMore">Read More</a>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>



    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>