// JavaScript Document
$(function(){
	$('.LastPeriodDate').datepicker({
		dateFormat: "dd/mm/yy"
	})
	$('.LastPeriodDate').on('click', function () {
		$('.displayCalendar').datepicker('setDate', $(this).val());
	});

	$('.CycleDays').on('change', function(){
		circleCalender()
		fixCalendar();
		OCScreens();
		fixCalendarFunction();
	});
})


$(function (e) {
    circleCalender()
	fixCalendar();
	OCScreens();
	fixCalendarFunction();
	setInterval(fixCalendar,100)
	// $(window).resize(function(e) {
	// 	circleCalender()
	// 	fixCalendar()
	// 	OCScreens();
	// });
	
	
	
});
function fixCalendar(){
	var tdWidth = Math.floor(($(".calendarBox").width()- 30) / 7)
	$(".displayCalendar").find('table').css("width","100%")
	$(".displayCalendar").find('.dp_daypicker').find('th, td').width(tdWidth)
	
}
function OCScreens(){			
	$(".OCScreens").find(".icon").on('click', function (e) {
		$(this).parents(".OCScreens").removeClass("active").next(".OCScreens").addClass("active")
	});
	$(".OCsubmit").on('click',function(e) {
		if($("#startDate").val() && $("#MPDay").val()){
				$(this).parents(".OCScreens").removeClass("active").next(".OCScreens").addClass("active")
				circleCalender();	
				fixCalendarFunction();					
			}else{
				alert(" Please fill Last Period field")
				}
	});
	$(".OCback").on('click',function(e) {
		$(this).parents(".OCScreens").removeClass("active").prev(".OCScreens").addClass("active");
		$('html, body').animate({scrollTop: $(".calculatorwrp").offset().top-80}, 2000);
	});
	// if($("#startDate").val() && $("#MPDay").val()){
	// 	fixCalendarFunction();
	// }
	
}

var NoOFMonth = 2;
function setNoOFMonth(){
	$('.displayCalendar').datepicker({
		dateFormat: "dd/mm/yy",
		numberOfMonths: NoOFMonth			
	});
}
var lpTDays		
function fixCalendarFunction(){	

		setNoOFMonth()
		$(".calendarBox").show()
		var getDateVal = $("#startDate").val();
		var fixMPDay = $("#MPDay").val();
		var viewCycleDays = parseInt($(".CycleDays").val());
		//console.log(getDateVal, fixMPDay, viewCycleDays);
		var dateSeparat = getDateVal.split("/")
		
			
			startDate = parseInt(dateSeparat[0]), startMonth = parseInt(dateSeparat[1]), startYear = parseInt(dateSeparat[2]);
			var year = startYear;
			var month = startMonth; 
		    var currentMonthDays = Math.round(((new Date(year, month))-(new Date(year, month-1)))/86400000);
			//var	NextMonthDays = Math.round(((new Date(year, Nextmonth))-(new Date(year, Nextmonth-1)))/86400000);
			
			var endDate = parseInt(startDate) + parseInt(fixMPDay)
			//alert("endDate : "+ endDate)
			var dateMonth = startMonth -1 
				
				var sDate = startDate;
				
				var dateMonth = dateMonth;
				for(t=1; t <= fixMPDay; t++){
						$(".displayCalendar").find("td[data-month= '" + dateMonth + "']").each(function(index, element) {
							if($("a",this).text() == sDate){ $(this).addClass("mp") }
						});
						if(sDate < currentMonthDays){
							sDate++						
						}else{
							sDate = 1
							dateMonth = dateMonth + 1
						}							
					}
				
				var gapDaye = 6;
				for(n=1; n <= gapDaye; n++){
						$(".displayCalendar").find("td[data-month= '" + dateMonth + "']").each(function(index, element) {
							if($("a",this).text() == sDate){ $(this).addClass("aa") }
						});
						if(sDate < currentMonthDays){
							sDate++						
						}else{
							sDate = 1
							dateMonth = dateMonth + 1
						}							
					}
				var odDays = 7;
				for(p=1; p <= odDays; p++){
						$(".displayCalendar").find("td[data-month= '" + dateMonth + "']").each(function(index, element) {
							if($("a",this).text() == sDate){ $(this).addClass("od") }
						});
						if(sDate < currentMonthDays){
							sDate++						
						}else{
							sDate = 1
							dateMonth = dateMonth + 1
						}							
					}
								
				var lpDays = lpTDays;
				for(q=1; q <= lpDays; q++){
						$(".displayCalendar").find("td[data-month= '" + dateMonth + "']").each(function(index, element) {
							if($("a",this).text() == sDate){ $(this).addClass("lp") }
						});
						if(sDate < currentMonthDays){
							sDate++						
						}else{
							sDate = 1
							dateMonth = dateMonth + 1
						}							
					}
				
			$(".MPDays").text(fixMPDay)
			$(".Fertile").text(odDays)
			$(".viewCycleDays").text(viewCycleDays)
	}

function circleCalender(){			
        $(".OvulationCalculator").show().each(function(index, element) {		
			// Calender Start 
			var currentMonthDays, NextMonthDays, startDate, startMonth, startYear, currentMonthName, nextMonthName, MPDay, CycleDays;
			
			var getDateValue = $("#startDate").val();
				MPDay = $("#MPDay option:selected").val();
			var CycleDays = parseInt($(".CycleDays option:selected").val());

			console.log(getDateValue, MPDay, CycleDays);
			var dateSeparat = getDateValue.split("/") 
			startDate = dateSeparat[0], startMonth = dateSeparat[1], startYear = dateSeparat[2];
			
			var year = startYear;
			var month = startMonth;
			var Nextmonth = parseInt(startMonth) + 1;
			
							
			currentMonthDays = Math.round(((new Date(year, month))-(new Date(year, month-1)))/86400000);
			NextMonthDays = Math.round(((new Date(year, Nextmonth))-(new Date(year, Nextmonth-1)))/86400000);
						
			//var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
			var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
			currentMonthName = monthNames[parseInt(startMonth)-1]			
			if(startMonth == 12){
					startMonth = 0;
				}
			nextMonthName = monthNames[parseInt(startMonth)]
			
			// Circle Box Placing			
			var mpDays = startDate /*- 3;*/
			//alert( startCal +" /"+ CycleDays)
           // var noOfDay = parseInt(currentMonthDays + NextMonthDays) - mpDays, deg = 0, calcdeg = 0;
			var noOfDay = CycleDays, deg = 0, calcdeg = 0;
			
			//console.log("currentMonthDays : "+ currentMonthDays + 'stDate :' + startDate + ' / CycleDays : ' + CycleDays + ' / noOfDay :' + noOfDay ) 
				
			var pixelgapwidth = 0, diamtr = parseInt($(".OvulationCalculator").width()), radius = diamtr / 2 - 20
			var totalpixelgapwidth = (noOfDay - 1) * pixelgapwidth;
			var totalcirclelength = 22 / 7 *  diamtr;
			var SingleBlockWidth = Math.round((totalcirclelength - totalpixelgapwidth) / noOfDay)
			calcdeg = 360 / noOfDay; 
			
			
			var dateNo = mpDays;
			var CMontName = currentMonthName;
			var setMonths = currentMonthDays
			
			
			$(this).find(".dateBox").remove()
			for(i = 1; i <= noOfDay; i++){
					$(this).append("<div class='dateBox box"+ i +" "+ CMontName +"' style='transform:rotate("+ deg +"deg) translateY(-"+ radius +"px); width: "+ SingleBlockWidth +"px' data-Date="+ dateNo +" data-Month="+ CMontName +">"+ dateNo +"</div>")
					deg = deg + calcdeg;
					if(dateNo < setMonths){
							dateNo++						
						}else{
							dateNo = 1
							setMonths = NextMonthDays
							CMontName = nextMonthName
						}
				}
			
			$("."+currentMonthName+":first").addClass("active").append('<span>'+ currentMonthName +'</span>')
			$("."+nextMonthName+":first").addClass("active").append('<span class="active">'+ nextMonthName +'</span>')
			
			
				mainWidth = $(this).width(), 
				mainHeight = $(this).height(),
				circleCenter = Math.round(mainWidth / 2),				
				svgStroke = 20
								
			/*----------------------- start mp  ------------------------------------*/		
							
			var mpMinDate = parseInt(startDate - mpDays) + 1, 
			mpMaxDate = parseInt(startDate - mpDays) + parseInt(MPDay);
			
			$(".dateBox").each(function(index, element) {
				for(k = mpMinDate; k <= mpMaxDate; k++ ){
					$(".box"+k).addClass("mp")
				}
            });
			
			var svgWrapDeg = -90 - (calcdeg / 2)
			$(".svgWrap").css({'transform': 'rotate(' + svgWrapDeg + 'deg)'})
			
			
			var mpRadius = Math.round(circleCenter - svgStroke),
				mpCircleLenght = Math.round(22/7 * (mpRadius * 2)),
				mpTextRadius = Math.round(circleCenter - 60),
				mpTextCircleLenght = Math.round(22/7 * (mpTextRadius * 2))
						
			
			mpBgDegPos = mpMinDate - 1,
			mpBgDeg = calcdeg * mpBgDegPos, 
			mpCircProgPos = mpMaxDate - mpBgDegPos,
			mpPrograss = Math.round((mpCircleLenght / noOfDay) * mpCircProgPos),
			mpTextPrograss = Math.round((mpTextCircleLenght / noOfDay) * mpCircProgPos),
			
			$(".mpBg").css({'transform': 'rotate(' + mpBgDeg + 'deg)'})	
			//$(".mpBg circle").css({"r" : mpRadius, "cx":circleCenter, "cy": circleCenter, 'stroke-dasharray': + mpPrograss +','+ mpCircleLenght})
			$(".mpBg circle").css({'stroke-dasharray': + mpPrograss +','+ mpCircleLenght}).attr({ r : mpRadius, cx : circleCenter, cy : circleCenter })

			//$(".mpBg .mpTextBg").css({"r" : mpTextRadius, "cx":circleCenter, "cy": circleCenter, 'stroke-dasharray': + mpTextPrograss +',' + mpTextCircleLenght})
			$(".mpBg .mpTextBg").css({'stroke-dasharray': + mpTextPrograss +',' + mpTextCircleLenght}).attr({ r : mpTextRadius, cx : circleCenter, cy : circleCenter })
			
			
			$(".dateBox.mp:first").append('<div class="mpT">MP</div>')
			
			/*----------------------- start od  ------------------------------------*/	
			
				
			var gapDays =  6;
			var odDays = 7
			var odMinDate = (mpMaxDate + 1) + gapDays, odMaxDate = (odMinDate - 1) + odDays;
			$(".dateBox").each(function(index, element) {
                for(n = odMinDate; n <= odMaxDate; n++ ){
					$(".box"+n).addClass("od")
				}
            });	
			
			var odRadius = Math.round(circleCenter - svgStroke),
				odCircleLenght = Math.round(22/7 * (odRadius * 2)),
				odTextRadius = Math.round(circleCenter - 60),
				odTextCircleLenght = Math.round(22/7 * (odTextRadius * 2))
			
			
			odBgDegPos = odMinDate -1 ,
			odBgDeg = calcdeg * odBgDegPos,
			odCircProgPos = odMaxDate - odBgDegPos,
			odPrograss = Math.round((odCircleLenght / noOfDay) * odCircProgPos)	
			odTextPrograss = Math.round((odTextCircleLenght / noOfDay) * odCircProgPos)
			
			
			$(".odBg").css({'transform': 'rotate(' + odBgDeg + 'deg)'})	
			$(".odBg circle").css({'stroke-dasharray': + odPrograss +','+ odCircleLenght}).attr({ r : odRadius, cx : circleCenter, cy : circleCenter })
			$(".odBg .odTextBg").css({'stroke-dasharray': + odTextPrograss +',' + odTextCircleLenght}).attr({ r : odTextRadius, cx : circleCenter, cy : circleCenter })
			
			$(".dateBox.od:first").append('<div class="mpT">OD</div>')
			
			/*----------------------- start lp  ------------------------------------*/			
			
			var lpDays = noOfDay - odMaxDate;
			//console.log(noOfDay +" / "+ lpDays)
			var lpMinDate = odMaxDate + 1, lpMaxDate = (lpMinDate -1) + lpDays;
			
			if(lpMinDate > lpMaxDate){
					lpMaxDate = parseInt(lpMinDate + lpMaxDate)
				}
			
			$(".dateBox").each(function(index, element) {
                for(p = lpMinDate; p <= lpMaxDate; p++ ){
					$(".box"+p).addClass("lp")
				}
            });	
			
			var lpRadius = Math.round(circleCenter - svgStroke),
				lpCircleLenght = Math.round(22/7 * (lpRadius * 2)),
				lpTextRadius = Math.round(circleCenter - 60),
				lpTextCircleLenght = Math.round(22/7 * (lpTextRadius * 2))
			
			
			lpBgDegPos = lpMinDate -1 ,
			lpBgDeg = calcdeg * lpBgDegPos,
			lpCircProgPos = lpMaxDate - lpBgDegPos,
			lpPrograss = Math.round((lpCircleLenght / noOfDay) * lpCircProgPos)	
			lpTextPrograss = Math.round((lpTextCircleLenght / noOfDay) * lpCircProgPos)
			
			
			$(".lpBg").css({'transform': 'rotate(' + lpBgDeg + 'deg)'})	
			$(".lpBg circle").css({'stroke-dasharray': + lpPrograss +','+ lpCircleLenght}).attr({ r : lpRadius, cx : circleCenter, cy : circleCenter })
			$(".lpBg .lpTextBg").css({'stroke-dasharray': + lpTextPrograss +',' + lpTextCircleLenght}).attr({ r : lpTextRadius, cx : circleCenter, cy : circleCenter })
			
			$(".dateBox.lp:first").append('<div class="mpT">LP</div>')
			
			/*----------------------- end lp  ------------------------------------*/
			
			var EPDate = $(".dateBox.mp:first").attr("data-date")
			var EPMonth = $(".dateBox.mp:first").attr("data-month")
			$(".EP-Date").html(EPDate)
			$(".EP-Month").html(EPMonth)
			
			var EODate = $(".dateBox.od:first").attr("data-date")
			var EOMonth = $(".dateBox.od:first").attr("data-month")
			$(".EO-Date").html(EODate)
			$(".EO-Month").html(EOMonth)
			
			$(".EP-Date, .EO-Date").each(function(index, element) {
				getGetOrdinal($(this).text())
				var t;
				function getGetOrdinal(n) {
					var s=["th","st","nd","rd"],
					v=n%100;
					return t = (s[(v-20)%10]||s[v]||s[0]);
				 }
				$(this).next('sup').text(t)
            });
			lpTDays = $(".lp").length
        });
	}