<header class="headerwrp">
    <div class="container">
        <div class="col-md-6 col-sm-4 col-lg-6">
            <a class="logowrp" [ngClass]="logo=='logo' ? 'align-logo' : 'logos2'" href="/">
                <img src="assets/images/{{logo}}.png" alt="" class="img-responsive {{logo}}" />
            </a>

            <div class="tfourhours">
                <h2><a href="tel:1800-2122">1800 2122</a> 
                    <!-- <span>or <a href="javascript:void(0);" (click)="modelFunc()" class="getaCall">Get a call</a></span> -->
                </h2>
            </div>
            <div class="getacallModel" *ngIf="showModel" [ngStyle]="showModelStyle(showModel)">
                <div class="overlay"></div>
                <div class="getacallwrp">
                    <a href="javascript:void(0)" (click)="modelFunc()" class="gacallclose"></a>
                    <div class="GetaCallScrren gorbox" [style.display]="getaCallScreen ?'block':'none'">
                        <h2>Enter your mobile number to get a call from us.</h2>
                        <form [formGroup]="click2callMobileForm" method="post">
                            <ul>
                                <li>
                                    <input type="text" formControlName="mobileNumber" [ngClass]="{'has-error':!click2callMobileForm.controls.mobileNumber.valid && click2callMobileForm.controls.mobileNumber.touched}" (keypress)="isNumber($event)" name="mobileNumber" id="txtGetaCall" max="10"
                                        maxlength="10" placeholder="Your Mobile Number">
                                </li>
                                <li><input type="button" id="submitGetaCall" [disabled]="!click2callMobileForm.valid" (click)="submitClick2callMobileForm()" name="submitGetaCall" class="submithp" value="Request a call back" /></li>
                            </ul>
                        </form>
                    </div>
                    <div class="OTPScrren gorbox" [style.display]="OTPScreen ?'block':'none'">
                        <h2>Enter OTP sent to your mobile.</h2>
                        <form [formGroup]="click2callOTPForm" method="post">
                            <p [ngStyle]="{'color':'red'}">
                                {{otpErrorMsg}}
                            </p>
                            <ul>
                                <li><input type="text" name="otp" formControlName="otp" [ngClass]="{'has-error':!click2callOTPForm.controls.otp.valid && click2callOTPForm.controls.otp.touched}" (keypress)="isNumber($event)" id="txtGetaCallOTP" max="6" maxlength="6"
                                        placeholder="Enter OTP"></li>

                                <li><input type="button" id="submitOTP" name="submitOTP" [disabled]="!click2callOTPForm.valid" (click)="submitClick2callOTPForm()" class="submithp" value="Submit" /></li>
                            </ul>
                        </form>
                    </div>
                    <div class="ResultScrren gorbox" [style.display]="ThankyouScreen ?'block':'none'">
                        <h1>Thank You</h1>
                        <h2>You will receive a call shortly.</h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-sm-8 col-lg-6 content-wrap">
            <ul class="tonnavwrp">
                <!-- <li class="langDD"><a href="javascript:void()" class="knowmore3"><span class="iconLang2">English</span></a>
                    <div class="submenu360 lang dropdown-tggle">
                        <ul>
                            <li><a href="/hindi" target="_blank">Hindi</a></li>
                        </ul>
                    </div>
                </li> -->
                <li><a title="Emergency Services" href="/other/emergency-service"><span class="icon1 i2" ></span><span class="text">EMERGENCY  <br>SERVICE</span></a></li>
                <li class="internationalDD"><a title="International Patients" href="/international-patients/index.html" target="_blank"><span class="international i2" ></span><span class="text">INTERNATIONAL  <br> PATIENTS</span></a></li>
                <!-- <li class="virtualtourDD hidden-xs" style="display:none;"><a href="javascript:void()"><span class="virtualtour"></span></a>
                    <div class="submenu360 dropdown-tggle">
                        <ul>
                            <li *ngFor="let tour of virtualTourLink; let i=  index;">
                                <a href="javascript:void(0);" class="dd360">{{tour.city}}</a>
                                <ul class="ddown360">
                                    <li *ngFor="let sublink of tour.tourlink">
                                        <a href="{{sublink.link}}" target="_blank">{{sublink.subCity}}</a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </li> -->
                <li class="mhide">
                    <div class="navgroup visible-md visible-lg visible-sm visible-xs">
                        <ul class="iconnavtop">                            
                            
                            <!-- <li><a title="Child Safety" href="/child-care/child-safety"><span class="iconCSaftey"></span></a></li> -->
                            <li class="dropdowntn" style="display: none;"><a href="javascript:void(0)" class="dropdownSocial"><span class="iconSocial"></span></a>
                                <div class="submenuSocial dropdown-tggle">
                                    <ul style="display: none;">
                                        <li><a title="Facebook" [href]="fb_url" target="_blank" class="fb">&nbsp;</a></li>
                                        <li><a title="Twitter" [href]="twitter_url" target="_blank" class="tw">&nbsp;</a></li>
                                        <li><a title="YouTube" [href]="youtube_url" target="_blank" class="yt">&nbsp;</a></li>
                                        <li><a title="Linkedin" [href]="linkedin_url" target="_blank" class="ln">&nbsp;</a></li>
                                    </ul>
                                    <ul>
                                        <li><a title="whatsapp" href="https://api.whatsapp.com/send?phone=+919619079190&text={{ metaDis }}" target="_blank" class="wt">&nbsp;</a></li>
                                    </ul>
                                </div>
                            </li>
                            
                            <li><a title="Investor Relations" href="/investor-relations"><span class="iconinvestor"></span><span class="text">INVESTOR  <br> RELATIONS </span></a></li>
                            <li><a title="Our Centres Map" href="/our-centres"><span class="icon3"></span><span class="text">Our <br> Centers </span></a></li>
                            <li><a title="Doctor’s Appointment" href="all/book-an-appointment"><span class="iconAC"></span><span class="text">Doctor’s <br> Appointment </span></a></li>
                            <li><a title="Search Here" href="javascript:void(0)" (click)="searchBar($event)" id="searchbtn"><span class="icon2"></span> <span class="text">Search <br> our Site</span></a></li>
                            <!-- <li style="display: none;"><a title="International Patients" href="/international-patients" target="_blank"><span class="iconInt"></span></a></li> -->
                            <!-- <li><a title="Health Card" href="https://patientservices.rainbowhospitals.in/~healthcard/" target="_blank"><span class="icon4"></span></a> </li> -->
                        </ul>
                    </div>
                </li>
                <li><a title="Menu" href="javascript:void(0)" class="sidenavbtn"><span class="icon6"></span></a></li>
            </ul>

        </div>

        <div class="clearfix"></div>
        <div class="slidenavwrp {{hidden}}" >
            <ul id="snav">
                <li><a (click)='halfCircle("0")' class="nav-button navSelected">Rainbow Hospital</a>
                    <div class="img"><img src="assets/images/rainbow.png" alt="" /></div>
                </li>
                <li> <a (click)='halfCircle("1")' class="nav-button">Child Care</a>
                    <div class="img"><img src="assets/images/childcare.png" alt="" /></div>
                </li>
                <li> <a (click)='halfCircle("2")' class="nav-button navSelected">Women’s Health</a>
                    <div class="img"><img src="assets/images/womencare.png" alt="" /></div>
                </li>
                <li> <a (click)='halfCircle("3")' class="nav-button">Fertility Care</a>
                    <div class="img"><img src="assets/images/FertilityCare.png" alt="" /></div>
                </li>
            </ul>
            <a href="javascript:void(0);" id="sprev"><img src="assets/images/icon-nav-arrow-left-active.png" alt="" /></a>
            <a href="javascript:void(0);" id="snext"><img src="assets/images/icon-nav-arrow-right-active.png" alt="" /></a>
        </div>
        <div class="clearfix"></div>
    </div>
</header>

<!-- <div *ngIf="modelPupupCon">
    <div *ngFor="let mpopup of modelPupup" [innerHTML]="mpopup.hcode" class="modelPupup"></div>    
</div> -->
<!-- <div class="capopup CovidPandemicModelPopup" data-popup="popup-Covid19Pandemic" style="display: block;" *ngIf="modelPupupCon2">
    <div *ngFor="let mpopup of modelPupup" class="capopup-inner bodyCont dbg" [style]="dBgImage">
        <a class="capopuplink" (click)="CovidPandemicModelPopupLink(mpopup.link, mpopup.linktarget, mpopup.settime)"></a>
        <a class="capopup-close capopupClose" data-popup-close="popup-Covid19Pandemic" href="javascript:void(0);" style="position: absolute; top:15px"><img src="assets/images/icon-close2.png" alt="" /></a>
    </div>
    <div *ngFor="let mpopup of modelPupup" class="capopup-inner bodyCont mbg"  [style]="mBgImage">
        <a class="capopuplink" (click)="CovidPandemicModelPopupLink(mpopup.link, mpopup.linktarget, mpopup.settime)"></a>
        <a class="capopup-close capopupClose" data-popup-close="popup-Covid19Pandemic" href="javascript:void(0);" style="top:15px"><img src="assets/images/icon-close2.png" alt="" /></a>
    </div>
</div> -->