import { Observable,Subject } from 'rxjs';
// import { trigger } from '@angular/animations';
import { Inject, Component, OnDestroy, OnInit, AfterViewInit, PLATFORM_ID, ViewChildren, QueryList, HostListener } from '@angular/core';
import { isPlatformBrowser,DatePipe } from '@angular/common';
import { ApiService } from "../services/api.service";
import { IMG_BASE_URL } from "../services/constants";
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
// import { DoorgetsTruncateService } from 'doorgets-ng-truncate';
// import { TruncatePipe } from '../services/truncate';
import {DomSanitizer, SafeHtml, SafeUrl, SafeStyle} from '@angular/platform-browser';
// import { TruncateTextPipe } from '../services/truncatetext';

declare let $: any;
declare let initHome: any;
declare let initGoogleMap: any;
declare let initAllPage: any;
// declare let initHomePageVideoSection: any;
declare let initRightHighlightsSlider: any;
//declare let initHomeSpecialities: any;
//declare let initRainbowSlider: any;
//declare let initAboutSlider: any;
declare let initIvfSlider: any;
declare let destroyIvfSlider: any;
declare let initPopup: any;
@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styles: [
    `
      .data p {
        //margin-right: 14% !important;
      }
      .vediobg.active {
        // z-index:0 !important;
      }
      .knowmore {
        margin-left: 20px;
        // width:100px !important;
      }
      .knowmore img {
        display: inline-block;
      }
      .has-error {
        border: 1px solid red !important;
      }
      .icon-right {
        position: static !important;
        left: 135% !important;
        top: 5px;
      }
      input[disabled] {
        border: 1px solid rgba(61, 41, 121, 0.01) !important;
        background: rgba(61, 41, 121, 0.5) !important;
      }
      // .Rainbowslider{
      // 	padding-top:0 !important;
      // }
      .knowmore:hover::before {
        width: 130%;
      }
      .openingstaff_cont {
        transition: all 0.3s linear;
        opacity: 0;
        display: block !important;
      }
      .openingstaff_cont.active {
        opacity: 1;
      }
      .homePageContainer {
        z-index: 2;
      }
      footer.subscribewrp {
        display: none !important;
      }
    `,
  ],
})
export class HomeComponent implements OnDestroy, OnInit, AfterViewInit {
  public ivfsliderConfig: any;
  public coveringsliderConfig: any;
  public RainbowSliderConfig: any;
  public ratingSliderConfig: any;
  public videoSliderConfig: any;

  public ratingSliderList: any = [];
  public selectedCentreId = new Subject<any>();
  public ourSliderConfig: any = {
    slide: '.sitem',
    dots: false,
    arrows: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          dots: true,
        },
      },
    ],
  };
  constructor(
    private api: ApiService,
    public formBuilder: FormBuilder,
    public metaTitle: Title,
    public meta: Meta,
    private sanitization: DomSanitizer
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;

      require('../../assets/js/bootstrap.js');
      require('../../assets/js/fancybox/jquery.fancybox.min.js');

      require('../../assets/js/slick.min.js');
    }
  }
  public subscriptionForm: any = [];

  public popupForm: any = [];
  public currentwrpUL: any = 0;
  public events: any = [];
  public videoSections: any = [];
  public specialities: any = [];
  public news: any = [];
  public newsevents: any = [];
  public academics: any = [];
  public awards: any = [];
  public careers: any = [];
  public highlights: any = [];
  // public childHighlights:any = [];
  // public womenHighlights:any = [];
  // public fertilityHighlights:any = [];
  public rightHighlights: any = [];
  public leftHighlights: any = [];
  public hidden: string;
  public specialities_link: string;
  public speciality_slug: string;
  public logo: string = 'logo';
  public imgBasePath: any = IMG_BASE_URL;
  public about: any = [];
  public centerDetailList: any = [];

  public popupTitle: string;
  public popupMsg: string;
  disableSlider: boolean = true;
  public circleInterval: any;
  public homeEventList: any;
  public circleDate: any = [];
  public circleIntervalTimer: any = 20000;
  public successStories: any = [];
  public homePageInfo: any = {};
  public modelPupup: any;
  public modelPupupCon: boolean = true;
  public modelPupupCon2: boolean = true;
  public BASEURL: any;
  public dBgImage: any;
  public mBgImage: any;
  public iconurl: boolean = false;

  //console.log(this.circleIntervalTimer);
  //@ViewChildren('videoSection') videoSlider: QueryList<any>;
  //@ViewChildren('rightHightlightSection') rightHightlightSlider: QueryList<any>;
  @ViewChildren('homeSpecialities') homespecialities: QueryList<any>;
  @ViewChildren('academicSection') academicSection: QueryList<any>;
  //@ViewChildren('awardsSlider') awardSlider: QueryList<any>;
  @ViewChildren('academicsSlider') academicSlider: QueryList<any>;
  //@ViewChildren('ivfslider') ivfslider: QueryList<any>;
  @ViewChildren('ratingSlider') rating: QueryList<any>;

  // @ViewChildren('bannerCircle') bannerCircle: QueryList<any>;

  ngOnInit() {
    this.getMetaInfo();
    this.configFunction();
    this.validatePopupForm();
    this.staticInfo();
    this.getRatingSlider();
    this.api
      .getSucessStories()
      .map((res) => res)
      .subscribe(
        (res) => {
          this.successStories = res;
        },
        (err) => {
          console.error(err);
        }
      );

    this.api
      .getHomePageDetail()
      .map((res) => res)
      .subscribe(
        (res) => {
          //console.log('homepage info ====>',res);
          this.homePageInfo.knowyourduedate = res.filter((data) => {
            return data.slug == 'know-your-due-date';
          });
          this.homePageInfo.rainbowguidestar = res.filter((data) => {
            return data.slug == 'rainbowguidestar';
          });
          //console.log(this.homePageInfo.rainbowguidestar.list);
          //this.knowyourduedate.push(knowyourduedate);
          this.homePageInfo.insurance = res.filter((data) => {
            return data.slug == 'insurance';
          });
          this.homePageInfo.careerInfo = res.filter((data) => {
            return data.slug == 'career';
          });
          this.homePageInfo.mdProfile = res.filter((data) => {
            return data.slug == 'md-profile';
          });
        },
        (err) => {
          console.error(err);
        }
      );
    //this.validateSubscriptionForm();
  }

  public staticInfo() {
    this.api
      .getHomeCircleList()
      .map((res) => res)
      .subscribe((res) => {
        this.circleDate = res;
        setTimeout(() => {
          $('.text').removeClass('active');
          $('.text.tdata1').addClass('active');
        }, 100);
        //console.log(res);
      });
  }
  getModelPopup() {
    this.api.getAllPageModelPopup().subscribe(
      (data) => {
        let settime;
        if (data.length > 0) {
          this.BASEURL = IMG_BASE_URL;
          this.modelPupup = data;

          if (this.modelPupup[0].settime !== '') {
            settime = parseInt(this.modelPupup[0].settime);
          }
          if (this.modelPupup[0].css != null) {
            $('style').append(this.modelPupup[0].css);
          }

          if (settime !== null) {
            if (!$.cookie('DynamicHomeModelPopup')) {
              if (this.modelPupup[0].hcode == null) {
                this.modelPupupCon2 = true;
                this.modelPupupCon = false;
                this.dBgImage = this.sanitization.bypassSecurityTrustStyle(
                  `background:transparent url(` +
                    '/assets/images/covid19_pandemic_popup_d_.jpg' +
                    `)  no-repeat top center; background-size: contain;`
                );
                this.mBgImage = this.sanitization.bypassSecurityTrustStyle(
                  `background:transparent url(` +
				  '/assets/images/covid19_pandemic_popup_d_.jpg'+
                    `)  no-repeat top center; background-size: contain;`
                );
              } else {
                this.modelPupupCon2 = false;
                this.modelPupupCon = true;

                if (this.modelPupup[0].image != null) {
                  this.dBgImage =
                    'background:transparent url(' + '/assets/images/covid19_pandemic_popup_d_.jpg'+')  no-repeat top center; background-size: cover;';
                  $('style').append(
                    `.CovidModelPopup .bodyCont.impMes{` + this.dBgImage + `}`
                  );
                }
              }
              setTimeout(() => {
                $('.modelPupup .capopup').show();
              }, 1000);
              setTimeout(() => {
                $('.capopup-close').click(function (e) {
                  e.preventDefault();
                  $('.capopup').hide();

                  var date = new Date(),
                    minutes = settime;
                  date.setTime(date.getTime() + minutes * 60 * 1000);
                  $.cookie('DynamicHomeModelPopup', true, { expires: date });
                });
              }, 1200);
            }
          } else {
            if (this.modelPupup[0].hcode == null) {
              this.modelPupupCon2 = true;
              this.modelPupupCon = false;
              this.dBgImage = this.sanitization.bypassSecurityTrustStyle(
                `background:transparent url(` +
				'/assets/images/covid19_pandemic_popup_d_.jpg'+
                  `)  no-repeat top center; background-size: contain;`
              );
              this.mBgImage = this.sanitization.bypassSecurityTrustStyle(
                `background:transparent url(` +
				'/assets/images/covid19_pandemic_popup_d_.jpg'+
                  `)  no-repeat top center; background-size: contain;`
              );
            } else {
              this.modelPupupCon2 = false;
              this.modelPupupCon = true;

              if (this.modelPupup[0].image != null) {
                this.dBgImage =
                  'background:transparent url(' +
				  '/assets/images/covid19_pandemic_popup_d_.jpg'+
                  ')  no-repeat top center; background-size: cover;';
                $('style').append(
                  `.CovidModelPopup .bodyCont.impMes{` + this.dBgImage + `}`
                );
              }
            }
            setTimeout(() => {
              $('.modelPupup .capopup').show();
              $.removeCookie('DynamicHomeModelPopup');
            }, 1000);
            setTimeout(() => {
              $('.capopup-close').click(function (e) {
                e.preventDefault();
                $('.capopup').hide();
              });
            }, 1200);
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  CovidPandemicModelPopupLink(link, target, settime) {
    $('.capopup').hide();
    if (settime !== null) {
      var date = new Date(),
        minutes = settime;
      date.setTime(date.getTime() + minutes * 60 * 1000);
      $.cookie('DynamicModelPopup', true, { expires: date });
    }
    window.open(link, target);
  }
  getRatingSlider() {
    this.api.getRatingSlider().subscribe(
      (res) => {
        //console.log(res);
        this.ratingSliderList = res;
      },
      (err) => {
        console.error(err);
      }
    );
  }
  getMetaInfo() {
    this.api.getPageDescription('home').subscribe(
      (data) => {
        if (data) {
          //console.log(data);
          this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
          this.meta.updateTag({
            name: 'keywords',
            content: data.page_descriptions[0].meta_keyword,
          });
          this.meta.updateTag({
            name: 'description',
            content: data.page_descriptions[0].meta_description,
          });
          this.meta.addTags([
            {
              property: 'og:title',
              content: data.page_descriptions[0].meta_title,
            },
            {
              property: 'og:description',
              content: data.page_descriptions[0].meta_description,
            },
            {
              property: 'og:keywords',
              content: data.page_descriptions[0].meta_keyword,
            },
          ]);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  ng2PipeFilter(value, format) {
    var datePipe = new DatePipe('en-US');
    //let filteredDate = datePipe.transform(this.carrenstime, 'y-MM-dd HH:mm:ss');
    //console.log(filteredDate);
    let valueDate = new Date(value);
    return datePipe.transform(valueDate, format);
  }
  jqueryLoad() {
    var $body = $('body');
    var $Rainbowslider = $body.find('.Rainbowslider');
    var $sstorieslider = $body.find('.sstorieslider');
    var $Acadmeicslider = $body.find('.Acadmeicslider');
    var $rainbowslider = $body.find('.rainbowslider');
    var $ratingSlider = $body.find('.ratingSlider');
    //$body.animate({ scrollTop: $('.ospesltyaccord').offset().top - 80 });

    $Rainbowslider
      .removeClass('slick-slider')
      .removeClass('slick-initialized')
      .removeClass('slider');
    $Rainbowslider.not('.slick-initialized').slick({
      slide: '.item',
      dots: false,
      arrows: true,
      infinite: false,
      prevArrow:
        '<a class="slick-prev"><img src="assets/images/btn-arrow-left.png" alt="" /></a>',
      nextArrow:
        '<a class="slick-next"><img src="assets/images/btn-arrow-right.png" alt="" /></a>',
      slidesToShow: 1,
      slidesToScroll: 1,
    });

    $sstorieslider
      .removeClass('slick-slider')
      .removeClass('slick-initialized')
      .removeClass('slider');
    $sstorieslider.not('.slick-initialized').slick({
      slide: '.ssitem',
      dots: true,
      arrows: false,
      infinite: false,
      slidesToShow: 2,
      slidesToScroll: 2,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            arrows: true,
            dots: true,
            aptiveHeight: true,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            arrows: true,
            dots: true,
            aptiveHeight: true,
          },
        },
        {
          breakpoint: 620,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            dots: true,
            aptiveHeight: true,
          },
        },
      ],
    });

    $Acadmeicslider
      .removeClass('slick-slider')
      .removeClass('slick-initialized')
      .removeClass('slider');
    // $(".Acadmeicslider").slick("unslick");
    $Acadmeicslider.not('.slick-initialized').slick({
      slide: '.item',
      dots: false,
      arrows: true,
      infinite: false,
      prevArrow:
        '<a class="slick-prev"><img src="assets/images/btn-arrow-left.png" alt="" /></a>',
      nextArrow:
        '<a class="slick-next"><img src="assets/images/btn-arrow-right.png" alt="" /></a>',
      slidesToShow: 1,
      slidesToScroll: 1,
    });

    $rainbowslider
      .removeClass('slick-slider')
      .removeClass('slick-initialized')
      .removeClass('slider');
    $rainbowslider.not('.slick-initialized').slick({
      slide: '.rbitem',
      dots: false,
      arrows: false,
      infinite: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            dots: true,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            dots: true,
          },
        },
      ],
    });
  }
  creadmFunc(event) {
    $('html, body').animate(
      { scrollTop: $('.ourSpecialitieswrp').offset().top - 40 },
      2000
    );
  }
  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
      //initAboutSlider();
    }

    this.changeContent('0');
    this.homespecialities.changes.subscribe((t) => {});
    // this.awardSlider.changes.subscribe(t =>{
    // 	//initAwardsSlider();
    // });

    this.academicSlider.changes.subscribe((t) => {
      //initAwardsSlider();
    });
    this.academicSection.changes.subscribe((t) => {
      this.jqueryLoad();
    });
    this.rating.changes.subscribe((t) => {
      // initHomePageVideoSection();
      // initAwardsSlider();
    });

    this.circleInterval = Observable.interval(
      this.circleIntervalTimer
    ).subscribe((res) => {
      this.autoCircle();
    });
    this.api.getHomeEvents().subscribe((res) => {
      this.homeEventList = res.home_events;
    });
    /*this.api.getHomePageVideos().subscribe(
			data => {
				this.videoSections = data.video_sections;
			},
			error => {
				console.log(error);
			}
		)*/
    this.api.getHomeLeftHighlights().subscribe(
      (data) => {
        this.leftHighlights = data;
      },
      (error) => {
        console.log(error);
      }
    );

    this.api.getHomeRightHighlights().subscribe(
      (data) => {
        this.rightHighlights = data.right_highlights;
        this.circleIntervalTimer = this.rightHighlights.length * 1500;
      },
      (error) => {
        console.log(error);
      }
    );
    this.api.getHomeNewsEvents().subscribe(
      (data) => {
        console.log('newsevents News:', data);
        this.news = data.home_events;
      },
      (error) => {
        console.log(error);
      }
    );
    this.api.getEvents().subscribe(
      (data) => {
        //console.log('newsevents Events:', data);
        this.events = data.home_events;
      },
      (error) => {
        console.log(error);
      }
    );

    this.api.getHomeAwards().subscribe(
      (data) => {
        this.awards = data.awards_histories;
      },
      (error) => {
        console.log(error);
      }
    );

    this.api.getHomeAcademics().subscribe(
      (data) => {
        this.academics = data.group_sections;
      },
      (error) => {
        console.log(error);
      }
    );

    this.api.getHomeCareers().subscribe(
      (data) => {
        this.careers = data.careers;
      },
      (error) => {
        console.log(error);
      }
    );
    this.api.getCenters().subscribe(
      (res) => {
        res.centers.map((data, key) => {
          let center = this.api.getFilteredCenterData(data);

          this.centerDetailList.push(center);
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }
  onNotify(id: any): void {
    $('.no[data-circle="' + id + '"]').trigger('click');
  }
  autoCircle() {
    //var self = this;

    if ($('.no').hasClass('active')) {
      var $attrCircleValue = $('.no.active').attr('data-circle');
      $attrCircleValue++;
      if ($attrCircleValue > 4) {
        $('[data-circle="1"]').trigger('click');
        this.changeContent('0');
        /*
					this.changeContent("0");
					$('.no').removeClass('active');
					
					$(".vd0, .vd1, .vd2, .vd3").removeClass("active");
					$(".bannerCircle .st0").removeClass("active0 active1 active2 active3");
					
					$('.text').removeClass('active');
					$(".text.first").addClass("active");
					*/
      } else {
        $('.no[data-circle="' + $attrCircleValue + '"]').trigger('click');
      }
    } else {
      $('[data-circle="1"]').trigger('click');
    }
  }
  isNumber(evt) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  validatePopupForm() {
    this.popupForm = this.formBuilder.group({
      name: [''],
      email: ['', Validators.required],
      phone: [''],
    });
  }
  submitValidatePopupForm() {
    if (this.popupForm.value) {
      this.api.postPoupForm(this.popupForm.value).subscribe(
        (res) => {
          if (res.status_code == 200) {
            //	console.log('=====> Thankyou');
            $('.homepopup, .backgroundPopup').fadeOut();
          } else if (res.status_code == 406) {
            //	console.log('====>email address exist');
            $('.homepopup, .backgroundPopup').fadeOut();
          } else {
            //	console.log('======> error');
            $('.homepopup, .backgroundPopup').fadeOut();
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  @HostListener('window:load', [])
  onWindowLoad() {
    this.getModelPopup();

    $('.fancybox').fancybox({
      type: 'iframe',
      maxWidth: 700,
      maxHeight: 600,
      fitToView: false,
      width: '70%',
      height: '70%',
      autoSize: false,
      closeClick: false,
      openEffect: 'none',
      closeEffect: 'none',
    });
    $('.aboutslider')
      .removeClass('slick-slider')
      .removeClass('slick-initialized')
      .removeClass('slider');
    $('.aboutslider')
      .not('.slick-initialized')
      .slick({
        slide: '.abitem',
        dots: false,
        arrows: false,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              arrows: true,
              dots: true,
              adaptiveHeight: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: true,
              dots: true,
              adaptiveHeight: true,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: true,
              dots: true,
              adaptiveHeight: true,
            },
          },
        ],
      });
  }

  youtube_parser(url) {
    var regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    var match = url.match(regExp);
    return match && match[7].length == 11 ? match[7] : false;
  }
  //('{{imgBasePath}}{{slide.image}}');
  highBgImage(img) {
    return `${this.imgBasePath}${img}`;
  }
  slider(id: any, $event) {
    //console.log($event.isTrusted);
    if ($event.isTrusted) {
      this.circleInterval.unsubscribe();
    }
    this.changeContent(id);
    //	this.circleInterval.unsubscribe();
  }
  changeContent(param: any) {
    let speciality: any;
    switch (param) {
      case '1':
      case 1:
        speciality = 'Child Care';
        this.specialities_link = 'child-care/specialities';
        break;
      case '2':
      case 2:
        speciality = 'Women Health';
        this.specialities_link = 'women-care/specialities';
        break;
      case '4':
      case 4:
        speciality = 'Fertility Care';
        this.specialities_link = 'fertility-care/treatments';
        break;
      default:
        speciality = 'default';
        param = 'default';
        this.specialities_link = 'our-specialities';
        break;
    }
    //console.log(param);
    if (param == 'default') {
      this.api.getHomeDefaultSpecialityFeatures().subscribe(
        (data) => {
          this.specialities = data.speciality_masters.speciality_features;
        },
        (error) => {
          console.log(error);
        }
      );

      this.api.getHomeDefaultHighlights().subscribe(
        (data) => {
          //console.log('----Jarvis----', data);
          this.highlights = data.speciality_masters.home_highlights;
          //console.log(this.highlights);
        },
        (error) => {
          console.log(error);
        }
      );

      this.api.getHomeDefaultAboutUs().subscribe(
        (data) => {
          this.about = data.speciality_masters.about_home[0];
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      this.api.getHomeHighlights(speciality).subscribe(
        (data) => {
          console.log(data);
          this.highlights = data.speciality_masters[0].home_highlights;
          // destroyIvfSlider();
        },
        (error) => {
          console.log(error);
        }
      );

      this.api.getHomeAboutDetails(param).subscribe(
        (data) => {
          this.about = data.about_homes[0];
          //console.log(this.about);
        },
        (error) => {
          console.log(error);
        }
      );

      this.api.getHomeSpecialityFeatures(param).subscribe(
        (data) => {
          this.specialities = data.our_speciality_features;
          // this.speciality_slug = data.speciality_masters[0].slug;

          // alert("data fetched " + this.speciality_slug);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }
  public configFunction() {
    this.ivfsliderConfig = {
      dots: true,
      arrows: false,
      infinite: true,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    this.coveringsliderConfig = {
      dots: true,
      arrows: false,
      infinite: true,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    this.RainbowSliderConfig = {
      slide: '.item',
      dots: false,
      arrows: true,
      infinite: true,
      prevArrow: `<a class="slick-prev"><img src="${IMG_BASE_URL}/assets/images/btn-arrow-left.png" alt="" /></a>`,
      nextArrow: `<a class="slick-next"><img src="${IMG_BASE_URL}/assets/images/btn-arrow-right.png" alt="" /></a>`,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    this.ratingSliderConfig = {
      slide: '.sitem',
      dots: true,
      arrows: false,
      infinite: true,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    this.videoSliderConfig = {
      slide: '.sitem',
      dots: true,
      arrows: false,
      infinite: true,
      speed: 2000,
      autoplaySpeed: 5000,
      autoplay: true,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
  }

  ngOnDestroy() {}
}
