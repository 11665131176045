<table cellpadding="0" cellspacing="0" border="0">
    <tr>
        <th>Date</th>
        
        <th>Morning Timing</th>
        
        <th>Evening Timing</th>
        <!-- <th>Availability</th> -->
    </tr>
    <ng-template ngFor let-slot [ngForOf]="sessionSlot" let-i="index">
        <tr>		
            <td>
                <a href="javascript:void(0)" class="viewtm" data-id="date-{{i}}">
                    {{slot.A | amDateFormat: 'ddd, DD MMM YYYY'}}
                </a>
            </td>
            <td [ngClass]="{'disabled':!slot.C}">
                <ng-template ngFor let-slotB [ngForOf]="getSplitValue(slot.B)" let-ind="index">
                    
                    <h3 *ngIf="slot.E">{{getSplitValue(slot.E)[ind]}}</h3>
                    <input type="hidden" value="{{slot.A | amDateFormat:'DD/MM/YYYY'}}" #hiddenBookingDate>
                    <input type="hidden" value="{{getSplitValue(slot.B)[ind]}}" #hiddenSessionMorningId>
                    <a *ngIf="!getSplitValue(slot.F)[ind]" href="javascript:void(0)" class="viewtm btnview" data-id="date-{{i}}" 
                    (click)="takeDatePicker(hiddenBookingDate.value, hiddenSessionMorningId.value, getSplitValue(slot.C)[ind])" [attr.disabled]="!getSplitValue(slot.C)[ind]">
                        <span *ngIf="getSplitValue(slot.C)[ind]">
                        {{getSplitValue(slot.C)[ind]}}
                        </span>
                        <span *ngIf="!getSplitValue(slot.C)[ind]">
                            Not Available
                        </span>   
                    </a>
                    <a *ngIf="getSplitValue(slot.F)[ind]" href="javascript:void(0);" (click)="closeModal()"  class="viewtm btnview" >
                        <span>
                            Find Doctor
                        </span>
                    </a>
                    <span *ngIf="getSplitValue(slot.F)[ind]" style="display:block;font-size: 13px;margin-top:1rem;line-height: 1.8rem;" [innerHTML]="getSplitValue(slot.F)[ind]"></span>
                </ng-template> 
            </td>

            <td [ngClass]="{'disabled':!slot.H}">
                <ng-template ngFor let-slotB [ngForOf]="getSplitValue(slot.G)" let-ind="index">
                    <h3 *ngIf="slot.J">{{getSplitValue(slot.J)[ind]}}</h3>
                    
                    <input type="hidden" value="{{slot.A | amDateFormat:'DD/MM/YYYY'}}" #hiddenBookingDate>
                    <input type="hidden" value="{{getSplitValue(slot.G)[ind]}}" #hiddenSessionEveningId>
                    <a *ngIf="!getSplitValue(slot.K)[ind]" href="javascript:void(0)" class="viewtm btnview" data-id="date-{{i}}" 
                    (click)="takeDatePicker(hiddenBookingDate.value, hiddenSessionEveningId.value, getSplitValue(slot.H)[ind])" [attr.disabled]="!slot.H">
                        <span *ngIf="getSplitValue(slot.H)[ind]">
                            {{getSplitValue(slot.H)[ind]}}
                        </span>
                        <span *ngIf="!getSplitValue(slot.H)[ind]">
                            Not Available
                        </span>
                    </a>
                    <a *ngIf="getSplitValue(slot.K)[ind]" href="javascript:void(0);" (click)="closeModal()" class="viewtm btnview" >
                        <span>
                            Find Doctor
                        </span>
                    </a>
                    <span *ngIf="getSplitValue(slot.K)[ind]" style="display:block;font-size: 13px;margin-top:1rem;line-height: 1.8rem;" [innerHTML]="getSplitValue(slot.K)[ind]"></span>
                </ng-template>
            </td>
            <!-- <td><a href="javascript:void(0)" class="viewtm btnview" [attr.data-id]="'date-'+i">Book</a></td> -->
        </tr>
    </ng-template>
</table>