<section class="pagebcwrp">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-lg-12">
        <ul>
          <li *ngFor="let link of bread_crumb_links" class="{{link.class}}">
            <a *ngIf="link.link" href="{{link.link}}">{{link.label}}</a>
            <span *ngIf="!link.link">{{link.label}}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
