<div class="container-fluid">
  <div class="loder"></div>
  <!-- Page Content -->
  <section class="cosp-first">
    <div class="row header w-full">
      <div class="container mx-auto w-full px-0" style="margin-bottom: -2em">
        <a href="https://rainbowhospitals.in/" target="_blank"><img src="assets/images/RCH-logo.png" alt=""
            style="transform: scale(75%)" /></a>
      </div>
    </div>
    <div class="cosp-hero-container">
      <div class="cosp-hero-column-outer-container">
        <div class="cosp-hero-column-container-bg">
          <!-- <img src="" /> -->
        </div>
        <div class="container">
          <div class="cosp-hero-column-container row">
            <div class="row col-sm-12 mx-auto">
              <div class="cosp-hero-columns col-lg-4 col-sm-12">
                <!-- <h1 class="cosp-hero-title">
                  Conference on Updates In Special Pediatrics
                </h1> -->
              </div>
              <div class="cosp-hero-columns col-lg-4 col-sm-12">
                <!-- <h2 class="cosp-hero-sub-title">Conference Highlights</h2>
                <ul class="cosp-highlights">
                  <li>Current hot topics in Pediatrics</li>
                  <li>State-of-the-art <b>Workshops</b> in critical care</li>
                  <li>
                    <b> Potpourri of debates </b>,
                    <b>lectures, </b>
                    <b>panel discussions</b>
                    and <b>clinicopathological correlation.</b>
                  </li>
                  <li>Renowned <b>international and national speakers</b></li>
                  <li>
                    <b>Interesting cases from various subspecialities</b>
                  </li>
                  <li>
                    <b>Opportunity to network</b> with experts in the field
                  </li>
                </ul>
                <h2 class="cosp-hero-sub-title">WHO SHOULD ATTEND?</h2>
                <ul class="cosp-highlights">
                  <li>General pediatricians</li>
                  <li>Pediatric medical sub-specialities</li>
                  <li>Post Graduates in Training</li>
                </ul> -->
                <img src="assets/rapid/rapid_logo.svg" style="width: 100%" />
              </div>
              <div class="cosp-hero-columns col-lg-4 col-sm-12">
                <div class="cosp-form-outer-container" style="min-height: 100%">
                  <div id="form"
                    style="min-height: 100%; display: flex; flex-direction: column; align-items: center; justify-content: center;">
                    <div class="headText">
                      Registration Update
                    </div>
                    <h3 style="margin-top: auto; margin-bottom: auto;">
                      We are currently not accepting any new registrations on the website for now. For any queries
                      please contact us at
                      
                      For Registration contact <br />
                      Mr. Shakti:
                      
                      <a href="tel:+918884436021">+91 8884436021</a>
                      & <br />
                      Mr Karthikeyan:
                      <a href="tel:+919880094313">+91 9880094313</a>

                    </h3>
                  </div>
                  
                </div>
              </div>
            </div>
            <div class="cosp-hero-columns col-md-12" style="width: 100%; overflow: hidden">
              <div class="cosp-location-strip" style="width: 100%; overflow: hidden">
                <div class="row mx-auto" style="text-align: center">
                  <div class="col-sm-12 col-md-4">
                    <!-- <div class="cosp-strip-icon">
                      <img src="" />
                    </div> -->
                    <h3>12<sup>th</sup> FEB 2023</h3>
                  </div>
                  <div class="col-sm-12 col-md-4">
                    <!-- <div class="cosp-strip-icon">
                      <img src="" />
                    </div> -->
                    <h3>JW Marriott Hotel , Bengaluru</h3>
                  </div>
                  <div class="col-sm-12 col-md-4">
                    <!-- <div class="cosp-register-now-btn">
                      <button class="cosp-reg-now">Register Now</button>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="initiate-payment-for">
    <!-- <form action="https://secure.payu.in/_payment" method="post" #form></form> -->
    <form action="https://rainbow.outshade.com/payment/success/" method="post" #form>
      <input type="hidden" id="i-p-f-key" name="key" />
      <input type="hidden" id="i-p-f-txnid" name="txnid" />
      <input type="hidden" id="i-p-f-productinfo" name="productinfo" />
      <input type="hidden" id="i-p-f-amount" name="amount" />
      <input type="hidden" id="i-p-f-email" name="email" />
      <input type="hidden" id="i-p-f-firstname" name="firstname" />
      <input type="hidden" id="i-p-f-lastname" name="lastname" />
      <input type="hidden" name="surl" value="https://rainbow.outshade.com/payment/success/" />
      <input type="hidden" name="furl" value="https://rainbow.outshade.com/payment/failure/" />
      <input type="hidden" id="i-p-f-phone" name="phone" />
      <input type="hidden" id="i-p-f-hash" name="hash" />
      <input type="hidden" id="i-p-f-udf1" name="udf1" />
      <input type="hidden" id="i-p-f-udf2" name="udf2" />
      <input type="hidden" id="i-p-f-udf3" name="udf3" />
      <input type="hidden" id="i-p-f-udf4" name="udf4" />
      <input type="hidden" id="i-p-f-udf5" name="udf5" />
      <input type="submit" value="click to proceed" (click)="form.submit()" />
      <button (click)="closePopUp()">close</button>
    </form>
  </section>
  <section>
    <div class="container ">
      <h1 style="color: #7c3996; text-align: center; font-size: 30px; margin-bottom: -1em;">
        Workshop Details
      </h1>
      <div class="row w-full cosp-table-container">
        <table class="table table-striped table-bordered">
          <thead>
            <tr>
              <!-- <th scope="col">
                                                                                                                                          SESSION I : 9:30 - 11:15 A.M <br />
                                                                                                                                          CHAIRPERSONS: Dr.Prakash Vemgal, Dr.Naveen Kini,
                                                                                                                                          Dr.Priya Shivalli
                                                                                                                                        </th> -->
            </tr>
          </thead>
          <tbody>
            <col />

            <col />

            <col />
            <tr>
              <td>
                <p>
                  <b>Time</b>
                </p>
              </td>
              <td>
                <p>
                  <b>Topic</b>
                </p>
              </td>
              <td>
                <p>
                  <b>Speaker</b>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  8:30 - 9:00 am</p>
              </td>
              <td>
                <p>
                  Registration &amp; Welcome
                  address</p>
              </td>
              <td>
                <p>
                  <br />
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  9:00 - 9:30 am</p>
              </td>
              <td>
                <p>
                  <b>Guest
                    Lecture (International)</b>
                </p>
                <p><b>Learn
                    from the legends</b></p>
                <p><b>Topic:
                    Effect of maternal anemia on fetus</b></p>
                <p><u>Chairperson</u></p>
                <p>Dr
                  Srimathy</p>
              </td>
              <td>
                <p>
                  Dr Srikanth Nagalla</p>
              </td>
              </tr>
              <tr>
                <td>
                <p>
                  9:30 - 10:15 am</p>
              </td>
              <td>
                <p>
                  <b>Symposium
                  </b>(<b>Saving
                    the most vulnerable</b>)
                </p>
                <p><b>Topic:</b></p>
                <p><b><span>Perinatal
                      management of extreme prematurity</span></b></p>
                <p><u>Chairperson</u></p>
                <p>1.
                  Dr Shilpa GB</p>
                <p>2.
                  Dr G Guruprasad</p>
              </td>
              <td>
                <ol class="ml-n5">
                  <li>
                    <p>
                      Fetal medicine insights
                      into extreme prematurity</p>
                    <p> Dr
                      Preiti Venkatesh</p>
                  </li>

                  <li>
                    <p>Labour
                      decisions: delay or deliver</p>
                  </li>
                  <p>
                    Dr Praveena Shenoi</p>

                  <li>
                    <p>NICU
                      course and long-term outcomes</p>
                    <p style="margin-left: 0.07cm"> Dr
                      Praveen Kumar</p>
                  </li>
                  </ol>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  10:15 - 11:00 am</p>
              </td>
              <td>
                <p>
                  <b>Panel
                    discussion</b>
                </p>
                <p><b>Topic:
                    Caught Unawares</b></p>
                <p><b>Moderator:</b>
                  p>
                <p><u>Chairpersons</u></p>
                <p>1.
                  Dr Anitha Rao</p>
                <p>2.
                  Dr Shwetha S Kamath</p>
              </td>
              <td>
                <p>
                  <br />

                </p>
                <p>Dr
                  Meghana Reddy</p>
                <p>Dr
                  Preiti Venkatesh</p>
                <p>Dr
                  Pranathi Reddy</p>
                <p>Dr
                  G Guruprasad</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  11:00 - 11:30 am</p>
              </td>
              <td>
                <p>
                  <b>Guest
                    Lecture</b>
                </p>
                <p><b>Insights
                    and eye openers from neonatal database</b></p>
                <p><u>Chairperson</u></p>
                <p>Dr Dinesh Chirla</p>
              </td>
              <td>
                <p>
                  Dr Praveen Kumar</p>
              </td>
              </tr>
              <tr>
                <td>
                <p>
                  11:30 - 11:45 am</p>
              </td>
              <td>
                <p>
                  Inauguration/Tea break</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  11:45 - 12:15pm
                </p>
              </td>
              <td>
                <p>
                  <b>Symposium</b>
                </p>
                <p><b>Mythical
                    Murmur</b></p>
                <p><u>Chairpersons</u></p>
                <p>Dr
                  Prakash Vemgal</p>
                <p></p>

              </td>
              <td>
                <ol class="ml-n5">
                  <li>
                    <p>
                      Antenatal diagnosis of
                      heart disease</p>
                    <p>
                      Dr Adinarayana Makam</p>
                  </li>


                  <li>
                    <p>Nonsurgical
                      management of critical congenital heart disease</p>
                    <p> Dr
                      Jayaranganath M</p>
                  </li>
                  </ol>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  12:15-12:45 pm</p>
              </td>
              <td>
                <p>
                  <b>Guest
                    Lecture </b><b>(</b><b>Preach
                    from PARAGON)</b>
                </p>
                <p><b>Genetic
                    insights in perinatal medicine</b></p>
                <p><u>Chairperson</u></p>
                <p> </p>
                <p>
                  Dr Preetha Isloor</p>
              </td>
              <td>
                <p>
                  Dr Ratna Dua Puri</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  12:45-1:15 pm</p>
              </td>
              <td>
                <p>
                  Evolution in neonatal
                  health care :20 years Rainbow experience
                </p>
              </td>
              <td>
                <p>
                  Dr Babu S Madarkar</p>
                <p>Dr
                  Arvind Shenoi</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  1:15 - 2 pm</p>
              </td>
              <td>
                <p>
                  <b>LUNCH</b>
                </p>
              </td>
              <td>
                <p>
                  <br />
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  2:00 - 3:00 pm</p>
              </td>
              <td>
                <p>
                  <b>Symposium
                  </b><b>(Saving
                    the baby’s brain)</b>
                </p>
                <p><b>Topic:</b></p>
                <p><b>Intrauterine
                    hypoxia: clinical consequences and therapeutic perspectives</b></p>
                <p><u>Chairperson</u></p>
                <p>1.
                  Dr Shailaja</p>
                <p>2.
                  Dr Geeta B</p>
              </td>
              <td>
                <ul class="ml-n5">
                  <li>
                    <p>
                      <u><b>International
                          guest speaker: </b></u>Intrapartum
                      strategies to reduce perinatal asphyxia
                    </p>
                    <p>
                      Dr
                      Rame
                    </p>
                  </li>

                  <li>
                    <p>Understanding
                      chronic hypoxemia in fetus:
                    </p>
                    <p>
                      Dr Adinarayana Makam
                    </p>
                  </li>
                  <li>
                    <p>Recent
                      updates in management of perinatal asphyxia-
                      <br />
                      Dr Dinesh Chirla</p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  3:00 - 4:15 pm</p>
              </td>
              <td>
                <p>
                  <b>Symposium
                  </b>(<b>Care
                    of the Deviant)</b>
                </p>
                <p>Honchoing:
                  <b>Fetal Anomalies</b>
                <p>
                <p><u>Chairperson</u></p>
                <p>Dr
                  Lata Venkataram</p>
                <p>Dr
                  V Madhura Geethika</p>
              </td>
              <td>
                <ol class="ml-n5">
                  <li>
                    <p>
                      Obstetrician’s
                      perspective of fetal anomaly- what to do? when to do? -
                      <br /> Dr Pranathi Reddy
                    </p>
                  <li>
                    <p>Managing
                      labour in anomalous fetus: special situations -
                      <br />
                      Dr Srimathy</p>
                      <li>
                        <p>Intrauterine
                          procedures including EXIT</p>

                    <p>
                      Dr Ramamurthy B S</p>
                  <li>
                    <p>Postnatal
                      Management of CDH <br /> Dr Antony Robert C</p>
                  <li>
                    <p>Postnatal
                      Management of TEF <br /> Dr Mukunda Ramachandra</p>
                </ol>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  4:15 - 5:00 pm</p>
              </td>
              <td>
                <p>
                  <b>Clinical
                    Snippets</b>
                </p>
              </td>
              <td>
                <p>
                  Dr Sridhar, Dr Pradeep, Dr
                  S
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  5:00 - 5:30 pm</p>
              </td>
              <td>
                <p>
                  <b>Perinatology
                    Quiz</b>
                </p>
              </td>
              <td>
                <p>
                  Dr Saravanan, Dr Srikanth
                  Kulkarni, Dr Sandeep R
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
  <footer class="contact-us-section">
    <div style="width: 100%; background-color: #231745; padding: 2em 0px">
      <div class="container">
        <div class="row mx-auto">
          <div class="col-lg-4 col-sm-12 cosp-con-columns">
            <p>
              For Registration contact <br />
              Mr. Shakti:

              <a href="tel:+918884436021">+91 8884436021</a>
              & <br />
              Mr Karthikeyan:
              <a href="tel:+919880094313">+91 9880094313</a>
            </p>
          </div>
          <div class="col-lg-4 col-sm-12 cosp-con-columns">
          </div>
          <div class="col-lg-4 col-sm-12 cosp-con-columns">
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!-- Page Content -->
  <!-- Page Hello doctor -->
</div>
<!-- <div *ngIf="isPending" class="pay-pop-up-container">
  <h1>Your Payment is processing</h1>
</div> -->
<div class="cosp-doc-info-pop-up" id="dr-kumran">
  <div class="doc-info-image-container">
    <img src="assets/images/conference-on-special-pediatrics/dr-kumran.jpg" />
  </div>
  <p>
    <b>Dr Kumaran Deiva MD,PhD <br />
      Head of Department and Consultant Pediatric Neurologist <br />
      Paris South University Hospitals <br />
      Paris, France <br /></b>
  </p> <br />
  <p>He is the national coordinator for neuro-inflammatory diseases in children in France since 2007. He is in charge of
    the national cohort of demyelinating diseases in children. His research focuses on immunological mechanism
    underlying demyelinating diseases. He leads the neuro-immune diseases group in the European Reference Networks-RITA
    and the European consortium on acute demyelinating diseases with anti-MOG antibodies.</p>
  <br />
  <div class="cosp-register-now-btn">
    <button class="close-doc-pop-up-button">Close</button>
  </div>
</div>
<div class="cosp-doc-info-pop-up" id="dr-steven">
  <div class="doc-info-image-container">
    <img src="assets/images/conference-on-special-pediatrics/dr-steven.jpg" />
  </div>
  <p>
    <b>Steven M. Holland, M.D. <br />
      Director, Division of Intramural Research <br />
      Chief, Immunopathogenesis Section <br />
      National Institute of Allergy & Infectious Diseases <br />
      National Institutes of Health, <br /></b>
  </p> <br />
  <p>
    <span>Bethesda, USA </span><br />
    His work is centred on the pathogenesis and management of chronic granulomatous disease, as well as other congenital
    immune defects affecting phagocytes, including those predisposing to mycobacterial diseases. He was chief of LCID
    from 2004 to 2016 and was selected as DIR director in 2016. Dr Holland Served as the president of ICHS from 2006 –
    2008.
  </p>
  <br />
  <div class="cosp-register-now-btn">
    <button class="close-doc-pop-up-button">Close</button>
  </div>
</div>
<div class="cosp-doc-info-pop-up" id="dr-carcillo">
  <div class="doc-info-image-container">
    <img src="assets/images/conference-on-special-pediatrics/dr-crucilo.jpg" />
  </div>
  <p>
    <b>
      Dr Joseph Carcillo <br />
      Professor of Critical Care Medicine and Pediatrics <br />
      University of Pittsburgh <br />
      USA <br />
    </b>
  </p> <br />
  <p>Dr Carcillo is a Pediatric Intensive Care physician <br />
    from the University of Pittsburgh in the United States who studies sepsis and multiple organ dysfunction. He has
    authored over 340 PubMed manuscripts in this regard. He is also a founding member of the ACCM taskforce guidelines
    for hemodynamic support in neonatal and pediatric septic shock, The Surviving Sepsis Campaign, The SCCM/ESICM
    pediatric multiple organ system dysfunction syndrome definitions taskforce, and the SCCM/EISCM pediatric sepsis
    definition taskforce. He is presently funded by the NIGMS to study pediatric sepsis phenotypes, and the NICHD to
    study the role of GM-CSF and anakinra study in these phenotypes in the PRECISE trial. </p>
  <br />
  <div class="cosp-register-now-btn">
    <button class="close-doc-pop-up-button">Close</button>
  </div>
</div>
<div class="cosp-register-now-btn floating-button">
  <button class="cosp-reg-now">Register Now</button>
</div>