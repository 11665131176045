export const MOBILE = (typeof window !== 'undefined') ? (window.screen.availWidth < 800) : true;
//var currentURL =  document.URL;
//export const API_BASE_URL = (currentURL.match(/ewebcore.in/g)) ? 'http://rainbowbackend.ewebcore.in/api/' : 'http://rainbowbackend.resultrix-apps.com/api/';
//export const IMG_BASE_URL = (currentURL.match(/ewebcore.in/g)) ? 'http://rainbowbackend.ewebcore.in/' : 'http://rainbowbackend.resultrix-apps.com/';

//export const API_BASE_URL = 'http://rainbowbe.resultrix-apps.com/api/';
//export const IMG_BASE_URL = 'http://rainbowbe.resultrix-apps.com/';

//export const API_BASE_URL = 'http://rainbowadmin/api/';
//export const API_BASE_URL_NON_SSL = 'http://rainbowadmin/api/';
//export const IMG_BASE_URL = 'http://rainbowadmin/'; 

export const BASE_URL = 'https://rainbowhospitals.in/';


export const API_BASE_URL = 'https://backend.rainbowhospitals.in/api/';
export const API_BASE_URL_NON_SSL = 'http://backend.rainbowhospitals.in/api/';
export const IMG_BASE_URL = 'https://backend.rainbowhospitals.in/';

export const CAN_API = "https://appointment.rainbowhospitals.in:7100/PATIENTPORTALSERVER/PortalService.svc/json/";


export const FB_URL = 'https://www.facebook.com/rainbowchildrenshospitals/';
export const YOUTUBE_URL = 'https://www.youtube.com/channel/UCx6MAbZuphamC0bUy85gK3Q'; //'https://www.youtube.com/channel/UC1REvm1Kfyt1FJJlLXRACbA';
export const TWITTER_URL = 'https://twitter.com/RCH_India'; //'https://twitter.com/RainbowSpectra';
export const LINKEDIN_URL = 'https://www.linkedin.com/company/rainbow-hospitals';



// export const IMG_BASE_URL: string = `http://rhadmin.ostrainer.com`;
//LOCAL Address
//export const API_BASE_URL: string = `http://rhadmin.dev/api/`;
//export const IMG_BASE_URL: string = `http://rhadmin.dev`;