<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>
    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <section class="page pageheader">
        <div class="container">
            <div class="col-xs-6 col-sm-6 col-lg-6 abannimgwrp">
                <div class="otherbannimg">
                    <img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" />
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-lg-6">
                <div class="ptitle">
                    <h1 class="underline">{{title}}</h1>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-lg-6 mcont100">
                <p [innerHTML]="description"></p>
            </div>
        </div>
    </section>
    <section #termsconditions class="container-fluid ospesltyaccord">
        <ng-template ngFor let-tnc let-i="index" [ngForOf]="tncDetails">
            <div class="whitecolor-sec faqswrp">
                <div class="container">
                    <div class="col-xs-12 col-lg-12">
                        <div class="GenraelPediatrics-aco" [ngClass]="{'sel': i == 0}">{{tnc.title}}</div>
                        <div class="GenraelPediatrics-Infotext tncont" [innerHTML]="tnc.description"></div>
                    </div>
                </div>
            </div>
        </ng-template>
    </section>
    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>