import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../services/api.service';
import * as moment from 'moment';

function popupResize(thisO) {
  var height = thisO.height() - (thisO.find('.headText').height() + 30);
  //console.log(height, thisO.find(".popupText").height(), thisO.find(".headText").height(), thisO.height())
  thisO.find('.popupText').height(height);
}
declare let $;
@Component({
  selector: 'app-hello-doctor',
  templateUrl: './hello-doctor.component.html',
  styleUrls: ['./css/css.css', './css/css2.css'],
})
export class HelloDoctorComponent implements OnInit {
  constructor(private api: ApiService) {}
  public formdata: FormGroup;
  public branches = {
    Hyderabad: [
      'Banjara Hills',
      'Secunderabad',
      'Kondapur IP',
      'Kondapur OP',
      'Hydernagar',
      'LB Nagar',
      'RCHI Hyderabad',
    ],
    Bangalore: ['Marathahalli', 'Bannerghatta Road', 'Hebbal'],
    Delhi: ['Malviya Nagar'],
    Chennai: ['Guindy'],
    Vijaywada: ['Currency Nagar', 'Governorpet'],
    Vizag: ['Health City', 'Visakhapatnam - Waltair Uplands'],
  };
  ngOnInit(): void {
    this.formdata = new FormGroup({
      Patient: new FormControl('', [Validators.required]),
      Guardian: new FormControl('', [Validators.required]),
      Mobile: new FormControl('', [Validators.required]),
      Email: new FormControl('', [Validators.required]),
      Address: new FormControl('', [Validators.required]),
      Branch: new FormControl('', [Validators.required]),
      City: new FormControl('', [Validators.required]),
      BirthDate: new FormControl('', [Validators.required]),
      Weight: new FormControl('', [Validators.required]),
      Temperature: new FormControl('', [Validators.required]),
      Symptoms: new FormControl('', [Validators.required]),
      PreferredDate: new FormControl('', [Validators.required]),
      Countrycode: new FormControl('91-India', [Validators.required]),
    });
  }
  get Patient() {
    return this.formdata.get('Patient');
  }
  get Guardian() {
    return this.formdata.get('Guardian');
  }
  get Mobile() {
    return this.formdata.get('Mobile');
  }
  get Email() {
    return this.formdata.get('Email');
  }
  get Address() {
    return this.formdata.get('Address');
  }
  get Branch() {
    return this.formdata.get('Branch');
  }
  get City() {
    return this.formdata.get('City');
  }
  get BirthDate() {
    return this.formdata.get('BirthDate');
  }
  get Weight() {
    return this.formdata.get('Weight');
  }

  get Temperature() {
    return this.formdata.get('Temperature');
  }
  get Symptoms() {
    return this.formdata.get('Symptoms');
  }
  get PreferredDate() {
    return this.formdata.get('PreferredDate');
  }
  get Countrycode() {
    return this.formdata.get('Countrycode');
  }

  formSubmit(data) {
    $('body').addClass('loader');
    let { Patient, Guardian, Countrycode, ...rest } = data;
    this.api
      .sendConsultationMail({
        Lead_Date: moment(Date.now()).format('DD-MM-YYYY'),
        Lead_Time: moment(Date.now()).format('hh:mm:ss A'),
        'Child Name': Patient,
        'Guardian Name': Guardian,
        ...rest,
        'Country Code': Countrycode,
      })
      .subscribe((res) => {
        let { status } = res;
        $('body').removeClass('loader');
        if (status) this.popupWindow('Submition');
        else this.popupWindow('Submition_Failed');
        this.formdata.reset();
      });
  }

  popupWindow(popupName) {
    $('.T_C_popupWrapper, .' + popupName).fadeIn();
    var thisO = $('.' + popupName);
    popupResize(thisO);
    $(window).resize(function () {
      popupResize(thisO);
    });

    if ($('.' + popupName).height() < $(window).height()) {
      $('body').css('overflow', 'hidden');
    } else {
      var height = $('.' + popupName).height();
      $('.' + popupName).css('position', 'absolute');
      $('body').css({ overflowY: 'hidden' });
    }

    $('.close').click(function () {
      $('.T_C_popupWrapper, .' + popupName).fadeOut();
      $('body').css({ overflowY: 'unset' });
    });
  }
  resetForm() {
    $('#Patient').val('');
    $('#Guardian').val('');
    $('#Mobile').val('');
    $('#EmailId').val('');
    $('#Transaction').val('');
    $('#City')[0].selectedIndex = 0;
    // $("#Speciality")
    //   .empty()
    //   .append('<option value="0">Select Speciality</option>');
    // $("#SubSpeciality")
    //   .empty()
    //   .append('<option value="0">Select Sub Speciality</option>');
    // $("#unit").empty().append('<option value="0">Select Unit</option>');
    // $("#upiidwrp").hide();
  }
}
