<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <section class="page wtwedo">
        <div class="row">
            <div>

                <div class="col-md-5 col-xs-5 col-lg-4">
                    <div class="circuleframe">
                        <img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" />
                    </div>
                    <!-- End Circle -->
                </div>
                <div class="col-md-7 col-xs-7 col-lg-7">
                    <div class="pagetitle">
                        <h1 class="tgradient">{{title}} </h1>
                        <div class="underline">
                            <img src="assets/images/underline2.png" alt="" />
                        </div>
                        <h3>{{subtitle}}</h3>
                    </div>
                </div>
                <div class="col-md-7 col-xs-7 col-lg-7 mcontentview">
                    <p [innerHTML]="description"></p>
                </div>
            </div>
        </div>

    </section>
    <section class="ddetailstimet ourssbg">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-xs-12 col-lg-12">
                    <div class="ourSpecialitiess slider">
                        <ng-container>
                            <div #allTheseThings class="sitem" *ngFor="let itm of sItems;">
                                <div class="text" *ngFor="let item of itm">
                                    <div class="osicons"><img src="{{imgBasePath}}{{item.image}}" class="img-responsive" alt="" /></div>

                                    <h3 class="textgradient" [innerHTML]="item.title"></h3>
                                    <p [innerHTML]="item.description | dgTruncate :{limit: 15, words: true} "></p>
                                    <a *ngIf="findComingSoon(item.description)" href="fertility-care/treatment/{{item.slug}}" class="knowmore"><span>Read More</span></a>
                                </div>
                                <!-- <div class="text">
                            <div class="osicons"><img src="assets/images/icon05.gif" alt="" /></div>
                            <h3 class="textgradient">The Pediatric<br><span>Neurology</span></h3>
                            <p>
                                Our Pediatric Neuro Rehabilitation and Allied Services Team at Banjara Hills, Hyderabad provides
                            </p>
                            <a href="#" class="knowmore"><span>Read More</span></a>
                        </div> -->
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <a class="iconFormV" href="javascript:void();" (click)="ShowForm()">ENQUIRE<span>NOW</span></a>
    <a class="iconForm" href="javascript:void();" (click)="ShowForm()"><img alt="" src="assets/images/Icon_form.png"></a>
    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>
<!-- Start Model Popup  *ngIf="showHide" -->
<div class="capopup" id="ModelFormGetInTouch">
    <div class="capopup-inner">
        <div class="launchpreloader" *ngIf="preloader"></div>
        <a class="capopup-close" href="javascript:void()" (click)="changeShowStatus()"> <img alt="" src="assets/images/icon-close2.png"> </a>
        <h2>GET IN TOUCH WITH US</h2>
        <form id="form" [formGroup]="GetInTouch" (submit)="onSubmit()" novalidate>
            <ul class="formcasn">
                <li>
                    <input type="text" name="name" [ngClass]="{'has-error':!GetInTouch.controls.name.valid && GetInTouch.controls.name.touched}" placeholder="Name*" formControlName="name" required/>
                    <span class="alertMsg" *ngIf="!GetInTouch.controls.name.valid && submited">required</span>
                </li>
                <li>
                    <input type="text" name="mobile" [ngClass]="{'has-error':!GetInTouch.controls.mobile.valid && GetInTouch.controls.mobile.touched}" maxlength="10" placeholder="Mobile*" formControlName="mobile" required/>
                    <span class="alertMsg" *ngIf="!GetInTouch.controls.mobile.valid && submited">required</span>
                </li>
                <li>
                    <input type="text" name="email" [ngClass]="{'has-error':!GetInTouch.controls.email.valid && GetInTouch.controls.email.touched}" placeholder="Email ID*" formControlName="email" required/>
                    <span class="alertMsg" *ngIf="!GetInTouch.controls.email.valid && submited">required</span>
                </li>
                <li class="singledropdown">
                    <div class="selectbox">
                        <select class="select" name="txtLocation" id="txtLocation" [(ngModel)]="locationModel" formControlName="txtLocation">
						<option value="" selected>Location*</option>
						<option *ngFor="let interest of locationList;let i= index;" [value]="interest.name">
							{{interest.name}}
						</option>
					</select>
                        <span class="select-span"></span>
                    </div>
                    <span class="alertMsg" *ngIf="!GetInTouch.controls.txtLocation.valid && submited">required </span>
                </li>
                <li class="singledropdown">
                    <div class="selectbox">
                        <select class="select" name="interested_in" id="txtinterested_in" [(ngModel)]="countryModel" formControlName="interested_in">
						<option value="" selected>I am interested in*</option>
						<option *ngFor="let interest of interestedList;let i= index;" [value]="interest.name">
							{{interest.name}}
						</option>
					</select>
                        <span class="select-span"></span>
                    </div>
                    <span class="alertMsg" *ngIf="!GetInTouch.controls.interested_in.valid && submited">required </span>
                </li>
                <li>
                    <input type="text" name="message" placeholder="Message" formControlName="message" />
                </li>
                <li>
                    <div class="submitbtn">

                        <input class="casubmit" id="txtSubmit" name="txtSubmit" type="submit" value="Submit">
                    </div>
                </li>
            </ul>
        </form>
    </div>
</div>
<!-- End Model Popup -->