<div class="container">
	<div class="row">
		<ng-template ngFor let-footer [ngForOf]="footerLinks">
			<div *ngIf="footer.slug != 'calculator' " class="col-sm-12 col-md-12 col-lg-2 visible-lg">
				<h3 class="footer-header" [innerHTML]="footer.title"></h3>
				<ul class="footernav">
					<li *ngFor="let link of footer.pages"><a [target]="link.target" [class]="link.class" [href]="link.link">{{link.title}}</a></li>
				</ul>
			</div>
		
		
			<div *ngIf="footer.slug =='calculator' " class=" col-sm-12 col-md-12 col-lg-3">
				<div class="footerwidget">
					<h3 class="footer-header">{{footer.title}}</h3>
					<div class="footer-container">
						<ul class="appnav">
							<li *ngFor="let link of footer.pages">
								<a [href]="link.link">{{link.title}}</a>
							</li>
						</ul>

					</div>
					<h3 class="footer-header">{{footer.title2}}</h3>
					<div class="footer-container">
						<ul>
							<li *ngFor="let link of footer.pages2">
								<a [href]="link.link"  [target]="link.target">
									<img [src]="link.icon" alt="" />
								</a>
							</li>
							
						</ul>
					</div>
				</div>
				<div class="footerwidget">
					<h3 class="footer-header">{{footer.title3}}</h3>
					<div class="footer-container">
						<ul class="twfeed">
							<li *ngFor="let feedlink of footer.pages3">
								<h2>{{feedlink.title}}</h2>
								<p>{{feedlink.description}}</p>
							</li>
							
						</ul>
					</div>
				</div>
				<div class="footerwidget">
					<h3 class="footer-header">{{footer.title4}}</h3>
					<div class="footer-container">
						<ul>
							<li *ngFor="let sociallink of footer.pages4">
								<a [href]="sociallink.link" [target]="sociallink.target">
									<img [src]="sociallink.img" 
									alt="{{sociallink.icon}} icon" /></a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</ng-template>
	</div>
</div>
