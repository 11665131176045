import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import {
  Router,
  ActivatedRoute,
  Params,
  NavigationEnd,
  RoutesRecognized,
} from '@angular/router';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL } from '../services/constants';
import { Meta, Title } from '@angular/platform-browser';
import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
declare let initAboutTabs: any;

@Component({
  selector: 'doctor-details',
  providers: [
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
  ],
  templateUrl: './doctor-details.component.html',
  styles: [
    `
      .active {
        display: block;
      }
      .appointmentNoteWrp2 > div {
        background: #f00;
        padding: 10px 10px 1px;
        width: 100%;
        margin: 20px auto;
        font-size: 14px;
        color: #fff;
        display: inline-block;
      }
      .bookAppPgBgAnim {
        background-color: #3d2979;
        margin: 0;
        padding: 8px 20px;
        display: inline-block;
        font-weight: normal;
        color: #fff;
        -webkit-animation-name: bganimate;
        -webkit-animation-duration: 1s;
        animation-iteration-count: infinite;
        border-radius: 2px;
        text-align: center;
        font-size: 16px;
        line-height: 20px;
      }
      @-webkit-keyframes bganimate {
        50% {
          background-color: #773996;
        }
      }
      @keyframes bganimate {
        50% {
          background-color: rgb(236, 28, 36, 0.8);
        }
      }
      @media (max-width: 420px) {
        .bookAppPgBgAnim {
          font-size: 14px;
          line-height: 18px;
          padding: 6px 10px;
        }
      }
    `,
  ],
})
export class DoctorDetailsComponent
  implements OnDestroy, OnInit, AfterViewInit
{
  public services: any = [];
  public doctor_desk: any = [];
  public centers: any = [];
  public title: any = '';
  public description: any = '';
  public doctor: any = [];
  public info: any = [];
  public imgBasePath: any = '';
  public hidden = 'hidden';
  public doctorslug: any = '';
  public imgsrc: any = '';
  public logo = 'logo';
  public links: any = [];
  public bread_crumb_links: any = [];
  public atabsSliderStatus: boolean = false;
  public AppointmentNoteNotAvailable: boolean = false;
  public Highlight: any;
  public Highlights: boolean = false;
  public previousUrl: string;
  public location: Location;
  public centreParamName: any;
  public centreParamNameCond: boolean = false;
  public cityParamName: any;
  public cityParamNameString: any;
  public categoryParamName: any;
  public specialityParamName: any;
  public specialityParamNameString: any;
  public specialityParamNameCond: boolean = false;

  public bread_crumb_child_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Child Care', link: '/child-care/doctors', class: '' },
    { label: 'Doctors', link: '', class: '' },
  ];

  public bread_crumb_women_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Women Care', link: '/women-care/doctors', class: '' },
    { label: 'Doctors', link: '', class: '' },
  ];

  public bread_crumb_fertility_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Fertility Care', link: '/fertility-care/doctors', class: '' },
    { label: 'Doctors', link: '', class: '' },
  ];

  public bread_crumb_centre: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'our-centre', link: '/our-centres', class: '' },
  ];

  public bread_crumb_default: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Doctors', link: '/all/doctors', class: '' },
  ];

  public bread_crumb_child: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Child Care', link: '/child-care/doctors', class: '' },
    { label: 'Our Specialities', link: '/child-care/specialities', class: '' },
  ];

  public bread_crumb_women: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Women Care', link: '/women-care/doctors', class: '' },
    { label: 'Our Specialities', link: '/women-care/specialities', class: '' },
  ];

  public bread_crumb_fertility: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Fertility Care', link: '/fertility-care/doctors', class: '' },
    {
      label: 'Our Specialities',
      link: '/fertility-care/specialities',
      class: '',
    },
  ];

  public child_care_links: any = [
    { label: 'About Child Care', link: '/child-care/about', class: '' },
    { label: 'Child Safety', link: '/child-care/child-safety', class: '' },
    { label: 'Doctors', link: '/child-care/doctors', class: 'active' },
    { label: 'Our Specialities', link: '/child-care/specialities', class: '' },
    { label: 'Our Centres', link: '/child-care/centres', class: '' },
    { label: 'Vaccination', link: '/child-care/vaccination', class: '' },
    { label: 'Testimonials', link: '/child-care/testimonials', class: '' },
    { label: 'Share Feedback', link: '/child-care/feedback', class: '' },
    {
      label: 'Book An Appointment',
      link: '/child-care/book-an-appointment',
      class: '',
    },
  ];

  public women_care_links: any = [
    { label: "About Women's Care", link: '/women-care/about', class: '' },
    { label: 'Doctors', link: '/women-care/doctors', class: 'active' },
    { label: 'Our Specialities', link: '/women-care/specialities', class: '' },
    { label: 'Our Centres', link: '/women-care/centres', class: '' },
    { label: 'Testimonials', link: '/women-care/testimonials', class: '' },
    { label: 'Share Feedback', link: '/women-care/feedback', class: '' },
    {
      label: 'Book An Appointment',
      link: '/women-care/book-an-appointment',
      class: '',
    },
  ];

  public fertility_care_links: any = [
    { label: 'About Fertility Care', link: '/fertility-care/about', class: '' },
    { label: 'Doctors', link: '/fertility-care/doctors', class: 'active' },
    {
      label: 'Our Specialities',
      link: '/fertility-care/specialities',
      class: '',
    },
    { label: 'Our Centres', link: '/fertility-care/centres', class: '' },
    { label: 'Testimonials', link: '/fertility-care/testimonials', class: '' },
    { label: 'Share Feedback', link: '/fertility-care/feedback', class: '' },
    {
      label: 'Book An Appointment',
      link: '/fertility-care/book-an-appointment',
      class: '',
    },
  ];

  @ViewChildren('abouttabs') things: QueryList<any>;
  public currentSection: any = '';
  public activeRoute: any = [];
  public activePage: any = [];
  public dofferswrpConfig = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  public pageType: string = null;
  constructor(
    private api: ApiService,
    private activatedRoute: ActivatedRoute,
    router: Router,
    location: Location,
    public metaTitle: Title,
    public meta: Meta
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
    }
  }
  ngOnInit() {
    //this.getActionSection(document.referrer); //doctor url refer route
    this.activatedRoute.params.subscribe((params: Params) => {
      //console.log(params);
      //let doctorurl = params['doctorslug'].split('.');
      //console.log(doctorurl);
      this.specialityParamName = params['speciality'];
      this.categoryParamName = params['category'];
      this.centreParamName = params['centre'];
      this.cityParamName = params['city'];
      this.doctorslug = params['doctorslug'];
      this.currentSection = params['section'];
      this.pageType =
        params['type'] == 'visiting-consultant'
          ? '/visiting-consultant'
          : '/doctors';
      console.log(
        'centre:',
        this.centreParamName,
        ', speciality:',
        this.specialityParamName,
        ', category:',
        this.categoryParamName
      );
    });

    if (this.centreParamName !== '' && this.centreParamName !== undefined) {
      this.activeBreadCrumLinks(this.centreParamName);
      this.centreParamNameCond = true;
      this.cityParamNameString = this.cityParamName.replace('-', ' ');
    } else if (
      this.categoryParamName !== '' &&
      this.categoryParamName !== undefined
    ) {
      this.activeBreadCrumLinks(this.categoryParamName);
      this.specialityParamNameCond = true;
      this.specialityParamNameString = this.specialityParamName.replace(
        '-',
        ' '
      );
    } else {
      this.activeBreadCrumLinks('');
    }

    this.activeBreadCrum(this.currentSection);
    this.api
      .getSpecialityMasters()
      .map((res) => res)
      .subscribe((data) => {
        //console.log(data);
        this.activeRoute = data.speciality_masters.filter((filterdata) => {
          if (this.currentSection == 'women-care') {
            return filterdata.slug == 'women-care';
          } else {
            return filterdata.slug == this.currentSection;
          }
        });
        //console.log(this.activeRoute);
        if (this.activeRoute.length > 0) {
          this.activePage = this.activeRoute[0].speciality;
        } else {
          this.activePage = 'All';
        }

        //console.log(this.activePage);
      });
    this.api.doctorsbyslug(this.doctorslug, null).subscribe(
      (data) => {
        this.doctor = data.doctors[0];
        //console.log(this.doctor);
        //  this.info = data.doctors.info,
        this.Highlight = data.doctors[0].highlight;
        if (this.Highlight != null) {
          this.Highlights = true;
        }
        this.imgBasePath = IMG_BASE_URL;
        //console.log(this.doctor);
        //console.log('appointmentnote',this.doctor.appointmentnote);
        if (
          this.doctor.appointmentnote != '' &&
          this.doctor.appointmentnote != null
        ) {
          this.AppointmentNoteNotAvailable = true;
        }
        setTimeout(() => {
          this.atabsSliderStatus = true;
        }, 200);
      },
      (error) => {
        console.log(error);
      }
    );
    this.api.doctorsbyslugMeta(this.doctorslug).subscribe(
      (data) => {
        if (data) {
          //console.log('doctors meta: ',data);
          this.metaTitle.setTitle(data.doctors[0].meta_title);
          this.meta.updateTag({
            name: 'keywords',
            content: data.doctors[0].meta_keyword,
          });
          this.meta.updateTag({
            name: 'description',
            content: data.doctors[0].meta_description,
          });
          this.meta.addTags([
            { property: 'og:title', content: data.doctors[0].meta_title },
            {
              property: 'og:description',
              content: data.doctors[0].meta_description,
            },
            { property: 'og:keywords', content: data.doctors[0].meta_keyword },
          ]);
        }
      },
      (error) => {
        console.log(error);
      }
    );
    this.api.doctordeskbyslug(this.doctorslug).subscribe(
      (data) => {
        (this.doctor_desk = data.doctors[0].doctor_desk),
          (this.imgBasePath = IMG_BASE_URL);

        this.doctor_desk.forEach((data) => {
          data.doctors_desk_questions.forEach((question) => {
            question.toggle = false;
          });
        });

        //console.log('doctordeskbyslug ',  this.doctor_desk)
      },
      (error) => {
        console.log(error);
      }
    );
    this.api.getHealthCareServices('childcare-doctors-detailpage').subscribe(
      (data) => {
        this.title = data.healthcare_services[0].title;
        this.description = data.healthcare_services[0].description;
        this.imgsrc = data.healthcare_services[0].image;
        this.imgBasePath = IMG_BASE_URL;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  activeBreadCrumLinks(section) {
    switch (section) {
      case 'our-centre':
        this.bread_crumb_links = this.bread_crumb_centre;
        break;
      case 'child-care':
        this.bread_crumb_links = this.bread_crumb_child;
        break;
      case 'women-care':
        this.bread_crumb_links = this.bread_crumb_women;
        break;
      case 'fertility-care':
        this.bread_crumb_links = this.bread_crumb_fertility;
        break;
      default:
        this.bread_crumb_links = this.bread_crumb_default;
        break;
    }
  }

  activeBreadCrum(section) {
    switch (section) {
      case 'child-care':
        this.bread_crumb_links = this.bread_crumb_child_links;
        this.links = this.child_care_links;
        break;
      case 'women-care':
        this.bread_crumb_links = this.bread_crumb_women_links;
        this.links = this.women_care_links;
        break;
      case 'fertility-care':
        this.bread_crumb_links = this.bread_crumb_fertility_links;
        this.links = this.fertility_care_links;
        break;
    }
  }

  toggle(doc_id, question) {
    var myObj = this.doctor_desk[doc_id].doctors_desk_questions;

    var test = myObj.find((element) => {
      return element.toggle == true;
    });

    if (test == undefined) {
      question.toggle = true;
    } else {
      test.toggle = false;
      question.toggle = true;
    }
  }
  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();

      setTimeout(() => {
        initAboutTabs();
      }, 2000);
      //var referrer = document.referrer
    }
  }

  ngOnDestroy() {}
}
