<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <section class="page pageheader">
        <div class="container">
            <div class="col-xs-6 col-sm-6 col-lg-6 abannimgwrp">
                <div class="otherbannimg">
                    <img src="{{imgBasePath}}{{imgsrc}}"  alt="{{title}}" title="{{title}}" class="img-responsive" />
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-lg-6">
                <div class="ptitle">
                    <h1>{{title}}</h1>
                    <h3><br> Thank you for submitting your enquiry.
                        <br> We will get in touch with you soon</h3>
                </div>
            </div>
        </div>
    </section>

    <div class="capopup" data-popup="popup-127">
        <div class="capopup-inner">
            <h2>Thank you</h2>
            <!--<h3>Thanks for contacting us! We will get in touch with you shortly.</h3>-->
            <a class="capopup-close" data-popup-close="popup-127" href="#">
                <img src="assets/images/icon-close2.png" alt="" />
            </a>
        </div>
    </div>

    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>