import { Component, OnDestroy, OnInit, AfterViewInit} from '@angular/core';
import { ApiService } from "../services/api.service";
import { IMG_BASE_URL } from "../services/constants";
import { Meta, Title } from '@angular/platform-browser';
declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
declare let initAboutTabs: any;




@Component({
  selector: 'goinghome',
  templateUrl: './goinghome.component.html'
})



export class GoingHomeComponent implements OnDestroy, OnInit, AfterViewInit {


	constructor(private api: ApiService, public metaTitle: Title, public meta: Meta) {
		if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
    }
	}

	public services: any[] = [];
	public brief_facilities: any[] = [];
	public title: any = "";
	public subtitle: any = "";
	public description: any = "";
	public imgsrc: any = "";
	public imgBasePath = "";
	public hidden = "hidden";
	public logo = "logo";
	public bread_crumb_links: any = [{ "label": "Home", "link": "/", "class": "","slug":"home" },
	{ "label": "Others", "link": "", "class": "","slug":"others" },
	{ "label": "Neonatal Support Group", "link": "other/neonatal-support-group", "class": "","slug":"neonatal-support-group" },
	{ "label": "Going Home", "link": "other/neonatal-support-group/going-home", "class": "","slug":"going-home" }];

	//public links: any = [];
	public links:any = [{"label":"Neonatal Support Group", "link": "/other/neonatal-support-group", "class": "active", "slug":"neonatal-support-group"},
                      {"label":"Before Birth", "link": "/other/neonatal-support-group/before-birth", "class": "", "slug":"before-birth"},
                      {"label":"In Hospital", "link": "/other/neonatal-support-group/in-hospital", "class": "", "slug":"in-hospital"},
                      {"label":"Going Home", "link": "/other/neonatal-support-group/going-home", "class": "", "slug":"going-home"},
                      {"label":"At Home", "link": "/other/neonatal-support-group/at-home", "class": "", "slug":"at-home"},
                      {"label":"Growing Up", "link": "/other/neonatal-support-group/growing-up", "class": "", "slug":"growing-up"}]
	public activePage:string = 'going-home';

	//@ViewChildren('atabs') atabsThings: QueryList<any>;

	ngOnInit() {
		this.api.getPageDescription('going-home')
			.subscribe(
				data => {
					this.title = data.page_descriptions[0].title,
						this.subtitle = data.page_descriptions[0].subtitle,
						this.description = data.page_descriptions[0].description,
						this.imgsrc = data.page_descriptions[0].image,
						this.imgBasePath = IMG_BASE_URL;
						if(data){
							//console.log(data);
							this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
							this.meta.updateTag({name:'keywords',content: data.page_descriptions[0].meta_keyword});
							this.meta.updateTag({name:'description',content: data.page_descriptions[0].meta_description});
							this.meta.addTags([
							  { property: 'og:title', content:  data.page_descriptions[0].meta_title},
							  { property: 'og:description', content: data.page_descriptions[0].meta_description },
							  { property: 'og:keywords', content: data.page_descriptions[0].meta_keyword }
							]);
						  }
				},
				error => {
					console.log(error);
				}
			)
		/*this.api.getOtherSubFooterDetails().map(res => res.json()).subscribe((data)=>{
			this.links =  data;
		})*/
	}

	ngAfterViewInit() {

		if (this.api.isBrowser && window['jQuery']) {
			initHome();
			initAllPage();
			//initAboutTabs();
		}
	}

	ngOnDestroy() { }

}
