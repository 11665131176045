<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>

    <section class="page pageheader">
        <div class="container">
            <div class="col-xs-6 col-sm-6 col-lg-6 abannimgwrp">
                <div class="otherbannimg">
                    <img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" />
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-lg-6">
                <div class="ptitle">
                    <h1>{{title}}</h1>
                    <h3>{{subtitle}}</h3>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-lg-6 mcont100">
                <p [innerHTML]="description"></p>
            </div>

        </div>
    </section>
    <section class="aboutsectin2 awardswapper">
        <div class="container">
            <div class="row nsg_text">
                <div class="col-xs-12 col-lg-12">
                    <h2>You and Your Baby</h2>
                    <p>There are various factors that sometimes contribute to your little one arriving earlier than expected. It is not uncommon and neither is it always an emergency. We understand that premature babies require specialized care and our world
                        standard protocols ensure that your child is attended to in the best possible manner.</p>
                    <p>Families, and understandably so, undergo tremendous stress to find their baby in Neonatology Intensive Care. However, with state-of-the-art infrastructure and highly experienced doctors your child is in safe hands.</p>
                    <p>For many families, having a baby admitted to a neonatal unit is a unique and frightening experience. Although a number of families will be diagnosed as having a high risk pregnancy, for the majority, this situation is neither expected
                        nor prepared for.</p>
                    <p>
                        <strong>a. Factors that Cause Premature Births</strong>
                    </p>
                    <p>Various factors, depending on individual cases, result in premature births. Some of the common factors which cause preterm births include – weakness in the neck of the womb, infection within the vagina or uterus, premature rupture
                        of membranes, pre-eclampsia, multiple births, or other medical conditions in the vagina.</p>
                    <p>The gravity of the situation is determined by the possible causes of the premature labour and delivery. Often it could be multiple factors that determine the eventual preterm birth, and based on certain conditions it may well be anticipated
                        in advance.</p>
                    <p>
                        <strong>b. Need for Specialist Care</strong>
                    </p>
                    <p>Babies born prematurely, certainly require a heightened medical attention than those children who are born at the end of a complete term of pregnancy. The level of specialized care is determined by the factors which caused preterm
                        pregnancy in the first place and how early in the term has the child been delivered.</p>
                    <p>On the other hand, preterm babies might be affected by certain birth-related issues which will require specialized care. Typically preterm babies have breathing difficulties, or they are susceptible to jaundice, and have lower immunity
                        levels that make them more susceptible to infections and viral diseases.
                    </p>
                    <p>In a few cases, they might need specialized care for more serious health conditions such as – brain haemorrhage and sepsis amongst others.</p>
                    <p>
                        <strong>c. Premature Birth &amp; You</strong>
                    </p>
                    <p>Premature birth is caused by certain medical conditions or complications in the mother. Secondly, the premature birth presents a certain level of stress for which the body is yet unprepared for! Therefore, premature birth have a considerable
                        impact on the health of the mother and they ought to be provided equally specialized care.</p>
                    <p>There is also a heightened level of stress and anxiety on the health of the premature baby, which is understandable. However extreme duress can cloud your ability to think and care for the health of your baby. And mothers who’ve delivered
                        a preterm might find their own health to be deteriorating due to the excess stress and strain.</p>
                </div>
            </div>
            <div class="row nsg_text">
                <div class="col-xs-12 col-lg-12">
                    <h2>About Neonatal Care</h2>
                    <p>Neonatology unit’s offer specialized care for babies who are born with acute medical condition that arise either due to premature birth of other factors. Especially in the case of children born preterm it is highly possible they have
                        certain birth related conditions – jaundice, infections haemorrhage etc., in such cases the Rainbow Neonatology departments offers them specialized care by highly trained and experienced doctors.</p>
                    <p>Children in neonatology received the best possible medical care with specially trained nurses monitoring various parameters and administering the requisite doses of medicines.They might be placed on breathing support or on other vital
                        life-saving equipment based on the level of emergency and medical attention required.
                    </p>
                    <p>The neonatology department at Rainbow Hospitals has three critical components that bring together the best of child healthcare.</p>
                    <p>
                        <strong>a. Clinical Expertise</strong>
                    </p>
                    <p>The department of neonatology at Rainbow Hospitals employs highly skilled team of senior specialists who work round-the-clock offering the highest standards of neonatal care.</p>
                    <p>The teams typically consist of a highly specialized paediatric physician, specialists in sub specialties such as Emergency Medicine, Anaesthesia, Neurology, Nephrology, Neurosurgery, and various others. The neonatology department also
                        includes a support structure of social workers, clinical dieticians, occupational and physical therapists - trained in neonatal care to provide vital support.</p>
                    <p>The team working in NICU consists of the best medical professions with extensive knowledge, skills, expertise, and experience to offer the best medical care such that your little ones have the best chance of recovery from critical
                        illness or injury.</p>
                    <p>
                        <strong>b. Infrastructure</strong>
                    </p>
                    <p>The Neonatal Intensive Care Unit at Rainbow Children’s Hospital is equipped with state of the art facilities to cater to all medical emergencies of the preterm babies. These facilities are on world class standards capable of attending
                        to extremely premature and very low birth weight babies. Some of the specialized infrastructure available at Rainbow Hospitals includes;</p>
                    <ul>
                        <li>Advanced incubators and warmers</li>
                        <li>Invasive and invasive ventilation</li>
                        <li>Multi parameter monitors</li>
                        <li>Total Parenteral Nutrition</li>
                        <li>LED phototherapy units</li>
                        <li>24 hours portable X Ray and Ultrasound</li>
                        <li>24 hours emergency neonatal transport facilities</li>
                        <li>Dedicated advanced neonatal ventilators</li>
                        <li>High frequency ventilators and transport ventilators</li>
                    </ul>
                    <p>
                        <strong>c. Allied Specialities</strong>
                    </p>
                    <p>The neonatology department at Rainbow Hospitals works in tandem with various sub-specialities to provide comprehensive and well-rounded emergency medical care to neonates. The neonatology department receives expertise and support from
                        sub-specialities such as paediatric neurology, paediatric nephrology, paediatric surgery and&nbsp;various others to ensure that your baby’s health is comprehensively attended to!
                    </p>
                    <p>The need for a multi-disciplinary approach is necessary given the complexity of the emergencies that arrive at the neonatology department. Taking a comprehensive approach helps pre-empt certain later effects while effectively dealing
                        with the current emergencies.</p>
                </div>
            </div>
            <div class="row nsg_text">
                <div class="col-xs-12 col-lg-12">
                    <h2>Visitng Your Baby</h2>
                    <p>At Rainbow Hospitals we understand your anxiety to see your baby and be assured of his or her well-being. The neonatology department helps you understand the exact nature of concern with your child’s health while giving you all the
                        precautions you need to take while visiting the baby. Certain restrictions may be imposed from time to time, only to ensure that your baby has the best chance of recovery and to avoid external infections which may be detrimental
                        to his or her recovery.</p>
                    <p>The issue of visiting, holding and spending the time with the baby while he or she is in neonatology care is one that must be treaded carefully. Premature babies have little resistance to immunity and therefore best to stay far if
                        you are carrying an infectious disease – common cold, allergies etc. Given that the parents will find a desire to bond with the baby, Rainbow Hospitals does seek to provide an atmosphere where parents can be the child.</p>
                    <p>The team of doctors will seek to provide you as much information as possible on the baby’s health as well as providing the possible course of treatment and date of recovery. The support staff will offer you regular updates and timely
                        counselling to help you cope with the stress and strain.</p>
                </div>
            </div>
            <div class="row nsg_text">
                <div class="col-xs-12 col-lg-12">
                    <h2>Medical Conditions and Procedures</h2>
                    <p>Given that premature babies will have problems with immunity as well as having a low birth weight, it is possible that they might have multiple complications that need delicate handling, accurate diagnosis and expert medical care.
                        Some of the common medical conditions that a premature baby might be susceptible to, have been discussed in the following sections.</p>
                </div>
            </div>
            <div class="row nsg_text">
                <div class="col-xs-12 col-lg-12">
                    <h2>Multiple Births</h2>
                    <p>Multiple births often pose a risk of complications during labour or delivery and might even be a cause for possible premature births. Rainbow Hospitals provides excellent neonatal care for multiple births from a pregnancy taking care
                        of their specialized medical and growth requirements. It is very common only for one of the babies to be unwell or critical while the other remains healthy and normal.</p>
                    <p>Rainbow Hospitals provides specialized care as well as adequate support for parents dealing with multiple birth emergencies.</p>
                </div>
            </div>
            <div class="row nsg_text">
                <div class="col-xs-12 col-lg-12">
                    <h2>Feeding</h2>
                    <p>Mother’s milk is the best source of nutrition for the new born baby and yet in case of premature babies in NICU, mothers might find it cumbersome and stressful to be able to feed the little ones. All the pre-birth learning on how to
                        feed the baby might not be applicable in premature babies and mothers will have to adapt to the changing needs of the baby. Babies born prematurely grow at a different pace and mothers ought to be able to respond to the needs of
                        the baby and provide adequate feeding.</p>
                    <p>
                        <strong>a. Total Prenatal Nutrition</strong>
                    </p>
                    <p>In this method of feeding the child is given nutrients such as glucose, protein and fat solutions directly into the baby’s blood stream. This might be injected via a vein and helps the baby grow adequately. Given that the premature
                        baby’s digestive system is yet immature and needs development this helps the child grow while allowing the digestive system to take shape.</p>
                    <p>
                        <strong>b. Tube Feeding</strong>
                    </p>
                    <p>In this method, the baby is fed either mother’s milk or formulated milk via a tube. The tube is inserted either via the nasal or the oral cavity of the child. This method is advised because most premature babies are unable to feed
                        for themselves. This way required nutrition reaches the baby without placing a great deal of stress on his or her metabolic systems.</p>
                    <p>
                        <strong>c. Breastfeeding</strong>
                    </p>
                    <p>Breastfeeding is possible even in case of premature babies – either directly or via expressing milk using a pump. Breast milk is a great option, given that it provides your little of the best nutrition but there are challenges of expressing
                        the milk and of digesting it by the baby. Also, mothers may not have sufficient milk to be fed for the baby.</p>
                    <p>
                        <strong>d. Weaning</strong>
                    </p>
                    <p>Irrespective of how prematurely a baby is born, his or her age is calculated according to the date of birth; and over a period of time they ought to be weaned off mother’s milk and allowed to consume solid food. This must be a gradual
                        process, and in case of premature babies the process might take slightly longer than normal. </p>
                </div>
            </div>
        </div>
    </section>



    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>