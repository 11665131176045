<div class="container-fluid">
    <div class="background">
        <img src="assets/images/bg-press-clip.png" data-t="bg_01_00.jpg" data-d="bg-press-clip.png" class="bgImg bg0 active" />
    </div>
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <section class="page mediaSection pageheader">
        <div class="row">
            <div class="container">
                <div class="col-xs-12 col-sm-7 col-lg-7 abannimgwrp">
                    <div class="aboutbannimg"><img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" /></div>
                </div>
                <div class="col-xs-12 col-sm-5 col-lg-5">
                    <div class="ptitle yellow">
                        <h4>{{title}}</h4>
                        <h3>{{subtitle}}</h3>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="container-fluid aboutbod">
        <div class="row">
            <div class="container">
                <div class="col-xs-12 col-sm-12 col-lg-12">
                    <div class="prelshead">
                        <div class="prdate">
                            <h3>{{pressDetail.date | amDateFormat : "MMMM"}}</h3>
                            <h2 class="gradient">{{pressDetail.date | amDateFormat : "DD"}}</h2>
                            <h4>{{pressDetail.date | amDateFormat : "YYYY"}}</h4>
                        </div>
                        <div class="prtitle">
                            <h1>{{pressDetail.title}}</h1>
                        </div>
                    </div>
                    <div class="prelscontent">
                        <p [innerHTML]="pressDetail.long_description"></p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <footer-nav-mini [links]="links"></footer-nav-mini>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>