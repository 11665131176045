<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <section class="page wtwedo">
        <div class="row">
            <div class="col-md-5 col-xs-5 col-lg-4">
                <div class="circuleframe"> <img src="assets/images/our-center-thumb.png" class="img-responsive" alt="Our Centres"  title="Our Centres" /> </div>
                <!-- End Circle -->
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7">
                <div class="pagetitle">
                    <h1 class="textgradient">Our <span>Centres</span></h1>
                    <div class="underline"> <img src="assets/images/underline.png" alt="" /> </div>
                    <h3>Branches of Rainbow Hospitals.</h3>
                </div>
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7 mcontentview">
                <p>Below you will find information about our different hospital locations.</p>
                <div class="dfindwrp">
                    <ul class="hlfcube">
                        <li>
                            <form action="" method="post" #f="ngForm">
                                <div class="locationwrp">
                                    <select class="select" name="selectedLocation" [(ngModel)]="selectedLocation">
											<option value="0" [selected]="selectedLocation == 0">Select location </option>
											<option *ngFor="let city of centerCity;let i = index;" [value]="city">{{city}}</option>		
										</select>
                                    <span id="selectlocation" class="select-span"></span>
                                </div>
                                <div class="searchbtn">
                                    <input value="search" type="button" name="txtSearch" (click)="submitLocation(f)" />
                                </div>
                            </form>

                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <section>
        <div class="container ourcenterlist-aro">
            <a class="slick-prev" id="slickPrev">&nbsp;</a>
            <div #centerSlider class="ourcenterlist slider">

                <div *ngFor="let center of centerList;" class="sitem" [attr.data-cityname]="center.city">

                    <div class="address-img-wapper">
                        <a *ngIf="center.virtual_tour_link" class="fancybox-effects-a icon-360" target="_blank" href="{{center.virtual_tour_link}}" data-fancybox-group="gallery" title="Rainbow Hospital">
                            <img src="assets/images/icon-360.png" width="49" height="49">
                        </a>

                        <img *ngIf="center.image" src="{{imgBasePath}}{{center.image}}" alt="{{center.name}}" width="560" />
                        <img *ngIf="!center.image" src="assets/images/mimg.jpg" alt="{{center.name}}" width="560">
                    </div>
                    <div class="address-wapper">
                        <h2 class="textname" [innerHTML]="center.center_name"></h2>
                        <div class="underline"><img src="assets/images/underline.png" width="77" height="3"></div>
                        <p [innerHTML]="center.address"></p>
                        <div class="call-no clearfix">{{center.telephone}}</div>
                        <a href="/{{currentSection}}/centre/{{center.slug}}" class="btn-aboutcenter"><span>About Centre</span></a>
                        <a href="{{currentSection}}/book-an-appointment" class="btn-Bookapopoi"><span>Book an Appointment</span></a>
                        <a target="_blank" href="https://www.google.com/maps?saddr=My+Location&daddr={{center.lat_long}}" class="direction ">Direction</a>
                    </div>
                </div>
            </div>
            <a class="slick-next" id="slickNext">&nbsp;</a> </div>
    </section>

    <find-a-doctors-nav></find-a-doctors-nav>
    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activeSubPage"></footer-nav-mini>
    </ng-template>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>