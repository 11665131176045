import { Injectable } from '@angular/core';
// import {
//   ConnectionBackend, Http, Request, RequestOptions,
//   RequestOptionsArgs, Response
// } from '@angular/http';

import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { TransferState } from '../transfer-state/transfer-state';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/observable/fromPromise';
import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';

@Injectable()
export class TransferHttp {
  constructor(
    private http: HttpClient,
    protected transferState: TransferState
  ) {}

  request(uri: string | HttpRequest<any>, options?: any): Observable<any> {
    return this.getData(uri, options, (url: any, options: any) => {
      return this.http.request(url, options);
    });
  }
  /**
   * Performs a request with `get` http method.
   */
  get(url: string, options?: any): Observable<any> {
    return this.getData(url, options, (url: any, options: any) => {
      return this.http.get(url, options);
    });
  }
  /**
   * Performs a request with `post` http method.
   */
  post(url: string, body: any, options?: any): Observable<any> {
    return this.getPostData(url, body, options, (url: any, options: any) => {
      return this.http.post<any>(url, body.options);
    });
  }
  /**
   * Performs a request with `put` http method.
   */
  put(url: string, body: any, options?: any): Observable<any> {
    return this.getData(url, options, (url: any, options: any) => {
      return this.http.put(url, options);
    });
  }
  /**
   * Performs a request with `delete` http method.
   */
  delete(url: string, options?: any): Observable<any> {
    return this.getData(url, options, (url: any, options: any) => {
      return this.http.delete(url, options);
    });
  }
  /**
   * Performs a request with `patch` http method.
   */
  patch(url: string, body: any, options?: any): Observable<any> {
    return this.getPostData(url, body, options, (url: any, options: any) => {
      return this.http.patch<any>(url, body.options);
    });
  }
  /**
   * Performs a request with `head` http method.
   */
  head(url: string, options?: any): Observable<any> {
    return this.getData(url, options, (url: any, options: any) => {
      return this.http.head(url, options);
    });
  }
  /**
   * Performs a request with `options` http method.
   */
  options(url: string, options?: any): Observable<any> {
    return this.getData(url, options, (url: any, options: any) => {
      return this.http.options(url, options);
    });
  }

  private getData(
    uri: string | HttpRequest<any>,
    options: any,
    callback: (uri: string | HttpRequest<any>, options?: any) => Observable<any>
  ) {
    let url = uri;

    if (typeof uri !== 'string') {
      url = uri.url;
    }

    const key = url + JSON.stringify(options);

    try {
      return this.resolveData(key);
    } catch (e) {
      return callback(uri, options)
        .map((res) => res)
        .do((data) => {
          this.setCache(key, data);
        });
    }
  }

  private getPostData(
    uri: string | HttpRequest<any>,
    body: any,
    options: any,
    callback: (
      uri: string | HttpRequest<any>,
      body: any,
      options?: any
    ) => Observable<Response>
  ) {
    let url = uri;

    if (typeof uri !== 'string') {
      url = uri.url;
    }

    const key = url + JSON.stringify(body) + JSON.stringify(options);

    try {
      return this.resolveData(key);
    } catch (e) {
      return callback(uri, body, options)
        .map((res) => res.json())
        .do((data) => {
          this.setCache(key, data);
        });
    }
  }

  private resolveData(key: string) {
    const data = this.getFromCache(key);

    if (!data) {
      throw new Error();
    }

    return Observable.fromPromise(Promise.resolve(data));
  }

  private setCache(key, data) {
    return this.transferState.set(key, data);
  }

  private getFromCache(key): any {
    return this.transferState.get(key);
  }
}
