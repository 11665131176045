<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <section class="page pageheader">
        <div class="container">
            <div class="col-xs-6 col-sm-6 col-lg-6 abannimgwrp">
                <div class="otherbannimg">
                    <img src="{{imgBasePath}}{{imgsrc}}"  alt="{{title}}" title="{{title}}" class="img-responsive" />
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-lg-6">
                <div class="ptitle">
                    <h1>{{title}}</h1>
                    <h3>{{subtitle}}</h3>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-lg-6 mcont100">
                <p [innerHTML]="description"></p>
            </div>
        </div>
    </section>
    <section class="bnaformwrp">
        <div class="CalcLoader">
            <img src="assets/images/loading.gif" alt="" />
        </div>

        <div class="container">
            <div class="col-lg-12">
                <h3>Fill in your details to book for an appointment </h3>
            </div>


            <div class="formcasn">
                <form [formGroup]="appointmentForm" (ngSubmit)="onSubmit()" novalidate>
                    <ul>

                        <li class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                            <label>Name*
								<span class="alertMsg" *ngIf="!appointmentForm.controls.name.valid && appointmentForm.controls.name.touched">
									required
								</span>
							</label>
                            <input type="text" name="name" [ngClass]="{'has-error':!appointmentForm.controls.name.valid && appointmentForm.controls.name.touched}" placeholder="Enter Name" formControlName="name" required/>
                            <!--<p class="help-block errorMsg" *ngIf="appointmentForm.get('name').hasError('required') && appointmentForm.get('name').touched">
              Please enter Patient Mobile Number.
            </p>-->
                        </li>

                        <li class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                            <label>Email*
                                <span class="alertMsg" *ngIf="!appointmentForm.controls.email.valid && appointmentForm.controls.email.touched">
									required
                                </span>
                                <!-- <span class="alertMsg" *ngIf="appointmentForm.controls.email.errors && (appointmentForm.controls.mobileNo.dirty || appointmentForm.controls.mobileNo.touched)">
                                    <p *ngIf="appointmentForm.controls.email.errors.required">required</p>
                                    <p *ngIf="appointmentForm.controls.email.errors.pattern">Email is not valid.</p>
                                </span> -->
							</label>
                            <input type="email" name="email" [ngClass]="{'has-error':!appointmentForm.controls.email.valid && appointmentForm.controls.email.touched}" placeholder="Enter Email ID" formControlName="email" required/>
                        </li>

                        <li class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                            <label>Mobile*
								<span class="alertMsg" *ngIf="!appointmentForm.controls.mobile.valid && appointmentForm.controls.mobile.touched">
									required
								</span>
							</label>
                            <input type="text" name="mobile" [ngClass]="{'has-error':!appointmentForm.controls.mobile.valid && appointmentForm.controls.mobile.touched}" maxlength="10" placeholder="Enter Mobile Number" formControlName="mobile" required/>
                        </li>

                        <li class="col-xs-12 col-sm-6 col-md-3 col-lg-3 singledropdown">
                            <label>Country
								<span class="alertMsg" *ngIf="!appointmentForm.controls.country.valid && appointmentForm.controls.country.touched">
									required
								</span>
							</label>
                            <div class="selectbox">

                                <select class="select" name="country" id="txtSourceSN" [(ngModel)]="countryModel" formControlName="country">
									<option value="0" selected>Country</option>
									<option *ngFor="let country of countryList;let i= index;" [value]="country.name">
										{{country.name}}
									</option>
								</select>
                                <span id="selectSource" class="select-span"></span>
                            </div>
                            <!-- <label>Country
								<span class="alertMsg" *ngIf="!appointmentForm.controls.country.valid && appointmentForm.controls.country.touched">
									required
								</span>
							</label>

							<input type="text" name="country" [ngClass]="{'has-error':!appointmentForm.controls.country.valid && appointmentForm.controls.country.touched}"
							 placeholder="Enter Country" formControlName="country" required/> -->
                        </li>

                        <li class="col-xs-12 col-sm-6 col-md-3 col-lg-3 singledropdown">
                            <label>Choose specialty *
								<span class="alertMsg" *ngIf="!appointmentForm.controls.interested_in.valid && appointmentForm.controls.interested_in.touched">
									required
								</span>
							</label>
                            <!-- <input type="text" name="interested_in" [ngClass]="{'has-error':!appointmentForm.controls.interested_in.valid && appointmentForm.controls.interested_in.touched}"
							 placeholder="Enter Interested in" formControlName="interested_in" required/> -->
                            <div class="selectbox">

                                <select class="select" name="country" id="txtSourceSN" [(ngModel)]="interestModel" formControlName="interested_in">
									<option value="0" selected>Interested in</option>
									<option *ngFor="let interest of interestInList;let i= index;" [value]="interest.name">
										{{interest.name}}
									</option>
								</select>
                                <span id="selectSource" class="select-span"></span>
                            </div>
                        </li>

                        <li class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <label>Message*
								<span class="alertMsg" *ngIf="!appointmentForm.controls.message.valid && appointmentForm.controls.message.touched">
									required
								</span>
							</label>
                            <input type="text" name="message" [ngClass]="{'has-error':!appointmentForm.controls.message.valid && appointmentForm.controls.message.touched}" placeholder="Enter Message" formControlName="message" required/>
                        </li>

                        <li class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                            <!--<input type="submit" name="submit"  class="rimsubmitbtn" value="Submit"/>-->
                            <button type="submit" class="rimsubmitbtn" [disabled]="!appointmentForm.valid">Submit</button>
                        </li>
                    </ul>
                </form>
            </div>

        </div>
    </section>

    <div class="capopup" data-popup="popup-127">
        <div class="capopup-inner">
            <h2>Thank you</h2>
            <!--<h3>Thanks for contacting us! We will get in touch with you shortly.</h3>-->
            <a class="capopup-close" data-popup-close="popup-127" href="#">
                <img src="assets/images/icon-close2.png" alt="" />
            </a>
        </div>
    </div>

    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>