<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>
    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>

    <section class="page KnowledgeCentreDetails" *ngIf="knowledge_detail">
        <div class="container">
            <div class="col-xs-12 col-sm-12 col-lg-12">
                <h1 [innerHTML]="knowledge_detail.title"></h1>
            </div>
            <div class="clearfix"></div>
            <div class="col-xs-12 col-sm-12 col-lg-12 k_d">
                <img src="{{knowledge_detail_image}}" alt="{{knowledge_detail.title}}" title="{{knowledge_detail.title}}" class="k_d_img" *ngIf="knowledge_detail_title_is_true" />
                <span class="long_description" [innerHTML]="knowledge_detail.long_description"></span>
            </div>
            <!--<div class="col-xs-12 col-sm-6 col-lg-6">
                <span [innerHTML]="knowledge_detail.long_description"></span>
            </div>-->
        </div>
    </section>

    <find-a-doctors-nav></find-a-doctors-nav>

    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>

</div>