import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL } from '../services/constants';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { Subject } from 'rxjs/Subject';
declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
declare let initAboutTabs: any;
declare let initGoogleMap: any;
declare let initCentersSpecialityTabs: any;
//declare let ourCenter:any;
@Component({
  selector: 'app-our-center-map-new',
  templateUrl: './our-center-map-new.component.html',
  styles: [
    `
      .visitext {
        white-space: pre-wrap;
      }
      ul li > a {
        text-transform: capitalize;
      }
      .gmapwrp .sidelocdetwrp .gmheader .box {
        width: 98%;
      }
      ul li a {
        text-transform: capitalize;
      }
      .OurCentertext .cityname li:nth-child(even) {
        width: 45% !important;
      }
      .OurCentertext .cityname li:nth-child(odd) {
        width: 45% !important;
      }
      ul.cityname a {
        color: #000;
      }
    `,
  ],
})
export class OurCenterMapNewComponent
  implements OnDestroy, OnInit, AfterViewInit
{
  public selectedCentreId = new Subject<any>();
  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    public metaTitle: Title,
    public meta: Meta
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
    }
  }
  public currentSection: any;
  public title: any = '';
  public subtitle: any = '';
  public description: any = '';
  public imgsrc: any = '';
  public imgBasePath = '';
  public hidden = 'hidden';
  public logo = 'logo';

  public activeRoute: any[] = [];
  public activePage: any[] = [];
  public centerCity: any[] = [];
  public centerList: any[] = [];
  public centerCopyList: any[] = [];

  public centreName: string;
  public centreAliasName: string;
  public centreAddress: string;
  public centreTelephone: string;
  public centreImage: string;
  public centreEmail: string;
  public centreLatLng: string;
  public virtual_tour_link: string;
  public selectedLocation: any = 0;
  public links: any = [];
  public bread_crumb_links: any = [];
  public activeSubPage: any;
  public cities: any = '';
  public centerData: any;

  public cSpecialty: any = 0;
  public cSubSpeciality: any = 0;
  public specilityMasterList = [
    { id: 1, slug: 'child-care', name: 'Child Care' },
    { id: 2, slug: 'women-care', name: 'Women Health' },
    { id: 3, slug: 'fertility-care', name: 'Fertility Care' },
  ];
  //public selectedCentreId:any = '';
  public bread_crumb_child_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Child Care', link: '/child-care/about', class: '' },
    { label: 'Our Centres', link: '', class: '' },
  ];

  public bread_crumb_women_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Women Health', link: '/women-care/about', class: '' },
    { label: 'Our Centres', link: '', class: '' },
  ];

  public bread_crumb_fertility_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Fertility Care', link: '/fertility-care/about', class: '' },
    { label: 'Our Centres', link: '', class: '' },
  ];

  public child_care_links: any = [
    {
      label: 'About Child Care',
      link: '/child-care-about',
      class: '',
      slug: 'about',
    },
    {
      label: 'Child Safety',
      link: '/child-care/child-safety',
      class: '',
      slug: 'child-safety',
    },
    {
      label: 'Doctors',
      link: '/child-care/doctors',
      class: '',
      slug: 'child-care-doctors',
    },
    {
      label: 'Our Specialities',
      link: '/child-care/specialities',
      class: '',
      slug: 'specialities',
    },
    {
      label: 'Our Centres',
      link: '/child-care/centres',
      class: '',
      slug: 'centres',
    },
    {
      label: 'Vaccination',
      link: '/child-care/vaccination',
      class: '',
      slug: 'vaccination',
    },
    {
      label: 'Testimonials',
      link: '/child-care/testimonials',
      class: '',
      slug: 'testimonials',
    },
    {
      label: 'Share Feedback',
      link: '/child-care/feedback',
      class: '',
      slug: 'feedback',
    },
    {
      label: 'Book An Appointment',
      link: '/child-care/book-an-appointment',
      class: 'active',
      slug: 'child-care',
    },
  ];

  public women_care_links: any = [
    {
      label: "About Women's Care",
      link: '/women-care/about',
      class: '',
      slug: 'about',
    },
    {
      label: 'Doctors',
      link: '/women-care/doctors',
      class: '',
      slug: 'women-care-doctors',
    },
    {
      label: 'Our Specialities',
      link: '/women-care/specialities',
      class: '',
      slug: 'specialities',
    },
    {
      label: 'Our Centres',
      link: '/women-care/centres',
      class: '',
      slug: 'centres',
    },
    {
      label: 'Testimonials',
      link: '/women-care/testimonials',
      class: '',
      slug: 'testimonials',
    },
    {
      label: 'Share Feedback',
      link: '/women-care/feedback',
      class: '',
      slug: 'feedback',
    },
    {
      label: 'Book An Appointment',
      link: '/women-care/book-an-appointment',
      class: 'active',
      slug: 'women-care',
    },
  ];

  public fertility_care_links: any = [
    {
      label: 'About Fertility Care',
      link: '/fertility-care/about',
      class: '',
      slug: 'about',
    },
    {
      label: 'Doctors',
      link: '/fertility-care/doctors',
      class: '',
      slug: 'fertility-care-doctors',
    },
    {
      label: 'Our Specialities',
      link: '/fertility-care/specialities',
      class: '',
      slug: 'specialities',
    },
    {
      label: 'Our Centres',
      link: '/fertility-care/centres',
      class: '',
      slug: 'centres',
    },
    {
      label: 'Testimonials',
      link: '/fertility-care/testimonials',
      class: '',
      slug: 'testimonials',
    },
    {
      label: 'Share Feedback',
      link: '/fertility-care/feedback',
      class: '',
      slug: 'feedback',
    },
    {
      label: 'Book An Appointment',
      link: '/fertility-care/book-an-appointment',
      class: 'active',
      slug: 'fertility-care',
    },
  ];

  //@ViewChildren('abouttabs') things: QueryList<any>;
  activeBreadCrum(currentSection) {
    switch (currentSection) {
      case 'child-care':
        this.bread_crumb_links = this.bread_crumb_child_links;
        this.links = this.child_care_links;
        this.logo = 'rainbow-logo';
        this.activeSubPage = 'child-care-centres';
        break;
      case 'women-care':
        this.bread_crumb_links = this.bread_crumb_women_links;
        this.links = this.women_care_links;
        this.logo = 'birth-right-logo';
        this.activeSubPage = 'child-care-centres';
        break;
      case 'fertility-care':
        this.bread_crumb_links = this.bread_crumb_fertility_links;
        this.links = this.fertility_care_links;
        this.logo = 'birth-right-logo';
        this.activeSubPage = 'child-care-centres';
        break;
      default:
        this.logo = 'logo';
    }
  }
  @ViewChildren('centerMap') things: QueryList<any>;
  ngOnInit() {
    this.api.getPageDescription('our-center-map').subscribe(
      (data) => {
        //console.log(data);
        this.title = data.page_descriptions[0].title;
        let tempSubtitle = data.page_descriptions[0].subtitle;
        // inserting the 3 Clinics Text in the Page Subtitle
        this.subtitle = tempSubtitle;
        this.description = data.page_descriptions[0].description;
        this.imgsrc = data.page_descriptions[0].image;
        this.imgBasePath = IMG_BASE_URL;
        if (data) {
          //console.log(data);
          this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
          this.meta.updateTag({
            name: 'keywords',
            content: data.page_descriptions[0].meta_keyword,
          });
          this.meta.updateTag({
            name: 'description',
            content: data.page_descriptions[0].meta_description,
          });
          this.meta.addTags([
            {
              property: 'og:title',
              content: data.page_descriptions[0].meta_title,
            },
            {
              property: 'og:description',
              content: data.page_descriptions[0].meta_description,
            },
            {
              property: 'og:keywords',
              content: data.page_descriptions[0].meta_keyword,
            },
          ]);
        }
      },
      (error) => {
        console.log(error);
      }
    );
    this.route.params.subscribe((params) => {
      //this.currentSection = params['id'];
      this.currentSection = params['section'];
    });
    this.activeBreadCrum(this.currentSection);
    this.api
      .getSpecialityMasters()
      .map((res) => res)
      .subscribe((data) => {
        //console.log(data);
        this.activeRoute = data.speciality_masters.filter((filterdata) => {
          return filterdata.slug == this.currentSection;
        });

        //console.log(this.activeRoute);
        if (this.activeRoute.length > 0) {
          //this.activePage = this.activeRoute[0].id;
          this.activePage = this.activeRoute[0].speciality;
        } else {
          this.activePage = this.currentSection;
        }
      });
    this.api.getCenters().subscribe(
      (res) => {
        res.centers.map((data, key) => {
          const cityName = data.center_name.split(',');
          let city = data.city ? data.city.city : cityName[1];
          this.centerCity.push(city.trim());
          let center = this.api.getFilteredCenterData(data);
          this.centerList.push(center);
          this.centerCopyList.push(center);
        });
        this.centerCity = this.centerCity.reduce(function (a, b) {
          if (a.indexOf(b) < 0) a.push(b);
          return a;
        }, []);
      },
      (err) => {
        console.log(err);
      }
    );

    this.api.getCenterCity().subscribe((data) => {
      this.cities = data.cities;
    });

    let cityN: any = 'hyderabad';
    this.api.getCenterCityDetailsBySlug(cityN).subscribe((data) => {
      this.centerData = data;
    });
    this.getCityLocationList();
  }
  submitLocation(f: NgForm) {
    //alert('clicked');
    this.centerList = [];
    //this.selectedCentreId = ;
    this.selectedCentreId.next(f.value['selectedLocation']);
    $('html, body').animate({ scrollTop: $('.gmap').offset().top - 80 }, 1000);
  }

  filterCenter(event) {
    //console.log(event.target.value);
    let filterCenter = this.centerCopyList.filter((res) => {
      return res.center_name_alias == event.target.value;
    });
  }

  public LocationList: any;
  public centerCityList: any;
  getCityLocationList() {
    this.api.getCentersByLocation().subscribe((data) => {
      //console.log("getCentersByLocation:", data);
      let citylist: any = [];
      data['centers'].forEach((item, index) => {
        if (citylist.findIndex((i) => i.city == item.city.city) === -1) {
          citylist.push({
            id: item.city.id,
            city: item.city.city,
            cityicon: IMG_BASE_URL + item.city.image,
          });
        }
      });
      citylist.forEach((element) => {
        let centres: any = [];
        data['centers'].forEach((element2) => {
          if (element.city == element2.city.city) {
            centres.push(element2);
          }
        });
        element.centers = centres;
      });
      this.centerCityList = citylist;
      console.log('getCentersByLocation:', this.centerCityList);
      setTimeout(() => {
        initOurCentreTabs();
        knowMore();
      }, 100);
    });
  }

  ngAfterViewChecked() {}
  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();

      /*this.things.changes.subscribe(t => {
				initOurCentreTabs();
			});*/
    }
  }
  keys(obj: any): Array<string> {
    return Object.keys(obj);
  }
  ngOnDestroy() {}
}
const knowMore = () => {
  var maxLength = 160;
  $('.about-read-more').each(() => {
    var myStr = $(this).text();
    if ($.trim(myStr).length > maxLength) {
      var newStr = myStr.substring(0, maxLength);
      var removedStr = myStr.substring(maxLength, $.trim(myStr).length);
      $(this)
        .find('span')
        .empty()
        .html(newStr + '...');
      //$(this).append(' <a href="javascript:void(0);" class="read-more">read more...</a>');
      //$(this).append('<span class="more-text">' + removedStr + '</span>');
    }
  });
  $('.read-more').click(() => {
    $(this).siblings('.more-text').contents().unwrap();
    $(this).remove();
  });
};
function initOurCentreTabs() {
  var $atabs = $('body').find('.ctabs');
  var $atabscontent = $('body').find('.ctabscontent');
  $atabs.removeClass('slick-initialized').removeClass('slick-slider');
  $atabscontent.removeClass('slick-initialized').removeClass('slick-slider');

  $atabscontent
    .not('.slick-initialized')
    .slick({
      slide: '.ctabsdata',
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: false,
      asNavFor: '.ctabs',
      infinite: false,
      draggable: false,
      swipe: true,
      touchMove: false,
      adaptiveHeight: true,
    })
    .on('beforeChange', function (slick, currentSlide, nextSlide) {
      //console.log('beforeChange:',$atabscontent.find('slick-list'));
    });
  $atabs.not('.slick-initialized').slick({
    slide: 'li',
    slidesToShow: 6,
    slidesToScroll: 1,
    prevArrow:
      '<a class="slick-prev"><img src="assets/images/prv-arrow2.png" alt="" /></a>',
    nextArrow:
      '<a class="slick-next"><img src="assets/images/nxt-arrow2.png" alt="" /></a>',
    asNavFor: '.ctabscontent',
    dots: false,
    centerMode: false,
    focusOnSelect: true,
    arrows: true,
    infinite: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });
  /*
	window.addEventListener('resize', function () {
		if(window.outerWidth < 1281){
			$('.ctabs .slick-prev').addClass('cthumbarrowleft');
			$('.ctabs .slick-next').addClass('cthumbarrowright');
			$('.ctabs .slick-prev').prepend('<div class="prev-slick-arrow arrow-icon"><span>&#60;</span></div><div class="prev-slick-img slick-thumb-nav"><img src="assets/images/prv-arrow2.png" class="img-responsive"></div>');
			$('.ctabs .slick-next').append('<div class="next-slick-arrow arrow-icon"><span>&#62;</span></div><div class="next-slick-img slick-thumb-nav"><img src="assets/images/nxt-arrow2.png" class="img-responsive"></div>');
			get_prev_slick_img();
			get_next_slick_img();
		}else{
			$('.ctabs .slick-prev').removeClass('cthumbarrowleft');
			$('.ctabs .slick-next').removeClass('cthumbarrowright');
		}
	  });
	window.addEventListener("load", function(){		
		if(window.outerWidth < 1281){
			console.log('load:',window.outerWidth);
			//setTimeout(function() {
				$('.ctabs .slick-prev').addClass('cthumbarrowleft');
				$('.ctabs .slick-next').addClass('cthumbarrowright');
				$('.ctabs .slick-prev').prepend('<div class="prev-slick-arrow arrow-icon"><span>&#60;</span></div><div class="prev-slick-img slick-thumb-nav"><img src="assets/images/prv-arrow2.png" class="img-responsive"></div>');
				$('.ctabs .slick-next').append('<div class="next-slick-arrow arrow-icon"><span>&#62;</span></div><div class="next-slick-img slick-thumb-nav"><img src="assets/images/nxt-arrow2.png" class="img-responsive"></div>');
				get_prev_slick_img();
				get_next_slick_img();
			//}, 500);
			
			  $atabs.on('click', '.slick-prev', function() {
				get_prev_slick_img();
			  });
			  $atabs.on('click', '.slick-next', function() {
				get_next_slick_img();
			  });
			  $atabs.on('swipe', function(event, slick, direction) {
				if (direction == 'left') {
				  get_prev_slick_img();
				}
				else {
				  get_next_slick_img();
				}
			  });
			  $('.ctabs .slick-dots').on('click', 'li button', function() {
				var li_no = $(this).parent('li').index();
				if ($(this).parent('li').index() > li_no) {
				  get_prev_slick_img()
				}
				else {
				  get_next_slick_img()
				}
			  });
		}else{
			$('.ctabs .slick-prev').prepend('<div class="prev-slick-arrow arrow-icon"><span>&#60;</span></div><div class="prev-slick-img slick-thumb-nav"><img src="assets/images/prv-arrow2.png" class="img-responsive"></div>');
			$('.ctabs .slick-next').append('<div class="next-slick-arrow arrow-icon"><span>&#62;</span></div><div class="next-slick-img slick-thumb-nav"><img src="assets/images/nxt-arrow2.png" class="img-responsive"></div>');
		}
	}, false);
	*/
}

const multiSlideAdaptiveHeight = (slider) => {
  let activeSlides = [];
  let tallestSlide = 0;
  setTimeout(() => {
    $('.slick-track .slick-active', slider).each((item) => {
      activeSlides[item] = $(this).outerHeight();
    });
    activeSlides.forEach(function (item) {
      if (item > tallestSlide) {
        tallestSlide = item;
      }
    });
    $('.slick-list', slider).height(tallestSlide);
  }, 10);
};
function get_prev_slick_img() {
  // For prev img
  var prev_slick_img = $('.ctabs .slick-current')
    .prev('.slick-slide')
    .find('img')
    .attr('src');
  console.log('prev_slick_img:', prev_slick_img);
  $('.ctabs .prev-slick-img img').attr('src', prev_slick_img);
  $('.ctabs .prev-slick-img').css(
    'background-image',
    'url(' + prev_slick_img + ')'
  );
  // For next img
  var prev_next_slick_img = $('.ctabs .slick-current')
    .next('.slick-slide')
    .find('img')
    .attr('src');
  console.log('prev_next_slick_img:', prev_next_slick_img);
  $('.ctabs .next-slick-img img').attr('src', prev_next_slick_img);
  $('.ctabs .next-slick-img').css(
    'background-image',
    'url(' + prev_next_slick_img + ')'
  );
}
function get_next_slick_img() {
  // For next img
  var next_slick_img = $('.ctabs .slick-current')
    .next('.slick-slide')
    .find('img')
    .attr('src');
  console.log('next_slick_img:', next_slick_img);
  $('.ctabs .next-slick-img img').attr('src', next_slick_img);
  $('.ctabs .next-slick-img').css(
    'background-image',
    'url(' + next_slick_img + ')'
  );
  // For prev img
  var next_prev_slick_img = $('.ctabs .slick-current')
    .prev('.slick-slide')
    .find('img')
    .attr('src');
  console.log('next_prev_slick_img:', next_prev_slick_img);
  $('.ctabs .prev-slick-img img').attr('src', next_prev_slick_img);
  $('.ctabs .prev-slick-img').css(
    'background-image',
    'url(' + next_prev_slick_img + ')'
  );
}
