import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL } from '../services/constants';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
} from '@angular/forms';

interface BOARD_MEMBER {
  name: string;
  post: string;
  description: string;
  imageUrl: string;
}
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';

declare let $: any;
declare let initHome: any;
declare let initGoogleMap: any;
declare let initAllPage: any;

@Component({
  selector: 'app-investor-relations',
  templateUrl: './investor-relations.component.html',
  styleUrls: ['./investor-relations.component.scss'],
})
export class InvestorRelationsComponent implements OnInit, AfterViewInit {
  constructor(
    private api: ApiService,
    public formBuilder: FormBuilder,
    public metaTitle: Title,
    public meta: Meta,
    private sanitization: DomSanitizer
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;

      require('../../assets/js/bootstrap.js');
      require('../../assets/js/fancybox/jquery.fancybox.min.js');

      require('../../assets/js/slick.min.js');
    }
  }
  public logo: string = 'logo';
  public hidden: string = 'hidden';
  public boardOfDirectors: Array<BOARD_MEMBER> = [
    {
      name: 'Dr. Ramesh Kancharla',
      post: 'Chairman & Managing Director',
      description: `Dr. Ramesh Kancharla is the Chairman and Managing Director of our Company. He holds a Bachelor of Medicine and Bachelor of Surgery ("MBBS") from Sri Venkateswara University and a Doctor in Medicine ("MD") in paediatrics from Mangalore University. He is a member of the Royal Colleges of Physicians of the United Kingdom. He has over 23 years of experience with our Company. Prior to setting up our Company, he was associated with King's College Hospital, where he completed his specialist training in paediatric gastroenterology, hepatology and nutrition. He is awarded with the Lifetime Achiever of the Year in Pediatric Gastroenterology & Hepatology by Times of India in the year 2017 and the award for the best healthcare professional at the Telangana's Best Healthcare Professional Awards, 2017. In 2018 he was awarded the Entrepreneur of the Year award by the Sakshi Media Group.`,
      imageUrl: '/assets/investor-relations/board-member1.jpg',
    },
    {
      name: 'Dr. Dinesh Kumar Chirla',
      post: 'Whole-time Director',
      description: `Dr. Dinesh Kumar Chirla is the Whole-time Director of our Company. He holds a MBBS degree from Marathwada University, Doctor of Medicine in paediatrics from Dr. Babasaheb Ambedkar Marathwada University, Doctor of Medicine in Neonatology from the University of Mumbai. He is a member of the Royal College of Paediatrics and Child Health, London. He is on the Specialist Register in Neonatology at the Specialist Training Authority of the Medical Royal Colleges, London and was a fellow in Neonatology at Mercy Hospital for Women. He was also a Clinical Fellow (Specialist Registrar) in the Paediatric Intensive Care Unit at the United Bristol Healthcare NHS Trust. He has over 18 years of experience in the healthcare industry. He is elected as a Fellow of National Neonatology Forum and is awarded with the Best Paediatric Neonatologist at "The Doctors Awards – 2019, Doctors The Living Gods."`,
      imageUrl: '/assets/investor-relations/board-member2.jpg',
    },
    {
      name: 'Mr. Aluri Srinivasa Rao',
      post: 'Independent Director',
      description: `Aluri Srinavasa Rao is an Independent Director of our Company. He was appointed to our Board on March 15, 2019. He holds a Bachelor of Pharmacy (Honours) from the Birla Institute of Technology & Science, Pilani, and a Master of Business Administration ("MBA") from Osmania University, Hyderabad. He has completed Global Executive Leadership Program from Yale School of Management and has completed Master of Science in Management from University of London, London Business School. He has over 22 years of experience. He has worked at Natco Pharma Limited in several capacities. He has also headed the operations as the Managing Director at Morgan Stanley Private Equity Asia and has also worked at ICICI Venture. He also serves as a member of the Board of several pharmaceutical companies like Medisys Eductech Private Limited, Qunu Labs Private Limited, Janaadhar (India) Private Limited.`,
      imageUrl: '/assets/investor-relations/board-member3.jpg',
    },
    {
      name: 'Dr Anil Dhawan',
      post: 'Independent Director',
      description: `Dr. Anil Dhawan is an Independent Director of our Company. He was appointed to our Board on August 30, 2018. He has passed his MBBS exam from the Himachal Pradesh University and has been awarded with the Shri Devi Chand Memorial Gold Medal and the Dr. Kranti Mohan Sharma Memorial Prize for securing first position in MBBS. He also holds a degree of MD in paediatrics from the Post Graduate Institute of Medical Education and Research (PGIMER), Chandigarh. He passed the examination of Educational Commission for Foreign Medical Graduates. He has over 25 years of experience in the Healthcare Industry. He worked as paediatric hepatologist at Kings College Hospital, London UK. Presently, He is director of Paediatric Liver GI and Nutrition Kings College Hospital, London UK.`,
      imageUrl: '/assets/investor-relations/board-member4.jpg',
    },
    {
      name: 'Sundari Raviprasad Pisupati',
      post: 'Independent Director',
      description: `Sundari Raviprasad Pisupati is an Independent Director of our Company. She was appointed to our Board on September 16, 2021. She holds a Bachelor of Laws degree from the The National Law School of India University, Bangalore, where she was the university topper and gold medallist. She also holds a Masters in Law degree from Columbia University School of Law, New York. She is a licensed lawyer in New York and India. She has over 27 years of experience as a lawyer in India and is qualified to practice in the USA and has handled a variety of large corporate and commercial transactions involving domestic and international clients. She advises companies in various industry verticals such as information technology, financial services, domestic and international funds, angel funds, venture capital, infrastructure, biotechnology and pharmaceutical companies among others. She has been enlisted in the Legal Powerlist 2020 as one of the Top Individual Lawyers and has also received the Certificate of Excellence under "25 Most Trusted Corporate Legal Consultant to Watch in 2019" by Startup City.`,
      imageUrl: '/assets/investor-relations/board-member5.jpg',
    },
    {
      name: 'Mr. Santanu Mukherjee',
      post: 'Independent Director',
      description: `Santanu Mukherjee is an Independent Director of our Company. He was appointed to our Board on October 22, 2021. He holds a Bachelor of Science (Honours) degree from the Presidency College, University of Calcutta. He is a certified associate of the Indian Institute of Bankers. He has over 37 years of experience in the banking sector. He was the Managing Director of State Bank of Hyderabad and also headed French operations of State Bank of India in Paris. He also worked in various important positions in Banks. He is a member of the Board of Governors of the Institute of Management Technology, Hyderabad. He is also serving on the boards of various reputed companies, including Bandhan Bank Limited,Muthoot Housing Finance Company Limited,Suven Lifesciences Ltd, etc.`,
      imageUrl: '/assets/investor-relations/board-member6.jpg',
    },
  ];

  ngOnInit(): void {}
  onNotify(id: any): void {
    $('.no[data-circle="' + id + '"]').trigger('click');
  }

  initTable(): void {
    $(document).ready(function () {
      $('#individual').DataTable();
    });
  }
  initTabToggle(): void {
    $('.tab-container').each(function () {
      if ($(this).attr('id') !== 'tc-1') {
        $(this).css('display', 'none');
      }
    });
    $('.tab').each(function () {
      if ($(this).attr('id') !== 'tab1') {
        $(this).css('display', 'none');
      }
    });
    $('.tabText').each(function () {
      let tabID = $(this).attr('id');
      if (tabID == 1) {
        $(this).css('background-color', '#444444');
      }
    });

    $('.tabText').on('click', function () {
      let tabID = $(this).attr('id');
      console.log(tabID);
      $('.tab').each(function () {
        // if ($(this).attr('id') !== 'tab1') {
        $(this).css('display', 'none');
        // }
      });
      $('.tab-container').each(function () {
        // if ($(this).attr('id') !== 'tab1') {
        $(this).css('display', 'none');
        // }
      });
      $(this).css('background-color', '#444444');
      $(this).siblings().css('background-color', '#3D2979');

      $('.tab' + tabID).css('display', 'flex');
      $('#tc-' + tabID).css('display', 'flex');
      $('#tc-' + tabID).css('background-color', '#FFFFFF');
    });
  }
  initMemberToggle(members: Array<any>): void {
    $('.member-item').on('click', function () {
      let memberId = $(this).attr('id');
      $('.member-info-popup').css('display', 'flex');
      $('#member-image').attr('src', members[memberId].imageUrl);
      $('#member-name').html(members[memberId].name);
      $('#member-post').html(members[memberId].post);
      $('#member-description').html(members[memberId].description);
    });

    $('#close').on('click', function () {
      $('.member-info-popup').css('display', 'none');
    });
  }

  ngAfterViewInit(): void {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
      this.initMemberToggle(this.boardOfDirectors);
      this.initTabToggle();
      //initAboutSlider();
    }
  }
}
