<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <section class="page wtwedo">
        <div class="row">
            <div class="col-md-5 col-sm-5 col-lg-4 col-xs-5">
                <div class="circuleframe active">
                    <img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" /> </div>
                <!-- End Circle -->
            </div>
            <div class="col-md-7 col-sm-7 col-lg-7 col-xs-7">
                <div class="pagetitle">
                    <h1 class="textgradient">{{title}}</h1>
                    <div class="underline">
                        <img src="assets/images/underline.png" alt=""> </div>
                    <h3 [innerHTML]="subtitle"></h3>

                </div>
            </div>
            <div class="col-md-7 col-sm-7 col-lg-7 col-xs-12">
                <p [innerHTML]="description"></p>
                <div class="dfindwrp">
                    <ul class="hlfcube">
                        <li>
                            <form action="" method="post" #f="ngForm">
                                <div class="locationwrp">
                                    <select class="select" name="selectedLocation" [(ngModel)]="selectedLocation">
										<option value="0" [selected]="selectedLocation == 0">Select location </option>
										<option *ngFor="let city of centerCity;let i = index;" [value]="city">{{city}}</option>
									</select>
                                    <span id="selectlocation" class="select-span"></span>
                                </div>
                                <div class="searchbtn">
                                    <input value="search" type="button" name="txtSearch" (click)="submitLocation(f)" />
                                </div>
                            </form>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <section class="pediatricianp">
        <div class="container">
            <ng-template ngFor let-city [ngForOf]="cities">
                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 OurCentertext" *ngIf="city.centres.length > 0">
                    <h3 class="col-xs-12">{{city.city}} </h3>
                    <ul class="cityname">
                        <li *ngFor="let center of city.centres">
                            <ng-template [ngIf]="center.speciality_masters[0]">
                                <a href="/our-centre/{{center.slug}}">
										{{center.center_name_alias}}
									</a>
                                <!--<a href="/{{center.speciality_masters[0].slug}}/centre/{{center.slug}}">
										{{center.center_name_alias}}
									</a>-->
                            </ng-template>
                        </li>
                    </ul>
                    <a href="/our-centre-details/{{city.slug}}" class="submitbtn">Contact Us</a>
                </div>
            </ng-template>

        </div>
    </section>
    <centres-map [centerList]="centerList" [centerId]="selectedCentreId"></centres-map>
    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>

    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>

</div>