import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL } from '../services/constants';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
// declare let initAboutTabs:any;

@Component({
  selector: 'csr-details',
  templateUrl: './csr-details.component.html',
  styles: [
    `
      .csrthumb {
        float: right;
        margin: 15px;
      }
      .pagecontent {
      }
      .pagecontent p,
      .pageheader p {
        display: inherit !important;
      }
    `,
  ],
})
export class CsrDetailsComponent implements OnDestroy, OnInit, AfterViewInit {
  constructor(
    private api: ApiService,
    private activatedRoute: ActivatedRoute,
    public metaTitle: Title,
    public meta: Meta
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
    }
  }

  public eventDetail: any = [];
  public slug: string;
  public title: any = '';
  public subtitle: any = '';
  public description: any = '';
  public imgsrc: any = '';
  public brief_facilities_description: any = '';
  public brief_facilities_image: any = '';
  public imgBasePath = '';
  public hidden = 'hidden';
  public logo = 'logo';
  public bread_crumb_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'About Us', link: '/about-us', class: '' },
    { label: 'CSR', link: '/about-us/csr', class: '' },
  ];

  public links: any = [];
  public activePage: string = 'csr';

  public id: any = '';
  // @ViewChildren('abouttabs') things: QueryList<any>;

  ngOnInit() {
    this.api
      .getAboutSubFooterDetails()
      .map((res) => res)
      .subscribe((data) => {
        this.links = data;
      });
    this.activatedRoute.params.subscribe((params: Params) => {
      this.slug = params['csrslug'];
      console.log('params', this.slug);
    });

    this.api.getPageDescription('csr').subscribe(
      (data) => {
        //this.title = data.page_descriptions[0].title,
        //this.subtitle = data.page_descriptions[0].subtitle,
        //this.description = data.page_descriptions[0].description,
        //this.imgsrc = data.page_descriptions[0].image,
        //this.imgBasePath = IMG_BASE_URL;
        if (data) {
          //console.log(data);
          this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
          this.meta.updateTag({
            name: 'keywords',
            content: data.page_descriptions[0].meta_keyword,
          });
          this.meta.updateTag({
            name: 'description',
            content: data.page_descriptions[0].meta_description,
          });
          this.meta.addTags([
            {
              property: 'og:title',
              content: data.page_descriptions[0].meta_title,
            },
            {
              property: 'og:description',
              content: data.page_descriptions[0].meta_description,
            },
            {
              property: 'og:keywords',
              content: data.page_descriptions[0].meta_keyword,
            },
          ]);
        }
      },
      (error) => {
        console.log(error);
      }
    );
    this.api.getCsrById(this.slug).subscribe(
      (data) => {
        console.log('data', data.csrs);
        this.getDescription(data.csrs);
      },
      (err) => {
        console.log('err');
      }
    );
  }
  public getDescription(data) {
    (this.title = data[0].title),
      (this.subtitle = data[0].sub_title),
      (this.description = data[0].long_description),
      (this.imgsrc = data[0].image),
      (this.imgBasePath = IMG_BASE_URL);
  }
  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
    }

    // this.things.changes.subscribe(t => {
    //   // this.ngForRendred();
    //   initAboutTabs();
    // })
  }

  ngOnDestroy() {}
}
