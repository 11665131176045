
import { Input, Component, OnDestroy, OnInit, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import * as moment from 'moment';

@Component({
	selector: 'day-slot',
	templateUrl: './book-an-appointment-available.component.html',
})

export class BookAnAppointmentAvailable implements OnInit,OnDestroy,AfterViewInit{
	@Input('slot') sessionSlot:any;
	@Output('closed') closed:EventEmitter<string> = new EventEmitter();
	@Output('slotDate') slotDate:EventEmitter<any> = new EventEmitter();
    constructor(){}
	ngOnInit(){}
	getSplitValue(str:string = null){
		return (/,/.test(str) ? str.split(',') :  [str]);
	}
	closeModal(){
		this.closed.emit('close');
	}
	takeDatePicker(date:any = null, sessionId:any = null, time:any = null):void{

		//console.log(date, time);
		let beforeSplit =  (date ? date.split('/') : '');
		let splitedTime =  (time ? time.split('-'):'');
		let apiBookingDate = null;
		if(beforeSplit){
			apiBookingDate = `${beforeSplit[1].trim()}-${beforeSplit[0].trim()}-${beforeSplit[2].trim()} ${splitedTime[1]}`;
		}
		let dateDetail = {
			"date":date,
			"dateTime":apiBookingDate,
			"session":sessionId
		};
		this.slotDate.emit(dateDetail);
	}
	ngAfterViewInit(){}
	ngOnDestroy(){
		this.sessionSlot = null;
	}
}
