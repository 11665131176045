import { Directive, HostListener, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[dropdownvalue]'

})
export class DropDownValue {
    @Input() dropdownvalue: string;
    public hel:any = this.el.nativeElement;
    constructor(public el: ElementRef) {}
    ngOnInit() {
        
    }
    ngAfterViewInit(){
        let label = this.hel.options[0].innerText;
       
        document.getElementById(this.dropdownvalue).innerText=label;
    }
    @HostListener('change') onMouseEnter() {
        let label = this.hel.options[this.hel.options.selectedIndex].text;
        document.getElementById(this.dropdownvalue).innerText=label;
    }
    @HostListener('onload') onload() {
        console.log(this.hel.options.selected)
    }
}