<div class="container-fluid">
  <div class="loder"></div>
  <sidenav></sidenav>

  <header-wrap
    [hidden]="hidden"
    [logo]="logo"
    (section)="onNotify($event)"
  ></header-wrap>
  <div *ngIf="modelPupupCon">
    <div *ngFor="let mpopup of modelPupup" [innerHTML]="mpopup.hcode" class="modelPupup"></div>    
</div>
  <!-- <div class="capopup CovidPandemicModelPopup" data-popup="popup-Covid19Pandemic" style="display: block;" *ngIf="modelPupupCon2">
    <a class="capopup-close capopupClose" data-popup-close="popup-Covid19Pandemic" href="javascript:void(0);" style="position: fixed; top: 30px; right: 20rem;background-color: #ffffff; padding: 2px 2rem;width: min-content; height: fit-content;"><img src="assets/images/icon-close2.png" alt="" /></a>
    <div *ngFor="let mpopup of modelPupup" class="capopup-inner bodyCont dbg" [style]="dBgImage">
      <a class="capopuplink" (click)="CovidPandemicModelPopupLink(mpopup.link, mpopup.linktarget, mpopup.settime)"></a>
      <div style="width: 100%; display: flex; align-items: center; justify-content: center; margin-top: 32%;">
        <a href="https://vaccinationpg.rainbowhospitals.in/" style="margin-left: 22%;">
          <div class="popupSubmit">
            <p >Slot booking (15-18 years)</p>
          </div>
        </a>
      </div>
      
    </div> -->
    <!-- <div *ngFor="let mpopup of modelPupup" class="capopup-inner bodyCont mbg"  [style]="mBgImage">
      <a class="capopuplink" (click)="CovidPandemicModelPopupLink(mpopup.link, mpopup.linktarget, mpopup.settime)"></a>
      <div style="width: 100%; display: flex; align-items: center; justify-content: center; margin-top: 32%;">
        <a href="https://vaccinationpg.rainbowhospitals.in/" style="margin-left: 22%;">
          <div class="popupSubmit">
            <p >Slot booking (15-18 years)</p>
          </div>
        </a>
      </div> -->
      <!-- <a class="capopup-close capopupClose" data-popup-close="popup-Covid19Pandemic" href="javascript:void(0);" style="top:15px"><img src="assets/images/icon-close2.png" alt="" /></a> -->
    <!-- </div> -->
<!-- </div> -->
  <section class="sfirst">
    <div class="background">
      <img
        src="assets/images/bg1.jpg"
        data-t="bg_01_00.jpg"
        data-d="bg1.jpg"
        class="bgImg bg0 active"
      />
      <img
        src="assets/images/bg2.jpg"
        data-t="bg_01_01.jpg"
        data-d="bg2.jpg"
        class="bgImg bg1"
      />
      <img
        src="assets/images/bg4.jpg"
        data-t="bg_01_02.jpg"
        data-d="bg4.jpg"
        class="bgImg bg2"
      />
      <img
        src="assets/images/bg3.jpg"
        data-t="bg_01_03.jpg"
        data-d="bg3.jpg"
        class="bgImg bg3"
      />
      <img
        src="assets/images/bg4-days.jpg"
        data-t="bg4-days.jpg"
        data-d="bg4-days.jpg"
        class="bgImg bg4"
      />
    </div>
    <div class="container homePageContainer">
      <div class="">
        <div class="redefiningwrp">
          <div class="hangingcircule"></div>
          <div #bannerCircle class="bannerCircle">
            <div class="no no1 active" data-circle="1">
              1
              <div class="label">What’s New</div>
            </div>
            <div class="no no2" data-circle="2" (click)="slider(1, $event)">
              2
              <div class="label">Child Care</div>
            </div>
            <div class="no no3" data-circle="3" (click)="slider(2, $event)">
              3
              <div class="label l2">Women Care</div>
            </div>

            <div class="no no4" data-circle="4" (click)="slider(4, $event)">
              4
              <div class="label">Fertility Care</div>
            </div>

            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 533.85 533.85"
              style="enable-background: new 0 0 533.85 533.85"
              xml:space="preserve"
            >
              <defs>
                <style>
                  .st0 {
                    fill: none;
                    stroke: #99a19b;
                    stroke-width: 14;
                    stroke-miterlimit: 10;
                    stroke-dasharray: 1, 2;
                  }

                  .st1 {
                    fill: none;
                    stroke: #ffffff;
                    stroke-width: 0;
                    stroke-miterlimit: 10;
                    stroke-dasharray: 1, 2;
                  }

                  .st2 {
                    fill: none;
                    stroke: #ffffff;
                    stroke-width: 15;
                    stroke-miterlimit: 10;
                    stroke-dasharray: 1, 2;
                  }

                  .st3 {
                    fill: none;
                    stroke: #ffffff;
                    stroke-width: 15;
                    stroke-miterlimit: 10;
                    stroke-dasharray: 1, 2;
                  }

                  .st0 {
                    stroke-dasharray: 1604;
                    stroke-dashoffset: 0;
                    stroke: #773a95;
                    -moz-transition: all 1s linear;
                    -ms-transition: all 1s linear;
                    -o-transition: all 1s linear;
                    -webkit-transition: all 1s linear;
                    transition: all 1s linear;
                  }

                  .st1 {
                    stroke-dasharray: 1604;
                    stroke-dashoffset: 0;
                    stroke: #ffffff;
                  }

                  .st2 {
                    stroke-dasharray: 2px 1px;
                    stroke-dashoffset: 0;
                    stroke: #e2e2e2;
                  }

                  .st0.active {
                    stroke-dashoffset: 1605px;
                    stroke: #773a95;
                  }

                  .st0.active0 {
                    stroke-dashoffset: 1605px;
                    stroke: #773a95;
                  }

                  .st0.active1 {
                    stroke-dashoffset: 1200px;
                    stroke: #773a95;
                  }

                  .st0.active2 {
                    stroke-dashoffset: 800px;
                    stroke: #773a95;
                  }

                  .st0.active3 {
                    stroke-dashoffset: 400px;
                    stroke: #773a95;
                  }
                </style>
              </defs>
              <path
                class="st1"
                d="M264.906,11.706c141.108,0,255.5,114.392,255.5,255.5c0,141.108-114.392,255.5-255.5,255.5
          s-255.5-114.393-255.5-255.5C9.406,126.097,123.797,11.706,264.906,11.706z"
              ></path>
              <path
                class="st0"
                d="M264.906,11.706c141.108,0,255.5,114.392,255.5,255.5c0,141.108-114.392,255.5-255.5,255.5
          s-255.5-114.393-255.5-255.5C9.406,126.097,123.797,11.706,264.906,11.706z"
              ></path>
              <path
                class="st2"
                d="M264.906,11.706c141.108,0,255.5,114.392,255.5,255.5c0,141.108-114.392,255.5-255.5,255.5
          s-255.5-114.393-255.5-255.5C9.406,126.097,123.797,11.706,264.906,11.706z"
              ></path>
            </svg>
            <!--<div class="zzwrp">
            <img src="assets/images/icon11.gif" class="zz1" />
            <img src="assets/images/icon12.gif" class="zz3" />
            <img src="assets/images/icon13.gif" class="zz2" /> </div>-->
            <div class="data">
              <img src="assets/images/loading.gif" alt="" class="cdloader" />
              <ng-template ngFor let-circle [ngForOf]="circleDate">
                <div
                  *ngIf="circle.default"
                  class="text first active"
                  style="display: none"
                >
                  <h2 class="gradient" [innerHTML]="circle.title"></h2>
                  <h3>{{ circle.subTitle }}</h3>
                  <p>{{ circle.description }}</p>
                  <div class="creadm" style="display: none">
                    <a
                      href="javascript:void()"
                      (click)="creadmFunc($event)"
                      class="knowmore2"
                      ><span
                        >Read more
                        <img
                          src="assets/images/btn-arrow-bottom.png"
                          alt="" /></span
                    ></a>
                  </div>
                </div>
                <div
                  *ngIf="!circle.default"
                  class="text tdata{{ circle.orderId }}"
                  style="width: 100%"
                >
                  <div class="circheder">
                    <div class="zzheadertitle">
                      <div
                        class="zzinrwrp"
                        [ngClass]="circle.title.length > 0 ? 'abc' : 'widset'"
                        style="
                          width: 100%;
                          margin: 0px 0px 10px 0px;
                          text-align: center;
                        "
                      >
                        <img
                          *ngIf="circle.icon2 != ''"
                          src="{{ circle.icon }}"
                          alt=""
                          class="hcir circleIcon2"
                        />
                        <img
                          *ngIf="circle.icon2 == ''"
                          src="assets/images/hcir/{{ circle.icon }}"
                          alt=""
                          class="hcir circleIcon"
                        />
                      </div>
                      <h2 class="gradient" [innerHTML]="circle.title"></h2>
                    </div>
                  </div>
                  <div class="creadm" *ngIf="circle.linkbuttonhide != 'yes'">
                    <a
                      *ngIf="!circle.link"
                      (click)="creadmFunc($event)"
                      href="javascript:void()"
                      class="knowmore2"
                    >
                      <span *ngIf="circle.linkname != ''"
                        >{{ circle.linkname }}
                        <img
                          src="assets/images/btn-arrow-{{
                            circle.direction
                          }}.png"
                          class="icon-{{ circle.direction }}"
                          alt="{{ circle.direction }} icon"
                        />
                      </span>
                      <span *ngIf="circle.linkname == ''"
                        >Read more
                        <img
                          src="assets/images/btn-arrow-{{
                            circle.direction
                          }}.png"
                          class="icon-{{ circle.direction }}"
                          alt="{{ circle.direction }} icon"
                        />
                      </span>
                    </a>
                    <a
                      *ngIf="circle.link"
                      href="{{ circle.link }}"
                      target="{{ circle.target }}"
                      class="knowmore2"
                    >
                      <span *ngIf="circle.linkname != ''"
                        >{{ circle.linkname }}
                        <img
                          src="assets/images/btn-arrow-{{
                            circle.direction
                          }}.png"
                          class="icon-{{ circle.direction }}"
                          alt="{{ circle.direction }} icon"
                        />
                      </span>
                      <span *ngIf="circle.linkname == ''"
                        >Read more
                        <img
                          src="assets/images/btn-arrow-{{
                            circle.direction
                          }}.png"
                          class="icon-{{ circle.direction }}"
                          alt="{{ circle.direction }} icon"
                        />
                      </span>
                    </a>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
          <!-- End Circle -->
        </div>

        <div class="coveringleft">
          <div class="sideslidertitle" *ngIf="leftHighlights">
            <h3>Clinical Corner</h3>
          </div>
          <div class="contentwrp">
            <slick-carousel class="coveringslider" *ngIf="leftHighlights">
              <div
                slick-carousel-item
                *ngFor="let highlight of leftHighlights"
                [sliderConfig]="coveringsliderConfig"
                class="sitem"
              >
                <div class="cvrbannwrp">
                  <img src="{{ imgBasePath }}/{{ highlight.image }}" alt="" />
                </div>
                <div class="cvrtxt">
                  <h2 class="textgradient">{{ highlight.title }}</h2>
                  <p
                    [innerHTML]="
                      highlight.description
                        | dgTruncate: { limit: 10, words: true }
                    "
                  ></p>
                  <a
                    *ngIf="highlight.read_more_link"
                    href="{{ highlight.read_more_link }}"
                    class="knowmore"
                    target="{{ highlight.link_target }}"
                  >
                    <span *ngIf="highlight.link_name != null"
                      >{{ highlight.link_name }}
                      <img src="assets/images/btn-arrow-right.png" alt=""
                    /></span>
                    <span *ngIf="highlight.link_name == null"
                      >Read More
                      <img src="assets/images/btn-arrow-right.png" alt=""
                    /></span>
                  </a>
                </div>
              </div>
            </slick-carousel>
          </div>
        </div>
        <div class="col-md-12 col-sm-6 col-lg-3 covering">
          <div class="sideslidertitle" *ngIf="rightHighlights">
            <h3>Rainbow Buzz</h3>
          </div>
          <div class="contentwrp">
            <slick-carousel class="coveringslider" *ngIf="rightHighlights">
              <div
                slick-carousel-item
                *ngFor="let highlight of rightHighlights"
                [sliderConfig]="coveringsliderConfig"
                class="sitem"
              >
                <div class="cvrbannwrp">
                  <img src="{{ imgBasePath }}/{{ highlight.image }}" alt="" />
                </div>
                <div class="cvrtxt">
                  <h2 class="textgradient">{{ highlight.title }}</h2>
                  <p
                    [innerHTML]="
                      highlight.description
                        | dgTruncate: { limit: 10, words: true }
                    "
                  ></p>
                  <a
                    *ngIf="highlight.read_more_link"
                    href="{{ highlight.read_more_link }}"
                    class="knowmore"
                    target="{{ highlight.link_target }}"
                  >
                    <span *ngIf="highlight.link_name != null"
                      >{{ highlight.link_name }}
                      <img src="assets/images/btn-arrow-right.png" alt=""
                    /></span>
                    <span *ngIf="highlight.link_name == null"
                      >Read More
                      <img src="assets/images/btn-arrow-right.png" alt=""
                    /></span>
                  </a>
                </div>
              </div>
            </slick-carousel>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="ourSpecialitieswrp">
    <div class="container">
      <div >
        <div class="ourstitlewrp">
          <h2 class="tgradient">
            Our <span>Services & Treatments</span><span class="bgt"></span>
          </h2>
          <p></p>
          <a href="{{ specialities_link }}" class="knowmore"
            ><span
              >Read More
              <img src="assets/images/btn-arrow-right.png" alt="" /></span
          ></a>
        </div>

        <slick-carousel class="oursslider" *ngIf="specialities.length > 0">
          <div
            slick-carousel-item
            *ngFor="let speciality of specialities; let i = index"
            class="sitem osbox{{ i + 1 }} anim hanginganim"
            [sliderConfig]="ourSliderConfig"
          >
            <div class="text">
              <div class="hangingcircule"></div>
              <div class="osicons">
                <img
                  *ngIf="speciality.animateimage"
                  src="{{ imgBasePath }}{{ speciality.animateimage }}"
                  alt=""
                /><img
                  *ngIf="!speciality.animateimage"
                  src="{{ imgBasePath }}{{ speciality.image }}"
                  alt=""
                />
              </div>
              <h3 class="textgradient">{{ speciality.title }}</h3>
              <p
                [innerHTML]="
                  speciality.description
                    | dgTruncate: { limit: 14, words: true }
                "
              ></p>
              <a
                href="{{ speciality.page }}/speciality/{{ speciality.slug }}"
                class="knowmore"
                ><span>Read More</span></a
              >
            </div>
          </div>
        </slick-carousel>
      </div>
    </div>
  </section>
  <section class="kaleidoscopewrp">
    <div class="container">
      <div class="row">
        <!-- <div class="ourstitlewrp">
          <h2 class="tgradient3">Rainbow <span>Kaleidoscope</span></h2>
        </div>
        <div class="clearfix"></div>
        <div class="row">
          <div #academicSection class="rainbowslider slider">
            <div class="rbitem">
              <div class="contentwrp rbbox1 anim">
                <div class="sitem osbox2 hanginganim">
                  <div class="text">
                    <div class="hangingcircule"></div>
                    <div class="osicons">
                      <img src="assets/images/icon-guide.gif" alt="" />
                    </div>
                    <h2 class="textgradient">
                      Rainbow <br />
                      <span>Guide</span>
                    </h2>

                    <slick-carousel>
                      <div
                        #academicsSlider
                        class="Rainbowslider"
                        *ngIf="homePageInfo.rainbowguidestar"
                      >
                        <div
                          *ngFor="
                            let academic of homePageInfo.rainbowguidestar[0]
                              .list
                          "
                          class="item"
                        >
                          <h3>{{ academic.title }}</h3>
                          <p [innerHTML]="academic.description"></p>
                          <a
                            *ngIf="academic.link"
                            [href]="academic.link"
                            class="knowmore"
                            ><span>Know more</span></a
                          >
                        </div>
                      </div>
                    </slick-carousel>
                    End Slider
                  </div>
                </div>
              </div>
              End wrp
            </div>
            <div class="rbitem">
              <div class="rainbownewswrp">
                <h2 class="textgradient text-center">
                  News &amp; <span>Events</span>
                </h2>
                <table cellpadding="0" cellspacing="0" border="0">
                  <tr *ngFor="let n of news">
                    <td align="left" valign="top" width="60">
                      <h3 class="textgradient">
                        {{ n.event_date | amDateFormat: "DD" }}
                      </h3>
                      <br />
                      <span class="monthn">
                        {{ n.event_date | amDateFormat: "MMM" }}</span
                      >
                    </td>
                    <td align="left" valign="top">
                      <div class="item">
                        <h4>{{ n.name }}</h4>
                        <h5>
                          {{ n.event_date | amDateFormat: "dddd, hh:mm" }}
                        </h5>
                        <p [innerHTML]="n.summery | truncatetext: 100"></p>
                        <a
                          *ngIf="n.type == 'events'"
                          href="/about-us/events/{{ n.slug }}"
                          class="knowmore"
                          ><span>Know more</span></a
                        >
                        <a
                          *ngIf="n.type == 'news'"
                          href="/about-us/news/news-details/{{ n.slug }}"
                          class="knowmore"
                          ><span>Know more</span></a
                        >
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
              End Wrp
            </div>
            <div class="rbitem" *ngIf="awards">
              <div class="contentwrp rbbox2 anim">
                <div class="sitem osbox2 hanginganim">
                  <div class="text">
                    <div class="hangingcircule"></div>
                    <div class="osicons">
                      <img src="assets/images/icon-awards.gif" alt="" />
                    </div>
                    <h2 class="textgradient">Awards</h2>

                    <div class="Rainbowslider">
                      <div *ngFor="let award of awards" class="item">
                        <h4>{{ award.subtitle }}</h4>
                        <p>{{ award.year }}</p>
                        <a href="/about-us/awards" class="knowmore"
                          ><span>Know more</span></a
                        >
                      </div>
                    </div>
                    End Slider
                  </div>
                </div>
                End wrp
              </div>
            </div>
          </div>
        </div>
        <div class="clearfix"></div> -->

        <div class="col-md-12 col-sm-12 col-lg-4" style="margin-top: -6em;">
          <div
            class="rainbowdateandinswrp"
            *ngFor="let duedate of homePageInfo.knowyourduedate | slice: 0:1"
          >
            <h2 class="textgradient" [innerHTML]="duedate.heading"></h2>
            <table cellpadding="0" cellspacing="0" border="0">
              <tr>
                <td align="left" valign="top" width="60">
                  <img [src]="duedate.img" [alt]="duedate.title" />
                </td>
                <td align="left" valign="top">
                  <div class="item">
                    <h4 [innerHTML]="duedate.title"></h4>
                    <p
                      [innerHTML]="duedate.description | truncate: [80, '...']"
                    ></p>
                    <a [href]="duedate.link" class="knowmore"
                      ><span>Know more</span></a
                    >
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="col-md-12 col-sm-12 col-lg-4" style="margin-top: -6em;"></div>
        <div class="col-md-12 col-sm-12 col-lg-4" style="margin-top: -6em;">
          <div
            class="rainbowdateandinswrp"
            *ngFor="let insurancedetail of homePageInfo.insurance | slice: 0:1"
          >
            <h2 class="textgradient" [innerHTML]="insurancedetail.heading"></h2>
            <table cellpadding="0" cellspacing="0" border="0">
              <tr>
                <td align="left" valign="top" width="60">
                  <div class="mthumberp">
                    <img
                      [src]="insurancedetail.img"
                      [alt]="insurancedetail.title"
                    />
                  </div>
                </td>
                <td align="left" valign="top">
                  <div class="item">
                    <h5 [innerHTML]="insurancedetail.title"></h5>
                    <p
                      [innerHTML]="
                        insurancedetail.description | truncate: [80, '...']
                      "
                    ></p>
                    <a [href]="insurancedetail.link" class="knowmore"
                      ><span>Know more</span></a
                    >
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Rainbow Kaleidoscope -->
  <section class="aboutuswrp">
    <div class="container">
      <div class="">
        <div class="ourstitlewrp">
          <h2 class="tgradient4">About <span>us</span></h2>
        </div>
        <div class="clearfix"></div>
        <div class="">
          <div class="aboutslider slider">
            <div class="abitem">
              <div class="col-md-12 col-sm-12 col-lg-9 col-xs-offset-0 col-lg-offset-3">
                <div class="aboutuscontentwrp">
                  <h2 class="textgradient">{{ about.title }}</h2>
                  <p [innerHTML]="about.description"></p>
                  <a href="{{ about.link }}" class="knowmore"
                    ><span>Know more</span></a
                  >
                </div>
              </div>
            </div>
            <div class="abitem">
              <div class="col-md-12 col-sm-12 col-lg-12">
                <div class="contentwrp anim" >
                  <div
                    class="sitem abbox1"
                    *ngFor="let mdprofile of homePageInfo.mdProfile"
                  >
                    <div class="circulewrp">
                      <div class="text">
                        <div class="hangingcircule"></div>
                        <div class="bordercir"></div>
                        <a
                          href="https://www.youtube.com/embed/{{
                            youtube_parser(
                              'https://www.youtube.com/watch?v=e_qpNBdO2-w'
                            )
                          }}"
                          class="playvid fancybox"
                        >
                          <span></span>
                          <img [src]="mdprofile.img" [alt]="mdprofile.title" />
                        </a>
                      </div>
                    </div>
                    <div class="aboutap">
                      <div class="abtitle">
                        <h2
                          class="textgradient"
                          [innerHTML]="mdprofile.heading"
                        ></h2>
                      </div>
                      <div class="clearfix"></div>
                      <div class="details">
                        <h3 [innerHTML]="mdprofile.title"></h3>
                        <!-- <p [innerHTML]="mdprofile.description"></p> -->
                        <a [href]="mdprofile.link" class="knowmore"
                          ><span>View Profile</span></a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="abitem">
              <div
                class="
                  col-md-12 col-sm-12 col-lg-12 test-align
                "
              >
                <div
                  class="aboutuscontentwrp"
                  *ngFor="let career of homePageInfo.careerInfo"
                >
                  <h2 class="textgradient" [innerHTML]="career.heading"></h2>
                  <div class="clearfix"></div>
                  <div class="abicons">
                    <img [src]="career.img" [alt]="career.heading" />
                  </div>
                  <p [innerHTML]="career.description"></p>
                  <div class="openingwrp">
                    <h2>OPENINGS</h2>
                    <ul class="openingwrpUL">
                      <li *ngFor="let career of careers; let i = index">
                        <h2
                          (click)="currentwrpUL = i"
                          [class.active]="currentwrpUL == i"
                        >
                          {{ career.title }}
                        </h2>
                        <div
                          *ngIf="career.short_description"
                          class="openingstaff_cont"
                          [ngClass]="{ active: currentwrpUL == i }"
                          [innerHTML]="career.short_description"
                        ></div>
                      </li>
                    </ul>
                  </div>
                  <a [href]="career.link" class="knowmore sc CareersBtnAll"
                    ><span>View All</span></a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Aboutus -->
  <section class="sstorieswrp">
    <div class="container">
      <div class="row">
        <div class="ourstitlewrp">
          <h2 class="tgradient5">Success <span>Stories</span></h2>
        </div>
        <div class="clearfix"></div>
        <div class="col-md-12 col-sm-12 col-lg-12">
          <div class="sstorieslider slider">
            <div
              *ngFor="
                let story of successStories;
                let i = index;
                odd as isOdd;
                even as isEven
              "
              class="ssitem"
            >
              <div [class.ssright]="isOdd" [class.ssleft]="isEven">
                <div class="ssthumbwrp" *ngIf="story.youtube">
                  <div class="thumb">
                    <a [href]="story.youtube" class="fancybox playvid">
                      <span></span>
                      <img src="{{ imgBasePath }}{{ story.thumb }}" alt=" " />
                    </a>
                  </div>
                </div>
                <div class="ssthumbwrp" *ngIf="!story.youtube">
                  <div class="thumb">
                    <img src="{{ imgBasePath }}{{ story.thumb }}" alt=" " />
                  </div>
                </div>
                <div class="ssdetailswrp">
                  <div class="ssicon">
                    <img src="{{ imgBasePath }}{{ story.icon }}" alt="" />
                  </div>
                  <ul>
                    <li>
                      <div class="txtComments">
                        <p>{{ story.comment }}</p>
                      </div>
                      <div class="txtName">
                        <h3 class="textgradient">
                          {{ story.userTitle
                          }}<span>{{ story.userName }} </span>
                        </h3>
                        <h4 *ngIf="story.city">( {{ story.city }} )</h4>

                        <a
                          *ngIf="isOdd"
                          [href]="story.readmore"
                          style="margin-left: 35px"
                          class="knowmore"
                          ><span>read more</span></a
                        >
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Success Stories -->
  <centres-map
    [centerList]="centerDetailList"
    [centerId]="selectedCentreId"
  ></centres-map>
  <!-- End Google Map -->
  <find-a-doctors-nav></find-a-doctors-nav>
  <footer-subscription></footer-subscription>
  <footer class="footerNavwrp">
    <footer-nav></footer-nav>
  </footer>
  <footer-copyright-nav></footer-copyright-nav>
</div>

<!-- <div *ngIf="modelPupupCon">
  <div
    *ngFor="let mpopup of modelPupup"
    [innerHTML]="mpopup.hcode"
    class="modelPupup"
  ></div>
</div>
<div
  class="capopup CovidPandemicModelPopup"
  data-popup="popup-Covid19Pandemic"
  style="display: block"
  *ngIf="modelPupupCon2"
>
  <div
    *ngFor="let mpopup of modelPupup"
    class="capopup-inner bodyCont dbg"
    [style]="dBgImage"
  >
    <a
      class="capopuplink"
      (click)="
        CovidPandemicModelPopupLink(
          mpopup.link,
          mpopup.linktarget,
          mpopup.settime
        )
      "
    ></a>
    <a
      class="capopup-close capopupClose"
      data-popup-close="popup-Covid19Pandemic"
      href="javascript:void(0);"
      style="top: 15px"
      ><img src="assets/images/icon-close2.png" alt=""
    /></a>
  </div>
  <div
    *ngFor="let mpopup of modelPupup"
    class="capopup-inner bodyCont mbg"
    [style]="mBgImage"
  >
    <a
      class="capopuplink"
      (click)="
        CovidPandemicModelPopupLink(
          mpopup.link,
          mpopup.linktarget,
          mpopup.settime
        )
      "
    ></a>
    <a
      class="capopup-close capopupClose"
      data-popup-close="popup-Covid19Pandemic"
      href="javascript:void(0);"
      style="top: 15px"
      ><img src="assets/images/icon-close2.png" alt=""
    /></a>
  </div>
</div> -->
