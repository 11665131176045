import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  HostListener,
  PLATFORM_ID,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { isPlatformBrowser, DatePipe } from '@angular/common';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL } from '../services/constants';
import { Meta, Title } from '@angular/platform-browser';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Pipe, PipeTransform } from '@angular/core';
import { TruncateTextPipe } from '../services/truncatetext';

declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
// declare let initAboutTabs:any;

@Component({
  selector: 'videohub',
  templateUrl: './videohub.component.html',
})
export class VideoHubComponent implements OnDestroy, OnInit, AfterViewInit {
  constructor(
    private api: ApiService,
    public metaTitle: Title,
    public meta: Meta,
    public datepipe: DatePipe,
    private sanitizer: DomSanitizer
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/fancybox/jquery.fancybox.min.js');
      require('../../assets/js/slick.min.js');
    }
  }

  public services: any[] = [];
  public title: any = '';
  public subtitle: any = '';
  public description: any = '';
  public imgsrc: any = '';
  public brief_facilities_description: any = '';
  public brief_facilities_image: any = '';
  public imgBasePath = '';
  public hidden = 'hidden';
  public logo = 'logo';
  public bread_crumb_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'About Us', link: '/about-us', class: '' },
    { label: 'Video Hub', link: '', class: '' },
  ];

  public specilityMasterList = [
    {
      id: 1,
      slug: 'child-care',
      name: 'Child Care',
    },
    {
      id: 2,
      slug: 'women-care',
      name: 'Women Health',
    },
    {
      id: 3,
      slug: 'fertility-care',
      name: 'Fertility Care',
    },
  ];

  public links: any = [];
  public activePage: string = 'video-hub';
  public videoList: any = [];
  public SpecialtyID: any;

  public videoListResult: boolean = false;
  public VideoDetails: any;
  public vhWebinarSpecialityID: any = '';
  public vhWebinarLocationID: any = '';
  public vhWebinarSSpecialityID: any = '';

  public testimonialListResult: boolean = false;
  public testimonialList: any;
  public vhTestimonialSpecialityID: any = '';
  public vhTestimonialLocationID: any = '';
  public vhTestimonialSSpecialityID: any = '';

  public NewsNMediaListResult: boolean = false;
  public NewsNMediaList: any;
  public vhNewsNMediaSpecialityID: any = '';
  public vhNewsNMediaLocationID: any = '';
  public vhNewsNMediaSSpecialityID: any = '';

  public eventsListResult: boolean = false;
  public eventsList: any;
  public vhEventsSpecialityID: any = '';
  public vhEventsLocationID: any = '';
  public vhEventsSSpecialityID: any = '';

  public videoHubBannerListResult: boolean = false;
  public videoHubBannerList: any;

  urlSafe: SafeResourceUrl;
  facebookUrlSafe: SafeResourceUrl;
  contentvideourlexit: boolean = false;

  public SubCategoryList: any = [];

  public speciality_master: number;
  public speciality_uid: number = 0;
  public specilityMaster: string = '0';
  public activeRoute: any;
  public centerList: any;
  public specialityFeatures: any;
  public NewsEventsCenterList: any;

  public CategoryList = [
    {
      ID: 1,
      Category: 'Webinars',
      SubCategory: [
        {
          SUBID: '1',
          SUBVALUE: 'Patient',
        },
        {
          SUBID: '2',
          SUBVALUE: 'Clinical',
        },
      ],
    },
    {
      ID: 2,
      Category: 'Patient Testimonials',
      SubCategory: [
        {
          SUBID: '3',
          SUBVALUE: 'RCH',
        },
        {
          SUBID: '4',
          SUBVALUE: 'BR',
        },
        {
          SUBID: '5',
          SUBVALUE: 'BR-F',
        },
      ],
    },
    {
      ID: 3,
      Category: 'News & Media',
      SubCategory: [
        {
          SUBID: '6',
          SUBVALUE: 'Safety Video',
        },
      ],
    },
    {
      ID: 4,
      Category: 'Events',
      SubCategory: [
        {
          SUBID: '7',
          SUBVALUE: 'RCH',
        },
        {
          SUBID: '8',
          SUBVALUE: 'BR',
        },
        {
          SUBID: '9',
          SUBVALUE: 'N/A',
        },
      ],
    },
  ];

  // @ViewChildren('abouttabs') things: QueryList<any>;

  ngOnInit() {
    $('.customloder').show();
    //console.log('sda', this.CategoryList)
    this.getVideoHubBanner();
    this.getNewsEventsCentreCities();
    this.api
      .getAboutSubFooterDetails()
      .map((res) => res)
      .subscribe((data) => {
        this.links = data;
      });
    this.api.getPageDescription('video-hub').subscribe(
      (data) => {
        (this.title = data.page_descriptions[0].title),
          (this.subtitle = data.page_descriptions[0].subtitle),
          (this.description = data.page_descriptions[0].description),
          (this.imgsrc = data.page_descriptions[0].image),
          (this.imgBasePath = IMG_BASE_URL);
        if (data) {
          //console.log(data);
          this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
          this.meta.updateTag({
            name: 'keywords',
            content: data.page_descriptions[0].meta_keyword,
          });
          this.meta.updateTag({
            name: 'description',
            content: data.page_descriptions[0].meta_description,
          });
          this.meta.addTags([
            {
              property: 'og:title',
              content: data.page_descriptions[0].meta_title,
            },
            {
              property: 'og:description',
              content: data.page_descriptions[0].meta_description,
            },
            {
              property: 'og:keywords',
              content: data.page_descriptions[0].meta_keyword,
            },
          ]);
        }
      },
      (error) => {
        console.log(error);
      }
    );
    //this.getVideoHub('','');
    this.getVideoHubWebinar();
    this.getVideoHubTestimonial();
    this.getVideoHubNewMedia();
    this.getVideoHubEvents();
  }

  copyToClipboard(e) {
    //console.log(e.target.children[0].innerHTML);
    const str = e.target.children[0].innerHTML;
    const el = document.createElement('textarea');
    el.className = 'createElementTextarea';
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    alert(e.target.children[0].innerHTML);
    //document.body.removeChild(el);
  }

  facebookVideo(videoId) {
    let popup = document.querySelector('.facebookVideo');
    popup.classList.add('active');

    console.log(popup.childNodes, videoId);
    popup.childNodes[0][
      'src'
    ] = `https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Ffacebook%2Fvideos%2F${videoId}%2F&show_text=false&width=734&appId=885401101640404&height=411`;

    // if(src){
    // 	popup.childNodes[0]['src'] = 'https://www.facebook.com/rainbowchildrenshospitals/videos/962506964176050'
    // }
  }

  closeFacebookPopup() {
    let popup = document.querySelector('.facebookVideo');
    popup.classList.remove('active');
    popup.childNodes[0]['src'] = ``;
  }

  onClickVideoDetails(e, data) {
    let id = e.target.parentNode.parentNode.getAttribute('id');
    $('#' + id).addClass('active');
    this.VideoDetails = [data];
    if (this.VideoDetails[0].facebookUrl) {
      this.contentvideourlexit = false;
      this.facebookUrlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
        `https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Ffacebook%2Fvideos%2F${this.VideoDetails[0].contentvideourlNew}%2F&show_text=false&width=734&appId=885401101640404&height=411&mute=0&allowfullscreen=true&autoplay=true`
      );
    } else {
      this.contentvideourlexit = true;
      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.VideoDetails[0].contentvideourlNew + '?autoplay=1&controls=0'
      );
    }
    customModal();
  }

  closeVideoModal() {
    $('.c_modal, .ImageBox, .dp_item')
      .removeClass('active')
      .css({ left: '', top: '' });
    $('.modal__content').css({ height: 'auto' });
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl('');
    this.facebookUrlSafe = this.sanitizer.bypassSecurityTrustResourceUrl('');
  }

  onClickOverFlow(e) {
    //console.log('onClickOverFlow: ', e.target.getAttribute("id"), e.target.parentNode.getAttribute("id"));
    if ('modal2' == e.target.getAttribute('id')) {
      this.closeVideoModal();
    }
  }

  getVideoHubBanner() {
    this.api.getVideoHubBanner().subscribe((data) => {
      //console.log('getVideoHubBanner: ', data);
      let videoHubBanner = data;
      let r = new RegExp('^(?:[a-z]+:)?//', 'i');
      if (videoHubBanner.length != 0) {
        this.videoHubBannerListResult = false;
      } else {
        this.videoHubBannerListResult = true;
      }

      if (videoHubBanner) {
        let chubarray = [];
        let bannerDataLimit = 0,
          bannerDataListNumber = 0;
        videoHubBanner.forEach((element) => {
          bannerDataLimit++;
          bannerDataListNumber++;
          if (bannerDataLimit < 4) {
            element.bannerNumber = bannerDataListNumber;
            if (r.test(element.contentimage)) {
              element.contentimageNew = element.contentimage;
            } else {
              element.contentimageNew = IMG_BASE_URL + element.contentimage;
            }
            if (r.test(element.contentvideoimage)) {
              element.contentvideoimageNew = element.contentvideoimage;
            } else {
              element.contentvideoimageNew =
                IMG_BASE_URL + element.contentvideoimage;
            }
            element.contentdate = this.datepipe.transform(
              element.contentdate,
              'd-MMMM-y'
            );
            if (element.contentvideourl != null) {
              if (element.contentvideourl.includes('https://youtu.be')) {
                let youtube_video_id = element.contentvideourl
                  .match(/youtu\.be.*(\/)(.{11})/)
                  .pop();
                element.contentvideourlNew =
                  'https://www.youtube.com/embed/' + youtube_video_id;
                element.facebookUrl = false;
              } else if (
                element.contentvideourl.includes('https://www.facebook.com')
              ) {
                let str = element.contentvideourl;
                var last = str.substring(str.lastIndexOf('/') + 1, str.length);
                element.contentvideourlNew = last;
                element.facebookUrl = true;
              }
            }
            chubarray.push(element);
          }
        });
        this.videoHubBannerList = chubarray;
        console.log('videoHubBannerList: ', this.videoHubBannerList);
      }

      setTimeout(() => {
        customBannerSlider();
      }, 1000);
    });
  }

  onChangeWebnarSpecialty(e) {
    $('#cWebnarLocation').show();
    $('#sl_Webnar').show();
    this.vhWebinarSpecialityID = e.target.value;
    this.getCentreCities();
    this.getVideoHubWebinarFilter();
  }
  onChangeWebnarLocation(e) {
    $('#cWebnarSpecialityFeatures').show();
    $('#sl_Webnar').show();
    this.vhWebinarLocationID = e.target.value;
    this.getAllCentresSpeciality(e.target.value);
    this.getVideoHubWebinarFilter();
  }
  onChangeWebnarSpecialityFeatures(e) {
    $('#sl_Webnar').show();
    this.vhWebinarSSpecialityID = e.target.value;
    console.log('SpecialityFeatures ID : ', e.target.value);
    this.getVideoHubWebinarFilter();
  }
  getVideoHubWebinar() {
    this.api.getVideoHubWebinar().subscribe((data) => {
      //console.log('getVideoHubWebinar: ', data);
      let content_hubs_Webinar = data;
      let r = new RegExp('^(?:[a-z]+:)?//', 'i');
      if (content_hubs_Webinar.length != 0) {
        this.videoListResult = false;
      } else {
        this.videoListResult = true;
      }

      if (content_hubs_Webinar) {
        let chubarray = [];
        content_hubs_Webinar.forEach((element) => {
          if (r.test(element.contentimage)) {
            element.contentimageNew = element.contentimage;
          } else {
            element.contentimageNew = IMG_BASE_URL + element.contentimage;
          }
          if (r.test(element.contentvideoimage)) {
            element.contentvideoimageNew = element.contentvideoimage;
          } else {
            element.contentvideoimageNew =
              IMG_BASE_URL + element.contentvideoimage;
          }
          element.contentdate = this.datepipe.transform(
            element.contentdate,
            'd-MMMM-y'
          );
          if (element.contentvideourl != null) {
            if (element.contentvideourl.includes('https://youtu.be')) {
              let youtube_video_id = element.contentvideourl
                .match(/youtu\.be.*(\/)(.{11})/)
                .pop();
              element.contentvideourlNew =
                'https://www.youtube.com/embed/' + youtube_video_id;
              element.facebookUrl = false;
            } else if (
              element.contentvideourl.includes('https://www.facebook.com')
            ) {
              let str = element.contentvideourl;
              var last = str.substring(str.lastIndexOf('/') + 1, str.length);
              element.contentvideourlNew = last;
              element.facebookUrl = true;
            }
          }
          chubarray.push(element);
        });
        this.videoList = chubarray;
      }
      //$('.customloder').hide();
      setTimeout(() => {
        WebinarSider();
        cutomeSlickSlider();
      }, 1000);
    });
  }
  getVideoHubWebinarFilter() {
    this.videoList = [];
    setTimeout(() => {
      $('.WebinarListWrp').removeClass('active');
      $('.WebinarSider')
        .removeClass('slick-initialized')
        .removeClass('slick-slider');
    }, 100);
    this.api
      .getVideoHubWebinarFilter(
        this.vhWebinarSpecialityID,
        this.vhWebinarLocationID,
        this.vhWebinarSSpecialityID
      )
      .subscribe((data) => {
        //console.log('getVideoHubWebinarFilter: ', data, data.length);
        let content_hubs_Webinar = data;
        let r = new RegExp('^(?:[a-z]+:)?//', 'i');
        if (content_hubs_Webinar.length != 0) {
          this.videoListResult = false;
        } else {
          this.videoListResult = true;
        }

        if (content_hubs_Webinar) {
          let chubarray = [];
          content_hubs_Webinar.forEach((element) => {
            if (r.test(element.contentimage)) {
              element.contentimageNew = element.contentimage;
            } else {
              element.contentimageNew = IMG_BASE_URL + element.contentimage;
            }
            if (r.test(element.contentvideoimage)) {
              element.contentvideoimageNew = element.contentvideoimage;
            } else {
              element.contentvideoimageNew =
                IMG_BASE_URL + element.contentvideoimage;
            }
            element.contentdate = this.datepipe.transform(
              element.contentdate,
              'd-MMMM-y'
            );
            if (element.contentvideourl != null) {
              if (element.contentvideourl.includes('https://youtu.be')) {
                let youtube_video_id = element.contentvideourl
                  .match(/youtu\.be.*(\/)(.{11})/)
                  .pop();
                element.contentvideourlNew =
                  'https://www.youtube.com/embed/' + youtube_video_id;
                element.facebookUrl = false;
              } else if (
                element.contentvideourl.includes('https://www.facebook.com')
              ) {
                let str = element.contentvideourl;
                var last = str.substring(str.lastIndexOf('/') + 1, str.length);
                element.contentvideourlNew = last;
                element.facebookUrl = true;
              }
            }
            chubarray.push(element);
          });
          this.videoList = chubarray;
        }
        //$('.customloder').hide();
        setTimeout(() => {
          setTimeout(() => {
            $('#sl_Webnar').hide();
            $('.WebinarListWrp').addClass('active');
          }, 500);
          WebinarSider();
          cutomeSlickSlider();
        }, 1000);
      });
  }

  onChangeTestimonialSpecialty(e) {
    $('#cTestimonialLocation').show();
    $('#sl_Testimonials').show();
    this.vhTestimonialSpecialityID = e.target.value;
    this.getCentreCities();
    this.getVideoHubTestimonialFilter();
  }
  onChangeTestimonialLocation(e) {
    $('#cTestimonialSpecialityFeatures').show();
    $('#sl_Testimonials').show();
    this.vhTestimonialLocationID = e.target.value;
    this.getAllCentresSpeciality(e.target.value);
    this.getVideoHubTestimonialFilter();
  }
  onChangeTestimonialSpecialityFeatures(e) {
    $('#sl_Testimonials').show();
    this.vhTestimonialSSpecialityID = e.target.value;
    console.log('SpecialityFeatures ID : ', e.target.value);
    this.getVideoHubTestimonialFilter();
  }
  getVideoHubTestimonial() {
    this.api.getVideoHubTestimonial().subscribe((data) => {
      //console.log('getVideoHubTestimonial: ', data);
      let content_hubs_Testimonial = data;
      let r = new RegExp('^(?:[a-z]+:)?//', 'i');
      if (content_hubs_Testimonial.length != 0) {
        this.testimonialListResult = false;
      } else {
        this.testimonialListResult = true;
      }

      if (content_hubs_Testimonial) {
        let chubarray = [];
        content_hubs_Testimonial.forEach((element) => {
          if (r.test(element.contentimage)) {
            element.contentimageNew = element.contentimage;
          } else {
            element.contentimageNew = IMG_BASE_URL + element.contentimage;
          }
          if (r.test(element.contentvideoimage)) {
            element.contentvideoimageNew = element.contentvideoimage;
          } else {
            element.contentvideoimageNew =
              IMG_BASE_URL + element.contentvideoimage;
          }
          element.contentdate = this.datepipe.transform(
            element.contentdate,
            'd-MMMM-y'
          );
          if (element.contentvideourl != null) {
            if (element.contentvideourl.includes('https://youtu.be')) {
              let youtube_video_id = element.contentvideourl
                .match(/youtu\.be.*(\/)(.{11})/)
                .pop();
              element.contentvideourlNew =
                'https://www.youtube.com/embed/' + youtube_video_id;
              element.facebookUrl = false;
            } else if (
              element.contentvideourl.includes('https://www.facebook.com')
            ) {
              let str = element.contentvideourl;
              var last = str.substring(str.lastIndexOf('/') + 1, str.length);
              element.contentvideourlNew = last;
              element.facebookUrl = true;
            }
          }
          chubarray.push(element);
        });
        this.testimonialList = chubarray;
      }
      //$('.customloder').hide();
      setTimeout(() => {
        testimonialsSider();
        cutomeSlickSlider();
      }, 1000);
    });
  }
  getVideoHubTestimonialFilter() {
    this.testimonialList = [];
    setTimeout(() => {
      $('.testimonialsWrp').removeClass('active');
      $('.testimonialsSider')
        .removeClass('slick-initialized')
        .removeClass('slick-slider');
    }, 100);
    this.api
      .getVideoHubTestimonialFilter(
        this.vhTestimonialSpecialityID,
        this.vhTestimonialLocationID,
        this.vhTestimonialSSpecialityID
      )
      .subscribe((data) => {
        console.log('getVideoHubTestimonialFilter: ', data, data.length);
        let content_hubs_Testimonial = data;
        let r = new RegExp('^(?:[a-z]+:)?//', 'i');
        if (content_hubs_Testimonial.length != 0) {
          this.testimonialListResult = false;
        } else {
          this.testimonialListResult = true;
        }

        if (content_hubs_Testimonial) {
          let chubarray = [];
          content_hubs_Testimonial.forEach((element) => {
            if (r.test(element.contentimage)) {
              element.contentimageNew = element.contentimage;
            } else {
              element.contentimageNew = IMG_BASE_URL + element.contentimage;
            }
            if (r.test(element.contentvideoimage)) {
              element.contentvideoimageNew = element.contentvideoimage;
            } else {
              element.contentvideoimageNew =
                IMG_BASE_URL + element.contentvideoimage;
            }
            element.contentdate = this.datepipe.transform(
              element.contentdate,
              'd-MMMM-y'
            );
            if (element.contentvideourl != null) {
              if (element.contentvideourl.includes('https://youtu.be')) {
                let youtube_video_id = element.contentvideourl
                  .match(/youtu\.be.*(\/)(.{11})/)
                  .pop();
                element.contentvideourlNew =
                  'https://www.youtube.com/embed/' + youtube_video_id;
                element.facebookUrl = false;
              } else if (
                element.contentvideourl.includes('https://www.facebook.com')
              ) {
                let str = element.contentvideourl;
                var last = str.substring(str.lastIndexOf('/') + 1, str.length);
                element.contentvideourlNew = last;
                element.facebookUrl = true;
              }
            }
            chubarray.push(element);
          });
          this.testimonialList = chubarray;
        }
        //$('.customloder').hide();

        setTimeout(() => {
          setTimeout(() => {
            $('#sl_Testimonials').hide();
            $('.testimonialsWrp').addClass('active');
          }, 500);
          testimonialsSider();
          cutomeSlickSlider();
        }, 1000);
      });
  }

  onChangeNewsNMediaSpecialty(e) {
    $('#cNewsNMediaLocation').show();
    $('#sl_NewsNMedia').show();
    this.vhNewsNMediaSpecialityID = e.target.value;
    this.getCentreCities();
    this.getVideoHubNewMediaFilter();
  }
  onChangeNewsNMediaLocation(e) {
    $('#sl_NewsNMedia').show();
    //$('#cSpecialityFeatures').show();
    this.vhNewsNMediaLocationID = e.target.value;
    //this.getAllCentresSpeciality(e.target.value);
    this.getVideoHubNewMediaFilter();
  }
  onChangeNewsNMediaSpecialityFeatures(e) {
    $('#sl_NewsNMedia').show();
    this.vhNewsNMediaSSpecialityID = e.target.value;
    //console.log('SpecialityFeatures ID : ', e.target.value);
    this.getVideoHubNewMediaFilter();
  }
  getVideoHubNewMedia() {
    this.api.getVideoHubNewMedia().subscribe((data) => {
      //console.log('getVideoHubNewMedia: ', data);
      let content_hubs_NewsNMedia = data;
      let r = new RegExp('^(?:[a-z]+:)?//', 'i');
      if (content_hubs_NewsNMedia.length != 0) {
        this.NewsNMediaListResult = false;
      } else {
        this.NewsNMediaListResult = true;
      }

      if (content_hubs_NewsNMedia) {
        let chubarray = [];
        content_hubs_NewsNMedia.forEach((element) => {
          if (r.test(element.contentimage)) {
            element.contentimageNew = element.contentimage;
          } else {
            element.contentimageNew = IMG_BASE_URL + element.contentimage;
          }
          if (r.test(element.contentvideoimage)) {
            element.contentvideoimageNew = element.contentvideoimage;
          } else {
            element.contentvideoimageNew =
              IMG_BASE_URL + element.contentvideoimage;
          }
          element.contentdate = this.datepipe.transform(
            element.contentdate,
            'd-MMMM-y'
          );
          if (element.contentvideourl != null) {
            if (element.contentvideourl.includes('https://youtu.be')) {
              let youtube_video_id = element.contentvideourl
                .match(/youtu\.be.*(\/)(.{11})/)
                .pop();
              element.contentvideourlNew =
                'https://www.youtube.com/embed/' + youtube_video_id;
              element.facebookUrl = false;
            } else if (
              element.contentvideourl.includes('https://www.facebook.com')
            ) {
              let str = element.contentvideourl;
              var last = str.substring(str.lastIndexOf('/') + 1, str.length);
              element.contentvideourlNew = last;
              element.facebookUrl = true;
            }
          }
          chubarray.push(element);
        });
        this.NewsNMediaList = chubarray;
      }
      //$('.customloder').hide();
      setTimeout(() => {
        NewsNMediaSider();
        cutomeSlickSlider();
      }, 1000);
    });
  }
  getVideoHubNewMediaFilter() {
    this.NewsNMediaList = [];
    setTimeout(() => {
      $('.NewsNMediaWrp').removeClass('active');
      $('.NewsNMediaSider')
        .removeClass('slick-initialized')
        .removeClass('slick-slider');
    }, 100);
    this.api
      .getVideoHubNewMediaFilter(
        this.vhNewsNMediaSpecialityID,
        this.vhNewsNMediaLocationID,
        this.vhNewsNMediaSSpecialityID
      )
      .subscribe((data) => {
        console.log('getVideoHubNewMediaFilter: ', data);
        let content_hubs_NewsNMedia = data;
        let r = new RegExp('^(?:[a-z]+:)?//', 'i');
        if (content_hubs_NewsNMedia.length != 0) {
          this.NewsNMediaListResult = false;
        } else {
          this.NewsNMediaListResult = true;
        }

        if (content_hubs_NewsNMedia) {
          let chubarray = [];
          content_hubs_NewsNMedia.forEach((element) => {
            if (r.test(element.contentimage)) {
              element.contentimageNew = element.contentimage;
            } else {
              element.contentimageNew = IMG_BASE_URL + element.contentimage;
            }
            if (r.test(element.contentvideoimage)) {
              element.contentvideoimageNew = element.contentvideoimage;
            } else {
              element.contentvideoimageNew =
                IMG_BASE_URL + element.contentvideoimage;
            }
            element.contentdate = this.datepipe.transform(
              element.contentdate,
              'd-MMMM-y'
            );
            if (element.contentvideourl != null) {
              if (element.contentvideourl.includes('https://youtu.be')) {
                let youtube_video_id = element.contentvideourl
                  .match(/youtu\.be.*(\/)(.{11})/)
                  .pop();
                element.contentvideourlNew =
                  'https://www.youtube.com/embed/' + youtube_video_id;
                element.facebookUrl = false;
              } else if (
                element.contentvideourl.includes('https://www.facebook.com')
              ) {
                let str = element.contentvideourl;
                var last = str.substring(str.lastIndexOf('/') + 1, str.length);
                element.contentvideourlNew = last;
                element.facebookUrl = true;
              }
            }
            chubarray.push(element);
          });
          this.NewsNMediaList = chubarray;
        }
        //$('.customloder').hide();

        setTimeout(() => {
          setTimeout(() => {
            $('#sl_NewsNMedia').hide();
            $('.NewsNMediaWrp').addClass('active');
          }, 500);
          NewsNMediaSider();
          cutomeSlickSlider();
        }, 1000);
      });
  }

  onChangeEventSpecialty(e) {
    $('#cEventLocation').show();
    $('#sl_Events').show();
    this.vhEventsSpecialityID = e.target.value;
    this.getCentreCities();
    this.getVideoHubEventsFilter();
  }
  onChangeEventLocation(e) {
    //$('#cSpecialityFeatures').show();
    $('#sl_Events').show();
    this.vhEventsLocationID = e.target.value;
    this.getAllCentresSpeciality(e.target.value);
    this.getVideoHubEventsFilter();
  }
  onChangeEventSpecialityFeatures(e) {
    $('#sl_Events').show();
    this.vhEventsSSpecialityID = e.target.value;
    console.log('SpecialityFeatures ID : ', e.target.value);
    this.getVideoHubEventsFilter();
  }
  getVideoHubEvents() {
    this.api.getVideoHubEvents().subscribe((data) => {
      //console.log('getVideoHubEvents: ', data);
      let content_hubs_events = data;
      let r = new RegExp('^(?:[a-z]+:)?//', 'i');
      if (content_hubs_events.length != 0) {
        this.eventsListResult = false;
      } else {
        this.eventsListResult = true;
      }

      if (content_hubs_events) {
        let chubarray = [];
        content_hubs_events.forEach((element) => {
          if (r.test(element.contentimage)) {
            element.contentimageNew = element.contentimage;
          } else {
            element.contentimageNew = IMG_BASE_URL + element.contentimage;
          }
          if (r.test(element.contentvideoimage)) {
            element.contentvideoimageNew = element.contentvideoimage;
          } else {
            element.contentvideoimageNew =
              IMG_BASE_URL + element.contentvideoimage;
          }
          element.contentdate = this.datepipe.transform(
            element.contentdate,
            'd-MMMM-y'
          );
          if (element.contentvideourl != null) {
            if (element.contentvideourl.includes('https://youtu.be')) {
              let youtube_video_id = element.contentvideourl
                .match(/youtu\.be.*(\/)(.{11})/)
                .pop();
              element.contentvideourlNew =
                'https://www.youtube.com/embed/' + youtube_video_id;
              element.facebookUrl = false;
            } else if (
              element.contentvideourl.includes('https://www.facebook.com')
            ) {
              let str = element.contentvideourl;
              var last = str.substring(str.lastIndexOf('/') + 1, str.length);
              element.contentvideourlNew = last;
              element.facebookUrl = true;
            }
          }
          chubarray.push(element);
        });
        this.eventsList = chubarray;
      }
      let e_length = this.eventsList.length;
      //$('.customloder').hide();
      setTimeout(() => {
        EventsSlider(e_length);
        cutomeSlickSlider();
      }, 1000);
    });
  }
  getVideoHubEventsFilter() {
    this.eventsList = [];
    setTimeout(() => {
      $('.EventsWrp').removeClass('active');
      $('.EventsSlider')
        .removeClass('slick-initialized')
        .removeClass('slick-slider');
    }, 100);
    this.api
      .getVideoHubEventsFilter(
        this.vhEventsSpecialityID,
        this.vhEventsLocationID,
        this.vhEventsSSpecialityID
      )
      .subscribe((data) => {
        console.log('getVideoHubEventsFilter: ', data);
        let content_hubs_events = data;
        let r = new RegExp('^(?:[a-z]+:)?//', 'i');
        if (content_hubs_events.length != 0) {
          this.eventsListResult = false;
        } else {
          this.eventsListResult = true;
        }

        if (content_hubs_events) {
          let chubarray = [];
          content_hubs_events.forEach((element) => {
            if (r.test(element.contentimage)) {
              element.contentimageNew = element.contentimage;
            } else {
              element.contentimageNew = IMG_BASE_URL + element.contentimage;
            }
            if (r.test(element.contentvideoimage)) {
              element.contentvideoimageNew = element.contentvideoimage;
            } else {
              element.contentvideoimageNew =
                IMG_BASE_URL + element.contentvideoimage;
            }
            element.contentdate = this.datepipe.transform(
              element.contentdate,
              'd-MMMM-y'
            );
            if (element.contentvideourl != null) {
              if (element.contentvideourl.includes('https://youtu.be')) {
                let youtube_video_id = element.contentvideourl
                  .match(/youtu\.be.*(\/)(.{11})/)
                  .pop();
                element.contentvideourlNew =
                  'https://www.youtube.com/embed/' + youtube_video_id;
                element.facebookUrl = false;
              } else if (
                element.contentvideourl.includes('https://www.facebook.com')
              ) {
                let str = element.contentvideourl;
                var last = str.substring(str.lastIndexOf('/') + 1, str.length);
                element.contentvideourlNew = last;
                element.facebookUrl = true;
              }
            }
            chubarray.push(element);
          });
          this.eventsList = chubarray;
        }
        //$('.customloder').hide();
        let e_length = this.eventsList.length;
        setTimeout(() => {
          setTimeout(() => {
            $('#sl_Events').hide();
            $('.EventsWrp').addClass('active');
          }, 500);
          EventsSlider(e_length);
          cutomeSlickSlider();
        }, 1000);
      });
  }

  getSpecialityMasters() {
    this.api
      .getSpecialityMasters()
      .map((res) => res.json())
      .subscribe((data) => {
        //console.log('getSpecialityMasters: ', data);
        this.specilityMasterList = data.speciality_masters;
      });
  }
  getCentreCities() {
    this.api.getCentreCities().subscribe((res) => {
      this.centerList = res;
      $('.inputLoader').hide();
    });
  }
  getNewsEventsCentreCities() {
    this.api.getCentreCities().subscribe((res) => {
      this.NewsEventsCenterList = res;
    });
  }

  getAllCentresSpeciality(id) {
    return this.api.getCentreSpecification().subscribe((res) => {
      let centersArray = res['centers'];
      let centerSpecialityFeaturesArray = [];
      centersArray.forEach((element) => {
        if (element.id == id) {
          centerSpecialityFeaturesArray.push(element.speciality_features);
        }
      });
      this.specialityFeatures = centerSpecialityFeaturesArray[0];
      $('.inputLoader').hide();
    });
  }

  getVideoHub(Category, SubCategory) {
    //$('.customloder').show();
    this.api.getVideoHub(Category, SubCategory).subscribe(
      (res) => {
        //console.log('getVideoHub: ', res);
        let content_hubs = res['content_hubs'];
        let r = new RegExp('^(?:[a-z]+:)?//', 'i');
        //console.log('content_hubs: ', content_hubs);
        if (content_hubs != undefined) {
          this.videoListResult = false;
        } else {
          this.videoListResult = true;
        }
        //videoListResult
        if (content_hubs) {
          let chubarray = [];
          //https://developers.facebook.com/docs/plugins/embedded-video-player/
          content_hubs.forEach((element) => {
            if (element.primaryfilter == 1) {
              element.primaryfilterText = 'Webinars';
              element.primaryfilterIcon =
                'assets/images/video-icon/webinar-icon.png';
            }
            if (element.primaryfilter == 2) {
              element.primaryfilterText = 'Patient Testimonials';
              element.primaryfilterIcon =
                'assets/images/video-icon/testimonial-icon.png';
            }
            if (element.primaryfilter == 3) {
              element.primaryfilterText = 'News & Media';
              element.primaryfilterIcon =
                'assets/images/video-icon/news-icon.png';
            }
            if (element.primaryfilter == 4) {
              element.primaryfilterText = 'Events';
              element.primaryfilterIcon =
                'assets/images/video-icon/event-icon.png';
            }

            if (r.test(element.contentimage)) {
              element.contentimageNew = element.contentimage;
            } else {
              element.contentimageNew = IMG_BASE_URL + element.contentimage;
            }
            if (r.test(element.contentvideoimage)) {
              element.contentvideoimageNew = element.contentvideoimage;
            } else {
              element.contentvideoimageNew =
                IMG_BASE_URL + element.contentvideoimage;
            }

            element.contentdate = this.datepipe.transform(
              element.contentdate,
              'd-MMMM-y'
            );
            if (element.contentvideourl != null) {
              if (element.contentvideourl.includes('https://youtu.be')) {
                let youtube_video_id = element.contentvideourl
                  .match(/youtu\.be.*(\/)(.{11})/)
                  .pop();
                element.contentvideourlNew =
                  'https://www.youtube.com/embed/' + youtube_video_id;
                element.facebookUrl = false;
              } else if (
                element.contentvideourl.includes('https://www.facebook.com')
              ) {
                let str = element.contentvideourl;
                var last = str.substring(str.lastIndexOf('/') + 1, str.length);
                element.contentvideourlNew = last;
                element.facebookUrl = true;
              }
            }
            chubarray.push(element);
          });
          this.videoList = chubarray;
        }

        //$('.customloder').hide();
        setTimeout(() => {
          cutomeSlickSlider();
        }, 1000);
      },
      (err) => {
        console.error(err);
      }
    );
  }

  public primaryCategory = '';
  onChangeCategory(e) {
    let SubCategoryEle = document.querySelector('#SubCategory');
    console.log(e);
    if (e.target.value == 1 || e.target.value == 2) {
      SubCategoryEle.parentElement.parentElement.style.display = 'block';
    } else {
      SubCategoryEle.parentElement.parentElement.style.display = 'none';
    }
    this.CategoryList.forEach((element) => {
      if (element.ID == e.target.value) {
        this.SubCategoryList = element.SubCategory;
      }
    });

    SubCategoryEle.nextElementSibling.innerHTML = 'Sub Category';
    this.primaryCategory = e.target.value == '' ? '' : e.target.value;
    this.getVideoHub(this.primaryCategory, '');
  }

  onChangeSubCategory(e) {
    console.log();
    let subCategory = e.target.value == '' ? '' : e.target.value;
    this.getVideoHub(this.primaryCategory, subCategory);
    console.log('subCategory', subCategory);
  }

  getVideoHubFilter(id) {
    this.api.getVideoHubFilter(id).subscribe((res) => {
      //console.log('getVideoHub: ', res);
      let content_hubs = res['content_hubs'];
      let r = new RegExp('^(?:[a-z]+:)?//', 'i');
      //console.log('content_hubs', content_hubs);
      if (content_hubs != undefined) {
        let chubarray = [];
        content_hubs.forEach((element) => {
          if (r.test(element.contentimage)) {
            element.contentimageNew = element.contentimage;
          } else {
            element.contentimageNew = IMG_BASE_URL + element.contentimage;
          }
          if (r.test(element.contentvideoimage)) {
            element.contentvideoimageNew = element.contentvideoimage;
          } else {
            element.contentvideoimageNew =
              IMG_BASE_URL + element.contentvideoimage;
          }
          element.contentdate = this.datepipe.transform(
            element.contentdate,
            'd-MMMM-y'
          );
          if (element.contentvideourl != null) {
            let youtube_video_id = element.contentvideourl
              .match(/youtu\.be.*(\/)(.{11})/)
              .pop();
            //element.contentvideoimage = "https://img.youtube.com/vi/"+youtube_video_id+"/0.jpg";
            element.contentvideourlNew =
              'https://www.youtube.com/embed/' + youtube_video_id;
          }
          chubarray.push(element);
        });
        this.videoList = chubarray;
      } else {
        this.videoList = [];
      }
      setTimeout(() => {
        cutomeSlickSlider();
      }, 1000);
    });
  }

  limitContentSet() {
    var showChar = 100,
      ellipsestext = '...',
      moretext = 'Show more +',
      lesstext = 'Show less -';
    $('.more').each(() => {
      var content = $(this).html();
      if (content.length > showChar) {
        var c = content.substr(0, showChar),
          h = content.substr(showChar, content.length - showChar);
        var html =
          c +
          '<span class="moreellipses">' +
          ellipsestext +
          '&nbsp;</span><span class="morecontent"><span>' +
          h +
          '</span>&nbsp;&nbsp;<div class="morelinkwrp"><a href="" class="morelink knowmore2"><span>' +
          moretext +
          '</span></a></div></span>';
        $(this).html(html);
      }
    });
    // $(".morelink").click(function () {
    // 	if ($(this).hasClass("less")) {
    // 		$(this).removeClass("less"); $(this).parents('.more').find('.moreellipses').show();
    // 		$(this).find('span').html(moretext);
    // 	} else {
    // 		$(this).addClass("less"); $(this).parents('.more').find('.moreellipses').hide();
    // 		$(this).find('span').html(lesstext);

    // 	}
    // 	$(this).parent().prev().toggle();
    // 	$(this).prev().toggle();
    // 	return false;
    // });

    $('.morelink').click((e) => {
      e.preventDefault();
      if ($(this).text() == 'Show less -') {
        $(this).parents('.morecontent').find('span:first-child').hide();
        $(this).parents('.morelinkwrp').find('span').text('Show more +');
      } else {
        console.log($(this).parents('ul').find('.morecontent span:first'));
        $(this)
          .parents('ul')
          .find('.morecontent')
          .find('span:first-child')
          .hide();
        $(this)
          .parents('ul')
          .find('.morelinkwrp')
          .find('span')
          .text('Show more +');
        $(this).parents('.morecontent').find('span:first-child').show();
        $(this).parents('.morelinkwrp').find('span').text('Show less -');
      }
    });
  }

  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
    }

    $('.specilityMasterLabel').text('Specialty');
  }

  ngOnDestroy() {}

  @HostListener('window:load', []) onWindowLoad() {
    //$('.customloder').hide();
  }
}

function setContentLimit() {
  let length = 200;
  let cHtml = $('.content').html();
  let cText = $('.content').text().substr(0, length).trim();
  $('.content')
    .addClass('compressed')
    .html(cText + "... <a href='#' class='exp'>More</a>");
  function handler() {
    $('.exp').click(function () {
      if ($('.content').hasClass('compressed')) {
        $('.content').html(cHtml + "<a href='#' class='exp'>Less</a>");
        $('.content').removeClass('compressed');
        handler();
        return false;
      } else {
        $('.content').html(cText + "... <a href='#' class='exp'>More</a>");
        $('.content').addClass('compressed');
        handler();
        return false;
      }
    });
  }
  handler();
}

const customBannerSlider = () => {
  var num = 1; // a variable to set the actual active slider

  $('#dp-dots li, #dp-slider .dp_item').click(() => {
    var get_slide = $(this).attr('lang');
    num = get_slide;
    generalClick(get_slide);
  });

  function generalClick(number) {
    $('#dp-dots li').removeClass('active');
    $('#dp-dots li:nth-child(' + number + ')').addClass('active');
    $('#dp-slider .dp_item[lang=' + number + ']')
      .hide()
      .prependTo('#dp-slider')
      .fadeIn();
    $.each($('#slider .dp_item'), function (index, dp_item) {
      $(dp_item).attr('id', index + 1);
    });

    $('.text .div').removeClass('is-active');
    $('.div-' + number).addClass('is-active');

    $('.dp_item .dp-content').removeClass('opacity');
    $('.dp_item .dp-content img').removeClass('fade-up');

    $('.item-' + number + ' .dp-content').addClass('opacity');
    $('.item-' + number + ' .dp-content img').addClass('fade-up');
  }

  setInterval(function () {
    num++;
    if (num > $('#dp-dots li').length) {
      num = 1;
    }
    generalClick(num);
  }, 5000);
  /*
	function detect_active(){
		// get active
		var get_active = $("#dp-slider .dp_item:first-child").data("class");
		$("#dp-dots li").removeClass("active");
		$("#dp-dots li[lang="+ get_active +"]").addClass("active");
	}
	$("#dp-next").click(function(){
		var total = $(".dp_item").length;
		$("#dp-slider .dp_item:first-child").hide().appendTo("#dp-slider").fadeIn();
		$.each($('.dp_item'), function (index, dp_item) {
			$(dp_item).attr('id', index + 1);
		});
		detect_active();

	});

	$("#dp-prev").click(function(){
		var total = $(".dp_item").length;
		$("#dp-slider .dp_item:last-child").hide().prependTo("#dp-slider").fadeIn();
		$.each($('.dp_item'), function (index, dp_item) {
			$(dp_item).attr('id', index + 1);
		});

		detect_active();
	});

	$("#dp-dots li").click(function(){
		$("#dp-dots li").removeClass("active");
		$(this).addClass("active");
		var get_slide = $(this).attr('lang');
		console.log(get_slide);
		$("#dp-slider .dp_item[lang=" + get_slide + "]").hide().prependTo("#dp-slider").fadeIn();
		$.each($('.dp_item'), function (index, dp_item) {
			$(dp_item).attr('id', index + 1);
		});
	});


	$("body").on("click", "#dp-slider .dp_item:not(:first-child)", function(){
		var get_slide = $(this).attr('lang');
		console.log(get_slide);
		$("#dp-slider .dp_item[lang=" + get_slide + "]").hide().prependTo("#dp-slider").fadeIn();
		$.each($('.dp_item'), function (index, dp_item) {
			$(dp_item).attr('id', index + 1);
		});

		detect_active();
	});
	*/
};

function WebinarSider() {
  $('.WebinarSider')
    .not('.slick-initialized')
    .slick({
      dots: false,
      arrows: true,
      infinite: false,
      slidesToShow: 3,
      slidesToScroll: 3,
      slidesPerRow: 1,
      adaptiveHeight: true,
      responsive: [
        { breakpoint: 1024, settings: { slidesToShow: 2, slidesToScroll: 1 } },
        { breakpoint: 680, settings: { slidesToShow: 1, slidesToScroll: 1 } },
        { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } },
      ],
    });
}
function testimonialsSider() {
  $('.testimonialsSider')
    .not('.slick-initialized')
    .slick({
      dots: false,
      arrows: true,
      infinite: false,
      slidesToShow: 3,
      slidesToScroll: 3,
      slidesPerRow: 1,
      adaptiveHeight: true,
      responsive: [
        { breakpoint: 1024, settings: { slidesToShow: 2, slidesToScroll: 1 } },
        { breakpoint: 680, settings: { slidesToShow: 1, slidesToScroll: 1 } },
        { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } },
      ],
    });
}
function NewsNMediaSider() {
  $('.NewsNMediaSider')
    .not('.slick-initialized')
    .slick({
      dots: false,
      arrows: true,
      infinite: false,
      slidesToShow: 3,
      slidesToScroll: 3,
      slidesPerRow: 1,
      adaptiveHeight: true,
      responsive: [
        { breakpoint: 1024, settings: { slidesToShow: 2, slidesToScroll: 1 } },
        { breakpoint: 680, settings: { slidesToShow: 1, slidesToScroll: 1 } },
        { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } },
      ],
    });
}
function EventsSlider(e_lenght) {
  console.log('lenght: ', e_lenght);
  $('.EventsSlider')
    .not('.slick-initialized')
    .slick({
      dots: false,
      arrows: true,
      infinite: false,
      slidesToShow: 3,
      slidesToScroll: 3,
      slidesPerRow: 1,
      adaptiveHeight: true,
      responsive: [
        { breakpoint: 1024, settings: { slidesToShow: 2, slidesToScroll: 1 } },
        { breakpoint: 680, settings: { slidesToShow: 1, slidesToScroll: 1 } },
        { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } },
      ],
    });
  setTimeout(() => {
    $('.customloder').hide();
  }, 1000);
}

function cutomeSlickSlider() {
  $('.RAINBOWChannelwapper .siderbox').addClass('active');
  $('.fancybox').not('.slick-initialized').fancybox({
    type: 'iframe',
    maxWidth: 700,
    maxHeight: 600,
    fitToView: false,
    width: '70%',
    height: '70%',
    autoSize: false,
    closeClick: false,
    openEffect: 'none',
    closeEffect: 'none',
  });
}

function customModal() {
  var win = $(window),
    tH,
    winH,
    winW,
    Othis = '.modal__dialog',
    OthisContnt = '.modal__content',
    ft = false;
  winW = (win.width() - $(Othis).parent().outerWidth()) / 2;
  winH = (win.height() - $(Othis).parent().outerHeight()) / 2;
  tH = win.height() - win.height() / 6;
  $(Othis).parent().css({ left: winW, top: winH }).addClass('scale');
  setTimeout(() => {
    $(Othis).parent().addClass('active');
    if ($(OthisContnt).outerHeight() > tH) {
      $(OthisContnt).css({ height: tH });
      ft = true;
    }
  }, 1000);

  win
    .resize(function (event) {
      var win = $(window);
      winW = (win.width() - $(Othis).parent().outerWidth()) / 2;
      winH = (win.height() - $(Othis).parent().outerHeight()) / 2;
      tH = win.height() - win.height() / 6;
      $(Othis).parent().css({ left: winW, top: winH });
      if (ft) {
        $(OthisContnt).css({ height: tH });
      }
    })
    .resize();
}
