import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  ViewChildren,
  QueryList,
  HostListener,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import {
  ReactiveFormsModule,
  FormsModule,
  FormControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL, API_BASE_URL } from '../services/constants';
import { dateFormatPipe } from '../home/dateFormatPipe';
import { Meta, Title } from '@angular/platform-browser';
import { json } from 'body-parser';
import { Observable } from 'rxjs';

declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
// declare let initAboutTabs:any;

@Component({
  selector: 'csr',
  templateUrl: './csr.component.html',
  styles: [
    `
      #ModelFormGetInTouch {
        display: none;
      }
      .capopup {
        display: none;
      }
      #ModelFormGetInTouch .capopup-inner {
        max-width: 600px;
        width: 90%;
        padding: 40px;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
        border-radius: 20px;
        background: #faf5ff;
      }
      #ModelFormGetInTouch .capopup-inner .launchpreloader {
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: 11;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        background: rgba(255, 255, 255, 0.6)
          url(../../assets/css/ajax-loader.gif) no-repeat center center;
      }
      #ModelFormGetInTouch h2 {
        width: 100%;
        text-transform: uppercase;
        color: #5f2160;
        font-weight: 700;
        font-family: 'dinrg';
        font-size: 30px;
        display: inline-block;
        margin: 0 0px 10px 0;
        position: relative;
        padding: 0px;
      }
      #ModelFormGetInTouch .formcasn {
        width: 100%;
        margin: 20px 0 0 0;
        padding: 0;
        position: relative;
        float: left;
      }
      #ModelFormGetInTouch .formcasn ul {
        margin: 0;
        padding: 0px;
      }
      #ModelFormGetInTouch .formcasn li {
        float: left;
        width: 100%;
        list-style: none;
        position: relative;
        margin-bottom: 10px;
      }
      #ModelFormGetInTouch .formcasn li:last-child {
        margin-bottom: 0px;
      }
      #ModelFormGetInTouch .formcasn li i {
        width: 25px;
        height: 25px;
        position: absolute;
        left: 32px;
        top: 39px;
      }
      #ModelFormGetInTouch .formcasn li .alertMsg {
        position: absolute;
        right: 12px;
        bottom: 14px;
        z-index: 1;
        color: #e51e2c;
        font-family: 'dinrg';
        font-size: 13px;
        font-weight: normal;
      }
      #ModelFormGetInTouch .formcasn li input {
        color: #545454;
        font-family: 'dinrg';
        font-size: 17px;
        width: 100%;
        float: left;
        background: #fff;
        border: 1px solid #ccc;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        padding: 10px 20px 10px 20px;
        box-sizing: border-box;
      }
      #ModelFormGetInTouch .formcasn li select {
        color: #545454;
        font-family: 'dinrg';
        font-size: 17px;
        width: 100%;
        float: left;
        background: #fff;
        border: 1px solid #ccc;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        padding: 10px 20px 10px 20px;
        box-sizing: border-box;
      }
      #ModelFormGetInTouch .formcasn li textarea {
        color: #545454;
        font-family: 'dinrg';
        font-size: 17px;
        width: 100%;
        float: left;
        background: #fff;
        border: 1px solid #ccc;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        padding: 10px 20px 10px 20px;
        box-sizing: border-box;
      }
      #ModelFormGetInTouch .formcasn li input:focus,
      #ModelFormGetInTouch .formcasn li textarea:focus,
      #ModelFormGetInTouch .formcasn li select:focus {
        outline: none;
      }
      #ModelFormGetInTouch .btnPrevca {
        color: #5f2160;
        font-family: 'dinrg';
        font-size: 14px;
        width: 100%;
        margin: 20px 10px 20px 0px;
        position: relative;
        display: inline-block;
        text-align: right;
      }
      #ModelFormGetInTouch .formcasn li .submitbtn {
        width: 100%;
        text-align: center;
        display: inline-block;
        float: left;
      }
      #ModelFormGetInTouch .formcasn li .submitbtn input {
        float: right;
        display: inline-block;
        width: auto;
        color: #fff;
        background: #3d2979;
        border: 1px solid #3d2979;
        padding: 0px 40px;
        margin: 2px 4px;
        height: 38px;
        text-transform: uppercase;
        font-family: 'dinrg';
        font-size: 18px;
        border-radius: 30px;
      }
      #ModelFormGetInTouch .formcasn li input:focus {
        outline: none;
      }
      span.error {
        position: absolute;
        right: 12px;
        bottom: 14px;
        z-index: 1;
        color: #e51e2c;
        font-family: 'dinrg';
        font-size: 13px;
        font-weight: normal;
      }

      .iconForm {
        bottom: 96px;
        position: fixed;
        right: 10px;
        z-index: 12;
        display: none;
      }
      @media (max-width: 767px) {
        .iconForm {
          bottom: 62px;
          position: fixed;
          right: 10px;
          z-index: 12;
        }
        .iconForm img {
          width: 50px;
        }
      }
      @media (max-width: 480px) {
        .iconForm {
          bottom: 112px;
          position: fixed;
          right: 6px;
          z-index: 12;
        }
        #ModelFormGetInTouch h2 {
          font-size: 18px;
        }
        #ModelFormGetInTouch .formcasn {
          margin: 10px 0 0 0;
        }
        #ModelFormGetInTouch .formcasn li input,
        #ModelFormGetInTouch .formcasn li select,
        #ModelFormGetInTouch .formcasn li textarea {
          font-size: 13px;
          padding: 6px 10px;
        }
        #ModelFormGetInTouch .formcasn li .submitbtn input {
          height: 30px;
          font-size: 18px;
        }
        #ModelFormGetInTouch .formcasn li .alertMsg {
          right: 5px;
          bottom: 9px;
          font-size: 11px;
        }
      }
      @media (max-width: 420px) {
        .iconForm {
          bottom: 90px;
          position: fixed;
          right: 6px;
          z-index: 12;
        }
        .iconForm img {
          width: 40px;
        }
      }
      @media (max-width: 420px) {
        .iconForm {
          bottom: 132px;
          position: fixed;
          right: 6px;
          z-index: 12;
        }
      }
    `,
  ],
})
export class CsrComponent implements OnDestroy, OnInit, AfterViewInit {
  GetInTouch: FormGroup;

  constructor(
    private api: ApiService,
    public metaTitle: Title,
    public meta: Meta,
    private fb: FormBuilder
  ) {
    if (this.api.isBrowser) {
      // some of these will be required in near future
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/datepickr.min.js');
      require('../../assets/js/slick.min.js');
    }
    this.GetInTouch = this.fb.group({
      name: ['', [Validators.required, Validators.pattern('.*\\S.*')]],
      mobile: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(/^(\+\d{1,3}[- ]?)?\d{10}$/),
        ]),
      ],
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            '[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}'
          ),
        ]),
      ],
      stateName: ['', [Validators.required]],
      cityName: ['', [Validators.required]],
      message: [''],
    });
  }

  public submited: boolean = false;
  public showHide: boolean = false;
  public preloader: boolean = false;
  public events: any[] = [];
  public csrList: any[] = [];
  public csrCode: any;
  public title: any = '';
  public subtitle: any = '';
  public description: any = '';
  public imgsrc: any = '';
  public brief_facilities_description: any = '';
  public brief_facilities_image: any = '';
  public imgBasePath = '';
  public hidden = 'hidden';
  public logo = 'logo';
  public bread_crumb_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'About Us', link: '/about-us', class: '' },
    { label: 'CSR', link: '', class: '' },
  ];

  public alertMG: boolean = false;
  public links: any = [];
  public activePage: string = 'csr';
  public selectMonth: any = '';
  public cityList: any = [];
  public stateList: any = [
    {
      name: 'Top Metropolitan Cities',
      city: [
        { name: 'Ahmedabad' },
        { name: 'Bengaluru/Bangalore' },
        { name: 'Chandigarh' },
        { name: 'Chennai' },
        { name: 'Delhi' },
        { name: 'Gurgaon' },
        { name: 'Hyderabad/Secunderabad' },
        { name: 'Kolkatta' },
        { name: 'Mumbai' },
        { name: 'Noida' },
        { name: 'Pune' },
      ],
    },
    {
      name: 'Andhra Pradesh',
      city: [
        { name: 'Anantapur' },
        { name: 'Guntakal' },
        { name: 'Guntur' },
        { name: 'Hyderabad/Secunderabad' },
        { name: 'kakinada' },
        { name: 'kurnool' },
        { name: 'Nellore' },
        { name: 'Nizamabad' },
        { name: 'Rajahmundry' },
        { name: 'Tirupati' },
        { name: 'Vijayawada' },
        { name: 'Visakhapatnam' },
        { name: 'Warangal' },
        { name: 'Andra Pradesh-Other' },
      ],
    },
    {
      name: 'Arunachal Pradesh',
      city: [{ name: 'Itanagar' }, { name: 'Arunachal Pradesh-Other' }],
    },
    {
      name: 'Assam',
      city: [
        { name: 'Guwahati' },
        { name: 'Silchar' },
        { name: 'Assam-Other' },
      ],
    },
    {
      name: 'Bihar',
      city: [{ name: 'Bhagalpur' }, { name: 'Patna' }, { name: 'Bihar-Other' }],
    },
    {
      name: 'Chhattisgarh',
      city: [
        { name: 'Bhillai' },
        { name: 'Bilaspur' },
        { name: 'Raipur' },
        { name: 'Chhattisgarh-Other' },
      ],
    },
    {
      name: 'Goa',
      city: [
        { name: 'Panjim/Panaji' },
        { name: 'Vasco Da Gama' },
        { name: 'Goa-Other' },
      ],
    },
    {
      name: 'Gujarat',
      city: [
        { name: 'Ahmedabad' },
        { name: 'Anand' },
        { name: 'Ankleshwar' },
        { name: 'Bharuch' },
        { name: 'Bhavnagar' },
        { name: 'Bhuj' },
        { name: 'Gandhinagar' },
        { name: 'Gir' },
        { name: 'Jamnagar' },
        { name: 'Kandla' },
        { name: 'Porbandar' },
        { name: 'Rajkot' },
        { name: 'Surat' },
        { name: 'Vadodara/Baroda' },
        { name: 'Valsad' },
        { name: 'Vapi' },
        { name: 'Gujarat-Other' },
      ],
    },
    {
      name: 'Haryana',
      city: [
        { name: 'Ambala' },
        { name: 'Chandigarh' },
        { name: 'Faridabad' },
        { name: 'Gurgaon' },
        { name: 'Hisar' },
        { name: 'Karnal' },
        { name: 'Kurukshetra' },
        { name: 'Panipat' },
        { name: 'Rohtak' },
        { name: 'Haryana-Other' },
      ],
    },
    {
      name: 'Himachal Pradesh',
      city: [
        { name: 'Dalhousie' },
        { name: 'Dharmasala' },
        { name: 'Kulu/Manali' },
        { name: 'Shimla' },
        { name: 'Himachal Pradesh-Other' },
      ],
    },
    {
      name: 'Jammu and Kashmir',
      city: [
        { name: 'Jammu' },
        { name: 'Srinagar' },
        { name: 'Jammu and Kashmir-Other' },
      ],
    },
    {
      name: 'Jharkhand',
      city: [
        { name: 'Bokaro' },
        { name: 'Dhanbad' },
        { name: 'Jamshedpur' },
        { name: 'Ranchi' },
        { name: 'Jharkhand-Other' },
      ],
    },
    {
      name: 'Karnataka',
      city: [
        { name: 'Bengaluru/Bangalore' },
        { name: 'Belgaum' },
        { name: 'Bellary' },
        { name: 'Bidar' },
        { name: 'Dharwad' },
        { name: 'Gulbarga' },
        { name: 'Hubli' },
        { name: 'Kolar' },
        { name: 'Mangalore' },
        { name: 'Mysoru/Mysore' },
        { name: 'Karnataka-Other' },
      ],
    },
    {
      name: 'Kerala',
      city: [
        { name: 'Calicut' },
        { name: 'Cochin' },
        { name: 'Ernakulam' },
        { name: 'Kannur' },
        { name: 'Kochi' },
        { name: 'Kollam' },
        { name: 'Kottayam' },
        { name: 'Kozhikode' },
        { name: 'Palakkad' },
        { name: 'Palghat' },
        { name: 'Thrissur' },
        { name: 'Trivandrum' },
        { name: 'Kerela-Other' },
      ],
    },
    {
      name: 'Madhya Pradesh',
      city: [
        { name: 'Bhopal' },
        { name: 'Gwalior' },
        { name: 'Indore' },
        { name: 'Jabalpur' },
        { name: 'Ujjain' },
        { name: 'Madhya Pradesh-Other' },
      ],
    },
    {
      name: 'Maharashtra',
      city: [
        { name: 'Ahmednagar' },
        { name: 'Aurangabad' },
        { name: 'Jalgaon' },
        { name: 'Kolhapur' },
        { name: 'Mumbai' },
        { name: 'Mumbai Suburbs' },
        { name: 'Nagpur' },
        { name: 'Nasik' },
        { name: 'Navi Mumbai' },
        { name: 'Pune' },
        { name: 'Solapur' },
        { name: 'Maharashtra-Other' },
      ],
    },
    { name: 'Manipur', city: [{ name: 'Imphal' }, { name: 'Manipur-Other' }] },
    {
      name: 'Meghalaya',
      city: [{ name: 'Shillong' }, { name: 'Meghalaya-Other' }],
    },
    { name: 'Mizoram', city: [{ name: 'Aizawal' }, { name: 'Mizoram-Other' }] },
    {
      name: 'Nagaland',
      city: [{ name: 'Dimapur' }, { name: 'Nagaland-Other' }],
    },
    {
      name: 'Orissa',
      city: [
        { name: 'Bhubaneshwar' },
        { name: 'Cuttak' },
        { name: 'Paradeep' },
        { name: 'Puri' },
        { name: 'Rourkela' },
        { name: 'Orissa-Other' },
      ],
    },
    {
      name: 'Punjab',
      city: [
        { name: 'Amritsar' },
        { name: 'Bathinda' },
        { name: 'Chandigarh' },
        { name: 'Jalandhar' },
        { name: 'Ludhiana' },
        { name: 'Mohali' },
        { name: 'Pathankot' },
        { name: 'Patiala' },
        { name: 'Punjab-Other' },
      ],
    },
    {
      name: 'Rajasthan',
      city: [
        { name: 'Ajmer' },
        { name: 'Jaipur' },
        { name: 'Jaisalmer' },
        { name: 'Jodhpur' },
        { name: 'Kota' },
        { name: 'Udaipur' },
        { name: 'Rajasthan-Other' },
      ],
    },
    { name: 'Sikkim', city: [{ name: 'Gangtok' }, { name: 'Sikkim-Other' }] },
    {
      name: 'Tamil Nadu',
      city: [
        { name: 'Chennai' },
        { name: 'Coimbatore' },
        { name: 'Cuddalore' },
        { name: 'Erode' },
        { name: 'Hosur' },
        { name: 'Madurai' },
        { name: 'Nagerkoil' },
        { name: 'Ooty' },
        { name: 'Salem' },
        { name: 'Thanjavur' },
        { name: 'Tirunalveli' },
        { name: 'Trichy' },
        { name: 'Tuticorin' },
        { name: 'Vellore' },
        { name: 'Tamil Nadu-Other' },
      ],
    },
    {
      name: 'Tripura',
      city: [{ name: 'Agartala' }, { name: 'Tripura-Other' }],
    },
    {
      name: 'Union Territories',
      city: [
        { name: 'Chandigarh' },
        { name: 'Dadra & Nagar Haveli-Silvassa' },
        { name: 'Daman & Diu' },
        { name: 'Delhi' },
        { name: 'Pondichery' },
      ],
    },
    {
      name: 'Uttar Pradesh',
      city: [
        { name: 'Agra' },
        { name: 'Aligarh' },
        { name: 'Allahabad' },
        { name: 'Bareilly' },
        { name: 'Faizabad' },
        { name: 'Ghaziabad' },
        { name: 'Gorakhpur' },
        { name: 'Kanpur' },
        { name: 'Lucknow' },
        { name: 'Mathura' },
        { name: 'Meerut' },
        { name: 'Moradabad' },
        { name: 'Noida' },
        { name: 'Varanasi/Banaras' },
        { name: 'Uttar Pradesh-Other' },
      ],
    },
    {
      name: 'Uttaranchal',
      city: [
        { name: 'Dehradun' },
        { name: 'Roorkee' },
        { name: 'Uttaranchal-Other' },
      ],
    },
    {
      name: 'West Bengal',
      city: [
        { name: 'Asansol' },
        { name: 'Durgapur' },
        { name: 'Haldia' },
        { name: 'Kharagpur' },
        { name: 'Kolkatta' },
        { name: 'Siliguri' },
        { name: 'West Bengal - Other' },
      ],
    },
  ];

  // @ViewChildren('abouttabs') things: QueryList<any>;

  ngOnInit() {
    this.api
      .getAboutSubFooterDetails()
      .map((res) => res)
      .subscribe((data) => {
        this.links = data;
      });
    this.api.getPageDescription('csr').subscribe(
      (data) => {
        (this.title = data.page_descriptions[0].title),
          (this.subtitle = data.page_descriptions[0].subtitle),
          (this.description = data.page_descriptions[0].description),
          (this.imgsrc = data.page_descriptions[0].image),
          (this.imgBasePath = IMG_BASE_URL);
        if (data) {
          //console.log(data);
          this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
          this.meta.updateTag({
            name: 'keywords',
            content: data.page_descriptions[0].meta_keyword,
          });
          this.meta.updateTag({
            name: 'description',
            content: data.page_descriptions[0].meta_description,
          });
          this.meta.addTags([
            {
              property: 'og:title',
              content: data.page_descriptions[0].meta_title,
            },
            {
              property: 'og:description',
              content: data.page_descriptions[0].meta_description,
            },
            {
              property: 'og:keywords',
              content: data.page_descriptions[0].meta_keyword,
            },
          ]);
        }
      },
      (error) => {
        console.log(error);
      }
    );

    this.api.getCsr().subscribe(
      (csrdata) => {
        //console.log(csrdata);
        this.csrList = csrdata;
        setTimeout(() => {
          $('.CSRACTIVITIESSlider')
            .not('.slick-initialized')
            .slick({
              dots: true,
              arrows: false,
              infinite: false,
              slidesToShow: 3,
              slidesToScroll: 3,
              slidesPerRow: 1,
              adaptiveHeight: true,
              responsive: [
                {
                  breakpoint: 1024,
                  settings: { slidesToShow: 2, slidesToScroll: 1 },
                },
                {
                  breakpoint: 680,
                  settings: { slidesToShow: 1, slidesToScroll: 1 },
                },
                {
                  breakpoint: 480,
                  settings: { slidesToShow: 1, slidesToScroll: 1 },
                },
              ],
            });
        }, 200);
      },
      (error) => {
        console.log(error);
      }
    );

    this.api.getCsrCode().subscribe(
      (res) => {
        console.log('getCsrCode', res);
        this.csrCode = res[0].code;

        setTimeout(function () {
          $('.newatabscontent').not('.slick-initialized').slick({
            slide: '.atabsdata',
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: false,
            asNavFor: '.newatabs',
            infinite: false,
            adaptiveHeight: true,
            draggable: false,
            touchMove: false,
            swipeToSlide: false,
            swipe: false,
          });
          $('.newatabs')
            .not('.slick-initialized')
            .slick({
              slide: 'li',
              slidesToShow: 6,
              slidesToScroll: 1,
              prevArrow:
                '<a class="slick-prev"><img src="assets/images/prv-arrow2.png" alt="" /></a>',
              nextArrow:
                '<a class="slick-next"><img src="assets/images/nxt-arrow2.png" alt="" /></a>',
              asNavFor: '.newatabscontent',
              dots: false,
              centerMode: false,
              focusOnSelect: true,
              arrows: true,
              infinite: false,
              draggable: false,
              responsive: [
                {
                  breakpoint: 1400,
                  settings: { slidesToShow: 5, slidesToScroll: 1 },
                },
                {
                  breakpoint: 1024,
                  settings: { slidesToShow: 3, slidesToScroll: 1 },
                },
                {
                  breakpoint: 768,
                  settings: { slidesToShow: 1, slidesToScroll: 1 },
                },
              ],
            });
          $('.AccordionCont .visitor-head').each(function (index, element) {
            $(this).click(function (e) {
              if (
                $(this).parent().find('.AccordionBody').css('display') ==
                'block'
              ) {
                $('.AccordionCont').removeClass('active');
                $('.AccordionBody').slideUp();
              } else {
                $('.AccordionCont').removeClass('active');
                $('.AccordionBody').slideUp();
                $(this)
                  .parent()
                  .addClass('active')
                  .find('.AccordionBody')
                  .slideDown();
              }
            });
          });
          setTimeout(() => {
            $('.btn-Collaborate').click(function () {
              $('#ModelFormGetInTouch').show();
            });
          }, 200);
        }, 200);
      },
      (error) => {
        console.log(error);
      }
    );
    //$('#ModelFormGetInTouch').show();
  }

  onChangeState(e) {
    //console.log('onChangeState', e.target.value);
    let sCity = [];
    this.cityList = [];
    this.GetInTouch.controls.cityName.setValue('');
    $('#cityName').text('City');
    this.stateList.forEach((element) => {
      if (element.name === e.target.value) {
        sCity.push(element.city);
      }
    });
    this.cityList = sCity[0];
  }

  @HostListener('window:load', [])
  onWindowLoad() {}

  changeShowStatus() {
    this.showHide = !this.showHide;
    $('#ModelFormGetInTouch').hide();
    $('.iconForm').show();
  }

  ShowForm() {
    this.showHide = true;
    $('.iconForm').hide();
    $('#ModelFormGetInTouch').show();
  }

  onSubmit() {
    this.submited = true;
    if (!this.GetInTouch.valid) {
      return;
    }
    this.preloader = true;
    var self = this;

    if (this.GetInTouch.valid) {
      let formTemp: any = <any>document.querySelector('#form');
      let formData: FormData = new FormData(formTemp);

      let xhr: XMLHttpRequest = this.buildForm(formData);

      xhr.onreadystatechange = () => {
        var obj = xhr;
        if (obj.readyState == 4) {
          this.alertMG = true;
          $('#ModelFormGetInTouch').hide();
          this.GetInTouch.reset();
          setTimeout(() => {
            this.alertMG = false;
          }, 2000);
          this.preloader = false;

          this.GetInTouch.controls.name.setValue('');
          this.GetInTouch.controls.mobile.setValue('');
          this.GetInTouch.controls.email.setValue('');
          this.GetInTouch.controls.stateName.setValue('');
          $('#slectStateName').text('State');
          this.GetInTouch.controls.cityName.setValue('');
          $('#cityName').text('City');
          this.GetInTouch.controls.message.setValue('');
        }
        if (obj.status == 200) {
          //console.log(obj.status);
        }
      };
    } else {
      this.preloader = false;
    }
  }

  public buildForm(formData) {
    let url: any = API_BASE_URL + 'home/csr/submit';
    let xhr: XMLHttpRequest = new XMLHttpRequest();
    xhr.open('POST', url, true);

    // IE bug fixes to clear cache
    xhr.setRequestHeader('Cache-Control', 'no-cache');
    xhr.setRequestHeader('Cache-Control', 'no-store');

    xhr.send(formData);
    return xhr;
  }
  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
    }
  }

  ngOnDestroy() {}
}
