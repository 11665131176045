import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-international-patients',
  templateUrl: './international-patients.component.html',
})
export class InternationalPatientsComponent implements OnInit {
  constructor(private router: Router, private api: ApiService) {}
  formdata;
  ngOnInit(): void {
    this.formdata = new FormGroup({
      name: new FormControl(''),
      code: new FormControl(''),
      country: new FormControl(''),
      phone: new FormControl(''),
      email: new FormControl(''),
      location: new FormControl(''),
      file: new FormControl(''),
    });
  }
  buildForm(formData) {
    let url: any = '../services/formsubmit.php';
    let xhr: XMLHttpRequest = new XMLHttpRequest();
    xhr.open('POST', url, true);

    // enctype For Multipart Request
    xhr.setRequestHeader('enctype', 'multipart/form-data');

    // IE bug fixes to clear cache
    xhr.setRequestHeader('Cache-Control', 'no-cache');
    xhr.setRequestHeader('Cache-Control', 'no-store');
    xhr.setRequestHeader('Pragma', 'no-cache');

    xhr.send(formData);
    return xhr;
  }
  formSubmit(data) {
    this.api.internationalSubmit(data).subscribe((res) => console.log(res));
    // let formTemp: any = <any>document.querySelector('#form');
    // console.log(formTemp);
    // let formData: FormData = new FormData(formTemp);
    // let xhr: XMLHttpRequest = this.buildForm(this.formdata);

    // xhr.onreadystatechange = () => {
    //   if (xhr.readyState === 4) {
    //     // this.resetForm();
    //     if (xhr.status === 201) {
    //       //window.location.href = this.routerUrl+'?tag=AW';
    //       // $('.CalcLoader').css('display', 'none');
    //       // this.popUpMessage = {
    //       //   title: 'Thankyou',
    //       //   message: 'You have applied sucessfully..',
    //       // };
    //       // initPopup(2);
    //       this.router.navigate(['/childcare']);
    //     } else if (xhr.status === 409) {
    //       //window.location.href = this.routerUrl+'?tag=AW';
    //       // $('.CalcLoader').css('display', 'none');
    //       // this.popUpMessage = {
    //       //   title: 'Failed',
    //       //   message: 'You have alredy applied with same details..',
    //       // };
    //       // initPopup(2);
    //     } else {
    //       // $('.CalcLoader').css('display', 'none');
    //       // this.popUpMessage = {
    //       //   title: 'Failed',
    //       //   message: 'Something went wrong..',
    //       // };
    //       // initPopup(2);
    //     }
    //   }
    // };
  }
}

