<div class="container-fluid">
    <div class="background">
        <img src="assets/images/bg_childcare.png" data-t="bg_01_00.jpg" data-d="bg_childcare.png" class="bgImg bg0 active" />
    </div>
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <section class="page pageheader">
        <div class="row">
            <div class="container">
                <div class="col-xs-12 col-sm-7 col-lg-7 abannimgwrp">
                    <div class="aboutbannimg"><img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" /></div>
                </div>
                <div class="col-xs-12 col-sm-5 col-lg-5">
                    <div class="ptitle">
                        <h1>{{title}}</h1>
                        <h3>{{subtitle}}</h3>
                    </div>
                </div>
                <div class="col-xs-12 mcontentview">
                    <p [innerHTML]="description"></p>
                </div>
            </div>
        </div>
    </section>
    <section class="qacrdnwrp">
        <div class="row">
            <div class="container">
                <div class="col-md-12 col-xs-12 col-lg-12">
                    <ul class="qalist">
                        <li *ngFor="let doctor of doctors">
                            <div class="cirwrp"><img src="{{imgBasePath}}{{doctor.doctors.photo}}" alt="" /> </div>
                            <div class="txtwrp">
                                <h3>{{doctor.doctors.name}}</h3>
                                <p>{{doctor.doctors.designation}}</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>

    <section class="aboutvm">
        <div class="container">
            <div class="col-xs-12 col-lg-12">
                <h3>{{ourVision.title}}</h3>
                <p [innerHTML]="ourVision.description"></p>
                <br /><br /><br /><br />
                <h3>{{ourMission.title}}</h3>
                <div class="row">
                    <div class="col-xs-12 col-sm-9 col-lg-9">
                        <p [innerHTML]="ourMission.description"></p>
                    </div>
                    <div class="col-xs-12 col-sm-3 col-lg-3">
                        <img src="assets/images/vistion_icon.png" alt="" class="right" />
                    </div>
                </div>
            </div>
        </div>
    </section>

    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>