import {
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  HostListener,
} from '@angular/core';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL } from '../services/constants';
import { Meta, Title } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { elementAt } from 'rxjs/operator/elementAt';
declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
declare let initAboutTabs: any;

@Component({
  selector: 'academic-webinar',
  templateUrl: './webinar.component.html',
  styles: [
    `
      ul li a {
        text-transform: capitalize;
      }
      a {
        cursor: pointer;
      }
      .disabled {
        pointer-events: none;
        cursor: default;
      }
      ul.popupmsg li {
        padding: 0;
        margin: 10px 0;
        font-size: 14px;
      }
      ul {
        padding-left: 5px;
        list-style: none;
      }
      ul.inactive {
        display: none;
      }
      ul.active {
        display: block !important;
      }
      .selectwrp li .finddwrp {
        position: relative;
      }
      .selectwrp li .finddwrp::before {
        content: url('../../assets/images/icon-findd.png');
        position: absolute;
        left: 15px;
        top: 22px;
        width: 16px;
        height: 21px;
      }
      .searchDD {
        position: relative;
      }
      .searchDD input {
        border: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        color: #fff;
        font-family: 'dinrg';
        font-size: 15px;
        width: 100%;
        height: 34px;
        background-color: transparent !important;
        border-radius: 7px;
        padding-left: 20px;
      }
      .searchDD input:focus,
      .searchDD input:active {
        outline: none;
      }
      .searchDD input::placeholder {
        color: #fff;
        opacity: 1;
      }
      .searchDD input:-ms-input-placeholder {
        color: #fff;
      }
      .searchDD input::-ms-input-placeholder {
        color: #fff;
      }
      .searchDD ul {
        width: 100%;
        display: none;
        background: #3d2979;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        left: 9px;
        margin: 0;
        padding: 0;
        padding-bottom: 10px;
        position: absolute;
        top: 46px;
        z-index: 2;
        overflow-x: hidden;
        overflow: auto;
      }
      .searchDD ul.active.maxhight {
        max-height: 170px;
      }
      .searchDD ul li {
        margin: 0px;
        float: left;
        list-style: none;
        width: 100%;
        background: #3d2979;
        border-radius: 0px;
        padding: 6px 10px;
        position: relative;
        color: #fff;
      }
      .searchDD ul li:hover {
        background: rgba(255, 255, 255, 0.1);
      }
      .searchDD ul li a {
        color: #fff;
        font-family: 'dinrg';
        font-size: 15px;
        text-decoration: none;
      }
      .bookAppPgBgAnim {
        background-color: #3d2979;
        margin: 0;
        padding: 8px 20px;
        display: inline-block;
        font-weight: normal;
        color: #fff;
        -webkit-animation-name: bganimate;
        -webkit-animation-duration: 1s;
        animation-iteration-count: infinite;
        border-radius: 2px;
        text-align: center;
        font-size: 16px;
        line-height: 20px;
      }
      @-webkit-keyframes bganimate {
        50% {
          background-color: #773996;
        }
      }
      @keyframes bganimate {
        50% {
          background-color: rgb(236, 28, 36, 0.8);
        }
      }
      @media (max-width: 1280px) {
        .searchDD ul {
          width: 122%;
        }
      }
      @media (max-width: 420px) {
        .searchDD ul {
          width: 136%;
        }
        .bookAppPgBgAnim {
          font-size: 14px;
          line-height: 18px;
          padding: 6px 10px;
        }
      }
    `,
  ],
})
export class AcademicWebinarComponent
  implements OnDestroy, OnInit, AfterViewInit
{
  constructor(
    private api: ApiService,
    public metaTitle: Title,
    public meta: Meta,
    public datepipe: DatePipe
  ) {
    if (this.api.isBrowser) {
      // some of these will be required in near future
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/fancybox/jquery.fancybox.js');
      require('../../assets/js/slick.min.js');
      require('../../assets/js/pagination.min.js');
    }
  }

  public services: any[] = [];
  public brief_facilities: any[] = [];
  public title: any = '';
  public subtitle: any = '';
  public description: any = '';
  public imgsrc: any = '';
  public imgBasePath = '';
  public hidden = 'hidden';
  public logo = 'logo';
  public AcademicWebinarData: any;
  public AcademicWebinarFData: any;
  public AcademicWebinarPData: any;
  public centerList: any[] = [];
  public SpecialtyID: any = '';
  public LocationID: any = '';
  public DoctorID: any = '';
  public PastWebinarsResult: boolean = false;
  public AcademicWebinarFilterDataBackup: any;
  public upcomingWebinarCheck: boolean = false;
  public firstTimePaging: boolean = false;

  public searchDoctor: any;
  public sectionType: any;
  public doctorSearchResult: Array<any>;
  public finddoctorclass: boolean = false;
  public specilityMasterList = [
    {
      id: 1,
      slug: 'child-care',
      name: 'Child Care',
    },
    {
      id: 2,
      slug: 'women-care',
      name: 'Women Health',
    },
    {
      id: 3,
      slug: 'fertility-care',
      name: 'Fertility Care',
    },
  ];

  public bread_crumb_links: any = [
    { label: 'Home', link: '/', class: '', slug: 'home' },
    { label: 'Others', link: '', class: '', slug: 'others' },
    {
      label: 'Webinars',
      link: '/other/academic-webinar',
      class: '',
      slug: 'academic-webinar',
    },
  ];

  //public links: any = [];
  public links: any = [
    {
      label: 'Webinars',
      link: '/other/academic-webinar',
      class: 'active',
      slug: 'academic-webinar',
    },
    {
      label: 'Publications',
      link: '/other/academic-article',
      class: '',
      slug: 'academic-article',
    },
    {
      label: 'Programs',
      link: '/other/academic-programs',
      class: '',
      slug: 'academic-programs',
    },
  ];

  public activePage: string = 'academic-webinar';

  //@ViewChildren('atabs') atabsThings: QueryList<any>;

  ngOnInit() {
    this.api.getPageDescription('academic-webinar').subscribe(
      (data) => {
        (this.title = data.page_descriptions[0].title),
          (this.subtitle = data.page_descriptions[0].subtitle),
          (this.description = data.page_descriptions[0].description),
          (this.imgsrc = data.page_descriptions[0].image),
          (this.imgBasePath = IMG_BASE_URL);
        if (data) {
          //console.log(data);
          this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
          this.meta.updateTag({
            name: 'keywords',
            content: data.page_descriptions[0].meta_keyword,
          });
          this.meta.updateTag({
            name: 'description',
            content: data.page_descriptions[0].meta_description,
          });
          this.meta.addTags([
            {
              property: 'og:title',
              content: data.page_descriptions[0].meta_title,
            },
            {
              property: 'og:description',
              content: data.page_descriptions[0].meta_description,
            },
            {
              property: 'og:keywords',
              content: data.page_descriptions[0].meta_keyword,
            },
          ]);
        }
      },
      (error) => {
        console.log(error);
      }
    );
    /*this.api.getOtherSubFooterDetails().map(res => res.json()).subscribe((data)=>{
			this.links =  data;
		})*/
    //this.getAllCentresSpeciality();
    this.getAcademicWebinar();
  }

  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
    }
  }

  upWebinarSlider() {
    $('body')
      .find('.uWebinarSlider')
      .not('.slick-initialized')
      .slick({
        slide: 'li',
        adaptiveHeight: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow:
          '<a class="slick-prev"><img src="assets/images/prv-arrow2.png" alt="" /></a>',
        nextArrow:
          '<a class="slick-next"><img src="assets/images/nxt-arrow2.png" alt="" /></a>',
        dots: true,
        centerMode: false,
        focusOnSelect: true,
        arrows: false,
        infinite: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 580,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
  }

  @HostListener('window:load', [])
  onWindowLoad() {
    $('.searchDoctor').mouseleave(() => {
      $(this).removeClass('active');
    });
  }

  copyToClipboard(e) {
    let eVal = e.contentvideourlnew;
    const el = document.createElement('textarea');
    el.value = eVal;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    alert(eVal);
    //document.body.removeChild(el);
  }

  getAllCentresSpeciality() {
    this.api.getCentreCities().subscribe((res) => {
      this.centerList = res;
    });
  }

  onChangeSpecialty(e) {
    $('.loder').show();
    this.SpecialtyID = e.target.value;
    this.getAllCentresSpeciality();

    if (this.SpecialtyID == '') {
      this.getAcademicWebinarFilter(
        this.SpecialtyID,
        this.LocationID,
        this.DoctorID
      );
    } else {
      this.getAcademicWebinarFilter(
        this.SpecialtyID,
        this.LocationID,
        this.DoctorID
      );
    }
  }

  onChangeLocation(e) {
    $('.loder').show();
    this.LocationID = e.target.value;
    if (this.SpecialtyID == '') {
      this.getAcademicWebinarFilter(
        this.SpecialtyID,
        this.LocationID,
        this.DoctorID
      );
    } else {
      this.getAcademicWebinarFilter(
        this.SpecialtyID,
        this.LocationID,
        this.DoctorID
      );
    }
  }
  selectDoctor(id) {
    $('.loder').show();
    this.DoctorID = id;
    if (this.DoctorID == '') {
      this.getAcademicWebinarFilter(
        this.SpecialtyID,
        this.LocationID,
        this.DoctorID
      );
    } else {
      this.getAcademicWebinarFilter(
        this.SpecialtyID,
        this.LocationID,
        this.DoctorID
      );
    }
    this.searchDoctor = '';
    $('.searchDoctor').removeClass('active');
  }

  getDoctorname(evt) {
    if (evt.target.value.length >= 2) {
      if (this.sectionType == 'visiting-consultant') {
        this.api
          .getConsultDoctorSearchList(evt.target.value)
          .subscribe((data) => {
            this.doctorSearchResult = data.doctors; //console.log('doctorSearchResult:', this.doctorSearchResult);
          });
        this.finddoctorclass = true;
        $('.searchDoctor').addClass('active');
      } else {
        this.api.getDoctorSearchList(evt.target.value).subscribe((data) => {
          this.doctorSearchResult = data.doctors; //console.log('doctorSearchResult2:', this.doctorSearchResult);
        });
        this.finddoctorclass = true;
        $('.searchDoctor').addClass('active');
      }
    } else {
      this.finddoctorclass = false;
    }
  }

  getAcademicWebinar() {
    this.api.getAcademicWebinar().subscribe((data) => {
      //console.log('getAcademicWebinar: ', data);
      //this.AcademicWebinarData = data.content_hubs;
      this.AcademicWebinarData = data;
      let fdata = [],
        pdata = [];
      let r = new RegExp('^(?:[a-z]+:)?//', 'i');
      this.AcademicWebinarData.forEach((element) => {
        //console.log('AcademicWebinarData: ', element.centerlist.length, element.doctorlist.length, element.citylist.length, element.specialitylist.length, element.subspecialitylist.length);
        if (element.secondaryfilter == 2) {
          if (element.centerlist.length != 0) {
            let CList = [];
            element.centerlist.forEach((cListElement) => {
              CList.push(cListElement.center_name_alias);
            });
            element.centerlistNew = CList.toString();
          } else {
            element.centerlistNew = '';
          }

          if (element.doctorlist.length != 0) {
            let DList = [];
            element.doctorlist.forEach((dListElement) => {
              DList.push(dListElement.name);
            });
            element.doctorlistNew = DList.toString();
          } else {
            element.doctorlistNew = '';
          }

          if (element.specialitylist.length != 0) {
            let SList = [];
            element.specialitylist.forEach((SListElement) => {
              SList.push(SListElement.speciality);
            });
            element.specialitylistNew = SList.toString();
          } else {
            element.specialitylistNew = '';
          }

          if (element.subspecialitylist.length != 0) {
            let SSList = [];
            element.subspecialitylist.forEach((SSListElement) => {
              SSList.push(SSListElement.title);
            });
            element.subspecialitylistNew = SSList.toString();
          } else {
            element.subspecialitylistNew = '';
          }

          if (r.test(element.contentimage)) {
            element.contentimageNew = element.contentimage;
          } else {
            element.contentimageNew = IMG_BASE_URL + element.contentimage;
          }
          if (r.test(element.contentvideoimage)) {
            element.contentvideoimageNew = element.contentvideoimage;
          } else {
            element.contentvideoimageNew =
              IMG_BASE_URL + element.contentvideoimage;
          }

          if (element.contentlinkimage != '') {
            if (r.test(element.contentlinkimage)) {
              element.contentlinkimageNew = element.contentlinkimage;
            } else {
              element.contentlinkimageNew =
                IMG_BASE_URL + element.contentlinkimage;
            }
          } else {
            element.contentlinkimageNew = element.contentvideoimageNew;
          }

          if (element.contentsmalldecription != null) {
            let dName = element.contentsmalldecription;
            //if(dName.match(/,/g) ){
            dName = dName.split(',');
            element.contentsmalldecription = dName;
            //}
          }
          if (new Date() < new Date(element.contentdate)) {
            element.contentdate = this.datepipe.transform(
              element.contentdate,
              'MMMM d, y'
            );
            fdata.push(element);
          } else {
            element.contentdate = this.datepipe.transform(
              element.contentdate,
              'd MMMM y'
            );
            // if youtube embed url match:, iframe_src.match(/youtu\.be.*(\?v=|\/embed\/)(.{11})/).pop();

            if (element.contentvideourl != null) {
              let youtube_video_id = element.contentvideourl
                .match(/youtu\.be.*(\/)(.{11})/)
                .pop();
              //element.contentvideoimage = "https://img.youtube.com/vi/"+youtube_video_id+"/0.jpg";
              element.contentvideourlnew =
                'https://www.youtube.com/embed/' +
                youtube_video_id +
                '?autoplay=1';
              element.contentvideourlcopy =
                'https://www.youtube.com/embed/' + youtube_video_id;
            } else {
              element.contentvideourlnew = element.contentvideoimageNew;
            }

            pdata.push(element);
          }
        }
      });

      fdata.forEach((element) => {
        element.sortDate = new Date(element.contentdate);
      });
      pdata.forEach((element) => {
        element.sortDate = new Date(element.contentdate);
      });

      this.AcademicWebinarFData = fdata;

      if (this.AcademicWebinarFData.length > 0) {
        this.upcomingWebinarCheck = false;
      } else {
        this.upcomingWebinarCheck = true;
      }
      this.AcademicWebinarPData = pdata;
      console.log(
        'AcademicWebinarPData',
        this.AcademicWebinarPData,
        this.AcademicWebinarPData.length
      );
      this.AcademicWebinarFData = this.AcademicWebinarFData.sort(
        (a, b) => b.sortDate - a.sortDate
      );
      this.AcademicWebinarPData = this.AcademicWebinarPData.sort(
        (a, b) => b.sortDate - a.sortDate
      );

      //console.log('AcademicWebinarFData: ', pdata);
      setTimeout(() => {
        this.upWebinarSlider();
      }, 100);
      setTimeout(() => {
        this.pagination();
      }, 2000);
    });
  }
  getAcademicWebinarFilter(sid, lid, did) {
    this.AcademicWebinarPData = [];
    $('#pagination').empty();
    this.api.getAcademicWebinarFilter(sid, lid, did).subscribe((data) => {
      //console.log('getAcademicWebinarFilter: ', data.content_hubs);
      //this.AcademicWebinarFilterDataBackup = data.content_hubs;
      //this.AcademicWebinarFilterDataBackup = data;
      let AcademicWebinarFilterData = data; //console.log('getAcademicWebinarFilter: ', data, data.length);
      let pdata = []; //contentdate
      if (AcademicWebinarFilterData.length == 0) {
        $('.loder').hide();
        this.PastWebinarsResult = true;
      } else {
        this.PastWebinarsResult = false;
        //console.log('AcademicWebinarFilterData: ', AcademicWebinarFilterData); //PastWebinarsResult
        let r = new RegExp('^(?:[a-z]+:)?//', 'i');
        AcademicWebinarFilterData.forEach((element) => {
          if (element.secondaryfilter == 2) {
            if (r.test(element.contentimage)) {
              element.contentimageNew = element.contentimage;
            } else {
              element.contentimageNew = IMG_BASE_URL + element.contentimage;
            }
            if (r.test(element.contentvideoimage)) {
              element.contentvideoimageNew = element.contentvideoimage;
            } else {
              element.contentvideoimageNew =
                IMG_BASE_URL + element.contentvideoimage;
            }

            if (element.contentlinkimage != '') {
              if (r.test(element.contentlinkimage)) {
                element.contentlinkimageNew = element.contentlinkimage;
              } else {
                element.contentlinkimageNew =
                  IMG_BASE_URL + element.contentlinkimage;
              }
            } else {
              element.contentlinkimageNew = element.contentvideoimageNew;
            }

            if (element.centerlist.length != 0) {
              let CList = [];
              element.centerlist.forEach((cListElement) => {
                CList.push(cListElement.center_name_alias);
              });
              element.centerlistNew = CList.toString();
            } else {
              element.centerlistNew = '';
            }

            if (element.doctorlist.length != 0) {
              let DList = [];
              element.doctorlist.forEach((dListElement) => {
                DList.push(dListElement.name);
              });
              element.doctorlistNew = DList.toString();
            } else {
              element.doctorlistNew = '';
            }

            if (element.specialitylist.length != 0) {
              let SList = [];
              element.specialitylist.forEach((SListElement) => {
                SList.push(SListElement.speciality);
              });
              element.specialitylistNew = SList.toString();
            } else {
              element.specialitylistNew = '';
            }

            if (element.subspecialitylist.length != 0) {
              let SSList = [];
              element.subspecialitylist.forEach((SSListElement) => {
                SSList.push(SSListElement.title);
              });
              element.subspecialitylistNew = SSList.toString();
            } else {
              element.subspecialitylistNew = '';
            }

            if (element.contentsmalldecription != null) {
              let dName2 = element.contentsmalldecription.toString();
              dName2 = dName2.split(',');
              element.contentsmalldecription = dName2;
            }
            if (new Date() > new Date(element.contentdate)) {
              element.contentdate = this.datepipe.transform(
                element.contentdate,
                'd MMMM y'
              );
              if (element.contentvideourl != null) {
                // if youtube embed url match:, iframe_src.match(/youtu\.be.*(\?v=|\/embed\/)(.{11})/).pop();
                let youtube_video_id = element.contentvideourl
                  .match(/youtu\.be.*(\/)(.{11})/)
                  .pop();
                //element.contentvideoimage = "https://img.youtube.com/vi/"+youtube_video_id+"/0.jpg";
                element.contentvideourlnew =
                  'https://www.youtube.com/embed/' +
                  youtube_video_id +
                  '?autoplay=1';
                element.contentvideourlcopy =
                  'https://www.youtube.com/embed/' + youtube_video_id;
              } else {
                element.contentvideourlnew = element.contentvideoimageNew;
              }
              pdata.push(element);
            }
          }
        });
        //console.log('pdata: ', pdata);
        this.AcademicWebinarPData = pdata;

        var newItem = '';
        for (var i = 0; i < pdata.length; i++) {
          //newItem += '<tr><td align="center" class="firstColumn" valign="middle" style="width: 20%;border:3px solid #fff;padding:15px;"><img src="'+pdata[i].contentvideoimageNew+'" alt="" /></td><td align="left" valign="middle" style="width: 50%;border:2px solid #fff;padding:15px;"><div class="column2"><h3>'+pdata[i].contentheading+'</h3><div class="adoctorNameWrp">'+DName+'</div><div class="adateWrp"><span>Date: '+pdata[i].contentdate+'</span></div><div class="videoWrp"><a href="'+pdata[i].contentvideourlnew+'" class="fancybox"> <img src="'+pdata[i].contentimageNew+'" alt="" /></a><br><br><a class="copyIcon" href="javascript:void(0);"><span style="display: none;">'+pdata[i].contentvideourlcopy+'</span>Copy Video URL to Share</a></div></div></td><td align="center" class="thurdColumn" valign="middle" style="width: 30%;border:3px solid #fff;padding:15px;"><a href="'+pdata[i].contentvideourlnew+'" class="fancybox"> <img src="'+pdata[i].contentimageNew+'" alt="" /></a><br><br><a class="copyIcon" href="javascript:void(0);"><span style="display: none;">'+pdata[i].contentvideourlcopy+'</span>Copy Video URL to Share</a></td></tr>';
          newItem +=
            '<tr><td align="center" class="firstColumn" valign="middle" style="width: 20%;border:3px solid #fff;padding:15px;"><a href="' +
            pdata[i].contentlinkimageNew +
            '" class="fancyboximg"><img src="' +
            pdata[i].contentvideoimageNew +
            '" alt="" /></a></td><td align="left" valign="middle" style="width: 50%;border:2px solid #fff;padding:15px;"><div class="column2"><h3>' +
            pdata[i].contentheading +
            '</h3><div class="smallContentWrp">' +
            pdata[i].contentsmalldecription +
            '</div><div class="specilitylistWrp"><strong>Doctors: </strong> <span>' +
            pdata[i].doctorlistNew +
            '</span></div><div class="specilitylistWrp"><strong>Specialty: </strong> <span>' +
            pdata[i].specialitylistNew +
            '</span></div><div class="specilitylistWrp"><strong>Sub Specialty: </strong> <span>' +
            pdata[i].subspecialitylistNew +
            '</span></div><div class="specilitylistWrp"><strong>Center: </strong> <span>' +
            pdata[i].centerlistNew +
            '</span></div><div class="specilitylistWrp"><strong>Date: </strong> <span>' +
            pdata[i].contentdate +
            '</span></div><div class="videoWrp"><a href="' +
            pdata[i].contentvideourlnew +
            '" class="fancybox"> <div class="videoThumb"><img src="' +
            pdata[i].contentimageNew +
            '" alt="" /></div></a><br><br><a class="copyIcon" href="javascript:void(0);"><span style="display: none;">' +
            pdata[i].contentvideourlcopy +
            '</span>Copy Video URL to Share</a></div></div></td><td align="center" class="thurdColumn" valign="middle" style="width: 30%;border:3px solid #fff;padding:15px;"><a href="' +
            pdata[i].contentvideourlnew +
            '" class="fancybox"> <div class="videoThumb"><img src="' +
            pdata[i].contentimageNew +
            '" alt="" /></div></a><br><br><a class="copyIcon" href="javascript:void(0);"><span style="display: none;">' +
            pdata[i].contentvideourlcopy +
            '</span>Copy Video URL to Share</a></td></tr>';
        }
        $('#PastWebinarsPaging').html('<tbody>' + newItem + '</tbody>');

        $('.loder').hide();
        setTimeout(() => {
          this.pagination();
        }, 2000);
      }
    });
  }
  pagination() {
    let rows = [];
    $('#PastWebinarsPaging tbody tr').each(function (i, row) {
      return rows.push(row);
    });

    $('#pagination').pagination({
      dataSource: rows,
      pageSize: 6,
      callback: function (data, pagination) {
        $('tbody').html(data);

        setTimeout(() => {
          $('.fancyboximg').fancybox({});
          $('.fancybox').fancybox({
            type: 'iframe',
            maxWidth: 700,
            maxHeight: 600,
            fitToView: true,
            width: '70%',
            height: '70%',
            autoSize: true,
            closeClick: true,
            openEffect: 'none',
            closeEffect: 'none',
            helpers: {
              media: true,
            },
            youtube: {
              autoplay: 1, // enable autoplay
            },
          });
          if (this.firstTimePaging) {
            $('html, body').animate(
              {
                scrollTop: $('#PastWebinarsWrp').offset().top,
              },
              1200
            );
          } else {
            this.firstTimePaging = true;
          }
          $('.copyIcon').each((e) => {
            $(this).click((e) => {
              let eVal = $(this).find('span').html();
              const el = document.createElement('textarea');
              el.value = eVal;
              document.body.appendChild(el);
              el.select();
              //   document.execCommand('copy');
              navigator.clipboard
                .writeText(el.value)
                .then(() => {
                  alert('Video URL copied');
                })
                .catch(() => {
                  alert('err');
                });
            });
          });
        }, 100);
      },
    });
  }
  ngOnDestroy() {}
}
