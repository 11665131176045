import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  HostListener,
  AfterViewInit,
  PLATFORM_ID,
  ElementRef,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { isPlatformBrowser, Location } from '@angular/common';
import { ApiService } from './services/api.service';
import { IMG_BASE_URL } from './services/constants';
import { Meta, Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
//declare let $:any;

declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
declare let initCustomSelect: any;

@Component({
  selector: 'my-not-found',
  templateUrl: './my-not-found.component.html',
  styles: [
    `
      .page_notf {
        padding: 40px 0px 0px;
      }
      .pnotf_container {
        width: 90%;
        max-width: 1170px;
        margin: 0 auto;
      }
      .Oops {
        text-align: center;
      }
      .Oops h2 {
        margin: 10px 0px 0px;
        display: inline-block;
        font-size: 50px;
      }
      .Oops h3 {
        margin: 0px 0px 15px;
      }
      .bgblue {
        background-color: #773a95;
        margin-top: 50px;
        background: url(../assets/images/bottombg.jpg) center center;
        background-size: cover;
      }
      .FZF-menu {
        position: relative;
        padding: 30px 0 80px;
      }
      .FZF-menu h2 {
        font-size: 22px;
        color: #fff;
        padding: 0px 0 30px 0;
      }
      .FZF-menu ul {
        margin: 0;
        padding: 0;
        list-style: none;
      }
      .FZF-menu ul li {
        float: left;
        font-size: 14px;
        padding: 0 2% 0 42px;
        background: url(../assets/images/dot-fzf.png) 27px 12px no-repeat;
        text-align: left;
      }
      .FZF-menu ul li a {
        text-align: left;
        font-size: 22px;
        color: #fff;
      }

      @media (max-width: 1199px) {
        .page_notf {
          padding: 140px 0px 0px;
        }
        .FZF-menu {
          display: inline-block;
        }
      }
      @media (max-width: 767px) {
        .page_notf {
          padding: 100px 0px 0px;
        }
      }
      @media (max-width: 580px) {
        .FZF-menu ul li {
          width: 50%;
        }
      }
      @media (max-width: 420px) {
        .FZF-menu ul li {
          width: 100%;
        }
      }
    `,
  ],
})
export class NotFound404Component implements OnDestroy, OnInit, AfterViewInit {
  router: Router;
  constructor(
    private api: ApiService,
    private location: Location,
    private _router: Router,
    private activatedRoute: ActivatedRoute,
    public metaTitle: Title,
    public meta: Meta
  ) {
    this.router = _router;
    if (this.api.isBrowser) {
      // window['jQuery'] = $;
      // window['$'] = $;
      // require('../assets/js/bootstrap.js');
    }
  }

  public hidden = 'hidden';
  public logo = 'birth-right-logo';
  public sideBarList: Array<any>;
  public bread_crumb_links: any = [{ label: '', link: '/', class: '' }];

  ngOnInit() {
    this.api
      .getSideBarlist()
      .map((res) => res)
      .subscribe((data) => {
        this.sideBarList = data;
      });
  }
  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
    }
  }
  ngOnDestroy() {}
  /*
  @HostListener("window:load", []) onWindowLoad(){
    $('.loder').fadeOut(1000);
		(function stikyFoot(){
      $(window).scroll(function() {
        if ($(window).scrollTop() >= (($(document).height() - $(window).height()) - ($('.stikyNavFooter').outerHeight()+50))) {
        $('.stikyNavFooter').hide();
        }else{
         $('.stikyNavFooter').show();
        }
      });	
    })()
  }*/
}
