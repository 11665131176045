<div class="container-fluid">
  <div class="loder"></div>
  <sidenav></sidenav>

  <header-wrap
    [hidden]="hidden"
    [logo]="logo"
    (section)="onNotify($event)"
  ></header-wrap>
  <section class="sfirst">
    <div class="container tab-navigation-container">
      <div class="tab-navigation-row">
        <div class="tabIndex">
          <div>
            <h1 id="1" class="tabText">Board&nbsp;of&nbsp;Directors</h1>
            <div class="m-view">

              <div class="tab-container" id="tc-1">
                <div id="tab1" class="tab tab1">
                <div class="members-list">
                  <div
                    *ngFor="let member of boardOfDirectors; let i = index"
                    class="member-item"
                    id="{{ i }}"
                  >
                    <img
                      src="{{ member.imageUrl }}"
                      alt="member.name"
                      class="display-pictures"
                      />
                    <p
                      [innerHTML]="member.name"
                      style="
                        font-size: 15px;
                        font-family: 'dinrg';
                        font-weight: bold;
                        margin-top: 10px;
                      "
                    ></p>
                    <p
                      [innerHTML]="member.post"
                      style="
                        font-size: 13px;
                        font-family: 'dinrg';
                        font-weight: normal;
                        margin-top: 10px;
                        color: #000;
                      "
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
            <h1 id="2" class="tabText">Financials</h1>
            <div class="m-view">

              <div class="tab-container" id="tc-2">
                <div id="tab2" class="tab tab2">
                <ul
                  style="
                    text-align: center;
                    display: block;
                    list-style-type: disc;
                  "
                >
                  <li class="listbullets">
                    <a
                      href="/investor-relations/reports/RCMPL%20Standalone%20Financial%20Statements%20FY2020-21.pdf"
                      class="heading-tab"
                      target="_blank"
                      >RCMPL Standalone Financial Statements FY2020-21</a
                    >
                  </li>
                  <li class="listbullets">
                    <a
                      href="/investor-relations/reports/RCMPL Standalone Financial Statements FY2019-20.pdf"
                      class="heading-tab"
                      target="_blank"
                      >RCMPL Standalone Financial Statements FY2019-20</a
                    >
                  </li>
                  <li class="listbullets">
                    <a
                      href="/investor-relations/reports/RCMPL Standalone Financial Statements FY2018-19.pdf"
                      class="heading-tab"
                      target="_blank"
                      >RCMPL Standalone Financial Statements FY2018-19</a
                      >
                  </li>
                </ul>
              </div>
            </div>
            </div>
            <h1 id="3" class="tabText">Annual Return</h1>
            <div class="m-view">
              
              <div class="tab-container" id="tc-3">
                <div id="tab3" class="tab tab3">
                <ul
                  style="
                    text-align: center;
                    display: block;
                    list-style-type: disc;
                  "
                >
                  <li class="listbullets">
                    <a
                      href="/investor-relations/reports/Annual Return FY 2020-21.pdf"
                      class="heading-tab"
                      target="_blank"
                      >Annual Return FY 2020-21</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
            <h1 id="4" class="tabText">Industry Report</h1>
            <div class="m-view">

              <div class="tab-container" id="tc-4">
                <div id="tab4" class="tab tab4">
                <ul
                  style="
                    text-align: center;
                    display: block;
                    list-style-type: disc;
                  "
                >
                  <li class="listbullets">
                    <a
                      href="/investor-relations/reports/industry_report.pdf"
                      class="heading-tab"
                      target="_blank"
                      >Assessment of maternity and paediatric healthcare
                      delivery sectors in India</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
            <h1 id="5" class="tabText">Policies</h1>
            <div class="m-view">

              <div class="tab-container" id="tc-5">
                <div id="tab5" class="tab tab5">
                <ul
                  style="
                    text-align: center;
                    display: block;
                    list-style-type: disc;
                  "
                >
                  <li class="listbullets">
                    <a
                      href="/investor-relations/reports/Archival_Policy.pdf"
                      class="heading-tab"
                      target="_blank"
                      >Archival Policy</a
                      >
                    </li>
                  <li class="listbullets">
                    <a
                      href="/investor-relations/reports/Dividend_Distribution_Policy.pdf"
                      class="heading-tab"
                      target="_blank"
                      >Dividend Distribution Policy</a
                    >
                  </li>
                  <li class="listbullets">
                    <a
                      href="/investor-relations/reports/policy/Code%20of%20conduct%20for%20Directors%20and%20Senior%20Management.pdf"
                      class="heading-tab"
                      target="_blank"
                      >Code of conduct for Directors and Senior Management</a
                      >
                    </li>
                  <li class="listbullets">
                    <a
                      href="/investor-relations/reports/policy/Materiality%20Policy%20for%20identification%20of%20Material%20Creditors%20and%20for%20Litigation.pdf"
                      class="heading-tab"
                      target="_blank"
                      >Materiality Policy for identification of Material
                      Creditors and for Litigation</a
                    >
                  </li>
                  <li class="listbullets">
                    <a
                      href="/investor-relations/reports/policy/Policy%20for%20determining%20Material%20Group%20Companies.pdf"
                      class="heading-tab"
                      target="_blank"
                      >Policy for determining Material Group Companies</a
                    >
                  </li>
                  <li class="listbullets">
                    <a
                    href="/investor-relations/reports/policy/Policy%20for%20determining%20Material%20Subsidiary.pdf"
                    class="heading-tab"
                    target="_blank"
                    >Policy for determining Material Subsidiary</a
                    >
                  </li>
                  <li class="listbullets">
                    <a
                    href="/investor-relations/reports/policy/Policy%20on%20dealing%20with%20Related%20Party%20Transactions.pdf"
                    class="heading-tab"
                    target="_blank"
                    >Policy on dealing with Related Party Transactions</a
                    >
                  </li>
                  <li class="listbullets">
                    <a
                    href="/investor-relations/reports/policy/Terms%20and%20Conditions%20of%20appointment%20of%20Independent%20Directors.pdf"
                    class="heading-tab"
                    target="_blank"
                    >Terms and Conditions of appointment of Independent
                    Directors</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
            <h1 id="6" class="tabText">
              Board&nbsp;&&nbsp;Board&nbsp;Committees
            </h1>
            <div class="m-view">
            <div class="tab-container" id="tc-6">
              <div id="tab6" class="tab tab6">
                <!-- <div id="individual_wrapper" class="dataTables_wrapper no-footer">
                  <div class="dt-buttons">
                    <button
                      class="dt-button buttons-copy buttons-html5"
                      tabindex="0"
                      aria-controls="individual"
                      type="button"
                    >
                      <span>Copy</span>
                    </button>
                    <button
                      class="dt-button buttons-excel buttons-html5"
                      tabindex="0"
                      aria-controls="individual"
                      type="button"
                    >
                      <span>Excel</span>
                    </button>
                    <button
                      class="dt-button buttons-csv buttons-html5"
                      tabindex="0"
                      aria-controls="individual"
                      type="button"
                    >
                      <span>CSV</span>
                    </button>
                    <button
                      class="dt-button buttons-pdf buttons-html5"
                      tabindex="0"
                      aria-controls="individual"
                      type="button"
                    >
                      <span>PDF</span>
                    </button>
                  </div>
                  <div id="individual_filter" class="dataTables_filter">
                    <label
                      >Search:<input
                        type="search"
                        class=""
                        placeholder=""
                        aria-controls="individual"
                    /></label>
                  </div>
                  <div class="dataTables_scroll">
                    <div
                      class="dataTables_scrollHead"
                      style="
                        overflow: hidden;
                        position: relative;
                        border: 0px;
                        width: 100%;
                      "
                    >
                      <div
                        class="dataTables_scrollHeadInner"
                        style="
                          box-sizing: content-box;
                          width: 937.379px;
                          padding-right: 14px;
                        "
                      >
                        <table
                          class="display dataTable no-footer "
                          style="width: 937.379px; margin-left: 0px"
                        >
                          <thead>
                            <tr>
                              <th
                                style="min-width: 120px; width: 120px"
                                class="sorting sorting_asc"
                                tabindex="0"
                                aria-controls="individual"
                                rowspan="1"
                                colspan="1"
                                aria-label="Name   : activate to sort column descending"
                                aria-sort="ascending"
                              >
                                Name
                              </th>
                              <th
                                style="min-width: 120px; width: 120px"
                                class="sorting"
                                tabindex="0"
                                aria-controls="individual"
                                rowspan="1"
                                colspan="1"
                                aria-label="Designation: activate to sort column ascending"
                              >
                                Designation
                              </th>
                              <th
                                class="sorting"
                                tabindex="0"
                                aria-controls="individual"
                                rowspan="1"
                                colspan="1"
                                style="width: 58.1034px"
                                aria-label="Audit Committee: activate to sort column ascending"
                              >
                                Audit Committee
                              </th>
                              <th
                                class="sorting"
                                tabindex="0"
                                aria-controls="individual"
                                rowspan="1"
                                colspan="1"
                                style="width: 68.7241px"
                                aria-label="Risk Management Committee: activate to sort column ascending"
                              >
                                Risk Management Committee
                              </th>
                              <th
                                class="sorting"
                                tabindex="0"
                                aria-controls="individual"
                                rowspan="1"
                                colspan="1"
                                style="width: 75.5862px"
                                aria-label="Nomination &amp;amp; Remuneration Committee: activate to sort column ascending"
                              >
                                Nomination &amp; Remuneration Committee
                              </th>
                              <th
                                class="sorting"
                                tabindex="0"
                                aria-controls="individual"
                                rowspan="1"
                                colspan="1"
                                style="width: 73.1034px"
                                aria-label="Stakeholders’ Relationship Committee: activate to sort column ascending"
                              >
                                Stakeholders’ Relationship Committee
                              </th>
                              <th
                                class="sorting"
                                tabindex="0"
                                aria-controls="individual"
                                rowspan="1"
                                colspan="1"
                                style="width: 58.1034px"
                                aria-label="CSR Committee: activate to sort column ascending"
                              >
                                CSR Committee
                              </th>
                              <th
                                class="sorting"
                                tabindex="0"
                                aria-controls="individual"
                                rowspan="1"
                                colspan="1"
                                style="width: 58.1034px"
                                aria-label="IPO Committee: activate to sort column ascending"
                              >
                                IPO Committee
                              </th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                    <div
                      class="dataTables_scrollBody"
                      style="position: relative; overflow: auto; width: 100%"
                    >
                      <table
                        id="individual"
                        class="display dataTable no-footer"
                        style="width: 100%"
                        aria-describedby="individual_info"
                      >
                        <thead>
                          <tr style="height: 0px">
                            <th
                              style="
                                min-width: 120px;
                                width: 120px;
                                padding-top: 0px;
                                padding-bottom: 0px;
                                border-top-width: 0px;
                                border-bottom-width: 0px;
                                height: 0px;
                              "
                              class="sorting sorting_desc"
                              aria-controls="individual"
                              rowspan="1"
                              colspan="1"
                              aria-label="Name   : activate to sort column ascending"
                              aria-sort="descending"
                            >
                              <div
                                class="dataTables_sizing"
                                style="height: 0px; overflow: hidden"
                              >
                                Name
                              </div>
                            </th>
                            <th
                              style="
                                min-width: 120px;
                                width: 120px;
                                padding-top: 0px;
                                padding-bottom: 0px;
                                border-top-width: 0px;
                                border-bottom-width: 0px;
                                height: 0px;
                              "
                              class="sorting"
                              aria-controls="individual"
                              rowspan="1"
                              colspan="1"
                              aria-label="Designation: activate to sort column ascending"
                            >
                              <div
                                class="dataTables_sizing"
                                style="height: 0px; overflow: hidden"
                              >
                                Designation
                              </div>
                            </th>
                            <th
                              class="sorting"
                              aria-controls="individual"
                              rowspan="1"
                              colspan="1"
                              style="
                                width: 58.1034px;
                                padding-top: 0px;
                                padding-bottom: 0px;
                                border-top-width: 0px;
                                border-bottom-width: 0px;
                                height: 0px;
                              "
                              aria-label="Audit Committee: activate to sort column ascending"
                            >
                              <div
                                class="dataTables_sizing"
                                style="height: 0px; overflow: hidden"
                              >
                                Audit Committee
                              </div>
                            </th>
                            <th
                              class="sorting"
                              aria-controls="individual"
                              rowspan="1"
                              colspan="1"
                              style="
                                width: 68.7241px;
                                padding-top: 0px;
                                padding-bottom: 0px;
                                border-top-width: 0px;
                                border-bottom-width: 0px;
                                height: 0px;
                              "
                              aria-label="Risk Management Committee: activate to sort column ascending"
                            >
                              <div
                                class="dataTables_sizing"
                                style="height: 0px; overflow: hidden"
                              >
                                Risk Management Committee
                              </div>
                            </th>
                            <th
                              class="sorting"
                              aria-controls="individual"
                              rowspan="1"
                              colspan="1"
                              style="
                                width: 75.5862px;
                                padding-top: 0px;
                                padding-bottom: 0px;
                                border-top-width: 0px;
                                border-bottom-width: 0px;
                                height: 0px;
                              "
                              aria-label="Nomination &amp;amp; Remuneration Committee: activate to sort column ascending"
                            >
                              <div
                                class="dataTables_sizing"
                                style="height: 0px; overflow: hidden"
                              >
                                Nomination &amp; Remuneration Committee
                              </div>
                            </th>
                            <th
                              class="sorting"
                              aria-controls="individual"
                              rowspan="1"
                              colspan="1"
                              style="
                                width: 73.1034px;
                                padding-top: 0px;
                                padding-bottom: 0px;
                                border-top-width: 0px;
                                border-bottom-width: 0px;
                                height: 0px;
                              "
                              aria-label="Stakeholders’ Relationship Committee: activate to sort column ascending"
                            >
                              <div
                                class="dataTables_sizing"
                                style="height: 0px; overflow: hidden"
                              >
                                Stakeholders’ Relationship Committee
                              </div>
                            </th>
                            <th
                              class="sorting"
                              aria-controls="individual"
                              rowspan="1"
                              colspan="1"
                              style="
                                width: 58.1034px;
                                padding-top: 0px;
                                padding-bottom: 0px;
                                border-top-width: 0px;
                                border-bottom-width: 0px;
                                height: 0px;
                              "
                              aria-label="CSR Committee: activate to sort column ascending"
                            >
                              <div
                                class="dataTables_sizing"
                                style="height: 0px; overflow: hidden"
                              >
                                CSR Committee
                              </div>
                            </th>
                            <th
                              class="sorting"
                              aria-controls="individual"
                              rowspan="1"
                              colspan="1"
                              style="
                                width: 58.1034px;
                                padding-top: 0px;
                                padding-bottom: 0px;
                                border-top-width: 0px;
                                border-bottom-width: 0px;
                                height: 0px;
                              "
                              aria-label="IPO Committee: activate to sort column ascending"
                            >
                              <div
                                class="dataTables_sizing"
                                style="height: 0px; overflow: hidden"
                              >
                                IPO Committee
                              </div>
                            </th>
                          </tr>
                        </thead>
    
                        <tbody>
                          <tr class="odd">
                            <td
                              style="min-width: 120px; width: 100%"
                              class="sorting_1"
                            >
                              Dr. Anil Dhawan
                            </td>
                            <td style="min-width: 120px; width: 100%">
                              Independent Director
                            </td>
                            <td><img src="/assets/images/iconA.png" /></td>
                            <td>–</td>
                            <td><img src="/assets/images/iconA.png" /></td>
                            <td><img src="/assets/images/iconA.png" /></td>
                            <td><img src="/assets/images/chairmenB.png" /></td>
                            <td>–</td>
                          </tr>
                          <tr class="even">
                            <td
                              style="min-width: 120px; width: 100%"
                              class="sorting_1"
                            >
                              Dr. Dinesh Kumar Chirla
                            </td>
                            <td style="min-width: 120px; width: 100%">
                              Whole-Time Director
                            </td>
                            <td>–</td>
                            <td>–</td>
                            <td>–</td>
                            <td>–</td>
                            <td><img src="/assets/images/iconA.png" /></td>
                            <td><img src="/assets/images/iconA.png" /></td>
                          </tr>
                          <tr class="odd">
                            <td
                              style="min-width: 120px; width: 100%"
                              class="sorting_1"
                            >
                              Dr. Ramesh Kancharla
                            </td>
                            <td style="min-width: 120px; width: 100%">
                              Chairman &amp; Managing Director
                            </td>
                            <td>–</td>
                            <td><img src="/assets/images/iconA.png" /></td>
                            <td>–</td>
                            <td><img src="/assets/images/iconA.png" /></td>
                            <td><img src="/assets/images/iconA.png" /></td>
                            <td><img src="/assets/images/chairmenB.png" /></td>
                          </tr>
                          <tr class="even">
                            <td
                              style="min-width: 120px; width: 100%"
                              class="sorting_1"
                            >
                              Mr. Aluri Srinivasa Rao
                            </td>
                            <td style="min-width: 120px; width: 100%">
                              Independent Director
                            </td>
                            <td><img src="/assets/images/iconA.png" /></td>
                            <td><img src="/assets/images/iconA.png" /></td>
                            <td><img src="/assets/images/chairmenB.png" /></td>
                            <td><img src="/assets/images/iconA.png" /></td>
                            <td>–</td>
                            <td><img src="/assets/images/iconA.png" /></td>
                          </tr>
                          <tr class="odd">
                            <td
                              style="min-width: 120px; width: 100%"
                              class="sorting_1"
                            >
                              Mr. Mahesh Madduri
                            </td>
                            <td style="min-width: 120px; width: 100%">
                              Head of Strategy
                            </td>
                            <td>–</td>
                            <td><img src="/assets/images/iconA.png" /></td>
                            <td>–</td>
                            <td>–</td>
                            <td>–</td>
                            <td>–</td>
                          </tr>
                          <tr class="even">
                            <td
                              style="min-width: 120px; width: 100%"
                              class="sorting_1"
                            >
                              Mr. R. Gowrisankar
                            </td>
                            <td style="min-width: 120px; width: 100%">
                              Chief Financial Officer
                            </td>
                            <td>–</td>
                            <td><img src="/assets/images/iconA.png" /></td>
                            <td>–</td>
                            <td>–</td>
                            <td>–</td>
                            <td>–</td>
                          </tr>
                          <tr class="odd">
                            <td
                              style="min-width: 120px; width: 100%"
                              class="sorting_1"
                            >
                              Mr. Santanu Mukherjee
                            </td>
                            <td style="min-width: 120px; width: 100%">
                              Independent Director
                            </td>
                            <td><img src="/assets/images/chairmenB.png" /></td>
                            <td><img src="/assets/images/chairmenB.png" /></td>
                            <td><img src="/assets/images/iconA.png" /></td>
                            <td>–</td>
                            <td><img src="/assets/images/iconA.png" /></td>
                            <td>–</td>
                          </tr>
                          <tr class="even">
                            <td
                              style="min-width: 120px; width: 100%"
                              class="sorting_1"
                            >
                              Ms. Sundari Raviprasad Pisupati
                            </td>
                            <td style="min-width: 120px; width: 100%">
                              Independent Director
                            </td>
                            <td><img src="/assets/images/iconA.png" /></td>
                            <td>–</td>
                            <td><img src="/assets/images/iconA.png" /></td>
                            <td><img src="/assets/images/iconA.png" /></td>
                            <td><img src="/assets/images/iconA.png" /></td>
                            <td>–</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    class="dataTables_info"
                    id="individual_info"
                    role="status"
                    aria-live="polite"
                  >
                    Showing 1 to 8 of 8 entries
                  </div>
                  <div
                    class="dataTables_paginate paging_simple_numbers"
                    id="individual_paginate"
                  >
                    <a
                      class="paginate_button previous disabled"
                      aria-controls="individual"
                      data-dt-idx="0"
                      tabindex="-1"
                      id="individual_previous"
                      >Previous</a
                    ><span
                      ><a
                        class="paginate_button current"
                        aria-controls="individual"
                        data-dt-idx="1"
                        tabindex="0"
                        >1</a
                      ></span
                    ><a
                      class="paginate_button next disabled"
                      aria-controls="individual"
                      data-dt-idx="2"
                      tabindex="-1"
                      id="individual_next"
                      >Next</a
                    >
                  </div>
                </div> -->
                <ul
                  style="
                    text-align: center;
                    display: block;
                    list-style-type: disc;
                  "
                >
                  <li class="listbullets">
                    <a
                      href="/investor-relations/reports/Committee_members.pdf"
                      class="heading-tab"
                      target="_blank"
                      >Constitution of Board &amp; Board Committees</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
            <h1 id="7" class="tabText">Material&nbsp;Creditors</h1>
            <div class="m-view">

            
            <div class="tab-container" id="tc-7">
              <div id="tab7" class="tab tab7">
                <p class="heading-tab">
                  As on Sept 30, 2021, the Company does not have any material
                  creditors
                </p>
              </div>
            </div>
          </div>
            <h1 id="8" class="tabText">Investor&nbsp;Contact</h1>
            <div class="m-view">

            
            <div class="tab-container" id="tc-8">
              <div id="tab8" class="ta tab8b">
                <div class="contact" style="margin-right: 20px">
                  <h2 style="font-size: 15px; font-weight: 600">
                    For Investor Grievances
                  </h2>
                  <h2 style="font-size: 14px; font-weight: 600">
                    Ashish Kapil
                  </h2>
                  <p style="font-size: 12px">
                    Company Secretary &amp; Compliance Officer
                  </p>
                  <p style="font-size: 12px">
                    Rainbow Children’s Medicare Limited
                  </p>
                  <p style="font-size: 12px">
                    8-2-19/1/A, Daulet Arcade, Road No. 11,
                  </p>
                  <p style="font-size: 12px">
                    Banjara Hills, Hyderabad – 500 034, Telangana
                  </p>
                  <p style="font-size: 12px">
                    <strong>Tel: +91 40 49692244</strong>
                  </p>
                  <p style="font-size: 12px">
                    <strong>Email:</strong> companysecretary@rainbowhospitals.in
                  </p>
                </div>
                <div class="contact">
                  <h2 style="font-size: 15px; font-weight: 600">
                    Registrar &amp; Share Transfer Agent
                  </h2>
                  <h2 style="font-size: 14px; font-weight: 600">
                    KFin Technologies Private Limited
                  </h2>
                  <p style="font-size: 12px">Selenium, Tower-B</p>
                  <p style="font-size: 12px">
                    Plot 31 and 32, Financial District
                  </p>
                  <p style="font-size: 12px">Nanakramguda, Serilingampally</p>
                  <p style="font-size: 12px">Hyderabad, Rangareddi 500 032</p>
                  <p style="font-size: 12px">Telangana, India</p>
                  <p style="font-size: 12px">Telephone: +91 40 6716 2222</p>
                  <p style="font-size: 12px">E-mail: rcml.ipo@kfintech.com</p>
                  <p style="font-size: 12px">
                    <strong>Website:</strong> www.kfintech.com
                  </p>
                  <p style="font-size: 12px">
                    <strong>Investor Grievance ID: </strong
                    >einward.ris@kfintech.com
                  </p>
                  <p style="font-size: 12px">
                    <strong>Contact Person:</strong> M Murali Krishna
                  </p>

                  <p style="font-size: 12px">
                    <strong>Tel: +91 40 49692244</strong>
                  </p>
                  <!--<p style="font-size:12px;"><strong>Email:</strong> companysecretary@rainbowhospitals.com</p>-->
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
        <div class="d-view">
          <div class="tab-view-container">
            <div id="tab1" class="tab tab1">
              <div class="members-list">
                <div
                  *ngFor="let member of boardOfDirectors; let i = index"
                  class="member-item"
                  id="{{ i }}"
                >
                  <img
                    src="{{ member.imageUrl }}"
                    alt="member.name"
                    class="display-pictures"
                  />
                  <p
                    [innerHTML]="member.name"
                    style="
                      font-size: 15px;
                      font-family: 'dinrg';
                      font-weight: bold;
                      margin-top: 10px;
                    "
                  ></p>
                  <p
                    [innerHTML]="member.post"
                    style="
                      font-size: 13px;
                      font-family: 'dinrg';
                      font-weight: normal;
                      margin-top: 10px;
                      color: #000;"
                  ></p>
                </div>
              </div>
            </div>
            <div id="tab2" class="tab tab2">
              <ul
                style="text-align: center; display: block; list-style-type: disc"
              >
                <li class="listbullets">
                  <a
                    href="/investor-relations/reports/RCMPL%20Standalone%20Financial%20Statements%20FY2020-21.pdf"
                    class="heading-tab"
                    target="_blank"
                    >RCMPL Standalone Financial Statements FY2020-21</a
                  >
                </li>
                <li class="listbullets">
                  <a
                    href="/investor-relations/reports/RCMPL Standalone Financial Statements FY2019-20.pdf"
                    class="heading-tab"
                    target="_blank"
                    >RCMPL Standalone Financial Statements FY2019-20</a
                  >
                </li>
                <li class="listbullets">
                  <a
                    href="/investor-relations/reports/RCMPL Standalone Financial Statements FY2018-19.pdf"
                    class="heading-tab"
                    target="_blank"
                    >RCMPL Standalone Financial Statements FY2018-19</a
                  >
                </li>
              </ul>
            </div>
            <div id="tab3" class="tab tab3">
              <ul
                style="text-align: center; display: block; list-style-type: disc"
              >
                <li class="listbullets">
                  <a
                    href="/investor-relations/reports/Annual Return FY 2020-21.pdf"
                    class="heading-tab"
                    target="_blank"
                    >Annual Return FY 2020-21</a
                  >
                </li>
              </ul>
            </div>
            <div id="tab4" class="tab tab4">
              <ul
                style="text-align: center; display: block; list-style-type: disc"
              >
                <li class="listbullets">
                  <a
                    href="/investor-relations/reports/industry_report.pdf"
                    class="heading-tab"
                    target="_blank"
                    >Assessment of maternity and paediatric healthcare delivery
                    sectors in India</a
                  >
                </li>
              </ul>
            </div>
  
            <div id="tab5" class="tab tab5">
              <ul
                style="text-align: center; display: block; list-style-type: disc"
              >
                <li class="listbullets">
                  <a
                    href="/investor-relations/reports/Archival_Policy.pdf"
                    class="heading-tab"
                    target="_blank"
                    >Archival Policy</a
                  >
                </li>
                <li class="listbullets">
                  <a
                    href="/investor-relations/reports/Dividend_Distribution_Policy.pdf"
                    class="heading-tab"
                    target="_blank"
                    >Dividend Distribution Policy</a
                  >
                </li>
                <li class="listbullets">
                  <a
                    href="/investor-relations/reports/policy/Code%20of%20conduct%20for%20Directors%20and%20Senior%20Management.pdf"
                    class="heading-tab"
                    target="_blank"
                    >Code of conduct for Directors and Senior Management</a
                  >
                </li>
                <li class="listbullets">
                  <a
                    href="/investor-relations/reports/policy/Materiality%20Policy%20for%20identification%20of%20Material%20Creditors%20and%20for%20Litigation.pdf"
                    class="heading-tab"
                    target="_blank"
                    >Materiality Policy for identification of Material Creditors
                    and for Litigation</a
                  >
                </li>
                <li class="listbullets">
                  <a
                    href="/investor-relations/reports/policy/Policy%20for%20determining%20Material%20Group%20Companies.pdf"
                    class="heading-tab"
                    target="_blank"
                    >Policy for determining Material Group Companies</a
                  >
                </li>
                <li class="listbullets">
                  <a
                    href="/investor-relations/reports/policy/Policy%20for%20determining%20Material%20Subsidiary.pdf"
                    class="heading-tab"
                    target="_blank"
                    >Policy for determining Material Subsidiary</a
                  >
                </li>
                <li class="listbullets">
                  <a
                    href="/investor-relations/reports/policy/Policy%20on%20dealing%20with%20Related%20Party%20Transactions.pdf"
                    class="heading-tab"
                    target="_blank"
                    >Policy on dealing with Related Party Transactions</a
                  >
                </li>
                <li class="listbullets">
                  <a
                    href="/investor-relations/reports/policy/Terms%20and%20Conditions%20of%20appointment%20of%20Independent%20Directors.pdf"
                    class="heading-tab"
                    target="_blank"
                    >Terms and Conditions of appointment of Independent
                    Directors</a
                  >
                </li>
              </ul>
            </div>
            <div id="tab6" class="tab tab6">
              <!-- <div id="individual_wrapper" class="dataTables_wrapper no-footer">
                <div class="dt-buttons">
                  <button
                    class="dt-button buttons-copy buttons-html5"
                    tabindex="0"
                    aria-controls="individual"
                    type="button"
                  >
                    <span>Copy</span>
                  </button>
                  <button
                    class="dt-button buttons-excel buttons-html5"
                    tabindex="0"
                    aria-controls="individual"
                    type="button"
                  >
                    <span>Excel</span>
                  </button>
                  <button
                    class="dt-button buttons-csv buttons-html5"
                    tabindex="0"
                    aria-controls="individual"
                    type="button"
                  >
                    <span>CSV</span>
                  </button>
                  <button
                    class="dt-button buttons-pdf buttons-html5"
                    tabindex="0"
                    aria-controls="individual"
                    type="button"
                  >
                    <span>PDF</span>
                  </button>
                </div>
                <div id="individual_filter" class="dataTables_filter">
                  <label
                    >Search:<input
                      type="search"
                      class=""
                      placeholder=""
                      aria-controls="individual"
                  /></label>
                </div>
                <div class="dataTables_scroll">
                  <div
                    class="dataTables_scrollHead"
                    style="
                      overflow: hidden;
                      position: relative;
                      border: 0px;
                      width: 100%;
                    "
                  >
                    <div
                      class="dataTables_scrollHeadInner"
                      style="
                        box-sizing: content-box;
                        width: 937.379px;
                        padding-right: 14px;
                      "
                    >
                      <table
                        class="display dataTable no-footer "
                        style="width: 937.379px; margin-left: 0px"
                      >
                        <thead>
                          <tr>
                            <th
                              style="min-width: 120px; width: 120px"
                              class="sorting sorting_asc"
                              tabindex="0"
                              aria-controls="individual"
                              rowspan="1"
                              colspan="1"
                              aria-label="Name   : activate to sort column descending"
                              aria-sort="ascending"
                            >
                              Name
                            </th>
                            <th
                              style="min-width: 120px; width: 120px"
                              class="sorting"
                              tabindex="0"
                              aria-controls="individual"
                              rowspan="1"
                              colspan="1"
                              aria-label="Designation: activate to sort column ascending"
                            >
                              Designation
                            </th>
                            <th
                              class="sorting"
                              tabindex="0"
                              aria-controls="individual"
                              rowspan="1"
                              colspan="1"
                              style="width: 58.1034px"
                              aria-label="Audit Committee: activate to sort column ascending"
                            >
                              Audit Committee
                            </th>
                            <th
                              class="sorting"
                              tabindex="0"
                              aria-controls="individual"
                              rowspan="1"
                              colspan="1"
                              style="width: 68.7241px"
                              aria-label="Risk Management Committee: activate to sort column ascending"
                            >
                              Risk Management Committee
                            </th>
                            <th
                              class="sorting"
                              tabindex="0"
                              aria-controls="individual"
                              rowspan="1"
                              colspan="1"
                              style="width: 75.5862px"
                              aria-label="Nomination &amp;amp; Remuneration Committee: activate to sort column ascending"
                            >
                              Nomination &amp; Remuneration Committee
                            </th>
                            <th
                              class="sorting"
                              tabindex="0"
                              aria-controls="individual"
                              rowspan="1"
                              colspan="1"
                              style="width: 73.1034px"
                              aria-label="Stakeholders’ Relationship Committee: activate to sort column ascending"
                            >
                              Stakeholders’ Relationship Committee
                            </th>
                            <th
                              class="sorting"
                              tabindex="0"
                              aria-controls="individual"
                              rowspan="1"
                              colspan="1"
                              style="width: 58.1034px"
                              aria-label="CSR Committee: activate to sort column ascending"
                            >
                              CSR Committee
                            </th>
                            <th
                              class="sorting"
                              tabindex="0"
                              aria-controls="individual"
                              rowspan="1"
                              colspan="1"
                              style="width: 58.1034px"
                              aria-label="IPO Committee: activate to sort column ascending"
                            >
                              IPO Committee
                            </th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </div>
                  <div
                    class="dataTables_scrollBody"
                    style="position: relative; overflow: auto; width: 100%"
                  >
                    <table
                      id="individual"
                      class="display dataTable no-footer"
                      style="width: 100%"
                      aria-describedby="individual_info"
                    >
                      <thead>
                        <tr style="height: 0px">
                          <th
                            style="
                              min-width: 120px;
                              width: 120px;
                              padding-top: 0px;
                              padding-bottom: 0px;
                              border-top-width: 0px;
                              border-bottom-width: 0px;
                              height: 0px;
                            "
                            class="sorting sorting_desc"
                            aria-controls="individual"
                            rowspan="1"
                            colspan="1"
                            aria-label="Name   : activate to sort column ascending"
                            aria-sort="descending"
                          >
                            <div
                              class="dataTables_sizing"
                              style="height: 0px; overflow: hidden"
                            >
                              Name
                            </div>
                          </th>
                          <th
                            style="
                              min-width: 120px;
                              width: 120px;
                              padding-top: 0px;
                              padding-bottom: 0px;
                              border-top-width: 0px;
                              border-bottom-width: 0px;
                              height: 0px;
                            "
                            class="sorting"
                            aria-controls="individual"
                            rowspan="1"
                            colspan="1"
                            aria-label="Designation: activate to sort column ascending"
                          >
                            <div
                              class="dataTables_sizing"
                              style="height: 0px; overflow: hidden"
                            >
                              Designation
                            </div>
                          </th>
                          <th
                            class="sorting"
                            aria-controls="individual"
                            rowspan="1"
                            colspan="1"
                            style="
                              width: 58.1034px;
                              padding-top: 0px;
                              padding-bottom: 0px;
                              border-top-width: 0px;
                              border-bottom-width: 0px;
                              height: 0px;
                            "
                            aria-label="Audit Committee: activate to sort column ascending"
                          >
                            <div
                              class="dataTables_sizing"
                              style="height: 0px; overflow: hidden"
                            >
                              Audit Committee
                            </div>
                          </th>
                          <th
                            class="sorting"
                            aria-controls="individual"
                            rowspan="1"
                            colspan="1"
                            style="
                              width: 68.7241px;
                              padding-top: 0px;
                              padding-bottom: 0px;
                              border-top-width: 0px;
                              border-bottom-width: 0px;
                              height: 0px;
                            "
                            aria-label="Risk Management Committee: activate to sort column ascending"
                          >
                            <div
                              class="dataTables_sizing"
                              style="height: 0px; overflow: hidden"
                            >
                              Risk Management Committee
                            </div>
                          </th>
                          <th
                            class="sorting"
                            aria-controls="individual"
                            rowspan="1"
                            colspan="1"
                            style="
                              width: 75.5862px;
                              padding-top: 0px;
                              padding-bottom: 0px;
                              border-top-width: 0px;
                              border-bottom-width: 0px;
                              height: 0px;
                            "
                            aria-label="Nomination &amp;amp; Remuneration Committee: activate to sort column ascending"
                          >
                            <div
                              class="dataTables_sizing"
                              style="height: 0px; overflow: hidden"
                            >
                              Nomination &amp; Remuneration Committee
                            </div>
                          </th>
                          <th
                            class="sorting"
                            aria-controls="individual"
                            rowspan="1"
                            colspan="1"
                            style="
                              width: 73.1034px;
                              padding-top: 0px;
                              padding-bottom: 0px;
                              border-top-width: 0px;
                              border-bottom-width: 0px;
                              height: 0px;
                            "
                            aria-label="Stakeholders’ Relationship Committee: activate to sort column ascending"
                          >
                            <div
                              class="dataTables_sizing"
                              style="height: 0px; overflow: hidden"
                            >
                              Stakeholders’ Relationship Committee
                            </div>
                          </th>
                          <th
                            class="sorting"
                            aria-controls="individual"
                            rowspan="1"
                            colspan="1"
                            style="
                              width: 58.1034px;
                              padding-top: 0px;
                              padding-bottom: 0px;
                              border-top-width: 0px;
                              border-bottom-width: 0px;
                              height: 0px;
                            "
                            aria-label="CSR Committee: activate to sort column ascending"
                          >
                            <div
                              class="dataTables_sizing"
                              style="height: 0px; overflow: hidden"
                            >
                              CSR Committee
                            </div>
                          </th>
                          <th
                            class="sorting"
                            aria-controls="individual"
                            rowspan="1"
                            colspan="1"
                            style="
                              width: 58.1034px;
                              padding-top: 0px;
                              padding-bottom: 0px;
                              border-top-width: 0px;
                              border-bottom-width: 0px;
                              height: 0px;
                            "
                            aria-label="IPO Committee: activate to sort column ascending"
                          >
                            <div
                              class="dataTables_sizing"
                              style="height: 0px; overflow: hidden"
                            >
                              IPO Committee
                            </div>
                          </th>
                        </tr>
                      </thead>
  
                      <tbody>
                        <tr class="odd">
                          <td
                            style="min-width: 120px; width: 100%"
                            class="sorting_1"
                          >
                            Dr. Anil Dhawan
                          </td>
                          <td style="min-width: 120px; width: 100%">
                            Independent Director
                          </td>
                          <td><img src="/assets/images/iconA.png" /></td>
                          <td>–</td>
                          <td><img src="/assets/images/iconA.png" /></td>
                          <td><img src="/assets/images/iconA.png" /></td>
                          <td><img src="/assets/images/chairmenB.png" /></td>
                          <td>–</td>
                        </tr>
                        <tr class="even">
                          <td
                            style="min-width: 120px; width: 100%"
                            class="sorting_1"
                          >
                            Dr. Dinesh Kumar Chirla
                          </td>
                          <td style="min-width: 120px; width: 100%">
                            Whole-Time Director
                          </td>
                          <td>–</td>
                          <td>–</td>
                          <td>–</td>
                          <td>–</td>
                          <td><img src="/assets/images/iconA.png" /></td>
                          <td><img src="/assets/images/iconA.png" /></td>
                        </tr>
                        <tr class="odd">
                          <td
                            style="min-width: 120px; width: 100%"
                            class="sorting_1"
                          >
                            Dr. Ramesh Kancharla
                          </td>
                          <td style="min-width: 120px; width: 100%">
                            Chairman &amp; Managing Director
                          </td>
                          <td>–</td>
                          <td><img src="/assets/images/iconA.png" /></td>
                          <td>–</td>
                          <td><img src="/assets/images/iconA.png" /></td>
                          <td><img src="/assets/images/iconA.png" /></td>
                          <td><img src="/assets/images/chairmenB.png" /></td>
                        </tr>
                        <tr class="even">
                          <td
                            style="min-width: 120px; width: 100%"
                            class="sorting_1"
                          >
                            Mr. Aluri Srinivasa Rao
                          </td>
                          <td style="min-width: 120px; width: 100%">
                            Independent Director
                          </td>
                          <td><img src="/assets/images/iconA.png" /></td>
                          <td><img src="/assets/images/iconA.png" /></td>
                          <td><img src="/assets/images/chairmenB.png" /></td>
                          <td><img src="/assets/images/iconA.png" /></td>
                          <td>–</td>
                          <td><img src="/assets/images/iconA.png" /></td>
                        </tr>
                        <tr class="odd">
                          <td
                            style="min-width: 120px; width: 100%"
                            class="sorting_1"
                          >
                            Mr. Mahesh Madduri
                          </td>
                          <td style="min-width: 120px; width: 100%">
                            Head of Strategy
                          </td>
                          <td>–</td>
                          <td><img src="/assets/images/iconA.png" /></td>
                          <td>–</td>
                          <td>–</td>
                          <td>–</td>
                          <td>–</td>
                        </tr>
                        <tr class="even">
                          <td
                            style="min-width: 120px; width: 100%"
                            class="sorting_1"
                          >
                            Mr. R. Gowrisankar
                          </td>
                          <td style="min-width: 120px; width: 100%">
                            Chief Financial Officer
                          </td>
                          <td>–</td>
                          <td><img src="/assets/images/iconA.png" /></td>
                          <td>–</td>
                          <td>–</td>
                          <td>–</td>
                          <td>–</td>
                        </tr>
                        <tr class="odd">
                          <td
                            style="min-width: 120px; width: 100%"
                            class="sorting_1"
                          >
                            Mr. Santanu Mukherjee
                          </td>
                          <td style="min-width: 120px; width: 100%">
                            Independent Director
                          </td>
                          <td><img src="/assets/images/chairmenB.png" /></td>
                          <td><img src="/assets/images/chairmenB.png" /></td>
                          <td><img src="/assets/images/iconA.png" /></td>
                          <td>–</td>
                          <td><img src="/assets/images/iconA.png" /></td>
                          <td>–</td>
                        </tr>
                        <tr class="even">
                          <td
                            style="min-width: 120px; width: 100%"
                            class="sorting_1"
                          >
                            Ms. Sundari Raviprasad Pisupati
                          </td>
                          <td style="min-width: 120px; width: 100%">
                            Independent Director
                          </td>
                          <td><img src="/assets/images/iconA.png" /></td>
                          <td>–</td>
                          <td><img src="/assets/images/iconA.png" /></td>
                          <td><img src="/assets/images/iconA.png" /></td>
                          <td><img src="/assets/images/iconA.png" /></td>
                          <td>–</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  class="dataTables_info"
                  id="individual_info"
                  role="status"
                  aria-live="polite"
                >
                  Showing 1 to 8 of 8 entries
                </div>
                <div
                  class="dataTables_paginate paging_simple_numbers"
                  id="individual_paginate"
                >
                  <a
                    class="paginate_button previous disabled"
                    aria-controls="individual"
                    data-dt-idx="0"
                    tabindex="-1"
                    id="individual_previous"
                    >Previous</a
                  ><span
                    ><a
                      class="paginate_button current"
                      aria-controls="individual"
                      data-dt-idx="1"
                      tabindex="0"
                      >1</a
                    ></span
                  ><a
                    class="paginate_button next disabled"
                    aria-controls="individual"
                    data-dt-idx="2"
                    tabindex="-1"
                    id="individual_next"
                    >Next</a
                  >
                </div>
              </div> -->
              <ul style="text-align:center;display: block;
      list-style-type: disc;">
   <li class="listbullets">
    <a href="/investor-relations/reports/Committee_members.pdf" class="heading-tab" target="_blank">Constitution of Board &amp; Board Committees</a></li>
    </ul>
            </div>
  
            <div id="tab7" class="tab tab7">
              <p class="heading-tab">
                As on Sept 30, 2021, the Company does not have any material
                creditors
              </p>
            </div>
            <div id="tab8" class="tab tab8" >
              <div class="contact " style="margin-right: 20px;">
                <h2 style="font-size: 15px; font-weight: 600">
                  For Investor Grievances
                </h2>
                <h2 style="font-size: 14px; font-weight: 600">Ashish Kapil</h2>
                <p style="font-size: 12px">
                  Company Secretary &amp; Compliance Officer
                </p>
                <p style="font-size: 12px">Rainbow Children’s Medicare Limited</p>
                <p style="font-size: 12px">
                  8-2-19/1/A, Daulet Arcade, Road No. 11,
                </p>
                <p style="font-size: 12px">
                  Banjara Hills, Hyderabad – 500 034, Telangana
                </p>
                <p style="font-size: 12px">
                  <strong>Tel: +91 40 49692244</strong>
                </p>
                <p style="font-size: 12px">
                  <strong>Email:</strong> companysecretary@rainbowhospitals.in
                </p>
              </div>
              <div class="contact ">
                <h2 style="font-size: 15px; font-weight: 600">
                  Registrar &amp; Share Transfer Agent
                </h2>
                <h2 style="font-size: 14px; font-weight: 600">
                  KFin Technologies Private Limited
                </h2>
                <p style="font-size: 12px">Selenium, Tower-B</p>
                <p style="font-size: 12px">Plot 31 and 32, Financial District</p>
                <p style="font-size: 12px">Nanakramguda, Serilingampally</p>
                <p style="font-size: 12px">Hyderabad, Rangareddi 500 032</p>
                <p style="font-size: 12px">Telangana, India</p>
                <p style="font-size: 12px">Telephone: +91 40 6716 2222</p>
                <p style="font-size: 12px">E-mail: rcml.ipo@kfintech.com</p>
                <p style="font-size: 12px">
                  <strong>Website:</strong> www.kfintech.com
                </p>
                <p style="font-size: 12px">
                  <strong>Investor Grievance ID: </strong>einward.ris@kfintech.com
                </p>
                <p style="font-size: 12px">
                  <strong>Contact Person:</strong> M Murali Krishna
                </p>
  
                <p style="font-size: 12px">
                  <strong>Tel: +91 40 49692244</strong>
                </p>
                <!--<p style="font-size:12px;"><strong>Email:</strong> companysecretary@rainbowhospitals.com</p>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="member-info-popup">
      <div class="pop-up-container">
        <div>
          <img src="" id="member-image" class="member-image" />
        </div>
        <div class="member-info">
          <p id="member-name" style="font-size: 14px; font-weight: bold"></p>
          <p id="member-post" style="margin-top: 10px; font-size: 14px"></p>
          <p
            id="member-description"
            style="margin-top: 10px; font-size: 14px"
          ></p>
        </div>
        <a id="close" class="close" href="javascript:void()">✖</a>
      </div>
    </div>
  </section>
  <footer class="footerNavwrp">
    <footer-nav></footer-nav>
  </footer>
  <footer-copyright-nav></footer-copyright-nav>
</div>
