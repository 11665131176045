<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>
    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>

    <section class="page pageheader">
        <div class="container">
            <div class="col-xs-6 col-sm-6 col-lg-6 abannimgwrp">
                <div class="otherbannimg"><img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" /></div>
            </div>
            <div class="col-xs-6 col-sm-6 col-lg-6">
                <div class="ptitle">
                    <h1>{{title}}</h1>
                    <h3>{{subtitle}}</h3>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-lg-6 mcont100">
                <p [innerHTML]="description"></p>
            </div>

        </div>
    </section>
    <section class="aboutsectin2 awardswapper">
        <div class="container">
            <div class="row nsg_text">
                <div class="col-xs-12 col-lg-12">
                    <h2>Settling in at home</h2>
                    <p>Your baby needs to settle down to the environs of home and you need to settle down to be able to take care of his or her needs in the best possible manner. Certainly, there is bound to be an element of anticipation, anxiety and stress.
                        However, with the right kind of support it is easy to settle in to a comfortable rhythm at home with your baby. Make sure that the environs are safe and comfortable for the child. They ought to be encouraging his growth and ensuring
                        his safety at all times.</p>
                    <p><strong>a. Dealing with visitors </strong></p>
                    <p>With a new member of the baby added in, visitors are inevitable. Well-wishers, family and friends. There are social niceties that cannot be ignored while the health of the child remains the most important factor in all of this. Giving
                        access to your visitors is important but always consider the fact your baby shouldn’t be exposed to too much of external infections and must have sufficient time to rest.</p>
                    <p><strong>b. Feeding your child</strong></p>
                    <p>Prior to having left the hospitals one ought to take a proper download on the feeding requirements of the child. Based on his needs and prescription of the doctor provide the baby with adequate feeds. Take time and do not be in a rush.
                        This time also helps you bond with the child.</p>
                    <p><strong>c. Follow up appointments </strong></p>
                    <p>Settling in to your home with your baby should not diminish the importance for follow up visits and regular doctor interface. This aids the proper growth of the child and keeps him or her away from relapse of any condition.</p>
                    <p><strong>d. Monitoring child’s development </strong></p>
                    <p>Understand from your doctor the growth patterns that you must be evidencing in your child. Ensure that you are able to identify them and report and possible challenges. Give your child the space to grow, and help him attain the normal
                        benchmarks with necessary help from the doctors.</p>
                </div>
            </div>
            <div class="row nsg_text">
                <div class="col-xs-12 col-lg-12">
                    <h2>Safe Sleeping</h2>
                    <p>In the initial days after returning from the hospital, your child might find it difficult to sleep given that he or she needs time to adjust to the new surroundings. Help your child sleep better and adequate by ensuring that they get
                        a nice cosy place with no disturbance and plenty of love from you. Cuddle or massage your child gently to lull him or her into sleep.</p>
                    <p>Babies will soon find themselves a regular sleep-wake cycle. You will have to alter your lifestyle in accordance to this such that the child is able to have your complete attention while he or she is awake. You can have your baby sleep
                        next to you. This promotes a sense of togetherness and helps build a maternal bond.</p>
                    <p>In terms of sleeping positions, make sure your child is lying on its back at the beginning of his or her sleep. Babies will have a tendency to roll over onto their tummy or side, it would be good to roll them back again.</p>
                </div>
            </div>
            <div class="row nsg_text">
                <div class="col-xs-12 col-lg-12">
                    <h2>Mulitple Babes</h2>
                    <p>Dealing with multiple babies is always a handful and will require an additional hand from your spouse. Both children have their specific requirements – nutritional, emotional and growth. They may or may not have the same sleep cycles
                        and parents ought to be able to deal with this scenario.</p>
                    <p>For preterm multiple births, one of the child might be well while the other face critical health condition. This presents a difficult emotional paradigm for the parents.</p>
                    <p>Irrespective of being twins or not, each child is unique and requires attention that is unique to him or her. The best way for parents to deal with them is not to think of them to be part of a set.</p>
                </div>
            </div>
            <div class="row nsg_text">
                <div class="col-xs-12 col-lg-12">
                    <h2>If your baby is unwell</h2>
                    <p>Even after leaving the hospital, babies will have itsy-bitsy health conditions to deal with.</p>
                    <p>These could be small infections, fever or cold; or something more serious. Parents ought to be prepared to deal with these conditions and respond accordingly.</p>
                    <p>The first important thing is understand the signs and symptoms of their health. And then develop a response mechanism accordingly. Some of the common symptoms include</p>
                    <ul>
                        <li>Excessive crying</li>
                        <li>Temperature changes</li>
                        <li>Lack of appetite</li>
                        <li>Vomiting</li>
                        <li>Change in stools</li>
                        <li>Rapid breathing</li>
                        <li>Skin changes</li>
                    </ul>
                    <p>The response mechanism to health conditions includes first aid, emergency care and taking them to the paediatrician.</p>
                </div>
            </div>
            <div class="row nsg_text">
                <div class="col-xs-12 col-lg-12">
                    <h2>Common winter Illness</h2>
                    <p>Children are affected by cough, cold and infections almost all year round. However, the chances of infection are considerably higher in the winter months. Parents ought to ensure that the children are all bundled up and kept warm.
                        Other than the warmth, it is important to ensure hygiene and make sure they stay away from infections.</p>
                    <ul>
                        <li>Winter illnesses can be prevented by:</li>
                        <li>Adequate warm clothes</li>
                        <li>Warm surroundings</li>
                        <li>Using disposable tissues &amp; wipes</li>
                        <li>Clean toys</li>
                    </ul>
                    <p>Make sure you have emergency medicine for common cold for your child and understand how to administer it. Keep contact details of the doctor handy in case of emergencies.</p>
                </div>
            </div>
            <div class="row nsg_text">
                <div class="col-xs-12 col-lg-12">
                    <h2>Weaning</h2>
                    <p>Irrespective of how prematurely a baby is born, his or her age is calculated according to the date of birth; and over a period of time they ought to be weaned off mother’s milk and allowed to consume solid food. This must be a gradual
                        process, and in case of premature babies the process might take slightly longer than normal.</p>
                    <p><strong>Start Weaning</strong></p>
                    <ul>
                        <li>When the baby starts sitting by itself</li>
                        <li>Holds the head stable</li>
                        <li>Able to handle new food</li>
                        <li>Showing interest in your food</li>
                        <li>Bringing hands to its mouth</li>
                    </ul>
                    <p><strong>First Foods </strong></p>
                    <p>The first to first try for the baby, include those that mash quickly in the mouth.</p>
                    <ul>
                        <li>Ripe, poached or peeled soft fruits like banana or peach</li>
                        <li>Cooked soft vegetables</li>
                        <li>Cooked soft rice</li>
                    </ul>
                    <p><strong>Important Points to Note</strong></p>
                    <ul>
                        <li>Never force feed the child</li>
                        <li>Do not add solid food to the bottle</li>
                        <li>Do not add sugars to baby food</li>
                        <li>Prefer home cooked food</li>
                        <li>Do not give children whole nuts</li>
                        <li>Do not add chilli</li>
                        <li>Do not add salt </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>



    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>