import { Subscription } from 'rxjs/Rx';
import { Input, Inject, Component, OnDestroy, OnInit, AfterViewInit, PLATFORM_ID, ViewChildren, QueryList,OnChanges } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from "../services/api.service";
import { IMG_BASE_URL } from "../services/constants";
import { Subject } from 'rxjs/Subject';
declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
declare let initAboutTabs: any;
declare let initGoogleMap: any;
declare let initializeMap: any;
@Component({
	selector: 'centres-map',
	templateUrl: './centres-map.component.html',
	styles:[`
	.gmapwrp .sidelocdetwrp .gmheader .box{
		width:100% !important;
	}`]
})

export class CentresMapComponent implements OnDestroy, OnInit, AfterViewInit {

	@Input() centerList: any;
	@Input() centerId:Subject<any>;;
	public imgBasePath = "";
	public hidden = "hidden";
	public logo = "logo";

	public centreName: string='';
	public centreAliasName: string='';
	public centreAddress: string='';
	public centreTelephone: string='';
	public centreImage: string='';
	public centreEmail: string='';
	public centreLatLng: string='';
	public virtual_tour_link: any='';
	public counter = 0;
	public selectCenter: any = '0';
	public centerDropDown: any;
	public centre_name:string;
	public bread_crumb_links: any = [{ "label": "Home", "link": "/", "class": "" },
	{ "label": "Others", "link": "", "class": "" },
	{ "label": "Contact us", "link": "", "class": "" }];

	constructor(private api: ApiService) {
		if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
      //	console.log(this.centerList);
    }

	}
	@ViewChildren('centerMap') things: QueryList<any>;

	ngOnInit() {
		this.imgBasePath = IMG_BASE_URL;
		this.filterCenter('default');
		this.centerId.subscribe(name =>{
			this.centre_name = name;
			if(this.centre_name){
				let cityslug;
				this.api.getCentreCities()
				.subscribe(data =>{
					
					this.centerDropDown = data.filter(res =>{
						//console.log(this.centre_name, res.city);
						//console.log(res.citySlug);
						return this.centre_name == res.city;
					});
					this.filterCenter(this.centerDropDown[0].area[0].slug)
				});
				//console.log()
				//this.filterCenter(this.centerDropDown);
				//console.log(this.centre_name);
			}
		});
		this.getCentreCities();
	}
	getCentreCities(){
		this.api.getCentreCities().subscribe(res =>{
			//console.log(res);
			this.centerDropDown = res;
			//console.log('list of centre and city',res);
		});
	}
	
	filterCenter(event) {
		
		if(event == 'default'){
			this.initMapLoad();
		}else{
			let targetValue;
			if(event.target){
				targetValue =  event.target.value;
			}else{
				targetValue = event;
			}
			this.centerDropDown.find((data)=>{
				//console.log('Filter Senter', data);
				let filterCenter =   data.area.filter(t =>{
					return t.slug.toLowerCase() == targetValue.toLowerCase();
					//console.log(t.slug, event.target.value);
				}).map(res =>{
					return res;
				});
				setTimeout(()=>{
					//console.log(filterCenter[0]);
					this.selectedCenter(filterCenter[0], 0);
				},500);	
				
			});
		}
	}
	
	initMapLoad(){
		this.api.getCenters()
		.first()
		.subscribe((res) => {
			//console.log('Map', res)
			let center = this.api.getFilteredCenterData(res.centers[0]);
			this.selectedCenter(center,1);
		}, err => {
			console.log(err);
		});
	}
	selectedCenter(data, show) {
		//console.log(data);
		if (data) {
			var newData = data;
			this.selectCenter = newData.center_name_alias;
			this.centreName = newData.center_name;
			this.centreAliasName = newData.center_name_alias;
			this.centreAddress = newData.address;
			this.centreTelephone = newData.telephone;
			this.centreImage = newData.image;
			this.centreEmail = newData.email;
			this.centreLatLng = newData.lat_long;
			this.virtual_tour_link =  newData.virtual_tour_link;
			//console.log(newData);
			let user_lat_long_info = newData.lat_long.split(',');

			// let maplist = data.lat_long;
			// let centerLatLngList:any = data.map((res,key) =>{
			// 	console.log(res,key);

			// 	return {
			// 		"title":res.center_name_alias,
			// 		"latlong":(res.lat_long)
			// 	}

			// });
			// console.log(centerLatLngList);
			//this.virtual_tour_link = data[0].virtual_tour_link;
			//let user_lat_long_info = this.centreLatLng.split(',');
			//initGoogleMap(user_lat_long_info[0],user_lat_long_info[1]);
			initializeMap('google-container', user_lat_long_info[0], user_lat_long_info[1], show);
		}
		//console.log(data);
		//console.log(data.center_name);

	}
	ngAfterViewInit() {

		if (this.api.isBrowser && window['jQuery']) {
			
		}
		this.things.changes.subscribe(t => {
			if (this.counter == 0) {
				setTimeout(() => {
					//console.log(this.centerList);
					this.selectedCenter(this.centerList[0], 1);
					//console.log(this.centerList[0]);
				}, 100);
			}
			this.counter++;

		});

	}

	ngOnDestroy() { }

}
