import {Inject, Component, OnDestroy, OnInit, AfterViewInit, PLATFORM_ID, ViewChildren, QueryList } from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {ApiService} from "../services/api.service";
import {IMG_BASE_URL} from "../services/constants";
import { Meta, Title } from '@angular/platform-browser';
declare let $:any;
declare let initHome:any;
declare let initAllPage:any;

declare let initAboutTabs:any;

@Component({
  selector: 'health-card',
  templateUrl: './health-card.component.html'
})

export class OtherHealthCardComponent implements OnDestroy, OnInit, AfterViewInit {

  constructor(private api:ApiService,  public metaTitle: Title, public meta: Meta) {
    if(this.api.isBrowser){
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
    }
  }

  public title:any = "";
  public subtitle:any = "";
  public description:any = "";
  public imgsrc:any = "";
  public imgBasePath = "";
  public hidden = "hidden";
  public logo="logo";
  public bread_crumb_links:any = [{"label":"Home", "link": "/", "class": ""},
                                 {"label":"Others", "link": "", "class": ""},
                                 {"label":"Health Card", "link": "", "class": ""}];

  public links:any = [{"label":"Faqs", "link": "/other/faqs", "class": ""},
                    {"label":"Insurance & Empanelment", "link": "/other/insurance-empanelment", "class": ""},
                    {"label":"Terms and Conditions", "link": "/other/terms-conditions", "class": ""},
                    {"label":"Academic Programs", "link":"/other/academic-programs", "class":""},
                    {"label":"Inpatient Feedback", "link": "http://103.231.214.196/~feedback/", "class": "", openNewTab: true},
                    {"label":"Quiz", "link": "/other/quiz", "class": ""},
                    {"label":"Packages/ Plans", "link": "/other/packages-plans", "class": "active"},
                    {"label":"International Patients", "link": "/other/international-patients", "class": ""},
                    {"label":"App", "link": "/other/app", "class": ""}];
                    
  @ViewChildren('health-card') things: QueryList<any>;

  ngOnInit() {
    this.api.getPageDescription('health-card')
      .subscribe(
        data =>{
          this.title = data.page_descriptions[0].title,
          this.subtitle = data.page_descriptions[0].subtitle,
          this.description = data.page_descriptions[0].description,
          this.imgsrc = data.page_descriptions[0].image,
          this.imgBasePath = IMG_BASE_URL;
          if(data){
            //console.log(data);
            this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
            this.meta.updateTag({name:'keywords',content: data.page_descriptions[0].meta_keyword});
            this.meta.updateTag({name:'description',content: data.page_descriptions[0].meta_description});
            this.meta.addTags([
              { property: 'og:title', content:  data.page_descriptions[0].meta_title},
              { property: 'og:description', content: data.page_descriptions[0].meta_description },
              { property: 'og:keywords', content: data.page_descriptions[0].meta_keyword }
            ]);
          }
        },
        error => {
          console.log(error);
        }
      )

  }

  ngAfterViewInit() {

    if ( this.api.isBrowser && window['jQuery'] ) {
      initHome();
      initAllPage();
      initAboutTabs();
    }

    this.things.changes.subscribe(t => {
      // this.ngForRendred();
      initAboutTabs();
    })
  }

  ngOnDestroy() {}

}
