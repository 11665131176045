import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL } from '../services/constants';
import { Meta, Title } from '@angular/platform-browser';

declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
declare let initAboutTabs: any;

@Component({
  selector: 'sitemap',
  templateUrl: './sitemap.component.html',
})
export class SiteMapComponent implements OnDestroy, OnInit, AfterViewInit {
  constructor(
    private api: ApiService,
    public metaTitle: Title,
    public meta: Meta
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
    }
  }

  public sideBarList: Array<any[]>;
  public fdisShow = false;
  public services: any[] = [];
  public brief_facilities: any[] = [];
  public title: any = '';
  public subtitle: any = '';
  public description: any = '';
  public imgsrc: any = '';
  public imgBasePath = '';
  public hidden = 'hidden';
  public logo = 'logo';
  public childSpec: any = [];
  public womenSpec: any = [];
  public fertilitySpec: any = [];
  public bread_crumb_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Sitemap', link: '', class: '' },
  ];

  public links: any = [];
  public activePage: string = 'sitemap';

  @ViewChildren('terms-conditions') things: QueryList<any>;

  ngOnInit() {
    /*
    this.api.getSideBarlist().map(res => res.json()).subscribe(data =>{
      console.log('getSideBarlist', data);
			this.sideBarList = data;
    })
    */

    this.api.getPageDescription('sitemap').subscribe(
      (data) => {
        (this.title = data.page_descriptions[0].title),
          (this.subtitle = data.page_descriptions[0].subtitle),
          (this.description = data.page_descriptions[0].description),
          (this.imgsrc = data.page_descriptions[0].image),
          (this.imgBasePath = IMG_BASE_URL);
        if (data) {
          //console.log(data);
          this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
          this.meta.updateTag({
            name: 'keywords',
            content: data.page_descriptions[0].meta_keyword,
          });
          this.meta.updateTag({
            name: 'description',
            content: data.page_descriptions[0].meta_description,
          });
          this.meta.addTags([
            {
              property: 'og:title',
              content: data.page_descriptions[0].meta_title,
            },
            {
              property: 'og:description',
              content: data.page_descriptions[0].meta_description,
            },
            {
              property: 'og:keywords',
              content: data.page_descriptions[0].meta_keyword,
            },
          ]);
        }
      },
      (error) => {
        console.log(error);
      }
    );

    this.api.getSpecialityFeatures('child-care').subscribe((res) => {
      //console.log(res);
      this.childSpec = res;
    });
    this.api.getSpecialityFeatures('women-care').subscribe((res) => {
      //console.log(res);
      this.womenSpec = res;
    });
    this.api.getSpecialityFeatures('fertility-care').subscribe((res) => {
      //console.log(res);
      this.fertilitySpec = res;
    });
    this.api
      .getOtherSubFooterDetails()
      .map((res) => res)
      .subscribe((data) => {
        this.links = data;
      });
  }

  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
    }

    // this.things.changes.subscribe(t => {
    //   // this.ngForRendred();
    //   initAboutTabs();
    // })
  }

  ngOnDestroy() {}
}
