import { ApiClick2Call } from './services/api.click2call';
import { ApiAppointment } from './services/api.appointment';
import { UserActions } from './user/user.actions';
import { UserService } from './user/user.service';
import {ApiService} from "./services/api.service";
import {
  DatePipe,
  APP_BASE_HREF,
  LocationStrategy,
  PathLocationStrategy,
  HashLocationStrategy,
} from '@angular/common';
import { TransferState } from 'src/modules/transfer-state/transfer-state';
import { getTransferState } from 'src/modules/transfer-state/browser-transfer-state.module';

export const APP_PROVIDERS = [
  ApiService,
  UserActions,
  UserService,
  ApiAppointment,
  DatePipe,
  ApiClick2Call, // { provide: APP_BASE_HREF, useValue: '/rh/' }
  {
    provide: TransferState,
    useFactory: getTransferState,
  },
  // { provide: LocationStrategy, useClass: PathLocationStrategy },
];
