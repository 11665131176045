import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TransferState } from '../modules/transfer-state/transfer-state';

import { views } from './app-nav-views';
import { MOBILE } from './services/constants';

@Component({
  selector: 'my-app',
  styleUrls: ['./app.component.css'],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  // showMonitor = (ENV === 'development' && !AOT &&
  //   ['monitor', 'both'].includes(STORE_DEV_TOOLS) // set in constants.js file in project root
  // );
  mobile = MOBILE;
  views = views;

  constructor(
    private cache: TransferState,
    public route: ActivatedRoute,
    public router: Router
  ) {}

  ngOnInit() {
    this.cache.set('cached', true);
  }

  activateEvent(event) {
    if (ENV === 'development') {
      //console.log('Activate Event:', event);
    }
  }

  deactivateEvent(event) {
    if (ENV === 'development') {
      // console.log('Deactivate Event', event);
    }
  }
}
