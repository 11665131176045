import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  ElementRef,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { isPlatformBrowser, Location } from '@angular/common';
import { ApiService } from '../services/api.service';
import { API_BASE_URL, IMG_BASE_URL } from '../services/constants';
import { Meta, Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {
  ReactiveFormsModule,
  FormsModule,
  FormControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';

declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
declare let initCustomSelect: any;
declare let changeShowStatus: any;
//declare let setSpecialitiesSlider:any;

@Component({
  selector: 'specialities',
  templateUrl: './specialities.component.html',
  styles: [
    `
      #ModelFormGetInTouch {
        display: none;
      }
      .capopup {
        display: none;
      }
      #ModelFormGetInTouch .capopup-inner {
        max-width: 600px;
        width: 90%;
        padding: 40px;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
        border-radius: 20px;
        background: #faf5ff;
      }
      #ModelFormGetInTouch .capopup-inner .launchpreloader {
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: 11;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        background: rgba(255, 255, 255, 0.6)
          url(../../assets/css/ajax-loader.gif) no-repeat center center;
      }
      #ModelFormGetInTouch h2 {
        width: 100%;
        text-transform: uppercase;
        color: #5f2160;
        font-weight: 700;
        font-family: 'dinrg';
        font-size: 30px;
        display: inline-block;
        margin: 0 0px 10px 0;
        position: relative;
        padding: 0px;
      }
      #ModelFormGetInTouch .formcasn {
        width: 100%;
        margin: 20px 0 0 0;
        padding: 0;
        position: relative;
        float: left;
      }
      #ModelFormGetInTouch .formcasn ul {
        margin: 0;
        padding: 0px;
      }
      #ModelFormGetInTouch .formcasn li {
        float: left;
        width: 100%;
        list-style: none;
        position: relative;
        margin-bottom: 10px;
      }
      #ModelFormGetInTouch .formcasn li:last-child {
        margin-bottom: 0px;
      }
      #ModelFormGetInTouch .formcasn li i {
        width: 25px;
        height: 25px;
        position: absolute;
        left: 32px;
        top: 39px;
      }
      #ModelFormGetInTouch .formcasn li .alertMsg {
        position: absolute;
        right: 12px;
        bottom: 14px;
        z-index: 1;
        color: #e51e2c;
        font-family: 'dinrg';
        font-size: 13px;
        font-weight: normal;
      }
      #ModelFormGetInTouch .formcasn li input {
        color: #545454;
        font-family: 'dinrg';
        font-size: 17px;
        width: 100%;
        float: left;
        background: #fff;
        border: 1px solid #ccc;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        padding: 10px 20px 10px 20px;
        box-sizing: border-box;
      }
      #ModelFormGetInTouch .formcasn li select {
        color: #545454;
        font-family: 'dinrg';
        font-size: 17px;
        width: 100%;
        float: left;
        background: #fff;
        border: 1px solid #ccc;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        padding: 10px 20px 10px 20px;
        box-sizing: border-box;
      }
      #ModelFormGetInTouch .formcasn li textarea {
        color: #545454;
        font-family: 'dinrg';
        font-size: 17px;
        width: 100%;
        float: left;
        background: #fff;
        border: 1px solid #ccc;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        padding: 10px 20px 10px 20px;
        box-sizing: border-box;
      }
      #ModelFormGetInTouch .formcasn li input:focus,
      #ModelFormGetInTouch .formcasn li textarea:focus,
      #ModelFormGetInTouch .formcasn li select:focus {
        outline: none;
      }
      #ModelFormGetInTouch .btnPrevca {
        color: #5f2160;
        font-family: 'dinrg';
        font-size: 14px;
        width: 100%;
        margin: 20px 10px 20px 0px;
        position: relative;
        display: inline-block;
        text-align: right;
      }
      #ModelFormGetInTouch .formcasn li .submitbtn {
        width: 100%;
        text-align: center;
        display: inline-block;
        float: left;
      }
      #ModelFormGetInTouch .formcasn li .submitbtn input {
        float: right;
        display: inline-block;
        width: auto;
        color: #fff;
        background: #3d2979;
        border: 1px solid #3d2979;
        padding: 0px 40px;
        margin: 2px 4px;
        height: 38px;
        text-transform: uppercase;
        font-family: 'dinrg';
        font-size: 18px;
        border-radius: 30px;
      }
      #ModelFormGetInTouch .formcasn li input:focus {
        outline: none;
      }
      span.error {
        position: absolute;
        right: 12px;
        bottom: 14px;
        z-index: 1;
        color: #e51e2c;
        font-family: 'dinrg';
        font-size: 13px;
        font-weight: normal;
      }
      .iconForm {
        bottom: 96px;
        position: fixed;
        right: 10px;
        z-index: 12;
        display: none !important;
      }
      .iconFormV {
        width: 30px;
        word-break: break-all;
        top: 100px;
        position: fixed;
        right: 0px;
        z-index: 12;
        display: none;
        background: #80368d;
        text-align: center;
        padding: 10px 10px;
        color: #fff;
        text-transform: uppercase;
        font-family: 'dinrg';
        font-size: 14px;
        line-height: 14px;
        text-transform: uppercase;
      }
      .iconFormV span {
        display: inline-block;
        margin-top: 6px;
      }
      @media (max-width: 767px) {
        .iconForm {
          bottom: 62px;
          position: fixed;
          right: 10px;
          z-index: 12;
        }
        .iconForm img {
          width: 50px;
        }
      }
      @media (max-width: 480px) {
        .iconForm {
          bottom: 112px;
          position: fixed;
          right: 6px;
          z-index: 12;
        }
        #ModelFormGetInTouch h2 {
          font-size: 18px;
        }
        #ModelFormGetInTouch .formcasn {
          margin: 10px 0 0 0;
        }
        #ModelFormGetInTouch .formcasn li input,
        #ModelFormGetInTouch .formcasn li select,
        #ModelFormGetInTouch .formcasn li textarea {
          font-size: 13px;
          padding: 6px 10px;
        }
        #ModelFormGetInTouch .formcasn li .submitbtn input {
          height: 30px;
          font-size: 18px;
        }
        #ModelFormGetInTouch .formcasn li .alertMsg {
          right: 5px;
          bottom: 9px;
          font-size: 11px;
        }
      }
      @media (max-width: 420px) {
        .iconForm {
          bottom: 90px;
          position: fixed;
          right: 6px;
          z-index: 12;
        }
        .iconForm img {
          width: 40px;
        }
      }
      @media (max-width: 420px) {
        .iconForm {
          bottom: 132px;
          position: fixed;
          right: 6px;
          z-index: 12;
        }
        .iconFormV {
          top: 80px;
          line-height: 12px;
        }
      }
    `,
  ],
})
export class FertilityCareSpecialitiesComponent
  implements OnDestroy, OnInit, AfterViewInit
{
  router: Router;
  GetInTouch: FormGroup;
  constructor(
    private api: ApiService,
    private location: Location,
    private formBuilder: FormBuilder,
    private _router: Router,
    private activatedRoute: ActivatedRoute,
    public metaTitle: Title,
    public meta: Meta
  ) {
    this.router = _router;
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
      require('../../assets/js/jquery.cookie.js');
    }
  }

  public features: any[] = [];
  public pageinfo: any[] = [];
  public title: any = '';
  public subtitle: any = '';
  public description: any = '';
  public imgsrc: any = '';
  public columns = -1;
  public sItems: any[] = [];
  public zone: any;
  public imgBasePath = '';
  public hidden = 'hidden';
  public logo = 'birth-right-logo';
  public bread_crumb_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Fertility Care', link: '/fertility-care/about', class: '' },
    { label: 'Our Treatments', link: '', class: '' },
  ];

  public links: any = [
    {
      label: 'About Fertility Care',
      link: '/fertility-care/about',
      class: '',
      slug: 'about',
    },
    { label: 'Doctors', link: '/all/doctors', class: '', slug: 'doctors' },
    {
      label: 'Our Treatments',
      link: '/fertility-care/treatments',
      class: 'active',
      slug: 'treatments',
    },
    {
      label: 'Our Centres',
      link: '/fertility-care/centres',
      class: '',
      slug: 'centres',
    },
    {
      label: 'Testimonials',
      link: '/fertility-care/testimonials',
      class: '',
      slug: 'testimonials',
    },
    {
      label: 'Share Feedback',
      link: '/fertility-care/feedback',
      class: '',
      slug: 'feedback',
    },
    {
      label: 'Book An Appointment',
      link: '/fertility-care/book-an-appointment',
      class: '',
      slug: 'book-an-appointment',
    },
  ];
  public activePage: string = 'treatments';
  public submited: boolean = false;
  public showHide: boolean = false;
  public preloader: boolean = false;
  public city: string;
  public countryModel: string = '';
  public locationModel: string = '';

  public reload(): any {
    return this.zone.runOutsideAngular(() => {
      location.reload();
    });
  }
  @ViewChildren('allTheseThings') things: QueryList<any>;

  // ngForRendred() {
  //
  // }
  public locationList: any = [
    { name: 'Hyderabad', code: 'Hyderabad' },
    { name: 'Bengaluru', code: 'Bengaluru' },
  ];
  public interestedList: any = [
    { name: 'Fertility treatment', code: 'Fertility treatment' },
    {
      name: 'Intrauterine insemination (IUI)',
      code: 'Intrauterine insemination (IUI)',
    },
    {
      name: 'In vitro fertilization (IVF)',
      code: 'In vitro fertilization (IVF)',
    },
    { name: 'Infertility screening', code: 'Infertility screening' },
    { name: 'Male infertility', code: 'Male infertility' },
    { name: 'Preconception counselling', code: 'Preconception counselling' },
  ];
  changeShowStatus() {
    this.showHide = !this.showHide;
    $('#ModelFormGetInTouch').hide();
    $('.iconForm').show();
    $('.iconFormV').show();
  }
  ShowForm() {
    this.showHide = true;
    $('.iconForm').hide();
    $('.iconFormV').hide();
    $('#ModelFormGetInTouch').show();
  }
  onSubmit() {
    //this.location.go('/fertility-care/treatments/thankyou');
    //this.reload();
    this.submited = true;
    if (!this.GetInTouch.valid) {
      return;
    }
    this.preloader = true;
    var self = this;

    if (this.GetInTouch.valid) {
      $('#ModelFormGetInTouch').hide();

      let formTemp: any = <any>document.querySelector('#form');
      let formData: FormData = new FormData(formTemp);
      //console.log('Form Post Data',formData);
      let xhr: XMLHttpRequest = this.buildForm(formData);
      ///console.log('XMLHttpRequest', xhr);
      xhr.onreadystatechange = () => {
        var obj = xhr;
        //console.log('obj', obj);
        if (obj.readyState == 4) {
          switch (this.city) {
            case 'bengaluru':
              window.open(
                this.city +
                  '/women-care/speciality/obstetrics-and-gynecology/thankyou',
                '_blank'
              );
              break;
            case 'hyderabad':
              window.open(
                this.city +
                  '/women-care/speciality/obstetrics-and-gynecology/thankyou',
                '_blank'
              );
              break;
            default:
              window.location.href = '/fertility-care/treatments/thankyou';
              //this.router.navigate(['/fertility-care/treatments/thankyou']);
              //this.reload();
              break;
          }
          $('#ModelFormGetInTouch').hide();
          self.showHide = false;
          self.preloader = false;
          this.GetInTouch.reset();
        }
        if (obj.status == 200) {
          //console.log(obj.status);
        }
      };
    }
  }

  public buildForm(formData) {
    let url: any =
      API_BASE_URL + 'home/fertility_Speciality_get_in_touch_with_us';
    let xhr: XMLHttpRequest = new XMLHttpRequest();
    xhr.open('POST', url, true);

    // IE bug fixes to clear cache
    xhr.setRequestHeader('Cache-Control', 'no-cache');
    xhr.setRequestHeader('Cache-Control', 'no-store');

    xhr.send(formData);
    return xhr;
  }

  ngOnInit() {
    this.api.getPageDescription('fertility-care-specialities').subscribe(
      (data) => {
        this.title = data.page_descriptions[0].title;
        this.subtitle = data.page_descriptions[0].subtitle;
        this.description = data.page_descriptions[0].description;
        this.imgsrc = data.page_descriptions[0].image;
        this.imgBasePath = IMG_BASE_URL;
        $.cookie('pTitle', this.title);
        $.cookie('pBannImg', this.imgsrc);
        if (data) {
          //console.log(data);
          this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
          this.meta.updateTag({
            name: 'keywords',
            content: data.page_descriptions[0].meta_keyword,
          });
          this.meta.updateTag({
            name: 'description',
            content: data.page_descriptions[0].meta_description,
          });
          this.meta.addTags([
            {
              property: 'og:title',
              content: data.page_descriptions[0].meta_title,
            },
            {
              property: 'og:description',
              content: data.page_descriptions[0].meta_description,
            },
            {
              property: 'og:keywords',
              content: data.page_descriptions[0].meta_keyword,
            },
          ]);
        }
      },
      (error) => {
        console.log(error);
      }
    );

    this.api.getSpecialityFeatures('fertility-care').subscribe(
      (data) => {
        this.features = data.our_speciality_features;
        if (
          Array.isArray(data.our_speciality_features) &&
          data.our_speciality_features.length > 0
        ) {
          for (let i = 0; i < data.our_speciality_features.length; i += 2) {
            let column = [];
            column.push(data.our_speciality_features[i]);
            if (data.our_speciality_features[i + 1]) {
              column.push(data.our_speciality_features[i + 1]);
            }
            this.sItems.push(column);
            column = [];
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );

    this.countryModel = '';
    this.locationModel = '';
    this.GetInTouch = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern('.*\\S.*')]],
      mobile: ['', Validators.required],
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            '[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}'
          ),
        ]),
      ],
      txtLocation: ['', [Validators.required]],
      interested_in: ['', [Validators.required]],
      message: [''],
      CityName: [''],
    });

    switch (this.city) {
      case 'bengaluru':
        setTimeout(() => {
          if ($.cookie('showThankYouPage') != 1) {
            this.showHide = true;
            $('#ModelFormGetInTouch').show();
          }
        }, 5000);
        break;
      case 'hyderabad':
        setTimeout(() => {
          if ($.cookie('showThankYouPage') != 1) {
            this.showHide = true;
            $('#ModelFormGetInTouch').show();
          }
        }, 5000);
        break;
      default:
        setTimeout(() => {
          if ($.cookie('showThankYouPage') != 1) {
            this.showHide = true;
            $('#ModelFormGetInTouch').show();
          }
        }, 5000);
        break;
    }
  }

  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
    }
    this.things.changes.subscribe((t) => {
      // this.ngForRendred();
      this.setSpecialitiesSlider();
    });
  }
  findComingSoon(data: string) {
    if (data) {
      let findmatching = data.toLowerCase().search('coming soon');
      //	console.log(findmatching);
      if (findmatching != -1) {
        return false;
      }
      return true;
    }
  }
  setSpecialitiesSlider() {
    var $body = $('body');
    //console.log("Specialities Slider Invoked");
    $body
      .find('.ourSpecialitiess')
      .removeClass('slick-slider')
      .removeClass('slick-initialized')
      .removeClass('slider');
    $body
      .find('.ourSpecialitiess')
      .not('.slick-initialized')
      .slick({
        slide: '.sitem',
        dots: true,
        arrows: false,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        slidesPerRow: 1,
        adaptiveHeight: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 680,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
  }
  ngOnDestroy() {}
}
