<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <section class="page abccare">
        <div class="row">
            <di>

                <div class="col-md-5 col-sm-5 col-lg-4 col-xs-5">
                    <div class="circuleframe">
                        <img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" />
                    </div>
                    <!-- End Circle -->
                </div>
                <div class="col-md-7 col-sm-7 col-lg-6 col-xs-7">
                    <div class="pagetitle">
                        <h1 class="textgradient">{{title}}</h1>
                        <div class="underline">
                            <img src="assets/images/underline.png" alt="" />
                        </div>
                        <h3>{{subtitle}}</h3>
                    </div>
                </div>
                <div class="col-md-7 col-xs-7 col-lg-8 mcontentview">
                    <p [innerHTML]="description"></p>
                </div>
            </di>
        </div>
    </section>
    <section class="aboutsectin2" >
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-lg-12">
                    <ul class="atabs">
                        <li #abouttabs *ngFor="let service of services; let first = first;" [ngClass]="{ first: 'active' }">
                            <a href="javascript:void()" data-id="{{service.id}}">
                                <div class="aiconwrp"><img src="{{imgBasePath}}{{service.image}}" alt="" /></div>
                                <h2>{{service.title}}</h2>
                            </a>
                        </li>
                    </ul>
                    <div class="atabscontent">
                        <div class="atabsdata" *ngFor="let service of services; let first = first;" [ngClass]="{ first: 'active' }">
                            <p [innerHTML]="service.description"> </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="careservices">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-lg-12">
                    <p class="p">We ensure that the patient are provided with the highest standards of care at every stage of their fertility treatment based on their clinical needs. We offer the following comprehensive medical care services as a part of our, out-patient
                        and in-patient departments:</p>
                    <ul class="csrlist">
                        <li *ngFor="let facility of brief_facilities">
                            <table cellpadding="0" cellspacing="0" border="0">
                                <tr>
                                    <td align="left" valign="top">
                                        <div class="csricon"><img src="{{imgBasePath}}{{facility.image}}" alt="" /></div>
                                    </td>
                                    <td align="left">{{facility.description}}</td>
                                </tr>
                            </table>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>