<div class="container-fluid">
    <div class="background">
        <img src="assets/images/childcarebg.png" data-t="bg_01_00.jpg" data-d="childcarebg.png" class="bgImg bg0 active" />
    </div>

    <div class="loder"></div>
    <sidenav></sidenav>
    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <section class="page wtwedo">
        <div class="row">
            <div>

                <div class="col-md-5 col-xs-5 col-lg-4">
                    <div class="circuleframe">
                        <img src="{{imgBasePath}}{{imgsrc}}"  class="img-responsive" alt="{{title}}" title="{{title}}" />
                    </div>
                    <!-- End Circle -->
                </div>
                <div class="col-md-7 col-xs-7 col-lg-7">
                    <div class="pagetitle">
                        <h1 class="textgradient">{{title}}</h1>
                        <div class="underline">
                            <img src="assets/images/underline.png" alt="" />
                        </div>
                        <h3>{{subtitle}}</h3>
                    </div>
                </div>
                <div class="col-md-7 col-xs-7 col-lg-7 mcontentview">
                    <p>{{description}}
                    </p>
                    
                </div>
            </div>
        </div>
    </section>
    <section class="vimmunitywrp">
        <div class="row">
            <div class="container">
                <div class="col-md-12 col-xs-12 col-lg-12">
                    <div class="header">
                        <h3>Vaccinate for better immunity</h3>
                        <p>Use this immunization scheduler to know the vaccinations to be given to your baby at the right time.</p>
                    </div>
                    <div class="vimform">
                        <form id="form" [formGroup]="applyVaccinateFormGroup" (submit)="vaccinateFormApply($event, applyVaccinateFormGroup)">
                            <ul>
                                <li>
                                    <p>Name of the Baby*
                                        <span class="alertMsg" *ngIf="!applyVaccinateFormGroup.controls.txtNOB.valid && submitted">
                                        required
                                        </span>
                                    </p>
                                    <div class="sdatewrp2">
                                        <input type="text" name="txtNOB" formControlName="txtNOB" class="" placeholder="Lusha" />
                                    </div>
                                </li>
                                <li>
                                    <p>Date of Birth*
                                        <span class="alertMsg" *ngIf="!applyVaccinateFormGroup.controls.txtDOB.valid && submitted">
                                        required
                                        </span>
                                    </p>
                                    <div class="sdatewrp">
                                        <input id="dOB" type="text" name="txtDOB" formControlName="txtDOB" (blur)="readDate()" class="datepickr_vaccination" placeholder="15 / 03 / 2017" />
                                    </div>
                                </li>
                                <li>
                                    <p>&nbsp;</p>
                                    <input type="submit" name="submit" class="rimsubmitbtn" value="Submit" />
                                    <input type="reset" name="reset" class="rimresetbtn" value="Reset" (click)="resetForm()"/>
                                </li>
                            </ul>
                        </form>
                    </div>
                    <div class="header" *ngIf="applyVaccinateFormGroup.valid && submitted">
                        <h4>{{applyVaccinateFormGroup.controls['txtNOB'].value}} is {{displayBirthDiff.cmpr}}  {{displayBirthDiff.show}} {{displayBirthDiff.append}} old.</h4>
                        <p>Check our recommended vaccine schedule & mark your calendars for upcoming vaccination days.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="vimmunityiconwrp">
        <div class="row">
            <div class="container">
                <div class="col-md-12 col-xs-12 col-lg-12">
                    <ul #watchvaccination class="vimslider slider">
                        <li *ngFor="let vaccinationDetail of vaccination_details" [attr.data-value]="vaccinationDetail.age_in_weeks">
                            <div class="viconwrp">
                                <p> {{vaccinationDetail.age_display}}</p>
                                <div class="cirwrp"><img src="{{imgBasePath}}{{vaccinationDetail.image}}" data-img="" alt="{{vaccinationDetail.age_display}}" /></div>
                            </div>
                        </li>
                      
                    </ul>
                    <ul #watchvaccinationCon class="vimslidercon slider">
                        <li *ngFor="let vaccinationDetail of vaccination_details">
                            <div class="viminfowrp" data-fancybox="modal" data-src="#srmodel-1">
                                <ul>
                                    <li>
                                        <!--<div class="iconbyname">
                                            <img src="assets/images/baby/icon-hepatitisb.png" alt="" />
                                        </div> -->
                                        <div class="namebyicon">
                                            <p>{{vaccinationDetail.oral}} {{vaccinationDetail.injection}}</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div id="srmodel-1" class="srmodel">
                                <div class="srmodelwrp">
                                    <h2>Immunization Schedule</h2>
                                    <h3>Birth :</h3>
                                    <p>BCG, OPV, Hepatitis B (1st)</p>
                                </div>
                            </div>
                        </li>
                      
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <section class="vimminfowrp">
        <div class="row">
            <div class="container">
                <div class="col-md-12 col-xs-12 col-lg-12">
                    <div class="infocell1" *ngIf="medical_contact">
                        <h3>For any medical information about Vaccination, please contact:</h3>
                        <ul *ngFor="let medical of medical_contact; let i =  index;">
                            <li>
                                <img src="{{imgBasePath}}{{medical.image}}" alt="" />
                            </li>
                            <li>
                                <p>{{medical.name}}</p>
                                <div class="infofvimm">
                                    <div *ngIf="medical.phone_num" class="ictextwrp phone">{{medical.phone_num}}</div>
                                    <div *ngIf="medical.email" class="ictextwrp mail"><a href="mailto:{{medical.email}}">{{medical.email}}</a></div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="infocell1">
                        <h3>For any general information (Vaccine availability, cost, etc) about Vaccination, please contact:</h3>
                        <ul>
                            <li *ngFor="let geninfo of information_contact;">
                                <p>{{geninfo.name}}</p>
                                <div class="infofvimm">
                                    <div class="ictextwrp phone">{{geninfo.phone_number}}</div>
                                    <div class="ictextwrp mail"><a href="mailto:{{geninfo.email}}">{{geninfo.email}}</a></div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="learnvmwrp" data-class="learnvmwrp">
        <div class="row">
            <div class="container">
                <div class="col-md-12 col-xs-12 col-lg-12" *ngFor="let vaccination of group_sections;">
                    <div class="learnvmheder">
                        <h2 class="textgradient" data-class="textgradient">{{vaccination.title}}</h2>
                        <img src="assets/images/icon-lvm.png" class="lvmicon" alt="" />
                    </div>
                    <div class="row learnacrwrp" data-class="learnacrwrp" *ngFor="let section of vaccination.questions;">
                        <div class="questionwrp" data-class="questionwrp" [class.sel]="activeQuestionId == section.id" (click)="activeQuestion(section);">
                            <h3>{{section.question}}</h3>
                        </div>
                        <div class="answerwrp" data-class="answerwrp" [style.display]="activeQuestionId == section.id ? 'block' : 'none'" [innerHTML]="section.answer"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>