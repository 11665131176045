<div class="container-fluid">
    <div class="background">
        <img src="assets/images/bg-press-clip.png" data-t="bg_01_00.jpg" data-d="bg-press-clip.png" class="bgImg bg0 active" />
    </div>
    <div class="loder"></div>
    <sidenav></sidenav>
    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <!--<bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>-->
    <section class="pagebcwrp">
        <div class="container">
            <div class="row">
            <div class="col-md-12 col-sm-12 col-lg-12">
                <ul>
                <li *ngFor="let link of bread_crumb_links" class="{{link.class}}">
                    <a *ngIf="link.link" href="{{link.link}}">{{link.label}}</a>
                    <span *ngIf="!link.link">{{link.label}}</span>
                </li>
                <li >{{bread_crumb_title | slice:0:32}}<span *ngIf="bread_crumb_title_length > 50">...</span></li>
                </ul>
            </div>
            </div>
        </div>
    </section>
    <section class="page mediaSection pageheader">
    </section>
    <section class="container-fluid aboutbod">
        <div class="row">
            <div class="container">
                <div class="col-xs-12 col-sm-12 col-lg-12 event-details">
                    <div class="prelshead">
                        <div class="prdate">
                            <h3>{{eventDetail.contentdate | amDateFormat : "MMMM"}}</h3>
                            <h2 class="gradient">{{eventDetail.contentdate | amDateFormat : "DD"}}</h2>
                            <h4>{{eventDetail.contentdate | amDateFormat : "YYYY"}}</h4>
                        </div>
                        <div class="prtitle">
                            <h2>{{eventDetail.contentheading}}</h2>
                            <h4>{{eventDetail.contentdate | amDateFormat : "dddd, hh:mm"}}</h4>
                        </div>
                    </div>
                    <div class="prelscontent">

                        <div class="col-xs-12 col-sm-12 col-lg-12">
                            <p [innerHTML]="eventDetail.contentlargedecription"></p>
                        </div>

                        <div *ngIf="videoUrl" class="iframeVideoWrp" style="display: none;">
                            <iframe [src]='safeURL' frameborder="0" allowfullscreen class="responsive_iframe"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--
    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>-->
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>

</div>