<div class="container-fluid">
    <div class="background">
        <img src="assets/images/bg-press-clip.png" data-t="bg_01_00.jpg" data-d="bg-press-clip.png" class="bgImg bg0 active" />
    </div>
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <section class="page">
        <div class="row mediaSection pageheader">
            <div class="container">
                <div class="col-xs-12 col-sm-7 col-lg-7 abannimgwrp">
                    <div class="aboutbannimg"><img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" /></div>
                </div>
                <div class="col-xs-12 col-sm-5 col-lg-5">
                    <div class="ptitle yellow">
                        <h1>{{title}}</h1>
                        <h3>{{subtitle}}</h3>                        
                    </div>
                </div>
            </div>
            
        </div>
    </section>
    <section class="container-fluid aboutbod">
        <div  class="container-fluid" style="background: #fcfaff;position: relative;">
            <div class="container content">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <ul class="tabpcwrp">
                            <li><span>Press Clips</span></li>
                            <li><a href="/about-us/media/media-press-releases">Press Releases</a></li>
                            <li style="display: none;">
                                <div class="selctwrp">
                                    <div class="specialtywrp">
                                        <select class="select" name="specialty">
                                            <option value="0">Select Specialty</option>
                                            <option value="Child Care">Child Care</option>
                                            <option value="Women Health">Women Health</option>
                                            <option value="Fertility Care">Fertility Care</option>
                                        </select>
                                        <span id="SelectSpecialty " class="select-span"></span>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="selctwrp mbutn">
                                    <div class="finddwrp">
                                        <div class="findbox">
                                            <input type="text" name="txtfinddoctors" class="datepickr" [(ngModel)]="selectMonth" id="selectMonthYear" placeholder="Archive" />
                                        </div>
                                    </div>
                                    <div class="gobtn">
                                        <input type="submit" name="txtSearch" value="GO" (click)="filterRecord()" />
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div  class="container-fluid">
            <div class="container content">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="tabfilterwrp">
                            <label *ngFor="let filters of filterPC" [(ngClass)]="filters.className">
                              <input type="checkbox" class="checkboxbg"  [(ngModel)]="filters.selected" (click)="shorting($event)" value="{{filters.id}}"> {{filters.name}} - {{filters.selected}}
                           </label>
                        </div>
                        <div class="tabfiltertitlewrp">
                            <p id="tabfilterText"><span *ngIf="!filterLabelCheck">All</span><span *ngIf="filterLabelCheck">{{filterLabel}}</span> Content</p>
                            <hr>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="container-fluid aboutbod">
        
        <div class="container content" myMatchHeight="pressrelease-sec">
            <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4 pressrelease-sec" *ngFor="let pressClips of filtered_record">
                <h2>{{pressClips.author}}</h2>
                <h3>{{pressClips.designation}}</h3>
                <p> <span class="style1">{{pressClips.publish_in}}</span> <br>
                    <span class="date">{{pressClips.date | amDateFormat: "dddd, DD MMMM YYYY"}} </span>
                </p>
                <a href="/about-us/media/media-press-clips/{{pressClips.page}}" class="knowmore2 active" (click)="pressClipId(pressClips.id)"><span> View Article</span></a>
                <a data-fancybox href="{{imgBasePath}}{{pressClips.image}}" class="knowmore2 active fancybox" style="display: none;">
                    <span> View Article</span>
                </a>
            </div>
        </div>
        
        <div class="container">
            <div id='page_navigation'></div>
        </div>
    </section>
    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>