<div class="container-fluid">
    <div class="loder" id="Loader"></div>
    <sidenav></sidenav>
    <div id="topsearchwrp">
        <div class="inputbox">
            <input class="searchInput" type="text" name="txtSearch" id="txtSearch" placeholder="type here..." />
            <input type="submit" name="submit" class="ssubmitbtn" value="" />
        </div>
    </div>
    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>

    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>


    <section class="page wtwedo">
        <div class="aboutcentre-banner container">
            <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5 floatright">
                <div id="centreFGetInTouch">
                    <div class="formWrp">
                        <div class="launchpreloader"></div>
                        <h2>GET IN TOUCH WITH US</h2>
                        <div class="thankYouMsg">
                            <span>Thanks for enquiring. Our team will get back to you shortly.</span>
                        </div>
                        <form id="pageForm" [formGroup]="GetInTouchOnPage" (submit)="onSubmitDirectPost()">
                            <ul class="formcasn">
                                <li>
                                    <input type="text" name="name"
                                        [ngClass]="{'has-error':!GetInTouchOnPage.controls.name.valid && GetInTouchOnPage.controls.name.touched}"
                                        placeholder="Name*" formControlName="name" (keypress)="isletters($event)" (blur)="onNameBlur($event)"
                                        required />
                                    <span class="alertMsg"
                                        *ngIf="!GetInTouchOnPage.controls.name.valid && submited">Please enter
                                        Name*</span>
                                </li>
                                <li>
                                    <input type="text" name="mobile"
                                        [ngClass]="{'has-error':!GetInTouchOnPage.controls.mobile.valid && GetInTouchOnPage.controls.mobile.touched}"
                                        maxlength="10" placeholder="Mobile*" formControlName="mobile" (blur)="onMobileBlur($event)"
                                        (keypress)="isNumber($event)" required />
                                    <span class="alertMsg"
                                        *ngIf="!GetInTouchOnPage.controls.mobile.valid && submited">Please enter
                                        Mobile*</span>
                                </li>
                                <li>
                                    <input type="text" name="email"
                                        [ngClass]="{'has-error':!GetInTouchOnPage.controls.email.valid && GetInTouchOnPage.controls.email.touched}"
                                        placeholder="Email ID*" formControlName="email" (blur)="onEmailBlur($event)" />
                                    <span class="alertMsg"
                                        *ngIf="!GetInTouchOnPage.controls.email.valid && submited">Please enter Email
                                        ID</span>
                                </li>
                                <li class="singledropdown">
                                    <div class="selectbox">
                                        <select class="select" name="speciality" [(ngModel)]="specialityModel" (change)="selectSubSpecility($event)"
                                            formControlName="speciality">
                                            <option value="" selected>Select Speciality</option>
                                            <option *ngFor="let special of specialityList;let i= index;"
                                                [value]="special.value">
                                                {{special.name}}
                                            </option>
                                        </select>
                                        <span class="select-span" id="txtSpeciality"></span>
                                    </div>
                                    <span class="alertMsg"  *ngIf="!GetInTouchOnPage.controls.speciality.valid && submited">Please select Speciality</span>
                                </li>

                                <li class="singledropdown">
                                    <div class="selectbox">
                                        <select class="select" name="subSpecialty" id="subSpecialty" (change)="OnChangeSubSpecility($event)"
                                            [(ngModel)]="subSpecialtyListModel" formControlName="subSpecialty">
                                            <option value="">Select Sub Specialty</option>
                                            <option *ngFor="let array of subSpecialtyListArray;let i= index;"
                                                [value]="array.title">
                                                {{array.title}}
                                            </option>
                                        </select>
                                        <span class="select-span" id="subSpecialtySpan"></span>
                                    </div>
                                    <span class="alertMsg"
                                        *ngIf="!GetInTouchOnPage.controls.subSpecialty.valid && submited">Please select Sub Specialty*</span>
                                </li>
                                
                                <!-- <li class="singledropdown">
                                    <div class="selectbox">
                                        <select class="select" name="intrestedin" id="intrestedin"
                                            [(ngModel)]="countryModel" formControlName="intrestedin">
                                            <option value="" selected>I am interested in</option>
                                            <option *ngFor="let interest of interestedList;let i= index;"
                                                [value]="interest.name">
                                                {{interest.name}}
                                            </option>
                                        </select>
                                        <span class="select-span"></span>
                                    </div>
                                    <span class="alertMsg"
                                        *ngIf="!GetInTouchOnPage.controls.intrestedin.valid && submited">Please select I
                                        am interested in*</span>
                                </li> -->
                                <!--
                            <li class="singledropdown">
                                <div class="selectbox">
                                    <select class="select" name="txtLocation" id="txtLocation" [(ngModel)]="centrelocation" formControlName="txtLocation">
                                        <option value="" selected>Select Unit</option>
                                        <ng-template ngFor let-c [ngForOf]="centerList" let-i="index">
                                            <optgroup *ngIf="c.area.length > 0" label="{{c.city}}">
                                                <option *ngFor="let branch of c.area" [value]="branch.center_name_alias">{{branch.center_name_alias}}</option> 
                                            </optgroup>
                                        </ng-template>
                                    </select>
                                    <span class="select-span"></span>
                                </div>
                                <span class="alertMsg" *ngIf="!GetInTouchOnPage.controls.txtLocation.valid && submited">Please select unit</span>
                            </li>-->
                                <li>
                                    <input type="text" name="message" placeholder="Message" formControlName="message" (blur)="onMassgeBlur($event)" />
                                </li>
                                <li>
                                    <label for="authorization" class="checklabel" placeholder="consent">
                                        <input id="authorization" placeholder="consent" name="authorization"
                                            formControlName="authorization" class="checkbox" type="checkbox"
                                            value="consent" (change)="OnChangeAuthorize($event)" required />
                                        I hereby authorize Rainbow Hospitals and their certified partners to contact me
                                        through email, phone or any other modes of communication. It will override my
                                        registry on NCPR
                                    </label>
                                    <span class="alertMsg"
                                        *ngIf="!GetInTouchOnPage.controls.authorization.valid && submited">Please select
                                        agree the consent</span>
                                </li>
                                <li>
                                    <div class="submitbtn">
                                        <input type="hidden" name="utm_source" id="utm_source" />
                                        <input type="hidden" name="utm_medium" id="utm_medium" />
                                        <input type="hidden" name="utm_campaign" id="utm_campaign" />
                                        <input type="hidden" name="utm_content" id="utm_content" />
                                        <input type="hidden" name="utm_term" id="utm_term" />
                                        <input type="hidden" name="utm_lms" id="utm_lms" />
                                        <input type="hidden" name="location" id="location" formControlName="location" />
                                        <input type="hidden" id="page" formControlName="page" value="">
                                        <input type="hidden" id="ipAddress" formControlName="ipAddress" value="">
                                        <input type="hidden" id="useragent" formControlName="useragent" value="">
                                        <input class="casubmit" id="txtSubmit" name="txtSubmit" type="submit" value="Submit">
                                    </div>
                                </li>
                            </ul>
                        </form>
                    </div>
                </div>
                <!--
                <div class="circuleframe2">
                    <a href="{{virtual_tour_link}}" target="_blank">
                        <img src="{{imgBasePath}}{{imgsrc}}"  alt="{{title}}" title="{{title}}" />
                    </a>
                </div>
                 End Circle -->
            </div>
            <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7 floatleft">
                <div class="pagetitle ">
                    <h1 class="textgradient fontsize">{{title}}</h1>
                    <div class="underline"> <img src="assets/images/underline.png" alt="" /> </div>
                </div>
            </div>
            <div class="col-md-7 col-xs-12 col-lg-7 mcontentview">
                <p [innerHTML]="description">
                    <!--{{description}}-->
                </p>
                <p> <strong>Address :</strong> {{address}}</p>
                <a href="{{MapLink}}" target="_blank">
                    <div class="viewMapBtn">View on Map</div>
                </a>
            </div>
        </div>
    </section>

    <section class="specialAnnouncementWrp">
        <div class="row">
            <div class="container">
                <div class="col-md-12 col-xs-12 col-lg-12">
                    <span class="saIcon">Special <br>Announcement</span>
                    <div class="AnnouncementSliderBox">
                        <div class="AnnouncementSlider">
                            <div *ngFor="let Announcement of specialAnnouncements">
                                <p class="snText"><span><strong>{{Announcement.announcementheading}} </strong><br>
                                        {{Announcement.announcementsmalldescription}}</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="centreServiceListWrp">
        <div class="row">
            <div class="container">
                <div class="col-md-12 col-xs-12 col-lg-12">
                    <ul class="centreServiceList">
                        <li class="active" id="csServicesPackages" (click)="DataLayerOnClickUnitactivities('Services & packages','Services&packages')">
                            <div class="circlewrp">
                                <div class="cir"><img src="assets/images/centreimg/circle_icon1.png" alt="" /></div>
                            </div>
                            <div class="txtwrp">Services & Packages</div>
                        </li>
                        <li id="csUNITActivities" (click)="DataLayerOnClickUnitactivities('Unit Activities','Unitactivities')">
                            <div class="circlewrp">
                                <div class="cir"><img src="assets/images/centreimg/circle_icon2.png" alt="" /></div>
                            </div>
                            <div class="txtwrp">UNIT Activities</div>
                        </li>
                        <li id="csDoctors" (click)="DataLayerOnClickUnitactivities('Doctors','Doctors')">
                            <div class="circlewrp">
                                <div class="cir"><img src="assets/images/centreimg/circle_icon3.png" alt="" /></div>
                            </div>
                            <div class="txtwrp">Doctors </div>
                        </li>
                        <!--
                        <li id="csPatientResources">
                            <div class="circlewrp">
                                <div class="cir"><img src="assets/images/centreimg/circle_icon4.png" alt="" /></div>
                            </div>
                            <div class="txtwrp">Patient Resources </div>
                        </li>
                        -->
                    </ul>
                </div>
            </div>
        </div>
        <div class="dataSection csServicesPackages">
            <!-- <div class="dataPopup">
                <div class="container">
                    <div class="dataPopupBox">
                        <div class="closeBtn" (click)="closePopup()">&#10006;</div>
                        <div class="row" *ngFor="let item of PackageListing">
                            <div class="col-xs-12 col-sm-6">
                                <img src="{{imgBasePath}}{{item.image}}" class="img-responsive" alt="">
                            </div>
                            <div class="col-xs-12 col-sm-6">
                                <div class="sheading">
                                    <h3>{{item['centerlist'][0].center_name}}</h3>
                                </div>
                                <p [innerHTML]="item['centerlist'][0].description"></p>
                            </div>
                        </div>
                    </div>
    
                </div>
            </div> -->
            <div class="row" *ngIf="AllPackegs">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 col-xs-12 col-lg-12">
                            <div class="innerContainerLoader" id="CPackagesLoader"></div>
                            <div class="centrePackagesWrp">
                                <div class="sheading">
                                    <h3>Packages</h3>
                                    <div class="filterBoxWrap">
                                        <div class="tab Active" (click)="PackagesFilter($event,'')">All</div>
                                        <div class="tab" (click)="PackagesFilter($event,'4')">Fertility Care </div>
                                        <div class="tab" (click)="PackagesFilter($event,'2')">Women Care</div>
                                        <div class="tab" (click)="PackagesFilter($event,'1')">Child Care</div>
                                    </div>
                                </div>
                                <div class="col-xs-12" *ngIf="Packages?.length == 0">
                                    <div class="noDataFound">No Packages available in this section</div>
                                </div>
                                <ul class="packagesLists" *ngIf="Packages?.length > 0">
                                    <li *ngFor="let item of Packages">
                                        <div class="leftBannWrp">
                                            <img src="{{imgBasePath}}{{item.image}}" alt="" /> 
                                        </div>
                                        <div class="rightTxtWrp">
                                            <h4>{{item.packageheading}}</h4>
                                            <!-- <p>{{item.packageduration}}</p> -->
                                            <p>{{item.packagesmalldecription.length > 150 ? item.packagesmalldecription.slice(0, 150) + '...' : item.packagesmalldecription}}</p>
                                            <!-- <p class="pcost">Package Cost: <img
                                                    src="assets/images/centreimg/icon_rs2.png">{{item.packagecost}}</p> -->
                                            <!-- <p><span class="readMoreStyle1" (click)="getPackageListing(item.id)">Read
                                                    more</span></p> -->
                                            <div class="col-xs-12">
                                                <a href="other/packages-plans/{{item.slug}}" (click)="DataLayerOnClickUnitactivities('Services&packages','Services&packages_Read_More')" class="readMoreStyle2"><span>Read More</span></a>
                                            </div>

                                        </div>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row bgWhite" *ngIf="AllWorkShopData">
                <div class="container">
                    <div class="col-md-12 col-xs-12 col-lg-12">
                        <div class="cWorkshopsWrp">
                            <div class="sheading">
                                <h3>Workshops</h3>
                                <div class="filterBoxWrap">
                                    <div class="tab Active" (click)="workshopFilter($event,'')">All</div>
                                    <div class="tab" (click)="workshopFilter($event,'4')">Fertility Care </div>
                                    <div class="tab" (click)="workshopFilter($event,'2')">Women Care</div>
                                    <div class="tab" (click)="workshopFilter($event,'1')">Child Care</div>
                                </div>
                            </div>

                            <div class="col-xs-12" *ngIf="WorkShopData?.length == 0">
                                <div class="noDataFound">Sorry No Workshop available in this section currently, please
                                    keep visiting for the updates. </div>
                            </div>
                            <ul class="cWorkshopsLists" *ngIf="WorkShopData?.length > 0">
                                <li *ngFor="let item of WorkShopData">
                                    <div class="cWorkshopsBox">
                                        <div class="topBannWrp">
                                            <img src="{{imgBasePath}}{{item.image}}" alt="" />
                                        </div>
                                        <div class="specialityWrp">
                                            <span class="sName"
                                                *ngFor="let sp of item.specialitylist">{{sp.speciality}}</span>
                                            <span class="wCost" style="display: none;"><i
                                                    class="rupee">`</i>{{item.workshopcost}}/-</span>
                                        </div>
                                        <div class="middleTxtWrp">
                                            <h4>{{item.workshopheading}}</h4>
                                            <h5><strong> {{item.workshopsmalldecription}}</strong></h5>
                                        </div>
                                        <div class="datetimeWrp">
                                            <span class="cdate">{{item.calenderDate['DateNo']}}
                                                {{item.calenderDate['MonthShort']}}
                                                {{item.calenderDate['yearNo']}}</span>|
                                            <span class="ctime">{{item.startTime}} to {{item.endTime}}</span>
                                        </div>
                                    </div>
                                    <div class="cWorkshopsBtnWrp">
                                        <!-- <p><a class="readMoreStyle1"
                                                href="about-us/media/media-press-releases/press-releases-details/4">Book
                                                Now</a></p> -->
                                        <div class="col-xs-12">
                                            <!-- <a href="our-center/banjara-hills/workshop-{{item.id}}" class="readMoreStyle1"><span>Book Now</span></a> -->
                                            <a href="{{item.onlineworkshopurl}}" class="readMoreStyle1" (click)="DataLayerOnClickUnitactivities('Services&packages&WorkShop','Services&packages_Book_Now')"><span>Book Now</span></a>
                                        </div>
                                    </div>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="AllsubspecialityListArray">
                <div class="container">
                    <div class="col-md-12 col-xs-12 col-lg-12">
                        <div class="sheading">
                            <h3>Our Specialties</h3>
                            <p>Following are the specialities that are provided in our center</p>
                        </div>

                        <div class="row">
                            <div class="col-xs-12">
                                <ul class="subSpecialityListItem">
                                    <li *ngFor="let item of subspecialityListArray"><a href="{{item.page}}/speciality/{{item.slug}}" (click)="DataLayerOnClickEvent('Services&packages_OurSpecialties','Services&packages_OurSpecialties_Link',item.slug)"> {{item.title}}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="dataSection csUNITActivities" >
            <div class="row unitActivityWrapper lightBg" *ngIf="AllWebinarData">
                <div class="container">
                    <div class="col-xs-12">
                        <div class="sheading">
                            <h3>Webinar</h3>
                            <div class="filterBoxWrap">
                                <!-- <div class="tab Active" (click)="WebinarFilter($event,'')" >All</div> -->
                                <div class="tab" (click)="WebinarFilter($event,'Future')">Upcoming </div>
                                <div class="tab Active" (click)="WebinarFilter($event,'Past')">Past</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 acadamicWrp">
                        <div class="col-xs-12" *ngIf="Webinar.length == 0">
                            <div class="noDataFound">No Webinar available in this section</div>
                        </div>
                        <ul class="WebinarSlider WebinarSliderWrap  col-xs-12" *ngIf="Webinar.length > 0">
                            <li *ngFor="let item of Webinar">
                                <div class="col-xs-12">
                                    <div class="thumbImgWrp">
                                        <a href="{{item.contentvideourlnew}}" class="fancybox"
                                            title="{{item.contentheading}}" (click)="DataLayerOnClickEvent('Unit Activities','Unitactivities_Webinar_Video_Play',item.contentheading)">
                                            <div class="videoThumb"><img src="{{imgBasePath}}{{item.contentvideoimage}}"
                                                    alt="" /></div>
                                        </a>
                                    </div>
                                    <h4>{{item.contentheading}}</h4>
                                    <p [innerHTML]="item.contentsmalldecription"></p>
                                    <p class="name"><span *ngFor="let dr of item.doctorlist">{{dr.name}}, </span></p>

                                    <div class="aBtnWrp" *ngIf="item.isDate == 'Future'">
                                        <a class="knowmore2" target="_blank" href="{{item.contentlink}}"
                                            tabindex="0"><span>Register Now</span></a>
                                    </div>

                                    <div class="locationWrap" *ngIf="item.isDate == 'Past'">
                                        <div class="text" *ngFor="let center of item.centerlist">
                                            <div class="icon"><img src="../../assets/images/location-new.png" alt=""
                                                    srcset="">
                                            </div>
                                            {{center.center_name_alias}}
                                        </div>
                                        <div class="text">
                                            <div class="icon"><img src="../../assets/images/calender-icon.png" alt=""
                                                    srcset="">
                                            </div>
                                            {{item.calenderDate['DateNo']}} {{item.calenderDate['MonthName']}}
                                            {{item.calenderDate['yearNo']}}
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="row centreServiceListWrp  bgWhite" *ngIf="isEvent">
                <div class="container">
                    <div class="col-xs-12">
                        <div class="sheading">
                            <h3>Events</h3>
                            <div class="filterBoxWrap">
                                <!-- <div class="tab Active" (click)="EventFilter($event,'')" >All</div> -->
                                <div class="tab" (click)="EventFilter($event,'Future')">Upcoming </div>
                                <div class="tab Active" (click)="EventFilter($event,'Past')">Past</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xs-12" *ngIf="Event?.length == 0">
                        <div class="noDataFound">No Event available in this section</div>
                    </div>
                    <div class="col-xs-12" *ngIf="Event?.length > 0">
                        <div class="WebinarSlider eventSliderWrap">
                            <div *ngFor="let item of Event">
                                <div class="col-xs-12 EventBoxSection">
                                    <div class="dateBox">
                                        <div class="month">{{item.calenderDate['MonthName']}}</div>
                                        <div class="date">{{item.calenderDate['DateNo']}}</div>
                                        <div class="year">{{item.calenderDate['yearNo']}}</div>
                                    </div>
                                    <h3>{{item.contentheading}}</h3>
                                    <div class="row">

                                        <div>
                                            <div class="thumbImgWrp">
                                                <a href="{{item.contentvideourlnew}}" class="fancybox"
                                                    title="{{item.contentheading}}"(click)="DataLayerOnClickEvent('Unit Activities','Unitactivities_Event_Video_Play',item.contentheading)">
                                                    <div class="videoThumb"><img
                                                            src="{{imgBasePath}}{{item.contentvideoimage}}" alt="" />
                                                    </div>
                                                </a>
                                            </div>
                                            <p [innerHTML]="item.contentsmalldecription"></p>
                                            <!-- <p class="drName"><span>Dr. Parag Dekate</span> MBBS, MD (PGI), DNB (Peds)</p>-->
                                            <p><a href="{{item.websiteurl}}" target="_blank"> <span
                                                        class="readMoreStyle2" (click)="DataLayerOnClickEvent('Unit Activities','Unitactivities_Event_Read_More',item.websiteurl)">Read
                                                        more</span></a></p>
                                        </div>
                                    </div>


                                    <!-- <p><a href="" class="readMore">Read More</a></p>  -->
                                </div>
                            </div>
                        </div>
                    </div>





                </div>
            </div>
            <div class="row centreServiceListWrp lightBg" *ngIf="AllMediaData">
                <div class="container">
                    <div class="col-xs-12">
                        <div class="sheading">
                            <h3>Media</h3>
                            <div class="filterBoxWrap">
                                <div class="selectWrap">
                                    <select class="select" (change)="selectDataYearFilter($event, 'Media')">
                                        <option value="">Select Year</option>
                                        <option value="2018">2018</option>
                                        <option value="2019">2019</option>
                                        <option value="2020">2020</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12" *ngIf="Media?.length == 0">
                        <div class="noDataFound">No Media available in this section</div>
                    </div>
                    <div class="col-xs-12" *ngIf="Media?.length > 0">
                        <div class="WebinarSlider MediaSlider">
                            <div *ngFor="let item of Media">
                                <div class="col-xs-12 EventBoxSection">
                                    <div class="dateBox">
                                        <div class="month">{{item.calenderDate['MonthName']}}</div>
                                        <div class="date">{{item.calenderDate['DateNo']}}</div>
                                        <div class="year">{{item.calenderDate['yearNo']}}</div>
                                    </div>
                                    <h3>{{item.contentheading}}</h3>
                                    <p [innerHTML]="item.contentsmalldecription"></p>
                                    <p class="drName" *ngFor="let drName of item.doctorlist">
                                        <span>{{drName.name}}</span> {{drName.qualification}}
                                    </p>
                                    <p><a href="{{item.websiteurl}}"  (click)="DataLayerOnClickEvent('Unit Activities','Unitactivities_Media_Read_More',item.websiteurl)" target="_blank"> <span class="readMoreStyle2">Read
                                                more</span></a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="row centreServiceListWrp bgWhite">
                <div class="container">
                    <div class="col-xs-12">
                        <div class="sheading">
                            <h3>Articles </h3>
                            <div class="filterBoxWrap">
                                <div class="selectWrap">
                                    <select class="select" (change)="selectDataYearFilter($event, 'Articles')">
                                        <option value="">Select Year</option>
                                        <option value="2018">2018</option>
                                        <option value="2019">2019</option>
                                        <option value="2020">2020</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xs-12" *ngIf="Articles?.length == 0">
                        <div class="noDataFound">No Articles available in this section</div>
                    </div>
                    <div class="col-xs-12" *ngIf="Articles?.length > 0">
                        <div class="WebinarSlider ArticlesSlider">
                            <div *ngFor="let item of Articles">
                                <div class="col-xs-12 EventBoxSection">
                                    <div class="dateBox">
                                        <div class="month">{{item.calenderDate['MonthName']}}</div>
                                        <div class="date">{{item.calenderDate['DateNo']}}</div>
                                        <div class="year">{{item.calenderDate['yearNo']}}</div>
                                    </div>
                                    <h3>{{item.contentheading}}</h3>
                                    <p [innerHTML]="item.contentsmalldecription"></p>
                                    <p class="drName" *ngFor="let drName of item.doctorlist">
                                        <span>{{drName.name}}</span> {{drName.qualification}}
                                    </p>
                                    <p><a href="{{item.websiteurl}}" target="_blank"> <span class="readMoreStyle2">Read
                                                more</span></a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>

        <div class="dataSection csDoctors">
            <div class="row unitActivityWrapper">
                <div class="container">
                    <div class="row">
                        <div class="col-xs-12 dfindwrp">
                            <ul class="hlfcube">
                                <li>
                                    <div class="specialtywrp sWrp">
                                        <select class="select" id="selecteSpecialty" [(ngModel)]="SearchSpec"
                                            name="eCategory" (change)="Selectlocation($event)">
                                            <option value="null">Select Specialty</option>
                                            <option value="">All</option>
                                            <option value="{{item.id}}" *ngFor="let item of Speciality">
                                                {{item.speciality}}
                                            </option>
                                        </select>
                                        <span class="select-span">Select Category</span>
                                    </div>
                                    <div class="searchbtn">
                                        <input name="txtSearch" type="button" value="Go" (click)="getDoctorsList()">
                                    </div>
                                </li>
                                <li>
                                    <div class="specialtywrp ssWrp">
                                        <select class="select" id="selecteSpecialty" [(ngModel)]="subSpecialtyModel"
                                            name="subSpecialty" (change)="SelectSubSpecialty($event)">
                                            <option value="null" selected>Select Sub Specialty</option>
                                            <option value="{{item.id}}" *ngFor="let item of SubSpecialtyList">
                                                {{item.title}}
                                            </option>
                                        </select>
                                        <span class="select-span">Select Sub Specialty</span>
                                    </div>
                                    <div class="searchbtn">
                                        <input name="txtSearch" type="button" value="Go"
                                            (click)="getDoctorsListBySubSpecialty()">
                                    </div>
                                </li>
                                <li>
                                    <div class="findbox DoctorSerchBox">
                                        <input type="text" class="searchInput" id="DoctorSerch"
                                            (keyup)="getDoctorname($event)" placeholder="Doctor’s Name" />
                                        <ul class="listWrap">
                                            <li *ngFor="let item of FilterNameList" data-value="{{item.DrID}}"
                                                (click)="selectDoctor($event)">{{item.DrName}}</li>
                                            <!-- <li *ngFor="let doctor of doctorSearchResult; let i = index">
                                                <a href="javascript:void(0);" (click)="selectDoctor(doctor.id)">{{doctor.name}}</a>
                                            </li> -->
                                        </ul>
                                    </div>

                                    <div class="searchbtn">
                                        <input name="txtSearch" type="button" value="Go">
                                    </div>
                                </li>
                                <!-- <li>
                                    <div class="btnVisiting resetBtnFilter" (click)="ResetSelectlocation()">Reset Filter</div>
                                </li> -->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row centerPage childdoctor-result">

                <div class="container">
                    <div class="col-xs-12" *ngIf="doctorsList?.length == 0">
                        <div class="noDataFound">No Doctors available in this section</div>
                    </div>

                    <div class="DoctorDataSleder" *ngIf="doctorsList?.length > 0">
                        <div *ngFor="let doctorBox of doctorsList">
                            <div *ngFor="let doctor of doctorBox">
                                <div class="DoctorDataBox">
                                    <div class="row">
                                        <div class="col-xs-12">
                                            <div class="dthumbwrp"> <img src="{{imgBasePath}}{{doctor.photo}}" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row doctorDataWrap">
                                        <div class="col-xs-12 ">
                                            <div class="ddetailswrp">
                                                <h3>{{doctor.name}}</h3>
                                                <p> {{doctor.qualification}}, {{doctor.designation}} </p>
                                            </div>
                                        </div>
                                        <div class="col-xs-12">
                                            <div class="dunderline"> <img src="assets/images/underline.png" alt="" />
                                            </div>
                                            <a href="/doctors/{{doctor.slug}}" target="_blank"
                                                class="knowmore2 active" (click)="DataLayerOnClickEvent('Doctors', 'Read_More', doctor.slug)"><span>Know
                                                    More</span></a> <a href="all/book-an-appointment/{{doctor.slug}}"
                                                target="_blank" class="btn-Bookapopoi" (click)="DataLayerOnClickEvent('Doctors','BookAppointment',doctor.slug)"><span>Book an
                                                    appointment</span></a>
                                        </div>
                                    </div>

                                </div>


                            </div>
                        </div>

                    </div>






                </div>
            </div>
            <div class="row FeaturedArticles">
                <section>
                    <div class="container">

                        <div class="row">
                            <div class="col-xs-12">
                                <div class="sheading">
                                    <h3>Featured Articles </h3>
                                </div>
                            </div>
                            <div class="col-xs-12" *ngIf="FeaturedArticlesData?.length == 0">No Records Found</div>
                            <div class="col-xs-12" *ngIf="FeaturedArticlesData?.length > 0">
                                <div class="col-xs-12 col-sm-6" *ngFor="let item of FeaturedArticlesData">
                                    <div class="ArticlesBox" *ngFor="let doctor of item.doctorlist">
                                        <div class="dthumbwrp">
                                            <img src="{{imgBasePath}}{{doctor.photo}}" />
                                        </div>
                                        <p class="DrName"><span>{{doctor.name}}</span> {{doctor.qualification}}</p>
                                        <p class="disc" [innerHTML]="doctor.designation"></p>
                                        <div class="cvisitext ">
                                            <p><a class="readMoreStyle2" href="javascript:void(0);" (click)="DataLayerOnClickEvent('Doctors','Featured_Articles_Read_More',doctor.name)">Read more</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </div>

        </div>

        <div class="dataSection csPatientResources">
            <div class="row cPrepareYourselfWrp" *ngIf="prepareYourselfYesources?.length > 0">
                <div class="container">
                    <div class="cPYourselfWrp">
                        <div class="sheading">
                            <h3>How to Prepare Yourself</h3>
                        </div>
                        <ul class="cPYourselfLists" myMatchHeight="cPYList">
                            <li class="cPYList" *ngFor="let item of prepareYourselfYesources">
                                <div class="leftBannWrp">
                                    <img src="{{imgBasePath}}{{item.image}}" alt="" />
                                </div>
                                <div class="rightTxtWrp">
                                    <h4>{{item.resourceheading}}</h4>
                                    <p>{{item.resourcesmalldescription}}</p>
                                    <p><a class="readMoreStyle2" target="_blank"
                                            href="{{imgBasePath}}{{item.resourcelinkfile}}">Download PDF</a></p>
                                </div>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>

            <div class="row cPatientGuidebookWrp" *ngIf="patientResources?.length > 0">
                <div class="container">
                    <div class="visitor-head col-lg-12">
                        <h2 class="textgradient fontsize">Patient Guidebook</h2>
                    </div>
                    <div class="cPGTxt col-lg-12" *ngFor="let item of patientResources">
                        <p>{{item.resourcesmalldescription}}</p>
                        <p><a class="readMoreStyle2" target="_blank"
                                href="{{imgBasePath}}{{item.resourcelinkfile}}">Download PDF</a></p>
                    </div>

                </div>
            </div>

            <div class="row cVisitorInformationWrp" *ngIf="visitorResources?.length > 0">
                <div class="container">
                    <div class="visitor-head col-lg-12">
                        <div class="visitor-icon"><img src="assets/images/icon-visitor.png" alt=""></div>
                        <h2 class="textgradient fontsize">Visitor Information</h2>
                    </div>

                    <div class="col-xs-12">
                        <div *ngFor="let item of visitorResources">
                            <div class="cvisitext col-lg-12">
                                <h3>{{item.resourceheading}}</h3>
                                <p>{{item.resourcesmalldescription}}</p>
                            </div>
                            <!-- <div class="cvisitext col-lg-12">
                                    <p><a class="readMoreStyle1" href="#">Read more</a></p>
                                </div> -->
                        </div>
                    </div>




                </div>
            </div>
        </div>
    </section>

















    <a class="iconFormV" href="javascript:void();" (click)="ShowForm()">ENQUIRE<span>NOW</span></a>
    <a class="iconForm" href="javascript:void();" (click)="ShowForm()"><img alt=""
            src="assets/images/Icon_form.png"></a>
    <footer-nav-mini [links]="links"></footer-nav-mini>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
    <!-- Start Model Popup  *ngIf="showHide" -->
    <div class="capopup" id="ModelFormGetInTouch">
        <div class="capopup-inner">
            <div class="launchpreloader" *ngIf="preloader"></div>
            <a class="capopup-close" href="javascript:void()" (click)="changeShowStatus()"> <img alt=""
                    src="assets/images/icon-close2.png"> </a>
            <h2>GET IN TOUCH WITH US</h2>
            <form id="form" [formGroup]="GetInTouch" (submit)="onSubmit()" novalidate>
                <ul class="formcasn">
                    <li>
                        <input type="text" name="name"
                            [ngClass]="{'has-error':!GetInTouch.controls.name.valid && GetInTouch.controls.name.touched}"
                            placeholder="Name*" formControlName="name" required />
                        <span class="alertMsg" *ngIf="!GetInTouch.controls.name.valid && submited">required</span>
                    </li>
                    <li>
                        <input type="text" name="mobile"
                            [ngClass]="{'has-error':!GetInTouch.controls.mobile.valid && GetInTouch.controls.mobile.touched}"
                            maxlength="10" placeholder="Mobile*" formControlName="mobile" required />
                        <span class="alertMsg" *ngIf="!GetInTouch.controls.mobile.valid && submited">required</span>
                    </li>
                    <li>
                        <input type="text" name="email"
                            [ngClass]="{'has-error':!GetInTouch.controls.email.valid && GetInTouch.controls.email.touched}"
                            placeholder="Email ID*" formControlName="email" required />
                        <span class="alertMsg" *ngIf="!GetInTouch.controls.email.valid && submited">required</span>
                    </li>
                    <li class="singledropdown">
                        <div class="selectbox">
                            <select class="select" name="txtLocation" id="txtLocation" [(ngModel)]="locationModel"
                                formControlName="txtLocation">
                                <option value="" selected>Location*</option>
                                <option *ngFor="let interest of locationList;let i= index;" [value]="interest.name">
                                    {{interest.name}}
                                </option>
                            </select>
                            <span class="select-span"></span>
                        </div>
                        <span class="alertMsg" *ngIf="!GetInTouch.controls.txtLocation.valid && submited">required
                        </span>
                    </li>
                    <li class="singledropdown">
                        <div class="selectbox">
                            <select class="select" name="interested_in" id="txtinterested_in" [(ngModel)]="countryModel"
                                formControlName="interested_in">
                                <option value="" selected>*</option>
                                <option *ngFor="let interest of interestedList;let i= index;" [value]="interest.name">
                                    {{interest.name}}
                                </option>
                            </select>
                            <span class="select-span"></span>
                        </div>
                        <span class="alertMsg" *ngIf="!GetInTouch.controls.interested_in.valid && submited">required
                        </span>
                    </li>
                    <li>
                        <input type="text" name="message" placeholder="Message" formControlName="message" />
                    </li>
                    <li>
                        <div class="submitbtn">

                            <input class="casubmit" id="txtSubmit" name="txtSubmit" type="submit" value="Submit">
                        </div>
                    </li>
                </ul>
            </form>
        </div>
    </div>

    <div class="capopup ModelFormStyle1" id="LMSDataCaptureFormGetInTouch">
        <div class="capopup-inner">
            <div class="customloder"></div>
            <a class="capopup-close " href="javascript:void()" (click)="hideModelForm();"> <img alt=""
                    src="assets/images/icon-close2.png"> </a>
            <h2>GET IN TOUCH WITH US</h2>
            <form id="GetInTouchLms" [formGroup]="GetInTouchLms" (submit)="onLMSDataCaptureFormSubmit()" novalidate>
                <ul class="formcasn">
                    <div class="ThankYouMsgWrp"><span>Thanks for enquiring about our Evening Clinic. Our team will get
                            back to you shortly.</span></div>
                    <li>
                        <input type="text" name="name"
                            [ngClass]="{'has-error':!GetInTouchLms.controls.name.valid && GetInTouchLms.controls.name.touched}"
                            placeholder="Name*" formControlName="name" required />
                        <span class="alertMsg" *ngIf="!GetInTouchLms.controls.name.valid && submited">required</span>
                    </li>
                    <li>
                        <input type="text" name="mobile"
                            [ngClass]="{'has-error':!GetInTouchLms.controls.mobile.valid && GetInTouchLms.controls.mobile.touched}"
                            placeholder="Mobile*" formControlName="mobile" required />
                        <span class="alertMsg" *ngIf="!GetInTouchLms.controls.mobile.valid && submited">required</span>
                    </li>
                    <li>
                        <input type="text" name="email"
                            [ngClass]="{'has-error':!GetInTouchLms.controls.email.valid && GetInTouchLms.controls.email.touched}"
                            placeholder="Email ID*" formControlName="email" required />
                        <span class="alertMsg" *ngIf="!GetInTouchLms.controls.email.valid && submited">required</span>
                    </li>
                    <li>
                        <input type="text" name="message" placeholder="Message" formControlName="message" />
                        <input type="hidden" name="programName" id="programName" formControlName="programName"
                            value="Academic Programs" />
                    </li>
                    <li>
                        <div class="submitbtn">
                            <input class="casubmit" id="txtSubmit" name="txtSubmit" type="submit" value="Submit">
                        </div>
                    </li>
                </ul>
            </form>
        </div>
    </div>
    <!-- End Model Popup -->
</div>