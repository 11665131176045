<div class="container-fluid">
    <!-- <div class="loder"></div> -->
    <div class="row header w-full">
      <div class="container mx-auto w-full px-0" style="margin-bottom: -2em">
        <a href="/" target="_blank"
          ><img
            src="assets/images/RCH-logo.png"
            alt=""
            style="transform: scale(75%)"
        /></a>
      </div>
    </div>
    <section class="container">
      <h3 style="color:#231745;">Transaction Id&nbsp;:&nbsp;{{tid}}</h3>
      <br />
        <h1 style="color: #7c3996;">We regret to inform you that your payment <br />
           was not successful. Please try registering again.</h1>
    </section>
  </div>