<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>

    <section class="page pageheader">
        <div class="container">
            <div class="col-xs-6 col-sm-6 col-lg-6 abannimgwrp">
                <div class="otherbannimg"><img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" /></div>
            </div>
            <div class="col-xs-6 col-sm-6 col-lg-6">
                <div class="ptitle">
                    <h1>{{title}}</h1>
                    <h3>{{subtitle}}</h3>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-lg-6 mcont100">
                <p [innerHTML]="description"></p>
            </div>

        </div>
    </section>
    <section class="page mediaSection">
        <div class="row">
            <div class="container">
                <div class="col-xs-12 PressReleases news_text">
                    <h2>Our Newsletters</h2>
                    <div class="row" mymatchheight="PressReleasesBox" ng-reflect-my-match-height="PressReleasesBox">
                        <div class="col-xs-12 col-sm-6 PressReleasesBox">
                            <div class="dateBox">
                                <div class="month">September</div>
                                <div class="date">09</div>
                                <div class="year">2016</div>
                            </div>
                            <h4>Department of Pediatric Surgery & Pediatric Urology and minimal access surgery</h4>
                            <p>Warm greetings from the Department of Pediatric Surgery, Urology and minimal access surgery, Rainbow Children’s Hospital, Hyderabad.</p>
                            <p><a class="readMore" href="https://backend.rainbowhospitals.in/assets/pdf/Newletter_Sep.pdf" target="_blank">View</a></p>
                        </div>
                        <div class="col-xs-12 col-sm-6 PressReleasesBox">
                            <div class="dateBox">
                                <div class="month">Novemer</div>
                                <div class="date">18</div>
                                <div class="year">2016</div>

                            </div>
                            <h4>Department of Pediatric Surgery & Pediatric Urology</h4>
                            <p>Warm greetings from the Department of Pediatric Surgery, Rainbow Children’s Hospitals.</p>
                            <p><a class="readMore" href="https://backend.rainbowhospitals.in/assets/pdf/Newletter_Nov.pdf" target="_blank">View</a></p>
                        </div>
                        <div class="clearfix"></div>
                        <div class="col-xs-12 col-sm-6 PressReleasesBox">
                            <div class="dateBox">
                                <div class="month">January</div>
                                <div class="date">14</div>
                                <div class="year">2017</div>
                            </div>
                            <h4>Department of Pediatric Surgery & Pediatric Urology</h4>
                            <p>Season’s greetings from the Department of Pediatric Surgery, Rainbow Children’s Hospital.</p>
                            <p><a class="readMore" href="https://backend.rainbowhospitals.in/assets/pdf/Newletter_Jan.pdf" target="_blank">View</a></p>
                        </div>
                        <div class="col-xs-12 col-sm-6 PressReleasesBox">
                            <div class="dateBox">
                                <div class="month">March</div>
                                <div class="date">28</div>
                                <div class="year">2017</div>
                            </div>
                            <h4>Department of Pediatric Neuro Surgery</h4>
                            <p>Warm greetings from the Department of Paediatric Neurosurgery, Rainbow Children’s Hospital.</p>
                            <p><a class="readMore" href="https://backend.rainbowhospitals.in/assets/pdf/Neuro_Newletter_March.pdf" target="_blank">View</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="aboutsectin2 awardswapper" style="display:none;">
        <div class="container">
            <div class="col-md-12 col-sm-12 col-lg-12 news_text">
                <h2>Our Newsletters</h2>
                <p>
                    <a class="btnStyleGradient fontsize17 marginTB5" href="https://backend.rainbowhospitals.in/assets/pdf/Neuro_Newletter_March.pdf" target="_blank"><i></i> Newletter Mar 2017</a>
                    <a class="btnStyleGradient fontsize17 marginTB5" href="https://backend.rainbowhospitals.in/assets/pdf/Newletter_Jan.pdf" target="_blank"><i></i>Newletter Jan 2017</a>
                    <a class="btnStyleGradient fontsize17 marginTB5" href="https://backend.rainbowhospitals.in/assets/pdf/Newletter_Nov.pdf" target="_blank"><i></i>Newletter Nov 2016</a>
                    <a class="btnStyleGradient fontsize17 marginTB5" href="https://backend.rainbowhospitals.in/assets/pdf/Newletter_Sep.pdf" target="_blank"><i></i>Newletter Sep 2016</a>
                </p>
            </div>
        </div>
    </section>



    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>