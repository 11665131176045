<div class="container-fluid">
	<a href="javascript:void(0);" (click)="closeModal()">
		Go back to Doctor List
	</a>
	<section #appointment class="ddetailstimet">
		<div class="CalcLoader"><img src="assets/images/loading.gif" alt="" /></div>
		<div class="container">
			<div class="row">
				<div class="col-md-12 col-sm-12 col-lg-2">
					<div class="dthumbwrp">
						<img src="{{imgBasePath}}{{doctorDetail.photo}}" alt="{{doctorDetail.name}}" title="{{doctorDetail.name}}" />
					</div>
				</div>
				<div class="col-md-12 col-sm-12 col-lg-7">
					<div class="ddetailswrp">
						<h3>{{doctorDetail.name}}</h3>
						<p>
							{{doctorDetail.qualification}}, {{doctorDetail.designation}} 
						</p>
						<!-- <a href="{{actionSection}}/doctor/{{doctorDetail.slug}}"  class="knowmore2 active"><span>Know More</span></a> -->
						<a href="/all/doctor/{{pageType}}{{doctorDetail.slug}}"  class="knowmore2 active" (click)="DataLayerOnFormChange('BookingPage','BookingPage_SelectDoctor_KnowMore','Book-an-appointment')"><span>Know More</span></a>
					</div>
					<div class="dunderline">
						<img src="assets/images/underline.png" alt="" />
					</div>
					<div class="dselectlwrp" *ngIf="disableSelectOnAppointmentBook">
						<ul>
							<li *ngIf="speciltyList.length > 0">
								<p>Select Specialty</p>
								<div class="locationwrp " id="specialtywrp">
									<div class="selectbox">									
										<select class="select" name="txtDSpecilty" id="txtDSpecilty" (change)="onChangeSpecilty($event)">
											<option *ngFor="let specilty of speciltyList" [value]="specilty.speciality_uid">
												{{specilty.speciality}}
											</option>
										</select>
										<span id="selectDSpecilty3" class="select-span" *ngFor="let specilt of speciltyList">{{specilt.speciality}}</span>
										<!-- <span id="selectDSpecilty3" class="select-span"></span> -->
									</div>
									
								</div>
								<span class="error error_specialty"></span>
							</li>
							<li>
								<p>Location</p>
								<div class="locationwrp">
									<div class="selectbox">
										<select class="select" name="txtDLocation"
										 (change)="onChangeLocation($event)">                                       
										<ng-template [ngIf]="doctorDetail.centers">
											<option *ngFor="let centre of doctorDetail.centers" [value]="centre.id" [selected]="formModel.locationModel == centre.center_name_alias">
												{{centre.center_name_alias}}
											</option>
										</ng-template>
										<ng-template [ngIf]="doctorDetail.doctor_consultant_center">
											<option *ngFor="let centre of doctorDetail.doctor_consultant_center" [value]="centre.id">
												{{centre.center_name_alias}}
											</option>
										</ng-template>
                                    	</select>
										<span id="selectDlocation" class="select-span"></span>
									</div>
								</div>
							</li>
							
						</ul>
					</div>
					<!--<div class="dselectlwrp" style="display: none;">
						<ul>
							<li *ngIf="speciltyList.length > 0">
								<p>Select Specialty</p>
								<div class="locationwrp " id="specialtywrp">
									<div class="selectbox">
									
										<select class="select" #selectSpeciltyId [(ngModel)]="formModel.speciltyModel" name="txtDSpecilty" 
										(ngModelChange)="selectSpeciltyID(selectSpeciltyId)">
											<option value="0" >Select Specialty</option>
											<option *ngFor="let specilty of speciltyList" [value]="specilty.speciality_uid" [selected]="specilty.speciality == formModel.speciltyLabel">
												{{specilty.speciality}}
											</option>
										</select>
										<span id="selectDSpecilty" class="select-span">
											{{formModel.speciltyLabel}}
										</span>
									</div>
									
								</div>
								<span class="error error_specialty"></span>
							</li>
							<li>
								<p>Location</p>
								<div class="locationwrp">
									<div class="selectbox">
										<select class="select" #selectedLocationId [(ngModel)]="formModel.locationModel" name="txtDLocation"
										 (ngModelChange)="changeLocation(selectedLocationId.value)">
                                        <option value="0" [selected]="formModel.locationModel == 0">Select Location</option>
                                       
										<ng-template [ngIf]="doctorDetail.centers">
											<option *ngFor="let centre of doctorDetail.centers" [value]="centre.id">
												{{centre.center_name_alias}}
											</option>
										</ng-template>
										<ng-template [ngIf]="doctorDetail.doctor_consultant_center">
											<option *ngFor="let centre of doctorDetail.doctor_consultant_center" [value]="centre.id">
												{{centre.center_name_alias}}
											</option>
										</ng-template>
                                    	</select>
										<span id="selectDlocation" class="select-span"></span>
									</div>
								</div>
							</li>
							
						</ul>
					</div>-->
					<div class="daddresswrp" *ngIf="locationdetail">
						<h3 *ngIf="locationdetail.center_name">{{locationdetail.center_name}}</h3>
						<p *ngIf="locationdetail.address">
							{{locationdetail.address}}
						</p>
						<a *ngIf="locationdetail.lat_lng" href="https://www.google.com/maps?saddr=My+Location&daddr={{locationdetail.lat_lng}}" target="_blank" class="viewmap">View map <img src="assets/images/btn-arrow-right.png" alt="" /></a>
					</div>
				</div>
				<div class="col-md-12 col-sm-12 col-lg-3">					
					<div class="mcall">
						<h3><span>Give a miss call</span><br>1800 2122</h3>
					</div>
				</div>
				<div class="clearfix"></div>
				<div class="col-md-12 col-sm-12 col-lg-12 appointmentNoteWrp" *ngIf="AppointmentNoteNotAvailable">
					<div [innerHTML]="doctorDetail.appointmentnote"></div>
				</div>
				<div class="clearfix" *ngIf="slotNotAvailableAddBut"></div>
				<div class="col-md-12 col-sm-12 col-lg-12 appointmentButnWrp" *ngIf="slotNotAvailableAddBut">
					<a class="dbut" href="javascript:void(0);" (click)="PhysicalAndVideoConsultation()" style="display: none;">Physical & Video Consultation</a>
					<a class="dbut PhysicalConsultation active" href="javascript:void(0);"(click)="PhysicalConsultation()">Physical Consultation</a>
					<a class="dbut VideoConsultation" href="javascript:void(0);" (click)="VideoConsultation()" style="display: none;">Video Consultation</a>
				</div>
				<!--
				<div class="col-md-12 col-sm-12 col-lg-12 appointmentButnWrp" *ngIf="serviceNotAvailable || slotNotAvailable">
					<a class="dbut" href="javascript:void(0);" (click)="PhysicalAndVideoConsultation()" style="display: none;">Physical & Video Consultation</a>
					<a class="dbut PhysicalConsultation active" href="javascript:void(0);"(click)="PhysicalConsultation()">Physical Consultation</a>
					<a class="dbut VideoConsultation" href="javascript:void(0);" (click)="VideoConsultation()">Video Consultation</a>
				</div>
				-->
				<div class="clearfix"></div>
				<div class="timetablewrp">
					<div class="BATablewrp" *ngIf="serviceNotAvailable || slotNotAvailable">
						<table *ngIf="serviceNotAvailable">
							<tr>
								<th>Status</th>
							</tr>	
							<tr>
								<td>
									<p>Dear Patient, We might be facing a technical issue. Please re-attempt the scheduling process or you may Call 1800 2122 (Toll Free)</p>
									<p class="purple">or</p>
									<p>Download the Rainbow or BirthRight app from the <a title="IOS APP" target="_blank" href="https://itunes.apple.com/in/app/rainbow-childrens-hospital/id1132607946?mt=8">IOS</a> or 
										<a title="Android APP" target="_blank" href="https://play.google.com/store/apps/details?id=com.sparctechnologies.rainbow">Google Play store.</a></p>
									<p>
											<a href="javascript:void(0)"   (click)="closeModal()"  class="knowmore2 active"><span>Find Doctor</span></a>
									</p>
								</td>
							</tr>
						</table>
						<table *ngIf="sessionSlot.length == 0 && !serviceNotAvailable">
							<tr>
								<th>Status</th>
							</tr>	
							<tr>
								<td>
										<p>Online appointments for the selected doctor is not available. For details, Call: 1800 2122</p>
									<!--<p>We are extremely sorry. Appointment of this Doctor for the selected date and time is not available. <br/>
										We would request you to try booking an appointment for another doctor.  </p>										
										<a href="javascript:void(0)"   (click)="closeModal()"  class="knowmore2 active"><span>Find Doctor</span></a>-->
								</td>
							</tr>
						</table>
					</div>
					<div class="clearfix"></div>	
					<div class="BATablewrp" [hidden]="isShowDate">
						<day-slot  *ngIf="!slotNotAvailable && sessionSlot.length > 0" 
							[slot]='sessionSlot' 
							(closed)="closeModal()" 
							(slotDate)="pickerSelectedDate($event)">
						</day-slot>
					</div>
					<div class="ttheader" *ngIf="calenderVisible">
						<div class="boxwrp">
							<h3>{{selectedDate | amDateFormat:'ddd, DD MMM YYYY'}} </h3>
							<a href="javascript:;" (click)="navBack()"class="btndPrevious"><img src="assets/images/navprew.png" alt="" /> Back</a>
							<!-- <a href="javascript:;" (click)="prevDate(selectedDate)"class="btndPrevious"><img src="assets/images/navprew.png" alt="" /> Previous day</a>
							<a href="javascript:;" (click)="nextDate(selectedDate)"  class="btndNext">Next day <img src="assets/images/navnext.png" alt="" /></a> -->
						</div>
						<div class="timewrp" [hidden]="isShowCalendar" *ngIf="doctorSlot">
							
							<ul *ngIf="doctorSlot.length > 0" >
								<ng-template ngFor let-slot [ngForOf]="doctorSlot" #noSlotFullDay>
									
									<ng-template [ngIf]="calendarTime(slot.F) > curTime" [ngIfElse]="noSlotDateTime" #currentTimeChange>
										<li [attr.class]="slot.N | lowercase" >
											<a href="javascript:void(0);" (click)="appointmentPopup(slot.N, slot.F, slot.G)">
												{{slot.F | amDateFormat:'hh:mm A' }} - {{slot.G | amDateFormat:'hh:mm A'}}
											</a>
										</li>
									</ng-template>
									<ng-template #noSlotDateTime>
										<li class="booked">
											<a href="javascript:void(0);">
												No Slot
											</a>
										</li>
									</ng-template>
								</ng-template>
							</ul>
							<ul *ngIf="doctorSlot.length == 0">
								<div class="text-center">
										<p>Online appointments for the selected doctor is not available. For details, Call: 1800 2122</p>
									<!--<p>We are extremely sorry. Appointment of this Doctor for the selected date and time is not available. <br/>
											We would request you to try booking an appointment for another doctor.  </p>		
									<a href="javascript:void(0)"   (click)="closeModal()"  class="knowmore2 active"><span>Find Doctor</span></a>-->
								</div>
							</ul>
						</div>
					</div>
					<div class="dconfirmawrp patientNameList" >
						<div class="ttheader2">
							<h3>Confirm Appointment</h3>
							<p>This Mobile No. is already registered  with the below mentioned
								UHID(s). Please select the ID or add the new patient to proceed.</p>
							<div class="listBox">
								<div class="dRow">
									<div>Patient Name</div><div>UHID</div><div></div>
								</div>
								<div class="dRow" *ngFor="let Data of patientNameList">
									<div > <span class="name">{{Data.C}}</span> </div><div > <span class="id">{{Data.H}}</span> </div><div><img src="../../assets/images/continue.png" class="continue" (click)="continueBtnFn(Data)" alt="" srcset=""></div>
								</div>
							</div>
							<button class="addPatient" (click)="AddPatient()">Add Patient</button><br>
							<a href="javascript:void(0);" (click)="goBackToDateSelection()" class="btnPrevca"><img src="assets/images/btn-arrow-left.png" alt="" />&nbsp; Go back to Date selection</a>
							<p style="margin-top: 18px;">New Registration Charge  or Follow-Up rule may add up during the Add patient</p>
						</div>
					</div>					

					<div class="dconfirmawrp dconfirmawrp1">
						<div class="ttheader2">
							<span class="loader2" *ngIf="appointmentCheckAuth"></span>
							<h3>Confirm Appointment</h3>
							<form [formGroup]="CAppointmentRegistered">
								<ul>
									<li>
										<label>Patient/Visitor Name*</label>
										<input type="text" [ngClass]="{'has-error':!CAppointmentRegistered.controls.VisitorName.valid && CAppointmentRegistered.controls.VisitorName.touched}" name="VisitorName" id="txtVisitorName" formControlName="VisitorName" (keypress)="isletters($event)"	placeholder="Patient/Visitor Name*"	/>
									</li>
									<li>
										<label>Mobile*</label>
										<input type="text" [ngClass]="{'has-error':!CAppointmentRegistered.controls.VisitorMobile.valid && CAppointmentRegistered.controls.VisitorMobile.touched}" name="VisitorMobile" required="" maxlength="10"	id="txtMobile" (keyup)="onChangeMobile('Registered')" (keypress)="isNumber($event)" [readonly]="true" formControlName="VisitorMobile" placeholder="Mobile*" />
									</li>
									<li>
										<label>Email ID</label>
										<input type="email" maxlength="40" [ngClass]="{'has-error':!CAppointmentRegistered.controls.VisitorEmail.valid && CAppointmentRegistered.controls.VisitorEmail.touched}" name="VisitorEmail" id="txtEmaiID" formControlName="VisitorEmail" placeholder="Email ID" />
									</li>									
								</ul>
								<div class="signupwrp">
									<div class="tncnbcwrp">
										<ul>
											<li>
												<span class='custom-checkbox' [ngClass]="{'selected': selected == true,'':selected !=true}" (click)="onSelectCheckBoxTNC('Registered')"><input type="checkbox" name="confirmCheckbox" formControlName="registeredUserTNC" id="TNCA"  required/></span><label class="tnc">I understand, accept, and agree to the <a _ngcontent-c3="" (click)="tncModelOpen()" href="javascript:void(0);"><u>terms and conditions.</u></a>
													I hereby authorize Rainbow Hospitals and their authorised partners to contact me through email, phone or any other modes of communication.
													It will override my registry on NCPR</label>
											</li>
											<li>
												<a href="javascript:void(0);" (click)="goBackToDateSelection()" class="btnPrevca"><img src="assets/images/btn-arrow-left.png" alt="" />&nbsp; Go back to Date selection</a>
											</li>
										</ul>
									</div>
									<div class="submitbtnrp">
										<div *ngIf="!bookingDoctorFeesErrorStatus">
											<div *ngIf="!phybookingstatus">
												<input type="button" name="submit" class="casubmit" id="1caSubmit" value="Book Now" (click)="onClickBookNow()" />
											</div>
											<div *ngIf="phybookingstatus">
												<!--<p class="paymentparagraph">Pay Now and Get Discounts!</p>-->
												<p *ngIf="bookingDoctorFeesStatus" class="ConsultationCharges">Consultation Charges: Rs {{apiDoctorFees}}</p>
												<input type="hidden" name="slotStartDate" value="{{slotStartDate}}" formControlName="sSelectedSlot">
												<input type="hidden" name="slotEndDate" value="{{slotEndDate}}}" formControlName="sSlotEndTime">
												<input type="hidden" name="sComments" value="{{sComments}}}" formControlName="sComments">
												<div *ngIf="bookingDoctorFeesStatus">
													<div *ngIf="!isDoctorFees">
														<input type="button" name="submit" class="casubmit" id="1caSubmit" value="Pay Now" (click)="onClickRegisteredPatient('paynow')" />
														<input type="button" *ngIf="appointmentType" name="submit" class="casubmit" id="1caSubmit" value="Pay Later" (click)="onClickRegisteredPatient('paylater')" />
													</div>
													<div *ngIf="isDoctorFees">
														<input type="button" name="submit" class="casubmit" id="1caSubmit" value="Book Now" (click)="onClickBookNow()" />
													</div>
												</div>
											</div>
										</div>
										<div *ngIf="bookingDoctorFeesErrorStatus">
											<p class="paymentparagraph">OPSS!! there is some issue at the booking--kindly coordinate with the call center at 18002122 </p>
										</div>
										<!--<div *ngIf="!isDoctorFees">
											<input type="button" name="submit" class="casubmit" id="1caSubmit" value="Pay Now" [disabled]="!CAppointmentRegistered.valid" (click)="onClickRegisteredPatient('paynow')" />
											<input type="button" *ngIf="appointmentType" name="submit" class="casubmit" id="1caSubmit" value="Pay Later" [disabled]="!CAppointmentRegistered.valid" (click)="onClickRegisteredPatient('paylater')" />
										</div>
										<div *ngIf="isDoctorFees">
											<input type="button" name="submit" class="casubmit" id="1caSubmit" value="Book Now" (click)="onClickBookNow()" />
										</div>-->
										<!--<input type="button" name="submit" class="casubmit" id="1caSubmit" value="Submit" [disabled]="!CAppointmentRegistered.valid" (click)="registeredPatient()" />-->
									</div>
								</div>
							</form>
						</div>
					</div>
					<div class="dconfirmawrp2">
						<div class="ttheader2" style="position: relative;">
							<span class="loader2" *ngIf="appointmentCheckAuth"></span>
							<h3>Confirm Appointment</h3>
							<form [formGroup]="CNewAppointmentRegistered">
								<ul class="formcasn">
									<li>
										<label>Patient/Visitor Name*</label>
										<input type="text" name="VisitorName" id="txtVisitorNameSN" placeholder="Patient/Visitor Name*" [ngClass]="{'has-error':!CNewAppointmentRegistered.controls.VisitorName.valid && CNewAppointmentRegistered.controls.VisitorName.touched}" formControlName="VisitorName"  (keypress)="isletters($event)" />
									</li>
									<li>
										<label>Mobile*</label>
										<input type="text" name="VisitorMobile" maxlength="10" id="txtMobileSN" placeholder="Mobile*" [ngClass]="{'has-error':!CNewAppointmentRegistered.controls.VisitorMobile.valid && CNewAppointmentRegistered.controls.VisitorMobile.touched}" required="" maxlength="10" (keyup)="onChangeMobile('new')" (keypress)="isNumber($event)" formControlName="VisitorMobile" />
									</li>
									<li>
										<label>Email ID</label>
										<input type="email" maxlength="40" name="VisitorEmail" id="txtEmaiIDSN" placeholder="Email ID" [ngClass]="{'has-error':!CNewAppointmentRegistered.controls.VisitorEmail.valid && CNewAppointmentRegistered.controls.VisitorEmail.touched}" formControlName="VisitorEmail" />
									</li>
									<li class="bdcalender">
										<label>Birth Date</label>
										<input type="text" class="datepickr2" name="VisitorDOB" id="txtBirthDateSN" placeholder="DD/MM/YYYY" [ngClass]="{'has-error':!CNewAppointmentRegistered.controls.VisitorDOB.valid && CNewAppointmentRegistered.controls.VisitorDOB.touched}" (blur)="onDateChange($event)" formControlName="VisitorDOB" />
									</li>
									<li>
										<label>Mother Name*</label>
										<input type="text" maxlength="35" name="VisitorMotherName" id="txtMNameSN" placeholder="Enter Mother`s Name*" [ngClass]="{'has-error':!CNewAppointmentRegistered.controls.VisitorMotherName.valid && CNewAppointmentRegistered.controls.VisitorMotherName.touched}" formControlName="VisitorMotherName" />
									</li>
									<li>
										<label>Source*</label>
										<div class="selectbox" [ngClass]="{'has-error':!CNewAppointmentRegistered.controls.sourceName.valid && CNewAppointmentRegistered.controls.sourceName.touched}">
											<select class="select" [(ngModel)]="sourceModel" [ngClass]="{'has-error':!CNewAppointmentRegistered.controls.sourceName.valid && CNewAppointmentRegistered.controls.sourceName.touched}" name="sourceName" id="txtSourceSN" formControlName="sourceName">
											 	<option value="0" [selected]="sourceModel == 0">Source</option>
												<option *ngFor="let source of leadSource;let i= index;" [value]="source.id">
													{{source.title}}
												</option>
                                    		</select>
											<span id="selectSource" class="select-span"></span>
										</div>
									</li>
								</ul>
								<div class="signupwrp">
									<div class="tncnbcwrp">
										<ul>
											<li>
												<span class='custom-checkbox' [ngClass]="{'selected': selected == true,'':selected !=true}" ><input type="checkbox" name="confirmCheck" formControlName="newUserTNC" id="SNTNCA" (click)="onSelectCheckBoxTNC('new')"  required/></span><label>I understand, accept, and agree to the <a _ngcontent-c3=""  (click)="tncModelOpen()" href="javascript:void(0);"><u>terms and conditions.</u></a>
													I hereby authorize Rainbow Hospitals and their authorised partners to contact me through email, phone or any other modes of communication.
													It will override my registry on NCPR</label>												
											</li>
											<li>
												<a href="javascript:void(0);" (click)="goBackToDateSelection()" class="btnPrevca"><img src="assets/images/btn-arrow-left.png" alt="" />&nbsp; Go back to Date selection</a>
											</li>
										</ul>
									</div>
									<div class="submitbtnrp">
										<div *ngIf="!bookingDoctorFeesErrorStatus">
											<div *ngIf="!phybookingstatus">
												<input type="button" name="submit" class="casubmit" id="1caSubmit" value="Book Now" (click)="onClickBookNow()" />
											</div>
											<div *ngIf="phybookingstatus">
												<!--<p class="paymentparagraph">Pay Now and Get Discounts!</p>-->											
												<p *ngIf="bookingDoctorFeesStatus" class="ConsultationCharges">Consultation Charges: Rs {{apiDoctorFees}}</p>
												<input type="hidden" name="slotStartDate" value="{{slotStartDate}}" formControlName="sSelectedSlot">
												<input type="hidden" name="slotEndDate" value="{{slotEndDate}}}" formControlName="sSlotEndTime">
												<input type="hidden" name="sComments" value="{{sComments}}}" formControlName="sComments">
												<div *ngIf="bookingDoctorFeesStatus">
													<div *ngIf="!isDoctorFees">
														<input type="button" name="submit" class="casubmit" id="1caSubmit" value="Pay Now" (click)="onClickNewPatient('paynow')" />
														<input type="button" *ngIf="appointmentType" name="submit" class="casubmit" id="1caSubmit" value="Pay Later" (click)="onClickNewPatient('paylater')" />
													</div>
													<div *ngIf="isDoctorFees">
														<input type="button" name="submit" class="casubmit" id="1caSubmit" value="Book Now" (click)="onClickBookNow()" />
													</div>
												</div>
											</div>
										</div>
										<div *ngIf="bookingDoctorFeesErrorStatus">
											<p class="paymentparagraph">OPSS!! there is some issue at the booking--kindly coordinate with the call center at 18002122 </p>
										</div>
										<!--<div *ngIf="!isDoctorFees">
											<input type="button" name="submit" class="casubmit" id="1caSubmit" value="Pay Now" [disabled]="!CNewAppointmentRegistered.valid" (click)="onClickNewPatient('paynow')" />
											<input type="button" *ngIf="appointmentType" name="submit" class="casubmit" id="1caSubmit" value="Pay Later" [disabled]="!CNewAppointmentRegistered.valid" (click)="onClickNewPatient('paylater')" />
										</div>
										<div *ngIf="isDoctorFees">
											<input type="button" name="submit" class="casubmit" id="1caSubmit" value="Book Now" (click)="onClickBookNow()" />
										</div>-->
										<!--<input type="button"name="submit" class="casubmit" id="1caSubmit" value="Submit" [disabled]="!CNewAppointmentRegistered.valid" (click)="newPatient()"  />-->
									</div>
								</div>
							</form>
						</div>
					</div>
					<div class="dconfirmadresswrp" style="position: relative;">
						<!--<span class="loader2" *ngIf="paymentBtn"></span>-->
						<div class="adrswrp">
							<h3>Appointment Summary</h3>
							<p>Thank you for choosing Rainbow Hospital as you healthcare provider. Your appointment details are given below.</p>
							<ul class="adrsdetails" *ngIf="locationdetail">
								<li><strong>Appointment  ID:</strong> {{BookingNumber}}</li>
								<li><strong>Preferred Time:</strong> {{slotStartDate}}</li>
								<li><strong>Registration No:</strong> {{LoggedInName}}</li>
								<li><strong>Dr. Name:</strong>{{doctorDetail.name}}</li>
								<li><strong>Center:</strong>{{locationdetail.center_name}}</li>
							</ul>
							<div class="paymentBTNWrp" style="display:none">                    
								<div class="payWrp">									
									<p class="paymentparagraph">Pay Now and Get Discounts!</p>
									<div class="dFeesWrp">								
										<div class="feeswrp">
											Consultation Charges: Rs {{doctorDetail.fees}}
										</div>
										<div class="payBtnWrp">
											<a href="javascript:void(0);" (click)="PayNow()">Pay Now</a>
											<a href="javascript:void(0);" (click)="PayLater()">Pay Later</a>
										</div>										
									</div>
									<div class="tandc" style="margin-top: 2px;">
										<a class="cntnc" href="javascript:void(0);" (click)="CancellationPolicy()">Cancellation Policy</a>|
										<a class="cntnc" href="javascript:void(0);" (click)="CancellationTC()">T&C</a>
									</div>
								</div>
							</div>
							<form #formPayment ngNoForm id="EMA" method="post" name="redirect" action="https://www.rainbowhospitals.in/payrequest/">
								<input type="hidden" name="patient_name" value="{{patientName}}">
								<input type="hidden" name="doctor_fees" value="{{apiDoctorFeesNew}}">
								<input type="hidden" name="patient_email" value="{{patientEmail}}">
								<input type="hidden" name="patient_mobile" value="{{patientMobile}}">
								<input type="hidden" name="appointment_number" value="{{BookingNumber}}">
								<input type="hidden" name="doctor_unit_name" value="{{ centerName }}">
								<input type="hidden" name="doctor_name" value="{{doctorDetail.name}}">
								<input type="hidden" name="unitcode" value="{{centerUid}}">
								<input type="hidden" name="patient_uid" value="{{Patient_UID}}">
								<input type="hidden" name="action" value="payAction">
							</form>
							<!-- <div class="rfdetails">
								<p>Do you want to enter full registration details? Which will save your time in the Hospital.</p>
								<a href="javascript:void(0)" class="btns2">Register Full Details</a>
							</div>
							<div class="Notewrp">
								<strong>Note:</strong>
								<p>Please ensure that the patient reachecs the hospital 30 minutes before the appointment. if the patient is late by
									more than 30 minutes then you will be taken as Non Appointment patient.</p>
							</div> -->
						</div>
					</div>

					<div class="capopup" data-popup="popup-cancelTnC">
						<div class="capopup-inner-tcn">
							<h2>Terms and Conditions</h2>
							<div class="contentwrp">
								<p>All the information displayed, transmitted or carried by Rainbow Children’s Medicare Private Limited and its subsidiaries (hereinafter called Rainbow Hospital) including, but not limited to, news articles, opinions, reviews, text, photographs, images, illustrations, profiles, audio clips, video clips, trademarks, service marks and others (collectively the "Content,") on this Rainbow Hospital’s website is for informational purposes only.</p>
							</div>
							<a class="otp-popup capopup-close" data-popup-close="popup-cancelTnC" href="javascript:void(0);" (click)="closePopup()"><img src="assets/images/icon-close2.png" alt="" /></a>
						</div>
					</div>
					<div class="capopup" data-popup="popup-cancelpolicy">
						<div class="capopup-inner-tcn">
							<h2>Cancellation Policy</h2>
							<div class="contentwrp">
								<p>Our goal is to provide quality medical care in a timely manner. In order to do so we have had to implement an
				appointment/cancellation policy. The policy enables us to do better utilize available appointments for our patients in need
				of medical care.</p>
								<ol>
									<li>
										<p><strong>Cancellation/No Show Policy for Doctor Appointment</strong></p>
										<p>We understand that there are times when you must miss an appointment due to emergencies or obligations for
										work or family. However, when you do not call to cancel an appointment, you may be preventing another patient
										from getting much needed treatment. Conversely, the situation may arise where another patient fails to cancel and we
										are unable to schedule you for a visit, due to a seemingly "full" appointment book.</p>
				
										<p>A "no show" is someone who misses an appointment without canceling it within a 24 hour working day in
										advance. No-shows inconvenience those individuals who need access to medical care in a timely manner.</p>
										<br>
										<p><strong>How To Cancel Your Appointment</strong></p>
				
										<p>If it is necessary to cancel your scheduled appointment, we require that you call one working day in advance.
										Appointments are high in demand, and your early cancellation will give another person the possibility to have access to
										timely medical care.</p>
									</li>
									<li>
										<p><strong> Scheduled Appointments</strong></p>
										<p>We understand that delays can happen, however, we must try to keep the other patients and doctors on time. If
											you are running late, please notify the office.</p>
									</li>
								</ol> 
							</div>
							<a class="otp-popup capopup-close" data-popup-close="popup-cancelpolicy" href="javascript:void(0);" (click)="closePopup()"><img src="assets/images/icon-close2.png" alt="" /></a>
						</div>
					</div>
					
					<!--  Start Model Popup Login -->
					<div class="capopup" data-popup="popup-paylater">
						<div class="capopup-inner">
							<h2>Kindly make the payment at the hospital payment desk, on your appointment day.</h2>
							<a class="capopup-close" data-popup-close="popup-paylater" href="javascript:void(0);"><img src="assets/images/icon-close2.png" alt="" /></a>
						</div>						
					</div>
					<div class="cmpopup" *ngIf="tncModelshow">
						<div class="inner_body">
							<div class="m_header">
								<a href="javascript:void(0);" (click)="tncModelClose()" class="m_close">X</a>
								<h2 class="textgradient">Terms &amp; Conditions</h2>
							</div>
							<div class="bodytext">
								<p>The online medical opinion services available on our website (the "Services") does not in any way constitute an invitation or recommendation to avail our teleconsultation services. The services provided through our website are not intended in any way to be considered as a substitute for face to face consultation with a doctor. We, Rainbow Children's Medicare Pvt Ltd advises the users to make independent assessment in respect of its accuracy or usefulness and suitability prior to making any decision in reliance hereof. Terms 'you' and 'your' hereafter refers to the user both registered and unregistered who is using/browsing through our website and/or availing any of our Services.</p>
								<p>By submitting this registration form, you hereby agree to avail the Services provided by Rainbow Children’s Hospitals Healthcare Service Private Limited ("Company") and/or its affiliates on such terms and conditions as described below:</p>
								
								<p><strong>1.	Informed Consent</strong></p>
								<p>You understand and acknowledge that there will not be any physical examination involved and the consultation services shall be provided remotely. The opinion delivered by the doctor / physician shall solely be based on the verbal communication between the concerned doctor or the physician and the patient and the test reports and other information provided/uploaded by you on the website.</p>
								<p>You hereby consent to the use of telephonic and/or electronic communications (including audio, video and written communications) through any media by our doctors and our representatives to contact you using the contact information provided to us by you and to provide our services to you remotely.</p>
								<p>You hereby consent to the recording of all communications (including audio, video and written communications) with our doctors and our representatives.</p>
								<p>I hereby authorize Rainbow Children’s Medicare Pvt Ltd (Rainbow Hospitals) and their authorised Partners to call me on the contact number made available by me on the website with a specific request to call back. I further declare that, irrespective of my contact number being registered on National Customer Preference Register (NCPR) or on National Do Not Call Registry (NDNC), any call made or SMS sent in response to my request shall not be construed as an Unsolicited Commercial Communication even though the content of the call may be for the purposes of providing information about Rainbow Hospitals, its services or for appointment and consultation.</p>
								<p>You hereby consent to the recording, saving and storing of all information pertaining to you provided to us or obtained during the course of providing the service, including without limitation, personal contact information, medical history, and laboratory reports, communications with our doctors and representatives, and other patient records. All such information shall be treated as confidential information by us, in accordance with our privacy policy and as per applicable laws.</p>

								<p><strong>2.	Patient's Undertaking</strong></p>
								<p>You are above the age of 18 years and legally competent to enter into this agreement with us to avail our services;</p>
								<p>The execution of this agreement and your use of our services are not in violation of any applicable law or third-party contracts executed by you;</p>
								<p>The service provided by our doctors is based solely on the information furnished to them by you and neither we nor our doctors will be able to countercheck the reliability of the information provided by you. Accordingly, you hereby undertake that (i) all information, including medical, health, diagnostic and personal information, the registration form and information shared during your communications with our doctors and our representatives, is complete, true and accurate; (ii) no information has been intentionally omitted / withheld; and (iii) no incomplete / misleading / fraudulent / inaccurate information has been / shall be provided.</p>
								<p><strong>3.	Limitation of liability</strong></p>
								<p>The Company, its affiliates, doctors, representatives or employees shall not be liable for any deficiency in service or loss, damages or adverse impact you may have incurred due to the breach of one or more of the terms and conditions (including the undertakings) by you.</p>
								<p>The Company, its affiliates, doctors, representatives or employees shall not be liable for any error in diagnosis, delay in providing the service or deficiency in service due to the occurrence of sub optimal technical conditions (including without limitation, poor network connectivity, power shutdown, service downtime, scheduled maintenance work by the internet service providers.</p>
								<p>The Company or its doctors or representatives will not be responsible for any misunderstanding or misinterpretation of any advice given by the doctors during the Service or of any terms and conditions herein.</p>
								<p>The Company or its doctors or representatives will not be liable for misdiagnosis / faulty judgment / interpretation error / perception error / Adverse events / inefficacy of prescribed treatment or advice / validity of the advice or prescription provided by the consulting doctor in your jurisdiction of residence / unavailability of the recommended or prescribed treatment or medication under any condition or circumstances. Users are advised to use their discretion for following the advice obtained post consultation from our Online Medical Opinion platform.</p>
								<p>In no event shall The Company or its doctors and representatives be liable for any direct, indirect, punitive, incidental, special or consequential damages or any damages whatsoever including without limitations, damages for loss of use, data or profits, arising out of or in any way connected with the use of our Services.</p>
								<p>Our Services are not meant in any way for emergency and life-threatening conditions. It is advised to take the patient to the nearest hospital in such cases.</p>

								<p><strong>4.	Booking, Rescheduling and Cancellation of Consultation Sessions</strong></p>
								<p>All consultation sessions shall be scheduled between 9am-2:30pm on all working days in all cities of operation. The time provided for consultation to you is indicative and actual consultation time may change depending on the consulting Doctor's discretion. The Company, its doctors and its representatives reserves the right to refuse the service to any user without providing any reason.</p>
								<p>All sessions are booked on a first-come basis. Therefore, you are requested to book your sessions in advance.</p>
								<p>Please ensure that you are equipped with proper video conference equipment and internet connectivity as mentioned or is relevant for the service you want to avail. In case of inadequate video conference equipment, technical error or defective internet connectivity the Company reserves the right to reschedule or cancel your appointment without any notice</p>

								<p><strong>5.	Indemnity</strong></p>
								<p>You agree and undertake to indemnify and keep indemnified the Company, its doctors and its representatives as well as Rainbow Children’s Hospitals ("Indemnified Parties") for any losses, costs, charges and expenses including reasonable attorney fees that the concerned doctor/physician and Indemnified Parties may suffer on account of (a)misdiagnosis / faulty judgment /interpretation errors / perception error arising from (i)your failure to provide correct and / or complete clinical information / history about the patient in timely and clinically appropriate manner; or (ii)suppression of material facts; or your failure to provide relevant clinical information about the patient; or (iv) misinterpretation of the advice / prescription / diagnosis by you; or (v) failure to follow doctor's advice / prescription by you; or (b) incorrect or inaccurate credit / debit card details provided by you; or (c) using a credit / debit card which is not lawfully owned by you; or (d) if you permit a third party to use your password or other means to access your account</p>

								<p><strong>6.	International Patients</strong></p>
								<p>If you are an international patient, </p>
								<ol>
									<li>Consultancy with our doctors can happen in the presence of your local doctor. Any prescription advised by our expert doctors shall have to be written by your local doctor on his/her on the prescription pad <br> Or</li>
									<li>In case you have already underwent treatment in any of our Hospital units, then  you can avail follow ups services through video consultancy <br> Or</li>
									<li>In case your local doctor refers you to our Hospital, then, you can avail services of our doctors through  consultancy in the presence of your local doctor <br> Or</li>
									<li>In case of second opinion, our Doctor solely relies on the diagnostic reports and other medical reports shared by you and your local doctor.</li>
								</ol>
								<p><strong>7.	Miscellaneous</strong></p>
								<ol>
									<li>This Agreement may be terminated by either party at any time, with or without cause</li>
									<li>This agreement shall be governed by the laws of India and shall be subject to the exclusive jurisdiction of the courts at Hyderabad</li>
									<li>By visiting our website you acknowledge, accept and expressly authorize the Company for the placement of Cookies on your web browser;</li>
									<li>The Company may at any time, without any prior notification to you, modify these terms of conditions. Please review the latest version of the terms and conditions before proceeding to avail the service</li>
									<li>If any part of the agreement is determined to be invalid or unenforceable pursuant to applicable law then the said provision will be superseded by a valid, enforceable provision and the remainder of the agreement shall continue in effect</li>
								</ol>
							</div>
						</div>
					</div>
					<div id="tncmodal" class="tncmodal" style="display: none;">
						<div class="tncmodalwrp">
							<h2>Terms &amp; Conditions</h2>
							<p>The online medical opinion services available on our website (the "Services") does not in any way constitute an invitation or recommendation to avail our teleconsultation services. The services provided through our website are not intended in any way to be considered as a substitute for face to face consultation with a doctor. We, Rainbow Children's Medicare Pvt Ltd advises the users to make independent assessment in respect of its accuracy or usefulness and suitability prior to making any decision in reliance hereof. Terms 'you' and 'your' hereafter refers to the user both registered and unregistered who is using/browsing through our website and/or availing any of our Services.</p>
							<p>By submitting this registration form, you hereby agree to avail the Services provided by Rainbow Children’s Hospitals Healthcare Service Private Limited ("Company") and/or its affiliates on such terms and conditions as described below:</p>
							
							<p><strong>1.	Informed Consent</strong></p>
							<p>You understand and acknowledge that there will not be any physical examination involved and the consultation services shall be provided remotely. The opinion delivered by the doctor / physician shall solely be based on the verbal communication between the concerned doctor or the physician and the patient and the test reports and other information provided/uploaded by you on the website.</p>
							<p>You hereby consent to the use of telephonic and/or electronic communications (including audio, video and written communications) through any media by our doctors and our representatives to contact you using the contact information provided to us by you and to provide our services to you remotely.</p>
							<p>You hereby consent to the recording of all communications (including audio, video and written communications) with our doctors and our representatives.</p>
							<p>I hereby authorize Rainbow Children’s Medicare Pvt Ltd (Rainbow Hospitals) and their authorised Partners to call me on the contact number made available by me on the website with a specific request to call back. I further declare that, irrespective of my contact number being registered on National Customer Preference Register (NCPR) or on National Do Not Call Registry (NDNC), any call made or SMS sent in response to my request shall not be construed as an Unsolicited Commercial Communication even though the content of the call may be for the purposes of providing information about Rainbow Hospitals, its services or for appointment and consultation.</p>
							<p>You hereby consent to the recording, saving and storing of all information pertaining to you provided to us or obtained during the course of providing the service, including without limitation, personal contact information, medical history, and laboratory reports, communications with our doctors and representatives, and other patient records. All such information shall be treated as confidential information by us, in accordance with our privacy policy and as per applicable laws.</p>

							<p><strong>2.	Patient's Undertaking</strong></p>
							<p>You are above the age of 18 years and legally competent to enter into this agreement with us to avail our services;</p>
							<p>The execution of this agreement and your use of our services are not in violation of any applicable law or third-party contracts executed by you;</p>
							<p>The service provided by our doctors is based solely on the information furnished to them by you and neither we nor our doctors will be able to countercheck the reliability of the information provided by you. Accordingly, you hereby undertake that (i) all information, including medical, health, diagnostic and personal information, the registration form and information shared during your communications with our doctors and our representatives, is complete, true and accurate; (ii) no information has been intentionally omitted/withheld; and (iii) no incomplete/misleading/fraudulent/inaccurate information has been/shall be provided.</p>
							<p><strong>3.	Limitation of liability</strong></p>
							<p>The Company, its affiliates, doctors, representatives or employees shall not be liable for any deficiency in service or loss, damages or adverse impact you may have incurred due to the breach of one or more of the terms and conditions (including the undertakings) by you.</p>
							<p>The Company, its affiliates, doctors, representatives or employees shall not be liable for any error in diagnosis, delay in providing the service or deficiency in service due to the occurrence of sub optimal technical conditions (including without limitation, poor network connectivity, power shutdown, service downtime, scheduled maintenance work by the internet service providers.</p>
							<p>The Company or its doctors or representatives will not be responsible for any misunderstanding or misinterpretation of any advice given by the doctors during the Service or of any terms and conditions herein.</p>
							<p>The Company or its doctors or representatives will not be liable for misdiagnosis / faulty judgment / interpretation error / perception error/Adverse events/ inefficacy of prescribed treatment or advice/validity of the advice or prescription provided by the consulting doctor in your jurisdiction of residence/ unavailability of the recommended or prescribed treatment or medication under any condition or circumstances. Users are advised to use their discretion for following the advice obtained post consultation from our Online Medical Opinion platform.</p>
							<p>In no event shall The Company or its doctors and representatives be liable for any direct, indirect, punitive, incidental, special or consequential damages or any damages whatsoever including without limitations, damages for loss of use, data or profits, arising out of or in any way connected with the use of our Services.</p>
							<p>Our Services are not meant in any way for emergency and life-threatening conditions. It is advised to take the patient to the nearest hospital in such cases.</p>

							<p><strong>4.	Booking, Rescheduling and Cancellation of Consultation Sessions</strong></p>
							<p>All consultation sessions shall be scheduled between 9am-2:30pm on all working days in all cities of operation. The time provided for consultation to you is indicative and actual consultation time may change depending on the consulting Doctor's discretion. The Company, its doctors and its representatives reserves the right to refuse the service to any user without providing any reason.</p>
							<p>All sessions are booked on a first-come basis. Therefore, you are requested to book your sessions in advance.</p>
							<p>Please ensure that you are equipped with proper video conference equipment and internet connectivity as mentioned or is relevant for the service you want to avail. In case of inadequate video conference equipment, technical error or defective internet connectivity the Company reserves the right to reschedule or cancel your appointment without any notice</p>

							<p><strong>5.	Indemnity</strong></p>
							<p>You agree and undertake to indemnify and keep indemnified the Company, its doctors and its representatives as well as Rainbow Children’s Hospitals ("Indemnified Parties") for any losses, costs, charges and expenses including reasonable attorney fees that the concerned doctor/physician and Indemnified Parties may suffer on account of (a)misdiagnosis / faulty judgment /interpretation errors / perception error arising from (i)your failure to provide correct and / or complete clinical information / history about the patient in timely and clinically appropriate manner; or (ii)suppression of material facts; or your failure to provide relevant clinical information about the patient; or (iv) misinterpretation of the advice / prescription / diagnosis by you; or (v) failure to follow doctor's advice / prescription by you; or (b) incorrect or inaccurate credit / debit card details provided by you; or (c) using a credit / debit card which is not lawfully owned by you; or (d) if you permit a third party to use your password or other means to access your account</p>

							<p><strong>6.	International Patients</strong></p>
							<p>If you are an international patient, </p>
							<ol>
								<li>Consultancy with our doctors can happen in the presence of your local doctor. Any prescription advised by our expert doctors shall have to be written by your local doctor on his/her on the prescription pad <br> Or</li>
								<li>In case you have already underwent treatment in any of our Hospital units, then  you can avail follow ups services through video consultancy <br> Or</li>
								<li>In case your local doctor refers you to our Hospital, then, you can avail services of our doctors through  consultancy in the presence of your local doctor <br> Or</li>
								<li>In case of second opinion, our Doctor solely relies on the diagnostic reports and other medical reports shared by you and your local doctor.</li>
							</ol>
							<p><strong>7.	Miscellaneous</strong></p>
							<ol>
								<li>This Agreement may be terminated by either party at any time, with or without cause</li>
								<li>This agreement shall be governed by the laws of India and shall be subject to the exclusive jurisdiction of the courts at Hyderabad</li>
								<li>By visiting our website you acknowledge, accept and expressly authorize the Company for the placement of Cookies on your web browser;</li>
								<li>The Company may at any time, without any prior notification to you, modify these terms of conditions. Please review the latest version of the terms and conditions before proceeding to avail the service</li>
								<li>If any part of the agreement is determined to be invalid or unenforceable pursuant to applicable law then the said provision will be superseded by a valid, enforceable provision and the remainder of the agreement shall continue in effect</li>
							</ol>
						</div>
					</div>

					<div class="capopup active" *ngIf="BookingMobile" >
						
						<div class="capopup-inner">
							<h2>Booking</h2>
							<h3>Please enter your Mobile No. to book an appoinment.</h3>
							<a class="capopup-close" (click)="appointmentPopupMobileClose();"><img src="assets/images/icon-close2.png" alt="" /></a>
							
							<div class="col-12">
								<form id="mobileNoForm">
									<label for="">Mobile*</label>
									<div class="form-group">
										<input type="text" name="mobileNo" class="form-control" id="mobileNo" (keyup)="numberOnly($event)"  maxlength="10">
									</div>
								</form>
																
							</div>
							<div class="col-12">
								<button class="submit" (click)="submitForm()">SUBMIT</button>
							</div>
							</div>

					</div>
					
					<div class="capopup" data-popup="popup-22">
						<div class="capopup-inner">
							<h2>Booking</h2>
							<h3>Do you want to Book appointment as <strong>Existing Patient</strong> or <strong>New Patient</strong></h3>
							<div class="enlinkswrp">
								<ul>
									<li>
										<a href="javascript:void(0)" class="ep" (click)="changeStatus('existing')">
											Existing Patient
										</a>
									</li>
									<li><a href="javascript:void(0)" (click)="changeStatus('new')" id="NewPatient">New Patient</a></li>
								</ul>
							</div>
							<a class="capopup-close" data-popup-close="popup-22" href="#"><img src="assets/images/icon-close2.png" alt="" /></a>
						</div>
					</div>
					<div class="capopup" data-popup="popup-invalidrecipt">
						<div class="capopup-inner">
							<h2>Error</h2>
							<p>Due to some technical errors the appointment hasn’t been confirmed. Please retry.</p>
							<div class="resendlinkwrp">
								<a href="javascript:void(0);" (click)="closeModal()">Retry</a>
							</div>
							<a class="capopup-close" data-popup-close="popup-invalidrecipt" href="#"><img src="assets/images/icon-close2.png" alt="" /></a>
						</div>
						
					</div>
					<div class="capopup" data-popup="popup-verifyAppointment">
						<div class="capopup-inner">							
							<p>{{BookingStatus}}</p>
							<a class="capopup-close" data-popup-close="popup-verifyAppointment" href="#"><img src="assets/images/icon-close2.png" alt="" /></a>
						</div>
					</div>
					<div class="capopup" data-popup="popup-timeSloteErrorMsg">
						<div class="capopup-inner">
							<h2>We are extremely sorry.</h2>
							<p>Appointment of this Doctor for the selected date and time is not available. Please select different slot. </p>
							<p style="text-align: center;"><a class="gobackBtn" href="javascript:void(0)" (click)="gobacktoDateSelection()">Go back to Date selection</a></p>
							<a class="capopup-close" data-popup-close="popup-timeSloteErrorMsg" (click)="gobacktoDateSelection()" href="#"><img src="assets/images/icon-close2.png" alt="" /></a>
						</div>
					</div>
					<div class="capopup" data-popup="popup-loginErrorMsg">
						<div class="capopup-inner">
							<!--<h2>{{loginTitle}}</h2>-->
							<p>{{loginMsg}}</p>
							<a class="capopup-close" data-popup-close="popup-loginErrorMsg" href="#"><img src="assets/images/icon-close2.png" alt="" /></a>
						</div>
					</div>
					<div class="capopup" data-popup="popup-loginmsg">
						<div class="capopup-inner">
							<!--<h2>{{loginTitle}}</h2>-->
							<p>{{loginMsg}}</p>
							<a class="capopup-close" data-popup-close="popup-loginmsg" href="#"><img src="assets/images/icon-close2.png" alt="" /></a>
						</div>
					</div>	
					<div class="capopup" data-popup="popup-noslot">
						<div class="capopup-inner">
							<h2>Out of time</h2>
							<p>Out of time for booking this slot</p>
							<a class="capopup-close" data-popup-close="popup-noslot" href="#"><img src="assets/images/icon-close2.png" alt="" /></a>
						</div>

					</div>
					<div class="capopup" data-popup="popup-bStatus">
						<div class="capopup-inner">							
							<p>Appointment already exists for the patient in the selected DateTime. Please select different slot. </p>
							<a class="capopup-close" data-popup-close="popup-bStatus" href="#"><img src="assets/images/icon-close2.png" alt="" /></a>
						</div>
					</div>
					<div class="capopup" data-popup="popup-notimeslot">
						<div class="capopup-inner">
							
							<p>We are extremely sorry.<br/> Appointment of this Doctor for the selected date and time is not available.  </p>
							<a class="capopup-close" data-popup-close="popup-notimeslot" href="#"><img src="assets/images/icon-close2.png" alt="" /></a>
						</div>

					</div>
					<div class="capopup" data-popup="popup-sendotp">
						<div class="capopup-inner" style="position: relative;">
							<span class="loader2" *ngIf="PopupPayNowPayLaterLoader"></span>
							<h2>Login</h2>
							<h3>We have sent you an OTP on {{otpMobileNumber}}</h3>
							<span class="otpMsg" *ngIf="otpMsg.length > 0">{{otpMsg}}</span>
							<form name="CAppointment" id="Appointment" method="post" [formGroup]="CSendOTPForm">
								<ul class="otpwrp">
									<li><input type="text" name="userOTPCode" id="txtOtp" maxlength="4" placeholder="Enter OTP" 
										[ngClass]="{'has-error':!CSendOTPForm.controls.userOTPCode.valid && CSendOTPForm.controls.userOTPCode.touched}"
										required="" maxlength="4" (keypress)="isNumber($event)" formControlName="userOTPCode" />
									</li>
									<!--<li [ngClass]="appointmentType? 'physicalType':'videoType'" >-->
									<li>
										<!--<input type="button" name="submit" class="casubmit" id="OtpSubmit" value="PayNow" [disabled]="!CSendOTPForm.valid" (click)="confirmOTPWithPayNow('paynow')" />
										<input type="button" name="submit" class="casubmit" id="OtpSubmit" value="PayLater" [disabled]="!CSendOTPForm.valid" (click)="confirmOTPWithPayNow('paylater')" *ngIf="appointmentType" />-->
										<input type="button" name="submit" class="casubmit" id="OtpSubmit" value="Submit" [disabled]="!CSendOTPForm.valid" (click)="confirmOTPWithPayNowPayLater()" />
										<input style="display: none;" type="button" name="submit" class="casubmit" id="OtpSubmit" value="Submit" [disabled]="!CSendOTPForm.valid" (click)="confirmOTP()" />
									</li>
								</ul>
							</form>
							<div class="linkwrp">
								<ul>
									<li>Still not received OTP?</li>
									<li>
										<div class="resendlinkwrp">
											
											<a href="javascript:void(0);" (click)="resendOTP()">Resend OTP</a>
										</div>
									</li>
								</ul>
							</div>
							<a class="otp-popup capopup-close" data-popup-close="popup-sendotp" (click)="resetLoginForm($event)" href="#"><img src="assets/images/icon-close2.png" alt="" /></a>
						</div>
					</div>
					<!--  Start Model Popup Login -->
				</div>
			</div>
		</div>
	</section>
</div>