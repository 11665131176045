import {
  Input,
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL } from '../services/constants';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { Meta, Title } from '@angular/platform-browser';

declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
declare let initPopup: any;
declare let initCustomSelect: any;
declare let dataLayerPush: any;

@Component({
  selector: 'book-an-appointment',
  templateUrl: './book-an-appointment.component.html',
  styles: [
    `
      ul li a {
        text-transform: capitalize;
      }
      a {
        cursor: pointer;
      }
      .disabled {
        pointer-events: none;
        cursor: default;
      }
      ul.popupmsg li {
        padding: 0;
        margin: 10px 0;
        font-size: 14px;
      }
      ul {
        padding-left: 5px;
        list-style: none;
      }
      ul.inactive {
        display: none;
      }
      ul.active {
        display: block !important;
      }
      .searchDD {
        position: relative;
      }
      .searchDD ul {
        width: 134%;
        display: none;
        background: #3d2979;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        left: -10px;
        margin: 0;
        padding: 0;
        padding-bottom: 10px;
        position: absolute;
        top: 46px;
        z-index: 2;
        overflow-x: hidden;
        overflow: auto;
      }
      .searchDD ul.active.maxhight {
        max-height: 170px;
      }
      .searchDD ul li {
        margin: 0px;
        float: left;
        list-style: none;
        width: 100%;
        background: #3d2979;
        border-radius: 0px;
        padding: 6px 10px;
        position: relative;
        color: #fff;
      }
      .searchDD ul li:hover {
        background: rgba(255, 255, 255, 0.1);
      }
      .searchDD ul li a {
        color: #fff;
        font-family: 'dinrg';
        font-size: 15px;
        text-decoration: none;
      }
      .bookAppPgBgAnim {
        background-color: #3d2979;
        margin: 0;
        padding: 8px 20px;
        display: inline-block;
        font-weight: normal;
        color: #fff;
        -webkit-animation-name: bganimate;
        -webkit-animation-duration: 1s;
        animation-iteration-count: infinite;
        border-radius: 2px;
        text-align: center;
        font-size: 16px;
        line-height: 20px;
      }
      @-webkit-keyframes bganimate {
        50% {
          background-color: #773996;
        }
      }
      @keyframes bganimate {
        50% {
          background-color: rgb(236, 28, 36, 0.8);
        }
      }
      @media (max-width: 1280px) {
        .searchDD ul {
          width: 122%;
        }
      }
      @media (max-width: 420px) {
        .searchDD ul {
          width: 136%;
        }
        .bookAppPgBgAnim {
          font-size: 14px;
          line-height: 18px;
          padding: 6px 10px;
        }
      }
    `,
  ],
})
export class BookanAppointment implements OnDestroy, OnInit, AfterViewInit {
  public doctorUID = new Subject<any>();
  public specialityUid = new Subject<any>();
  //private actionSection = new Subject<any>();
  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    public metaTitle: Title,
    public meta: Meta
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
    }
  }

  public doctors: any = [];
  public filteredSpecDoctor: any[];
  public title: any = '';
  public info: any = '';
  public subtitle: any = '';
  public description: any = '';
  public imgsrc: any = '';
  public imgBasePath = '';
  public currentSection: any;
  public activeRoute: any;
  public activePage: any;
  public centerList: any[] = [];
  public specList: any[] = [];
  public specility: any;
  public searchDoctor: any;
  public centrelocation: any = 0;
  public hidden = 'hidden';
  public logo = '';
  public hideCloseBtn: boolean = true;
  finddoctorclass: boolean = false;
  public links: any = [];
  public bread_crumb_links: any = [];
  doctorSearchResult: Array<any>;
  public bread_crumb_child_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Child Care', link: '/child-care/about', class: '' },
    { label: 'Book An Appointment', link: '', class: '' },
  ];

  public bread_crumb_women_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Women Health', link: '/women-care/about', class: '' },
    { label: 'Book An Appointment', link: '', class: '' },
  ];

  public bread_crumb_fertility_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Fertility Care', link: '/fertility-care/about', class: '' },
    { label: 'Book An Appointment', link: '', class: '' },
  ];

  public child_care_links: any = [
    {
      label: 'About Child Care',
      link: '/child-care-about',
      class: '',
      slug: 'about',
    },
    {
      label: 'Child Safety',
      link: '/child-care/child-safety',
      class: '',
      slug: 'child-safety',
    },
    {
      label: 'Doctors',
      link: '/child-care/doctors',
      class: '',
      slug: 'doctors',
    },
    {
      label: 'Our Specialities',
      link: '/child-care/specialities',
      class: '',
      slug: 'specialities',
    },
    {
      label: 'Our Centres',
      link: '/child-care/centres',
      class: '',
      slug: 'centres',
    },
    {
      label: 'Vaccination',
      link: '/child-care/vaccination',
      class: '',
      slug: 'vaccination',
    },
    {
      label: 'Testimonials',
      link: '/child-care/testimonials',
      class: '',
      slug: 'testimonials',
    },
    {
      label: 'Share Feedback',
      link: '/child-care/feedback',
      class: '',
      slug: 'feedback',
    },
    {
      label: 'Book An Appointment',
      link: '/child-care/book-an-appointment',
      class: 'active',
      slug: 'child-care',
    },
  ];

  public women_care_links: any = [
    {
      label: "About Women's Care",
      link: '/women-care/about',
      class: '',
      slug: 'about',
    },
    {
      label: 'Doctors',
      link: '/women-care/doctors',
      class: '',
      slug: 'doctors',
    },
    {
      label: 'Our Specialities',
      link: '/women-care/specialities',
      class: '',
      slug: 'specialities',
    },
    {
      label: 'Our Centres',
      link: '/women-care/centres',
      class: '',
      slug: 'centres',
    },
    {
      label: 'Testimonials',
      link: '/women-care/testimonials',
      class: '',
      slug: 'testimonials',
    },
    {
      label: 'Share Feedback',
      link: '/women-care/feedback',
      class: '',
      slug: 'feedback',
    },
    {
      label: 'Book An Appointment',
      link: '/women-care/book-an-appointment',
      class: 'active',
      slug: 'women-care',
    },
  ];

  public fertility_care_links: any = [
    {
      label: 'About Fertility Care',
      link: '/fertility-care/about',
      class: '',
      slug: 'about',
    },
    {
      label: 'Doctors',
      link: '/fertility-care/doctors',
      class: '',
      slug: 'doctors',
    },
    {
      label: 'Our Treatments',
      link: '/fertility-care/treatments',
      class: 'active',
      slug: 'treatments',
    },
    {
      label: 'Our Centres',
      link: '/fertility-care/centres',
      class: '',
      slug: 'centres',
    },
    {
      label: 'Testimonials',
      link: '/fertility-care/testimonials',
      class: '',
      slug: 'testimonials',
    },
    {
      label: 'Share Feedback',
      link: '/fertility-care/feedback',
      class: '',
      slug: 'feedback',
    },
    {
      label: 'Book An Appointment',
      link: '/fertility-care/book-an-appointment',
      class: 'active',
      slug: 'fertility-care',
    },
  ];

  //public doctorUID:number;
  public showSubField: boolean = false;
  public speciality_master: number;
  public centre_id: number = 0;
  public isShow: boolean = true;
  public specialty: any = 0;
  public doctorcentres: any = 0;
  public speciality_uid: number = 0;
  public doctorslug: string;
  public doctorIDparam: number;
  public sectionType: any;
  public pageType: any;
  public activeSubPage: any;
  public appointmentnote: any;
  public Highlight: any;
  public Highlights: boolean = false;

  public searchAppointmentData: any;
  public ConfirmAppointmentData: any;

  welcomePopup: any = {
    title: 'Dear Visitor',
    msg: [
      {
        data: 'If you find the appointments for any of our doctors across all the centres completely booked, you can always call us at our Toll Free - 1800 2122 to check if any appointments are still available and also for any further assistance.',
      },
      {
        data: "The online appointment booking system is an effort to streamline Outpatient Consultations. Being a Children's Hospital, there may be waiting periods for about 30-60 minutes. Kindly bear with us. We appreciate your understanding.",
      },
      {
        data: 'You would receive the appointment confirmation by SMS once you book an appointment online. In case you do not receive a confirmation SMS please write to confirmappointment@rainbowhospitals.in and we will resolve it.',
      },
    ],
  };
  public specilityMaster: any = 0;
  public bookingBtnStatus: number = 1;
  specilityMasterList = [
    {
      id: 1,
      slug: 'child-care',
      name: 'Child Care',
    },
    {
      id: 2,
      slug: 'women-care',
      name: 'Women Health',
    },
    {
      id: 3,
      slug: 'fertility-care',
      name: 'Fertility Care',
    },
  ];

  @ViewChildren('doctors') things: QueryList<any>;
  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.currentSection = params['section'];
      this.sectionType = params['type'] ? params['type'] : '';
      this.pageType =
        this.sectionType == 'visiting-consultant' ? '/visiting-consultant' : '';
      if (this.currentSection == 'all') {
        this.bookingBtnStatus = 0;
      }
      if (params['doctorslug']) {
        let doctorurl = params['doctorslug'].split('.');
        this.doctorslug = doctorurl[0];
        //console.log(this.doctorslug);
      }
    });

    //getPageinfo
    this.getPageInfo();

    //console.log(this.doctorIDparam);

    this.activeBreadCrum(this.currentSection);
    this.getSpecialityMasters(this.currentSection);
    //this.postAppointmentDetails();

    this.findSpecilityMaster = localStorage.getItem('SpecilityMasterId');
    this.findCenterId = localStorage.getItem('CentreLocationId');
    this.findSpecilityId = localStorage.getItem('SubSpecialtyId');

    //console.log('findSpecilityMaster:',this.findSpecilityMaster);
    //console.log('findCenterId:',this.findCenterId);
    //console.log('findSpecilityId:',this.findSpecilityId);
    if (this.findCenterId !== null) {
      if (this.findCenterId !== '') {
        this.api.getOurDoctorList(this.findSpecilityMaster).subscribe((res) => {
          this.doctors = res.speciality_master;
          let centers = res.speciality_master.centers;
          this.api.getCentreCities().subscribe((res) => {
            this.centerList = res;
            this.dlocationChange();
          });
        });
      }
    }
    if (
      this.findSpecilityMaster !== null ||
      this.findCenterId !== null ||
      this.findSpecilityId !== null
    ) {
      if (
        this.findSpecilityMaster !== '' ||
        this.findCenterId !== '' ||
        this.findSpecilityId !== ''
      ) {
        this.getDefaultDoctorList();
      }
    }
  }
  postAppointmentDetails() {
    this.ConfirmAppointmentData = {
      doctor: '1338',
      doctor_fees: '1',
      appointment_id: 'FGS354543DS630',
      registration_no: 'SSF3535662',
      patient_name: 'Test',
      patient_email: 'test@gmail.com',
      patient_mobile: '9619079190',
      location: '7',
      center: '12',
      speciality: '28',
      status: '1',
      appointmentdate: '2020-02-15 18:30:00',
      appointmentstarttime: '2020-02-15 18:30:00',
      appointmentendtime: '2020-02-15 18:45:00',
      entry_time: '2020-02-11 14:12:00',
    };

    this.api
      .postAppointmentDetails(this.ConfirmAppointmentData)
      .subscribe((res) => {
        console.log(
          'Create New DB Response',
          res,
          res.status_code,
          res.appointment_id
        );
      });
  }
  activeBreadCrum(currentSection) {
    switch (currentSection) {
      case 'child-care':
        this.bread_crumb_links = this.bread_crumb_child_links;
        this.links = this.child_care_links;
        this.logo = 'rainbow-logo';
        this.activeSubPage = 'child-care';
        break;
      case 'women-care':
        this.bread_crumb_links = this.bread_crumb_women_links;
        this.links = this.women_care_links;
        this.logo = 'birth-right-logo';
        this.activeSubPage = 'women-care';
        break;
      case 'fertility-care':
        this.bread_crumb_links = this.bread_crumb_fertility_links;
        this.links = this.fertility_care_links;
        this.logo = 'birth-right-logo';
        this.activeSubPage = 'fertility-care';
        break;
      default:
        this.logo = 'logo';
    }
  }
  getPageInfo() {
    this.api.getPageDescription('book-an-appointment').subscribe(
      (data) => {
        this.title = data.page_descriptions[0].title;
        this.subtitle = data.page_descriptions[0].subtitle;
        this.description = data.page_descriptions[0].description;
        this.info = data.page_descriptions[0].info;
        this.imgsrc = data.page_descriptions[0].image;
        this.imgBasePath = IMG_BASE_URL;
        this.appointmentnote = data.page_descriptions[0].appointmentnote;
        this.Highlight = data.page_descriptions[0].highlight;
        if (this.Highlight != null) {
          this.Highlights = true;
        }
        if (data) {
          //console.log(data);
          this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
          this.meta.updateTag({
            name: 'keywords',
            content: data.page_descriptions[0].meta_keyword,
          });
          this.meta.updateTag({
            name: 'description',
            content: data.page_descriptions[0].meta_description,
          });
          this.meta.addTags([
            {
              property: 'og:title',
              content: data.page_descriptions[0].meta_title,
            },
            {
              property: 'og:description',
              content: data.page_descriptions[0].meta_description,
            },
            {
              property: 'og:keywords',
              content: data.page_descriptions[0].meta_keyword,
            },
          ]);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getAllCentresSpeciality() {
    return this.api.getCentreSpecification().subscribe((res) => {
      this.doctors = res;
      let centers = res.centers;
      this.api.getCentreCities().subscribe((res) => {
        this.centerList = res;
        //console.log(this.centerList);
        //console.log('list of centre and city',res);
      });
    });
  }
  getCenterList(id) {
    this.api.getOurDoctorList(id).subscribe((res) => {
      this.doctors = res.speciality_master;
      let centers = res.speciality_master.centers;
      this.api.getCentreCities().subscribe((res) => {
        this.centerList = res;
        //console.log(this.centerList);
        //console.log('list of centre and city',res);
      });
      //console.log(this.centerList);
    });
  }
  locationChange() {
    if (this.centrelocation != 0) {
      this.specialty = 0;
      let doctorSpec = this.doctors.centers;
      this.specList = [];
      /*
			if(this.findSpecilityMaster == 0){
				localStorage.setItem('SpecilityMasterId','');
			}*/
      if (this.findCenterId === 0) {
        localStorage.setItem('CentreLocationId', '');
      } else {
        localStorage.setItem('CentreLocationId', this.centrelocation);
      }
      localStorage.setItem('SubSpecialtyId', '');
      /*
			if(this.findSpecilityId == 0){
				localStorage.setItem('SubSpecialtyId','');
			}*/

      //console.log('locationChange doctorSpec:', doctorSpec, this.centrelocation);
      let filterSpec = doctorSpec
        .map((r) => {
          return r.speciality_features;
        })
        .map((s) => {
          for (var index = 0; index < s.length; index++) {
            //console.log(s[index].centers_id);

            if (s[index].centers_id == this.centrelocation) {
              //console.log(s[index]);
              //console.log('speciality_features:', s[index]);
              this.specList.push(s[index]);
            }
          }
        });
      this.specialty = this.specList[0].id;
      // $('#SelectSubSpecialty').html(this.specList[0].title);
    } else {
      localStorage.setItem('CentreLocationId', '');
      console.log('no data');
    }
    this.DataLayerOnFormChange(
      'BookingPage',
      'BookingPage_Formfill_Select_Location',
      'Book-an-appointment'
    );
    this.subSpecialty();
  }
  dlocationChange() {
    if (this.findCenterId != 0) {
      let doctorSpec = this.doctors.centers;
      this.specList = [];
      let filterSpec = doctorSpec
        .map((r) => {
          return r.speciality_features;
        })
        .map((s) => {
          for (var index = 0; index < s.length; index++) {
            //console.log(s[index].centers_id);
            if (s[index].centers_id == this.findCenterId) {
              this.specList.push(s[index]);
            }
          }
        });
    } else {
      console.log('no data');
    }
  }
  subSpecialty() {
    if (this.specialty != 0) {
      if (this.findSpecilityId === 0) {
        localStorage.setItem('SubSpecialtyId', '');
      } else {
        localStorage.setItem('SubSpecialtyId', this.specialty);
      }
    } else {
      localStorage.setItem('SubSpecialtyId', '');
    }
    this.DataLayerOnFormChange(
      'BookingPage',
      'BookingPage_Formfill_Select_SubSpecialty',
      'Book-an-appointment'
    );
  }
  getDoctorname(evt) {
    if (evt.target.value.length >= 2) {
      if (this.sectionType == 'visiting-consultant') {
        this.api
          .getConsultDoctorSearchList(evt.target.value)
          .subscribe((data) => {
            this.doctorSearchResult = data.doctors; //console.log('doctorSearchResult:', this.doctorSearchResult);
          });
        this.finddoctorclass = true;
      } else {
        this.api.getDoctorSearchList(evt.target.value).subscribe((data) => {
          this.doctorSearchResult = data.doctors; //console.log('doctorSearchResult2:', this.doctorSearchResult);
        });
        this.finddoctorclass = true;
      }
    } else {
      this.finddoctorclass = false;
    }
  }
  selectDoctor(id) {
    this.api.getSearchedDoctorDetail(id).subscribe((res) => {
      //console.log(res);
      setTimeout(() => {
        var childResult = $('.childdoctor-result');
        if (childResult.length) {
          //$("html, body").delay(500).animate({ scrollTop: childResult.offset().top - 80  }, 500);
        }
      }, 500);
      this.isShow = true;
      this.filteredSpecDoctor = res;
      this.searchDoctor = '';
      this.finddoctorclass = false;
    });
    this.DataLayerOnFormChange(
      'BookingPage',
      'BookingPage_SelectDoctor',
      'Book-an-appointment'
    );
  }
  getSpecialityMasters(currentSection) {
    this.api
      .getSpecialityMasters()
      .map((res) => res)
      .subscribe((data) => {
        //console.log(currentSection);
        this.activeRoute = data.speciality_masters.filter((filterdata) => {
          if (currentSection == 'women-care') {
            return filterdata.slug == 'women-care';
          } else {
            return filterdata.slug == currentSection;
          }
        });
        //console.log(this.activeRoute);
        if (this.activeRoute.length > 0) {
          this.speciality_master = this.activeRoute[0].id;
          this.speciality_uid = this.activeRoute[0].speciality_uid;
          this.activePage = this.activeRoute[0].speciality;
          if (this.findSpecilityMaster === 0) {
            localStorage.setItem('SpecilityMasterId', '');
          } else {
            localStorage.setItem('SpecilityMasterId', this.activeRoute[0].id);
          }
          localStorage.setItem('CentreLocationId', '');
          localStorage.setItem('SubSpecialtyId', '');
          /*
				if(this.findCenterId == 0){
					localStorage.setItem('CentreLocationId','');
				}
				if(this.findSpecilityId == 0){
					localStorage.setItem('SubSpecialtyId','');
				}*/
        } else {
          console.log('currentSection: ', currentSection);

          if (currentSection == 0) {
            this.specilityMaster = 0;
            this.speciality_master = 0;
            localStorage.setItem('SpecilityMasterId', '');
            localStorage.setItem('CentreLocationId', '');
            localStorage.setItem('SubSpecialtyId', '');
          }
          //this.speciality_master=0;
          this.activePage = currentSection;
        }
        //console.log(this.activeRoute);
        if (this.activeRoute.length > 0) {
          this.getCenterList(this.activeRoute[0].id);
        } else {
          this.getAllCentresSpeciality();
        }

        // this.api.getDoctorListBySpecilityMaster(this.speciality_master,0,this.centre_id,0).subscribe(res=>{
        // 	this.filteredSpecDoctor = res;
        // 	//console.log(this.filteredSpecDoctor);
        // });
        //console.log(this.activeRoute);
        //console.log(this.activePage);
      });
  }
  selectSpecMaster(e) {
    //if(this.specilityMaster !== '0'){
    this.showSubField = true;
    this.bookingBtnStatus = 1;
    this.centrelocation = 0;
    this.specList = [];
    this.centerList = [];
    localStorage.setItem('SpecilityName', this.specilityMaster);
    //console.log(this.specilityMaster);
    this.specialty = 0;
    this.getSpecialityMasters(this.specilityMaster);
    this.DataLayerOnFormChange(
      'BookingPage',
      'BookingPage_Formfill_Select_Specility',
      'Book-an-appointment'
    );
    /*}else{
			localStorage.setItem('SpecilityName', this.specilityMaster);
		}*/
  }
  closeChildren() {
    this.isShow = true;
    $('.loder').css('display', 'block');
    setTimeout(() => {
      $('.loder').css('display', 'none');
    }, 1000);
    initAllPage();
    //this.specialityUid.next(0);
  }
  bookanappointment(slug) {
    //this.router.navigate(['./all/book-an-appointment/'+slug]);
    //console.log('bookanappointment:', slug, this.speciality_uid);
    //console.log(this.speciality_uid);
    this.isShow = false;
    this.doctorUID.next(slug);
    this.specialityUid.next(this.speciality_uid);
    var $windowheight = $(window).height();
    var scrollHeight = $windowheight - 20 / 100;
    //console.log(scrollHeight);

    //this.actionSection.next(this.currentSection);
    this.DataLayerOnFormChange(
      'BookingPage',
      'BookingPage_SelectDoctor_BookAppointment',
      'Book-an-appointment'
    );
  }

  public findSpecilityMaster: any = '';
  public findSpecilityId: any = '';
  public findCenterId: any = '';
  getDefaultDoctorList() {
    this.isShow = true;
    if (this.sectionType === 'visiting-consultant') {
      this.getConsultDoctorListByFilter(
        this.findSpecilityMaster,
        this.findSpecilityId,
        this.findCenterId
      );
    } else {
      console.log(
        'oninit:',
        this.findSpecilityMaster,
        this.findCenterId,
        this.findSpecilityId
      );
      this.getDoctorListByFilter(
        this.findSpecilityMaster,
        this.findSpecilityId,
        this.findCenterId
      );
    }
  }
  submitfilter(f) {
    $('.loder_opct').css('display', 'block');
    if (f.value) {
      this.isShow = true;
      let center_id = f.value['location'];
      let specialty_id = this.specialty ? this.specialty : 0;
      console.log('center_id', center_id);
      localStorage.setItem('Locationid', center_id);

      if (this.sectionType === 'visiting-consultant') {
        this.getConsultDoctorListByFilter(
          this.speciality_master,
          specialty_id,
          center_id
        );
      } else {
        this.getDoctorListByFilter(
          this.speciality_master,
          specialty_id,
          center_id
        );
      }

      setTimeout(() => {
        var childResult = $('.childdoctor-result');
        if (childResult.length) {
          //$("html, body").delay(500).animate({ scrollTop: childResult.offset().top - 80  }, 500);
        }
      }, 500);
    }
    this.DataLayerOnFormChange(
      'BookingPage',
      'BookingPage_clickSearch',
      'Book-an-appointment'
    );
  }
  getDoctorListByFilter(speciality_master, specialty_id, center_id) {
    return this.api
      .getDoctorListBySpecilityMaster(
        speciality_master,
        specialty_id,
        center_id,
        0
      )
      .subscribe(
        (res) => {
          $('.loder_opct').css('display', 'none');
          setTimeout(() => {
            var childResult = $('.ddetailstimet');
            if (childResult.length) {
              $('html, body')
                .delay(500)
                .animate({ scrollTop: childResult.offset().top + 240 }, 1500);
            }
          }, 1000);
          return (this.filteredSpecDoctor = res);
        },
        (err) => {
          console.log(err);
        }
      );
  }
  getConsultDoctorListByFilter(speciality_master, specialty_id, center_id) {
    return this.api
      .getConsultDoctorListBySpecilityMaster(
        speciality_master,
        specialty_id,
        center_id,
        0
      )
      .subscribe(
        (res) => {
          $('.loder_opct').css('display', 'none');
          setTimeout(() => {
            var childResult = $('.childdoctor-result');
            if (childResult.length) {
              $('html, body')
                .delay(500)
                .animate({ scrollTop: childResult.offset().top - 40 }, 500);
            }
          }, 1000);
          return (this.filteredSpecDoctor = res);
        },
        (err) => {
          console.log(err);
        }
      );
  }

  DataLayerOnFormChange(eventAction, eventlabel, eventCategory) {
    let c_path = window.location.pathname.split('/');
    let pName = c_path[c_path.length - 2],
      spName = c_path[c_path.length - 1];
    pName = pName.replace(/-/gi, '-');
    spName = spName.replace(/-/gi, '_');
    dataLayerPush(
      eventAction,
      eventlabel,
      eventCategory,
      '',
      pName,
      pName,
      spName,
      '',
      'English',
      pName,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      ''
    );
  }

  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
      //initPopup('welcomePopup');
      $('[data-toggle="tooltip"]').tooltip();
    }

    if (this.doctorslug) {
      this.bookanappointment(this.doctorslug);
      //console.log('from url:', this.doctorslug);
      this.hideCloseBtn = true;
    }

    this.things.changes.subscribe((t) => {
      // this.ngForRendred();
      initAllPage();
      initCustomSelect();
      this.locationChange();
    });
    if (this.findSpecilityMaster !== null) {
      if (this.findSpecilityMaster !== '') {
        this.specilityMaster = localStorage.getItem('SpecilityName');
        this.speciality_master = this.findSpecilityMaster;
        this.centrelocation = 0;
      } else {
        this.specilityMaster = 0;
        this.speciality_master = 0;
      }
    } else {
      this.showSubField = false;
      this.specilityMaster = 0;
      this.speciality_master = 0;
      this.centrelocation = 0;
      this.specialty = 0;
      // this.filteredSpecDoctor = [];
      // this.searchDoctor = [];
      // this.selectSpecMaster(null);
      this.locationChange();
      this.subSpecialty();

      localStorage.clear();
    }
    // if (this.findCenterId !== null) {
    //   if (this.findCenterId !== '') {
    //     this.centrelocation = localStorage.getItem('CentreLocationId');
    //     //$("#selectlocation").text(localStorage.getItem('CentreLocationId'));
    //   } else {
    //     this.centrelocation = 0;
    //   }
    // }
    // if (this.findSpecilityId !== null) {
    //   if (this.findSpecilityId !== '') {
    //     this.specialty = this.findSpecilityId;
    //     this.subSpecialty();
    //   } else {
    //     this.specialty = 0;
    //     this.subSpecialty();
    //   }
    // }

    this.DataLayerOnFormChange(
      'BookingPage',
      'BookingPage_DetailPage',
      'Book-an-appointment'
    );
  }

  ngOnDestroy() {}
}
