<div class="container-fluid">
  <div class="loder"></div>
  <section>
      <div class="row header w-full">
        <div class="container mx-auto w-full px-0" style="margin-bottom: -2em">
          <a href="https://rainbowhospitals.in/" target="_blank"
            ><img
              src="assets/images/RCH-logo.png"
              alt=""
              style="transform: scale(75%)"
          /></a>
        </div>
      </div>
  </section>
  <section class="hero-section">
    
  </section>
</div>
