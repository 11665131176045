<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>
    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <section class="page wtwedo">
        <div class="row">
            <div>

                <div class="col-md-5 col-xs-5 col-lg-4">
                    <div class="circuleframe">
                        <img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" />
                    </div>
                    <!-- End Circle -->
                </div>
                <div class="col-md-7 col-xs-7 col-lg-7">
                    <div class="pagetitle">
                        <h1 class="tgradient">{{title}} </h1>
                        <div class="underline">
                            <img src="assets/images/underline2.png" alt="" />
                        </div>
                        <h3>{{subtitle}}</h3>
                    </div>
                </div>
                <div class="col-md-7 col-xs-7 col-lg-7 mcontentview">
                    <p [innerHTML]="description"></p>
                </div>
            </div>
        </div>

    </section>
    <section class="ddetailstimet ourssbg">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-xs-12 col-lg-12">
                    <div class="ourSpecialitiess slider">
                        <ng-container>
                            <div #allTheseThings class="sitem" *ngFor="let itm of sItems;">
                                <div class="text" *ngFor="let item of itm">
                                    <div class="osicons"><img src="{{imgBasePath}}{{item.image}}" alt="" /></div>

                                    <h3 class="textgradient" [innerHTML]="item.title"></h3>
                                    <p [innerHTML]="item.description | dgTruncate :{limit: 15, words: true} "></p>
                                    <a *ngIf="findComingSoon(item.description)" href="women-care/speciality/{{item.slug}}" class="knowmore"><span>Read More</span></a>
                                </div>
                                <!-- <div class="text">
                            <div class="osicons"><img src="assets/images/icon05.gif" alt="" /></div>
                            <h3 class="textgradient">The Pediatric<br><span>Neurology</span></h3>
                            <p>
                                Our Pediatric Neuro Rehabilitation and Allied Services Team at Banjara Hills, Hyderabad provides
                            </p>
                            <a href="#" class="knowmore"><span>Read More</span></a>
                        </div> -->
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>