import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL } from '../services/constants';

import { Meta, Title } from '@angular/platform-browser';
declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
// declare let initAboutTabs:any;

@Component({
  selector: 'media',
  templateUrl: './media.component.html',
  styles: [
    `
      .mediaSection .PressClips .pressClipsBox .imageBox img {
        display: inline;
        margin: 0 auto;
        width: inherit;
      }
      .mediaSection .PressClips .pressClipsBox .imageBox {
        max-width: 100% !important;
        width: 100%;
        padding: 11px;
        background-size: cover;
        display: inline-block;
        margin-bottom: 20px;
      }
      h4.social-title {
        cursor: pointer;
      }
      h4.social-title.icon-plus::before {
        content: ' + ';
      }
      h4.social-title.icon-minus::before {
        content: ' - ';
      }
    `,
  ],
})
export class MediaComponent implements OnDestroy, OnInit, AfterViewInit {
  constructor(
    private api: ApiService,
    public metaTitle: Title,
    public meta: Meta
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/fancybox/jquery.fancybox.min.js');
      require('../../assets/js/slick.min.js');
    }
  }

  public press_release: any[] = [];
  public press_clips: any[] = [];

  public title: any = '';
  public subtitle: any = '';
  public description: any = '';
  public imgsrc: any = '';
  public brief_facilities_description: any = '';
  public brief_facilities_image: any = '';
  public imgBasePath = '';
  public hidden = 'hidden';
  public logo = 'logo';
  public bread_crumb_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'About Us', link: '/about-us', class: '' },
    { label: 'Media', link: '', class: '' },
  ];

  public links: any = [];
  public activePage: string = 'media';

  public selectMonth: any = '';

  public id: number;
  public viewmore: boolean = false;
  public showSocial: boolean = false;
  public readmoreTitle: string = 'Read More';
  // @ViewChildren('abouttabs') things: QueryList<any>;

  ngOnInit() {
    this.api
      .getAboutSubFooterDetails()
      .map((res) => res)
      .subscribe((data) => {
        this.links = data;
      });
    this.api.getPageDescription('media').subscribe(
      (data) => {
        this.title = data.page_descriptions[0].title;
        this.subtitle = data.page_descriptions[0].subtitle;
        this.description = data.page_descriptions[0].description;
        this.imgsrc = data.page_descriptions[0].image;
        this.imgBasePath = IMG_BASE_URL;
        if (data) {
          //console.log(data);
          this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
          this.meta.updateTag({
            name: 'keywords',
            content: data.page_descriptions[0].meta_keyword,
          });
          this.meta.updateTag({
            name: 'description',
            content: data.page_descriptions[0].meta_description,
          });
          this.meta.addTags([
            {
              property: 'og:title',
              content: data.page_descriptions[0].meta_title,
            },
            {
              property: 'og:description',
              content: data.page_descriptions[0].meta_description,
            },
            {
              property: 'og:keywords',
              content: data.page_descriptions[0].meta_keyword,
            },
          ]);
        }
      },
      (error) => {
        console.log(error);
      }
    );

    this.api.getMediaRelease().subscribe(
      (data) => {
        this.press_release = data.media_press_releases;
      },
      (error) => {
        console.log(error);
      }
    );

    this.api.getPressClips().subscribe(
      (data) => {
        for (let i: number = 0; i < 3; i++) {
          this.press_clips.push(data.press_clips[i]);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  gotoReadMore(news) {
    console.log(news);
  }
  toggleReadMore() {
    this.viewmore = !this.viewmore;
    console.log(this.viewmore);
    this.readmoreTitle = this.viewmore ? 'Less' : 'Read More';
  }
  toggleSocial() {
    this.showSocial = !this.showSocial;
  }
  pressClipId(id) {
    localStorage.setItem('pressClipId', id);
  }
  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();

      //var $pressClipsBoxHeight = $('.pressClipsBox:first').height();
      //$('.imageBox').find('img').css('max-height',$pressClipsBoxHeight);
    }

    // this.things.changes.subscribe(t => {
    //   // this.ngForRendred();
    //   initAboutTabs();
    // })
  }

  ngOnDestroy() {}
}
