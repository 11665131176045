<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>
    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <section class="pagebcwrp">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-lg-12">
                    <ul>
                        <li><a href="javascript:void()">Home</a></li>
                        <li><a href="javascript:void()">Child Care</a></li>
                        <li>Vaccination</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <section class="page wtwedo">
        <div class="row">
            <div class="col-md-5 col-xs-5 col-lg-4">
                <div class="circuleframe">
                    <img src="{{imgBasePath}}{{imgsrc}}" alt="{{title}}" title="{{title}}" />
                </div>
                <!-- End Circle -->
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7">
                <div class="pagetitle">
                    <h1 class="textgradient">{{title}}</h1>
                    <div class="underline">
                        <img src="assets/images/underline.png" alt="" />
                    </div>
                    <h3>{{subtitle}}</h3>
                </div>
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7 mcontentview">
                <p>{{description}}
                </p>

            </div>
        </div>
    </section>
    <section class="vimmunitywrp">
        <div class="row">
            <div class="container">
                <div class="col-md-12 col-xs-12 col-lg-12">
                    <div class="header">
                        <h3>Vaccinate for better immunity</h3>
                        <p>Use this immunization scheduler to know the vaccinations to be given to your baby at the right time.</p>
                    </div>
                    <div class="vimform">
                        <ul>
                            <li>
                                <p>Name of the Baby</p>
                                <div class="sdatewrp2">
                                    <input type="text" name="txtNOB" class="" placeholder="Lusha" />
                                </div>
                            </li>
                            <li>
                                <p>Date of Birth</p>
                                <div class="sdatewrp">
                                    <input type="text" name="txtDOB" class="datepickr" placeholder="15 / 03 / 2017" />
                                </div>
                            </li>
                            <li>
                                <p>&nbsp;</p>
                                <input type="submit" name="submit" class="rimsubmitbtn" value="Submit" />
                                <input type="reset" name="reset" class="rimresetbtn" value="Reset" />
                            </li>
                        </ul>
                    </div>
                    <div class="header">
                        <h4>Lusha is 6 Months old.</h4>
                        <p>Check our recommended vaccine schedule & mark your calendars for upcoming vaccination days.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="vimmunityiconwrp">
        <div class="row">
            <div class="container">
                <div class="col-md-12 col-xs-12 col-lg-12">
                    <ul class="vimslider slider">
                        <li>
                            <div class="viconwrp">
                                <p>Birth</p>
                                <div class="cirwrp"><img src="assets/images/baby/icon_1.png" data-img="" alt="" /></div>
                            </div>
                        </li>
                        <li>
                            <div class="viconwrp">
                                <p>6 Weeks</p>
                                <div class="cirwrp"><img src="assets/images/baby/icon_2.png" alt="" /></div>
                            </div>
                        </li>
                        <li>
                            <div class="viconwrp">
                                <p>10 Weeks</p>
                                <div class="cirwrp"><img src="assets/images/baby/icon_3.png" alt="" /></div>
                            </div>
                        </li>
                        <li>
                            <div class="viconwrp">
                                <p>14 Weeks</p>
                                <div class="cirwrp"><img src="assets/images/baby/icon_4.png" alt="" /></div>
                            </div>
                        </li>
                        <li>
                            <div class="viconwrp">
                                <p>6 Months</p>
                                <div class="cirwrp"><img src="assets/images/baby/icon_5.png" alt="" /></div>
                            </div>
                        </li>
                        <li>
                            <div class="viconwrp">
                                <p>9 Months</p>
                                <div class="cirwrp"><img src="assets/images/baby/icon_6.png" alt="" /></div>
                            </div>
                        </li>
                        <li>
                            <div class="viconwrp">
                                <p>12 Months</p>
                                <div class="cirwrp"><img src="assets/images/baby/icon_7.png" alt="" /></div>
                            </div>
                        </li>
                        <li>
                            <div class="viconwrp">
                                <p>15 Months</p>
                                <div class="cirwrp"><img src="assets/images/baby/icon_8.png" alt="" /></div>
                            </div>
                        </li>
                        <li>
                            <div class="viconwrp">
                                <p>18 Months</p>
                                <div class="cirwrp"><img src="assets/images/baby/icon_9.png" alt="" /></div>
                            </div>
                        </li>
                        <li>
                            <div class="viconwrp">
                                <p>2 Years</p>
                                <div class="cirwrp"><img src="assets/images/baby/icon_8.png" alt="" /></div>
                            </div>
                        </li>
                        <li>
                            <div class="viconwrp">
                                <p>3 Years</p>
                                <div class="cirwrp"><img src="assets/images/baby/icon_9.png" alt="" /></div>
                            </div>
                        </li>
                        <li>
                            <div class="viconwrp">
                                <p>5 Years</p>
                                <div class="cirwrp"><img src="assets/images/baby/icon_9.png" alt="" /></div>
                            </div>
                        </li>
                    </ul>
                    <ul class="vimslidercon slider">
                        <li>
                            <div class="viminfowrp" data-fancybox="modal" data-src="#srmodel-1">
                                <ul>
                                    <li>
                                        <div class="iconbyname">
                                            <img src="assets/images/baby/icon-hepatitisb.png" alt="" />
                                        </div>
                                        <div class="namebyicon">
                                            <p>BCG, OPV, Hepatitis B (1st)</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div id="srmodel-1" class="srmodel">
                                <div class="srmodelwrp">
                                    <h2>Immunization Schedule</h2>
                                    <h3>Birth :</h3>
                                    <p>BCG, OPV, Hepatitis B (1st)</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="viminfowrp" data-fancybox="modal" data-src="#srmodel-2">
                                <ul>
                                    <li>
                                        <div class="iconbyname">
                                            <img src="assets/images/baby/icon-hepatitisb.png" alt="" />
                                        </div>
                                        <div class="namebyicon">
                                            <p>DTP/ DTaP +HiB + OPV/ IPV, Pneumonococcal Vaccine (1st)</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="iconbyname">
                                            <img src="assets/images/baby/icon-opv.png" alt="" />
                                        </div>
                                        <div class="namebyicon">
                                            <p>Hepatitis B (2nd), Rotavirus (1st)</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div id="srmodel-2" class="srmodel">
                                <div class="srmodelwrp">
                                    <h2>Immunization Schedule</h2>
                                    <h3>6 Weeks :</h3>
                                    <p>DTP/ DTaP +HiB + OPV/ IPV<br> Pneumonococcal Vaccine (1st)<br> Hepatitis B (2nd)<br> Rotavirus (1st)
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="viminfowrp" data-fancybox="modal" data-src="#srmodel-3">
                                <ul>
                                    <li>
                                        <div class="iconbyname">
                                            <img src="assets/images/baby/icon-hepatitisb.png" alt="" />
                                        </div>
                                        <div class="namebyicon">
                                            <p>DTP/ DTaP +HiB + OPV/ IPV</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="iconbyname">
                                            <img src="assets/images/baby/icon-opv.png" alt="" />
                                        </div>
                                        <div class="namebyicon">
                                            <p>Pneumonococcal Vaccine (2nd), Rotavirus (2nd)</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div id="srmodel-3" class="srmodel">
                                <div class="srmodelwrp">
                                    <h2>Immunization Schedule</h2>
                                    <h3>10 Weeks :</h3>
                                    <p>DTP/ DTaP +HiB + OPV/ IPV<br> Pneumonococcal Vaccine (2nd)<br> Rotavirus (2nd)
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="viminfowrp" data-fancybox="modal" data-src="#srmodel-4">
                                <ul>
                                    <li>
                                        <div class="iconbyname">
                                            <img src="assets/images/baby/icon-hepatitisb.png" alt="" />
                                        </div>
                                        <div class="namebyicon">
                                            <p>DTP/ DTaP +HiB + OPV/ IPV</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="iconbyname">
                                            <img src="assets/images/baby/icon-opv.png" alt="" />
                                        </div>
                                        <div class="namebyicon">
                                            <p>Pneumonococcal Vaccine (3rd), Rotavirus (3rd)</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div id="srmodel-4" class="srmodel">
                                <div class="srmodelwrp">
                                    <h2>Immunization Schedule</h2>
                                    <h3>14 Weeks :</h3>
                                    <p>DTP/ DTaP +HiB + OPV/ IPV<br> Pneumonococcal Vaccine (3rd)<br> Rotavirus (3rd)</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="viminfowrp" data-fancybox="modal" data-src="#srmodel-5">
                                <ul>
                                    <li>
                                        <div class="iconbyname">
                                            <img src="assets/images/baby/icon-hepatitisb.png" alt="" />
                                        </div>
                                        <div class="namebyicon">
                                            <p>Hepatitis B (3rd), OPV</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="iconbyname">
                                            <img src="assets/images/baby/icon-opv.png" alt="" />
                                        </div>
                                        <div class="namebyicon">
                                            <p>First Dental Check Up *</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div id="srmodel-5" class="srmodel">
                                <div class="srmodelwrp">
                                    <h2>Immunization Schedule</h2>
                                    <h3>6 Months :</h3>
                                    <p>Hepatitis B (3rd), OPV<br> First Dental Check Up *</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="viminfowrp" data-fancybox="modal" data-src="#srmodel-6">
                                <ul>
                                    <li>
                                        <div class="iconbyname">
                                            <img src="assets/images/baby/icon-hepatitisb.png" alt="" />
                                        </div>
                                        <div class="namebyicon">
                                            <p>Measles Vaccine</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="iconbyname">
                                            <img src="assets/images/baby/icon-opv.png" alt="" />
                                        </div>
                                        <div class="namebyicon">
                                            <p>OPV</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div id="srmodel-6" class="srmodel">
                                <div class="srmodelwrp">
                                    <h2>Immunization Schedule</h2>
                                    <h3>9 Months :</h3>
                                    <p>Measles Vaccine, OPV</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="viminfowrp" data-fancybox="modal" data-src="#srmodel-7">
                                <ul>
                                    <li>
                                        <div class="iconbyname">
                                            <img src="assets/images/baby/icon-hepatitisb.png" alt="" />
                                        </div>
                                        <div class="namebyicon">
                                            <p>Pneumonococcal (4th Dose)</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="iconbyname">
                                            <img src="assets/images/baby/icon-opv.png" alt="" />
                                        </div>
                                        <div class="namebyicon">
                                            <p>Hepatitis A (1st)</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div id="srmodel-7" class="srmodel">
                                <div class="srmodelwrp">
                                    <h2>Immunization Schedule</h2>
                                    <h3>12 Months :</h3>
                                    <p>Pneumonococcal (4th Dose)<br> Hepatitis A (1st)</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="viminfowrp" data-fancybox="modal" data-src="#srmodel-8">
                                <ul>
                                    <li>
                                        <div class="iconbyname">
                                            <img src="assets/images/baby/icon-hepatitisb.png" alt="" />
                                        </div>
                                        <div class="namebyicon">
                                            <p>MMR (1st)</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="iconbyname">
                                            <img src="assets/images/baby/icon-opv.png" alt="" />
                                        </div>
                                        <div class="namebyicon">
                                            <p>Chicken Pox (1st)</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div id="srmodel-8" class="srmodel">
                                <div class="srmodelwrp">
                                    <h2>Immunization Schedule</h2>
                                    <h3>15 Months :</h3>
                                    <p>MMR (1st) <br>Chicken Pox (1st)</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="viminfowrp" data-fancybox="modal" data-src="#srmodel-9">
                                <ul>
                                    <li>
                                        <div class="iconbyname">
                                            <img src="assets/images/baby/icon-hepatitisb.png" alt="" />
                                        </div>
                                        <div class="namebyicon">
                                            <p>DTP/ DTaP + OPV/ IPV + HiB (1st Booster)</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="iconbyname">
                                            <img src="assets/images/baby/icon-opv.png" alt="" />
                                        </div>
                                        <div class="namebyicon">
                                            <p>Hepatitis A (2nd)</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div id="srmodel-9" class="srmodel">
                                <div class="srmodelwrp">
                                    <h2>Immunization Schedule</h2>
                                    <h3>18 Months :</h3>
                                    <p>DTP/ DTaP + OPV/ IPV + HiB<br> (1st Booster)<br> Hepatitis A (2nd)</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="viminfowrp" data-fancybox="modal" data-src="#srmodel-10">
                                <ul>
                                    <li>
                                        <div class="iconbyname">
                                            <img src="assets/images/baby/icon-hepatitisb.png" alt="" />
                                        </div>
                                        <div class="namebyicon">
                                            <p>Typhoid Vaccine</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div id="srmodel-10" class="srmodel">
                                <div class="srmodelwrp">
                                    <h2>Immunization Schedule</h2>
                                    <h3>2 Years :</h3>
                                    <p>Typhoid Vaccine</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="viminfowrp" data-fancybox="modal" data-src="#srmodel-11">
                                <ul>
                                    <li>
                                        <div class="iconbyname">
                                            <img src="assets/images/baby/icon-hepatitisb.png" alt="" />
                                        </div>
                                        <div class="namebyicon">
                                            <p>MMR (2nd) ***</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="iconbyname">
                                            <img src="assets/images/baby/icon-opv.png" alt="" />
                                        </div>
                                        <div class="namebyicon">
                                            <p>Chicken Pox (2nd) ***</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div id="srmodel-11" class="srmodel">
                                <div class="srmodelwrp">
                                    <h2>Immunization Schedule</h2>
                                    <h3>3 Years :</h3>
                                    <p>MMR (2nd) *** <br>Chicken Pox (2nd) ***</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="viminfowrp" data-fancybox="modal" data-src="#srmodel-12">
                                <ul>
                                    <li>
                                        <div class="iconbyname">
                                            <img src="assets/images/baby/icon-hepatitisb.png" alt="" />
                                        </div>
                                        <div class="namebyicon">
                                            <p>DTP/ DTaP,&nbsp; OPV/ IPV (2nd Booster)</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="iconbyname">
                                            <img src="assets/images/baby/icon-opv.png" alt="" />
                                        </div>
                                        <div class="namebyicon">
                                            <p>Typhoid Vaccine</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div id="srmodel-12" class="srmodel">
                                <div class="srmodelwrp">
                                    <h2>Immunization Schedule</h2>
                                    <h3>5 Years :</h3>
                                    <p>DTP/ DTaP,&nbsp; OPV/ IPV<br> (2nd Booster)<br> Typhoid Vaccine</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <section class="vimminfowrp">
        <div class="row">
            <div class="container">
                <div class="col-md-12 col-xs-12 col-lg-12">
                    <div class="infocell1">
                        <h3>For any medical information about Vaccination, please contact:</h3>
                        <ul>
                            <li>
                                <img src="assets/images/vimminfo_pic.png" alt="" />
                            </li>
                            <li>
                                <p>Dr. Farhan Shaikh </p>
                                <div class="infofvimm">
                                    <div class="ictextwrp phone">044 1234 5678 | 1234 5678</div>
                                    <div class="ictextwrp mail"><a href="mailto:drfarhan@rainbowhospitals.in">drfarhan@rainbowhospitals.in</a></div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="infocell1">
                        <h3>For any general information (Vaccine availability, cost, etc) about Vaccination, please contact:</h3>
                        <ul>
                            <li>
                                <p>Mr. Deepak </p>
                                <div class="infofvimm">
                                    <div class="ictextwrp phone">044 1234 5678 | 1234 5678</div>
                                    <div class="ictextwrp mail"><a href="mailto:drfarhan@rainbowhospitals.in">drfarhan@rainbowhospitals.in</a></div>
                                </div>
                            </li>
                            <li>
                                <p>Ms. Pranitha </p>
                                <div class="infofvimm">
                                    <div class="ictextwrp phone">044 1234 5678 | 1234 5678</div>
                                    <div class="ictextwrp mail"><a href="mailto:drfarhan@rainbowhospitals.in">drfarhan@rainbowhospitals.in</a></div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="learnvmwrp">
        <div class="row">
            <div class="container">
                <div class="col-md-12 col-xs-12 col-lg-12">
                    <div class="learnvmheder">
                        <h2 class="textgradient">Learn why on-time vaccination matters.</h2>
                        <img src="assets/images/icon-lvm.png" class="lvmicon" alt="" />
                    </div>
                    <div class="row learnacrwrp">
                        <div class="questionwrp">
                            <h3>What is an infection?</h3>
                        </div>
                        <div class="answerwrp">
                            <p>An infection happens when a "microorganism" (a living thing so small that we can't see them individually) begins to grow and multiply in the body. We all have millions of microorganisms on and in our bodies. There are many
                                bacteria (microorganisms that can live independently) on our skin, and even more in our intestines and mouths. These bacteria are not only fairly harmless (unless your immune system isn't working quite right) but actually
                                help you out.</p>
                            <p>However, there are many bacteria that will make you sick. Some are usually not found in the body unless you are sick. In other cases, normally harmless or helpful bacteria may overgrow or invade parts of the body where they
                                aren't supposed to be.There are also viruses, particles that cannot live by themselves but which can invade your body's cells, take over those cells' growth machinery, and make the cells start producing more virus particles.</p>
                        </div>
                        <div class="questionwrp">
                            <h3>What is an immunization?</h3>
                        </div>
                        <div class="answerwrp">
                            <p>Teething patterns vary greatly from child to child. Some don’t have any teeth at their first birthday, while others are sporting a mouthful by then. The vast majority of babies sprout their first teeth between 4 and 7 months
                                of age. (If your child still doesn’t have any teeth by 15 to 18 months, schedule a visit with a pediatric dentist)</p>
                            <p>Teething patterns vary greatly from child to child. Some don’t have any teeth at their first birthday, while others are sporting a mouthful by then. The vast majority of babies sprout their first teeth between 4 and 7 months
                                of age. (If your child still doesn’t have any teeth by 15 to 18 months, schedule a visit with a pediatric dentist)</p>
                        </div>
                        <div class="questionwrp">
                            <h3>Why are vaccines given at such an early age?</h3>
                        </div>
                        <div class="answerwrp">
                            <p>Teething patterns vary greatly from child to child. Some don’t have any teeth at their first birthday, while others are sporting a mouthful by then. The vast majority of babies sprout their first teeth between 4 and 7 months
                                of age. (If your child still doesn’t have any teeth by 15 to 18 months, schedule a visit with a pediatric dentist)</p>
                            <p>Teething patterns vary greatly from child to child. Some don’t have any teeth at their first birthday, while others are sporting a mouthful by then. The vast majority of babies sprout their first teeth between 4 and 7 months
                                of age. (If your child still doesn’t have any teeth by 15 to 18 months, schedule a visit with a pediatric dentist)</p>
                        </div>
                        <div class="questionwrp">
                            <h3>What if my child misses a dose of vaccine?</h3>
                        </div>
                        <div class="answerwrp">
                            <p>Teething patterns vary greatly from child to child. Some don’t have any teeth at their first birthday, while others are sporting a mouthful by then. The vast majority of babies sprout their first teeth between 4 and 7 months
                                of age. (If your child still doesn’t have any teeth by 15 to 18 months, schedule a visit with a pediatric dentist)</p>
                        </div>
                        <div class="questionwrp">
                            <h3>How safe are vaccines?</h3>
                        </div>
                        <div class="answerwrp">
                            <p>Teething patterns vary greatly from child to child. Some don’t have any teeth at their first birthday, while others are sporting a mouthful by then. The vast majority of babies sprout their first teeth between 4 and 7 months
                                of age. (If your child still doesn’t have any teeth by 15 to 18 months, schedule a visit with a pediatric dentist)</p>
                        </div>
                        <div class="questionwrp">
                            <h3>Do vaccines always work?</h3>
                        </div>
                        <div class="answerwrp">
                            <p>Teething patterns vary greatly from child to child. Some don’t have any teeth at their first birthday, while others are sporting a mouthful by then. The vast majority of babies sprout their first teeth between 4 and 7 months
                                of age. (If your child still doesn’t have any teeth by 15 to 18 months, schedule a visit with a pediatric dentist)</p>
                        </div>
                        <div class="questionwrp">
                            <h3>What will happen if my child doesn’t get his vaccinations?</h3>
                        </div>
                        <div class="answerwrp">
                            <p>Teething patterns vary greatly from child to child. Some don’t have any teeth at their first birthday, while others are sporting a mouthful by then. The vast majority of babies sprout their first teeth between 4 and 7 months
                                of age. (If your child still doesn’t have any teeth by 15 to 18 months, schedule a visit with a pediatric dentist)</p>
                            <p>Teething patterns vary greatly from child to child. Some don’t have any teeth at their first birthday, while others are sporting a mouthful by then. The vast majority of babies sprout their first teeth between 4 and 7 months
                                of age. (If your child still doesn’t have any teeth by 15 to 18 months, schedule a visit with a pediatric dentist)</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <footer-nav-mini [links]="links"></footer-nav-mini>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>