<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <section class="page pageheader">
        <div class="container" *ngIf="pageData">
            <div class="col-xs-6 col-sm-6 col-lg-6 abannimgwrp">
                <div class="otherbannimg active"><img src="{{imgBasePath}}{{pageData.image}}" class="img-responsive" alt="{{pageData.title}}" title="{{pageData.title}}" /></div>
            </div>
            <div class="col-xs-6 col-sm-6 col-lg-6">
                <div class="ptitle">
                    <h1 class="underline">{{pageData.title}}</h1>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-lg-6 mcont100">
                <p>{{pageData.description}}</p>
            </div>

        </div>
    </section>
    <section class="aboutsectin2 wcuswrp">
        <div class="container" *ngIf="academicPrograms">
            <div class="row" #faqlist>
                <div class="col-md-12 col-sm-12 col-lg-12">
                    <ul class="atabs slider">
                        <div style="width: 838px;">
                            <ng-template ngFor let-academicProgram [ngForOf]="academicPrograms.group_sections">
                                <li style="width: 419px;" class="{{activeList == academicProgram.id  ? 'active slick-current' : ''}}">
                                    <a href="javascript:void()" (click)="showAcademicDetails(academicProgram, academicProgram.id)" data-id="data{{activeList.id}}">
                                        <div class="aiconwrp"><img src="{{imgBasePath}}{{academicProgram.image}}" alt="{{academicProgram?.title}}" /></div>
                                        <h2>{{academicProgram.title}}</h2>
                                    </a>
                                </li>
                            </ng-template>
                        </div>
                    </ul>
                    <div class="atabscontent slider">
                        <div class="atabsdata academicProgram">
                            <ng-template ngFor let-subGroup [ngForOf]="showAcademicProgramsDetails.sub_group">
                                <div class=" text-left">
                                    <h3>{{subGroup.title}}</h3>
                                </div>
                                <p [innerHTML]="subGroup.description"></p>

                                <div class="row">
                                    <div class="APtable table-responsive">
                                        <ng-template [ngIf]="subGroup.academic_programs.length">
                                            <table class="mainHead">
                                                <tr>
                                                    <td style="width:280px;">Program</td>
                                                    <td>City</td>
                                                    <td>Specialties</td>
                                                    <td>No. of seats</td>
                                                    <td>Duration</td>
                                                    <td>Eligibility</td>
                                                    <td>Session</td>
                                                </tr>
                                            </table>
                                        </ng-template>
                                        <div class="tableAccordion row">
                                            <ng-template ngFor let-academicProgram [ngForOf]="subGroup.academic_programs">
                                                <table class="subHead  TAHead" [class.active]="activeTableId == academicProgram.id" (click)="activeTable(academicProgram);">
                                                    <tr>
                                                        <td style="width:280px;">{{academicProgram.program_name}}</td>
                                                        <td>{{academicProgram.city}}</td>
                                                        <td>{{academicProgram.specialities}}</td>
                                                        <td>{{academicProgram.number_of_seats}}</td>
                                                        <td>{{academicProgram.duration}}</td>
                                                        <td>{{academicProgram.eligibility}}</td>
                                                        <td>{{academicProgram.session}}</td>
                                                    </tr>
                                                </table>

                                                <div class="col-xs-12 TAContant" [style.display]="activeTableId == academicProgram.id ? 'block' : 'none'">
                                                    <h4>{{academicProgram.title}}
                                                        <span>{{academicProgram.sub_title}}</span></h4>
                                                    <p [innerHTML]="academicProgram.description"></p>
                                                    <div class="col-xs-12 col-sm-4 contactBox" *ngFor="let contact of academicProgram.academic_contact">
                                                        <h4>{{contact.name}}
                                                            <span>{{contact.designation}} </span></h4>
                                                        <p><span class="call">{{contact.mobile}} </span> <br />
                                                            <span class="email">{{contact.email}}</span></p>
                                                    </div>


                                                </div>

                                            </ng-template>
                                        </div>
                                    </div>
                                </div>

                            </ng-template>


                            <div class="row" *ngIf="programsShowActive">
                                <ng-template ngFor let-question [ngForOf]="showAcademicProgramsDetails.questions">
                                    <div class="DoctorDesk-aco" [class.sel]="activeQuestionId == question.id" (click)="activeQuestion(question);">{{question.question}}</div>
                                    <div class="infotext" [style.display]="activeQuestionId == question.id ? 'block' : 'none'" [innerHTML]="question.answer"></div>

                                </ng-template>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>