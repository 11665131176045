<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>

    <section class="page wtwedo">
        <div class="row">
            <div class="col-md-5 col-xs-5 col-lg-4">
                <div class="circuleframe">
                    <img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" />
                </div>
                <!-- End Circle -->
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7">
                <div class="pagetitle">
                    <h1 class="tgradient">{{title}} </h1>
                    <div class="underline">
                        <img src="assets/images/underline2.png" alt="" />
                    </div>
                    <h3>{{subtitle}}</h3>
                </div>
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7 mcontentview">
                <p [innerHTML]="description"></p>
            </div>
        </div>

    </section>
    <section class="aboutsectin2 awardswapper">
        <div class="container">
            <div class="row nsg_text">
                <div class="col-xs-12 col-lg-12">
                    <h2>What is Rhesus factor?</h2>
                    <p>The rhesus-D factor (or antigen), which is a special protein, is also found on red blood cells. Whether or not you possess this factor is determined by&nbsp;your&nbsp;genes which are inherited from&nbsp;your&nbsp;parents.85% of people
                        have the rhesus-D factor and are called rhesus-D positive. 15% do not and are called rhesus-D negative. RhD status matters if a woman who is RhD negative becomes pregnant with a baby who is RhD positive. This can only happen if
                        the baby's father is RhD positive.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>How is our Rhesus blood group determined?</h2>
                    <p>We each inherit a D or d rhesus blood group from each of our parents. That means we can be DD, Dd, dD or dd. Big D is what we call dominant and if either one or both of our rhesus D group is a big D then we are rhesus positive. If
                        we are dd then we are rhesus negative. Being rhesus negative has not effect on us at all unless we come into contact with blood from a Big D group.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>What is Rhesus sensitisation ?</h2>
                    <p>If a rhesus negative person is injected with a small amount of rhesus positive blood then their body will recognise that blood as being foreign and in much the same way as we fight infection it will start to produce antibodies against
                        that blood. Initially the response to the first challenge is quite weak but if this happens again then the body will remember that it has seen this foreign problem before and will produce large numbers of antibody to fight off
                        the challenge. This is what happens with an infection. The first time we come across it we suffer the disease but produce some antibodies against it. The second time we come into contact with it our immune system produces the antibodies
                        to stop us getting the same infection twice.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>How does rhesus sensitisation occur?</h2>
                    <p>It can occur because of a miss matched blood transfusion although this is very unlikely. However in pregnancy it is not unusual for small amounts of the baby’s blood to cross through the placenta into the mum’s blood. This is more
                        likely to occur if there is bleeding after the 12th week of pregnancy, if the mother has an amniocentesis or if there is significant trauma to the maternal abdomen such as in a car accident. In addition the baby’s blood often enters
                        into the mums circulation at the time of delivery. Such events are only likely to cause a problem if the mother is rhesus negative.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>Why is being rhesus negative a problem?</h2>
                    <p>If you are rhesus negative and you are carrying a rhesus positive child then if some of your baby’s blood enters your circulation this will potentially cause you to form antibodies against that blood type.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>What is the effect of having rhesus antibodies?</h2>
                    <p>At very low levels (less than 4 international units) these have little or no effect. However, if the levels rise then they will cross through the placenta and start to attach themselves to the baby’s blood. The baby then recognises
                        these red cell as being covered in antibodies and will destroy those red cells. With low levels of antibodies the effect is small and the baby can compensate by simply producing more red blood cells. If the levels continue to rise
                        then it will reach a point when the baby can no longer produce red blood cells faster than they are being broken down. At this point the baby starts to become anaemic. If the baby becomes very anaemic then it develops a condition
                        called immune hydrops. This is a medical term for a baby who had developed fluid under the skin in the abdomen and in the chest. Left untreated the baby will become more and more anaemic and would eventually die.</p>
                    <p><strong>First pregnancy</strong></p>
                    <p>During pregnancy and childbirth the rhesus factor D can have an important role to play when a RhD-negative mother is expecting a&nbsp; RhD-positive baby. This can only happen if the baby's father is RhD-positive – but not all children
                        who have a RhD-positive father will be RhD-positive.<br> During pregnancy the placenta acts as a barrier between the red blood cells of the mother andbaby. However, sometimes small amounts of the baby’s blood can cross over into
                        the mother’s blood stream.<br> If any of the blood cells from a RhD-positive baby get into the blood of a RhD-negative woman, she will react to the D antigen in the baby's blood as though it is a foreign substance and will produce
                        antibodies. This is called ‘rhesus sensitisation’. This is not usually a problem during the first pregnancy, but can have serious consequences for subsequent pregnancies.<br> The most common time for a baby's blood cells to get
                        into the mother's blood is at the time of birth. But it can happen at other times, for example during a miscarriage or abortion, or if something happens during the pregnancy such as having an amniocentesis, chorionic villus sampling,
                        vaginal bleeding or after abdominal injury. An event that could cause the mother to produce antibodies against the D antigen is called a 'potentially sensitising event'.</p>
                    <p><br> <strong>Subsequent pregnancies</strong></p>
                    <p>As a general rule the first child that triggers this sensitisation does not suffer any adverse consequences, as it will already have been born by the time antibodies have developed. However, if the woman goes on to become pregnant
                        with a second RhD-positive child, antibodies may cross into the baby’s blood and attack the red blood cells. This is called ‘haemolytic disease of the newborn’ or ‘HDN’. HDN can be mild, but also can lead to jaundice, brain damage,
                        or even to the death of the child.</p>
                    <p>With further RhD-positive children the antibody reaction becomes faster and more serious. Thanks to rhesus prophylaxis with anti-D immunoglobulin, rhesus sensitisation during pregnancy and after childbirth can now largely&nbsp; be
                        prevented.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>What is anti-D prophylaxis?</h2>
                    <p>Prophylaxis is the word given to a medicine that is used to prevent something happening.<br> Anti-D prophylaxis means giving anti-D immunoglobulin to prevent a woman producing antibodies against RhD-positive blood cells and so to prevent
                        the development of HDN in an unborn baby. Anti-D immunoglobulin is made from a part of the blood called plasma that is collected from donors.<br> Routine antenatal anti-D prophylaxis (RAADP) is given by injection to pregnant women
                        who are RhD-negative usually at weeks 28 and 34 of their pregnancy. After the birth, a blood sample will be taken to test the baby's blood group. If the baby is RhD positive, a mother who is RhD negative will be given a further
                        injection of anti-D immunoglobulin – this is known as postnatal anti-D prophylaxis<br> If an RhDnegative woman has a potentially sensitising event during the pregnancy she will be offered anti-D prophylaxis at the time of the event:
                        this is known as antenatal anti-D p</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>Can the baby be treated?</h2>
                    <p>Yes. There are two ways to treat a baby. The first is to deliver the baby early and then treat the baby outside of the womb. The second is to treat the baby before delivery with a blood transfusion. Using ultrasound we identify either
                        the site of the cord insertion into the placenta or a large blood vessel in the baby’s abdomen. A needle is then passed through the mother’s abdomen, again watching all the time on ultrasound, and into the baby’s blood vessel.
                        Blood is taken to see if the baby is anaemic and if this is confirmed blood is then transfused into the baby. The amount of blood given is dependant on the number of weeks pregnant you are and how anaemic the baby is. Although
                        this will correct the anaemia it does not cure the problem and therefore further transfusions have to be given about every two weeks until delivery.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>What are the risks of fetal blood transfusions?</h2>
                    <p>Fetal blood transfusions use very fresh blood that has been cross matched against the mother, we choose donors who we have tested for infection and are known to be healthy. The blood is treated so as to provide as minimal a risk as
                        possible to the baby. This procedure is not without risk and like amniocentesis carries a risk of causing a miscarriage. That risk is in the order of 1 in a hundred for each transfusion that is performed.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>How do we decide when a transfusion is necessary?</h2>
                    <p><strong>Maternal antibodies</strong><br> All women have their antibodies checked as part of their routine booking bloods and again at around 28 weeks. If either of those tests suggest the presence of a blood group antibody these results
                        will be discussed with you along with the further action required. Usually this just involves a repeat blood &nbsp;test every 4 weeks. As long as the antibody levels are below 4 international units then they rarely cause a problem.
                        If however they rise above this level then you will be referred for more ultrasound scans.</p>
                    <p><strong>Ultrasound assessment</strong><br> It has been known for some time that we can screen for fetal anaemia by studying the blood flow in a blood vessel inside the baby’s head. This sounds frightening but simply involves scanning
                        the baby as was done at the booking visit or the time of the mid pregnancy scan. We then use a technique called colour flow Doppler which allows us to spot the blood flow inside the head. Once we know where the blood flow is it
                        is very easy for the machine to record the flow inside the vessel and for us to calculate the maximum flow velocity. We usually take a number of recordings and then compare your result with the expected level at that stage of the
                        pregnancy. Unfortunately the result can change quickly so we often have to repeat this measurement on a weekly basis. If the value is very high that suggests the baby is anaemic and we would then make a decision as to whether to
                        deliver or treat.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>Delivery or transfusion?</h2>
                    <p>The decision to deliver or transfuse depends on a number of factors but as a general rule if the baby is less than 34 weeks then we would opt for a transfusion and if beyond that stage we would deliver.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>How can we prevent this problem?</h2>
                    <p>If we know that a women has had an incident that may cause the baby’s blood to enter her blood stream we can do two things. One is to take blood from the mother and perform a test called a Kleihauer. This enables the lab to see if
                        there is any fetal blood in the mum’s blood stream and secondly if it is present is allows us to calculate how much we think there is. Secondly we can give the mother anti D. This is a dose of rhesus antibodies which will attach
                        to the fetal red cells. Once coated in antibody the mother will destroy them before she has a chance to produce her own. This is why we give anti D after miscarriages which occur after 12 weeks, amniocentesis or episodes of bleeding.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>What happens after delivery with a rhesus negative mother?</h2>
                    <p>After delivery we take blood from the cord and from the mother. The cord blood enables us to check the fetal blood group. If the baby is rhesus negative like the mother then we need do nothing further. However if the baby is rhesus
                        positive we would perform a Kleihauer and give an appropriate amount of anti D.</p>
                </div>
            </div>
        </div>
    </section>
    <ng-template [ngIf]="ins_links.length > 0">
        <footer-nav-mini [links]="ins_links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>