import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  ViewChildren,
  QueryList,
  HostListener,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL } from '../services/constants';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
declare let initGoogleMap: any;
declare let initCentersSpecialityTabs: any;
//declare let ourCenter:any;
@Component({
  selector: 'our-center-detail',
  templateUrl: './our-center-detail.component.html',
  styles: [
    `
      .visitext {
        white-space: pre-wrap;
      }
      .gmapwrp .sidelocdetwrp .gmheader .box {
        width: 100%;
      }
      .specdoctorslider {
        list-style: none;
        position: relative;
        color: #4d4d4d;
        font-family: 'dinrg';
        font-size: 14px;
        float: left;
        text-align: center;
        padding: 0px;
      }
      .showdiv {
        opacity: 1;
      }
      .hidediv {
        opacity: 0;
      }
    `,
  ],
})
export class OurCenterDetailComponent
  implements OnDestroy, OnInit, AfterViewInit
{
  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    public metaTitle: Title,
    public meta: Meta
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
    }
  }
  public currentSection: string;
  public specilityDoctor: any[] = [];
  public centerList: any[] = [];
  public visitor_information: string;
  public center_brief_facilities: any[] = [];
  public address: string;
  public telephone: string;
  public lat_lng: string;
  public our_speciality_features: any = [];
  public services: any = [];
  public features: any = [];
  public docspecsfeatures: any = [];
  public brief_facilities: any[] = [];
  public title: any = '';
  public subtitle: any = '';
  public description: any = '';
  public imgsrc: any = '';
  public imgBasePath = '';
  public hidden = 'hidden';
  public logo = '';
  public links: any = [];
  public bread_crumb_links: any = [];
  public bread_crumb_child_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Child Care', link: '/child-care/about', class: '' },
    { label: 'Our Centres', link: '/child-care/centres', class: '' },
    { label: 'About Centre', link: '', class: '' },
  ];

  public bread_crumb_women_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Women Care', link: '/women-care/about', class: '' },
    { label: 'Our Centres', link: '/women-care/centres', class: '' },
    { label: 'About Centre', link: '', class: '' },
  ];

  public bread_crumb_fertility_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Fertility Care', link: '/fertility-care/about', class: '' },
    { label: 'Our Centres', link: '/fertility-care/centres', class: '' },
    { label: 'About Centre', link: '', class: '' },
  ];

  public child_care_links: any = [
    { label: 'About Child Care', link: '/child-care/about', class: '' },
    { label: 'Child Safety', link: '/child-care/child-safety', class: '' },
    { label: 'Doctors', link: '/child-care/doctors', class: '' },
    { label: 'Our Specialities', link: '/child-care/specialities', class: '' },
    { label: 'Our Centres', link: '/child-care/centres', class: 'active' },
    { label: 'Vaccination', link: '/child-care/vaccination', class: '' },
    { label: 'Testimonials', link: '/child-care/testimonials', class: '' },
    { label: 'Share Feedback', link: '/child-care/feedback', class: '' },
    {
      label: 'Book An Appointment',
      link: '/child-care/book-an-appointment',
      class: '',
    },
  ];

  public women_care_links: any = [
    { label: "About Women's Care", link: '/women-care/about', class: '' },
    { label: 'Doctors', link: '/women-care/doctors', class: '' },
    { label: 'Our Specialities', link: '/women-care/specialities', class: '' },
    { label: 'Our Centres', link: '/women-care/centres', class: 'active' },
    { label: 'Testimonials', link: '/women-care/testimonials', class: '' },
    { label: 'Share Feedback', link: '/women-care/feedback', class: '' },
    {
      label: 'Book An Appointment',
      link: '/women-care/book-an-appointment',
      class: '',
    },
  ];

  public fertility_care_links: any = [
    { label: 'About Fertility Care', link: '/fertility-care/about', class: '' },
    { label: 'Doctors', link: '/fertility-care/doctors', class: '' },
    {
      label: 'Our Specialities',
      link: '/fertility-care/specialities',
      class: '',
    },
    { label: 'Our Centres', link: '/fertility-care/centres', class: 'active' },
    { label: 'Testimonials', link: '/fertility-care/testimonials', class: '' },
    { label: 'Share Feedback', link: '/fertility-care/feedback', class: '' },
    {
      label: 'Book An Appointment',
      link: '/fertility-care/book-an-appointment',
      class: '',
    },
  ];

  public virtual_tour_link: any;
  public activeRoute: any = [];
  public speciality_master: number;
  public centre_id: any;
  public activePage: any;
  public paramsId: any;
  //public our_speciality_features_id:any = 0;
  //@ViewChildren('abouttabs') things: QueryList<any>;
  activeBreadCrum(currentSection) {
    switch (currentSection) {
      case 'child-care':
        this.bread_crumb_links = this.bread_crumb_child_links;
        this.links = this.child_care_links;
        this.logo = 'rainbow-logo';
        break;
      case 'women-care':
        this.bread_crumb_links = this.bread_crumb_women_links;
        this.links = this.women_care_links;
        this.logo = 'birth-right-logo';
        break;
      case 'fertility-care':
        this.bread_crumb_links = this.bread_crumb_fertility_links;
        this.links = this.fertility_care_links;
        this.logo = 'birth-right-logo';
        break;
      default:
        this.logo = 'logo';
    }
  }
  public aboutusliderconConfig: any = {
    slide: 'li',
    slidesToShow: 4,
    slidesToScroll: 4,
    fade: true,
    asNavFor: '.aboutuslider',
    infinite: true,
    adaptiveHeight: true,
    arrows: true,
    prevArrow:
      '<a class="slick-prev"><img src="assets/images/prv-arrow2.png" alt="" /></a>',
    nextArrow:
      '<a class="slick-next"><img src="assets/images/nxt-arrow2.png" alt="" /></a>',
  };
  public showDoctor: boolean;
  @ViewChildren('aboutSlider') things: QueryList<any>;
  @ViewChildren('abouticonslider') iconSliderthing: QueryList<any>;
  ngOnInit() {
    this.route.params.subscribe((params) => {
      //this.currentSection = params['id'];
      this.currentSection = params['section'];
      this.paramsId = params['id'];
      this.api
        .getSpecialityMasters()
        .map((res) => res)
        .subscribe((data) => {
          //console.log(data);
          this.activeRoute = data.speciality_masters.filter((filterdata) => {
            if (this.currentSection == 'women-care') {
              return filterdata.slug == 'women-care';
            } else {
              return filterdata.slug == this.currentSection;
            }
          });
          this.activePage = this.activeRoute[0].speciality;
          //console.log(this.activeRoute);
          this.speciality_master = this.activeRoute[0].id;
        });
      this.api.getCenterDetailsBySlug(this.paramsId).subscribe(
        (res) => {
          //console.log(res);
          this.centre_id = res.centers[0].id;
          let imgUrl = res.centers[0].circleimage
            ? res.centers[0].circleimage
            : this.imgsrc;
          this.title = res.centers[0].center_name;
          //this.subtitle = res.centers[0].subtitle,
          this.description = res.centers[0].description;
          this.address = res.centers[0].address;
          this.telephone = res.centers[0].telephone;
          this.lat_lng = res.centers[0].lat_long;
          this.imgsrc = imgUrl;
          this.imgBasePath = IMG_BASE_URL;
          this.center_brief_facilities = res.centers[0].brief_facilities;
          this.visitor_information = res.centers[0].visitor_information;

          //console.log(res.center[0].meta_title);

          this.getMetaInfo(res.centers[0]);
          this.filterCenterSpecility(
            this.currentSection,
            res.centers[0].our_speciality_features
          );
          this.virtual_tour_link = res.centers[0].virtual_tour_link;

          let our_speciality_features_id = this.our_speciality_features[0]
            ? this.our_speciality_features[0].id
            : 0;
          this.api
            .getDoctorListBySpecilityMaster(
              this.speciality_master,
              our_speciality_features_id,
              this.centre_id,
              0
            )
            .subscribe((res) => {
              this.specilityDoctor = res;
              this.showDoctor = this.showDoctorFunc(this.specilityDoctor);
            });
          let user_lat_long_info = res.centers[0].lat_long.split(',');
          //console.log(user_lat_long_info);
          initGoogleMap(user_lat_long_info[0], user_lat_long_info[1]);
          //console.log(this.visitor_information);
        },
        (err) => {
          console.log(err);
        }
      );
    });
    this.activeBreadCrum(this.currentSection);
    this.api.getHealthCareServices('center-detail').subscribe(
      (data) => {
        this.services = data.healthcare_services[0];
      },
      (error) => {
        console.log(error);
      }
    );

    this.api.getBriefFacilities(`about-${this.paramsId}-center`).subscribe(
      (data) => {
        if (data) {
          this.brief_facilities = data.brief_facilities;
        }
      },
      (error) => {
        console.log(error);
      }
    );

    this.api.getDoctorSpecialityFeaturesByName('Neonatal').subscribe(
      (data) => {
        this.docspecsfeatures = data.our_speciality_features;
      },
      (error) => {
        console.log(error);
      }
    );

    this.api.getspeciality_features().subscribe(
      (data) => {
        this.features = data.our_speciality_features;
      },
      (error) => {
        console.log(error);
      }
    );
    this._getcenter();
  }
  getMetaInfo(metadetail: any) {
    this.metaTitle.setTitle(metadetail.meta_title);
    this.meta.updateTag({ name: 'keywords', content: metadetail.meta_keyword });
    this.meta.updateTag({
      name: 'description',
      content: metadetail.meta_description,
    });
    this.meta.addTags([
      { property: 'og:title', content: metadetail.meta_title },
      { property: 'og:description', content: metadetail.meta_description },
      { property: 'og:keywords', content: metadetail.meta_keyword },
    ]);
  }
  filterCenterSpecility(currentSection: string, data: any) {
    //console.log(currentSection);
    this.our_speciality_features = data.filter((res) => {
      //console.log(res.page)
      return res.page == currentSection;
    });
  }
  _getcenter() {
    this.api.getCentreCities().subscribe(
      (res) => {
        this.centerList = res;
      },
      (err) => {
        console.error(err);
      }
    );
  }
  changeCenter(value) {
    //console.log(value);
    document.location.href = '/' + this.currentSection + '/centre/' + value;
  }
  viewDoctorList(speciality_feature): void {
    this.api
      .getDoctorListBySpecilityMaster(
        this.speciality_master,
        speciality_feature,
        this.centre_id,
        0
      )
      .subscribe((res) => {
        this.specilityDoctor = res;
        this.showDoctor = this.showDoctorFunc(this.specilityDoctor);
        //console.log(this.showDoctor);
      });
  }
  showDoctorFunc(doctorList) {
    return this.specilityDoctor.length > 0 ? true : false;
  }
  ngAfterViewChecked() {
    //console.log('after view checked');
    //initCentersSpecialityTabs();
  }
  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
      initGoogleMap();
      this.initCentersSpecialityTabs();
      this.iconSliderthing.changes.subscribe((t) => {
        //$(".aboutuslidercon").removeClass('slick-initialized').removeClass("slider").removeClass("slick-slider");

        $('.aboutuslidercon')
          .not('.slick-initialized')
          .slick(this.aboutusliderconConfig);
      });
    }
  }
  @HostListener('window:load', []) onWindowLoad() {
    $('.apapproachlist').slick({
      slide: 'li',
      dots: false,
      arrows: true,
      prevArrow:
        '<a class="slick-prev"><img src="assets/images/prv-arrow2.png" alt="" /></a>',
      nextArrow:
        '<a class="slick-next"><img src="assets/images/nxt-arrow2.png" alt="" /></a>',
      infinite: false,
      autoplay: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1100,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 680,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
    //$(".aboutuslidercon").removeClass('slick-initialized').removeClass("slider").removeClass("slick-slider");

    $('.aboutuslidercon')
      .not('.slick-initialized')
      .slick(this.aboutusliderconConfig);
    $('.aboutuslider')
      .removeClass('slick-initialized')
      .removeClass('slider')
      .removeClass('slick-slider');
    $('.aboutuslider')
      .not('.slick-initialized')
      .slick({
        slide: 'li',
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: true,
        prevArrow:
          '<a class="slick-prev"><img src="assets/images/prv-arrow2.png" alt="" /></a>',
        nextArrow:
          '<a class="slick-next"><img src="assets/images/nxt-arrow2.png" alt="" /></a>',
        asNavFor: '.aboutuslidercon',
        dots: false,
        centerMode: true,
        centerPadding: 20,
        focusOnSelect: true,
        infinite: true,
        responsive: [
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 680,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
  }

  initCentersSpecialityTabs() {}
  ngOnDestroy() {}
}
