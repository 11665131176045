<div class="container-fluid">
    <div class="background">
        <img src="assets/images/bg_childcare.png" data-t="bg_01_00.jpg" data-d="bg_childcare.png" class="bgImg bg0 active" />
    </div>
    <div class="loder"></div>
    <sidenav></sidenav>
    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <section class="page pageheader">
        <div class="row">
            <div class="container">
                <div class="col-xs-12 col-sm-7 col-lg-7 abannimgwrp">
                    <div class="aboutbannimg"><img src="assets/images/about-Whychooseus.png" class="img-responsive" alt="{{title}}" title="{{title}}" /></div>
                </div>
                <div class="col-xs-12 col-sm-5 col-lg-5">
                    <div class="ptitle blue">
                        <h1>{{title}}</h1>
                        <h3>{{subtitle}}</h3>
                    </div>
                </div>
                <div class="col-xs-12 mcontentview">
                    <p [innerHTML]="description"></p>
                </div>
            </div>
        </div>
    </section>
    <section class="aboutsectin2 wcuswrp">
        <div class="container">
            <div class="row" #whychoosetabs>
                <div class="col-md-12 col-sm-12 col-lg-12">
                    <ul class="atabs slider">
                        <li class="active" *ngFor="let service of services">
                            <a href="javascript:void()" data-id="data1">
                                <div class="aiconwrp"><img src="{{imgBasePath}}{{service.image}}" alt="" /></div>
                                <h2>{{service.title}}</h2>
                            </a>
                        </li>
                        <!-- <li>
                	<a href="javascript:void()" data-id="data2">
                        <div class="aiconwrp"><img src="assets/images/icon-wcus2.png" alt="" /></div>
                        <h2>We are there for you anytime</h2>
                    </a>
                </li>
                <li>
                	<a href="javascript:void()" data-id="data3">
                        <div class="aiconwrp"><img src="assets/images/icon-wcus3.png" alt="" /></div>
                        <h2>Being with you in times  of your critical needs</h2>
                    </a>
                </li> -->
                    </ul>
                    <div class="atabscontent slider">
                        <div class="atabsdata" *ngFor="let service of services">
                            <p [innerHTML]="service.description"></p>
                        </div>
                        <!-- <div class="atabsdata">
                	<p>Rainbow Hospitals for Children is 200- bed comprehensive health center for Pediatrics and offers the best care available in modern pediatric medicine, delivered in its state of the art facilities, spread over five locations and expanding. We are among the top five multi specialty Pediatric Hospitals of our country and have won this recognition for the last three consecutive years from “The Week” and “HANSA” research. We are also the winner of CNBC TV 18 and ICICI Lombard in India Health care awards 2010 for the Best Children’s Hospital in India.</p>
                </div>
                <div class="atabsdata">
                	<p>Rainbow Hospitals for Children is 300- bed comprehensive health center for Pediatrics and offers the best care available in modern pediatric medicine, delivered in its state of the art facilities, spread over five locations and expanding. We are among the top five multi specialty Pediatric Hospitals of our country and have won this recognition for the last three consecutive years from “The Week” and “HANSA” research. We are also the winner of CNBC TV 18 and ICICI Lombard in India Health care awards 2010 for the Best Children’s Hospital in India.</p>
                </div> -->
                    </div>
                </div>
            </div>
        </div>
    </section>
    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>

    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>