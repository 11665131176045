<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>

    <section class="page wtwedo">
        <div class="row">
            <div class="col-md-5 col-xs-5 col-lg-4">
                <div class="circuleframe">
                    <img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" />
                </div>
                <!-- End Circle -->
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7">
                <div class="pagetitle">
                    <h1 class="tgradient">{{title}} </h1>
                    <div class="underline">
                        <img src="assets/images/underline2.png" alt="" />
                    </div>
                    <h3>{{subtitle}}</h3>
                </div>
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7 mcontentview">
                <p [innerHTML]="description"></p>
            </div>
        </div>

    </section>
    <section class="aboutsectin2 awardswapper">
        <div class="container">
            <div class="row nsg_text">
                <div class="col-xs-12 col-lg-12">
                    <h2><strong>Pregnancy Contractions:</strong> I am in the eighth month of pregnancy and have been feeling contractions when my stomach hardens. Is there any cause for worry?</h2>
                    <p>What you are feeling now are called Braxton Hicks contractions. They occur due to tightening of the uterine muscles. They get their name from John Braxton Hicks, an English doctor who first described them in 1872</p>
                    <p>Braxton Hicks contractions feel differently for each woman. They are generally infrequent, irregular, and involve only mild cramping. Some find that they are not bothered by these contractions, while others feel very uncomfortable.
                        Typically, Braxton Hicks contractions are described as causing more discomfort than pain.</p>
                    <p>However these contractions while by themselves quite innocuous; cause women confusion as to whether or not they were going into actual labor. Labor contractions are noticeably longer as well as more regular, frequent and painful than
                        Braxton Hicks contractions. Also, labor pains will increase in frequency, duration, and intensity as time goes on, while Braxton Hicks remain unpredictable and non-rhythmic. Braxton Hicks may reduce in intensity or cease when you
                        change positions.</p>
                    <p>Frequent Braxton Hicks contractions can also be uncomfortable, but not painful. If your Braxton Hicks are bothering you, try some of these suggestions:</p>
                    <ul>
                        <li>Drink lots of water. Dehydration can make your muscles spasm, bringing on a contraction.</li>
                        <li>Avoid Caffeine</li>
                        <li>Rhythmic breathing may alleviate the discomfort of Braxton Hicks contractions.</li>
                        <li>Lie down on your left side when you have a contraction. This should help to ease the pain and keep you rested.</li>
                        <li>Change the position you are in or switch activities for a while when you have a contraction.</li>
                        <li>Urinate when you need to. A full bladder can sometimes trigger Braxton Hicks &nbsp;</li>
                    </ul>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2><strong>Pregnancy Weight Gain / Overweight :</strong> I am six months pregnant and my doctor says I’m overweight. What are the dangers of being overweight?</h2>
                    <p>The most clinically relevant definition of obesity is the bodymass index. BMI is weight in kilograms divided by height in meters squared (kg/m2).Overweight is BMI 25–30 kg/m2 and obese is BMI greater 30 kg/m2. BMI should be calculated
                        from pre-pregnancy height and weight.</p>
                    <p>The risk of spontaneous abortion is increased in obese women. Women with high BMI in pregnancy are at risk for medical complications such as cardiac disease, pulmonary disease, gestational hypertension, gestational diabetes, and obstructive
                        sleep apnea. They also have an increased risk of Caesarean Section. Maternal obesity is a significant risk factor for certain birth defects (neural tube defects) in babies. Ultrasound visualization of fetal anatomy is more difficult
                        in women with high BMI. It is possible that this decreased sensitivity for evaluation of anatomy has contributed to a higher proportion of live-born infants affected by malformations in this population. They also have an increased
                        risk of stillbirth (death of the fetus in third trimester).</p>
                    <p>Women should be encouraged to enter pregnancy with a BMI of less than 25 kg/m. pregnant. Women with high BMI should receive counseling about weight gain, nutrition, and food choices by an experienced nutritionist.Body weight and weight
                        gain are a result of energy intake against total energy expenditure. Regular exercise during pregnancy may help to reduce some of these risks. The suggested weight gain in pregnancy is based on BMI.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2><strong>Bowel Bleeding:</strong>I am in my sixth month of pregnancy and have been noticing some bleeding with my bowel movements. What could be causing this?</h2>
                    <p>You should be examined by your doctor to try to identify the source of the bleeding. Because it can sometimes be difficult to tell whether the blood is coming from your rectum or your vagina.</p>
                    <p>The most common cause of this kind of bleeding in pregnancy is due to hemorrhoids which are varicose (swollen) veins in and around the rectum. Hemorrhoids are relatively common during pregnancy, particularly in the last trimester and
                        in the weeks after giving birth. If you strain during a bowel movement, pass hard stools, or rub when you wipe, these swollen veins may bleed.</p>
                    <p>Anal fissures are another cause of rectal bleeding. These painful cracks in the skin of the anus may be caused by passing hard stools as a result of constipation which is common during pregnancy.</p>
                    <ul>
                        <li>Drink plenty of water: at least six to eight glasses a day.</li>
                        <li>Exercise regularly. Walking, swimming and yoga can all help ease constipation and leave you feeling more fit and healthy.</li>
                        <li>Eat food rich in fiber: These include whole-grain foods (oatmeal, whole wheat bread, whole wheat pasta and brown rice), fresh fruits (including the skin and pulp), dried or stewed fruits (such as prunes, raisins, or apricots),
                            root vegetables (such as carrots, turnips), raw or fresh vegetables, such as cabbage.</li>
                        <li>Eat frequent small meals</li>
                        <li>Your doctor will prescribe smoothening creams to applied for the hemorrhoids</li>
                        <li>Ideally wash with water and use moistened soft toilet tissue paper to wipe after bowel movements</li>
                        <li>Using ice packs or cold compresses over the hemorrhoids also helps</li>
                    </ul>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2><strong>Jaundice in Pregnancy:</strong>In my seventh month of pregnancy I have developed jaundice. What are the implications of this and will it affect my baby?</h2>
                    <p>Jaundice in pregnancy, whilst relatively rare, has potentially serious consequences for maternal and fetal health. Some common causes of jaundice in pregnancy are</p>
                    <ul>
                        <li>Pre-eclampsia and HELLP syndrome</li>
                        <li>Hepatitis (infections)</li>
                        <li>Acute fatty liver of pregnancy</li>
                        <li>Cholestasis</li>
                    </ul>
                    <p>If diagnosis is established some of the conditions warrant urgent delivery.</p>
                    <ul>
                        <li>Depending on the cause can lead to bleeding disorders in the mother, liver and renal failure.</li>
                        <li>It can cause prematurity in the baby, lead to reduced oxygen supply and occasionally death of the bay in the womb</li>
                        <li>It is a medical emergency and warrants hospitalization, urgent evaluation and treatment. It is crucial that a right diagnosis is established at the earliest.</li>
                    </ul>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2><strong>Episiotomy – Required or Not During Delivery? :</strong>When a woman has a normal delivery is it a normal procedure to have an incision to make the delivery easier? Is this very painful? Does it cause any problem later? Can
                        it be avoided?</h2>
                    <p>An incision or cut performed during normal delivery is called an episiotomy. This cut is made through your vagina and perineal muscles. An episiotomy in modern practice is not a routine practice anymore. It is restricted only to those
                        women who need it.</p>
                    <p><strong>An episiotomy may be needed</strong></p>
                    <ul>
                        <li>Speed up the birth if there is concern about the baby's heart rate.</li>
                        <li>Prevent Tearing if the baby's head or shoulders are too big for the mother's vaginal opening, or the baby is in a breech position</li>
                        <li>Protects against incontinence</li>
                        <li>Protects against pelvic floor relaxation</li>
                        <li>Heals easier than tears</li>
                    </ul>
                    <p>Most of these indications can only be evident during the process of delivery and hence there cannot be a pre-planned decision to do or not to do an episiotomy. An episiotomy usually heals without problems and may be easier to repair
                        than multiple tears.</p>
                    <p><strong>The following have been reported as side effects of the episiotomy:</strong></p>
                    <ul>
                        <li>Infection</li>
                        <li>Increased Pain</li>
                        <li>Longer healing times</li>
                        <li>Increased discomfort when intercourse is resumed</li>
                    </ul>
                    <p><strong>Episiotomies are not always necessary, and there is much you can do to lessen your chances of having this surgical incision.</strong></p>
                    <p>Some preventive measures are:</p>
                    <ul>
                        <li>Good nutrition (Healthy skin stretches more easily)</li>
                        <li>Perineal massages during pregnancy especially in last trimester</li>
                        <li>A slowed second stage (controlled pushing)</li>
                        <li>Warm compresses and perineal support during delivery</li>
                    </ul>
                    <p>Remember, as with any medical procedure, there is always a time and a place where it is a valid option.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2><strong>Insulin Usage and Requirement in Gestational Diabetes:</strong> My wife is a diabetic patient since the last four years and has to inject herself once in the afternoon. We got married a year back and now she is pregnant. We
                        are both worried about the effect of insulin on the child. Is there any adverse effect on the child?</h2>
                    <p>In diabetic women the pancreas is unable to produce enough insulin. Insulin moves glucose out of your bloodstream and into your cells to use as energy. Insulin is the hormone which is normally present in your body and by taking it
                        you will not be causing any harm to yourself or the baby. Insulin does not hurt the fetus. <em><strong>In fact it is the high blood sugars which cause damage and not insulin.</strong></em> In a woman who is already diabetic the
                        insulin requirements increase in pregnancy due to the effects of pregnancy hormones. If blood sugar control is not maintained it causes damage not only to the fetus but also increases risk of kidney and eye damage in the mother.</p>
                    <p>Taking insulin is the same as any other part of this treatment plan. It's just another way to help you stay healthy.</p>
                    <p><strong>Here are some other things you should know about insulin:</strong></p>
                    <ul>
                        <li>Insulin does not cause any harm to the fetus</li>
                        <li>An increase in the amount or dosage of insulin you need does not mean that your pregnancy is in danger. The amount of insulin you take will probably increase as your pregnancy goes on. Because your insulin resistance increases
                            as your pregnancy continues, your body needs more insulin to overcome this resistance. The goal is to keep your blood sugar under control, no matter how much insulin it takes. Most women take two insulin shots each day, but
                            you may get better glucose control with three injections.</li>
                        <li>You may need more insulin if you are under high amounts of stress or if you are sick because your blood sugar level gets higher on its own in these cases. Some medicines can also cause blood sugars to rise above the healthy range.</li>
                    </ul>
                    <p><strong>Special instructions for women taking insulin</strong></p>
                    <ul>
                        <li><strong>Follow a regular eating schedule.</strong> Your health care provider can tell you when to take the insulin and when to eat your meals so that the timing of both is correct. Do not skip or delay meals and snacks when taking
                            insulin because this can affect your glucose-insulin balance.</li>
                        <li><strong>Know the symptoms of low blood sugar.</strong> If your blood sugar level drops below 60 at any time, you have <strong>hypoglycemia</strong> (hypo means low, and glycemia means sugar). Low blood sugar can be dangerous. Hypoglycemia
                            is not common in women with gestational diabetes, but you are at greater risk for it if you take insulin.</li>
                        <li><strong>Be careful about doing physical activity, but remain active.</strong> Because both insulin and physical activity lower your blood sugar level, when combined they can cause your blood sugar level to drop very quickly. Test
                            your blood sugar before you do any physical activity. If your level is low, eat something and test again to make sure your level is higher before you start an activity. Be smart about how much physical activity you do, how
                            much you eat, and how much insulin you take.</li>
                        <li><strong>Be prepared.</strong> Take your insulin supplies with you when you go out, especially if you are going to be gone a long time. You should also take some form of sugar with you, in your purse, in your car's glove compartment,
                            or in your pocket, in case your blood sugar drops too low. The best form of sugar for an emergency is glucose powder or glucose tablets.</li>
                        <li><strong>Test your blood sugar if you start to feel dizzy, faint, or tired</strong></li>
                        <li><strong>Report any abnormal blood sugar level to endocrinologist right away, in case a change in your treatment plan is needed</strong></li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <ng-template [ngIf]="ins_links.length > 0">
        <footer-nav-mini [links]="ins_links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>