<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>


    <section class="page pageheader">
        <div class="container">
            <div class="col-xs-6 col-sm-6 col-lg-6 abannimgwrp">
                <div class="otherbannimg"><img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" /></div>
            </div>
            <div class="col-xs-6 col-sm-6 col-lg-6">
                <div class="ptitle">
                    <h1>{{title}} </h1>
                    <h3>{{subtitle}}</h3>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-lg-6 mcont100">
                <p [innerHTML]="description"></p>
            </div>

        </div>
    </section>
    <section class="aboutsectin2 wcuswrp acadamicWrp">
        <div class="container">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-lg-12">
                    <ul class="aCircleList">
                        <li>
                            <div class="iconwrp">
                                <a href="javascript:void(0);">
                                    <img src="../../assets/images/academic/icon-webinar.png" alt="" />
                                    <h4 class="active">Webinars</h4>
                                </a>
                            </div>
                        </li>
                        
                        <li>
                            <div class="iconwrp">
                                <a href="/other/academic-programs">
                                    <img src="../../assets/images/academic/icon-programs.png" alt="" />
                                    <h4>Programs</h4>
                                </a>
                            </div>
                        </li>
                        <li>
                            <div class="iconwrp">
                                <a href="/other/academic-article">
                                    <img src="../../assets/images/academic/icon-article.png" alt="" />
                                    <h4>Publications</h4>
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-12 pgLine">
                    <p>Join interactive webinars addressing a variety of trending topics for future healthcare leaders.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-lg-12 headingWrp">
                    <h3>Upcoming Webinars</h3>
                </div>
                <div class="col-sm-12 col-lg-12">
                    <h3 class="upcomingWebinarCheck" *ngIf="upcomingWebinarCheck">There are currently no upcoming webinars scheduled.<br> 
                        Please watch this space to stay updated about our upcoming webinars.
                        </h3>
                    <ul class="uWebinarSlider" myMatchHeight="PressReleasesBox"> 
                        <li class="PressReleasesBox" *ngFor="let fdata of AcademicWebinarFData">
                            <div class="uWebinarSWrp">
                                <div class="thumbImgWrp">
                                    <a href="{{fdata.contentvideourlnew}}" class="fancybox" title="{{fdata.contentheading}}"><div class="videoThumb"><img src="{{fdata.contentimage}}" alt="" /></div></a>
                                </div>
                                <div class="aheadingWrp">
                                    <h3>{{fdata.contentheading}}</h3>
                                </div>
                                <div class="adoctorNameWrp">
                                    <span *ngFor="let dname of fdata.contentsmalldecription">{{dname}}</span> 
                                </div>
                                <div class="adateWrp">
                                    <span>{{fdata.contentdate}}</span>
                                </div>
                                <div class="aBtnWrp">
                                    <a href="{{fdata.contentlink}}" class="knowmore2" target="_blank"><span>Register Now</span></a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="col-sm-12 col-lg-12">
                    <hr class="dotbg">
                </div>
            </div>
            <div class="row" style="position: relative;" id="PastWebinarsWrp">
                <div class="loder"></div>
                <div class="col-sm-12 col-lg-12 headingWrp">
                    <h3>Past Webinars</h3>
                </div>
                <div class="col-sm-12 col-lg-12 seprateSelectWrp">                    
                    <ul class="selectwrp">
                        <li>
                            <div class="specialitywrp">
                                <select class="select" id="cSpecialty" (change)="onChangeSpecialty($event)">
                                    <option value="0" selected>Select Specialty</option>
									<option *ngFor="let specMaster of specilityMasterList;" value="{{specMaster.id}}">{{specMaster.name}}</option>
                                </select>
                                <span class="select-span" id="SelectYear">Select Specialty</span>
                            </div>
                        </li>
                        <li>
                            <div class="unitwrp">
                                <select class="select" id="cLocation" (change)="onChangeLocation($event)">
                                    <option value="0" selected>Select Location </option>
                                    <ng-template ngFor let-c [ngForOf]="centerList" let-i="index">
                                        <optgroup *ngIf="c.area.length > 0" label="{{c.city}}">
                                            <option *ngFor="let branch of c.area" [value]="branch.id">{{branch.center_name_alias}}</option> 
                                        </optgroup>
                                    </ng-template>
                                </select>
                                <span class="select-span" id="SelectYear">Select Location</span>
                            </div>
                        </li>
                        <li>
                            <div class="finddwrp">
                                <div class="findbox searchDD">
                                    <input type="text" name="txtfinddoctors" (keyup)="getDoctorname($event)" [(ngModel)]="searchDoctor" placeholder="Search by Doctor’s Name"  autocomplete="off" />
                                    <ul class="searchDoctor" [ngClass]="{'active maxhight':finddoctorclass, 'inactive': !finddoctorclass}">
                                        <li *ngFor="let doctor of doctorSearchResult; let i = index">
                                            <a href="javascript:void(0);" (click)="selectDoctor(doctor.id)">{{doctor.name}}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        <li class="btnReset"><a href="javascript:window.location.reload();">Reset Filter</a></li>
                    </ul>
                </div>
                <div class="col-sm-12 col-lg-12 PastWebinarsWrp">
                    <h3 *ngIf="PastWebinarsResult">Sorry, we couldn't fetch any results.</h3>
                    <div class="table-responsive" *ngIf="!PastWebinarsResult">
                        <table cellpadding="0" cellspacing="0" border="0" id="PastWebinarsPaging" style="background: #f9f7fd;">
                            <tr *ngFor="let pData of AcademicWebinarPData">
                                <td align="center" valign="middle" class="firstColumn" style="width: 20%;border:3px solid #fff;padding:15px;">
                                    <a href="{{pData.contentlinkimageNew}}" class="fancyboximg" title="{{pData.contentheading}}"><img src="{{pData.contentvideoimageNew}}"  alt="" /></a>
                                </td>
                                <td align="left" valign="middle" style="width: 50%;border:2px solid #fff;padding:15px;">
                                    <div class="column2">
                                        <h3>{{pData.contentheading}}</h3>
                                        <div class="smallContentWrp" [innerHTML]="pData.contentsmalldecription"></div>
                                        <div class="specilitylistWrp">
                                            <strong>Doctors: </strong> <span>{{pData.doctorlistNew}}</span>
                                        </div>
                                        <div class="specilitylistWrp">
                                            <strong>Specialty: </strong> <span>{{pData.specialitylistNew}}</span>
                                        </div>
                                        <div class="specilitylistWrp">
                                            <strong>Sub Specialty: </strong> <span>{{pData.subspecialitylistNew}}</span>
                                        </div>
                                        <div class="specilitylistWrp">
                                            <strong>Center: </strong> <span>{{pData.centerlistNew}}</span>
                                        </div>
                                        <div class="specilitylistWrp">
                                            <strong>Date: </strong> <span>{{pData.contentdate}}</span>
                                        </div>
                                        <!--
                                        <div class="adateWrp">
                                            <span>Date: {{pData.contentdate}}</span>
                                        </div>
                                        -->
                                        <div class="videoWrp">
                                            <a href="{{pData.contentvideourlnew}}" class="fancybox" title="{{pData.contentheading}}"> <div class="videoThumb"><img src="{{pData.contentimageNew}}" alt="" /></div></a>
                                            <br><br><a class="copyIcon" href="javascript:void(0);"><span style="display: none;">{{pData.contentvideourlcopy}}</span>Copy Video URL to Share</a>
                                        </div>                                
                                    </div>
                                </td>
                                <td align="center" class="thurdColumn" valign="middle" style="width: 30%;border:3px solid #fff;padding:15px;">
                                    <a href="{{pData.contentvideourlnew}}" class="fancybox" title="{{pData.contentheading}}"> <div class="videoThumb"><img src="{{pData.contentimageNew}}" alt="" /></div></a>
                                    <br><br><a class="copyIcon" href="javascript:void(0);"><span style="display: none;">{{pData.contentvideourlcopy}}</span>Copy Video URL to Share</a>
                                </td>
                            </tr>
                        </table>

                    </div>
                    <div id="pagination"></div>
                </div>
            </div>
        </div>
    </section>



    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>