<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <section class="pagebcwrp">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-lg-12">
                    <ul>
                        <li>
                            <a href="javascript:void()">Home</a>
                        </li>
                        <!--<li><a href="javascript:void()">{{activePage}}</a></li>-->
                        <li>Our Centres / Specialities</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <section class="page wtwedo">
        <div class="row" *ngIf="pageData">
            <div class="col-md-5 col-xs-5 col-lg-4">
                <div class="circuleframe active">
                    <img src="{{imgBasePath}}{{pageData.image}}" class="img-responsive" alt="{{pageData.title}}" title="{{pageData.title}}" /> </div>
                <!-- End Circle -->
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7">
                <div class="pagetitle">
                    <h1 class="textgradient">{{pageData.title}}</h1>
                    <div class="underline">
                        <img src="assets/images/underline.png" alt=""> </div>
                    <h3 [innerHTML]="pageData.subtitle"></h3>
                </div>
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7 mcontentview">
                <p [innerHTML]="pageData.description"></p>
            </div>
        </div>
    </section>
    <section class="pediatricianp">
        <div class="container">
            <ng-template ngFor let-city [ngForOf]="cities">
                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 OurCentertext" *ngIf="city.centres.length > 0">
                    <h3 class="col-xs-12">{{city.city}} </h3>
                    <ul class="cityname">
                        <li *ngFor="let center of city.centres">
                            <ng-template [ngIf]="center.speciality_masters[0]">
                                <a href="/our-centre/{{center.slug}}">
										{{center.center_name_alias}}
									</a>
                                <!-- <a href="/{{center.speciality_masters[0].slug}}/centre/{{center.slug}}">
										{{center.center_name_alias}}
									</a>-->
                            </ng-template>
                        </li>
                    </ul>
                    <a href="/our-centre-details/{{city.slug}}" class="submitbtn">Know More</a>
                </div>
            </ng-template>

        </div>
    </section>


    <!-- <footer-nav-mini [links]="links"></footer-nav-mini> -->
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>