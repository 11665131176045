<footer class="copyright">
	<div class="container">
		<div class="row">
			<div class="col-md-12 col-sm-12 col-lg-6">
				<ul>
					<li><a href="/sitemap">Sitemap</a></li>
					<!-- <li><a href="/privacy-policy">PRIVACY POLICY</a></li>-->
					<!--<li><a href="/other/terms-conditions">TERMS AND CONDITIONS</a></li>-->
					<!-- <li><a href="/disclaimer">Disclaimer</a></li> -->
				</ul>
			</div>
			<div class="col-md-12 col-sm-12 col-lg-6"> © 2022 Rainbow Hospital - ALL RIGHTS RESERVED </div>
		</div>
	</div>
</footer>