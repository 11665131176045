<header class="headerwrp">
    <div class="container">
        <div class="col-md-12 col-sm-12 col-lg-12">
            <a class="logowrp" href="https://www.rainbowhospitals.in/">
                <img src="assets/images/birth-right-logo.png" alt="" class="img-responsive" /> </a>
            <div class="tfourhours">
                <h2>
                    <a href="tel:1800-2122">1800 2122</a>
                    <span>or
            <a href="javascript:void(0);" class="getaCall">Get a call</a>
          </span>
                </h2>
            </div>
        </div>

    </div>
</header>
<section class="page pageheader">
    <div class="row">
        <div class="col-md-5 col-sm-5 col-lg-4 col-xs-5">
            <div class="circuleframe active">
                <img src="https://backend.rainbowhospitals.in/{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" /> </div>
            <!-- End Circle -->
        </div>
        <div class="col-md-7 col-sm-7 col-lg-7 col-xs-7">
            <div class="ptitle blue">
                <br>
                <h2>{{title}}</h2>
                <h3><br> Thank you for submitting your enquiry.
                    <br> We will get in touch with you soon</h3>
            </div>
        </div>
    </div>
</section>
<footer class="copyright">
    <div class="container">
        <div class="col-md-12 col-sm-12 col-lg-12" style="text-align:center; font-size:16px;"> © 2017 Rainbow Hospital - ALL RIGHTS RESERVED </div>
    </div>
</footer>
