import { RemoveSpaces, ReversePipe } from './calculator/contraction.component';
import { Search } from './services/search';

/**
 * This module is the entry for your App when NOT using universal.
 *
 * Make sure to use the 3 constant APP_ imports so you don't have to keep
 * track of your root app dependencies here. Only import directly in this file if
 * there is something that is specific to the environment.
 */

import {
  ApplicationRef,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
// import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  removeNgStyles,
  createNewHosts,
  createInputTransfer,
} from '@angularclass/hmr';

import { Store, StoreModule } from '@ngrx/store';

import { APP_DECLARATIONS } from './app.declarations';
import { APP_ENTRY_COMPONENTS } from './app.entry-components';
import { APP_IMPORTS } from './app.imports';
import { APP_PROVIDERS } from './app.providers';
import { dateFormatPipe } from './home/dateFormatPipe';

import { AppComponent } from './app.component';
import { DoorgetsTruncateModule } from 'doorgets-ng-truncate';
// import { MomentModule } from 'angular2-moment';
import { MomentModule } from 'ngx-moment';
import { AppState } from './reducers';
import { MatchHeightDirective } from './services/match-height.directive';
import { TruncatePipe } from './services/truncate';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NoSanitizePipe } from './dom-sanitzer';
import { InvestorRelationsComponent } from './investor-relations/investor-relations.component';
import { InternationalPatientsComponent } from './international-patients/international-patients.component';
import { HelloDoctorComponent } from './hello-doctor/hello-doctor.component';
import { ConferenceOnSpecialPediatricsComponent } from './conference-on-special-pediatrics/conference-on-special-pediatrics.component';
import { EveningClinicAtBanjaraHillsComponent } from './evening-clinic-at-banjara-hills/evening-clinic-at-banjara-hills.component';
import { CuspSuccessComponent } from './cusp-success/cusp-success.component';
import { CuspFailureComponent } from './cusp-failure/cusp-failure.component';
@NgModule({
  declarations: [
    AppComponent,
    ...APP_DECLARATIONS,
    Search,
    dateFormatPipe,
    RemoveSpaces,
    ReversePipe,
    MatchHeightDirective,
    TruncatePipe,
    NoSanitizePipe,
    InvestorRelationsComponent,
    InternationalPatientsComponent,
    HelloDoctorComponent,
    ConferenceOnSpecialPediatricsComponent,
    EveningClinicAtBanjaraHillsComponent,
    CuspSuccessComponent,
    CuspFailureComponent,
  ],
  entryComponents: [APP_ENTRY_COMPONENTS],
  imports: [
    APP_IMPORTS,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    FormsModule,
    ReactiveFormsModule,
    DoorgetsTruncateModule,
    MomentModule,
    StoreModule.forRoot({}, {}),
    HttpClientModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot(),
    NgbModule,
    SlickCarouselModule,
    // HttpModule,
  ],
  bootstrap: [AppComponent],
  providers: [APP_PROVIDERS],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule {
  constructor(public appRef: ApplicationRef, private _store: Store<AppState>) {}

  hmrOnInit(store) {
    if (!store || !store.rootState) return;

    // restore state by dispatch a SET_ROOT_STATE action
    if (store.rootState) {
      this._store.dispatch({
        type: 'SET_ROOT_STATE',
        payload: store.rootState,
      });
    }

    if ('restoreInputValues' in store) {
      store.restoreInputValues();
    }
    this.appRef.tick();
    Object.keys(store).forEach((prop) => delete store[prop]);
  }
  hmrOnDestroy(store) {
    const cmpLocation = this.appRef.components.map(
      (cmp) => cmp.location.nativeElement
    );
    this._store.take(1).subscribe((s) => (store.rootState = s));
    store.disposeOldHosts = createNewHosts(cmpLocation);
    store.restoreInputValues = createInputTransfer();
    removeNgStyles();
  }
  hmrAfterDestroy(store) {
    store.disposeOldHosts();
    delete store.disposeOldHosts;
  }
}
