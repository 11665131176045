import {
  Inject,
  Component,
  NgZone,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  ViewChildren,
  QueryList,
  HostListener,
} from '@angular/core';
import { isPlatformBrowser, Location } from '@angular/common';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL, API_BASE_URL } from '../services/constants';
import { Meta, Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {
  ReactiveFormsModule,
  FormsModule,
  FormControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
// import { URLSearchParams } from "@angular/http";
declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
declare let initGoogleMap: any;
declare let initCentersSpecialityTabs: any;
declare let changeShowStatus: any;
const componentThis = this;
//declare let ourCenter:any;
@Component({
  selector: 'our-center-details',
  templateUrl: './our-center-details.component.html',
  styles: [
    `
      .visitext {
        white-space: pre-wrap;
      }
      .gmapwrp .sidelocdetwrp .gmheader .box {
        width: 100%;
      }
      .specdoctorslider {
        list-style: none;
        position: relative;
        color: #4d4d4d;
        font-family: 'dinrg';
        font-size: 14px;
        float: left;
        text-align: center;
        padding: 0px;
      }
      .showdiv {
        opacity: 1;
      }
      .hidediv {
        opacity: 0;
      }

      #ModelFormGetInTouch {
        display: none;
      }
      .capopup {
        display: none;
      }
      #ModelFormGetInTouch .capopup-inner {
        max-width: 600px;
        width: 90%;
        padding: 40px;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
        border-radius: 20px;
        background: #faf5ff;
      }
      #ModelFormGetInTouch .capopup-inner .launchpreloader {
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: 11;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        background: rgba(255, 255, 255, 0.6)
          url(../../assets/css/ajax-loader.gif) no-repeat center center;
      }
      #ModelFormGetInTouch h2 {
        width: 100%;
        text-transform: uppercase;
        color: #5f2160;
        font-weight: 700;
        font-family: 'dinrg';
        font-size: 30px;
        display: inline-block;
        margin: 0 0px 10px 0;
        position: relative;
        padding: 0px;
      }
      #ModelFormGetInTouch .formcasn {
        width: 100%;
        margin: 20px 0 0 0;
        padding: 0;
        position: relative;
        float: left;
      }
      #ModelFormGetInTouch .formcasn ul {
        margin: 0;
        padding: 0px;
      }
      #ModelFormGetInTouch .formcasn li {
        float: left;
        width: 100%;
        list-style: none;
        position: relative;
        margin-bottom: 10px;
      }
      #ModelFormGetInTouch .formcasn li:last-child {
        margin-bottom: 0px;
      }
      #ModelFormGetInTouch .formcasn li i {
        width: 25px;
        height: 25px;
        position: absolute;
        left: 32px;
        top: 39px;
      }
      #ModelFormGetInTouch .formcasn li .alertMsg {
        position: absolute;
        right: 12px;
        bottom: 14px;
        z-index: 1;
        color: #e51e2c;
        font-family: 'dinrg';
        font-size: 13px;
        font-weight: normal;
      }
      #ModelFormGetInTouch .formcasn li input {
        color: #545454;
        font-family: 'dinrg';
        font-size: 17px;
        width: 100%;
        float: left;
        background: #fff;
        border: 1px solid #ccc;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        padding: 10px 20px 10px 20px;
        box-sizing: border-box;
      }
      #ModelFormGetInTouch .formcasn li select {
        color: #545454;
        font-family: 'dinrg';
        font-size: 17px;
        width: 100%;
        float: left;
        background: #fff;
        border: 1px solid #ccc;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        padding: 10px 20px 10px 20px;
        box-sizing: border-box;
      }
      #ModelFormGetInTouch .formcasn li textarea {
        color: #545454;
        font-family: 'dinrg';
        font-size: 17px;
        width: 100%;
        float: left;
        background: #fff;
        border: 1px solid #ccc;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        padding: 10px 20px 10px 20px;
        box-sizing: border-box;
      }
      #ModelFormGetInTouch .formcasn li input:focus,
      #ModelFormGetInTouch .formcasn li textarea:focus,
      #ModelFormGetInTouch .formcasn li select:focus {
        outline: none;
      }
      #ModelFormGetInTouch .btnPrevca {
        color: #5f2160;
        font-family: 'dinrg';
        font-size: 14px;
        width: 100%;
        margin: 20px 10px 20px 0px;
        position: relative;
        display: inline-block;
        text-align: right;
      }
      #ModelFormGetInTouch .formcasn li .submitbtn {
        width: 100%;
        text-align: center;
        display: inline-block;
        float: left;
      }
      #ModelFormGetInTouch .formcasn li .submitbtn input {
        float: right;
        display: inline-block;
        width: auto;
        color: #fff;
        background: #3d2979;
        border: 1px solid #3d2979;
        padding: 0px 40px;
        margin: 2px 4px;
        height: 38px;
        text-transform: uppercase;
        font-family: 'dinrg';
        font-size: 18px;
        border-radius: 30px;
      }
      #ModelFormGetInTouch .formcasn li input:focus {
        outline: none;
      }
      span.error {
        position: absolute;
        right: 12px;
        bottom: 14px;
        z-index: 1;
        color: #e51e2c;
        font-family: 'dinrg';
        font-size: 13px;
        font-weight: normal;
      }
      .iconForm {
        bottom: 96px;
        position: fixed;
        right: 10px;
        z-index: 12;
        display: none !important;
      }
      .iconFormV {
        width: 30px;
        word-break: break-all;
        top: 100px;
        position: fixed;
        right: 0px;
        z-index: 12;
        display: none;
        background: #80368d;
        text-align: center;
        padding: 10px 10px;
        color: #fff;
        text-transform: uppercase;
        font-family: 'dinrg';
        font-size: 14px;
        line-height: 14px;
        text-transform: uppercase;
      }
      .iconFormV span {
        display: inline-block;
        margin-top: 6px;
      }
      @media (max-width: 767px) {
        .iconForm {
          bottom: 62px;
          position: fixed;
          right: 10px;
          z-index: 12;
        }
        .iconForm img {
          width: 50px;
        }
      }
      @media (max-width: 480px) {
        .iconForm {
          bottom: 112px;
          position: fixed;
          right: 6px;
          z-index: 12;
        }
        #ModelFormGetInTouch h2 {
          font-size: 18px;
        }
        #ModelFormGetInTouch .formcasn {
          margin: 10px 0 0 0;
        }
        #ModelFormGetInTouch .formcasn li input,
        #ModelFormGetInTouch .formcasn li select,
        #ModelFormGetInTouch .formcasn li textarea {
          font-size: 13px;
          padding: 6px 10px;
        }
        #ModelFormGetInTouch .formcasn li .submitbtn input {
          height: 30px;
          font-size: 18px;
        }
        #ModelFormGetInTouch .formcasn li .alertMsg {
          right: 5px;
          bottom: 9px;
          font-size: 11px;
        }
      }
      @media (max-width: 420px) {
        .iconForm {
          bottom: 90px;
          position: fixed;
          right: 6px;
          z-index: 12;
        }
        .iconForm img {
          width: 40px;
        }
      }
      @media (max-width: 420px) {
        .iconForm {
          bottom: 132px;
          position: fixed;
          right: 6px;
          z-index: 12;
        }
        .iconFormV {
          top: 80px;
          line-height: 12px;
        }
      }
    `,
  ],
})
export class OurCenterDetailsComponent
  implements OnDestroy, OnInit, AfterViewInit
{
  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    public metaTitle: Title,
    public meta: Meta,
    private location: Location,
    private formBuilder: FormBuilder,
    private _router: Router,
    private ngZone: NgZone
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
      require('../../assets/js/jquery.cookie.js');
    }

    //window['angularComponentRef'] = {component: this, zone: _ngZone};
  }
  //@HostListener("window:angularComponent")
  public zone: any;
  public submited: boolean = false;
  public showHide: boolean = false;
  public preloader: boolean = false;
  public doctorLen: boolean = false;
  GetInTouch: FormGroup;
  GetInTouchLms: FormGroup;
  public programName: string = '';
  public pageName: string;
  public countryModel: string = '';
  public locationModel: string = '';
  public locationList: any = [
    { name: 'Hyderabad', code: 'Hyderabad' },
    { name: 'Bengaluru', code: 'Bengaluru' },
  ];
  public interestedList: any = [
    { name: 'Fertility treatment', code: 'Fertility treatment' },
    {
      name: 'Intrauterine insemination (IUI)',
      code: 'Intrauterine insemination (IUI)',
    },
    {
      name: 'In vitro fertilization (IVF)',
      code: 'In vitro fertilization (IVF)',
    },
    { name: 'Infertility screening', code: 'Infertility screening' },
    { name: 'Male infertility', code: 'Male infertility' },
    { name: 'Preconception counselling', code: 'Preconception counselling' },
  ];
  public reload(): any {
    return this.zone.runOutsideAngular(() => {
      location.reload();
    });
  }

  public cityName: string = '';
  public CenterName: string;
  public currentSection: string;
  public specilityDoctor: any[] = [];
  public centerList: any[] = [];
  public visitor_information: string;
  public center_brief_facilities: any[] = [];
  public address: string;
  public telephone: string;
  public lat_lng: string;
  public our_speciality_features: any = [];
  public services: any = [];
  public features: any = [];
  public docspecsfeatures: any = [];
  public brief_facilities: any[] = [];
  public title: any = '';
  public subtitle: any = '';
  public description: any = '';
  public imgsrc: any = '';
  public imgBasePath = '';
  public centerImgsrc: any = '';
  public hidden = 'hidden';
  public logo = '';
  public links: any = [];
  public bread_crumb_links: any = [];
  public bread_crumb_child_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Child Care', link: '/child-care/about', class: '' },
    { label: 'Our Centres', link: '/child-care/centres', class: '' },
    { label: 'About Centre', link: '', class: '' },
  ];

  public bread_crumb_women_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Women Care', link: '/women-care/about', class: '' },
    { label: 'Our Centres', link: '/women-care/centres', class: '' },
    { label: 'About Centre', link: '', class: '' },
  ];

  public bread_crumb_fertility_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Fertility Care', link: '/fertility-care/about', class: '' },
    { label: 'Our Centres', link: '/fertility-care/centres', class: '' },
    { label: 'About Centre', link: '', class: '' },
  ];

  public bread_crumb_center_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Our Centres', link: '/our-centres', class: '' },
    { label: this.cityName, link: '', class: '' },
  ];

  public child_care_links: any = [
    { label: 'About Child Care', link: '/child-care/about', class: '' },
    { label: 'Child Safety', link: '/child-care/child-safety', class: '' },
    { label: 'Doctors', link: '/child-care/doctors', class: '' },
    { label: 'Our Specialities', link: '/child-care/specialities', class: '' },
    { label: 'Our Centres', link: '/child-care/centres', class: 'active' },
    { label: 'Vaccination', link: '/child-care/vaccination', class: '' },
    { label: 'Testimonials', link: '/child-care/testimonials', class: '' },
    { label: 'Share Feedback', link: '/child-care/feedback', class: '' },
    {
      label: 'Book An Appointment',
      link: '/child-care/book-an-appointment',
      class: '',
    },
  ];

  public women_care_links: any = [
    { label: "About Women's Care", link: '/women-care/about', class: '' },
    { label: 'Doctors', link: '/women-care/doctors', class: '' },
    { label: 'Our Specialities', link: '/women-care/specialities', class: '' },
    { label: 'Our Centres', link: '/women-care/centres', class: 'active' },
    { label: 'Testimonials', link: '/women-care/testimonials', class: '' },
    { label: 'Share Feedback', link: '/women-care/feedback', class: '' },
    {
      label: 'Book An Appointment',
      link: '/women-care/book-an-appointment',
      class: '',
    },
  ];

  public fertility_care_links: any = [
    { label: 'About Fertility Care', link: '/fertility-care/about', class: '' },
    { label: 'Doctors', link: '/fertility-care/doctors', class: '' },
    {
      label: 'Our Specialities',
      link: '/fertility-care/specialities',
      class: '',
    },
    { label: 'Our Centres', link: '/fertility-care/centres', class: 'active' },
    { label: 'Testimonials', link: '/fertility-care/testimonials', class: '' },
    { label: 'Share Feedback', link: '/fertility-care/feedback', class: '' },
    {
      label: 'Book An Appointment',
      link: '/fertility-care/book-an-appointment',
      class: '',
    },
  ];

  public virtual_tour_link: any;
  public activeRoute: any = [];
  public speciality_master: number;
  public centre_id: any;
  public activePage: any;
  public paramsId: any;
  public params: any;
  //public our_speciality_features_id:any = 0;
  //@ViewChildren('abouttabs') things: QueryList<any>;

  capitalize(s) {
    return s.toLowerCase().replace(/\b./g, function (a) {
      return a.toUpperCase();
    });
  }
  activeBreadCrum(currentSection) {
    switch (currentSection) {
      case 'child-care':
        this.bread_crumb_links = this.bread_crumb_child_links;
        this.links = this.child_care_links;
        this.logo = 'rainbow-logo';
        break;
      case 'women-care':
        this.bread_crumb_links = this.bread_crumb_women_links;
        this.links = this.women_care_links;
        this.logo = 'birth-right-logo';
        break;
      case 'fertility-care':
        this.bread_crumb_links = this.bread_crumb_fertility_links;
        this.links = this.fertility_care_links;
        this.logo = 'birth-right-logo';
        break;
      default:
        this.bread_crumb_links = [
          { label: 'Home', link: '/', class: '' },
          { label: 'Our Centres', link: '/our-centres', class: '' },
          {
            label: this.capitalize(this.cityName.replace('-', ' ')),
            link: '',
            class: '',
          },
        ];
        this.logo = 'logo';
    }
  }
  public aboutusliderconConfig: any = {
    slide: 'li',
    slidesToShow: 4,
    slidesToScroll: 4,
    fade: true,
    asNavFor: '.aboutuslider',
    infinite: true,
    adaptiveHeight: true,
    arrows: true,
    prevArrow:
      '<a class="slick-prev"><img src="assets/images/prv-arrow2.png" alt="" /></a>',
    nextArrow:
      '<a class="slick-next"><img src="assets/images/nxt-arrow2.png" alt="" /></a>',
  };
  public showDoctor: boolean;
  @ViewChildren('aboutSlider') things: QueryList<any>;
  @ViewChildren('abouticonslider') iconSliderthing: QueryList<any>;
  ngOnInit() {
    if (this.activatedRoute && this.activatedRoute.params) {
      //console.log('ROUTE', this.route);
      this.params = this.activatedRoute.params['value'];
      this.cityName = this.params.id;
      //console.log('cityName', this.params.id);
    }

    this.countryModel = '';
    this.locationModel = '';
    this.GetInTouch = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern('.*\\S.*')]],
      mobile: ['', Validators.required],
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            '[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}'
          ),
        ]),
      ],
      txtLocation: ['', [Validators.required]],
      interested_in: ['', [Validators.required]],
      message: [''],
      CityName: [''],
    });

    this.GetInTouchLms = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern('.*\\S.*')]],
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            '[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}'
          ),
        ]),
      ],
      mobile: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(/^(\+\d{1,3}[- ]?)?\d{10}$/),
        ]),
      ],
      message: [''],
      programName: [''],
    });

    this.route.params.subscribe((params) => {
      //console.log('params', params.id);
      switch (params.id) {
        case 'madhapur':
          this.pageName = params.id;
          setTimeout(() => {
            if ($.cookie('showThankYouPage') != 1) {
              this.showHide = true;
              $('#ModelFormGetInTouch').show();
            }
          }, 5000);
          break;
        case 'kondapur-op-block':
          this.pageName = params.id;
          setTimeout(() => {
            if ($.cookie('showThankYouPage') != 1) {
              this.showHide = true;
              $('#ModelFormGetInTouch').show();
            }
          }, 5000);
          break;
        case 'malviya-nagar':
          this.pageName = params.id;
          this.programName = 'Our Centres Malviya Nagar';
          this.GetInTouchLms.controls.programName.setValue(this.programName);
          setTimeout(function () {
            $('#LMSDataCaptureFormGetInTouch').show();
          }, 5000);
          break;
      }

      this.paramsId = params['id'];
      this.currentSection = params['section'];
      //console.log('route currentSection', this.currentSection);
      if (this.currentSection) {
        this.api
          .getSpecialityMasters()
          .map((res) => res.json())
          .subscribe((data) => {
            //console.log(data);
            this.activeRoute = data.speciality_masters.filter((filterdata) => {
              if (this.currentSection == 'women-care') {
                return filterdata.slug == 'women-care';
              } else {
                return filterdata.slug == this.currentSection;
              }
            });
            this.activePage = this.activeRoute[0].speciality;
            this.speciality_master = this.activeRoute[0].id;
          });
      }

      this.api.getCenterDetailsBySlug(this.paramsId).subscribe(
        (res) => {
          console.log('CENTER_DET',res.centers[0])
          this.centre_id = res.centers[0].id;
          let imgUrl = res.centers[0].circleimage
            ? res.centers[0].circleimage
            : res.centers[0].centerimage ? res.centers[0].centerimage : this.imgsrc ; 
          this.title = res.centers[0].center_name;

          this.description = res.centers[0].description;
          this.address = res.centers[0].address;
          this.telephone = res.centers[0].telephone;
          this.lat_lng = res.centers[0].lat_long;
          this.imgsrc = imgUrl;
          this.imgBasePath = IMG_BASE_URL;
          this.center_brief_facilities = res.centers[0].brief_facilities;
          this.visitor_information = res.centers[0].visitor_information;
          this.centerImgsrc = IMG_BASE_URL + res.centers[0].centerimage;
          this.CenterName = res.centers[0].center_name_alias;
          //console.log('Center image',res.centers[0]);
          $.cookie('pTitle', this.title);
          $.cookie('pBannImg', this.imgsrc);

          this.getMetaInfo(res.centers[0]);
          if (this.currentSection) {
            this.filterCenterSpecility(
              this.currentSection,
              res.centers[0].our_speciality_features
            );
          } else {
            this.our_speciality_features =
              res.centers[0].our_speciality_features;
            //console.log('our_speciality_features',this.our_speciality_features)
          }

          this.virtual_tour_link = res.centers[0].virtual_tour_link;

          let our_speciality_features_id = this.our_speciality_features[0]
            ? this.our_speciality_features[0].id
            : 0;
          let speciality_master = this.our_speciality_features[0]
            ? this.our_speciality_features[0].speciality_masters_id
            : 0;

          this.viewDoctorList(speciality_master, our_speciality_features_id);

          let user_lat_long_info = res.centers[0].lat_long.split(',');
          initGoogleMap(user_lat_long_info[0], user_lat_long_info[1]);
        },
        (err) => {
          console.log(err);
        }
      );
    });
    this.activeBreadCrum(this.currentSection);
    this.api.getHealthCareServices('center-detail').subscribe(
      (data) => {
        this.services = data.healthcare_services[0];
      },
      (error) => {
        console.log(error);
      }
    );

    this.api.getBriefFacilities(`about-${this.paramsId}-center`).subscribe(
      (data) => {
        if (data) {
          this.brief_facilities = data.brief_facilities;
        }
      },
      (error) => {
        console.log(error);
      }
    );

    this.api.getDoctorSpecialityFeaturesByName('Neonatal').subscribe(
      (data) => {
        this.docspecsfeatures = data.our_speciality_features;
      },
      (error) => {
        console.log(error);
      }
    );

    this.api.getspeciality_features().subscribe(
      (data) => {
        this.features = data.our_speciality_features;
      },
      (error) => {
        console.log(error);
      }
    );
    this._getcenter();
  }
  getMetaInfo(metadetail: any) {
    this.metaTitle.setTitle(metadetail.meta_title);
    this.meta.updateTag({ name: 'keywords', content: metadetail.meta_keyword });
    this.meta.updateTag({
      name: 'description',
      content: metadetail.meta_description,
    });
    this.meta.addTags([
      { property: 'og:title', content: metadetail.meta_title },
      { property: 'og:description', content: metadetail.meta_description },
      { property: 'og:keywords', content: metadetail.meta_keyword },
    ]);
  }
  filterCenterSpecility(currentSection: string, data: any) {
    this.our_speciality_features = data.filter((res) => {
      //console.log('filterCenterSpecility', res.page)
      return res.page == currentSection;
    });
  }
  _getcenter() {
    this.api.getCentreCities().subscribe(
      (res) => {
        this.centerList = res;
      },
      (err) => {
        console.error(err);
      }
    );
  }
  changeCenter(value) {
    //console.log('changeCenter', value);
    if (this.activePage == null) {
      document.location.href = '/our-centre/' + value;
    } else {
      document.location.href = '/' + this.currentSection + '/centre/' + value;
    }
  }
  viewDoctorList(speciality_master, speciality_feature): void {
    console.log('viewDoctorList:', speciality_master, speciality_feature);
    this.api
      .getDoctorListBySpecilityMaster(
        speciality_master,
        speciality_feature,
        this.centre_id,
        0
      )
      .subscribe((res) => {
        this.specilityDoctor = res;
        this.showDoctor = this.showDoctorFunc(this.specilityDoctor);
      });
    setTimeout(() => {
      this.doctorLen = this.leanthDoctorFunc(this.specilityDoctor);
      //console.log('doctorLen:', this.doctorLen);
    }, 2000);
  }
  showDoctorFunc(doctorList) {
    return this.specilityDoctor.length > 0 ? true : false;
  }
  leanthDoctorFunc(sDoctor) {
    return sDoctor.length > 4 ? true : false;
  }
  ngAfterViewChecked() {
    //console.log('after view checked');
    //initCentersSpecialityTabs();
  }

  hideModelForm() {
    $('#LMSDataCaptureFormGetInTouch').hide();
    $('#LMSDataCaptureFormGetInTouch .ThankYouMsgWrp').hide();
  }
  changeShowStatus() {
    this.showHide = !this.showHide;
    $('#ModelFormGetInTouch').hide();
    $('.iconForm').show();
    $('.iconFormV').show();
  }
  ShowForm() {
    this.showHide = true;
    $('.iconForm').hide();
    $('.iconFormV').hide();
    $('#ModelFormGetInTouch').show();
  }
  onSubmit() {
    this.submited = true;
    if (!this.GetInTouch.valid) {
      return;
    }
    this.preloader = true;
    var self = this;

    if (this.GetInTouch.valid) {
      $('#ModelFormGetInTouch').hide();

      let formTemp: any = <any>document.querySelector('#form');
      let formData: FormData = new FormData(formTemp);
      //console.log('Form Post Data',formData);
      let xhr: XMLHttpRequest = this.buildForm(formData);
      //console.log('XMLHttpRequest', xhr);
      xhr.onreadystatechange = () => {
        var obj = xhr;
        //console.log('obj', obj);
        if (obj.readyState == 4) {
          switch (this.pageName) {
            case 'madhapur':
              window.open(
                '/our-centre/' + this.pageName + '/thankyou?tag=AW',
                '_blank'
              );
              break;
            case 'kondapur-op-block':
              window.open(
                '/our-centre/' + this.pageName + '/thankyou?tag=AW',
                '_blank'
              );
              break;
            default:
              window.location.href =
                '/fertility-care/treatments/thankyou?tag=AW';
              break;
          }
          $('#ModelFormGetInTouch').hide();
          self.showHide = false;
          self.preloader = false;
          this.GetInTouch.reset();
        }
        if (obj.status == 200) {
          //console.log(obj.status);
        }
      };
    }
  }

  public buildForm(formData) {
    let url: any =
      API_BASE_URL + 'home/fertility_Speciality_get_in_touch_with_us';
    let xhr: XMLHttpRequest = new XMLHttpRequest();
    xhr.open('POST', url, true);

    // IE bug fixes to clear cache
    xhr.setRequestHeader('Cache-Control', 'no-cache');
    xhr.setRequestHeader('Cache-Control', 'no-store');

    xhr.send(formData);
    return xhr;
  }

  onLMSDataCaptureFormSubmit() {
    this.submited = true;
    if (!this.GetInTouchLms.valid) {
      return;
    }
    this.preloader = true;
    var self = this;

    if (this.GetInTouchLms.valid) {
      let formTemp: any = <any>document.querySelector('#GetInTouchLms');
      console.log('formTemp: ', formTemp);
      let formData: FormData = new FormData(formTemp);

      let xhr: XMLHttpRequest = this.buildLMSDataCaptureForm(formData);

      xhr.onreadystatechange = () => {
        var obj = xhr;
        if (obj.readyState == 4) {
          //window.location.href = `other/academic-programs/thankyou?tag=AW`;/
          $('#LMSDataCaptureFormGetInTouch .ThankYouMsgWrp').show();
          setTimeout(() => {
            $('#LMSDataCaptureFormGetInTouch .ThankYouMsgWrp').hide();
            $('#LMSDataCaptureFormGetInTouch').hide();
            this.GetInTouch.reset();
          }, 2000);
        }
        if (obj.status == 200) {
          //console.log(obj.status);
        }
      };
    }
  }

  public buildLMSDataCaptureForm(formData) {
    let url: any = API_BASE_URL + 'home/get/in_touch_with_us';
    let xhr: XMLHttpRequest = new XMLHttpRequest();
    xhr.open('POST', url, true);

    // IE bug fixes to clear cache
    xhr.setRequestHeader('Cache-Control', 'no-cache');
    xhr.setRequestHeader('Cache-Control', 'no-store');

    xhr.send(formData);
    return xhr;
  }

  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
      initGoogleMap();
      this.initCentersSpecialityTabs();
      this.iconSliderthing.changes.subscribe((t) => {
        //$(".aboutuslidercon").removeClass('slick-initialized').removeClass("slider").removeClass("slick-slider");

        $('.aboutuslidercon')
          .not('.slick-initialized')
          .slick(this.aboutusliderconConfig);
      });
    }
  }

  @HostListener('window:load', []) onWindowLoad() {
    $('.apapproachlist').slick({
      slide: 'li',
      dots: false,
      arrows: true,
      prevArrow:
        '<a class="slick-prev"><img src="assets/images/prv-arrow2.png" alt="" /></a>',
      nextArrow:
        '<a class="slick-next"><img src="assets/images/nxt-arrow2.png" alt="" /></a>',
      infinite: false,
      autoplay: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1100,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 680,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
    //$(".aboutuslidercon").removeClass('slick-initialized').removeClass("slider").removeClass("slick-slider");
    $('.aboutuslidercon')
      .not('.slick-initialized')
      .slick(this.aboutusliderconConfig);

    $('.aboutuslider')
      .removeClass('slick-initialized')
      .removeClass('slider')
      .removeClass('slick-slider');
    $('.aboutuslider')
      .not('.slick-initialized')
      .slick({
        slide: 'li',
        slidesToShow: this.our_speciality_features.length > 1 ? 5 : 1,
        slidesToScroll: 1,
        arrows: true,
        prevArrow:
          '<a class="slick-prev" id="SpecialtiesPrev"><img src="assets/images/prv-arrow2.png" alt="" /></a>',
        nextArrow:
          '<a class="slick-next" id="SpecialtiesNext"><img src="assets/images/nxt-arrow2.png" alt="" /></a>',
        asNavFor: '.aboutuslidercon',
        dots: false,
        centerMode: true,
        centerPadding: 20,
        focusOnSelect: true,
        infinite: true,
        responsive: [
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 680,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });

    $(document).on('click', '#SpecialtiesNext', () => {
      var thisO = $('#SpecialtiesNext')
        .parents('.aboutuslider')
        .find('li')
        .next('.slick-center');
      var mastersid = thisO.attr('data-mastersid'),
        doctorid = thisO.attr('data-doctorid');
      //console.log('next: ', mastersid, doctorid);
      this.ngZone.run(() => {
        this.viewDoctorList(mastersid, doctorid);
      });
    });
    $(document).on('click', '#SpecialtiesPrev', () => {
      var thisP = $('#SpecialtiesPrev')
        .parents('.aboutuslider')
        .find('li')
        .prev('.slick-center');
      var pMastersid = thisP.attr('data-mastersid'),
        pDoctorid = thisP.attr('data-doctorid');
      //console.log('Prev: ', pMastersid, pDoctorid);
      this.ngZone.run(() => {
        this.viewDoctorList(pMastersid, pDoctorid);
      });
    });

    setTimeout(() => {
      if (this.programName != '') {
        $('.CovidPandemicModelPopup').hide();
      }
    }, 2500);
  }

  initCentersSpecialityTabs() {}
  ngOnDestroy() {}
}

const prevFunction = () => {
  $(this)
    .parents('.aboutuslider')
    .find('.slick-center')
    .click(() => {
      console.log(
        'prev: ',
        $(this).prev('li').attr('data-doctorid'),
        $(this).next('li').attr('data-mastersid')
      );
    });
};
const nextFunction = () => {
  console.log('nextFunction click');
  $(document).ready(() => {
    $('#SpecialtiesNext')
      .parents('.aboutuslider')
      .find('.slick-center')
      .click(() => {
        console.log(
          'next: ',
          $(this).next('li').attr('data-doctorid'),
          $(this).next('li').attr('data-mastersid')
        );
      });
  });
};
