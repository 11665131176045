<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>
    <div id="topsearchwrp">
        <div class="inputbox">
            <input class="searchInput" type="text" name="txtSearch" id="txtSearch" placeholder="type here..." />
            <input type="submit" name="submit" class="ssubmitbtn" value="" />
        </div>
    </div>
    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>

    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <section class="page pageheader">
        <div class="row">
            <div>

                <div class="col-md-5 col-sm-5 col-lg-4 col-xs-5">
                    <div class="circuleframe active">
                        <img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" />
                    </div>
                    <!-- End Circle -->
                </div>
                <div class="col-md-7 col-sm-7 col-lg-7 col-xs-7">
                    <div class="ptitle">
                        <h4>{{title}}</h4>
                        <h3 [innerHTML]="subtitle"></h3>
                    </div>
                </div>
                <div class="col-md-7 col-xs-7 col-lg-8 mcontentview">
                    <p [innerHTML]="description"></p>
                    <div class="dfindwrp">
                        <ul class="hlfcube">
                            <li>
                                <div class="findsdwrp">
                                    <select class="select" name="txtLocation" #doctorSelected>
                                        <option value="Select a Doctor" selected="">Select a Doctor</option>
                                        <option  *ngFor="let doctor of doctorList" [value]="doctor.id" [ngClass]="{'selected':doctor.id == selectedDoctor}" [selected]="doctor.id == selectedDoctor">
											{{doctor.name}}
                                        </option>
                                    </select>
                                    <span id="selectlocation" class="select-span">Select Doctor</span>
                                </div>
                                <div class="gobtn">
                                    <input type="submit" name="txtSearch" value="GO" (click)="getDoctorDetail(doctorSelected.value)">
                                    
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        </section>
        
    <section class="ddetailstimet" [hidden]="doctorDetailsViewStatus">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-3 col-md-3 col-lg-2">
                    <div class="dthumbwrp">
                        <img src="{{imgBasePath}}{{selectedDoctorImg}}" alt="">
                    </div>
                </div>
                <div class="col-md-12 col-sm-9 col-md-9 col-lg-6">
                    <div class="ddetailswrp">
                        <h3>{{selectedDoctorName}}</h3>
                        <p>
                            {{selectedDoctorDesignation}}
                        </p>
                        <a href="/all/doctor{{pageType}}/{{selectedDoctorSlug}}" class="knowmore2 active"><span>Know More</span></a>
                    </div>
                </div>

                <div class="col-md-12 col-sm-12 col-lg-12">
                    <div class="sharefwrp">
                        <div class="dconfirmawrp">
                            <div class="ttheader2">
                                <h1>Share your experience with {{selectedDoctorName}}</h1>
                                <form name="CAppointment" class="quizform" [formGroup]="feedbackForm" id="CAppointment" method="post" action="">
                                    <div class="hproblem">
                                        <label>For what health problem / treatment did you visit the doctor? *</label>
                                        <ul>
                                            <li>
                                                <input type="hidden" name="doctor_id" formControlName="doctor_id">
                                                <input type="text" [ngClass]="{'has-error':!feedbackForm.controls.health_problem.valid && feedbackForm.controls.health_problem.touched}" name="health_problem" id="txtVisitorName" placeholder="" formControlName="health_problem">


                                            </li>
                                        </ul>
                                    </div>
                                    <div class="hproblem">
                                        <label>Tell us about your experience with the doctor.</label>
                                        <textarea [ngClass]="{'has-error':!feedbackForm.controls.doctor_experience.valid && feedbackForm.controls.doctor_experience.touched}" name="doctor_experience" formControlName="doctor_experience" id="txtDoctorExperience" placeholder=""></textarea>

                                    </div>



                                    <div class="hproblem">
                                        <label>What were you most happy with? *</label>
                                        <ul class="hprob" formGroupName="happyList">
                                            <li *ngFor="let happy of mostHappyList">
                                                <input type="checkbox" class="MostHappy" data-class="MostHappy" name="MostHappy" id="txtMostHappy" [formControlName]="happy.key" value="">
                                                <label>{{happy.name}}</label>

                                            </li>
                                        </ul>

                                    </div>
                                    <div class="hproblem">
                                        <label>Would you like to recommend the doctor? *</label>
                                        <ul class="mhappy">
                                            <li>

                                                <input type="radio" class="recommend" data-class="recommend" name="doctor_recommend" id="txtRecommend" formControlName="doctor_recommend" value="Yes">

                                                <label>Yes</label>
                                            </li>
                                            <li>

                                                <input type="radio" class="recommend" data-class="recommend" name="doctor_recommend" id="txtRecommend" formControlName="doctor_recommend" value="NO">

                                                <label>No</label>
                                            </li>


                                        </ul>
                                    </div>
                                    <div class="bggrad">
                                        <ul>
                                            <li>
                                                <label>Patient Name*</label>
                                                <input type="text" [ngClass]="{'has-error':!feedbackForm.controls.patient_name.valid && feedbackForm.controls.patient_name.touched}" formControlName="patient_name" name="patient_name" id="txtRecommend" placeholder="Enter Patient Name">


                                            </li>
                                            <li>
                                                <label>Patient ID</label>
                                                <!-- (keypress)="isNumber($event)" -->
                                                <!-- Removed keypress event from below field  -->
                                                <input type="text" [ngClass]="{'has-error':!feedbackForm.controls.patient_id.valid && feedbackForm.controls.patient_id.touched}" formControlName="patient_id" name="patient_id" id="txtRecommend" placeholder="Enter Patient ID">


                                            </li>
                                            <li>
                                                <label>Mobile*</label>
                                                <input type="text" [ngClass]="{'has-error':!feedbackForm.controls.mobile.valid && feedbackForm.controls.mobile.touched}" formControlName="mobile" name="mobile" id="txtRecommend" placeholder="Enter Mobile Number" (keypress)="isNumber($event)">


                                            </li>
                                        </ul>
                                    </div>
                                    <div class="confirstmwrp">
                                        <ul>
                                            <li>
                                                <div class="confirbox1">

                                                    <input type="checkbox" data-class="submit_as_anonymously" name="submit_as_anonymously" formControlName="submit_as_anonymously" id="TNCA" value="1">

                                                    <label class="tnc">Submit As Anonymously</label>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="confirbox2">

                                                    <input type="checkbox" name="TandCApprove" data-class="TandCApprove" formControlName="TandCApprove" id="TNCA" checked="checked" value="1">

                                                    <label class="tnc">
												<a data-fancybox="modal" data-src="#tncmodal" href="javascript:;">Terms &amp; conditions apply</a>
												
													
											</label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </form>
                                <div class="signupwrp">
                                    <div class="submitbtnrp syfbtn">
                                        <input type="button" (click)="submitForm()" [disabled]="!feedbackForm.valid" name="submit" class="casubmit" id="caSubmit" value="Submit">

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <!--  Start Model Popup Login -->
                    <div id="tncmodal" class="tncmodal">
                        <div class="tncmodalwrp">
                            <h2>Terms &amp; Conditions</h2>
                            <p>All the information displayed, transmitted or carried by Rainbow Children’s Medicare Private Limited and its subsidiaries (hereinafter called Rainbow Hospital) including, but not limited to, news articles, opinions, reviews,
                                text, photographs, images, illustrations, profiles, audio clips, video clips, trademarks, service marks and others (collectively the "Content,") on this Rainbow Hospital’s website is for informational purposes only.</p>
                            <p>The Content is owned by Rainbow Hospital, its affiliates or third party licensors. No one has any right to modify, publish, transmit, transfer, sell, reproduce, create derivative work from, distribute, repost, perform, display
                                or in any way commercially exploit any of the Content listed on the website.</p>
                            <p>By usage of this Rainbow Hospital’s website the users agree to abide by all copyright notices and restrictions attached to any Content accessed through it and failure to do so will be considered a case of Copyright Infringement,
                                which is termed an offence under the Copyright Act of India, 1957, and is punishable with imprisonment which may extend to one year, or with fine, or with both. Any individual who knowingly infringes or abets the infringement
                                is liable for appropriate legal action to be taken by Rainbow Hospital.</p>
                            <p>The Rainbow Hospital does not represent or endorse the accuracy, completeness or reliability of any advice, opinion, statement or other information displayed, uploaded or distributed through its website. The usage of the content
                                by any person implies that it is for his information purpose only and any self-drawn conclusions, experimenting on the basis of content provided there in shall be at user’s own risk and Rainbow Hospital is not liable or
                                responsible for the same.</p>
                            <p>The content displayed on the Rainbow Hospital’s website is primarily to enable the users to obtain a better understanding of the medical services provided by the hospital and facilities available, Doctors and Consultants expertise
                                and allied services provided by the Rainbow Hospital Group and the information thereby provided is subject to changes and modifications as and when required without any prior notice.</p>
                            <p>None of the individual developers, system operators, third-party contributors and management of Rainbow Hospital or anyone else connected to Rainbow Hospital can be held responsible for the results or consequences incurred
                                due to any self-attempt to copy, use, imitate or adopt any of the information presented on this Rainbow Hospital’s website without proper medical guidance. Rainbow Hospital reserves the right to modify/change the policy
                                without prior intimation/publication/prior information.</p>
                            <p><strong>Privacy Policy</strong></p>
                            <p>Rainbow Hospital has its own Privacy Policy and it is also required by law to maintain the privacy of the patients medical information, users information and to abide by the terms of the Privacy Policy entered in person or
                                through this Rainbow Hospital’s website. This policy shall be applicable to the information provided by users displayed, collected, or payment collected through payment gateway on Rainbow Hospital’s website for any medical
                                Advise Services/Educational/Skill Enhancement Programmes,/ Conferences/ interactions/ meetings conducted by the Rainbow Hospital.</p>
                            <p>Rainbow Hospital will not intentionally share the information of any user who has accessed the Rainbow Hospital’s website to any third party unless as required under statutory laws. We do not guarantee or assure that the electronic
                                communications received from you or contents or records may not be accessible to the third parties.</p>
                            <p>Rainbow Hospital reserves the right to modify/change the policy without prior intimation/publication/prior information.
                            </p>
                            <p><strong>Refund Policy</strong></p>
                            <p>Rainbow Hospital will facilitate a hassle-free online secured payment gateway registration facility wherein the users visiting Rainbow Hospital’s website can access the payment gateway /registration and can utilise the said
                                services and make payments. Since most of the services offered under the Rainbow Hospital are medical advisory &amp; services in nature, there is no return of the services availed through our Rainbow Hospital’s website,
                                but certain refund and cancellation policies apply which may differ or vary from time to time and also service to service, please read all refund and cancellation related policies to the service, or promotion before availing
                                and/or before registration.</p>
                            <p>Rainbow Hospital will offer refunds purely on the basis of facts concerning an individual case. There will be no refunds for cancellations post a registration, unless sufficiently provided evidence for – mistaken denominations,
                                factual errors, inaccurate payment deductions, duplicate payments or any other instance wherein the payee has faced a loss beyond reasonable control.</p>
                            <p>Refunds if any will be made to the same card or account using which the payment was initially made and it will be processed only after verification of the accountholders credentials. No cash or cheque refunds will be made under
                                any circumstances.</p>
                            <ul>
                                <li>Users cannot return the same order more than once.</li>
                                <li>Rainbow Hospital shall not be responsible in case communication is not received by the customer due to incomplete contact details given by the individual.</li>
                                <li>Any registration and/or payment made during a promotional campaign will be not considered for cancellation or refund.</li>
                                <li>Customers requesting serial or repeated refunds may be blocked to access the services provided on the website.</li>
                                <li>For claiming refund, the payee should provide valid invoice of the investigations &amp; ID documents of the customer who is seeking refund so as to be able to get the refund.</li>
                                <li>No interest will be paid on refund</li>
                            </ul>
                            <p>Rainbow Hospitals reserves the right to modify/change the policy without prior intimation/publication/prior information.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="capopup" data-popup="popup-124">
        <div class="capopup-inner">
            <h2>Thank You</h2>
            <h3>For your feedback</h3>
            <a class="capopup-close" data-popup-close="popup-124" href="#"><img src="assets/images/icon-close2.png" alt="" /></a>
        </div>
    </div>


    <find-a-doctors-nav></find-a-doctors-nav>
    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activeSubPage"></footer-nav-mini>
    </ng-template>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>