import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL } from '../services/constants';

import { Meta, Title } from '@angular/platform-browser';
declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
declare let initAboutTabs: any;
declare let initToggle: any;
declare let initInsuranceToggle: any;
//declare let initGoToSlick:any;

@Component({
  selector: 'insurance',
  templateUrl: './insurance.component.html',
  styles: [
    `
      .infotext {
        display: none;
      }
      .infotext:first {
        display: block;
      }
    `,
  ],
})
export class OtherInsuranceComponent
  implements OnDestroy, OnInit, AfterViewInit
{
  constructor(
    private api: ApiService,
    public metaTitle: Title,
    public meta: Meta
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
    }
  }

  public services: any[] = [];
  public brief_facilities: any[] = [];
  public title: any = '';
  public subtitle: any = '';
  public description: any = '';
  public imgsrc: any = '';
  public imgBasePath = '';
  public hidden = 'hidden';
  public logo = 'logo';
  public centrename: any = 0;

  public bread_crumb_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Other', link: '', class: '' },
    { label: 'Insurance', link: '', class: '' },
  ];

  public links: any = [];
  public activePage: string = 'insurance_empanelment';
  public centerList: any = [];
  public centerInsuranceList: any = '';
  public filteredCenter: any;
  // atabsConfig = {
  // 	slide: 'li',
  // 		slidesToShow: 3,
  // 		slidesToScroll: 1,
  // 		prevArrow: '<a class="slick-prev"><img src="assets/images/prv-arrow2.png" alt="" /></a>',
  // 		nextArrow: '<a class="slick-next"><img src="assets/images/nxt-arrow2.png" alt="" /></a>',
  // 		asNavFor: '.atabscontent',
  // 		dots: false,
  // 		centerMode: false,
  // 		focusOnSelect: true,
  // 		arrows: true,
  // 		infinite: false,
  // 		responsive: [
  // 			{
  // 				breakpoint: 1024, settings: {
  // 					slidesToShow: 2, slidesToScroll: 1
  // 				}
  // 			},
  // 			{
  // 				breakpoint: 768, settings: {
  // 					slidesToShow: 2, slidesToScroll: 1
  // 				}
  // 			},
  // 			{
  // 				breakpoint: 480, settings: {
  // 					slidesToShow: 1, slidesToScroll: 1
  // 				}
  // 			}
  // 		]
  // }
  // atabscontentConfig = {
  // 	slide: '.atabsdata',
  // 	slidesToShow: 1,
  // 	slidesToScroll: 1,
  // 	arrows: false,
  // 	fade: false,
  // 	asNavFor: '.atabs',
  // 	infinite: false,
  // 	adaptiveHeight: true,
  // };
  @ViewChildren('insurance') things: QueryList<any>;
  //@ViewChildren('') insuranceToggle: QueryList<any>;
  //@ViewChildren('insurance') things: QueryList<any>;
  ngOnInit() {
    this.imgBasePath = IMG_BASE_URL;
    this.api.getPageDescription('insurance').subscribe(
      (data) => {
        (this.title = data.page_descriptions[0].title),
          (this.subtitle = data.page_descriptions[0].subtitle),
          (this.description = data.page_descriptions[0].description),
          (this.imgsrc = data.page_descriptions[0].image),
          (this.imgBasePath = IMG_BASE_URL);
        if (data) {
          //console.log(data);
          this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
          this.meta.updateTag({
            name: 'keywords',
            content: data.page_descriptions[0].meta_keyword,
          });
          this.meta.updateTag({
            name: 'description',
            content: data.page_descriptions[0].meta_description,
          });
          this.meta.addTags([
            {
              property: 'og:title',
              content: data.page_descriptions[0].meta_title,
            },
            {
              property: 'og:description',
              content: data.page_descriptions[0].meta_description,
            },
            {
              property: 'og:keywords',
              content: data.page_descriptions[0].meta_keyword,
            },
          ]);
        }
      },
      (error) => {
        console.log(error);
      }
    );
    this.getCenter();
    this.api
      .getOtherSubFooterDetails()
      .map((res) => res)
      .subscribe((data) => {
        this.links = data;
      });
  }
  getCenter() {
    this.api.getCentreSpecification().subscribe(
      (res) => {
        this.centerList = res.centers;
      },
      (err) => {
        console.log('center error log:', err);
      }
    );
  }
  changeCentre() {
    //console.log(this.centrename);
    this.centerInsuranceList = [];
    this.api.getCentreInsurance(this.centrename).subscribe((res) => {
      this.centerInsuranceList = res;
    });

    this.filteredCenter = this.centerList
      .filter((res) => {
        return res.id == this.centrename;
      })
      .map((t) => {
        return t.center_name;
      });

    //initInsuranceToggle();
    //console.log(this.centerInsuranceList);
    //initAboutTabs();
  }
  showSlick(i) {
    //initGoToSlick('.atabsdata', parseInt(i));
    //$('.atabsdata').slick('slickGoTo',parseInt(i));
  }
  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
      //initAboutTabs();
    }

    this.things.changes.subscribe((t) => {
      //	alert(t);
      initAboutTabs();
      //initInsuranceToggle();
    });

    // this.insuranceToggle.changes.subscribe(a =>{
    // 	//initInsuranceToggle();
    // });
  }

  ngOnDestroy() {}
}
