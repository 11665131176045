<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <section class="pagebcwrp">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-lg-12">
                    <ul class="breadnav">
                        <li><a href="/">Home</a></li>
                        <li>Appointment Confirmetion</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>


    <!-- Start App Middle Contents Add after bread-crumb -->
    <section class="page wtwedo">
        <div class="row" *ngIf="payment_status_code">
            <div class="col-md-12 col-xs-12 col-lg-12">
                <div class="pagetitle">
                    <h1 class="textgradient">We're Sorry!</h1>
                    <h3>There was a problem completing this transaction and your appointment isn't confirmed. You may book another one. </h3>
                    <div class="underline" style="padding: 5px 0px 25px;"> <img src="assets/images/underline.png" alt="" /> </div>
                </div>
            </div>
            <div class="col-md-12 col-xs-12 col-lg-12 mcontentview caDetails">
                <div class="col-md-12 col-xs-12 col-lg-12">
                    <p class="cNote"><strong>Please Note:</strong> If the payment is debited, our team will get in touch with you.</p>
                </div>
                <div class="col-md-12 col-xs-12 col-lg-12 bookingConBtnwrp">
                    <a class="btnVisiting" href="/">Home</a>
                    <a class="btnVisiting" href="/all/doctors">Book another appointment</a>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="!payment_status_code">
            <div class="col-md-5 col-xs-5 col-lg-4">
                <div class="circuleframe active"> <img src="{{imgBasePath}}{{ConfirmAppointmentInfo.profile}}" class="img-responsive" /> </div>
                <!-- End Circle -->
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7">
                <div class="pagetitle" *ngIf="payment_sucsses_status">
                    <h1 class="textgradient">Thank You!</h1>                                        
                    <h3>We have received your payment and your appointment is confirmed.</h3>
                    <div class="underline" style="padding: 5px 0px 25px;"> <img src="assets/images/underline.png" alt="" /> </div>
                </div>
                <div class="pagetitle" *ngIf="payment_fail_status">
                    <h1 class="textgradient">We're Sorry!</h1>
                    <h3>There was a problem completing this transaction and your appointment isn't confirmed. You may book another one. </h3>
                    <div class="underline" style="padding: 5px 0px 25px;"> <img src="assets/images/underline.png" alt="" /> </div>
                </div>
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7 mcontentview caDetails" *ngIf="payment_sucsses_status">
                <div class="col-md-6 col-xs-12 col-lg-6"><span>Dr. Name:</span> {{ConfirmAppointmentInfo.name}}</div>
                <div class="col-md-6 col-xs-12 col-lg-6"><span>Preferred Time:</span> {{ConfirmAppointmentInfo.appointmentdate}}</div>
                <div class="col-md-6 col-xs-12 col-lg-6"><span>Fees:</span> {{ConfirmAppointmentInfo.doctor_fees}}</div>
                <div class="col-md-6 col-xs-12 col-lg-6"><span>Appointment ID:</span> {{ConfirmAppointmentInfo.account_number}}</div>
                <div class="col-md-6 col-xs-12 col-lg-6"><span>Transaction Number:</span> {{ConfirmAppointmentInfo.transactionReference}}</div>
                <div class="col-md-6 col-xs-12 col-lg-6"><span>Order ID:</span> {{ConfirmAppointmentInfo.orderid}}</div>
                <div class="col-md-6 col-xs-12 col-lg-6"><span>Center:</span> {{ConfirmAppointmentInfo.center_name}}</div>
                
                <div class="col-md-12 col-xs-12 col-lg-12 bookingConBtnwrp">
                    <a class="btnVisiting" href="/">Home</a>
                    <a class="btnVisiting" href="/all/doctors">Book another appointment</a>
                </div>
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7 mcontentview caDetails" *ngIf="payment_fail_status">
                <div class="col-md-12 col-xs-12 col-lg-12">
                    <p class="cNote"><strong>Please Note:</strong> If the payment is debited, our team will get in touch with you.</p>
                </div>
                <div class="col-md-12 col-xs-12 col-lg-12 bookingConBtnwrp">
                    <a class="btnVisiting" href="/">Home</a>
                    <a class="btnVisiting" href="/all/doctors">Book another appointment</a>
                </div>
            </div>
            
        </div>
    </section>
    <!-- End App Middle Contents before footer-nav-mini 
    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>-->
    <find-a-doctors-nav></find-a-doctors-nav>

    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>