<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>

    <section class="pagebcwrp">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-lg-12">
                    <ul>
                        <li><a href="javascript:void(0)">Home</a></li>
                        <li>Our Centres / Specialities</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>

    <section class="page wtwedo">
        <div class="row" *ngIf="pageData">
            <div class="col-md-5 col-xs-5 col-lg-4">
                <div class="circuleframe active"> <img src="{{imgBasePath}}{{pageData.image}}" class="img-responsive" alt="{{pageData.title}}" title="{{pageData.title}}" > </div>
                <!-- End Circle -->
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7">
                <div class="pagetitle">
                    <h1 class="textgradient">{{pageData.title}}</h1>
                    <div class="underline"> <img src="assets/images/underline.png" alt=""> </div>
                    <h3>{{pageData.subtitle}}</h3>
                </div>
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7 mcontentview">
                <p [innerHTML]="pageData.description"></p>
            </div>
        </div>
    </section>
    <section class="ourcenterbranchbg">
        <div class="container">
            <ul #centreCity class="atabs">
                <ng-template ngFor let-i="index" let-center [ngForOf]="centerData">
                    <li>
                        <a href="javascript:void(0)" (click)="activeId = center.id" data-id="data{{i}}">
                            <div class="aiconwrp"><img src="assets/images/icon-{{center.slug}}.png" alt=""></div>
                            <!-- TODO: Can find any image To map-->
                            <h2>{{center.title}}</h2>

                        </a>
                    </li>
                </ng-template>
            </ul>

            <div class="atabscontent">
                <ng-template ngFor let-center [ngForOf]="centerData">
                    <div class="atabsdata">
                        <div class="tagslink">
                            <ul>
                                <div *ngFor="let key of keys(center.speciality_features)">
                                    <li><a href="{{params.slug}}/{{center.slug}}/speciality/{{center.speciality_features[key].slug}}" tabindex="0">{{center.speciality_features[key].title}}</a></li>
                                </div>
                            </ul>
                        </div>
                        <div class="OurCenterLists">
                            <div *ngFor="let key of keys(center.centres)">

                                <ul>
                                    <li class="ourcenterbranch">
                                        <div class="BranchImg ">
                                            <img src="{{imgBasePath}}{{center.centres[key].image}}" class="img-responsive">
                                            <a class="directiontext" href="https://www.google.com/maps?saddr=My+Location&daddr={{center.centres[key].lat_long}}" target="_blank">
                                                <img src="assets/images/icon_dir.png">
                                                <p>Direction</p>
                                            </a>
                                        </div>
                                        <div class="address-wapper">
                                            <h2 class="textname">{{center.centres[key].center_name}}</h2>
                                            <p>{{center.centres[key].address}}</p>
                                            <div class="call-no clearfix">{{center.centres[key].telephone}}</div>
                                            <a href="{{center.slug}}/centre/{{center.centres[key].slug}}" class="btn-aboutcenter" tabindex="0"><span>About Centre</span></a> <a href="all/book-an-appointment" class="btn-Bookapopoi" tabindex="0"><span>Book an Appointment</span></a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </section>
    <div class="footersitenav">
        <div class="container">
            <div class="col-md-12 col-sm-12 col-lg-12">
                <div class="fncarousel2">
                    <ul>
                        <li><a href="#">About <br>Child Care</a></li>
                        <li><a href="#">Doctors</a></li>
                        <li><a href="#">Our <br>Specialties </a></li>
                        <li class="active"><a href="#">Our <br>Centres</a></li>
                        <li><a href="#">Vaccination </a></li>
                        <li><a href="#">Testimonials</a></li>
                        <li><a href="#">Share <br>Feedback</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <a class="iconForm" href="javascript:void();" (click)="ShowForm()"><img alt="" src="assets/images/Icon_form.png"></a>

    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>



<!-- Start Model Popup -->
<div class="capopup" id="ModelFormGetInTouch">
    <div class="capopup-inner">
        <div class="launchpreloader" *ngIf="preloader"></div>
        <a class="capopup-close" href="javascript:void()" (click)="changeShowStatus()"> <img alt="" src="assets/images/icon-close2.png"> </a>
        <h2>GET IN TOUCH WITH US</h2>
        <form id="form" [formGroup]="GetInTouch" (submit)="onSubmit()" novalidate>
            <ul class="formcasn">
                <li>
                    <input type="text" name="name" [ngClass]="{'has-error':!GetInTouch.controls.name.valid && GetInTouch.controls.name.touched}" placeholder="Name*" formControlName="name" required/>
                    <span class="alertMsg" *ngIf="!GetInTouch.controls.name.valid && submited">required</span>
                </li>
                <li>
                    <input type="text" name="mobile" [ngClass]="{'has-error':!GetInTouch.controls.mobile.valid && GetInTouch.controls.mobile.touched}" placeholder="Mobile*" formControlName="mobile" required/>
                    <span class="alertMsg" *ngIf="!GetInTouch.controls.mobile.valid && submited">required</span>
                </li>
                <li>
                    <input type="text" name="email" [ngClass]="{'has-error':!GetInTouch.controls.email.valid && GetInTouch.controls.email.touched}" placeholder="Email ID*" formControlName="email" required/>
                    <span class="alertMsg" *ngIf="!GetInTouch.controls.email.valid && submited">required</span>
                </li>
                <li class="singledropdown">
                    <div class="selectbox">
                        <select class="select" name="interested_in" id="txtinterested_in" [(ngModel)]="countryModel" formControlName="interested_in">
						<option value="" selected>I am interested in*</option>
						<option *ngFor="let interest of interestedList;let i= index;" [value]="interest.name">
							{{interest.name}}
						</option>
					</select>
                        <span class="select-span"></span>
                    </div>
                    <span class="alertMsg" *ngIf="!GetInTouch.controls.interested_in.valid && submited">required </span>
                </li>
                <li>
                    <input type="text" name="message" placeholder="Message" formControlName="message" />
                    <input type="hidden" name="CityName" id="CityName" formControlName="CityName" [(ngModel)]="CityName" />
                </li>
                <li>
                    <div class="submitbtn">

                        <input class="casubmit" id="txtSubmit" name="txtSubmit" type="submit" value="Submit">
                    </div>
                </li>
            </ul>
        </form>
    </div>
</div>
<!-- End Model Popup -->