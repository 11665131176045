<div>
    <div class="loder"></div>
    <sidenav></sidenav>
    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <!--<careers-join-withus></careers-join-withus>-->
    
    <section class="page pageheader">
        <div class="row">
            <div class="container">
                <div class="col-xs-12 col-sm-7 col-lg-7 abannimgwrp">
                    <div class="aboutbannimg">
                        <img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" />
                    </div>
                </div>
                <div class="col-xs-12 col-sm-5 col-lg-5">
                    <div class="ptitle">
                        <h1>{{title}}</h1>
                        <h3>{{subtitle}}</h3>
                    </div>
                </div>
                <div class="col-xs-12 mcontentview">
                    <p [innerHTML]="description"></p>
                </div>
            </div>
        </div>
    </section>
    <section class="apapproachwrp">
        <div class="row">
            <div class="container" style="display: none;">
                <div class="col-md-12 col-xs-12 col-lg-12">
                    <div class="apatitle">
                        <h2 class="gradient">WHY JOIN US?</h2>
                    </div>
                    <p class="text-center">At Rainbow Hospital, we believe that people are the DNA of our organization and each one brings a special worldview that contributes largely to high quality, focused and dedicated patient care, research and education for which we are
                        renowned. </p>
                </div>
            </div>
            <div class="joinusvideowapper joinusslide" style="display: none;">
                <div *ngFor="let slide of videoItemList" class="videoitems ">
                    <iframe width="100%" src="https://www.youtube.com/embed/3J_R5qXHsCs" frameborder="0" allowfullscreen></iframe>
                </div>
            </div>
            <div class="working-exp text-center">
                <h2 class="gradient">WORKING EXPERIENCE</h2>
                <div class="col-xs-12 PressClips">

                    <div *ngFor="let slide of workingexpsliderData" class="col-xs-12 col-sm-4 pressClipsB workingexpslider">
                        <div class="imageBox"><img src="assets/images/{{slide.image}}" /></div>
                        <div class="clearfix">
                            <h4>{{slide.title}}</h4>
                            <p>{{slide.description}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12">
                    <!--<a href="/about-us/careers/careers-join-withus" class="btn-current-openings">Current Openings</a>-->
                    <a href="https://ehubnext.myadrenalin.com/candidate/LoginPage.aspx?obj=0qKjcPeCekWVUp%2f8Wz3cmM5%2fF4SZ%2bT68" target="_blank" class="btn-current-openings">Current Openings</a>
                </div>
            </div>
        </div>
    </section>
    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>