<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>

    <section class="page wtwedo">
        <div class="row">
            <div class="col-md-5 col-xs-5 col-lg-4">
                <div class="circuleframe">
                    <img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" />
                </div>
                <!-- End Circle -->
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7">
                <div class="pagetitle">
                    <h1 class="tgradient">{{title}} </h1>
                    <div class="underline">
                        <img src="assets/images/underline2.png" alt="" />
                    </div>
                    <h3>{{subtitle}}</h3>
                </div>
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7 mcontentview">
                <p [innerHTML]="description"></p>
            </div>
        </div>

    </section>
    <section class="aboutsectin2 awardswapper">
        <div class="container">
            <div class="row nsg_text">
                <div class="col-xs-12 col-lg-12">
                    <h2>Care for the C-Section Incision</h2>
                    <p>Until complete healing takes place, you may feel pain, discomfort and tiredness. This is normal and you should be completely healed within 4-6 weeks. Please use the following steps to help the incision heal faster:</p>
                    <ul type="disc">
                        <li>For the first few weeks, avoid lifting anything heavy. You can however lift and care for your baby.</li>
                        <li>Take plenty of rest and avoid exerting yourself.</li>
                        <li>Maintain an erect posture while standing and walking. Avoid sudden movements and hold your stomach near the incision when you sneeze or cough to prevent pain.</li>
                        <li>Take pain relieving medications if you are experiencing too much pain. Your doctor will prescribe medications that are safe even when you are feeding your baby.</li>
                        <li>It is important to drink plenty of water and fluids. This helps your body to replace the fluids that were lost during delivery.</li>
                        <li>Check with your doctor or nutritionist for designing a balanced diet. This will help you recover faster, ensure good health while feeding your baby and prevent problems such as constipation.</li>
                        <li>Make sure that you empty your bladder at frequent intervals. This will reduce the risks of urinary tract infections.</li>
                    </ul>
                    <ul type="disc">
                        <li>&nbsp;You must keep checking on your C-section incision for signs of infection. If you are running a fever, and the incision is red, swollen or leaking discharge, and you are experiencing pain around the area, you must contact
                            your doctor immediately.</li>
                    </ul>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>Repeat C-Sections: How many C-Sections can a woman have?</h2>
                    <p>Generally, women cannot have more than three cesarean deliveries. For some women, the risk of surgical complications increases only slightly in the next C-section. The healing process, however, may possibly be longer if you develop
                        complications. For women who have significant internal scarring or bands of scar-like tissue that cling to the uterus, the risk of each repeat C-section becomes higher.<br> Repeat C-sections can give rise to the following complications:</p>
                    <ul type="disc">
                        <li>There are higher risks of developing problems with the placenta. The most common problems are placenta accreta (when the placenta implants too deeply and firmly to the uterine wall) or placenta previa (when the placenta partially
                            or completely covers the opening of the cervix).</li>
                        <li>Uterine incisions leave a weak spot in the wall of the uterus. This might interfere with future pregnancies.</li>
                        <li>Bladder injuries are more likely with repeat C-sections. The scar-like tissue that develops after a previous C-section, binding the bladder to the uterus increases the risk of such injuries.</li>
                        <li>Heavy bleeding may happen after a C-section and chances of excessive bleeding increase with repeat C-sections.</li>
                        <li>The risk of a hysterectomy or uterus removal to control excess bleeding that could be life-threatening is higher with repeat C-sections.</li>
                    </ul>
                    <p>Women who have had a C-section have slightly higher long-term risks, which increase with each repeat cesarean delivery. These include:</p>
                    <ul type="disc">
                        <li>Rupture of the incision scar during a later&nbsp;pregnancy&nbsp;or labor.</li>
                        <li>Placenta previa, the growth of the placenta low in the uterus, blocking the&nbsp;cervix.</li>
                        <li>Placenta accreta,&nbsp;where the placenta grows deeper into the uterine wall than normal, leading to severe bleeding after childbirth and may require a&nbsp;hysterectomy.</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <ng-template [ngIf]="ins_links.length > 0">
        <footer-nav-mini [links]="ins_links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>