<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>


    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <section class="page wtwedo">
        <div class="row">
            <div class="col-md-5 col-xs-5 col-lg-4">
                <div class="circuleframe"> <img class="img-responsive" src="assets/images/our-center-thumb.png" alt="{{title}}" title="{{title}}" /> </div>
                <!-- End Circle -->
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7">
                <div class="pagetitle">
                    <h1 class="textgradient">Our <span>Centres</span></h1>
                    <div class="underline"> <img src="assets/images/underline.png" alt="" /> </div>
                    <h3>Branches of Rainbow Hospitals</h3>
                </div>
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7 mcontentview">
                <p>Below you will find information about our different hospital locations.</p>
                <div class="dfindwrp">
                    <ul class="hlfcube">
                        <li>
                            <div class="locationwrp">
                                <select class="select" name="txtLocation">
                  <option value="Select location" selected>Select location </option>
                  <option value="DL">Delhi</option>
                  <option value="MU">Mumbai</option>
                </select>
                                <span id="selectlocation" class="select-span"></span> </div>
                            <div class="searchbtn">
                                <input type="submit" name="txtSearch" value="Search" />
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <section>
        <div class="container ourcenterlist-aro"> <a class="slick-prev" id="slickPrev">&nbsp;</a>
            <div class="ourcenterlist slider">
                <div class="sitem" data-cityname="Banjara Hills">
                    <div class="address-img-wapper">
                        <a class="fancybox-effects-a icon-360" href="assets/images/imagemap.jpg" data-fancybox-group="gallery" title="Rainbow Hospital"><img src="assets/images/icon-360.png" width="49" height="49"></a> <img src="assets/images/address-img.png" width="546"></div>
                    <div class="address-wapper">
                        <h2 class="textname">Rainbow Children's Hospital & <br> BirthRight by Rainbow, Banjara Hills</h2>
                        <div class="underline"><img src="assets/images/underline.png" width="77" height="3"></div>
                        <p> 22, Road No - 4, (old road no-10), Karvy Lanes,<br> Banjara Hills, Hyderabad - 500 034. Telangana, INDIA.</p>
                        <div class="call-no clearfix">+91-40-2331 9191, +91-40-4466 5555.</div>
                        <a href="about-center.html" class="btn-aboutcenter"><span>About Centre</span></a> <a href="book-an-appointment.html" class="btn-Bookapopoi"><span>Book an Appointment</span></a> <a href="our-center-map.html" class="direction ">Direction</a>                        </div>
                </div>
                <div class="sitem" data-cityname="Secunderabad">
                    <div class="address-img-wapper ">
                        <a class="fancybox-effects-a icon-360" href="assets/images/imagemap.jpg" data-fancybox-group="gallery" title="Rainbow Hospital"><img src="assets/images/icon-360.png" width="49" height="49"></a> <img src="assets/images/address-img.png" width="546"></div>
                    <div class="address-wapper">
                        <h2 class="textname">Rainbow Children's Hospital & <br>BirthRight by Rainbow, Secunderabad</h2>
                        <div class="underline"><img src="assets/images/underline.png" width="77" height="3"></div>
                        <p> 22, Road No - 4, (old road no-10), Karvy Lanes,<br> Banjara Hills, Hyderabad - 500 034. Telangana, INDIA.</p>
                        <div class="call-no clearfix">+91-40-2331 9191, +91-40-4466 5555.</div>
                        <a href="about-center.html" class="btn-aboutcenter"><span>About Centre</span></a> <a href="book-an-appointment.html" class="btn-Bookapopoi"><span>Book an Appointment</span></a> <a href="our-center-map.html" class="direction ">Direction</a>                        </div>
                </div>
                <div class="sitem" data-cityname="Kondapur">
                    <div class="address-img-wapper ">
                        <a class="fancybox-effects-a icon-360" href="assets/images/imagemap.jpg" data-fancybox-group="gallery" title="Rainbow Hospital"><img src="assets/images/icon-360.png" width="49" height="49"></a> <img src="assets/images/address-img.png" width="546"></div>
                    <div class="address-wapper">
                        <h2 class="textname">Rainbow Children's Hospital & <br>BirthRight by Rainbow, Kondapur</h2>
                        <div class="underline"><img src="assets/images/underline.png" width="77" height="3"></div>
                        <p> 22, Road No - 4, (old road no-10), Karvy Lanes,<br> Banjara Hills, Hyderabad - 500 034. Telangana, INDIA.</p>
                        <div class="call-no clearfix">+91-40-2331 9191, +91-40-4466 5555.</div>
                        <a href="about-center.html" class="btn-aboutcenter"><span>About Centre</span></a> <a href="book-an-appointment.html" class="btn-Bookapopoi"><span>Book an Appointment</span></a> <a href="our-center-map.html" class="direction ">Direction</a>                        </div>
                </div>
            </div>
            <a class="slick-next" id="slickNext">&nbsp;</a> </div>
    </section>
    <footer-nav-mini [links]="links"></footer-nav-mini>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>