import {Inject, Component, OnDestroy, OnInit, AfterViewInit, PLATFORM_ID, ViewChildren, QueryList } from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {ApiService} from "../services/api.service";
import {IMG_BASE_URL} from "../services/constants";
declare let $:any;
declare let initHome:any;
declare let initAllPage:any;

declare let initCustomSelect:any;

@Component({
  selector: 'doctors',
  templateUrl: './doctors.component.html'
})

export class WomenCareDoctorsComponent implements OnDestroy, OnInit, AfterViewInit {


  constructor(private api:ApiService, ) {
    if(this.api.isBrowser){
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
    }
  }

  public speciality:any[] = [];
  public doctors:any[] = [];
  public title:any = "";
  public subtitle:any = "";
  public description:any = "";
  public imgsrc:any = "";
  public imgBasePath = "";
  public hidden = "hidden";
  public logo="birth-right-logo";
  public bread_crumb_links:any = [{"label":"Home", "link": "/", "class": ""},
                                 {"label":"Women Care", "link": "/women-care-about", "class": ""},
                                 {"label":"Doctors", "link": "", "class": ""}];

  public links:any = [{"label":"About Women's Care", "link": "/women-care-about", "class": ""},
                      {"label":"Doctors", "link": "/women-care-doctors", "class": "active"},
                      {"label":"Our Specialities", "link": "/women-care-specialities", "class": ""},
                      {"label":"Our Centres", "link": "/women-care-centres", "class": ""},
                      {"label":"Testimonials", "link": "/women-care-testimonials", "class": ""},
                      {"label":"Share Feedback", "link": "/women-care-feedback", "class": ""},
                      {"label":"Book An Appointment", "link": "/women-care-appointment", "class": ""}]

  @ViewChildren('doctors') things: QueryList<any>;

  ngOnInit() {
    this.api.getPageDescription('women-care-doctors')
      .subscribe(
        data =>{
          this.title = data.page_descriptions[0].title,
          this.subtitle = data.page_descriptions[0].subtitle,
          this.description = data.page_descriptions[0].description,
          this.imgsrc = data.page_descriptions[0].image,
          this.imgBasePath = IMG_BASE_URL
        },
        error => {
          console.log(error);
        }
      )


        this.api.getDoctorSpecialityMasterByName("Women Health").subscribe(
          data => {
            this.doctors = data.speciality_masters[0].doctors
          },
          error => {
            console.log(error)
          }
        )

     
  }

  ngAfterViewInit() {

    if ( this.api.isBrowser && window['jQuery'] ) {
      initHome();
      initAllPage();
    }

    this.things.changes.subscribe(t => {
      // this.ngForRendred();
      initCustomSelect();
    })
  }

  ngOnDestroy() {}

}
