import { Pipe,PipeTransform} from '@angular/core';

@Pipe({
    name: 'search'
})
export class Search implements PipeTransform {

	transform(doctorList: any, searchText: any): any {
		if(searchText == null) return doctorList;

		return doctorList.filter(function(doctorList){
			//console.log(searchText.toLowerCase());
			return doctorList.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
		})
	}
}