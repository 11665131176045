import {Inject, Component, OnDestroy, OnInit, AfterViewInit, PLATFORM_ID, ViewChildren, QueryList } from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {ApiService} from "../services/api.service";
import {IMG_BASE_URL} from "../services/constants";

import { Meta, Title } from '@angular/platform-browser';
declare let $:any;
declare let initHome:any;
declare let initAllPage:any;

declare let initAboutTabs:any;

@Component({
  selector: 'child-safety',
  templateUrl: './child-safety.component.html',
  styles:[`
  .csrlist li{
	  width:100%;
  } 
  .careservices{
	  padding-top:0px !important;
  }
  `]
})

export class ChildSafetyComponent implements OnDestroy, OnInit, AfterViewInit {


  constructor(private api:ApiService,  public metaTitle: Title, public meta: Meta ) {
    if(this.api.isBrowser){
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
    }
  }

  public services:any[] = [];
  public brief_facilities:any[] = [];
  public title:any = "";
  public subtitle:any = "";
  public description:any = "";
  public imgsrc:any = "";
  public imgBasePath = "";
  public hidden = "hidden";
  public logo = "rainbow-logo";
  public bread_crumb_links:any = [{"label":"Home", "link": "/home", "class": ""},
                                 {"label":"Child Care", "link": "/child-care/about", "class": ""},
                                 {"label":"Child Safety", "link": "", "class": ""}];

                                 public links: any = [
                                  { "label": "About Child Care", "link": "/child-care/about", "class": "","slug":"about" },
                                  { "label": "Child Safety", "link": "/child-care/child-safety", "class": "","slug":"child-safety"  },
                                { "label": "Doctors", "link": "/child-care/doctors", "class": "","slug":"doctors"  },
                                { "label": "Our Specialities", "link": "/child-care/specialities", "class": "","slug":"specialities"  },
                                { "label": "Our Centres", "link": "/child-care/centres", "class": "active","slug":"centres"  },
                                { "label": "Vaccination", "link": "/child-care/vaccination", "class": "","slug":"vaccination"  },
                                { "label": "Testimonials", "link": "/child-care/testimonials", "class": "","slug":"testimonials"  },
                                { "label": "Share Feedback", "link": "/child-care/feedback", "class": "","slug":"feedback"  },
                                { "label": "Book An Appointment", "link": "/child-care/book-an-appointment", "class": "","slug":"book-an-appointment"  }]
                                public activePage:string = 'child-safety';

  @ViewChildren('abouttabs') things: QueryList<any>;

  ngOnInit() {
    this.api.getPageDescription('children-safety')
      .subscribe(
        data =>{
          this.title = data.page_descriptions[0].title;
          this.subtitle = data.page_descriptions[0].subtitle;
          this.description = data.page_descriptions[0].description;
          this.imgsrc = data.page_descriptions[0].image;
          this.imgBasePath = IMG_BASE_URL;
          if(data){
            //console.log(data);
            this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
            this.meta.updateTag({name:'keywords',content: data.page_descriptions[0].meta_keyword});
            this.meta.updateTag({name:'description',content: data.page_descriptions[0].meta_description});
            this.meta.addTags([
              { property: 'og:title', content:  data.page_descriptions[0].meta_title},
              { property: 'og:description', content: data.page_descriptions[0].meta_description },
              { property: 'og:keywords', content: data.page_descriptions[0].meta_keyword }
            ]);
            }
        },
        error => {
          console.log(error);
        }
      )

      this.api.getHealthCareServices('children-safety')
        .subscribe(
          data =>{
            this.services = data.healthcare_services
          },
          error => {
            console.log(error);
          }
        )

        this.api.getBriefFacilities('children-safety')
          .subscribe(
            data =>{
              this.brief_facilities = data.brief_facilities
            },
            error => {
              console.log(error);
            }
          )
  }

  ngAfterViewInit() {

    if ( this.api.isBrowser && window['jQuery'] ) {
      initHome();
      initAllPage();
     // initAboutTabs();
    }

    this.things.changes.subscribe(t => {
      // this.ngForRendred();
      initAboutTabs();
    })
  }

  ngOnDestroy() {}

}
