import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  ViewChildren,
  QueryList,
  HostListener,
} from '@angular/core';
import { isPlatformBrowser, Location } from '@angular/common';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL, API_BASE_URL } from '../services/constants';
import { Meta, Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {
  ReactiveFormsModule,
  FormsModule,
  FormControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
// import { URLSearchParams } from "@angular/http";
import { filter } from 'rxjs/operator/filter';
declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
declare let initGoogleMap: any;
declare let initCentersSpecialityTabs: any;
declare let changeShowStatus: any;
//declare let ourCenter:any;
@Component({
  selector: 'our-center-details',
  templateUrl: './our-center-detaillist.component.html',
  styles: [
    `
      .visitext {
        white-space: pre-wrap;
      }
      .gmapwrp .sidelocdetwrp .gmheader .box {
        width: 100%;
      }
      .specdoctorslider {
        list-style: none;
        position: relative;
        color: #4d4d4d;
        font-family: 'dinrg';
        font-size: 14px;
        float: left;
        text-align: center;
        padding: 0px;
      }
      .showdiv {
        opacity: 1;
      }
      .hidediv {
        opacity: 0;
      }

      #ModelFormGetInTouch {
        display: none;
      }
      .capopup {
        display: none;
      }
      #ModelFormGetInTouch .capopup-inner {
        max-width: 600px;
        width: 90%;
        padding: 40px;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
        border-radius: 20px;
        background: #faf5ff;
      }
      #ModelFormGetInTouch .capopup-inner .launchpreloader {
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: 11;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        background: rgba(255, 255, 255, 0.6)
          url(../../assets/css/ajax-loader.gif) no-repeat center center;
      }
      #ModelFormGetInTouch h2 {
        width: 100%;
        text-transform: uppercase;
        color: #5f2160;
        font-weight: 700;
        font-family: 'dinrg';
        font-size: 30px;
        display: inline-block;
        margin: 0 0px 10px 0;
        position: relative;
        padding: 0px;
      }
      #ModelFormGetInTouch .formcasn {
        width: 100%;
        margin: 20px 0 0 0;
        padding: 0;
        position: relative;
        float: left;
      }
      #ModelFormGetInTouch .formcasn ul {
        margin: 0;
        padding: 0px;
      }
      #ModelFormGetInTouch .formcasn li {
        float: left;
        width: 100%;
        list-style: none;
        position: relative;
        margin-bottom: 10px;
      }
      #ModelFormGetInTouch .formcasn li:last-child {
        margin-bottom: 0px;
      }
      #ModelFormGetInTouch .formcasn li i {
        width: 25px;
        height: 25px;
        position: absolute;
        left: 32px;
        top: 39px;
      }
      #ModelFormGetInTouch .formcasn li .alertMsg {
        position: absolute;
        right: 12px;
        bottom: 14px;
        z-index: 1;
        color: #e51e2c;
        font-family: 'dinrg';
        font-size: 13px;
        font-weight: normal;
      }
      #ModelFormGetInTouch .formcasn li input {
        color: #545454;
        font-family: 'dinrg';
        font-size: 17px;
        width: 100%;
        float: left;
        background: #fff;
        border: 1px solid #ccc;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        padding: 10px 20px 10px 20px;
        box-sizing: border-box;
      }
      #ModelFormGetInTouch .formcasn li select {
        color: #545454;
        font-family: 'dinrg';
        font-size: 17px;
        width: 100%;
        float: left;
        background: #fff;
        border: 1px solid #ccc;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        padding: 10px 20px 10px 20px;
        box-sizing: border-box;
      }
      #ModelFormGetInTouch .formcasn li textarea {
        color: #545454;
        font-family: 'dinrg';
        font-size: 17px;
        width: 100%;
        float: left;
        background: #fff;
        border: 1px solid #ccc;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        padding: 10px 20px 10px 20px;
        box-sizing: border-box;
      }
      #ModelFormGetInTouch .formcasn li input:focus,
      #ModelFormGetInTouch .formcasn li textarea:focus,
      #ModelFormGetInTouch .formcasn li select:focus {
        outline: none;
      }
      #ModelFormGetInTouch .btnPrevca {
        color: #5f2160;
        font-family: 'dinrg';
        font-size: 14px;
        width: 100%;
        margin: 20px 10px 20px 0px;
        position: relative;
        display: inline-block;
        text-align: right;
      }
      #ModelFormGetInTouch .formcasn li .submitbtn {
        width: 100%;
        text-align: center;
        display: inline-block;
        float: left;
      }
      #ModelFormGetInTouch .formcasn li .submitbtn input {
        float: right;
        display: inline-block;
        width: auto;
        color: #fff;
        background: #3d2979;
        border: 1px solid #3d2979;
        padding: 0px 40px;
        margin: 2px 4px;
        height: 38px;
        text-transform: uppercase;
        font-family: 'dinrg';
        font-size: 18px;
        border-radius: 30px;
      }
      #ModelFormGetInTouch .formcasn li input:focus {
        outline: none;
      }
      span.error {
        position: absolute;
        right: 12px;
        bottom: 14px;
        z-index: 1;
        color: #e51e2c;
        font-family: 'dinrg';
        font-size: 13px;
        font-weight: normal;
      }
      .iconForm {
        bottom: 96px;
        position: fixed;
        right: 10px;
        z-index: 12;
        display: none !important;
      }
      .iconFormV {
        width: 30px;
        word-break: break-all;
        top: 100px;
        position: fixed;
        right: 0px;
        z-index: 12;
        display: none;
        background: #80368d;
        text-align: center;
        padding: 10px 10px;
        color: #fff;
        text-transform: uppercase;
        font-family: 'dinrg';
        font-size: 14px;
        line-height: 14px;
        text-transform: uppercase;
      }
      .iconFormV span {
        display: inline-block;
        margin-top: 6px;
      }
      @media (max-width: 767px) {
        .iconForm {
          bottom: 62px;
          position: fixed;
          right: 10px;
          z-index: 12;
        }
        .iconForm img {
          width: 50px;
        }
      }
      @media (max-width: 480px) {
        .iconForm {
          bottom: 112px;
          position: fixed;
          right: 6px;
          z-index: 12;
        }
        #ModelFormGetInTouch h2 {
          font-size: 18px;
        }
        #ModelFormGetInTouch .formcasn {
          margin: 10px 0 0 0;
        }
        #ModelFormGetInTouch .formcasn li input,
        #ModelFormGetInTouch .formcasn li select,
        #ModelFormGetInTouch .formcasn li textarea {
          font-size: 13px;
          padding: 6px 10px;
        }
        #ModelFormGetInTouch .formcasn li .submitbtn input {
          height: 30px;
          font-size: 18px;
        }
        #ModelFormGetInTouch .formcasn li .alertMsg {
          right: 5px;
          bottom: 9px;
          font-size: 11px;
        }
      }
      @media (max-width: 420px) {
        .iconForm {
          bottom: 90px;
          position: fixed;
          right: 6px;
          z-index: 12;
        }
        .iconForm img {
          width: 40px;
        }
      }
      @media (max-width: 420px) {
        .iconForm {
          bottom: 132px;
          position: fixed;
          right: 6px;
          z-index: 12;
        }
        .iconFormV {
          top: 80px;
          line-height: 12px;
        }
      }
    `,
  ],
})
export class OurCenterDetaillistComponent
  implements OnDestroy, OnInit, AfterViewInit
{
  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    public metaTitle: Title,
    public meta: Meta,
    private location: Location,
    private formBuilder: FormBuilder,
    private _router: Router
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/fancybox/jquery.fancybox.js');
      require('../../assets/js/slick.min.js');
      require('../../assets/js/jquery.cookie.js');
    }
  }

  public zone: any;
  public submited: boolean = false;
  public showHide: boolean = false;
  public preloader: boolean = false;
  GetInTouch: FormGroup;
  GetInTouchLms: FormGroup;
  public pageName: string;
  public countryModel: string = '';
  public locationModel: string = '';
  public locationList: any = [
    { name: 'Hyderabad', code: 'Hyderabad' },
    { name: 'Bengaluru', code: 'Bengaluru' },
  ];
  public interestedList: any = [];

  public centrelocation: any = '';
  public specialityModel: any = '';
  public CentreDetails: any;
  public GetInTouchOnPage: FormGroup;
  public specialityList: any = [
    { name: 'Paediatric', code: 'Paediatric' },
    { name: 'Obstetrics and Gynaecology', code: 'Obstetrics and Gynaecology' },
    { name: 'Fertility', code: 'Fertility' },
    { name: 'Others', code: 'Others' },
  ];

  public reload(): any {
    return this.zone.runOutsideAngular(() => {
      location.reload();
    });
  }

  public programName: string = '';
  public cityName: string = '';
  public CenterName: string;
  public CenterID = '';
  public currentSection: string;
  public specilityDoctor: any = [];
  public centerList: any[] = [];
  public visitor_information: string;
  public center_brief_facilities: any[] = [];
  public PackageListing: any = [];
  public MainServiceList: any = [];
  public address: string;
  public telephone: string;
  public lat_lng: string;
  public our_speciality_features: any = [];
  public services: any = [];
  public features: any = [];
  public docspecsfeatures: any = [];
  public brief_facilities: any[] = [];
  public title: any = '';
  public specialAnnouncements: any[] = [];
  public firstTimePaging: boolean = false;
  public subtitle: any = '';
  public description: any = '';
  public imgsrc: any = '';
  public imgBasePath = '';
  public centerImgsrc: any = '';
  public hidden = 'hidden';
  public logo = '';
  public links: any = [];
  public bread_crumb_links: any = [];
  public bread_crumb_child_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Child Care', link: '/child-care/about', class: '' },
    { label: 'Our Centres', link: '/child-care/centres', class: '' },
    { label: 'About Centre', link: '', class: '' },
  ];

  public bread_crumb_women_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Women Care', link: '/women-care/about', class: '' },
    { label: 'Our Centres', link: '/women-care/centres', class: '' },
    { label: 'About Centre', link: '', class: '' },
  ];

  public bread_crumb_fertility_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Fertility Care', link: '/fertility-care/about', class: '' },
    { label: 'Our Centres', link: '/fertility-care/centres', class: '' },
    { label: 'About Centre', link: '', class: '' },
  ];

  public bread_crumb_center_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Our Centres', link: '/our-centres', class: '' },
    { label: this.cityName, link: '', class: '' },
  ];

  public child_care_links: any = [
    { label: 'About Child Care', link: '/child-care/about', class: '' },
    { label: 'Child Safety', link: '/child-care/child-safety', class: '' },
    { label: 'Doctors', link: '/child-care/doctors', class: '' },
    { label: 'Our Specialities', link: '/child-care/specialities', class: '' },
    { label: 'Our Centres', link: '/child-care/centres', class: 'active' },
    { label: 'Vaccination', link: '/child-care/vaccination', class: '' },
    { label: 'Testimonials', link: '/child-care/testimonials', class: '' },
    { label: 'Share Feedback', link: '/child-care/feedback', class: '' },
    {
      label: 'Book An Appointment',
      link: '/child-care/book-an-appointment',
      class: '',
    },
  ];

  public women_care_links: any = [
    { label: "About Women's Care", link: '/women-care/about', class: '' },
    { label: 'Doctors', link: '/women-care/doctors', class: '' },
    { label: 'Our Specialities', link: '/women-care/specialities', class: '' },
    { label: 'Our Centres', link: '/women-care/centres', class: 'active' },
    { label: 'Testimonials', link: '/women-care/testimonials', class: '' },
    { label: 'Share Feedback', link: '/women-care/feedback', class: '' },
    {
      label: 'Book An Appointment',
      link: '/women-care/book-an-appointment',
      class: '',
    },
  ];

  public fertility_care_links: any = [
    { label: 'About Fertility Care', link: '/fertility-care/about', class: '' },
    { label: 'Doctors', link: '/fertility-care/doctors', class: '' },
    {
      label: 'Our Specialities',
      link: '/fertility-care/specialities',
      class: '',
    },
    { label: 'Our Centres', link: '/fertility-care/centres', class: 'active' },
    { label: 'Testimonials', link: '/fertility-care/testimonials', class: '' },
    { label: 'Share Feedback', link: '/fertility-care/feedback', class: '' },
    {
      label: 'Book An Appointment',
      link: '/fertility-care/book-an-appointment',
      class: '',
    },
  ];

  public virtual_tour_link: any;
  public activeRoute: any = [];
  public speciality_master: number;
  public centre_id: any;
  public activePage: any;
  public paramsId: any;
  public params: any;
  public MapLink: any;
  //public our_speciality_features_id:any = 0;
  //@ViewChildren('abouttabs') things: QueryList<any>;

  capitalize(s) {
    return s.toLowerCase().replace(/\b./g, (a) => {
      return a.toUpperCase();
    });
  }
  activeBreadCrum(currentSection) {
    switch (currentSection) {
      case 'child-care':
        this.bread_crumb_links = this.bread_crumb_child_links;
        this.links = this.child_care_links;
        this.logo = 'rainbow-logo';
        break;
      case 'women-care':
        this.bread_crumb_links = this.bread_crumb_women_links;
        this.links = this.women_care_links;
        this.logo = 'birth-right-logo';
        break;
      case 'fertility-care':
        this.bread_crumb_links = this.bread_crumb_fertility_links;
        this.links = this.fertility_care_links;
        this.logo = 'birth-right-logo';
        break;
      default:
        this.bread_crumb_links = [
          { label: 'Home', link: '/', class: '' },
          { label: 'Our Centres', link: '/our-centres', class: '' },
          {
            label: this.capitalize(this.cityName.replace('-', ' ')),
            link: '/our-centres/' + this.cityName,
            class: '',
          },
          { label: this.PackageListing[0].packageheading, link: '', class: '' },
        ];
        this.logo = 'logo';
    }
  }
  public aboutusliderconConfig: any = {
    slide: 'li',
    slidesToShow: 4,
    slidesToScroll: 4,
    fade: true,
    asNavFor: '.aboutuslider',
    infinite: true,
    adaptiveHeight: true,
    arrows: true,
    prevArrow:
      '<a class="slick-prev"><img src="assets/images/prv-arrow2.png" alt="" /></a>',
    nextArrow:
      '<a class="slick-next"><img src="assets/images/nxt-arrow2.png" alt="" /></a>',
  };
  public showDoctor: boolean;
  @ViewChildren('aboutSlider') things: QueryList<any>;
  @ViewChildren('abouticonslider') iconSliderthing: QueryList<any>;
  ngOnInit() {
    if (this.activatedRoute && this.activatedRoute.params) {
      //console.log('ROUTE', this.route);
      this.params = this.activatedRoute.params['value'];
      this.cityName = this.params.id;

      this.params['name'];

      //console.log('cityName', this.params.id);
    }

    this.countryModel = '';
    this.locationModel = '';
    this.GetInTouch = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern('.*\\S.*')]],
      mobile: ['', Validators.required],
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            '[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}'
          ),
        ]),
      ],
      txtLocation: ['', [Validators.required]],
      interested_in: ['', [Validators.required]],
      message: [''],
      CityName: [''],
    });

    this.GetInTouchLms = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern('.*\\S.*')]],
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            '[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}'
          ),
        ]),
      ],
      mobile: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(/^(\+\d{1,3}[- ]?)?\d{10}$/),
        ]),
      ],
      message: [''],
      programName: [''],
    });

    this.GetInTouchOnPage = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern('.*\\S.*')]],
      mobile: [
        '',
        Validators.compose([Validators.required, Validators.minLength(10)]),
      ],
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            '[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}'
          ),
        ]),
      ],
      speciality: [''],
      intrestedin: [''],
      location: [''],
      message: [''],
      authorization: [false, [Validators.required]],
    });
    this.GetInTouchOnPage.controls.location.setValue(
      this.capitalize(this.cityName.replace('-', ' '))
    );

    this.route.params.subscribe((params) => {
      //console.log('params', params.id);
      switch (params.id) {
        case 'madhapur':
          this.pageName = params.id;
          setTimeout(() => {
            if ($.cookie('showThankYouPage') != 1) {
              this.showHide = true;
              $('#ModelFormGetInTouch').show();
            }
          }, 5000);
          break;
        case 'kondapur-op-block':
          this.pageName = params.id;
          setTimeout(() => {
            if ($.cookie('showThankYouPage') != 1) {
              this.showHide = true;
              $('#ModelFormGetInTouch').show();
            }
          }, 5000);
          break;
        case 'malviya-nagar':
          this.pageName = params.id;
          this.programName = 'Our Centres Malviya Nagar';
          this.GetInTouchLms.controls.programName.setValue(this.programName);
          setTimeout(() => {
            $('#LMSDataCaptureFormGetInTouch').show();
          }, 5000);
          break;
      }

      this.paramsId = params['id'];
      this.currentSection = params['section'];
      //console.log('route currentSection', this.currentSection);
      if (this.currentSection) {
        this.api
          .getSpecialityMasters()
          .map((res) => res.json())
          .subscribe((data) => {
            //console.log(data);
            this.activeRoute = data.speciality_masters.filter((filterdata) => {
              if (this.currentSection == 'women-care') {
                return filterdata.slug == 'women-care';
              } else {
                return filterdata.slug == this.currentSection;
              }
            });
            this.activePage = this.activeRoute[0].speciality;
            this.speciality_master = this.activeRoute[0].id;
          });
      }

      //this.api.getCenterDetailsBySlug(this.paramsId).subscribe((res)=>{
      this.api.getCentreSpecification().subscribe((res) => {
        res.centers.forEach((element) => {
          if (element.slug == this.paramsId) {
            this.CenterID = element.id;
            //console.log('CenterID', this.CenterID);
            this.centerDetails(this.CenterID);
            let dataID = this.params['name'].split('-');
            //console.log('AAAAA ====', this.params['name'], dataID[0], dataID[1]);
            this.getPackageListing(dataID[0], dataID[1], this.CenterID, '');
            this.getPackageList('', this.CenterID, '');
          }
        });
      });
    });

    this.api.getHealthCareServices('center-detail').subscribe(
      (data) => {
        this.services = data.healthcare_services[0];
      },
      (error) => {
        console.log(error);
      }
    );

    this.api.getBriefFacilities(`about-${this.paramsId}-center`).subscribe(
      (data) => {
        if (data) {
          this.brief_facilities = data.brief_facilities;
        }
      },
      (error) => {
        console.log(error);
      }
    );

    this.api.getDoctorSpecialityFeaturesByName('Neonatal').subscribe(
      (data) => {
        this.docspecsfeatures = data.our_speciality_features;
      },
      (error) => {
        console.log(error);
      }
    );

    this.api.getspeciality_features().subscribe(
      (data) => {
        this.features = data.our_speciality_features;
      },
      (error) => {
        console.log(error);
      }
    );
    // this._getcenter();
    // this.getPackages()
    // this.getWorkShop();
    // this.SpecialityMasters();
    // this.AllCenters();
    // this.GetContantHubList();
    // this.getCentersDetails2();
    this.getCentreList();
  }

  getCentreList() {
    this.api.getCentreCities().subscribe((res) => {
      this.centerList = res;
      let cityDetails = [];
      this.centerList.forEach((element) => {
        element['area'].forEach((element2) => {
          if (this.cityName == element2.slug) {
            cityDetails.push({
              location: element.city,
              hospital: element2.center_name,
              city: element2.center_name_alias,
            });
          }
        });
      });
      this.CentreDetails = cityDetails;
      //console.log('CentreDetails: ', this.CentreDetails);
    });
  }

  public utm: any;
  getUtmSource() {
    let utmLms = getUrlVars()['utm_lms'];
    if (utmLms == '' || utmLms == undefined) {
      utmLms = [''];
    } else {
      utmLms = utmLms.split('-');
    }
    //console.log('utmLms:', utmLms);
    let lms_product = utmLms[0],
      lms_campaign = utmLms[1],
      lms_lpname = utmLms[2],
      lms_agency = utmLms[3],
      lms_publisher = utmLms[4],
      lms_source = utmLms[5],
      lms_medium = utmLms[6],
      lms_dealtype = utmLms[7],
      lms_creative = utmLms[8],
      lms_cta = utmLms[9];
    //console.log("product:",lms_product,"campaign:",lms_campaign,"lpname:",lms_lpname,"agency:",lms_agency,"publisher:",lms_publisher,"source:",lms_source,"medium:",lms_medium,"dealtype:",lms_dealtype,"creative:",lms_creative,"cta:",lms_cta);
    if (lms_product == '' || lms_product == undefined) {
      lms_product = 'RCW';
    }
    if (lms_campaign == '' || lms_campaign == undefined) {
      lms_campaign = 'RainbowWebsiteLead';
    }
    if (lms_lpname == '' || lms_lpname == undefined) {
      lms_lpname = 'RainbowCorpWeb';
    }
    if (lms_agency == '' || lms_agency == undefined) {
      lms_agency = 'na';
    }
    if (lms_publisher == '' || lms_publisher == undefined) {
      lms_publisher = 'na';
    }
    if (lms_source == '' || lms_source == undefined) {
      lms_source = 'website';
    }
    if (lms_medium == '' || lms_medium == undefined) {
      lms_medium = 'na';
    }
    if (lms_dealtype == '' || lms_dealtype == undefined) {
      lms_dealtype = 'na';
    }
    if (lms_creative == '' || lms_creative == undefined) {
      lms_creative = 'na';
    }
    if (lms_cta == '' || lms_cta == undefined) {
      lms_cta = 'na';
    }

    let Leadtype = '';
    if (
      getUrlVars()['utm_source'] == '' ||
      getUrlVars()['utm_source'] == undefined
    ) {
      Leadtype = 'earned';
    } else {
      Leadtype = 'owned';
    }

    let utm_Source = getUrlVars()['utm_source'],
      utm_Medium = getUrlVars()['utm_medium'],
      utm_Campaign = getUrlVars()['utm_campaign'],
      utm_Content = getUrlVars()['utm_content'],
      utm_Term = getUrlVars()['utm_term'],
      utm_Lms = getUrlVars()['utm_lms'];
    if (utm_Source == '' || utm_Source == undefined) {
      utm_Source = '';
    }
    if (utm_Medium == '' || utm_Medium == undefined) {
      utm_Medium = '';
    }
    if (utm_Campaign == '' || utm_Campaign == undefined) {
      utm_Campaign = '';
    }
    if (utm_Content == '' || utm_Content == undefined) {
      utm_Content = '';
    }
    if (utm_Term == '' || utm_Term == undefined) {
      utm_Term = '';
    }
    if (utm_Lms == '' || utm_Lms == undefined) {
      utm_Lms = '';
    }

    this.utm = {
      utm_source: utm_Source,
      utm_medium: utm_Medium,
      utm_campaign: utm_Campaign,
      utm_content: utm_Content,
      utm_term: utm_Term,
      utm_lms: utm_Lms,
      product: lms_product,
      campaign: lms_campaign,
      lpname: lms_lpname,
      agency: lms_agency,
      publisher: lms_publisher,
      source: lms_source,
      medium: lms_medium,
      dealtype: lms_dealtype,
      creative: lms_creative,
      cta: lms_cta,
      leadtype: Leadtype,
      modeoflead: 'website',
      page: 'Centre',
    };

    //var me = getUrlVars()["me"];
    function getUrlVars() {
      var vars = [],
        hash;
      var hashes = window.location.href
        .slice(window.location.href.indexOf('?') + 1)
        .split('&');
      for (var i = 0; i < hashes.length; i++) {
        hash = hashes[i].split('=');
        vars.push(hash[0]);
        vars[hash[0]] = hash[1];
      }
      return vars;
    }
  }

  onSubmitDirectPost() {
    this.submited = true;
    if (!this.GetInTouchOnPage.valid) {
      return;
    }
    $('#cDetailFGetInTouch .formWrp .launchpreloader').show();
    if (this.GetInTouchOnPage.valid) {
      this.api
        .postGetInTouchFormData(
          this.GetInTouchOnPage.value,
          this.CentreDetails,
          this.utm
        )
        .subscribe((res) => {
          console.log('postGetInTouchFormData:', res);
          $('#cDetailFGetInTouch .formWrp .launchpreloader').hide();
          $('#cDetailFGetInTouch .thankYouMsg').show();
          setTimeout(() => {
            $('#cDetailFGetInTouch .thankYouMsg').hide();
            this.GetInTouchOnPage.reset();
            setTimeout(() => {
              this.GetInTouchOnPage.controls.location.setValue(
                this.capitalize(this.cityName.replace('-', ' '))
              );
            }, 1000);
          }, 3000);
        });
    }
  }

  centerDetails(center) {
    this.api.getCentersDetails(center).subscribe(
      (res) => {
        this.centre_id = res.centers[0].id;
        let imgUrl = res.centers[0].circleimage
          ? res.centers[0].circleimage
          : this.imgsrc;
        this.title = res.centers[0].center_name;
        this.specialAnnouncements = res.centers[0].special_announcements;
        this.description = res.centers[0].description;
        this.address = res.centers[0].address;
        this.telephone = res.centers[0].telephone;
        this.lat_lng = res.centers[0].lat_long;
        this.imgsrc = imgUrl;
        this.imgBasePath = IMG_BASE_URL;
        this.center_brief_facilities = res.centers[0].brief_facilities;
        this.visitor_information = res.centers[0].visitor_information;
        this.centerImgsrc = IMG_BASE_URL + res.centers[0].centerimage;
        this.CenterName = res.centers[0].center_name_alias;
        this.MapLink = `http://www.google.com/maps/place/${res.centers[0].lat_long}`;
        //this.CenterID =
        //console.log('Center ',res);
        $.cookie('pTitle', this.title);
        $.cookie('pBannImg', this.imgsrc);

        this.getMetaInfo(res.centers[0]);
        if (this.currentSection) {
          this.filterCenterSpecility(
            this.currentSection,
            res.centers[0].our_speciality_features
          );
        } else {
          this.our_speciality_features = res.centers[0].our_speciality_features;
          //console.log('our_speciality_features',this.our_speciality_features)
        }

        this.virtual_tour_link = res.centers[0].virtual_tour_link;

        //let our_speciality_features_id = (this.our_speciality_features[0] ? this.our_speciality_features[0].id : 0);
        //let speciality_master = (this.our_speciality_features[0] ? this.our_speciality_features[0].speciality_masters_id : 0);

        //this.viewDoctorList(speciality_master,our_speciality_features_id);

        let user_lat_long_info = res.centers[0].lat_long.split(',');
        initGoogleMap(user_lat_long_info[0], user_lat_long_info[1]);
      },
      (err) => {
        console.log(err);
      }
    );
  }
  getMetaInfo(metadetail: any) {
    this.metaTitle.setTitle(metadetail.meta_title);
    this.meta.updateTag({ name: 'keywords', content: metadetail.meta_keyword });
    this.meta.updateTag({
      name: 'description',
      content: metadetail.meta_description,
    });
    this.meta.addTags([
      { property: 'og:title', content: metadetail.meta_title },
      { property: 'og:description', content: metadetail.meta_description },
      { property: 'og:keywords', content: metadetail.meta_keyword },
    ]);
  }
  filterCenterSpecility(currentSection: string, data: any) {
    this.our_speciality_features = data.filter((res) => {
      //console.log('filterCenterSpecility', res.page)
      return res.page == currentSection;
    });
  }
  _getcenter() {
    this.api.getCentreCities().subscribe(
      (res) => {
        this.centerList = res;
      },
      (err) => {
        console.error(err);
      }
    );
  }
  changeCenter(value) {
    //console.log('changeCenter', value);
    if (this.activePage == null) {
      document.location.href = '/our-centre/' + value;
    } else {
      document.location.href = '/' + this.currentSection + '/centre/' + value;
    }
  }
  viewDoctorList(speciality_master, speciality_feature): void {
    this.api
      .getDoctorListBySpecilityMaster(
        speciality_master,
        speciality_feature,
        this.centre_id,
        0
      )
      .subscribe((res) => {
        this.specilityDoctor = res;
        this.showDoctor = this.showDoctorFunc(this.specilityDoctor);
      });
  }
  showDoctorFunc(doctorList) {
    return this.specilityDoctor.length > 0 ? true : false;
  }

  public patientResources: any = [];
  public prepareYourselfYesources: any = [];
  public visitorResources: any = [];

  getCentersDetails2() {
    this.api.getCentersDetails2().subscribe((res) => {
      //console.log('CentersDetails', res['centers'])
      this.patientResources = res['centers'][0]['patient_resources'];
      this.prepareYourselfYesources =
        res['centers'][0]['prepare_yourself_resources'];
      this.visitorResources = res['centers'][0]['visitor_resources'];
    });
  }

  public WorkshopListing = [];

  getPackageListing(Type, ID, centerIDO, specialityid) {
    //console.log('type', Type, ID)
    if (Type == 'packages') {
      this.api.getPackageListing(ID, centerIDO, '').subscribe((res) => {
        //res = res.filter(res => res.headertag !== null);
        //console.log('PackageListing', res);
        this.PackageListing = res;
        this.activeBreadCrum(this.currentSection);
      });
    } else {
      this.api
        .getWorkshopListing(ID, centerIDO, specialityid)
        .subscribe((res) => {
          //res = res.filter(res => res.headertag !== null);
          //console.log('WorkshopListing', res);
          this.WorkshopListing = res;
        });
    }
  }

  closePopup() {
    document.querySelector('.dataPopupBox').classList.remove('active');
  }
  public Packages: any = [];

  getPackages() {
    this.api.getPackages().subscribe((res) => {
      //console.log('getPackages', res);
      this.Packages = res['packages'];
      this.Packages = this.Packages.filter((res) => res.headertag !== null);
    });
  }
  getPackageList(PackageID, center, specialityid) {
    this.api.getPackageListing(PackageID, center, '').subscribe((res) => {
      //console.log('PackageListing2:', res);
      this.interestedList = res;
      setTimeout(() => {
        this.GetInTouchOnPage.controls.intrestedin.setValue(
          this.PackageListing[0].packageheading
        );
        $('#intrestedinP').text(this.PackageListing[0].packageheading);
      }, 10);
    });
  }

  public specialityVal;
  public subSpeciality;
  public center;

  public Speciality: any = [];
  SpecialityMasters() {
    this.api
      .getSpecialityMasters()
      .map((res) => res.json())
      .subscribe((res) => {
        console.log('SpecialityMasters----', res.speciality_masters);
        this.Speciality = res.speciality_masters;
      });
  }

  public AllCenter: any = [];
  AllCenters() {
    this.api.getCentreSpecification().subscribe((res) => {
      this.AllCenter = res.centers;
      console.log('AllCenter', this.AllCenter);
    });
  }

  public SubSpeciality: any = [];
  selectSubCenters(event) {
    this.center = event.target.value;
    console.log(event.target.value);
    this.AllCenter.forEach((element) => {
      console.log(element.center_uid, parseInt(event.target.value));
      if (element.center_uid == parseInt(event.target.value)) {
        this.SubSpeciality = element.speciality_features;
      }
    });
    console.log('location', this.center);
  }

  selectAllValue(event) {
    this.subSpeciality = event.target.value;
    console.log('subSpeciality', this.subSpeciality);
    console.log('specialityVal', this.specialityVal);
  }

  public locationListItem: any = [];
  Selectlocation(event) {
    this.specialityVal = event.target.value;
    this.api.getCentreCities().subscribe((res) => {
      console.log('locationList----', res);
      this.locationListItem = res;
    });
    console.log('specialityVal', this.specialityVal);
  }

  public WorkShopData: any = [];
  getWorkShop() {
    this.api.getWorkShop().subscribe((res) => {
      console.log('WorkShopData', res);
      this.WorkShopData = res['workshops'];
      this.WorkShopData = this.WorkShopData.filter(
        (res) => res.workshopdate !== null
      );
      this.WorkShopData.forEach((element) => {
        element.startTime = element.workshopstartime.split(' ')[1];
        element.endTime = element.workshopendtime.split(' ')[1];
      });
    });
  }

  // public WorkshopListing: any = [];
  // getWorkshopListing(WorkshopID) {
  // 	this.api.getWorkshopListing(WorkshopID).subscribe(res => {
  // 		res.forEach(element => {
  // 			element.startTime = element.workshopstartime.split(" ")[1]
  // 			element.endTime = element.workshopendtime.split(" ")[1]
  // 		});
  // 		console.log('WorkshopListing', res)
  // 		this.WorkshopListing = res
  // 	})
  // }

  isNumber(evt) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  isletters(event: any) {
    const pattern = /^[a-zA-Z, '']+$/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      return false;
    }
    return true;
  }
  isAlfa(evt) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (
      charCode > 31 &&
      (charCode < 65 || charCode > 90) &&
      (charCode < 97 || charCode > 122)
    ) {
      return false;
    }
    return true;
  }

  DateFormater(dateStr) {
    dateStr.split(' ');
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    let newDate = new Date(dateStr);
    let DateNo = newDate.getDate();
    let MonthNo = newDate.getMonth();
    let yearNo = newDate.getFullYear();

    let DateData = {
      DateNo: DateNo,
      MonthName: monthNames[MonthNo],
      yearNo: yearNo,
    };
    return DateData;
  }

  public doctorsList: any = [];
  getDoctorsList() {
    this.api
      .getgetDoctors(this.specialityVal, this.subSpeciality, this.center)
      .subscribe((res) => {
        console.log('DoctorsList', res);
        this.doctorsList = res;
      });
  }

  public Webinar: any = [];
  public Event: any = [];
  public Media: any = [];
  public Articles: any = [];
  GetContantHubList() {
    this.api.getContentHubList(this.CenterID, `webinar`).subscribe((res) => {
      this.Webinar = res;
      //console.log('webinar Data', this.Webinar)
    });

    this.api.getContentHubList(this.CenterID, `webinar`).subscribe((res) => {
      //console.log('ContantHubList', res['content_hubs'])

      res['content_hubs'].forEach((element) => {
        element.calenderDate = this.DateFormater(element.contentdate);
      });

      this.Webinar = res['content_hubs'].filter(
        (data) => data.contentcategory == 1
      );
      this.Event = res['content_hubs'].filter(
        (data) => data.contentcategory == 5
      );
      this.Media = res['content_hubs'].filter(
        (data) => data.contentcategory == 7
      );
      this.Articles = res['content_hubs'].filter(
        (data) => data.contentcategory == 3
      );
      //console.log('Webinar', this.Webinar);
      //console.log('Event', this.Event);
      //console.log('Media', this.Media);
      //console.log('Articles', this.Articles);

      this.Webinar.forEach((element) => {
        if (element.contentvideourl != null) {
          // if youtube embed url match:, iframe_src.match(/youtu\.be.*(\?v=|\/embed\/)(.{11})/).pop();
          let youtube_video_id = element.contentvideourl
            .match(/youtu\.be.*(\/)(.{11})/)
            .pop();
          //element.contentvideoimage = "https://img.youtube.com/vi/"+youtube_video_id+"/0.jpg";
          element.contentvideourlnew =
            'https://www.youtube.com/embed/' + youtube_video_id + '?autoplay=1';
          element.contentvideourlcopy =
            'https://www.youtube.com/embed/' + youtube_video_id;
        }
      });

      setTimeout(() => {
        console.log('fancy box loaded');
        $('.fancyboximg').fancybox({});

        $('.fancybox').fancybox({
          type: 'iframe',
          maxWidth: 700,
          maxHeight: 600,
          fitToView: true,
          width: '70%',
          height: '70%',
          autoSize: true,
          closeClick: true,
          openEffect: 'none',
          closeEffect: 'none',
          helpers: {
            media: true,
          },
          youtube: {
            autoplay: 1, // enable autoplay
          },
        });
        if (this.firstTimePaging) {
          $('html, body').animate(
            {
              scrollTop: $('#PastWebinarsWrp').offset().top,
            },
            1200
          );
        } else {
          this.firstTimePaging = true;
        }
        $('.copyIcon').each((e) => {
          $(this).click((e) => {
            let eVal = $(this).find('span').html();
            const el = document.createElement('textarea');
            el.value = eVal;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            alert('Video URL copied');
          });
        });

        $('.WebinarSliderWrap')
          .not('.slick-initialized')
          .slick({
            arrows: true,
            prevArrow:
              '<a class="slick-prev"><img src="assets/images/centreimg/icon_prev_circle2.png" alt="" /></a>',
            nextArrow:
              '<a class="slick-next"><img src="assets/images/centreimg/icon_next_circle2.png" alt="" /></a>',
            dots: false,
            centerMode: false,
            focusOnSelect: true,
            infinite: false,
            slidesToShow: 2,
            slidesToScroll: 2,
            responsive: [
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                },
              },
            ],
          });

        $('.eventSliderWrap')
          .not('.slick-initialized')
          .slick({
            arrows: true,
            prevArrow:
              '<a class="slick-prev"><img src="assets/images/centreimg/icon_prev_circle2.png" alt="" /></a>',
            nextArrow:
              '<a class="slick-next"><img src="assets/images/centreimg/icon_next_circle2.png" alt="" /></a>',
            dots: false,
            centerMode: false,
            adaptiveHeight: true,
            focusOnSelect: true,
            infinite: false,
            slidesToShow: 2,
            slidesToScroll: 2,
            responsive: [
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
            ],
          });

        $('.MediaSlider')
          .not('.slick-initialized')
          .slick({
            arrows: true,
            prevArrow:
              '<a class="slick-prev"><img src="assets/images/centreimg/icon_prev_circle2.png" alt="" /></a>',
            nextArrow:
              '<a class="slick-next"><img src="assets/images/centreimg/icon_next_circle2.png" alt="" /></a>',
            dots: false,
            centerMode: true,
            centerPadding: 20,
            focusOnSelect: true,
            adaptiveHeight: true,
            infinite: true,
            slidesToShow: 2,
            slidesToScroll: 2,
            responsive: [
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                },
              },
            ],
          });

        $('.ArticlesSlider')
          .not('.slick-initialized')
          .slick({
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: true,
            prevArrow:
              '<a class="slick-prev"><img src="assets/images/centreimg/icon_prev_circle2.png" alt="" /></a>',
            nextArrow:
              '<a class="slick-next"><img src="assets/images/centreimg/icon_next_circle2.png" alt="" /></a>',
            dots: false,
            centerMode: true,
            centerPadding: 20,
            focusOnSelect: true,
            adaptiveHeight: true,
            infinite: true,
            responsive: [
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
            ],
          });

        $('.AnnouncementSlider').not('.slick-initialized').slick({
          arrows: true,
          prevArrow:
            '<a class="slick-prev"><img src="assets/images/centreimg/icon_btn_prev.png" alt="" /></a>',
          nextArrow:
            '<a class="slick-next"><img src="assets/images/centreimg/icon_btn_next.png" alt="" /></a>',
          dots: false,
          centerMode: false,
          infinite: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        });
        setTimeout(() => {
          $('.dataSection').removeClass('activeDiv').addClass('hideElement');
          $('.csServicesPackages')
            .removeClass('hideElement')
            .addClass('activeDiv');
        }, 1000);
      }, 1000);
    });
  }

  ngAfterViewChecked() {
    //console.log('after view checked');
    //initCentersSpecialityTabs();
  }

  hideModelForm() {
    $('#LMSDataCaptureFormGetInTouch').hide();
    $('#LMSDataCaptureFormGetInTouch .ThankYouMsgWrp').hide();
  }
  changeShowStatus() {
    this.showHide = !this.showHide;
    $('#ModelFormGetInTouch').hide();
    $('.iconForm').show();
    $('.iconFormV').show();
  }
  ShowForm() {
    this.showHide = true;
    $('.iconForm').hide();
    $('.iconFormV').hide();
    $('#ModelFormGetInTouch').show();
  }
  onSubmit() {
    this.submited = true;
    if (!this.GetInTouch.valid) {
      return;
    }
    this.preloader = true;
    var self = this;

    if (this.GetInTouch.valid) {
      $('#ModelFormGetInTouch').hide();

      let formTemp: any = <any>document.querySelector('#form');
      let formData: FormData = new FormData(formTemp);
      //console.log('Form Post Data',formData);
      let xhr: XMLHttpRequest = this.buildForm(formData);
      //console.log('XMLHttpRequest', xhr);
      xhr.onreadystatechange = () => {
        var obj = xhr;
        //console.log('obj', obj);
        if (obj.readyState == 4) {
          switch (this.pageName) {
            case 'madhapur':
              window.open(
                '/our-centre/' + this.pageName + '/thankyou?tag=AW',
                '_blank'
              );
              break;
            case 'kondapur-op-block':
              window.open(
                '/our-centre/' + this.pageName + '/thankyou?tag=AW',
                '_blank'
              );
              break;
            default:
              window.location.href =
                '/fertility-care/treatments/thankyou?tag=AW';
              break;
          }
          $('#ModelFormGetInTouch').hide();
          self.showHide = false;
          self.preloader = false;
          this.GetInTouch.reset();
        }
        if (obj.status == 200) {
          //console.log(obj.status);
        }
      };
    }
  }

  public buildForm(formData) {
    let url: any =
      API_BASE_URL + 'home/fertility_Speciality_get_in_touch_with_us';
    let xhr: XMLHttpRequest = new XMLHttpRequest();
    xhr.open('POST', url, true);

    // IE bug fixes to clear cache
    xhr.setRequestHeader('Cache-Control', 'no-cache');
    xhr.setRequestHeader('Cache-Control', 'no-store');

    xhr.send(formData);
    return xhr;
  }

  onLMSDataCaptureFormSubmit() {
    this.submited = true;
    if (!this.GetInTouchLms.valid) {
      return;
    }
    this.preloader = true;
    var self = this;

    if (this.GetInTouchLms.valid) {
      let formTemp: any = <any>document.querySelector('#GetInTouchLms');
      console.log('formTemp: ', formTemp);
      let formData: FormData = new FormData(formTemp);

      let xhr: XMLHttpRequest = this.buildLMSDataCaptureForm(formData);

      xhr.onreadystatechange = () => {
        var obj = xhr;
        if (obj.readyState == 4) {
          //window.location.href = `other/academic-programs/thankyou?tag=AW`;/
          $('#LMSDataCaptureFormGetInTouch .ThankYouMsgWrp').show();
          setTimeout(() => {
            $('#LMSDataCaptureFormGetInTouch .ThankYouMsgWrp').hide();
            $('#LMSDataCaptureFormGetInTouch').hide();
            this.GetInTouch.reset();
          }, 2000);
        }
        if (obj.status == 200) {
          //console.log(obj.status);
        }
      };
    }
  }

  public buildLMSDataCaptureForm(formData) {
    let url: any = API_BASE_URL + 'home/get/in_touch_with_us';
    let xhr: XMLHttpRequest = new XMLHttpRequest();
    xhr.open('POST', url, true);

    // IE bug fixes to clear cache
    xhr.setRequestHeader('Cache-Control', 'no-cache');
    xhr.setRequestHeader('Cache-Control', 'no-store');

    xhr.send(formData);
    return xhr;
  }

  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
      initGoogleMap();
      this.initCentersSpecialityTabs();
      this.iconSliderthing.changes.subscribe((t) => {
        //$(".aboutuslidercon").removeClass('slick-initialized').removeClass("slider").removeClass("slick-slider");

        $('.aboutuslidercon')
          .not('.slick-initialized')
          .slick(this.aboutusliderconConfig);
      });
    }

    this.getUtmSource();
  }
  @HostListener('window:load', []) onWindowLoad() {
    $('.footersitenav').hide();
    $('.centreServiceList')
      .not('.slick-initialized')
      .slick({
        slide: 'li',
        dots: false,
        arrows: true,
        draggable: false,
        swipeToSlide: false,
        focusOnSelect: true,
        prevArrow:
          '<a class="slick-prev"><img src="assets/images/centreimg/icon_btn_prev.png" alt="" /></a>',
        nextArrow:
          '<a class="slick-next"><img src="assets/images/centreimg/icon_btn_next.png" alt="" /></a>',
        infinite: false,
        autoplay: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        lazyLoad: 'ondemand',
        cssEase: 'linear',
        adaptiveHeight: true,
        responsive: [
          {
            breakpoint: 1080,
            settings: { infinite: true, slidesToShow: 2, slidesToScroll: 1 },
          },
          {
            breakpoint: 580,
            settings: { infinite: false, slidesToShow: 1, slidesToScroll: 1 },
          },
        ],
      });

    $('.centreServiceList').on(
      'afterChange',
      (event, slick, currentSlide, nextSlide) => {
        var $current = $('li.active');
        $('li').removeClass('active');
        $current.prev().addClass('active');
        $current.next().addClass('active');
        $('.dataSection').removeClass('activeDiv').addClass('hideElement');
        $('.' + $('.slick-current').attr('id'))
          .removeClass('hideElement')
          .addClass('activeDiv');
        console.log(
          'mobile slide',
          currentSlide,
          nextSlide,
          $('.slick-current').attr('id')
        );
      }
    );
    $('.centreServiceList li').each(() => {
      $(this).click((e) => {
        console.log('li clicked', e, $(this).attr('id'));
        $('.centreServiceList li').removeClass('active');
        $(this).addClass('active');
        var slideIndex = $(this).data('slick-index');
        console.log(slideIndex);
        $('.centreServiceList').slick('slickGoTo', parseInt(slideIndex)); //.slickGoTo(parseInt(slideIndex));
        $('.dataSection').removeClass('activeDiv').addClass('hideElement');
        $('.' + $(this).attr('id'))
          .removeClass('hideElement')
          .addClass('activeDiv');
        console.log($(this).attr('id'));
      });
    });

    $('.packagesLists')
      .not('.slick-initialized')
      .slick({
        slide: 'li',
        dots: false,
        arrows: true,
        draggable: false,
        swipeToSlide: false,
        prevArrow:
          '<a class="slick-prev"><img src="assets/images/centreimg/icon_prev_circle2.png" alt="" /></a>',
        nextArrow:
          '<a class="slick-next"><img src="assets/images/centreimg/icon_next_circle2.png" alt="" /></a>',
        infinite: false,
        autoplay: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        lazyLoad: 'ondemand',
        responsive: [
          {
            breakpoint: 1100,
            settings: { slidesToShow: 2, slidesToScroll: 1 },
          },
          { breakpoint: 680, settings: { slidesToShow: 2, slidesToScroll: 1 } },
          { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } },
        ],
      });

    $('.cWorkshopsLists')
      .not('.slick-initialized')
      .slick({
        slide: 'li',
        dots: false,
        arrows: true,
        draggable: false,
        swipeToSlide: false,
        focusOnSelect: true,
        prevArrow:
          '<a class="slick-prev"><img src="assets/images/centreimg/icon_btn_prev.png" alt="" /></a>',
        nextArrow:
          '<a class="slick-next"><img src="assets/images/centreimg/icon_btn_next.png" alt="" /></a>',
        infinite: false,
        autoplay: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        lazyLoad: 'ondemand',
        cssEase: 'linear',
        adaptiveHeight: true,
        responsive: [
          {
            breakpoint: 1080,
            settings: { infinite: false, slidesToShow: 2, slidesToScroll: 1 },
          },
          {
            breakpoint: 580,
            settings: { infinite: false, slidesToShow: 1, slidesToScroll: 1 },
          },
        ],
      });

    $('.apapproachlist').slick({
      slide: 'li',
      dots: false,
      arrows: true,
      prevArrow:
        '<a class="slick-prev"><img src="assets/images/prv-arrow2.png" alt="" /></a>',
      nextArrow:
        '<a class="slick-next"><img src="assets/images/nxt-arrow2.png" alt="" /></a>',
      infinite: false,
      autoplay: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        { breakpoint: 1100, settings: { slidesToShow: 2, slidesToScroll: 1 } },
        { breakpoint: 680, settings: { slidesToShow: 2, slidesToScroll: 1 } },
        { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } },
      ],
    });
    //$(".aboutuslidercon").removeClass('slick-initialized').removeClass("slider").removeClass("slick-slider");

    $('.aboutuslidercon')
      .not('.slick-initialized')
      .slick(this.aboutusliderconConfig);
    $('.aboutuslider')
      .removeClass('slick-initialized')
      .removeClass('slider')
      .removeClass('slick-slider');
    $('.aboutuslider')
      .not('.slick-initialized')
      .slick({
        slide: 'li',
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: true,
        prevArrow:
          '<a class="slick-prev"><img src="assets/images/prv-arrow2.png" alt="" /></a>',
        nextArrow:
          '<a class="slick-next"><img src="assets/images/nxt-arrow2.png" alt="" /></a>',
        asNavFor: '.aboutuslidercon',
        dots: false,
        centerMode: true,
        centerPadding: 20,
        focusOnSelect: true,
        infinite: true,
        responsive: [
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 680,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    setTimeout(() => {
      console.log('programName: ', this.programName);
      if (this.programName != '') {
        $('.modelPupup').hide();
      }
    }, 2500);
  }

  initCentersSpecialityTabs() {}
  ngOnDestroy() {}
}
