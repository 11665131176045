import { Inject, Component, OnDestroy, OnInit, AfterViewInit, PLATFORM_ID, ViewChildren, QueryList } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from "../services/api.service";
import { IMG_BASE_URL } from "../services/constants";
declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
declare let initGoogleMap: any;
declare let initCentersSpecialityTabs: any;

import { Meta, Title } from '@angular/platform-browser';
@Component({
	selector: 'about-center',
	templateUrl: './about-center.component.html'
})

export class ChildCareAboutCenterComponent implements OnDestroy, OnInit, AfterViewInit {


	constructor(private api: ApiService, public metaTitle: Title, public meta: Meta) {
		if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
    }
	}

	public services: any = [];
	public features: any = [];
	public docspecsfeatures: any = [];
	public brief_facilities: any[] = [];
	public title: any = "";
	public subtitle: any = "";
	public description: any = "";
	public imgsrc: any = "";
	public imgBasePath = "";
	public hidden = "hidden";
	public logo = "rainbow-logo";
	public links: any = [
		{ "label": "About Child Care", "link": "/child-care/about", "class": "","slug":"about" },
		{ "label": "Child Safety", "link": "/child-care/child-safety", "class": "","slug":"child-safety"  },
	{ "label": "Doctors", "link": "/child-care/doctors", "class": "","slug":"doctors"  },
	{ "label": "Our Specialities", "link": "/child-care/specialities", "class": "","slug":"specialities"  },
	{ "label": "Our Centres", "link": "/child-care/centres", "class": "active","slug":"centres"  },
	{ "label": "Vaccination", "link": "/child-care/vaccination", "class": "","slug":"vaccination"  },
	{ "label": "Testimonials", "link": "/child-care/testimonials", "class": "","slug":"testimonials"  },
	{ "label": "Share Feedback", "link": "/child-care/feedback", "class": "","slug":"feedback"  },
	{ "label": "Book An Appointment", "link": "/child-care/book-an-appointment", "class": "","slug":"book-an-appointment"  }]
	public activePage:string = 'about';
	@ViewChildren('abouttabs') things: QueryList<any>;

	ngOnInit() {

		this.api.getPageDescription('about-center')
			.subscribe(
			data => {
				this.title = data.page_descriptions[0].title;
				this.subtitle = data.page_descriptions[0].subtitle;
				this.description = data.page_descriptions[0].description;
				this.imgsrc = data.page_descriptions[0].image;
				this.imgBasePath = IMG_BASE_URL;
				if(data){
					//console.log(data);
					this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
					this.meta.updateTag({name:'keywords',content: data.page_descriptions[0].meta_keyword});
					this.meta.updateTag({name:'description',content: data.page_descriptions[0].meta_description});
					this.meta.addTags([
					  { property: 'og:title', content:  data.page_descriptions[0].meta_title},
					  { property: 'og:description', content: data.page_descriptions[0].meta_description },
					  { property: 'og:keywords', content: data.page_descriptions[0].meta_keyword }
					]);
				  }
			},
			error => {
				console.log(error);
			}
			)

		this.api.getHealthCareServices('center-detail')
			.subscribe(
			data => {
				this.services = data.healthcare_services[0]
			},
			error => {
				console.log(error);
			}
			)

		this.api.getBriefFacilities('about-center')
			.subscribe(
			data => {
				this.brief_facilities = data.brief_facilities
			},
			error => {
				console.log(error);
			}
			)

		this.api.getDoctorSpecialityFeaturesByName('Neonatal')
			.subscribe(
			data => {
				this.docspecsfeatures = data.our_speciality_features
			},
			error => {
				console.log(error);
			}
			)

		this.api.getspeciality_features()
			.subscribe(
			data => {
				this.features = data.our_speciality_features
			},
			error => {
				console.log(error);
			}
			)


	}

	ngAfterViewInit() {

		if (this.api.isBrowser && window['jQuery']) {
			initHome();
			initAllPage();
			initGoogleMap();

			$('.apapproachlist').slick({
				slide: 'li',
				dots: false,
				arrows: true,
				prevArrow: '<a class="slick-prev"><img src="assets/images/prv-arrow2.png" alt="" /></a>',
				nextArrow: '<a class="slick-next"><img src="assets/images/nxt-arrow2.png" alt="" /></a>',
				infinite: false,
				autoplay: false,
				slidesToShow: 4,
				slidesToScroll: 1,
				responsive: [
					{
						breakpoint: 1100, settings: {
							slidesToShow: 2, slidesToScroll: 1
						}
					},
					{
						breakpoint: 680, settings: {
							slidesToShow: 2, slidesToScroll: 1
						}
					},
					{
						breakpoint: 480, settings: {
							slidesToShow: 1, slidesToScroll: 1
						}
					}
				]
			});
		}

		this.things.changes.subscribe(t => {
			// this.ngForRendred();
			initCentersSpecialityTabs();
		})
	}

	ngOnDestroy() { }

}
