import { Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'page-info',
  templateUrl: './pageinfo.component.html',
})
export class PageinfoComponent implements OnInit {
  @Input() title: "";
  @Input() subtitle: "";
  @Input() description: "";
  @Input() imgsrc: "";
  @Input() imgBasePath: "";
    ngOnInit() {
    }
}
