import { Inject, Component, OnDestroy, OnInit, AfterViewInit, PLATFORM_ID, ViewChildren, QueryList } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from "../services/api.service";
import { Meta, Title } from '@angular/platform-browser';
import { IMG_BASE_URL, API_BASE_URL } from "../services/constants";
import { ActivatedRoute } from '@angular/router';
import { ReactiveFormsModule, FormsModule, FormControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
declare let initAboutTabs: any;
declare let dataLayerPush:any;
declare let MD5:any;

@Component({
	selector: 'packages-plans',
	templateUrl: './packages-plans-details.component.html',
	styles:[`
			.packages_table{width: 99.9%; margin-top: 10px;}
			.table>tbody>tr.warning>td, .table>tbody>tr.warning>th, .table>tbody>tr>td.warning, .table>tbody>tr>th.warning, .table>tfoot>tr.warning>td, .table>tfoot>tr.warning>th, .table>tfoot>tr>td.warning, .table>tfoot>tr>th.warning, .table>thead>tr.warning>td, .table>thead>tr.warning>th, .table>thead>tr>td.warning, .table>thead>tr>th.warning{background-color: #ffebff;}
			.packages_table .table>tbody>tr>td, .packages_table .table>tbody>tr>th, .packages_table .table>thead>tr>td, .packages_table .table>thead>tr>th{padding: 8px;}
			@media only screen and (max-width: 680px) {
				.packages_table>.table>tbody>tr>td, .packages_table>.table>tbody>tr>th, .packages_table>.table>thead>tr>td, .packages_table>.table>thead>tr>th{padding: 5px;}
			   }
			@media only screen and (max-width: 580px) {
				.packages_table>.table>tbody>tr>td, .packages_table>.table>tbody>tr>th, .packages_table>.table>thead>tr>td, .packages_table>.table>thead>tr>th{padding: 4px;}
			   }
			@media only screen and (max-width: 480px) {
				.packages_table>.table>tbody>tr>td, .packages_table>.table>tbody>tr>th, .packages_table>.table>thead>tr>td, .packages_table>.table>thead>tr>th{padding: 3px;}
	   		}
			@media only screen and (max-width: 420px) {
				.packages_table{
					font-size: 10px;
				}
				.packages_table>.table>tbody>tr>td, .packages_table>.table>tbody>tr>th, .packages_table>.table>thead>tr>td, .packages_table>.table>thead>tr>th{padding: 2px;}
		   }
		   @media only screen and (max-width: 360px) {
				.packages_table>.table>tbody>tr>td, .packages_table>.table>tbody>tr>th, .packages_table>.table>thead>tr>td, .packages_table>.table>thead>tr>th{padding: 1px;}
	   		}
		`]
})

export class OtherPackagesAndPlansDetailsComponent implements OnDestroy, OnInit, AfterViewInit {


	constructor(private api: ApiService, public metaTitle: Title, private formBuilder: FormBuilder, public meta: Meta, private route: ActivatedRoute, private activatedRoute: ActivatedRoute) {
		if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
    }
	}

	public services: any[] = [];
	public brief_facilities: any[] = [];
	public title: any = "";
	public subtitle: any = "";
	public description: any = "";
	public imgsrc: any = "";
	public imgBasePath = "";
	public hidden = "hidden";
	public logo = "logo";
	public bread_crumb_links: any = [{ "label": "Home", "link": "/", "class": "" },
	{ "label": "Others", "link": "", "class": "" },
	{ "label": "Packages / Plans", "link": "", "class": "" }];

	public links: any = [];
	public activePage:string = 'packages_plans';

	public packages: any = [];
    public packageDetails: any = [];
    public activeList:any;
	public bread_crumb_child_links
	public child_care_links
	public bread_crumb_women_links
	public women_care_links
	public bread_crumb_fertility_links
	public fertility_care_links
	public currentSection

	public PackageListing = []
	public LargeDecription
	public centersList

	public locationModel:any = null;


	@ViewChildren('atabs') atabsThings: QueryList<any>;

	isletters(event: any) {
        const pattern = /^[a-zA-Z, '']+$/;  
        let inputChar = String.fromCharCode(event.charCode);
        if (event.keyCode != 8 && !pattern.test(inputChar)) {
            return false;
        }
        return true;
    }

	isNumber(evt) {
		evt = (evt) ? evt : window.event;
		var charCode = (evt.which) ? evt.which : evt.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}

	activeBreadCrum(currentSection) {
		switch (currentSection) {
			case "child-care":
				this.bread_crumb_links = this.bread_crumb_child_links;
				this.links = this.child_care_links;
				this.logo = 'rainbow-logo';
				break;
			case "women-care":
				this.bread_crumb_links = this.bread_crumb_women_links;
				this.links = this.women_care_links;
				this.logo = 'birth-right-logo';
				break;
			case "fertility-care":
				this.bread_crumb_links = this.bread_crumb_fertility_links;
				this.links = this.fertility_care_links;
				this.logo = 'birth-right-logo';
				break;
			default:
				this.bread_crumb_links = [{ "label": "Home", "link": "/", "class": "" },
                { "label": "Packages Plans", "link": "other/packages-plans", "class": "" },
                { "label": this.PackageListing[0].packageheading, "link": "", "class": "" }];
                this.logo = 'logo';
		}
	}

	public utm:any;
	getUtmSource(){		
		let utmLms = getUrlVars()["utm_lms"];
			if(utmLms == "" || utmLms == undefined){
				utmLms = [""];
			}else{
				utmLms = utmLms.split("-");
			}
		//console.log('utmLms:', utmLms);
		let lms_product=utmLms[0], 
			lms_campaign=utmLms[1], 
			lms_lpname=utmLms[2], 
			lms_agency=utmLms[3], 
			lms_publisher=utmLms[4], 
			lms_source=utmLms[5], 
			lms_medium=utmLms[6], 
			lms_dealtype=utmLms[7], 
			lms_creative=utmLms[8], 
			lms_cta=utmLms[9];
			//console.log("product:",lms_product,"campaign:",lms_campaign,"lpname:",lms_lpname,"agency:",lms_agency,"publisher:",lms_publisher,"source:",lms_source,"medium:",lms_medium,"dealtype:",lms_dealtype,"creative:",lms_creative,"cta:",lms_cta);
		if(lms_product == "" || lms_product == undefined){
			lms_product = "RCW";
		}
		if(lms_campaign == "" || lms_campaign == undefined){
			lms_campaign = "RainbowWebsiteLead";
		}
		if(lms_lpname == "" || lms_lpname == undefined){
			lms_lpname = "RainbowCorpWeb";
		}
		if(lms_agency == "" || lms_agency == undefined){
			lms_agency = "na";
		}
		if(lms_publisher == "" || lms_publisher == undefined){
			lms_publisher = "na";
		}
		if(lms_source == "" || lms_source == undefined){
			lms_source = "website";
		}
		if(lms_medium == "" || lms_medium == undefined){
			lms_medium = "na";
		}
		if(lms_dealtype == "" || lms_dealtype == undefined){
			lms_dealtype = "na";
		}
		if(lms_creative == "" || lms_creative == undefined){
			lms_creative = "na";
		}
		if(lms_cta == "" || lms_cta == undefined){
			lms_cta = "na";
		}

		let Leadtype = "";
		if(getUrlVars()["utm_source"] == "" || getUrlVars()["utm_source"] == undefined){
			Leadtype = "earned";
		}else{
			Leadtype = "owned";
		}

		let utm_Source=getUrlVars()["utm_source"],
			utm_Medium= getUrlVars()["utm_medium"],
			utm_Campaign= getUrlVars()["utm_campaign"],
			utm_Content= getUrlVars()["utm_content"],
			utm_Term= getUrlVars()["utm_term"],
			utm_Lms= getUrlVars()["utm_lms"]
		if(utm_Source == "" || utm_Source == undefined){
			utm_Source = "";
		}
		if(utm_Medium == "" || utm_Medium == undefined){
			utm_Medium = "";
		}
		if(utm_Campaign == "" || utm_Campaign == undefined){
			utm_Campaign = "";
		}
		if(utm_Content == "" || utm_Content == undefined){
			utm_Content = "";
		}
		if(utm_Term == "" || utm_Term == undefined){
			utm_Term = "";
		}
		if(utm_Lms == "" || utm_Lms == undefined){
			utm_Lms = "";
		}

		this.utm=
			{
				utm_source: utm_Source,
				utm_medium: utm_Medium,
				utm_campaign: utm_Campaign,
				utm_content: utm_Content,
				utm_term: utm_Term,
				utm_lms: utm_Lms,
				product: lms_product, 
				campaign: lms_campaign, 
				lpname: lms_lpname, 
				agency: lms_agency, 
				publisher: lms_publisher, 
				source: lms_source, 
				medium: lms_medium, 
				dealtype: lms_dealtype, 
				creative: lms_creative, 
				cta: lms_cta,
				leadtype:Leadtype,
				modeoflead:"website",
				page:"Centre"
			}
		

		//var me = getUrlVars()["me"];
		function getUrlVars() {
			var vars = [], hash;
			var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
			for (var i = 0; i < hashes.length; i++) {
				hash = hashes[i].split('=');
				vars.push(hash[0]);
				vars[hash[0]] = hash[1];
			}
			return vars;
		}
	}

	public nameValid = false
	public mobileValid = false
	public emailValid = false
	public locationValid = false
	public authorizationValid = false
	
	onSubmitPost(){
		
		if(this.postaddLeadsForm.controls.name.invalid) this.nameValid = true;
		if(this.postaddLeadsForm.controls.mobile.invalid ) this.mobileValid = true;
		if(this.postaddLeadsForm.controls.email.invalid) this.emailValid = true;
		if(this.postaddLeadsForm.controls.location.invalid ) this.locationValid = true;
		if(this.postaddLeadsForm.controls.authorization.invalid ) this.authorizationValid = true;

		this.postaddLeadsForm.controls.intrestedin.setValue(this.PackageListing[0]['packageheading']);
		console.log('form value', this.postaddLeadsForm.value, this.postaddLeadsForm);
		if(this.postaddLeadsForm.valid){
			this.DataLayerONSubmitForm(this.postaddLeadsForm.controls.name.value, this.postaddLeadsForm.controls.email.value, this.postaddLeadsForm.controls.mobile.value, this.postaddLeadsForm.controls.location.value);
			$(".launchpreloader").show();
			this.api.postaddLeads(this.postaddLeadsForm.value, this.utm).subscribe(res =>{
				console.log(res);
				if(res.Message == "Success"){
					$(".launchpreloader").hide();
					$(".postaddLeads").show();
					this.postaddLeadsForm.reset();
					this.nameValid = false
					this.mobileValid = false
					this.emailValid = false
					this.locationValid = false
					this.authorizationValid = false
					this.locationModel = null
					$("#locationCtrl").html('Select Centre')
					
					setTimeout(() => {
						$(".postaddLeads").hide();
						console.log('form value', this.postaddLeadsForm.value);
						this.DataLayerONThankYouPage();
					}, 3000);
				}
			})
		}
	}


	setHospital(thisO){		
		this.postaddLeadsForm.controls.hospital.setValue(thisO.target.options[thisO.target.options.selectedIndex].text)
		//console.log('form Data', this.postaddLeadsForm)
	}

	OnClickAuthorization(e){
		//console.log('OnClickAuthorization:', e.currentTarget.checked);
		if(e.currentTarget.checked){
			this.DataLayerOnFillForm();
		}
	}

	public postaddLeadsForm: FormGroup;
	public utmParems
	ngOnInit() {

		this.getUtmSource()
		// console.log('this.activatedRoute', this.activatedRoute.queryParams['value'])
		
		// if(this.activatedRoute.queryParams['value']){
		// 	this.utmParems = this.activatedRoute.queryParams['value']
		// }
		
		
		
		this.postaddLeadsForm = this.formBuilder.group({
			'name': ['', [Validators.required, Validators.pattern(".*\\S.*")]],
			'mobile': ['', Validators.compose([Validators.required,Validators.minLength(10)])],
			'email': ['', Validators.compose([ Validators.required, Validators.pattern("[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}")]) ],			
			'location': ['', Validators.required],
			'message': [''],
			'page': ['Package'],
			'modeoflead':['Website'],
			'hospital':[''],
			'intrestedin': [''],
			'authorization': [false, [Validators.required]],
		});





		this.imgBasePath = IMG_BASE_URL;
		const packageParem = this.activatedRoute.params['value']['name']
		const id = packageParem			

		this.api.getPackageListing('', '', '').subscribe(res => {
			console.log("SSSS", res);
			res.forEach(element => {
			//	console.log("=====", element.slug , packageParem);
				if(element.slug == packageParem){
					this.PackageListing.push(element);
					this.LargeDecription = element.packagelargedecription
					this.activeBreadCrum(this.currentSection);					
					console.log('this.PackageListing',this.PackageListing)
					this.centersList = element.centerlist
				}
			});
			this.pageLoadDataLayerPush();
		})
	}

	pageLoadDataLayerPush(){
		let c_path = window.location.pathname.split('/');
		let pName = c_path[c_path.length-2], spName = c_path[c_path.length-1];
			pName = pName.replace(/-/gi, "-"); spName = spName.replace(/-/gi, "-");
		//eventAction,eventlabel,eventCategory,linkClicked,pageName,pageCategory,pageSubcategory,AppointmentDate,language,pageType,ageofPatient,appointmentId,appointmentdate,appointmentTime,bookforLocation,bookforSpeciality,bookforSubSpeciality,packagePlan,dobofPatient,doctorsName,usermobilenumber,useremail,username,selectedHospitalUnit,typeOfAppointment,userType,patientType,weightofPatient,temperatureofPatient,transactionID,revenue,products,price,Quantity,paymentStatus,utmSource,message
		dataLayerPush('Packages_DetailPage','Packageplan_GetinTouchwithUs_DetailPage','Packageplan_GetinTouchwithUs_DetailPage','',pName,'Packages',spName,'','English',pName,'','','','','','','',spName,'','','','','','','','','','','','','','','','','','','');
	}
	DataLayerOnFillForm(){
		let c_path = window.location.pathname.split('/');
		let pName = c_path[c_path.length-2], spName = c_path[c_path.length-1];
			pName = pName.replace(/-/gi, "-"); spName = spName.replace(/-/gi, "-");
		//eventAction,eventlabel,eventCategory,linkClicked,pageName,pageCategory,pageSubcategory,AppointmentDate,language,pageType,ageofPatient,appointmentId,appointmentdate,appointmentTime,bookforLocation,bookforSpeciality,bookforSubSpeciality,packagePlan,dobofPatient,doctorsName,usermobilenumber,useremail,username,selectedHospitalUnit,typeOfAppointment,userType,patientType,weightofPatient,temperatureofPatient,transactionID,revenue,products,price,Quantity,paymentStatus,utmSource,message
		dataLayerPush('Packages_DetailPage','Packageplan_GetinTouchwithUs_FillForm','Packageplan_GetinTouchwithUs_FillForm','',pName,'Packages',spName,'','English',pName,'','','','','','','',spName,'','','','','','','','','','','','','','','','','','','');
	}
	DataLayerONSubmitForm(name:any, email:any, mobile:any, city:any){
		if(email != ''){
			email  = MD5(email.toString());
		}
		if(mobile != ''){
			mobile  = MD5(mobile.toString());
		}		
		let c_path = window.location.pathname.split('/');
		let pName = c_path[c_path.length-2], spName = c_path[c_path.length-1];
			pName = pName.replace(/-/gi, "-"); spName = spName.replace(/-/gi, "-");
		//eventAction,eventlabel,eventCategory,linkClicked,pageName,pageCategory,pageSubcategory,AppointmentDate,language,pageType,ageofPatient,appointmentId,appointmentdate,appointmentTime,bookforLocation,bookforSpeciality,bookforSubSpeciality,packagePlan,dobofPatient,doctorsName,usermobilenumber,useremail,username,selectedHospitalUnit,typeOfAppointment,userType,patientType,weightofPatient,temperatureofPatient,transactionID,revenue,products,price,Quantity,paymentStatus,utmSource,message
		dataLayerPush('Packages_DetailPage','Packageplan_GetinTouchwithUs_Submit','Packageplan_GetinTouchwithUs_Submit','',pName,'Packages',spName,'','English',pName,'','','','',city,'','',spName,'','',mobile,email,name,'','','','','','','','','','','','','','');
	}
	DataLayerONThankYouPage(){
		let c_path = window.location.pathname.split('/');
		let pName = c_path[c_path.length-2], spName = c_path[c_path.length-1];
			pName = pName.replace(/-/gi, "-"); spName = spName.replace(/-/gi, "-");
		//eventAction,eventlabel,eventCategory,linkClicked,pageName,pageCategory,pageSubcategory,AppointmentDate,language,pageType,ageofPatient,appointmentId,appointmentdate,appointmentTime,bookforLocation,bookforSpeciality,bookforSubSpeciality,packagePlan,dobofPatient,doctorsName,usermobilenumber,useremail,username,selectedHospitalUnit,typeOfAppointment,userType,patientType,weightofPatient,temperatureofPatient,transactionID,revenue,products,price,Quantity,paymentStatus,utmSource,message
		dataLayerPush('Packages_DetailPage','Packageplan_GetinTouchwithUs_Thankyoupage','Packageplan_GetinTouchwithUs_Thankyoupage','',pName,'Packages',spName,'','English',pName,'','','','','','','',spName,'','','','','','','','','','','','','','','','','','','');
	}

	ngAfterViewInit() {

		if (this.api.isBrowser && window['jQuery']) {
			initHome();
			initAllPage();
			initAboutTabs();
			
		}
	}

	ngOnDestroy() { }

}
