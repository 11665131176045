import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  ViewChildren,
  QueryList,
  HostListener,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL } from '../services/constants';

declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
import { Meta, Title } from '@angular/platform-browser';
declare let initAboutTabs: any;

@Component({
  selector: 'careers',
  templateUrl: './careers.component.html',
})
export class CareersComponent implements OnDestroy, OnInit, AfterViewInit {
  constructor(
    private api: ApiService,
    public metaTitle: Title,
    public meta: Meta
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
    }
  }

  public careers: any = [];

  public joinusslideConfig: any;
  public workingexpsliderConfig: any;
  public showSlider: boolean = false;
  public services: any = [];
  public title: any = '';
  public subtitle: any = '';
  public description: any = '';
  public imgsrc: any = '';
  public brief_facilities_description: any = '';
  public brief_facilities_image: any = '';
  public imgBasePath = '';
  public hidden = 'hidden';
  public logo = 'logo';
  public videoItemList: Array<any> = [0, 1, 2];
  public workingexpsliderData: any = [
    {
      id: 1,
      title: 'Life Influencer',
      image: 'about-Careers_02.png',
      description: `Each day at Rainbow Hospital brings a chance to influence lives of people, further your learning and discover something new.`,
    },
    {
      id: 2,
      title: 'Serve with passion',
      image: 'about-Careers_03.png',
      description: `Get ready to join a committed team of doctors, nurses, educators, researchers, administrative professionals, support staff who all work together in harmony to practice compassionate medical care.`,
    },
    {
      id: 3,
      title: 'Patient centric approach',
      image: 'about-Careers_04.png',
      description: `You are joining a multidisciplinary specialty team of medical experts are committed to providing exceptional care and patient services for the Nation's mother and children from across all walks of life.`,
    },
  ];
  public bread_crumb_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'About Us', link: '/about-us', class: '' },
    { label: 'Careers', link: '', class: '' },
  ];

  public links: any = [];
  public activePage: string = 'careers';

  // @ViewChildren('abouttabs') things: QueryList<any>;

  ngOnInit() {
    this.api
      .getAboutSubFooterDetails()
      .map((res) => res)
      .subscribe((data) => {
        this.links = data;
      });
    this.api.getPageDescription('careers').subscribe(
      (data) => {
        (this.title = data.page_descriptions[0].title),
          (this.subtitle = data.page_descriptions[0].subtitle),
          (this.description = data.page_descriptions[0].description),
          (this.imgsrc = data.page_descriptions[0].image),
          (this.imgBasePath = IMG_BASE_URL);
        if (data) {
          //console.log(data);
          this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
          this.meta.updateTag({
            name: 'keywords',
            content: data.page_descriptions[0].meta_keyword,
          });
          this.meta.updateTag({
            name: 'description',
            content: data.page_descriptions[0].meta_description,
          });
          this.meta.addTags([
            {
              property: 'og:title',
              content: data.page_descriptions[0].meta_title,
            },
            {
              property: 'og:description',
              content: data.page_descriptions[0].meta_description,
            },
            {
              property: 'og:keywords',
              content: data.page_descriptions[0].meta_keyword,
            },
          ]);
        }
      },
      (error) => {
        console.log(error);
      }
    );

    // this.api.getHealthCareServices('who-we-are')
    // 	.subscribe(
    // 	data => {
    // 		this.services = data.healthcare_services
    // 	},
    // 	error => {
    // 		console.log(error);
    // 	}
    // 	)

    // this.api.getBriefFacilities('who-we-are')
    // 	.subscribe(
    // 	data => {
    // 		this.brief_facilities_description = data.brief_facilities[0].description,
    // 			this.brief_facilities_image = data.brief_facilities[0].image
    // 	},
    // 	error => {
    // 		console.log(error);
    // 	}
    // 	)
    /*
		this.api.getCareers().subscribe( data =>{
            this.careers = data.careers;
			this.imgBasePath = IMG_BASE_URL;
			//console.log('careers', this.careers);
          },
          error => {
            console.log(error);
          }
        );
*/
  }

  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
      setTimeout(() => {
        this.showSlider = true;
      }, 1000);
    }
  }

  ngOnDestroy() {}

  @HostListener('window:load', []) onWindowLoad() {
    $('.joinusslide').not('.slick-initialized').slick({
      slide: '.videoitems',
      speed: 1000,
      dots: true,
      arrows: false,
      infinite: true,
      autoplay: true,
      slidesToShow: 1,
      slidesToScroll: 1,
    });

    $('.workingexpslider')
      .not('.slick-initialized')
      .slick({
        slide: '.pressClipsBox',
        dots: true,
        arrows: false,
        infinite: true,
        autoplay: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
        responsive: [
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 680,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
  }
}
