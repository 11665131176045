<div class="container-fluid">
    <div class="background">
        <img src="assets/images/bg-press-clip.png" data-t="bg_01_00.jpg" data-d="bg-press-clip.png" class="bgImg bg0 active" />
    </div>

    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <section class="page mediaSection pageheader">
        <div class="row">
            <div class="container">
                <div class="col-xs-12 col-sm-7 col-lg-7 abannimgwrp">
                    <div class="aboutbannimg"><img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" /></div>
                </div>
                <div class="col-xs-12 col-sm-5 col-lg-5">
                    <div class="ptitle yellow">
                        <h1>{{title}}</h1>
                        <h3>{{subtitle}}</h3>
                    </div>
                </div>

                <div class="col-xs-12 PressReleases">
                    <h3>Press Clips <a href="about-us/media/media-press-clips" class="viewAll">View All</a></h3>
                    <div class="row">
                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 PressReleasesBox" *ngFor="let pressClips of press_clips">
                            <div class="dateBox">
                                <div class="month">{{pressClips.date | amDateFormat : "MMMM"}}</div>
                                <div class="date">{{pressClips.date | amDateFormat : "DD"}}</div>
                                <div class="year">{{pressClips.date | amDateFormat : "YYYY"}}</div>
                            </div>
                            <h4>{{pressClips.author}}</h4>
                            <h5>{{pressClips.designation}}</h5>
                            <p class="publish_in"> <span class="style1">{{pressClips.publish_in}}</span> <br>
                                <span class="date">{{pressClips.date | amDateFormat: "dddd, DD MMMM YYYY"}} </span>
                            </p>
                            <p [innerHTML]="pressClips.description | slice:0:100">...</p>
                            <p><a href="about-us/media/media-press-clips/{{pressClips.page}}" class="readMore" (click)="pressClipId(pressClips.id)">Read More</a></p>
                        </div>

                    </div>
                </div>

                <!-- <div class="col-xs-12 PressReleases">
                    <h3>Press Releases <a href="about-us/media/media-press-releases" class="viewAll">View All</a></h3>
                    <div class="row" myMatchHeight="PressReleasesBox">
                        <div class="col-xs-12 col-sm-6 PressReleasesBox" *ngFor="let pressRealeases of (press_release|slice:press_release.length - 2);">
                            <div class="dateBox">
                                <div class="month">{{pressRealeases.date | amDateFormat : "MMMM"}}</div>
                                <div class="date">{{pressRealeases.date | amDateFormat : "DD"}}</div>
                                <div class="year">{{pressRealeases.date | amDateFormat : "YYYY"}}</div>
                            </div>
                            <h4>{{pressRealeases.title}}</h4>
                            <p>{{pressRealeases.location}}:</p>
                            <p [innerHTML]="pressRealeases.description"></p>
                            <p><a href="about-us/media/media-press-releases/press-releases-details/{{pressRealeases.id}}" class="readMore">Read More</a></p>
                        </div>
                    </div>
                </div> -->

                <!-- <div class="col-xs-12 MediaContactPolicy">
                    <h3>Media Contact & Policy <a href="/assets/pdf/CSR-Policy-Rainbow-Hospitals.pdf" target="__blank" class="viewAll">CSR Policy - Rainbow Hospitals</a></h3>
                    <div class="row">
                        <div class="col-xs-12 col-sm-4">
                            <img src="assets/images/Media-Contact.png" class="img-responsive img" />
                        </div>
                        <div class="col-xs-12 col-sm-8">
                            <div class="default-t-and-c">
                                <p>
                                    The Public Relations & Media Department at Rainbow Hospitals handles all media inquiries. We welcome inquiries from local, regional and national media and members of the media should call the Public Relations and Media Department if you have any of the
                                    following requests
                                </p>
                                <ul>
                                    <li> Interview with an expert spokesperson for medical news or with a doctor on healthcare topics. We are happy to connect you with some of the world’s most knowledgeable pediatric and women care doctors from across different
                                        specialties. Our experts can provide information about cutting edge medical care, healthcare trends & topics, patient stories and more.</li>
                                    <li> Arrangement for any photography or video shoots within the hospital during any press meet or events. Please note that the Hospital Policy requires that a PR team member accompany all media personnel during their visit
                                        to Rainbow Hospital across all locations.</li>
                                    <li> Any information request related to a news releases, event or press release from the hospital </li>
                                </ul>
                            </div>
                            <div class="viewmore" *ngIf="viewmore">
                                <h3>MEDIA CONTACT</h3>
                                <p>The Public Relations &amp; Media Department at Rainbow Hospitals is available between 9.00 AM to 6.00 PM, Monday to Saturday, every week. You can address any media inquiries to:</p>
                                <address>
									<p style="display:block">
										Mr. Nishant Mishra<br>
										Address: Rainbow Children’s Hospital &amp; BirthRight By Rainbow<br>
										22, Road No - 4, (old road no-10), Karvy Lanes,<br>
										Banjara Hills, Hyderabad - 500 034<br>
										Andhra Pradesh, INDIA.<br>
										Email: <a href="mailto:nishant.mishra@rainbowhospitals.in">nishant.mishra@rainbowhospitals.in</a> <br>
									</p>
								</address>
                            </div>
                            <div class="viewmore" *ngIf="viewmore">
                                <h3>MEDIA GUIDELINES</h3>
                                <p>The Public Relations and Media Department has put together the following guidelines for any media inquiry to effectively accommodate any media request or news and events at Rainbow Hospitals.</p>
                                <ul>
                                    <li>Media should provide advance notice to the Public Relations &amp; Media Department for arrangement of any interview or photo shoot or coverage of any news &amp; events from the Hospital.</li>
                                    <li>To ensure confidentiality and to protect the privacy &amp; appropriate sensitivity to patients &amp; families, a Public Relations team member must accompany all reporters, photographers or any media crews on the Rainbow
                                        Hospitals campus across all locations.</li>
                                    <li>Patient information will not be released to the media without the written consent of the patient or legal representative.</li>
                                    <li>Media will be met by a Public Relations team member and will accompany them to all locations during their visit at the Hospital</li>
                                </ul>
                            </div>
                            <div class="viewmore" *ngIf="viewmore">
                                <h3>SOCIAL MEDIA</h3>
                                <p>Rainbow Hospitals is just starting to build a number of active communities, and we encourage you to connect with us here, share your experiences with us and each other. We encourage everyone participating in social conversations
                                    with or about Rainbow Hospitals to comment, share their experiences with each other and build this community. We want to ensure that all of our social media networking sites are welcoming, provide meaningful and educational
                                    information, and positive expressions of support for all participants. We do make reasonable efforts to monitor all of our social media channels to ensure that posted comments are appropriate. While we stay connected,
                                    let us respect privacy. Read our guidelines below and join the conversation. We’re listening!</p>

                                <h4 class="social-title" [ngClass]="{'icon-minus': showSocial, 'icon-plus':!showSocial }" (click)="toggleSocial()">Social Media Terms & Conditions</h4>
                                <div class="terms-conditions" *ngIf="showSocial">
                                    <ol>
                                        <li>Rainbow Hospitals reserves the ultimate right to edit or delete any comments or content deemed inappropriate. The following types of comments or content will be deleted by administrators:
                                            <ul>
                                                <li>Abusive or hurtful comments</li>
                                                <li>Off-topic and redundant comments (this includes promotion of events, groups, pages, websites, organizations and programs not related to or affiliated with Rainbow Hospitals)</li>
                                                <li>Comments that use foul language or "hate speech" (racial-, ethnic- or gender-bashing language)</li>
                                                <li>Personal attacks or defamatory comments (i.e., making negative personal comments about a fellow commenter) instead of just expressing a difference of opinion</li>
                                                <li>Comments that violate the privacy of our patients and their families</li>
                                                <li>Content that violates copyright or that may be confidential</li>
                                            </ul>
                                        </li>
                                        <li>You agree not to post content that is illegal, obscene, defamatory or threatening or abuse intellectual property rights on Rainbow Hospitals’ social sites.&nbsp;</li>
                                        <li>Rainbow Hospitals reserves the right to review, edit and/or delete any of your postings in its sole discretion. While Rainbow Hospitals makes reasonable efforts to monitor and moderate posted content, it does not
                                            moderate all comments and cannot always respond in a timely manner to online requests for information.</li>
                                        <li>Remember that information posted on any of our social media platforms should not be considered medical advice and should not replace a consultation with a health care professional.</li>
                                        <li>Participants will not evaluate, endorse or recommend any particular health care providers, medications or treatments. If you have questions about your health, please contact your health care provider.</li>
                                        <li>By submitting content to any Rainbow Hospitals social media page (wall posts, comments, photos, links, etc.), you understand that this information is publicly available, and that Rainbow Hospitals may use this information
                                            in staff, family and external publications and other communications. If you do not wish to have your information published, please do not post the information.</li>
                                        <li>Other participants on social media channels may use your posted content beyond the control of Rainbow Hospitals. Rainbow Hospitals does not have the ability to monitor, intercept or otherwise remove your content
                                            from any web presence owned by another entity.</li>
                                        <li>Never disclose personal identifiable information such as your location, medical record number, financial information, or other private information.</li>
                                        <li>We do not entertain any requests on our social media channels from concerned individuals asking for information about a particular patient’s medical status. As an organization providing health care services, we
                                            are prevented by law from publicly providing any information about our patients, even if the information has already been disclosed by a patient’s parents, friends or family members</li>
                                        <li>While it may seem that, by not replying to comments left on our social media channels that the hospital is not being proactive in its approach to a patient’s care, the reality is that our clinical teams always partner
                                            closely with our patients and their families as part of their approach to care delivery. As part of our close partnership, we give families the luxury of updating their loved ones on their terms and in a manner
                                            that is convenient and comfortable for them.</li>
                                    </ol>
                                    <p>We place an absolute priority on rigorously adhering to our discipline of preserving our patients’ privacy. This is a discipline that we carefully follow no matter how eager individuals may be to receive answers on
                                        social media.</p>
                                </div>
                            </div>

                            <a href="javascript:void(0)" class="readMore" (click)="toggleReadMore()">{{readmoreTitle}}</a>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </section>
    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>

    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>