import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  ViewChildren,
  QueryList,
  HostListener,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL } from '../services/constants';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
// import { FindValueSubscriber } from 'rxjs/operator/find';

declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
declare let initAboutTabs: any;

@Component({
  selector: 'knowledge-centre-detail',
  templateUrl: './knowledge-centre-detail.component.html',
  styles: [
    `
      .KnowledgeCentreDetails {
        padding: 20px 0px 20px;
      }
      .KnowledgeCentreDetails ol,
      .KnowledgeCentreDetails ul {
        margin: 0px;
        padding: 0px;
        padding-left: 20px;
      }
      .KnowledgeCentreDetails li {
        color: #545454;
        font-family: 'dinrg';
        font-size: 16px;
      }
      .KnowledgeCentreDetails h2 {
        position: relative;
        width: auto;
        font-family: 'DINNextRoundedLTW01';
        font-size: 62px;
        display: inline-block;
        padding: 0px 0px 30px;
        margin: 20px 0px 30px 0;
        background: -webkit-linear-gradient(left, #773996 10%, #ec1c24 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: #7b3891;
      }
      .KnowledgeCentreDetails h2::before {
        content: url(../../assets/images/underline.png);
        position: absolute;
        left: 0px;
        bottom: 0px;
        width: 80px;
      }
      .KnowledgeCentreDetails h1 {
        position: relative;
        width: auto;
        font-family: 'DINNextRoundedLTW01';
        font-size: 62px;
        display: inline-block;
        padding: 0px 0px 30px;
        margin: 20px 0px 30px 0;
        background: -webkit-linear-gradient(left, #773996 10%, #ec1c24 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: #7b3891;
      }
      .KnowledgeCentreDetails h1::before {
        content: url(../../assets/images/underline.png);
        position: absolute;
        left: 0px;
        bottom: 0px;
        width: 80px;
      }
      .k_d {
        color: #545454;
        font-family: 'dinrg';
        font-size: 16px;
      }
      .k_d ul,
      .k_d ol {
        margin-left: 20px !important;
        padding-left: 20px !important;
      }
      .k_d_img {
        display: inline-block;
        margin: 20px 30px 10px 0px;
        float: left;
      }
      .k_d table {
        width: 100%;
        clear: both;
      }
      .k_d table td {
        text-align: left;
        vertical-align: top;
      }

      table {
        width: 100%;
        clear: both;
      }
      td {
        text-align: left;
        vertical-align: top !important;
      }
      @media (max-width: 1200px) {
        .KnowledgeCentreDetails {
          padding: 120px 0px 40px;
        }
      }
      @media (max-width: 800px) {
        .KnowledgeCentreDetails {
          padding: 70px 0px 40px;
        }
        .KnowledgeCentreDetails h2 {
          font-size: 42px;
        }
      }
      @media (max-width: 480px) {
        .KnowledgeCentreDetails h2 {
          font-size: 32px;
        }
      }
    `,
  ],
})
export class KnowledgeCentreDetailComponent
  implements OnDestroy, OnInit, AfterViewInit
{
  public activePage: any;
  public logo = 'logo';
  public hidden = 'hidden';
  public links: any = [];
  private blog_id: string;
  public knowledge_detail: any = null;
  public knowledge_detail_title: string = null;
  public knowledge_detail_title_is_true = false;
  public knowledge_detail_image: string = null;
  public knowledge__title: string;
  public bread_crumb_links: any = [];
  /*public bread_crumb_links: any = [{ "label": "Home", "link": "/", "class": "" },
	{ "label": "Others", "link": "", "class": "" },
    { "label": "Knowledge Centre", "link": "/other/knowledge-centre", "class": "" },
    { "label": this.knowledge__title, "link": "", "class": "" }];
    */

  constructor(
    private api: ApiService,
    public metaTitle: Title,
    public meta: Meta,
    public route: ActivatedRoute
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
    }
  }
  ngOnInit() {
    this.route.params.subscribe((params) => {
      //console.log(params['knowledgeslug'])
      this.blog_id = params['knowledgeslug'];
    });
    this.api
      .getOtherSubFooterDetails()
      .map((res) => res)
      .subscribe((data) => {
        this.links = data;
      });

    this.api
      .getKnowledgetCentreDetail(this.blog_id)
      .map((res) => res[0])
      .subscribe((data) => {
        this.knowledge_detail = data;
        this.knowledge_detail_title = data.title;
        this.knowledge_detail_image =
          'http://backend.rainbowhospitals.in/' + data.image;
        this.knowledge__title = data.title;
        if (data.image != null) {
          this.knowledge_detail_title_is_true = true;
        }
        //console.log('KnowledgetCentreDetail:',data)
        //console.log(this.knowledge_detail_title);
        // setTimeout(function(){
        // $('span.long_description p').contents().unwrap();
        //}, 500);
        if (data) {
          if (data.meta_title != '') {
            this.metaTitle.setTitle(data.meta_title);
            this.meta.updateTag({
              name: 'keywords',
              content: data.meta_keyword,
            });
            this.meta.updateTag({
              name: 'description',
              content: data.meta_description,
            });
            this.meta.addTags([
              { property: 'og:title', content: data.meta_title },
              { property: 'og:description', content: data.meta_description },
              { property: 'og:keywords', content: data.meta_keyword },
            ]);
          } else {
            this.metaTitle.setTitle(data.title);
            this.meta.updateTag({ name: 'keywords', content: data.title });
            this.meta.updateTag({ name: 'description', content: data.title });
            this.meta.addTags([
              { property: 'og:title', content: data.title },
              { property: 'og:description', content: data.title },
              { property: 'og:keywords', content: data.title },
            ]);
          }
        }
        this.bread_crumb_links = [
          { label: 'Home', link: '/', class: '' },
          { label: 'Others', link: '', class: '' },
          {
            label: 'Knowledge Centre',
            link: '/other/knowledge-centre',
            class: '',
          },
          { label: this.knowledge_detail_title, link: '', class: '' },
        ];
      });
  }
  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
    }
  }
  ngOnDestroy() {}
}
