import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-cusp-success',
  templateUrl: './cusp-success.component.html',
  styleUrls: ['./cusp-success.component.scss'],
})
export class CuspSuccessComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}

  public tid: string = '';
  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      console.log(params); // { orderby: "price" }
      this.tid = params.tid;
      console.log(this.tid); // price
    });
  }
}
