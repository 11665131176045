<div class="container-fluid">
  <div class="loder"></div>
  <sidenav></sidenav>

  <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
  <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
  <section class="page wtwedo">
    <div class="">
      <div class="col-md-5 col-sm-5 col-lg-4 col-xs-5">
        <div class="circuleframe">
          <img
            src="{{ imgBasePath }}{{ imgsrc }}"
            class="img-responsive"
            alt="{{ title }}"
            title="{{ title }}"
          />
        </div>
        <!-- End Circle -->
      </div>
      <div class="col-md-7 col-sm-7 col-lg-7 col-xs-7">
        <div class="pagetitle">
          <h1 class="textgradient">{{ title }}</h1>
          <div class="underline">
            <img src="assets/images/underline.png" alt="" />
          </div>
          <h3>{{ subtitle }}</h3>
        </div>
      </div>
      <div class="col-md-7 col-xs-7 col-lg-8 mcontentview">
        <p>
          Use the tools below to search for a doctor by name, specialty, gender,
          service line or practice location.
        </p>
        <div class="dfindwrp">
          <form #f="ngForm">
            <ul class="hlfcube">
              <li>
                <div class="specialtywrp" *ngIf="currentSection == 'all'">
                  <select
                    class="select"
                    name="specilityMaster"
                    [(ngModel)]="specilityMaster"
                    (change)="selectSpecMaster()"
                  >
                    <option value="'0'" [selected]="specilityMaster == '0'">
                      Select Specialty
                    </option>
                    <!-- <option *ngFor="let specMaster of specilityMasterList;" value="{{specMaster.slug}}">{{specMaster.name}}</option> -->
                    <option value="child-care">Child Care</option>
                    <option value="women-care">Women Health</option>
                    <option value="fertility-care">Fertility Care</option>
                  </select>
                  <span id="SelectSpecialty " class="select-span"></span>
                </div>

                <div class="locationwrp">
                  <select
                    class="select"
                    name="location"
                    [(ngModel)]="centrelocation"
                    (change)="locationChange()"
                  >
                    <option value="0" [selected]="centrelocation == 0">
                      Select location
                    </option>
                    <ng-template
                      ngFor
                      let-c
                      [ngForOf]="centerList"
                      let-i="index"
                    >
                      <optgroup *ngIf="c.area.length > 0" label="{{ c.city }}">
                        <option
                          *ngFor="let branch of c.area"
                          [value]="branch.id"
                        >
                          {{ branch.center_name_alias }}
                        </option>
                      </optgroup>
                    </ng-template>
                    <!-- <option  *ngFor="let center of centerList;" [value]="center.centres_id">{{center.center_name_alias}}</option> -->
                  </select>
                  <span id="selectlocation" class="select-span"></span>
                </div>

                <div class="specialtywrp">
                  <select
                    class="select"
                    name="specialty"
                    [(ngModel)]="specialty"
                  >
                    <option value="0" [selected]="specialty == 0">
                      Select Sub Specialty
                    </option>
                    <option *ngFor="let spec of specList" [value]="spec.id">
                      {{ spec.title }}
                    </option>
                  </select>
                  <span id="SelectSpecialty " class="select-span"></span>
                </div>

                <div class="searchbtn">
                  <input
                    type="button"
                    name="txtSearch"
                    value="Search"
                    (click)="submitfilter(f)"
                  />
                </div>
              </li>
              <li>
                <div class="finddwrp">
                  <!-- <div class="findbox">
										<input type="text" name="searchDoctor" placeholder="Doctor’s Name"  [(ngModel)]="searchDoctor" value="Find a Doctor by name" />
									</div> -->
                  <div class="findbox searchDD">
                    <input
                      type="text"
                      name="txtfinddoctors"
                      (keyup)="getDoctorname($event)"
                      [(ngModel)]="searchDoctor"
                      placeholder="Doctor’s Name"
                    />
                    <ul
                      [ngClass]="{
                        'active maxhight': finddoctorclass,
                        inactive: !finddoctorclass
                      }"
                    >
                      <li
                        *ngFor="let doctor of doctorSearchResult; let i = index"
                      >
                        <a
                          href="javascript:void(0);"
                          (click)="selectDoctor(doctor.id)"
                          >{{ doctor.name }}</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="gobtn">
                  <input
                    type="button"
                    name="txtSearch"
                    value="GO"
                    (click)="searchDoctorSection()"
                  />
                </div>
              </li>
            </ul>
          </form>
        </div>
      </div>
    </div>
  </section>
  <section
    id="doctorfilter"
    class="childdoctor-result"
    *ngIf="filteredSpecDoctor"
  >
    <div class="container">
      <ul myMatchHeight="doctorsListwrp">
        <li
          #doctors
          class="col-xs-12 col-sm-6 col-md-4"
          *ngFor="let doctor of filteredSpecDoctor | search: searchDoctor"
        >
          <div class="doctorsListwrp">
            <div class="dthumbwrp">
              <img src="{{ imgBasePath }}{{ doctor.photo }}" alt="" />
            </div>
            <div class="ddetailswrp">
              <h3>{{ doctor.name }}</h3>
              <p>{{ doctor.qualification }}, {{ doctor.designation }}</p>
            </div>
            <div class="dunderline">
              <img src="assets/images/underline.png" alt="" />
            </div>
            <div class="dselectlwrp">
              <!-- <div class="locationwrp">
								<div class="selectbox">
									<select class="select" name="txtDLocation">
										<option value="0" selected>Select location </option>
										<option *ngFor="let center of doctor.centres" [attr.selected]="center.id == centrelocation ?true : null " value="{{center.id}}">{{center.center_name_alias}}</option>
									</select>
									<span id="selectDlocation" class="select-span"></span> 
								</div>
							</div>
							<p>Rainbow Children’s Hospital</p> -->
              <h3>Consultant</h3>
            </div>

            <a
              href="/all/doctor/visiting-consultant/{{ doctor.slug }}"
              class="knowmore2 active"
              ><span>Know More</span></a
            >

            <a
              href="./{{
                currentSection
              }}/visiting-consultant/book-an-appointment/{{ doctor.slug }}"
              class="btn-Bookapopoi"
              ><span>Book an appointment</span></a
            >
          </div>
        </li>
      </ul>
    </div>
  </section>
  <section class="childdoctor-result" *ngIf="noDoctor">
    <div class="container">
      <h1 class="textgradient">
        No Visiting Consultant doctor available for the selected speciality in
        this centre. Though you can book an appointment for our specialist
        doctor by clicking on the Book An Appointment button below
      </h1>
    </div>
  </section>
  <find-a-doctors-nav></find-a-doctors-nav>
  <ng-template [ngIf]="links.length > 0">
    <footer-nav-mini
      [links]="links"
      [activePage]="activeSubPage"
    ></footer-nav-mini>
  </ng-template>
  <footer-subscription></footer-subscription>
  <footer class="footerNavwrp">
    <footer-nav></footer-nav>
  </footer>
  <footer-copyright-nav></footer-copyright-nav>
</div>
