<div class="container-fluid">
    <div class="background">
        <img src="assets/images/bg_childcare.png" data-t="bg_01_00.jpg" data-d="bg_childcare.png" class="bgImg bg0 active" />
    </div>
    <div class="loder"></div>
    <sidenav></sidenav>
    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <section class="page pageheader">
        <div class="row ">
            <div class="container">
                <div class="col-xs-12 col-sm-7 col-lg-7 abannimgwrp">
                    <div class="aboutbannimg"><img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" /></div>
                </div>
                <div class="col-xs-12 col-sm-5 col-lg-5">
                    <div class="ptitle">
                        <h1>{{title}}</h1>
                        <div class="clearfix"></div>
                        <h3>{{subtitle}}</h3>
                    </div>
                </div>
                <div class="col-xs-12">
                    <p [innerHTML]="description"></p>
                </div>
            </div>
        </div>
    </section>
    <section class="page history-wapper">
        <div class="container">
            <!-- <ul>
				<li *ngFor="let h of history; let index= index">{{h.year}}</li>
			</ul> -->
            <div class="col-xs-12">
                <div class="htimeline htimeline-style-8 frst-date-opposite frst-alternate">

                    <ng-template ngFor let-years [ngForOf]="historyList">
                        <div class="htimeline-block htimeline-label-block" data-animation="slideInUp">
                            <div class="frst-labels frst-start-label">
                                <span class="year">{{years.year}}</span>
                            </div>
                        </div>
                        <div *ngFor="let h of years.history; let i=index;  let odd=odd; let even=even;" class="htimeline-block newsblock" data-animation="slideInUp">
                            <div class="htimeline-img"> <span> </span> </div>
                            <div class="htimeline-content">
                                <div class="htimeline-content-inner">
                                    <div class="media-section" *ngIf="h.image"> <img src="{{imgBasePath}}{{h.image}}" alt="history"> </div>
                                    <h2>{{h.title}}</h2>
                                    <span class="frst-date">{{h.history_date|date:'MMMM'}}</span>
                                    <div class="htimelineContent">
                                        <p>{{h.description | dgTruncate :{limit: 30, words: true} }}</p>
                                    </div>
                                    <div class="ReadMoreh">
                                        <a class="readMore fancybox" href="#box{{h.id}}">Read More</a>
                                    </div>
                                </div>
                            </div>
                            <div class="timelineModelBox" id="box{{h.id}}">
                                <div class="htimeline-content-inner">
                                    <span class="frst-date">{{h.history_date|date:'MMMM'}}</span>
                                    <div class="media-section"> <img src="{{imgBasePath}}{{h.image}}" alt="history"> </div>
                                    <h2>{{h.title}}</h2>
                                    <div class="htimelineContent">
                                        <p [innerHTML]="h.description"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>

        </div>
    </section>
    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>

    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>