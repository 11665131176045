import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL } from '../services/constants';
import { Meta, Title } from '@angular/platform-browser';
// import * as elasticlunr from 'elasticlunr';
declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
declare let initAboutTabs: any;
declare let elasticlunr: any;
@Component({
  selector: 'search',
  templateUrl: './search.component.html',
})
export class SearchComponent implements OnDestroy, OnInit, AfterViewInit {
  constructor(
    private api: ApiService,
    public metaTitle: Title,
    public meta: Meta,
    private route: ActivatedRoute,
    private router: Router
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
      require('../../assets/js/elasticlunr.js');
    }
  }

  public services: any[] = [];
  public brief_facilities: any[] = [];
  public title: any = '';
  public subtitle: any = '';
  public description: any = '';
  public imgsrc: any = '';
  public imgBasePath = '';
  public hidden = 'hidden';
  public logo = 'logo';
  public childSpec: any = [];
  public womenSpec: any = [];
  public fertilitySpec: any = [];
  public bread_crumb_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Search', link: '', class: '' },
  ];

  public links: any = [
    { label: 'Faqs', link: '/other/faqs', class: '' },
    {
      label: 'Insurance & Empanelment',
      link: '/other/insurance-empanelment',
      class: '',
    },
    {
      label: 'Terms and Conditions',
      link: '/other/terms-conditions',
      class: 'active',
    },
    { label: 'Academic Programs', link: '/other/academic-programs', class: '' },
    {
      label: 'Inpatient Feedback',
      link: '/other/inpatient-feedback',
      class: '',
    },
    { label: 'Quiz', link: '/other/quiz', class: '' },
    {
      label: 'Packages/ Plans',
      link: '/other/packages-plans',
      class: 'active',
    },
    {
      label: 'International Patients',
      link: '/other/international-patients',
      class: '',
    },
    { label: 'App', link: '/other/app', class: '' },
  ];
  public searchList: any;
  public filteredList: any = [];
  public index: any;
  public store: any;
  public sub: any;
  public pageData: any;
  public searchParam: string;
  @ViewChildren('terms-conditions') things: QueryList<any>;

  ngOnInit() {
    this.api.getPageDescription('search').subscribe(
      (data) => {
        this.pageData = data.page_descriptions[0];
        // this.subtitle = data.page_descriptions[0].subtitle || 'adf',
        // this.description = data.page_descriptions[0].description,
        // this.imgsrc = data.page_descriptions[0].image,
        this.imgBasePath = IMG_BASE_URL;
        if (data) {
          //console.log(data);
          this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
          this.meta.updateTag({
            name: 'keywords',
            content: data.page_descriptions[0].meta_keyword,
          });
          this.meta.updateTag({
            name: 'description',
            content: data.page_descriptions[0].meta_description,
          });
          this.meta.addTags([
            {
              property: 'og:title',
              content: data.page_descriptions[0].meta_title,
            },
            {
              property: 'og:description',
              content: data.page_descriptions[0].meta_description,
            },
            {
              property: 'og:keywords',
              content: data.page_descriptions[0].meta_keyword,
            },
          ]);
        }
      },
      (error) => {
        console.log(error);
      }
    );

    this.api
      .getSearchList()
      .map((res) => res)
      .subscribe((res) => {
        //console.log(res);
        this.searchList = res;
        this.store = res.store;
      });
    setTimeout(() => {
      this.sub = this.route.queryParams.subscribe((params) => {
        //console.log(params['q']);
        if (params['q']) {
          this.searchParam = params['q'];
          this.getSearch();
        }
      });
    }, 2000);
  }
  getSearch() {
    //console.log('index', this.index);
    //console.log('search List', this.searchList.index);
    this.index = elasticlunr.Index.load(this.searchList.index);
    if (this.index == 'undefined') {
      setTimeout(() => {
        this.getSearch();
      }, 1000);
    }
    if (this.index) {
      this.filteredList = this.index.search(this.searchParam, {
        fields: {
          title: { boost: 2 },
          body: { boost: 1 },
        },
        boolean: 'OR',
      });
      //console.log(this.index.search(val));
      //console.log('search List', this.filteredList);
      for (var item in this.filteredList) {
        var ref = this.filteredList[item].ref;
        let info = {
          title: this.store[ref].title,
          href: ref,
          body: this.store[ref].body,
        };
        this.filteredList.push(info);
      }
    }
  }
  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
      //$('.loder').fadeOut(200);
      //initAllPage();
    }

    // this.things.changes.subscribe(t => {
    //   // this.ngForRendred();
    //   initAboutTabs();
    // })
  }

  ngOnDestroy() {
    //initHome();
  }
}
