import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL } from '../services/constants';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
// declare let initAboutTabs:any;

@Component({
  selector: 'events-details',
  templateUrl: './events-details.component.html',
})
export class EventsDetailsComponent
  implements OnDestroy, OnInit, AfterViewInit
{
  constructor(
    private api: ApiService,
    private activatedRoute: ActivatedRoute,
    public metaTitle: Title,
    public meta: Meta,
    private _sanitizer: DomSanitizer
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
    }
  }

  public bread_crumb_title: any;
  public bread_crumb_title_length: any;
  public safeURL: any;
  public videoUrl: boolean = false;
  public eventDetail: any = [];
  public slug: string;
  public title: any = '';
  public subtitle: any = '';
  public description: any = '';
  public imgsrc: any = '';
  public brief_facilities_description: any = '';
  public brief_facilities_image: any = '';
  public imgBasePath = '';
  public hidden = 'hidden';
  public logo = 'logo';
  public bread_crumb_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'About Us', link: '/about-us', class: '' },
    { label: 'Events', link: '/about-us/events', class: '' },
  ];

  public links: any = [];
  public activePage: string = 'events';

  public id: any = '';
  // @ViewChildren('abouttabs') things: QueryList<any>;

  ngOnInit() {
    this.api
      .getAboutSubFooterDetails()
      .map((res) => res)
      .subscribe((data) => {
        this.links = data;
      });
    this.activatedRoute.params.subscribe((params: Params) => {
      this.id = params['id'];
    });

    this.api.getNewsEventsById(this.id).subscribe(
      (data) => {
        this.eventDetail = data.home_events[0];
        this.bread_crumb_title = this.eventDetail.name;
        this.bread_crumb_title_length = this.bread_crumb_title.length;
        console.log(this.eventDetail.video);
        if (this.eventDetail.video !== null && this.eventDetail.video !== '') {
          console.log('if', this.eventDetail.video);
          this.videoUrl = true;
        }
        this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(
          this.eventDetail.video
        );
        this.slug = this.eventDetail.slug;
        if (data) {
          //console.log(data);
          this.metaTitle.setTitle(this.eventDetail.meta_title);
          this.meta.updateTag({
            name: 'keywords',
            content: this.eventDetail.meta_keyword,
          });
          this.meta.updateTag({
            name: 'description',
            content: this.eventDetail.meta_description,
          });
          this.meta.addTags([
            { property: 'og:title', content: this.eventDetail.meta_title },
            {
              property: 'og:description',
              content: this.eventDetail.meta_description,
            },
            { property: 'og:keywords', content: this.eventDetail.meta_keyword },
          ]);
        }

        this.api
          .getPageDescription(this.slug)
          .map((res) => res)
          .subscribe(
            (data) => {
              if (JSON.stringify(data).length > 100) {
                this.getDescription(data);
              } else {
                this.slug = 'events-details';
                this.api.getPageDescription(this.slug).subscribe((data) => {
                  //console.log('error=>>',data)
                  this.getDescription(data);
                });
              }
            },
            (err) => {
              console.log('err');
            }
          );
        //console.log(this.eventDetail);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  public getDescription(data) {
    // console.log(data);
    (this.title = data.page_descriptions[0].title),
      (this.subtitle = data.page_descriptions[0].subtitle),
      (this.description = data.page_descriptions[0].description),
      (this.imgsrc = data.page_descriptions[0].image),
      (this.imgBasePath = IMG_BASE_URL);
  }
  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
    }

    // this.things.changes.subscribe(t => {
    //   // this.ngForRendred();
    //   initAboutTabs();
    // })
  }

  ngOnDestroy() {}
}
