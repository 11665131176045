// import { compose } from '@ngrx/core/compose';
import { Action, ActionReducer, combineReducers } from '@ngrx/store';
import { storeFreeze } from 'ngrx-store-freeze';
// import { storeLogger } from 'ngrx-store-logger';
import { routerReducer, RouterState } from '@ngrx/router-store';
import { compose } from '@ngrx/store';

import * as fromUser from '../user/user.reducer';
interface ExtendedAction extends Action {
  payload: any;
}

const modules = {
  user: fromUser,
};

export interface AppState {
  router: RouterState;
  user: fromUser.UserState;
}

export const reducers = {
  router: routerReducer,
  user: fromUser.userReducer,
};

// Generate a reducer to set the root state in dev mode for HMR
function stateSetter(
  reducer: ActionReducer<any, ExtendedAction>
): ActionReducer<any, ExtendedAction> {
  return function (state, action) {
    if (action.type === 'SET_ROOT_STATE') {
      return action.payload;
    }
    return reducer(state, action);
  };
}

const resetOnLogout = (reducer: Function) => {
  return function (state, action) {
    let newState;
    if (action.type === '[User] Logout Success') {
      newState = Object.assign({}, state);
      Object.keys(modules).forEach((key) => {
        newState[key] = modules[key]['initialState'];
      });
    }
    return reducer(newState || state, action);
  };
};

const DEV_REDUCERS = [stateSetter, storeFreeze];
// set in constants.js file of project root
// if (['logger', 'both'].indexOf(STORE_DEV_TOOLS) !== -1) {
//   // DEV_REDUCERS.push(storeLogger());
// }

const developmentReducer = compose(
  ...DEV_REDUCERS,
  resetOnLogout,
  combineReducers
)(reducers);
const productionReducer = compose(resetOnLogout, combineReducers)(reducers);

export function rootReducer(state: any, action: any) {
  if (ENV !== 'development') {
    return productionReducer(state, action);
  } else {
    return developmentReducer(state, action);
  }
}
