import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
} from '@angular/core';
// import { Http } from '@angular/http';
import { ApiService } from '../../services/api.service';
import { IMG_BASE_URL } from '../../services/constants';
import { Meta, Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
declare let initAboutTabs: any;

@Component({
  selector: 'pregnancy',
  templateUrl: './pregnancy.component.html',
  styles: [
    `
      .news_text {
        margin: 0px 0px;
      }
      .news_text h2 {
        width: 100%;
        color: #5f2160;
        font-weight: 600;
        font-family: 'dinrg';
        font-size: 24px;
        margin: 0px 0px 20px;
        position: relative;
        padding: 0px 0px 20px;
      }
      .news_text h2::before {
        content: url(../../../assets/images/underline3.png);
        position: absolute;
        left: 0px;
        bottom: 0px;
        width: 77px;
      }
      .btnStyleGradient {
        min-height: 42px;
        padding-left: 45px;
        padding-top: 10px;
      }
      .btnStyleGradient i {
        width: 36px;
        height: 36px;
        // background: #fff url(../../../assets/images/icon-PfdBtn.png) center center no-repeat;
        position: absolute;
        left: 4px;
        top: 3px;
        border-radius: 50%;
      }
      .mediaSection .PressReleases .PressReleasesBox {
        padding-left: 0px;
        position: relative;
        margin-bottom: 30px;
      }
      .mediaSection .PressReleases .PressReleasesBox.paddrt {
        padding-right: 30px;
      }
      @media (max-width: 400px) {
        .mediaSection .PressReleases .PressReleasesBox h4 {
          padding-left: 0px;
          min-height: 0px;
        }
      }
    `,
  ],
})
export class PregnancyComponent implements OnDestroy, OnInit, AfterViewInit {
  constructor(
    private api: ApiService,
    private http: HttpClient,
    public metaTitle: Title,
    public meta: Meta
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../../assets/js/bootstrap.js');
      require('../../../assets/js/slick.min.js');
    }
  }

  public services: any[] = [];
  public brief_facilities: any[] = [];
  public title: any = '';
  public subtitle: any = '';
  public description: any = '';
  public imgsrc: any = '';
  public imgBasePath = '';
  public hidden = 'hidden';
  public logo = 'logo';
  public bread_crumb_links: any = [
    { label: 'Home', link: '/', class: '', slug: 'home' },
    { label: 'Insights', link: '/insights', class: '', slug: 'insights' },
    {
      label: 'Pregnancy',
      link: '/insights/pregnancy',
      class: '',
      slug: 'pregnancy',
    },
  ];

  public ins_links: any = [];
  public activePage: string = 'pregnancy';

  //@ViewChildren('atabs') atabsThings: QueryList<any>;

  ngOnInit() {
    this.api.getPageDescription('insights_pregnancy').subscribe(
      (data) => {
        (this.title = data.page_descriptions[0].title),
          (this.subtitle = data.page_descriptions[0].subtitle),
          (this.description = data.page_descriptions[0].description),
          (this.imgsrc = data.page_descriptions[0].image),
          (this.imgBasePath = IMG_BASE_URL);
        if (data) {
          //console.log(data);
          this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
          this.meta.updateTag({
            name: 'keywords',
            content: data.page_descriptions[0].meta_keyword,
          });
          this.meta.updateTag({
            name: 'description',
            content: data.page_descriptions[0].meta_description,
          });
          this.meta.addTags([
            {
              property: 'og:title',
              content: data.page_descriptions[0].meta_title,
            },
            {
              property: 'og:description',
              content: data.page_descriptions[0].meta_description,
            },
            {
              property: 'og:keywords',
              content: data.page_descriptions[0].meta_keyword,
            },
          ]);
        }
      },
      (error) => {
        console.log(error);
      }
    );
    this.http
      .get('../../../assets/json/pregnancy_sub_nav.json')
      .map((res) => res)
      .subscribe((data) => {
        this.ins_links = data;
      });
  }

  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
      //initAboutTabs();
    }
  }

  ngOnDestroy() {}
}
