<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>

    <section class="page wtwedo">
        <div class="row">
            <div class="col-md-5 col-xs-5 col-lg-4">
                <div class="circuleframe">
                    <img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" />
                </div>
                <!-- End Circle -->
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7">
                <div class="pagetitle">
                    <h1 class="tgradient">{{title}} </h1>
                    <div class="underline">
                        <img src="assets/images/underline2.png" alt="" />
                    </div>
                    <h3>{{subtitle}}</h3>
                </div>
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7 mcontentview">
                <p [innerHTML]="description"></p>
            </div>
        </div>

    </section>
    <section class="aboutsectin2 awardswapper">
        <div class="container">
            <div class="row nsg_text">
                <div class="col-xs-12 col-lg-12">
                    <h2>Types of Multiple Pregnancy</h2>
                    <p><strong>M</strong><strong>onochorionic or MC twins: </strong>Better known as identical twins, these twins develop from one fertilized egg.</p>
                    <p><strong>Dichorionic or DC twins: </strong>These come from two fertilized eggs and are the more common form of twins. Non-identical twins are more common in women over 35 and those having fertility treatment.</p>
                    <p><strong>Higher order pregnancies </strong>(<strong>triplet or more</strong>): These happen when one, two or more fertilised eggs develop into three or more babies. They can all be identical or a mixture of non identical and identical
                        babies</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>What to expect when you are expecting twins?</h2>
                    <p>Women who are expecting twins are likely to face problems such as fatigue, backache, morning sickness, heartburn, swelling in the feet and varicose veins in a higher degree. <br> Apart from the above mentioned discomfort, women expecting
                        twins also face higher risks of the following:</p>
                    <ul>
                        <li>Premature labour (before 37 weeks)</li>
                        <li>Vaginal bleeding</li>
                        <li>High blood pressure</li>
                        <li>Diabetes in pregnancy</li>
                        <li>Anemia</li>
                        <li>One or both babies being small</li>
                        <li>Babies being born with Down’s Syndrome</li>
                    </ul>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>What should you do to take care of yourself when you are expecting twins?</h2>
                    <ul type="disc">
                        <li>Take adequate rest and do exercises to strengthen your pelvic floor.</li>
                        <li>Women expecting twins need more minerals and vitamins or they may become anemic. Remember to take iron and folate tablets throughout your pregnancy.</li>
                        <li>Visit your doctor for routine checks to determine your babies’ and your general well being.</li>
                        <li>Get regular blood tests to check for anemia and scans to check your babies’ growth.</li>
                        <li>Check with your gynecologist for a detailed between 18 and 22 weeks to check the physical structures of the babies.</li>
                    </ul>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>When would you deliver?</h2>
                    <p>It may be possible to have a vaginal delivery, but twins are twice as likely to need either forceps or a C-section. Your obstetrician will advise the best method of delivery of your twins.</p>
                    <p>Women with uncomplicated monochorionic twin pregnancies should be offered elective birth from 37 weeks after a course of medication suggested by your doctor.</p>
                    <p>Women with dichorionic twin pregnancies should be offered elective birth from 38 weeks.</p>
                    <ul type="disc">
                        <li>Women with triplet pregnancies should be offered elective birth from 35 weeks 0 days, after a course of medication suggested by your doctor.</li>
                    </ul>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>What could be the possible complications?</h2>
                    <p>There could be complications such as twin-to-twin transfusion syndrome (TTTS) with monochorionic twins, who share the same placenta and have connections between their blood circulation systems. Problems may arise when blood flows from
                        one twin to the other, causing one twin to grow bigger than the other, who has less blood flow. This can also result in the larger twin having a large bladder and producing more urine, and this in turn causes an imbalance in the
                        amount of amniotic fluid surrounding the twins. If this condition is prolonged, the smaller twin produces less urine and has less fluid within its sac causing it to become closely wrapped by its sac. This can lead to problems for
                        both the babies, depending on how rapidly it develops and at what stage of pregnancy it happens. Therefore, it is very important to regularly to check on the babies’ growth and to measure the fluid around each baby.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>Taking Care after Delivery</h2>
                    <ul type="disc">
                        <li>After delivery twins or triplets, you need more help. You need to have a solid support system to manage your babies, be it family, friends or any other help.</li>
                        <li>Postpartum depression is very common in mothers with multiple births, so talk to your doctor soon if you notice yourself feeling grumpy, depressed or low.</li>
                        <li>Take medication and supplements as advised by your doctor.</li>
                        <li>Get plenty of rest immediately after your delivery. Start increasing the quantum of tasks you manage slowly.</li>
                        <li>Talk to a doctor and the dietician if you are breastfeeding your twins to help you design a balanced diet plan.</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <ng-template [ngIf]="ins_links.length > 0">
        <footer-nav-mini [links]="ins_links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>