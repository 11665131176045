<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <section class="page wtwedo">
        <div class="row">
            <div class="col-md-5 col-xs-5 col-lg-4">
                <div class="circuleframe">
                    <img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" />
                </div>
                <!-- End Circle -->
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7">
                <div class="pagetitle">
                    <h1 class="tgradient">{{title}}</h1>
                    <div class="underline">
                        <img src="assets/images/underline2.png" alt="" />
                    </div>
                    <h3>{{subtitle}}</h3>
                </div>
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7 mcontentview">
                <p [innerHTML]="description"></p>
            </div>
        </div>
    </section>
    <section class="ddetailstimet ourssbg">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-xs-12 col-lg-12">
                    <ul class="osatabs slider">
                        <li class="active">
                            <a href="javascript:void()" data-id="data1">
                                <div class="aiconwrp"><img src="assets/images/icon-child-care1.png" alt="" /></div>
                                <h2>Child Care</h2>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void()" data-id="data2">
                                <div class="aiconwrp"><img src="assets/images/icon-child-care2.png" alt="" /></div>
                                <h2>Women Care</h2>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void()" data-id="data3">
                                <div class="aiconwrp"><img src="assets/images/icon-child-care3.png" alt="" /></div>
                                <h2>Fertility Care</h2>
                            </a>
                        </li>
                    </ul>
                    <ul #allTheseThings class="ourScs slider">
                        <li>
                            <div class="ourscsmain">
                                <div class="sitem" *ngFor="let Item of cItems">
                                    <div class="text" *ngFor="let itm of Item">
                                        <div class="osicons"><img src="{{imgBasePath}}{{itm.image}}" alt="" /></div>
                                        <h3 class="textgradient">{{itm.title}}</h3>
                                        <p [innerHTML]="itm.description | dgTruncate :{limit: 10, words: true} "></p>
                                        <a href="child-care/speciality/{{itm.slug}}" class="knowmore"><span>Read More</span></a>
                                    </div>
                                </div>
                            </div>
                            <div class="viewlllinkwrp">
                                <a href="child-care/specialities">View All</a>
                            </div>
                        </li>

                        <li>
                            <div class="ourscsmain">
                                <div class="sitem" *ngFor="let Item of wItems">
                                    <div class="text" *ngFor="let itm of Item">
                                        <div class="osicons"><img src="{{imgBasePath}}{{itm.image}}" alt="" /></div>
                                        <h3 class="textgradient">{{itm.title}}</h3>
                                        <p [innerHTML]="itm.description | dgTruncate :{limit: 10, words: true} "></p>
                                        <a href="women-care/speciality/{{itm.slug}}" class="knowmore"><span>Read More</span></a>
                                    </div>
                                </div>
                            </div>
                            <div class="viewlllinkwrp">
                                <a href="women-care/specialities">View All</a>
                            </div>
                        </li>

                        <li>
                            <div class="ourscsmain">
                                <div class="sitem" *ngFor="let Item of fItems">
                                    <div class="text" *ngFor="let itm of Item">
                                        <div class="osicons"><img src="{{imgBasePath}}{{itm.image}}" alt="" /></div>
                                        <h3 class="textgradient">{{itm.title}}</h3>
                                        <p [innerHTML]="itm.description | dgTruncate :{limit: 10, words: true} "></p>
                                        <a href="fertility-care/speciality/{{itm.slug}}" class="knowmore"><span>Read More</span></a>
                                    </div>
                                </div>
                            </div>
                            <div class="viewlllinkwrp">
                                <a href="fertility-care/treatments">View All</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <find-a-doctors-nav *ngIf="isShow"></find-a-doctors-nav>
    <!-- <footer-nav-mini [links]="links"></footer-nav-mini> -->
    <!--<div class="footerstikynav active">
        <div class="container">
            <div class="btnwrpappointment">
                <ul>
                    <li>
                        <a href="/all/doctors"><img src="assets/images/icon-findadoctor.png" alt="" /> Find a doctor</a>
                    </li>
                    <li>
                        <a href="/all/book-an-appointment"><img src="assets/images/icon-appointment.png" alt="" />Book an appointment</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>-->

    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>