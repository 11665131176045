import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
} from '@angular/forms';

import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { Subscription, interval } from 'rxjs';
import { animate } from '@angular/animations';
import * as crypto from 'crypto';
import { v4 as uuidv4 } from 'uuid';
import { sha512 } from 'js-sha512';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';

declare let $: any;
declare let initHome: any;
declare let initGoogleMap: any;
declare let initAllPage: any;
const PAY_U_DOM_KEY: string = 'sCpJTG';
const PAY_U_INTER_KEY: string = '2HCMCC';
const PAY_U_SALT = { sCpJTG: '6sq9wMS0', '2HCMCC': 'lmTXyEKT' };

function popupResize(thisO) {
  var height = thisO.height() - (thisO.find('.headText').height() + 30);
  //console.log(height, thisO.find(".popupText").height(), thisO.find(".headText").height(), thisO.height())
  thisO.find('.popupText').height(height);
}

@Component({
  selector: 'app-conference-on-special-pediatrics',
  templateUrl: './conference-on-special-pediatrics.component.html',
  styleUrls: ['./conference-on-special-pediatrics.component.scss'],
})
export class ConferenceOnSpecialPediatricsComponent implements OnInit {
  constructor(
    private api: ApiService,
    public formBuilder: FormBuilder,
    public metaTitle: Title,
    public meta: Meta,
    private sanitization: DomSanitizer,
    private http: HttpClient
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;

      require('../../assets/js/bootstrap.js');
      require('../../assets/js/fancybox/jquery.fancybox.min.js');

      require('../../assets/js/slick.min.js');
    }
  }
  public logo: string = 'logo';
  public hidden: string = 'hidden';
  public formdata: FormGroup;

  public branches = {
    Hyderabad: [
      'Banjara Hills',
      'Secunderabad',
      'Kondapur IP',
      'Kondapur OP',
      'Hydernagar',
      'LB Nagar',
      'RCHI Hyderabad',
    ],
    Bangalore: ['Marathahalli', 'Bannerghatta Road', 'Hebbal'],
    Delhi: ['Malviya Nagar'],
    Chennai: ['Guindy'],
    Vijaywada: ['Currency Nagar', 'Governorpet'],
    Vizag: ['Health City', 'Visakhapatnam - Waltair Uplands'],
  };
  public cities: Array<string> = [];
  public enrolledWorkshops: Array<string> = [];
  public isPending: boolean = false;

  // ---------
  private subscription: Subscription;

  public dateNow = new Date();
  public dDay = new Date('Jul 18 2022 00:00:00');
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute = 60;

  public timeDifference;
  public secondsToDday;
  public minutesToDday;
  public hoursToDday;
  public daysToDday;
  public firstName = '';
  public lastName = '';
  public hashString = '';
  public amountTobePaid: number = 0;
  public showPopUp: boolean = false;
  public isShowDocpop: boolean = false;

  private getTimeDifference() {
    this.timeDifference = this.dDay.getTime() - new Date().getTime();
    this.allocateTimeUnits(this.timeDifference);
  }

  private allocateTimeUnits(timeDifference) {
    this.secondsToDday = Math.floor(
      (timeDifference / this.milliSecondsInASecond) % this.SecondsInAMinute
    );
    this.minutesToDday = Math.floor(
      (timeDifference / (this.milliSecondsInASecond * this.minutesInAnHour)) %
        this.SecondsInAMinute
    );
    this.hoursToDday = Math.floor(
      (timeDifference /
        (this.milliSecondsInASecond *
          this.minutesInAnHour *
          this.SecondsInAMinute)) %
        this.hoursInADay
    );
    this.daysToDday = Math.floor(
      timeDifference /
        (this.milliSecondsInASecond *
          this.minutesInAnHour *
          this.SecondsInAMinute *
          this.hoursInADay)
    );
  }
  // ---------
  transaction_id() {
    return crypto.randomBytes(18).toString('hex');
  }

  ngOnInit(): void {
    this.formdata = new FormGroup({
      Patient: new FormControl('', [Validators.required]),
      Mobile: new FormControl('', [Validators.required]),
      Email: new FormControl('', [Validators.required]),
      Institution: new FormControl('', [Validators.required]),
      Occupation: new FormControl('', [Validators.required]),
      Country: new FormControl('India', [Validators.required]),
      City: new FormControl('', [Validators.required]),
      key: new FormControl('sCpJTG', []),
      txnid: new FormControl('', []),
      amount: new FormControl('1', []),
      productinfo: new FormControl('iphone', []),
      hash: new FormControl('', []),
      MedicalCouncilNumber: new FormControl('', [Validators.required]),
    });

    this.onWorkshopChange();
    this.metaTitle.setTitle('NON-INVASIVE NEONATAL VENTILATION WORKSHOP');
    this.subscription = interval(1000).subscribe((x) => {
      this.getTimeDifference();
    });
    this.formdata.get('Country').valueChanges.subscribe((val) => {
      // alert(val);
      // console.log({ val });
      if (val === 'India') {
        return this.formdata.patchValue({ key: PAY_U_DOM_KEY });
      }
      this.formdata.patchValue({ key: PAY_U_INTER_KEY });
    });

    this.formdata.valueChanges.subscribe((val) => {
      let {
        key,
        City,
        Country,
        Email,
        Institution,
        Mobile,
        Occupation,
        Patient,
        txnid,
        amount,
        productinfo,
        MedicalCouncilNumber,
      } = val;
      // console.log({ val });
      alert(Patient);
      console.log({ Patient });
      let name = Patient;
      let firstName = name;
      this.firstName = name;
      this.lastName = '';
      // this.lastName = name.join(' ');
      // console.log(firstName, this.lastName);
      // console.log({ salt: PAY_U_SALT[key] });
      let hashString = `${key}|${txnid}|${this.amountTobePaid}|${productinfo}|${firstName}|${Email}|${MedicalCouncilNumber}|${Occupation}|${Institution}|${City}|${Country}||||||${PAY_U_SALT[key]}`;
      console.log(hashString);
      this.hashString = sha512(hashString);
      // console.log({ hash: sha512(hashString) });

      // this.formdata.patchValue({ hash: sha512(hashString) });
    });

    // let txnid = transaction_id();
    $(function () {
      $('input[data-group="np-group"]').on('click', function () {
        // alert($(this).attr('data-name'));
        if ($(this).prop('checked')) {
          $(`input[data-name="${$(this).attr('data-filter')}"]`).prop(
            'checked',
            false
          ); // Unchecks it
          // $(this).prop('checked', true);
          // $(`input[data-name="${$(this).attr('data-name')}"]`).prop(
          //   'checked',
          //   true
          // );
        } else {
          $(this).prop('checked', false);
        }
      });

      $('.custom-accordain').on('click', function () {
        $(this).find('.custom-accordain-section').slideDown();
        $(this).siblings().find('.custom-accordain-section').slideUp();
      });
      $('.cusp-tab').on('click', function () {
        let id = $(this).attr('data-tabid');
        $(this).addClass('active');
        $(this).parent().siblings().find('.cusp-tab').removeClass('active');
        let currentTab = $('body').find(`#${id}`);
        currentTab.slideDown();
        currentTab.siblings().slideUp();
      });
      $('.cusp-sub-tab').on('click', function () {
        let id = $(this).attr('data-tabid');
        let currentTab = $('body').find(`#${id}`);
        $(this).addClass('active');
        $(this).siblings('.cusp-sub-tab').removeClass('active');
        currentTab.slideDown();
        currentTab.siblings('.sub-tab').slideUp();
      });
      $('.cosp-doc-pop-up-controller').on('click', function () {
        let popId = $(this).attr('data-pop-id');
        let currentPopup = $('body').find(`.cosp-doc-info-pop-up#${popId}`);
        currentPopup.css('display', 'block');
        currentPopup.siblings('.cosp-doc-info-pop-up').css('display', 'none');
      });

      $('.close-doc-pop-up-button').on('click', function () {
        $('.cosp-doc-info-pop-up').css('display', 'none');
      });
      // $('select#country').on('change', function () {
      //   let country = $(this).val();
      //   if (country == 'India') $('body').find('#pay-u-key').val(PAY_U_DOM_KEY);
      //   if (country !== 'India')
      //     $('body').find('#pay-u-key').val(PAY_U_INTER_KEY);
      // });
    });
  }
  onNotify(id: any): void {
    $('.no[data-circle="' + id + '"]').trigger('click');
  }
  test = () => 'sd fkjndskfnks';
  ngAfterViewInit(): void {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      this.hideVirtualAssistant();
      let id = setTimeout(this.hideVirtualAssistant, 2500);
      initAllPage();
      this.scrollToTop();
      this.formdata.patchValue({
        txnid: uuidv4(),
      });
    }
  }
  get Patient() {
    return this.formdata.get('Patient');
  }
  get Mobile() {
    return this.formdata.get('Mobile');
  }
  get Email() {
    return this.formdata.get('Email');
  }
  get Country() {
    return this.formdata.get('Country');
  }
  get City() {
    return this.formdata.get('City');
  }
  get Countrycode() {
    return this.formdata.get('Countrycode');
  }
  get Occupation() {
    return this.formdata.get('Occupation');
  }
  get NAVW() {
    return this.formdata.get('NAVW');
  }
  get SW() {
    return this.formdata.get('SW');
  }
  get NPW() {
    return this.formdata.get('NPW');
  }
  get CUSP() {
    return this.formdata.get('CUSP');
  }
  onWorkshopChange() {
    this.NAVW.valueChanges.subscribe((val) => {
      let index = this.enrolledWorkshops.findIndex(
        (item) => item == 'Neu Advanced Ventilation Workshop'
      );
      if (index == -1) {
        this.enrolledWorkshops.push('Neu Advanced Ventilation Workshop');
      } else {
        this.enrolledWorkshops = this.enrolledWorkshops.filter(
          (item) => item !== 'Neu Advanced Ventilation Workshop'
        );
      }
      this.amountTobePaid = 0;
      this.enrolledWorkshops.map((item) => {
        switch (item) {
          case 'Neu Advanced Ventilation Workshop':
            this.amountTobePaid += 500;
            break;
          case 'SONIC Workshop':
            this.amountTobePaid += 500;
            break;
          case 'Neonatal & Paediatric Workshop':
            this.amountTobePaid += 500;
            break;
          case 'Conference on Updates in Specialty Paediatrics':
            this.amountTobePaid += 500;
            break;
        }
      });
    });
    this.SW.valueChanges.subscribe((val) => {
      let index = this.enrolledWorkshops.findIndex(
        (item) => item == 'SONIC Workshop'
      );
      if (index == -1) {
        this.enrolledWorkshops = this.enrolledWorkshops.filter(
          (item) => item !== 'Neonatal & Paediatric Workshop'
        );
        this.enrolledWorkshops.push('SONIC Workshop');
      } else {
        this.enrolledWorkshops = this.enrolledWorkshops.filter(
          (item) => item !== 'SONIC Workshop'
        );
      }
      this.amountTobePaid = 0;
      this.enrolledWorkshops.map((item) => {
        switch (item) {
          case 'Neu Advanced Ventilation Workshop':
            this.amountTobePaid += 500;
            break;
          case 'SONIC Workshop':
            this.amountTobePaid += 500;
            break;
          case 'Neonatal & Paediatric Workshop':
            this.amountTobePaid += 500;
            break;
          case 'Conference on Updates in Specialty Paediatrics':
            this.amountTobePaid += 500;
            break;
        }
      });
    });
    this.NPW.valueChanges.subscribe((val) => {
      let index = this.enrolledWorkshops.findIndex(
        (item) => item == 'Neonatal & Paediatric Workshop'
      );
      if (index == -1) {
        this.enrolledWorkshops = this.enrolledWorkshops.filter(
          (item) => item !== 'SONIC Workshop'
        );
        this.enrolledWorkshops.push('Neonatal & Paediatric Workshop');
      } else {
        this.enrolledWorkshops = this.enrolledWorkshops.filter(
          (item) => item !== 'Neonatal & Paediatric Workshop'
        );
      }
      this.amountTobePaid = 0;
      this.enrolledWorkshops.map((item) => {
        switch (item) {
          case 'Neu Advanced Ventilation Workshop':
            this.amountTobePaid += 500;
            break;
          case 'SONIC Workshop':
            this.amountTobePaid += 500;
            break;
          case 'Neonatal & Paediatric Workshop':
            this.amountTobePaid += 500;
            break;
          case 'Conference on Updates in Specialty Paediatrics':
            this.amountTobePaid += 500;
            break;
        }
      });
    });
    // this.CUSP.valueChanges.subscribe((val) => {
    //   let index = this.enrolledWorkshops.findIndex(
    //     (item) => item == 'Conference on Updates in Specialty Paediatrics'
    //   );
    //   if (index == -1) {
    //     this.enrolledWorkshops.push(
    //       'Conference on Updates in Specialty Paediatrics'
    //     );
    //   } else {
    //     this.enrolledWorkshops = this.enrolledWorkshops.filter(
    //       (item) => item !== 'Conference on Updates in Specialty Paediatrics'
    //     );
    //   }
    //   this.amountTobePaid = 0;
    //   this.enrolledWorkshops.map((item) => {
    //     switch (item) {
    //       case 'Neu Advanced Ventilation Workshop':
    //         this.amountTobePaid += 500;
    //         break;
    //       case 'SONIC Workshop':
    //         this.amountTobePaid += 500;
    //         break;
    //       case 'Neonatal & Paediatric Workshop':
    //         this.amountTobePaid += 500;
    //         break;
    //       case 'Conference on Updates in Specialty Paediatrics':
    //         this.amountTobePaid += 500;
    //         break;
    //     }
    //   });
    // });
  }

  formSubmit(data) {
    // if (this.amountTobePaid == 0) return;
    // alert(this.formdata.get('Patient').value);
    if (!this.formdata.valid) {
      return;
    }
    $('body').addClass('loader');
    // let { } = data;
    $('body').removeClass('loader');
    let name = this.Patient.value.split(' ');
    // console.log(name.shift());
    // console.log({ name });
    let firstName = this.Patient.value,
      lastName = '',
      hash = this.hashString,
      phone = this.formdata.get('Mobile').value,
      amount = 0,
      key = this.formdata.get('key').value,
      txnid = this.formdata.get('txnid').value,
      productinfo = this.formdata.get('productinfo').value,
      udf1 = this.formdata.get('MedicalCouncilNumber').value,
      udf2 = this.formdata.get('Occupation').value,
      udf3 = this.formdata.get('Institution').value,
      udf4 = this.formdata.get('City').value,
      udf5 = this.formdata.get('Country').value,
      email = this.formdata.get('Email').value;
    $('body').find('input#i-p-f-key').val(key);
    $('body').find('input#i-p-f-txnid').val(txnid);
    $('body').find('input#i-p-f-productinfo').val(productinfo);
    $('body').find('input#i-p-f-amount').val(this.amountTobePaid);
    $('body').find('input#i-p-f-email').val(email);
    $('body').find('input#i-p-f-firstname').val(firstName);
    $('body').find('input#i-p-f-lastname').val(lastName);
    $('body').find('input#i-p-f-phone').val(phone);
    $('body').find('input#i-p-f-hash').val(hash);
    $('body').find('input#i-p-f-udf1').val(udf1);
    $('body').find('input#i-p-f-udf2').val(udf2);
    $('body').find('input#i-p-f-udf3').val(udf3);
    $('body').find('input#i-p-f-udf4').val(udf4);
    $('body').find('input#i-p-f-udf5').val(udf5);
    $('body').find('#initiate-payment-for').css('display', 'flex');
    // console.log(this.hashString);
    this.showPopUp = true;

    // this.api.postConferenceRegistration(data).subscribe((res) => {
    //   // let { status } = res;
    //   // if (status === 200) {
    //   console.log(res);
    //   this.isPending = true;

    //   // localStorage.setItem('userName', data.Patient);
    //   // localStorage.setItem(
    //   //   'amount',
    //   //   JSON.stringify(this.enrolledWorkshops.length * 1000)
    //   // );
    //   // localStorage.setItem('email', data.Email);
    //   // window.location.href = '/conference-on-special-pediatrics/pay';
    //   // }
    // });
    let { Patient, Guardian, Countrycode, ...rest } = data;
    this.api
      .sendConsultationMail({
        Lead_Date: moment(Date.now()).format('DD-MM-YYYY'),
        Lead_Time: moment(Date.now()).format('hh:mm:ss A'),
        'Child Name': Patient,
        'Guardian Name': Guardian,
        ...rest,
        'Country Code': Countrycode,
      })
      .subscribe((res) => {
        let { status } = res;
        if (status) this.popupWindow('Submition');
        else this.popupWindow('Submition_Failed');
        this.formdata.reset();
      });
  }

  popupWindow(popupName) {
    $('.T_C_popupWrapper, .' + popupName).fadeIn();
    var thisO = $('.' + popupName);
    popupResize(thisO);
    $(window).resize(function () {
      popupResize(thisO);
    });

    if ($('.' + popupName).height() < $(window).height()) {
      $('body').css('overflow', 'hidden');
    } else {
      var height = $('.' + popupName).height();
      $('.' + popupName).css('position', 'absolute');
      $('body').css({ overflowY: 'hidden' });
    }

    $('.close').click(function () {
      $('.T_C_popupWrapper, .' + popupName).fadeOut();
      $('body').css({ overflowY: 'unset' });
    });
  }
  resetForm() {
    $('#Patient').val('');
    $('#Guardian').val('');
    $('#Mobile').val('');
    $('#EmailId').val('');
    $('#Transaction').val('');
    $('#City')[0].selectedIndex = 0;
    // $("#Speciality")
    //   .empty()
    //   .append('<option value="0">Select Speciality</option>');
    // $("#SubSpeciality")
    //   .empty()
    //   .append('<option value="0">Select Sub Speciality</option>');
    // $("#unit").empty().append('<option value="0">Select Unit</option>');
    // $("#upiidwrp").hide();
  }
  closePopUp() {
    // this.showPopUp = false;
    $('body').find('#initiate-payment-for').css('display', 'none');
  }
  hideVirtualAssistant() {
    $('#ymDivBar').remove();
  }

  scrollToTop() {
    $(function () {
      $('.cosp-reg-now').on('click', function () {
        $('html , body').animate(
          {
            scrollTop: $('#form').offset().top - 20,
          },
          1000
        );
      });
    });
  }
  closePopup() {
    this.isPending = false;
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
