<div class="container-fluid">
		<div class="loder"></div>
		<sidenav></sidenav>
		
		<header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
		<bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
		<section class="page pageheader">
			<div class="container">
				<div class="col-xs-12 col-sm-12 col-lg-12">
					<div class="ptitle">
						<h1 class="underline">Search</h1>
					</div>
				</div>
			</div>
		</section>
		<section class="aboutsectin2 wcuswrp">
			<div class="container">
				<div class="row SiteMap">
					<div class="form-group col-xs-6">
							<input type="text" id="search" [(ngModel)]="searchParam"  (keyup)="getSearch()" placeholder="search string" class="form-control ">
					</div>
					<div class="clearfix"></div>
					<ul>
						<ng-template ngFor let-search [ngForOf]="filteredList">
							
							<li *ngIf="search.title">
								<a [href]="search.href"><h1 [innerHTML]="search.title"></h1></a>
								<p>
									{{search.body | slice:0:130}}
								</p>
							</li>
						</ng-template>
					</ul>
				</div>	
			</div>
		</section>
		<footer-nav-mini [links]="links"></footer-nav-mini>
		
		<footer-subscription></footer-subscription>
		<footer class="footerNavwrp">
			<footer-nav></footer-nav>
		</footer>
		<footer-copyright-nav></footer-copyright-nav>
	</div>