<div class="container-fluid">
    <div class="background">
        <img src="assets/images/bg-press-clip.png" data-t="bg_01_00.jpg" data-d="bg-press-clip.png" class="bgImg bg0 active" />
    </div>
    <div class="loder"></div>
    <sidenav></sidenav>
    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <section class="sfirst">
        <div class="row mediaSection pageheader">
            <div class="container">
                <div class="col-xs-12 col-sm-7 col-lg-7 abannimgwrp">
                    <div class="aboutbannimg"><img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" /></div>
                </div>
                <div class="col-xs-12 col-sm-5 col-lg-5">
                    <div class="ptitle yellow">
                        <h1>{{title}}</h1>
                        <h3>{{subtitle}}</h3>
                        <div class="dfindwrp">
                            <ul class="hlfcube">
                                <li>
                                    <div class="selctwrp">
                                        <div class="specialtywrp">
                                            <select class="select" name="eCategory" id="selecteCategory" (change)="filterCategoryRecord($event)">
                                                <option value="">Select Category</option>
                                                <option value="1">Academics</option>
                                                <option value="0">Non Academics</option>
                                            </select>
                                            <span id="SelectECategory" class="select-span"></span>
                                        </div>
                                    </div>
                                </li>
                                <li style="display: none;">
                                    <div class="finddwrp">
                                        <div class="findbox">
                                            <input type="text" name="txtfinddoctors" [(ngModel)]="selectMonth" id="selectMonthYear" class="datepickr" placeholder="Events Archive" />
                                        </div>
                                    </div>
                                    <div class="gobtn">
                                        <input type="submit" name="txtSearch" value="GO" (click)="filterRecord()" />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 PressReleases">
                    <div class="row" >

                        <div class="col-xs-12 col-sm-6 PressReleasesBox" *ngFor="let event of filtered_events">
                            <div class="dateBox">
                                <div class="month">{{event.event_date | amDateFormat : "MMMM"}}</div>
                                <div class="date">{{event.event_date | amDateFormat : "DD"}}</div>
                                <div class="year">{{event.event_date | amDateFormat : "YYYY"}}</div>
                            </div>
                            <h4>{{event.name}}</h4>
                            <p [innerHTML]="event.summery"></p>
                            <a href="about-us/events/{{event.slug}}" class="readMore">Read More</a>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>

    <find-a-doctors-nav></find-a-doctors-nav>
  <footer-subscription></footer-subscription>
  <footer class="footerNavwrp">
    <footer-nav></footer-nav>
  </footer>
  <footer-copyright-nav></footer-copyright-nav>
</div>