<div class="container-fluid">
  <div class="loder"></div>
  <sidenav></sidenav>
  <div id="topsearchwrp">
    <div class="inputbox">
      <input
        class="searchInput"
        type="text"
        name="txtSearch"
        id="txtSearch"
        placeholder="type here..."
      />
      <input type="submit" name="submit" class="ssubmitbtn" value="" />
    </div>
  </div>
  <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>

  <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
  <section class="page wtwedo">
    <div class="aboutcentre-banner container">
      <div class="col-md-5 col-xs-5 col-lg-4 floatright">
        <div class="circuleframe2">
          <a href="{{ virtual_tour_link }}" target="_blank">
            <img
              src="{{ imgBasePath }}{{ imgsrc }}"
              alt="{{ title }}"
              title="{{ title }}"
            />
          </a>
        </div>
        <!-- End Circle -->
      </div>
      <div class="col-md-7 col-xs-7 col-lg-7 floatleft">
        <div class="pagetitle">
          <h1 class="textgradient fontsize">{{ title }}</h1>
          <div class="underline">
            <img src="assets/images/underline.png" alt="" />
          </div>
        </div>
      </div>
      <div class="col-md-7 col-xs-12 col-lg-7 mcontentview">
        <p [innerHTML]="description">
          <!--{{description}}-->
        </p>
      </div>
      <!-- <div class=" col-xs-12 col-sm-12 col-md-7 col-lg-7 ios-text">
      <p class="col-xs-12 col-sm-7 col-md-5 col-lg-7  padding ">The centre also runs one of the largest post graduate training programs in the country. It offers the following training programs for doctors – DNB in pediatrics, DNB in Neonatology, Pediatric Critical Care Fellowship certification course by IAP and IAP Fellowship in Neonatology.</p>
      <div class=" col-xs-12 col-sm-5 col-md-5 col-lg-5"> <img src="assets/images/icon-ios.png" alt="" /> <img src="assets/images/icon-nabh.png" alt="" /></div>
    </div> -->
    </div>
  </section>
  <ng-template [ngIf]="brief_facilities">
    <section class="apapproachwrp">
      <div class="row">
        <div class="container">
          <div class="col-md-12 col-xs-12 col-lg-12">
            <div class="apatitle">
              <h2 class="gradient">A prudent approach</h2>
            </div>
            <ul class="apapproachlist">
              <li *ngFor="let brief_facility of brief_facilities">
                <div class="cirwrp">
                  <img
                    src="{{ imgBasePath }}{{ brief_facility.image }}"
                    alt=""
                  />
                </div>
                <div
                  class="txtwrp"
                  [innerHTML]="brief_facility.description"
                ></div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </ng-template>
  <section class="aboutityiconwrp {{pageName == 'malviya-nagar' ? '' : 'hidden'}}">
    <div class="row">
      <div class="ourspecialties" style="text-align: center;">
        <h2 class="gradient fontsize">Certified</h2>
      </div>
      <div class="col-md-12 col-xs-12 col-lg-12">
          <div style="display: flex; flex-direction: row; align-items:flex-start; justify-content: center; width: 100%; flex-wrap: wrap;">
              <img style="width: 150px; margin: 2rem 6rem;" src="../../assets/images/NABH-Logo.png" alt="Certified by NABH"/>
              <img style="width: 150px; margin: 2rem 6rem;" src="../../assets/images/NABL_Official_LOGO_Registered.png" alt="Certified by NABL" />
            </div>
      </div>
    </div>
  </section>

  <section
    class="aboutityiconwrp"
    #abouticonslider
    *ngIf="our_speciality_features.length > 0"
  >
    <div class="row">
      <div class="ourspecialties">
        <h2 class="gradient fontsize">Our Specialties</h2>
      </div>
      <div class="col-md-12 col-xs-12 col-lg-12 text-center">
        <p>Following are the specialities that are provided in our centre</p>
      </div>
      <div class="col-md-12 col-xs-12 col-lg-12">
        <ul data-class="aboutuslider" class="aboutuslider">
          <ng-template
            ngFor
            let-i="index"
            let-speciality_feature
            [ngForOf]="our_speciality_features"
          >
            <li
              [attr.data-doctorid]="speciality_feature.id"
              [attr.data-mastersid]="speciality_feature.speciality_masters_id"
              (click)="
                viewDoctorList(
                  speciality_feature.speciality_masters_id,
                  speciality_feature.id
                )
              "
            >
              <div class="viconwrp">
                <div class="cirwrp">
                  <img
                    src="{{ imgBasePath }}{{ speciality_feature.image }}"
                    data-img=""
                    alt=""
                  />
                </div>
                <div class="textbtn">
                  <div class="inner">
                    <p>{{ speciality_feature.title }}</p>
                    <!--<a href="our-centre/{{cityName}}/{{speciality_feature.page}}/speciality/{{speciality_feature.slug}}" (click)="viewDoctorList(speciality_feature.speciality_masters_id,speciality_feature.id)" class="knowmore"><span>Know More</span></a></div> -->
                    <a
                      href="{{ speciality_feature.page }}/speciality/{{
                        speciality_feature.slug
                      }}"
                      (click)="
                        viewDoctorList(
                          speciality_feature.speciality_masters_id,
                          speciality_feature.id
                        )
                      "
                      class="knowmore"
                      ><span>Know More</span></a
                    >
                  </div>
                </div>
              </div>
            </li>
          </ng-template>
        </ul>

        <ul
          data-class="aboutuslidercon"
          class="aboutuslidercon overlay"
          [ngClass]="{
            showdiv: showDoctor,
            hidediv: !showDoctor,
            overlay: doctorLen,
            overlaynon: !doctorLen
          }"
        >
          <ng-template
            ngFor
            let-doctor
            [ngForOf]="specilityDoctor | slice: 0:50"
          >
            <div
              class="col-xs-12 col-sm-3 col-md-3 specdoctorslider"
              style="min-height: 370px"
            >
              <div class="doctorsListwrp">
                <div class="dthumbwrp">
                  <img src="{{ imgBasePath }}{{ doctor.photo }}" alt="" />
                </div>
                <div class="ddetailswrp">
                  <h3>{{ doctor.name }}</h3>
                  <p>{{ doctor.designation }}</p>
                </div>
                <div class="clr"></div>
                <!-- <a href="/{{currentSection}}/doctor/{{doctor.slug}}" class="knowmore2 active">
										<span>Know More</span>
									</a>
								<a href="/{{currentSection}}/book-an-appointment/{{doctor.slug}}" class="btn-Bookapopoi">
										<span>Book an appointment</span>
									</a> -->

                <a
                  href="/all/doctor/{{ doctor.slug }}"
                  class="knowmore2 active"
                >
                  <span>Know More</span>
                </a>
                <!--<a href="/our-centre/{{cityName}}/{{doctor.slug}}" class="knowmore2 active">
                                    <span>Know More</span>
                                </a>-->
                <a
                  href="/all/book-an-appointment/{{ doctor.slug }}"
                  class="btn-Bookapopoi"
                >
                  <span>Book an appointment</span>
                </a>
              </div>
            </div>
          </ng-template>
        </ul>
      </div>
    </div>
  </section>
  <!-- <section *ngIf="visitor_information">
        <div class="container">
            <div class="visitor-head col-lg-12">
                <div class="visitor-icon"><img src="assets/images/icon-visitor.png" alt=""></div>
                <h2 class="textgradient fontsize">Visitor Information</h2>
            </div>
            <div class="visitext col-lg-12" [innerHTML]="visitor_information"></div>
        </div>
    </section> -->
  <section class="gmapwrp row">
    <div class="sidelocdetwrp">
      <div class="gmheader">
        <div class="box">
          <select
            #centermap
            class="select"
            name="location"
            (change)="changeCenter(centermap.value)"
          >
            <option value="0">Select location</option>

            <ng-template ngFor let-c [ngForOf]="centerList" let-i="index">
              <optgroup *ngIf="c.area.length > 0" label="{{ c.city }}">
                <option *ngFor="let branch of c.area" [value]="branch.slug">
                  {{ branch.center_name_alias }}
                </option>
              </optgroup>
            </ng-template>
            <!-- <option  *ngFor="let center of centerList;" [value]="center.centres_id">{{center.center_name_alias}}</option> -->
          </select>
          <!-- <select #centermap (change)="changeCenter(centermap.value)" name="textMap" id="textMap" class="select">
						<option selected>Find a Center</option>
						<option *ngFor="let center of centerList;" [value]="center.center_slug">{{center.center_name_alias}}</option>
					</select> -->
          <span id="selectcity" class="select-span"></span>
        </div>
        <!--<div class="box">
					<input type="text" name="txtMapsearch" class="msearch" />
					<input type="submit" name="txtMsubmit" class="mssubmit" value="" />
				</div>-->
      </div>
      <div class="mimgrolatedwrp">
        <div class="mimgl">
          <a href="{{ virtual_tour_link }}" target="_blank" class="rolink"
            ><img src="assets/images/icon-360.png" alt=""
          /></a>
          <img [src]="centerImgsrc" alt="" />
        </div>
      </div>
      <div class="mdrwrp">
        <div class="mtitle">
          <p [innerHTML]="title"></p>
        </div>
        <div class="icondr">
          <a
            href="https://www.google.com/maps?saddr=My+Location&daddr={{
              lat_lng
            }}"
            target="_blank"
            ><img src="assets/images/icon_dir.png" alt=""
          /></a>
        </div>
      </div>
      <div class="mdetailwrp">
        <ul>
          <li>
            <p [innerHTML]="address"></p>
          </li>
          <li class="phone">
            <strong>{{ telephone }}</strong>
          </li>
          <li class="mail">
            <a href="mailto:info@rainbowhospitals.in"
              >info@rainbowhospitals.in</a
            >
          </li>
          <div class="clearfix"></div>
          <li class="view-map"><a href="javascript:void()">view-map</a></li>
        </ul>
      </div>
    </div>
    <div class="gmap mapshow-mob" id="google-container"></div>
  </section>
  <a class="iconFormV" href="javascript:void();" (click)="ShowForm()"
    >ENQUIRE<span>NOW</span></a
  >
  <a class="iconForm" href="javascript:void();" (click)="ShowForm()"
    ><img alt="" src="assets/images/Icon_form.png"
  /></a>
  <footer-nav-mini [links]="links"></footer-nav-mini>
  <find-a-doctors-nav></find-a-doctors-nav>
  <footer-subscription></footer-subscription>
  <footer class="footerNavwrp">
    <footer-nav></footer-nav>
  </footer>
  <footer-copyright-nav></footer-copyright-nav>
  <!-- Start Model Popup  *ngIf="showHide" -->
  <div class="capopup" id="ModelFormGetInTouch">
    <div class="capopup-inner">
      <div class="launchpreloader" *ngIf="preloader"></div>
      <a
        class="capopup-close"
        href="javascript:void()"
        (click)="changeShowStatus()"
      >
        <img alt="" src="assets/images/icon-close2.png" />
      </a>
      <h2>GET IN TOUCH WITH US</h2>
      <form id="form" [formGroup]="GetInTouch" (submit)="onSubmit()" novalidate>
        <ul class="formcasn">
          <li>
            <input
              type="text"
              name="name"
              [ngClass]="{
                'has-error':
                  !GetInTouch.controls.name.valid &&
                  GetInTouch.controls.name.touched
              }"
              placeholder="Name*"
              formControlName="name"
              required
            />
            <span
              class="alertMsg"
              *ngIf="!GetInTouch.controls.name.valid && submited"
              >required</span
            >
          </li>
          <li>
            <input
              type="text"
              name="mobile"
              [ngClass]="{
                'has-error':
                  !GetInTouch.controls.mobile.valid &&
                  GetInTouch.controls.mobile.touched
              }"
              maxlength="10"
              placeholder="Mobile*"
              formControlName="mobile"
              required
            />
            <span
              class="alertMsg"
              *ngIf="!GetInTouch.controls.mobile.valid && submited"
              >required</span
            >
          </li>
          <li>
            <input
              type="text"
              name="email"
              [ngClass]="{
                'has-error':
                  !GetInTouch.controls.email.valid &&
                  GetInTouch.controls.email.touched
              }"
              placeholder="Email ID*"
              formControlName="email"
              required
            />
            <span
              class="alertMsg"
              *ngIf="!GetInTouch.controls.email.valid && submited"
              >required</span
            >
          </li>
          <li class="singledropdown">
            <div class="selectbox">
              <select
                class="select"
                name="txtLocation"
                id="txtLocation"
                [(ngModel)]="locationModel"
                formControlName="txtLocation"
              >
                <option value="" selected>Location*</option>
                <option
                  *ngFor="let interest of locationList; let i = index"
                  [value]="interest.name"
                >
                  {{ interest.name }}
                </option>
              </select>
              <span class="select-span"></span>
            </div>
            <span
              class="alertMsg"
              *ngIf="!GetInTouch.controls.txtLocation.valid && submited"
              >required
            </span>
          </li>
          <li class="singledropdown">
            <div class="selectbox">
              <select
                class="select"
                name="interested_in"
                id="txtinterested_in"
                [(ngModel)]="countryModel"
                formControlName="interested_in"
              >
                <option value="" selected>I am interested in*</option>
                <option
                  *ngFor="let interest of interestedList; let i = index"
                  [value]="interest.name"
                >
                  {{ interest.name }}
                </option>
              </select>
              <span class="select-span"></span>
            </div>
            <span
              class="alertMsg"
              *ngIf="!GetInTouch.controls.interested_in.valid && submited"
              >required
            </span>
          </li>
          <li>
            <input
              type="text"
              name="message"
              placeholder="Message"
              formControlName="message"
            />
          </li>
          <li>
            <div class="submitbtn">
              <input
                class="casubmit"
                id="txtSubmit"
                name="txtSubmit"
                type="submit"
                value="Submit"
              />
            </div>
          </li>
        </ul>
      </form>
    </div>
  </div>

  <div class="capopup ModelFormStyle1" id="LMSDataCaptureFormGetInTouch">
    <div class="capopup-inner">
      <div class="customloder"></div>
      <a
        class="capopup-close"
        href="javascript:void()"
        (click)="hideModelForm()"
      >
        <img alt="" src="assets/images/icon-close2.png" />
      </a>
      <h2>GET IN TOUCH WITH US</h2>
      <form
        id="GetInTouchLms"
        [formGroup]="GetInTouchLms"
        (submit)="onLMSDataCaptureFormSubmit()"
        novalidate
      >
        <ul class="formcasn">
          <div class="ThankYouMsgWrp">
            <span
              >Thanks for enquiring about our Evening Clinic. Our team will get
              back to you shortly.</span
            >
          </div>
          <li>
            <input
              type="text"
              name="name"
              [ngClass]="{
                'has-error':
                  !GetInTouchLms.controls.name.valid &&
                  GetInTouchLms.controls.name.touched
              }"
              placeholder="Name*"
              formControlName="name"
              required
            />
            <span
              class="alertMsg"
              *ngIf="!GetInTouchLms.controls.name.valid && submited"
              >required</span
            >
          </li>
          <li>
            <input
              type="text"
              name="mobile"
              [ngClass]="{
                'has-error':
                  !GetInTouchLms.controls.mobile.valid &&
                  GetInTouchLms.controls.mobile.touched
              }"
              placeholder="Mobile*"
              formControlName="mobile"
              required
            />
            <span
              class="alertMsg"
              *ngIf="!GetInTouchLms.controls.mobile.valid && submited"
              >required</span
            >
          </li>
          <li>
            <input
              type="text"
              name="email"
              [ngClass]="{
                'has-error':
                  !GetInTouchLms.controls.email.valid &&
                  GetInTouchLms.controls.email.touched
              }"
              placeholder="Email ID*"
              formControlName="email"
              required
            />
            <span
              class="alertMsg"
              *ngIf="!GetInTouchLms.controls.email.valid && submited"
              >required</span
            >
          </li>
          <li>
            <input
              type="text"
              name="message"
              placeholder="Message"
              formControlName="message"
            />
            <input
              type="hidden"
              name="programName"
              id="programName"
              formControlName="programName"
            />
          </li>
          <li>
            <div class="submitbtn">
              <input
                class="casubmit"
                id="txtSubmit"
                name="txtSubmit"
                type="submit"
                value="Submit"
              />
            </div>
          </li>
        </ul>
      </form>
    </div>
  </div>
  <!-- End Model Popup -->
</div>
