import { Inject, Component, OnDestroy, OnInit, AfterViewInit, PLATFORM_ID, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from "../services/api.service";
import { IMG_BASE_URL } from "../services/constants";
import { Observable } from 'rxjs';

import { Meta, Title } from '@angular/platform-browser';
declare let $: any;
declare let initHome: any;
declare let initAllPage: any;

@Component({
	selector: 'our-specialities',
	templateUrl: './our-specialities.component.html'
})

export class OurSpecialitiesComponent implements OnDestroy, OnInit, AfterViewInit {

	public isShow = true;
	constructor(private api: ApiService, public metaTitle: Title, public meta: Meta) {
		if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
    }
	}

	public child_features: any = [];
	public women_features: any = [];
	public fertility_features: any = [];
	public pageinfo: any[] = [];
	public title: any = "";
	public subtitle: any = "";
	public description: any = "";
	public imgsrc: any = "";
	public columns = -1;
	public cItems: any = [];
	public wItems: any = [];
	public fItems: any = [];
	public zone: any;
	public imgBasePath = "";
	public hidden = "hidden";
	public logo = "logo";
	public bread_crumb_links: any = [{ "label": "Home", "link": "/", "class": "" },
	{ "label": "Our Specialities", "link": "", "class": "" }];

	// public links:any = [{"label":"About Fertility Care", "link": "/fertility-care-about", "class": ""},
	//                     {"label":"Doctors", "link": "/fertility-care-doctors", "class": ""},
	//                     {"label":"Our Specialities", "link": "/fertility-care-specialities", "class": "active"},
	//                     {"label":"Our Centres", "link": "/fertility-care-centres", "class": ""},
	//                     {"label":"Testimonials", "link": "/fertility-care-testimonials", "class": ""},
	//                     {"label":"Share Feedback", "link": "/fertility-care-feedback", "class": ""},
	//                     {"label":"Book An Appointment", "link": "/fertility-care-appointment", "class": ""}]


	@ViewChildren('allTheseThings') things: QueryList<any>;

	// ngForRendred() {
	//
	// }

	ngOnInit() {
		this.api.getPageDescription('our-specialities')
			.subscribe(
			data => {
				this.title = data.page_descriptions[0].title;
				this.subtitle = data.page_descriptions[0].subtitle;
				this.description = data.page_descriptions[0].description;
				this.imgsrc = data.page_descriptions[0].image;
				this.imgBasePath = IMG_BASE_URL;
				if(data){
					//console.log(data);
					this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
					this.meta.updateTag({name:'keywords',content: data.page_descriptions[0].meta_keyword});
					this.meta.updateTag({name:'description',content: data.page_descriptions[0].meta_description});
					this.meta.addTags([
					  { property: 'og:title', content:  data.page_descriptions[0].meta_title},
					  { property: 'og:description', content: data.page_descriptions[0].meta_description },
					  { property: 'og:keywords', content: data.page_descriptions[0].meta_keyword }
					]);
				  }
			},
			error => {
				console.log(error);
			}
			)

		this.api.getSpecialityFeatures('child-care')
			.subscribe(
			data => {
				this.imgBasePath = IMG_BASE_URL;
				this.child_features = data.our_speciality_features;
				if (Array.isArray(data.our_speciality_features) && data.our_speciality_features.length > 0) {
					for (let i = 0; i < 6; i += 2) {
						let column = [];
						column.push(data.our_speciality_features[i])
						if (data.our_speciality_features[i + 1]) {
							column.push(data.our_speciality_features[i + 1])
						}
						this.cItems.push(column);
						column = [];
					}
				};
			},
			error => {
				console.log(error);
			}
			)

		this.api.getSpecialityFeatures('women-care')
			.subscribe(
			data => {
				this.imgBasePath = IMG_BASE_URL;
				this.women_features = data.our_speciality_features;
				if (Array.isArray(data.our_speciality_features) && data.our_speciality_features.length > 0) {
					for (let i = 0; i < 6; i += 2) {
						let column = [];
						column.push(data.our_speciality_features[i])
						if (data.our_speciality_features[i + 1]) {
							column.push(data.our_speciality_features[i + 1])
						}
						this.wItems.push(column);
						column = [];
					}
				};
			},
			error => {
				console.log(error);
			}
			)

		this.api.getSpecialityFeatures('fertility-care')
			.subscribe(
			data => {
				this.imgBasePath = IMG_BASE_URL;
				this.fertility_features = data.our_speciality_features;
				if (Array.isArray(data.our_speciality_features) && data.our_speciality_features.length > 0) {
					for (let i = 0; i < 6; i += 2) {
						let column = [];
						column.push(data.our_speciality_features[i])
						if (data.our_speciality_features[i + 1]) {
							column.push(data.our_speciality_features[i + 1])
						}
						this.fItems.push(column);
						column = [];
					}
				};
			},
			error => {
				console.log(error);
			}
			)



	}

	ngAfterViewInit() {
		if (this.api.isBrowser && window['jQuery']) {
			initHome();
			initAllPage();
		}

		this.things.changes.delay(1000).subscribe(t => {
			
			var $ourscsmain = $('.ourscsmain');
			var $ourScs = $('.ourScs');
			var $osatabs = $('.osatabs');

			//$ourscsmain.not('.slick-initialized').slick("unslick");

			$ourscsmain.not('.slick-initialized').slick({
				slide: '.sitem',
				dots: true,
				arrows: false,
				infinite: false,
				slidesToShow: 3,
				slidesToScroll: 3,
				slidesPerRow: 1,
				adaptiveHeight: true,
				responsive: [
					{
						breakpoint: 1024, settings: {
							slidesToShow: 2, slidesToScroll: 1
						}
					},
					{
						breakpoint: 680, settings: {
							slidesToShow: 1, slidesToScroll: 1
						}
					},
					{
						breakpoint: 480, settings: {
							slidesToShow: 1, slidesToScroll: 1
						}
					}
				]
			});
			$ourScs.not('.slick-initialized').slick({
				slide: 'li',
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: true,
				fade: false,
				asNavFor: '.osatabs',
				infinite: false,
				adaptiveHeight: true,
			});
			$osatabs.not('.slick-initialized').slick({
				slide: 'li',
				slidesToShow: 3,
				slidesToScroll: 1,
				prevArrow: '<a class="slick-prev"><img src="assets/images/prv-arrow2.png" alt="" /></a>',
				nextArrow: '<a class="slick-next"><img src="assets/images/nxt-arrow2.png" alt="" /></a>',
				asNavFor: '.ourScs',
				dots: false,
				centerMode: false,
				focusOnSelect: true,
				arrows: true,
				infinite: false,
				responsive: [
					{
						breakpoint: 1024, settings: {
							slidesToShow: 2, slidesToScroll: 1
						}
					},
					{
						breakpoint: 768, settings: {
							slidesToShow: 2, slidesToScroll: 1
						}
					},
					{
						breakpoint: 480, settings: {
							slidesToShow: 1, slidesToScroll: 1
						}
					}
				]
			});
			if ($osatabs.find('a').hasClass('slick-arrow')) {
				$osatabs.addClass('acarrow');
			} else { $osatabs.removeClass('acarrow'); }
			$(window).resize(function () {
				if ($osatabs.find('a').hasClass('slick-arrow')) {
					$osatabs.addClass('acarrow')
				} else { $osatabs.removeClass('acarrow'); }
			});
		})
	}
	setSpecialitiesSlider() {
		var $body = $('body');
			//console.log("Specialities Slider Invoked");
			$body.find(".ourSpecialitiess").removeClass("slick-slider").removeClass("slick-initialized").removeClass("slider");
			$body.find(".ourSpecialitiess").not('.slick-initialized').slick({
				slide: '.sitem',
				dots: true,
				arrows: false,
				infinite: false,
				slidesToShow: 3,
				slidesToScroll: 3,
				slidesPerRow: 1,
				adaptiveHeight: true,
				responsive: [
					{
						breakpoint: 1024, settings: {
							slidesToShow: 2, slidesToScroll: 1
						}
					},
					{
						breakpoint: 680, settings: {
							slidesToShow: 1, slidesToScroll: 1
						}
					},
					{
						breakpoint: 480, settings: {
							slidesToShow: 1, slidesToScroll: 1
						}
					}
				]
			});
	
		}
	ngOnDestroy() { }

}
