<div class="container-fluid">
    <div class="background">
        <img src="assets/images/bg_childcare.png" data-t="bg_01_00.jpg" data-d="bg_childcare.png" class="bgImg bg0 active" />
    </div>
    <div class="loder"></div>
    <sidenav></sidenav>
    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <section class="page pageheader">
        <div class="row">
            <div class="container">
                <div class="col-xs-12 col-sm-7 col-lg-7 abannimgwrp">
                    <div class="aboutbannimg"><img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" /></div>
                </div>
                <div class="col-xs-12 col-sm-5 col-lg-5">
                    <div class="ptitle blue">
                        <h1>{{title}} </h1>
                        <h3>{{subtitle}}</h3>
                    </div>
                </div>
                <div class="col-xs-12 mcontentview">
                    <p [innerHTML]="description"></p>
                </div>
            </div>
        </div>
    </section>
    <section class="awardswapper">
        <div class="row">
            <div class="container">
                <div class="col-xs-12 text-center">
                    <div class="awardsBtn"><a href="javascript:void(0)" data-showSlider="HospitalAwards" class="active">Hospital Awards</a></div>
                    <div class="awardsBtn" style="display: none;"><a href="javascript:void(0)" data-showSlider="DoctorsAwards">Doctors Awards</a></div>
                </div>
            </div>
            <div class="container">
                <div class="col-md-12 col-xs-12 col-lg-12 ">

                    <!-- <ng-template> -->
                    <ul class="awardsSlider slider HospitalAwards">
                        <li *ngFor="let award of allAwards">
                            <div class="viconwrp">
                                <div class="cirwrp"> <img src="{{imgBasePath}}{{award.image}}" /> </div>
                            </div>
                            <div class="textBox">
                                <h2>{{award.year}}</h2>
                                <h3>{{award.title}}</h3>
                                <p>{{award.subtitle}}</p>
                            </div>
                        </li>
                    </ul>
                    <!-- </ng-template>
					<ng-template> -->
                    <!-- <ul  class="awardsSlider slider DoctorsAwards">
							<li *ngFor="let award of allAwards">
								<div class="viconwrp">
									<div class="cirwrp"> <img src="{{imgBasePath}}{{award.image}}" /> </div>
								</div>
								<div class="textBox">
									<h2>{{award.year}}</h2>
									<h3>{{award.title}}</h3>
									<p>{{award.subtitle}}</p>
								</div>
							</li>
						</ul> -->
                    <ul class="awardsSlider DoctorsAwards">
                        <li style="
							width:100vw !important;
    text-align: center;
    height: 35vh;
    justify-content: center;
    align-items: center;
    display: flex;">
                            <h3>Coming Soon</h3>
                        </li>
                    </ul>
                    <!-- </ng-template> -->

                </div>
            </div>
        </div>
    </section>


    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>

    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>