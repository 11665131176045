<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>
    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <section class="page_notf wtwedo">
        <div class="row">
            <div class="pnotf_container">
                <div class="col-xs-12 col-sm-6"> <img src="assets/images/404.jpg" class="img-responsive"> </div>
                <div class="col-xs-12 col-sm-6 Oops">
                    <h2 class="textgradient">Oops!</h2>
                    <h3>Looks like you took a wrong turn!</h3>
                    <a class="knowmore" href="https://www.rainbowhospitals.in/"><span >Go Back To The Homepage <img _ngcontent-c1="" alt="" src="assets/images/btn-arrow-right.png"></span> </a>
                </div>
            </div>
        </div>
        <div class="row bgblue">
            <div class="pnotf_container text-center FZF-menu">
                <h2>Explore our quick links instead</h2>
                <ng-template ngFor let-footernav [ngForOf]="sideBarList">
                    <ul *ngIf="footernav.type == 'footer-2'" class="tnav">
                        <ng-template ngFor let-link [ngForOf]="footernav.links">
                            <li *ngIf="link.status == 1"><a [href]="link.link" [target]="link.target">{{link.title}}</a></li>
                        </ng-template>
                    </ul>
                </ng-template>
                <ul style="display: none;">
                    <li><a href="#"> Find a Doctor</a></li>
                    <li><a href="#">Book an Appointment </a></li>
                    <li><a href="#">Centres </a></li>
                    <li><a href="#">Testimonials </a></li>
                    <li><a href="#">Our Specialities</a></li>
                    <li><a href="#">Insights</a></li>
                </ul>
            </div>
        </div>
    </section>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>