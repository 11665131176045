import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL } from '../services/constants';
import { Meta, Title } from '@angular/platform-browser';
declare let $: any;
declare let initHome: any;
declare let initAllPage: any;

declare let initAboutTabs: any;

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: [`./app.component.css`],
})
export class OtherAppComponent implements OnDestroy, OnInit, AfterViewInit {
  public hidden = 'hidden';
  public logo = 'logo';
  public bread_crumb_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Others', link: '', class: '' },
    { label: 'App', link: '', class: '' },
  ];
  public pageData: any = {};
  public imgBasePath = '';
  public links: any = [];
  public activePage: string = 'app';
  constructor(
    private api: ApiService,
    public metaTitle: Title,
    public meta: Meta
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
    }
  }

  @ViewChildren('faq') things: QueryList<any>;

  ngOnInit() {
    this.api.getPageDescription('rainbow-app').subscribe(
      (data) => {
        this.pageData = data.page_descriptions[0];
        this.imgBasePath = IMG_BASE_URL;
        if (data) {
          //console.log(data);

          this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
          this.meta.updateTag({
            name: 'keywords',
            content: data.page_descriptions[0].meta_keyword,
          });
          this.meta.updateTag({
            name: 'description',
            content: data.page_descriptions[0].meta_description,
          });
          this.meta.addTags([
            {
              property: 'og:title',
              content: data.page_descriptions[0].meta_title,
            },
            {
              property: 'og:description',
              content: data.page_descriptions[0].meta_description,
            },
            {
              property: 'og:keywords',
              content: data.page_descriptions[0].meta_keyword,
            },
          ]);
        }
        /* Removed for extra declaration of variables */
        /* this.title = data.page_descriptions[0].title,
				this.subtitle = data.page_descriptions[0].subtitle,
				this.description = data.page_descriptions[0].description,
				this.imgsrc = data.page_descriptions[0].image,
				this.imgBasePath = IMG_BASE_URL;*/
      },
      (error) => {
        console.log(error);
      }
    );

    this.api
      .getOtherSubFooterDetails()
      .map((res) => res)
      .subscribe((data) => {
        this.links = data;
      });
  }

  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
      $('.appLink').each((index, element) => {
        $(this).click(() => {
          $('html, body').animate(
            { scrollTop: $('#' + $(this).attr('data-id')).offset().top - 50 },
            2000
          );
        });
      });
    }

    this.things.changes.subscribe((t) => {
      // this.ngForRendred();
      initAboutTabs();
    });
  }

  ngOnDestroy() {}
}
