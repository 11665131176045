import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL } from '../services/constants';

import { Meta, Title } from '@angular/platform-browser';
declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
declare let initAboutTabs: any;

@Component({
  selector: 'management',
  templateUrl: './management.component.html',
})
export class ManagementComponent implements OnDestroy, OnInit, AfterViewInit {
  constructor(
    private api: ApiService,
    public metaTitle: Title,
    public meta: Meta
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
    }
  }

  public services: any[] = [];
  public title: any = '';
  public subtitle: any = '';
  public description: any = '';
  public imgsrc: any = '';
  public brief_facilities_description: any = '';
  public brief_facilities_image: any = '';
  public imgBasePath = '';
  public hidden = 'hidden';
  public board: any = '';
  public logo = 'logo';
  public bread_crumb_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'About Us', link: '/about-us', class: '' },
    { label: 'Board of Directors', link: '', class: '' },
  ];

  public links: any = [];
  public activePage: string = 'management';

  @ViewChildren('abouttabs') things: QueryList<any>;

  ngOnInit() {
    this.api
      .getAboutSubFooterDetails()
      .map((res) => res)
      .subscribe((data) => {
        this.links = data;
      });
    this.api.getPageDescription('board-of-directors').subscribe(
      (data) => {
        this.title = data.page_descriptions[0].title;
        this.subtitle = data.page_descriptions[0].subtitle;
        this.description = data.page_descriptions[0].description;
        this.imgsrc = data.page_descriptions[0].image;
        this.imgBasePath = IMG_BASE_URL;
        if (data) {
          //console.log(data);
          this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
          this.meta.updateTag({
            name: 'keywords',
            content: data.page_descriptions[0].meta_keyword,
          });
          this.meta.updateTag({
            name: 'description',
            content: data.page_descriptions[0].meta_description,
          });
          this.meta.addTags([
            {
              property: 'og:title',
              content: data.page_descriptions[0].meta_title,
            },
            {
              property: 'og:description',
              content: data.page_descriptions[0].meta_description,
            },
            {
              property: 'og:keywords',
              content: data.page_descriptions[0].meta_keyword,
            },
          ]);
        }
      },
      (error) => {
        console.log(error);
      }
    );

    this.api.getBoardOfDirectors('Board of Directors').subscribe(
      (data) => {
        this.board = data.md_messages;
      },
      (error) => {
        console.log(error);
      }
    );

    this.api.getBriefFacilities('who-we-are').subscribe(
      (data) => {
        (this.brief_facilities_description =
          data.brief_facilities[0].description),
          (this.brief_facilities_image = data.brief_facilities[0].image);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
    }

    this.things.changes.subscribe((t) => {
      // this.ngForRendred();
      initAboutTabs();
    });
  }

  ngOnDestroy() {}
}
