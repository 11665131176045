import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  ViewChildren,
  QueryList,
  NgZone,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL } from '../services/constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Meta, Title } from '@angular/platform-browser';
declare let $: any;
declare let initHome: any;
declare let initAllPage: any;

declare let initAboutTabs: any;
declare let initRemoveClass: any;

@Component({
  selector: 'international_p_thankyou',
  templateUrl: './international_p_thankyou.component.html',
  styles: [
    `
      .has-error {
        border: 1px solid red !important;
      }
      input[disabled] {
        border: 1px solid rgba(61, 41, 121, 0.5) !important;
        background: rgba(61, 41, 121, 0.5) !important;
      }
      .alertMsg {
        color: red !important;
      }
      .CalcLoader {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto auto;
        z-index: 999;
        width: 100%;
        height: 100%;
        background: rgba(51, 51, 51, 0.2);
      }
      .CalcLoader img {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto auto;
        z-index: 999;
      }
    `,
  ],
})
export class OtherInternationalPatientsThankYouComponent {
  appointmentForm: FormGroup;

  public group_sections: any = [];
  public title: any = '';
  public subtitle: any = '';
  public description: any = '';
  public logo = 'logo';
  public hidden = 'hidden';
  public imgsrc: any = '';
  public imgBasePath = '';
  public bread_crumb_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Others', link: '', class: '' },
    { label: 'International Patients', link: '', class: '' },
  ];

  public links: any = [];
  public activePage: string = 'international_patients';

  constructor(
    public zone: NgZone,
    private api: ApiService,
    private formBuilder: FormBuilder,
    public metaTitle: Title,
    public meta: Meta
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
    }

    this.api
      .getOtherSubFooterDetails()
      .map((res) => res)
      .subscribe((data) => {
        this.links = data;
      });
  }

  ngOnInit() {
    this.api.getPageDescription('international_patients').subscribe(
      (data) => {
        (this.title = data.page_descriptions[0].title),
          (this.subtitle = data.page_descriptions[0].subtitle),
          (this.description = data.page_descriptions[0].description),
          (this.imgsrc = data.page_descriptions[0].image),
          (this.imgBasePath = IMG_BASE_URL);
        if (data) {
          //console.log(data);
          this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
          this.meta.updateTag({
            name: 'keywords',
            content: data.page_descriptions[0].meta_keyword,
          });
          this.meta.updateTag({
            name: 'description',
            content: data.page_descriptions[0].meta_description,
          });
          this.meta.addTags([
            {
              property: 'og:title',
              content: data.page_descriptions[0].meta_title,
            },
            {
              property: 'og:description',
              content: data.page_descriptions[0].meta_description,
            },
            {
              property: 'og:keywords',
              content: data.page_descriptions[0].meta_keyword,
            },
          ]);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  @ViewChildren('international-patients') things: QueryList<any>;

  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
      initRemoveClass();
    }

    this.things.changes.subscribe((t) => {
      // this.ngForRendred();
      initAboutTabs();
    });

    setTimeout(function () {
      history.back();
    }, 5000);
  }

  ngOnDestroy() {}
}
