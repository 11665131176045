<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>
    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <section class="page pageheader">
        <div class="container">
            <div class="col-xs-6 col-sm-6 col-lg-6 abannimgwrp">
                <div class="otherbannimg">
                    <img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" />
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-lg-6">
                <div class="ptitle">
                    <h1>{{title}}</h1>
                    <h3>{{subtitle}}</h3>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-lg-6 mcont100">
                <p [innerHTML]="description"></p>
            </div>

        </div>
    </section>
    <section class="aboutsectin2 awardswapper">
        <div class="container">
            <div class="row nsg_text">
                <div class="col-xs-12 col-lg-12">
                    <h2>Going Home with your Baby</h2>
                    <p>Doctors at Rainbow Hospitals will be constantly in touch with you to help you give a sense of the time that is best to start thinking of going back home. Time at the hospital can be stressful and therefore parents welcome the thought
                        of going home but in the interim they might be anxious &amp; nervous. Doctors will have to be completely sure about the stability of the child’s health and that alone is the primary factor in deciding the appropriate date for allowing
                        you to go home.</p>
                    <p>The team at Rainbow Hospitals will look to understand your state of mind and preparedness to handle the health of the baby.</p>
                    <p>
                        <strong>a. Multiple Babies</strong>
                    </p>
                    <p>In case of multiple babies it is possible that one of them makes a quicker recovery than the other is ready to go home earlier than the others. Depending on various factors it is possible for one of them to leave the hospital early
                        or in certain other conditions keep them both in the hospital and allow them to leave together.</p>
                    <p>
                        <strong>b. Length of Hospital Stay</strong>
                    </p>
                    <p>The length of your baby’s hospital stay is completely dependent on the progress he or she is making. A regular growth pattern must be evidenced as well as the vital signs should be normal and there should be no immediate or pertinent
                        threat to recurrence of the problems for the child to be allowed to go home.</p>
                    <p>
                        <strong>c. Growth of the Baby at Home </strong>
                    </p>
                    <p>A child whether premature or normal born, will grow as per the normal timescale. Certain variances are normal and parents need not worry unduly about the growth of a premature baby. With adequate nutrition, appropriate care and timely
                        attention to emerging medical conditions will ensure that your baby grows as normal as any.</p>
                </div>
            </div>
            <div class="row nsg_text">
                <div class="col-xs-12 col-lg-12">
                    <h2>Preparing to go home</h2>
                    <p>As the D-Day arrives and you are ready to go home, it’s a day filled with excitement and anticipation. The worries of the hospital stay can now be put behind and you can look forward to spending time with your child in the comfort
                        of your own home. In most cases, parents will receive an advance notice from Hospital on the date of remission from the hospital. It is good to start preparing in advance and not leave things to the last minute.</p>
                    <ul>
                        <li>Take a complete debrief from the doctor on the dos &amp; don’t.</li>
                        <li>Make sure you have all the reports &amp; documentation for future reference.</li>
                        <li>Ensure that your home is cleaned and ready to welcome the child home.</li>
                        <li>Settle your hospital bills in advance.</li>
                        <li>Have a list of emergency contacts handy.</li>
                        <li>Have adequate and comfortable transportation arranged.</li>
                    </ul>
                </div>
            </div>
            <div class="row nsg_text">
                <div class="col-xs-12 col-lg-12">
                    <h2>Before you leave</h2>
                    <p>Prior to stepping out of the neonatal ward, it is good to prepare for yourself a checklist of things do, such that you don’t miss out on something important. This checklist is to ensure that you are able to take adequate care of your
                        child while he or she is under your care at your home.</p>
                    <ul>
                        <li>Learn how to make your baby feel comfortable and reassured.</li>
                        <li>Learn how to bathe your baby</li>
                        <li>Learn how to administer medication to your baby</li>
                        <li>Learn how to use specialized equipment, if any</li>
                        <li>Learn how to keep your baby’s bottles, feeds sterile</li>
                        <li>Learn how to perform emergency CPR</li>
                        <li>Learn how to check and monitor temperature</li>
                        <li>Learn how to breastfeed your child</li>
                    </ul>
                    <p>The staff at Rainbow Hospitals will be happy to extend to your all the possible help and support that you need to get home and take the best care of your child.</p>
                </div>
            </div>
            <div class="row nsg_text">
                <div class="col-xs-12 col-lg-12">
                    <h2>Going home on oxygen</h2>
                    <p>If the baby has been earmarked for discharge, it means that the staff at Rainbow Hospitals neonatology department are confident of his or her health condition. However, the child’s health will sometimes warrant that he or she be sent
                        home on oxygen.</p>
                    <p>Rainbow Hospitals will help you understand the need and modalities of administering oxygen while you care for the baby at home. They will help you work with an authorized vendor in your area to help you have adequate supply and support.
                    </p>
                    <p>Sometimes, it is necessary to ensure that the baby is on oxygen support because he or she may not be receiving adequate amount of it. This is normal and should subside over a period of time.</p>
                </div>
            </div>
            <div class="row nsg_text">
                <div class="col-xs-12 col-lg-12">
                    <h2>Transporting your Baby</h2>
                    <p>Prior to discharge, parents must ensure that safe and adequate means of transport have been arranged to take your baby home. The baby is coming out from a sterile and clean environment and you ought to ensure that during the transit
                        he or she is well protected from the elements.</p>
                    <p>Driving home, you can avoid high traffic density areas and find a route that is the quickest to get home. You may time your discharge to non-peak traffic hours. Ensuring that the baby is safe from pollution, jerks and bumps on the
                        way is also essential. </p>
                </div>
            </div>
        </div>
    </section>




    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>