<div class="container-fluid">
    <div class="loder"></div>
    <div class="background">
        <img src="assets/images/bg_childcare.png" data-t="bg_01_00.jpg" data-d="bg_childcare.png" class="bgImg bg0 active" />
    </div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>
    <section class="page pageheader">
        <div class="row">
            <div class="container">
                <div class="col-xs-12 col-sm-7 col-lg-7 abannimgwrp">
                    <div class="aboutbannimg"><img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" /></div>
                </div>
                <div class="col-xs-12 col-sm-5 col-lg-5">
                    <div class="ptitle blue">
                        <h1> {{title}}</h1>
                        <h3> {{subtitle}} </h3>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="container-fluid aboutbod">
        <div class="container">
            <ul class="Bordofdirector">
                <li *ngFor="let member of board;">

                    <div class="leftsec col-xs-12 col-sm-12 col-md-3 col-lg-3 ">
                        <div class="imagedirectors"><img src="{{imgBasePath}}{{member.image}}" class="img-responsive" alt="" /></div>
                        <h3>{{member.name}}</h3>
                        <p>{{member.designation}}</p>
                        <a href="{{member.link}}" class="btn-Bookapopoi" *ngIf="member.link != '#'">View Profile</a> </div>
                    <div class="rightsec col-xs-12 col-sm-12 col-md-9 col-lg-9 ">
                        <p>{{member.description}}</p>
                    </div>
                </li>

            </ul>
        </div>
    </section>
    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>

    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>