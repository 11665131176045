import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL } from '../services/constants';
import { Meta, Title } from '@angular/platform-browser';
declare let $: any;
declare let initHome: any;
declare let initAllPage: any;

declare let initAboutTabs: any;

@Component({
  selector: 'contact-us',
  templateUrl: './contact-us.component.html',
})
export class ContactUsComponent implements OnDestroy, OnInit, AfterViewInit {
  public imgBasePath = '';
  public hidden = 'hidden';
  public logo = 'logo';
  public bread_crumb_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'About', link: '/about-us', class: '' },
    { label: 'Contact us', link: '', class: 'active' },
  ];
  public pageData: any = [];
  public contactus_list: any[];
  public vaccines: any;
  public emergencyContact: any;
  public corporate: any;
  constructor(
    private api: ApiService,
    public metaTitle: Title,
    public meta: Meta
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
    }
  }

  //@ViewChildren('contact-us') things: QueryList<any>;

  ngOnInit() {
    this.api.getPageDescription('contact-us').subscribe(
      (data) => {
        this.pageData = data.page_descriptions[0];
        //console.log(this.pageData);
        this.imgBasePath = IMG_BASE_URL;
        if (data) {
          //console.log(data);
          this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
          this.meta.updateTag({
            name: 'keywords',
            content: data.page_descriptions[0].meta_keyword,
          });
          this.meta.updateTag({
            name: 'description',
            content: data.page_descriptions[0].meta_description,
          });
          this.meta.addTags([
            {
              property: 'og:title',
              content: data.page_descriptions[0].meta_title,
            },
            {
              property: 'og:description',
              content: data.page_descriptions[0].meta_description,
            },
            {
              property: 'og:keywords',
              content: data.page_descriptions[0].meta_keyword,
            },
          ]);
        }
      },
      (error) => {
        console.log(error);
      }
    );

    this.api
      .getContactUsDetails()
      .map((res) => res)
      .subscribe((res) => {
        this.contactus_list = res.telephonic;
        this.vaccines = res.vaccines;
        this.emergencyContact = res.emergencyAndContact;
        this.corporate = res.corporate;
      });
  }

  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
    }

    // this.things.changes.subscribe(t => {
    //   // this.ngForRendred();
    // //  initAboutTabs();
    // })
  }

  ngOnDestroy() {}
}
