<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>

    <section class="page wtwedo">
        <div class="row">
            <div class="col-md-5 col-xs-5 col-lg-4">
                <div class="circuleframe">
                    <img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" />
                </div>
                <!-- End Circle -->
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7">
                <div class="pagetitle">
                    <h1 class="tgradient">{{title}} </h1>
                    <div class="underline">
                        <img src="assets/images/underline2.png" alt="" />
                    </div>
                    <h3>{{subtitle}}</h3>
                </div>
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7 mcontentview">
                <p [innerHTML]="description"></p>
            </div>
        </div>

    </section>
    <section class="page mediaSection">
        <div class="row">
            <div class="container">
                <div class="col-xs-12 PressReleases news_text">
                    <h2>Pregnancy</h2>
                    <div class="row" mymatchheight="PressReleasesBox" ng-reflect-my-match-height="PressReleasesBox">
                        <div class="col-xs-12 col-sm-6 PressReleasesBox paddrt">
                            <h4>Blood transfusion during pregnancy and birth</h4>
                            <p>Most transfusions during pregnancy and after birth are given as red blood cells only. Very occasionally, platelets and plasma may be required as well.</p>
                            <p><a class="readMore" href="/insights/pregnancy/blood-transfusion-during-pregnancy-and-birth">Read More</a></p>
                        </div>
                        <div class="col-xs-12 col-sm-6 PressReleasesBox">
                            <h4>Frequently Asked Questions in Pregnancy</h4>
                            <p>What you are feeling now are called Braxton Hicks contractions. They occur due to tightening of the uterine muscles…</p>
                            <p><a class="readMore" href="/insights/pregnancy/frequently-asked-questions-in-pregnancy">Read More</a></p>
                        </div>
                        <div class="clearfix"></div>
                        <div class="col-xs-12 col-sm-6 PressReleasesBox paddrt">
                            <h4>Exercise in Pregnancy</h4>
                            <p>During pregnancy, exercise is considered to be safe and beneficial. The aim of exercise during pregnancy is to stay fit…</p>
                            <p><a class="readMore" href="/insights/pregnancy/exercise-in-pregnancy">Read More</a></p>
                        </div>
                        <div class="col-xs-12 col-sm-6 PressReleasesBox">
                            <h4>Pregnancy And Gastrointestinal System</h4>
                            <p>Symptoms of nausea, vomiting are common during early pregnancy. Around half of all pregnant women will experience both nausea and vomiting. Nausea and vomiting in pregnancy (NVP) is an unwelcome, but perfectly normal, part
                                of pregnancy and it does not put your baby at any increased risk…</p>
                            <p><a class="readMore" href="/insights/pregnancy/pregnancy-and-gastrointestinal-system">Read More</a></p>
                        </div>
                        <div class="col-xs-12 col-sm-6 PressReleasesBox">
                            <h4>Testing Blood Group in Pregnancy</h4>
                            <p>There are four main blood groups: A, B, AB and O thatare determined by the types of antigens on the blood cells. Each red blood cell has identifiers on its surface which correspond to the type of blood that it is…</p>
                            <p><a class="readMore" href="/insights/pregnancy/testing-blood-group-in-pregnancy">Read More</a></p>
                        </div>
                        <div class="col-xs-12 col-sm-6 PressReleasesBox">
                            <h4>Multiple Pregnancy : Twins</h4>
                            <p>A multiple pregnancy refers to the condition in which a woman is expecting two or more babies. Multiple pregnancy can happen in the following conditions…</p>
                            <p><a class="readMore" href="/insights/pregnancy/multiple-pregnancy-twins">Read More</a></p>
                        </div>
                        <div class="col-xs-12 col-sm-6 PressReleasesBox">
                            <h4>Testing Blood Group in Pregnancy : The Rhesus Problem</h4>
                            <p>Early in your pregnancy you should be offered tests to find out your blood group and your Rhesus D (RhD) status. There are four main blood groups: A, B, AB and O (the commonest), which are carried on red blood cells…</p>
                            <p><a class="readMore" href="/insights/pregnancy/testing-blood-group-in-pregnancy-the-rhesus-problem">Read More</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <ng-template [ngIf]="ins_links.length > 0">
        <footer-nav-mini [links]="ins_links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>