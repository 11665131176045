import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-cusp-failure',
  templateUrl: './cusp-failure.component.html',
  styleUrls: ['./cusp-failure.component.scss'],
})
export class CuspFailureComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}
  public tid: string = '';
  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      console.log(params); // { orderby: "price" }
      this.tid = params.tid;
      console.log(this.tid); // price
    });
  }
}
