<!-- <!DOCTYPE html>-->
<!-- <html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    />
    <meta http-equiv="X-UA-Compatible" content="ie=edge" /> -->

<!-- Google Tag Manager-->

<!-- <script>
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
          "gtm.start": new Date().getTime(),
          event: "gtm.js",
        });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", "GTM-TZ372NN");
    </script> -->

<!-- End Google Tag Manager-->

<!-- Global site tag (gtag.js)-Google Ads: 975509116-->
<!-- <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=AW-975509116"
    ></script>
    <script>
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("js", new Date());

      gtag("config", "AW-975509116");
    </script> -->

<!-- Event snippet for All_Leads conversion page-->
<!-- <script>
      gtag("event", "conversion", {
        send_to: "AW-975509116/Ux_-CMbgj4gBEPyslNED",
      });
    </script> -->

<!-- <title>
      Online Video Consultations-Hello Doctor by Rainbow Children’s Hospital
    </title>
    <meta
      name="title"
      content="Online Video Consultations-Hello Doctor by Rainbow Children’s Hospital"
    />
    <meta
      name="description"
      content="Hello Doctor, a precautionary measure against COVID-19. We are proud to announce the child & women care facility by video consultation. #StayHome #StaySafe"
    />

    <link rel="stylesheet" href="css/jquery-ui.css" />
    <link rel="stylesheet" href="css/bootstrap.min.css" />
    <link rel="stylesheet" href="css/css2.css" /> -->

<!-- Open Graph / Facebook-->
<!-- <meta property="og:type" content="website" />
    <meta
      property="og:url"
      content="https://www.rainbowhospitals.in/hello-doctor-online-consultation/"
    />
    <meta
      property="og:title"
      content="Online Video Consultations-Hello Doctor by Rainbow Children’s Hospital"
    />
    <meta
      property="og:description"
      content="Hello Doctor, a precautionary measure against COVID-19. We are proud to announce the child & women care facility by video consultation. #StayHome #StaySafe"
    />
    <meta
      property="og:image"
      content="https://www.rainbowhospitals.in/hello-doctor-online-consultation/assets/images/rainbowchildrenshospital.png"
    /> -->

<!-- Twitter-->
<!-- <meta property="twitter:card" content="summary_large_image" />
    <meta
      property="twitter:url"
      content="https://www.rainbowhospitals.in/hello-doctor-online-consultation/"
    />
    <meta
      property="twitter:title"
      content="Online Video Consultations-Hello Doctor by Rainbow Children’s Hospital"
    />
    <meta
      property="twitter:description"
      content="Hello Doctor, a precautionary measure against COVID-19. We are proud to announce the child & women care facility by video consultation. #StayHome #StaySafe"
    />
    <meta
      property="twitter:image"
      content="https://www.rainbowhospitals.in/hello-doctor-online-consultation/assets/images/rainbowchildrenshospital.png"
    />

    <meta
      name="google-site-verification"
      content="Lk0eZm9YdfOxf2keptj7-AidKIxxPBlbTDi5u3KSKyw"
    /> -->
<!-- </head> -->

<!-- <body> -->
<!-- Google Tag Manager (noscript)-->
<div class="loader"></div>
<noscript
  ><iframe
    src="https://www.googletagmanager.com/ns.html?id=GTM-TZ372NN"
    height="0"
    width="0"
    style="display: none; visibility: hidden"
  ></iframe
></noscript>

<!-- End Google Tag Manager (noscript)-->

<div class="container-fluid">
  <div class="row header">
    <div class="container">
      <a href="https://rainbowhospitals.in/" target="_blank"
        ><img src="assets/images/RCH-logo.png" alt=""
      /></a>
    </div>
  </div>
  <div class="row banner">
    <!--  <div class="img"></div> -->
    <div class="img2">
      <div class="textBoxWrapper" style="padding: 50px">
        <h1>
          <div
            class="textgradient mainText banner-head"
            style="
              background: -webkit-linear-gradient(
                left,
                #48327c 10%,
                #48327c 40%
              );
              -webkit-background-clip: text;
            "
          >
            YOU ARE SAFE WITH US!
          </div>
        </h1>
      </div>
    </div>
    <div class="container">
      <div class="textBoxWrapper"></div>
      <div class="formWrapper">
        <div class="thankYoy Success">
          <div class="row">
            <img
              src="assets/images/Success.png"
              style="margin: 0 auto 10px auto"
              alt=""
            />
            <h2>Success!</h2>
            <h3>
              Thank you for making the payment of Rs.600 for the Transaction ID
              XXX.
            </h3>
            <p>
              our video consultation with
              <span class="boldText">Dr.Vimal Nair</span> booked on behalf of
              <span class="boldText">Miss Renu Verma</span>, is confirmed. We
              will get in touch with you and share further updates on
              98xxxxxxxx.
            </p>
          </div>
        </div>
        <div class="thankYoy failed">
          <div class="row">
            <img
              src="assets/images/Opps.png"
              style="margin: 0 auto 10px auto"
              alt=""
            />
            <h2>Oops!</h2>
            <h3 style="width: 100%">Something went wrong.</h3>
            <p>
              There was a problem in completing the payment for the Transaction
              ID: xxx. Don't worry, you have not been charged. Would you like to
              try again?
            </p>

            <div class="form-group text-center" style="width: 100%">
              <button class="submitBtn">RETRY</button>
              <button class="submitBtn">HOME</button>
            </div>
          </div>
        </div>

        <form
          id="form"
          [formGroup]="formdata"
          (ngSubmit)="formSubmit(formdata.value)"
        >
          <!-- <form id="form">-->
          <div class="headText">
            BOOK A VIDEO CONSULTATION
            <!--  <span>*All fields are mandatory</span>-->
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control Personal"
                  id="Patient"
                  name="Patient"
                  formControlName="Patient"
                  placeholder="*Name of Patient"
                  required
                />
                <div
                  *ngIf="Patient.invalid && (Patient.dirty || Patient.touched) && formdata.touched"
                  class="alert alert-danger"
                >
                  <div *ngIf="Patient.errors?.['required']">Name is required.</div>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control Personal"
                  id="Guardian"
                  name="Guardian"
                  formControlName="Guardian"
                  placeholder="*Name of Guardian"
                  required
                />
                <div
                  *ngIf="Guardian.invalid && (Guardian.dirty || Guardian.touched) && formdata.touched"
                  class="alert alert-danger"
                >
                  <div *ngIf="Guardian.errors?.['required']">Guardian is required.</div>
                </div>
              </div>
            </div>

            <!-- <div class="col-sm-6">
                                <div class="form-group">


                                    <div class="error"></div>
                                </div>
                            </div> -->

            <div class="col-sm-6">
              <div class="form-group">
                <select
                  name="Countrycode"
                  style="width: 50%; float: left"
                  onchange="setSymbol(event)"
                  formControlName="Countrycode"
                  class="form-control Countrycode"
                  id="Countrycode"
                  required=""
                >
                  <option data-countryCode="IN" value="91-India" selected>
                    India (+91)
                  </option>
                  <optgroup label="Other countries">
                    <option data-countryCode="AF" value="93-Afghanistan">
                      Afghanistan (+ 93)
                    </option>
                    <option data-countryCode="AL" value="355-Albania">
                      Albania (+ 355)
                    </option>
                    <option data-countryCode="DZ" value="213-Algeria">
                      Algeria (+ 213)
                    </option>
                    <option data-countryCode="ASM" value="1684-American Samoa">
                      American Samoa (+ 1684)
                    </option>
                    <option data-countryCode="AD" value="376-Andorra">
                      Andorra (+ 376)
                    </option>
                    <option data-countryCode="AO" value="244-Angola">
                      Angola (+ 244)
                    </option>
                    <option data-countryCode="AIA" value="1264-Anguilla">
                      Anguilla (+ 1264)
                    </option>
                    <option data-countryCode="ATA" value="672-Antarctica">
                      Antarctica (+ 672)
                    </option>
                    <option
                      data-countryCode="AG"
                      value="1268-Antigua and Barbuda"
                    >
                      Antigua and Barbuda (+ 1268)
                    </option>
                    <option data-countryCode="AR" value="54-Argentina">
                      Argentina (+ 54)
                    </option>
                    <option data-countryCode="AM" value="374-Armenia">
                      Armenia (+ 374)
                    </option>
                    <option data-countryCode="ABW" value="297-Aruba">
                      Aruba (+ 297)
                    </option>
                    <option data-countryCode="AU" value="61-Australia">
                      Australia (+ 61)
                    </option>
                    <option data-countryCode="AT" value="43-Austria">
                      Austria (+ 43)
                    </option>
                    <option data-countryCode="AZ" value="994-Azerbaijan">
                      Azerbaijan (+ 994)
                    </option>
                    <option data-countryCode="BS" value="1242-Bahamas">
                      Bahamas (+ 1242)
                    </option>
                    <option data-countryCode="BH" value="973-Bahrain">
                      Bahrain (+ 973)
                    </option>
                    <option data-countryCode="BD" value="880-Bangladesh">
                      Bangladesh (+ 880)
                    </option>
                    <option data-countryCode="BB" value="1246-Barbados">
                      Barbados (+ 1246)
                    </option>
                    <option data-countryCode="BY" value="375-Belarus">
                      Belarus (+ 375)
                    </option>
                    <option data-countryCode="BE" value="32-Belgium">
                      Belgium (+ 32)
                    </option>
                    <option data-countryCode="BZ" value="501-Belize">
                      Belize (+ 501)
                    </option>
                    <option data-countryCode="BJ" value="229-Benin">
                      Benin (+ 229)
                    </option>
                    <option data-countryCode="BMU" value="1441-Bermuda">
                      Bermuda (+ 1441)
                    </option>
                    <option data-countryCode="BT" value="975-Bhutan">
                      Bhutan (+ 975)
                    </option>
                    <option data-countryCode="BO" value="591-Bolivia">
                      Bolivia (+ 591)
                    </option>
                    <option
                      data-countryCode="BA"
                      value="387-Bosnia and Herzegovina"
                    >
                      Bosnia and Herzegovina (+ 387)
                    </option>
                    <option data-countryCode="BW" value="267-Botswana">
                      Botswana (+ 267)
                    </option>
                    <option data-countryCode="BR" value="55-Brazil">
                      Brazil (+ 55)
                    </option>
                    <option
                      data-countryCode="IO"
                      value="246-British Indian Ocean Territory"
                    >
                      British Indian Ocean Territory (+ 246)
                    </option>
                    <option
                      data-countryCode="VG"
                      value="1284-British Virgin Islands"
                    >
                      British Virgin Islands (+ 1284)
                    </option>
                    <option data-countryCode="BN" value="673-Brunei">
                      Brunei (+ 673)
                    </option>
                    <option data-countryCode="BG" value="359-Bulgaria">
                      Bulgaria (+ 359)
                    </option>
                    <option data-countryCode="BF" value="226-Burkina Faso">
                      Burkina Faso (+ 226)
                    </option>
                    <option data-countryCode="BI" value="257-Burundi">
                      Burundi (+ 257)
                    </option>
                    <option data-countryCode="KH" value="855-Cambodia">
                      Cambodia (+ 855)
                    </option>
                    <option data-countryCode="CM" value="237-Cameroon">
                      Cameroon (+ 237)
                    </option>
                    <option data-countryCode="CA" value="1-Canada">
                      Canada (+ 1)
                    </option>
                    <option data-countryCode="CV" value="238-Cape Verde">
                      Cape Verde (+ 238)
                    </option>
                    <option data-countryCode="KY" value="1345-Cayman Islands">
                      Cayman Islands (+ 1345)
                    </option>
                    <option
                      data-countryCode="CF"
                      value="236-Central African Republic"
                    >
                      Central African Republic (+ 236)
                    </option>
                    <option data-countryCode="TD" value="235-Chad">
                      Chad (+ 235)
                    </option>
                    <option data-countryCode="CL" value="56-Chile">
                      Chile (+ 56)
                    </option>
                    <option data-countryCode="CN" value="86-China">
                      China (+ 86)
                    </option>
                    <option data-countryCode="CX" value="61-Christmas Island">
                      Christmas Island (+ 61)
                    </option>
                    <option data-countryCode="CC" value="61-Cocos Islands">
                      Cocos Islands (+ 61)
                    </option>
                    <option data-countryCode="CO" value="57-Colombia">
                      Colombia (+ 57)
                    </option>
                    <option data-countryCode="KM" value="269-Comoros">
                      Comoros (+ 269)
                    </option>
                    <option data-countryCode="CK" value="682-Cook Islands">
                      Cook Islands (+ 682)
                    </option>
                    <option data-countryCode="CR" value="506-Costa Rica">
                      Costa Rica (+ 506)
                    </option>
                    <option data-countryCode="HR" value="385-Croatia">
                      Croatia (+ 385)
                    </option>
                    <option data-countryCode="CU" value="53-Cuba">
                      Cuba (+ 53)
                    </option>
                    <option data-countryCode="CW" value="599-Curacao">
                      Curacao (+ 599)
                    </option>
                    <option data-countryCode="CY" value="357-Cyprus">
                      Cyprus (+ 357)
                    </option>
                    <option data-countryCode="CZ" value="420-Czech Republic">
                      Czech Republic (+ 420)
                    </option>
                    <option
                      data-countryCode="CD"
                      value="243-Democratic Republic of the Congo"
                    >
                      Democratic Republic of the Congo (+ 243)
                    </option>
                    <option data-countryCode="DK" value="45-Denmark">
                      Denmark (+ 45)
                    </option>
                    <option data-countryCode="DJ" value="253-Djibouti">
                      Djibouti (+ 253)
                    </option>
                    <option data-countryCode="DM" value="1767-Dominica">
                      Dominica (+ 1767)
                    </option>
                    <option
                      data-countryCode="DO"
                      value="1809-Dominican Republic"
                    >
                      Dominican Republic (+ 1809)
                    </option>
                    <option data-countryCode="TL" value="670-East Timor">
                      East Timor (+ 670)
                    </option>
                    <option data-countryCode="EC" value="593-Ecuador">
                      Ecuador (+ 593)
                    </option>
                    <option data-countryCode="EG" value="20-Egypt">
                      Egypt (+ 20)
                    </option>
                    <option data-countryCode="SV" value="503-El Salvador">
                      El Salvador (+ 503)
                    </option>
                    <option data-countryCode="GQ" value="240-Equatorial Guinea">
                      Equatorial Guinea (+ 240)
                    </option>
                    <option data-countryCode="ER" value="291-Eritrea">
                      Eritrea (+ 291)
                    </option>
                    <option data-countryCode="EE" value="372-Estonia">
                      Estonia (+ 372)
                    </option>
                    <option data-countryCode="ET" value="251-Ethiopia">
                      Ethiopia (+ 251)
                    </option>
                    <option data-countryCode="FK" value="500-Falkland Islands">
                      Falkland Islands (+ 500)
                    </option>
                    <option data-countryCode="FO" value="298-Faroe Islands">
                      Faroe Islands (+ 298)
                    </option>
                    <option data-countryCode="FJ" value="679-Fiji">
                      Fiji (+ 679)
                    </option>
                    <option data-countryCode="FI" value="358-Finland">
                      Finland (+ 358)
                    </option>
                    <option data-countryCode="FR" value="33-France">
                      France (+ 33)
                    </option>
                    <option data-countryCode="PF" value="689-French Polynesia">
                      French Polynesia (+ 689)
                    </option>
                    <option data-countryCode="GA" value="241-Gabon">
                      Gabon (+ 241)
                    </option>
                    <option data-countryCode="GM" value="220-Gambia">
                      Gambia (+ 220)
                    </option>
                    <option data-countryCode="GE" value="995-Georgia">
                      Georgia (+ 995)
                    </option>
                    <option data-countryCode="DE" value="49-Germany">
                      Germany (+ 49)
                    </option>
                    <option data-countryCode="GH" value="233-Ghana">
                      Ghana (+ 233)
                    </option>
                    <option data-countryCode="GI" value="350-Gibraltar">
                      Gibraltar (+ 350)
                    </option>
                    <option data-countryCode="GR" value="30-Greece">
                      Greece (+ 30)
                    </option>
                    <option data-countryCode="GL" value="299-Greenland">
                      Greenland (+ 299)
                    </option>
                    <option data-countryCode="GD" value="1473-Grenada">
                      Grenada (+ 1473)
                    </option>
                    <option data-countryCode="GU" value="1671-Guam">
                      Guam (+ 1671)
                    </option>
                    <option data-countryCode="GT" value="502-Guatemala">
                      Guatemala (+ 502)
                    </option>
                    <option data-countryCode="GG" value="441481-Guernsey">
                      Guernsey (+ 441481)
                    </option>
                    <option data-countryCode="GN" value="224-Guinea">
                      Guinea (+ 224)
                    </option>
                    <option data-countryCode="GW" value="245-GuineaBissau">
                      GuineaBissau (+ 245)
                    </option>
                    <option data-countryCode="GY" value="592-Guyana">
                      Guyana (+ 592)
                    </option>
                    <option data-countryCode="HT" value="509-Haiti">
                      Haiti (+ 509)
                    </option>
                    <option data-countryCode="HN" value="504-Honduras">
                      Honduras (+ 504)
                    </option>
                    <option data-countryCode="HK" value="852-Hong Kong">
                      Hong Kong (+ 852)
                    </option>
                    <option data-countryCode="HU" value="36-Hungary">
                      Hungary (+ 36)
                    </option>
                    <option data-countryCode="IS" value="354-Iceland">
                      Iceland (+ 354)
                    </option>
                    <option data-countryCode="IN" value="91-India">
                      India (+ 91)
                    </option>
                    <option data-countryCode="ID" value="62-Indonesia">
                      Indonesia (+ 62)
                    </option>
                    <option data-countryCode="IR" value="98-Iran">
                      Iran (+ 98)
                    </option>
                    <option data-countryCode="IQ" value="964-Iraq">
                      Iraq (+ 964)
                    </option>
                    <option data-countryCode="IE" value="353-Ireland">
                      Ireland (+ 353)
                    </option>
                    <option data-countryCode="IM" value="441624-Isle of Man">
                      Isle of Man (+ 441624)
                    </option>
                    <option data-countryCode="IL" value="972-Israel">
                      Israel (+ 972)
                    </option>
                    <option data-countryCode="IT" value="39-Italy">
                      Italy (+ 39)
                    </option>
                    <option data-countryCode="CI" value="225-Ivory Coast">
                      Ivory Coast (+ 225)
                    </option>
                    <option data-countryCode="JM" value="1876-Jamaica">
                      Jamaica (+ 1876)
                    </option>
                    <option data-countryCode="JP" value="81-Japan">
                      Japan (+ 81)
                    </option>
                    <option data-countryCode="JE" value="441534-Jersey">
                      Jersey (+ 441534)
                    </option>
                    <option data-countryCode="JO" value="962-Jordan">
                      Jordan (+ 962)
                    </option>
                    <option data-countryCode="KZ" value="7-Kazakhstan">
                      Kazakhstan (+ 7)
                    </option>
                    <option data-countryCode="KE" value="254-Kenya">
                      Kenya (+ 254)
                    </option>
                    <option data-countryCode="KI" value="686-Kiribati">
                      Kiribati (+ 686)
                    </option>
                    <option data-countryCode="XK" value="383-Kosovo">
                      Kosovo (+ 383)
                    </option>
                    <option data-countryCode="KW" value="965-Kuwait">
                      Kuwait (+ 965)
                    </option>
                    <option data-countryCode="KG" value="996-Kyrgyzstan">
                      Kyrgyzstan (+ 996)
                    </option>
                    <option data-countryCode="LA" value="856-Laos">
                      Laos (+ 856)
                    </option>
                    <option data-countryCode="LV" value="371-Latvia">
                      Latvia (+ 371)
                    </option>
                    <option data-countryCode="LB" value="961-Lebanon">
                      Lebanon (+ 961)
                    </option>
                    <option data-countryCode="LS" value="266-Lesotho">
                      Lesotho (+ 266)
                    </option>
                    <option data-countryCode="LR" value="231-Liberia">
                      Liberia (+ 231)
                    </option>
                    <option data-countryCode="LY" value="218-Libya">
                      Libya (+ 218)
                    </option>
                    <option data-countryCode="LI" value="423-Liechtenstein">
                      Liechtenstein (+ 423)
                    </option>
                    <option data-countryCode="LT" value="370-Lithuania">
                      Lithuania (+ 370)
                    </option>
                    <option data-countryCode="LU" value="352-Luxembourg">
                      Luxembourg (+ 352)
                    </option>
                    <option data-countryCode="MO" value="853-Macau">
                      Macau (+ 853)
                    </option>
                    <option data-countryCode="MK" value="389-Macedonia">
                      Macedonia (+ 389)
                    </option>
                    <option data-countryCode="MG" value="261-Madagascar">
                      Madagascar (+ 261)
                    </option>
                    <option data-countryCode="MW" value="265-Malawi">
                      Malawi (+ 265)
                    </option>
                    <option data-countryCode="MY" value="60-Malaysia">
                      Malaysia (+ 60)
                    </option>
                    <option data-countryCode="MV" value="960-Maldives">
                      Maldives (+ 960)
                    </option>
                    <option data-countryCode="ML" value="223-Mali">
                      Mali (+ 223)
                    </option>
                    <option data-countryCode="MT" value="356-Malta">
                      Malta (+ 356)
                    </option>
                    <option data-countryCode="MH" value="692-Marshall Islands">
                      Marshall Islands (+ 692)
                    </option>
                    <option data-countryCode="MR" value="222-Mauritania">
                      Mauritania (+ 222)
                    </option>
                    <option data-countryCode="MU" value="230-Mauritius">
                      Mauritius (+ 230)
                    </option>
                    <option data-countryCode="YT" value="262-Mayotte">
                      Mayotte (+ 262)
                    </option>
                    <option data-countryCode="MX" value="52-Mexico">
                      Mexico (+ 52)
                    </option>
                    <option data-countryCode="FM" value="691-Micronesia">
                      Micronesia (+ 691)
                    </option>
                    <option data-countryCode="MD" value="373-Moldova">
                      Moldova (+ 373)
                    </option>
                    <option data-countryCode="MC" value="377-Monaco">
                      Monaco (+ 377)
                    </option>
                    <option data-countryCode="MN" value="976-Mongolia">
                      Mongolia (+ 976)
                    </option>
                    <option data-countryCode="ME" value="382-Montenegro">
                      Montenegro (+ 382)
                    </option>
                    <option data-countryCode="MS" value="1664-Montserrat">
                      Montserrat (+ 1664)
                    </option>
                    <option data-countryCode="MA" value="212-Morocco">
                      Morocco (+ 212)
                    </option>
                    <option data-countryCode="MZ" value="258-Mozambique">
                      Mozambique (+ 258)
                    </option>
                    <option data-countryCode="MM" value="95-Myanmar">
                      Myanmar (+ 95)
                    </option>
                    <option data-countryCode="NA" value="264-Namibia">
                      Namibia (+ 264)
                    </option>
                    <option data-countryCode="NR" value="674-Nauru">
                      Nauru (+ 674)
                    </option>
                    <option data-countryCode="NP" value="977-Nepal">
                      Nepal (+ 977)
                    </option>
                    <option data-countryCode="NL" value="31-Netherlands">
                      Netherlands (+ 31)
                    </option>
                    <option
                      data-countryCode="AN"
                      value="599-Netherlands Antilles"
                    >
                      Netherlands Antilles (+ 599)
                    </option>
                    <option data-countryCode="NC" value="687-New Caledonia">
                      New Caledonia (+ 687)
                    </option>
                    <option data-countryCode="NZ" value="64-New Zealand">
                      New Zealand (+ 64)
                    </option>
                    <option data-countryCode="NI" value="505-Nicaragua">
                      Nicaragua (+ 505)
                    </option>
                    <option data-countryCode="NE" value="227-Niger">
                      Niger (+ 227)
                    </option>
                    <option data-countryCode="NG" value="234-Nigeria">
                      Nigeria (+ 234)
                    </option>
                    <option data-countryCode="NU" value="683-Niue">
                      Niue (+ 683)
                    </option>
                    <option data-countryCode="KP" value="850-North Korea">
                      North Korea (+ 850)
                    </option>
                    <option
                      data-countryCode="MP"
                      value="1670-Northern Mariana Islands"
                    >
                      Northern Mariana Islands (+ 1670)
                    </option>
                    <option data-countryCode="NO" value="47-Norway">
                      Norway (+ 47)
                    </option>
                    <option data-countryCode="OM" value="968-Oman">
                      Oman (+ 968)
                    </option>
                    <option data-countryCode="PK" value="92-Pakistan">
                      Pakistan (+ 92)
                    </option>
                    <option data-countryCode="PW" value="680-Palau">
                      Palau (+ 680)
                    </option>
                    <option data-countryCode="PS" value="970-Palestine">
                      Palestine (+ 970)
                    </option>
                    <option data-countryCode="PA" value="507-Panama">
                      Panama (+ 507)
                    </option>
                    <option data-countryCode="PG" value="675-Papua New Guinea">
                      Papua New Guinea (+ 675)
                    </option>
                    <option data-countryCode="PY" value="595-Paraguay">
                      Paraguay (+ 595)
                    </option>
                    <option data-countryCode="PE" value="51-Peru">
                      Peru (+ 51)
                    </option>
                    <option data-countryCode="PH" value="63-Philippines">
                      Philippines (+ 63)
                    </option>
                    <option data-countryCode="PN" value="64-Pitcairn">
                      Pitcairn (+ 64)
                    </option>
                    <option data-countryCode="PL" value="48-Poland">
                      Poland (+ 48)
                    </option>
                    <option data-countryCode="PT" value="351-Portugal">
                      Portugal (+ 351)
                    </option>
                    <option data-countryCode="PR" value="1787-Puerto Rico">
                      Puerto Rico (+ 1787)
                    </option>
                    <option data-countryCode="QA" value="974-Qatar">
                      Qatar (+ 974)
                    </option>
                    <option
                      data-countryCode="CG"
                      value="242-Republic of the Congo"
                    >
                      Republic of the Congo (+ 242)
                    </option>
                    <option data-countryCode="RE" value="262-Reunion">
                      Reunion (+ 262)
                    </option>
                    <option data-countryCode="RO" value="40-Romania">
                      Romania (+ 40)
                    </option>
                    <option data-countryCode="RU" value="7-Russia">
                      Russia (+ 7)
                    </option>
                    <option data-countryCode="RW" value="250-Rwanda">
                      Rwanda (+ 250)
                    </option>
                    <option data-countryCode="BL" value="590-Saint Barthelemy">
                      Saint Barthelemy (+ 590)
                    </option>
                    <option data-countryCode="SH" value="290-Saint Helena">
                      Saint Helena (+ 290)
                    </option>
                    <option
                      data-countryCode="KN"
                      value="1869-Saint Kitts and Nevis"
                    >
                      Saint Kitts and Nevis (+ 1869)
                    </option>
                    <option data-countryCode="LC" value="1758-Saint Lucia">
                      Saint Lucia (+ 1758)
                    </option>
                    <option data-countryCode="MF" value="590-Saint Martin">
                      Saint Martin (+ 590)
                    </option>
                    <option
                      data-countryCode="PM"
                      value="508-Saint Pierre and Miquelon"
                    >
                      Saint Pierre and Miquelon (+ 508)
                    </option>
                    <option
                      data-countryCode="VC"
                      value="1784-Saint Vincent and the Grenadines"
                    >
                      Saint Vincent and the Grenadines (+ 1784)
                    </option>
                    <option data-countryCode="WS" value="685-Samoa">
                      Samoa (+ 685)
                    </option>
                    <option data-countryCode="SM" value="378-San Marino">
                      San Marino (+ 378)
                    </option>
                    <option
                      data-countryCode="ST"
                      value="239-Sao Tome and Principe"
                    >
                      Sao Tome and Principe (+ 239)
                    </option>
                    <option data-countryCode="SA" value="966-Saudi Arabia">
                      Saudi Arabia (+ 966)
                    </option>
                    <option data-countryCode="SN" value="221-Senegal">
                      Senegal (+ 221)
                    </option>
                    <option data-countryCode="RS" value="381-Serbia">
                      Serbia (+ 381)
                    </option>
                    <option data-countryCode="SC" value="248-Seychelles">
                      Seychelles (+ 248)
                    </option>
                    <option data-countryCode="SL" value="232-Sierra Leone">
                      Sierra Leone (+ 232)
                    </option>
                    <option data-countryCode="SG" value="65-Singapore">
                      Singapore (+ 65)
                    </option>
                    <option data-countryCode="SX" value="1721-Sint Maarten">
                      Sint Maarten (+ 1721)
                    </option>
                    <option data-countryCode="SK" value="421-Slovakia">
                      Slovakia (+ 421)
                    </option>
                    <option data-countryCode="SI" value="386-Slovenia">
                      Slovenia (+ 386)
                    </option>
                    <option data-countryCode="SB" value="677-Solomon Islands">
                      Solomon Islands (+ 677)
                    </option>
                    <option data-countryCode="SO" value="252-Somalia">
                      Somalia (+ 252)
                    </option>
                    <option data-countryCode="ZA" value="27-South Africa">
                      South Africa (+ 27)
                    </option>
                    <option data-countryCode="KR" value="82-South Korea">
                      South Korea (+ 82)
                    </option>
                    <option data-countryCode="SS" value="211-South Sudan">
                      South Sudan (+ 211)
                    </option>
                    <option data-countryCode="ES" value="34-Spain">
                      Spain (+ 34)
                    </option>
                    <option data-countryCode="LK" value="94-Sri Lanka">
                      Sri Lanka (+ 94)
                    </option>
                    <option data-countryCode="SD" value="249-Sudan">
                      Sudan (+ 249)
                    </option>
                    <option data-countryCode="SR" value="597-Suriname">
                      Suriname (+ 597)
                    </option>
                    <option
                      data-countryCode="SJ"
                      value="47-Svalbard and Jan Mayen"
                    >
                      Svalbard and Jan Mayen (+ 47)
                    </option>
                    <option data-countryCode="SZ" value="268-Swaziland">
                      Swaziland (+ 268)
                    </option>
                    <option data-countryCode="SE" value="46-Sweden">
                      Sweden (+ 46)
                    </option>
                    <option data-countryCode="CH" value="41-Switzerland">
                      Switzerland (+ 41)
                    </option>
                    <option data-countryCode="SY" value="963-Syria">
                      Syria (+ 963)
                    </option>
                    <option data-countryCode="TW" value="886-Taiwan">
                      Taiwan (+ 886)
                    </option>
                    <option data-countryCode="TJ" value="992-Tajikistan">
                      Tajikistan (+ 992)
                    </option>
                    <option data-countryCode="TZ" value="255-Tanzania">
                      Tanzania (+ 255)
                    </option>
                    <option data-countryCode="TH" value="66-Thailand">
                      Thailand (+ 66)
                    </option>
                    <option data-countryCode="TG" value="228-Togo">
                      Togo (+ 228)
                    </option>
                    <option data-countryCode="TK" value="690-Tokelau">
                      Tokelau (+ 690)
                    </option>
                    <option data-countryCode="TO" value="676-Tonga">
                      Tonga (+ 676)
                    </option>
                    <option
                      data-countryCode="TT"
                      value="1868-Trinidad and Tobago"
                    >
                      Trinidad and Tobago (+ 1868)
                    </option>
                    <option data-countryCode="TN" value="216-Tunisia">
                      Tunisia (+ 216)
                    </option>
                    <option data-countryCode="TR" value="90-Turkey">
                      Turkey (+ 90)
                    </option>
                    <option data-countryCode="TM" value="993-Turkmenistan">
                      Turkmenistan (+ 993)
                    </option>
                    <option
                      data-countryCode="TC"
                      value="1649-Turks and Caicos Islands"
                    >
                      Turks and Caicos Islands (+ 1649)
                    </option>
                    <option data-countryCode="TV" value="688-Tuvalu">
                      Tuvalu (+ 688)
                    </option>
                    <option
                      data-countryCode="VI"
                      value="1340-U.S. Virgin Islands"
                    >
                      U.S. Virgin Islands (+ 1340)
                    </option>
                    <option data-countryCode="UG" value="256-Uganda">
                      Uganda (+ 256)
                    </option>
                    <option data-countryCode="UA" value="380-Ukraine">
                      Ukraine (+ 380)
                    </option>
                    <option
                      data-countryCode="AE"
                      value="971-United Arab Emirates"
                    >
                      United Arab Emirates (+ 971)
                    </option>
                    <option data-countryCode="GB" value="44-United Kingdom">
                      United Kingdom (+ 44)
                    </option>
                    <option data-countryCode="US" value="1-United States">
                      United States (+ 1)
                    </option>
                    <option data-countryCode="UY" value="598-Uruguay">
                      Uruguay (+ 598)
                    </option>
                    <option data-countryCode="UZ" value="998-Uzbekistan">
                      Uzbekistan (+ 998)
                    </option>
                    <option data-countryCode="VU" value="678-Vanuatu">
                      Vanuatu (+ 678)
                    </option>
                    <option data-countryCode="VA" value="379-Vatican">
                      Vatican (+ 379)
                    </option>
                    <option data-countryCode="VE" value="58-Venezuela">
                      Venezuela (+ 58)
                    </option>
                    <option data-countryCode="VN" value="84-Vietnam">
                      Vietnam (+ 84)
                    </option>
                    <option data-countryCode="WF" value="681-Wallis and Futuna">
                      Wallis and Futuna (+ 681)
                    </option>
                    <option data-countryCode="EH" value="212-Western Sahara">
                      Western Sahara (+ 212)
                    </option>
                    <option data-countryCode="YE" value="967-Yemen">
                      Yemen (+ 967)
                    </option>
                    <option data-countryCode="ZM" value="260-Zambia">
                      Zambia (+ 260)
                    </option>
                    <option data-countryCode="ZW" value="263-Zimbabwe">
                      Zimbabwe (+ 263)
                    </option>
                  </optgroup>
                </select>

                <input
                  type="text"
                  class="form-control Personal mobileNo"
                  id="Mobile"
                  style="width: 50%"
                  min="6"
                  maxlength="10"
                  name="Mobile"
                  placeholder="*Reg. Mobile"
                  onkeyup="isNumber(event)"
                  formControlName="Mobile"
                  required
                />
                <div
                  *ngIf="Mobile.invalid && (Mobile.dirty || Mobile.touched) && formdata.touched"
                  class="alert alert-danger"
                >
                  <div *ngIf="Mobile.errors?.['required']">Mobile is required.</div>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <input
                  type="email"
                  class="form-control Personal"
                  id="EmailId"
                  name="EmailId"
                  placeholder="*Reg. Email ID"
                  formControlName="Email"
                  required
                />
                <div
                  *ngIf="Email.invalid && (Email.dirty || Email.touched) && formdata.touched"
                  class="alert alert-danger"
                >
                  <div *ngIf="Email.errors?.['required']">Email is required.</div>
                </div>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control Personal"
                  id="Address"
                  name="Address"
                  formControlName="Address"
                  placeholder="*Address"
                  required
                />
                <div
                  *ngIf="Address.invalid && (Address.dirty || Address.touched) && formdata.touched"
                  class="alert alert-danger"
                >
                  <div *ngIf="Address.errors?.['required']">Address is required.</div>
                </div>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="form-group">
                <select
                  name="City"
                  class="form-control cityList"
                  formControlName="City"
                  id="City"
                  required
                >
                  <option value="">*Select Hospital City</option>
                  <option value="Hyderabad">Hyderabad</option>
                  <option value="Bangalore">Bangalore</option>
                  <option value="Delhi">Delhi</option>
                  <option value="Chennai">Chennai</option>
                  <option value="Vijaywada">Vijaywada</option>
                  <option value="Vizag">Vizag</option>
                </select>
                <!--
                                    <select name="City" class="form-control cityList" id="City" required>
                                        <option value="">*Select City</option>
                                    </select>-->
                                    <div
                                    *ngIf="City.invalid && (City.dirty || City.touched) && formdata.touched"
                                    class="alert alert-danger"
                                  >
                                    <div *ngIf="City.errors?.['required']">City is required.</div>
                                  </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <select
                  name="Branch"
                  class="form-control cityList"
                  formControlName="Branch"
                  id="Branch"
                  required
                >
                  <option value="" selected>*Select Hospital</option>
                  <option
                    *ngFor="
                      let subBranch of branches[formdata.value.City];
                      let i = index
                    "
                    [value]="subBranch"
                  >
                    {{ subBranch }}
                  </option>
                </select>
                <!--
                                    <select name="City" class="form-control cityList" id="City" required>
                                        <option value="">*Select City</option>
                                    </select>-->
                                    <div
                                    *ngIf="Branch.invalid && (Branch.dirty || Branch.touched) && formdata.touched"
                                    class="alert alert-danger"
                                  >
                                    <div *ngIf="Branch.errors?.['required']">Branch is required.</div>
                                  </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <input
                  type="text"
                  id="BirthDate"
                  class="form-control BasicDetails calender"
                  placeholder="Birth Date in DD/MM/YYYY"
                  name="BirthDate"
                  autocomplete="off"
                  formControlName="BirthDate"
                  required
                />
                <div
                                    *ngIf="BirthDate.invalid && (BirthDate.dirty || BirthDate.touched) && formdata.touched"
                                    class="alert alert-danger"
                                  >
                                    <div *ngIf="BirthDate.errors?.['required']">BirthDate is required.</div>
                                  </div>
              </div>
            </div>

            <div class="col-12">
              <div class="row highlight">
                <div class="col-sm-6">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control BasicDetails NumberWithDecimal"
                      maxlength="5"
                      id="Weight"
                      name="Weight"
                      formControlName="Weight"
                      placeholder="Weight in KG"
                    />
                    <div
                                    *ngIf="Weight.invalid && (Weight.dirty || Weight.touched) && formdata.touched"
                                    class="alert alert-danger"
                                  >
                                    <div *ngIf="Weight.errors?.['required']">Weight is required.</div>
                                  </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control BasicDetails NumberWithDecimal"
                      maxlength="5"
                      id="Temperature"
                      name="Temperature"
                      formControlName="Temperature"
                      placeholder="Temperature  °F"
                    />
                    <div
                                    *ngIf="Temperature.invalid && (Temperature.dirty || Temperature.touched) && formdata.touched"
                                    class="alert alert-danger"
                                  >
                                    <div *ngIf="Temperature.errors?.['required']">Temperature is required.</div>
                                  </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control BasicDetails"
                      id="Symptoms"
                      name="Symptoms"
                      formControlName="Symptoms"
                      placeholder="Symptoms/Known Allergies"
                    />
                    <div
                                    *ngIf="Symptoms.invalid && (Symptoms.dirty || Symptoms.touched) && formdata.touched"
                                    class="alert alert-danger"
                                  >
                                    <div *ngIf="Symptoms.errors?.['required']">Symptoms is required.</div>
                                  </div>
                  </div>
                </div>
                <div class="col-sm-6" id="theDateSelector1">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control Personal calender"
                      id="PreferredDate"
                      name="PreferredDate"
                      placeholder="Preferred Date in DD/MM/YYYY"
                      formControlName="PreferredDate"
                    />
                    <div
                                    *ngIf="PreferredDate.invalid && (PreferredDate.dirty || PreferredDate.touched) && formdata.touched"
                                    class="alert alert-danger"
                                  >
                                    <div *ngIf="PreferredDate.errors?.['required']">PreferredDate is required.</div>
                                  </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="col-sm-6">
                  <div class="form-group">
                    <select
                      name="Speciality"
                      class="form-control SpecialityList"
                      id="Speciality"
                      required
                    >
                      <option value="">*Speciality</option>
                    </select>
                    <div class="error"></div>
                  </div>
                </div> -->
            <!-- <div class="col-sm-6">
                                <div class="form-group">
                                    <select name="SubSpeciality" class="form-control SubSpeciality" id="SubSpeciality"
                                        required>
                                        <option value="">*Sub Speciality</option>
                                    </select>
                                    <div class="error"></div>
                                </div>
                            </div>-->

            <!-- <div class="col-sm-6">
                  <div class="form-group">
                    <select
                      name="Unit"
                      class="form-control UnitList"
                      id="unit"
                      required
                    >
                      <option value="">*Select Unit</option>
                    </select>
                    <div class="error"></div>
                  </div>
                </div> -->

            <!-- <div class="col-sm-6">
                  <div class="form-group">
                    <select
                      name="Doctor"
                      class="form-control DoctorList"
                      id="Doctor"
                      required
                    >
                      <option value="">*Doctor</option>
                    </select>
                    <div class="error"></div>
                  </div>
                </div> -->

            <!-- <div class="col-sm-6" id="theDateSelector2">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control Personal calender showOnlyFridays"
                      id="PreferredDate2"
                      name="PreferredDate2"
                      placeholder="Only Fridays"
                      readonly
                    />
                    <div
                      class="datePickerErrorClass"
                      id="datePickerErrorMessage"
                    ></div>
                  </div> 
                </div> -->
          </div>
          <br />
          <div
            class="col-sm-12"
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
            "
          >
            <!-- <div class="form-group text-right">
              <p class="paymentRsBox">
                <span class="currencySymbols"></span
                ><span class="paymentRs"></span>/-
              </p> -->
            <button class="submitBtn" [disabled]="!formdata.valid">Submit</button>
            <!-- </div> -->
            <div class="error">
              <p *ngIf="!formdata.valid" style="margin-top: 1em">
                *Please Fill all the fields
              </p>
            </div>
          </div>
          <br />
          <div class="QR-Wrapper">
            <!-- <div class="subText">
                                Please make the payment of Rs. <span class="paymentRs">600</span>/- by clicking the ‘Pay Now’ button below to book a Video Consultation session
                            </div>-->
            <div class="row qrWrp">
              <!-- <div class="col-sm-12">
                                    <p class="text2 upiid">madhukarrainbowchild.41497933 <br>
                                        @hdfcbank </p>
                                </div>-->
              <!-- <div class="col-sm-6">
                                    

                                    <div class="form-group mb-3">
                                        <input type="text" class="form-control TransactionID" name="Transaction" minlength="12"
                                            required placeholder="*UPI/UTR Transaction ID">
                                        <div class="error"></div>
                                    </div>

                                    <div class="form-group mb-3">
                                        <input type="text" class="form-control VPAID" id="VPAID" minlength="7" name="VPAID" placeholder="*Your UPI ID" required>
                                        <div class="error"></div>
                                    </div>

                                    <div class="form-group">
                                        <p class="small" style="line-height: 1.2em; font-weight: 600; color:#F00 !important;">Please input correct transaction details to avoid delays in appointment booking</p>
                                    </div>                                  

                                </div>-->
              <!-- <div class="col-sm-6 text-cemter">
                                    <img src="assets/images/qr-img.jpg" class="img-fluid QR_Image" alt="">
                                </div>-->

              <div class="col-sm-12">
                <div class="form-group mb-2">
                  <div class="smallCheckBox">
                    <input
                      type="checkbox"
                      name="existingCustomer"
                      required
                      id="a"
                      class="authorised"
                      onclick="getSlotsAgain()"
                    /><label for="a">
                      <div class="cheIcon"></div>
                      I understand, accept, and agree to the
                      <span
                        class="TandC"
                        onClick="popupWindow('TermsandConditions')"
                      >
                        terms and conditions.</span
                      ><br />I hereby authorize Rainbow Hospitals and their
                      authorised partners to contact me through email, phone or
                      any other modes of communication.<br />It will override my
                      registry on NCPR
                    </label>
                  </div>
                  <div class="error"></div>
                </div>
                <input type="hidden" id="amount" name="amount" value="0" />
                <input
                  type="hidden"
                  id="page"
                  name="page"
                  value="hello-doctor"
                />
                <input
                  type="hidden"
                  id="utm_source"
                  name="utm_source"
                  value=""
                />
                <input
                  type="hidden"
                  id="utm_medium"
                  name="utm_medium"
                  value=""
                />
                <input
                  type="hidden"
                  id="utm_campaign"
                  name="utm_campaign"
                  value=""
                />
                <input
                  type="hidden"
                  id="utm_content"
                  name="utm_content"
                  value=""
                />
                <input type="hidden" id="utm_term" name="utm_term" value="" />
                <input type="hidden" id="utm_lms" name="utm_lms" value="" />
                <input type="hidden" id="gclid" name="gclid" value="" />

                <div class="col-12" id="ifNotIndia">
                  <p class="text001">
                    I/we confirm that I/we will pay
                    <strong>
                      <span class="currencySymbols"></span
                      ><span class="paym"></span></strong
                    >/- for the video consultation.
                  </p>
                </div>

                <div class="form-group text-right">
                  <p class="paymentRsBox">
                    <span class="currencySymbols"></span
                    ><span class="paymentRs"></span>/-
                  </p>
                  <button class="submitBtn">PAY NOW</button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div>
          <p style="font-weight: 600">
            Patients living outside India are requested to call on +91&nbsp;9642628895
            for appointments. Please note that local bookings for International
            patients shall not be honored. International patients need to choose
            the credit/debit card (international) option only in the payment
            gateway
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="row section">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 text-left">
          <p>
            We at Rainbow Children's Hospital & BirthRight by Rainbow are
            committed to continuing essential services to mother & child during
            these difficult times. Thus, we introduce a novel way for you to
            meet your doctor, from the ease of your home. <br />
            <span class="textPrimary"> #StayHome #StaySafe </span>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="row section bg1">
    <div class="container text-cemter">
      <div class="row">
        <div class="col">
          <p>
            <strong>
              Rainbow Children’s Hospital is proud to announce,
              <span class="color-red"> Hello Doctor.</span></strong
            >
          </p>

          <p>
            In view of the current health scenario, as a precautionary measure
            against COVID-19, several constraints have been implemented to limit
            the spread of the pandemic. We at Rainbow Children's Hospital &
            BirthRight by Rainbow are committed to continuing essential services
            to mother & child during these difficult times.
          </p>

          <p>
            Combining the diligence of our ground staff and the advancement in
            technology, we would like to make the process of getting a
            consultation with our doctor, as seamless as possible, while not
            compromising on the precautions that must be maintained during this
            time.
          </p>

          <p>
            Please fill in your details, complete the process and we will get in
            touch with you.
          </p>

          <p>
            We will help you get the consultation with our doctor, without you
            having to leave your home.
            <br />
            <span class="textPrimary"> #StayHome #StaySafe </span>
          </p>
          <!--  <p style="font-size:20px;"><strong>Physical OPDs open in Bangalore units; Call 1800 2122 to book
                                an appointment </strong></p> -->
        </div>
      </div>
    </div>
  </div>
  <div class="row section">
    <div class="container">
      <div class="row">
        <div class="col-12 text-cemter mb-4">
          <h2 class="sectionTitle text-purple">HOW IT WORKS</h2>
        </div>
        <div class="col-sm-6 col-md-4">
          <div class="bulletItem">
            <span class="no">1</span> Fill in your details.
          </div>
        </div>
        <div class="col-sm-6 col-md-4">
          <div class="bulletItem">
            <span class="no">2</span>Read the terms and conditions and tick the
            check box.
          </div>
        </div>
        <div class="col-sm-6 col-md-4">
          <div class="bulletItem">
            <span class="no">3</span>Proceed to make the payment for the video
            consultation, by clicking the 'Pay Now' button. The fees may vary
            depending on the doctor, specialist, etc., you are booking for.
          </div>
        </div>
        <div class="col-sm-6 col-md-4">
          <div class="bulletItem">
            <span class="no">4</span>On successful payment, our hospital
            representative will call you to explain the process, doctor
            availability & confirm the time slot.
          </div>
        </div>

        <div class="col-sm-6 col-md-4">
          <div class="bulletItem">
            <span class="no">5</span>On confirmation, you will receive a meeting
            link for a video consultation with our doctor.
          </div>
        </div>
        <div class="col-sm-6 col-md-4">
          <div class="bulletItem">
            <span class="no">6</span>On completion of the Video Consultation you
            will receive the prescription in your email. Please note to check
            for your prescription in the junk folder.
          </div>
        </div>

        <div class="col-12 text-cemter mt-3">
          <div class="note">
            Please do keep your reports and prescriptions ready with you. If you
            are an existing patient, please keep your UHID handy as well.
          </div>
        </div>
        <div class="col-12 text-cemter">
          <br />
          <br />
          <h4
            onClick="popupWindow('Refund')"
            style="cursor: pointer; text-decoration: underline"
          >
            Refund Policy
          </h4>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="T_C_popupWrapper"></div>
<div class="T_C_popup Submition">
  <div class="close">&#x2715;</div>
  <h2 class="headText">Video Consultation Request</h2>
  <div class="popupText">
    <p>You request is Submitted successfuly</p>
  </div>
</div>
<div class="T_C_popupWrapper"></div>
<div class="T_C_popup Submition_Failed">
  <div class="close">&#x2715;</div>
  <h2 class="headText">Video Consultation Request</h2>
  <div class="popupText">
    <p>Sorry cannot process your request at the moment</p>
  </div>
</div>
<div class="T_C_popup TermsandConditions">
  <div class="close">&#x2715;</div>
  <h2 class="headText">Terms and Conditions</h2>
  <div class="popupText">
    <p>
      The online medical opinion services available on our website (the
      "Services") does not in any way constitute an invitation or recommendation
      to avail our teleconsultation services. The services provided through our
      website are not intended in any way to be considered as a substitute for
      face to face consultation with a doctor. We, Rainbow Children's Medicare
      Pvt Ltd advises the users to make independent assessment in respect of its
      accuracy or usefulness and suitability prior to making any decision in
      reliance hereof. Terms 'you' and 'your' hereafter refers to the user both
      registered and unregistered who is using/browsing through our website
      and/or availing any of our Services.
    </p>

    <p>
      By submitting this registration form, you hereby agree to avail the
      Services provided by Rainbow Children’s Hospitals Healthcare Service
      Private Limited ("Company") and/or its affiliates on such terms and
      conditions as described below:
    </p>

    <ol>
      <li>
        <strong>Informed Consent</strong>
        <p>
          You understand and acknowledge that there will not be any physical
          examination involved and the consultation services shall be provided
          remotely. The opinion delivered by the doctor / physician shall solely
          be based on the verbal communication between the concerned doctor or
          the physician and the patient and the test reports and other
          information provided/uploaded by you on the website.
        </p>
        <p>
          You hereby consent to the use of telephonic and/or electronic
          communications (including audio, video and written communications)
          through any media by our doctors and our representatives to contact
          you using the contact information provided to us by you and to provide
          our services to you remotely.
        </p>
        <p>
          You hereby consent to the recording of all communications (including
          audio, video and written communications) with our doctors and our
          representatives.
        </p>
        <p>
          I hereby authorize Rainbow Children’s Medicare Pvt Ltd (Rainbow
          Hospitals) and their authorised Partners to call me on the contact
          number made available by me on the website with a specific request to
          call back. I further declare that, irrespective of my contact number
          being registered on National Customer Preference Register (NCPR) or on
          National Do Not Call Registry (NDNC), any call made or SMS sent in
          response to my request shall not be construed as an Unsolicited
          Commercial Communication even though the content of the call may be
          for the purposes of providing information about Rainbow Hospitals, its
          services or for appointment and consultation.
        </p>
        <p>
          You hereby consent to the recording, saving and storing of all
          information pertaining to you provided to us or obtained during the
          course of providing the service, including without limitation,
          personal contact information, medical history, and laboratory reports,
          communications with our doctors and representatives, and other patient
          records. All such information shall be treated as confidential
          information by us, in accordance with our privacy policy and as per
          applicable laws.
        </p>
      </li>

      <li>
        <strong>Patient's Undertaking</strong>

        <p>
          You are above the age of 18 years and legally competent to enter into
          this agreement with us to avail our services;
        </p>

        <p>
          The execution of this agreement and your use of our services are not
          in violation of any applicable law or third-party contracts executed
          by you;
        </p>

        <p>
          The service provided by our doctors is based solely on the information
          furnished to them by you and neither we nor our doctors will be able
          to countercheck the reliability of the information provided by you.
          Accordingly, you hereby undertake that (i) all information, including
          medical, health, diagnostic and personal information, the registration
          form and information shared during your communications with our
          doctors and our representatives, is complete, true and accurate; (ii)
          no information has been intentionally omitted/withheld; and (iii) no
          incomplete/misleading/fraudulent/inaccurate information has been/shall
          be provided.
        </p>
      </li>

      <li>
        <strong>Limitation of liability</strong>
        <p>
          The Company, its affiliates, doctors, representatives or employees
          shall not be liable for any deficiency in service or loss, damages or
          adverse impact you may have incurred due to the breach of one or more
          of the terms and conditions (including the undertakings) by you.
        </p>
        <p>
          The Company, its affiliates, doctors, representatives or employees
          shall not be liable for any error in diagnosis, delay in providing the
          service or deficiency in service due to the occurrence of sub optimal
          technical conditions (including without limitation, poor network
          connectivity, power shutdown, service downtime, scheduled maintenance
          work by the internet service providers.
        </p>
        <p>
          The Company or its doctors or representatives will not be responsible
          for any misunderstanding or misinterpretation of any advice given by
          the doctors during the Service or of any terms and conditions herein.
        </p>
        <p>
          The Company or its doctors or representatives will not be liable for
          misdiagnosis / faulty judgment / interpretation error / perception
          error/Adverse events/ inefficacy of prescribed treatment or
          advice/validity of the advice or prescription provided by the
          consulting doctor in your jurisdiction of residence/ unavailability of
          the recommended or prescribed treatment or medication under any
          condition or circumstances. Users are advised to use their discretion
          for following the advice obtained post consultation from our Online
          Medical Opinion platform.
        </p>
        <p>
          In no event shall The Company or its doctors and representatives be
          liable for any direct, indirect, punitive, incidental, special or
          consequential damages or any damages whatsoever including without
          limitations, damages for loss of use, data or profits, arising out of
          or in any way connected with the use of our Services.
        </p>
        <p>
          Our Services are not meant in any way for emergency and
          life-threatening conditions. It is advised to take the patient to the
          nearest hospital in such cases.
        </p>
      </li>
      <li>
        <strong
          >Booking, Rescheduling and Cancellation of Consultation
          Sessions</strong
        >
        <p>
          All consultation sessions shall be scheduled between 9am-2:30pm on all
          working days in all cities of operation. The time provided for
          consultation to you is indicative and actual consultation time may
          change depending on the consulting Doctor's discretion. The Company,
          its doctors and its representatives reserves the right to refuse the
          service to any user without providing any reason.
        </p>
        <p>
          All sessions are booked on a first-come basis. Therefore, you are
          requested to book your sessions in advance.
        </p>
        <p>
          Please ensure that you are equipped with proper video conference
          equipment and internet connectivity as mentioned or is relevant for
          the service you want to avail. In case of inadequate video conference
          equipment, technical error or defective internet connectivity the
          Company reserves the right to reschedule or cancel your appointment
          without any notice
        </p>
      </li>

      <li>
        <strong>Indemnity</strong>
        <p>
          You agree and undertake to indemnify and keep indemnified the Company,
          its doctors and its representatives as well as Rainbow Children’s
          Hospitals ("Indemnified Parties") for any losses, costs, charges and
          expenses including reasonable attorney fees that the concerned
          doctor/physician and Indemnified Parties may suffer on account of
          (a)misdiagnosis / faulty judgment /interpretation errors / perception
          error arising from (i)your failure to provide correct and / or
          complete clinical information / history about the patient in timely
          and clinically appropriate manner; or (ii)suppression of material
          facts; or your failure to provide relevant clinical information about
          the patient; or (iv) misinterpretation of the advice / prescription /
          diagnosis by you; or (v) failure to follow doctor's advice /
          prescription by you; or (b) incorrect or inaccurate credit / debit
          card details provided by you; or (c) using a credit / debit card which
          is not lawfully owned by you; or (d) if you permit a third party to
          use your password or other means to access your account
        </p>
      </li>

      <li>
        <strong>International Patients</strong>
        <p>If you are an international patient,</p>

        <p>
          a) Consultancy with our doctors can happen in the presence of your
          local doctor. Any prescription advised by our expert doctors shall
          have to be written by your local doctor on his/her on the prescription
          pad
        </p>
        <p>OR</p>
        <p>
          b) In case you have already underwent treatment in any of our Hospital
          units, then you can avail follow ups services through video
          consultancy
        </p>
        <p>OR</p>
        <p>
          c) In case your local doctor refers you to our Hospital, then, you can
          avail services of our doctors through consultancy in the presence of
          your local doctor
        </p>
        <p>OR</p>
        <p>
          d) In case of second opinion, our Doctor solely relies on the
          diagnostic reports and other medical reports shared by you and your
          local doctor.
        </p>
      </li>

      <li>
        <strong>Miscellaneous</strong>
        <p>
          This Agreement may be terminated by either party at any time, with or
          without cause This agreement shall be governed by the laws of India
          and shall be subject to the exclusive jurisdiction of the courts at
          Hyderabad
        </p>
        <p>
          By visiting our website you acknowledge, accept and expressly
          authorize the Company for the placement of Cookies on your web
          browser;
        </p>
        <p>
          The Company may at any time, without any prior notification to you,
          modify these terms of conditions. Please review the latest version of
          the terms and conditions before proceeding to avail the service
        </p>
        <p>
          If any part of the agreement is determined to be invalid or
          unenforceable pursuant to applicable law then the said provision will
          be superseded by a valid, enforceable provision and the remainder of
          the agreement shall continue in effect
        </p>
      </li>
    </ol>
  </div>
  <!-- <div class="popupText">

            <p>The online medical opinion services available on our website (the &quot;Services&quot;) does not in any
                way constitute an invitation or recommendation to avail our teleconsultation services. The services
                provided through our website are not intended in any way to be considered as a substitute for face to
                face consultation with a doctor. We, Rainbow Children's Medicare Pvt Ltd advises the users to make
                independent assessment in respect of its accuracy or usefulness and suitability prior to making any
                decision in reliance hereof. Terms 'you' and 'your' hereafter refers to the user both registered and
                unregistered who is using/browsing through our website and/or availing any of our Services.</p>
            <p>By submitting this registration form, you hereby agree to avail the Services provided by Rainbow
                Children’s Hospitals Healthcare Service Private Limited (&quot;Company&quot;) and/or its affiliates on
                such terms and conditions as described below:</p>
            <ol>
                <li><strong>Informed Consent</strong><br>
                    You understand and acknowledge that there will not be any physical examination involved and the
                    consultation services shall be provided remotely. The opinion delivered by the doctor / physician
                    shall solely be based on the verbal communication between the concerned doctor or the physician and
                    the patient and the test reports and other information provided/uploaded by you on the website<br>
                    <br>
                    You hereby consent to the use of telephonic and/or electronic communications (including audio, video
                    and written communications) through any media by our doctors and our representatives to contact you
                    using the contact information provided to us by you and to provide our services to you remotely.<br>
                    <br>
                    You hereby consent to the recording of all communications (including audio, video and written
                    communications) with our doctors and our representatives.<br>
                    <br>
                    I hereby authorize Rainbow Children’s Medicare Pvt Ltd (Rainbow Hospitals) and their authorised
                    Partners to call me on the contact number made available by me on the website with a specific
                    request to call back. I further declare that, irrespective of my contact number being registered on
                    National Customer Preference Register (NCPR) or on National Do Not Call Registry (NDNC), any call
                    made or SMS sent in response to my request shall not be construed as an Unsolicited Commercial
                    Communication even though the content of the call may be for the purposes of providing information
                    about Rainbow Hospitals, its services or for appointment and consultation.<br>
                    You hereby consent to the recording, saving and storing of all information pertaining to you
                    provided to us or obtained during the course of providing the service, including without limitation,
                    personal contact information, medical history, and laboratory reports, communications with our
                    doctors and representatives, and other patient records. All such information shall be treated as
                    confidential information by us, in accordance with our privacy policy and as per applicable
                    laws.<br>
                    <br>
                </li>
                <li><strong>Patient's Undertaking</strong><br>
                    You are above the age of 18 years and legally competent to enter into this agreement with us to
                    avail our services;<br>
                    <br>
                    The execution of this agreement and your use of our services are not in violation of any applicable
                    law or third party contracts executed by you;<br>
                    <br>
                    The service provided by our doctors is based solely on the information furnished to them by you and
                    neither we nor our doctors will be able to countercheck the reliability of the information provided
                    by you. Accordingly, you hereby undertake that (i) all information, including medical, health,
                    diagnostic and personal information, the registration form and information shared during your
                    communications with our doctors and our representatives, is complete, true and accurate; (ii) no
                    information has been intentionally omitted/withheld; and (iii) no
                    incomplete/misleading/fraudulent/inaccurate information has been/shall be provided.<br>
                    <br>
                </li>
                <li><strong>Limitation of liability</strong><br>
                    The Company, its affiliates, doctors, representatives or employees shall not be liable for any
                    deficiency in service or loss, damages or adverse impact you may have incurred due to the breach of
                    one or more of the terms and conditions (including the undertakings) by you.<br>
                    <br>
                    The Company , its affiliates, doctors, representatives or employees shall not be liable for any
                    error in diagnosis, delay in providing the service or deficiency in service due to the occurrence of
                    sub optimal technical conditions (including without limitation, poor network connectivity, power
                    shutdown, service downtime, scheduled maintenance work by the internet service providers.<br>
                    <br>
                    The Company or its doctors or representatives will not be responsible for any misunderstanding or
                    misinterpretation of any advice given by the doctors during the Service or of any terms and
                    conditions herein.<br>
                    <br>
                    The Company or its doctors or representatives will not be liable for misdiagnosis / faulty judgment
                    / interpretation error / perception error/Adverse events/ inefficacy of prescribed treatment or
                    advice/validity of the advice or prescription provided by the consulting doctor in your jurisdiction
                    of residence/ unavailability of the recommended or prescribed treatment or medication under any
                    condition or circumstances. Users are advised to use their discretion for following the advice
                    obtained post consultation from our Online Medical Opinion platform.<br>
                    <br>
                    In no event shall The Company or its doctors and representatives be liable for any direct, indirect,
                    punitive, incidental, special or consequential damages or any damages whatsoever including without
                    limitations, damages for loss of use, data or profits, arising out of or in any way connected with
                    the use of our Services.<br>
                    <br>
                    Our Services are not meant in any way for emergency and life-threatening conditions. It is advised
                    to take the patient to the nearest hospital in such cases.<br>
                    <br>
                </li>
                <li><strong>Booking, Rescheduling and Cancellation of Consultation Sessions</strong><br>
                    All consultation sessions shall be scheduled between 9am-2:30pm on all working days in all cities of
                    operation. The time provided for consultation to you is indicative and actual consultation time may
                    change depending on the consulting Doctor's discretion. The Company, its doctors and its
                    representatives reserves the right to refuse the service to any user without providing any
                    reason.<br>
                    <br>
                    All sessions are booked on a first-come basis. Therefore, you are requested to book your sessions in
                    advance.<br>
                    <br>
                    Please ensure that you are equipped with proper video conference equipment and internet connectivity
                    as mentioned or is relevant for the service you want to avail. In case of inadequate video
                    conference equipment, technical error or defective internet connectivity the Company reserves the
                    right to reschedule or cancel your appointment without any notice<br>
                    <br>
                </li>
                <li><strong> Indemnity</strong><br>
                    You agree and undertake to indemnify and keep indemnified the Company, its doctors and its
                    representatives as well as Rainbow Children’s Hospitals (&quot;Indemnified Parties&quot;) for any
                    losses, costs, charges and expenses including reasonable attorney fees that the concerned
                    doctor/physician and Indemnified Parties may suffer on account of (a)misdiagnosis / faulty judgment
                    /interpretation errors / perception error arising from (i)your failure to provide correct and / or
                    complete clinical information / history about the patient in timely and clinically appropriate
                    manner; or (ii)suppression of material facts; or your failure to provide relevant clinical
                    information about the patient; or (iv) misinterpretation of the advice / prescription / diagnosis by
                    you; or (v) failure to follow doctor's advice / prescription by you; or (b) incorrect or inaccurate
                    credit / debit card details provided by you; or (c) using a credit / debit card which is not
                    lawfully owned by you; or (d) if you permit a third party to use your password or other means to
                    access your account<br>
                    <br>
                </li>
            </ol>
            <p><strong>Miscellaneous</strong><br>
                This agreement may be terminated by either party at any time, with or without cause</p>
            <p>This agreement shall be governed by the laws of India and shall be subject to the exclusive jurisdiction
                of the courts at Hyderabad</p>
            <p>By visiting our website you acknowledge, accept and expressly authorize the Company for the placement of
                Cookies on your web browser;</p>
            <p>The Company may at any time, without any prior notification to you, modify these terms of conditions.
                Please review the latest version of the terms and conditions before proceeding to avail the service</p>
            <p>If any part of the agreement is determined to be invalid or unenforceable pursuant to applicable law then
                the said provision will be superseded by a valid, enforceable provision and the remainder of the
                agreement shall continue in effect<br>
            </p>


        </div> -->
</div>

<div class="T_C_popup Refund">
  <div class="close">&#x2715;</div>
  <h2 class="headText">
    Refund and Cancellation Policy for video Consultation payments
  </h2>
  <div class="popupText">
    <p>
      This policy is approved by the management of Rainbow Children’s Medicare
      Private Limited (RCMPL) and is applicable for all units of RCMPL and its
      affiliates. This policy is subject to revisions based on the decisions of
      the management.
    </p>
    <ol>
      <li>
        Amount once paid through the payment gateway shall not be refunded other
        than in the following circumstances:
        <br />
        <ol type="a">
          <li>
            Multiple times debiting of Customer’s Bank Account due to technical
            error OR Customer's account being debited with excess amount in a
            single transaction due to technical error.
            <br />
          </li>
          <li>
            In case the customer has paid the full amount and due to non
            availability of the doctor the consultation cannot be provided. The
            cutomer can opt for using the amount as a deposit for any future
            consultations at Rainbow. <br />
            <br />
          </li>
        </ol>
      </li>
      <li>
        If the services have not been availed and customer does not intend to
        keep it as a deposit with Rainbow childrens medicare Pvt Ltd. The
        customer can send and email to
        <a href="mailto:refund@rainbowhospitals.in"
          >refund@rainbowhospitals.in</a
        >
        in the detailed format shared in Annexure1 below. <br />
        <br />
      </li>
      <li>
        The refund request will be processed manually after due verification. If
        the claim is found valid the eligible amount will be refunded by Rainbow
        Children’s Medicare Pvt Ltd through electronic mode within the next
        Seven (7) working days from the date of receiving the email request in
        the format shared in Annexure1 below. <br />
        <br />
      </li>
      <li>
        It may take 3 to 21 working days for the payment to reflect in your bank
        account depending on your banks policy.<br />
        <br />
      </li>
      <li>
        Company assumes no responsibility and shall incur no liability if it is
        unable to effect any Payment Instruction(s) on the Payment Date owing to
        any one or more of the following circumstances:<br />
        <br />
        <ol type="a">
          <li>
            If the payment instructions(s) issued by you is/are incomplete,
            inaccurate and invalid.<br />
          </li>
          <li>
            If the receiving bank refuses or delays in honoring the Payment
            instruction(s).<br />
          </li>
          <li>
            Circumstances beyond the control of Company (including, but not
            limited to,fire, flood,natural disasters, bank strikes, power
            failure, systems failure like computer or telephone lines breakdown
            due to an unforeseeable cause or interference from an outside
            force)<br />
          </li>
          <li>
            In case the payment is not effected for any reason, you will be
            intimated about the failed payment by an e-mail. <br />
          </li>
        </ol>
      </li>
      <li>
        User agrees that Company, in its sole discretion, for any or no reason,
        and without penalty, may suspend or terminate his/her account (or any
        part thereof) or use of the services and remove and discard all or any
        part of his/her account, user profile, or his/her recipient profile, at
        any time. Company may also in its sole discretion and at any time
        discontinue providing access to the Services, or any part thereof, with
        or without notice. User agrees that any termination of his/her access to
        the service or any account he/she may have or portion thereof may be
        effected without prior notice, and also agree that company will not be
        liable to user or any third party for any such termination. Any
        suspected, fraudulent, abusive or illegal activity may be referred to
        appropriate law enforcement authorities. These remedies are in addition
        to any other remedies company may have at law or in equity. Upon
        termination for any reason, user agrees to immediately stop using the
        services.<br />
        <br />
      </li>
      <li>
        Company may elect to resolve any dispute, controversy or claim arising
        out of or relating to this Agreement or Service provided in connection
        with this Agreement by binding arbitration in accordance with the
        provisions of the Indian Arbitration & Conciliation Act 1996, Any such
        dispute, controversy or claim shall be arbitrated on an individual basis
        and shall not be consolidated in any arbitration with any claim or
        controversy of any other party. <br />
        <br />
      </li>
      <li>
        Governing Law & Jurisdiction: Subject to the foregoing, it is hereby
        expressly agreed and declared that court of competent jurisdiction in
        Hyderabad alone shall have jurisdiction with respect to matters
        pertaining hereto.
      </li>
    </ol>
    <strong>Annexure 1</strong>
    <p>
      In case of a refund the Customer should email to
      <a href="mailto:refund@rainbowhospitals.in">
        refund@rainbowhospitals.in</a
      >
      with the following details. Do send the refund details from the registered
      email ID or the ID used for raising the Video Consultation Request. <br />
    </p>
    <ol>
      <li>
        <strong>Patient Name:</strong>
        <em>Mentioned while booking the Video Consult request</em>
      </li>
      <li>
        <strong>Ph No:</strong>
        <em>Mentioned while booking the Video Consult request</em>
      </li>
      <li>
        <strong>Email ID:</strong>
        <em>Mentioned while booking the Video Consult request</em>
      </li>
      <li>
        <strong>City:</strong>
        <em>Mentioned while booking the Video Consult request</em>
      </li>
      <li>
        <strong>Unit (Hospital Location)</strong>
        <em>To which the Request was raised</em>
      </li>
      <li>
        <strong>Payment Transaction ID: </strong
        ><em>The date on which the request was raised</em>
      </li>
      <li>
        <strong>Transaction Date: </strong
        ><em>The date on which the request was raised</em>
      </li>
      <li><strong>Account No: </strong></li>
      <li><strong>Account Holder Name: </strong></li>
      <li><strong>IFSC Code: </strong></li>
    </ol>
    <p></p>
  </div>
</div>

<div class="row footer">
  <div class="container">
    <div class="text">© 2022 Rainbow Hospital-ALL RIGHTS RESERVED</div>
  </div>
</div>
<!-- <script src="js/jquery.min.js"></script>-->
<!-- <script src="js/jquery.min.js"></script>
    <script src="js/jquery-ui.js"></script> -->
<script>
  //   function isNumber(evt) {
  //     evt = evt ? evt : window.event;
  //     var charCode = evt.which ? evt.which : evt.keyCode;
  //     if (charCode > 31 && (charCode < 48 || charCode > 57)) {
  //       return false;
  //     }
  //     return true;
  //   }

  //   function checkvpaid(val) {
  //     if (val.indexOf("@") < 0) {
  //       return false;
  //     } else {
  //       var newVal = val.split("@");
  //       if (
  //         newVal[0].length < 3 ||
  //         newVal[1].length < 3 ||
  //         newVal[1].indexOf("@") > 0
  //       ) {
  //         return false;
  //       } else {
  //         return true;
  //       }
  //     }
  //   }

  //   function datePickerValidator() {
  //     var dateChosen = document.getElementById("PreferredDate2").value;

  //     if (dateChosen === "") {
  //       document.getElementById("datePickerErrorMessage").innerHTML =
  //         "Please Pick a Date";
  //     } else {
  //       formValidation();
  //     }
  //   }

  //   function checkIfDateIsStillInvalid() {
  //     var dateChosen = document.getElementById("PreferredDate2").value;

  //     if (dateChosen === "") {
  //       document.getElementById("datePickerErrorMessage").innerHTML =
  //         "Please Pick a Date";
  //     } else {
  //       document.getElementById("datePickerErrorMessage").innerHTML = "";
  //     }
  //   }

  //   var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,20})?$/;
  //   //var mobileReg = /^([5-9]{1})([0-9]{9})$/;
  //   var mobileReg = /^\+?[0-9]{2}-?[0-9]{4,14}$/;
  //   var pancardReg = /^([a-zA-Z]{5})(\d{4})([a-zA-Z]{1})$/;
  //   var landlineReg = /^[0-9]{8,13}?$/;

  //   function formValidation() {
  //     var ActiveFormElenent = [];
  //     var InValidElement = [];
  //     //$('body').addClass('loaderWrap');
  //     $(document)
  //       .find("[name]")
  //       .each(function () {
  //         var attr = $(this).attr("required");
  //         if (
  //           $(this).is(":visible") &&
  //           typeof attr !== typeof undefined &&
  //           attr !== false
  //         ) {
  //           ActiveFormElenent.push($(this).attr("name"));
  //         }
  //       });

  //     ActiveFormElenent.forEach(function (elementName) {
  //       var formElement = $(document).find(
  //         "[name='" + elementName + "']:visible"
  //       );
  //       if (formElement.is("select")) {
  //         label = "select " + formElement.find("option:first").text();
  //       } else if (
  //         formElement.is('input[type="text"]') ||
  //         formElement.is('input[type="tel"]') ||
  //         formElement.is('input[type="date"]') ||
  //         formElement.is('input[type="email"]')
  //       ) {
  //         label = "enter " + formElement.attr("placeholder");
  //       } else if (formElement.is(":checkbox")) {
  //         label = "select " + formElement.attr("type");
  //       } else if (formElement.is('input[type="radio"]')) {
  //         label = "select " + formElement.attr("name");
  //       }
  //       if (
  //         (formElement.is('input[type="text"]') && !formElement.val()) ||
  //         (formElement.is('input[type="tel"]') && !formElement.val()) ||
  //         (formElement.is('input[type="tel"]') &&
  //           !mobileReg.test(formElement.val())) ||
  //         (formElement.is('input[type="date"]') && !formElement.val()) ||
  //         (formElement.is('input[type="email"]') && !formElement.val()) ||
  //         (formElement.is('input[type="email"]') &&
  //           !emailReg.test(formElement.val())) ||
  //         (formElement.is("select") && !formElement.val()) ||
  //         (formElement.is(":checkbox") && !formElement.is(":checked")) ||
  //         (formElement.is(":radio") && formElement.is(":checked") === false)
  //       ) {
  //         formElement.siblings(".error").text("Please " + label);
  //         InValidElement.push(elementName);
  //       }

  //       if (
  //         (formElement.hasClass("TransactionID") &&
  //           formElement.val().length < formElement.attr("minlength")) ||
  //         (formElement.hasClass("TransactionID") &&
  //           formElement.val().indexOf("@") > 0)
  //       ) {
  //         label = "P20041013212687125#####, 20200415120032####, 010920327###";
  //         formElement.siblings(".error").text("Example " + label);
  //         InValidElement.push(elementName);
  //       }

  //       if (
  //         (formElement.hasClass("VPAID") &&
  //           formElement.val().length < formElement.attr("minlength")) ||
  //         (formElement.hasClass("VPAID") && !checkvpaid(formElement.val()))
  //       ) {
  //         label = "####435085@upi, ###vi.##ju@okicici, ####936326@paytm";
  //         formElement.siblings(".error").text("Example " + label);
  //         InValidElement.push(elementName);
  //       }
  //     });
  //     if (InValidElement.length === 0) {
  //       console.log("Form is valid");
  //       $("body").addClass("loaderWrap");
  //       getDataLayerValue(
  //         "Videoconsultation_RegisterationDetails_Submit",
  //         "Book Consultation",
  //         "hello-doctor-online-consultation_RegisterationDetails_Submit"
  //       );
  //       document.getElementById("form").submit();

  //       // Dr. Lokesh Limiting Start
  //       var currentNumberOfSlots = localStorage.getItem("no_of_slots");

  //       var finalNumberOfSLots = parseInt(currentNumberOfSlots) + 1;

  //       var dateChosen = document.getElementById("PreferredDate2").value;

  //       updateSlots(dateChosen, finalNumberOfSLots);

  //       localStorage.clear();
  //       // Dr. Lokesh Limiting End
  //     } else {
  //       getDataLayerValue(
  //         "Videoconsultation_RegisterationDetails_TryAgain",
  //         "Book Consultation",
  //         "hello-doctor-online-consultation_RegisterationDetails_TryAgain"
  //       );
  //     }

  //     // console.log(ActiveFormElenent, '\n', InValidElement);
  //     //$('body').removeClass('loader');
  //   }

  //   // function clientinsert(formData,leadid) {
  //   //     $.ajax({
  //   //         url: "https://rainbowfrontenduat.rainbowhospitals.in/landing-page/hello-doctor/insert_clientside.php",

  //   //         type: "POST",
  //   //         data: formData + '&leadid='+leadid,
  //   //         success: function (data) {
  //   //             resetForm();
  //   //             $('body').removeClass('loaderWrap');
  //   //             $(".thankYoy").show();
  //   //             $('#form').hide();
  //   //             console.log("leadid = "+leadid);
  //   //         }
  //   //     });
  //   // }

  //   // function paymentform(formData) {
  //   //     $.ajax({
  //   //                 url: "https://rainbowfrontenduat.rainbowhospitals.in/landing-page/hello-doctor/payment.php",
  //   //                 // url: "https://www.rainbowhospitals.in/hello-doctor-online-consultation/insert.php",
  //   //                 type: "POST",
  //   //                 data: formData  + '&action=payment_process',
  //   //                 success: function (data) {
  //   //                     // resetForm();
  //   //                     // $('body').removeClass('loader');
  //   //                     // $(".thankYoy").show();
  //   //                     // $('#form').hide();
  //   //                 }
  //   //             });
  //   // }

  //   function resetForm() {
  //     $("#Patient").val("");
  //     $("#Guardian").val("");
  //     $("#Mobile").val("");
  //     $("#EmailId").val("");
  //     $("#Transaction").val("");
  //     $("#City")[0].selectedIndex = 0;
  //     $("#Speciality")
  //       .empty()
  //       .append('<option value="0">Select Speciality</option>');
  //     $("#SubSpeciality")
  //       .empty()
  //       .append('<option value="0">Select Sub Speciality</option>');
  //     $("#unit").empty().append('<option value="0">Select Unit</option>');
  //     $("#upiidwrp").hide();
  //   }

  //   function popupResize(thisO) {
  //     var height = thisO.height() - (thisO.find(".headText").height() + 30);
  //     //console.log(height, thisO.find(".popupText").height(), thisO.find(".headText").height(), thisO.height())
  //     thisO.find(".popupText").height(height);
  //   }

  //   function popupWindow(popupName) {
  //     $(".T_C_popupWrapper, ." + popupName).fadeIn();
  //     var thisO = $("." + popupName);
  //     popupResize(thisO);
  //     $(window).resize(function () {
  //       popupResize(thisO);
  //     });

  //     if ($("." + popupName).height() < $(window).height()) {
  //       $("body").css("overflow", "hidden");
  //     } else {
  //       var height = $("." + popupName).height();
  //       $("." + popupName).css("position", "absolute");
  //       $("body").css({ overflowY: "hidden" });
  //     }

  //     $(".close").click(function () {
  //       $(".T_C_popupWrapper, ." + popupName).fadeOut();
  //       $("body").css({ overflowY: "unset" });
  //     });
  //   }

  //   function formatAMPM(date) {
  //     var hours = date.getHours();
  //     var minutes = date.getMinutes();
  //     var ampm = hours >= 12 ? "pm" : "am";
  //     hours = hours % 12;
  //     hours = hours ? hours : 12; // the hour '0' should be '12'
  //     minutes = minutes < 10 ? "0" + minutes : minutes; // Change here? - Sriharish
  //     var strTime = hours + ":" + minutes + " " + ampm;
  //     return strTime;
  //   }

  //   $(document).ready(function () {
  //     $("#BirthDate").datepicker({
  //       changeMonth: true,
  //       changeYear: true,
  //       minDate: "-110Y",
  //       maxDate: "0D",
  //       yearRange: "c-100:c+100",
  //       dateFormat: "dd-mm-yy",
  //     });

  //     $("#PreferredDate").datepicker({
  //       changeMonth: true,
  //       changeYear: true,
  //       minDate: formatAMPM(new Date()) >= "12:00 pm" ? "1D" : "0D",
  //       minTime: "12:00",
  //       startDate: "-0d",
  //       yearRange: "c-100:c+100",
  //       dateFormat: "dd-mm-yy",
  //       beforeShowDay: function (date) {
  //         // var day = date.getDay();
  //         // return [(day != 0), ''];
  //         var array = ["2021-04-12", "2021-04-13"];
  //         var string = jQuery.datepicker.formatDate("yy-mm-dd", date);
  //         return [array.indexOf(string) == -1];
  //       },
  //     });

  //     // $("#PreferredDate2").datepicker({
  //     //     changeMonth: true,
  //     //     changeYear: true,
  //     //     minDate: formatAMPM(new Date()) >= '12:00 pm' ? "1D" : "0D",
  //     //     maxDate: "110Y",
  //     //     startDate: '-0d',
  //     //     yearRange: "c-100:c+100",
  //     //     dateFormat: "dd-mm-yy",
  //     //     beforeShowDay: function (date) {
  //     //        // var day = date.getDay();
  //     //        // return [(day != 0), ''];
  //     //        var array = ["2021-04-12","2021-04-13"]
  //     //        var string = jQuery.datepicker.formatDate('yy-mm-dd', date);
  //     //         return [ array.indexOf(string) == -1 ]
  //     //     }
  //     // });

  //     var setTimer = setInterval(function () {
  //       // console.log(formatAMPM(new Date()));
  //       if (formatAMPM(new Date()) == "5:59 pm") {
  //         setTimeout(() => {
  //           window.location.reload();
  //         }, 59000);
  //       }
  //     }, 1000);

  //     $(document).on(
  //       "keyup",
  //       'input[type="text"], input[type="tel"], input[type="email"]',
  //       function (e) {
  //         var label = $(this).attr("placeholder");
  //         if ($(this).val()) {
  //           if (
  //             ($(this).hasClass("mobileNo") &&
  //               !mobileReg.test($(this).val())) ||
  //             ($(this).hasClass("landlineNo") &&
  //               !landlineReg.test($(this).val())) ||
  //             ($(this).is('input[type="email"]') &&
  //               !emailReg.test($(this).val())) ||
  //             ($(this).is('input[name="panCard"]') &&
  //               !pancardReg.test($(this).val()))
  //           ) {
  //             $(this)
  //               .siblings(".error")
  //               .text("Please enter valid " + label);
  //           } else {
  //             $(this).siblings(".error").text("");
  //           }
  //         }
  //       }
  //     );

  //     $(document).on("keyup", ".TransactionID", function () {
  //       if (
  //         $(this).val().length < $(this).attr("minlength") ||
  //         $(this).val().indexOf("@") > 0
  //       ) {
  //         label = "P20041013212687125#####, 20200415120032####, 010920327###";
  //         $(this)
  //           .siblings(".error")
  //           .text("Example " + label);
  //       }
  //     });
  //     $(document).on("keyup", ".VPAID", function () {
  //       if (
  //         $(this).val().length < $(this).attr("minlength") ||
  //         !checkvpaid($(this).val())
  //       ) {
  //         label = "####435085@upi, ###vi.##ju@okicici, ####936326@paytm";
  //         $(this)
  //           .siblings(".error")
  //           .text("Example " + label);
  //       }
  //     });

  //     $("#BirthDate")
  //       .click(function () {
  //         $(this).attr("readonly", "readonly");
  //       })
  //       .blur(function () {
  //         $(this).removeAttr("readonly");
  //       })
  //       .change(function () {
  //         if ($(this).val()) {
  //           $(this).siblings(".error").text("");
  //         }
  //       });

  //     $("#phone").keyup(function (e) {
  //       $(this).val(MobileValidation($(this).val()));
  //     });

  //     function MobileValidation(val) {
  //       var mRex = /^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/;
  //       if (!mRex.test(val)) {
  //         return val.replace(/[^\d+]/g, "");
  //       } else {
  //         return val;
  //       }
  //     }

  //     $(" .landlineNo, .onlyNumber").keyup(function (e) {
  //       //var maxdigit = $(this).val().substring(0, 10)
  //       $(this).val(onlyNumbers($(this).val()));
  //     });
  //     $(".onlyAphabets").each(function () {
  //       $(this).keyup(function () {
  //         $(this).val(onlyAphabets($(this).val()));
  //       });
  //     });

  //     $(".NumberWithDecimal").each(function () {
  //       $(this).keyup(function () {
  //         $(this).val(numberWithDec($(this).val()));
  //       });
  //     });

  //     function numberWithDec(val) {
  //       if (!/^\d*\.?\d*$/.test(val)) {
  //         return val.replace(/(?!-)[^0-9.]/g, "");
  //       } else {
  //         return val;
  //       }
  //     }

  //     function onlyNumbers(val) {
  //       if (!/^-?\d*$/.test(val)) {
  //         return val.replace(/[^\d]/g, "");
  //       } else {
  //         return val;
  //       }
  //     }
  //     function onlyAphabets(val) {
  //       if (!/^[a-zA-Z. ]+$/.test(val)) {
  //         return val.substr(0, val.length - 1);
  //       } else {
  //         return val;
  //       }
  //     }

  //     $(document).on("change", 'input[type="radio"]', function (e) {
  //       var label = "select " + $(this).attr("type");
  //       if (e.target.checked) {
  //         $(this).siblings(".error").text("");
  //       } else {
  //         $(this).siblings(".error").text("Please Check");
  //       }
  //     });

  //     $(".submitBtn").click(function () {
  //       var doctorChosen = document.getElementById("Doctor").value;

  //       if (doctorChosen === "LOKESH LINGAPPA") {
  //         datePickerValidator();
  //       } else {
  //         formValidation();
  //       }
  //     });

  //     function manualValidate(ev) {
  //       ev.target.checkValidity();
  //       return false;
  //     }
  //     $("#form").bind("submit", manualValidate);

  //     /*
  //                     $('body').addClass('loader');
  //                     $.getJSON("https://backend.rainbowhospitals.in/api/home/get/city", function (data) {
  //                         var items = [];
  //                         $.each(data, function (key, val) {
  //                             items.push("<option value='" + val.city + "'>" + val.city + "</li>");
  //                         });
  //                         $(".cityList").append(items.join(""))
  //                         $('body').removeClass('loader');
  //                     });
  //         */

  //     $(".cityList").change(function () {
  //       if ($(this).val()) {
  //         $("body").addClass("loaderWrap");
  //         $.getJSON(
  //           "https://backend.rainbowhospitals.in/api/home/get/specialty/" +
  //             $(this).val(),
  //           function (data) {
  //             var items = [];
  //             $.each(data, function (key, val) {
  //               items.push(
  //                 "<option value='" +
  //                   val.specialityid +
  //                   "'>" +
  //                   val.speciality +
  //                   "</option>"
  //               );
  //             });
  //             $(".SpecialityList")
  //               .html("")
  //               .append('<option value="">*Speciality</option>')
  //               .append(items.join(""));
  //             $(".SubSpeciality")
  //               .html("")
  //               .append('<option value="">*Sub Speciality</option>');
  //             $(".UnitList")
  //               .html("")
  //               .append('<option value="">*Select Unit</option>');
  //             $("body").removeClass("loaderWrap");
  //             $(".DoctorList")
  //               .html("")
  //               .append('<option value="">*Doctor</option>');
  //           }
  //         );
  //       }
  //       //console.log($(this).val())
  //       // if ($(this).val() == 'Chennai' || $(this).val() == 'Vijaywada') {
  //       //     if ($(this).val() == 'Vijaywada') {
  //       //     $('.paymentRs').text('500')
  //       // }
  //       // else if ($(this).val() == 'Delhi') {
  //       //     $('.paymentRs').text('1000')
  //       // }
  //       // else {
  //       //     $('.paymentRs').text('600')
  //       // }
  //       //console.log('aaaaa', $('.paymentRs').html)
  //     });

  //     // $(".SpecialityList").change(function () {
  //     //     $('body').addClass('loaderWrap');
  //     //     $.getJSON("https://backend.rainbowhospitals.in/api/home/get/sub_speciality/" + $(this).val() + "," + $(".cityList").val(), function (data) {
  //     //         var items = [];
  //     //         $.each(data, function (key, val) {
  //     //             items.push("<option value='" + val.subspecialityid + "'>" + val.subspecialityname + "</option>");
  //     //         });
  //     //         $(".SubSpeciality").html('').append('<option value="">*Sub Speciality</option>').append(items.join(""));
  //     // 		$(".UnitList").html('').append('<option value="">*Select Unit</option>');
  //     // 		$(".QR-Wrapper").hide();
  //     //         $('body').removeClass('loaderWrap');
  //     //     });
  //     // })

  //     $(".SpecialityList").change(function () {
  //       if ($(this).val()) {
  //         $("body").addClass("loaderWrap");
  //         //console.log($(this).val(), $(".cityList").val())
  //         $.ajax({
  //           type: "GET",
  //           url:
  //             "https://backend.rainbowhospitals.in/api/home/get/specialty_new/" +
  //             $(this).val() +
  //             "," +
  //             $(".cityList").val(),
  //           success: function (data) {
  //             //console.log('aaa', data);
  //             var items = [];
  //             $.each(data, function (key, val) {
  //               items.push(
  //                 '<option value="' +
  //                   val.hospitalname +
  //                   '">' +
  //                   val.hospitalname +
  //                   "</option>"
  //               );
  //             });
  //             $(".UnitList")
  //               .html("")
  //               .append('<option value="">*Select Unit</option>')
  //               .append(items.join(""));
  //             $("body").removeClass("loaderWrap");
  //             $(".DoctorList")
  //               .html("")
  //               .append('<option value="">*Doctor</option>');
  //           },
  //           // error: function (data) { onSuccess(data); }
  //         });
  //       }
  //     });

  //     $(".UnitList").change(function () {
  //       if ($(this).val()) {
  //         // $('body').addClass('loaderWrap');
  //         // $.ajax({
  //         //     type: 'GET', url: 'https://backend.rainbowhospitals.in/api/home/get/upi/' + $(this).val(),
  //         //     success: function (data) {
  //         //         $(".QR_Image").attr('src', data[0].upilink);
  //         //         $(".upiid").text(data[0].upiid);
  //         //         $(".QR-Wrapper").show();
  //         //         $('body').removeClass('loaderWrap');
  //         //     }, error: function (data) { onSuccess(data); }
  //         // });
  //         $("body").addClass("loaderWrap");
  //         var unitselected = $(this).val();
  //         var specialityselecetd = $(".SpecialityList").val();
  //         $.ajax({
  //           type: "POST",
  //           // url: "https://lmsuat.rainbowhospitals.in:8080/hdp/hdptest/insert.php?action=getdoctorlist",
  //           url: "https://www.rainbowhospitals.in/hello-doctor-online-consultation/insert.php?action=getdoctorlist",
  //           data: {
  //             unit: unitselected,
  //             spec: specialityselecetd,
  //             action: "getdoctorlist",
  //           },
  //           success: function (response) {
  //             //console.log('aaaa-', response)
  //             // alert(response); //List of all doctors called
  //             var items = [];
  //             var dr = "ANY OTHER DOCTOR";
  //             // items.push("<option value='" + 'Any Other Doctor' + "'>" + 'Any Other Doctor' + "</option>");
  //             $.each(JSON.parse(response), function (key, val) {
  //               if (val.doctor_name.toLowerCase() == dr.toLowerCase())
  //                 items.push(
  //                   "<option value='" +
  //                     val.doctor_name +
  //                     "'>" +
  //                     val.doctor_name.toUpperCase() +
  //                     "</option>"
  //                 );
  //               else
  //                 items.push(
  //                   "<option value='" +
  //                     val.doctor_name +
  //                     "'>" +
  //                     "Dr. " +
  //                     val.doctor_name.toUpperCase() +
  //                     "</option>"
  //                 );
  //             });
  //             $(".DoctorList")
  //               .html("")
  //               .append('<option value="">*Doctor</option>')
  //               .append(items.join(""));
  //             $("body").removeClass("loaderWrap");
  //           },
  //         });
  //       }
  //     });

  //     var currency = "";
  //     $(".DoctorList").change(function () {
  //       if ($(this).val()) {
  //         var upi = false;
  //         var fees = false;
  //         console.log(
  //           $(this).val(),
  //           $(".UnitList").val(),
  //           $(".SpecialityList").val()
  //         );
  //         $("body").addClass("loaderWrap");
  //         $.ajax({
  //           type: "GET",
  //           url:
  //             "https://backend.rainbowhospitals.in/api/home/get/upi/" +
  //             $(".UnitList").val(),
  //           success: function (data) {
  //             upi = true;
  //             $(".QR_Image").attr("src", data[0].upilink);
  //             $(".upiid").text(data[0].upiid);
  //             $(".QR-Wrapper").show();
  //             if (upi && fees) {
  //               $("body").removeClass("loaderWrap");
  //             }
  //           },
  //           // error: function (data) { onSuccess(data); }
  //         });
  //         var doctorselected = $(this).val();
  //         fridayOnlyFunction(doctorselected);
  //         var unitselected = $(".UnitList").val();
  //         var countryselected = $(".Countrycode").val();
  //         console.log(doctorselected);
  //         $("body").addClass("loaderWrap");
  //         $.ajax({
  //           type: "POST",
  //           //  url: "https://lmsuat.rainbowhospitals.in:8080/hdp/hdptest/insert.php?action=getdoctorfees",
  //           url: "https://www.rainbowhospitals.in/hello-doctor-online-consultation/insert.php?action=getdoctorlist",
  //           data: {
  //             Doctor: doctorselected,
  //             Unit: unitselected,
  //             Country: countryselected,
  //             action: "getdoctorfees",
  //           },
  //           success: function (response) {
  //             fees = true;
  //             $("body").removeClass("loaderWrap");
  //             var countryselected = $(".Countrycode").val();
  //             console.log(countryselected);
  //             console.log(response);
  //             var fees = response.replace(/"/g, "");
  //             if (fees != "0") {
  //               $("#amount").val(fees);
  //               $(".paymentRs").text(fees);
  //               $(".paym").text(fees);
  //               if (upi && fees) {
  //                 $("body").removeClass("loaderWrap");
  //               }
  //             }
  //             // if(response == 'IN' && $("#Countrycode").val() == '91-India'){
  //             //         $(".currencySymbols").html('₹');
  //             //         $("#ifNotIndia").hide();
  //             //     }else{
  //             //         $(".currencySymbols").html('$');
  //             //         $("#ifNotIndia").show();
  //             //     }
  //           },
  //         });
  //       }
  //     });
  //   });

  //   function setSymbol(e) {
  //     console.log(event.target.value);
  //     if (event.target.value == "91-India") {
  //       $(".currencySymbols").html("₹");
  //     } else {
  //       $(".currencySymbols").html("$");
  //     }

  //     $("#City").val("");
  //     $("#Speciality").html('<option value="">*Speciality</option>');
  //     $("#unit").html('<option value="">*Select Unit</option>');
  //     $("#Doctor").html('<option value="">*Doctor</option>');
  //     $(".QR-Wrapper").hide();
  //     $(".paymentRs").html("");
  //   }

  //   function getCountryCodes() {
  //     $("body").addClass("loaderWrap");
  //     $.ajax({
  //       type: "POST",
  //       // url: "https://lmsuat.rainbowhospitals.in:8080/hdp/hdptest/insert.php?action=getUserLocation",
  //       url: "https://www.rainbowhospitals.in/hello-doctor-online-consultation/insert.php?action=getUserLocation",
  //       //  url: "https://rainbowfrontenduat.rainbowhospitals.in/hello-doctor-online-consultation/insert.php?action=getUserLocation",
  //       data: { ino: "", action: "getUserLocation" },
  //       success: function (response) {
  //         console.log("ip response =", response);
  //         $("body").removeClass("loaderWrap");

  //         if (response) {
  //           $(".Countrycode optgroup option").each(function () {
  //             if (response == $(this).attr("data-countrycode")) {
  //               $(this).attr("selected", "selected");
  //             }
  //           });
  //         } else if (response == "") {
  //           $(".Countrycode optgroup option").each(function () {
  //             if ($(this).attr("data-countrycode") == "IN") {
  //               $(this).attr("selected", "selected");
  //             }
  //           });
  //         }
  //         console.log("response", response, $("#Countrycode").val());
  //         if (response == "IN" && $("#Countrycode").val() == "91-India") {
  //           $(".currencySymbols").html("₹");
  //           $("#ifNotIndia").hide();
  //         } else {
  //           $(".currencySymbols").html("$");
  //           $("#ifNotIndia").show();
  //         }
  //       },
  //     });
  //   }
  //   getCountryCodes();

  //   var y = document.getElementById("theDateSelector2");
  //   y.style.display = "none";

  //   // Friday Only Slots Start

  //   function fridayOnlyFunction(doctorSelected) {
  //     if (doctorSelected === "LOKESH LINGAPPA") {
  //       var x = document.getElementById("theDateSelector1");
  //       x.style.display = "none";
  //       var y = document.getElementById("theDateSelector2");
  //       y.style.display = "block";
  //     } else {
  //       var x = document.getElementById("theDateSelector1");
  //       x.style.display = "block";
  //       var y = document.getElementById("theDateSelector2");
  //       y.style.display = "none";
  //     }
  //   }

  //   function getNoOfSlots(getDate) {
  //     var dateSelected = getDate;

  //     $.ajax({
  //       type: "POST",
  //       url: "https://www.rainbowhospitals.in/hello-doctor-online-consultation/slotlimmiter.php?action=getDoctorLimit",
  //       // data: { action: "getDoctorLimit" }, // Just Call Function
  //       data: { dateChosen: dateSelected, action: "getDoctorLimit" }, // Call Function with Parameters
  //       success: function (response) {
  //         // console.log(response);

  //         if (response != "[]") {
  //           var result = $.parseJSON(response);

  //           console.log("No. of Slots Booked: " + result[0].no_of_slots);

  //           localStorage.setItem("no_of_slots", result[0].no_of_slots);

  //           // Slots Limited to 8 Per Every Friday
  //           if (result[0].no_of_slots >= 8) {
  //             alert(
  //               "All slots for Dr. Lokesh Lingappa are booked on this date."
  //             );
  //             document.getElementById("PreferredDate2").value = null;
  //           }
  //         } else {
  //           postSlots(dateSelected, 0);
  //         }
  //       },
  //     });
  //   }

  //   function postSlots(getDate, getSlots) {
  //     var dateSelected = getDate;
  //     var no_of_slots = getSlots;

  //     $.ajax({
  //       type: "POST",
  //       url: "https://www.rainbowhospitals.in/hello-doctor-online-consultation/slotlimmiter.php?action=postDoctorLimit",
  //       data: {
  //         dateChosen: dateSelected,
  //         no_of_slots: no_of_slots,
  //         action: "postDoctorLimit",
  //       }, // Call Function with Parameters
  //       success: function (response) {
  //         console.log(response);
  //       },
  //     });
  //   }

  //   function getSlotsAgain() {
  //     var dateChosen = document.getElementById("PreferredDate2").value;
  //     getNoOfSlots(dateChosen);
  //   }

  //   function updateSlots(getDate, getSlots) {
  //     var dateSelected = getDate;
  //     var no_of_slots = getSlots;

  //     $.ajax({
  //       type: "POST",
  //       url: "https://www.rainbowhospitals.in/hello-doctor-online-consultation/slotlimmiter.php?action=updateDoctorLimit",
  //       data: {
  //         dateChosen: dateSelected,
  //         no_of_slots: no_of_slots,
  //         action: "updateDoctorLimit",
  //       }, // Call Function with Parameters
  //       success: function (response) {
  //         console.log(response);
  //       },
  //     });
  //   }

  //   $(".showOnlyFridays").datepicker({
  //     dateFormat: "yy-mm-dd",
  //     minDate: formatAMPM(new Date()) >= "12:00 pm" ? "1D" : "0D",
  //     minTime: "12:00",
  //     maxDate: "110Y",
  //     startDate: "-0d",
  //     beforeShowDay: function (date) {
  //       return [date.getDay() === 5, ""];
  //     },
  //     onSelect: function (date) {
  //       getNoOfSlots(date);
  //       checkIfDateIsStillInvalid();
  //     },
  //   });

  //   // Friday Only Slots End
</script>
<!-- <script type="text/javascript" src="js/getQueryString.js"></script> -->
<script type="text/javascript">
  getQueryData();
</script>
<!-- <script src="https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.14.1/moment.min.js"></script> -->
<!-- <script type="text/javascript" src="js/dataLayer.js"></script> -->
<!-- </body> -->

<!-- <style>
    .datePickerErrorClass {
      position: relative;
      font-size: 15px;
      font-weight: bold;
      color: #c0274f;
      padding-left: 6px;
    }
  </style> -->
<!-- </html> -->
