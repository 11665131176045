<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>

    <section class="page wtwedo">
        <div class="row">
            <div class="col-md-5 col-xs-5 col-lg-4">
                <div class="circuleframe">
                    <img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" />
                </div>
                <!-- End Circle -->
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7">
                <div class="pagetitle">
                    <h1 class="tgradient">{{title}} </h1>
                    <div class="underline">
                        <img src="assets/images/underline2.png" alt="" />
                    </div>
                    <h3>{{subtitle}}</h3>
                </div>
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7 mcontentview">
                <p [innerHTML]="description"></p>
            </div>
        </div>

    </section>
    <section class="aboutsectin2 awardswapper">
        <div class="container">
            <div class="row nsg_text">
                <div class="col-xs-12 col-lg-12">
                    <h2>Screening for Chromosomal abnormalities in the fetus</h2>
                    <p>Screening is a process applied on apparently healthy individuals to detect the risk of any particular problem. It is offered to a general population and the results of screening will determine whether the individual is at ‘high risk’
                        or ‘low risk’ for a given condition. When we talk about screening for fetal abnormalities, we need to first understand that every pregnant woman carries a risk of having a fetus with chromosomal abnormalities – this risk is called
                        ‘background risk’ or ‘a priori risk’ and depends on maternal age and some factors in her medical history.&nbsp; This risk can be reassessed based on screening tests to ‘high’ or ‘low’ risk. Women with ‘low risk’ can be reassured
                        while those with ‘high risk’ can be offered confirmatory tests to check fetal chromosomes. The tests to confirm fetal chromosomes are generally ‘invasive tests’ which carry a risk of miscarriage and hence they are justifiably undertaken
                        only if the risk of finding a chromosomal abnormality in the fetus is high based on the results of the screening test.</p>
                    <p>Therefore, a screening test helps in optimizing the needs for invasive testing while increasing the pick-up rate for fetal chromosomal problems (aneuploidies). The following tests can be offered to any pregnant woman who opts for “Fetal
                        Aneuploidy Screening”.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>First trimester combined screening test</h2>
                    <p>This test involves a blood test and the Nuchal Translucency scan.</p>
                    <ul>
                        <li><strong>First trimester screening blood test (also called “double test”):</strong> This test involves a blood test that measures the quantity of two chemicals in the mother’s blood – free beta hCG (human chorionic gonadotropin)
                            and PAPP – A (Pregnancy Associated Plasma Protein A). PAPP – A is a protein produced first by the outer layer of the developing pregnancy (trophoblast) and then by the growing placenta. During a normal pregnancy, levels of
                            this protein increase in the mother’s blood until delivery. Human chorionic gonadotropic (hCG) is a hormone produced by the developing early pregnancy and then large quantities by the placenta. The free beta subunit of hCG
                            usually rise rapidly in the mother’s circulation for the first 8-10 weeks, then decrease and stabilize at a lower level for the remainder of the pregnancy.<br> The first trimester screening blood test can be done at anytime
                            from 9-13+6 weeks of pregnancy. The sensitivity of the test is better around 10 weeks than around 13 weeks.</li>
                    </ul>
                    <ul>
                        <li><strong>Nuchal Translucency scan (NT): </strong>Nuchal Translucency (NT) is a sonographic (ultrasound scan) appearance of the collection of fluid under the skin behind the fetal neck in the first trimester in pregnancy. Increase
                            nuchal translucency has been associated with increased risk of fetal aneuploidies.<br>This scan, also called the NT scan is a highly specialized scan done by doctors who are certified by the Fetal Medicine Foundation (UK) for
                            these scans. These doctors have a valid license which allows them to calculate individual numeric risk of Down syndrome and other chromosomal abnormalities in the fetus. In addition to the nuchal translucency, there are other
                            first trimester markers that can be checked on this scan such as - the flow across the tricuspid valve, the ductus venosus Doppler, the presence/absence of the fetal nasal bone and the fetal facial angle. These markers add
                            to the sensitivity of the NT scan in detecting the risk for Down syndrome. This scan is also the first opportunity to screen the fetus for major structural anomalies.</li>
                    </ul>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>Screening for Fetal Defects at 11-13+6 weeks</h2>
                    <p>The first trimester combined screening test gives a risk reassessment to the woman as to whether her risks of having a fetus with Down syndrome is increased or decreased as compared to her age related risk. This numerical assessment
                        helps to reassure the couple when the risks have reduced and when the risks have increased, the couple have an opportunity to decide whether they need further screening tests or opt for diagnostic tests directly.<br> The diagnostic
                        test at this stage (11-14 weeks of gestation) is called a “chorionic villus sampling (CVS)” and involves collection of fetal tissue from the placenta by inserting a needle into the womb through the mother’s abdomen, under ultrasound
                        guidance.</p>
                    <p>This procedure will help in getting tissue to confirm the chromosomal composition (Karyotype) of the fetus and this will further enable us – the ‘fetal care team’ to formulate a plan for further management of the pregnancy. Despite
                        the definitive advantage of confirming fetal karyotype, the concern with CVS is that it is an “invasive test” where we prick a needle into the womb and hence it carries a risk of miscarriage of pregnancy which is of the order of
                        1% above the background risk of miscarriage at that gestation.&nbsp; For this reason, the decision to opt for invasive testing should be well considered and discussed thoroughly between the couple and the fetal care team. In case
                        the couple opt for further screening tests to reassess the risks rather than an invasive test right away, then they have the options of carrying out other tests during second trimester.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>Second trimester maternal serum biochemistry (Quadruple test)</h2>
                    <p>The composition of certain “feto-placental” chemicals can be tested in the maternal serum between 15-22 weeks to establish the risk for fetal aneuploidies. The relative proportion of these chemicals change in pregnancies associated
                        with chromosomal abnormalities and this the rationale of evaluating the quantity of these substances and comparing them in terms of “multiples of median (MoM)” for that stage of gestation.<br> Traditionally, the triple serum test
                        was performed which evaluated maternal serum alfa-fetoprotein, hCG and unconjugated estriol to assess the risk for Down syndrome. Now, the quadruple test has been known to have a better sensitivity with a lower false positive rate
                        and hence is the preferred modality of second trimester maternal serum biochemistry.<br> The quadruple test checks for the maternal serum Inhibin – A in addition to alfa-fetoprotein, hCG and unconjugated estriol to assess the risk
                        for Down syndrome.&nbsp; The quadruple test is also a ‘screening test’ and the results generally indicated a ‘low risk’ or ‘high risk’ patients are explained that they need to be further evaluated to establish the need for definitive
                        diagnostic tests to confirm fetal karyotype.&nbsp; As explained earlier, the diagnostic tests are ‘invasive’ and carry the risk of miscarriage so they are done only when all options of risk reassessment justify the need for such
                        tests.</p>
                    <p>The other option for risk assessment for women with a ‘high risk’ on quadruple test is a detailed ultrasound called the ‘genetic sonogram’.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>Genetic Sonogram</h2>
                    <p>This is a systematic ultrasound evaluation of the fetus to look for any obvious ‘markers’ for fetal aneuploidy. There are ‘major markers’ or ‘minor markers’ that can be seen at the time of the scan. The major makers are the structural
                        anomalies which definitely increase the risk for associated chromosomal abnormalities and hence justify the need for invasive testing to confirm fetal karyotype. The ‘minor markers’ are subtle features which can be detected by
                        trained sonographers and although they may not cause any functional implications to the fetus as such, their presence or absence alters the risk for fetal aneuploidies.</p>
                    <p>This alteration occurs based on the ‘likelihood ratio’ of that particular minor marker with relation to fetal aneuploidy. A minor marker with a high likelihood ration (&gt;1) for Down syndrome will increase the risk for the condition
                        of it is present and decrease the risk for the condition if it is absent. Therefore the final risk for Down Syndrome is allocated after considering the presence or absence of many minor makers as shown in the picture.<br> Once
                        again, couples with ‘low risk’ for aneuploidies are reassured and those with ‘high risk’ are given the option of confirming fetal karyotype by an ‘amniocentesis’ or ‘fetal blood sampling’. Both procedures are invasive and carry
                        the risk of miscarriage of the pregnancy which is of the order of 1% above the background risk of miscarriage at this stage of gestation.</p>
                    <p>In ‘amniocentesis’, a needle is introduced through the mother’s abdomen into the womb and some amniotic fluid is collected from around the fetus. This fluid has ‘amniocytes’ which are cells of fetal origin and they can be cultured
                        to grow into many cells and the fetal chromosomes can be tested on them.</p>
                    <p>The ‘fetal blood sampling’ procedure of introduction of the needle is similar to amniocentesis but instead of the amniotic fluid, the umbilical cord blood is collected to obtain fetal cells. This procedure can be useful when the amniotic
                        fluid volume is very less or when amniotic fluid culture has failed to grow.</p>
                    <p>The results of fetal chromosome analysis can be obtained in 2-3 weeks after the test is performed as that is the time needed for the cells to grow in culture to check the complete fetal karyotype. In case the results are needed earlier,
                        FISH (fluorescent in situ hybridization) can be done to check for few chromosomes and specific aneuploidies can be ruled out in 3-4 days.</p>
                    <p>If the fetal karyotype is normal, it is hugely reassuring for the parents and the fetal care team. In some unfortunate couples, the fetal karyotype may show abnormalities and then they are counseled by the fetal medicine specialists
                        and advised on the future management of the pregnancy after considering their individual circumstances, expectations and options. We may not be able to provide a “cure” for everything but we can certainly assure “care” for everything.</p>
                </div>
            </div>
        </div>
    </section>
    <ng-template [ngIf]="ins_links.length > 0">
        <footer-nav-mini [links]="ins_links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>