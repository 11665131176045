import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL } from '../services/constants';
declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
import { Meta, Title } from '@angular/platform-browser';
// declare let initAboutTabs:any;

@Component({
  selector: 'careers-join-withus',
  templateUrl: './careers-join-withus.component.html',
})
export class CareersJoinWithUsComponent
  implements OnDestroy, OnInit, AfterViewInit
{
  selectSpeciality: any = 0;
  selectLocation: any = 0;

  constructor(
    private api: ApiService,
    public metaTitle: Title,
    public meta: Meta
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
    }
  }

  public careers: any = [];
  public careersList: boolean = false;
  public cspeciality: any = [];
  public clocation: any = [];
  public title: any = '';
  public subtitle: any = '';
  public description: any = '';
  public imgsrc: any = '';
  public imgBasePath = '';
  public hidden = 'hidden';
  public logo = 'logo';
  public bread_crumb_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'About Us', link: '/about-us', class: '' },
    { label: 'Careers', link: '/about-us/careers', class: '' },
    { label: 'Current openings', link: '', class: '' },
  ];

  public links: any = [];
  public activePage: string = 'careers';

  // @ViewChildren('abouttabs') things: QueryList<any>;

  ngOnInit() {
    this.api
      .getAboutSubFooterDetails()
      .map((res) => res)
      .subscribe((data) => {
        this.links = data;
      });
    this.api.getPageDescription('careers-join-withus').subscribe(
      (data) => {
        (this.title = data.page_descriptions[0].title),
          (this.subtitle = data.page_descriptions[0].subtitle),
          (this.description = data.page_descriptions[0].description),
          (this.imgsrc = data.page_descriptions[0].image),
          (this.imgBasePath = IMG_BASE_URL);
        if (data) {
          //console.log(data);
          this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
          this.meta.updateTag({
            name: 'keywords',
            content: data.page_descriptions[0].meta_keyword,
          });
          this.meta.updateTag({
            name: 'description',
            content: data.page_descriptions[0].meta_description,
          });
          this.meta.addTags([
            {
              property: 'og:title',
              content: data.page_descriptions[0].meta_title,
            },
            {
              property: 'og:description',
              content: data.page_descriptions[0].meta_description,
            },
            {
              property: 'og:keywords',
              content: data.page_descriptions[0].meta_keyword,
            },
          ]);
        }
      },
      (error) => {
        console.log(error);
      }
    );

    this.api.getCareers().subscribe(
      (data) => {
        (this.careers = data.careers), (this.imgBasePath = IMG_BASE_URL);
      },
      (error) => {
        console.log(error);
      }
    );

    this.api
      .getSpecialityMasters()
      .map((res) => res)
      .subscribe(
        (data) => {
          this.cspeciality = data.speciality_masters;
        },
        (error) => {
          console.log(error);
        }
      );

    this.api
      .getCities()
      .map((res) => res)
      .subscribe(
        (data) => {
          this.clocation = data.cities;
        },
        (error) => {
          console.log(error);
        }
      );
  }
  searchCareerDetails() {
    this.api
      .searchCareerDetails(this.selectLocation, this.selectSpeciality)
      .subscribe(
        (data) => {
          (this.careers = data.careers), (this.imgBasePath = IMG_BASE_URL);
        },
        (error) => {
          console.log(error);
        }
      );
    console.log('Location ' + this.selectLocation);
    console.log('Speciality ' + this.selectSpeciality);
  }
  searchCareerDetailsByCity() {
    this.api.searchCareerDetailsByCity(this.selectLocation).subscribe(
      (data) => {
        (this.careers = data.careers), (this.imgBasePath = IMG_BASE_URL);
        //console.log('City '+this.selectLocation);
        if (this.selectLocation == 0) {
          this.api.getCareers().subscribe((data) => {
            (this.careers = data.careers), (this.imgBasePath = IMG_BASE_URL);
          });
          this.careersList = false;
        }
        if (this.careers) {
          this.careersList = false;
        } else {
          this.careersList = true;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
    }

    // this.things.changes.subscribe(t => {
    //   // this.ngForRendred();
    //   initAboutTabs();
    // })
  }

  ngOnDestroy() {}
}
