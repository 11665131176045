<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>

    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>

    <section class="page pageheader">
        <div class="container">
            <div class="col-xs-12 col-sm-12 col-lg-12">
                <div class="ptitle">
                    <h1>{{pageInfo.title}}</h1>
                    <h3>{{pageInfo.subtitle}}</h3>
                    <p [innerHTML]="pageInfo.description"></p>
                </div>
            </div>
        </div>
    </section>
    <section class="aboutsectin2 wcuswrp">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-lg-12">
                    <div class="calculatorwrp">
                        <div class="calcInfoWrp">
                            <div class="alertOverlay"></div>
                            <div class="calcInfobox">
                                <div class="closebtn">
                                    <a href="#"><img src="assets/images/icon-close2.png" alt="" /></a>
                                </div>
                                <h2> How does the ovulation calculator work?</h2>
                                <p class="first">
                                    Most women ovulate about two weeks before the first day of their period. Our calculator works out when your cycle will end, and counts back two weeks to estimate when you may ovulate. You will be fertile in the days leading up to ovulation, as well as
                                    the day itself, so the ovulation calculator will show your entire fertile window. This is the best time of the month for you to have intercourse if you're trying for a baby.
                                </p>
                                <p>
                                    You will also get to know your upcoming fertile days, and when your baby will be due if you do conceive. So you'll have all the information you need to start planning your pregnancy.
                                </p>
                            </div>
                        </div>
                        <div class="calctopnav">
                            <a href="#" class="gobacktotime"><img src="assets/images/calc/icon-backarrow.png" alt="" /> Go back to Date selection</a>
                            <ul class="calcnav">
                                <li class="dropdowntn CalcSignIn active"><a href="javascript:void(0)" [attr.data-id]="calcCAccountShow"><span class="login"></span></a>
                                    <div class="calcSignIn dropdown-tggle">
                                        <ul *ngIf="!loggedInUser">
                                            <li><a href="javascript:void(0)" class="SignIn">Sign In</a></li>
                                            <li><a href="javascript:void(0)" class="CreateAnAccount">Create an account</a></li>
                                        </ul>
                                        <ul *ngIf="loggedInUser">
                                            <li id="ApiUser" *ngIf="ApiUsersignOut"><span class="infoEmail">Hello <br />{{userInfoEmail}}</span><br /><a (click)="userLogout()" href="javascript:void(0)">Logout</a></li>
                                            <li id="facebookUser" *ngIf="facebookLoggedInUser"><span class="infoEmail">Hello <br />{{userInfoEmail}}</span><br /><a (click)="logoutFacebook()" href="javascript:void(0)">Logout</a></li>
                                            <li id="googleUser" *ngIf="googleLoggedInUser"><span class="infoEmail">Hello <br />{{userInfoEmail}}</span><br /><a (click)="GoogleSignOut()" href="javascript:void(0)">Logout</a></li>
                                        </ul>
                                    </div>
                                </li>
                                <li class="ovulationCal"><a href="javascript:void(0)" data-id="ocCalc"><span class="ocCal1"></span></a></li>
                                <li><a href="javascript:void(0)" data-id="ocCalc2"><span class="ocCal2"></span></a></li>
                                <li class="CalcAlarm"><a href="javascript:void(0)" class="calcInfo"><span class="info"></span></a></li>
                                <li class="dropdowntn"><a href="javascript:void(0)"><span class="share"></span></a>
                                    <div class="calcSocial dropdown-tggle">
                                        <ul>
                                            <li><a [href]="fb_url" target="_blank" class="fb">&nbsp;</a></li>
                                            <li><a [href]="twitter_url" target="_blank" class="tw">&nbsp;</a></li>
                                        </ul>
                                    </div>
                                </li>

                            </ul>
                        </div>
                        <!-- Child Calculator Navigation -->
                        <div class="caawrp calcscreen3 calcLogn calctab">
                            <div class="formbox">
                                <div class="calcttitle">
                                    <h2>Sign in</h2>
                                </div>
                                <form name="CAppointmentSN" [formGroup]="userLogin" id="CAppointmentSN" method="post" action="">
                                    <ul class="formcasn signForm">
                                        <li>
                                            <div class="fbox"><label>Email ID*</label>
                                                <input type="text" name="userEmailId" id="txtEmaiIDSN" placeholder="Enter Email ID" [ngClass]="{'has-error':!userLogin.controls.userEmailId.valid 
                                            && userLogin.controls.userEmailId.touched}" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" formControlName="userEmailId">
                                            </div>
                                        </li>
                                        <li>
                                            <div class="fbox">
                                                <label>Password</label>
                                                <input type="password" name="userPassword" maxlength="10" id="txtMNameSN" placeholder="Enter Password" [ngClass]="{'has-error':!userLogin.controls.userPassword.valid 
                                            && userLogin.controls.userPassword.touched}" formControlName="userPassword">
                                            </div>
                                        </li>
                                    </ul>
                                    <div class="signupwrp" style="margin:0px 0px 0px">
                                        <div class="tncnbcwrp signLinkColor">
                                            <a href="javascript:;" class="FPassBtn">Forgot password</a>
                                        </div>
                                    </div>
                                    <div class="signupwrp">
                                        <div class="tncnbcwrp signLinkColor">
                                            <a href="javascript:;" class="CreateAnAccount">Create An Account</a> <span style="opacity: 1;">/ or login with
                                            <a href="javascript:void(0);" (click)="FBLogin()"><img src="../../assets/images/facebook_icon.png" alt="Facebook Login" /></a>
                                            <a href="javascript:void(0);" id="googleBtn"><img src="../../assets/images/google_icon.png" alt="Google Login" /></a></span>
                                        </div>
                                        <div class="submitbtnrp">
                                            <input type="button" name="submit" [disabled]="!userLogin.valid" (click)="submitLogin()" class="casubmit" id="caSubmitSN" value="Submit">
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <!-- Account Login -->
                        <div class="caawrp calcscreen4 calcFPass calctab">
                            <div class="formbox">
                                <div class="calcttitle">
                                    <h2>Forgot Password</h2>
                                </div>
                                <form name="CAppointmentSN" [formGroup]="userForgotPassword" id="CAppointmentSN" method="post" action="">
                                    <ul class="formcasn">
                                        <li>
                                            <div class="fbox">
                                                <label>Email ID*</label>
                                                <input type="text" name="userEmailId" id="txtEmaiIDSN" placeholder="Enter Email ID" [ngClass]="{'has-error':!userForgotPassword.controls.userEmailId.valid 
                                            && userForgotPassword.controls.userEmailId.touched}" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" formControlName="userEmailId">
                                            </div>
                                        </li>
                                    </ul>
                                    <div class="signupwrp">
                                        <div class="submitbtnrp">
                                            <input type="button" name="submit" class="casubmit" [disabled]="!userForgotPassword.valid" (click)="submitForgotpassword()" id="caSubmitSN" value="Submit">
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <!-- Forgot Password -->
                        <div class="caawrp calcscreen5 calcCAccount calctab">
                            <div class="formbox">
                                <div class="calcttitle">
                                    <h2>Create An Account</h2>
                                </div>
                                <form name="CAppointmentSN" [formGroup]="userRegisterForm" id="CAppointmentSN" method="post" action="">
                                    <ul class="formcasn">
                                        <li>
                                            <div class="fbox"><label>Name*<span class="nameErrorMsg"></span></label><input type="text" formControlName="userName" name="userName" (keypress)="isAlpha($event)" pattern="[A-Za-z]+" id="txtVisitorNameSN" placeholder="Enter Patient/Visitor Name"></div>
                                        </li>
                                        <li>
                                            <div class="fbox"><label>Email ID*<span class=" emailErrorMsg"></span></label><input type="text" formControlName="userEmailId" name="userEmailId" [ngClass]="{'has-error':!userRegisterForm.controls.userEmailId.valid 
                                            && userRegisterForm.controls.userEmailId.touched}" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" id="txtEmaiIDSN" placeholder="Enter Email ID"></div>
                                        </li>
                                        <li>
                                            <div class="fbox"><label>Mobile*<span class=" mobileErrorMsg"></span></label><input type="text" formControlName="userMobile" name="userMobile" [ngClass]="{'has-error':!userRegisterForm.controls.userMobile.valid 
											&& userRegisterForm.controls.userMobile.touched}" maxlength="10" (keypress)="isNumber($event)" id="txtMobileSN" placeholder="Enter Mobile Number"></div>
                                        </li>
                                        <li>
                                            <div class="fbox" formGroupName="passGroup"><label>Password</label><input type="password" [class.has-error]="userRegisterForm.get('passGroup').errors?.invalid" formControlName="userRegPassword" name="userRegPassword" maxlength="10" id="txtMNameSN" placeholder="Enter Password"></div>
                                        </li>
                                        <li>
                                            <div class="fbox" formGroupName="passGroup">
                                                <label>Confirm Password</label>
                                                <input type="password" formControlName="userRegConfirmPassword" name="userRegConfirmPassword" maxlength="10" [class.has-error]="userRegisterForm.get('passGroup').errors?.invalid" id="txtCalcCP" placeholder="Re Enter Password">
                                            </div>
                                        </li>
                                        <!-- <li class="bdcalender">
                                            <div class="fbox"><label>Birth Date</label>
                                                <input type="text" formControlName="userDOB" [ngClass]="{'has-error':!userRegisterForm.controls.userDOB.valid 
                                            && userRegisterForm.controls.userDOB.touched}" (blur)="onDateChange($event)" class="datepickr2" name="userDOB" id="txtCalcBD" placeholder="DD / MM / YYYY" /></div>
                                        </li> -->
                                        <li  class="bdcalender">
                                            <div  class="fbox"><label >Birth Date</label>
                                                <span class="Zebra_DatePicker_Icon_Wrapper" style="display: block; position: relative; float: left; inset: auto; width: 0px;"><input  class="datepickr2 " formcontrolname="userDOB" id="txtCalcBD" name="userDOB" placeholder="DD / MM / YYYY" [ngClass]="{'has-error':!userRegisterForm.controls.userDOB.valid && userRegisterForm.controls.userDOB.touched}"  type="text" (blur)="onDateChange($event)" style="position: relative; inset: auto;"><button type="button" class="Zebra_DatePicker_Icon Zebra_DatePicker_Icon_Inside_Right" style="top: 14.2414px; right: 0px;">Pick a date</button></span></div>
                                        </li>
                                        <li>
                                            <div class="fbox"><label>Age</label>
                                                <div class="selectbox">
                                                    <select class="select" formControlName="userAge" name="userAge" id="txtCalcAge">
                                                    <option value="Refer by Doctor" selected>Refer by Doctor</option>
                                                    <option *ngFor="let a of ageArray; let i = index;" value="{{i+20}}">
                                                        {{ i+20 }} Years
                                                    </option>
                                                </select>
                                                    <span id="selectSource" class="select-span">Refer by Doctor</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="CalcWeight">
                                            <div class="fbox"><label>Weight</label>
                                                <input type="text" formControlName="userWeight" [ngClass]="{'has-error':!userRegisterForm.controls.userWeight.valid 
                                            && userRegisterForm.controls.userWeight.touched}" name="userWeight" (keypress)="isNumber($event)" id="txtCalcWeight" minlength="1" maxlength="3" placeholder="55"></div>
                                        </li>
                                    </ul>
                                    <div class="signupwrp">
                                        <div class="tncnbcwrp">
                                            <ul>
                                                <li>
                                                    <input type="checkbox" name="confirm" formControlName="confirm" id="SNTNCA" checked="checked" value="yes">
                                                    <label>
                                                    <a data-fancybox="modal" data-src="#tncmodal" href="javascript:;">Terms &amp; conditions apply</a>
                                                </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="submitbtnrp">
                                            <input type="button" [disabled]="!userRegisterForm.valid" (click)="submitUserRegister()" name="submit" class="casubmit" id="caSubmitSN" value="Register">
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <!-- Create An Account -->

                        <!--ovulation start-->
                        <div class="OvulationWrapper ocCalc calctab">
                            <div class="col-xs-12 Selectoption OCScreens active">
                                <div class="calcttitle">
                                    <h2>I Want to</h2>
                                </div>
                                <div class="calcirwrp">
                                    <div class="col-xs-12 col-sm-10 col-md-8 col-sm-push-1 col-md-push-2">

                                        <div class="col-xs-12 col-sm-6">
                                            <div class="icon">
                                                <a href="javascript:;"><img src="assets/images/Get-Pregnant.png" /></a>
                                            </div>
                                            <h2>Get Pregnant </h2>
                                        </div>

                                        <div class="col-xs-12 col-sm-6">
                                            <div class="icon">
                                                <a href="javascript:;"><img src="assets/images/Track-My-Periods.png" /></a>
                                            </div>
                                            <h2>Track My Periods</h2>
                                        </div>
                                        <div class="clearfix"></div>
                                        <p class="note">Select your option to explore the sections.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="caawrp OCScreens">

                                <div class="formbox">
                                    <div class="calcttitle">
                                        <h2>Tell Us about you</h2>
                                    </div>
                                    <form name="CAppointmentSN" id="CAppointmentSN" method="post" action="">
                                        <ul class="formcasn">
                                            <li class="bdcalender">
                                                <div class="fbox"><label>1st day of your Last Period</label>
                                                    <input type="text" class="LastPeriodDate hasDatepicker" name="txtCalcBD" id="startDate" placeholder="DD / MM / YYYY"  /></div>
                                            </li>
                                            <li>
                                                <div class="fbox"><label>Period Length</label>
                                                    <div class="selectbox">
                                                        <select class="select" name="txtCalcAge" id="MPDay">
                                            <option value="1">1 Days</option>
                                            <option value="2">2 Days</option>
                                            <option value="3">3 Days</option>
                                            <option value="4" selected="selected">4 Days</option>
                                            <option value="5">5 Days</option>
                                            <option value="6">6 Days</option>
                                            <option value="7">7 Days</option>
                                        </select>
                                                        <span id="selectSource" class="select-span">Refer by Doctor</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="fbox"><label>Cycle Length</label>
                                                    <div class="selectbox">
                                                        <select class="select CycleDays" name="txtCalcAge">
                                            <option value="20">20 Days</option>
                                            <option value="21">21 Days</option>
                                            <option value="22">22 Days</option>
                                            <option value="23">23 Days</option>
                                            <option value="24">24 Days</option>
                                            <option value="25">25 Days</option>
                                            <option value="26">26 Days</option>
                                            <option value="27">27 Days</option>
                                            <option selected="selected" value="28">28 Days</option>
                                            <option value="29">29 Days</option>
                                            <option value="30">30 Days</option>
                                            <option value="31">31 Days</option>
                                            <option value="32">32 Days</option>
                                            <option value="33">33 Days</option>
                                            <option value="34">34 Days</option>
                                            <option value="35">35 Days</option>
                                            <option value="36">36 Days</option>
                                            <option value="37">37 Days</option>
                                            <option value="38">38 Days</option>
                                            <option value="39">39 Days</option>
                                            <option value="40">40 Days</option>
                                            <option value="41">41 Days</option>
                                            <option value="42">42 Days</option>
                                            <option value="43">43 Days</option>
                                            <option value="44">44 Days</option>
                                            <option value="45">45 Days</option>
                                        </select>
                                                        <span id="selectSource" class="select-span">Refer by Doctor</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <!-- <li><div class="fbox"><label>Cycle irregular</label>
                                    <div class="selectbox">
                                        <select class="select" name="txtCalcAge" id="txtCalcAge">
                                            <option value="Refer by Doctor" selected="">Select Cycle irregular</option>
                                            <option value="DL">0 Days</option>
                                            <option value="DL">1 Days</option>
                                            <option value="DL">2 Days</option>
                                            <option value="MU">3 Days</option>
                                            <option value="MU">4 Days</option>
                                            <option value="MU">5 Days</option>
                                            <option value="MU">6 Days</option>
                                            <option value="MU">7 Days</option>
                                        </select>
                                        <span id="selectSource" class="select-span">Refer by Doctor</span>
                                    </div>
                                    </div>
                                </li>
                                <li><div class="fbox"><label>Period Length</label>
                                    <div class="selectbox">
                                        <select class="select" name="txtCalcAge" id="txtCalcAge">
                                            <option value="Refer by Doctor" selected="">Select Your Age</option>
                                            <option value="DL">14 Years</option>
                                            <option value="MU">15 Years</option>
                                            <option value="MU">16 Years</option>
                                            <option value="MU">17 Years</option>
                                            <option value="MU">18 Years</option>
                                            <option value="MU">19 Years</option>
                                        </select>
                                        <span id="selectSource" class="select-span">Refer by Doctor</span>
                                    </div>
                                    </div>
                                </li>
                                <li class="CalcWeight">
                                	<div class="fbox">
                                    	<label>Weight</label>
                                        <input type="text" name="txtCalcWeight" id="txtCalcWeight" placeholder="55">
                                    </div>
                                </li>-->
                                        </ul>
                                        <div class="signupwrp">
                                            <div class="tncnbcwrp">
                                                <ul>
                                                    <li>
                                                        <input type="checkbox" name="confirm" id="SNTNCA" checked="checked" value="yes">
                                                        <label>
                                            	<a data-fancybox="modal" data-src="#tncmodal" href="javascript:;">Terms &amp; conditions apply</a>
                                            </label>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="submitbtnrp">
                                                <input type="button" name="submit" class="casubmit OCsubmit" value="Submit">
                                                <input type="button" name="back" class="casubmit OCback" value="Back">
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div class="OvulationCalculatorBox OCScreens">
                                <div class="calcttitle">
                                    <h2>Ovulation Circle</h2>
                                </div>
                                <div class="calcirwrp">

                                    <div class="OvulationCalculator">
                                        <img src="assets/images/pixel.png" class="pixel" border="0" />
                                        <div class="svgWrap">
                                            <svg class="mpBg" height="100%" width="100%">
                                            <circle r="" cx="" cy="" stroke-width="40" fill="none" ></circle>
                                            <circle class="mpTextBg" r="" cx="" cy="" stroke-width="40" fill="none" ></circle>
                                            <circle class="mpTextBg" r="" cx="" cy="" stroke-width="40" fill="none" ></circle>
                                        </svg>
                                            <svg class="odBg" height="100%" width="100%">
                                            <circle stroke-width="40" fill="none" ></circle>
                                            <circle class="odTextBg" stroke-width="40" fill="none" ></circle>
                                            <circle class="odTextBg" stroke-width="40" fill="none" ></circle>
                                        </svg>
                                            <svg class="lpBg" height="100%" width="100%">
                                            <circle  stroke-width="40" fill="none" ></circle>
                                            <circle class="lpTextBg"  stroke-width="40" fill="none" ></circle>
                                            <circle class="lpTextBg"  stroke-width="40" fill="none" ></circle>
                                        </svg>
                                        </div>
                                        <div class="centerText">
                                            <div class="EP">
                                                <h2 class="DateMonth"><span class="EP-Date">3</span><sup></sup><span class="EP-Month">May</span></h2>
                                                <h3>Expected Period </h3>
                                            </div>
                                            <div class="EO">
                                                <h2 class="DateMonth"><span class="EO-Date">3</span><sup></sup><span class="EO-Month">May</span></h2>
                                                <h3>Expected Ovulation</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-12">
                                        <div class="legendGroup">
                                            <div class="legend">Menstrual Period</div>
                                            <div class="legend">Ovulation</div>
                                            <div class="legend">Luteal Phase</div>
                                        </div>
                                        <p class="note">Results may vary as all women's cycles are unique.</p>
                                    </div>

                                    <div class="submitbtnrp col-xs-12">
                                        <br/><br/>
                                        <input type="button" name="back" class="casubmit OCback" value="Back">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="OvulationWrapper ocAlarm calctab">
                            <!--<div class="col-xs-12 Selectoption">
                        	<div class="calcttitle">
                                <h2>I Want to</h2>
                            </div>
                        	<div class="calcirwrp">
                                <div class="col-xs-12 col-sm-10 col-md-8 col-sm-push-1 col-md-push-2">
                                    <div class="col-xs-12 col-sm-6">
                                        <div class="icon">
                                            <img src="assets/images/Get-Pregnant.png" />
                                        </div>
                                        <h2>Get Pregnant </h2>
                                    </div>
                                    <div class="col-xs-12 col-sm-6">
                                        <div class="icon">
                                            <img src="assets/images/Track-My-Periods.png" />
                                        </div>
                                        <h2>Track My Periods</h2>
                                    </div>
                                    <div class="clearfix"></div>	
                                    <p class="note">Select your option to explore the sections.</p>
                                </div>
                            </div>
                        </div>-->
                        </div>

                        <div class="OvulationWrapper ocCalc2 calctab">
                            <div class="col-xs-12 Selectoption">
                                <div class="calcttitle">
                                    <h2>CALENDAR</h2>
                                </div>
                                <input type="text" id="FixStartDate" value="12-4-2017" style="display:none">
                                <input type="text" id="fixMPDay" value="5" style="display:none">
                                <div class="calendarBox">
                                    <div class="clOverlay"></div>
                                    <div #displayCalendar class="displayCalendar">

                                    </div>
                                    <div class="calLegendGroup">
                                        <div class="calLegand">
                                            <h4><span class="MPDays">7</span>Menstrual Period </h4>
                                        </div>
                                        <div class="calLegand">
                                            <h4><span class="Fertile">8</span>Fertile </h4>
                                        </div>
                                        <div class="calLegand">
                                            <h4><span class="viewCycleDays">28</span>Whole Cycle </h4>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="capopup" data-popup="popup-10">
        <div class="capopup-inner">
            <h2>Thankyou</h2>
            <h3>{{popupTitle}}</h3>
            <p>
                {{popupMsg}}
            </p>
            <a class="capopup-close" data-popup-close="popup-10" href="#"><img src="assets/images/icon-close2.png" alt="" /></a>
        </div>
    </div>
    <div class="capopup" data-popup="popup-forgotpopup">
        <div class="capopup-inner">
            <h2>{{forgotTitle}}</h2>
            <br>
            <p>
                {{forgotMsg}}
            </p>
            <a class="capopup-close" data-popup-close="popup-forgotpopup" href="#"><img src="assets/images/icon-close2.png" alt="" /></a>
        </div>
    </div>
    <div id="tncmodal" class="tncmodal">
        <div class="tncmodalwrp">
            <h2>Terms &amp; Conditions</h2>
            <p>All the information displayed, transmitted or carried by Rainbow Children’s Medicare Private Limited and its subsidiaries (hereinafter called Rainbow Hospital) including, but not limited to, news articles, opinions, reviews, text, photographs,
                images, illustrations, profiles, audio clips, video clips, trademarks, service marks and others (collectively the "Content,") on this Rainbow Hospital’s website is for informational purposes only.</p>
            <p>The Content is owned by Rainbow Hospital, its affiliates or third party licensors. No one has any right to modify, publish, transmit, transfer, sell, reproduce, create derivative work from, distribute, repost, perform, display or in any way
                commercially exploit any of the Content listed on the website.</p>
            <p>By usage of this Rainbow Hospital’s website the users agree to abide by all copyright notices and restrictions attached to any Content accessed through it and failure to do so will be considered a case of Copyright Infringement, which is termed
                an offence under the Copyright Act of India, 1957, and is punishable with imprisonment which may extend to one year, or with fine, or with both. Any individual who knowingly infringes or abets the infringement is liable for appropriate
                legal action to be taken by Rainbow Hospital.</p>
            <p>The Rainbow Hospital does not represent or endorse the accuracy, completeness or reliability of any advice, opinion, statement or other information displayed, uploaded or distributed through its website. The usage of the content by any person
                implies that it is for his information purpose only and any self-drawn conclusions, experimenting on the basis of content provided there in shall be at user’s own risk and Rainbow Hospital is not liable or responsible for the same.</p>
            <p>The content displayed on the Rainbow Hospital’s website is primarily to enable the users to obtain a better understanding of the medical services provided by the hospital and facilities available, Doctors and Consultants expertise and allied
                services provided by the Rainbow Hospital Group and the information thereby provided is subject to changes and modifications as and when required without any prior notice.</p>
            <p>None of the individual developers, system operators, third-party contributors and management of Rainbow Hospital or anyone else connected to Rainbow Hospital can be held responsible for the results or consequences incurred due to any self-attempt
                to copy, use, imitate or adopt any of the information presented on this Rainbow Hospital’s website without proper medical guidance. Rainbow Hospital reserves the right to modify/change the policy without prior intimation/publication/prior
                information.</p>
            <p><strong>Privacy Policy</strong></p>
            <p>Rainbow Hospital has its own Privacy Policy and it is also required by law to maintain the privacy of the patients medical information, users information and to abide by the terms of the Privacy Policy entered in person or through this Rainbow
                Hospital’s website. This policy shall be applicable to the information provided by users displayed, collected, or payment collected through payment gateway on Rainbow Hospital’s website for any medical Advise Services/Educational/Skill
                Enhancement Programmes,/ Conferences/ interactions/ meetings conducted by the Rainbow Hospital.</p>
            <p>Rainbow Hospital will not intentionally share the information of any user who has accessed the Rainbow Hospital’s website to any third party unless as required under statutory laws. We do not guarantee or assure that the electronic communications
                received from you or contents or records may not be accessible to the third parties.</p>
            <p>Rainbow Hospital reserves the right to modify/change the policy without prior intimation/publication/prior information.
            </p>
            <p><strong>Refund Policy</strong></p>
            <p>Rainbow Hospital will facilitate a hassle-free online secured payment gateway registration facility wherein the users visiting Rainbow Hospital’s website can access the payment gateway /registration and can utilise the said services and make
                payments. Since most of the services offered under the Rainbow Hospital are medical advisory &amp; services in nature, there is no return of the services availed through our Rainbow Hospital’s website, but certain refund and cancellation
                policies apply which may differ or vary from time to time and also service to service, please read all refund and cancellation related policies to the service, or promotion before availing and/or before registration.</p>
            <p>Rainbow Hospital will offer refunds purely on the basis of facts concerning an individual case. There will be no refunds for cancellations post a registration, unless sufficiently provided evidence for – mistaken denominations, factual errors,
                inaccurate payment deductions, duplicate payments or any other instance wherein the payee has faced a loss beyond reasonable control.</p>
            <p>Refunds if any will be made to the same card or account using which the payment was initially made and it will be processed only after verification of the accountholders credentials. No cash or cheque refunds will be made under any circumstances.</p>
            <ul>
                <li>Users cannot return the same order more than once.</li>
                <li>Rainbow Hospital shall not be responsible in case communication is not received by the customer due to incomplete contact details given by the individual.</li>
                <li>Any registration and/or payment made during a promotional campaign will be not considered for cancellation or refund.</li>
                <li>Customers requesting serial or repeated refunds may be blocked to access the services provided on the website.</li>
                <li>For claiming refund, the payee should provide valid invoice of the investigations &amp; ID documents of the customer who is seeking refund so as to be able to get the refund.</li>
                <li>No interest will be paid on refund</li>
            </ul>
            <p>Rainbow Hospitals reserves the right to modify/change the policy without prior intimation/publication/prior information.
            </p>
        </div>
    </div>
    <!-- 	
    <footer-nav-mini [links]="links"></footer-nav-mini> -->
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>