<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>

    <div class="capopup" id="ModelFormGetInTouch">
      <div class="capopup-inner">
        <div class="formLoder" *ngIf="GetInTouchLoader"></div>
          <a class="capopup-close " href="javascript:void()" (click)="hideModelForm();"> <img alt="" src="assets/images/icon-close2.png"> </a>
          <h2>GET IN TOUCH WITH US</h2>
          <form id="form" [formGroup]="GetInTouch" (submit)="onSubmit()" novalidate>
            <ul class="formcasn">
              <div class="ThankYouMsgWrp">Thank You<br> <span>for submitting your enquery. We will get in touch with you soon.</span></div>
                <li>
                    <input type="text" name="name" [ngClass]="{'has-error':!GetInTouch.controls.name.valid && GetInTouch.controls.name.touched}" placeholder="Name*" formControlName="name" required/>
                    <span class="alertMsg" *ngIf="!GetInTouch.controls.name.valid && submited">required</span>
                </li>
                <li>
                    <input type="text" name="mobile" [ngClass]="{'has-error':!GetInTouch.controls.mobile.valid && GetInTouch.controls.mobile.touched}" placeholder="Mobile*" formControlName="mobile" required/>
                    <span class="alertMsg" *ngIf="!GetInTouch.controls.mobile.valid && submited">required</span>
                </li>
                <li>
                    <input type="text" name="email" [ngClass]="{'has-error':!GetInTouch.controls.email.valid && GetInTouch.controls.email.touched}" placeholder="Email ID*" formControlName="email" required/>
                    <span class="alertMsg" *ngIf="!GetInTouch.controls.email.valid && submited">required</span>
                </li>
                <li>
                    <input type="text" name="message" placeholder="Message" formControlName="message" />
                    <input type="hidden" name="programName" id="programName" formControlName="programName" value="Academic Programs" />
                </li>
                <li>
                    <div class="submitbtn">
                        <input type="hidden" name="emailto" formControlName="emailto" />
                        <input type="hidden" name="programe" formControlName="programe" />
                        <input class="casubmit" id="txtSubmit" name="txtSubmit" type="submit" value="Submit">
                    </div>
                </li>
            </ul>
        </form>
      </div>
  </div>
    <section class="page pageheader">
        <div class="container">
            <div class="col-xs-6 col-sm-6 col-lg-6 abannimgwrp">
                <div class="otherbannimg"><img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" /></div>
            </div>
            <div class="col-xs-6 col-sm-6 col-lg-6">
                <div class="ptitle">
                    <h1>{{title}}</h1>
                    <h3>{{subtitle}}</h3>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-lg-6 mcont100">
                <p [innerHTML]="description"></p>
            </div>

        </div>
    </section>

    
    <div class="customloder"></div>
    <section class="aboutsectin2 wcuswrp acadamicWrp">
        <div class="container">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-lg-12">
                    <ul class="aCircleList">
                        <li>
                            <div class="iconwrp">
                                <a href="/other/academic-webinar">
                                    <img src="../../assets/images/academic/icon-webinar.png" alt="" />
                                    <h4>Webinars</h4>
                                </a>
                            </div>
                        </li>
                        
                        <li>
                            <div class="iconwrp">
                                <a href="javascript:void(0);">
                                    <img src="../../assets/images/academic/icon-programs.png" alt="" />
                                    <h4 class="active">Programs</h4>
                                </a>
                            </div>
                        </li>
                        <li>
                          <div class="iconwrp">
                              <a href="/other/academic-article">
                                  <img src="../../assets/images/academic/icon-article.png" alt="" />
                                  <h4>Publications</h4>
                              </a>
                          </div>
                      </li>
                    </ul>
                </div>
                <div class="col-lg-12 pgLine">
                    <p>Learn from the field experts and elevate your capabilities to drive innovation and transform healthcare</p>
                </div>
            </div>
            
            <div class="row">
                <div class="col-sm-12 col-lg-12 headingWrp">
                    <h3>Featured Programs</h3>
                </div>
                <div class="col-sm-12 col-lg-12 seprateSelectWrp">
                    <!-- Static Select Filter -->
                    <ul class="selectwrp">
                        <li>
                            <div class="specialitywrp">
                                <select class="select filterby Specialty" id="Specialty" (change)="filterData($event, 'Specialty')">
                                    <option value="">Select Specialty</option>
                                    <option value="1">Child Care</option>
                                    <option value="2">Women Health</option>
                                    <option value="3">Fertility Care</option>
                                </select>
                                <span class="select-span" id="SelectYear">Select Specialty</span>
                            </div>
                        </li>
                        <li>
                          <div class="unitwrp">
                              <select class="select filterby location" id="aLocation" (change)="filterSubSpeciality($event)">
                                  <option value="" selected>Select Unit </option>                                   
                                    <optgroup label="{{cityName.city}}" *ngFor="let cityName of centerList">
                                      <option value="{{center.id}}" *ngFor="let center of cityName.area">{{center.center_name_alias}}</option>
                                    </optgroup>

                              </select>
                              <span class="select-span" id="SelectYear">Select City</span>
                          </div>
                      </li>
                        <li>
                            <div class="specialitywrp subspecialitywrp">
                                <select class="select filterby SubSpeciality" id="aSubSpecialty" (change)="filterData($event, 'SubSpeciality')">
                                    <option value="" selected>Select Sub Specialty</option>
									                  
                                    <option value="{{option.id}}" *ngFor="let option of SubSpecialtyData">{{option.title}}</option>
                                </select>
                                <span class="select-span" id="SelectYear">Select Sub Specialty</span>
                            </div>
                        </li>
                        
                        <li class="btnReset" style="clear: both;"><a href="javascript:void(0)" (click)="filterData($event, 'all')">Reset Filter</a></li>
                    </ul>
                   
                   
                </div>
                <div class="col-sm-12 col-lg-12 PastWebinarsWrp">
                  <div class="row rowMar">
                    <div class="col-xs-12" *ngIf="programListResult">
                      <h3>Data Not Found !</h3>
                    </div>
                    <div class="col-xs-12" *ngFor="let item of ProgramListData">
                      <div class="FeaturedPrograms">
                        <div class="visitor-head">
                          <h2 class="fontsize">{{item.contentheading}}</h2>
                        </div>
                
                        <div class="leftWapper">
                          <div class="points">
                            <ul>
                              <li>Location: <span *ngFor="let city of item.citylist">{{city.city}}</span></li>
                              <li>Available seats: {{item.contentavailableseat}}</li>
                              <li>Duration: {{item.contentduration}}</li>
                            </ul>
                            <ul>
                              <li>Speciality : <span *ngFor="let specs of item.specialitylist">{{specs.speciality}}</span></li>
                              <li>Eligibility : {{item.contenteligibility}}</li>
                              <li>Session: {{item.contentsession}} </li>
                            </ul>
                          </div>

                          <div [innerHTML]="item.contentlargedecription"></div>
                
                          
                          <div class="BtnApplynowWrp">
                            <a href="#" class="BtnApplynow" data-id="{{item.contentcontactemail}}" data-name="{{item.contentheading}}"><img src="../../assets/images/academic/btn-border-img.png" class="borderline" alt="" /></a>
                          </div>
                        </div>
                        <div class="OurContent">
                          <ul>
                            <li class="arrow">
                              <p class="t1">Or Contact</p>
                            </li>
                            <li> 
                              <div class="icon"><img src="../../assets/images/academic/icon-doc.png" alt="" /></div>
                              <p class="t2">{{item.contentcontactname}}</p>
                              
                            </li>
                            <li> 
                                <div class="icon"><img src="../../assets/images/academic/icon-call-n.png" alt="" /></div>
                                <p class="t2"><a href="tel:{{item.contentcontactphone}}">{{item.contentcontactphone}}</a></p>
                            </li>
                            <li>
                              <div class="icon"><img src="../../assets/images/academic/icon-mess.png" alt="" /></div>
                              <p class="t2"><a href="mailto:{{item.contentcontactemail}}"
                                  target="_blank">{{item.contentcontactemail}}</a></p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  
                    <!--  End Custom Programs Data -->
                </div>
            </div>
        </div>
    </section>



    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>

