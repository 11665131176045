import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TransferHttp } from '../../modules/transfer-http/transfer-http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  API_BASE_URL,
  IMG_BASE_URL,
  API_BASE_URL_NON_SSL,
  BASE_URL,
  CAN_API,
} from './constants';
import { isPlatformBrowser } from '@angular/common';
import { Observable } from 'rxjs';
import { Router, NavigationEnd, RoutesRecognized } from '@angular/router';
// var nodemailer = require('nodemailer');
import * as nodemailer from 'nodemailer';

var headers = new HttpHeaders();
headers.append('Content-Type', 'application/x-www-form-urlencoded');

@Injectable()
export class ApiService {
  public previousUrl: string;
  public currentUrl: string;

  public isBrowser: boolean = isPlatformBrowser(this.platform_id);

  constructor(
    @Inject(PLATFORM_ID) private platform_id: any,
    private tHttp: TransferHttp,
    private http: HttpClient,
    private router: Router
  ) {}

  /* HTTP related Methods */
  getHeaders() {
    let headers;
    // if(this.isLoggedIn() && this.access_token != ''){
    //   headers = new Headers({'Content-Type': 'application/json', 'Authorization': "Bearer "+this.access_token, 'Accept': '*/*'});
    // }else{
    headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: '*/*',
    });
    // }
    return headers;
  }

  get(url: string, options?: any) {
    if (!options) {
      options = {
        headers: this.getHeaders(),
      };
    }

    return this.tHttp
      .get(url, options)
      .map((res) => {
        return this.extractData(res);
      })
      .catch((e) => this.handleError(e));
  }

  put(url: string, body?: any, options?: any) {
    if (!options) {
      options = {
        headers: this.getHeaders(),
      };
    }
    if (!body) {
      body = {};
    }
    return this.http
      .put(url, body, options)
      .map((res) => this.extractData(res))
      .catch((e) => this.handleError(e));
  }

  post(url: string, body?: any, options?: any) {
    if (!options) {
      options = {
        headers: this.getHeaders(),
      };
    }
    if (!body) {
      body = {};
    }
    return this.http
      .post<any>(url, body, options)
      .map((res) => this.extractData(res))
      .catch((e) => this.handleError(e));
  }

  delete(url: string, body?: any, options?: any) {
    if (!body) {
      body = {};
    }
    if (!options) {
      options = {
        headers: this.getHeaders(),
        body: body,
      };
    }

    return this.http
      .delete(url, options)
      .map((res) => this.extractData(res))
      .catch((e) => this.handleError(e));
  }

  private extractData(res: any) {
    // console.log(res);
    // debugger;
    return res;
  }

  private handleError(err: any) {
    return Observable.throw(err);
  }

  getEvents() {
    return this.get(`${API_BASE_URL}home/events`);
  }
  getPageEvents(page: string) {
    return this.get(`${API_BASE_URL}home/get_page_events/${page}`);
  }
  getMediaRelease() {
    return this.get(`${API_BASE_URL}home/media_press_release`);
  }
  getMediaReleaseById(id: any) {
    return this.get(`${API_BASE_URL}home/media_press_release/${id}`);
  }

  getPressClips() {
    return this.get(`${API_BASE_URL}home/press_clips`);
  }
  getMediaPressClipsById(id: any) {
    return this.get(`${API_BASE_URL}home/press_clips/${id}`);
  }

  getNewsEvents(page: any) {
    return this.get(`${API_BASE_URL}home/events/${page}`);
  }
  getNewsEventsById(id: any) {
    return this.get(`${API_BASE_URL}home/events_by_id/${id}`);
  }

  getEventsById(id: any) {
    return this.get(`${API_BASE_URL}home/events/${id}`);
  }

  getWhoWeAre() {
    return this.get(`${API_BASE_URL}home/page_description/who-we-are`);
  }

  getspeciality_features() {
    return this.get(`${API_BASE_URL}home/our_speciality_features`);
  }

  getHealthCareServices(page: any) {
    return this.get(`${API_BASE_URL}home/healthcare_service/${page}`);
  }

  getBriefFacilities(page: any) {
    return this.get(`${API_BASE_URL}home/brief_facilities/${page}`);
  }
  getTermsNConditions() {
    return this.get(`${API_BASE_URL}home/mission_vision`);
  }

  getPageDescription(page: any, force?: boolean) {
    return this.get(
      `${force ? API_BASE_URL : API_BASE_URL}home/page_description/${page}`
    );
  }

  getSectionDoctor(section: any) {
    return this.get(`${API_BASE_URL}`);
  }
  getSpecialityFeatures(page: any) {
    return this.get(`${API_BASE_URL}home/our_speciality_features/${page}`);
  }

  getDoctors(page: any) {
    return this.get(`${API_BASE_URL}home/doctors/${page}`);
  }

  getDoctorById(id: any) {
    return this.get(`${API_BASE_URL}home/doctors/${id}`);
  }
  doctorsbyslug(slug: any, type: string) {
    let appointmentLocation =
      type == 'visiting-consultant'
        ? 'consultant_doctors_center_list'
        : 'doctorsbyslug';
    return this.get(`${API_BASE_URL}home/${appointmentLocation}/${slug}`);
  }
  doctorsbyslugMeta(slug: any) {
    //return this.get(`${API_BASE_URL_NON_SSL}home/doctorsbyslug/${slug}`);
    return this.get(`${API_BASE_URL}home/doctorsbyslug/${slug}`);
  }
  doctordeskbyslug(slug: any) {
    return this.get(`${API_BASE_URL}home/doctordeskbyslug/${slug}`);
  }
  getDoctorDeskByDoctorId(id: any) {
    return this.get(`${API_BASE_URL}home/doctors_desk/${id}`);
  }

  getDoctorDeskByDoctorSlug(slug: any) {
    return this.get(`${API_BASE_URL}home/doctors_desk/${slug}`);
  }
  getDoctorSpecialityMasterByName(page: any) {
    return this.get(`${API_BASE_URL}home/doctors_speciality_master/${page}`);
  }

  getDoctorSpecialityMasterByID(id: any) {
    return this.get(`${API_BASE_URL}home/doctors_speciality_master_id/${id}`);
  }

  getDoctorsCenterByID(id: any) {
    return this.get(`${API_BASE_URL}home/doctors_centers/${id}`);
  }

  getDoctorSpecialityFeaturesByName(slug: any) {
    return this.get(`${API_BASE_URL}home/doctors_specility_features/${slug}`);
  }
  getDoctorSpecialityFeaturesByNameAndCity(slug: string, city: string) {
    return this.get(
      `${API_BASE_URL}home/doctors_specility_features/${slug}/${city}`
    );
  }

  getBoardOfDirectors(page: any) {
    return this.get(`${API_BASE_URL}home/md_message/${page}`);
  }

  getHistoryDetails(page: any) {
    return this.get(`${API_BASE_URL}home/history_details/${page}`);
  }

  getFaqSection(page: any) {
    return this.get(`${API_BASE_URL}home/faq_section/${page}`);
  }

  getdescription_section(page: any) {
    return this.get(`${API_BASE_URL}home/description_section/${page}`);
  }

  getknowledgeSection(id: any) {
    return this.get(`${API_BASE_URL}home/knowledge_center/${id}`);
  }

  getvaccination_details() {
    return this.get(`${API_BASE_URL}home/vaccination_details`);
  }

  getvaccination_contacts() {
    return this.get(`${API_BASE_URL}home/vaccination_contacts`);
  }

  getCareers() {
    return this.get(`${API_BASE_URL}home/careers`);
  }
  searchCareerDetails(location: any, speciality: any) {
    return this.get(
      `${API_BASE_URL}home/search_career_details/${location}/${speciality}`
    );
  }
  searchCareerDetailsByCity(location: any) {
    return this.get(
      `${API_BASE_URL}home/search_career_details_by_city/${location}`
    );
  }
  getCareerDetail(id: any) {
    return this.get(`${API_BASE_URL}home/get_career_details/${id}`);
  }

  getSpecialityQuestions(page: any) {
    return this.get(`${API_BASE_URL}home/get_speciality_questions/${page}`);
  }

  getCsr() {
    return this.get(`${API_BASE_URL}home/csr`);
  }
  getCsrCode() {
    return this.get(`${API_BASE_URL}home/csr/code`);
  }
  getCsrById(slug: any) {
    return this.get(`${API_BASE_URL}home/csr/${slug}`);
  }

  getBWMCenters() {
    return this.get(`${API_BASE_URL}home/get/bmw_centers`);
  }

  getBWMYears() {
    return this.get(`${API_BASE_URL}home/get/bmw_year`);
  }
  getBWMAllData() {
    return this.get(`${API_BASE_URL}home/get/bmwdata`);
  }

  // getcenters(){
  //   return this.get(`${API_BASE_URL}home/child-care-centers`);
  // }

  /**Post Request*/
  postFeedback(feedbackDetails) {
    var feedbackParams = new HttpParams();
    for (let param in feedbackDetails) {
      if (typeof feedbackDetails[param] == 'object') {
        for (let childparam in feedbackDetails[param]) {
          let childparamstatus =
            feedbackDetails[param][childparam] == false ? '0' : '1';
          feedbackParams.set(childparam, childparamstatus);
        }
      } else {
        feedbackParams.set(param, feedbackDetails[param]);
      }
    }
    return this.http
      .post<any>(`${API_BASE_URL}home/feedback/submit`, feedbackParams, {
        headers: headers,
      })
      .map((res) => res);
  }

  postAppointment(appointmentDetails) {
    var appointmentParams = new HttpParams();

    for (let param in appointmentDetails) {
      appointmentParams.set(param, appointmentDetails[param]);
    }
    console.log(appointmentParams);
    return this.http
      .post<any>(
        `${API_BASE_URL}home/international_patients/submit`,
        appointmentParams,
        {
          headers: headers,
        }
      )
      .map((res) => res);
  }

  /**
   *Homepage API calls
   **/
  getHomeHighlights(page: any) {
    return this.get(`${API_BASE_URL}home/get_home_highlights/${page}`);
  }

  getHomePageVideos() {
    return this.get(`${API_BASE_URL}home/get_home_videos/home`);
  }

  getHomeLeftHighlights() {
    return this.get(`${API_BASE_URL}home/get_home_lefts_highlights`);
  }

  getHomeRightHighlights() {
    return this.get(`${API_BASE_URL}home/get_home_rights_highlights`);
  }

  getHomeSpecialityFeatures(id: any) {
    return this.get(`${API_BASE_URL}home/get_home_speciality_features/${id}`);
  }

  getHomeNews() {
    return this.get(`${API_BASE_URL}home/get_home_news/news`);
  }

  getHomeNewsEvents() {
    return this.get(`${API_BASE_URL}home/get_home_newsevents`);
  }

  getHomeAcademics() {
    return this.get(`${API_BASE_URL}home/academic_programs/academic-programs`);
  }

  //Rk

  getAcademicWebinar() {
    return this.get(
      `${API_BASE_URL}home/ContentHubListing?category=1&contentid=&locationid=&centerid=&specialityid=&subspecialityid=&doctorid=&tagid=`
    );
  }
  getAcademicWebinarFilter(sid: any, l_id: any, Doctorid: any) {
    return this.get(
      `${API_BASE_URL}home/ContentHubListing?category=1&contentid=&locationid=&centerid=${l_id}&specialityid=${sid}&subspecialityid=&doctorid=${Doctorid}&tagid=`
    );
  }
  getContentHubListingAcademicArticles(aid: any) {
    return this.get(
      `${API_BASE_URL}home/ContentHubListing?category=&contentid=&locationid=&centerid=&specialityid=&subspecialityid=&doctorid=&tagid=${aid}`
    );
  }

  getAcademicProgramsList(specialityid, subspecialityid, centerid) {
    return this.get(
      `${API_BASE_URL}home/ContentHubListing?category=&contentid=&locationid=&centerid=${centerid}&specialityid=${specialityid}&subspecialityid=${subspecialityid}&doctorid=&tagid=2&primaryfilter=&secondaryfilter=`
    );
  }

  getVideoHubFilter(sid: any) {
    return this.get(
      `${API_BASE_URL}home/ContentHubList?category=4&contentid=&locationid=&centerid=&specialityid=${sid}&subspecialityid=&doctorid=&tagid=`
    );
  }

  getVideoHub(Category, SubCategory) {
    return this.get(
      `${API_BASE_URL}home/ContentHubList?category=&contentid=&locationid=&centerid=&specialityid=&subspecialityid=&doctorid=&tagid=4&primaryfilter=${Category}&secondaryfilter=${SubCategory}`
    );
    //return this.get(`${API_BASE_URL}home/ContentHubList?category=&contentid=&locationid=&centerid=&specialityid=&subspecialityid=&doctorid=&tagid=4,6`);
  }

  getVideoHubBanner() {
    return this.get(
      `${API_BASE_URL}home/ContentHubListing?category=&contentid=&locationid=&centerid=&specialityid=&subspecialityid=&doctorid=&tagid=4`
    );
  }
  getVideoHubWebinar() {
    return this.get(
      `${API_BASE_URL}home/ContentHubListing?category=&contentid=&locationid=&centerid=&specialityid=&subspecialityid=&doctorid=&tagid=1`
    );
  }
  getVideoHubWebinarFilter(s_id: any, l_id: any, ss_id: any) {
    return this.get(
      `${API_BASE_URL}home/ContentHubListing?category=&contentid=&locationid=${l_id}&centerid=&specialityid=${s_id}&subspecialityid=${ss_id}&doctorid=&tagid=1`
    );
  }
  getVideoHubTestimonial() {
    return this.get(
      `${API_BASE_URL}home/ContentHubListing?category=&contentid=&locationid=&centerid=&specialityid=&subspecialityid=&doctorid=&tagid=6`
    );
  }
  getVideoHubTestimonialFilter(s_id: any, l_id: any, ss_id: any) {
    return this.get(
      `${API_BASE_URL}home/ContentHubListing?category=&contentid=&locationid=${l_id}&centerid=&specialityid=${s_id}&subspecialityid=${ss_id}&doctorid=&tagid=6`
    );
  }
  getVideoHubNewMedia() {
    return this.get(
      `${API_BASE_URL}home/ContentHubListing?category=&contentid=&locationid=&centerid=&specialityid=&subspecialityid=&doctorid=&tagid=7`
    );
  }
  getVideoHubNewMediaFilter(s_id: any, l_id: any, ss_id: any) {
    return this.get(
      `${API_BASE_URL}home/ContentHubListing?category=&contentid=&locationid=${l_id}&centerid=&specialityid=${s_id}&subspecialityid=${ss_id}&doctorid=&tagid=7`
    );
  }
  getVideoHubEvents() {
    return this.get(
      `${API_BASE_URL}home/ContentHubListing?category=&contentid=&locationid=&centerid=&specialityid=&subspecialityid=&doctorid=&tagid=5`
    );
  }
  getVideoHubEventsFilter(s_id: any, l_id: any, ss_id: any) {
    return this.get(
      `${API_BASE_URL}home/ContentHubListing?category=&contentid=&locationid=${l_id}&centerid=&specialityid=${s_id}&subspecialityid=${ss_id}&doctorid=&tagid=5`
    );
  }
  getfeaturedArticles(centerId) {
    return this.get(
      `${API_BASE_URL}home/ContentHubListing?category=&contentid=&locationid=&centerid=${centerId}&specialityid=&subspecialityid=&doctorid=&tagid=3`
    );
  }

  getAcademicArticles() {
    return this.get(`${API_BASE_URL}home/academic/articles`);
  }
  getAcademicArticleDetail(id: any) {
    return this.get(`${API_BASE_URL}home/academic/articles/${id}`);
  }
  getsubSpeciality() {
    return this.get(`${API_BASE_URL}home/get/subSpeciality`);
  }
  getAcademicPrograms() {
    return this.get(`${API_BASE_URL}home/academic/programs`);
  }

  //end rk

  getHomeAwards() {
    return this.get(`${API_BASE_URL}home/awards_history/hospital-awards`);
  }

  getHomeModelPopup() {
    return this.get(`${API_BASE_URL}home/get_home_page_model_popup`);
  }

  getAllPageModelPopup() {
    return this.get(`${API_BASE_URL}home/get_all_page_model_popup`);
  }
  /**
     *
     Ranjit
     */
  getAcademicContent() {
    return this.http.get(
      `${API_BASE_URL}home/academic_programs/academic-programs`
    );
  }
  getSpecialityMasters() {
    return this.http.get<any>(`${API_BASE_URL}home/speciality_masters`);
  }
  getCities() {
    return this.http.get<any>(`${API_BASE_URL}home/cities`);
  }
  getCenters() {
    return this.get(`${API_BASE_URL}home/centersByCity`);
  }
  getCentersDetail(id) {
    return this.get(`${API_BASE_URL}home/centers_detail/${id}`);
  }
  getCenterDetailsBySlug(slug: string) {
    //return this.get(`${API_BASE_URL_NON_SSL}home/centers_detail_by_slug/${slug}`);
    return this.get(`${API_BASE_URL}home/centers_detail_by_slug/${slug}`);
  }
  getCenterCity() {
    return this.get(`${API_BASE_URL}home/get_city_centres`);
  }
  getCenterCityDetails(id: number) {
    console.log('ID', id);
    return this.get(`${API_BASE_URL}home/get_city_centres/${id}`);
  }
  getCenterCityDetailsBySlug(slug: number) {
    //console.log('ID', id);
    return this.get(`${API_BASE_URL}home/get_city_centres_slug/${slug}`);
  }
  getDoctorListBySpecility(id) {
    return this.get(`${API_BASE_URL}home/specility_features_doctors/${id}`);
  }

  /*ranjit 18/6/2017*/
  getDoctorListBySpecilityMaster(
    speciality_master,
    speciality_feature,
    centre_id,
    page
  ) {
    // speciality_master;
    // speciality_feature = '';
    // centre_id = '';
    var params = '';

    if (speciality_master != 0 || speciality_master != undefined) {
      params += `speciality_master=${speciality_master}`;
    }
    if (speciality_feature != 0) {
      params += `&speciality_feature=${speciality_feature}`;
    }
    if (centre_id != 0) {
      params += `&centre=${centre_id}`;
    }
    if (page != 0) {
      params += `&page=${page}`;
    }
    //console.log(params);
    return this.get(`${API_BASE_URL}home/getDoctors?${params}`);
  }
  getConsultDoctorListBySpecilityMaster(
    speciality_master,
    speciality_feature,
    centre_id,
    page
  ) {
    // speciality_master;
    // speciality_feature = '';
    // centre_id = '';
    var params = '';

    if (speciality_master != 0 || speciality_master != undefined) {
      params += `speciality_master=${speciality_master}`;
    }
    if (speciality_feature != 0) {
      params += `&speciality_feature=${speciality_feature}`;
    }
    if (centre_id != 0) {
      params += `&centre=${centre_id}`;
    }
    if (page != 0) {
      params += `&page=${page}`;
    }
    //console.log(params);
    return this.get(`${API_BASE_URL}home/get_consultant_doctors?${params}`);
  }
  getSearchedDoctorDetail(id: number) {
    var params = '';
    if (id) {
      params += `d_id=${id}`;
    }
    return this.get(`${API_BASE_URL}home/getDoctors?${params}`);
  }
  getDoctorSpecitlytID(did: number) {
    return this.get(`${API_BASE_URL}home/getspecialitybyDoctor/${did}`);
  }
  getOurDoctorList(id: number) {
    return this.get(`${API_BASE_URL}home/centersBySpeciality/${id}`);
  }

  /*ranjit 19/6/2017*/
  getSpecDoctorList(centerid, specid) {
    return this.get(
      `${API_BASE_URL}home/doctorsBySpecialityCentre/${centerid}/${specid}`
    );
  }

  getHomeAboutDetails(page: any) {
    return this.get(`${API_BASE_URL}home/get_home_about/${page}`);
  }

  getHomeDefaultSpecialityFeatures() {
    return this.get(`${API_BASE_URL}home/get_default_speciality_features`);
  }

  getHomeDefaultHighlights() {
    return this.get(`${API_BASE_URL}home/get_default_home_highlights`);
  }

  getHomeDefaultAboutUs() {
    return this.get(`${API_BASE_URL}home/get_default_home_about`);
  }

  getHomeCareers() {
    return this.get(`${API_BASE_URL}home/get_home_careers`);
  }

  postQuiz(quizDetails) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let body = JSON.stringify(quizDetails);
    return this.http
      .post<any>(`${API_BASE_URL}home/quiz/submit`, body, options)
      .map((res) => res);
  }
  getQuiz(id: number) {
    return this.get(`${API_BASE_URL}home/quiz_section/${id}`);
  }
  postInpatientFeedback(inpatitentFeedback) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let body = JSON.stringify(inpatitentFeedback);
    return this.http
      .post<any>(`${API_BASE_URL}home/inpatientfeedback/submit`, body, options)
      .map((res) => res);
  }
  getDoctorsList(page: any) {
    return this.get(`${API_BASE_URL}home/doctors`);
  }
  getFilteredCenterData(data: any) {
    const cityName = data.center_name.split(',');
    let city = data.city ? data.city.city : cityName[1];
    let filterdata = {
      id: data.id,
      center_name: data.center_name,
      center_name_alias: data.center_name_alias,
      address: data.address,
      email: data.centre_email,
      telephone: data.telephone,
      lat_long: data.lat_long,
      image: data.image,
      city: city,
      virtual_tour_link: data.virtual_tour_link,
    };
    return filterdata;
    //console.log(filterdata);
  }
  postForgotpassword(email) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let body = JSON.stringify(email);
    return this.http
      .post<any>(`${API_BASE_URL}home/forgot_password`, body, options)
      .map((res) => res);
  }
  postSubmitSubscription(email) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let body = JSON.stringify(email);
    return this.http
      .post<any>(`${API_BASE_URL}home/usersubscription`, body, options)
      .map((res) => res);
  }
  postPoupForm(value) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let body = JSON.stringify(value);
    return this.http
      .post<any>(`${API_BASE_URL}home/center_update_submit`, body, options)
      .map((res) => res);
  }
  getCentreSpecification() {
    return this.get(`${API_BASE_URL}home/centers`);
  }
  getCentreInsurance(id: number) {
    return this.get(`${API_BASE_URL}home/insurance/${id}`);
  }

  getCentresbyspecialityfeatures(slug: string) {
    return this.get(`${API_BASE_URL}home/centresbyspecialityfeatures/${slug}`);
  }
  getHomeEvents() {
    return this.get(`${API_BASE_URL}home/get_home_events/events`);
  }
  fbgmlogin(data: any) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let body = JSON.stringify(data);
    return this.http
      .post<any>(`${API_BASE_URL}home/fbgmlogin`, body, options)
      .map((res) => res);
  }
  fbregistration(data: any) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let body = JSON.stringify(data);
    return this.http
      .post<any>(`${API_BASE_URL}home/fbregistration`, body, options)
      .map((res) => res);
  }
  postLogin(data: any) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let body = JSON.stringify(data);
    return this.http
      .post<any>(`${API_BASE_URL}home/login`, body, options)
      .map((res) => res);
  }
  postRegister(data: any) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let body = JSON.stringify(data);
    return this.http
      .post<any>(`${API_BASE_URL}home/registration`, body, options)
      .map((res) => res);
  }
  postBMIDetail(data: any) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let body = JSON.stringify(data);
    return this.http
      .post<any>(`${API_BASE_URL}home/bmi_calculator`, body, options)
      .map((res) => res);
  }
  postContractionDetail(data: any) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let body = JSON.stringify(data);
    return this.http
      .post<any>(`${API_BASE_URL}home/contraction_calculator`, body, options)
      .map((res) => res);
  }
  getContractionUserDetail(email: string, date: any) {
    let param = date ? `${email}/${date}` : `${email}`;
    if (email != '') {
      return this.http
        .get(`${API_BASE_URL}home/contraction_calculator_list/${param}`)
        .map((res) => res);
    }
  }
  postKickedTime(data: any) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let body = JSON.stringify(data);
    return this.http
      .post<any>(`${API_BASE_URL}home/kick_counter_calculator`, body, options)
      .map((res) => res);
  }
  getKickCounterList(email: string, date: any) {
    let param = date ? `${email}/${date}` : `${email}`;
    if (email != '') {
      return this.http
        .get(`${API_BASE_URL}home/kick_counter_calculator/${param}`)
        .map((res) => res);
    }
  }
  getOTP(mobile: any) {
    return this.http
      .get<any>(`${API_BASE_URL}home/user_send_otp?number=${mobile}`)
      .map((res) => res);
  }
  click2callAPI(data: any) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let body = JSON.stringify(data);
    return this.http
      .post<any>(`${API_BASE_URL}home/click_to_call_api`, body, options)
      .map((res) => res);
  }
  getVideos(page) {
    return this.http.get<any>(`${API_BASE_URL}home/video_section/${page}`);
  }
  getCentreCities() {
    return this.http
      .get<any>(`${API_BASE_URL}home/get_cities_centres`)
      .map((res) => res);
  }
  getSearchList() {
    return this.http.get<any>(`${IMG_BASE_URL}search.php`);
  }
  getSideBarlist() {
    return this.http.get<any>(`${IMG_BASE_URL}sidebar.php`);
  }
  getHeaderVirtualTour() {
    return this.http.get(`${IMG_BASE_URL}virtualtour.php`);
  }
  getHomeCircleList() {
    return this.http.get(`${IMG_BASE_URL}homecircle.php`);
    //return this.http.get(`${API_BASE_URL}home/home_page_circle`);
  }
  getSucessStories() {
    //return this.http.get(`${IMG_BASE_URL}success_stories.php`);
    return this.http.get(`${API_BASE_URL}home/success_stories`);
  }
  getHomePageDetail() {
    return this.http.get<Array<any>>(`${IMG_BASE_URL}homepage.php`);
  }
  getTestimonialsDetail() {
    return this.http.get<Array<any>>(`${API_BASE_URL}home/testimonials`);
  }
  getFooterDetail() {
    return this.http.get<Array<any>>(`${IMG_BASE_URL}footerdetail.php`);
  }
  getTnCDetails() {
    return this.http.get(`${IMG_BASE_URL}tnc.php`);
  }
  getOtherSubFooterDetails() {
    return this.http.get(`${IMG_BASE_URL}other_sub_footer.php`);
  }
  getAboutSubFooterDetails() {
    return this.http.get<Array<any>>(`${IMG_BASE_URL}about_sub_footer.php`);
  }
  getContactUsDetails() {
    return this.http.get<any>(`${IMG_BASE_URL}contactus.php`);
  }
  postConfirmationOTP(data: any) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let body = JSON.stringify(data);
    return this.http
      .post<any>(
        `${API_BASE_URL}home/send_book_an_appointment_otp`,
        body,
        options
      )
      .map((res) => res);
  }
  postConfirmationOTPVerify(data: any) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let body = JSON.stringify(data);
    return this.http
      .post<any>(
        `${API_BASE_URL}home/verify_book_an_appointment_otp`,
        body,
        options
      )
      .map((res) => res);
  }
  getRatingSlider() {
    return this.get(`${API_BASE_URL}home/reviews_list`);
  }
  /**
   * GET Doctor On Key Up search List
   * @param searchkey:string
   * @return Doctor List Array
   */
  getDoctorSearchList(searchkey) {
    return this.get(`${API_BASE_URL}home/doctors_list_by_name/${searchkey}`);
  }
  /**
   * GET Consultant Doctor On Key Up search List
   * @param searchkey:string
   * @return Doctor List Array
   */
  getConsultDoctorSearchList(searchkey) {
    return this.get(
      `${API_BASE_URL}home/consult_doctors_list_by_name/${searchkey}`
    );
  }
  getKnowledgetCentreDetail(blogslug: string) {
    return this.get(`${API_BASE_URL}home/knowledge_center/detail/${blogslug}`);
  }
  /**
   *  Ranjit
   */
  /**
   * umesh
   */
  getdoctorqualityaccreditation() {
    return this.get(`${API_BASE_URL}home/doctor_quality_accreditation`);
  }

  getOurMission(page: any) {
    return this.get(`${API_BASE_URL}home/our_mission/${page}`);
  }

  getAllAwards(awards_type: any) {
    return this.get(`${API_BASE_URL}home/awards_history/${awards_type}`);
  }
  getRedirectURl(oldurl: string) {
    return this.get(`${API_BASE_URL}home/get_redirect_url?url=${oldurl}`);
  }

  /**
   * Umesh
   */
  getpackageData() {
    return this.get(`${API_BASE_URL}home/package_sections/packages`);
  }

  /** Ranjay: Appointment Api **/
  postAppointmentDetails(appointmentDetails) {
    var appointmentParams = new HttpParams();

    for (let param in appointmentDetails) {
      appointmentParams.set(param, appointmentDetails[param]);
    }
    return this.http
      .post<any>(`${API_BASE_URL}home/appointment/submit`, appointmentParams, {
        headers: headers,
      })
      .map((res) => res);
  }

  AppointmentPostClientData(appointmentDetails) {
    var appointmentParams = new HttpParams();
    for (let param in appointmentDetails) {
      appointmentParams.set(param, appointmentDetails[param]);
    }
    return this.http
      .post<any>(
        `https://lmsuat.rainbowhospitals.in:8080/hdp/payrequest/insert_clientside.php`,
        appointmentParams,
        {
          headers: headers,
        }
      )
      .map((res) => res);
  }
  get_Appointment_id(InsertID: any) {
    return this.get(`${API_BASE_URL}home/appointment/get_id/${InsertID}`);
  }
  getemailNsmsSend(dID: any) {
    return this.get(`${API_BASE_URL}home/get/mailsms/${dID}`);
  }

  getPatient_Uid(dID: any) {
    return this.get(`${API_BASE_URL}home/appointment/get_patient_uid/${dID}`);
  }

  postPayNowStatus(btnClickDetails) {
    var clickParams = new HttpParams();

    for (let param in btnClickDetails) {
      clickParams.set(param, btnClickDetails[param]);
    }
    return this.http
      .post<any>(`${API_BASE_URL}home/appointment/PayNow`, clickParams, {
        headers: headers,
      })
      .map((res) => res);
  }

  postBtnClickDetails(btnClickDetails) {
    var clickParams = new HttpParams();

    for (let param in btnClickDetails) {
      clickParams.set(param, btnClickDetails[param]);
    }
    return this.http
      .post<any>(`${API_BASE_URL}home/appointment/btn_click`, clickParams, {
        headers: headers,
      })
      .map((res) => res);
  }

  getAppointmentDetails(dID: any) {
    return this.get(
      `${API_BASE_URL}home/appointment/get_appointment_details/${dID}`
    );
  }

  postPaymentsForm(paymentsdata) {
    var paymentParams = new HttpParams();

    for (let param in paymentsdata) {
      paymentParams.set(param, paymentsdata[param]);
    }
    console.log('paymentParams', paymentsdata);

    return this.http
      .post<any>(
        `https://staging.in.salucro.net/patient/app/payments`,
        paymentParams,
        {
          headers: headers,
        }
      )
      .map((res) => res);
  }

  getPaymentResponse() {
    return this.get(`${BASE_URL}appointment-confirmed`);
  }

  searchAppointmentDetails(searchAD) {
    var clickParams = new HttpParams();

    for (let param in searchAD) {
      clickParams.set(param, searchAD[param]);
    }
    return this.http
      .post<any>(`${API_BASE_URL}home/appointment/search`, clickParams, {
        headers: headers,
      })
      .map((res) => res);
  }

  cancelAppointment(updateAD) {
    var clickParams = new HttpParams();

    for (let param in updateAD) {
      clickParams.set(param, updateAD[param]);
    }
    return this.http
      .post<any>(`${API_BASE_URL}home/appointment/cancel`, clickParams, {
        headers: headers,
      })
      .map((res) => res);
  }

  postCancelConfirmationOTP(data: any) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let body = JSON.stringify(data);
    return this.http
      .post<any>(`${API_BASE_URL}home/appointment/send_otp`, body, options)
      .map((res) => res);
  }
  postCancelConfirmationOTPVerify(data: any) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let body = JSON.stringify(data);
    return this.http
      .post<any>(`${API_BASE_URL}home/appointment/verify_otp`, body, options)
      .map((res) => res);
  }

  postAppointmentCancellation(bankDetails) {
    var appointmentParams = new HttpParams();

    for (let param in bankDetails) {
      appointmentParams.set(param, bankDetails[param]);
    }
    return this.http
      .post<any>(
        `${API_BASE_URL}home/account_cancellation`,
        appointmentParams,
        {
          headers: headers,
        }
      )
      .map((res) => res);
  }

  postCancellationAPI(nBookingUID: any, sReason: any) {
    return this.get(
      `${CAN_API}CancelPortalAppointment?nBookingUID=${nBookingUID}&sReason=${sReason}`
    );
    /*
		var CancellationAPIParams = new HttpParams();
		for (let param in nBooking) {
			CancellationAPIParams.set(param, nBooking[param]);
		}
		return this.http.post<any>(`${CAN_API}CancelPortalAppointment`, CancellationAPIParams, {
			headers: headers
		}).map((res) => res);
		*/
  }
  GetAppointmentForPatientID(id: any) {
    return this.get(`${CAN_API}GetAppointmentForPatient?lPatientUID=${id}`);
  }

  getCentersDetails(centerId) {
    return this.get(`${API_BASE_URL}home/getcentersdetails/${centerId}`);
  }

  getCentersDetails2() {
    return this.get(`${API_BASE_URL}home/getcentersdetails/1`);
  }

  getPackageListing(PackageID, centerId, specialityid) {
    return this.get(
      `${API_BASE_URL}home/PackageListing?packageid=${PackageID}&locationid=&centerid=${centerId}&specialityid=${specialityid}&subspecialityid=&mainserviceid=&subserviceid=`
    );
  }

  getPackages() {
    return this.get(`${API_BASE_URL}home/Packages`);
  }
  getWorkShop() {
    return this.get(`${API_BASE_URL}home/Workshops`);
  }
  getWorkshopListing(WorkshopID, centerID, specialityid) {
    return this.get(
      `${API_BASE_URL}home/WorkshopListing?workshopid=${WorkshopID}&locationid=&centerid=${centerID}&specialityid=${specialityid}&subspecialityid=&doctorid=&venueid=`
    );
  }

  getSpecialityMappingByCenter(centerID, specialityid) {
    return this.get(
      `${API_BASE_URL}home/getspecialitymappingbycenter?centerid=${centerID}&specialityid=${specialityid}`
    );
  }

  getgetDoctors(speciality, subSpeciality, center) {
    return this.get(
      `${API_BASE_URL}home/getdoctorsbycenter?center=${center}&speciality_master=${speciality}&speciality_feature=${subSpeciality}&doctor_name=&location=`
    );
  }

  getContentHubList(centerId, tagId) {
    return this.get(
      `${API_BASE_URL}home/ContentHubListing?category=&contentid=&locationid=&centerid=${centerId}&specialityid=&subspecialityid=&doctorid=&tagid=${tagId}`
    );
  }

  GetInTouchLMS(formdata, cDatils: any, utm: any) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let newParam =
      '{"leaddate": "","country":"+91","countrycode":"+91","mobile": "' +
      formdata.mobile +
      '","name": "' +
      formdata.name +
      '","email": "' +
      formdata.email +
      '","speciality": "' +
      formdata.speciality +
      '","subspeciality": "' +
      formdata.subspeciality +
      '","location": "' +
      formdata.location +
      '","hospital":"' +
      formdata.hospitalname +
      '","doctor": "' +
      formdata.doctor +
      '","city": "' +
      formdata.city +
      '","comment": "","intrestedin": "","message": "' +
      formdata.message +
      '","leadtype": "' +
      utm.leadtype +
      '","page": "' +
      utm.page +
      '","modeoflead": "' +
      utm.modeoflead +
      '","utm_source": "' +
      utm.utm_source +
      '","utm_medium": "' +
      utm.utm_medium +
      '","utm_campaign": "' +
      utm.utm_campaign +
      '","utm_content": "' +
      utm.utm_content +
      '","utm_term": "' +
      utm.utm_term +
      '","utm_info": "","source": "' +
      utm.source +
      '","medium": "' +
      utm.medium +
      '","publisher": "' +
      utm.publisher +
      '","referral": "","agency": "' +
      utm.agency +
      '","campaign": "' +
      utm.campaign +
      '","dealtype": "' +
      utm.dealtype +
      '","device": "","lp":"","product": "' +
      utm.product +
      '","useragent": "' +
      formdata.useragent +
      '","ipaddress": "' +
      formdata.ipAddress +
      '","latestdisposition": "","mobileverificationstatus":"","mobileverificationresponse":"","gaparam": "","creative":"' +
      utm.creative +
      '","cta":"' +
      utm.cta +
      '","crmleadpushstatus":"","crmleadid":"","crmleadpushrequest":"","crmleadpushresponse":"","crmleadpushdatetime":"","dispositiondatetime":"","field1":"","field2":"","field3":"","field4":"","field5":"","field6":"","field7":"","field8":"","field9":"","field10":"","field11":"","field12":"","field13":"","field14":"","field15":"","preferreddatetime":""}';
    //console.log('newParam:', newParam, " cDatils:",cDatils);
    return this.http
      .post<any>(
        `https://lms.rainbowhospitals.in/api/index.php/CrudController/addLeads`,
        newParam,
        options
      )
      .map((res) => res);
  }
  postGetInTouchFormData(formdata, cDatils: any, utm: any) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let speciality = '',
      hospitalName = '';
    if (
      formdata.speciality == 'Obstetrics and Gynaecology' ||
      formdata.speciality == 'Fertility'
    ) {
      speciality = 'women Care';
      hospitalName = 'Birthright – ' + cDatils[0].city;
    } else if (formdata.speciality == 'Paediatric') {
      speciality = 'Child Care';
      hospitalName = "Rainbow Children's Hospital – " + cDatils[0].city;
    } else {
      speciality = formdata.speciality;
      hospitalName = cDatils[0].city;
    }
    let body = JSON.stringify(formdata);
    let newParam =
      '{"leaddate": "","country":"+91","countrycode":"+91","mobile": "' +
      formdata.mobile +
      '","name": "' +
      formdata.name +
      '","email": "' +
      formdata.email +
      '","speciality": "' +
      speciality +
      '","subspeciality": "' +
      formdata.speciality +
      '","location": "' +
      cDatils[0].location +
      '","hospital":"' +
      hospitalName +
      '","doctor": "","city": "","comment": "","intrestedin": "' +
      formdata.intrestedin +
      '","message": "' +
      formdata.message +
      '","leadtype": "' +
      utm.leadtype +
      '","page": "' +
      formdata.page +
      '","modeoflead": "' +
      utm.modeoflead +
      '","utm_source": "' +
      utm.utm_source +
      '","utm_medium": "' +
      utm.utm_medium +
      '","utm_campaign": "' +
      utm.utm_campaign +
      '","utm_content": "' +
      utm.utm_content +
      '","utm_term": "' +
      utm.utm_term +
      '","utm_info": "","source": "' +
      utm.source +
      '","medium": "' +
      utm.medium +
      '","publisher": "' +
      utm.publisher +
      '","referral": "","agency": "' +
      utm.agency +
      '","campaign": "' +
      utm.campaign +
      '","dealtype": "' +
      utm.dealtype +
      '","device": "","lp":"","product": "' +
      utm.product +
      '","useragent": "' +
      formdata.useragent +
      '","ipaddress": "' +
      formdata.ipAddress +
      '","latestdisposition": "","mobileverificationstatus":"","mobileverificationresponse":"","gaparam": "","creative":"' +
      utm.creative +
      '","cta":"' +
      utm.cta +
      '","crmleadpushstatus":"","crmleadid":"","crmleadpushrequest":"","crmleadpushresponse":"","crmleadpushdatetime":"","dispositiondatetime":"","field1":"","field2":"","field3":"","field4":"","field5":"","field6":"","field7":"","field8":"","field9":"","field10":"","field11":"","field12":"","field13":"","field14":"","field15":"","preferreddatetime":""}';
    //console.log('newParam:', newParam, " cDatils:",cDatils);
    return this.http
      .post<any>(
        `https://lms.rainbowhospitals.in/api/index.php/CrudController/addLeads`,
        newParam,
        options
      )
      .map((res) => res);
  }
  getSubSpeciality(cid: number, sid: number) {
    return this.get(
      `${API_BASE_URL}home/getspecialitymappingbycenter/?centerid=${cid}&specialityid=${sid}`
    );
  }

  getCentersByLocation() {
    return this.get(`${API_BASE_URL}home/centersByCity`);
  }

  getCenterByIpRegionCity() {
    return this.get(`${API_BASE_URL}home/getCenterByIpRegionCity`);
  }

  // Conference
  postConferenceRegistration(data: any) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    return this.http
      .post<any>(`http://localhost:4000/pay`, data, options)
      .map((item) => item);
  }
  /*
	postaddLeads(formData) {
        let headers = new Headers({ 'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });
        let newParam = '{"leaddate": "","country":"+91","countrycode":"+91","mobile": "' + formData.mobile + '","name": "' + formData.name + '","email": "' + formData.email + '","speciality": "","subspeciality": "","location": "' + formData.location + '","hospital":"","doctor": "","city": "","comment": "","intrestedin": "'+formData.intrestedin+'","message": "' + formData.message + '","leadtype": "","page": "'+formData.page+'","modeoflead": "'+formData.modeoflead+'","utm_source": "","utm_medium": "","utm_campaign": "","utm_content": "","utm_term": "","utm_info": "","source": "","medium": "","publisher": "","referral": "","agency": "","campaign": "","dealtype": "","device": "","lp":"","product": "","useragent": "","ipaddress": "","latestdisposition": "","mobileverificationstatus":"","mobileverificationresponse":"","gaparam": "","creative":"","cta":"","crmleadpushstatus":"","crmleadid":"","crmleadpushrequest":"","crmleadpushresponse":"","crmleadpushdatetime":"","dispositiondatetime":"","field1":"","field2":"","field3":"","field4":"","field5":"","field6":"","field7":"","field8":"","field9":"","field10":"","field11":"","field12":"","field13":"","field14":"","field15":"","preferreddatetime":""}'
        return this.http.post<any>(`https://lms.rainbowhospitals.in/api/index.php/CrudController/addLeads`, newParam, options).map((res) => res);
    }
	*/
  postaddLeads(formdata, utm: any) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let newParam =
      '{"leaddate": "","country":"","countrycode":"","mobile": "' +
      formdata.mobile +
      '","name": "' +
      formdata.name +
      '","email": "' +
      formdata.email +
      '","speciality": "","subspeciality": "","location": "' +
      formdata.location +
      '","hospital":"' +
      formdata.hospital +
      '","doctor": "","city": "","comment": "","intrestedin": "' +
      formdata.intrestedin +
      '","message": "' +
      formdata.message +
      '","leadtype": "' +
      utm.leadtype +
      '","page": "' +
      formdata.page +
      '","modeoflead": "' +
      utm.modeoflead +
      '","utm_source": "' +
      utm.utm_source +
      '","utm_medium": "' +
      utm.utm_medium +
      '","utm_campaign": "' +
      utm.utm_campaign +
      '","utm_content": "' +
      utm.utm_content +
      '","utm_term": "' +
      utm.utm_term +
      '","utm_info": "","source": "' +
      utm.source +
      '","medium": "' +
      utm.medium +
      '","publisher": "' +
      utm.publisher +
      '","referral": "","agency": "' +
      utm.agency +
      '","campaign": "' +
      utm.campaign +
      '","dealtype": "' +
      utm.dealtype +
      '","device": "","lp":"","product": "' +
      utm.product +
      '","useragent": "","ipaddress": "","latestdisposition": "","mobileverificationstatus":"","mobileverificationresponse":"","gaparam": "","creative":"' +
      utm.creative +
      '","cta":"' +
      utm.cta +
      '","crmleadpushstatus":"","crmleadid":"","crmleadpushrequest":"","crmleadpushresponse":"","crmleadpushdatetime":"","dispositiondatetime":"","field1":"","field2":"","field3":"","field4":"","field5":"","field6":"","field7":"","field8":"","field9":"","field10":"","field11":"","field12":"","field13":"","field14":"","field15":"","preferreddatetime":""}';
    return this.http
      .post<any>(
        `https://lms.rainbowhospitals.in/api/index.php/CrudController/addLeads`,
        newParam,
        options
      )
      .map((res) => res);
  }
  internationalSubmit(value) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let body = JSON.stringify(value);
    return this.http
      .post<any>(`formsubmit.php`, body, options)
      .map((res) => res);
  }
  sendConsultationMail(value) {
    let headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    });
    let options = { headers: headers };
    let body = JSON.stringify(value);
    return this.http
      .post<any>(
        `https://emailconsultation.rainb.in/api/send-book-consultation-mail`,
        body,
        options
      )
      .map((res) => res);
  }
}
