import { Component, ElementRef, NgZone, Host, Directive,Input } from '@angular/core';

import {ApiService} from "../services/api.service";
declare let $:any;
@Component({
  selector: 'slick-carousel',
  template: `<ng-content></ng-content>`
})

export class SlickCarouselComponent {
  constructor(private el: ElementRef, private zone: NgZone,private api:ApiService) {
	  if(this.api.isBrowser){
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/slick.min.js');
    }
    //  console.log(this.sliderConfig);
  }

  public $carousel:any;
  initialized = false;
 
  initCarousel(config) {
    this.zone.runOutsideAngular(() => {
      this.$carousel = $(this.el.nativeElement).slick(config);
    });
    this.initialized = true;
  }

  slides = [];

  addSlide(slide) {
    !this.initialized && this.initCarousel(slide.sliderConfig);
    this.slides.push(slide);
    this.$carousel.slick('slickAdd', slide.el.nativeElement);
  }

  removeSlide(slide) {
    //console.log('slide',slide);
    let idx = this.slides.indexOf(slide);
    if(idx == 1){
      //console.log('idx',idx)
      this.$carousel.slick('slickRemove', idx);
      this.slides = this.slides.filter(s => s != slide);
    }
  }
}


@Directive({
  selector: '[slick-carousel-item]',
})
export class SlickCarouselItem {
  constructor(private el: ElementRef, @Host() private carousel: SlickCarouselComponent) {
  }
   @Input() sliderConfig:any[];
  ngAfterViewInit() {
    this.carousel.addSlide(this);
    //console.log(this.sliderConfig);
  
    
  }
  ngOnDestroy() {
    this.carousel.removeSlide(this);
  }
}

