<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>

    <section class="page wtwedo">
        <div class="row">
            <div class="col-md-5 col-xs-5 col-lg-4">
                <div class="circuleframe">
                    <img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" />
                </div>
                <!-- End Circle -->
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7">
                <div class="pagetitle">
                    <h1 class="tgradient">{{title}} </h1>
                    <div class="underline">
                        <img src="assets/images/underline2.png" alt="" />
                    </div>
                    <h3>{{subtitle}}</h3>
                </div>
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7 mcontentview">
                <p [innerHTML]="description"></p>
            </div>
        </div>

    </section>
    <section class="page mediaSection">
        <div class="row">
            <div class="container">
                <div class="col-xs-12 PressReleases news_text">
                    <h2>Insights</h2>
                    <div class="row" mymatchheight="PressReleasesBox" ng-reflect-my-match-height="PressReleasesBox">
                        <div class="col-xs-12 col-sm-6 PressReleasesBox paddrt">
                            <h4>Pregnancy</h4>
                            <p>Department of Pediatric Surgery & Pediatric Urology and minimal access surgery</p>
                            <p><a class="readMore" href="/insights/pregnancy">Read More</a></p>
                        </div>
                        <div class="col-xs-12 col-sm-6 PressReleasesBox">
                            <h4>Urinary Incontinence</h4>
                            <p>Urinary incontinence is the inability of a woman to control her bladder function. There are many different kinds of incontinence.</p>
                            <p><a class="readMore" href="/insights/urinary-incontinence">Read More</a></p>
                        </div>
                        <div class="clearfix"></div>
                        <div class="col-xs-12 col-sm-6 PressReleasesBox paddrt">
                            <h4>Recurrent Miscarriage</h4>
                            <p>A recurrent miscarriage is defined as a woman losing her baby in the first 23 weeksand she has miscarried three or more times. Around one woman in every 100 has recurrent miscarriages.</p>
                            <p><a class="readMore" href="/insights/recurrent-miscarriage">Read More</a></p>
                        </div>
                        <div class="col-xs-12 col-sm-6 PressReleasesBox">
                            <h4>Presmenstrual Syndrome (PMS)</h4>
                            <p>Premenstrual syndrome is the medical terms used for the physical and emotional symptoms that some women have before their menstrual period begins. PMS is sometimes called premenstrual tension (PMT). The symptoms start 7 to
                                14 days before your period (menstruation). The symptoms usually stop soon after your period begins.</p>
                            <p><a class="readMore" href="/insights/presmenstrual-syndrome-pms">Read More</a></p>
                        </div>

                        <div class="clearfix"></div>
                        <div class="col-xs-12 col-sm-6 PressReleasesBox paddrt">
                            <h4>Preparing for a Normal Delivery</h4>
                            <p>Birth – the culmination of nine months of pregnancy is definitely daunting, to all of us. Many of us face an overflow of information on the subject (from friends, family and the written word) that can leave us wondering whether
                                we can go ahead with a normal delivery. If you are considering a normal delivery, it shows you believe in yourself and you trust your body.</p>
                            <p><a class="readMore" href="/insights/preparing-for-a-normal-delivery">Read More</a></p>
                        </div>
                        <div class="col-xs-12 col-sm-6 PressReleasesBox">
                            <h4>Fetal Medicine: Screenings</h4>
                            <p>Screening for fetal aneuploidies is now an accepted norm in contemporary Maternal – Fetal medicine practice. It is imperative that every pregnant woman be offered an opportunity to assess the risk of chromosomal problems in
                                her unborn fetus so that several decisions about the care of pregnancy are facilitated. However, with the advent of multiple tests at various gestational ages – each with its own advantages and limitations, the entire process
                                of screening for fetal aneuploidies becomes an enigma for Obstetricians.</p>
                            <p><a class="readMore" href="/insights/fetal-medicine-screenings">Read More</a></p>
                        </div>
                        <div class="clearfix"></div>
                        <div class="col-xs-12 col-sm-6 PressReleasesBox paddrt">
                            <h4>Cervical Cancer</h4>
                            <p>Sumithra was suffering from prolonged white discharge. When she discussed with her friends they said that it was common in women. It was occasionally blood stained and she started worrying when she noticed that every time she
                                had intercourse she was bleeding. At this point her husband insisted that she see a gynecologist.</p>
                            <p><a class="readMore" href="/insights/cervical-cancer">Read More</a></p>
                        </div>
                        <div class="col-xs-12 col-sm-6 PressReleasesBox">
                            <h4>Caesarean Section Recovery & Risks</h4>
                            <p>These days, having a caesarean section is becoming more and more common. This is a major abdominal surgery and therefore, the woman undergoing this procedure is susceptible to the following…</p>
                            <p><a class="readMore" href="/insights/caesarean-section-recovery-risks">Read More</a></p>
                        </div>
                        <div class="clearfix"></div>
                        <div class="col-xs-12 col-sm-6 PressReleasesBox paddrt">
                            <h4>Breast feeding Advice for You</h4>
                            <p>Breast feeding is dependent on a basic principle ofdemand and supply. The mother’s body responds to the baby’s sucking by producing milk as per the requirement. However, there may be a delay in establishment of a breast feeding
                                pattern in some mothers. In such cases, it is very important to stay relaxed and positive.</p>
                            <p><a class="readMore" href="/insights/breast-feeding-advice-for-you">Read More</a></p>
                        </div>
                        <div class="col-xs-12 col-sm-6 PressReleasesBox">
                            <h4>Advantages of breastfeeding</h4>
                            <p>Breast milk is the best form of nutrition for babies. It has the perfect combination of proteins, fats, carbohydrates, and fluids that new-born babies require. It is recommended that babies are fed only breast milk for their
                                first six months - with no water, other fluids or solids.</p>
                            <p><a class="readMore" href="/insights/advantages-of-breastfeeding">Read More</a></p>
                        </div>
                        <div class="clearfix"></div>
                        <div class="col-xs-12 col-sm-6 PressReleasesBox paddrt">
                            <h4>Recurrent Urinary Tract Infection</h4>
                            <p>Ratna came out of her boss's room in tears. He had issued a warning to her saying that she was taking too much time off from work claiming to be sick.</p>
                            <p><a class="readMore" href="/insights/recurrent-urinary-tract-infection">Read More</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <ng-template [ngIf]="ins_links.length > 0">
        <footer-nav-mini [links]="ins_links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>