<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>

    <section class="page wtwedo">
        <div class="row">
            <div class="col-md-5 col-xs-5 col-lg-4">
                <div class="circuleframe">
                    <img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" />
                </div>
                <!-- End Circle -->
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7">
                <div class="pagetitle">
                    <h1 class="tgradient">{{title}} </h1>
                    <div class="underline">
                        <img src="assets/images/underline2.png" alt="" />
                    </div>
                    <h3>{{subtitle}}</h3>
                </div>
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7 mcontentview">
                <p [innerHTML]="description"></p>
            </div>
        </div>

    </section>
    <section class="aboutsectin2 awardswapper">
        <div class="container">
            <div class="row nsg_text">
                <div class="col-xs-12 col-lg-12">
                    <h2>What are the benefits of recreational exercise during pregnancy?</h2>
                    <p><strong>Benefits for mother</strong></p>
                    <ul>
                        <li>Many women find that recreational exercise helps them to adjust to the physical changes that occur during pregnancy</li>
                        <li>It may help relieve tiredness, lower back pain and reduce varicose veins and swelling of the feet and ankles.</li>
                        <li>Exercise improves muscle tone, strength and endurance. It makes it easier to carry the weight you gain during pregnancy and helps prepare you for the physical challenge of labour.May improve your ability to cope with the pain of
                            labor.</li>
                        <li>Exercise promotes a sense of wellbeing. Staying fit during pregnancy may help to reduce feelings of stress, anxiety and depression.</li>
                        <li>Exercise also improves sleep</li>
                        <li>This will make it easier for you to get back in shape after the baby is born</li>
                        <li>Exercise during pregnancy may help to prevent medical conditions such as:</li>
                        <li>Gestational diabetes mellitus (diabetes that develops during pregnancy)</li>
                        <li>Hypertension (high blood pressure).</li>
                        <li>If you have gestational diabetes mellitus (diabetes that develops during pregnancy), exercise can help to improve your blood sugar levels.</li>
                        <li>Women who do not do recreational exercise during pregnancy may become unfit.</li>
                    </ul>
                    <p><strong>Benefits for the baby</strong></p>
                    <p>Women who do strength conditioning exercise during pregnancy tend to have a shorter labor time, lower rates of intervention during child birth and fewer delivery complications.</p>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>Are there any risks of exercising in pregnancy?</h2>
                    <p>Although recreational exercise can be beneficial during pregnancy, there may also be certain risks. These are related to the physical changes that occur as your body adapts to pregnancy. The risks are more likely to occur when you
                        do inappropriate kinds of exercise and when you over-exert yourself. By making appropriate adjustments to your exercise routine, you can reduce the likelihood of harm to you and the baby.</p>
                    <p><strong>The risks include:</strong></p>
                    <ul>
                        <li><strong>Getting too hot (hyperthermia). To reduce the risk of getting too hot, you should:</strong></li>
                        <li>ensure that you drink lots of water before and during exercise</li>
                        <li>avoid over-exerting yourself, particularly in the first 12 weeks of pregnancy</li>
                        <li>avoid exercising in a very hot and humid climate</li>
                        <li>Low blood pressure (hypotension). To reduce the risk of low blood pressure, you should avoid exercises which involve lying flat on your back, particularly after 16 weeks.</li>
                        <li><strong>Physical injury: During pregnancy you may notice that your joints become looser. It occurs because hormonal changes affect the ligaments which normally support your joints which in turn make the joints loose in preparation for birth. When your joints and ligaments are less stable, you are at increased risk of injuring yourself. To reduce the risk of physical injury, you should:</strong></li>
                        <li>make sure that you do warm-up and cool-down exercises</li>
                        <li>avoid sudden changes of direction, if you are doing aerobic exercise</li>
                        <li>consider wearing pelvic support belts during exercise</li>
                        <li><strong>Fluctuating blood sugar levels. Blood glucose is a source of energy for both you and the baby. It is important that you:</strong></li>
                        <li>eat well during pregnancy</li>
                        <li>exercise for no more than 45 minutes at a time</li>
                        <li><strong>Women with one of the following conditions will be advised by their doctors not to exercise during pregnancy:</strong></li>
                        <li>Risk factors for preterm labor</li>
                        <li>Vaginal bleeding</li>
                        <li>Premature rupture of membranes</li>
                    </ul>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>What is the most suitable form of exercise in pregnancy?</h2>
                    <p><strong>Yoga</strong> is great for working your muscles without too much impact on your joints, which is ideal throughout pregnancy. Also, most of the breathing techniques used in yoga are a great preparation for childbirth, helping
                        you to remain calm and breathe steadily through contractions. Yoga also improves posture, which will help with back pain and can increase flexibility, to make birthing positions such as squatting easier</p>
                    <p><strong>Walking</strong> is a good exercise for anyone. Brisk walking gives a total body workout and is easy on the joints and muscles. If you were not active before getting pregnant, walking is a great way to start an exercise program.</p>
                    <p><strong>Swimming</strong> is great for your body because it works so many muscles. The water supports your weight so you avoid injury and muscle strain. It also helps you stay cool and helps prevent your legs from swelling.</p>
                    <p><strong>Cycling</strong> provides a good aerobic workout. However, your growing belly can affect your balance and make you more prone to falls. You may want to stick with stationary or recumbent biking later in pregnancy.</p>
                    <p><strong>Aerobics</strong> is a good way to keep your heart and lungs strong. You should attend aerobics classes designed just for pregnant women. Low-impact and water aerobics are ideally suited for pregnant women.</p>
                    <p>Strength training will make your muscles stronger and may help prevent some of the aches and pains common in pregnancy.</p>
                    <p>If you do not exercise routinely and you are starting an exercise programme for the first time in pregnancy, you should be advised to begin with no more than 15 minutes continuous exercise three times per week, increasing gradually
                        to a maximum of 30 minute sessions four times a week to daily.</p>
                    <p>If you exercised regularly before pregnancy, you should be able to engage 30 minute sessions four times a week to daily.</p>
                    <p><strong>Getting Started</strong></p>
                    <p>Before beginning your exercise program, talk with your doctor to make sure you do not have any obstetric or health condition that would limit your activity. Ask about any specific exercises or sports that interest you. Your doctor
                        can offer advice about what type of exercise routine is best for you.</p>
                    <ul>
                        <li>Wear comfortable clothing that will help you to remain cool.</li>
                        <li>Wear a bra that fits well and gives lots of support to help protect your breasts.</li>
                        <li>Drink plenty of water to help keep you from overheating and dehydrating.</li>
                        <li>Make sure you consume the daily extra calories you need during pregnancy</li>
                    </ul>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>What kind of exercise should I avoid?</h2>
                    <ul>
                        <li>You should avoid exercises which involve lying flat on your back, particularly after 16 weeks.</li>
                        <li>You should avoid contact sports where there is a risk of being hit in the abdomen, such as kickboxing, judo or squash</li>
                        <li>You should take particular care when doing exercises where there is a possibility of falling or losing your balance, such as horse riding, gymnastics and cycling. Because your joints are less stable, your centre of gravity is altered
                            (the bump tends to overbalance you), and your reactions are slower.</li>
                        <li>You should avoid scuba diving for your entire pregnancy because the baby has no protection against decompression sickness and gas embolism under water.</li>
                        <li>You should avoid exercising over 2500 meters until you have acclimatized.</li>
                        <li>Do not use saunas or steam rooms</li>
                    </ul>
                </div>
                <div class="col-xs-12 col-lg-12">
                    <h2>When should I stop exercising?</h2>
                    <p>If you have any of the following unusual symptoms, you should stop exercise and see your doctor.</p>
                    <ul>
                        <li>dizziness or feeling faint</li>
                        <li>headache</li>
                        <li>shortness of breath before exertion</li>
                        <li>difficulty getting your breath whilst exercising</li>
                        <li>pain or palpitations in your chest</li>
                        <li>pain in your abdomen, back or pubic area</li>
                        <li>pain in your pelvic girdle</li>
                        <li>weakness in your muscles</li>
                        <li>pain or swelling in your leg/legs</li>
                        <li>painful uterine contractions or preterm labour</li>
                        <li>fewer movements from baby</li>
                        <li>leakage of your 'waters' (amniotic fluid)</li>
                        <li>bleeding</li>
                    </ul>
                    <p>If you had complications during a pregnancy or a caesarean delivery, then you should discuss what exercise is safe to do after birth with a doctor.</p>
                </div>
            </div>
        </div>
    </section>
    <ng-template [ngIf]="ins_links.length > 0">
        <footer-nav-mini [links]="ins_links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>