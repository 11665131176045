import {
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  HostListener,
} from '@angular/core';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL, API_BASE_URL } from '../services/constants';
import { Meta, Title } from '@angular/platform-browser';
import {
  ReactiveFormsModule,
  FormsModule,
  FormControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
declare let initAboutTabs: any;

@Component({
  selector: 'academic-programs',
  templateUrl: './programs.component.html',
})
export class AcademicProgramsComponent
  implements OnDestroy, OnInit, AfterViewInit
{
  GetInTouch: FormGroup;
  constructor(
    private api: ApiService,
    public metaTitle: Title,
    public meta: Meta,
    private fb: FormBuilder
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/fancybox/jquery.fancybox.js');
      require('../../assets/js/slick.min.js');
    }

    this.GetInTouch = this.fb.group({
      name: ['', [Validators.required, Validators.pattern('.*\\S.*')]],
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            '[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}'
          ),
        ]),
      ],
      mobile: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(/^(\+\d{1,3}[- ]?)?\d{10}$/),
        ]),
      ],
      message: [''],
      programName: ['Academic Programs'],
      emailto: [''],
      programe: [''],
    });
  }
  public className: any;
  public services: any[] = [];
  public brief_facilities: any[] = [];
  public title: any = '';
  public subtitle: any = '';
  public description: any = '';
  public imgsrc: any = '';
  public imgBasePath = '';
  public hidden = 'hidden';
  public logo = 'logo';
  public centerList: any[] = [];
  public SpecialtyID: any = '';
  public SubSpecialtyID: any = '';
  public SubSpecialtyData: any;
  public LocationID: any = '';
  public academicAllProgramsData: any[];
  public academicPrograms: any;
  public submited: boolean = false;
  public showHide: boolean = false;
  public preloader: boolean = false;
  public GetInTouchLoader: boolean = false;
  public programListResult: boolean = false;

  public specilityMasterList = [
    {
      id: 1,
      slug: 'child-care',
      name: 'Child Care',
    },
    {
      id: 2,
      slug: 'women-care',
      name: 'Women Health',
    },
    {
      id: 3,
      slug: 'fertility-care',
      name: 'Fertility Care',
    },
  ];
  public bread_crumb_links: any = [
    { label: 'Home', link: '/', class: '', slug: 'home' },
    { label: 'Others', link: '', class: '', slug: 'others' },
    {
      label: 'Programs',
      link: '/other/academic-programs',
      class: '',
      slug: 'academic-programs',
    },
  ];

  //public links: any = [];
  public links: any = [
    {
      label: 'Programs',
      link: '/other/academic-programs',
      class: 'active',
      slug: 'academic-programs',
    },
    {
      label: 'Publications',
      link: '/other/academic-article',
      class: '',
      slug: 'academic-article',
    },
    {
      label: 'Webinars',
      link: '/other/academic-webinar',
      class: '',
      slug: 'academic-webinar',
    },
  ];

  public activePage: string = 'academic-programs';

  // public doctors;

  // getCenterList(id) {
  // 	this.api.getOurDoctorList(id).subscribe(res => {
  // 		this.doctors = res.speciality_master;
  // 		let centers = res.speciality_master.centers;

  // 		this.api.getCentreCities().subscribe(res =>{
  // 			this.centerList = res;
  // 		});
  // 	});
  // }

  //@ViewChildren('atabs') atabsThings: QueryList<any>;
  public ProgramListData;
  ProgramList(specialityid, subspecialityid, centerid) {
    $('.customloder').show();
    this.api
      .getAcademicProgramsList(specialityid, subspecialityid, centerid)
      .subscribe((res) => {
        //console.log('getAcademicProgramsList: ', res);
        //this.ProgramListData = res['content_hubs'];
        this.ProgramListData = res;
        if (res.length != 0) {
          this.programListResult = false;
        } else {
          this.programListResult = true;
        }
        $('.customloder').hide();
        //console.log('ProgramListData', this.ProgramListData);
        setTimeout(() => {
          this.clickModelPopup();
        }, 200);
      });
  }

  public specialityid = '';
  public subspecialityid = '';
  public centerid = '';

  filterData(event, cat) {
    if (cat == 'Specialty') {
      this.specialityid = event.target.value;
    }
    if (cat == 'SubSpeciality') {
      this.subspecialityid = event.target.value;
    }
    if (cat == 'location') {
      this.centerid = event.target.value;
    }
    if (cat == 'all') {
      this.specialityid = '';
      this.subspecialityid = '';
      this.centerid = '';
      $('.filterby').each(() => {
        $(this).val('').change();
      });
    }
    //("specialityid", this.specialityid, "subspecialityid", this.subspecialityid, "centerid", this.centerid);
    this.ProgramList(this.specialityid, this.subspecialityid, this.centerid);
  }

  getAcademicPrograms() {
    this.api.getAcademicPrograms().subscribe((res) => {
      this.academicAllProgramsData = res;
      this.academicPrograms = res;
      console.log('getAcademicPrograms: ', this.academicPrograms);
    });
  }

  public AllsubSpeciality;

  getsubSpeciality() {
    $('.customloder').show();
    this.api.getCentreSpecification().subscribe((res) => {
      this.AllsubSpeciality = res.centers;
      //console.log('AllsubSpeciality', this.AllsubSpeciality);
      $('.customloder').hide();
    });
  }

  filterSubSpeciality(event) {
    let id = event.target.value;
    this.AllsubSpeciality.forEach((element) => {
      if (element.id == id) {
        return (this.SubSpecialtyData = element.speciality_features);
      }
    });
    //console.log('SubSpecialtyData', this.SubSpecialtyData);
    this.filterData(event, 'location');
  }

  getAllCentresSpeciality() {
    this.api.getCentreCities().subscribe((res) => {
      this.centerList = res;
      //console.log('CenterList', this.centerList);
    });
  }

  ngOnInit() {
    this.ProgramList('', '', '');

    this.api.getPageDescription('academic-programs').subscribe(
      (data) => {
        (this.title = data.page_descriptions[0].title),
          (this.subtitle = data.page_descriptions[0].subtitle),
          (this.description = data.page_descriptions[0].description),
          (this.imgsrc = data.page_descriptions[0].image),
          (this.imgBasePath = IMG_BASE_URL);
        if (data) {
          //console.log(data);
          this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
          this.meta.updateTag({
            name: 'keywords',
            content: data.page_descriptions[0].meta_keyword,
          });
          this.meta.updateTag({
            name: 'description',
            content: data.page_descriptions[0].meta_description,
          });
          this.meta.addTags([
            {
              property: 'og:title',
              content: data.page_descriptions[0].meta_title,
            },
            {
              property: 'og:description',
              content: data.page_descriptions[0].meta_description,
            },
            {
              property: 'og:keywords',
              content: data.page_descriptions[0].meta_keyword,
            },
          ]);
        }
      },
      (error) => {
        console.log(error);
      }
    );
    /*this.api.getOtherSubFooterDetails().map(res => res.json()).subscribe((data)=>{
			this.links =  data;
		})*/
    this.getsubSpeciality();
    this.getAllCentresSpeciality();
    //this.getAcademicPrograms();
  }

  onChangeSpecialty(e) {
    //$('.loder').show();
    this.SpecialtyID = e.target.value;
    // console.log('onChangeSpecialty: ', this.SpecialtyID, this.SubSpecialtyID, this.LocationID);

    if (this.SpecialtyID != '') {
      if (
        this.SpecialtyID != '' &&
        this.SubSpecialtyID != '' &&
        this.LocationID != ''
      ) {
        let sProgramArray = [];
        this.academicAllProgramsData.forEach((element) => {
          if (
            element.specialty_filter_id == this.SpecialtyID &&
            element.subspecialty_filter_id == this.SubSpecialtyID &&
            element.center_filter_id == this.LocationID
          ) {
            sProgramArray.push(element);
          }
        });
        this.academicPrograms = sProgramArray;
      } else if (this.SpecialtyID != '' && this.SubSpecialtyID != '') {
        let sProgramArray1 = [];
        this.academicAllProgramsData.forEach((element) => {
          if (
            element.subspecialty_filter_id == this.SubSpecialtyID &&
            element.specialty_filter_id == this.SpecialtyID
          ) {
            sProgramArray1.push(element);
          }
        });
        this.academicPrograms = sProgramArray1;
      } else if (this.LocationID != '' && this.SpecialtyID != '') {
        let sProgramArray2 = [];
        this.academicAllProgramsData.forEach((element) => {
          if (
            element.center_filter_id == this.LocationID &&
            element.specialty_filter_id == this.SpecialtyID
          ) {
            sProgramArray2.push(element);
          }
        });
        this.academicPrograms = sProgramArray2;
      } else {
        let sProgramArray3 = [];
        this.academicAllProgramsData.forEach((element) => {
          if (element.specialty_filter_id == this.SpecialtyID) {
            sProgramArray3.push(element);
          }
        });
        this.academicPrograms = sProgramArray3;
      }
    } else {
      if (this.SpecialtyID != '' && this.LocationID != '') {
        let sProgramArrayE1 = [];
        this.academicAllProgramsData.forEach((element) => {
          if (
            element.specialty_filter_id == this.SpecialtyID &&
            element.center_filter_id == this.LocationID
          ) {
            sProgramArrayE1.push(element);
          }
        });
        this.academicPrograms = sProgramArrayE1;
      } else if (this.SpecialtyID != '') {
        let sProgramArrayE2 = [];
        this.academicAllProgramsData.forEach((element) => {
          if (element.specialty_filter_id == this.SpecialtyID) {
            sProgramArrayE2.push(element);
          }
        });
        this.academicPrograms = sProgramArrayE2;
      } else if (this.LocationID != '') {
        let sProgramArrayE3 = [];
        this.academicAllProgramsData.forEach((element) => {
          if (element.center_filter_id == this.LocationID) {
            sProgramArrayE3.push(element);
          }
        });
        this.academicPrograms = sProgramArrayE3;
      }
    }
  }

  onChangeSubSpecialty(e) {
    //$('.loder').show();

    this.SubSpecialtyID = e.target.value;
    //console.log('onChangeSubSpecialty: ', this.SpecialtyID, this.SubSpecialtyID, this.LocationID);
    if (this.SubSpecialtyID != '') {
      if (
        this.SpecialtyID != '' &&
        this.SubSpecialtyID != '' &&
        this.LocationID != ''
      ) {
        let sProgramArray = [];
        this.academicAllProgramsData.forEach((element) => {
          if (
            element.specialty_filter_id == this.SpecialtyID &&
            element.subspecialty_filter_id == this.SubSpecialtyID &&
            element.center_filter_id == this.LocationID
          ) {
            sProgramArray.push(element);
          }
        });
        this.academicPrograms = sProgramArray;
      } else if (this.SpecialtyID != '' && this.SubSpecialtyID != '') {
        let sProgramArray1 = [];
        this.academicAllProgramsData.forEach((element) => {
          if (
            element.specialty_filter_id == this.SpecialtyID &&
            element.subspecialty_filter_id == this.SubSpecialtyID
          ) {
            sProgramArray1.push(element);
          }
        });
        this.academicPrograms = sProgramArray1;
      } else if (this.LocationID != '' && this.SubSpecialtyID != '') {
        let sProgramArray2 = [];
        this.academicAllProgramsData.forEach((element) => {
          if (
            element.center_filter_id == this.LocationID &&
            element.subspecialty_filter_id == this.SubSpecialtyID
          ) {
            sProgramArray2.push(element);
          }
        });
        this.academicPrograms = sProgramArray2;
      } else {
        let sProgramArray3 = [];
        this.academicAllProgramsData.forEach((element) => {
          if (element.subspecialty_filter_id == this.SubSpecialtyID) {
            sProgramArray3.push(element);
          }
        });
        this.academicPrograms = sProgramArray3;
      }
    } else {
      if (this.SpecialtyID != '' && this.LocationID != '') {
        let sProgramArrayE1 = [];
        this.academicAllProgramsData.forEach((element) => {
          if (
            element.specialty_filter_id == this.SpecialtyID &&
            element.center_filter_id == this.LocationID
          ) {
            sProgramArrayE1.push(element);
          }
        });
        this.academicPrograms = sProgramArrayE1;
      } else if (this.SpecialtyID != '') {
        let sProgramArrayE2 = [];
        this.academicAllProgramsData.forEach((element) => {
          if (element.specialty_filter_id == this.SpecialtyID) {
            sProgramArrayE2.push(element);
          }
        });
        this.academicPrograms = sProgramArrayE2;
      } else if (this.LocationID != '') {
        let sProgramArrayE3 = [];
        this.academicAllProgramsData.forEach((element) => {
          if (element.center_filter_id == this.LocationID) {
            sProgramArrayE3.push(element);
          }
        });
        this.academicPrograms = sProgramArrayE3;
      }
    }
  }
  onChangeLocation(e) {
    //$('.loder').show();
    this.LocationID = e.target.value;
    //console.log('onChangeLocation: ', this.SpecialtyID, this.SubSpecialtyID, this.LocationID);
    if (this.LocationID != '') {
      if (
        this.SpecialtyID != '' &&
        this.SubSpecialtyID != '' &&
        this.LocationID != ''
      ) {
        let sProgramArray = [];
        this.academicAllProgramsData.forEach((element) => {
          if (
            element.specialty_filter_id == this.SpecialtyID &&
            element.subspecialty_filter_id == this.SubSpecialtyID &&
            element.center_filter_id == this.LocationID
          ) {
            sProgramArray.push(element);
          }
        });
        this.academicPrograms = sProgramArray;
      } else if (this.SpecialtyID != '' && this.LocationID != '') {
        let sProgramArray1 = [];
        this.academicAllProgramsData.forEach((element) => {
          if (
            element.specialty_filter_id == this.SpecialtyID &&
            element.center_filter_id == this.LocationID
          ) {
            sProgramArray1.push(element);
          }
        });
        this.academicPrograms = sProgramArray1;
      } else if (this.SubSpecialtyID != '' && this.LocationID != '') {
        let sProgramArray2 = [];
        this.academicAllProgramsData.forEach((element) => {
          if (
            element.subspecialty_filter_id == this.SubSpecialtyID &&
            element.center_filter_id == this.LocationID
          ) {
            sProgramArray2.push(element);
          }
        });
        this.academicPrograms = sProgramArray2;
      } else {
        let sProgramArray3 = [];
        this.academicAllProgramsData.forEach((element) => {
          if (element.center_filter_id == this.LocationID) {
            sProgramArray3.push(element);
          }
        });
        this.academicPrograms = sProgramArray3;
      }
    } else {
      if (this.SpecialtyID != '' && this.SubSpecialtyID != '') {
        let sProgramArrayE1 = [];
        this.academicAllProgramsData.forEach((element) => {
          if (
            element.specialty_filter_id == this.SpecialtyID &&
            element.subspecialty_filter_id == this.SubSpecialtyID
          ) {
            sProgramArrayE1.push(element);
          }
        });
        this.academicPrograms = sProgramArrayE1;
      } else if (this.SpecialtyID != '') {
        let sProgramArrayE2 = [];
        this.academicAllProgramsData.forEach((element) => {
          if (element.specialty_filter_id == this.SpecialtyID) {
            sProgramArrayE2.push(element);
          }
        });
        this.academicPrograms = sProgramArrayE2;
      } else if (this.SubSpecialtyID != '') {
        let sProgramArrayE3 = [];
        this.academicAllProgramsData.forEach((element) => {
          if (element.subspecialty_filter_id == this.SubSpecialtyID) {
            sProgramArrayE3.push(element);
          }
        });
        this.academicPrograms = sProgramArrayE3;
      }
    }
  }
  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
    }
  }

  clickModelPopup() {
    var othis = this;
    $('.FeaturedPrograms .BtnApplynow').each(function () {
      $(this).click(function (e) {
        e.preventDefault();
        let emailidTo = $(this).attr('id');
        let programeName = $(this).attr('name');
        $('.ThankYouMsgWrp').hide();
        $('#ModelFormGetInTouch').show();
        othis.GetInTouch.controls.emailto.setValue(emailidTo);
        othis.GetInTouch.controls.programe.setValue(programeName);
      });
    });
  }

  @HostListener('window:load', [])
  onWindowLoad() {
    $('.filterby').each(() => {
      $(this).change(() => {
        var filterValues = [];
        $('.Specialty').val() ? filterValues.push($('.Specialty').val()) : '';
        $('.location').val() ? filterValues.push($('.location').val()) : '';
        $('.SubSpeciality').val()
          ? filterValues.push($('.SubSpeciality').val())
          : '';
        $('.all').each(() => {
          var thisO = this,
            validEle = true;
          filterValues.forEach((element) => {
            if (!thisO.className.includes(element)) {
              validEle = false;
            }
          });
          if (validEle) {
            $(this).show();
          } else {
            $(this).hide();
          }
        });
      });
    });
  }

  hideModelForm() {
    $('#ModelFormGetInTouch').hide();
  }

  ShowModelForm() {
    $('#ModelFormGetInTouch').show();
  }

  onSubmit() {
    this.submited = true;
    if (!this.GetInTouch.valid) {
      return;
    }
    this.preloader = true;
    var self = this;

    if (this.GetInTouch.valid) {
      this.GetInTouchLoader = true;
      let formTemp: any = <any>document.querySelector('#form');
      let formData: FormData = new FormData(formTemp);

      let xhr: XMLHttpRequest = this.buildForm(formData);

      xhr.onreadystatechange = () => {
        var obj = xhr;
        if (obj.readyState == 4) {
          this.GetInTouchLoader = false;
          //window.location.href = `other/academic-programs/thankyou?tag=AW`;/
          $('.ThankYouMsgWrp').show();
          setTimeout(() => {
            $('#ModelFormGetInTouch').hide();
            this.GetInTouch.reset();
            setTimeout(() => {
              this.GetInTouch.controls.programName.setValue(
                'Academic Programs'
              );
            }, 100);
          }, 2500);
        }
        if (obj.status == 200) {
          //console.log(obj.status);
        }
      };
    }
  }

  public buildForm(formData) {
    let url: any = API_BASE_URL + 'home/academic/get_in_touch_with_us';
    let xhr: XMLHttpRequest = new XMLHttpRequest();
    xhr.open('POST', url, true);

    // IE bug fixes to clear cache
    xhr.setRequestHeader('Cache-Control', 'no-cache');
    xhr.setRequestHeader('Cache-Control', 'no-store');

    xhr.send(formData);
    return xhr;
  }

  ngOnDestroy() {}
}
