import { SearchComponent } from './other/search.component';
import { SiteMapComponent } from './other/sitemap.component';
import { ChildSafetyComponent } from './childcare/child-safety.component';
import { CenterSpecialitiesComponent } from './specialities/center-specialities.component';
import { MdProfileComponent } from './other/md-profile.component';
import { OvulationComponent } from './calculator/ovulation.component';
import { KickcounterComponent } from './calculator/kickcounter.component';
import { ContractionComponent } from './calculator/contraction.component';
import { BmiComponent } from './calculator/bmi.component';
import { BookanAppointment } from './other/book-an-appointment.component';
import { OurCenterMapComponent } from './other/our-center-map.component';
import { AcademicPrograms } from './other/academic-programs.component';
import { HealthCardRegisterComponent } from './other/health-card-register.component';
import { OurDoctorComponent } from './other/our-doctor.component';
import { QuizPage } from './other/quiz.component';
import { OurCenterDetailComponent } from './other/our-center-detail.component';
import { OurCenterComponent } from './other/our-center.component';
import { InpatientFeedbackComponent } from './feedback/inpatientfeedback.component';
/* tslint:disable: max-line-length */
import { Routes } from '@angular/router';



import { DashboardComponent } from './features/dashboard.component';
import { HomeComponent } from './home/home.component';



import { OtherFaqComponent } from './other/faq.component';
import { OtherInsuranceComponent } from './other/insurance.component';
import { OtherTermsAndConditionsComponent } from './other/terms-conditions.component';
import { OtherHealthCardComponent } from './other/health-card.component';
import { OtherPackagesAndPlansComponent } from './other/packages-plans.component';
import { FertilityCareAboutComponent } from './fertilitycare/about.component';
import { FertilityCareDoctorsComponent } from './fertilitycare/doctors.component';
import { FertilityCareSpecialitiesComponent } from './fertilitycare/specialities.component';
import { FertilityCareCentersComponent } from './fertilitycare/centers.component';
import { FertilityCareVaccinationComponent } from './fertilitycare/vaccination.component';
import { FertilityCareTestimonialsComponent } from './fertilitycare/testimonials.component';
import { WomenCareAboutComponent } from './womenhealth/about.component';
import { WomenCareDoctorsComponent } from './womenhealth/doctors.component';
import { WomenCareSpecialitiesComponent } from './womenhealth/specialities.component';
import { WomenCareCentersComponent } from './womenhealth/centers.component';
import { WomenCareVaccinationComponent } from './womenhealth/vaccination.component';
import { WomenCareTestimonialsComponent } from './womenhealth/testimonials.component';
import { ChildCareAboutComponent } from './childcare/about.component';
import { ChildCareDoctorsComponent } from './childcare/doctors.component';
import { ChildCareSpecialitiesComponent } from './childcare/specialities.component';
import { OurSpecialityDetailsComponent } from './other/our-speciality-details.component';
import { ChildCareAboutCenterComponent } from './childcare/about-center.component';
import { ChildCareVaccinationComponent } from './childcare/vaccination.component';
import { ChildCareTestimonialsComponent } from './childcare/testimonials.component';
//import { OurChildCareSpecialitiesComponent } from './childcare/our-child-care-specialities.component';
import { DoctorDetailsComponent } from './other/doctor-details.component';
import { AboutUsComponent } from './about/about-us.component';
import { WhoWeAreComponent } from './about/who-we-are.component';
import { HistoryComponent } from './about/history.component';
import { AwardsComponent } from './about/awards.component';
import { CareersComponent } from './about/careers.component';
import { CareersJoinWithUsComponent } from './about/careers-join-withus.component';
import { CareersApplyComponent } from './about/careers-apply.component';
import { NewsComponent } from './about/news.component';
import { EventsComponent } from './about/events.component';
import { ChannelComponent } from './about/channel.component';
import { WhatWeDoComponent } from './about/what-we-do.component';
import { MdsMessageComponent } from './about/mds-message.component';
import { WhyChooseUsComponent } from './about/why-chooseus.component';
import { QualityAccreditationComponent } from './about/quality-accreditation.component';
import { CuttingEdgeMedicalSolutionsComponent } from './about/cutting-edge-medical-solutions.component';
import { ContactUsComponent } from './about/contact-us.component';
import { MediaComponent } from './about/media.component';
import { ManagementComponent } from './about/management.component';
import { NotFound404Component } from './not-found404.component';
import {FeedbackComponent} from './feedback/feedback.component';
import { OtherAppComponent } from "./other/app.component";
import { OtherInternationalPatientsComponent } from "./other/international-patients.component";
import { OtherknowledgeCentreComponent } from "./other/knowledge-centre.component";
import { PressReleasesDetailsComponent } from "./about/press-releases-details";
import { MediaPressReleasesComponent } from "./about/media-press-releases";
import { MediaPressClipsComponent } from "./about/media-press-clips";
import { NewsDetailsComponent } from "./about/news-details.component";
import { EventsDetailsComponent } from "./about/events-details.component";
import { EmergencyServiceComponent } from "./other/emergency-service.component";
import { OurSpecialitiesComponent } from "./other/our-specialities.component";
import { BreadCrumbComponent } from "./home/bread-crumb.component";
import { OurCenterCityComponent } from "./other/our-center-city.component";
import { OurCenterCityDetailsComponent } from "./other/our-center-city-details.component";
import { OurTestimonialsComponent } from './other/our-testimonials.component';
import { OurDoctorConsultantComponent } from './other/our-doctor-consultant.component';
import { KnowledgeCentreDetailComponent } from './other/knowledge-centre-detail.component';
import { GetInTouchThankyouComponent } from './specialities/thankyou.component';
import { NewsletterComponent } from './other/newsletter.component';
import { NeonatalSupportGroupComponent } from './nsg/nsg.component';
import { BeforeBirthComponent } from './nsg/beforebirth.component';

import { InHospitalComponent } from './nsg/inhospital.component';
import { GoingHomeComponent } from './nsg/goinghome.component';
import { AtHomeComponent } from './nsg/athome.component';
import { GrowingUpComponent } from './nsg/growingup.component';

import { InsightsComponent } from './insights/insights.component';
import { UrinaryIncontinenceComponent } from './insights/urinaryincontinence.component';
import { RecurrentMiscarriageComponent } from './insights/recurrentmiscarriage.component';
import { PresmenstrualSyndromePMSComponent } from './insights/presmenstrual-syndrome-pms.component';
import { PreparingForANormalDeliveryComponent } from './insights/preparing-for-a-normal-delivery.component';
import { PregnancyComponent } from './insights/pregnancy/pregnancy.component';
import { OurCenterDetailsComponent } from './other/our-center-details.component';
import { AdvantagesOfBreastfeedingComponent } from './insights/advantages-of-breastfeeding.component';
import { BreastFeedingAdviceForYouComponent } from './insights/breast-feeding-advice-for-you.component';
import { CaesareanSectionRecoveryRisksComponent } from './insights/caesarean-section-recovery-risks.component';
import { CervicalCancerComponent } from './insights/cervical-cancer.component';
import { FetalMedicineScreeningsComponent } from './insights/fetal-medicine-screenings.component';
import { RecurrentUrinaryTractInfectionComponent } from './insights/recurrent-urinary-tract-infection.component';
import { TestingBloodGroupInPregnancyComponent } from './insights/pregnancy/testing-blood-group-in-pregnancy.component';
import { PregnancyAndGastrointestinalSystemComponent } from './insights/pregnancy/pregnancy-and-gastrointestinal-system.component';
import { ExerciseInPregnancyComponent } from './insights/pregnancy/exercise-in-pregnancy.component';
import { FrequentlyAskedQuestionsInPregnancyComponent } from './insights/pregnancy/frequently-asked-questions-in-pregnancy.component';
import { BloodTransfusionDuringPregnancyAndBirthComponent } from './insights/pregnancy/blood-transfusion-during-pregnancy-and-birth.component';
import { MultiplePregnancyTwinsComponent } from './insights/pregnancy/multiple-pregnancy-twins.component';
import { TestingBloodGroupInPregnancyTheThesusProblemComponent } from './insights/pregnancy/testing-blood-group-in-pregnancy-the-rhesus-problem.component';
import { CitySpecialitiesComponent } from './specialities/city-specialities.component';
import { OtherInternationalPatientsThankYouComponent } from './other/international_p_thankyou.component';
import { CsrComponent } from './about/csr.component';
import { CsrDetailsComponent } from './about/csr-details.component';
import { ConfirmedComponent } from './appointmentconfirmed/confirmed.component';
import { PressClipDetailsComponent } from './about/press-clips-details.component';
import { BmwComponent } from './about/bmw.component';
import { VideoHubComponent } from './academic/videohub.component';
import { ArticleDetailsComponent } from './academic/articles-details.component';
import { AcademicProgramsComponent } from './academic/programs.component';
import { AcademicArticlesComponent } from './academic/articles.component';
import { AcademicWebinarComponent } from './academic/webinar.component';
import { OurCenterDetaillistComponent } from './other/our-center-detaillist.component';
import { OurCenterNewComponent } from './other/our-center-new.component';
import { OurCenterMapNewComponent } from './ourcentre/our-center-map-new.component';
import { OtherPackagesAndPlansDetailsComponent } from './other/packages-plans-details.component';
import { CancelComponent } from './appointmentconfirmed/cancel.component';
import { TestPageComponent } from './insights/testpage.component';
import { InvestorRelationsComponent } from './investor-relations/investor-relations.component';
import { InternationalPatientsComponent } from './international-patients/international-patients.component';
import { HelloDoctorComponent } from './hello-doctor/hello-doctor.component';
import { ConferenceOnSpecialPediatricsComponent } from './conference-on-special-pediatrics/conference-on-special-pediatrics.component';
import { ConferencePayComponent } from './conference-pay/conference-pay.component';
import { EveningClinicAtBanjaraHillsComponent } from './evening-clinic-at-banjara-hills/evening-clinic-at-banjara-hills.component';
import { CuspSuccessComponent } from './cusp-success/cusp-success.component';
import { CuspFailureComponent } from './cusp-failure/cusp-failure.component';
//import { OurCenterRevisedComponent } from "./home/our-center-revised.component";

export const routes: Routes = [
  { path: 'home', redirectTo: '', pathMatch: 'full' },
  {
    path: '',
    component: ConferenceOnSpecialPediatricsComponent,
    /* HomeComponent*/ pathMatch: 'full',
  },
  //{ path: 'faq', component: OtherFaqComponent },
  //{ path: 'insurance', component: OtherInsuranceComponent },
  //{ path: 'terms-conditions', component: OtherTermsAndConditionsComponent },
  //{ path: 'health-card', component: OtherHealthCardComponent },
  //{ path: 'other/health-card', component: OtherHealthCardComponent },
  // { path: 'packages-plans', component: OtherPackagesAndPlansComponent },
  //{ path: 'other/packages-plans', component: OtherPackagesAndPlansComponent },
  { path: 'fertility-care/about', component: FertilityCareAboutComponent },
  // { path: 'fertility-care/doctors', component: FertilityCareDoctorsComponent },

  /* Added Route Path needed for newly created component */
  { path: 'our-centres-old', component: OurCenterCityComponent },
  { path: 'our-centres', component: OurCenterMapNewComponent },
  {
    path: 'our-centre-details/:slug',
    component: OurCenterCityDetailsComponent,
  },
  {
    path: 'fertility-care/treatments',
    component: FertilityCareSpecialitiesComponent,
  },
  //{ path: 'fertility-care/centres', component: FertilityCareCentersComponent },
  {
    path: 'fertility-care/vaccination',
    component: FertilityCareVaccinationComponent,
  },
  {
    path: 'fertility-care/testimonials',
    component: FertilityCareTestimonialsComponent,
  },
  //{ path: 'fertility-care/feedback', component: FertilityCareFeedbackComponent },
  //{ path: 'fertility-care/appointment', component: FertilityCareAppointmentComponent },
  { path: 'women-care/about', component: WomenCareAboutComponent },
  //{ path: 'women-care/doctors', component: WomenCareDoctorsComponent },
  {
    path: 'women-care/specialities',
    component: WomenCareSpecialitiesComponent,
  },
  //{ path: 'women-care/centres', component: WomenCareCentersComponent },
  { path: 'women-care/vaccination', component: WomenCareVaccinationComponent },
  {
    path: 'women-care/testimonials',
    component: WomenCareTestimonialsComponent,
  },
  //{ path: 'women-care/feedback', component: WomenCareFeedbackComponent },
  //{ path: 'women-care/appointment', component: WomenCareAppointmentComponent },
  { path: 'child-care/about', component: ChildCareAboutComponent },
  //{ path: 'child-care/doctors', component: ChildCareDoctorsComponent },
  {
    path: 'child-care/specialities',
    component: ChildCareSpecialitiesComponent,
  },
  {
    path: 'our-speciality-details/:speciality',
    component: OurSpecialityDetailsComponent,
  },
  {
    path: ':category/speciality/:speciality',
    component: OurSpecialityDetailsComponent,
  },
  {
    path: ':category/treatment/:speciality',
    component: OurSpecialityDetailsComponent,
  },
  //{ path: 'child-care/centres', component: ChildCareCentersComponent },
  { path: 'child-care/about-center', component: ChildCareAboutCenterComponent },
  { path: 'child-care/vaccination', component: ChildCareVaccinationComponent },
  {
    path: 'child-care/testimonials',
    component: ChildCareTestimonialsComponent,
  },
  { path: 'child-care/child-safety', component: ChildSafetyComponent },
  { path: 'our-centre/:id', component: OurCenterDetailsComponent },
  { path: 'our-center/:id', component: OurCenterNewComponent },
  { path: 'our-center/:id/:name', component: OurCenterDetaillistComponent },
  //{ path: 'child-care/feedback', component: ChildCareFeedbackComponent },
  //{ path: 'child-care/appointment', component: ChildCareAppointmentComponent },
  //{ path: 'our-child-care-specialities', component: OurChildCareSpecialitiesComponent },
  //{ path: ':doctorslug', component: DoctorDetailsComponent },

  {
    path: ':section/doctor/dr-ramesh-kancharla',
    component: MdProfileComponent,
  },
  { path: ':section/doctor/:doctorslug', component: DoctorDetailsComponent },
  {
    path: ':section/doctor/:type/:doctorslug',
    component: DoctorDetailsComponent,
  },

  { path: 'about-us', component: AboutUsComponent },
  { path: 'about-us/who-we-are', component: WhoWeAreComponent },
  { path: 'about-us/what-we-do', component: WhatWeDoComponent },
  { path: 'about-us/why-chooseus', component: WhyChooseUsComponent },
  {
    path: 'about-us/cutting-edge-medical-solutions',
    component: CuttingEdgeMedicalSolutionsComponent,
  },
  { path: 'about-us/mds-message', component: MdsMessageComponent },
  { path: 'about-us/management', component: ManagementComponent },
  { path: 'about-us/history', component: HistoryComponent },
  { path: 'about-us/awards', component: AwardsComponent },
  { path: 'about-us/channel', component: ChannelComponent },
  { path: 'about-us/media', component: MediaComponent },
  { path: 'about-us/careers', component: CareersComponent },
  {
    path: 'about-us/careers/careers-apply/:id',
    component: CareersApplyComponent,
  },
  {
    path: 'about-us/careers/careers-join-withus',
    component: CareersJoinWithUsComponent,
  },
  {
    path: 'about-us/careers/careers-join-withus/careers-apply/:id',
    component: CareersApplyComponent,
  },
  {
    path: 'about-us/quality-accreditation',
    component: QualityAccreditationComponent,
  },
  { path: 'about-us/contact-us', component: ContactUsComponent },
  { path: 'lazy', loadChildren: './features/lazy/index#LazyModule' },
  { path: 'sync', loadChildren: './features/sync/index#SyncModule?sync=true' },
  { path: 'other/app', component: OtherAppComponent },
  { path: 'other/faqs', component: OtherFaqComponent },
  { path: 'other/insurance-empanelment', component: OtherInsuranceComponent },
  {
    path: 'other/terms-conditions',
    component: OtherTermsAndConditionsComponent,
  },
  { path: 'other/health-card', component: OtherHealthCardComponent },
  { path: 'other/packages-plans', component: OtherPackagesAndPlansComponent },
  {
    path: 'other/packages-plans/:name',
    component: OtherPackagesAndPlansDetailsComponent,
  },
  { path: 'packages-plans', component: OtherPackagesAndPlansComponent },
  {
    path: 'packages-plans/:name',
    component: OtherPackagesAndPlansDetailsComponent,
  },
  { path: 'other/inpatient-feedback', component: InpatientFeedbackComponent },
  {
    path: 'other/international-patients',
    component: OtherInternationalPatientsComponent,
  },
  { path: 'other/knowledge-centre', component: OtherknowledgeCentreComponent },
  {
    path: 'other/knowledge-centre/:knowledgeslug',
    component: KnowledgeCentreDetailComponent,
  },
  {
    path: 'about-us/media/media-press-releases/press-releases-details/:id',
    component: PressReleasesDetailsComponent,
  },
  {
    path: 'about-us/media/media-press-releases',
    component: MediaPressReleasesComponent,
  },
  {
    path: 'about-us/media/media-press-clips',
    component: MediaPressClipsComponent,
  },
  {
    path: 'about-us/media/media-press-clips/:id',
    component: PressClipDetailsComponent,
  },
  { path: 'about-us/media/press-clips', component: MediaPressClipsComponent },
  { path: 'about-us/news', component: NewsComponent },
  { path: 'about-us/news/news-details/:id', component: NewsDetailsComponent },
  { path: 'about-us/events', component: EventsComponent },
  { path: 'about-us/events/:id', component: EventsDetailsComponent },
  { path: 'other/emergency-service', component: EmergencyServiceComponent },
  { path: 'our-specialities', component: OurSpecialitiesComponent },
  { path: 'bread-crumb', component: BreadCrumbComponent },
  { path: 'other/academic-programs-old', component: AcademicPrograms },
  {
    path: 'other/health-card/register',
    component: HealthCardRegisterComponent,
  },
  { path: ':section/centres', component: OurCenterComponent },
  { path: ':section/centre/:id', component: OurCenterDetailComponent },
  { path: 'other/quiz', component: QuizPage },
  { path: ':section/doctors', component: OurDoctorComponent },
  {
    path: ':section/visiting-consultant',
    component: OurDoctorConsultantComponent,
  },
  { path: ':section/our-centre-map', component: OurCenterMapComponent },
  { path: ':section/book-an-appointment', component: BookanAppointment },
  {
    path: ':section/book-an-appointment/:doctorslug',
    component: BookanAppointment,
  },
  {
    path: ':section/book-an-appointment/:doctorslug/:centreid',
    component: BookanAppointment,
  },
  {
    path: ':section/:type/book-an-appointment/:doctorslug',
    component: BookanAppointment,
  },
  {
    path: ':section/:type/book-an-appointment/:doctorslug/:centreid',
    component: BookanAppointment,
  },
  { path: 'other/bmi-calculator', component: BmiComponent },
  { path: 'other/contraction-calculator', component: ContractionComponent },
  { path: 'other/kick-counter-calculator', component: KickcounterComponent },
  { path: 'other/ovulation-calculator', component: OvulationComponent },
  {
    path: ':section/doctor/dr-ramesh-kancharla',
    component: MdProfileComponent,
  },
  { path: 'md-profile', component: MdProfileComponent },
  {
    path: ':city/:category/speciality/:speciality',
    component: CenterSpecialitiesComponent,
  },
  {
    path: 'our-centre/:city/:category/speciality/:speciality',
    component: CitySpecialitiesComponent,
  },
  { path: 'sitemap', component: SiteMapComponent },
  { path: 'search', component: SearchComponent },
  { path: 'all/testimonials', component: OurTestimonialsComponent },
  { path: ':section/feedback', component: FeedbackComponent },
  {
    path: ':section/feedback/:doctorslug',
    component: FeedbackComponent,
    pathMatch: 'full',
  },
  {
    path: ':section/:type/feedback/:doctorslug',
    component: FeedbackComponent,
    pathMatch: 'full',
  },
  // { path: 'our-center-revised', component: OurCenterRevisedComponent },

  {
    path: ':section/women-care/speciality/obstetrics-and-gynecology/thankyou',
    component: GetInTouchThankyouComponent,
  },
  {
    path: ':section/:category/speciality/:speciality/thankyou',
    component: GetInTouchThankyouComponent,
  },
  {
    path: 'fertility-care/treatments/thankyou',
    component: GetInTouchThankyouComponent,
  },
  {
    path: 'our-centre/:city/:category/speciality/:speciality/thankyou',
    component: GetInTouchThankyouComponent,
  },
  {
    path: 'our-centre-details/:city/thankyou',
    component: GetInTouchThankyouComponent,
  },
  {
    path: 'fertility-care/treatment/:speciality/thankyou',
    component: GetInTouchThankyouComponent,
  },
  {
    path: 'women-care/speciality/:speciality/thankyou',
    component: GetInTouchThankyouComponent,
  },
  {
    path: 'women-care/speciality/:speciality/thankyou',
    component: GetInTouchThankyouComponent,
  },
  { path: 'our-centre/:city/thankyou', component: GetInTouchThankyouComponent },

  { path: 'other/newsletters', component: NewsletterComponent },
  {
    path: 'other/neonatal-support-group',
    component: NeonatalSupportGroupComponent,
  },
  {
    path: 'other/neonatal-support-group/before-birth',
    component: BeforeBirthComponent,
  },
  {
    path: 'other/neonatal-support-group/in-hospital',
    component: InHospitalComponent,
  },
  {
    path: 'other/neonatal-support-group/going-home',
    component: GoingHomeComponent,
  },
  { path: 'other/neonatal-support-group/at-home', component: AtHomeComponent },
  {
    path: 'other/neonatal-support-group/growing-up',
    component: GrowingUpComponent,
  },
  { path: 'insights', component: InsightsComponent },
  {
    path: 'insights/urinary-incontinence',
    component: UrinaryIncontinenceComponent,
  },
  {
    path: 'insights/recurrent-miscarriage',
    component: RecurrentMiscarriageComponent,
  },
  {
    path: 'insights/presmenstrual-syndrome-pms',
    component: PresmenstrualSyndromePMSComponent,
  },
  {
    path: 'insights/preparing-for-a-normal-delivery',
    component: PreparingForANormalDeliveryComponent,
  },
  {
    path: 'insights/fetal-medicine-screenings',
    component: FetalMedicineScreeningsComponent,
  },
  { path: 'insights/cervical-cancer', component: CervicalCancerComponent },
  {
    path: 'insights/caesarean-section-recovery-risks',
    component: CaesareanSectionRecoveryRisksComponent,
  },
  {
    path: 'insights/breast-feeding-advice-for-you',
    component: BreastFeedingAdviceForYouComponent,
  },
  {
    path: 'insights/advantages-of-breastfeeding',
    component: AdvantagesOfBreastfeedingComponent,
  },
  {
    path: 'insights/recurrent-urinary-tract-infection',
    component: RecurrentUrinaryTractInfectionComponent,
  },
  { path: 'insights/pregnancy', component: PregnancyComponent },
  {
    path: 'insights/pregnancy/blood-transfusion-during-pregnancy-and-birth',
    component: BloodTransfusionDuringPregnancyAndBirthComponent,
  },
  {
    path: 'insights/pregnancy/frequently-asked-questions-in-pregnancy',
    component: FrequentlyAskedQuestionsInPregnancyComponent,
  },
  {
    path: 'insights/pregnancy/exercise-in-pregnancy',
    component: ExerciseInPregnancyComponent,
  },
  {
    path: 'insights/pregnancy/pregnancy-and-gastrointestinal-system',
    component: PregnancyAndGastrointestinalSystemComponent,
  },
  {
    path: 'insights/pregnancy/testing-blood-group-in-pregnancy',
    component: TestingBloodGroupInPregnancyComponent,
  },
  {
    path: 'insights/pregnancy/multiple-pregnancy-twins',
    component: MultiplePregnancyTwinsComponent,
  },
  {
    path: 'insights/pregnancy/testing-blood-group-in-pregnancy-the-rhesus-problem',
    component: TestingBloodGroupInPregnancyTheThesusProblemComponent,
  },
  {
    path: 'other/international-patients/thankyou',
    component: OtherInternationalPatientsThankYouComponent,
  },
  { path: 'about-us/csr', component: CsrComponent },
  { path: 'about-us/csr/:csrslug', component: CsrDetailsComponent },
  { path: 'about-us/bio-medical-waste', component: BmwComponent },
  { path: 'appointment-confirmed', component: ConfirmedComponent },
  { path: 'appointment-cancel', component: CancelComponent },
  { path: 'other/appointment-confirmed', component: ConfirmedComponent },
  { path: 'other/appointment-cancel', component: CancelComponent },
  { path: 'all/appointment-confirmed', component: ConfirmedComponent },
  { path: 'all/appointment-cancel', component: CancelComponent },
  { path: 'other/cancel', component: CancelComponent },
  { path: ':centre/:city/:doctorslug', component: DoctorDetailsComponent },
  {
    path: ':category/speciality/:speciality/:doctorslug',
    component: DoctorDetailsComponent,
  },
  { path: 'other/academic-webinar', component: AcademicWebinarComponent },
  { path: 'other/academic-article', component: AcademicArticlesComponent },
  { path: 'other/academic-programs', component: AcademicProgramsComponent },
  {
    path: 'other/academic-article/detail/:slug',
    component: ArticleDetailsComponent,
  },
  { path: 'about-us/video-hub', component: VideoHubComponent },
  { path: 'insights/testpage', component: TestPageComponent },
  // Investor Relations
  {
    path: 'investor-relations',
    component: InvestorRelationsComponent,
  },
  // {
  //   path: 'international-patients',
  //   component: InternationalPatientsComponent,
  // },
  {
    path: 'hello-doctor-online-consultation',
    component: HelloDoctorComponent,
  },
  // {
  //   path: 'conference-on-special-pediatrics',
  //   component: ConferenceOnSpecialPediatricsComponent,
  // },
  {
    path: 'conference-on-special-pediatrics/pay',
    component: ConferencePayComponent,
  },
  {
    path: 'evening-clinic/banjara-hills',
    component: EveningClinicAtBanjaraHillsComponent,
  },
  {
    path: 'conference/success',
    component: CuspSuccessComponent,
  },
  {
    path: 'conference/fail',
    component: CuspFailureComponent,
  },
  { path: '**', component: NotFound404Component },
];
