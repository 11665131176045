import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  ViewChildren,
  QueryList,
} from '@angular/core';
// import { Http } from '@angular/http';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from '../../services/api.service';
import { IMG_BASE_URL } from '../../services/constants';
import { Meta, Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
declare let $: any;
declare let initHome: any;
declare let initAllPage: any;

@Component({
  selector: 'frequently-asked-questions-in-pregnancy',
  templateUrl: './frequently-asked-questions-in-pregnancy.component.html',
  styles: [``],
})
export class FrequentlyAskedQuestionsInPregnancyComponent
  implements OnDestroy, OnInit, AfterViewInit
{
  constructor(
    private api: ApiService,
    private http: HttpClient,
    public metaTitle: Title,
    public meta: Meta
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../../assets/js/bootstrap.js');
      require('../../../assets/js/slick.min.js');
    }
  }

  public services: any[] = [];
  public brief_facilities: any[] = [];
  public title: any = '';
  public subtitle: any = '';
  public description: any = '';
  public imgsrc: any = '';
  public imgBasePath = '';
  public hidden = 'hidden';
  public logo = 'logo';
  public bread_crumb_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'insights', link: '/insights', class: '' },
    { label: 'Pregnancy', link: '/insights/pregnancy', class: '' },
    {
      label: 'Frequently Asked Questions in Pregnancy',
      link: '/insights/pregnancy/frequently-asked-questions-in-pregnancy',
      class: '',
    },
  ];

  public ins_links: any = [];
  public activePage: string = 'frequently-asked-questions-in-pregnancy';

  //@ViewChildren('atabs') atabsThings: QueryList<any>;

  ngOnInit() {
    this.api
      .getPageDescription('frequently-asked-questions-in-pregnancy')
      .subscribe(
        (data) => {
          (this.title = data.page_descriptions[0].title),
            (this.subtitle = data.page_descriptions[0].subtitle),
            (this.description = data.page_descriptions[0].description),
            (this.imgsrc = data.page_descriptions[0].image),
            (this.imgBasePath = IMG_BASE_URL);
          if (data) {
            //console.log(data);
            this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
            this.meta.updateTag({
              name: 'keywords',
              content: data.page_descriptions[0].meta_keyword,
            });
            this.meta.updateTag({
              name: 'description',
              content: data.page_descriptions[0].meta_description,
            });
            this.meta.addTags([
              {
                property: 'og:title',
                content: data.page_descriptions[0].meta_title,
              },
              {
                property: 'og:description',
                content: data.page_descriptions[0].meta_description,
              },
              {
                property: 'og:keywords',
                content: data.page_descriptions[0].meta_keyword,
              },
            ]);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    this.http
      .get('../../../assets/json/pregnancy_sub_nav.json')
      .map((res) => res)
      .subscribe((data) => {
        this.ins_links = data;
      });
  }

  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
    }
  }

  ngOnDestroy() {}
}
