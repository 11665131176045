import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL } from '../services/constants';
import { Meta, Title } from '@angular/platform-browser';
declare let $: any;
declare let initHome: any;
declare let initAllPage: any;

@Component({
  selector: 'testimonials',
  templateUrl: './testimonials.component.html',
})
export class WomenCareTestimonialsComponent
  implements OnDestroy, OnInit, AfterViewInit
{
  constructor(
    private api: ApiService,
    public metaTitle: Title,
    public meta: Meta
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
      require('../../assets/js/fancybox/jquery.fancybox.min.js');
    }
  }

  public features: any[] = [];
  public title: any = '';
  public subtitle: any = '';
  public description: any = '';
  public imgsrc: any = '';
  public imgBasePath: string = '';
  public hidden: string = 'hidden';
  public logo = 'birth-right-logo';
  public testimonials: any;
  public bread_crumb_links: any = [
    { label: 'Home', link: '/', class: '' },
    { label: 'Women Care', link: '/women-care/about', class: '' },
    { label: 'Testimonials', link: '', class: '' },
  ];

  public links: any = [
    {
      label: "About Women's Care",
      link: '/women-care/about',
      class: 'active',
      slug: 'about',
    },
    {
      label: 'Doctors',
      link: '/women-care/doctors',
      class: '',
      slug: 'doctors',
    },
    {
      label: 'Our Specialities',
      link: '/women-care/specialities',
      class: '',
      slug: 'specialities',
    },
    {
      label: 'Our Centres',
      link: '/women-care/centres',
      class: '',
      slug: 'centres',
    },
    {
      label: 'Testimonials',
      link: '/women-care/testimonials',
      class: '',
      slug: 'testimonials',
    },
    {
      label: 'Share Feedback',
      link: '/women-care/feedback',
      class: '',
      slug: 'feedback',
    },
    {
      label: 'Book An Appointment',
      link: '/women-care/book-an-appointment',
      class: '',
      slug: 'book-an-appointment',
    },
  ];
  public activePage: string = 'testimonials';

  public filterdspecilityList: any = [];
  ngOnInit() {
    this.api.getPageDescription('women-care-testimonials').subscribe(
      (data) => {
        this.title = data.page_descriptions[0].title;
        this.subtitle = data.page_descriptions[0].subtitle;
        this.description = data.page_descriptions[0].description;
        this.imgsrc = data.page_descriptions[0].image;
        this.imgBasePath = IMG_BASE_URL;
        if (data) {
          //console.log(data);
          this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
          this.meta.updateTag({
            name: 'keywords',
            content: data.page_descriptions[0].meta_keyword,
          });
          this.meta.updateTag({
            name: 'description',
            content: data.page_descriptions[0].meta_description,
          });
          this.meta.addTags([
            {
              property: 'og:title',
              content: data.page_descriptions[0].meta_title,
            },
            {
              property: 'og:description',
              content: data.page_descriptions[0].meta_description,
            },
            {
              property: 'og:keywords',
              content: data.page_descriptions[0].meta_keyword,
            },
          ]);
        }
      },
      (error) => {
        console.log(error);
      }
    );
    this.api
      .getTestimonialsDetail()
      .map((res) => res)
      .subscribe(
        (res) => {
          this.testimonials = res
            .filter((data) => {
              return data.key == 'women-health';
            })
            .map((res) => {
              return res.details;
            });
          this.testimonials.forEach((element) => {
            this.testimonials = element;
          });
          console.log(this.testimonials);
        },
        (err) => {
          console.error(err);
        }
      );
    this.api.getSpecialityFeatures('women-care-testimonials').subscribe(
      (data) => {
        this.features = data.our_speciality_features;
      },
      (error) => {
        console.log(error);
      }
    );
    this.getSpeciality();
  }
  removeDuplicates(originalArray, objKey) {
    var trimmedArray = [];
    var values = [];
    var value;

    for (var i = 0; i < originalArray.length; i++) {
      value = originalArray[i][objKey];

      if (values.indexOf(value) === -1) {
        trimmedArray.push(originalArray[i]);
        values.push(value);
      }
    }

    return trimmedArray;
  }

  getCenterList(id) {
    this.api.getOurDoctorList(id).subscribe((res) => {
      //this.doctors = res.speciality_master;
      //res.speciality_master

      let specilityList = [];
      res.speciality_master.centers.map((res) => {
        res.speciality_features.map((t) => {
          specilityList.push({ id: t.id, slug: t.slug, title: t.title });
        });
      });
      specilityList.filter(function (elem, pos) {
        return specilityList.indexOf(elem) == specilityList[pos];
      });
      this.filterdspecilityList = this.removeDuplicates(specilityList, 'id');
      //	console.log('filterdspecilityList',filterdspecilityList);
    });
  }
  getSpeciality() {
    this.api
      .getSpecialityMasters()
      .map((res) => res)
      .subscribe((data) => {
        //console.log(data);
        let fertilityData = data.speciality_masters.filter((filterdata) => {
          if (filterdata.slug == 'women-care') {
            return filterdata;
          }
        });
        this.getCenterList(fertilityData[0].id);
        //console.log(fertilityData[0].id);
        this.api
          .getDoctorListBySpecilityMaster(fertilityData[0].id, 0, 0, 0)
          .subscribe((res) => {
            //console.log(res);
          });

        //console.log(this.speciality_master);
        //console.log(this.activePage);
      });
  }
  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
    }
  }

  ngOnDestroy() {}
}
