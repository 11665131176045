import { Inject, Component, OnDestroy, OnInit, AfterViewInit, PLATFORM_ID, ChangeDetectorRef, ViewChildren,HostListener } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
// import { ValidatorFn } from '@angular/forms/src/directives/validators';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from "../services/api.service";
import { IMG_BASE_URL } from "../services/constants";
import { Meta, Title } from '@angular/platform-browser';
declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
declare let initAboutTabs: any;
//declare let initVaccination: any;
//declare let initVaccinationCon: any;
@Component({
	selector: 'vaccination',
	templateUrl: './vaccination.component.html',
	styles: [`
	.alertMsg{
		color:red !important;
	}`]
})

export class ChildCareVaccinationComponent implements OnDestroy, OnInit, AfterViewInit {


	constructor(private api: ApiService, public ref: ChangeDetectorRef, public fb: FormBuilder, public metaTitle: Title, public meta: Meta) {
		if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
      require('../../assets/js/datepickr.min.js');
    }
	}
	@ViewChildren('watchvaccination') things: any;
	@ViewChildren('watchvaccinationCon') thingsCon: any;
	public events: any[] = [];
	public hidden: string = "hidden";
	public title: any = "";
	public subtitle: any = "";
	public description: any = "";
	public imgsrc: any = "";
	public imgBasePath = "";
	public vaccination_details: any = [];
	public vaccination_contacts: any = [];
	public medical_contact: any = [];
	public information_contact: any = [];
	public group_sections: any = [];
	public logo = "rainbow-logo";
	public activeQuestionId: any;
	public applyVaccinateFormGroup: FormGroup;
	public submitted: boolean = false;
	public activeWeek: any;
	public vaccinateAgeWeeksArray: any = [];
	public displayBirthDiff: any = { show: '', append: '', cmpr: '' };
	public bread_crumb_links: any = [{ "label": "Home", "link": "/home", "class": "" },
	{ "label": "Child Care", "link": "/child-care/about", "class": "" },
	{ "label": "Vaccination", "link": "", "class": "" }];

	public links: any = [
		{ "label": "About Child Care", "link": "/child-care/about", "class": "","slug":"about" },
		{ "label": "Child Safety", "link": "/child-care/child-safety", "class": "","slug":"child-safety"  },
	{ "label": "Doctors", "link": "/child-care/doctors", "class": "","slug":"doctors"  },
	{ "label": "Our Specialities", "link": "/child-care/specialities", "class": "","slug":"specialities"  },
	{ "label": "Our Centres", "link": "/child-care/centres", "class": "","slug":"centres"  },
	{ "label": "Vaccination", "link": "/child-care/vaccination", "class": "active","slug":"vaccination"  },
	{ "label": "Testimonials", "link": "/child-care/testimonials", "class": "","slug":"testimonials"  },
	{ "label": "Share Feedback", "link": "/child-care/feedback", "class": "","slug":"feedback"  },
	{ "label": "Book An Appointment", "link": "/child-care/book-an-appointment", "class": "","slug":"book-an-appointment"  }]
	public activePage:string = 'vaccination';


	ngOnInit() {
		this.applyVaccinateFormGroup = this.fb.group({
			txtNOB: ["", Validators.required],
			txtDOB: ["", Validators.required]
		});
		this.api.getEvents()
			.subscribe(
			data => {
				this.events = data.home_events;
			},
			error => {
				console.log(error);
			}
			)


		this.api.getPageDescription('vaccination')
			.subscribe(
			data => {
				this.title = data.page_descriptions[0].title;
				this.subtitle = data.page_descriptions[0].subtitle;
				this.description = data.page_descriptions[0].description;
				this.imgsrc = data.page_descriptions[0].image;
				this.imgBasePath = IMG_BASE_URL;
				if(data){
					//console.log(data);
					this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
					this.meta.updateTag({name:'keywords',content: data.page_descriptions[0].meta_keyword});
					this.meta.updateTag({name:'description',content: data.page_descriptions[0].meta_description});
					this.meta.addTags([
					  { property: 'og:title', content:  data.page_descriptions[0].meta_title},
					  { property: 'og:description', content: data.page_descriptions[0].meta_description },
					  { property: 'og:keywords', content: data.page_descriptions[0].meta_keyword }
					]);
				  }
			},
			error => {
				console.log(error);
			}
			)

		this.api.getvaccination_details()
			.subscribe(
			data => {
				// console.log('vaccination_details:',data);
				this.vaccination_details = data.vaccination_details;
			},
			error => {
				console.log(error);
			}
			)

		this.api.getFaqSection('child-care-vaccination')
			.subscribe(
			data => {
				this.group_sections = data.group_sections
			},
			error => {
				console.log(error);
			}
			)

		this.api.getvaccination_contacts()
			.subscribe(
			data => {
				this.vaccination_contacts = data.vaccination_contacts.map(res => {
					if (res.type == "medical information") {
						this.medical_contact.push(res);
					} else {
						this.information_contact.push(res);
					}
				});


				// for (let contact in this.vaccination_contacts) {
				//   console.log(this.vaccination_contacts[contact]);
				//   if(this.vaccination_contacts[contact]["type"] == ""){

				//   }
				//   else {
				//     this.information_contact.push(this.vaccination_contacts[contact]);
				//   }
				// }

				// console.log(this.information_contact);
				// console.log(this.medical_contact);
			},
			error => {
				console.log(error);
			}
			)



	}
	jqueryLoad(){
		$(".ourcenterlist").removeClass("slick-initialized").removeClass("slick-slider");
		$('.vimslider').not('.slick-initialized').slick({
			slide: 'li',
			slidesToShow: 9,
			slidesToScroll: 1,
			arrows: true,
			prevArrow: '<a class="slick-prev"><img src="assets/images/prv-arrow2.png" alt="" /></a>',
			nextArrow: '<a class="slick-next"><img src="assets/images/nxt-arrow2.png" alt="" /></a>',
			asNavFor: '.vimslidercon',
			dots: false,
			centerMode: true,
			centerPadding: 20,
			focusOnSelect: true,
			infinite: true,
			responsive: [
				{
					breakpoint: 1900, settings: {
						slidesToShow: 5, slidesToScroll: 1
					}
				},
				{
					breakpoint: 1024, settings: {
						slidesToShow: 5, slidesToScroll: 1
					}
				},
				{
					breakpoint: 768, settings: {
						slidesToShow: 3, slidesToScroll: 1
					}
				},
				{
					breakpoint: 480, settings: {
						slidesToShow: 3, slidesToScroll: 1, centerPadding: 2,
					}
				},
				{
					breakpoint: 400, settings: {
						slidesToShow: 1, slidesToScroll: 1, centerPadding: 2,
					}
				}
			]
		});
		$('.vimslidercon').not('.slick-initialized').slick({
			slide: 'li',
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			fade: true,
			asNavFor: '.vimslider',
			infinite: true,
			adaptiveHeight: true,
		});
		
	}
	ngAfterViewInit() {

		if (this.api.isBrowser && window['jQuery']) {
			initHome();
			initAllPage();
			setTimeout(()=>this.jqueryLoad(),1000);
		}
	}
	// @HostListener("window:load", [])
	// onWindowLoad() {
		
	// }
	public activeQuestion(que: any) {
		this.activeQuestionId = que.id;
		this.ref.markForCheck();
		this.ref.detectChanges();
	}

	public vaccinateFormApply(event: Event, form: FormGroup): void {
		this.submitted = true;
		if (form.valid && form.dirty) {
			this.vaccinateAgeWeeksArray = [];
			// console.log(form, form.valid, form.dirty);
			// console.log(form.controls.txtNOB.value);
			// console.log(form.controls.txtDOB.value);

			let today = new Date();
			let dd: any = today.getDate();
			let mm: any = today.getMonth() + 1; //January is 0!

			var yyyy = today.getFullYear();
			if (dd < 10) {
				dd = '0' + dd;
			}
			if (mm < 10) {
				mm = '0' + mm;
			}
			var todays = dd + '/' + mm + '/' + yyyy;
			// console.log(todays);
			this.monthDiff(this.applyVaccinateFormGroup.controls['txtDOB'].value, todays);
			for (let i: number = 0; i < this.vaccination_details.length; i++) {
				// console.log('Calculate weeks', this.activeWeek);
				// console.log('age_in_weeks ', this.vaccination_details[i].age_in_weeks);
				this.vaccinateAgeWeeksArray.push(this.vaccination_details[i].age_in_weeks);
				// console.log('vaccinateAgeWeeksArray', this.vaccinateAgeWeeksArray);
			}
			if (this.vaccination_details.length == this.vaccinateAgeWeeksArray.length) {
				// console.log('activeWeek', this.activeWeek);
				// console.log('vaccinateAgeWeeksArray ', this.vaccinateAgeWeeksArray);

				let closest = this.closestNumber(this.activeWeek, this.vaccinateAgeWeeksArray);
				// console.log(closest);
				
				var artIndex = $('[data-value=' + closest + ']').data('slick-index');
				// console.log('artIndex', artIndex);



				$('.slider').slick('slickGoTo', this.vaccination_details.length + artIndex);
			}
		}
	}

	closestNumber(num, arr) {
		var curr = arr[0];
		var diff = Math.abs(num - curr);
		for (var val = 0; val < arr.length; val++) {
			var newdiff = Math.abs(num - arr[val]);
			if (newdiff < diff) {
				diff = newdiff;
				curr = arr[val];
			}
		}
		return (num > 625 ? -1 : curr);
		
	}

	monthDiff(d1, d2) {
		// console.log('Calc Diff', d1, d2);
		var x = d1.split("/");
		var y = d2.split("/");
		var bdays = x[0];
		var bmonths = x[1];
		var byear = x[2];

		var sdays = y[0];
		var smonths = y[1];
		var syear = y[2];

		if (sdays < bdays) {
			sdays = parseInt(sdays) + 30;
			smonths = parseInt(smonths) - 1;

			var fdays = sdays - bdays;

		}
		else {
			var fdays = sdays - bdays;
		}
		if (smonths < bmonths) {
			smonths = parseInt(smonths) + 12;
			syear = syear - 1;
			var fmonths = smonths - bmonths;
		}
		else {
			var fmonths = smonths - bmonths;
		}
		var fyear = syear - byear;
		var fweek = Math.floor(fdays / 7);
		// alert(fyear + ' years ' + fmonths + ' months ' + fdays + ' days ' + fweek + ' fweek');

		let obj: any;
		if (fyear > 0) {
			this.activeWeek = Math.floor(((((fyear * 12) + fmonths) * 30) + fdays) / 7);
			obj = { show: this.activeWeek, append: 'weeks', cmpr: 'approx' };
		} else if (fyear == 0 && fmonths > 0) {
			this.activeWeek = Math.floor(((fmonths * 30) + fdays) / 7);
			obj = { show: this.activeWeek, append: 'weeks', cmpr: 'approx' };
		} else if (fyear == 0 && fmonths == 0 && fweek > 0) {
			this.activeWeek = fweek;
			obj = { show: this.activeWeek, append: 'weeks', cmpr: 'approx' };
		} else {
			this.activeWeek = 1;
			obj = { show: this.activeWeek, append: 'weeks', cmpr: 'less than' };
		}
		this.displayBirthDiff = obj;
		// console.log( this.displayBirthDiff);


	}

	resetForm() {
		this.applyVaccinateFormGroup.reset();
	}

	readDate() {
		// console.log(document.getElementById('dOB')['value']);
		(<any>this.applyVaccinateFormGroup.controls['txtDOB']).setValue(document.getElementById('dOB')['value']);
	}

	ngOnDestroy() { }

}
