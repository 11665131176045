
import {Inject, Component, OnDestroy, OnInit, AfterViewInit, PLATFORM_ID, ViewChildren, QueryList } from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {ApiService} from "../services/api.service";
import {IMG_BASE_URL} from "../services/constants";
import { ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

import { FormBuilder, FormGroup, Validators,FormControl,FormArray } from '@angular/forms';


declare let $:any;
declare let initHome:any;
declare let initAllPage:any;
declare let initPopup:any;
declare let initAboutTabs:any;
declare let initRemoveClass:any;

@Component({
  selector: 'health-card-register',
  templateUrl: './health-card-register.component.html',
  styles:[`.has-error{border:1px solid red !important;}input[disabled]{
		border:1px solid rgba(61, 41, 121, 0.50) !Important;
		background:rgba(61, 41, 121, 0.50) !important;
	}
	.alertMsg{
		color:red !important;
	}`]
})

export class HealthCardRegisterComponent implements OnDestroy, OnInit {
	/* Declare variable*/
	public services:any[] = [];
	public brief_facilities:any[] = [];
	public title:any = "";
	public subtitle:any = "";
	public description:any = "";
	public imgsrc:any = "";
	public imgBasePath = "";
	public hidden = "hidden";
	public currentSection:string = '';
	public doctorList:any = [];
	public doctorDetailsViewStatus:boolean = true;
	public logo="logo";
	//doctor detail
	public selectedDoctorName:string = '';
	public selectedDoctorImg:string ='';
	public selectedDoctorInfo:string='';
	public selectedDoctorDesignation:string = '';
	//doctor
	public mostHappyList:any[] = [];
	public cityList:any[] = [];
	public SpouseList:any[] = [];
	public GenderList:any[] = [];
	//feedbackform
	inpatientFeedbackForm:any;
	public bread_crumb_links:any = [{"label":"Home", "link": "/", "class": ""},
                                 {"label":"Others", "link": "", "class": ""},
                                 {"label":"Health Card", "link": "", "class": ""}];

  	public links:any = [{"label":"Faqs", "link": "/other/faqs", "class": ""},
                    {"label":"Insurance & Empanelment", "link": "/other/insurance-empanelment", "class": ""},
                    {"label":"Terms and Conditions", "link": "/other/terms-conditions", "class": ""},
                    {"label":"Academic Programs", "link":"/other/academic-programs", "class":""},
                    {"label":"Inpatient Feedback", "link": "/other/inpatient-feedback", "class": ""},
                    {"label":"Quiz", "link": "/other/quiz", "class": ""},
                    {"label":"Packages/ Plans", "link": "/other/packages-plans", "class": "active"},
                    {"label":"International Patients", "link": "/other/international-patients", "class": ""},
                    {"label":"App", "link": "/other/app", "class": ""}];
					
	constructor(private api:ApiService, private route:ActivatedRoute,public formBuilder:FormBuilder, public metaTitle: Title, public meta: Meta ) {
	    if(this.api.isBrowser){
          window['jQuery'] = $;
          window['$'] = $;
          require('../../assets/js/bootstrap.js');
          require('../../assets/js/fancybox/jquery.fancybox.js');
          require('../../assets/js/slick.min.js');
      }
	}

   @ViewChildren('abouttabs') things: QueryList<any>;
		ngOnInit(){
			this.api.getPageDescription('health-card-register')
			.subscribe(
				data =>{
					//console.log(data);
					this.title = data.page_descriptions[0].title;
					this.subtitle = data.page_descriptions[0].subtitle;
					this.description = data.page_descriptions[0].description;
					this.imgsrc = data.page_descriptions[0].image;
					this.imgBasePath = IMG_BASE_URL;
					if(data){
						//console.log(data);
						this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
						this.meta.updateTag({name:'keywords',content: data.page_descriptions[0].meta_keyword});
						this.meta.updateTag({name:'description',content: data.page_descriptions[0].meta_description});
						this.meta.addTags([
						  { property: 'og:title', content:  data.page_descriptions[0].meta_title},
						  { property: 'og:description', content: data.page_descriptions[0].meta_description },
						  { property: 'og:keywords', content: data.page_descriptions[0].meta_keyword }
						]);
					  }
				},
				error => {
					console.log(error);
				}
			)
			this.cityList = [
				{
					name:'Bangalore'
				},{
					name:'Hyderabad'
				},{
					name:'Vijayawada'
				}
			];
			
			this.SpouseList = [
				{
					name:'Select Spouse'
				},
				{
					name:'Child1'
				},{
					name:'Child2'
				},{
					name:'Child3'
				},{
					name:'Child4'
				},{
					name:'Child5'
				}
			];
			this.GenderList = [
				{
					name:'Select Gender'
				},
				{
					name:'Male'
				},{
					name:'Female'
				}
			]
			this.inpatientFeedbackForm = this.formBuilder.group({
					'city':['',Validators.required],
					'health_card_no':['', Validators.required],
					'uhid_no':[''],
					'name':['',[Validators.required, Validators.pattern(".*\\S.*")]],
					'age':['',Validators.compose([Validators.required,Validators.minLength(1),Validators.maxLength(3)])],
					'mobile':['',Validators.compose([Validators.required,Validators.minLength(10)])],
					'email':['',Validators.compose([Validators.required])],
					'company':['',[Validators.required, Validators.pattern(".*\\S.*")]],
					'area':[''],
					'zip':['',Validators.compose([Validators.minLength(6)])],
					'category':[''],
					'userDetails':this.formBuilder.array([
						this.initUserDetails(),
				])
			});
	}
	initUserDetails() {
			return this.formBuilder.group({
					family_member_spouse:[''],
					family_member_name: [''],
					family_member_age: [''],
					family_member_gender:[''],
					family_member_uhid:[''],
			});
	}

	addUserDetail() {
			const control = <FormArray>this.inpatientFeedbackForm.controls['userDetails'];
			control.push(this.initUserDetails());
	}
	removeUserDetail(i: number) {
			const control = <FormArray>this.inpatientFeedbackForm.controls['userDetails'];
			control.removeAt(i);
	}

	isNumber(evt) {
		evt = (evt) ? evt : window.event;
		var charCode = (evt.which) ? evt.which : evt.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}
	submitUserDetails(){
		if(this.inpatientFeedbackForm.value){
			//api for post data
			this.api.postInpatientFeedback(this.inpatientFeedbackForm.value).subscribe(res =>{
				if(res.status_code == 200){
					initPopup(127);
					this.inpatientFeedbackForm.reset();
					initRemoveClass();
				}
			});
		}
	}

	ngAfterViewInit() {

		if ( this.api.isBrowser && window['jQuery'] ) {
			initHome();
			initAllPage();
			initRemoveClass();
		}

	}
	ngOnDestroy() {

	}
}
