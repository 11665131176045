<div class="container-fluid">
    <div class="background">
        <img src="assets/images/bg-press-clip.png" data-t="bg_01_00.jpg" data-d="bg-press-clip.png" class="bgImg bg0 active" />
    </div>
    <div class="loder"></div>
    <sidenav></sidenav>

    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <!--<bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>-->
    <section class="pagebcwrp">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-sm-12 col-lg-12">
              <ul>
                <li *ngFor="let link of bread_crumb_links" class="{{link.class}}">
                  <a *ngIf="link.link" href="{{link.link}}">{{link.label}}</a>
                  <span *ngIf="!link.link">{{link.label}}</span>
                </li>
                <li >{{ptitle | slice:0:32}}<span *ngIf="ptitle.length > 32">...</span></li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    <section class="page mediaSection pageheader">
        <div class="row">
            <div class="container">
                <div class="col-xs-12 col-sm-7 col-lg-7 abannimgwrp">
                    <div class="aboutbannimg"><img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" /></div>
                </div>
                <div class="col-xs-12 col-sm-5 col-lg-5">
                    <div class="ptitle yellow">
                        <h2>{{title}}</h2>
                        <h3>{{subtitle}}</h3>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="container-fluid aboutbod">
        <div class="row">
            <div class="container">
                <div class="col-xs-12 col-sm-12 col-lg-12">
                    <div class="prelshead">
                        <div class="prdate">
                            <h3>{{pressDetail.date | amDateFormat : "MMMM"}}</h3>
                            <h2 class="gradient">{{pressDetail.date | amDateFormat : "DD"}}</h2>
                            <h4>{{pressDetail.date | amDateFormat : "YYYY"}}</h4>
                        </div>
                        <div class="prtitle">
                            <h1>{{pressDetail.meta_title}}</h1>
                            <h3>{{pressDetail.author}} <br><span>({{pressDetail.designation}})</span></h3>
                            <p> <span class="style1">{{pressDetail.publish_in}}</span> <br>
                                <span class="date">{{pressDetail.date | amDateFormat: "dddd, DD MMMM YYYY"}} </span>
                            </p>
                        </div>
                    </div>                    

                    <div class="prelscontent">
                        <div [innerHTML]="pressDetail.description"></div>
                        <!--<p [innerHTML]="pressDetail.designation"></p>-->
                        
                        <div *ngIf="videoUrl" class="iframeVideoWrp">
                            <iframe [src]='safeURL' frameborder="0" allowfullscreen class="responsive_iframe"></iframe>
                        </div>
                        <div *ngIf="pressDetail.image != ''" class="pressclipimgWrp">
                            <img src="{{imgBasePath}}{{pressDetail.image}}" class="img-responsive" alt="" />
                        </div>
                        <div class="shareButtonWrp" style="text-align: left;">
                            <div id="shareButton" class="need-share-button-default need-share-button" data-share-icon-style="box" data-share-networks="Facebook,Twitter,GooglePlus,Linkedin,Mailto" data-share-position="middleRight"></div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </section>
    <footer-nav-mini [links]="links"></footer-nav-mini>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>