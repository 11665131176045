<div class="container-fluid">
    <div class="loder"></div>
    <sidenav></sidenav>


    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <!-- <bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb> -->
    <section class="pagebcwrp">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-lg-12">
                    <ul class="breadnav">
                        <li><a href="javascript:void()">Home</a></li>
                        <li><a href="our-centre">Our-Centre</a></li>
                        <li><a href="our-centre/{{city}}">{{city}}</a></li>
                        <li><a href="{{category}}/about">{{replaceString(category)}}</a></li>
                        <li><a href="{{category}}/specialities">Our Specialities</a></li>
                        <li>
                            {{title}}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <section class="page wtwedo">
        <div class="row">
            <div class="col-md-5 col-xs-5 col-lg-4">
                <div class="circuleframe"> <img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" /> </div>
                <!-- End Circle -->
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7">
                <h4 _ngcontent-c1="" class="bookAppPgBgAnim" *ngIf="highlightText != null">{{highlightText}}</h4>
                <div class="pagetitle" style="padding-top: 10px;">
                    <h1 class="tgradient">{{title}}</h1>
                    <div class="underline"> <img src="assets/images/underline2.png" alt="" /> </div>
                    <h3>{{subtitle}}</h3>
                </div>
            </div>
            <div class="col-md-7 col-xs-7 col-lg-7 mcontentview">
                <p [innerHTML]="description"></p>
            </div>
        </div>
    </section>
    <section class="container-fluid ospesltyaccord">
        <div class="whitecolor-sec">
            <div class="container">
                <div class="col-xs-12 col-lg-12">
                    <div class="GenraelPediatrics-aco sel">About Service</div>
                    <div class="GenraelPediatrics-Infotext">
                        <div class="row">
                            <ul class="about-service" class="col-xs-12" style="list-style:none;">
                                <li class="col-xs-12 col-sm-12" *ngFor="let facility of facilities; let i = index">
                                    <div class="col-xs-12 col-md-6" *ngFor="let facilityData of facility; let i = index">
                                        <div *ngIf="facility.length == 2">
                                            <div class="icon-wapper"> <img src="{{imgBasePath}}{{facilityData.image}}"></div>
                                            <div class="icon-wapper-text" [innerHTML]="facilityData.description"></div>
                                        </div>
                                    </div>
                                </li>
                                <li class="col-xs-12 " *ngFor="let facility of facilities">
                                    <div class="col-xs-12" *ngFor="let facilityData of facility">
                                        <div *ngIf="facility.length == 1">
                                            <div class="icon-wapper"> <img src="{{imgBasePath}}{{facilityData.image}}"></div>
                                            <div class="icon-wapper-text" [innerHTML]="facilityData.description"></div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="whitecolor-sec" *ngIf="question.length > 0">
            <div class="container">
                <div class="col-xs-12 col-lg-12">
                    <div class="GenraelPediatrics-aco">patient information</div>
                    <div class="GenraelPediatrics-Infotext" data-class="GenraelPediatrics-Infotext">
                        <div class="row">
                            <div class="row aco-wapper">
                                <div *ngFor="let questions of question; let i = index;">
                                    <div data-class="DoctorDesk-aco" class="DoctorDesk-aco" [ngClass]="i == 0 ? 'sel':'test'">{{questions.question}}</div>
                                    <div class="infotext" [innerHTML]="questions.answer"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="whitecolor-sec" *ngIf="DoctorNameByCity.length > 0 ">
            <div class="container">
                <div class="col-xs-12 col-lg-12">
                    <div class="GenraelPediatrics-aco" (click)="EqualCellHeight()">Doctors</div>
                    <div class="GenraelPediatrics-Infotext">
                        <div class="childdoctor-result ">
                            <ul>
                                <li class="col-xs-12 col-sm-6 col-md-4" *ngFor="let doctor of DoctorNameByCity">
                                    <div #doctorlist class="doctorsListwrp" *ngIf="doctor.doctor_uid">
                                        <div class="dthumbwrp"> <img src="{{imgBasePath}}{{doctor.photo}}" alt="" /> </div>
                                        <div class="ddetailswrp">
                                            <h3>{{doctor.name}}</h3>
                                            <p> {{doctor.qualification}},{{doctor.designation}} </p>
                                        </div>
                                        <div class="dunderline"> <img src="assets/images/underline.png" alt="" /> </div>
                                        <div class="dselectlwrp">
                                            <!-- <div class="locationwrp">
												<div class="selectbox">
													<select class="select" name="txtDLocation">
														<option value="Select location" selected>Select location </option>
														<option *ngFor="let center of doctor.centers" value="{{center.id}}">{{center.center_name_alias}}</option>
                      								</select>
													<span id="selectDlocation" class="select-span"></span> </div>
											</div>
											<p>Rainbow Children’s Hospital</p> -->
                                        </div>
                                        <!-- <a href="{{category}}/doctor/{{doctor.slug}}" class="knowmore2 active"><span>Know More</span></a>										
										<a href="{{category}}/book-an-appointment/{{doctor.slug}}" class="btn-Bookapopoi"><span>Book an appointment</span></a> -->
                                        <a href="/all/doctor/{{doctor.slug}}" class="knowmore2 active"><span>Know More</span></a>
                                        <a href="/all/book-an-appointment/{{doctor.slug}}" class="btn-Bookapopoi"><span>Book an appointment</span></a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="whitecolor-sec" *ngIf="centerList.length > 0">
            <div class="container">
                <div class="col-xs-12 col-lg-12">
                    <div class="GenraelPediatrics-aco">Centers</div>
                    <div class="GenraelPediatrics-Infotext">

                        <div class="row about-dr" *ngFor="let center of centerList">
                            <div class="col-xs-12 col-sm-6 col-md-6 address-wapper ">
                                <h2 class="textname">{{center.center_name}}</h2>
                                <div class="underline"><img src="assets/images/underline.png" width="77" height="3"></div>
                                <p>{{center.address}}</p>
                                <a href="https://www.google.com/maps?saddr=My+Location&daddr={{center.lat_long}}" target="_blank" class="viewmap">View map</a>
                                <a href="{{category}}/book-an-appointment" class="btn-Bookapopoi"><span>Book an Appointment</span></a>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6">

                                <div class="mimgl">
                                    <a *ngIf="center.virtual_tour_link" class="fancybox-effects-a rolink" target="_blank" [href]="center.virtual_tour_link" data-fancybox-group="gallery" title="{{center.name}}">
                                        <img src="assets/images/icon-360.png" data-icon="360icon" alt="" />
                                    </a>
                                    <img *ngIf="center.image" src="{{imgBasePath}}{{center.image}}" alt="{{center.name}}" />
                                    <img *ngIf="!center.image" src="assets/images/mimg.jpg" alt="{{center.name}}">
                                </div>
                            </div>

                        </div>
                        <hr>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <a class="iconFormV" href="javascript:void();" (click)="ShowForm()">ENQUIRE<span>NOW</span></a>
    <footer-nav-mini [links]="links"></footer-nav-mini>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>
</div>

<!-- Start Model Popup  *ngIf="showHide" -->
<div class="capopup" id="ModelFormGetInTouch">
    <div class="capopup-inner">
        <div class="launchpreloader" *ngIf="preloader"></div>
        <a class="capopup-close" href="javascript:void()" (click)="changeShowStatus()"> <img alt="" src="assets/images/icon-close2.png"> </a>
        <h2>GET IN TOUCH WITH US</h2>
        <form id="form" [formGroup]="GetInTouch" (submit)="onSubmit()" novalidate>
            <ul class="formcasn">
                <li>
                    <input type="text" name="name" [ngClass]="{'has-error':!GetInTouch.controls.name.valid && GetInTouch.controls.name.touched}" placeholder="Name*" formControlName="name" required/>
                    <span class="alertMsg" *ngIf="!GetInTouch.controls.name.valid && submited">required</span>
                </li>
                <li>
                    <input type="text" name="mobile" maxlength="10" [ngClass]="{'has-error':!GetInTouch.controls.mobile.valid && GetInTouch.controls.mobile.touched}" placeholder="Mobile*" formControlName="mobile" required/>
                    <span class="alertMsg" *ngIf="!GetInTouch.controls.mobile.valid && submited">required</span>
                </li>
                <li>
                    <input type="text" name="email" [ngClass]="{'has-error':!GetInTouch.controls.email.valid && GetInTouch.controls.email.touched}" placeholder="Email ID*" formControlName="email" required/>
                    <span class="alertMsg" *ngIf="!GetInTouch.controls.email.valid && submited">required</span>
                </li>
                <li class="singledropdown">
                    <div class="selectbox">
                        <select class="select" name="txtLocation" id="txtLocation" [(ngModel)]="countryModel" formControlName="txtLocation">
						<option value="" selected>Location*</option>
						<option *ngFor="let country of CountryName;let i= index;" [value]="country.name">
							{{country.name}}
						</option>
					</select>
                        <span class="select-span"></span>
                    </div>
                    <span class="alertMsg" *ngIf="!GetInTouch.controls.txtLocation.valid && submited">required </span>
                </li>
                <li class="singledropdown">
                    <div class="selectbox">
                        <select class="select" name="interested_in" id="txtinterested_in" [(ngModel)]="interestedinModel" formControlName="interested_in">
						<option value="" selected>I am interested in*</option>
						<option *ngFor="let interest of interestedList;let i= index;" [value]="interest.name">
							{{interest.name}}
						</option>
					</select>
                        <span class="select-span"></span>
                    </div>
                    <span class="alertMsg" *ngIf="!GetInTouch.controls.interested_in.valid && submited">required </span>
                </li>
                <li>
                    <input type="text" name="message" placeholder="Message" formControlName="message" />
                    <input type="hidden" name="CityName" id="CityName" formControlName="CityName" [(ngModel)]="CityName" />
                    <input type="hidden" name="txtSpeciality" id="txtSpeciality" formControlName="txtSpeciality" [(ngModel)]="txtSpeciality" />
                </li>
                <li>
                    <div class="submitbtn">

                        <input class="casubmit" id="txtSubmit" name="txtSubmit" type="submit" value="Submit">
                    </div>
                </li>
            </ul>
        </form>
    </div>
</div>
<!-- End Model Popup -->