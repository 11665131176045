import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TransferHttp } from '../../modules/transfer-http/transfer-http';
// import { Http, RequestOptions, URLSearchParams, Headers } from "@angular/http";

import { API_BASE_URL } from './constants';
import { isPlatformBrowser } from '@angular/common';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

var headers = new HttpHeaders();
headers.append('Content-Type', 'application/x-www-form-urlencoded');

@Injectable()
export class ApiAppointment {
  /*getAppointmentByPhoneNo(doctor_uid: number, nOwnerOrganisationUID: any, loginMobile: any, selectedDate: any, slotStartDate: any, slotEndDate: any, UID: any): any {
		throw new Error("Method not implemented.");
	}*/
  public isBrowser: boolean = isPlatformBrowser(this.platform_id);
  public api_url: string =
    'https://appointment.rainbowhospitals.in:7100/PATIENTPORTALSERVER/PortalService.svc/json/'; //live server
  //public api_url: string = 'http://103.231.212.12/PORTALTESTSERVER/PortalService.svc/json/'; // test server
  public api_GetAppointmentByPhoneNo: string =
    'https://appointment.rainbowhospitals.in:7100/PATIENTPORTALSERVER/PortalService.svc/json/';
  constructor(
    @Inject(PLATFORM_ID) private platform_id,
    private tHttp: TransferHttp,
    private http: HttpClient
  ) {}

  public getDummyCentreSlot(dUid, cUid, sUid) {
    return this.http.get('/assets/sessionjson.json');
  }
  public getDummyDoctorSlot(sessionId, date) {
    return this.http.get('/assets/doctorslot.json');
  }
  public getDoctorSessionID(dUid, cUid, sUid, aType): Observable<any> {
    return this.http
      .get<any>(
        `${this.api_url}PRTGetSessionsByDoctor?nCareproviderUID=${dUid}&nOwnerOrganisationUID=${cUid}&nSpecialityUID=${sUid}&nSessionCount=1&nAppointmentType=${aType}`
      )
      .map((res) => res);
  }
  public getSessionsByDoctor(sID, date) {
    //http://103.231.212.10/PATIENTPORTALSERVER/PortalService.svc/json/PRTGetSlotsBySession?nSessionDefintionUID=560&sAppointmentDttm=07-03-2016
    return this.http.get<any>(
      `${this.api_url}PRTGetSlotsBySession?nSessionDefintionUID=${sID}&sAppointmentDttm=${date}`
    );
  }

  public PortalPatientData(mobileNo) {
    //http://103.231.212.10/PATIENTPORTALSERVER/PortalService.svc/json/PRTGetSlotsBySession?nSessionDefintionUID=560&sAppointmentDttm=07-03-2016
    return this.http.get(
      `${this.api_url}PortalPatientData?sPatientID=&sPatientName=&sMobileNo=${mobileNo}`
    );
  }
  public getFeesByDoctor(lPatientUID, lUid, cUid, oUid, atype) {
    return this.http.get<any>(
      `${this.api_url}PRTFollowUpRuleConsultationCharge?lPatientUID=${lPatientUID}&nLocationUID=${lUid}&nCareproviderUID=${cUid}&nOwnerOrganisationUID=${oUid}&nAppointmentType=${atype}`
    );
    //return this.http.get(`${this.api_url}PRTFollowUpRuleConsultationCharge?nCareproviderUID=${cUid}&nOwnerOrganisationUID=${oUid}&nAppointmentType=${atype}`);
  }

  public AuthenticatePatientLogin(loginName, mobileNumber) {
    //console.log('from service:',loginName,mobileNumber);
    let password = '01011987';
    return this.http.get(
      `${this.api_url}AuthenticatePatientLogin?sPatientID=${loginName}&sPassword=${password}&sMobileNo=${mobileNumber}&nMode=1`
    );
  }

  public verifyAppointmentByPhoneNo(data, umobile) {
    var sdate = data.sSelectedSlot.split(' ');
    var edate = data.sSlotEndTime.split(' ');
    var dtdate = sdate[0].split('-');
    let nCareproviderUID = data.nCareproviderUID;
    let nOwnerOrganisationUID = data.nOwnerOrganisationUID;
    let sMobileNo = umobile;
    let dtAppointmentDttm = dtdate[2] + '-' + dtdate[1] + '-' + dtdate[0];
    let dtSlotStartDttm = sdate[1];
    let dtSlotEndDttm = edate[1];
    let lPatientUID = data.lPatientUID;
    return this.http.get<any>(
      `${this.api_GetAppointmentByPhoneNo}GetAppointmentByPhoneNo?nCareproviderUID=${nCareproviderUID}&nOwnerOrganisationUID=${nOwnerOrganisationUID}&sMobileNo=${sMobileNo}&dtAppointmentDttm=${dtAppointmentDttm}&dtSlotStartDttm=${dtSlotStartDttm}&dtSlotEndDttm=${dtSlotEndDttm}&lPatientUID=${lPatientUID}`
    );
  }

  public newPatientRegister(data) {
    let fName = data.first_name;
    let mName = data.middle_name;
    let lName = data.last_name;
    let dob = data.dob;
    let mNumber = data.mobile_number;
    let email = data.email_address;
    let orgUID = data.org_id;
    let motherName = data.mother_name;
    let refId = data.reference_id;

    return this.http.get<any>(`${this.api_url}RegisterPatient?sForeName=${fName}
&sMiddleName=${mName}
&sLastName=${lName}
&nGender=55632
&dtBirthDttm=${dob}
&sPhoneNo=
&sMobileNo=${mNumber}
&sEmailId=${email}
&nOwnerOrganisationUID=${orgUID}
&sMotherName=${motherName}
&nLoginID=0
&nTITLEUID=0
&nRFRTPUID=${refId}
&nReferredByID=0`);
  }
  public ConfirmBooking(data, atype) {
    let lPatientUID = data.lPatientUID;
    let nOwnerOrganisationUID = data.nOwnerOrganisationUID;
    let nLocationUID = data.nLocationUID;
    let nCareproviderUID = data.nCareproviderUID;
    let nServiceUID = data.nServiceUID;
    let nSessionUID = data.nSessionUID;
    let sSelectedSlot = data.sSelectedSlot;
    let sSlotEndTime = data.sSlotEndTime;
    let sComments = data.sComments;
    //console.log(data);
    return this.http
      .get<any>(`${this.api_url}ConfirmBooking?lPatientUID=${lPatientUID}&nOwnerOrganisationUID=${nOwnerOrganisationUID}
&nLocationUID=${nLocationUID}&nCareproviderUID=${nCareproviderUID}&nServiceUID=${nServiceUID}&nSessionUID=${nSessionUID}&sSelectedSlot=${sSelectedSlot}&sSlotEndTime=${sSlotEndTime}&sComments=${sComments}&nAppointmentType=${atype}&nBookingMode=0`);
  }
}
