import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, PreloadAllModules } from '@angular/router';

import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
// import { useLogMonitor } from '@ngrx/store-log-monitor';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TransferHttpModule } from '../modules/transfer-http/transfer-http.module';

import { routes } from './app.routing';
import { rootReducer } from './reducers';
import { StoreDevToolsModule } from './features/store-devtools.module';
import { UserEffects } from './user/user.effects';

const STORE_DEV_TOOLS_IMPORTS = [];
// if (
//   ENV === 'development' &&
//   !AOT &&
//   ['monitor', 'both'].includes(STORE_DEV_TOOLS) // set in constants.js file in project root
// )
//   STORE_DEV_TOOLS_IMPORTS.push(
//     ...[
//       StoreDevtoolsModule.instrument({
//         maxAge: 25, // Retains last 25 states
//         // logOnly: environment.production, // Restrict extension to log-only mode
//         autoPause: true,
//       }),
//     ]
//   );

export const APP_IMPORTS = [
  EffectsModule.forRoot([UserEffects]),
  NgbModule,
  ReactiveFormsModule,
  RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    // useHash: true,
  }),
  StoreRouterConnectingModule,
  StoreModule.forRoot(rootReducer),
  STORE_DEV_TOOLS_IMPORTS,
  StoreDevToolsModule,
  TransferHttpModule,
];
