import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from '../services/api.service';
import { Meta, Title } from '@angular/platform-browser';
import { IMG_BASE_URL } from '../services/constants';
import { ActivatedRoute } from '@angular/router';

declare let $: any;
declare let initHome: any;
declare let initAllPage: any;

@Component({
  selector: 'out-center-city',
  templateUrl: './our-center-city.component.html',
  styles: [
    `
      ul li a {
        text-transform: capitalize;
      }
      .OurCentertext .cityname li:nth-child(even) {
        width: 45% !important;
      }
      .OurCentertext .cityname li:nth-child(odd) {
        width: 45% !important;
      }
      ul.cityname a {
        color: #000;
      }
    `,
  ],
})
export class OurCenterCityComponent
  implements OnDestroy, OnInit, AfterViewInit
{
  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    public metaTitle: Title,
    public meta: Meta
  ) {
    if (this.api.isBrowser) {
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
    }
  }

  public features: any = [];
  public title: any = '';
  public cities: any = '';
  public pageData: any;
  public subtitle: any = '';
  public description: any = '';
  public imgsrc: any = '';
  public hidden: string = 'hidden';
  public logo = 'logo';
  public imgBasePath = '';
  public currentSection: string = '';
  public activeRoute: any = [];
  public activePage: string = '';
  ngOnInit() {
    this.api.getPageDescription('our-center').subscribe(
      (data) => {
        this.pageData = data.page_descriptions[0];
        //console.log(this.pageData);
        (this.title = data.page_descriptions[0].title),
          (this.subtitle = data.page_descriptions[0].subtitle),
          (this.description = data.page_descriptions[0].description),
          (this.imgsrc = data.page_descriptions[0].image),
          (this.imgBasePath = IMG_BASE_URL);
        if (data) {
          //console.log(data);
          this.metaTitle.setTitle(data.page_descriptions[0].meta_title);
          this.meta.updateTag({
            name: 'keywords',
            content: data.page_descriptions[0].meta_keyword,
          });
          this.meta.updateTag({
            name: 'description',
            content: data.page_descriptions[0].meta_description,
          });
          this.meta.addTags([
            {
              property: 'og:title',
              content: data.page_descriptions[0].meta_title,
            },
            {
              property: 'og:description',
              content: data.page_descriptions[0].meta_description,
            },
            {
              property: 'og:keywords',
              content: data.page_descriptions[0].meta_keyword,
            },
          ]);
        }
      },
      (error) => {
        console.log(error);
      }
    );
    this.route.params.subscribe((params) => {
      this.currentSection = params['section'];
    });
    this.api
      .getSpecialityMasters()
      .map((res) => res)
      .subscribe((data) => {
        //console.log(data);
        this.activeRoute = data.speciality_masters.filter((filterdata) => {
          return filterdata.slug == this.currentSection;
        });
        if (this.activeRoute.length > 0) {
          //console.log('array');
          this.activePage = this.activeRoute[0].speciality;
        } else {
          this.activePage = this.currentSection;
        }
      });
    this.api.getSpecialityFeatures('child-care-centers').subscribe(
      (data) => {
        this.features = data.our_speciality_features;
      },
      (error) => {
        console.log(error);
      }
    );

    this.api.getCenterCity().subscribe((data) => {
      this.cities = data.cities;

      //console.log(this.cities);
    });
  }

  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
    }
  }

  ngOnDestroy() {}
}
