<div class="container-fluid">
    <div class="background">
        <img src="assets/images/childcarebg.png" data-t="bg_01_00.jpg" data-d="childcarebg.png" class="bgImg bg0 active" />
    </div>
    <div class="loder"></div>
    <sidenav></sidenav>
    <header-wrap [hidden]="hidden" [logo]="logo"></header-wrap>
    <!--<bread-crumb [bread_crumb_links]="bread_crumb_links"></bread-crumb>-->
    <section class="pagebcwrp">
        <div class="container">
            <div class="row">
            <div class="col-md-12 col-sm-12 col-lg-12">
                <ul>
                <li *ngFor="let link of bread_crumb_links" class="{{link.class}}">
                    <a *ngIf="link.link" href="{{link.link}}">{{link.label}}</a>
                    <span *ngIf="!link.link">{{link.label}}</span>
                </li>
                <li >{{title | slice:0:60}}<span *ngIf="title.length > 60">...</span></li>
                </ul>
            </div>
            </div>
        </div>
    </section>
    <section class="page mediaSection pageheader pagecontent">
        <div class="row">
            <div class="container">
                <div class="col-xs-12 col-sm-12 col-lg-12">
                    <div class="csrthumb" *ngIf="imgsrc?.length > 0"><img src="{{imgBasePath}}{{imgsrc}}" class="img-responsive" alt="{{title}}" title="{{title}}" /></div>
                    <div class="ptitle blue">
                        <h1>{{title}}</h1>
                        <h3>{{subtitle}}</h3>
                    </div>
                    <div [innerHTML]="description"></div>                    
                </div>
            </div>
        </div>
    </section>
    <section class="container-fluid aboutbod">
        <div class="row">
            <div class="container">
                <div class="col-xs-12 col-sm-12 col-lg-12 event-details">
                </div>
            </div>
        </div>
    </section>
    <ng-template [ngIf]="links.length > 0">
        <footer-nav-mini [links]="links" [activePage]="activePage"></footer-nav-mini>
    </ng-template>
    <find-a-doctors-nav></find-a-doctors-nav>
    <footer-subscription></footer-subscription>
    <footer class="footerNavwrp">
        <footer-nav></footer-nav>
    </footer>
    <footer-copyright-nav></footer-copyright-nav>

</div>