<div class="row">
    <div class="col-md-5 col-xs-5 col-lg-4">
        <div class="circuleframe">
        <img src="{{imgBasePath}}{{imgsrc}}" alt="" />
        </div><!-- End Circle -->
    </div>
    <div class="col-md-7 col-xs-7 col-lg-7">
        <div class="pagetitle">
            <h2 class="tgradient">{{title}} </h2>
            <div class="underline">
                <img src="assets/images/underline2.png" alt="" />
            </div>
            <h3>{{subtitle}}</h3>
        </div>
    </div>
    <div class="col-md-7 col-xs-7 col-lg-7 mcontentview">
        <p>
               {{description}}
            </p>

    </div>
</div>
