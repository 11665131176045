import {
  Inject,
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  PLATFORM_ID,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from '../services/api.service';
import { IMG_BASE_URL, BASE_URL } from '../services/constants';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
declare let $: any;
declare let initHome: any;
declare let initAllPage: any;
declare let needShareDropdown: any;
// declare let initAboutTabs:any;

@Component({
  selector: 'press-clips-details',
  templateUrl: './press-clips-details.component.html',
})
export class PressClipDetailsComponent
  implements OnDestroy, OnInit, AfterViewInit
{
  constructor(
    private router: Router,
    private api: ApiService,
    private activatedRoute: ActivatedRoute,
    public metaTitle: Title,
    public meta: Meta,
    private _sanitizer: DomSanitizer
  ) {
    if (this.api.isBrowser) {
      // some of these will be required in near future
      window['jQuery'] = $;
      window['$'] = $;
      require('../../assets/js/bootstrap.js');
      require('../../assets/js/slick.min.js');
      require('../../assets/js/needsharebutton.min.js');
    }
    this.activatedRoute.params.subscribe((params: Params) => {
      this.id = params['id'];
    });
    this.urlparamsID = this.id;
  }

  public pressClipId = localStorage.getItem('pressClipId');
  public safeURL: any;
  public videoUrl: boolean = false;
  public ptitle: any = '';
  public urlparamsID: any;

  public pressDetail: any = [];

  public title: any = '';
  public subtitle: any = '';
  public description: any = '';
  public imgsrc: any = '';
  public brief_facilities_description: any = '';
  public brief_facilities_image: any = '';
  public imgBasePath = '';
  public hidden = 'hidden';
  public logo = 'logo';
  public bread_crumb_links: any = [];
  public ogImage: any;
  public shareTitle: any;
  public shareText: any;
  public ShareMedia: any;

  public links: any = [
    { label: 'About Us', link: '/about-us', class: '' },
    { label: 'Who We are', link: '/about-us/who-we-are', class: '' },
    { label: 'What We do', link: '/about-us/what-we-do', class: '' },
    { label: 'Why Choose Us', link: '/about-us/why-chooseus', class: '' },
    {
      label: 'Cutting Edge Medical Solutions',
      link: '/about-us/cutting-edge-medical-solutions',
      class: '',
    },
    { label: 'MD Message', link: '/about-us/mds-message', class: '' },
    { label: 'Management', link: '/about-us/management', class: '' },
    { label: 'History', link: '/about-us/history', class: '' },
    { label: 'Awards', link: '/about-us/awards', class: '' },
    { label: 'Media', link: '/about-us/media', class: 'active' },
    { label: 'News', link: '/about-us/news', class: '' },
    { label: 'Events', link: '/about-us/events', class: '' },
    {
      label: 'Careers',
      link: 'https://ehubnext.myadrenalin.com/candidate/LoginPage.aspx?obj=0qKjcPeCekWVUp%2f8Wz3cmM5%2fF4SZ%2bT68',
      class: '',
    },
    { label: 'Channel', link: '/about-us/channel', class: '' },
    {
      label: 'Quality & Accreditation',
      link: '/about-us/quality-accreditation',
      class: '',
    },
  ];

  public id: any = '';

  ngOnInit() {
    //console.log('pressClipId:', this.pressClipId);

    this.activatedRoute.params.subscribe((params: Params) => {
      // this.urlparamsID = params['id'];
    });
    //console.log('urlparamsID', this.urlparamsID);

    this.api.getPageDescription('press-clips-details').subscribe(
      (data) => {
        this.title = data.page_descriptions[0].title;
        this.subtitle = data.page_descriptions[0].subtitle;
        this.description = data.page_descriptions[0].description;
        this.imgsrc = data.page_descriptions[0].image;
        this.imgBasePath = IMG_BASE_URL;
        this.ogImage = this.imgBasePath + this.imgsrc;
      },
      (error) => {
        console.log(error);
      }
    );

    this.api.getMediaPressClipsById(this.urlparamsID).subscribe(
      (data) => {
        console.log('getMediaPressClipsById:', data.press_clips[0]);
        this.pressDetail = data.press_clips[0];
        if (this.pressDetail.video !== null && this.pressDetail.video != '') {
          this.videoUrl = true;
        }
        this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(
          this.pressDetail.video
        );
        this.ptitle = this.pressDetail.meta_title;
        this.shareTitle = this.pressDetail.meta_title;
        this.shareText = this.pressDetail.meta_keyword;
        this.ShareMedia = this.imgBasePath + this.pressDetail.image;

        if (this.pressDetail.image != '') {
          this.ogImage = this.imgBasePath + this.pressDetail.image;
        } else {
          if (this.pressDetail.video != '') {
            this.ogImage = this.pressDetail.video;
          }
        }
        if (data) {
          //console.log(data);
          this.metaTitle.setTitle(this.pressDetail.meta_title);
          this.meta.updateTag({
            name: 'keywords',
            content: this.pressDetail.meta_keyword,
          });
          this.meta.updateTag({
            name: 'description',
            content: this.pressDetail.meta_description,
          });
          this.meta.addTags([
            { property: 'og:url', content: BASE_URL + this.router.url },
            { property: 'og:title', content: this.pressDetail.meta_title },
            {
              property: 'og:description',
              content: this.pressDetail.meta_description,
            },
            { property: 'og:keywords', content: this.pressDetail.meta_keyword },
            { property: 'og:image', content: this.ogImage },
          ]);
          this.meta.addTags([
            { name: 'twitter:url', content: BASE_URL + this.router.url },
            { name: 'twitter:title', content: this.pressDetail.meta_title },
            {
              name: 'twitter:description',
              content: this.pressDetail.meta_description,
            },
            {
              name: 'twitter:keywords',
              content: this.pressDetail.meta_keyword,
            },
            { name: 'twitter:image', content: this.ogImage },
          ]);
        }

        this.bread_crumb_links = [
          { label: 'Home', link: '/', class: '' },
          { label: 'About Us', link: '/about-us', class: '' },
          { label: 'Media', link: '/about-us/media', class: '' },
          {
            label: 'Press Clips',
            link: '/about-us/media/media-press-clips',
            class: '',
          },
        ];
      },
      (error) => {
        console.log(error);
      }
    );
  }
  socialShare(shareTitle, shareText, ShareMedia) {
    var id = document.getElementById('shareButton');
    new needShareDropdown(id, {
      boxForm: 'horizontal', // horizontal or vertical
      position: 'middleRight', // top / middle / bottom + Left / Center / Right
      url: window.location.href,
      title: shareTitle, //root.getTitle(),
      image: shareText, //root.getImage(),
      description: ShareMedia, //root.getDescription(),
    });
    setTimeout(function () {
      $('.need-share-button_linkedin').after(
        '<span class="need-share-button_link-box need-share-button_link button_watsapp"  id="whatsApp" data-text="' +
          window.location.href +
          '" data-action="share/whatsapp/share"><img src="assets/images/whatsapp.svg" width="22" height="22"/></span>'
      );
    }, 100);

    setTimeout(() => {
      $('#whatsApp').click(() => {
        var text = $(this).attr('data-text');
        window.open(
          'https://api.whatsapp.com/send?text=' + text,
          'myWindow',
          'width=600,height=500'
        );
      });
    }, 500);
  }
  ngAfterViewInit() {
    if (this.api.isBrowser && window['jQuery']) {
      initHome();
      initAllPage();
      this.socialShare(this.shareTitle, this.shareText, this.ShareMedia);
    }
  }

  ngOnDestroy() {}
}
